import { post, exibirToast } from '../../../../../../common/requisicoes/index'
import { services } from '../../../../../../common/constantes/api';

export async function asyncCompartilharDocumento(idDocumento, values, onSuccess) {

    var dados = {
        ...values,
        email: values.emailLabel,
        destinatariosCopia: values.enviarCopia? values.destinatariosCopia.split(',') : null
    };

    await post(`${services.GESTOR}/v1/documentos/${idDocumento}/enviar_para_assinatura`, dados, null, exibirToast(onSuccess, 'E-mail enviado com sucesso '));
}

export async function asyncCompartilharDocumentoUnicoSignatario(idDocumento, idSignatario, values, onSuccess) {

    var dados = {
        ...values,
        email: values.emailLabel,
        destinatariosCopia: values.enviarCopia? values.destinatariosCopia.split(',') : null
    };

    await post(`${services.GESTOR}/v1/documentos/${idDocumento}/signatarios/${idSignatario}/reenviar_email`, dados, null, exibirToast(onSuccess, 'E-mail enviado com sucesso '));
}
