import { Field } from 'formik';
import React from 'react';
import InputMoneyGroup from '../../../../../../../../components/Input/InputMoneyGroup';
import Dropdown from '../../../../../../../../components/Select/Dropdown';
import { optionsAliqInterUFEnvolvidas, optionsAliquotasFCP } from '../../../../../../utils/constantes';
import { helpNFeProd } from '../help';

export default function PartilhaICMS(props) {
    const { informacoesPermissoes, values, onChangeImpostoICMSUFDest, dest } = props;

    const disableButtonFlash = !dest.enderDest || !dest.enderDest.municipio || !informacoesPermissoes.podeEditar;

    return (
        <>
            <Field
                sm="12" md="4" lg='4' xl='4'
                label='Alíquota interna da UF de destino'
                name="pICMSUFDest"
                value={values.imposto.ICMSUFDest.pICMSUFDest}
                onChange={e => onChangeImpostoICMSUFDest('pICMSUFDest', e.target.value)}
                size={8}
                component={InputMoneyGroup}
                decimalScale={2}
                prefix=''
                suffix='%'
                maxValue={100}
                allowNegative={false}
                placeholder='0,00 %'
                iconButton="fa fa-flash"
                titleButton={disableButtonFlash ? "Você não pode buscar a alíquota do estado de destino" : "Buscar alíquota do estado de destino"}
                colorIconButton="success"
                disabledButton={disableButtonFlash}
                onClickButton={() => onChangeImpostoICMSUFDest('pICMSUFDest', dest.enderDest.estAliqInt)}
                helpMessage={helpNFeProd.pICMSUFDest}
                {...informacoesPermissoes}
            />
            <Field sm="12" md="4" lg='4' xl='4'
                component={Dropdown}
                label='Alíquota inter. das UF envolvidas'
                name="pICMSInter"
                onChange={e => onChangeImpostoICMSUFDest('pICMSInter', e.value)}
                options={optionsAliqInterUFEnvolvidas}
                value={values.imposto.ICMSUFDest.pICMSInter}
                showClear={false}
                helpMessage={helpNFeProd.pICMSInter}
                {...informacoesPermissoes}
            />
            <Field sm="12" md="4" lg='4' xl='4'
                component={Dropdown}
                label='Fundo de Combate à Pobreza'
                name="pFCPUFDest"
                onChange={e => onChangeImpostoICMSUFDest('pFCPUFDest', e.value)}
                options={optionsAliquotasFCP}
                value={values.imposto.ICMSUFDest.pFCPUFDest}
                showClear={false}
                helpMessage={helpNFeProd.pFCPUFDest}
                {...informacoesPermissoes}
            />
        </>
    )
}