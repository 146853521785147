import { manterApenasNumeros } from "../../../../common/mascara";

export function converterCadastroParaApi(values) {
    return {
        ...values,
        cpf: values.tipo === 'FISICA' ? manterApenasNumeros(values.cpf) : null,
        cnpj: values.tipo === 'JURIDICA' ? manterApenasNumeros(values.cnpj) : null,
        telefone: manterApenasNumeros(values.telefone),
        agenteAutorizado: values.agenteAutorizado ? {
            id: values.agenteAutorizado
        } : null
    }
}