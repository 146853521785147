export const buttonAlterarStyle = {
    color: '#006095',
    fontWeight: '700',
    padding: '14px 0px 0px 14px',
    cursor: 'pointer',
}

export const buttonRemoverCartaoStyle = {
    color: '#e91224',
    fontWeight: '700',
    padding: '14px 0px 0px 14px',
    cursor: 'pointer',
}


export const detalhesCardStyle = {
    color: '#7b7b7b',
    fontWeight: '600',
    margin: '0px',
    paddingLeft: '5px',
    paddingRight: '15px'
}

export const helpTooltipCardStyle = {
    paddingTop: '5px',
}