import { contaReceberStatus } from "../../../utils/constantes";

export function converterParcelaDoModalParaFormulario(registroSelecionado, values) {
    return {
        ...registroSelecionado,
        status: calcularValorAReceber(registroSelecionado, values) === 0 ? contaReceberStatus.RECEBIDA : contaReceberStatus.PARCIALMENTE_RECEBIDA,
        valorAReceber: calcularValorAReceber(registroSelecionado, values)
    }
}

function calcularValorAReceber(registroSelecionado, values) {
    const resultado = registroSelecionado.valorAReceber - values.valor;

    if (resultado <= 0) return 0;

    return parseFloat(resultado).toFixed(2);
}
