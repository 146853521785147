import React from 'react';
import autoBind from "react-autobind";
import { Field, withFormik } from 'formik';
import { Component } from "react";
import { services } from '../../../../../../../common/constantes/api';
import Grid from '../../../../../../../components/Grid';
import InputDouble from '../../../../../../../components/Input/InputDouble';
import SingleSelectProduto from '../../../../../../../components/Select/SingleSelectProduto';
import { helpMessageNFCe } from '../../help';
import { TiposDesconto } from '../../../util/constantes';
import { gerarUUID } from '../../../../../../../common/manipulacaoDeString';
import { buscarDadosLoginLocalStorage } from '../../../../../../../common/autenticacao';
import { buscarLabelCsosn } from '../../../util/functions';
import { buscarDisabledDeAcordoComAsPermissoes } from '../../../../../../../common/autorizacao/manipulacaoDeComponentes';
import InputSelectPercentualOrValorNfce from '../../../components/InputSelectPercentualOrValorNfce';

const customStyleSelects = {
    option: option => ({
        ...option,
        fontSize: 22
    }),
    control: control => ({
        ...control,
        height: 50,
        minHeight: 50,
    }),
    placeholder: placeholder => ({
        ...placeholder,
        fontSize: 22
    }),
    valueContainer: valueContainer => ({
        ...valueContainer,
        fontSize: 22
    })
};

const initialValue = {
    produto: {
        nome: '',
        valorUnitario: 0,
        unidadeMedida: '',
        ncm: '',
        cest: '',
        origem: ''
    },
    quantidade: 1,
    desconto: {
        tipoDesconto: TiposDesconto.VALOR,
        valorDesconto: 0
    }
}

class ProdutoNfceForm extends Component {

    constructor(props) {
        super(props);

        autoBind(this);

        this.state = {
            preferenciasNfce: {},
            restart: false
        }
    }

    componentDidMount() {
        const { organizacao } = buscarDadosLoginLocalStorage();

        let preferenciasNfce = {
            cfop: null,
            csosn: null,
        }

        if (organizacao.fiscalNfce) {
            const cfopPadraoNfce = organizacao.fiscalNfce.cfopPadraoNfce
            const csosnPadraoNfce = organizacao.fiscalNfce.csosnPadraoNfce

            if (cfopPadraoNfce) {
                preferenciasNfce = {
                    ...preferenciasNfce,
                    cfop: {
                        label: `${cfopPadraoNfce.codigo} - ${cfopPadraoNfce.descricao}`,
                        value: cfopPadraoNfce.id
                    }
                }
            }

            if (csosnPadraoNfce) {
                preferenciasNfce = {
                    ...preferenciasNfce,
                    csosn: {
                        label: buscarLabelCsosn(csosnPadraoNfce),
                        value: csosnPadraoNfce
                    }

                }
            }
        }

        this.setState({ preferenciasNfce: preferenciasNfce })
    }

    componentDidUpdate() {

        const { restart } = this.props;

        if (restart) {
            this.props.resetForm(initialValue)
            this.setState({restart: true})
        }
    }

    adicionarProdutoNaNfce(produto) {
        const registro = produto?.registro

        if (registro) {
            const { preferenciasNfce } = this.state
            const { values, onInsertProduto, resetForm } = this.props

            const produto = {
                idTemporario: gerarUUID(),
                idProd: registro.id,
                produto: registro.sku && registro.nome ? `${registro.sku}-${registro.nome}` : '',
                codigoBarras: registro.codigoBarras ?? '',
                quantidade: values.quantidade > 0 ? values.quantidade : '',
                valorUnitario: registro.valorUnitario > 0 ? registro.valorUnitario : '',
                unidadeMedida: registro.unidadeMedida ? { label: `${registro.unidadeMedida.unidade}-${registro.unidadeMedida.descricao}`, value: registro.unidadeMedida.id } : '',
                ncm: registro.ncm ? { label: `${registro.ncm.codigo} - ${registro.ncm.descricao}`, value: registro.ncm.id } : '',
                cest: registro.cest ? { label: `${registro.cest.codigo} - ${registro.cest.descricao}`, value: registro.cest.id } : '',
                cfop: preferenciasNfce.cfop ?? '',
                csosn: preferenciasNfce.csosn ? preferenciasNfce.csosn.value : '',
                origem: registro.origem ?? '',
                desconto: {
                    tipoDesconto: values.desconto?.tipoDesconto ?? '',
                    valorDesconto: values.desconto?.valorDesconto ?? 0,
                }
            }

            onInsertProduto({ produto })
            resetForm(initialValue)
            this.setState({restart: true})
        }
    }

    trocarTipoDesconto(tipo, value, percentual) {
        const desconto = {
            tipoDesconto: tipo,
            valorDesconto: tipo === TiposDesconto.PERCENTUAL ? percentual : value
        }
        this.props.setFieldValue('desconto', desconto)
    }

    render() {
        const { values, disabled, podeInserir, podeEditar, estadoCadastro, } = this.props

        const disabledFormatado = buscarDisabledDeAcordoComAsPermissoes(
            podeInserir,
            podeEditar,
            estadoCadastro,
            disabled
        )

        return (
            <Grid>
                <Field
                    sm="12" md="6" lg='6' xl='6'
                    name="produto"
                    label="Produto"
                    labelSize={18}
                    component={SingleSelectProduto}
                    value={values.produto.nome}
                    obrigatorio
                    styles={customStyleSelects}
                    helpMessage={helpMessageNFCe.produto}
                    onChange={produtoSelecionado => this.adicionarProdutoNaNfce(produtoSelecionado)}
                    url={`${services.GESTOR}/v1/nfce/relacoes/produtos`}
                    tipoPredefinido="PRODUTO"
                    disabled={disabledFormatado}
                    filtroAdicionalUrl=";tipo==PRODUTO;situacao==ATIVO"
                    esconderBotao={true}
                />
                <Field sm="12" md="3" lg='3' xl='3'
                    component={InputDouble}
                    name="quantidade"
                    label='Quantidade/Peso'
                    obrigatorio
                    labelSize={18}
                    style={{ height: '50px', fontSize: '22px' }}
                    placeholder='0,00'
                    onChange={e => this.props.setFieldValue('quantidade', e.target.value)}
                    value={values.quantidade}
                    size={10}
                    disabled={disabledFormatado}
                    helpMessage={helpMessageNFCe.quantidade}
                />
                <Field
                    sm="12" md="3" lg='3' xl='3'
                    component={InputSelectPercentualOrValorNfce}
                    name="desconto"
                    label="Desconto total item"
                    labelSize={18}
                    restart={this.state.restart}
                    onRestart={() => { this.setState({restart: false})}}
                    style={{ height: '50px', fontSize: '22px' }}
                    placeholder='0,00'
                    onChange={(tipo, value, percentual) => this.trocarTipoDesconto(tipo, value, percentual)}
                    value={values.desconto.valorDesconto}
                    size={9}
                    disabled={disabledFormatado}
                    helpMessage={helpMessageNFCe.descontoProduto}
                    allowNegative={false}
                />
            </Grid>
        )
    }
}

ProdutoNfceForm = withFormik({

    enableReinitialize: true,
    validateOnChange: false,

    mapPropsToValues() {
        return initialValue
    },

})(ProdutoNfceForm);

export default ProdutoNfceForm;