import React from 'react';
import Col from '../../../../../../../../../../components/Col';
import SingleSelectProduto from '../../../../../../../../../../components/Select/SingleSelectProduto';

export default function ProdutoComEstoqueDisponivel(props) {
    const {
        colStyle,
        url,
        label,
        helpMessage,
        name,
        onChange,
        value,
        placeholder,
        touched,
        disabled,
        errors,
        informacoesPermissoes,
        options,
        loadInitialValues,
        onChangeOptions,
        sm, md, lg, xl,
        mostrarInformacaoDoEstoque,
        exibirLabels
    } = props;

    function montarMensagemProdutoDisponivel() {
        if (value && value.registro && mostrarInformacaoDoEstoque && value.registro.tipo === "PRODUTO") {
            return (
                <div style={{ fontSize: '12px', padding: '0px' }}>
                    Saldo atual no estoque: {value.registro.estoqueSaldo}
                </div>
            )
        }
    }

    return (
        <Col sm={sm} md={md} lg={lg} xl={xl} style={{ padding: '0px 4px', minHeight: exibirLabels ? '73px' : '55px' }}>
            <SingleSelectProduto
                colStyle={colStyle}
                url={url}
                label={label}
                helpMessage={helpMessage}
                name={name}
                onChange={onChange}
                value={value}

                options={options}
                loadInitialValues={loadInitialValues}
                onChangeOptions={onChangeOptions}

                placeholder={placeholder}
                touched={touched}
                disabled={disabled}
                errors={errors}
                {...informacoesPermissoes}
            />
            {montarMensagemProdutoDisponivel()}
        </Col>
    )
}