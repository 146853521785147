import { Field, withFormik } from 'formik';
import React, { Component } from 'react';
import autoBind from 'react-autobind';
import * as Yup from 'yup';
import { estadosCadastro, modulos, permissoes, recursos } from '../../../../common/constantes/autorizacao';
import { mensagensDeValidacao } from '../../../../common/constantes/mensagens';
import { removerCaracteres, validarUUID } from '../../../../common/manipulacaoDeString';
import ButtonCancelar, { estadosBotaoCancelar } from '../../../../components/Button/ButtonCancelar';
import ButtonExcluir from '../../../../components/Button/ButtonExcluir';
import ButtonNovo, { estadosBotaoNovo } from '../../../../components/Button/ButtonNovo';
import ButtonSalvar from '../../../../components/Button/ButtonSalvar';
import Grid from '../../../../components/Grid';
import InputField from '../../../../components/Input//InputField';
import MsysCheckbox from '../../../../components/Input/Checkbox';
import InputMoney from '../../../../components/Input/InputMoney';
import Prompt from '../../../../components/Route/Prompt';
import { atualizarUrl, metodosAtualizarUrl, validarFormulario, voltarParaAPesquisa } from '../../../util';
import { converterProdutoParaFormulario, converterProdutoParaApi } from '../util/produtoConverter';
import { confirmarExclusao } from '../../../util/exclusaoDeRegistros';
import { asyncCreateProduto, asyncDeleteProduto, asyncGetProduto, asyncUpdateProduto, asyncGetProdutoMesmoSku, asyncAjustarSaldo } from '../requests';
import { helpProdutosForm } from './help';
import TextArea from '../../../../components/TextArea';
import { usuarioPossuiModulo, usuarioPossuiPermissao } from '../../../../common/autenticacao';
import If from '../../../../components/If';
import AutoProgressBar from '../../../../components/Loading/AutoProgressBar';
import { keyFilterConsultaRsql } from '../../../../common/rsql';
import Form from '../../../../components/Form';
import FormActions from '../../../../components/FormActions';
import FormContent from '../../../../components/FormContent';
import SingleSelectUnidadeMedida from '../../../../components/Select/SingleSelectUnidadeMedida';
import Col from '../../../../components/Col';
import InputDouble, { InternalInputDouble } from '../../../../components/Input/InputDouble';
import Button from '../../../../components/Button';
import { Menu } from 'primereact/menu';
import ModalEntradaEstoque from '../../../estoque/Movimentacoes/components/ModalEntradaEstoque';
import ModalSaidaEstoque from '../../../estoque/Movimentacoes/components/ModalSaidaEstoque';
import { FaCheck, FaTimes } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import Dropdown from '../../../../components/Select/Dropdown';
import './style/index.css'
import SingleSelectNcm from '../../../../components/Select/SingleSelectNcm';
import { services } from '../../../../common/constantes/api';
import SingleSelectCest from '../../../../components/Select/SingleSelectCest';
import Fieldset from '../../../../components/FieldSet';
import { confirm } from '../../../../components/Toast';

const CADASTROURL = '/produtos/cadastro'
const PESQUISAURL = '/produtos'

const tipo = [
    { label: 'Produto', value: 'PRODUTO' },
    { label: 'Serviço', value: 'SERVICO' },
];

const origem = [
    { label: '0 - Nacional, exceto as indicadas nos código 3, 4, 5 e 8', value: 'NACIONAL' },
    { label: '1 - Estrangeira - Importação direta, exceto a indicada no código 6', value: 'ESTRANGEIRA' },
    { label: '2 - Estrangeira - Adquirida no mercado interno, exceto a indicada no código 7', value: 'ESTRANGEIRA_ADQUIRIDA_MERCADO_INTERNO' },
    { label: '3 - Nacional, mercadoria ou bem com Conteúdo de Importação superior a 40% e inferior ou igual a 70%', value: 'NACIONAL_IMPORTACAO_ENTRE_40_E_70_PORCENTO' },
    { label: '4 - Nacional, cuja produção tenha sido feita em conformidade com os processos produtivos básicos de que tratam as legislações citadas nos Ajustes', value: 'NACIONAL_COM_PROCESSO_PRODUTIVO' },
    { label: '5 - Nacional, mercadoria ou bem com Conteúdo de Importação inferior ou igual a 40%', value: 'NACIONAL_IMPORTACAO_INFERIOR_40_PORCENTO' },
    { label: '6 - Estrangeira - Importação direta, sem similar nacional, constante em lista da CAMEX e gás natural', value: 'ESTRANGEIRA_IMPORTACAO_DIRETA' },
    { label: '7 - Estrangeira - Adquirida no mercado interno, sem similar nacional, constante em lista da CAMEX e gás natural', value: 'ESTRANGEIRA_ADQUIR_MERCADO_INTERNO_SEM_SIMILAR_NAC' },
    { label: '8 - Nacional, mercadoria ou bem com Conteúdo de Importação superior a 70%', value: 'NACIONAL_IMPORTACAO_SUPERIOR_70_PORCENTO' },
]

const styleButtonEditar = {
    color: "#006095",
    cursor: 'pointer',
    width: "32px",
    height: "32px",
    backgroundColor: "transparent",
    borderColor: "transparent",
    fontSize: "22px",
    boxShadow: "none",
    marginTop: '15px',
};


const styleButtonCancelarAlteracaoEstoque = {
    color: '#e91224',
    cursor: 'pointer',
    padding: '5px',
    width: "32px",
    height: "32px",
    marginTop: '26px',
};

const styleButtonSalvarAlteracaoEstoque = {
    color: '#34a835',
    cursor: 'pointer',
    padding: '5px',
    width: "32px",
    height: "32px",
    marginTop: '26px',
};

const styleButtonSalvarAlteracaoEstoqueDisabled = {
    color: '#34a835',
    cursor: 'unset',
    opacity: '0.5',
    padding: '5px',
    width: "32px",
    height: "32px",
    marginTop: '26px',
};

const initialValue = {
    id: "",
    nome: '',
    sku: '',
    situacao: 'ATIVO',
    tipo: 'PRODUTO',
    controlarEstoque: true,
    preco: 0,
    custo: 0,
    codigoBarras: '',
    unidadeMedida: null,
    observacao: '',
    estoqueMinimo: null,
    estoqueSaldo: null,
    ultimoCusto: null,

    origem: null,
    ncm: null,
    cest: null,
    pesoBruto: null,
    pesoLiquido: null
}

class ProdutosForm extends Component {

    constructor(props) {
        super(props);

        autoBind(this);

        this.state = {
            exibirModalEntradaEstoque: false,
            exibirModalSaidaEstoque: false,
            ajustarSaldoEmEstoque: false,
            estoqueSaldo: null,
            estoqueSaldoAnterior: null,
            ultimoCusto: null,

            possuiModuloEstoque: usuarioPossuiModulo(modulos.ESTOQUE),
            possuiModuloVendas: usuarioPossuiModulo(modulos.VENDAS),

            podeInserir: usuarioPossuiPermissao(recursos.PRODUTOS, permissoes.INSERIR),
            podeEditar: usuarioPossuiPermissao(recursos.PRODUTOS, permissoes.EDITAR),
            podeExcluir: usuarioPossuiPermissao(recursos.PRODUTOS, permissoes.EXCLUIR),

            podeVisualizarMovimentacaoEstoque: usuarioPossuiPermissao(recursos.ESTOQUE_MOVIMENTACOES, permissoes.VISUALIZAR),
            podeInserirMovimentacaoEstoque: usuarioPossuiPermissao(recursos.ESTOQUE_MOVIMENTACOES, permissoes.INSERIR),
        }
    }

    componentDidMount() {
        if (!this.props.isModal) {
            const id = this.props.match.params.id;

            if (validarUUID(id)) {
                this.asyncSelectRegistro(id)
            }
        }
    }

    buscarEstadoCadastro() {
        return this.props.values.id ? estadosCadastro.EDICAO : estadosCadastro.INCLUSAO
    }

    novo() {
        atualizarUrl(this.props.history, CADASTROURL, null, metodosAtualizarUrl.POP)
        this.props.resetForm({ values: initialValue })
        this.setState({
            estoqueSaldo: null,
            estoqueSaldoAnterior: null,
            ultimoCusto: null,
        })
    }

    excluir() {
        confirmarExclusao(this.asyncDeleteRegistro)
    }

    async salvar(e, novoOnSuccess) {

        this.props.handleSubmit()

        if (await validarFormulario(this.props)) {
            if (await this.validarSku()) {
                let dadosFormulario = converterProdutoParaApi(this.props.values)
                if (this.props.values.id) {
                    this.asyncUpdateRegistro(dadosFormulario, novoOnSuccess)
                    this.setState({
                        ajustarSaldoEmEstoque: false,
                        estoqueSaldo: this.state.estoqueSaldoAnterior
                    })
                } else {
                    this.asyncCreateRegistro(dadosFormulario, novoOnSuccess)
                    this.setState({
                        estoqueSaldo: dadosFormulario.estoqueSaldo,
                        estoqueSaldoAnterior: dadosFormulario.estoqueSaldo,
                        ultimoCusto: dadosFormulario.ultimoCusto,
                    })
                }
            } else {
                this.props.setFieldError('sku', 'O código informado já existe')
            }
        }
    }

    async validarSku() {
        let skuJaExiste = false
        const { values } = this.props
        await asyncGetProdutoMesmoSku(values.sku, (e) => {
            if (e.data.content.length > 0 && e.data.content[0].id !== values.id) {
                skuJaExiste = true
            }
        })
        return !skuJaExiste
    }

    cancelar() {
        if (this.props.dirty) {
            this.props.resetForm({ values: this.props.initialValues })
        } else if (this.props.isModal) {
            this.fecharModal()
        } else {
            this.props.history.goBack();
        }
        this.setState({
            ajustarSaldoEmEstoque: false,
            estoqueSaldo: this.state.estoqueSaldoAnterior
        })
    }

    fecharModal() {
        if (this.props.values.id) {
            this.props.hideModal(this.props.values);
        } else {
            this.props.hideModal();
        }
    }

    async asyncDeleteRegistro() {
        await asyncDeleteProduto(this.props.values.id, () => {
            this.props.resetForm()
            voltarParaAPesquisa(this.props.history, PESQUISAURL);
        })
    }

    async asyncUpdateRegistro(dadosFormulario, novoOnSuccess) {
        if (this.props.isModal && !this.props.dirty && this.props.values.id) {
            this.fecharModal()
        } else {
            await asyncUpdateProduto(dadosFormulario, () => {
                if (novoOnSuccess) {
                    novoOnSuccess();
                } else {
                    this.props.resetForm({ values: this.props.values })
                }
            })
        }
    }

    async asyncCreateRegistro(dadosFormulario, novoOnSuccess) {
        await asyncCreateProduto(dadosFormulario, ({ data: produto }) => {

            if (novoOnSuccess) {
                novoOnSuccess();
            } else {
                this.props.resetForm({ values: { ...this.props.values, id: produto.id } })
                if (this.props.isModal) {
                    this.fecharModal()
                } else {
                    atualizarUrl(this.props.history, CADASTROURL, produto.id, metodosAtualizarUrl.POP)
                }
            }
        })
    }

    async asyncSelectRegistro(idProduto) {
        await asyncGetProduto(idProduto, ({ data: produto }) => {
            this.props.resetForm({ values: converterProdutoParaFormulario(produto) })
            if (!this.props.isModal) {
                atualizarUrl(this.props.history, CADASTROURL, idProduto, metodosAtualizarUrl.POP)
            }
            this.setState({
                estoqueSaldo: produto.estoqueSaldo,
                estoqueSaldoAnterior: produto.estoqueSaldo,
                ultimoCusto: produto.ultimoCusto,
            })
        })
    }

    async asyncAjustarSaldoEmEstoquePorProduto(idProduto, data) {
        if (this.state.estoqueSaldo !== this.state.estoqueSaldoAnterior) {
            await asyncAjustarSaldo(idProduto, data, () => this.asyncSelectRegistro(idProduto))
        }
        this.setState({ ajustarSaldoEmEstoque: false })
    }


    onHideModalEstoque() {
        this.setState({ exibirModalEntradaEstoque: false, exibirModalSaidaEstoque: false }, () => this.asyncSelectRegistro(this.props.values.id))
    }

    onCancelarAlteracaoEstoque() {
        this.setState({
            ajustarSaldoEmEstoque: false,
            estoqueSaldo: this.state.estoqueSaldoAnterior
        })
    }

    onSalvarAlteracoesEstoque(values) {
        let novoSaldo = this.state.estoqueSaldo
        if (values.controlarEstoque && novoSaldo < values.estoqueMinimo && novoSaldo !== null && novoSaldo >= 0) {
            confirm("Confirmação", "Novo saldo será menor que estoque mínimo, deseja continuar?", () => {
                this.movimentarNovoSaldoEstoque(values)
            }, () => {
                this.setState({
                    ajustarSaldoEmEstoque: false,
                    estoqueSaldo: this.state.estoqueSaldoAnterior
                })
            })
        } else {
            this.movimentarNovoSaldoEstoque(values)
        }
    }

    movimentarNovoSaldoEstoque(values) {
        let novoSaldo = this.state.estoqueSaldo
        if (novoSaldo !== null && novoSaldo >= 0) {
            this.asyncAjustarSaldoEmEstoquePorProduto(values.id, {
                custo: values.ultimoCusto,
                novoSaldo: this.state.estoqueSaldo
            })
        }
    }

    render() {

        const { exibirModalEntradaEstoque, exibirModalSaidaEstoque, ajustarSaldoEmEstoque, possuiModuloEstoque, possuiModuloVendas, podeVisualizarMovimentacaoEstoque } = this.state

        const informacoesPermissoes = {
            estadoCadastro: this.buscarEstadoCadastro(),
            podeInserir: this.state.podeInserir,
            podeEditar: this.state.podeEditar,
            podeExcluir: this.state.podeExcluir
        }

        const { values, isModal, dirty, setFieldValue, tipoPredefinido } = this.props;

        const estadoBotaoCancelar = dirty ? estadosBotaoCancelar.CANCELAR : estadosBotaoCancelar.VOLTAR
        const estadoBotaoNovo = dirty ? estadosBotaoNovo.SALVAR_E_NOVO : estadosBotaoNovo.NOVO;

        const desabilitarBotaoAlterarEstoque = !this.state.podeInserirMovimentacaoEstoque || !this.state.podeVisualizarMovimentacaoEstoque || !informacoesPermissoes.podeEditar
        const isEdicao = informacoesPermissoes.estadoCadastro === estadosCadastro.EDICAO
        const isInsercao = !isEdicao

        const produtoSelecionado = {
            label: values.sku + ' - ' + values.nome,
            value: values.id,
            registro: {
                ...values,
                custo: values.ultimoCusto
            }
        }

        const onClickNovo = dirty ? (e) => this.salvar(e, this.novo) : this.novo;

        return (
            <>
                <Menu style={{ width: "212px" }}
                    model={[
                        {
                            label: 'Nova entrada de estoque',
                            icon: 'fa fa-plus',
                            command: () => this.setState({ exibirModalEntradaEstoque: true }),
                            disabled: false
                        },
                        {
                            label: 'Nova saída de estoque',
                            icon: 'fa fa-minus',
                            command: () => this.setState({ exibirModalSaidaEstoque: true }),
                            disabled: false
                        },
                        {
                            label: 'Ajustar saldo em estoque',
                            icon: 'fa fa-wrench',
                            command: () => {
                                this.setState({ ajustarSaldoEmEstoque: true }, () => {
                                    document.getElementById('estoqueSaldo').focus()
                                })
                            },
                            disabled: false
                        }
                    ]}
                    popup={true}
                    ref={el => this.novaMovimentacao = el}
                />

                <Prompt dirty={dirty} isModal={isModal} />

                <Form header="Cadastro de produto e serviço" isModal={isModal} className="card-default screen-max-width">
                    <If test={this.props.isModal}>
                        <AutoProgressBar />
                    </If>
                    <FormActions className="screen-max-width">
                        <ButtonCancelar
                            {...informacoesPermissoes}
                            hidden={Boolean(isModal && values.id && !dirty)}
                            estadoBotao={estadoBotaoCancelar}
                            onClick={this.cancelar}
                        />
                        <ButtonSalvar
                            {...informacoesPermissoes}
                            disabled={!dirty}
                            onClick={() => this.salvar()}
                        />
                        <ButtonNovo
                            onClick={onClickNovo}
                            hidden={(!dirty && !values.id) || isModal}
                            estadoBotao={estadoBotaoNovo}
                            {...informacoesPermissoes}
                        />
                        <ButtonExcluir
                            hidden={!values.id || isModal}
                            {...informacoesPermissoes}
                            onClick={this.excluir}
                        />
                    </FormActions>
                    <FormContent>
                        <Grid>
                            <Field
                                sm="12" md="12" lg='12' xl='12'
                                component={MsysCheckbox}
                                label="Ativo"
                                name="situacao"
                                helpMessage={helpProdutosForm.situacao}
                                onChange={(e) => setFieldValue('situacao', e.checked ? 'ATIVO' : 'INATIVO')}
                                checked={values.situacao === 'ATIVO'}
                                {...informacoesPermissoes}
                            />
                            <Field sm="12" md="4" lg='4' xl='3'
                                component={InputField}
                                label='Código'
                                obrigatorio
                                name="sku"
                                keyfilter={keyFilterConsultaRsql}
                                size={60}
                                helpMessage={helpProdutosForm.sku}
                                {...informacoesPermissoes}
                            />
                            <Field sm="12" md="4" lg='4' xl='3'
                                component={InputField}
                                label='Nome'
                                obrigatorio
                                name="nome"
                                onChange={e => setFieldValue('nome', removerCaracteres(e.target.value, ['─']))}
                                value={values.nome}
                                helpMessage={helpProdutosForm.nome}
                                size={120}
                                {...informacoesPermissoes}
                            />
                            <Field
                                sm="12" md="4" lg='4' xl='3'
                                component={Dropdown}
                                label='Tipo'
                                obrigatorio
                                name="tipo"
                                showClear={false}
                                helpMessage={helpProdutosForm.tipo}
                                onChange={(e) => this.props.setFieldValue('tipo', e.value)}
                                options={tipo}
                                disabled={tipoPredefinido}
                                {...informacoesPermissoes}
                            />
                            <Field sm="12" md="4" lg='4' xl='3'
                                component={InputMoney}
                                label='Preço'
                                name="preco"
                                size={12}
                                helpMessage={helpProdutosForm.preco}
                                onChange={e => setFieldValue('preco', e.target.value)}
                                value={values.preco}
                                {...informacoesPermissoes}
                            />
                            <If test={values.tipo === 'PRODUTO'}>
                                <Field sm="12" md="4" lg='4' xl='3'
                                    component={InputField}
                                    label='Código de barras'
                                    type="number"
                                    name="codigoBarras"
                                    onChange={e => setFieldValue('codigoBarras', e.target.value)}
                                    value={values.codigoBarras}
                                    size={13}
                                    helpMessage={helpProdutosForm.codigoBarras}
                                    {...informacoesPermissoes}
                                />
                                <Field sm="12" md="4" lg='4' xl='3'
                                    label='Unidade de medida'
                                    name="unidadeMedida"
                                    component={SingleSelectUnidadeMedida}
                                    value={values.unidadeMedida}
                                    onChange={e => setFieldValue('unidadeMedida', e)}
                                    helpMessage={helpProdutosForm.unidadeMedida}
                                    {...informacoesPermissoes}
                                />
                            </If>
                        </Grid>
                        <If test={values.tipo === 'PRODUTO' && possuiModuloEstoque}>
                            <Grid>
                                <Col>
                                    <Fieldset legend='Estoque' className='fieldset-light'>
                                        <Grid>
                                            <Field
                                                col="12"
                                                component={MsysCheckbox}
                                                label="Controlar estoque deste produto"
                                                name="controlarEstoque"
                                                helpMessage={helpProdutosForm.controlarEstoque}
                                                onChange={(e) => { setFieldValue('controlarEstoque', !values.controlarEstoque) }}
                                                checked={values.controlarEstoque}
                                                disabled={Boolean(tipoPredefinido)}
                                                {...informacoesPermissoes}
                                            />
                                        </Grid>
                                        <If test={values.controlarEstoque}>
                                            <Grid>
                                                <Field sm="12" md="6" lg='4' xl='3'
                                                    component={InputDouble}
                                                    label='Estoque mínimo'
                                                    name="estoqueMinimo"
                                                    onChange={e => setFieldValue('estoqueMinimo', e.target.value)}
                                                    value={values.estoqueMinimo}
                                                    size={13}
                                                    helpMessage={helpProdutosForm.estoqueMinimo}
                                                    {...informacoesPermissoes}
                                                />
                                                <If test={isInsercao}>
                                                    <Field sm="12" md="6" lg='4' xl='3'
                                                        component={InputDouble}
                                                        label='Saldo em estoque'
                                                        name="estoqueSaldo"
                                                        id='estoqueSaldo'
                                                        onChange={e => setFieldValue('estoqueSaldo', e.target.value)}
                                                        value={values.estoqueSaldo}
                                                        size={13}
                                                        helpMessage={helpProdutosForm.estoqueSaldo}
                                                        {...informacoesPermissoes}
                                                    />
                                                    <Field sm="12" md="6" lg='4' xl='3'
                                                        component={InputMoney}
                                                        label='Último custo'
                                                        name="ultimoCusto"
                                                        size={12}
                                                        obrigatorio={values.estoqueSaldo}
                                                        disabled={!values.estoqueSaldo}
                                                        title={!values.estoqueSaldo ? "Informe um Saldo em estoque para habilitar o Último custo" : null}
                                                        helpMessage={helpProdutosForm.ultimoCusto}
                                                        onChange={e => setFieldValue('ultimoCusto', e.target.value)}
                                                        value={values.ultimoCusto}
                                                        {...informacoesPermissoes}
                                                    />
                                                </If>

                                                <If test={isEdicao}>
                                                    <Field sm="12" md="6" lg='4' xl='3'
                                                        component={InputMoney}
                                                        label='Último custo'
                                                        disabled
                                                        name="ultimoCusto"
                                                        value={this.state.ultimoCusto}
                                                        title={!this.state.ultimoCusto ? "Não há informação de último custo" : null}
                                                        helpMessage={helpProdutosForm.ultimoCusto}
                                                        {...informacoesPermissoes}
                                                    />
                                                    <Col sm="12" md="6" lg='4' xl='3'>
                                                        <Grid>
                                                            <InternalInputDouble
                                                                colStyle={{ paddingBottom: '0px', width: ajustarSaldoEmEstoque ? 'calc(100% - 64px)' : 'calc(100% - 32px)' }}
                                                                title={ajustarSaldoEmEstoque && desabilitarBotaoAlterarEstoque ? null : "Clique no ícone de edição para alterar o saldo em estoque"}
                                                                label='Saldo em estoque'
                                                                disabled={!ajustarSaldoEmEstoque}
                                                                onKeyDown={(e) => e.key === "Enter" && this.onSalvarAlteracoesEstoque(values)}
                                                                name="estoqueSaldo"
                                                                id='estoqueSaldo'
                                                                onChange={e => this.setState({ estoqueSaldo: e.target.value })}
                                                                value={this.state.estoqueSaldo}
                                                                size={13}
                                                                helpMessage={helpProdutosForm.estoqueSaldo}
                                                                {...informacoesPermissoes}
                                                            />
                                                            <If test={!ajustarSaldoEmEstoque}>
                                                                <Button
                                                                    icon="fa fa-pencil"
                                                                    title={desabilitarBotaoAlterarEstoque ? "Você não tem permissão para executar esta ação" : "Alterar estoque deste produto"}
                                                                    style={styleButtonEditar}
                                                                    styleContentButton={{ display: "flex", alignItems: "center" }}
                                                                    disabled={desabilitarBotaoAlterarEstoque}
                                                                    onClick={event => this.novaMovimentacao.toggle(event)}
                                                                    tabIndex={-1}
                                                                />
                                                            </If>
                                                            <If test={ajustarSaldoEmEstoque}>
                                                                <FaCheck
                                                                    style={this.state.estoqueSaldo !== null && this.state.estoqueSaldo >= 0 ? styleButtonSalvarAlteracaoEstoque : styleButtonSalvarAlteracaoEstoqueDisabled}
                                                                    title={this.state.estoqueSaldo !== null && this.state.estoqueSaldo >= 0 ? "Salvar alteração de estoque" : "Informe um saldo válido"}
                                                                    onClick={() => this.onSalvarAlteracoesEstoque(values)}
                                                                />
                                                                <FaTimes
                                                                    style={styleButtonCancelarAlteracaoEstoque}
                                                                    title="Cancelar alteração de estoque"
                                                                    onClick={this.onCancelarAlteracaoEstoque}
                                                                />
                                                            </If>
                                                            <If test={podeVisualizarMovimentacaoEstoque}>
                                                                <Link
                                                                    id='botao-ver-movimentacoes-estoque-produto'
                                                                    title='Visualizar movimentações de estoque do produto'
                                                                    to={{
                                                                        pathname: `/movimentacoes_estoque`,
                                                                        state: {
                                                                            sku: values.sku,
                                                                            nome: values.nome,
                                                                            id: values.id
                                                                        }
                                                                    }}>
                                                                    Visualizar movimentações
                                                        </Link>
                                                            </If>
                                                        </Grid>
                                                    </Col>
                                                </If>
                                            </Grid>
                                        </If>
                                    </Fieldset>
                                </Col>
                            </Grid>
                        </If>

                        <If test={values.tipo === 'PRODUTO' && possuiModuloVendas}>
                            <Grid>
                                <Col>
                                    <Fieldset legend='Nota fiscal' className='fieldset-light'>
                                        <Grid>
                                            <Field
                                                sm="12" md="6" lg='6' xl='6'
                                                component={Dropdown}
                                                label='Origem do produto'
                                                name="origem"
                                                helpMessage={helpProdutosForm.origem}
                                                onChange={(e) => this.props.setFieldValue('origem', e.value)}
                                                options={origem}
                                                {...informacoesPermissoes}
                                            />
                                            <Field sm="12" md="6" lg='6' xl='6'
                                                label='NCM'
                                                name="ncm"
                                                component={SingleSelectNcm}
                                                url={`${services.GESTOR}/v1/produtos/relacoes/ncms`}
                                                value={values.ncm}
                                                onChange={e => setFieldValue('ncm', e)}
                                                helpMessage={helpProdutosForm.ncm}
                                                {...informacoesPermissoes}
                                            />
                                            <Field sm="12" md="6" lg='6' xl='6'
                                                label='CEST'
                                                name="cest"
                                                component={SingleSelectCest}
                                                value={values.cest}
                                                onChange={e => setFieldValue('cest', e)}
                                                helpMessage={helpProdutosForm.cest}
                                                {...informacoesPermissoes}
                                            />
                                            <Field
                                                sm="12" md="6" lg="3" xl="3"
                                                component={InputMoney}
                                                label='Peso bruto'
                                                name="pesoBruto"
                                                size={11}
                                                prefix=""
                                                placeholder=""
                                                helpMessage={helpProdutosForm.pesoBruto}
                                                onChange={e => this.props.setFieldValue('pesoBruto', e.target.value)}
                                                value={values.pesoBruto}
                                                {...informacoesPermissoes}
                                            />
                                            <Field
                                                sm="12" md="3" lg="3" xl="3"
                                                component={InputMoney}
                                                label='Peso líquido'
                                                size={11}
                                                prefix=""
                                                placeholder=""
                                                helpMessage={helpProdutosForm.pesoLiquido}
                                                onChange={e => this.props.setFieldValue('pesoLiquido', e.target.value)}
                                                value={values.pesoLiquido}
                                                {...informacoesPermissoes}
                                            />
                                        </Grid>
                                    </Fieldset>
                                </Col>
                            </Grid>
                        </If>

                        <Grid style={{ marginTop: '-18px' }} >
                            <Col>
                                <Fieldset legend='Informações complementares' className='fieldset-light'>
                                    <Grid>
                                        <Field sm="12" md="12" lg='12' xl='12'
                                            component={TextArea}
                                            placeholder="Escreva sua observação aqui"
                                            label='Observações'
                                            name="observacao"
                                            helpMessage={helpProdutosForm.observacao}
                                            onChange={e => setFieldValue('observacao', e.target.value)}
                                            {...informacoesPermissoes}
                                        />
                                    </Grid>
                                </Fieldset>
                            </Col>
                        </Grid>
                    </FormContent>
                </Form>
                <If test={exibirModalEntradaEstoque}>
                    <ModalEntradaEstoque
                        visible={exibirModalEntradaEstoque}
                        produto={produtoSelecionado}
                        onHide={this.onHideModalEstoque}
                    />
                </If>
                <If test={exibirModalSaidaEstoque}>
                    <ModalSaidaEstoque
                        visible={exibirModalSaidaEstoque}
                        produto={produtoSelecionado}
                        onHide={this.onHideModalEstoque}
                    />
                </If>
            </>
        )
    }
}

ProdutosForm = withFormik({

    enableReinitialize: true,
    validateOnChange: false,

    mapPropsToValues(props) {
        if (props.tipoPredefinido) {
            return {
                ...initialValue,
                tipo: props.tipoPredefinido
            }
        }
        return initialValue
    },

    validate(values) {
        let errors = {};

        if (!values.id && values.estoqueSaldo > 0 && values.ultimoCusto <= 0) {
            errors.ultimoCusto = "Informe o custo"
        }

        if (values.estoqueMinimo && values.estoqueMinimo < 0) {
            errors.estoqueMinimo = "Informe um valor válido"
        }

        return errors
    },

    validationSchema: Yup.object().shape({
        sku: Yup.string().nullable().required(mensagensDeValidacao.OBRIGATORIO),
        nome: Yup.string().nullable().required(mensagensDeValidacao.OBRIGATORIO)
    }),
    handleSubmit: () => { },

})(ProdutosForm);

export default ProdutosForm
