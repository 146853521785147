import { format, parse } from "date-fns"

export function converterNegociacaoParaFormulario(negociacao) {
    return {
        ...negociacao,
        ativo: converterAtivoParaFormulario(negociacao.ativo),
        conta: converterContaParaFormulario(negociacao.conta)
    }
}

function converterAtivoParaFormulario(ativo) {
    if (ativo) {
        return {
            label: ativo.nome,
            value: ativo.id
        }
    }
    return null
}

function converterContaParaFormulario(conta) {
    if (conta) {
        return {
            label: `${conta.corretora.nome} (${conta.codigo})`,
            value: conta.id
        }
    }
    return null
}


export function converterNegociacaoParaApi(negociacao) {
    return {
        ...negociacao,
        conta: {
            id: negociacao.conta.value,
        },
        ativo: {
            id: negociacao.ativo.value,
        }
    }
}

export function converterNegociacoesParaListagem(negociacaos) {
    return negociacaos.map((negociacao) => {
        return {
            ...negociacao,
            data: format(parse(negociacao.data, 'yyyy-MM-dd hh:mm', new Date()), 'dd/MM/yyyy')
        }
    })
}
