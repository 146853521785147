import { montarLabelPessoa } from "../../../../../../components/Select/SingleSelectPessoa/utils"

export function converterLancamentoDespesaParaFormulario(values) {
    return {
        ...values,
        categoria: converterCategoriaParaFormulario(values.categoria),
        conta: converterContaParaFormulario(values.conta),
        pessoa: converterPessoaParaFormulario(values.pessoa),
    }
}

function converterCategoriaParaFormulario(categoriaApi) {
    if (categoriaApi) {
        return {
            label: categoriaApi.nome,
            value: categoriaApi.id,
            registro: categoriaApi
        }
    }
    return null
}

function converterContaParaFormulario(contaApi) {
    if (contaApi) {
        return {
            label: contaApi.nome,
            value: contaApi.id,
            registro: contaApi
        }
    }
    return null
}

function converterPessoaParaFormulario(pessoaApi) {
    if (pessoaApi) {
        return {
            label: montarLabelPessoa(pessoaApi),
            value: pessoaApi.id,
            registro: pessoaApi
        }
    }
    return null
}

export function converterLancamentoDespesaParaApi(values) {
    return {
        ...values,
        categoria: values.categoria ? { id: values.categoria.value } : null,
        conta: values.conta ? { id: values.conta.value } : null,
        pessoa: values.pessoa ? { id: values.pessoa.value } : null,
    }
}