import React from 'react';
import { Field } from 'formik';
import Grid from '../../../../../components/Grid';
import InputField from '../../../../../components/Input/InputField';
import InputMask from '../../../../../components/Input/InputMask';
import FormGroup from '../../../../../components/FormGoup';
import { helpFieldText } from './help';
import MsysCheckbox from '../../../../../components/Input/Checkbox';

export function renderizarFieldsPessoaJuridica(values, setFieldValue, informacoesPermissoes) {

    return (
        <FormGroup>
            <Grid>
                <Field sm="12" md="4" lg='4' xl='3'
                    component={InputMask}
                    mask="00.000.000/0000-00"
                    placeholder="  .   .   /    -  "
                    label='CNPJ'
                    name="cnpj"
                    onChange={e => setFieldValue('cnpj', e.target.value)}
                    value={values.cnpj}
                    helpMessage={helpFieldText.cnpj}
                    {...informacoesPermissoes}
                />
                <Field sm="12" md="4" lg='4' xl='3'
                    component={InputField}
                    type="text"
                    helpMessage={helpFieldText.razaoSocial}
                    label='Razão social'
                    size={255}
                    name="razaoSocial"
                    value={values.razaoSocial}
                    {...informacoesPermissoes}
                />
                 <Field sm="12" md="4" lg='4' xl='3'
                    component={InputField}
                    size={9}
                    type="text"
                    label='Inscrição municipal'
                    helpMessage={helpFieldText.inscricaoMunicipal}
                    name="inscricaoMunicipal"
                    value={values.inscricaoMunicipal}
                    {...informacoesPermissoes}
                />
                 <Field sm="12" md="4" lg='4' xl='3'
                    component={InputField}
                    size={9}
                    type="number"
                    label='Inscrição suframa'
                    helpMessage={helpFieldText.inscricaoSuframa}
                    name="inscricaoSuframa"
                    value={values.inscricaoSuframa}
                    {...informacoesPermissoes}
                />
                <Field sm="12" md="12" lg='6' xl='6'
                    component={MsysCheckbox}
                    label={"Optante do Simples Nacional"}
                    name="simplesNacional"
                    helpMessage={helpFieldText.simplesNacional}
                    onChange={(e) => setFieldValue('simplesNacional', e.checked)}
                    checked={values.simplesNacional}
                    {...informacoesPermissoes}
                />
            </Grid>
        </FormGroup>
    )
}