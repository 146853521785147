import { Field } from 'formik';
import React from 'react';
import InputDouble from '../../../../../../../../components/Input/InputDouble';
import InputMoneyGroup from '../../../../../../../../components/Input/InputMoneyGroup';
import Dropdown from '../../../../../../../../components/Select/Dropdown';
import { modalidadeICMSST, optionsAliquotasFCP, optionsModalidadeDeterminacaoST } from '../../../../../../utils/constantes';
import { helpNFeProd } from '../help';

export default function CalculoICMSST(props) {
    const { informacoesPermissoes, values, onChangeImpostoIcms, dest } = props;

    function onChangeModalidadeST(value) {
        if (value !== modalidadeICMSST.MARGEM_VALOR_AGREGADO) {
            onChangeImpostoIcms('pMVAST', 0);
            onChangeImpostoIcms('modBCST', value);
        } else {
            onChangeImpostoIcms('modBCST', value);
        }
    }

    return (
        <>
            <Field
                sm="12" md="4" lg='4' xl='4'
                label='Alíquota Interna da UF Destino'
                name="pICMSST"
                value={values.imposto.icms.pICMSST}
                onChange={e => onChangeImpostoIcms('pICMSST', e.target.value)}
                size={8}
                component={InputMoneyGroup}
                decimalScale={2}
                prefix=''
                suffix='%'
                maxValue={100}
                allowNegative={false}
                placeholder='0,00 %'
                iconButton="fa fa-flash"
                titleButton={false ? "Você não pode buscar a alíquota do estado de destino" : "Buscar alíquota do estado de destino"}
                colorIconButton="success"
                disabledButton={false}
                onClickButton={() => onChangeImpostoIcms('pICMSST', dest.enderDest.estAliqInt)}
                helpMessage={helpNFeProd.pICMSST}
                {...informacoesPermissoes}
            />
            <Field sm="12" md="4" lg='4' xl='4'
                component={Dropdown}
                label='Perc. FCP retido por ST'
                name="pFCPST"
                onChange={e => onChangeImpostoIcms('pFCPST', e.value)}
                options={optionsAliquotasFCP}
                value={values.imposto.icms.pFCPST}
                showClear={false}
                helpMessage={helpNFeProd.pFCPST}
                {...informacoesPermissoes}
            />
            <Field sm="12" md="4" lg='4' xl='4'
                component={Dropdown}
                label='Modalidade do ICMS-ST'
                name="modBCST"
                onChange={e => onChangeModalidadeST(e.value)}
                options={optionsModalidadeDeterminacaoST}
                value={values.imposto.icms.modBCST}
                showClear={false}
                helpMessage={helpNFeProd.modBCST}
                {...informacoesPermissoes}
            />
            <Field sm="12" md="4" lg='4' xl='4'
                label='Percentual da MVA'
                name="pMVAST"
                value={values.imposto.icms.pMVAST}
                onChange={e => onChangeImpostoIcms('pMVAST', e.target.value)}
                size={8}
                component={InputDouble}
                decimalScale={2}
                allowNegative={false}
                helpMessage={helpNFeProd.pMVAST}
                suffix=' %'
                maxValue={100}
                hidden={values.imposto.icms.modBCST !== modalidadeICMSST.MARGEM_VALOR_AGREGADO}
                {...informacoesPermissoes}
            />
            <Field sm="12" md="4" lg='4' xl='4'
                label='Perc. da red. de BC do ICMS ST'
                name="pRedBCST"
                value={values.imposto.icms.pRedBCST}
                onChange={e => onChangeImpostoIcms('pRedBCST', e.target.value)}
                size={8}
                component={InputDouble}
                decimalScale={2}
                allowNegative={false}
                helpMessage={helpNFeProd.pRedBCST}
                suffix=' %'
                maxValue={100}
                {...informacoesPermissoes}
            />
            <Field sm="12" md="4" lg='4' xl='4'
                label='Percentual de redução do MVA'
                name="pMVASTReducao"
                value={values.imposto.icms.pMVASTReducao}
                onChange={e => onChangeImpostoIcms('pMVASTReducao', e.target.value)}
                size={8}
                component={InputDouble}
                decimalScale={2}
                allowNegative={false}
                helpMessage={helpNFeProd.pMVASTReducao}
                suffix=' %'
                maxValue={100}
                hidden={values.imposto.icms.modBCST !== modalidadeICMSST.MARGEM_VALOR_AGREGADO}
                {...informacoesPermissoes}
            />
        </>
    )
}