export const sizeSmall = {
    minHeight: '22px',
    minWidth: '22px',
    fontSize: '0.8125rem',
};

export const sizeMedium = {
    minHeight: '24px',
    minWidth: '24px',
    padding: '1px 2px',
    fontSize: '0.875rem',
};

export const sizeLarge = {
    minHeight: '26px',
    minWidth: '26px',
    padding: '3px 4px',
    fontSize: '0.9375rem',
};

export const iconButton = {
    height: '25px',
    width: '25px',
};
