import { montarLabelPessoa } from "../../../../components/Select/SingleSelectPessoa/utils"

export function converterDocumentoParaFormulario(values) {
    return {
        id: values.id,
        identificacao: values.identificacao,
        status: values.status,
        pessoa: converterPessoaParaFormulario(values.pessoa),
        hash256: values.hash256,
        arquivado: values.arquivado,
        expiracaoAssinaturas: values.expiracaoAssinaturas,
        usuario: converterUsuarioParaFormulario(values.responsavel ? values.responsavel.usuario : null),
        setor: converterSetorParaFormulario(values.responsavel ? values.responsavel.setor : null),
        confirmacoes: converterConfirmacoesParaFormulario(values.confirmacoes),
        signatarios: converterSignatariosParaFormulario(values.signatarios),
        arquivo: converterArquivoParaFormulario(values.arquivo),
        observacao: values.observacao
    }
}

export function converterDocumentoParaApi(values) {
    return {
        id: values.id,
        identificacao: values.identificacao,
        pessoa: values.pessoa ? { id: values.pessoa.value } : null,
        arquivado: values.arquivado,
        status: values.status,
        signatarios: converterSignatariosParaAPI(values.signatarios),
        confirmacoes: converterConfirmacoesParaAPI(values.confirmacoes),
        expiracaoAssinaturas: values.expiracaoAssinaturas,
        responsavel: {
            usuario: { id: values.usuario ? values.usuario.value : null },
            setor: { id: values.setor ? values.setor.value : null }
        },
        observacao: values.observacao,
        notificarAssinatura: false
    }
}

export function converterUsuarioParaFormulario(usuario) {
    if (usuario) {
        return {
            label: usuario.nome,
            value: usuario.id
        }
    }
    return null
}

export function converterSetorParaFormulario(setor) {
    if (setor) {
        return {
            label: setor.nome,
            value: setor.id
        }
    }
    return null
}

function converterPessoaParaFormulario(pessoaApi) {
    if (pessoaApi) {
        return {
            label: montarLabelPessoa(pessoaApi),
            value: pessoaApi.id
        }
    }
    return null
}

function converterSignatariosParaFormulario(signatarios) {
    return signatarios.map((signatario) => {
        return {
            id: signatario.id,
            pessoa: signatario.pessoa ? { label: signatario.pessoa.nome, value: signatario.pessoa.id } : null,
            email: signatario.email,
            nome: signatario.nome,
            papel: signatario.papel,
            assinatura: signatario.assinatura,
            confirmacoes: signatario.confirmacoes
        }
    })
}


function converterConfirmacoesParaFormulario(confirmacoes) {
    return confirmacoes.map((confirmacao) => {
        return {
            id: confirmacao.id,
            pergunta: confirmacao.pergunta,
            respostaTipo: confirmacao.respostaTipo,
            respostaEsperada: confirmacao.respostaEsperada,
        }
    })
}

function converterArquivoParaFormulario(arquivo){
    if(arquivo){
        return {
            name: arquivo.nome,
            size: arquivo.tamanho,
            type: arquivo.tipo
        }
    }
    return null
}

function converterSignatariosParaAPI(signatarios) {
    return signatarios.map((signatario) => {
        return {
            id: signatario.id,
            pessoa: signatario.pessoa ? {
                id: signatario.pessoa.value
            } : null,
            email: signatario.email,
            nome: signatario.pessoa && signatario.pessoa.registro ? signatario.pessoa.registro.nome : signatario.nome,
            papel: signatario.papel,
            confirmacoes: converterConfirmacoesParaAPI(signatario.confirmacoes),
        }
    })
}

function converterConfirmacoesParaAPI(confirmacoes) {
    if(!confirmacoes) {
        return []
    }
    return confirmacoes.map((confirmacao) => {
        return {
            id: confirmacao.id,
            pergunta: confirmacao.pergunta,
            respostaTipo: confirmacao.respostaTipo,
            respostaEsperada: confirmacao.respostaEsperada,
        }
    })
}
