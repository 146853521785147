import { Toolbar } from 'primereact/components/toolbar/Toolbar';
import { Menu } from 'primereact/components/menu/Menu';
import { Button } from 'primereact/components/button/Button';
import React, { Component } from 'react';
import propTypes from 'prop-types'
import autoBind from 'react-autobind'
import Grid from '../Grid';

class MenuSuperior extends Component {

    constructor(props) {
        super(props)

        autoBind(this);

        this.state = {
            displayWidth: window.innerWidth
        }
    }

    componentDidMount() {
        if (this.props.items) {
            window.addEventListener('resize', this.updateWindowDimensions);
        }
    }

    updateWindowDimensions() {
        if (this.props.items) {
            if (((this.state.displayWidth < 750) && (window.innerWidth > 750)) ||
                ((this.state.displayWidth > 750) && (window.innerWidth < 750))) {
                this.setState({ ...this.state, displayWidth: window.innerWidth })
            }
        }
    }

    componentWillUnmount() {
        if (this.props.items) {
            window.removeEventListener('resize', this.updateWindowDimensions);
        }
    }

    renderButtons() {
        if (this.state.displayWidth < 750 && this.props.items) {
            return (
                <React.Fragment>
                    <Button type='button' label="Opções" icon="fa fa-list" onClick={(event) => this.menu.toggle(event)} />
                </React.Fragment>
            )
        } else {
            return this.props.children
        }
    }

    render() {

        const divStyle = {
            zIndex: '1',
            display: 'block',
            paddingTop: '0px',
            position: 'fixed',
            top: '70',
            width: '100%'
        }

        const modalDivStyle = {
            paddingBottom: '5px'
        }

        const { hidden, isModal, children, id, items } = this.props

        if (hidden) {
            return null;
        }

        if (isModal) {
            return (
                <div style={modalDivStyle}>
                    {children}
                </div>
            )
        }

        return (
            <React.Fragment>
                <Menu
                    id={id}
                    model={items}
                    popup={true}
                    ref={el => this.menu = el}
                />
                <Grid style={divStyle}>
                    <Toolbar
                        className='p-toolbar color-template'
                        style={{ border: '0px', paddingTop: '5px', paddingBottom: '0px' }}
                    >
                        {this.renderButtons()}
                    </Toolbar>
                </Grid>
            </React.Fragment>
        );

    }
}

MenuSuperior.defaultProps = {
    hidden: false
}

MenuSuperior.propTypes = {
    /** Identificador do componente */
    id: propTypes.string,
    /** Itens do menu quando o mesmo estiver no tamanho pequeno, seguindo a documentação do componente Menu do PrimeReact */
    items: propTypes.array,
    /** Define se o botão está escondido */
    hidden: propTypes.bool,
    /** Define se o componente é modal, aí o estilo muda */
    isModal: propTypes.bool,
}

export default MenuSuperior;
