import React, { useState, useEffect } from "react";
import './styles/index.css'


function Manutencao(props) {
	const [css, setCss] = useState('')

	useEffect(() => {
		document.addEventListener('mousemove', (event) => {
			if (event.pageY % 2 === 0 || event.pageX % 2 === 0) {
				let pageX = window.innerWidth;
				let pageY = window.innerHeight;
				let mouseY = 0;
				let mouseX = 0;
				//verticalAxis
				mouseY = event.pageY;
				let yAxis = ((pageY / 2 - mouseY) / pageY) * 300;
				//horizontalAxis
				mouseX = event.pageX / -pageX;
				let xAxis = -mouseX * 100 - 100;

				setCss("translate(" + xAxis + "%,-" + yAxis + "%)")
			}
		})
	}, [])

	return (
		<div className="manutencao" id="telaManutencao">
			<div className="boxe">
				<div className="box__ghost">
					<div className="symbol"></div>
					<div className="symbol"></div>
					<div className="symbol"></div>
					<div className="symbol"></div>
					<div className="symbol"></div>
					<div className="symbol"></div>

					<div className="box__ghost-container">
						<div className="box__ghost-eyes" style={{ transform: css }}>
							<div className="box__eye-left"></div>
							<div className="box__eye-right"></div>
						</div>
						<div className="box__ghost-bottom">
							<div></div>
							<div></div>
							<div></div>
							<div></div>
							<div></div>
						</div>
					</div>
					<div className="box__ghost-shadow"></div>
				</div>

				<div className="box__description">
					<div className="box__description-container">
						<div className="box__description-title">{props.mensagem}</div>
						<div className="box__description-text">{props.mensagem2}</div>
					</div>
				</div>
			</div>
		</div>
	);
}

Manutencao.defaultProps = {
	mensagem: 'Ops, estamos em manutenção!',
	mensagem2: 'Pegue uma xícara de café que em breve estaremos de volta!'
}

export default Manutencao