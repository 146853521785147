import React, { Component } from 'react';
import ButtonCancelar, { estadosBotaoCancelar } from "../../../../../components/Button/ButtonCancelar";
import MenuSuperior from "../../../../../components/MenuSuperior";
import LayoutMenuSuperior from "../../../../../components/Layout/LayoutMenuSuperior";
import FormGroup from "../../../../../components/FormGoup";
import Paper from "../../../../../components/Paper";
import autoBind from 'react-autobind';
import { Field, withFormik } from 'formik';
import { asyncCompartilharDocumento, asyncCompartilharDocumentoUnicoSignatario } from './requests';
import NormalButton from '../../../../../components/Button';
import InputField from '../../../../../components/Input/InputField';
import Grid from '../../../../../components/Grid';
import { salvarUltimoEmail, buscarUltimoEmail, salvarNotificacaoPorEmail, buscarNotificacaoPorEmail } from './utils';
import { validarFormulario } from '../../../../util';
import { mensagensDeValidacao } from '../../../../../common/constantes/mensagens';
import * as Yup from "yup";
import Modal from '../../../../../components/Modal';
import Dropdown from '../../../../../components/Select/Dropdown';
import TextArea from '../../../../../components/TextArea';
import Checkbox from '../../../../../components/Input/Checkbox';
import { buscarDadosLoginLocalStorage } from '../../../../../common/autenticacao';
import { initialValue } from '../../utils/constantes';
import If from '../../../../../components/If';

const initialValues = {
    email: [],
    titulo: "",
    texto: "",
    enviarCopia: false,
    destinatariosCopia: null,
    notificarAssinatura: false
}

class ModalEnviarPorEmail extends Component {

    constructor(props) {
        super(props);

        autoBind(this);
    }

    componentDidMount() {
        this.asyncSelectRegistro()
    }

    async compartilharDocumento() {

        this.props.handleSubmit();

        if (await validarFormulario(this.props)) {
            if (this.props.signatarioEspecifico) {
                this.compartilharComSignatarioEspecifico();
            } else {
                this.compartilharComTodosOsSignatarios();
            }
        }
    }

    async compartilharComSignatarioEspecifico() {
        const { values, onDocumentoEnviado, onHide, idDocumento } = this.props;

        await asyncCompartilharDocumentoUnicoSignatario(idDocumento, this.props.signatarioEspecifico.id, values, () => {
            onDocumentoEnviado && onDocumentoEnviado();
            salvarUltimoEmail(values)
            salvarNotificacaoPorEmail(values.notificarAssinatura)
            onHide();
        });
    }

    async compartilharComTodosOsSignatarios() {
        const { values, onDocumentoEnviado, onHide, idDocumento } = this.props;
        await asyncCompartilharDocumento(idDocumento, values, () => {
            onDocumentoEnviado && onDocumentoEnviado();
            salvarUltimoEmail(values)
            salvarNotificacaoPorEmail(values.notificarAssinatura)
            onHide();
        });
    }

    cancelar() {
        if (this.props.dirty) {
            this.props.handleReset();
        } else {
            this.props.onHide();
        }
    }

    async asyncSelectRegistro() {
        let ultimoEmail = await buscarUltimoEmail();
        let notificarAssinatura = await buscarNotificacaoPorEmail() || false;
        const dadosLocalStorage = buscarDadosLoginLocalStorage()

        if (!ultimoEmail) {
            ultimoEmail = {
                titulo: "Novo documento",
                texto: "Olá, segue novo documento para assinatura eletrônica. Qualquer dúvida favor entrar em contato."
            }
        }

        this.props.resetForm({
            values: {
                ...initialValues,
                ...ultimoEmail,
                notificarAssinatura,
                destinatariosCopia: dadosLocalStorage.email,
                email: (this.props.signatarioEspecifico && this.props.signatarioEspecifico.email) ? [this.props.signatarioEspecifico.email] : this.props.emailsSignatarios
            }
        })
    }

    render() {

        const { dirty, values, setFieldValue, visible, onHide, arquivo } = this.props;
        return (
            <React.Fragment>
                <Modal
                    visible={visible}
                    header="Enviar documento por e-mail"
                    onHide={onHide}
                >
                    <MenuSuperior isModal={true}>
                        <NormalButton
                            label="Enviar e-mail"
                            icon="fa fa-paper-plane"
                            disabled={!this.props.errors}
                            onClick={() => this.compartilharDocumento()}
                        />
                        <ButtonCancelar
                            estadoBotao={dirty ? estadosBotaoCancelar.CANCELAR : estadosBotaoCancelar.VOLTAR}
                            onClick={this.cancelar}
                        />
                    </MenuSuperior>
                    <LayoutMenuSuperior isModal={true}>
                        <Paper dirty={dirty} childsOnly={true}>
                            <FormGroup>
                                <Grid>
                                    <Field sm="12" md="12" lg="12" xl="12"
                                        component={Dropdown}
                                        isMulti
                                        options={values.email && values.email.map(email => { return { label: email, value: email } })}
                                        label="Destinatário(s)"
                                        obrigatorio
                                        name="email"
                                        value={values.email}
                                        disabled
                                    />
                                    <Field
                                        sm="12" md="12" lg="12" xl="12"
                                        component={InputField}
                                        name="titulo"
                                        helpMessage="Título do e-mail enviado ao destinatário"
                                        label="Título do e-mail"
                                        obrigatorio
                                        height={255}
                                        onChange={e => setFieldValue("titulo", e.target.value)}
                                        value={values.titulo}
                                    />
                                    <Field
                                        sm="12" md="12" lg="12" xl="12"
                                        label="Texto do e-mail"
                                        component={TextArea}
                                        obrigatorio
                                        helpMessage="Texto presente no e-mail enviado ao destinatário"
                                        name="texto"
                                        value={values.texto}
                                        onChange={e => setFieldValue('texto', e.target.value)}
                                    />
                                    <If test={this.props.modalReenviarEmail === false}>
                                        <Field sm="12" md="12" lg='12' xl='12'
                                            component={Checkbox}
                                            label="Desejo ser notificado por e-mail assim que o documento for assinado ou rejeitado"
                                            helpMessage='Você pode ser notificado por e-mail quando o documento for assinado ou rejeitado.'
                                            name="notificarAssinatura"
                                            onChange={() => this.props.setFieldValue('notificarAssinatura', !values.notificarAssinatura)}
                                            checked={values.notificarAssinatura}
                                        />
                                        <Field sm="12" md="12" lg='12' xl='12'
                                            component={Checkbox}
                                            label="Desejo enviar uma cópia deste documento para outros destinatários"
                                            helpMessage='Você pode enviar uma cópia deste documento para você e outros destinatários.'
                                            name="enviarCopia"
                                            onChange={() => this.props.setFieldValue('enviarCopia', !values.enviarCopia)}
                                            checked={values.enviarCopia}
                                            //não permite enviar copia de arquivos de mais de 8 MB
                                            disabled={arquivo && arquivo.size > 8388608}
                                            title={arquivo && arquivo.size > 8388608 ? "Não é possível enviar uma cópia com um arquivo com tamanho superior a 8 MB no e-mail." +
                                                " Para obter o arquivo, você pode clicar em 'Opções' e 'Visualizar arquivo original'" : ""}
                                        />
                                        <Field sm="12" md="12" lg="12" xl="12"
                                            component={InputField}
                                            label='Enviar cópia para'
                                            hidden={!values.enviarCopia}
                                            name="destinatariosCopia"
                                            helpMessage='E-mails que irão receber a cópia. Podem ser informados vários e-mails separados por vírgula'
                                            obrigatorio
                                            size={255}
                                        />
                                    </If>
                                </Grid>
                            </FormGroup>
                        </Paper>
                    </LayoutMenuSuperior>
                </Modal>
            </React.Fragment >
        )
    }
}

ModalEnviarPorEmail = withFormik({
    enableReinitialize: true,
    validateOnChange: false,

    mapPropsToValues() {
        return initialValue;
    },

    async validate(values) {
        const errors = {}

        if (values.destinatariosCopia) {
            let destinatariosCopia = values.destinatariosCopia.split(",").map(item => item.trim())
            let schemaEmail = Yup.string().email()

            for (const item of destinatariosCopia) {
                if (item === "") {
                    errors.destinatariosCopia = `O e-mail "${item}" não é um e-mail válido`
                    break
                } else if (!await schemaEmail.isValid(item)) {
                    errors.destinatariosCopia = `O e-mail "${item}" não é um e-mail válido`
                    break
                }
            }
        }

        return errors
    },

    validationSchema: Yup.object().shape({
        titulo: Yup.string().nullable().required(mensagensDeValidacao.OBRIGATORIO),
        texto: Yup.string().nullable().required(mensagensDeValidacao.OBRIGATORIO),
    }),

})(ModalEnviarPorEmail);

export default ModalEnviarPorEmail;
