import React, { Component } from "react";
import Select from "react-select";
import autoBind from "react-autobind";
import propTypes from "prop-types";
import Col from "../../Col";
import If from "../../If";
import Button from "../../Button";
import { renderizarValidacao } from "../../../common/tratamentoDeErro/validacoesDeCampos";
import { asyncGetOpcoesSelect } from './requests'
import { buscarOption, converterRegistrosParaOptions, filtrarOpcoesRepetidas } from "./utils/functions";
import { buscarDisabledDeAcordoComAsPermissoes, buscarHiddenDeAcordoComAsPermissoes } from "../../../common/autorizacao/manipulacaoDeComponentes";
import { removerCaracteresInvalidosRsql } from "../../../common/rsql";
import { usuarioPossuiModulos } from "../../../common/autenticacao";
import MenuList from '../components/FastList'
import { connect } from "react-redux";

const customStyles = {
    control: (provided, state) => ({
        ...provided,
        height: 33,
        border:
            state.selectProps.errors && state.selectProps.touched
                ? "1px solid #ff0000 !important"
                : "1px solid #a6a6a6 !important",
        backgroundColor: "#fff",
        opacity: state.isDisabled ? "0.5" : "1",
        ...dot(state.selectProps.errors && state.selectProps.touched)
    }),
    menu: style => ({
        ...style,
        marginTop: 0,
        marginBottom: 0
    }),
    option: (provided, state) => {
        const customStyle = state.selectProps.customOptionStyle ? state.selectProps.customOptionStyle : undefined;
        return {
            ...provided,
            ...customStyle,
            whiteSpace: 'nowrap',
            minWidth: 'max-content'
        };
    },
};

const dot = errors => ({
    ":hover": {
        border: errors
            ? "1px solid #ff0000 !important"
            : "1px solid black !important",
        cursor: "pointer"
    }
});

const buttonStyle = {
    width: "23px",
    height: "23px",
    borderRadius: "3px",
    color: "#006095",
    backgroundColor: "transparent",
    borderColor: "transparent",
    fontSize: "15px",
    boxShadow: "none",
    margin: "unset",
    border: "unset",
};

class SingleSelect extends Component {
    render() {
        const { field, form, ...rest } = this.props;

        /** A tela da venda usa o select de produto sem o FORMIK, por isso preciso verificar se a propriedade form existe */
        return (
            <ReactSelect
                {...field}
                {...rest}
                dirty={form ? form.dirty : this.props.dirty}
                errors={form ? form.errors[field.name] : this.props.errors}
                touched={form ? Boolean(form.touched[field.name]) : this.props.touched}
                onBlur={() => {
                    form?.setFieldTouched && form.setFieldTouched(field.name)
                    rest?.onBlur && rest.onBlur()
                }}
                value={this.props.value}
            />
        );
    }
}

class ReactSelect extends Component {
    constructor(props) {
        super(props);

        autoBind(this);

        this.state = {
            inputValue: "",
            options: [],
            loading: false,
            validouModulo: true,
            timeOutPesquisa: null,
            pageSelecionada: 0,
            pageSelecionadaPesquisaEspecifica: 0,
            totalPages: null,
            totalPagesPesquisaEspecifica: null,
            buscouTodosOsRegistros: false
        };
    }

    componentDidMount() {
        const { value, modulosEspecificos, loadInitialValues } = this.props
        const { pageSelecionada } = this.state
        const validouModulo = usuarioPossuiModulos(modulosEspecificos)

        this.setState({ validouModulo })
        if (value) {
            this.atribuirNovaOpcao(value)
        }

        if (validouModulo && loadInitialValues) {
            this.buscarRegistros("", pageSelecionada)
        }
    }

    componentDidUpdate(prevProps) {
        const { resetarPesquisa, value } = this.props
        if (prevProps.resetarPesquisa !== resetarPesquisa) {
            this.resetarPesquisa()
        }
        if (value && prevProps.value !== value) {
            this.atribuirNovaOpcao(value)
        }
    }

    resetarPesquisa() {
        const { montarLabel, buscarUrlPesquisa } = this.props
        asyncGetOpcoesSelect(buscarUrlPesquisa("", 0), ({ data: registros }) => {
            this.atualizarOptions(converterRegistrosParaOptions(registros.content, montarLabel));
            this.setState({
                pageSelecionada: 0,
                pageSelecionadaPesquisaEspecifica: 0
            })
        })
    }

    atribuirNovaOpcao(novaOpcao) {
        const options = this.buscarOptions();
        if (!buscarOption(options, novaOpcao)) {
            this.atualizarOptions([...options, novaOpcao])
        }
    }

    buscarRegistros(inputValue, pageSelecionada) {
        const { montarLabel, buscarUrlPesquisa } = this.props
        this.setState({ loading: true })
        asyncGetOpcoesSelect(buscarUrlPesquisa(removerCaracteresInvalidosRsql(inputValue), pageSelecionada), ({ data: registros }) => {
            const options = this.buscarOptions();
            const novasOptions = converterRegistrosParaOptions(registros.content, montarLabel)
            if (inputValue) {
                this.atualizarOptions(filtrarOpcoesRepetidas(options, novasOptions))
                this.setState({
                    loading: false,
                    pageSelecionadaPesquisaEspecifica: pageSelecionada,
                    totalPagesPesquisaEspecifica: registros.totalPages
                })
            } else {
                this.atualizarOptions(filtrarOpcoesRepetidas(options, novasOptions))
                this.setState({
                    loading: false,
                    pageSelecionada: pageSelecionada,
                    totalPages: registros.totalPages,
                    buscouTodosOsRegistros: registros.totalPages === pageSelecionada + 1
                })
            }
        }, () => {
            this.setState({ loading: false })
        })
    }

    onInputChange(inputValue) {
        const { timeOutPesquisa, buscouTodosOsRegistros } = this.state
        if (inputValue && !buscouTodosOsRegistros) {
            clearTimeout(timeOutPesquisa)
            this.setState({
                ...this.state,
                inputValue,
                timeOutPesquisa: setTimeout(() => {
                    this.buscarRegistros(inputValue, 0)
                }, 500)
            })
        } else {
            this.setState({ inputValue })
        }
    }

    onScrollToBottom() {
        const { pageSelecionada, pageSelecionadaPesquisaEspecifica, totalPages, totalPagesPesquisaEspecifica, inputValue, buscouTodosOsRegistros } = this.state
        if (!buscouTodosOsRegistros) {
            const page = pageSelecionada + 1
            const pageEspecifica = pageSelecionadaPesquisaEspecifica + 1
            if (inputValue) {
                if (totalPagesPesquisaEspecifica && pageEspecifica < totalPagesPesquisaEspecifica) {
                    this.buscarRegistros(inputValue, pageEspecifica)
                }
            } else {
                if (totalPages && page < totalPages) {
                    this.buscarRegistros(inputValue, page)
                }
            }

        }
    }

    filterOption(e) {
        return (e.label || "").toLowerCase().includes(this.state.inputValue.toLowerCase());
    }

    getColStyle() {
        const { podeVisualizar, hidden, colStyle } = this.props
        if (buscarHiddenDeAcordoComAsPermissoes(podeVisualizar, hidden))
            return { display: 'none', ...colStyle }
        return colStyle
    }

    montarTitulo() {
        const { obrigatorio, label, helpMessage, labelSize} = this.props;
        if (obrigatorio) {
            return <label style={{fontSize: labelSize}} title={helpMessage}> {label} <b style={{ fontSize: labelSize ? labelSize : '18px', lineHeight: '5px' }} > *</b> </label>
        }
        return <label style={{fontSize: labelSize}} title={helpMessage}> {label} </label>
    }

    atualizarOptions(novasOptions) {
        if (this.props.options) {
            this.props.onChangeOptions(novasOptions)
        } else {
            this.setState({ options: novasOptions })
        }
    }

    buscarOptions() {
        if (this.props.options) {
            return this.props.options;
        } else {
            return this.state.options;
        }
    }

    render() {
        if (!this.state.validouModulo) return null;
        const { sm, md, lg, xl, isClearable, onClickModal, esconderBotao, errors, touched, botaoColor, botaoIcon,
            podeInserir, podeEditar, estadoCadastro, disabled, placeholder, disabledButton, titleBotaoNovo, value, onChange, isSearchable,
            isMobile, formatOptionLabel, components, customOptionStyle } = this.props;
        const { inputValue, loading, totalPages } = this.state;
        const options = this.buscarOptions();

        const desabilitarSelect = buscarDisabledDeAcordoComAsPermissoes(podeInserir, podeEditar, estadoCadastro, disabled)
        const desabilitarBotao = disabledButton || desabilitarSelect
        const searchableSelect = isSearchable !== undefined ? isSearchable : totalPages > 1 || !isMobile
        const placeholderSelect = !searchableSelect && placeholder === "Digite para pesquisar" ? "" : placeholder
        return (
            <Col sm={sm} md={md} lg={lg} xl={xl} style={this.getColStyle()}>
                {this.montarTitulo()}
                <div className="p-inputgroup">
                    <Select
                        isLoading={loading}
                        inputValue={inputValue}
                        isClearable={isClearable}
                        options={options}
                        closeMenuOnSelect={true}
                        onInputChange={this.onInputChange}
                        isDisabled={desabilitarSelect}
                        {...this.props}
                        placeholder={placeholderSelect}
                        styles={{ ...customStyles, ...this.props.styles }}
                        value={value}
                        onChange={onChange}
                        customOptionStyle={customOptionStyle}
                        onMenuScrollToBottom={this.onScrollToBottom}
                        components={options.length > 0 ? { MenuList, ...components } : { ...components }}
                        formatOptionLabel={formatOptionLabel}
                        filterOption={this.filterOption}
                        isSearchable={searchableSelect}
                    />
                    <If test={!esconderBotao}>
                        <Button
                            icon={botaoIcon}
                            title={titleBotaoNovo ? titleBotaoNovo : "Inserir um novo registro"}
                            style={buttonStyle}
                            styleContentButton={{ display: "flex", alignItems: "center" }}
                            disabled={desabilitarBotao}
                            color={botaoColor}
                            onClick={onClickModal}
                            tabIndex={-1}
                        />
                    </If>
                </div>
                {renderizarValidacao(errors, touched)}
            </Col>
        );
    }
}

ReactSelect.defaultProps = {
    noOptionsMessage: () => "Nenhum elemento encontrado",
    loadingMessage: () => "Por favor, aguarde...",
    menuPlacement: "auto",
    className: "react-select-base",
    classNamePrefix: "reactSelect",
    podeVisualizar: true,
    botaoColor: "primary",
    botaoIcon: "fa fa-plus",
    isClearable: true,
    placeholder: "Digite para pesquisar",
    loadInitialValues: true
};

ReactSelect.propTypes = {
    /** Evento disparado ao modificar o valor do componente */
    onChange: propTypes.func,
    /** Passa as options personalizadas para o componente caso necessário. */
    options: propTypes.array,
    /** Funcao para atualizar as options passadas por parâmetro.*/
    onChangeOptions: propTypes.func,
    /** Label do componente */
    label: propTypes.string,
    /** Placeholder do componente */
    placeholder: propTypes.string,
    /** Valor do componente do componente (UUID)*/
    value: propTypes.any,
    /** Nome da classe do componente */
    className: propTypes.string,
    /** Prefixo do nome da classe do componente e das classes filhas */
    classNamePrefix: propTypes.string,
    /** Define se o componente está desabilitado*/
    disabled: propTypes.bool,
    /** Define se o botão do componente está desabilitado*/
    disabledButton: propTypes.bool,
    /** Tamanho padrão da coluna utilizado em dispositivos muito pequenos (0 a 12) */
    col: propTypes.string,
    /** Tamanho do campo em small devices*/
    sm: propTypes.string,
    /** Tamanho do campo em medium devices*/
    md: propTypes.string,
    /** Tamanho do campo em large devices*/
    lg: propTypes.string,
    /** Tamanho do campo em extra large devices*/
    xl: propTypes.string,
    /** Estilo da coluna*/
    colStyle: propTypes.object,
    /** Diz se o usuário possui permissão de visualizar*/
    podeVisualizar: propTypes.bool,
    /** Diz se o usuário possui permissão de editar*/
    podeEditar: propTypes.bool,
    /** Diz se o usuário possui permissão de excluir*/
    podeInserir: propTypes.bool,
    /** Esconde o componente*/
    hidden: propTypes.bool,
    /** Resetar os options do componente ao inserir novo registro*/
    resetarPesquisa: propTypes.bool,
    /** Campo destinado a uma breve  explicação sobre o campo. Irá renderizar um ícone de pergunta caso a propriedade for alimentada.*/
    helpMessage: propTypes.string,
    /** Função que recebe o parâmetro de pesquisa e deve retornar a URL da API que retornará todos os registros */
    buscarUrlPesquisa: propTypes.any.isRequired,
    /** Função que recebe o registro e deve retornar o label do select */
    montarLabel: propTypes.func.isRequired,
    /** Deve exibir o botão de limpar o select */
    isClearable: propTypes.bool,
    /** OnClick do botão de abrir o modal de inserir registro */
    onClickModal: propTypes.func,
    /** Esconder o botão de abrir o modal de inserir registro  */
    esconderBotao: propTypes.bool,
    /** Cor do botão de abrir o modal  */
    botaoColor: propTypes.string,
    /** Ícone do botão de abrir o modal  */
    botaoIcon: propTypes.string,
    /** CSS customizado para as options do dropdown */
    customOptionStyle: propTypes.object,
    /** Pode ser passado componentes customizados para complementar o select base. Um exemplo é o SingleSelectProduto */
    components: propTypes.object,
    /** Determina se o componente irá buscar ou não as options inicialmente */
    loadInitialValues: propTypes.bool
};

const mapStateToProps = state => ({
    isMobile: state.dispositivo.isMobile,
})

export default connect(mapStateToProps)(SingleSelect);
