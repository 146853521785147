import React, { Component } from 'react'
import Modal from '../../../../../../../components/Modal';
import Form from '../../../../../../../components/Form';
import format from 'date-fns/format';
import { asyncGetHistorico } from './request';
import reactAutobind from 'react-autobind';
import FormActions from '../../../../../../../components/FormActions';
import ButtonCancelar, { estadosBotaoCancelar } from '../../../../../../../components/Button/ButtonCancelar';
import FormContent from '../../../../../../../components/FormContent';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/components/datatable/DataTable';
import { Paginator } from 'primereact/paginator';
import { construirUrl } from '../../../../../../../common/rsql';
import NenhumRegistroEncontrado from '../../../../../../../components/NenhumRegistroEncontrado';
import { services } from '../../../../../../../common/constantes/api';

export default class ModalHistorico extends Component {

    constructor(props) {
        super(props);

        reactAutobind(this);

        this.state = {
            registros: [],
            totalElements: 0,
            rows: 10,
            page: 0,
            sortField: 'dataHora',
            sortOrder: -1
        }
    }

    componentDidMount() {
        this.pesquisar();
    }

    pesquisar() {
        const { page, sortField, sortOrder, rows } = this.state
        const url = construirUrl(`${services.GESTOR}/v1/nfce/${this.props.idNfce}/historico`, '?query=()', rows, page, sortOrder > 0 ? `${sortField},asc` : `${sortField},desc`)
        asyncGetHistorico(url, ({ data: historico }) => {
            this.setState({
                registros: historico.content,
                totalElements: historico.totalElements
            })
        })
    }

    renderData(row) {
        if (row.dataHora) {
            return <span>{format(new Date(row.dataHora), 'dd/MM/yyyy HH:mm:ss')}</span>
        }
        return "";
    }

    renderOperacao(row) {
        return row.acao;
    }

    renderUsuario(row) {
        if (row.nome && row.nome) {
            if (row.nome) {
                return `${row.nome} (${row.email})`
            } else {
                return `${row.email}`
            }
        } else {
            return "Usuário não cadastrado no sistema"
        }
    }

    onSort(e) {
        this.setState({ sortField: e.sortField, sortOrder: e.sortOrder }, () => {
            this.pesquisar()
        })
    }

    onPageChange(e) {
        this.setState({ page: e.page, rows: e.rows }, () => {
            this.pesquisar()
        })
    }

    render() {
        const { visible, onHide } = this.props
        const { sortField, sortOrder, registros, rows, page, totalElements } = this.state;

        return (
            <Modal
                header="Histórico da NFC-e"
                visible={visible}
                onHide={onHide}
            >
                <Form>
                    <FormActions>
                        <ButtonCancelar
                            estadoBotao={estadosBotaoCancelar.VOLTAR}
                            onClick={onHide}
                        />
                    </FormActions>
                    <FormContent>
                        <DataTable
                            className="table-light"
                            responsive
                            value={registros}
                            sortField={sortField}
                            sortOrder={sortOrder}
                            onSort={this.onSort}
                            emptyMessage={<NenhumRegistroEncontrado />}
                        >
                            <Column
                                field="dataHora"
                                header="Data"
                                body={row => this.renderData(row)}
                                sortable
                            />
                            <Column
                                field="nome"
                                header="Usuário"
                                body={this.renderUsuario}
                            />
                            <Column
                                field="acao"
                                header="Ação"
                                body={this.renderOperacao}
                            />
                        </DataTable>
                        <Paginator
                            className='paginator-light'
                            rowsPerPageOptions={[10, 25, 50]}
                            totalRecords={totalElements}
                            rows={rows}
                            first={page * rows}
                            onPageChange={this.onPageChange}
                            rightContent={<div style={{ margin: '5px' }}>{totalElements > 0 ? `${totalElements} registro(s) encontrado(s)` : null}</div>}
                        />
                    </FormContent>
                </Form>
            </Modal>

        )
    }
}