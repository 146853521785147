import { store } from "../.."

export function exibirLoading() {
    store.dispatch((dispatch) => { dispatch({ type: 'EXIBIR_LOADING' }) })
}

export function ocultarLoading() {
    store.dispatch((dispatch) => { dispatch({ type: 'OCULTAR_LOADING' }) })
}

export async function executarComLoading(funcao) {
    if (funcao) {
        exibirLoading()
        await funcao()
        ocultarLoading()
    }
}