import React, { Component } from "react";
import Button from "../../../../../components/Button";
import { FaCheck } from "react-icons/fa";
import { formatarMonetario } from "../../../../../common/mascara";
import If from "../../../../../components/If";

const detalhesStyle = {
    color: '#7b7b7b',
    margin: '15px 5px',
    display: 'flex', alignItems: 'center'
}

const styleCardNormal = {
    padding: '15px 5px',
    borderRadius: '10px',
    height: '100%',
    maxWidth: '320px'
}

const styleCardAderido = {
    ...styleCardNormal,
    border: '5px solid #36a536'

}

const styleNomePlanoNormal = {
    color: '#2a7ecb',
    display: 'flex',
    fontSize: '16px',
    textAlign: 'center',
    justifyContent: 'center',
    fontWeight: 'bold'
}

const styleNomePlanoAderido = {
    ...styleNomePlanoNormal,
    color: '#36a536'
}

const styleValorNormal = {
    margin: '5px',
    fontSize: '22px',
    fontWeight: 'bold',
    color: '#2a7ecb',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end'
}

const styleValorAderido = {
    ...styleValorNormal,
    color: '#36a536',
}

const styleButtonAdquirir = {
    marginTop: '20px',
    borderRadius: '25px',
    padding: '5px',
    minWidth: '180px',
}

const valorLetrasStyle = {
    fontSize: '14px',
    fontWeight: 'normal'
}

const contentStyle = {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
}

class CardPlano extends Component {
    render() {
        const { podeEditar } = this.props;
        const { descricao, valor, aderido, labels, ilimitado, tipo } = this.props.plano;
        const valorNumeros = formatarMonetario(valor);

        return (
            <div className='card-default' style={aderido ? styleCardAderido : styleCardNormal}>
                <div style={contentStyle}>
                    <div>
                        <div style={aderido ? styleNomePlanoAderido : styleNomePlanoNormal}>
                            {descricao.toUpperCase()}
                        </div>
                        {labels.map((detalhe, index) => {
                            return (
                                <div key={index} style={detalhesStyle}>
                                    <div style={{ marginRight: '10px' }}>
                                        <FaCheck color={aderido ? 'green' : '#006095'} />
                                    </div>
                                    <div>{detalhe}</div>
                                </div>
                            )
                        })}
                    </div>
                    <div>
                        <If test={!ilimitado}>
                            <div style={aderido ? styleValorAderido : styleValorNormal}>
                                <span>{valorNumeros}<span style={valorLetrasStyle}>/mês</span></span>
                            </div>
                        </If>
                        {
                            tipo === 'NORMAL' ? (
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    <Button
                                        title={!podeEditar ? "Você não possui permissão para contratar o plano" : null}
                                        label={aderido ? "Plano atual" : "Escolher plano"}
                                        disabled={aderido || !podeEditar}
                                        onClick={() => this.props.onAderirClick(this.props.plano)}
                                        style={styleButtonAdquirir}
                                        color={aderido ? 'success' : 'primary'}
                                    />
                                </div>
                            ) : (
                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                        <Button
                                            title="Acesso o nosso site para ver todas as formas de entrar em contato conosco"
                                            label="CONTATE-NOS"
                                            onClick={() => window.open('http://www.microsys.inf.br/s/index.php?pg=contato', '_blank')}
                                            style={styleButtonAdquirir}
                                            color="success"
                                        />
                                    </div>
                                )
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default CardPlano;
