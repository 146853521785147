import React, { Component } from 'react';
import propTypes from 'prop-types'
import Col from '../../Col';
import { buscarDisabledDeAcordoComAsPermissoes, buscarHiddenDeAcordoComAsPermissoes, buscarTitleCampoDeAcordoComAsPermissoes } from '../../../common/autorizacao/manipulacaoDeComponentes';
import { renderizarValidacao, buscarClassNamePelosErros } from '../../../common/tratamentoDeErro/validacoesDeCampos';
import autoBind from 'react-autobind'
import { usuarioPossuiModulos } from '../../../common/autenticacao';
import MaskInput from 'react-maskinput';
import { manterApenasNumeros } from '../../../common/mascara';

export default class InputMask extends React.Component {

    render() {
        const { field, form, ...rest } = this.props;
        return (
            <React.Fragment>
                <InternalInputMask
                    {...field}
                    {...rest}
                    errors={form.errors[field.name]}
                    touched={form.touched[field.name]}
                />
            </React.Fragment>
        );
    }
}


//componente: https://github.com/xnimorz/masked-input/tree/master/packages/react-maskinput
export class InternalInputMask extends Component {

    constructor(props) {
        super(props)
        autoBind(this)

        this.state = {
            validouModulo: true
        }
    }

    componentDidMount() {
        if (this.props.modulosEspecificos)
            this.setState({ validouModulo: usuarioPossuiModulos(this.props.modulosEspecificos) })
    }

    componentDidUpdate(prevProps) {
        if (prevProps.mask !== this.props.mask && this.props.value !== prevProps.value) {
            this.props.onChange({
                target: {
                    value: this.props.value,
                    name: this.props.name
                }
            })
        }
    }


    getColStyle() {
        if (buscarHiddenDeAcordoComAsPermissoes(this.props.podeVisualizar, this.props.hidden))
            return { display: 'none', ...this.props.colStyle }
        return this.props.colStyle
    }

    onChange(e) {
        const value = this.props.removerMascara ? manterApenasNumeros(e.target.value): e.target.value

        this.props.onChange({
            ...e,
            target: {
                ...e.target,
                value: e.target.value === '' ? null : value
            }
        })
    }

    getValue() {
        return this.props.value ? this.props.value : ''
    }

    montarLabel() {
        const { obrigatorio, label, labelSize } = this.props;

        if (obrigatorio) {
            return <label style={{ fontSize: labelSize }} title={this.props.helpMessage}> {label} <b style={{ fontSize: labelSize ? labelSize : '18px', lineHeight: '5px' }} > *</b> </label>
        }

        return <label style={{ fontSize: labelSize }} title={this.props.helpMessage}> {label} </label>
    }

    getStyle() {
        const { style } = this.props
        return {
            borderRadius: '3px',
            height: style?.height? style.height : '32px',
             ...style
        }
    }

    render() {

        if (!this.state.validouModulo)
            return null

        const { onFocus, sm, md, lg, xl, id, name, placeholder, mask, onBlur, errors, touched,
            podeVisualizar, podeInserir, podeEditar, estadoCadastro, title, colId, type } = this.props

        const disabled = buscarDisabledDeAcordoComAsPermissoes(podeInserir, podeEditar, estadoCadastro, this.props.disabled)

        return (
            <Col className="p-fluid" id={colId} sm={sm} md={md} lg={lg} xl={xl} style={this.getColStyle()}>
                {this.montarLabel()}
                <MaskInput
                    title={buscarTitleCampoDeAcordoComAsPermissoes(podeVisualizar, podeInserir, podeEditar, estadoCadastro, title)}
                    id={id}
                    className={buscarClassNamePelosErros("p-inputtext p-state-default p-corner-all p-widget " + (disabled && 'p-disabled'), errors, touched)}
                    style={this.getStyle()}
                    name={name}
                    placeholder={placeholder}
                    value={this.getValue()}
                    onChange={this.onChange}
                    disabled={disabled}
                    mask={mask}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    type={type}
                />
                {renderizarValidacao(errors, touched)}
            </Col>
        )
    }
}
InternalInputMask.defaultProps = {
    type:'tel',
    removerMascara: true
}

InternalInputMask.propTypes = {
    /** Evento disparado ao modificar o componente do componente */
    onChange: propTypes.func,
    /** Label do componente */
    label: propTypes.string,
    /** Identificador do componente */
    id: propTypes.string,
    /** Identificador do id da coluna componente */
    colId: propTypes.string,
    /** Placeholder do componente */
    placeholder: propTypes.string,
    /** Valor do componente do componente */
    value: propTypes.string,
    /** Define se o componente está desabilitado*/
    disabled: propTypes.bool,
    /** Especifica os erros de validação que o componente possui (geralmente vindos do Yup)*/
    errors: propTypes.string,
    /** Especifica se o componente foi 'tocado'*/
    touched: propTypes.bool,
    /** Especifica a máscara do componente. Ex: '111.111.111-11'*/
    mask: propTypes.string,
    /** Evento executado ao sair do campo*/
    onBlur: propTypes.any,
    /** Tamanho do campo em small devices*/
    sm: propTypes.string,
    /** Tamanho do campo em medium devices*/
    md: propTypes.string,
    /** Tamanho do campo em large devices*/
    lg: propTypes.string,
    /** Tamanho do campo em extra large devices*/
    xl: propTypes.string,
    /** Estilo da coluna*/
    colStyle: propTypes.object,
    /** Estado em que o cadastro se encontra*/
    estadoCadastro: propTypes.string,
    /** Diz se o usuário possui permissão de visualizar*/
    podeVisualizar: propTypes.bool,
    /** Diz se o usuário possui permissão de editar*/
    podeEditar: propTypes.bool,
    /** Diz se o usuário possui permissão de excluir*/
    podeInserir: propTypes.bool,
    /** Title do componente*/
    title: propTypes.string,
    /** Campo destinado a uma breve  explicação sobre o campo. Irá renderizar um ícone de pergunta caso a propriedade for alimentada.*/
    helpMessage: propTypes.string,
    /** Evento ao focar no componente*/
    onFocus: propTypes.func,

    obrigatorio: propTypes.bool,

}
