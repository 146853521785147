import React, { Component } from 'react';
import autoBind from 'react-autobind';
import propTypes from 'prop-types';

import { Button } from 'primereact/button';
import ResponsiveModal from 'react-responsive-modal'
import ColorOption from './components/ColorOption';
import Grid from '../Grid';
import LinkedList from './utils/LinkedList';

const styleGridContent = {
    minWidth: '265px',
    maxWidth: '265px',
    display: 'flex'
};

const styleButton = {
    borderRadius: '5px',
};

const styleModal = {
    modal: {
        maxWidth: '220px',
    },
    overlay: {
        transform: 'translateZ(0)',
    },
    closeButton: {
        cursor: 'pointer',
    },
};

export const coresPadrao = [
    "#191970",
    "#4682B4",
    "#00CED1",
    "#008080",
    "#66CDAA",
    "#3CB371",
    "#6B8E23",
    "#B8860B",
    "#D2691E",
    "#9370DB",
    "#DA70D6",
    "#B22222",
    "#B0E0E6",
    "#4B0082",
    "#8B4513",
    "#006400",
    "#008B8B",
    "#9E0870",
    "#4F4F4F",
    "#2F4F4F",
    "#B2BABB",
];

class ColorPicker extends Component {

    constructor(props) {
        super(props);

        autoBind(this);

        this.state = {
            selectedColor: null,
            showModal: false,
            firstElements: [],
            colors: new LinkedList(coresPadrao),
        }
    }

    componentDidMount() {
        const { value } = this.props;
        const {colors} = this.state

        if (colors.indexOf(value) !== -1) {
            this.setState({ selectedColor: value })
            this.findFirstElements(value);
        } else {

            this.setState({ selectedColor: colors.getByIndex(0) }, () => this.findFirstElements())

            // const randomPositionOnArray = Math.floor(Math.random() * colors.size);
            // const colorPicked = colors.getByIndex(randomPositionOnArray).element;
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.value !== this.props.value) {
            const { value } = this.props;
            const {colors} = this.state

            if (colors.indexOf(value) !== -1) {
                this.setState({ selectedColor: value })
                this.findFirstElements(value);
            } else {
                this.setState({ selectedColor: colors.getByIndex(0) })
            }
        }
    }

    handleSelectColor(color) {
        this.setState({ selectedColor: color, showModal: false });
        this.findFirstElements(color);
        this.props.onChange(color);
    }

    findFirstElements(selectedColor) {
        const { firstElements, colors } = this.state;

        if (selectedColor && firstElements) {
            this.setState({ firstElements: firstElements.includes(selectedColor) ? firstElements : colors.getElements(selectedColor, 3) });
        } else {
            this.setState({ firstElements: colors.getElements(colors.getByIndex(0).element, 3) });
        }
    }

    render() {

        const { disabled } = this.props;
        const { firstElements, colors } = this.state;
        const elements = colors ?  colors.getList() : [];
        return (
            <div style={disabled ? { ...styleGridContent, opacity: 0.5 } : styleGridContent}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    {firstElements.map(color => (
                        <ColorOption
                            key={color}
                            onClick={!disabled && this.handleSelectColor}
                            color={color}
                            selected={this.state.selectedColor === color}
                        />
                    ))}
                </div>
                <Button
                    style={styleButton}
                    className="p-button p-button-secondary"
                    label="Outras"
                    onClick={() => this.setState({ showModal: true })}
                    disabled={disabled}
                    title="Outras"
                />
                <ResponsiveModal
                    open={this.state.showModal}
                    closeOnOverlayClick
                    onClose={() => this.setState({ showModal: false })}
                    styles={styleModal}
                    showCloseIcon
                    closeOnEsc
                >
                    <Grid justifyStart verticalAlignCenter style={{ marginTop: '25px' }}>
                        {elements.map(color => (
                            <ColorOption
                                key={color}
                                onClick={this.handleSelectColor}
                                color={color}
                                index={colors.indexOf(color)}
                                selectedColor={this.state.selectedColor}
                            />
                        ))}
                    </Grid>
                </ResponsiveModal>
            </div>
        );
    }
}

ColorPicker.propTypes = {
    color: propTypes.oneOf(coresPadrao)
};

export default ColorPicker;
