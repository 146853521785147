import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import { usuarioPossuiPermissao } from '../../../../../common/autenticacao';
import { recursos, permissoes } from '../../../../../common/constantes/autorizacao';
import autoBind from 'react-autobind'
import Message from '../../../../../components/Message';
import If from '../../../../../components/If';

const textStyle = {
    margin: '5px 0px'
}

class MessageDiasRestantes extends Component {

    constructor(props) {
        super(props);
        autoBind(this);
        this.state = {
            podeVisualizarPlanos: usuarioPossuiPermissao(recursos.PLANOS, permissoes.VISUALIZAR)
        }
    }

    render() {
        return (
            <Message
                severity="info"
                colStyle={{ margin: '1em 0em' }}
                text={
                    <div>
                        <h2>Não foi possível confirmar seu pagamento</h2>
                        <p style={textStyle}>Regularize seu cartão de crédito para evitar o bloqueio do sistema</p>
                        <If test={this.state.podeVisualizarPlanos}>
                            <span onClick={() => this.props.history.push("/planos")} style={{ cursor: 'pointer', fontWeight: 'bold' }}>
                                Ir para configurações
                            </span>
                        </If>
                    </div>
                }
            />
        )
    }
}

export default withRouter(MessageDiasRestantes)
