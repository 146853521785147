import { get, put, exibirToast } from '../../../../../../../../common/requisicoes';
import { services } from '../../../../../../../../common/constantes/api';
import { formatISO, isValid, isDate } from 'date-fns';

export function buscarReceitasPrevistas(dataInicial, dataFinal, onSuccess, onError) {
    let dataInicialFormatada = formatISO(new Date(1900, 1, 1), {representation: 'date'});
    let dataFinalFormatada = formatISO(new Date(2100, 1, 1), {representation: 'date'});

    if (isDate(dataInicial) && isValid(dataInicial)) {
        dataInicialFormatada = formatISO(dataInicial, {representation: 'date'})
    }
    if (isDate(dataFinal) && isValid(dataFinal)) {
        dataFinalFormatada = formatISO(dataFinal, {representation: 'date'})
    }
    return get(`${services.GESTOR}/v1/dashboards/financas/receitas_previstas?dataInicial=${dataInicialFormatada}&dataFinal=${dataFinalFormatada}`, null, onSuccess, onError, false);
}

export function efetuarRecebimento(idReceita, onSuccess, onError) {
    return put(`${services.GESTOR}/v1/contas_receber/${idReceita}/receber`, null, null, exibirToast(onSuccess, "Recebimento efetuado com sucesso"), onError, false);    
}
