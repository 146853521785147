import { Checkbox } from "primereact/checkbox";
import { Column } from "primereact/column";
import { Menu } from "primereact/menu";
import { TreeTable } from 'primereact/treetable';
import React, { Component } from "react";
import autoBind from "react-autobind/lib/autoBind";
import { GiPlainCircle } from "react-icons/gi";
import { GoBookmark } from "react-icons/go";
import Button from "../../../../../components/Button";
import Grid from "../../../../../components/Grid";
import If from "../../../../../components/If";
import NenhumRegistroEncontrado from "../../../../../components/NenhumRegistroEncontrado";
import { converterParaTreeTable } from "./utils/converter";

const styleButton = {
    borderRadius: '50%',
    padding: '5px',
    width: '30px',
    height: '30px',
    margin: '3px'
};

class TreeTabelaCategorias extends Component {

    constructor(props) {
        super(props);

        autoBind(this);

        this.state = {
            exibirCategoriasInativas: false,
            expandedKeys: {},
            categoriasTree: [],
            registroSelecionado: null
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const { categorias } = this.props
        const { exibirCategoriasInativas } = this.state

        if (prevProps.categorias !== this.props.categorias || prevState.exibirCategoriasInativas !== exibirCategoriasInativas) {
            let categoriasFiltradas = []
            if (exibirCategoriasInativas) {
                categoriasFiltradas = categorias
            } else {
                categoriasFiltradas = this.filtrarCategoriasAtivas(categorias);
            }

            this.setState({
                categoriasTree: converterParaTreeTable(categoriasFiltradas)
            })
        }
    }


    filtrarCategoriasAtivas(categorias) {
        let result = []

        for (const categoria of categorias) {
            if (categoria.ativa) {
                result.push({
                    ...categoria,
                    subCategorias: this.filtrarCategoriasAtivas(categoria.subCategorias)
                })
            }
        }

        return result;
    }

    renderAcoesField(row) {
        if (this.props.isMobile) {
            return this.acoesMobile(row)
        } else {
            return this.acoesTelaNormal(row)
        }
    }

    acoesMobile(row) {
        let data = row.data

        const acoesDoBotaoMobile = this.buscarAcoesMobile(data);
        return (
            <span>
                <Button
                    color='secondary'
                    style={styleButton}
                    icon="fa fa-ellipsis-v"
                    size="icon"
                    disabled={Boolean(acoesDoBotaoMobile.length === 0)}
                    title={Boolean(acoesDoBotaoMobile.length === 0) ? "Nenhuma operação possível para esta categoria" : null}
                    onClick={event => {
                        this.menuOpcoes.toggle(event)
                        this.setState({ registroSelecionado: data })
                    }}
                />
            </span>
        );
    }

    buscarAcoesMobile(registroSelecionado) {
        const { informacoesPermissoes } = this.props

        let itens = [];

        if (registroSelecionado) {

            if (registroSelecionado.ativa) {
                itens.push({
                    label: "Inativar",
                    icon: "fa fa-ban",
                    command: () => this.props.inativarCategoria(registroSelecionado)
                });
            } else {
                itens.push({
                    label: "Ativar",
                    icon: "fa fa-check",
                    command: () => this.props.ativarCategoria(registroSelecionado)
                });
            }

            if (informacoesPermissoes.podeVisualizar) {
                itens.push({
                    label: 'Editar',
                    icon: 'fa fa-pencil',
                    command: () => {
                        this.props.selecionarCategoria(registroSelecionado);
                        this.props.exibirModalCategoria();
                    },
                })
            }

            if (informacoesPermissoes.podeExcluir) {
                itens.push({
                    label: 'Excluir',
                    icon: 'fa fa-trash',
                    command: () => this.props.excluirCategoria(registroSelecionado),
                })
            }
        }


        return itens;
    }

    acoesTelaNormal(row) {
        const { informacoesPermissoes } = this.props

        let data = row.data

        return (
            <span>
                {data.ativa ? (
                    <Button
                        style={styleButton}
                        className="p-button p-button-secondary"
                        icon="fa fa-ban"
                        title={!informacoesPermissoes.podeEditar ? "Inativar: Você não possui permissão para executar essa ação" : "Inativar"}
                        disabled={!informacoesPermissoes.podeEditar}
                        onClick={() => this.props.inativarCategoria(data)}
                    />
                ) : (
                    <Button
                        style={styleButton}
                        className="p-button p-button-secondary"
                        icon="fa fa-check"
                        title={!informacoesPermissoes.podeEditar ? "Ativar: Você não possui permissão para executar essa ação" : "Ativar"}
                        disabled={!informacoesPermissoes.podeEditar}
                        onClick={() => this.props.ativarCategoria(data)}
                    />
                )}
                <Button
                    style={styleButton}
                    className="p-button p-button-primary"
                    icon="fa fa-pencil"
                    title={!informacoesPermissoes.podeVisualizar ? "Editar: Você não possui permissão para executar essa ação" : "Editar"}
                    disabled={!informacoesPermissoes.podeVisualizar}
                    onClick={() => {
                        this.props.selecionarCategoria(data);
                        this.props.exibirModalCategoria();
                    }}
                />
                <Button
                    style={styleButton}
                    className="p-button p-button-danger"
                    icon="fa fa-trash"
                    title={!informacoesPermissoes.podeExcluir ? "Excluir: Você não possui permissão para executar essa ação" : "Excluir"}
                    disabled={!informacoesPermissoes.podeExcluir}
                    onClick={() => this.props.excluirCategoria(data)}
                />
            </span>
        );
    }

    renderNomeField(row) {
        let data = row.data
        let marginLeftStyle = null;

        const keyLength = row.key.split("-").length;

        if (keyLength > 1) {
            marginLeftStyle = { marginLeft: `${(keyLength) * 16}px` }
        }

        const stylePrimeiroNivel = keyLength === 1 ? { fontWeight: "bold", fontSize: "16px" } : null;
        const styleSegundoNivel = keyLength === 2 ? { fontWeight: "bold" } : null;
        const style = data.ativa ? null : { opacity: '0.50' }
        const texto = data.ativa ? data.nome : data.nome + ' (Inativa)'

        return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <span style={{ ...marginLeftStyle, ...styleSegundoNivel, ...style, ...stylePrimeiroNivel, marginRight: '5px' }} title={texto}>{texto}</span>
                {this.renderFavorita(row)}
            </div>
        );
    }

    renderFavorita(row) {

        const { tipoCategoria } = this.props

        if (tipoCategoria === "RECEITA") {
            return (
                <>
                    {row.data.favoritaReceita ? <GoBookmark size={22} color="#fbc02d" title="Categoria favorita receita" /> : null}
                </>
            )
        } else {
            return (
                <>
                    {row.data.favoritaDespesa ? <GoBookmark size={22} color="#fbc02d" title="Categoria favorita despesa" /> : null}
                </>
            )
        }
    }

    renderCorField(row) {
        let data = row.data
        const temPai = row.key.split("-").length > 1;
        return (
            <>
                {!temPai ? <GiPlainCircle style={!data.ativa ? { opacity: '0.50' } : null} color={data.aparencia} size={24} /> : null}
            </>
        )
    }

    onClickExibirContasInativas() {
        this.setState({ exibirCategoriasInativas: !this.state.exibirCategoriasInativas });
    }

    render() {
        const { categoriasTree, registroSelecionado, exibirCategoriasInativas } = this.state;
        const { isMobile, tabName } = this.props;

        const exibirTabela = Boolean(categoriasTree.root && categoriasTree.root.length > 0);

        return (
            <>
                <Menu
                    model={this.buscarAcoesMobile(registroSelecionado)}
                    popup={true}
                    style={{ minWidth: '230px' }}
                    ref={el => this.menuOpcoes = el}
                />
                <If test={exibirTabela}>
                    <TreeTable
                        tableClassName="tree-table-categorias"
                        value={categoriasTree.root}
                        expandedKeys={categoriasTree.chaves}
                        onToggle={e => null} // precisa passar algo para onToggle senão o expandedKeys não funciona...
                        responsive
                        header={
                            <span style={{ display: 'flex', justifyContent: 'flex-end', margin: '15px 0px' }}>
                                <Checkbox
                                    inputId={`exibitCategoriasCheck-${tabName}`}
                                    checked={exibirCategoriasInativas}
                                    name={`exibitCategoriasCheck-${tabName}`}
                                    onChange={this.onClickExibirContasInativas}
                                />
                                <label htmlFor={`exibitCategoriasCheck-${tabName}`} className="p-checkbox-label">Exibir categorias inativas</label>
                            </span>
                        }
                    >
                        <Column
                            header="Cor"
                            field="aparencia"
                            body={this.renderCorField}
                            style={{ width: '40px', textAlign: 'start' }}
                        />
                        <Column
                            header="Nome"
                            field="nome"
                            body={this.renderNomeField}
                            style={{ textAlign: 'justify', wordBreak: 'break-all' }}
                            expander
                        />
                        <Column
                            header="Ações"
                            body={this.renderAcoesField}
                            style={{ width: `${isMobile ? '60' : '135'}px` }}
                        />
                    </TreeTable>
                </If>
                <If test={!exibirTabela}>
                    <NenhumRegistroEncontrado message="Nenhuma categoria encontrada" />
                    <div style={{ color: '#999999', width: '100%' }}>
                        <Grid justifyCenter style={{ padding: '15px' }}>
                            Clique no botão "Exibir categorias inativas" para visualizar todas as categorias
                        </Grid>
                    </div>
                </If>
            </>
        )
    }
}

export default TreeTabelaCategorias;
