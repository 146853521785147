import React from 'react';
import Col from '../../../../../../components/Col';
import Grid from '../../../../../../components/Grid';
import { formatarDecimais } from '../../../../../../common/mascara';

const styleCardContent = {
    borderRadius: '5px'
};

const styleTitle = {
    fontSize: '14px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textAlign: 'end',
    padding: '0px',
};

const styleValue = {
    fontSize: '18px',
    marginTop: '5px',
    fontWeight: 'bold',
    overflow: 'hidden',
};

const SimpleCard = ({ primaryColor, title, value, helpMessage }) => {
    return (
        <div className="p-card" style={styleCardContent}>
            <Grid verticalAlignCenter justifyBetween style={{ margin: '0px' }}>
                <Col col="12">
                    <Grid justifyEnd verticalAlignCenter style={{ margin: '0px' }}>
                        <div className="p-col" style={styleTitle} title={helpMessage}>{title}</div>
                    </Grid>
                    <Grid justifyEnd style={{ margin: '0px' }}>
                        <div style={{ ...styleValue, color: primaryColor }}>
                            {formatarDecimais(value)}
                        </div>
                    </Grid>
                </Col>
            </Grid>
        </div>
    );
}

export default SimpleCard;
