import React, { Component } from "react";
import MenuSuperior from "../../../components/MenuSuperior";
import LayoutMenuSuperior from "../../../components/Layout/LayoutMenuSuperior";
import FormGroup from "../../../components/FormGoup";
import CardMeuCartao from "./components/CardMeuCartao";
import Grid from "../../../components/Grid";
import Col from "../../../components/Col";
import ButtonCancelar, { estadosBotaoCancelar } from "../../../components/Button/ButtonCancelar";
import autoBind from 'react-autobind';
import Paper from "../../../components/Paper";
import CardHistorico from "./components/CardHistorico";
import { connect } from "react-redux";
import { asyncGetPlano, asyncGetHistorico, asyncGetInformacoesGeraisPlano, asyncAderirAoPlano, asyncGetCartao, asyncRemoverCartao } from "./requests";
import { buscarConfiguracaoUsuario, configuracoesUsuario, salvarConfiguracaoUsuario } from "../../../common/configuracaoUsuario";
import Tutorial from "../../../components/Tutorial";
import { tutorialSteps } from "../../../components/Tutorial/steps/planos";
import CardUtilizacao from "./components/CardUtilizacao";
import { converterPlanoParaFormulario } from "./utils/planoConverter";
import CardPlano from "./components/CardPlano";
import ModalConfirmarAdesao from "./components/ModalConfirmarAdesao";
import ModalCartao from "./components/ModalCartaoForm";
import If from "../../../components/If";
import { buscarDadosLoginLocalStorage, fazerLogin, usuarioPossuiPermissao, sincronizarInformacoesLogin } from "../../../common/autenticacao";
import { recursos, permissoes, modulos } from "../../../common/constantes/autorizacao";
import parse from "date-fns/parse";
import { dispararAcoesReduxPossuiSuporteViaChat } from "./utils/redux";
import { construirUrl } from "../../../common/rsql";
import { services } from "../../../common/constantes/api";
import { confirm } from "../../../components/Toast";

const MeioPagamento = {
    SEM_PAGAMENTO: "SEM_PAGAMENTO",
    CARTAO_CREDITO: "CARTAO_CREDITO",
    BOLETO_BANCARIO: "BOLETO_BANCARIO",
}

const avisoPlanoExpiradoStyle = {
    fontSize: '16px',
    fontWeight: 'bold',
    color: '#e23838',
    textAlign: 'center'
}

class Planos extends Component {

    constructor(props) {
        super(props)
        autoBind(this)
        this.state = {
            podeEditar: usuarioPossuiPermissao(recursos.PLANOS, permissoes.EDITAR),
            planos: [],
            historico: {
                registros: [],
                totalElements: 0,
            },
            tutorialVisible: false,
            modalAdesaoVisible: false,
            modalCartaoVisible: false,
            novoPlano: {},
            novaAdesaoEfetuada: false,
            informacoesCartao: {
                numero: '-',
                expiracao: '-',
                titular: '-'
            },
            informacoesAdicionaisPlano: {
                valorPlanoAtual: 0,
                efetuarPagamentoAoAderir: false,
                cartaoDeCreditoCadastrado: false
            }
        }
    }

    componentDidMount() {
        this.atualizarDados()

        if (buscarConfiguracaoUsuario(configuracoesUsuario.EXIBIR_TUTORIAL_PLANOS) !== false) {
            this.setState({ tutorialVisible: true })
            salvarConfiguracaoUsuario(configuracoesUsuario.EXIBIR_TUTORIAL_PLANOS, false, null, false)
        }
    }

    atualizarDados() {
        this.atualizarInformacoesPlano()
        this.atualizarInformacoesGeraisPlano()
        this.atualizarInformacoesCartao()
        this.atualizarInformacoesHistorico()
    }

    atualizarInformacoesGeraisPlano() {
        asyncGetInformacoesGeraisPlano(({ data: informacoesAdicionaisPlano }) => {
            this.setState({
                informacoesAdicionaisPlano: {
                    ...informacoesAdicionaisPlano,
                    dataProximoPagamento: parse(informacoesAdicionaisPlano.dataProximoPagamento, 'yyyy-MM-dd', new Date())
                }
            })
        })
    }

    atualizarInformacoesPlano() {
        asyncGetPlano(({ data: planos }) => {
            this.setState({ planos: converterPlanoParaFormulario(planos) })
        })
    }

    atualizarInformacoesCartao() {
        asyncGetCartao(({ data: informacoesCartao }) => {
            this.setState({ informacoesCartao })
        })
    }



    atualizarInformacoesHistorico(page = 0, sortField = 'receivedDate', sortOrder = -1, rows = 10) {
        const url = construirUrl(`${services.GESTOR}/v1/planos/historico_pagamentos`, '?query=()', rows, page, sortOrder > 0 ? `${sortField},asc` : `${sortField},desc`)

        asyncGetHistorico(url, ({ data: historico }) => {
            this.setState({
                historico: {
                    registros: historico.content,
                    totalElements: historico.totalElements
                },
            })
        })
    }

    onVoltarClick() {
        if (this.props.isModal) {
            this.props.onHide()
        } else {
            this.props.history.goBack();
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.dataAtualizacaoPlano !== this.props.dataAtualizacaoPlano) {
            this.atualizarDados()
        }
    }

    onAderirClick(novoPlano) {
        this.setState({ novoPlano, modalAdesaoVisible: true })
    }

    async aderir() {
        const { informacoesAdicionaisPlano, novoPlano } = this.state
        if (novoPlano.valor > 0 && !informacoesAdicionaisPlano.cartaoDeCreditoCadastrado) {
            this.setState({ modalCartaoVisible: true })
        } else {
            let mensagemSucesso = "Plano contratado com sucesso!"
            if (novoPlano.valor > 0 && informacoesAdicionaisPlano.efetuarPagamentoAoAderir) {
                mensagemSucesso = "Pagamento efetuado com sucesso!"
            }
            await asyncAderirAoPlano(novoPlano.id, mensagemSucesso, async () => {
                this.setState({ modalAdesaoVisible: false, novaAdesaoEfetuada: true })
                const dadosLocalStorage = buscarDadosLoginLocalStorage()
                if (novoPlano.valor === 0 && dadosLocalStorage.organizacao.bloqueada) {
                    fazerLogin(dadosLocalStorage.email, dadosLocalStorage.senha, dadosLocalStorage.manterConectado, () => {
                        this.atualizarDados();
                    })
                } else {
                    this.atualizarDados()
                    await sincronizarInformacoesLogin()
                    if (novoPlano.modulo === modulos.ESSENCIAL) {
                        dispararAcoesReduxPossuiSuporteViaChat(novoPlano.suporteChat)
                    }
                }
            })
        }
    }

    removerCartaoCredito(){
        confirm('Confirmação', 'Tem certeza que deseja remover seu cartão de crédito?', async () => {
			await asyncRemoverCartao(() => this.atualizarDados())
		})
    }

    renderizarInformacoesPlano() {
        const { planos, historico, informacoesAdicionaisPlano, informacoesCartao, podeEditar } = this.state
        const planoAtual = planos.find(plano => plano.aderido);

        if (planoAtual && (planoAtual.meioPagamento === MeioPagamento.CARTAO_CREDITO)) {
            return (
                <>
                    <Col sm='12' md='6' lg='4' xl='4'>
                        <CardMeuCartao
                            className="step-planos-cartao"
                            informacoesCartao={informacoesCartao}
                            valorPlano={informacoesAdicionaisPlano.valorPlanoAtual}
                            onAlterarClick={() => this.setState({ modalCartaoVisible: true })}
                            onRemoverCartaoClick={this.removerCartaoCredito}
                            podeEditar={podeEditar}
                            planoAtual={planoAtual}
                        />
                    </Col>
                    <Col sm='12' md='6' lg='4' xl='4'>
                        <CardUtilizacao
                            planos={planos}
                            planoAtual={planoAtual}
                            className="step-planos-utilizacao"
                        />
                    </Col>
                    <Col sm='12' md='6' lg='4' xl='4'>
                        <CardHistorico
                            className="step-planos-historico"
                            historico={historico}
                            planoAtual={planoAtual}
                            atualizarInformacoesHistorico={this.atualizarInformacoesHistorico}
                        />
                    </Col>
                </>
            )
        }

        if (planoAtual && (planoAtual.meioPagamento === MeioPagamento.BOLETO_BANCARIO)) {
            return (
                <Col sm='12' md='6' lg='4' xl='4'>
                    <CardUtilizacao
                        planoAtual={planoAtual}
                        className="step-planos-utilizacao"
                    />
                </Col>
            )
        }
    }

    renderizarPlanos() {
        const { planos, podeEditar } = this.state
        const planoAtual = planos.find(plano => plano.aderido);

        if (planoAtual && (planoAtual.meioPagamento === MeioPagamento.BOLETO_BANCARIO)) {
            return (
                <Col key={planoAtual.id} sm='12' md='6' lg='6' xl='3'>
                    <CardPlano
                        podeEditar={podeEditar}
                        plano={planoAtual}
                        onAderirClick={this.onAderirClick}
                    />
                </Col>
            )
        }

        return this.state.planos.map(plano => {
            return (
                <Col key={plano.id} sm='12' md='6' lg='6' xl='3'>
                    <CardPlano
                        podeEditar={podeEditar}
                        plano={plano}
                        onAderirClick={this.onAderirClick}
                    />
                </Col>
            )
        })
    }

    render() {
        const { informacoesAdicionaisPlano, novaAdesaoEfetuada, novoPlano } = this.state
        const { isModal, mensagemAviso } = this.props

        return (
            <>
                <Tutorial
                    steps={tutorialSteps}
                    showSkipButton
                    continuous
                    disableScrolling
                    visible={this.state.tutorialVisible}
                    onHide={() => this.setState({ tutorialVisible: false })}
                />
                <MenuSuperior isModal={isModal}>
                    <ButtonCancelar
                        estadoBotao={estadosBotaoCancelar.VOLTAR}
                        onClick={this.onVoltarClick}
                    />
                </MenuSuperior>
                <LayoutMenuSuperior isModal={isModal}>
                    <Paper header="Planos e preços" childsOnly={isModal}>
                        <FormGroup>
                            <Grid justifyCenter>
                                <If test={mensagemAviso && !novaAdesaoEfetuada}>
                                    <Col style={avisoPlanoExpiradoStyle}>
                                        {mensagemAviso}
                                    </Col>
                                </If>

                                {this.renderizarInformacoesPlano()}

                            </Grid>
                            <Grid className="step-planos-contratacao" style={{ marginTop: '10px' }} justifyCenter>
                                {this.renderizarPlanos()}
                            </Grid>
                        </FormGroup>
                    </Paper>
                </LayoutMenuSuperior>
                <If test={this.state.modalAdesaoVisible}>
                    <ModalConfirmarAdesao
                        visible={this.state.modalAdesaoVisible}
                        onHide={() => this.setState({ modalAdesaoVisible: false })}
                        onConfirmarAdesaoClick={this.aderir}
                        valorPlanoAtual={informacoesAdicionaisPlano.valorPlanoAtual}
                        novoPlano={novoPlano}
                        dataProximoPagamento={informacoesAdicionaisPlano.dataProximoPagamento}
                        efetuarPagamentoAoAderir={novoPlano.valor > 0 && informacoesAdicionaisPlano.efetuarPagamentoAoAderir}
                    />
                </If>
                <If test={this.state.modalCartaoVisible}>
                    <ModalCartao
                        visible={this.state.modalCartaoVisible}
                        onHide={() => this.setState({ modalCartaoVisible: false })}
                    />
                </If>
            </>
        );
    }
}

const mapStateToProps = state => ({
    dataAtualizacaoPlano: state.plano && state.plano.dataAtualizacaoPlano,
    possuiSuporteViaChat: state.possuiSuporteViaChat
})

export default connect(mapStateToProps)(Planos);
