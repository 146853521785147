import { actionTypes } from "../../../../../../../common/constantes/reduxTypes";

export default (state = false, action) => {
    switch (action.type) {
        case actionTypes.MENU_LATERAL_FECHADO: {
            return action.payload
        }
        default: {
            return state
        }
    }
}