import React, { Component } from 'react'
import { withFormik, Field } from 'formik';
import * as Yup from 'yup';
import Button from '../../../../components/Button';
import ButtonNovo, { estadosBotaoNovo } from '../../../../components/Button/ButtonNovo';
import ButtonCancelar, { estadosBotaoCancelar } from '../../../../components/Button/ButtonCancelar';
import ButtonSalvar from '../../../../components/Button/ButtonSalvar';
import ButtonExcluir from '../../../../components/Button/ButtonExcluir';
import { mensagensDeValidacao } from '../../../../common/constantes/mensagens'
import InputField from '../../../../components/Input//InputField'
import Grid from '../../../../components/Grid/index';
import { asyncBuscarEstruturaPapeis, asyncGetPapel, asyncCreatePapel, asyncUpdatePapel, asyncDeletePapel, asyncGetPapelComMesmoNome } from '../requests';
import Col from '../../../../components/Col';
import { estadosCadastro, recursos, permissoes } from '../../../../common/constantes/autorizacao';
import Tree from '../../../../components/Tree';
import { confirmarExclusao } from '../../../util/exclusaoDeRegistros';
import { validarUUID } from '../../../../common/manipulacaoDeString';
import { atualizarUrl, metodosAtualizarUrl, voltarParaAPesquisa, validarFormulario } from '../../../util';
import autoBind from 'react-autobind';
import { alert } from '../../../../components/Toast';
import { helpFieldText } from './help';
import { usuarioPossuiPermissao } from '../../../../common/autenticacao';
import { converterPapelParaFormulario, converterPapelParaApi, converterEstruturaParaTree } from '../util/papelConverter';
import { possuiPermissoesDePapeis, permissoesForamMarcadas } from '../util/functions';
import { configuracoesUsuario, salvarConfiguracaoUsuario, buscarConfiguracaoUsuario } from '../../../../common/configuracaoUsuario';
import { tutorialSteps } from '../../../../components/Tutorial/steps/papeisPermissoes';
import Tutorial from '../../../../components/Tutorial';
import { keyFilterConsultaRsql } from '../../../../common/rsql';
import Form from '../../../../components/Form';
import FormActions from '../../../../components/FormActions';
import FormContent from '../../../../components/FormContent';

const initialValues = {
    id: null,
    nome: null,
    permissoes: {}
}

const CADASTROURL = '/papeis/cadastro'
const PESQUISAURL = '/papeis'

class Fields extends Component {

    constructor(props) {
        super(props);
        autoBind(this);

        this.state = {
            podeInserir: usuarioPossuiPermissao(recursos.PAPEIS, permissoes.INSERIR),
            podeEditar: usuarioPossuiPermissao(recursos.PAPEIS, permissoes.EDITAR),
            podeExcluir: usuarioPossuiPermissao(recursos.PAPEIS, permissoes.EXCLUIR),
            deveExibirTutorial: buscarConfiguracaoUsuario(configuracoesUsuario.EXIBIR_TUTORIAL_PAPEIS_PERMISSOES),
            estruturaTree: [],
            tutorialVisible: false
        };
    }

    async componentDidMount() {
        const id = this.props.match.params.id;

        await asyncBuscarEstruturaPapeis(({ data: papeisPermissoes }) => {
            const estruturaTree = converterEstruturaParaTree(papeisPermissoes.recursos);
            this.setState({ estruturaTree: estruturaTree })

            if (validarUUID(id) && !this.props.isModal) {
                this.asyncSelectRegistro(id)
            }
        })

        if (this.state.deveExibirTutorial !== false) {
            this.setState({ tutorialVisible: true })
            salvarConfiguracaoUsuario(configuracoesUsuario.EXIBIR_TUTORIAL_PAPEIS_PERMISSOES, false, null, false)
        }
    }

    novo() {
        atualizarUrl(this.props.history, CADASTROURL, null, metodosAtualizarUrl.POP)
        this.props.resetForm({ values: initialValues })
    }

    excluir() {
        confirmarExclusao(this.asyncDeleteRegistro)
    }

    buscarEstadoCadastro() {
        return this.props.values.id ? estadosCadastro.EDICAO : estadosCadastro.INCLUSAO
    }

    async salvar(e, novoOnSuccess) {
        await this.props.handleSubmit()
        if (await validarFormulario(this.props) && !await this.existePapelComMesmoNome()) {
            if (this.props.values.id) {
                await this.asyncUpdateRegistro(novoOnSuccess)
            } else {
                await this.asyncCreateRegistro(novoOnSuccess)
            }
        }
    }

    async existePapelComMesmoNome() {
        const { nome, id } = this.props.values
        let existePapelComMesmoNome = false
        await asyncGetPapelComMesmoNome(nome, ({ data: papeis }) => {
            papeis.content.forEach(papel => {
                if (papel.id !== id) {
                    existePapelComMesmoNome = true
                    this.props.setFieldError("nome", "Já existe um grupo com o nome " + nome)
                }
            })
        })
        return existePapelComMesmoNome
    }

    cancelar() {
        if (this.props.dirty) {
            this.props.resetForm({ values: this.props.initialValues })
        } else {
            voltarParaAPesquisa(this.props.history, PESQUISAURL)
        }
    }

    async asyncDeleteRegistro() {
        await asyncDeletePapel(this.props.values.id, () => {
            this.props.resetForm()
            voltarParaAPesquisa(this.props.history, PESQUISAURL)
        })
    }

    async asyncUpdateRegistro(novoOnSuccess) {
        if (this.props.values.administrador && !possuiPermissoesDePapeis(this.props.values.permissoes)) {
            alert('Atenção', 'Você não pode remover as permissões "Grupos de usuários" do usuário Administrador.');
        } else {
            const values = converterPapelParaApi(this.props.values);
            await asyncUpdatePapel(values, () => {
                if (novoOnSuccess) {
                    novoOnSuccess();
                } else {
                    this.props.resetForm({ values: this.props.values })
                    this.asyncSelectRegistro(values.id)
                }
            })
        }
    }

    async asyncCreateRegistro(novoOnSuccess) {
        const values = converterPapelParaApi(this.props.values)
        await asyncCreatePapel(values, ({ data: papel }) => {
            if (novoOnSuccess) {
                novoOnSuccess();
            } else {
                this.asyncSelectRegistro(papel.id)
            }
        })
    }

    async asyncSelectRegistro(papelId) {
        await asyncGetPapel(papelId, ({ data: papel }) => {
            this.props.resetForm({ values: converterPapelParaFormulario(papel, this.state.estruturaTree) })
            atualizarUrl(this.props.history, CADASTROURL, papelId, metodosAtualizarUrl.POP);
        })
    }

    render() {
        const { isModal, values, dirty, setFieldValue, errors } = this.props;
        const { estruturaTree, podeInserir, podeEditar, podeExcluir } = this.state

        const informacoesPermissoes = {
            estadoCadastro: this.buscarEstadoCadastro(),
            podeInserir,
            podeEditar,
            podeExcluir
        }

        const estadoBotaoNovo = dirty ? estadosBotaoNovo.SALVAR_E_NOVO : estadosBotaoNovo.NOVO;

        const onClickNovo = dirty ? (e) => this.salvar(e, this.novo) : this.novo;


        return (
            <>
                <Tutorial
                    steps={tutorialSteps}
                    showSkipButton
                    continuous
                    visible={this.state.tutorialVisible}
                    onHide={() => this.setState({ tutorialVisible: false })}
                />

                <Form header="Cadastro de grupo de usuário" isModal={isModal} className="card-default screen-max-width">
                    <FormActions className="screen-max-width">
                        <ButtonCancelar
                            onClick={this.cancelar}
                            hidden={isModal && values.id && !dirty}
                            estadoBotao={dirty && !isModal ? estadosBotaoCancelar.CANCELAR : estadosBotaoCancelar.VOLTAR}
                            {...informacoesPermissoes}
                        />
                        <ButtonSalvar
                            onClick={this.salvar}
                            disabled={!dirty}
                            {...informacoesPermissoes}
                        />
                        <ButtonNovo
                            onClick={onClickNovo}
                            hidden={(!dirty && !values.id) || isModal}
                            estadoBotao={estadoBotaoNovo}
                            {...informacoesPermissoes}
                        />
                        <ButtonExcluir
                            hidden={!values.id}
                            onClick={this.excluir}
                            disabled={values.administrador}
                            title={values.administrador ? "Não é possível excluir o grupo administrador" : "Excluir o registro"}
                            {...informacoesPermissoes}
                        />
                        <Button
                            className='p-button-secondary'
                            type='button' label="Opções"
                            icon="fa fa-list"
                            hidden={true}
                            onClick={(event) => this.menuOpcoes.toggle(event)}
                        />
                    </FormActions>
                    <FormContent>
                        <Grid>
                            <Field
                                className="step-papeis-nome"
                                label='Nome '
                                name="nome"
                                obrigatorio
                                keyfilter={keyFilterConsultaRsql}
                                helpMessage={helpFieldText.nome}
                                value={values.nome}
                                component={InputField}
                                {...informacoesPermissoes}
                            />
                            <Col>
                                <label title={helpFieldText.permissoes}>Permissões <b style={{ fontSize: '18px', lineHeight: '5px' }}> *</b></label>
                                <Tree
                                    className="step-papeis-three"
                                    style={{ border: 0, padding: 0, width: 'auto' }}
                                    value={estruturaTree}
                                    selectionKeys={values.permissoes}
                                    onSelectionChange={async e => {
                                        await setFieldValue('permissoes', e.value)
                                        this.props.validateForm()
                                    }}
                                    selection={values.permissoes}
                                    sortMode='single'
                                    selectionMode="checkbox"
                                    header="Checkbox Selection"
                                    errors={errors.permissoes}
                                    {...informacoesPermissoes}>
                                </Tree>
                            </Col>
                        </Grid>
                    </FormContent>
                </Form>
            </>
        )
    }
}

Fields = withFormik({
    enableReinitialize: true,
    validateOnChange: false,
    mapPropsToValues() {
        return initialValues
    },

    validationSchema: Yup.object().shape({
        nome: Yup.string().nullable().required(mensagensDeValidacao.OBRIGATORIO),
        permissoes: Yup.object().nullable().test('Permissoes marcadas', "Selecione ao menos uma permissão para este grupo", val => permissoesForamMarcadas(val)),
    }),

    handleSubmit: () => { },


})(Fields);


export default Fields