import React, { Component } from "react";
import autoBind from 'react-autobind';
import { buscarHiddenDeAcordoComAsPermissoes } from "../../../common/autorizacao/manipulacaoDeComponentes";
import { renderizarValidacao } from "../../../common/tratamentoDeErro/validacoesDeCampos";
import Col from "../../Col";
import If from "../../If";
import { InternalInputMask } from "../InputMask";
import './styles/index.css'

export const tipos = {
    CPF: 'CPF',
    CNPJ: 'CNPJ'
}

const inputStyle = {
    width: 'calc(100% - 54px)',
    padding: '0px',
}

const alteraPercentualOuValor = {
    fontSize: '18px',
    height: '32px',
    width: '55px',
}

const styleDiv = {
    alignItems: 'flex-end'
}

class InputSelectCpfOrCnpj extends Component {

    constructor(props) {
        super(props);
        autoBind(this);

        this.state = {
            cnpj: '',
            cpf: ''
        }
    }

    getColStyle() {
        if (buscarHiddenDeAcordoComAsPermissoes(this.props.podeVisualizar, this.props.hidden))
            return { display: 'none', ...this.props.colStyle }
        return this.props.colStyle
    }

    onChangeTipoDocumento(e) {
        const { valueDocumentoTipo } = this.props

        if (valueDocumentoTipo === tipos.CPF) {
            this.setState({
                cnpj: ''
            }, () => {
                this.props.onChangeDocumentoTipo(tipos.CNPJ)
            })
        } else {
            this.setState({
                cpf: ''
            }, () => {
                this.props.onChangeDocumentoTipo(tipos.CPF)
            })
        }
    }

    changeValor(e) {
        const cpf = e.target.value
        this.props.onChange(cpf)
    }

    changePercentual(e) {
        const cnpj = e.target.value
        this.props.onChange(cnpj)
    }

    componentDidUpdate() {
        const { restart, onRestart } = this.props;

        if (restart && onRestart) {
            this.setState({ cnpj: '' })
            onRestart();
        }
    }

    render() {
        const { name, value, colId, colClassName, col, sm, md, lg, xl, style, labelSize, valueDocumentoTipo, errors, disabled, obrigatorio, helpMessage } = this.props;
        return (
            <Col id={colId} className={colClassName} col={col} sm={sm} md={md} lg={lg} xl={xl} style={this.getColStyle()}>
                <div className="p-inputgroup" style={styleDiv} >
                    <If test={valueDocumentoTipo === tipos.CPF}>
                        <InternalInputMask
                            style={style}
                            colStyle={inputStyle}
                            label='CPF'
                            labelSize={labelSize}
                            obrigatorio={obrigatorio}
                            name={name}
                            placeholder="   .   .   -  "
                            value={value}
                            helpMessage={helpMessage}
                            onChange={this.changeValor}
                            mask="000.000.000-00"
                            disabled={disabled}
                        />
                    </If>
                    <If test={valueDocumentoTipo === tipos.CNPJ}>
                        <InternalInputMask
                            style={style}
                            colStyle={inputStyle}
                            label='CNPJ'
                            obrigatorio={obrigatorio}
                            labelSize={labelSize}
                            helpMessage={helpMessage}
                            name={name}
                            mask="00.000.000/0000-00"
                            placeholder="  .   .   /    -  "
                            value={value}
                            onChange={this.changeValor}
                            disabled={disabled}
                        />
                    </If>
                    <span
                        color='secondary'
                        onClick={disabled ? null : this.onChangeTipoDocumento}
                        style={style ? style : alteraPercentualOuValor}
                        className='altera-cnpj-ou-cpf'
                        title="Clique aqui para alternar modo de entrada"
                    >
                        <>
                            {valueDocumentoTipo === tipos.CPF ?
                                <span className='altera-cnpj-ou-cpf-text'>CPF</span>
                                :
                                <span className='altera-cnpj-ou-cpf-text'>CNPJ</span>
                            }
                            <i className="fa fa-refresh" aria-hidden="true" />
                        </>
                    </span >
                </div >
                {renderizarValidacao(errors, errors ? true : false)}
            </Col>
        )
    }
}

export default InputSelectCpfOrCnpj;