import React, { Component } from 'react';
import MaskInput from 'react-maskinput';
import propTypes from 'prop-types'
import Col from '../../Col';
import { buscarDisabledDeAcordoComAsPermissoes, buscarHiddenDeAcordoComAsPermissoes, buscarTitleCampoDeAcordoComAsPermissoes } from '../../../common/autorizacao/manipulacaoDeComponentes';
import { renderizarValidacao, buscarClassNamePelosErros } from '../../../common/tratamentoDeErro/validacoesDeCampos';
import autoBind from 'react-autobind'
import { manterApenasNumeros } from '../../../common/mascara';
import { buscarDadosCEP, montarObjetoMunicipio } from './functions'
import Button from '../../Button';
import { alert } from '../../Toast';
import { isValidCep } from '@brazilian-utils/validators';
import { usuarioPossuiModulos } from '../../../common/autenticacao';
import { asyncBuscarMunicipiosPeloCodigo } from './requests';

export default class InputCep extends React.Component {

    render() {
        const { field, form, ...rest } = this.props;
        return (
            <React.Fragment>
                <InternalInputCep
                    {...field}
                    {...rest}
                    errors={form.errors[field.name]}
                    touched={form.touched[field.name]}
                />
            </React.Fragment>
        );
    }
}

export class InternalInputCep extends Component {

    constructor(props) {
        super(props)
        autoBind(this)

        this.state = {
            validouModulo: true
        }
    }

    componentDidMount() {
        if (this.props.modulosEspecificos)
            this.setState({ validouModulo: usuarioPossuiModulos(this.props.modulosEspecificos) })
    }

    getColStyle() {
        if (buscarHiddenDeAcordoComAsPermissoes(this.props.podeVisualizar, this.props.hidden))
            return { display: 'none', ...this.props.colStyle }
        return this.props.colStyle
    }

    modificarOnChangeParaNull(e) {
        return {
            target: {
                value: null,
                name: e.target.name
            }
        }
    }
    onChange(e) {
        if (e.target.value === '') {
            this.props.onChange({ value: null });
        } else {
            this.props.onChange({ value: manterApenasNumeros(e.target.value) });
        }
    }

    getValue() {
        return this.props.value ? this.props.value : ''
    }

    onClickCep() {
        const cep = manterApenasNumeros(this.props.value);

        buscarDadosCEP(cep,

            async data => {
                asyncBuscarMunicipiosPeloCodigo(data, ({ data: municipios }) => {
                    const dadosForm = montarObjetoMunicipio(municipios, data)
                    this.props.onChange({
                        ...dadosForm,
                        value: this.props.value,
                        pesquisou: true
                    })
                })
            },
            //onError
            err => {
                alert('Atenção', 'Não foi possível estabelecer uma conexão com o servidor. Por favor, preencha manualmente os valores ou tente novamente mais tarde.');
            },
            //invalidCEP
            message => {
                this.props.onInvalidCep && this.props.onInvalidCep(message)
            }
        )

    }

    montarLabel() {
        const { obrigatorio } = this.props;

        const label = this.props.label
        if (obrigatorio) {
            return <label title={this.props.helpMessage}> {label} <b style={{ fontSize: '18px', lineHeight: '5px' }} > *</b> </label>
        }
        return <label title={this.props.helpMessage}>{label}</label>
    }

    render() {

        if (!this.state.validouModulo) return null;

        const { sm, md, lg, xl, id, name, placeholder, onBlur, errors, touched,
            podeVisualizar, podeInserir, podeEditar, estadoCadastro, colId, type } = this.props

        const disabled = buscarDisabledDeAcordoComAsPermissoes(podeInserir, podeEditar, estadoCadastro, this.props.disabled);
        const colStyle = this.getColStyle()
        const title = buscarTitleCampoDeAcordoComAsPermissoes(podeVisualizar, podeInserir, podeEditar, estadoCadastro, this.props.title)
        const classNameInput = buscarClassNamePelosErros("p-inputtext p-state-default p-corner-all p-widget " + (disabled && 'p-disabled'), errors, touched)
        const disabledButton = !isValidCep(manterApenasNumeros(this.props.value)) || disabled;

        return (
            <Col id={colId} sm={sm} md={md} lg={lg} xl={xl} style={colStyle}>

                {this.montarLabel()}

                <span className="p-fluid" title={title}>
                    <div className="p-inputgroup">
                        <MaskInput
                            id={id}
                            className={classNameInput}
                            style={{ height: '33px' }}
                            name={name}
                            placeholder={placeholder}
                            value={this.getValue()}
                            onChange={this.onChange}
                            disabled={disabled}
                            mask='00000-000'
                            onBlur={onBlur}
                            type={type}
                        />
                        <Button
                            style={{ margin: '0px', borderTopLeftRadius: '0px', borderBottomLeftRadius: '0px' }}
                            icon="fa fa-search"
                            title="Preencher as informações automaticamente"
                            disabled={disabledButton}
                            onClick={this.onClickCep}
                        />
                    </div>
                </span>
                {renderizarValidacao(errors, touched)}
            </Col>
        )
    }
}


InternalInputCep.propTypes = {
    /** Evento disparado ao modificar o componente do componente */
    onChange: propTypes.func,
    /** Label do componente */
    label: propTypes.string,
    /** Identificador do componente */
    id: propTypes.string,
    /** Identificador do id da coluna componente */
    colId: propTypes.string,
    /** Placeholder do componente */
    placeholder: propTypes.string,
    /** Valor do componente do componente */
    value: propTypes.string,
    /** Define se o componente está desabilitado*/
    disabled: propTypes.bool,
    /** Especifica os erros de validação que o componente possui (geralmente vindos do Yup)*/
    errors: propTypes.string,
    /** Especifica se o componente foi 'tocado'*/
    touched: propTypes.bool,
    /** Especifica a máscara do componente. Ex: '111.111.111-11'*/
    mask: propTypes.string,

    /** Evento executado ao sair do campo*/
    onBlur: propTypes.any,
    /** Tamanho do campo em small devices*/
    sm: propTypes.string,
    /** Tamanho do campo em medium devices*/
    md: propTypes.string,
    /** Tamanho do campo em large devices*/
    lg: propTypes.string,
    /** Tamanho do campo em extra large devices*/
    xl: propTypes.string,
    /** Estilo da coluna*/
    colStyle: propTypes.object,
    /** Estado em que o cadastro se encontra*/
    estadoCadastro: propTypes.string,
    /** Diz se o usuário possui permissão de visualizar*/
    podeVisualizar: propTypes.bool,
    /** Diz se o usuário possui permissão de editar*/
    podeEditar: propTypes.bool,
    /** Diz se o usuário possui permissão de excluir*/
    podeInserir: propTypes.bool,
    /** Title do componente*/
    title: propTypes.string,
    /** Campo destinado a uma breve  explicação sobre o campo. Irá renderizar um ícone de pergunta caso a propriedade for alimentada.*/
    helpMessage: propTypes.string
}
