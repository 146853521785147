import React, { Component } from 'react'
import { Fieldset as InternalFieldSet } from 'primereact/fieldset'
import propTypes from 'prop-types'

export default class Fieldset extends Component {

    getStyle() {
        if (this.props.hidden || this.props.podeVisualizar === false) {
            return { display: 'none', ...this.props.style }
        }
        else return this.props.style
    }

    render() {
        return (
            <div style={this.getStyle()}>
                <InternalFieldSet  {...this.props} legend={this.props.legend} />
            </div>
        )
    }
}

Fieldset.propTypes = {
    /** Identificador do componente */
    id: propTypes.string,
    /** Diz se o usuário possui permissão de inserir */
    podeVisualizar: propTypes.bool,
    /** Esconde o componente */
    hidden: propTypes.bool,
    /** Estilo do componente */
    style: propTypes.any
}

