import React, { Component } from 'react';
import Grid from '../Grid';
import propTypes from 'prop-types';
import autoBind from 'react-autobind';
import sub from 'date-fns/sub';
import format from 'date-fns/format';
import add from 'date-fns/add';
import startOfMonth from 'date-fns/startOfMonth';
import lastDayOfMonth from 'date-fns/lastDayOfMonth';
import isValid from 'date-fns/isValid';
import lastDayOfWeek from 'date-fns/lastDayOfWeek';
import startOfWeek from 'date-fns/startOfWeek';
import isBefore from 'date-fns/isBefore';
import ptLocale from 'date-fns/locale/pt-BR';
import { Button } from 'primereact/button';
import { OverlayPanel } from 'primereact/overlaypanel';
import { isLastDayOfMonth } from 'date-fns/esm';
import Col from '../Col';
import { InternalInputDate as InputDate } from '../Input/InputDate';

import {
    differenceInDays,
    isDate,
    parseISO,
    isSameDay,
    isSameWeek,
    isSunday,
    isSaturday,
    isSameMonth,
    isFirstDayOfMonth,
    endOfMonth,
    formatISO,
} from 'date-fns';
import If from '../If';


const styleDataAtual = {
    cursor: 'pointer',
    padding: '3px'
};

const styleMenuItemOption = {
    padding: '7px',
    display: 'block',
    fontWeight: 'bold',
    minWidth: '150px'
};

const styleGridContent = {
    minWidth: '245px',
    maxWidth: '245px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '2px'
};

const styleButtonStep = {
    borderRadius: '50%'
};

function ItemSelecionavel({ title, onSelect, selected }) {
    const estiloBotao = selected ? 'primary' : 'secondary';
    return (
        <span
            id={title}
            className={`p-inputgroup-addon p-button p-button-${estiloBotao}`}
            style={styleMenuItemOption}
            onClick={onSelect}
        >
            {title}
        </span>
    );
}

const ESTILO = {
    DIA: 'DIA',
    SEMANA: 'SEMANA',
    MES: 'MES',
    PERSONALIZADO: 'PERSONALIZADO',
};

class DateInterval extends Component {

    constructor(props) {
        super(props);

        autoBind(this);

        this.state = {
            dataInicial: startOfMonth(new Date()),
            dataFinal: endOfMonth(new Date()),

            dataInicialPersonalizada: null,
            dataFinalPersonalizada: null,
            validateMessage: "",
            isIntervalValid: false,
        }
    }

    componentDidMount() {
        const dataInicial = this.buscarDataInicial();
        const dataFinal = this.buscarDataFinal();

        const estilo = this.buscarEstiloData(dataInicial, dataFinal);

        this.pesquisarDataComBaseNoEstilo(estilo);
    }

    buscarDataInicial() {
        if (this.props.interval) {
            return this.props.interval.dataInicial
        } else {
            return this.state.dataInicial;
        }
    }

    buscarDataFinal() {
        if (this.props.interval) {
            return this.props.interval.dataFinal;
        } else {
            return this.state.dataFinal;
        }
    }

    atualizarIntervalo(dataInicial, dataFinal) {
        if (this.props.interval) {
            this.props.onChange({ dataInicial, dataFinal });
        } else {
            this.setState({ dataInicial, dataFinal }, () => {
                this.props.onChange({ dataInicial, dataFinal });
            });
        }
    }

    buscarEstiloData(dataInicio, dataFim) {
        if (isSameDay(dataInicio, dataFim) && isSameDay(dataInicio, new Date()) && isSameDay(dataFim, new Date())) {
            return ESTILO.DIA;
        } else if (isSameWeek(dataInicio, dataFim) && isSunday(dataInicio) && isSaturday(dataFim)) {
            return ESTILO.SEMANA;
        } else if (isSameMonth(dataInicio, dataFim) && isFirstDayOfMonth(dataInicio) && isLastDayOfMonth(dataFim)) {
            return ESTILO.MES;
        } else {
            return ESTILO.PERSONALIZADO;
        }
    }

    adicionar() {
        const dataInicial = this.buscarDataInicial();
        const dataFinal = this.buscarDataFinal();

        switch (this.buscarEstiloData(dataInicial, dataFinal)) {
            case ESTILO.DIA: {
                const novoValor = add(dataInicial, { days: 1 });

                this.atualizarIntervalo(novoValor, novoValor);

                break;
            }
            case ESTILO.SEMANA: {
                const novoValorInicial = add(dataInicial, { weeks: 1 });
                const novoValorFinal = add(dataFinal, { weeks: 1 });

                this.atualizarIntervalo(novoValorInicial, novoValorFinal);

                break;
            }
            case ESTILO.MES: {
                const novoValorInicial = startOfMonth(add(dataInicial, { months: 1 }));
                const novoValorFinal = lastDayOfMonth(add(dataFinal, { months: 1 }));

                this.atualizarIntervalo(novoValorInicial, novoValorFinal);

                break;
            }
            case ESTILO.PERSONALIZADO: {
                if (dataInicial && dataFinal) {
                    const diffInDays = differenceInDays(dataFinal, dataInicial);
                    let daysToAdd = diffInDays === 0 ? 1 : diffInDays;

                    const novoValorInicial = add(dataInicial, { days: daysToAdd });
                    const novoValorFinal = add(dataFinal, { days: daysToAdd });

                    this.atualizarIntervalo(novoValorInicial, novoValorFinal);
                }
                break;
            }
            default:
                break;
        }
    }

    buscarTitleBotaoProximo() {
        const formatoData = this.buscarEstiloData(this.buscarDataInicial(), this.buscarDataFinal());

        if (formatoData === ESTILO.DIA) {
            return "Próximo dia";
        } else if (formatoData === ESTILO.SEMANA) {
            return "Próxima semana";
        } else if (formatoData === ESTILO.MES) {
            return "Próximo mês";
        } else {
            return "Próximo intervalo";
        }
    }

    buscarTitleBotaoAnterior() {
        const dataInicial = this.buscarDataInicial();
        const dataFinal = this.buscarDataFinal();

        const formatoData = this.buscarEstiloData(dataInicial, dataFinal);

        if (formatoData === ESTILO.DIA) {
            return "Dia anterior";
        } else if (formatoData === ESTILO.SEMANA) {
            return "Semana anterior";
        } else if (formatoData === ESTILO.MES) {
            return "Mês anterior";
        } else {
            return "Intervalo anterior";
        }
    }

    reduzir() {
        const dataInicial = this.buscarDataInicial();
        const dataFinal = this.buscarDataFinal();

        switch (this.buscarEstiloData(dataInicial, dataFinal)) {
            case ESTILO.DIA: {
                const novoValor = sub(dataInicial, { days: 1 });

                this.atualizarIntervalo(novoValor, novoValor);

                break;
            }
            case ESTILO.SEMANA: {
                const novoValorInicial = sub(dataInicial, { weeks: 1 });
                const novoValorFinal = sub(dataFinal, { weeks: 1 });

                this.atualizarIntervalo(novoValorInicial, novoValorFinal);

                break;
            }
            case ESTILO.MES: {
                const novoValorInicial = startOfMonth(sub(dataInicial, { months: 1 }));
                const novoValorFinal = lastDayOfMonth(sub(dataFinal, { months: 1 }));

                this.atualizarIntervalo(novoValorInicial, novoValorFinal);

                break;
            }
            case ESTILO.PERSONALIZADO: {
                if (dataInicial && dataFinal) {
                    const diffInDays = differenceInDays(dataFinal, dataInicial);
                    let daysToAdd = diffInDays === 0 ? 1 : diffInDays;

                    const novoValorInicial = sub(dataInicial, { days: daysToAdd });
                    const novoValorFinal = sub(dataFinal, { days: daysToAdd });

                    this.atualizarIntervalo(novoValorInicial, novoValorFinal);
                }
                break;
            }
            default:
                break;
        }
    }

    buscarDataSelecionada() {
        const dataInicial = this.buscarDataInicial();
        const dataFinal = this.buscarDataFinal();

        switch (this.buscarEstiloData(dataInicial, dataFinal)) {
            case ESTILO.DIA: {
                return format(dataInicial, "d 'de' LLL", { locale: ptLocale }).toUpperCase();
            }
            case ESTILO.SEMANA: {
                return format(dataInicial, "dd", { locale: ptLocale }) + " a " + format(dataFinal, "dd", { locale: ptLocale }) + " DE " + format(dataFinal, "LLL", { locale: ptLocale }).toUpperCase();
            }
            case ESTILO.MES: {
                return format(dataInicial, "LLL '-' yyyy", { locale: ptLocale }).toUpperCase();
            }
            case ESTILO.PERSONALIZADO: {
                if (isValid(dataInicial) && isValid(dataFinal)) {
                    return format(dataInicial, "dd LLL yy", { locale: ptLocale }).toUpperCase() + " à " + format(dataFinal, "dd LLL yy", { locale: ptLocale }).toUpperCase();
                }
                return "Selecione uma data"
            }
            default: break;
        }
    }

    pesquisarDataComBaseNoEstilo(estilo) {
        switch (estilo) {
            case ESTILO.DIA: {
                this.atualizarIntervalo(new Date(), new Date());
                this.overlaySelecao.hide();

                break;
            }
            case ESTILO.SEMANA: {
                this.atualizarIntervalo(startOfWeek(new Date()), lastDayOfWeek(new Date()));
                this.overlaySelecao.hide();

                break;
            }
            case ESTILO.MES: {
                this.atualizarIntervalo(startOfMonth(new Date()), lastDayOfMonth(new Date()));
                this.overlaySelecao.hide();

                break;
            }
            default:
                break;
        }
    }

    datasValidas(dataInicial, dataFinal, callback) {
        if (!dataInicial || !isDate(dataInicial) || !isValid(dataInicial)) {
            this.setState({ validateMessage: "Intervalo de datas inválido", isIntervalValid: false }, () => callback())
        } else if (!dataFinal || !isDate(dataFinal) || !isValid(dataFinal)) {
            this.setState({ validateMessage: "Intervalo de datas inválido", isIntervalValid: false }, () => callback())
        } else if (isBefore(dataFinal, dataInicial)) {
            this.setState({ validateMessage: "Data inicial maior que a final", isIntervalValid: false }, () => callback())
        } else if (differenceInDays(dataFinal, dataInicial) >= 367) {
            this.setState({ validateMessage: "Intervalo máximo permitido é 1 ano", isIntervalValid: false }, () => callback())
        } else {
            this.setState({ validateMessage: "", isIntervalValid: true }, () => callback())
        }
    }

    onConfirm(e) {
        e.preventDefault();

        this.datasValidas(this.state.dataInicialPersonalizada, this.state.dataFinalPersonalizada, () => {
            if (this.state.isIntervalValid) {
                this.atualizarIntervalo(this.state.dataInicialPersonalizada, this.state.dataFinalPersonalizada);
                this.overlayData.hide();
            }
        })
    }

    render() {
        const { disabled } = this.props;

        const dataInicial = this.buscarDataInicial();
        const dataFinal = this.buscarDataFinal();
        const formatoData = this.buscarEstiloData(dataInicial, dataFinal);

        return (
            <>
                <div style={styleGridContent}>
                    <Button
                        style={styleButtonStep}
                        className="p-button p-button-primary"
                        icon="fa fa-arrow-left"
                        onClick={this.reduzir}
                        disabled={disabled}
                        title={this.buscarTitleBotaoAnterior()}
                    />
                    <span
                        style={disabled ? { ...styleDataAtual, cursor: 'default' } : styleDataAtual}
                        onClick={(e) => {
                            if (!disabled) {
                                this.overlayData.hide()
                                this.overlaySelecao.show(e)
                            }
                        }}
                        aria-controls="overlay_panel"
                        aria-haspopup={true}
                    >
                        <span className='link_to' style={{ fontWeight: 'bold', color: '#006095' }} id="dateIntervalDataSelecionada">
                            {this.buscarDataSelecionada()}
                        </span>
                    </span>
                    <Button
                        style={styleButtonStep}
                        className="p-button p-button-primary"
                        icon="fa fa-arrow-right"
                        title={this.buscarTitleBotaoProximo()}
                        onClick={this.adicionar}
                        disabled={disabled}
                    />
                </div>
                <OverlayPanel ref={(el) => { this.overlaySelecao = el }} showCloseIcon>
                    <ItemSelecionavel
                        title="HOJE"
                        onSelect={() => this.pesquisarDataComBaseNoEstilo(ESTILO.DIA)}
                        selected={formatoData === ESTILO.DIA}
                    />
                    <ItemSelecionavel
                        title="ESTA SEMANA"
                        onSelect={() => this.pesquisarDataComBaseNoEstilo(ESTILO.SEMANA)}
                        selected={formatoData === ESTILO.SEMANA}
                    />
                    <ItemSelecionavel
                        title="ESTE MÊS"
                        onSelect={() => this.pesquisarDataComBaseNoEstilo(ESTILO.MES)}
                        selected={formatoData === ESTILO.MES}
                    />
                    <ItemSelecionavel
                        title="ESCOLHER"
                        onSelect={(e) => {
                            this.overlaySelecao.hide()
                            this.overlayData.show(e, document.getElementById('dateIntervalDataSelecionada'))
                        }}
                        selected={formatoData === ESTILO.PERSONALIZADO}
                    />
                </OverlayPanel>
                <OverlayPanel ref={(el) => { this.overlayData = el }} showCloseIcon dismissable={false}>
                    <form onSubmit={this.onConfirm} style={{ width: '245px' }}>
                        <InputDate sm="12" md="12" lg="12" xl="12"
                            obrigatorio
                            label="Data inicial"
                            name="dataInicialPersonalizada"
                            onChange={e => {
                                if (e.target.value) {
                                    this.setState({ dataInicialPersonalizada: parseISO(e.target.value) })
                                } else {
                                    this.setState({ dataInicialPersonalizada: null })
                                }
                            }}
                            value={this.state.dataInicialPersonalizada ? formatISO(this.state.dataInicialPersonalizada) : this.state.dataInicialPersonalizada}
                            disabled={disabled}
                        />
                        <InputDate sm="12" md="12" lg="12" xl="12"
                            obrigatorio
                            label="Data final"
                            name="dataFinal"
                            onChange={e => {
                                if (e.target.value) {
                                    this.setState({ dataFinalPersonalizada: parseISO(e.target.value) })
                                } else {
                                    this.setState({ dataFinalPersonalizada: null })
                                }
                            }}
                            value={this.state.dataFinalPersonalizada ? formatISO(this.state.dataFinalPersonalizada) : this.state.dataFinalPersonalizada}
                            disabled={disabled}
                        />
                        <Grid justifyCenter>
                            <Col col="12" style={{ display: 'flex', justifyContent: 'space-around' }}>
                                <Button
                                    label="Confirmar"
                                    disabled={!dataInicial || !dataFinal || !isValid(dataInicial) || !isValid(dataFinal) || (this.state.dataInicialPersonalizada === null && this.state.dataFinalPersonalizada === null)}
                                    onClick={this.onConfirm}
                                />
                                <Button
                                    className="p-button-secondary"
                                    label="Cancelar"
                                    onClick={(e) => {
                                        e.preventDefault()
                                        this.setState({
                                            validateMessage: "",
                                            isIntervalValid: false,
                                            dataInicialPersonalizada: null,
                                            dataFinalPersonalizada: null,
                                        }, () => {
                                            this.overlayData.hide()
                                        })
                                    }}
                                />
                            </Col>
                            <If test={!this.state.isIntervalValid}>
                                <div style={{ color: '#e91224', fontSize: '13px' }}>{this.state.validateMessage}</div>
                            </If>
                        </Grid>
                    </form>
                </OverlayPanel>
            </>
        );
    }
}

DateInterval.propTypes = {
    interval: propTypes.shape({
        dataInicial: propTypes.object,
        dataFinal: propTypes.object
    }),
    disabled: propTypes.bool,
};

export default DateInterval;
