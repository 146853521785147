import React, { Component } from 'react';
import '../../../node_modules/alertifyjs/build/css/alertify.min.css';
import '../../../node_modules/alertifyjs/build/css/themes/default.min.css';

import classNames from 'classnames';
import AppTopbar from './menuLateral/AppTopbar'
import { AppMenu } from './menuLateral/AppMenu';
import AppInlineProfile from './menuLateral/AppInlineProfile';
import { Switch } from 'react-router-dom';
import Dashboard from './Dashboard';
import { ScrollPanel } from 'primereact/components/scrollpanel/ScrollPanel';
import { BreadCrumb } from 'primereact/components/breadcrumb/BreadCrumb';
import { criarIntensBreadcrumb } from './functions';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import PrivateRoute from '../../components/Route'
import { buscarMenu } from './menuLateral/itens';
import { recursos, permissoes } from '../../common/constantes/autorizacao'
import autoBind from 'react-autobind'
import { carregarTomticket } from '../../common/tomticket';
import AutoProgressBar from '../../components/Loading/AutoProgressBar';
import Pessoas from '../cadastros/Pessoas'
import PessoasForm from '../cadastros/Pessoas/Form'
import Setores from '../cadastros/Setores'
import SetoresForm from '../cadastros/Setores/Form'
import Papeis from '../configuracao/Papeis'
import PapeisForm from '../configuracao/Papeis/Form'
import Usuarios from '../configuracao/Usuarios'
import UsuariosForm from '../configuracao/Usuarios/Form'
import ConfiguracoesGerais from '../configuracao/ConfiguracoesGerais'
import OrganizacoesForm from '../administracao/Organizacoes/Form'
import Organizacoes from '../administracao/Organizacoes'
import ProdutosForm from '../cadastros/Produtos/Form'
import Produtos from '../cadastros/Produtos'
import MinhaConta from '../configuracao/MinhaConta'
import NoMatch from '../../views/util/NoMatch'
import Documentos from '../assinaturaeletronica'
import DocumentosForm from '../assinaturaeletronica/Form'
import Planos from '../configuracao/Planos'
import AdministracaoPlanos from '../administracao/Planos';
import AdministracaoPlanosForm from '../administracao/Planos/Form'
import Contas from '../financas/Contas'
import Categorias from '../financas/Categorias'
import Lancamentos from '../financas/Lancamentos'
import ContasReceber from '../financas/ContasReceber';
import ContasPagar from '../financas/ContasPagar';
import VendasOrcamentos from '../fiscal/vendas/VendasOrcamentos';
import { buscarDadosLoginLocalStorage } from '../../common/autenticacao';
import VendasOrcamentosForm from '../fiscal/vendas/VendasOrcamentos/Form';
import MovimentacoesEstoque from '../estoque/Movimentacoes';
import AgentesAutorizadosForm from '../administracao/AgentesAutorizados/Form';
import AgentesAutorizados from '../administracao/AgentesAutorizados';
import NFe from '../fiscal/vendas/NFe';
import NFeForm from '../fiscal/vendas/NFe/Form';
import NFCeForm from '../fiscal/vendas/NFCe/Form';
import Ativos from '../investimentos/Ativos';
import Proventos from '../investimentos/Proventos';
import Corretoras from '../investimentos/Corretoras';
import Negociacoes from '../investimentos/Negociacoes';
import Importacoes from '../investimentos/Importacoes';
import NFCe from '../fiscal/vendas/NFCe';

class InitialPage extends Component {

    constructor() {
        super();
        this.state = {
            layoutMode: 'static',
            layoutColorMode: 'light',
            staticMenuInactive: false,
            overlayMenuActive: false,
            mobileMenuActive: false,
            location: '',
            items: [],
            menu: undefined,
            width: window.innerWidth,
        };

        autoBind(this)
    }

    onWrapperClick(event) {
        if (!this.menuClick && (this.state.overlayMenuActive || this.state.mobileMenuActive)) {
            this.setState({
                overlayMenuActive: false,
                mobileMenuActive: false,
            })
        } else {
            this.menuClick = false;
        }
    }

    componentDidUpdate(prevProps) {
        if (this.state.location !== window.location.hash) {
            this.setState({ ...this.state, location: window.location.hash, items: undefined })
        }

        if (this.state.items === undefined) {
            this.setState({ ...this.state, items: criarIntensBreadcrumb(window.location.hash, this.props.history) })
        }

        if (prevProps.dataAtualizacaoMenuLateral !== this.props.dataAtualizacaoMenuLateral){
            this.createMenu()
        }

        if (prevProps.possuiSuporteViaChat !== this.props.possuiSuporteViaChat) {
            carregarTomticket(this.props.possuiSuporteViaChat)
        }
    }


    componentDidMount() {
        this.setState({
            location: window.location.hash,
            items: criarIntensBreadcrumb(window.location.hash, this.props.history)
        }, () => this.createMenu())
        carregarTomticket(this.possuiSuporteChat())
    }

    possuiSuporteChat() {
        let organizacao = buscarDadosLoginLocalStorage().organizacao
        return organizacao && organizacao.plano && organizacao.plano.suporteChat
    }

    onToggleMenu(event) {
        this.menuClick = true;

        if (this.isDesktop()) {
            if (this.state.layoutMode === 'overlay') {
                this.setState({
                    overlayMenuActive: !this.state.overlayMenuActive
                });
            }
            else if (this.state.layoutMode === 'static') {
                this.setState({
                    staticMenuInactive: !this.state.staticMenuInactive
                });
            }
        }
        else {
            const mobileMenuActive = this.state.mobileMenuActive;
            this.setState({
                mobileMenuActive: !mobileMenuActive
            });
        }

        event.preventDefault();
    }

    onSidebarClick(event) {
        this.menuClick = true;
        setTimeout(() => {
            if (this.layoutMenuScroller) {
                this.layoutMenuScroller.moveBar();
            }
        }, 500);
    }

    onMenuItemClick(event) {
        if (!event.item.items) {
            this.setState({
                overlayMenuActive: false,
                mobileMenuActive: false
            })
        }
    }

    alterarRota(rota) {
        const locationAntigo = window.location.hash
        this.props.history.push(rota)
        if (locationAntigo !== window.location.hash)
            this.setState({ ...this.state, items: [] })
    }

    createMenu() {
        this.setState({ ...this.state, menu: buscarMenu(this.alterarRota) })
    }

    isDesktop() {
        return window.innerWidth > 1024;
    }

    getStyleLayoutMain() {
        const mostrarChat = this.props.possuiSuporteViaChat;
        const estaNoDashboard = this.props.location.pathname === "/";

        if (this.props.isMobile) {
            return {
                paddingTop: '55px',
                paddingRight: '0px',
                paddingBottom: mostrarChat ? '75px' : '55px',
                paddingLeft: '0px'
            }
        } else {
            return {
                paddingTop: estaNoDashboard ? '70px' : '94px',
                paddingBottom: mostrarChat ? '40px' : '0px'
            }
        }
    }

    render() {

        let sidebarClassName = classNames("layout-sidebar", { 'layout-sidebar-dark': this.state.layoutColorMode === 'dark' });

        let wrapperClass = classNames('layout-wrapper', {
            'layout-overlay': this.state.layoutMode === 'overlay',
            'layout-static': this.state.layoutMode === 'static',
            'layout-static-sidebar-inactive': this.state.staticMenuInactive && this.state.layoutMode === 'static',
            'layout-overlay-sidebar-active': this.state.overlayMenuActive && this.state.layoutMode === 'overlay',
            'layout-mobile-sidebar-active': this.state.mobileMenuActive
        });
        const { possuiInternet } = this.props
        return (
            <React.Fragment>
                <div
                    className={wrapperClass}
                    onClick={this.onWrapperClick}
                >
                    <AppTopbar onToggleMenu={this.onToggleMenu} />
                    <div style={{ height: '4px', top: possuiInternet ? '50px' : '70px', zIndex: 2, width: '100%', position: 'fixed' }}>
                        <AutoProgressBar />
                    </div>
                    <div ref={(el) => this.sidebar = el} className={sidebarClassName} onClick={this.onSidebarClick}>

                        <ScrollPanel ref={(el) => this.layoutMenuScroller = el} style={{ height: '100%' }}>
                            <div className="layout-sidebar-scroll-content" style={{ width: '250px' }}>
                                <div className="logo" />
                                <AppInlineProfile />
                                <AppMenu model={this.state.menu} onMenuItemClick={this.onMenuItemClick} />
                            </div>
                        </ScrollPanel>
                    </div>
                    {/* Esconde o breadcrumb quando está no dashboard */}
                    {this.props.location.pathname !== "/" && (
                        <div className="layout-main color-template" style={{ position: 'fixed', zIndex: '1', width: '100%', display: 'flex', padding: '50px 0px 0px 0px' }}>
                            <div hidden={this.props.isMobile} >
                                <BreadCrumb
                                    model={this.state.items}
                                    home={{ icon: 'pi pi-home', url: '/#' }}
                                    style={{
                                        background: '#fff0',
                                        border: 'none',
                                        whiteSpace: 'nowrap',
                                        overflow: 'overlay',
                                        textOverflow: 'ellipsis',
                                    }}
                                />
                            </div>
                        </div>
                    )}

                    <div className="layout-main" style={this.getStyleLayoutMain()}>
                        <Switch>
                            <PrivateRoute
                                exact
                                path="/"
                                render={props => <Dashboard {...props} />}
                                validarPermissoes={false}
                            />
                            <PrivateRoute
                                exact
                                path="/pessoas"
                                render={props => <Pessoas {...props} />}
                                recurso={recursos.PESSOAS}
                                permissao={permissoes.VISUALIZAR}
                            />
                            <PrivateRoute
                                exact
                                path={["/pessoas/cadastro", "/pessoas/cadastro/:id"]}
                                render={props => <PessoasForm {...props} />}
                                recurso={recursos.PESSOAS}
                                permissao={permissoes.VISUALIZAR}
                            />
                            <PrivateRoute
                                exact
                                path="/setores"
                                render={props => <Setores {...props} />}
                                recurso={recursos.SETORES}
                                permissao={permissoes.VISUALIZAR}
                            />
                            <PrivateRoute
                                exact
                                path={["/setores/cadastro", "/setores/cadastro/:id"]}
                                render={props => <SetoresForm {...props} />}
                                recurso={recursos.SETORES}
                                permissao={permissoes.VISUALIZAR}
                            />
                            <PrivateRoute
                                exact
                                path="/produtos"
                                render={props => <Produtos {...props} />}
                                recurso={recursos.PRODUTOS}
                                permissao={permissoes.VISUALIZAR}
                            />
                            <PrivateRoute
                                exact
                                path={["/produtos/cadastro", "/produtos/cadastro/:id"]}
                                render={props => <ProdutosForm {...props} />}
                                recurso={recursos.PRODUTOS}
                                permissao={permissoes.VISUALIZAR}
                            />
                            <PrivateRoute
                                exact
                                path="/papeis"
                                render={props => <Papeis {...props} />}
                                recurso={recursos.PAPEIS}
                                permissao={permissoes.VISUALIZAR}
                            />
                            <PrivateRoute
                                exact
                                path={["/papeis/cadastro", "/papeis/cadastro/:id"]}
                                render={props => <PapeisForm {...props} />}
                                recurso={recursos.PAPEIS}
                                permissao={permissoes.VISUALIZAR}
                            />
                            <PrivateRoute
                                exact
                                path="/usuarios"
                                render={props => <Usuarios {...props} />}
                                recurso={recursos.USUARIOS}
                                permissao={permissoes.VISUALIZAR}
                            />
                            <PrivateRoute
                                exact
                                path={["/usuarios/cadastro", "/usuarios/cadastro/:id"]}
                                render={props => <UsuariosForm {...props} />}
                                recurso={recursos.USUARIOS}
                                permissao={permissoes.VISUALIZAR}
                            />
                            <PrivateRoute
                                exact
                                path="/configuracoes_gerais"
                                render={props => <ConfiguracoesGerais {...props} />}
                                validarPermissoes={false}
                            />
                            <PrivateRoute
                                exact
                                path="/organizacoes"
                                render={props => <Organizacoes {...props} />}
                                recurso={recursos.ORGANIZACOES}
                                permissao={permissoes.VISUALIZAR}
                            />
                            <PrivateRoute
                                exact
                                path={["/organizacoes/cadastro", "/organizacoes/cadastro/:id"]}
                                render={props => <OrganizacoesForm {...props} />}
                                recurso={recursos.ORGANIZACOES}
                                permissao={permissoes.VISUALIZAR}
                            />
                            <PrivateRoute
                                exact
                                path="/minhaconta"
                                validarPermissoes={false}
                                render={props => <MinhaConta {...props} />}
                            />
                            <PrivateRoute
                                exact
                                path="/planos"
                                render={props => <Planos {...props} />}
                                recurso={recursos.PLANOS}
                                permissao={permissoes.VISUALIZAR}
                            />
                            <PrivateRoute
                                exact
                                path="/documentos"
                                render={props => <Documentos {...props} />}
                                recurso={recursos.DOCUMENTOS}
                                permissao={permissoes.VISUALIZAR}
                            />
                            <PrivateRoute
                                exact
                                path={["/documentos/cadastro", "/documentos/cadastro/:id"]}
                                render={props => <DocumentosForm {...props} />}
                                recurso={recursos.DOCUMENTOS}
                                permissao={permissoes.VISUALIZAR}
                            />
                            <PrivateRoute
                                exact
                                path={["/contas", "/contas/:id"]}
                                render={props => <Contas {...props} />}
                                recurso={recursos.FINANCAS_CONTAS}
                                permissao={permissoes.VISUALIZAR}
                            />
                            <PrivateRoute
                                exact
                                path={["/categorias"]}
                                render={props => <Categorias {...props} />}
                                recurso={recursos.FINANCAS_CATEGORIAS}
                                permissao={permissoes.VISUALIZAR}
                            />
                            <PrivateRoute
                                exact
                                path={["/movimentacoes"]}
                                render={props => <Lancamentos {...props} />}
                                recurso={recursos.FINANCAS_LANCAMENTOS}
                                permissao={permissoes.VISUALIZAR}
                            />
                            <PrivateRoute
                                exact
                                path={["/contas_receber"]}
                                render={props => <ContasReceber {...props} />}
                                recurso={recursos.FINANCAS_CONTAS_RECEBER}
                                permissao={permissoes.VISUALIZAR}
                            />
                            <PrivateRoute
                                exact
                                path={["/contas_pagar"]}
                                render={props => <ContasPagar {...props} />}
                                recurso={recursos.FINANCAS_CONTAS_PAGAR}
                                permissao={permissoes.VISUALIZAR}
                            />
                            <PrivateRoute
                                exact
                                path={["/vendas"]}
                                render={props => <VendasOrcamentos {...props} />}
                                recurso={recursos.VENDAS_VENDAS}
                                permissao={permissoes.VISUALIZAR}
                            />
                            <PrivateRoute
                                exact
                                path={["/vendas/cadastro", "/vendas/cadastro/:id"]}
                                render={props => <VendasOrcamentosForm {...props} />}
                                recurso={recursos.VENDAS_VENDAS}
                                permissao={permissoes.VISUALIZAR}
                            />
                            <PrivateRoute
                                exact
                                path={["/nfes"]}
                                render={props => <NFe {...props} />}
                                recurso={recursos.VENDAS_VENDAS}
                                permissao={permissoes.VISUALIZAR}
                            />                            
                            <PrivateRoute
                                exact
                                path={["/nfes/cadastro", "/nfes/cadastro/:id"]}
                                render={props => <NFeForm {...props} />}
                                recurso={recursos.VENDAS_VENDAS}
                                permissao={permissoes.VISUALIZAR}
                            />
                            <PrivateRoute
                                exact
                                path={["/nfces"]}
                                render={props => <NFCe {...props} />}
                                recurso={recursos.VENDAS_VENDAS}
                                permissao={permissoes.VISUALIZAR}
                            />
                            <PrivateRoute
                                exact
                                path={["/nfces/cadastro", "/nfces/cadastro/:id"]}
                                render={props => <NFCeForm {...props} />}
                                recurso={recursos.VENDAS_VENDAS}
                                permissao={permissoes.VISUALIZAR}
                            />
                            <PrivateRoute
                                exact
                                path={["/agentes_autorizados"]}
                                render={props => <AgentesAutorizados {...props} />}
                                recurso={recursos.AGENTES_AUTORIZADOS}
                                permissao={permissoes.VISUALIZAR}
                            />
                            <PrivateRoute
                                exact
                                path={["/agentes_autorizados/cadastro", "/agentes_autorizados/cadastro/:id"]}
                                render={props => <AgentesAutorizadosForm {...props} />}
                                recurso={recursos.AGENTES_AUTORIZADOS}
                                permissao={permissoes.VISUALIZAR}
                            />
                            <PrivateRoute
                                exact
                                path={["/administracao_planos"]}
                                render={props => <AdministracaoPlanos {...props} />}
                                recurso={recursos.PLANOS_ADMINISTRACAO}
                                permissao={permissoes.VISUALIZAR}
                            />
                            <PrivateRoute
                                exact
                                path={["/administracao_planos/cadastro", "/administracao_planos/cadastro/:id"]}
                                render={props => <AdministracaoPlanosForm {...props} />}
                                recurso={recursos.PLANOS_ADMINISTRACAO}
                                permissao={permissoes.VISUALIZAR}
                            />
                            <PrivateRoute
                                exact
                                path={["/movimentacoes_estoque"]}
                                render={props => <MovimentacoesEstoque {...props} />}
                                recurso={recursos.ESTOQUE_MOVIMENTACOES}
                                permissao={permissoes.VISUALIZAR}
                            />
                            <PrivateRoute
                                exact
                                path={["/ativos"]}
                                render={props => <Ativos {...props} />}
                                recurso={recursos.INVESTIDOR_ATIVOS}
                                permissao={permissoes.VISUALIZAR}
                            />
                            <PrivateRoute
                                exact
                                path={["/corretoras"]}
                                render={props => <Corretoras {...props} />}
                                recurso={recursos.INVESTIDOR_CORRETORAS}
                                permissao={permissoes.VISUALIZAR}
                            />
                            <PrivateRoute
                                path={["/negociacoes"]}
                                render={props => <Negociacoes {...props} />}
                                recurso={recursos.INVESTIDOR_NEGOCIACOES}
                                permissao={permissoes.VISUALIZAR}
                            />
                            <PrivateRoute
                                exact
                                path={["/proventos"]}
                                render={props => <Proventos {...props} />}
                                recurso={recursos.INVESTIDOR_PROVENTOS}
                                permissao={permissoes.VISUALIZAR}
                            />
                            <PrivateRoute
                                exact
                                path={["/importacoes"]}
                                render={props => <Importacoes {...props} />}
                                recurso={recursos.INVESTIDOR_IMPORTACOES}
                                permissao={permissoes.VISUALIZAR}
                            />
                            <PrivateRoute path="*" component={NoMatch} />
                        </Switch>
                    </div>

                    <div className="layout-mask"></div>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    dataAtualizacaoMenuLateral: state.menuLateral.dataAtualizacaoMenuLateral,
    dataAtualizacaoPlano: state.plano.dataAtualizacaoPlano,
    possuiSuporteViaChat: state.possuiSuporteViaChat,
    isMobile: state.dispositivo.isMobile,
    possuiInternet: state.possuiInternet,
    menuLateralFechado: state.menuLateralFechado
})

export default withRouter(connect(mapStateToProps)(InitialPage))
