import { post } from '../../../../common/requisicoes';


export async function asyncConfirmarSenha(urlConfirmacao, data, onSuccess, onError) {
    const body = {
        senha: data.senha,
        token: data.token
    }
    return await post(urlConfirmacao, body, null, onSuccess, onError)
}
