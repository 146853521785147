import React, { Component } from 'react'
import propTypes from 'prop-types'
import If from '../If';

class FormGroup extends Component {
    render() {

        const { id, hidden, children, style } = this.props;

        return (
            <If test={!hidden}>
                <div id={id} className="form-group" style={style}>
                    {children}
                </div>
            </If>
        )
    }
}
export default FormGroup

FormGroup.propTypes = {
    /** Identificador do componente */
    id: propTypes.string,
    /** Filhos do componente */
    children: propTypes.any,
    /** Esconde o componente*/
    hidden: propTypes.bool,
    /** Estilo do componente*/
    style: propTypes.object
}
