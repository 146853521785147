export const helpNFeForm = {
    indPres: 'Indicador de presença do comprador no estabelecimento comercial no momento da  operação',
    dhSaiEnt: 'Data e hora de saída da mercadoria/produto, campo preenchido automaticamente',
    vFrete: 'Valor Total do Frete',
    vSeg: 'Valor Total do Seguro',
    vOutro: 'Outras despesas acessórias',
    vDesc: 'Valor do desconto',
    ufSaidaPais: 'UF de Embarque ou de transposição de fronteira ',
    xLocExporta: 'Descrição do Local de Embarque ou de transposição de fronteira',
    xLocDespacho: 'Descrição do local de despacho'
}
