import React from 'react';
import { statusDocumento } from "../util/constantes";
import { formatarParaPesquisarTiposEnumerados } from '../../../common/manipulacaoDeString';
import format from 'date-fns/format';
import { parseISO } from 'date-fns';

export function renderStatus(row) {
    let strongColor = '#721358'
    let lightColor = '#BFA8C5'
    let texto
    switch (row.status) {
        case statusDocumento.PENDENTE:
            texto = aplicarEstiloArquivado(row.arquivado, 'Pendente');
            break;
        case statusDocumento.ARQUIVO_ADICIONADO:
            texto = aplicarEstiloArquivado(row.arquivado, 'Arquivo adicionado');
            strongColor = "#01579b"
            lightColor = "#b3e5fc"
            break;
        case statusDocumento.AGUARDANDO_ASSINATURAS:
            texto = aplicarEstiloArquivado(row.arquivado, 'Aguardando assinaturas');
            strongColor = '#c16b13'
            lightColor = '#fff7cd'
            break;
        case statusDocumento.ASSINADO:
            texto = aplicarEstiloArquivado(row.arquivado, 'Assinado');
            strongColor = "green"
            lightColor = "#dcedc8"
            break;
        case statusDocumento.REJEITADO:
            texto = aplicarEstiloArquivado(row.arquivado, 'Rejeitado');
            strongColor = "#da1f1f"
            lightColor = "#ffcdd2"
            break;
        case statusDocumento.EXPIRADO:
            texto = aplicarEstiloArquivado(row.arquivado, 'Expirado');
            strongColor = "#313131"
            lightColor = "#e6e6e6"
            break;
        default:
            texto = '';
    }
    return (
        <span style={{
            backgroundColor: lightColor,
            color: strongColor,
            fontWeight: 'bold',
            fontSize: '13px',
            borderRadius: '3px',
            padding: '3px 10px',
        }}>
            <span style={{ width: '100%', textAlign: 'center' }}>{texto}</span>
        </span>
    )
}


export function renderDataCriacao(row) {
    return row.criadoEm ? aplicarEstiloArquivado(row.arquivado, format(parseISO(row.criadoEm, new Date()), 'dd/MM/yyyy')) : ''
}

export function aplicarEstiloArquivado(arquivado, field) {
    if (arquivado) {
        return <span style={{ fontStyle: 'italic', opacity: '0.8' }}>{field}</span>
    }
    return field
}


export function montarRsqlPesquisaDocumentos(valorPesquisa, filtroAvancado, exibirArquivados) {
    filtroAvancado = filtroAvancado || "";
    const enumStatus = formatarParaPesquisarTiposEnumerados(valorPesquisa);

    let urlArquivados = ``;
    if (!exibirArquivados) {
        urlArquivados = `;arquivado==${false}`;
    }

    let urlFiltroAvancado = '';
    if (filtroAvancado) {
        urlFiltroAvancado += `;${filtroAvancado}`
    }
    let result = `?query=(identificacao=contains="*${valorPesquisa}*",pessoaNome=contains="*${valorPesquisa}*",status=contains="*${enumStatus}*")${urlArquivados}${urlFiltroAvancado}`;

    return result
}

export function buscarMensagemExcluirDocumento(documento) {
    let mensagem = 'Tem certeza que deseja excluir?'

    if (documento.status === statusDocumento.ASSINADO) {
        mensagem = `Após exluir o documento, ele só poderá ser consultado em <a href="https://documentos.gestordigital.net/" target="_blank">documentos.gestordigital.net</a> através do identificador <b>${documento.id}</b> <br/><br/>` +
            "Armazene este identificador em local seguro pois o processo de exclusão não poderá ser revertido. <br/><br/>" +
            "Deseja mesmo excluir?"
    }
    return mensagem
}