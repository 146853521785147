import React, { Component } from 'react'
import ButtonCancelar, { estadosBotaoCancelar } from '../../../components/Button/ButtonCancelar';
import Grid from '../../../components/Grid/index';
import AlterarEmail from './AlterarEmail'
import AlterarSenha from './AlterarSenha'
import Modal from '../../../components/Modal';
import Button from '../../../components/Button';
import autoBind from 'react-autobind';
import If from '../../../components/If';
import Form from '../../../components/Form';
import FormActions from '../../../components/FormActions';
import FormContent from '../../../components/FormContent';

const buttonsStyle = {
	margin: '26px 26px 20px 7px',
	width: '170px',
	height: '35px'
}

class MinhaConta extends Component {

	constructor(props) {
		super(props);

		autoBind(this);

		this.state = {
			visibleModalSenha: false,
			visibleModalEmail: false,
		};
	}

	cancelar() {
		this.props.history.goBack()
	}

	esconderModalSenha() {
		this.setState({ visibleModalSenha: !this.state.visibleModalSenha })
	}

	esconderModalEmail() {
		this.setState({ visibleModalEmail: !this.state.visibleModalEmail })
	}


	render() {
		const { visibleModalEmail, visibleModalSenha } = this.state

		return (
			<>
				<Form header="Minha conta">
					<FormActions>
						<ButtonCancelar
							onClick={this.cancelar}
							estadoBotao={estadosBotaoCancelar.VOLTAR}
						/>
					</FormActions>
					<FormContent>
						<>
							<div style={{ fontSize: '16px', margin: '10px 0px', fontWeight: 'bold' }}>
								Segurança
							</div>
							Clicando abaixo você poderá alterar o seu e-mail ou sua senha de acesso ao sistema.
							<Grid>
								<Button
									type='button'
									label='Alterar e-mail'
									icon='fa fa-envelope'
									onClick={this.esconderModalEmail}
									style={buttonsStyle}
								/>
								<Button
									type='button'
									label='Alterar senha'
									icon='fa fa-key'
									onClick={this.esconderModalSenha}
									style={buttonsStyle}
								/>
							</Grid>
						</>
					</FormContent>
				</Form>
				<If test={visibleModalEmail}>
					<Modal
						header='Alterar e-mail'
						visible={visibleModalEmail}
						onHide={this.esconderModalEmail}
					>
						<AlterarEmail onHide={this.esconderModalEmail} />
					</Modal>
				</If>

				<If test={visibleModalSenha}>
					<Modal
						header='Alterar senha'
						visible={visibleModalSenha}
						onHide={this.esconderModalSenha}
					>
						<AlterarSenha onHide={this.esconderModalSenha} />
					</Modal>
				</If>
			</>
		)
	}
}

export default MinhaConta
