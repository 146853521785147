export function validarCalculoDIFAL(dest, indFinal, organizacao) {

    if (dest && !(dest.enderDest && dest.enderDest.municipio)) {
        return false;
    }

    if (organizacao && (organizacao.endereco && organizacao.endereco.municipio) && (dest.enderDest.idEst !== organizacao.endereco.municipio.estado.id)) {
        if (organizacao && (organizacao.endereco && organizacao.endereco.municipio) && (dest.enderDest && dest.enderDest.municipio)) {
            const consumidorFinal = indFinal === "CONSUMIDOR_FINAL";
            const contribuinteIsento = dest.indIEDest === "NAO_CONTRIBUINTE";

            if (!consumidorFinal || !contribuinteIsento) {
                return false;
            }
        }
    }

    if(dest.tipo === "ESTRANGEIRO") {
        return false;
    }

    if (organizacao && (organizacao.endereco && organizacao.endereco.municipio) && (dest.enderDest.idEst === organizacao.endereco.municipio.estado.id)) {
        return false;
    }
    return true;
}