import React, { Component } from 'react';
import autoBind from "react-autobind/lib/autoBind";
import { DataTable } from 'primereact/components/datatable/DataTable';
import { Column } from 'primereact/column';
import { Paginator } from 'primereact/paginator';
import { recursos, permissoes } from '../../../../../common/constantes/autorizacao';
import { usuarioPossuiPermissao } from '../../../../../common/autenticacao';
import Button from '../../../../../components/Button';
import { connect } from 'react-redux';
import NenhumRegistroEncontrado from '../../../../../components/NenhumRegistroEncontrado';
import { Badge } from '../../../../../components/Badge';
import { format, parse } from 'date-fns/esm';
import { formatarDecimais, formatarMonetario } from '../../../../../common/mascara';

import './style/index.css'

const styleColumnOverflowElipsis = {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
}

const styleButton = {
    borderRadius: '50%',
    padding: '5px',
    width: '30px',
    height: '30px',
    margin: '2px'
};

const Colors = {
    bgDividendo: '#a2e2ff',
    textDividendo: '#145383',
    bgRendimento: '#dcedc8',
    textRendimento: '#1b5e20',
    bgJcp: '#f6fab9',
    textJcp: '#996a13'
}

class TabelaProventos extends Component {

    constructor(props) {
        super(props);
        autoBind(this)
        this.state = {
            podeExcluirProventos: usuarioPossuiPermissao(recursos.INVESTIDOR_PROVENTOS, permissoes.EXCLUIR),
            podeVisualizarProventos: usuarioPossuiPermissao(recursos.INVESTIDOR_PROVENTOS, permissoes.VISUALIZAR),
            podeEditarProventos: usuarioPossuiPermissao(recursos.INVESTIDOR_PROVENTOS, permissoes.EDITAR),
        }
    }

    renderTipo(row) {
        switch (row.tipo) {
            case 'DIVIDENDO': {
                return Badge(Colors.textDividendo, Colors.bgDividendo, "Dividendo");
            }
            case 'RENDIMENTO': {
                return Badge(Colors.textRendimento, Colors.bgRendimento, "Rendimento");
            }
            case 'JCP': {
                return Badge(Colors.textJcp, Colors.bgJcp, "JCP");
            }
            default: return row.tipo
        }
    }

    renderData(row) {
        const dataFormatada = format(parse(row.data, 'yyyy-MM-dd', new Date()), 'dd/MM/yyyy');
        return <span title={dataFormatada}>{dataFormatada}</span>
    }

    renderValorBruto(row) {
        if (row.valorBruto > 0) {
            const valorBruto = formatarMonetario(row.valorBruto);
            return <span title={valorBruto}>{valorBruto}</span>
        }
    }

    renderValorLiquido(row) {
        if (row.valorLiquido > 0) {
            const valorLiquido = formatarMonetario(row.valorLiquido);
            return <span title={valorLiquido} style={{ fontWeight: 'bold', color: '#424242' }}>{valorLiquido}</span>
        }
    }

    renderQuantidade(row) {
        const quantidadeFormatado = formatarDecimais(row.quantidade);
        return (
            <span title={quantidadeFormatado} style={{ display: 'inline-flex', justifyContent: 'center', fontWeight: 'bold' }}>
                {quantidadeFormatado}
            </span>
        );
    }

    renderAtivo(row) {
        return (
            <span title={row.ativo.codigo} style={{ fontWeight: 'bold', color: '#424242' }} >
                {row.ativo.codigo}
            </span>
        );
    }

    renderCorretora(row) {
        return (
            <span title={`${row.conta.corretora.nome} (${row.conta.codigo})`} >
                {`${row.conta.corretora.nome} - ${row.conta.codigo}`}
            </span>
        );
    }

    renderOpcoes(el) {
        const { podeExcluirProventos, podeVisualizarProventos } = this.state;
        let titleEditar = "Editar";
        let titleExcluir = "Excluir";
        let disableBtnExcluir = false;

        if (!podeVisualizarProventos) {
            titleEditar = "Você não possuir permissão para executar essa ação"
        }

        if (!podeExcluirProventos) {
            titleExcluir = "Você não possui permissão para executar essa ação"
            disableBtnExcluir = true;
        };

        return (
            <>
                <Button
                    style={styleButton}
                    className="p-button p-button-primary"
                    icon="fa fa-pencil"
                    title={titleEditar}
                    onClick={() => this.props.onEditItem(el)}
                />
                <Button
                    style={styleButton}
                    className="p-button p-button-danger"
                    icon="fa fa-trash"
                    title={titleExcluir}
                    disabled={disableBtnExcluir}
                    onClick={() => this.props.onRemoveItem(el)}
                />
            </>
        );
    }

    render() {
        const { registros, sortField, sortOrder, isMobile, rows, totalRecords, first, onPageChange } = this.props;

        const acoesStyle = !isMobile && { textAlign: 'end' };
        return (
            <>
                <DataTable
                    className="table-light"
                    responsive
                    value={registros}
                    sortField={sortField}
                    sortOrder={sortOrder}
                    onSort={this.onSort}
                    emptyMessage={<NenhumRegistroEncontrado />}
                >
                    <Column
                        field="data"
                        header="Data"
                        sortable={true}
                        body={this.renderData}
                    />
                    <Column
                        header="Ativo"
                        field="ativo.codigo"
                        sortable={true}
                        body={this.renderAtivo}
                        style={{ width: '100px', ...styleColumnOverflowElipsis }}

                    />
                    <Column
                        header="Corretora (conta)"
                        field="conta.codigo"
                        sortable={true}
                        body={this.renderCorretora}
                        style={styleColumnOverflowElipsis}
                    />
                    <Column
                        field="tipo"
                        header="Tipo"
                        sortable={true}
                        body={this.renderTipo}
                        style={styleColumnOverflowElipsis}
                    />
                    <Column
                        field="valorBruto"
                        header="Valor bruto"
                        sortable={true}
                        body={this.renderValorBruto}
                        style={styleColumnOverflowElipsis}
                    />
                    <Column
                        field="valorLiquido"
                        header="Valor líquido"
                        sortable={true}
                        body={this.renderValorLiquido}
                        style={styleColumnOverflowElipsis}
                    />
                    <Column
                        header="Ações"
                        headerClassName='coluna-acoes-end'
                        body={this.renderOpcoes}
                        style={{ width: '130px', ...acoesStyle }}
                    />
                </DataTable>
                <Paginator
                    className='paginator-light'
                    rowsPerPageOptions={[10, 25, 50]}
                    totalRecords={totalRecords}
                    rows={rows}
                    first={first}
                    onPageChange={onPageChange}
                    rightContent={<div style={{ margin: '5px' }}>{totalRecords > 0 ? `${totalRecords} registro(s) encontrado(s)` : null}</div>}
                />
            </>
        )
    }
}

const mapStateToProps = state => ({
    isMobile: state.dispositivo.isMobile,
})

export default connect(mapStateToProps)(TabelaProventos)