import { buscarDescricaoTamanhoArquivo } from "../../../../common/manipulacaoDeString"

export function converterPlanoParaFormulario(planos) {

    let result = planos.map(plano => {
        if (!plano.utilizado) {
            plano.utilizado = {}
        }
        plano.utilizado = converterUtilizadoParaFormuario(plano)
        plano.labels = buscarDescricoesPlano(plano)
        return plano
    })
    result = result.sort((a, b) => a.valor - b.valor)
    return result
}

function buscarDescricoesPlano(plano) {
    let result = []

    if (plano.ilimitado) {
        result.push('Usuários ilimitados')
        result.push('Armazenamento ilimitado')
        result.push('Documentos ilimitados')
        result.push('Contas financeiras ilimitadas')
        result.push('Vendas ilimitadas')
        result.push('Notas ilimitadas')
        result.push(`Suporte via chat`)
    } else {
        if (Number.parseInt(plano.armazenamento) === Number.parseInt("100000000000000000")) {
            result.push('Armazenamento ilimitado')
        } else {
            result.push(`${buscarDescricaoTamanhoArquivo(plano.armazenamento)} de armazenamento`)
        }

        if (plano.documentosMensais === 999999999) {
            result.push('Documentos ilimitados')
        } else {
            result.push(`${plano.documentosMensais} documentos por mês`)
        }

        if (plano.usuarios === 999999999) {
            result.push('Usuários ilimitados')
        } else {
            result.push(`${plano.usuarios} usuários`)
        }

        if (plano.contasFinanceiras === 999999999) {
            result.push('Contas financeiras ilimitadas')
        } else {
            result.push(`${plano.contasFinanceiras} contas financeiras`)
        }

        if (plano.vendasMensais === 999999999) {
            result.push('Vendas ilimitadas')
        } else {
            result.push(`${plano.vendasMensais} vendas por mês`)
        }

        if (plano.nfeMensais === 999999999) {
            result.push('Notas ilimitadas')
        } else {
            if (plano.nfeMensais > 1) {
                result.push(`${plano.nfeMensais} notas por mês`)
            }
        }
        if (plano.suporteChat) {
            result.push(`Suporte via chat`)
        }
    }
    return result
}

function converterUtilizadoParaFormuario(plano) {
    plano.utilizado.labels = []
    if (plano.utilizado) {
        if (plano.ilimitado) {
            plano.utilizado.labels.push(`${plano.utilizado.usuarios} usuário(s)`)
            plano.utilizado.labels.push(`${buscarDescricaoTamanhoArquivo(plano.utilizado.armazenamento)} de armazenamento`)
            plano.utilizado.labels.push(`${plano.utilizado.documentosMensais} documentos neste mês`)
            plano.utilizado.labels.push(`${plano.utilizado.vendasMensais} vendas neste mês`)
            plano.utilizado.labels.push(`${plano.utilizado.nfeMensais} notas neste mês`)
            plano.utilizado.labels.push(`${plano.utilizado.contasFinanceiras} conta(s) financeira(s)`)
        } else {
            if (plano.documentosMensais === 999999999) {
                plano.utilizado.labels.push(plano.utilizado.documentosMensais > 1 ? `${plano.utilizado.documentosMensais} documentos` : `${plano.utilizado.documentosMensais} documento`)
            } else {
                plano.utilizado.labels.push(`${plano.utilizado.documentosMensais} de ${plano.documentosMensais} documentos`)
            }

            if (plano.armazenamento === Number.parseInt("100000000000000000")) {
                plano.utilizado.labels.push(`${buscarDescricaoTamanhoArquivo(plano.utilizado.armazenamento)} utilizado`)
            } else {
                plano.utilizado.labels.push(`${buscarDescricaoTamanhoArquivo(plano.utilizado.armazenamento)} de ${buscarDescricaoTamanhoArquivo(plano.armazenamento)} utilizado`)
            }

            if (plano.usuarios === 999999999) {
                plano.utilizado.labels.push(plano.utilizado.usuarios > 1 ? `${plano.utilizado.usuarios} usuários` : `${plano.utilizado.usuarios} usuário`)
            } else {
                plano.utilizado.labels.push(`${plano.utilizado.usuarios} de ${plano.usuarios} usuários`)
            }

            if (plano.vendasMensais === 999999999) {
                plano.utilizado.labels.push(plano.utilizado.vendasMensais > 1 ? `${plano.utilizado.vendasMensais} vendas` : `${plano.utilizado.vendasMensais} venda`)
            } else {
                plano.utilizado.labels.push(`${plano.utilizado.vendasMensais} de ${plano.vendasMensais} vendas`)
            }

            if (plano.nfeMensais === 999999999) {
                plano.utilizado.labels.push(plano.utilizado.nfeMensais > 1 ? `${plano.utilizado.nfeMensais} notas` : `${plano.utilizado.nfeMensais} nota`)
            } else {
                plano.utilizado.labels.push(`${plano.utilizado.nfeMensais} de ${plano.nfeMensais} notas`)
            }

            if (plano.contasFinanceiras === 999999999) {
                plano.utilizado.labels.push(plano.utilizado.contasFinanceiras > 1 ? `${plano.utilizado.contasFinanceiras} contas financeiras` : `${plano.utilizado.contasFinanceiras} conta financeira`)
            } else {
                plano.utilizado.labels.push(`${plano.utilizado.contasFinanceiras} de ${plano.contasFinanceiras} contas financeiras`)
            }
        }
    }
    return plano.utilizado
}