import { asyncGetPessoaFisica, asyncGetPessoaJuridica, asyncGetPessoaEstrangeira } from '../../requests';
import { manterApenasNumeros } from '../../../../../common/mascara';

export async function jaExisteMesmoCpf(values) {
    let existeMesmoCpf = false
    
    await asyncGetPessoaFisica(manterApenasNumeros(values.cpf), (e) => {
        if (e.data.content.length > 0 && e.data.content[0].id !== values.id) {
            existeMesmoCpf = true
        }
    })
    return existeMesmoCpf
}

export async function jaExisteMesmoCnpj(values) {
    let existeMesmoCnpj = false
    
    await asyncGetPessoaJuridica(manterApenasNumeros(values.cnpj), (e) => {
        if (e.data.content.length > 0 && e.data.content[0].id !== values.id) {
            existeMesmoCnpj = true
        }
    })
    return existeMesmoCnpj
}

export async function jaExisteMesmaIdentificacao(values) {
    let existeMesmaIdentificacao = false

    await asyncGetPessoaEstrangeira(values.identificacao, (e) => {
        if (e.data.content.length > 0 && e.data.content[0].id !== values.id) {
            existeMesmaIdentificacao = true
        }
    })
    return existeMesmaIdentificacao
}

export async function validarFormulario(props) {
    if (Object.getOwnPropertyNames(props.errors).length > 1){
        return false;
    }
    
    props.handleSubmit();

    const validacao = await props.validateForm(props.values);
    return Object.getOwnPropertyNames(validacao).length === 1;
}