import React, { Component } from 'react';
import AdministracaoPlanosForm from '../Form';
import Modal from '../../../../components/Modal/index';
import If from '../../../../components/If'


class ModalPlanosAdministracao extends Component {

    render() {
        return (
            <Modal
                className={this.props.visible ? "modalDeUmAsyncSelect" : undefined}
                header='Cadastro de plano'
                visible={this.props.visible}
                onHide={this.props.onHide}
                styleModal={{ maxWidth: '1200px' }}
            >
                <If test={this.props.visible}>
                    <AdministracaoPlanosForm
                        hideModal={this.props.onHide}
                        isModal={true}
                    />
                </If>
            </Modal>
        )
    }
}

export default ModalPlanosAdministracao