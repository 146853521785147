import { Field } from 'formik';
import { Button } from 'primereact/button';
import React, { Component } from 'react';
import autoBind from 'react-autobind'
import { formatarMonetario } from '../../../../../../../common/mascara';
import Col from '../../../../../../../components/Col';
import InputField from '../../../../../../../components/Input/InputField';
import { OverlayPanel } from 'primereact/overlaypanel';
import Grid from '../../../../../../../components/Grid';
import mensagensValidacaoPadrao from '../../utils/validacoes/validacoesPadrao';
import NfeLinkVerDados from '../NfeLinkVerDados';
import If from '../../../../../../../components/If';
import ModalDadosProduto from '../ModalDadosProduto';

export default class NfeProduto extends Component {

    constructor(props) {
        super(props);

        autoBind(this);

        this.state = {
            mensagensValidacao: [],
            modalDadosProdutoVisible: false
        }
    }

    renderButton() {
        const { onAliquotasClick } = this.props;

        if (this.buscarMensagensValidacao().length === 0) {
            return (
                <span className="p-buttonset" style={{ width: '100%' }}>
                    <Button
                        className='p-button-success'
                        label="Alíquotas"
                        onClick={onAliquotasClick}
                        icon="fa fa-check" style={{ width: 'calc(100% - 40px)' }}
                    />
                    <Button
                        className='p-button-success'
                        icon="fa fa-info"
                        onClick={e => this.opTributos.show(e)}
                        style={{ marginLeft: '5px' }}
                        title="Ver dados tributários do produto"
                    />
                </span>
            )
        } else {
            return (
                <span id="btnAliquotasWarning" className="p-buttonset" style={{ width: '100%' }}>
                    <Button
                        className='p-button-warning'
                        label="Alíquotas"
                        onClick={onAliquotasClick}
                        icon="fa fa-exclamation-triangle"
                        style={{ width: 'calc(100% - 40px)' }}
                    />
                    <Button
                        className='p-button-warning'
                        icon="fa fa-lightbulb-o"
                        onClick={e => this.opInconsistencias.show(e)}
                        style={{ marginLeft: '5px' }}
                        title="Ver informações incorretas do item"
                    />
                </span>
            )
        }
    }

    buscarMensagensValidacao() {
        const { det, dest, onAliquotasClick, corrigirDadosDestinatarioClick } = this.props;

        return mensagensValidacaoPadrao(det, dest, onAliquotasClick, corrigirDadosDestinatarioClick);
    }

    render() {
        const { det, informacoesPermissoes, onChange, isTablet } = this.props;
        const { modalDadosProdutoVisible } = this.state;

        return (
            <Grid>
                <Col sm="6" md="4" lg='4' xl='4'>
                    <Field
                        colStyle={{ padding: '0px' }}
                        component={InputField}
                        label="Produto"
                        value={det.prod.cProd + ' - ' + det.prod.xProd}
                        disabled
                    />
                    <NfeLinkVerDados
                        label='Ver dados do produto'
                        onClick={() => this.setState({ modalDadosProdutoVisible: true })}
                    />
                </Col>
                <Field sm="6" md="4" lg='1' xl='1'
                    component={InputField}
                    label="Quantidade"
                    value={det.prod.qCom}
                    disabled
                />
                <Field sm="4" md="4" lg='2' xl='2'
                    component={InputField}
                    label="Valor unitário"
                    value={formatarMonetario(det.prod.vUnCom)}
                    disabled
                />
                <Field sm="4" md="6" lg='2' xl='2'
                    component={InputField}
                    label="Valor total"
                    value={formatarMonetario(det.prod.vProd)}
                    disabled
                />
                <Col sm="4" md="6" lg='3' xl='3' style={{ display: 'flex', alignItems: isTablet ? 'flex-end' : 'center' }}>
                    {this.renderButton()}
                </Col>
                <OverlayPanel ref={(el) => { this.opTributos = el }} dismissable>
                    <div style={{ marginBottom: '14px' }}>
                        <b>INFORMAÇÕES TRIBUTÁRIAS</b>
                    </div>
                    <div>Valor de ICMS: {formatarMonetario(det.imposto.icms.vICMS + det.imposto.icms.vICMSSubstituto)}</div>
                    <div>Valor de ICMS-ST: {formatarMonetario(det.imposto.icms.vICMSST + det.imposto.icms.vICMSSTRet)}</div>
                    <div>Valor da partilha de ICMS: {formatarMonetario(det.imposto.ICMSUFDest.vICMSUFDest)}</div>
                    <div style={{ marginTop: '14px' }}>
                        <b>TOTAL: {formatarMonetario(det.imposto.vTotTrib)}</b>
                    </div>
                </OverlayPanel>
                <OverlayPanel ref={(el) => { this.opInconsistencias = el }} dismissable style={{ minWidth: '320px' }}>
                    <h5 style={{ marginTop: '0px', marginBottom: '12px' }}>AJUSTES NECESSÁRIOS</h5>
                    {this.buscarMensagensValidacao().map((validacao, key) => {
                        return (
                            <div
                                key={key}
                                className="link_to"
                                title="Clique para corrigir"
                                style={{ marginBottom: '7px', color: 'blue', fontSize: '12px' }}
                                onClick={() => {
                                    this.opInconsistencias.hide();
                                    validacao.onClick();
                                }}>
                                <div>{validacao.message}</div>
                            </div>
                        )
                    })}
                </OverlayPanel>
                <If test={modalDadosProdutoVisible}>
                    <ModalDadosProduto
                        visible={modalDadosProdutoVisible}
                        values={det}
                        onChange={values => onChange(values)}
                        informacoesPermissoes={informacoesPermissoes}
                        onHide={() => this.setState({ modalDadosProdutoVisible: false })}
                    />
                </If>
            </Grid>
        )
    }
}