import React, { Component } from 'react';
import './styles/index.css';
import autoBind from 'react-autobind/lib/autoBind';
import { connect } from 'react-redux';

class AutoCursorWait extends Component {
    constructor(props) {
        super(props)
        autoBind(this)
        this.state = {
            exibirLoading: false
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.actionLoading !== this.props.actionLoading) {
            if (this.props.actionLoading && typeof this.props.actionLoading === 'string') {
                if (this.props.actionLoading.includes('OCULTAR_LOADING') && this.state.exibirLoading) {
                    this.setState({ exibirLoading: false });
                    document.removeEventListener('keypress', this.bloquearKeyPress)
                    document.removeEventListener('keydown', this.bloquearKeyPress)
                    document.removeEventListener('keyup', this.bloquearKeyPress)
                } else if (this.props.actionLoading.includes('EXIBIR_LOADING') && !this.state.exibirLoading) {
                    this.setState({ exibirLoading: true });
                    document.addEventListener('keypress', this.bloquearKeyPress)
                    document.addEventListener('keydown', this.bloquearKeyPress)
                    document.addEventListener('keyup', this.bloquearKeyPress)
                }
            }
        }
    }

    bloquearKeyPress(e){
        e.preventDefault()
    }

    render() {
        if (this.state.exibirLoading) {
            return <span id="loading-content" />
        } else {
            return null
        }
    }

}

const mapStateToProps = state => ({
    actionLoading: state.loading.actionLoading
})

export default connect(mapStateToProps)(AutoCursorWait)

