import React, { Component } from 'react';
import { ProgressBar } from 'primereact/progressbar';
import { formatarMonetario } from '../../../../../../../../../common/mascara';
import FalhaAoCarregarAsInformacoes from '../../../../../FalhaAoCarregarAsInformacoes';
import Grid from '../../../../../../../../../components/Grid';
import Col from '../../../../../../../../../components/Col';
import If from '../../../../../../../../../components/If';
import TituloCard from '../../../../../../../../../components/TituloCard';

const iconStyle = {
    maxWidth: '50px',
    display: 'flex',
    alignItems: 'center',
    padding: '0px'
}

const cardStyle = {
    padding: '7px',
    borderRadius: '5px',
    backgroundColor: 'white',
    boxShadow: '#00000033 0px 1px 3px 0px, #00000024 0px 1px 1px 0px, #0000001f 0px 2px 1px -1px',
    minHeight: '100%'
}

const dashboardContent = {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
}

const valorStyle = {
    fontSize: '21px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    paddingBottom: '5px'
}

const evolucaoStyle = {
    fontWeight: 'bold',
    fontSize: '12px',
    borderRadius: '3px',
    padding: '3px 5px',
    marginRight: '5px'
}

const nowRapStyle = {
    whiteSpace: 'nowrap',
    overflow: "hidden",
    textOverflow: "ellipsis"
}

export default class CardResultado extends Component {

    render() {
        const { loading, error, title, helpMessage } = this.props

        if (loading || error) {
            return (
                <div style={cardStyle}>
                    <TituloCard
                        title={title}
                        helpMessage={helpMessage}
                    />
                    <div style={dashboardContent}>
                        {loading && <ProgressBar mode="indeterminate" style={{ height: '3px', width: '100%', margin: '10px 0px' }} />}
                        {error && <FalhaAoCarregarAsInformacoes />}
                    </div>
                </div>
            )
        } else {
            const { valor, percentualEvolucao, colorValor, colorPercentualEvolucao, backgroundPercentualEvolucao, icon } = this.props;

            const valorFormatado = formatarMonetario(valor || 0)
            return (
                <div style={cardStyle}>
                    <TituloCard
                        title={title}
                        helpMessage={helpMessage}
                    />
                    <Grid>
                        <Col style={iconStyle}>
                            {icon}
                        </Col>
                        <Col style={{ maxWidth: 'calc(100% - 50px)', paddingLeft: '0px' }}>
                            <Grid>
                                <Col style={{ ...valorStyle, color: colorValor }}   >
                                    <If test={percentualEvolucao}>
                                        <div
                                            title="Percentual de evolução em relação ao período anterior"
                                            style={{
                                                ...evolucaoStyle,
                                                color: colorPercentualEvolucao,
                                                background: backgroundPercentualEvolucao
                                            }}
                                        > {`${percentualEvolucao}%`}</div>
                                    </If>
                                    <span
                                        style={nowRapStyle}
                                        title={valorFormatado}
                                    >
                                        {valorFormatado}
                                    </span>
                                </Col>
                            </Grid>
                        </Col>
                    </Grid>
                </div>
            );
        }
    }
}
