import React from 'react';

const styleStatus = {
    height: '38px',
    borderRadius: '25px',
    fontWeight: "bold",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '14px'
}

const styleName = {
    textAlign: 'center',
    fontWeight: 'bold',
    padding: '2px 0px'
}

export default function NfceStatus({ backgroundColor, color, name, description }) {
        return (
            <div title={description} style={{ ...styleStatus, backgroundColor, color }}>
                <div style={styleName}>
                    {name}
                </div>
            </div>
        );
}
