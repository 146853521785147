export function buscarIconeHistorico(descricaoOperacao) {
    if (descricaoOperacao.includes('Criou o documento')) {
        return 'fa fa-plus'
    }
    if (descricaoOperacao.includes('Atualizou o documento')) {
        return 'fa fa-edit'
    }
    if (descricaoOperacao.includes('Baixou o documento')) {
        return 'fa fa-download'
    }
    if (descricaoOperacao.includes('Arquivou o documento')) {
        return 'fa fa-archive'
    }
    if (descricaoOperacao.includes('Desarquivou o documento')) {
        return 'fa fa-archive'
    }
    if (descricaoOperacao.includes('Gerou um link compartilhável do documento')) {
        return 'fa fa-link'
    }
    if (descricaoOperacao.includes('Enviou o documento por e-mail para')) {
        return 'fa fa-paper-plane'
    }
    if (descricaoOperacao.includes('Adicionou o arquivo')) {
        return 'fa fa-upload'
    }
    if (descricaoOperacao.includes('Removeu o arquivo')) {
        return 'fa fa-trash'
    }
    if (descricaoOperacao.includes('Enviou para assinatura para todos os signatários')) {
        return 'fa fa-paper-plane'
    }
    if (descricaoOperacao.includes('Reenviou o documento por e-mail para')) {
        return 'fa fa-paper-plane'
    }
    if (descricaoOperacao.includes('Visualizou o documento')) {
        return 'fa fa-eye'
    }
    if(descricaoOperacao.includes('Efetuou a assinatura eletrônica')) {
        return 'fa fa-check'
    }
    if(descricaoOperacao.includes('Rejeitou a assinatura eletrônica')) {
        return 'fa fa-ban'
    }
}