import { manterApenasNumeros } from "../../../../common/mascara";

function converterPessoaParaFormulario(pessoa) {
    return {
        id: pessoa.id,
        nome: pessoa.nome,
        situacao: pessoa.situacao,
        tipo: pessoa.tipo,
        observacao: pessoa.observacao,
        enderecos: pessoa.enderecos,
        emails: pessoa.emails,
        telefones: pessoa.telefones,
        contatos: pessoa.contatos,
        inscricaoEstadual: pessoa.inscricaoEstadual,
        indicadorInscricaoEstadual: pessoa.indicadorInscricaoEstadual,
        consumidorFinal: pessoa.consumidorFinal,
        //fisica
        cpf: pessoa.pessoaFisica ? pessoa.pessoaFisica.cpf : null,
        rg: pessoa.pessoaFisica ? pessoa.pessoaFisica.rg : null,
        genero: pessoa.pessoaFisica ? pessoa.pessoaFisica.genero : null,
        nascimento: pessoa.pessoaFisica ? pessoa.pessoaFisica.nascimento : null,
        //juridica
        cnpj: pessoa.pessoaJuridica ? pessoa.pessoaJuridica.cnpj : null,
        razaoSocial: pessoa.pessoaJuridica ? pessoa.pessoaJuridica.razaoSocial : null,
        simplesNacional: pessoa.pessoaJuridica ? pessoa.pessoaJuridica.simplesNacional : null,
        inscricaoSuframa: pessoa.pessoaJuridica ? pessoa.pessoaJuridica.inscricaoSuframa : null,
        inscricaoMunicipal: pessoa.pessoaJuridica ? pessoa.pessoaJuridica.inscricaoMunicipal : null,
        //estrangeira
        identificacao: pessoa.pessoaEstrangeira ? pessoa.pessoaEstrangeira.identificacao : null
    }
}

function converterPessoaFisicaParaApi(pessoa) {
    if (pessoa.tipo === 'FISICA') {
        return {
            cpf: manterApenasNumeros(pessoa.cpf),
            rg: pessoa.rg,
            genero: pessoa.genero,
            nascimento: pessoa.nascimento,
        }
    } else {
        return null
    }
}

function converterPessoaEstrangeiraParaApi(pessoa) {
    if (pessoa.tipo === 'ESTRANGEIRO') {
        return {
            identificacao: pessoa.identificacao
        }
    } else {
        return null
    }
}

function converterPessoaJuridicaParaApi(pessoa) {
    if (pessoa.tipo === 'JURIDICA') {
        return {
            cnpj: manterApenasNumeros(pessoa.cnpj),
            razaoSocial: pessoa.razaoSocial,
            simplesNacional: pessoa.simplesNacional,
            inscricaoSuframa: pessoa.inscricaoSuframa,
            inscricaoMunicipal: pessoa.inscricaoMunicipal
        }
    } else {
        return null
    }
}

function converterPessoaParaApi(pessoa) {
    return {
        id: pessoa.id,
        nome: pessoa.nome,
        situacao: pessoa.situacao,
        tipo: pessoa.tipo,
        observacao: pessoa.observacao,
        enderecos: pessoa.enderecos,
        emails: pessoa.emails,
        consumidorFinal: pessoa.consumidorFinal,
        inscricaoEstadual: pessoa.inscricaoEstadual,
        indicadorInscricaoEstadual: pessoa.indicadorInscricaoEstadual,
        telefones: removerMascaraTelefones(pessoa.telefones),
        contatos: removerMascaraContatos(pessoa.contatos),
        pessoaFisica: converterPessoaFisicaParaApi(pessoa),
        pessoaJuridica: converterPessoaJuridicaParaApi(pessoa),
        pessoaEstrangeira: converterPessoaEstrangeiraParaApi(pessoa),
    }
}

function removerMascaraTelefones(telefones) {
    if (telefones) {
        return telefones.map(telefone => {
            return {
                ...telefone,
                numero: manterApenasNumeros(telefone.numero)
            }
        });
    }
    return null;
}

function removerMascaraContatos(contatos) {
    if (contatos) {
        return contatos.map(contato => {
            return {
                ...contato,
                telefone: manterApenasNumeros(contato.telefone)
            }
        });
    }
    return null;
}

export { converterPessoaParaApi, converterPessoaParaFormulario }