import { estadosCadastro } from "../../constantes/autorizacao";

export function buscarDisabledDeAcordoComAsPermissoes(podeInserir, podeEditar, estadoCadastro, disabledOriginal) {
    if (estadoCadastro === undefined)
        return disabledOriginal

    if (estadoCadastro === estadosCadastro.INCLUSAO && !podeInserir)
        return true

    if (estadoCadastro === estadosCadastro.EDICAO && !podeEditar)
        return true

    return disabledOriginal
}

export function buscarHiddenDeAcordoComAsPermissoes(podeVisualizar, hiddenOriginal) {
    if (podeVisualizar === false)
        return true
    if (hiddenOriginal !== undefined)
        return hiddenOriginal
    return false
}

export function buscarTitleCampoDeAcordoComAsPermissoes(podeVisualizar, podeInserir, podeEditar, estadoCadastro, titleOriginal) {
    if (estadoCadastro === undefined)
        return titleOriginal

    if (estadoCadastro === estadosCadastro.INCLUSAO && podeInserir === false)
        return "Você não possui permissão para inserir"

    if (estadoCadastro === estadosCadastro.EDICAO && podeEditar === false)
        return "Você não possui permissão para editar"

    return titleOriginal
}

export function buscarTitleBotaoDeAcordoComAsPermissoes(podeInserir, podeEditar, estadoCadastro, titleOriginal) {
    if (estadoCadastro === undefined)
        return titleOriginal
    if ((estadoCadastro === estadosCadastro.INCLUSAO && !podeInserir) ||
        (estadoCadastro === estadosCadastro.EDICAO && !podeEditar))
        return "Você não possui permissão ou o módulo habilitado para executar essa ação"

    return titleOriginal
}

export function buscarMensagemEstadoCadastro(podeInserir, podeEditar, estadoCadastro, dirty) {
    let mensagem

    if (podeInserir === false && estadoCadastro === estadosCadastro.INCLUSAO)
        mensagem = 'Você não possui permissão para inserir!'
    else if (podeEditar === false && estadoCadastro === estadosCadastro.EDICAO)
        mensagem = 'Você não possui permissão para editar!'
    else if (estadoCadastro === estadosCadastro.EDICAO)
        mensagem = dirty ? 'Editando' : ''
    else if (estadoCadastro === estadosCadastro.INCLUSAO)
        mensagem = dirty ? 'Inserindo' : ''
    return mensagem
}
