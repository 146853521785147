import { services } from "../../../../common/constantes/api";
import { del, get, post, put, exibirToast } from "../../../../common/requisicoes";

export async function asyncGetPesquisaSetor(url, onSuccess, onError) {
    return await get(url, null, onSuccess, onError, true)
}

export async function asyncGetSetor(idSetor, onSuccess, onError) {
    await get(`${services.GESTOR}/v1/setores/${idSetor}`, null, onSuccess,onError);
}

export async function asyncDeleteSetor(idSetor, onSuccess, onError) {
    await del(`${services.GESTOR}/v1/setores/${idSetor}`, null, exibirToast(onSuccess, "Setor excluído com sucesso"),onError);
}

export async function asyncCreateSetor(setor, onSuccess, onError) {
    await post(`${services.GESTOR}/v1/setores`, setor, null, exibirToast(onSuccess, "Setor criado com sucesso"),onError);
}

export async function asyncUpdateSetor(setor, onSuccess, onError) {
    await put(`${services.GESTOR}/v1/setores/${setor.id}`, setor, null, exibirToast(onSuccess, "Setor atualizado com sucesso"),onError);
}

export async function asyncGetSetorMesmoNome(nome, onSuccess, onError) {
    await get(`${services.GESTOR}/v1/setores?query=nome=="${nome}"`, null, onSuccess,onError);
}
