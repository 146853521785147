import React, { Component } from 'react';
import * as Yup from "yup";
import autoBind from 'react-autobind';
import Modal from '../../../../../components/Modal';
import { withRouter } from 'react-router';
import { usuarioPossuiPermissao } from '../../../../../common/autenticacao';
import { recursos, permissoes, estadosCadastro } from '../../../../../common/constantes/autorizacao';
import AutoProgressBar from '../../../../../components/Loading/AutoProgressBar';
import { withFormik, Field } from 'formik';
import { converterContaReceberParaFormulario, converterContaReceberParaApi } from './util/contaReceberConverter';
import { mensagensDeValidacao } from '../../../../../common/constantes/mensagens';
import ButtonNovo, { estadosBotaoNovo } from '../../../../../components/Button/ButtonNovo';
import ButtonSalvar from '../../../../../components/Button/ButtonSalvar';
import ButtonCancelar, { estadosBotaoCancelar } from '../../../../../components/Button/ButtonCancelar';
import ButtonExcluir from '../../../../../components/Button/ButtonExcluir';
import InputField from '../../../../../components/Input/InputField';
import { helpMessage } from './util/constantes';
import Grid from '../../../../../components/Grid';
import { services } from '../../../../../common/constantes/api';
import SingleSelectCategoria, { tiposCategoria } from '../../../../../components/Select/SingleSelectCategoria';
import InputDate from '../../../../../components/Input/InputDate';
import InputMoney from '../../../../../components/Input/InputMoney';
import Dropdown from '../../../../../components/Select/Dropdown';
import TabelaRecebimentos from './components/TabelaRecebimentos';
import { asyncCreateContaReceber, asyncUpdateContaReceber, asyncDeleteContaReceber, buscarCategoriaFavoritaReceita, asyncEditarContaReceberProximasEmAberto, asyncEditarContaReceberTodasEmAberto } from '../../requests';
import SingleSelectPessoa from '../../../../../components/Select/SingleSelectPessoa';
import { confirm } from '../../../../../components/Toast';
import ModalRecebimento from './components/ModalRecebimento';
import { gerarUUID } from '../../../../../common/manipulacaoDeString';
import { removerElemento, copiarObjeto } from '../../../../../common/array';
import { Accordion, AccordionTab } from 'primereact/accordion';
import TextArea from '../../../../../components/TextArea';
import Form from '../../../../../components/Form';
import FormActions from '../../../../../components/FormActions';
import FormContent from '../../../../../components/FormContent';
import Checkbox from '../../../../../components/Input/Checkbox';
import Fieldset from '../../../../../components/FieldSet';
import If from '../../../../../components/If';
import ModalExclusaoContasReceber, { tiposExclusoesContasReceber } from '../ModalExclusaoContasReceber';
import { asyncDeleteContaReceberProximasEmAberto, asyncDeleteContaReceberTodasEmAberto, buscarContaFavoritaReceita } from '../../requests';
import ModalEdicaoContasReceber, { tiposEdicoesContasReceber } from '../ModalEdicaoContasReceber';
import { exibirConfirmacaoMesmoNumeroDocumento, jaExisteContaReceberComMesmoNumeroDocumentoOrigem, saoIguais } from './util/utils';
import SingleSelectConta from '../../../../../components/Select/SingleSelectConta';
import { formatISO, isValid, parseISO } from 'date-fns';
import { Button } from 'primereact/button';
import { connect } from 'react-redux';
import Col from '../../../../../components/Col';
import { formatarMonetario } from '../../../../../common/mascara';
import { RadioButton } from 'primereact/radiobutton';
import InputDouble from '../../../../../components/Input/InputDouble';
import montarMensagemExclusaoTitulos from '../../../utils/functions';

export const periodicidadeDropDown = [
    { label: 'Dias', value: 'DIARIA' },
    { label: 'Semanas', value: 'SEMANAL' },
    { label: 'Meses', value: 'MENSAL' },
    { label: 'Trimestres', value: 'TRIMESTRAL' },
    { label: 'Semestres', value: 'SEMESTRAL' },
    { label: 'Anos', value: 'ANUAL' },
];

export const tipoContaReceber = {
    UNICA: 'UNICA',
    VALOR_FIXO: 'VALOR_FIXO',
    VALOR_PARCELADO: 'VALOR_PARCELADO'
};

const styleDescricaoRecebimento = {
    margin: '0',
    width: '100%',
    textAlign: 'end',
    padding: '0.5em'
}


const initialValue = {
    id: null,
    descricao: null,
    categoria: null,
    tipo: 'UNICA',
    emissao: formatISO(new Date()),
    vencimento: "",
    valor: 0,
    ocorrencia: 'MENSAL',
    origem: 'CONTA_RECEBER',
    quantidadeRepeticoes: 2,
    recebido: false,
    valorRecebido: null,
    dataRecebido: formatISO(new Date()),
    contaRecebido: null,

    recebimentos: [],

    pessoa: null,
    competencia: null,
    dataDocumento: null,
    numeroDocumento: null,
    observacao: null,

    activeIndex: null,
};

class ModalContaReceber extends Component {

    constructor(props) {
        super(props);
        autoBind(this);

        this.state = {
            podeInserir: usuarioPossuiPermissao(recursos.FINANCAS_CONTAS_RECEBER, permissoes.INSERIR),
            podeVisualizarFinancasCategorias: usuarioPossuiPermissao(recursos.FINANCAS_CATEGORIAS, permissoes.VISUALIZAR),
            podeVisualizarVenda: usuarioPossuiPermissao(recursos.VENDAS_VENDAS, permissoes.VISUALIZAR),
            podeEditar: usuarioPossuiPermissao(recursos.FINANCAS_CONTAS_RECEBER, permissoes.EDITAR),
            podeExcluir: usuarioPossuiPermissao(recursos.FINANCAS_CONTAS_RECEBER, permissoes.EXCLUIR),
            exibirModalRecebimento: false,
            registroSelecionado: null,
            exibirModalExclusao: false,
            exibirModalConfirmacaoEdicao: false
        }
    }

    componentDidMount() {
        if (!this.props.values.id) {
            this.atribuirValoresIniciais()
        }
    }

    novo() {
        this.props.onNovoClick && this.props.onNovoClick()
        this.props.resetForm({ values: initialValue });
        this.setState({
            registroSelecionado: null
        })
        this.atribuirValoresIniciais()
    }

    atribuirValoresIniciais() {
        const { values, valorPadraoDataVencimento } = this.props
        let categoria = values.categoria
        let contaRecebido = values.contaRecebido
        let vencimento = values.vencimento

        if (valorPadraoDataVencimento) {
            vencimento = formatISO(valorPadraoDataVencimento);
        }

        buscarCategoriaFavoritaReceita(({ data }) => {
            if (data.totalElements > 0) {
                categoria = {
                    label: data.content[0].nome,
                    value: data.content[0].id,
                    registro: data.content[0]
                }
            }
            if (this.props.dirty) {
                this.props.setFieldValue('categoria', categoria)
                this.props.setFieldValue('vencimento', vencimento)
            } else {
                this.props.resetForm({ values: { ...this.props.values, categoria, vencimento } })
            }
        })

        buscarContaFavoritaReceita(({ data }) => {
            if (data.totalElements > 0) {
                contaRecebido = {
                    label: data.content[0].nome,
                    value: data.content[0].id,
                    registro: data.content[0]
                }
            }
            if (this.props.dirty) {
                this.props.setFieldValue('contaRecebido', contaRecebido)
            } else {
                this.props.resetForm({ values: { ...this.props.values, contaRecebido } })
            }
        })
    }

    alterouApenasRepeticoes() {
        let { values, initialValues } = this.props

        values = { ...values, recebimentos: [] }
        initialValues = { ...initialValues, recebimentos: [] }

        return saoIguais(values, initialValues)
    }


    async salvar(e, novoOnSuccess) {
        const { values, errors, initialValues } = this.props;

        await this.props.handleSubmit();
        await this.props.validateForm();

        if (this.props.isValid) {
            if (values) {
                if (values.numeroDocumento && values.numeroDocumento !== initialValues.numeroDocumento && await jaExisteContaReceberComMesmoNumeroDocumentoOrigem(values.id, values.numeroDocumento)) {
                    exibirConfirmacaoMesmoNumeroDocumento(() => {
                        this.salvarOuAtualizar(values, novoOnSuccess)
                    })
                } else {
                    this.salvarOuAtualizar(values, novoOnSuccess)
                }
            }
        } else {
            this.setState({ activeIndex: errors.activeIndex })
        }
    }

    async salvarOuAtualizar(values, novoOnSuccess) {
        if (values.id) {
            if (this.props.values.itemRepeticao && !this.alterouApenasRepeticoes()) {
                this.setState({ exibirModalConfirmacaoEdicao: true })
            } else {
                await asyncUpdateContaReceber(converterContaReceberParaApi(values), () => {
                    if (novoOnSuccess) {
                        novoOnSuccess();
                    } else {
                        this.props.onHide(values);
                    }
                })
            }
        } else {
            await asyncCreateContaReceber(converterContaReceberParaApi(values), () => {
                if (novoOnSuccess) {
                    novoOnSuccess();
                } else {
                    this.props.onHide(values);
                }
            })
        }
    }

    excluir() {
        const { id, itemRepeticao, status, origem } = this.props.values
        if (itemRepeticao) {
            this.setState({ exibirModalExclusao: true })
        } else {
            confirm('Confirmação', montarMensagemExclusaoTitulos(status, origem), async () => {
                await asyncDeleteContaReceber(id, () => this.props.onHide())
            });
        }
    }

    cancelar() {
        if (this.props.dirty) {
            this.props.resetForm({ values: this.props.initialValues });
        } else {
            this.props.onHide(true);
        }
    }

    novoRecebimento() {
        this.setState({
            exibirModalRecebimento: true,
            registroSelecionado: null
        })
    }

    onHideRecebimento() {
        this.setState({ exibirModalRecebimento: false })
    }

    salvarRecebimento(recebimento) {
        let novoRecebimento = { ...recebimento }

        if (!recebimento.id && !recebimento.idTemporario) {

            novoRecebimento.idTemporario = gerarUUID();

            this.inserirRegistroNaLista(novoRecebimento)
        } else {
            this.atualizarRegistroNaLista(novoRecebimento)
        }

        this.setState({
            registroSelecionado: novoRecebimento
        })
        this.onHideRecebimento();
    }

    inserirRegistroNaLista(dadosFormulario) {
        let recebimentos = copiarObjeto(this.props.values.recebimentos)
        recebimentos && recebimentos.push(dadosFormulario);

        this.props.setFieldValue('recebimentos', recebimentos)
    }

    atualizarRegistroNaLista(dadosFormulario) {
        const { recebimentos } = this.props.values;
        const result = recebimentos && recebimentos.map(item => {

            if (item.idTemporario && item.idTemporario === dadosFormulario.idTemporario) {
                return dadosFormulario
            } else if (item.id && item.id === dadosFormulario.id) {
                return dadosFormulario
            }
            return item;
        })
        this.props.setFieldValue('recebimentos', result)
    }

    editarRecebimento(dadosFormulario) {
        this.setState({
            registroSelecionado: dadosFormulario,
            exibirModalRecebimento: true
        })
    }

    calcularValorAReceber() {
        const { values } = this.props;
        let valorRecebido = 0;

        values.recebimentos && values.recebimentos.forEach(recebimento => {
            valorRecebido += recebimento.valor
        });

        return values.valor - valorRecebido
    }

    buscarTituloRecebimentos(valorAReceber, valorTotal) {
        const totalRecebimentos = valorTotal - valorAReceber
        if (!totalRecebimentos) {
            return "Recebimentos"
        } else {
            return <span> Recebimentos (recebidos <b>{formatarMonetario(totalRecebimentos)}</b> de  {formatarMonetario(valorTotal || 0)} ) </span>
        }
    }

    excluirRecebimento(dadosFormulario) {
        this.props.setFieldValue('recebimentos', removerElemento(this.props.values.recebimentos, dadosFormulario));
        this.setState({ registroSelecionado: null })
    }

    onHideModalExclusao(tipoExclusao) {
        if (tipoExclusao) {
            const { values } = this.props
            if (tipoExclusao === tiposExclusoesContasReceber.APENAS_SELECIONADA) {
                asyncDeleteContaReceber(values.id, () => {
                    this.props.onHide()
                })
            } else if (tipoExclusao === tiposExclusoesContasReceber.PROXIMAS_EM_ABERTO) {
                asyncDeleteContaReceberProximasEmAberto(values.id, () => {
                    this.props.onHide()
                })
            } else {
                asyncDeleteContaReceberTodasEmAberto(values.id, () => {
                    this.props.onHide()
                })
            }
        } else {
            this.setState({ exibirModalExclusao: false, values: null })
        }
    }

    onHideModalEdicao(tipoEdicao) {
        if (tipoEdicao) {
            const { values } = this.props
            if (tipoEdicao === tiposEdicoesContasReceber.APENAS_SELECIONADA) {
                asyncUpdateContaReceber(converterContaReceberParaApi(values), () => {
                    this.props.onHide()
                })
            } else if (tipoEdicao === tiposEdicoesContasReceber.PROXIMAS_EM_ABERTO) {
                asyncEditarContaReceberProximasEmAberto(converterContaReceberParaApi(values), () => {
                    this.props.onHide()
                })
            } else {
                asyncEditarContaReceberTodasEmAberto(converterContaReceberParaApi(values), () => {
                    this.props.onHide()
                })
            }
        } else {
            this.setState({ exibirModalConfirmacaoEdicao: false, values: null })
        }
    }

    renderModalHeader(values) {
        if (values.itemRepeticao && values.quantidadeRepeticoes) {
            const tituloRepeticao = `${values.itemRepeticao}/${values.quantidadeRepeticoes}`
            return values.id ? `Editar conta a receber ${tituloRepeticao}` : `Nova conta a receber ${tituloRepeticao}`;
        }

        return values.id ? `Editar conta a receber` : `Nova conta a receber`;
    }

    onHideModal() {
        if (this.props.dirty) {
            confirm('Confirmação', 'A conta a receber possui informações não salvas, tem certeza que deseja sair sem salvar?', () => {
                this.props.onHide(true)
            })
        } else {
            this.props.onHide()
        }
    }

    buscarValor() {
        const { tipo, valor, quantidadeRepeticoes } = this.props.values
        if (tipo === tipoContaReceber.VALOR_PARCELADO) {
            return Math.trunc((valor / quantidadeRepeticoes) * 100) / 100
        } else {
            return valor
        }
    }

    desabilitarExcluir() {
        const { values } = this.props
        
        if(values.vendaId || values.origem === 'VENDA') {
            return true
        }
        return false
    }

    render() {
        const { visible, dirty, values, isModal, setFieldValue, isMobile } = this.props;
        const { exibirModalRecebimento, podeInserir, podeEditar, podeExcluir, podeVisualizarVenda, podeVisualizarFinancasCategorias,
            registroSelecionado, activeIndex, exibirModalExclusao, exibirModalConfirmacaoEdicao } = this.state;

        const informacoesPermissoes = {
            podeInserir: podeInserir,
            podeEditar: podeEditar,
            podeExcluir: podeExcluir,
            estadoCadastro: values.id ? estadosCadastro.EDICAO : estadosCadastro.INCLUSAO
        };

        const desabilitarCampos = (informacoesPermissoes.estadoCadastro === estadosCadastro.EDICAO && !podeEditar);
        const isEdicao = values.id !== null;

        const valorAReceber = this.calcularValorAReceber();
        const tituloRecebimentos = this.buscarTituloRecebimentos(valorAReceber, values.valor)

        const estadoBotaoNovo = dirty ? estadosBotaoNovo.SALVAR_E_NOVO : estadosBotaoNovo.NOVO;
        const onClickNovo = dirty ? (e) => this.salvar(e, this.novo) : this.novo;


        return (
            <Modal
                header={this.renderModalHeader(values)}
                visible={visible}
                onHide={this.onHideModal}
                styleModal={{ padding: isMobile ? "5px" : '20px' }}
            >
                <AutoProgressBar />
                <Form>
                    <FormActions>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div>
                                <ButtonCancelar
                                    {...informacoesPermissoes}
                                    hidden={isModal && values.id && !dirty}
                                    estadoBotao={dirty ? estadosBotaoCancelar.CANCELAR : estadosBotaoCancelar.VOLTAR}
                                    onClick={this.cancelar}
                                />
                                <ButtonSalvar
                                    {...informacoesPermissoes}
                                    disabled={!dirty}
                                    onClick={this.salvar}
                                />
                                <ButtonNovo
                                    onClick={onClickNovo}
                                    hidden={!dirty && !values.id}
                                    estadoBotao={estadoBotaoNovo}
                                    {...informacoesPermissoes}
                                />
                                <ButtonExcluir
                                    hidden={!values.id || isModal}
                                    {...informacoesPermissoes}
                                    disabled={this.desabilitarExcluir()}
                                    onClick={this.excluir}
                                    title={values.vendaId ? "Não é possível excluir uma conta a receber de uma venda" : "Excluir"}
                                />
                            </div>
                            <If test={values.vendaId && podeVisualizarVenda}>
                                <div>
                                    <Button
                                        className='p-button-success'
                                        style={{ marginTop: '5px' }}
                                        label={!isMobile ? "Visualizar venda" : "Venda"}
                                        icon="fa fa-handshake-o"
                                        onClick={() => this.props.history.push("/vendas/cadastro/" + values.vendaId)}
                                    />
                                </div>
                            </If>
                        </div>
                    </FormActions>
                    <FormContent>
                        <Grid>
                            <Field sm="12" md="6" lg="4" xl="4"
                                component={InputMoney}
                                autofocus
                                label='Valor'
                                name="valor"
                                obrigatorio
                                size={15}
                                helpMessage={helpMessage.valor}
                                onChange={e => setFieldValue('valor', e.target.value)}
                                // await setFieldValue('valorRecebido', this.buscarValor())
                                value={values.valor}
                                disabled={values.origem === 'VENDA'}
                                title={values.origem === 'VENDA' ? "Não é possível editar o valor de uma conta a receber derivada de uma venda" : null}
                                {...informacoesPermissoes}
                            />

                            <Field sm="12" md="6" lg="4" xl="4"
                                component={InputDate}
                                label="Emissão"
                                name="emissao"
                                obrigatorio
                                helpMessage={helpMessage.emissao}
                                onChange={e => setFieldValue('emissao', e.target.value)}
                                value={values.emissao}
                                {...informacoesPermissoes}
                            />

                            <Field sm="12" md="6" lg="4" xl="4"
                                component={InputDate}
                                label="Vencimento"
                                name="vencimento"
                                obrigatorio
                                helpMessage={helpMessage.vencimento}
                                onChange={e => setFieldValue('vencimento', e.target.value)}
                                value={values.vencimento}
                                {...informacoesPermissoes}
                            />
                            <Field sm="12" md="6" lg='4' xl='4'
                                component={InputField}
                                label='Descrição'
                                name="descricao"
                                helpMessage={helpMessage.descricao}
                                size={255}
                                {...informacoesPermissoes}
                            />

                            <Field sm="12" md="6" lg="4" xl="4"
                                name="pessoa"
                                label="Pessoa (devedor)"
                                component={SingleSelectPessoa}
                                value={values.pessoa}
                                helpMessage={helpMessage.pessoa}
                                onChange={e => setFieldValue('pessoa', e)}
                                url={`${services.GESTOR}/v1/contas_receber/relacoes/pessoas`}
                                {...informacoesPermissoes}
                            />

                            <Field sm="12" md="6" lg='4' xl='4'
                                name="categoria"
                                label="Categoria"
                                helpMessage={helpMessage.categoria}
                                tipoCategoria={tiposCategoria.RECEITA}
                                component={SingleSelectCategoria}
                                value={values.categoria}
                                onChange={e => setFieldValue('categoria', e)}
                                url={`${services.GESTOR}/v1/contas_receber/relacoes/categorias/receitas`}
                                disabledButton={!podeVisualizarFinancasCategorias}
                                {...informacoesPermissoes}
                            />

                            <If test={!isEdicao}>
                                <Col md='4' lg='3'>
                                    <RadioButton
                                        inputId="rb-tipo-valor-unico"
                                        name="tipo"
                                        value={tipoContaReceber.UNICA}
                                        onChange={e => this.props.setFieldValue('tipo', e.value)}
                                        checked={values.tipo === tipoContaReceber.UNICA}
                                    />
                                    <label htmlFor="rb-tipo-valor-unico" className="p-radiobutton-label">É uma conta única</label>
                                </Col>
                                <Col md='4' lg='3'>
                                    <RadioButton
                                        inputId="rb-tipo-valor-fixo"
                                        name="tipo"
                                        value={tipoContaReceber.VALOR_FIXO}
                                        onChange={e => this.props.setFieldValue('tipo', e.value)}
                                        checked={values.tipo === tipoContaReceber.VALOR_FIXO}
                                    />
                                    <label htmlFor="rb-tipo-valor-fixo" className="p-radiobutton-label">É uma conta recorrente</label>
                                </Col>
                                <Col md='4' lg='3'>
                                    <RadioButton
                                        inputId="rb-tipo-valor-parcelado"
                                        name="tipo"
                                        value={tipoContaReceber.VALOR_PARCELADO}
                                        onChange={e => this.props.setFieldValue('tipo', e.value)}
                                        checked={values.tipo === tipoContaReceber.VALOR_PARCELADO}
                                    />
                                    <label htmlFor="rb-tipo-valor-parcelado" className="p-radiobutton-label">É uma conta parcelada</label>
                                </Col>

                                <If test={values.tipo !== tipoContaReceber.UNICA}>
                                    <Field col='6' sm="6" md="6" lg="6" xl="6"
                                        component={InputDouble}
                                        decimalScale={0}
                                        disabled={informacoesPermissoes.estadoCadastro === estadosCadastro.EDICAO}
                                        type="number"
                                        keyfilter='pint'
                                        label={values.tipo === tipoContaReceber.VALOR_FIXO ? 'Repetir por' : 'Parcelado em'}
                                        name="quantidadeRepeticoes"
                                        value={values.quantidadeRepeticoes}
                                        helpMessage={values.tipo === tipoContaReceber.VALOR_FIXO ? helpMessage.quantidadeRepeticoesValorFixo : helpMessage.quantidadeRepeticoesParcelas}
                                        size={2}
                                        onChange={async (e) => {
                                            await setFieldValue('quantidadeRepeticoes', e.target.value)
                                            await setFieldValue('valorRecebido', this.buscarValor())
                                        }}
                                        {...informacoesPermissoes}
                                    />
                                    <Field col='6' sm="6" md="6" lg='6' xl='6'
                                        component={Dropdown}
                                        colStyle={{ paddingTop: '26px' }}
                                        name="ocorrencia"
                                        autoFocus
                                        disabled={informacoesPermissoes.estadoCadastro === estadosCadastro.EDICAO}
                                        value={values.ocorrencia}
                                        showClear={false}
                                        isSearchableOnMobile={false}
                                        options={periodicidadeDropDown}
                                        onChange={e => setFieldValue('ocorrencia', e.value || null)}
                                        {...informacoesPermissoes}
                                    />
                                    <p style={styleDescricaoRecebimento}>
                                        Serão geradas {values.quantidadeRepeticoes} contas a receber de <b>{formatarMonetario(this.buscarValor())}</b>
                                    </p>
                                    <p style={{ ...styleDescricaoRecebimento, fontSize: '12px' }}>
                                        Em caso de divisão não exata, a sobra será somada à última parcela.
                                    </p>
                                </If>
                            </If>
                            <Field sm="12" md="12" lg="12" xl="12"
                                colStyle={{ marginTop: '10px' }}
                                component={Checkbox}
                                label='Valor já recebido'
                                name="recebido"
                                hidden={isEdicao}
                                onChange={() => {
                                    setFieldValue('recebido', !values.recebido)
                                    if (!values.recebido) {
                                        this.props.setFieldValue('valorRecebido', this.buscarValor())
                                    }
                                }}
                                checked={values.recebido}
                                {...informacoesPermissoes}
                            />

                            <If test={values.recebido}>
                                <Field sm="12" md="6" lg="4" xl="4"
                                    component={InputMoney}
                                    label="Valor recebido"
                                    name="valorRecebido"
                                    obrigatorio
                                    value={values.valorRecebido}
                                    onChange={e => setFieldValue('valorRecebido', e.target.value)}
                                    size={12}
                                    {...informacoesPermissoes}
                                />
                                <Field sm="12" md="6" lg="4" xl="4"
                                    component={InputDate}
                                    label="Data do recebimento"
                                    name="dataRecebido"
                                    obrigatorio
                                    onChange={e => setFieldValue('dataRecebido', e.target.value)}
                                    value={values.dataRecebido}

                                    {...informacoesPermissoes}
                                />
                                <Field sm="12" md="6" lg='4' xl='4'
                                    name="contaRecebido"
                                    label="Conta"
                                    obrigatorio
                                    component={SingleSelectConta}
                                    value={values.contaRecebido}
                                    helpMessage={helpMessage.conta}
                                    onChange={e => setFieldValue('contaRecebido', e)}
                                    url={`${services.GESTOR}/v1/contas_receber/relacoes/contas`}
                                />
                            </If>

                            <If test={isEdicao}>
                                <Fieldset legend={tituloRecebimentos} style={{ width: '100%', marginRight: '4px' }} className='fieldset-light'>
                                    <Grid>
                                        <ButtonNovo
                                            label="Novo recebimento"
                                            podeInserir={!desabilitarCampos}
                                            onClick={this.novoRecebimento}
                                        />
                                        <If test={exibirModalRecebimento}>
                                            <ModalRecebimento
                                                isModal={true}
                                                visible={true}
                                                registroSelecionado={registroSelecionado}
                                                onSalvar={this.salvarRecebimento}
                                                onHide={this.onHideRecebimento}
                                                valorAReceber={valorAReceber}
                                            />
                                        </If>
                                        <TabelaRecebimentos
                                            podeEditar={!desabilitarCampos}
                                            podeExcluir={!desabilitarCampos}
                                            registros={values.recebimentos}
                                            onEditar={this.editarRecebimento}
                                            onExcluir={this.excluirRecebimento}
                                        />
                                    </Grid>
                                </Fieldset>
                            </If>

                            <Accordion
                                className="tab-accordion-lite"
                                style={{ marginTop: '10px' }}
                                activeIndex={activeIndex}
                                onTabChange={(e) => this.setState({ activeIndex: e.index })}
                                multiple={true}
                            >
                                <AccordionTab header="Informações complementares">
                                    <Grid>
                                        <Field sm="12" md="6" lg="4" xl="4"
                                            component={InputDate}
                                            label="Competência"
                                            name="competencia"
                                            helpMessage={helpMessage.competencia}
                                            onChange={e => setFieldValue('competencia', e.target.value)}
                                            value={values.competencia}
                                            {...informacoesPermissoes}
                                        />


                                        <Field sm="12" md="6" lg='4' xl='4'
                                            component={InputField}
                                            label='Número do documento de origem'
                                            name="numeroDocumento"
                                            helpMessage={helpMessage.numeroDocumento}
                                            size={255}
                                            {...informacoesPermissoes}
                                        />

                                        <Field sm="12" md="6" lg="4" xl="4"
                                            component={InputDate}
                                            label="Data do documento de origem"
                                            name="dataDocumento"
                                            helpMessage={helpMessage.dataDocumento}
                                            onChange={e => setFieldValue('dataDocumento', e.target.value)}
                                            value={values.dataDocumento}
                                            {...informacoesPermissoes}
                                        />

                                        <Field sm="12"
                                            component={TextArea}
                                            placeholder="Escreva sua observação aqui"
                                            label='Observação'
                                            name="observacao"
                                            helpMessage={helpMessage.observacao}
                                            maxLength={4096}
                                            onChange={e => setFieldValue('observacao', e.target.value)}
                                            {...informacoesPermissoes}
                                        />
                                    </Grid>
                                </AccordionTab>
                            </Accordion>
                        </Grid>
                    </FormContent>
                </Form>
                <If test={exibirModalExclusao}>
                    <ModalExclusaoContasReceber
                        visible={exibirModalExclusao}
                        onHide={this.onHideModalExclusao}
                    />
                </If>
                <If test={exibirModalConfirmacaoEdicao}>
                    <ModalEdicaoContasReceber
                        visible={exibirModalConfirmacaoEdicao}
                        onHide={this.onHideModalEdicao}
                    />
                </If>
            </Modal>
        )
    }
}

ModalContaReceber = withFormik({
    validateOnChange: false,
    validateOnBlur: false,

    mapPropsToValues(props) {
        if (props.registroSelecionado) {
            return converterContaReceberParaFormulario(props.registroSelecionado)
        } else {
            return initialValue;
        }
    },

    validate(values) {
        let errors = {};

        if (values.valor <= 0) {
            errors.valor = "O valor deve ser maior que zero"
        }

        if (values.emissao && !isValid(parseISO(values.emissao))) {
            errors.emissao = mensagensDeValidacao.DATA_INVALIDA
        }

        if (values.vencimento && !isValid(parseISO(values.vencimento))) {
            errors.vencimento = mensagensDeValidacao.DATA_INVALIDA
        }

        if (values.competencia && !isValid(parseISO(values.competencia))) {
            errors.competencia = mensagensDeValidacao.DATA_INVALIDA
            errors.activeIndex = [0];
        }

        if (values.dataDocumento && !isValid(parseISO(values.dataDocumento))) {
            errors.dataDocumento = mensagensDeValidacao.DATA_INVALIDA
            errors.activeIndex = [0];
        }
        if (values.tipo !== tipoContaReceber.UNICA) {
            if (values.quantidadeRepeticoes < 2) {
                errors.quantidadeRepeticoes = "Não pode ser inferior a 2";
            }
        }

        if (values.observacao && values.observacao.length > 4096) {
            errors.activeIndex = [0];
        }

        if (values.recebido) {

            if (values.valorRecebido <= 0) {
                errors.valorRecebido = "O valor deve ser maior que zero";
            }
            if (!isValid(parseISO(values.dataRecebido))) {
                errors.dataRecebido = mensagensDeValidacao.DATA_INVALIDA
            }

            if (!values.contaRecebido) {
                errors.contaRecebido = mensagensDeValidacao.OBRIGATORIO
            }
        }

        return errors
    },

    validationSchema: Yup.object().shape({
        vencimento: Yup.string().nullable().required(mensagensDeValidacao.DATA_INVALIDA),
        emissao: Yup.string().nullable().required(mensagensDeValidacao.DATA_INVALIDA),
        valor: Yup.string().required(mensagensDeValidacao.OBRIGATORIO),
        numeroDocumento: Yup.string().max(50, 'O campo observação não pode ter mais que 50 caracteres.').nullable(),
        observacao: Yup.string().max(4096, 'O campo observação não pode ter mais que 4096 caracteres.').nullable()
    }),
    handleSubmit: () => { }
})(ModalContaReceber);

ModalContaReceber = withRouter(ModalContaReceber)

const mapStateToProps = state => ({
    isMobile: state.dispositivo.isMobile,
})

export default connect(mapStateToProps)(ModalContaReceber);
