import React, { Component } from 'react';
import autoBind from 'react-autobind';
import SingleSelect from '../SingleSelect';
import propTypes from 'prop-types'
import { services } from '../../../common/constantes/api';

class SingleSelectMunicipio extends Component {
    constructor(props) {
        super(props);

        autoBind(this);

        this.state = {
            visible: false,
        }
    }

    buscarUrlPesquisa(pesquisa, page) {
        return `${services.GESTOR}/v1/municipios?query=nome=contains="*${pesquisa}*",estado.sigla=contains="*${pesquisa}*"&page=${page}&size=50&sort=nome`;
    }

    montarLabel(row) {
        if (row && row.estado) {
            return row.nome + ' - ' + row.estado.sigla;
        }
         else {
             return row.nome;
         }
    }

    render() {
        return (
            <React.Fragment>
                <SingleSelect
                    buscarUrlPesquisa={this.buscarUrlPesquisa}
                    montarLabel={this.montarLabel}
                    label={this.props.label}
                    {...this.props}
                    onChange={this.props.onChange}
                    esconderBotao
                />
            </React.Fragment>
        );
    }
}

SingleSelectMunicipio.defaultProps = {
    label: 'Municipio'
}

SingleSelectMunicipio.propTypes = {
    service: propTypes.string
}

export default SingleSelectMunicipio;
