import React, { Component } from 'react';
import { Field, withFormik } from 'formik';
import Grid from '../../../../../components/Grid';
import { withRouter } from 'react-router';
import ButtonSalvar, { estadosBotaoSalvar } from '../../../../../components/Button/ButtonSalvar';
import ButtonCancelar, { estadosBotaoCancelar } from '../../../../../components/Button/ButtonCancelar';
import {
    asyncGetNFe,
    asyncDeleteNFe,
    asyncUpdateNFe,
    asyncRecalcularNfe,
    asyncTransmitirNFe,
    imprimirCorrecao,
    imprimirDANFE,
    imprimirXMLCorrecao,
    imprimirXMLNfe
} from '../requests';
import { recursos, permissoes, estadosCadastro } from '../../../../../common/constantes/autorizacao';
import { confirmarExclusao } from '../../../../util/exclusaoDeRegistros';
import Prompt from '../../../../../components/Route/Prompt';
import ButtonExcluir from '../../../../../components/Button/ButtonExcluir';
import Button from '../../../../../components/Button';
import autoBind from "react-autobind";
import { validarUUID } from '../../../../../common/manipulacaoDeString';
import { atualizarUrl, metodosAtualizarUrl, voltarParaAPesquisa, validarFormulario } from '../../../../util';
import { buscarDadosLoginLocalStorage, usuarioPossuiPermissao } from '../../../../../common/autenticacao';
import Form from '../../../../../components/Form';
import FormActions from '../../../../../components/FormActions';
import FormContent from '../../../../../components/FormContent';
import { converterNFeParaFormulario } from './utils/nfeConverterParaFormulario';
import NfeStatus from './components/NfeStatus';
import Col from '../../../../../components/Col';
import { IndPres, infoStatusNfe, Status, TipoDestinatario } from '../util/constantes';
import NfeSerieNumero from './components/NfeSerieNumero';
import InputField from '../../../../../components/Input/InputField';
import NfeLinkVerDados from './components/NfeLinkVerDados';
import ModalDadosDestinatario from './components/ModalDadosDestinatario';
import If from '../../../../../components/If';
import Dropdown from '../../../../../components/Select/Dropdown';
import { optionsIndPres } from '../../../utils/constantes';
import { helpNFeForm } from './help';
import { Fieldset } from 'primereact/fieldset';
import NfeProduto from './components/NfeProduto'
import ModalAliquotasProduto from './components/ModalAliquotasProduto';
import { copiarObjeto } from '../../../../../common/array';
import NfeTransporte from './components/NfeTransporte';
import TextArea from '../../../../../components/TextArea';
import Checkbox from '../../../../../components/Input/Checkbox';
import InputMoney from '../../../../../components/Input/InputMoney';
import NfeTabelaTotalizadores from './components/NfeTabelaTotalizadores';
import { converterNFeParaApi } from './utils/nfeConverterParaApi';
import debounce from 'lodash.debounce';
import { connect } from 'react-redux';
import NfeCardValidacao from './components/NfeCardValidacao';
import { baixarArquivo } from '../../../../../common/relatorios';
import { Menu } from 'primereact/menu';
import { copiarParaAreaTransferencia } from '../../../../../common/areaDeTransferencia';
import { notify, ToastTypes } from '../../../../../components/Toast';
import ModalCartaCorrecao from './components/ModalCartaCorrecao';
import ModalLoadingTransmissao from './components/ModalLoadingTransmissao';
import { BiRefresh } from 'react-icons/bi';
import ModalCancelamento from './components/ModalCancelamento';
import ModalHistoricoCorrecoes from './components/ModalHistoricoCorrecoes';
import { format, getMonth, getYear, parseISO } from 'date-fns';
import ButtonNovo from '../../../../../components/Button/ButtonNovo';
import { FaDownload } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import NotasHistorico from './components/NotasHistorico';
import SingleSelectEstado from '../../../../../components/Select/SingleSelectEstado';
import ModalInformacoesComplementares from './components/ModalInformacoesComplementares';
import { validarTransmissao } from './utils/validacoes/validacoesTransmissao';
import Message from '../../../../../components/Message';
import { services } from '../../../../../common/constantes/api';
import { csosns } from '../../../utils/constantes'
import SingleSelectIntermediador from '../../../../../components/Select/SingleSelectIntermediador';
import { validarCalculoDIFAL } from './utils/validacoes/validacoesDIFAL';
import { validarCalculoST } from './utils/validacoes/validacoesST';
import InputMask from '../../../../../components/Input/InputMask'
import { buscarAliquotaSimples } from '../../NFe/requests'
import ModalAliquotaSimples from '../../../../configuracao/ConfiguracoesGerais/components/ModalAliquotaSimples';

const styleBotaoImprimir = {
    padding: '0px 10px',
    background: '#fff0',
    fontWeight: 'bold',
    fontSize: '15px',
    alignItems: 'center',
    border: 'none'
};

const initialValue = {
    id: '',
    dest: {},
    det: [],
    pag: [],
    transp: {
        vol: {},
        transporta: {},
        veicTransp: {}
    },
    infAdic: {},
    total: {
        ICMSTot: {}
    },
    exporta: {},
    infIntermed: {},
}

const optionsModFrete = [
    { label: 'Por conta do emitente', value: 'EMITENTE' },
    { label: 'Por conta do destinatário', value: 'DESTINATARIO' },
    { label: 'Por conta de terceiros', value: 'TERCEIROS' },
    { label: 'Sem frete', value: 'SEM_FRETE' },
]

const CADASTROURL = '/nfes/cadastro';
const PESQUISAURL = '/nfes';

class NFeForm extends Component {

    constructor(props) {
        super(props);

        autoBind(this);

        this.onChangeICMSTot = debounce(this.onChangeICMSTot.bind(this), 350)

        this.state = {
            podeInserir: usuarioPossuiPermissao(recursos.VENDAS_VENDAS, permissoes.INSERIR),
            podeEditar: usuarioPossuiPermissao(recursos.VENDAS_VENDAS, permissoes.EDITAR),
            podeExcluir: usuarioPossuiPermissao(recursos.VENDAS_VENDAS, permissoes.EXCLUIR),

            modalDestinatarioVisible: false,
            modalAliquotasProdutoVisible: false,
            modalCartaCorrecao: false,
            modalHistoricoCorrecoes: false,
            modalHistoricoVisible: false,
            modalCancelamento: false,
            modalInfoCplVisible: false,
            modalConfigurarAliquotaSimplesVisible: false,

            exibirLoadingTransmissao: false,
            detSelecionado: null,

            credencial: buscarDadosLoginLocalStorage(),

            validacoes: [],

            ultimaAtualizacaoModalAliquota: null,

            aliquotaDoSimplesEstaAtualizada: false
        }
    }

    componentDidMount() {
        const id = this.props.match.params.id;
        if (validarUUID(id)) {
            this.asyncSelectRegistro(id);
        }
    }

    buscarEstadoCadastro() {
        return this.props.values.id ? estadosCadastro.EDICAO : estadosCadastro.INCLUSAO
    }

    novo() {
        atualizarUrl(this.props.history, CADASTROURL, null, metodosAtualizarUrl.POP)
        this.props.resetForm({ values: initialValue })
    }

    excluir() {
        confirmarExclusao(this.asyncDeleteRegistro);
    }

    async salvar() {
        this.props.handleSubmit();
        if (await validarFormulario(this.props)) {
            let dadosFormulario = converterNFeParaApi({ ...this.props.values, erros: validarTransmissao(this.props) });
            this.asyncUpdateRegistro(dadosFormulario);
        }
    }

    cancelar() {
        if (this.props.dirty) {
            this.props.resetForm({ values: this.props.initialValues })
        } else {
            this.props.history.goBack();
        }
    }

    async asyncDeleteRegistro() {
        await asyncDeleteNFe(this.props.values.id, () => {
            voltarParaAPesquisa(this.props.history, PESQUISAURL);
        });
    }

    async asyncUpdateRegistro(dadosFormulario) {
        await asyncUpdateNFe(dadosFormulario, () => {
            this.asyncSelectRegistro(this.props.values.id)
        })
    }

    async asyncSelectRegistro(idNfe) {
        await asyncGetNFe(idNfe, ({ data: nfe }) => {
            this.props.resetForm({ values: converterNFeParaFormulario(nfe, this.state.credencial) });
        }, () => {
            this.novo();
        })
    }

    onChangeDet(values) {
        let det = copiarObjeto(this.props.values.det)
        det = det.map(item => {
            if (item.id === values.id) {
                return values
            }
            return item
        })
        this.props.setFieldValue('det', det)
    }

    onChangeCorrecoes(correcoes) {
        let values = copiarObjeto(this.props.values);

        this.props.resetForm({ values: { ...values, correcoes } });
    }

    onChangePag(index, values) {
        let pag = copiarObjeto(this.props.values.pag)
        pag[index] = values
        this.props.setFieldValue('pag', pag)
    }

    onChangeTransp(field, value) {
        let transp = copiarObjeto(this.props.values.transp)
        transp[field] = value
        this.props.setFieldValue('transp', transp)
    }

    onChangeInfAdic(field, value) {
        let infAdic = copiarObjeto(this.props.values.infAdic)
        infAdic[field] = value
        this.props.setFieldValue('infAdic', infAdic)
    }

    onChangeExporta(field, value) {
        let exporta = copiarObjeto(this.props.values.exporta)
        exporta[field] = value
        this.props.setFieldValue('exporta', exporta)
    }

    onChangeInfIntermed(field, value) {
        let infIntermed = copiarObjeto(this.props.values.infIntermed)
        if (field === 'pessoa') {
            infIntermed.pessoa = value
            infIntermed.CNPJ = value && value.registro.cnpj
            infIntermed.idCadIntTran = value && value.registro.nome
            this.props.setFieldValue('infIntermed', infIntermed)
        } else {
            infIntermed[field] = value
            this.props.setFieldValue('infIntermed', infIntermed)
        }
    }

    onChangeIntermediadorCadastrado(e) {
        let infIntermed = copiarObjeto(this.props.values.infIntermed)
        infIntermed.pessoa = null
        infIntermed.CNPJ = null
        infIntermed.idCadIntTran = null
        infIntermed.intermediadorCadastrado = e.checked
        this.props.setFieldValue('infIntermed', infIntermed)
    }

    onChangeIndPres(e) {
        const value = e.value;

        let infIntermed = copiarObjeto(this.props.values.infIntermed)
        infIntermed.pessoa = null
        infIntermed.CNPJ = null
        infIntermed.idCadIntTran = null

        if (value !== IndPres.OPERACAO_PELA_INTERNET || value !== IndPres.OPERACAO_POR_TELEATENDIMENTO) {
            this.props.setFieldValue('indPres', e.value)
            this.props.setFieldValue('infIntermed', infIntermed)
        } else {
            this.props.setFieldValue('indPres', e.value)
        }

    }

    onChangeICMSTot(field, value) {
        let total = copiarObjeto(this.props.values.total)
        total.ICMSTot[field] = value

        this.props.setFieldValue('total', total)

        if (this.props.dirty) {
            this.buscarTotaisNfe();
        }
    }

    montarObjetoParaCalcularNfe(values) {
        return {
            det: values.det,
            total: values.total,
            idDest: values.idDest,
            indFinal: values.indFinal,
            emit: {
                idOrganizacao: buscarDadosLoginLocalStorage().organizacao.id
            }
        }
    }

    buscarTotaisNfe() {
        const { values } = this.props
        asyncRecalcularNfe(this.montarObjetoParaCalcularNfe(values), ({ data: totais }) => {
            if (this.props.dirty) {
                let det = this.montarDetComTotaisAtualizados(totais.det)
                this.props.setFieldValue('total', totais.total)
                this.props.setFieldValue('det', det)
                this.props.setFieldValue('msgCreditoICMS', totais.msgCreditoICMS)
            }
        })
    }

    montarDetComTotaisAtualizados(detTotais) {
        let det = copiarObjeto(this.props.values.det)
        let detsAtualizados = [];

        for (let i = 0; i < det.length; i++) {
            const detAntigo = det[i];

            for (let j = 0; j < detTotais.length; j++) {
                const detNovo = detTotais[j];

                if (detAntigo.id === detNovo.id) {
                    detsAtualizados.push({
                        ...detAntigo,
                        imposto: {
                            ICMSUFDest: {
                                ...detAntigo.imposto.ICMSUFDest,
                                ...detNovo.imposto.ICMSUFDest,
                            },
                            icms: {
                                ...detAntigo.imposto.icms,
                                ...detNovo.imposto.icms,
                            },
                            vTotTrib: detNovo.imposto.vTotTrib,
                        },
                        prod: {
                            ...detAntigo.prod,
                            ...detNovo.prod
                        }
                    });
                }
            }
        }
        return detsAtualizados;
    }

    async transmitirNfe() {
        if (await this.validarAliquotaSimples()) {
            this.setState({ exibirLoadingTransmissao: true }, () => {
                asyncTransmitirNFe(this.props.values.id, () => {
                    this.setState({ exibirLoadingTransmissao: false }, () => {
                        this.asyncSelectRegistro(this.props.values.id)
                    })
                }, () => {
                    this.setState({ exibirLoadingTransmissao: false }, () => {
                        this.asyncSelectRegistro(this.props.values.id)
                    })
                })
            })
        } else {
            this.setState({ modalConfigurarAliquotaSimplesVisible: true })
        }
    }

    usouCsosnQueGeraDireitoACredito() {
        let produtos = this.props.values.det
        const csons = produtos.filter(produto => {
            if ([csosns.CSOSN_101, csosns.CSOSN_201, csosns.CSOSN_900].includes(produto.imposto.icms.CSOSN)) {
                return true
            }
            return false
        })
        return csons.length > 0 ? true : false
    }

    async aliquotaDoSimplesEstaAtualizada() {
        await buscarAliquotaSimples(({ data }) => {
            const anoAtual = getYear(new Date())
            const mesAtual = getMonth(new Date()) + 1
            if (data.anoVigencia === anoAtual && data.mesVigencia === mesAtual) {
                this.setState({ aliquotaDoSimplesEstaAtualizada: true })
            }
        })
    }

    async validarAliquotaSimples() {
        if (this.usouCsosnQueGeraDireitoACredito()) {
            await this.aliquotaDoSimplesEstaAtualizada()
            return this.state.aliquotaDoSimplesEstaAtualizada
        }
        return true
    }

    montarHeader() {
        const { status } = this.props.values;
        const { isMobile } = this.props;

        if (status === Status.TRANSMITIDA || status === Status.CORRIGIDA) {
            return (
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <span>{isMobile ? "NF-e" : "Nota fiscal de venda"}</span>
                    <span style={{ display: 'flex', alignItems: 'center', fontSize: '16px', cursor: 'pointer' }}>
                        <div
                            style={styleBotaoImprimir}
                            onClick={() => this.imprimirDANFE()}
                        >
                            <FaDownload size="15px" style={!isMobile && { marginRight: '5px' }} />
                            <span>DANF-e</span>
                        </div>
                        <div
                            style={styleBotaoImprimir}
                            onClick={() => this.baixarXMLNotaFiscal()}
                        >
                            <FaDownload size="15px" style={!isMobile && { marginRight: '5px' }} />
                            <span>XML</span>
                        </div>
                    </span>
                </div>
            )
        } else if (status === Status.AGUARDANDO_AUTORIZACAO) {
            return (
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <span>{isMobile ? "NF-e" : "Nota fiscal de venda"}</span>
                    <span
                        title="Atualizar informações da nota"
                        style={{ cursor: 'pointer' }}
                        onClick={() => this.asyncSelectRegistro(this.props.values.id)}
                    >
                        <BiRefresh />
                    </span>
                </div>
            )
        } else {
            return (
                <div>
                    <span>{isMobile ? "Nota fiscal" : "Nota fiscal de venda"}</span>
                </div>
            )
        }
    }

    imprimirDANFE() {
        const nomeArquivo = (this.props.values.status === Status.CANCELADA) ? "Cancelamento.pdf" : "Nota fiscal.pdf";

        imprimirDANFE(this.props.values.id, ({ data: file }) => {
            baixarArquivo(file, nomeArquivo);
        });
    }

    baixarXMLCorrecao() {
        imprimirXMLCorrecao(this.props.values.id, ({ data: file }) => {
            baixarArquivo(file, "XML da correção", "application/xml");
        });
    }

    imprimirCorrecao() {
        imprimirCorrecao(this.props.values.id, ({ data: file }) => {
            baixarArquivo(file, "Correção da nota fiscal");
        });
    }

    baixarXMLNotaFiscal() {
        const nomeArquivo = (this.props.values.status === Status.CANCELADA) ? "XML Cancelamento" : (this.props.values.chNfe || "XML Nota fiscal");

        imprimirXMLNfe(this.props.values.id, ({ data: file }) => {
            baixarArquivo(file, nomeArquivo, "application/xml");
        });
    }

    buscarItensOpcoes() {
        const { values } = this.props;
        const podeEditar = this.state.podeEditar;
        const notaFiscalTransmitida = (values.status === Status.TRANSMITIDA);
        const notaFiscalCorrigida = (values.status === Status.CORRIGIDA);
        const notaFiscalCancelada = (values.status === Status.CANCELADA);
        const notaFiscalRejeitada = (values.status === Status.REJEITADA);
        const notaFiscalAguardando = (values.status === Status.AGUARDANDO_AUTORIZACAO);

        let res = [];

        const baixarXMLCancelamento = {
            label: "Baixar XML do cancelamento",
            icon: "fa fa-download",
            command: () => this.baixarXMLNotaFiscal()
        };
        const baixarCancelamento = {
            label: "Baixar PDF do cancelamento",
            icon: "fa fa-download",
            command: () => this.imprimirDANFE()
        };

        const copiarChaveNFE = {
            label: "Copiar chave da NF-e",
            icon: "fa fa-copy",
            command: () => {
                copiarParaAreaTransferencia(values.chNfe || "", () => {
                    notify("Chave de acesso copiada para área de transferência", ToastTypes.SUCCESS)
                })
            }
        };

        const copiarPlugnotasId = {
            label: "Copiar identificador da NF-e",
            icon: "fa fa-copy",
            command: () => {
                copiarParaAreaTransferencia(values.plugnotasId || "", () => {
                    notify("Identificador da nota copiado para área de transferência", ToastTypes.SUCCESS)
                })
            }
        };

        const verHistoricoNfe = {
            label: "Histórico",
            icon: "fa fa-history",
            command: () => this.setState({ modalHistoricoVisible: true })
        };

        const corrigirNotaFiscal = {
            label: "Emitir carta de correção",
            icon: "fa fa-edit",
            command: () => this.setState({ modalCartaCorrecao: true })
        };
        const cancelarNotaFiscal = {
            label: "Cancelar nota fiscal",
            icon: "fa fa-ban",
            command: () => this.setState({ modalCancelamento: true })
        };
        const verMotivoCancelamento = {
            label: "Ver motivo do cancelamento",
            icon: "fa fa-eye",
            command: () => this.setState({ modalCancelamento: true })
        };
        const corrigirNovamente = {
            label: "Emitir nova carta de correção",
            icon: "fa fa-edit",
            command: () => this.setState({ modalCartaCorrecao: true })
        };
        const verCorrecoes = {
            label: "Ver correções",
            icon: "fa fa-eye",
            command: () => this.setState({ modalHistoricoCorrecoes: true })
        };

        if (values.id && notaFiscalRejeitada) {
            res.push(copiarPlugnotasId)
        }

        if (values.id && notaFiscalAguardando) {
            res.push(copiarPlugnotasId)
        }

        if (values.id && notaFiscalTransmitida) {
            if (podeEditar) {
                res.push(corrigirNotaFiscal);
                res.push(cancelarNotaFiscal);
            }
            res.push(copiarChaveNFE);
        }

        if (values.id && notaFiscalCorrigida) {
            if (podeEditar) {
                res.push(cancelarNotaFiscal);
                res.push(corrigirNovamente);
            }
            res.push(verCorrecoes);
        }

        if (values.id && notaFiscalCancelada) {
            res.push(verMotivoCancelamento);
            res.push(baixarCancelamento);
            res.push(baixarXMLCancelamento);
        }

        if (values.id) {
            res.push(verHistoricoNfe);
        }

        return res;
    }

    getValueDestinatario() {
        const { values } = this.props;

        if (values.dest) {
            const tipoDest = values.dest.tipo;

            if (tipoDest === TipoDestinatario.FISICA) {
                return values.dest.xNome + (values.dest.CPF ? ` - ${values.dest.CPF}` : "");
            }
            if (tipoDest === TipoDestinatario.JURIDICA) {
                return values.dest.xNome + (values.dest.CNPJ ? ` - ${values.dest.CNPJ}` : "");
            }
            if (tipoDest === TipoDestinatario.ESTRANGEIRO) {
                return values.dest.xNome + (values.dest.idEstrangeiro ? ` - ${values.dest.idEstrangeiro}` : "");
            }
        }
        return "Nenhum destinatário informado";
    }

    buscarCreditoICMS() {
        const { values } = this.props;
        let somaCreditoICMS = 0;

        values.det.forEach(det => {
            somaCreditoICMS += det.imposto.icms.vCredICMSSN;
        });

        return {
            valor: somaCreditoICMS,
            aliquota: values.det[0].imposto.icms.pCredSN
        };
    }

    organizacaoPodeTransmitirNfe() {
        const { credencial } = this.state;

        if (credencial.organizacao && credencial.organizacao.fiscalNfe) {
            if (!credencial.organizacao.certificado || !credencial.organizacao.fiscalNfe.serieNfe) {
                return false;
            }
        }
        return true;
    }

    buscarMensagemOrganizacaoPodeTransmitirNfe() {
        return (
            <span>
                {this.getTitleButtonTransmitir()}
                <div>
                    <b
                        style={{ cursor: 'pointer' }}
                        onClick={() => this.props.history.push({ pathname: "/configuracoes_gerais", state: { configurarSerieNfe: true } })}
                    >
                        Clique aqui para configurar
                    </b>
                </div>
            </span>
        )
    }

    getTitleButtonTransmitir() {
        const { credencial } = this.state;

        if (credencial.organizacao && credencial.organizacao.fiscalNfe) {
            if (!credencial.organizacao.certificado) {
                return "Organização sem certificado digital configurado para transmissão de NF-e"
            } else if (!credencial.organizacao.fiscalNfe.serieNfe) {
                return "Série da nota fiscal não configurada"
            }
        }
        return "";
    }

    buscarMensagemNFeAguardandoTransmissao() {
        return (
            <span>
                Nota fiscal em processamento
                <div>
                    A sefaz está processando a nota fiscal. Por favor, aguarde alguns instantes e atualize a nota
                </div>
                <b
                    style={{ cursor: 'pointer' }}
                    onClick={() => this.asyncSelectRegistro(this.props.values.id)}
                >
                    Clique aqui para atualizar
                </b>
            </span>
        )
    }

    onHideModalAliquotasProduto(ultimaAtualizacaoModalAliquota) {
        if (ultimaAtualizacaoModalAliquota && (ultimaAtualizacaoModalAliquota !== this.state.ultimaAtualizacaoModalAliquota)) {
            this.setState({ ultimaAtualizacaoModalAliquota, modalAliquotasProdutoVisible: false, detSelecionado: null }, () => this.buscarTotaisNfe())
        } else {
            this.setState({ modalAliquotasProdutoVisible: false, detSelecionado: null })
        }
    }

    render() {
        const {
            podeEditar,
            podeExcluir,
            podeInserir,
            modalDestinatarioVisible,
            modalAliquotasProdutoVisible,
            modalCartaCorrecao,
            modalHistoricoCorrecoes,
            modalCancelamento,
            detSelecionado,
            exibirLoadingTransmissao,
            credencial,
            modalHistoricoVisible,
            modalInfoCplVisible,
            validacoes,
            modalConfigurarAliquotaSimplesVisible
        } = this.state;

        const { values, isModal, dirty, isMobile, isTablet } = this.props;
        const nfeComProblemasFiscais = document.getElementById("btnAliquotasWarning") !== null;
        const notaFiscalTransmitida = (values.status !== Status.NAO_ENVIADA && values.status !== Status.REJEITADA);
        const podeEditarForm = (podeEditar && (values.status === Status.NAO_ENVIADA || values.status === Status.REJEITADA));
        const estadoBotaoSalvar = (!dirty && isModal && values.id ? estadosBotaoSalvar.CONFIRMAR : estadosBotaoSalvar.SALVAR);
        const desabilitarBotaoTransmitir = (dirty || notaFiscalTransmitida || !podeEditar || nfeComProblemasFiscais || !this.organizacaoPodeTransmitirNfe());
        const desabilitarBotaoExcluir = (!podeExcluir || notaFiscalTransmitida);

        const informacoesPermissoes = {
            estadoCadastro: this.buscarEstadoCadastro(),
            podeInserir: podeInserir,
            podeEditar: podeEditarForm,
            podeExcluir: podeExcluir
        };
        const itensOpcoes = this.buscarItensOpcoes();

        return (
            <>
                <Prompt dirty={dirty} isModal={isModal} />
                <Menu model={itensOpcoes} style={{ minWidth: '230px' }} popup={true} ref={el => (this.menuOpcoes = el)} />

                <ModalLoadingTransmissao visible={exibirLoadingTransmissao} message="Transmitindo nota fiscal..." />
                <Form className="card-default screen-max-width" header={this.montarHeader()} isModal={isModal}>
                    <If test={values.status === Status.AGUARDANDO_AUTORIZACAO}>
                        <Message severity="warn" text={this.buscarMensagemNFeAguardandoTransmissao()} colStyle={{ padding: '5px 0px' }} />
                    </If>
                    <FormActions className="screen-max-width">
                        <ButtonCancelar
                            {...informacoesPermissoes}
                            hidden={isModal && values.id && !dirty}
                            estadoBotao={dirty ? estadosBotaoCancelar.CANCELAR : estadosBotaoCancelar.VOLTAR}
                            onClick={this.cancelar}
                        />
                        <ButtonSalvar
                            {...informacoesPermissoes}
                            estadoBotao={estadoBotaoSalvar}
                            disabled={!dirty && estadoBotaoSalvar === estadosBotaoSalvar.SALVAR}
                            hidden={notaFiscalTransmitida}
                            onClick={this.salvar}
                        />
                        <Link to={podeInserir ? { pathname: "/vendas/cadastro", state: { venda: true } } : null}>
                            <ButtonNovo
                                label="Nova venda"
                                className="p-button-success"
                                title='Inserir uma nova venda'
                                podeInserir={podeInserir}
                                hidden={!notaFiscalTransmitida}
                            />
                        </Link>
                        <ButtonExcluir
                            hidden={!values.id || isModal}
                            onClick={this.excluir}
                            disabled={desabilitarBotaoExcluir}
                            title={desabilitarBotaoExcluir ? "Impossível excluir uma nota fiscal transmitida" : ""}
                            {...informacoesPermissoes}
                        />
                        <Button
                            className='p-button-success'
                            label='Transmitir NF-e'
                            icon='fa fa-send'
                            title={this.getTitleButtonTransmitir()}
                            onClick={this.transmitirNfe}
                            disabled={desabilitarBotaoTransmitir}
                            hidden={values.status !== Status.NAO_ENVIADA && values.status !== Status.REJEITADA}
                        />
                        <Button
                            className="p-button-primary"
                            type="button"
                            label="Opções"
                            icon="fa fa-list"
                            style={{ margin: '5px' }}
                            hidden={itensOpcoes.length === 0}
                            onClick={event => this.menuOpcoes.toggle(event)}
                        />
                    </FormActions>
                    <NfeCardValidacao
                        erros={values.erros}
                        validacoes={validacoes}
                        isMobile={isMobile}
                    />
                    <If test={this.organizacaoPodeTransmitirNfe() === false}>
                        <Message severity="error" text={this.buscarMensagemOrganizacaoPodeTransmitirNfe()} />
                    </If>
                    <FormContent>
                        <Grid style={{ display: 'flex', justifyContent: 'space-between', alignItems: (values.erros && values.erros.length > 0) ? 'flex-start' : 'center' }}>
                            <Col sm='7' lg='6' xl='5'>
                                <NfeStatus
                                    backgroundColor={values.status ? infoStatusNfe[values.status].lightColor : null}
                                    color={values.status ? infoStatusNfe[values.status].strongColor : null}
                                    name={values.status ? infoStatusNfe[values.status].name : null}
                                    description={values.status ? infoStatusNfe[values.status].description : null}
                                    chave={values.chNfe}
                                />
                            </Col>
                            <Col sm='5' lg='6' xl='5'>
                                <NfeSerieNumero
                                    isMobile={isMobile}
                                    serie={values.serie}
                                    numero={values.nNF}
                                    onVisualizarVendaClick={values ? () => this.props.history.push("/vendas/cadastro/" + values.venda.id) : null}
                                />
                            </Col>
                        </Grid>
                        <Grid>
                            <Col sm="12" md="5" lg='5' xl='5'>
                                <Field
                                    colStyle={{ padding: '0px' }}
                                    component={InputField}
                                    label='Destinatário da nota fiscal'
                                    value={this.getValueDestinatario()}
                                    name="nome"
                                    disabled
                                />
                                <NfeLinkVerDados
                                    label='Ver dados do destinatário'
                                    onClick={() => this.setState({ modalDestinatarioVisible: true })}
                                />
                            </Col>
                            <Field sm="6" md="4" lg='4' xl='4'
                                component={Dropdown}
                                obrigatorio
                                label='Tipo de atendimento'
                                name='indPres'
                                onChange={this.onChangeIndPres}
                                options={optionsIndPres}
                                value={values.indPres}
                                showClear={false}
                                helpMessage={helpNFeForm.indPres}
                                {...informacoesPermissoes}
                            />
                            <Field sm="6" md="3" lg='3' xl='3'
                                component={InputField}
                                name="dhSaiEnt"
                                disabled
                                label="Data e hora de saída"
                                helpMessage={helpNFeForm.dhSaiEnt}
                                value={values.dhSaiEnt ? format(parseISO(values.dhSaiEnt, new Date()), 'dd/MM/yyyy HH:mm') : values.dhSaiEnt}
                                {...informacoesPermissoes}
                            />
                        </Grid>
                        <If test={values.indPres && ([IndPres.OPERACAO_PELA_INTERNET, IndPres.OPERACAO_POR_TELEATENDIMENTO, IndPres.OUTROS].includes(values.indPres))}>
                            <Fieldset legend="Dados do intermediador" className='fieldset-light'>
                                <Grid>
                                    <Field sm="12" md="12" lg="12" xl="12"
                                        component={Checkbox}
                                        label="Intermediador já cadastrado"
                                        name="intermediadorCadastrado"
                                        onChange={this.onChangeIntermediadorCadastrado}
                                        checked={values.infIntermed.intermediadorCadastrado}
                                        {...informacoesPermissoes}
                                    />
                                    <If test={!values.infIntermed.intermediadorCadastrado}>
                                        <Field sm="12" md="6" lg="6" xl="6"
                                            component={InputField}
                                            size={60}
                                            label='Intermediador'
                                            name="idCadIntTran"
                                            obrigatorio
                                            value={values.infIntermed.idCadIntTran}
                                            onChange={e => this.onChangeInfIntermed('idCadIntTran', e.target.value)}
                                            helpMessage={''}
                                            {...informacoesPermissoes}
                                        />
                                        <Field sm="12" md="6" lg='6' xl='6'
                                            component={InputMask}
                                            mask="00.000.000/0000-00"
                                            placeholder="  .   .   /    -  "
                                            label='CNPJ'
                                            name="CNPJ"
                                            obrigatorio
                                            value={values.infIntermed.CNPJ}
                                            onChange={e => this.onChangeInfIntermed('CNPJ', e.target.value)}
                                            {...informacoesPermissoes}
                                        />
                                    </If>
                                    <If test={values.infIntermed.intermediadorCadastrado}>
                                        <Field sm="12" md="6" lg="6" xl="6"
                                            name="pessoa"
                                            label="Intermediador"
                                            component={SingleSelectIntermediador}
                                            value={values.infIntermed.pessoa}
                                            helpMessage={"Intermediador da venda"}
                                            onChange={e => this.onChangeInfIntermed('pessoa', e)}
                                            url={`${services.GESTOR}/v1/vendas/relacoes/clientes`}
                                            {...informacoesPermissoes}
                                        />
                                    </If>
                                </Grid>
                            </Fieldset>
                        </If>
                        <Fieldset legend="Produtos" className='fieldset-light'>
                            {values.det.map((det, index) =>
                                <NfeProduto
                                    key={index}
                                    det={det}
                                    dest={values.dest}
                                    isTablet={isTablet}
                                    onChange={(values) => this.onChangeDet(values)}
                                    onAliquotasClick={() => this.setState({ detSelecionado: det, modalAliquotasProdutoVisible: true })}
                                    corrigirDadosDestinatarioClick={() => this.setState({ modalDestinatarioVisible: true })}
                                    informacoesPermissoes={informacoesPermissoes}
                                />
                            )}
                        </Fieldset>
                        <Fieldset legend="Totalizadores" className='fieldset-light'>
                            <Grid>
                                <Field sm="6" md="3" lg='3' xl='3'
                                    component={InputMoney}
                                    size={14}
                                    label='Valor do frete'
                                    name="vFrete"
                                    value={values.total.ICMSTot.vFrete}
                                    onChange={(e) => this.onChangeICMSTot('vFrete', e.target.value)}
                                    helpMessage={helpNFeForm.vFrete}
                                    {...informacoesPermissoes}
                                />
                                <Field sm="6" md="3" lg='3' xl='3'
                                    component={InputMoney}
                                    size={14}
                                    label='Valor do seguro'
                                    name="vSeg"
                                    value={values.total.ICMSTot.vSeg}
                                    onChange={(e) => this.onChangeICMSTot('vSeg', e.target.value)}
                                    helpMessage={helpNFeForm.vSeg}
                                    {...informacoesPermissoes}
                                />
                                <Field sm="6" md="3" lg='3' xl='3'
                                    component={InputMoney}
                                    size={14}
                                    label='Outras desp. acessórias'
                                    name="vOutro"
                                    value={values.total.ICMSTot.vOutro}
                                    onChange={(e) => this.onChangeICMSTot('vOutro', e.target.value)}
                                    helpMessage={helpNFeForm.vOutro}
                                    {...informacoesPermissoes}
                                />
                                <Field sm="6" md="3" lg='3' xl='3'
                                    component={InputMoney}
                                    size={14}
                                    label='Valor do desconto'
                                    name="vDesc"
                                    value={values.total.ICMSTot.vDesc}
                                    onChange={(e) => this.onChangeICMSTot('vDesc', e.target.value)}
                                    helpMessage={helpNFeForm.vDesc}
                                    {...informacoesPermissoes}
                                />
                            </Grid>
                            <NfeTabelaTotalizadores
                                icmsTot={values.total.ICMSTot}
                            />
                        </Fieldset>
                        <Fieldset legend="Frete" className='fieldset-light'>
                            <Grid>
                                <Field sm="4" md="4" lg='4' xl='4'
                                    component={Dropdown}
                                    obrigatorio
                                    label='Responsável pelo frete'
                                    name="modFrete"
                                    onChange={(e) => this.onChangeTransp('modFrete', e.value)}
                                    options={optionsModFrete}
                                    value={values.transp.modFrete}
                                    showClear={false}
                                    helpMessage={helpNFeForm.modFrete}
                                    {...informacoesPermissoes}
                                />
                                <If test={values.transp.modFrete && values.transp.modFrete !== 'SEM_FRETE'}>
                                    <Field sm="8" md="8" lg='8' xl='8'
                                        style={{ marginBottom: '7px' }}
                                        colStyle={{ alignItems: 'flex-end', display: 'flex' }}
                                        component={Checkbox}
                                        label="O frete será feito por um transportador"
                                        name="utilizaTransportador"
                                        onChange={(e) => this.onChangeTransp('utilizaTransportador', e.checked)}
                                        checked={values.transp.utilizaTransportador}
                                        {...informacoesPermissoes}
                                    />
                                </If>
                            </Grid>
                            <If test={values.transp.modFrete && values.transp.modFrete !== 'SEM_FRETE'}>
                                <NfeTransporte
                                    transp={values.transp}
                                    onChange={(transp) => this.props.setFieldValue('transp', transp)}
                                    informacoesPermissoes={informacoesPermissoes}
                                    errors={this.props.errors}
                                />
                            </If>
                        </Fieldset>
                        <If test={values && values.dest.tipo === 'ESTRANGEIRO'}>
                            <Fieldset legend="Exportação" className='fieldset-light'>
                                <Grid>
                                    <Field sm="6" md="4" lg='4' xl='4'
                                        component={SingleSelectEstado}
                                        obrigatorio
                                        name='ufSaidaPais'
                                        label='UF de embarque'
                                        helpMessage={helpNFeForm.ufSaidaPais}
                                        value={values.exporta.ufSaidaPais}
                                        onChange={(e) => this.onChangeExporta('ufSaidaPais', e)}
                                        {...informacoesPermissoes}
                                    />
                                    <Field sm="6" md="4" lg='4' xl='4'
                                        component={InputField}
                                        obrigatorio
                                        size={14}
                                        label='Local de Embarque'
                                        name="xLocExporta"
                                        value={values.exporta.xLocExporta}
                                        onChange={(e) => this.onChangeExporta('xLocExporta', e.target.value)}
                                        helpMessage={helpNFeForm.xLocExporta}
                                        {...informacoesPermissoes}
                                    />
                                    <Field sm="6" md="4" lg='4' xl='4'
                                        component={InputField}
                                        size={14}
                                        label='Descrição do local de despacho'
                                        name="xLocDespacho"
                                        value={values.exporta.xLocDespacho}
                                        onChange={(e) => this.onChangeExporta('xLocDespacho', e.target.value)}
                                        helpMessage={helpNFeForm.xLocDespacho}
                                        {...informacoesPermissoes}
                                    />
                                </Grid>
                            </Fieldset>
                        </If>
                        <Fieldset legend="Informações adicionais" className='fieldset-light'>
                            <Grid>
                                <Col sm="12" md="6" lg='6' xl='6'>
                                    <Field
                                        colStyle={{ padding: '0px' }}
                                        component={TextArea}
                                        label='Informações complementares de interesse do contribuinte'
                                        name="infCpl"
                                        maxLength={4096}
                                        value={values.infAdic.infCpl}
                                        onChange={e => this.onChangeInfAdic('infCpl', e.target.value)}
                                        {...informacoesPermissoes}
                                    />
                                    <NfeLinkVerDados
                                        label='Ver informações complementares fiscais'
                                        onClick={() => this.setState({ modalInfoCplVisible: true })}
                                    />
                                </Col>
                                <Field sm="12" md="6" lg='6' xl='6'
                                    component={TextArea}
                                    label='Informações adicionais de interesse do fisco'
                                    name="infAdicFisco"
                                    maxLength={2000}
                                    value={values.infAdic.infAdicFisco}
                                    onChange={e => this.onChangeInfAdic('infAdicFisco', e.target.value)}
                                    {...informacoesPermissoes}
                                />
                            </Grid>
                        </Fieldset>
                    </FormContent>
                </Form>
                <If test={modalHistoricoVisible}>
                    <NotasHistorico
                        idNota={values.id}
                        visible={modalHistoricoVisible}
                        onHide={() => this.setState({ modalHistoricoVisible: false })}
                    />
                </If>
                <If test={modalDestinatarioVisible}>
                    <ModalDadosDestinatario
                        values={values.dest}
                        onChange={(value) => this.props.setFieldValue('dest', value)}
                        onChangeIndFinal={(value) => this.props.setFieldValue('indFinal', value)}
                        valueIndFinal={values.indFinal}
                        visible={modalDestinatarioVisible}
                        onHide={() => this.setState({ modalDestinatarioVisible: false })}
                        informacoesPermissoes={informacoesPermissoes}
                    />
                </If>
                <If test={modalAliquotasProdutoVisible}>
                    <ModalAliquotasProduto
                        values={detSelecionado}
                        dest={values.dest}
                        organizacao={credencial.organizacao}
                        indFinal={values.indFinal}
                        onChange={(values) => this.onChangeDet(values)}
                        visible={modalAliquotasProdutoVisible}
                        onHide={this.onHideModalAliquotasProduto}
                        informacoesPermissoes={informacoesPermissoes}
                        corrigirDadosDestinatarioClick={() => this.setState({ modalDestinatarioVisible: true })}
                        onConfigurarOrganizacao={() => this.props.history.push({ pathname: "/configuracoes_gerais" })}
                        podeCalcularDIFAL={validarCalculoDIFAL(values.dest, values.indFinal, credencial.organizacao)}
                        podeCalcularICMSST={validarCalculoST(values.dest)}

                    />
                </If>
                <If test={modalHistoricoCorrecoes}>
                    <ModalHistoricoCorrecoes
                        idNfe={values.id}
                        visible={modalHistoricoCorrecoes}
                        statusNfe={values.status}
                        correcoesNfe={values.correcoes}
                        isMobile={isMobile}
                        onHide={() => this.setState({ modalHistoricoCorrecoes: false })}
                    />
                </If>
                <If test={modalCartaCorrecao}>
                    <ModalCartaCorrecao
                        idNfe={values.id}
                        visible={modalCartaCorrecao}
                        onHide={(confirmou) => {
                            this.setState({ modalCartaCorrecao: false }, () => {
                                if (confirmou) this.asyncSelectRegistro(values.id);
                            });
                        }}
                    />
                </If>
                <If test={modalCancelamento}>
                    <ModalCancelamento
                        idNfe={values.id}
                        visible={modalCancelamento}
                        xJust={values.xJustCancelamento}
                        dhCancelamento={values.dhCancelamento}
                        statusNfe={values.status}
                        onHide={(confirmou) => {
                            this.setState({ modalCancelamento: false }, () => {
                                if (confirmou) this.asyncSelectRegistro(values.id);
                            })
                        }}
                    />
                </If>
                <If test={modalInfoCplVisible}>
                    <ModalInformacoesComplementares
                        visible={modalInfoCplVisible}
                        isMobile={isMobile}
                        msgCreditoICMS={values.msgCreditoICMS}
                        infAdic={values.infAdic.infCpl}
                        onHide={() => this.setState({ modalInfoCplVisible: false })}
                    />
                </If>
                <If test={modalConfigurarAliquotaSimplesVisible}>
                    <ModalAliquotaSimples
                        visible={modalConfigurarAliquotaSimplesVisible}
                        onHide={() => this.setState({ modalConfigurarAliquotaSimplesVisible: false })}
                    />
                </If>
            </>
        )
    }
}

NFeForm = withFormik({

    enableReinitialize: true,
    validateOnChange: false,

    mapPropsToValues() {
        return initialValue
    },

})(NFeForm);

const mapStateToProps = state => ({
    isMobile: state.dispositivo.isMobile,
    isTablet: state.dispositivo.isTablet
})

export default connect(mapStateToProps)(withRouter(NFeForm));
