import React, { Component } from 'react';
import { withFormik, Field } from 'formik';
import * as Yup from 'yup';
import FormGroup from '../../../../../../components/FormGoup';
import Grid from '../../../../../../components/Grid';
import LayoutMenuSuperior from '../../../../../../components/Layout/LayoutMenuSuperior';
import MenuSuperior from '../../../../../../components/MenuSuperior';
import ButtonNovo from '../../../../../../components/Button/ButtonNovo';
import ButtonSalvar, { estadosBotaoSalvar } from '../../../../../../components/Button/ButtonSalvar';
import ButtonCancelar, { estadosBotaoCancelar } from '../../../../../../components/Button/ButtonCancelar';
import InputField from '../../../../../../components/Input//InputField';
import ButtonExcluir from '../../../../../../components/Button/ButtonExcluir';
import { mensagensDeValidacao } from '../../../../../../common/constantes/mensagens';
import { converterPessoaEmailParaApi } from './util/pessoaEmailConverter';
import { estadosCadastro } from '../../../../../../common/constantes/autorizacao';
import { validarFormulario } from '../../../../../util';
import autoBind from 'react-autobind'
import MsysCheckbox from '../../../../../../components/Input/Checkbox';
import { helpPessoaEmailForm } from './help';
import Paper from '../../../../../../components/Paper';
import { gerarUUID } from '../../../../../../common/manipulacaoDeString';

const initialValue = {
    id: '',
    email: '',
    observacao: '',
    favorito: false,
    idTemporario: null
}

class PessoaEmailForm extends Component {

    constructor(props) {
        super(props);

        autoBind(this)

        this.state = {
            inserindoNovoRegistro: false
        }
    }
    componentDidMount() {
        this.setState({ inserindoNovoRegistro: !this.props.registroSelecionado });
    }

    novo() {
        this.props.onNovoClick()
        this.props.resetForm({ values: initialValue })
        this.setState({ inserindoNovoRegistro: true })
    }

    excluir() {
        this.props.excluirRegistro(this.props.registroSelecionado)
        this.props.onHide()
    }

    async salvar() {

        this.props.handleSubmit();
        if (await validarFormulario(this.props)) {
            if (!this.props.validarMesmoEmail(this.props.values)) {
                if (this.state.inserindoNovoRegistro) {
                    this.criarRegistro()
                } else {
                    this.atualizarRegistro()
                }
            } else {
                this.props.setFieldError("email", "Este e-mail já existe")
            }
        }
    }

    atualizarRegistro() {
        const dadosFormulario = converterPessoaEmailParaApi(this.props.values);
        this.props.atualizarRegistro(dadosFormulario);
        this.props.onHide()
    }

    criarRegistro() {
        const dadosFormulario = converterPessoaEmailParaApi(this.props.values);
        this.props.inserirRegistro({ ...dadosFormulario, idTemporario: gerarUUID() })
        this.props.onHide()
    }

    cancelar() {
        if (this.props.dirty) {
            this.props.handleReset();
        } else {
            this.props.onHide()
        }
    }

    render() {

        const informacoesPermissoes = {
            estadoCadastro: this.state.inserindoNovoRegistro ? estadosCadastro.INCLUSAO : estadosCadastro.EDICAO,
            podeInserir: this.props.podeEditar,
            podeEditar: this.props.podeEditar,
            podeExcluir: this.props.podeEditar
        };

        const { values, dirty } = this.props;

        const { inserindoNovoRegistro } = this.state;
        return (
            <React.Fragment>
                <MenuSuperior isModal={true}>
                    <ButtonCancelar
                        {...informacoesPermissoes}
                        estadoBotao={dirty ? estadosBotaoCancelar.CANCELAR : estadosBotaoCancelar.VOLTAR}
                        onClick={this.cancelar}
                    />
                    <ButtonSalvar
                        estadoBotao={estadosBotaoSalvar.CONFIRMAR}
                        {...informacoesPermissoes}
                        disabled={!dirty}
                        onClick={this.salvar}
                    />
                    <ButtonNovo
                        onClick={this.novo}
                        hidden={inserindoNovoRegistro}
                        {...informacoesPermissoes}
                    />
                    <ButtonExcluir
                        hidden={inserindoNovoRegistro}
                        {...informacoesPermissoes}
                        onClick={this.excluir}
                        podeExcluir={this.props.podeExcluir}
                    />
                </MenuSuperior>
                <LayoutMenuSuperior isModal={true}>
                    <Paper
                        dirty={dirty}
                        {...informacoesPermissoes}
                        childsOnly={true}
                    >
                        <FormGroup>
                            <Grid>
                                <Field sm="12" md="12" lg='12' xl='12'
                                    component={MsysCheckbox}
                                    label="E-mail favorito"
                                    title="Marque esta opção caso o e-mail seja o favorito"
                                    name="favorito"
                                    helpMessage={helpPessoaEmailForm.favorito}
                                    onChange={() => this.props.setFieldValue('favorito', !this.props.values.favorito)}
                                    checked={values.favorito}
                                    {...informacoesPermissoes}
                                />
                                <Field sm="12" md="12" lg='12' xl='12'
                                    component={InputField}
                                    type="email"
                                    label='E-mail'
                                    obrigatorio
                                    helpMessage={helpPessoaEmailForm.email}
                                    name="email"
                                    size={255}
                                    {...informacoesPermissoes}
                                />
                                <Field sm="12" md="12" lg='12' xl='12'
                                    component={InputField}
                                    type="text"
                                    label='Observação'
                                    name="observacao"
                                    {...informacoesPermissoes}
                                />
                            </Grid>
                        </FormGroup>
                    </Paper>
                </LayoutMenuSuperior>
            </React.Fragment>
        )
    }
}

PessoaEmailForm = withFormik({


    enableReinitialize: true,
    validateOnChange: false,

    mapPropsToValues(props) {
        if (props.registroSelecionado) {
            return { ...props.registroSelecionado }
        }
        return { ...initialValue, favorito: props.marcarComoFavorito() };
    },

    validationSchema: Yup.object().shape({
        email: Yup.string().email("Informe um e-mail válido").nullable().required(mensagensDeValidacao.OBRIGATORIO)
    })
})(PessoaEmailForm);

export default PessoaEmailForm
