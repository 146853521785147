import { format, parse } from "date-fns"

export function converterProventoParaFormulario(provento) {
    return {
        ...provento,
        ativo: converterAtivoParaFormulario(provento.ativo),
        conta: converterContaParaFormulario(provento.conta)
    }
}

function converterAtivoParaFormulario(ativo) {
    if (ativo) {
        return {
            label: ativo.nome,
            value: ativo.id
        }
    }
    return null
}

function converterContaParaFormulario(conta) {
    if (conta) {
        return {
            label: `${conta.corretora.nome} (${conta.codigo})`,
            value: conta.id
        }
    }
    return null
}


export function converterProventoParaApi(provento) {
    return {
        ...provento,
        conta: {
            id: provento.conta.value,
        },
        ativo: {
            id: provento.ativo.value,
        }
    }
}

export function converterProventosParaListagem(proventos) {
    return proventos.map((provento) => {
        return {
            ...provento,
            data: format(parse(provento.data, 'yyyy-MM-dd hh:mm', new Date()), 'dd/MM/yyyy hh:mm')
        }
    })
}
