import { actionTypes } from "../../constantes/reduxTypes";

export default (state = {}, action) => {
    switch (action.type) {
        case actionTypes.LIMITE_PLANO_VIOLADO:
            {
                return {
                    ...state,
                    mostrarModalPlanoViolado: new Date(),
                    mensagemErroPlanoViolado: action.payload
                }
            }
            
        default:
            {
                return state
            }
    }
}