import React, { Component } from 'react';
import ButtonNovo from '../../../components/Button/ButtonNovo';
import { usuarioPossuiPermissao } from '../../../common/autenticacao';
import { recursos, permissoes } from '../../../common/constantes/autorizacao';
import autoBind from 'react-autobind';
import { buscarContas } from './requests';
import ModalConta from './components/ModalConta';
import CardConta from './components/CardConta';
import Grid from '../../../components/Grid';
import { asyncDeleteRegistro, asyncUpdateRegistro } from './components/ModalConta/requests';
import { confirm } from '../../../components/Toast';
import { removerElementoPorId } from '../../../common/array';
import If from '../../../components/If';
import Form from '../../../components/Form';
import FormActions from '../../../components/FormActions';
import FormContent from '../../../components/FormContent';
import NenhumRegistroEncontrado from '../../../components/NenhumRegistroEncontrado';
import { Checkbox } from 'primereact/checkbox';

class Contas extends Component {

    constructor(props) {

        super(props);

        autoBind(this);

        this.state = {
            podeInserir: usuarioPossuiPermissao(recursos.FINANCAS_CONTAS, permissoes.INSERIR),
            podeVisualizarLancamentos: usuarioPossuiPermissao(recursos.FINANCAS_LANCAMENTOS, permissoes.VISUALIZAR),

            exibirModalConta: false,
            exibirContasInativas: false,
            contaSelecionada: null,
            contas: []
        }
    }

    componentDidMount() {
        this.buscarTodasAsContas();
    }

    buscarTodasAsContas() {
        buscarContas(({ data }) => {
            this.setState({ contas: data.content });
        })
    }

    onClickExibirContasInativas() {
        this.setState({ exibirContasInativas: !this.state.exibirContasInativas });
    }

    ativarConta(conta) {
        confirm('Confirmação', 'Tem certeza que deseja reativar a conta?', async () => {
            await asyncUpdateRegistro({ ...conta, ativa: true }, () => {
                this.buscarTodasAsContas({ ...conta, ativa: true });
            })
        })
    }

    editarConta(conta) {
        this.setState({
            contaSelecionada: conta,
            exibirModalConta: true
        });
    };

    excluirConta(conta) {
        confirm('Confirmação', 'Tem certeza que deseja excluir a conta?', async () => {
            await asyncDeleteRegistro(conta.id, () => {
                this.setState({ contas: removerElementoPorId(this.state.contas, conta, 'id') });
            })
        })
    }

    desativarConta(conta) {
        confirm('Confirmação', 'Tem certeza que deseja inativar a conta?', async () => {
            const dados = { ...conta, ativa: false, favoritaDespesa: false, favoritaReceita: false }
            await asyncUpdateRegistro(dados, () => {
                this.buscarTodasAsContas(dados);
            })
        })
    }

    onVerDetalhesClick(conta) {
        this.props.history.push({
            pathname: '/movimentacoes',
            state: { contaSelecionadaId: conta.id }
        })
    }

    renderizarContas() {
        const { exibirContasInativas, podeVisualizarLancamentos } = this.state
        let contas = this.state.contas;

        if (exibirContasInativas === false) {
            contas = this.state.contas.filter(conta => conta.ativa);
        }

        if (contas.length === 0) {
            return <NenhumRegistroEncontrado message='Nenhuma conta encontrada' />
        }

        return contas.map((conta, key) => {
            return <CardConta
                key={key}
                conta={conta}
                editarConta={this.editarConta}
                excluirConta={this.excluirConta}
                desativarConta={this.desativarConta}
                ativarConta={this.ativarConta}
                verDetalhesClick={this.onVerDetalhesClick}
                habilitarVerDetalhes={podeVisualizarLancamentos && conta.ativa}
            />
        });

    }

    onHideModalConta() {
        this.buscarTodasAsContas();
        this.setState({ exibirModalConta: false, contaSelecionada: null })
    }

    render() {
        return (
            <>
                <Form header="Contas">
                    <FormActions>
                        <ButtonNovo
                            label="Nova conta"
                            onClick={() => this.setState({ exibirModalConta: true })}
                            podeInserir={this.state.podeInserir}
                        />
                    </FormActions>
                    <FormContent>
                        <div style={{ display: 'flex', justifyContent: 'flex-end', margin: '15px 0px' }}>
                            <Checkbox
                                inputId="exibirContasInativas"
                                checked={this.state.exibirContasInativas}
                                name="exibirContasInativas"
                                onChange={this.onClickExibirContasInativas}
                            />
                            <label htmlFor="exibirContasInativas" className="p-checkbox-label">Exibir contas inativas</label>
                        </div>
                        <Grid>
                            {this.renderizarContas()}
                        </Grid>
                    </FormContent>
                </Form>
                <If test={this.state.exibirModalConta}>
                    <ModalConta
                        visible={this.state.exibirModalConta}
                        onHide={this.onHideModalConta}
                        registroSelecionado={this.state.contaSelecionada}
                    />
                </If>
            </>
        )
    }
}

export default Contas;
