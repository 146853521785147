import { colorsStatus } from "../../util/constantes"

export function validarValorNegativo(valor){
    return valor < 0 ? 0.0 : valor 
}

export function getPrimaryColorOfSelectedItem(item) {
    if(item) {
        return colorsStatus[item.status].strongColor;
    }
    return "#006095";
}
