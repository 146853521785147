import React, { Component } from 'react';
import autoBind from 'react-autobind';
import SingleSelect from '../SingleSelect';
import { services } from '../../../common/constantes/api';

class SingleSelectPais extends Component {
    constructor(props) {
        super(props);
        autoBind(this);
    }

    buscarUrlPesquisa(pesquisa, page) {
        return `${services.GESTOR}/v1/paises?query=nome=contains="*${pesquisa}*"&page=${page}&size=50&sort=nome`;
    }

    setarNovoRegistroNoSelect(novoRegistro) {
        if (novoRegistro) {
            this.props.onChange(novoRegistro)
        }
    }
    
    montarLabel(row) {
        if (row) {
            return row.nome
        }
    }

    render() {
        return (
            <React.Fragment>
                <SingleSelect
                    titleBotaoNovo="Adicionar novo país"
                    esconderBotao
                    montarLabel={this.montarLabel}
                    buscarUrlPesquisa={this.buscarUrlPesquisa}
                    onClickModal={this.mostrarModal}
                    onChange={this.props.onChange}
                    {...this.props}
                />
            </React.Fragment>
        );
    }
}

SingleSelectPais.defaultProps = {
    label: 'País *',
}

export default SingleSelectPais;
