import { confirm } from "../../../../../../components/Toast"
import { buscarAtivoPeloMesmoCodigo } from "../../../request"

export async function jaExisteAtivosComMesmoCodigo(idAtivo, codigo) {
    let existeMesmoCodigo = false
    await buscarAtivoPeloMesmoCodigo(codigo, (e) => {
        if (e.data.content.length > 0 && e.data.content[0].id !== idAtivo) {
            existeMesmoCodigo = true
        }
    })
    return existeMesmoCodigo
}

export function exibirConfirmacaoCodigo(onOk){
    confirm("Confirmação", `Já existe um cadastro de ativo com o mesmo código. Tem certeza que deseja salvar?`, onOk)
}
