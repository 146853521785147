import React, { Component } from "react";
import Button from "../";
import propTypes from "prop-types";
import {
    buscarDisabledDeAcordoComAsPermissoes,
    buscarTitleBotaoDeAcordoComAsPermissoes
} from "../../../common/autorizacao/manipulacaoDeComponentes";

export const estadosBotaoSalvar = {
    SALVAR: "SALVAR",
    CONFIRMAR: "CONFIRMAR"
};

class ButtonSalvar extends Component {
    render() {
        const {
            podeInserir,
            podeEditar,
            estadoCadastro,
            disabled,
            onClick,
            hidden,
            enableShortCut,
            style,
            id,
            title,
            estadoBotao,
            label,
            raised,
            rounded
        } = this.props;

        const labelFormatado =
            estadoBotao === estadosBotaoSalvar.CONFIRMAR
                ? "Confirmar"
                : label;
        const disabledFormatado = buscarDisabledDeAcordoComAsPermissoes(
            podeInserir,
            podeEditar,
            estadoCadastro,
            disabled
        );
        const titleFormatado = buscarTitleBotaoDeAcordoComAsPermissoes(
            podeInserir,
            podeEditar,
            estadoCadastro,
            title
        );

        return (
            <Button
                label={labelFormatado}
                id={id}
                type="button"
                color="success"
                icon="fa fa-check"
                disabled={disabledFormatado}
                title={titleFormatado}
                hidden={hidden}
                raised={raised}
                rounded={rounded}
                style={{ ...style, margin: "5px" }}
                onClick={onClick}
                hotKey={["alt", "s"]}
                enableShortCut={enableShortCut}
            />
        );
    }
}

ButtonSalvar.defaultProps = {
    hidden: false,
    disabled: false,
    label: "Salvar",
    title: "Salvar o registro"
};

ButtonSalvar.propTypes = {
    /** Identificador do componente */
    id: propTypes.string,
    /** Derfine o botão como invisível */
    hidden: propTypes.bool,
    /** Ação executada ao clicar no botão */
    onClick: propTypes.func,
    /** Define o botão como desabilitaedo */
    disabled: propTypes.bool,
    /** Habilitar atalho */
    enableShortCut: propTypes.bool,
    /** Estado em que o cadastro se encontra*/
    estadoCadastro: propTypes.string,
    /** Diz se o usuário possui permissão de editar*/
    podeEditar: propTypes.bool,
    /** Diz se o usuário possui permissão de excluir*/
    podeInserir: propTypes.bool,
    /** Estilo do componente */
    style: propTypes.object,
    /** Title do componente*/
    title: propTypes.string,
    /** Especifica o label do botão*/
    estadoBotao: propTypes.oneOf([
        estadosBotaoSalvar.SALVAR,
        estadosBotaoSalvar.CONFIRMAR
    ])
};

export default ButtonSalvar;
