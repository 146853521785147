import React from 'react'
import propTypes from 'prop-types'
import Grid from '../Grid'
import { FaList } from 'react-icons/fa'

export default function NenhumRegistroEncontrado(props) {
    return (
        <div style={{ opacity: '0.50', width: '100%' }}>
            <Grid justifyCenter style={{ padding: '10px' }}>
                <FaList size={50} />
            </Grid>
            <Grid justifyCenter>
                <span style={{ fontSize: '20px', textAlign: 'center' }}>
                    {props.message}
                </span>
            </Grid>
        </div>
    )
}

NenhumRegistroEncontrado.propTypes = {
    message: propTypes.any
}

NenhumRegistroEncontrado.defaultProps = {
    message: 'Nenhum registro encontrado'
}