import { copiarObjeto } from "../../../../../../common/array"

export function converterNFeParaApi(valuesFormulario) {
    const values = {
        ...valuesFormulario,
        dest: converterDestParaApi(valuesFormulario.dest),
        det: converterDetParaApi(valuesFormulario.det),
        transp: converterTranspParaApi(valuesFormulario.transp),
        total: converterTotalParaApi(valuesFormulario.total),
        exporta: converterExportaParaApi(valuesFormulario.exporta),
        infIntermed: converterInfIntermedParaApi(valuesFormulario.infIntermed)
    }

    return removerPropriedadesNaoUtilizadas(copiarObjeto(values))
}

function converterDestParaApi(dest) {
    return {
        ...dest,
        xNome: dest.xNome.substr(0, 60),
        enderDest: converterEnderDestParaApi(dest.enderDest)
    }
}

function converterInfIntermedParaApi(infIntermed) {
    if(infIntermed && infIntermed.pessoa){
        return {
            ...infIntermed,        
            pessoa: {
                id: infIntermed.pessoa.value
            }
        }        
    } 
    return {
        ...infIntermed
    }    
}

function converterExportaParaApi(exporta) {
    if (exporta && exporta.ufSaidaPais) {
        return {
            ...exporta,
            ufSaidaPais: {
                id: exporta.ufSaidaPais.value
            }
        }
    }
    return null
}


function converterEnderDestParaApi(enderDest) {
    return {
        ...enderDest,
        idMun: enderDest.municipio ? enderDest.municipio.value : null,
        idPais: enderDest.pais ? enderDest.pais.value : null,
    }
}

function converterDetParaApi(det) {
    let result = []

    for (const item of det) {
        result.push({
            ...item,
            prod: converterProdParaApi(item.prod)
        })
    }

    return result
}

function converterProdParaApi(prod) {
    return {
        ...prod,
        idNCM: prod.NCM ? prod.NCM.value : null,
        idCFOP: prod.CFOP ? prod.CFOP.value : null,
        idCom: prod.unidadeMedida ? prod.unidadeMedida.value : null,
        idCEST: prod.CEST ? prod.CEST.value : null,
    }
}

function converterTranspParaApi(transp) {
    if(transp.modFrete === 'SEM_FRETE'){
        return {
            ...transp,
            transportadorCadastrado: false,
            utilizaTransportador: false,
            transporta: {},
            veicTransp: {},
            vol: [],
            pessoa: null
        }
    }
    return {
        ...transp,
        transporta: transp.utilizaTransportador ? converterTransportaParaApi(transp.transporta) : null,
        veicTransp: transp.utilizaTransportador ? converterVeicTranspParaApi(transp.veicTransp) : null,
        vol: converterVolParaApi(transp.vol),
        pessoa: transp.pessoa ? { id: transp.pessoa.value } : null
    }
}

function converterTransportaParaApi(transporta) {
    if (!transporta) {
        return null
    }
    return {
        ...transporta,
        idMun: transporta.municipio ? transporta.municipio.value : null
    }
}

function converterVeicTranspParaApi(veicTransp) {
    if (!veicTransp) {
        return null
    }
    return {
        ...veicTransp,
        idUf: veicTransp.UF ? veicTransp.UF.value : null,
    }
}

function converterVolParaApi(vol) {
    if (!vol) {
        return []
    }
    return [{
        ...vol
    }]
}

function converterTotalParaApi(total) {
    if (!total.ICMSTot) {
        return null;
    }
    return {
        ICMSTot: {
            ...total.ICMSTot,
        }
    }
}

function removerPropriedadesNaoUtilizadas(values) {
    delete values.NFref
    delete values.alteradoEm
    delete values.chNfe
    delete values.cobr
    delete values.criadoEm
    delete values.dhCancelamento
    delete values.dhEmi
    delete values.emit
    delete values.finNfe
    delete values.idDest
    delete values.indPag
    delete values.mod
    delete values.nNF
    delete values.nProt
    delete values.nProt
    delete values.nRec
    delete values.natOp
    delete values.pag
    delete values.serie
    delete values.status
    delete values.statusCancelamento
    delete values.statusCorrecao
    delete values.tpAmb
    delete values.tpEmis
    delete values.tpNF
    delete values.venda
    delete values.xCorrecao
    delete values.xJustCancelamento
    delete values.xMotivo
    delete values.tpImp

    if (values.dest.enderDest) {
        delete values.dest.enderDest.UF
        delete values.dest.enderDest.cMun
        delete values.dest.enderDest.cPais
        delete values.dest.enderDest.xMun
        delete values.dest.enderDest.xPais
        delete values.dest.enderDest.municipio
        delete values.dest.enderDest.pais
    }

    for (const det of values.det) {
        delete det.imposto.icms.pFCP
        delete det.nItem
        delete det.prod.CEST
        delete det.prod.CFOP
        delete det.prod.NCM
        delete det.prod.cProd
        delete det.prod.indTot
        delete det.prod.qCom
        delete det.prod.uCom
        delete det.prod.unidadeMedida
        delete det.prod.vDesc
        delete det.prod.vFrete
        delete det.prod.vOutro
        delete det.prod.vProd
        delete det.prod.vSeg
        delete det.prod.vUnCom
        delete det.prod.xCEST
        delete det.prod.xCFOP
        delete det.prod.xCom
        delete det.prod.xNCM
        delete det.prod.xProd
        delete det.prod.xCom
        delete det.prod.xCom
        delete det.impostoDevol
    }

    if (values.transp.transporta) {
        delete values.transp.transporta.UF
        delete values.transp.transporta.municipio
        delete values.transp.transporta.xMun
    }

    if (values.transp.veicTransp) {
        delete values.transp.veicTransp.UF
        delete values.transp.veicTransp.uf
        delete values.transp.veicTransp.xUf
    }

    return values
}