import React, { Component } from 'react'
import autoBind from 'react-autobind';
import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';
import Dropzone from 'react-dropzone';
import If from '../../../../../components/If';
import { alert } from '../../../../../components/Toast';
import ModalCropImage from '../ModalCropImage';

const selecionarLogotipoStyle = {
    background: 'none',
    border: 'none',
    fontWeight: 'bold',
    boxShadow: 'none',
    color: "#006095"
}

const imgStyle = {
    width: '200px',
    height: '127px',
    marginTop: '30px',
    borderRadius: '5px'
}

class LogotipoUpload extends Component {
    constructor(props) {
        super(props);
        autoBind(this);
        this.state = {
            imagemOriginal: null,
            imagemOriginalUrl: null,
            modalCropImageVisible: false
        }
    }

    onUploadLogotipo(files, error) {
        if (error) {
            alert("Problema ao adicionar a imagem",
                "São permitidas imagens com tamanho máximo de 200KB e do tipo PNG")
        } else {
            this.setState({ imagemOriginal: files[0], imagemOriginalUrl: URL.createObjectURL(files[0]), modalCropImageVisible: true })
        }
    }

    onHideModalCropImage(imagemCortada) {
        if (imagemCortada) {
            this.props.onChange(imagemCortada)
        }
        this.setState({ imagemOriginal: null, imagemOriginalUrl: null, modalCropImageVisible: false })
    }

    render() {
        const { loading, logotipo, podeEditarEmpresa } = this.props

        return (
            <>
                <div style={{ alignItems: 'center', display: 'flex', flexDirection: 'column' }}>
                    <If test={loading}>
                        <ProgressSpinner style={{ marginTop: '40px' }} />
                    </If>
                    <If test={logotipo}>
                        <img style={imgStyle} src={logotipo} alt="" />
                        <Dropzone
                            multiple={false}
                            accept='image/png'
                            onDropAccepted={e => this.onUploadLogotipo(e, false)}
                            onDropRejected={e => this.onUploadLogotipo(e, true)}
                            maxSize={204800}
                            disabled={!podeEditarEmpresa}
                        >
                            {({ getRootProps, getInputProps }) => (
                                <div {...getRootProps()} >
                                    <input {...getInputProps()} />
                                    <Button
                                        label='SELECIONAR LOGOTIPO (.PNG)'
                                        style={{
                                            ...selecionarLogotipoStyle,
                                            opacity: !podeEditarEmpresa ? "0.5" : '1'
                                        }}
                                        title={!podeEditarEmpresa ? "Você não possui permissão para editar" : null}
                                    />
                                </div>
                            )}
                        </Dropzone>

                    </If>
                </div>
                <If test={this.state.modalCropImageVisible}>
                    <ModalCropImage
                        onHide={this.onHideModalCropImage}
                        visible={this.state.modalCropImageVisible}
                        imagemUrl={this.state.imagemOriginalUrl}
                    />
                </If>

            </>
        )
    }
}

export default LogotipoUpload
