import { del, post, get, put, exibirToast } from '../../../../../common/requisicoes';
import { services } from '../../../../../common/constantes/api';

export async function asyncDeleteAgenteAutorizado(agenteAutorizadoId, onSuccess, onError) {
    await del(`${services.GESTOR}/v1/agentes_autorizados/${agenteAutorizadoId}`, null, exibirToast(onSuccess, "Agente autorizado excluído com sucesso"), onError);
}

export async function asyncCreateAgenteAutorizado(agenteAutorizado, onSuccess, onError) {
    await post(`${services.GESTOR}/v1/agentes_autorizados`, agenteAutorizado, null, exibirToast(onSuccess, "Agente autorizado criado com sucesso"), onError);
}

export async function asyncUpdateAgenteAutorizado(agenteAutorizado, onSuccess, onError) {
    await put(`${services.GESTOR}/v1/agentes_autorizados/${agenteAutorizado.id}`, agenteAutorizado, null, exibirToast(onSuccess, "Agente autorizado atualizado com sucesso"), onError);
}

export async function asyncGetAgenteAutorizado(agenteAutorizadoId, onSuccess, onError) {
    await get(`${services.GESTOR}/v1/agentes_autorizados/${agenteAutorizadoId}`, null, exibirToast(onSuccess), onError);
}