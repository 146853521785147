import { get, post, put, exibirToast } from "../../../../../../common/requisicoes";
import { services } from "../../../../../../common/constantes/api";

export async function asyncCreateNegociacao(negociacao, onSuccess, onError) {
    await post(`${services.GESTOR}/v1/investidor/negociacoes`, negociacao, null, exibirToast(onSuccess, "Negociação criada com sucesso"), onError);
}

export async function asyncGetNegociacao(idNegociacao, onSuccess, onError) {
    await get(`${services.GESTOR}/v1/investidor/negociacoes/${idNegociacao}`, null, onSuccess, onError);
}

export async function asyncUpdateNegociacao(negociacao, onSuccess, onError) {
    await put(`${services.GESTOR}/v1/investidor/negociacoes/${negociacao.id}`, negociacao, null, exibirToast(onSuccess, "Negociação atualizada com sucesso"), onError);
}

