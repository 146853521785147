import { tipoCampos } from "../../../../../components/PesquisaAvancada/util/constantes";

export const optionsFiltroAvancado = [
    { label: 'Número', name: 'numero', type: tipoCampos.INTEGER },
    {
        label: 'Status', name: 'status', type: tipoCampos.SELECT,
        optionSelect: [
            { label: "Orçamento", value: "ORCAMENTO" },
            { label: "Venda", value: "VENDA" },
            { label: "Cancelada", value: "CANCELADA" },
        ]
    },
    { label: 'Valor', name: 'totalLiquido', type: tipoCampos.DECIMAL },
];

export const ColorsCard = {
    ORCAMENTO: {
        lightColor: '#b3e5fc',
        strongColor: '#01579B',
    },
    CANCELADA: {
        lightColor: '#ffcdd2',
        strongColor: '#b71c1c',
    },
    VENDA: {
        lightColor: '#dcedc8',
        strongColor: '#1b5e20',
    },
    TOTAL: {
        lightColor: '#e0e0e0',
        strongColor: '#000000de',
    },
}

export const colorsStatus = {
    ORCAMENTO: {
        lightColor: '#b3e5fc',
        strongColor: '#01579B',
    },
    VENDA: {
        lightColor: '#dcedc8',
        strongColor: '#1b5e20',
    },
    CANCELADA: {
        lightColor: '#ffcdd2',
        strongColor: '#b71c1c',
    }
};

export const Status = {
    ORCAMENTO: 'ORCAMENTO',
    VENDA: 'VENDA',
    CANCELADA: 'CANCELADA'
};

export const Operacao = {
    INSERIR: 'INSERIR',
    EDITAR: 'EDITAR',
    EXCLUIR: 'EXCLUIR',
};

export const CADASTROURL = '/vendas/cadastro';
export const PESQUISAURL = '/vendas';
