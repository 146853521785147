import { get, del, exibirToast } from "../../../../common/requisicoes";
import { services } from "../../../../common/constantes/api";
import { formatISO, format } from "date-fns";

export async function asyncGetLancamentos(url, onSuccess, onError) {
    return await get(url, null, onSuccess, onError, true)
}

export async function asyncRemoveLancamento(idLancamento, onSuccess, onError) {
    return await del(`${services.GESTOR}/v1/lancamentos/${idLancamento}`, null, exibirToast(onSuccess, "Movimentação excluída com sucesso"), onError, true)
}

export async function asyncGetLancamento(idLancamento, onSuccess, onError) {
    await get(`${services.GESTOR}/v1/lancamentos/${idLancamento}`, null, onSuccess, onError);
}

export async function asyncGetTotalizadores(filtro, conta, onSuccess, onError) {
    const filtroConta = conta ? `&conta=${conta}` : ''
    return await get(`${services.GESTOR}/v1/lancamentos/totais?dataInicio=${formatISO(filtro.dataInicial, { representation: 'date' })}&dataFim=${formatISO(filtro.dataFinal, { representation: 'date' })}${filtroConta}`, null, onSuccess, onError, true)
}

export async function asyncGetContas(onSuccess, onError) {
    return await get(`${services.GESTOR}/v1/lancamentos/relacoes/contas?query=ativa==true&size=500`, null, onSuccess, onError, true)
}

export async function asyncGetConta(idConta, onSuccess, onError) {
    await get(`${services.GESTOR}/v1/contas/${idConta}`, null, onSuccess, onError);
}

export async function asyncGetCategoriasDespesas(onSuccess, onError) {
    return await get(`${services.GESTOR}/v1/lancamentos/relacoes/categorias/despesas?size=500`, null, onSuccess, onError, true)
}

export async function asyncGetCategoriasReceita(onSuccess, onError) {
    return await get(`${services.GESTOR}/v1/lancamentos/relacoes/categorias/receitas?size=500`, null, onSuccess, onError, true)
}

export async function asyncBaixarRelatorioMovimentacoesPorPeriodo(dataInicial, dataFinal, contaId, onSuccess, onError) {
    const dataInicialFormatada = format(dataInicial, 'yyyy-MM-dd');
    const dataFinalFormatada = format(dataFinal, 'yyyy-MM-dd');
    return await get(`${services.GESTOR}/v1/lancamentos/imprimir/movimentacoes_por_periodo?dataInicial=${dataInicialFormatada}&dataFinal=${dataFinalFormatada}&contaId=${contaId || ""}`, { responseType: "arraybuffer" }, onSuccess, onError);
}

export async function asyncGetContaPagar(idContaPagar, onSuccess, onError) {
    return await get(`${services.GESTOR}/v1/contas_pagar/${idContaPagar}`, null, onSuccess, onError);
}

export async function asyncGetContaReceber(idContaReceber, onSuccess, onError) {
    return await get(`${services.GESTOR}/v1/contas_receber/${idContaReceber}`, null, onSuccess, onError);
}
