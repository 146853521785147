export const formaPagamento = [
    { label: "Dinheiro", value: "DINHEIRO" },
    { label: "Cheque", value: "CHEQUE" },
    { label: "Cartão de crédito", value: "CARTAO_CREDITO" },
    { label: "Cartão de débito", value: "CARTAO_DEBITO" },
    { label: "Crédito na loja", value: "CREDITO_LOJA" },
    { label: "Vale alimentação", value: "VALE_ALIMENTACAO" },
    { label: "Vale refeição", value: "VALE_REFEICAO" },
    { label: "Vale presente", value: "VALE_PRESENTE" },
    { label: "Vale combustível", value: "VALE_COMBUSTIVEL" },
    { label: "Boleto bancário", value: "BOLETO_BANCARIO" },
    { label: "Sem Pagamento", value: "SEM_PAGAMENTO" },
    { label: "Depósito bancário", value: "DEPOSITO_BANCARIO" },
    { label: "Pagamento instantâneo (PIX)", value: "PAGAMENTO_INSTANTANEO_PIX"},
    { label: "Transferência bancária, Carteira digital", value: "TRANFERENCIA_BANCARIA_CARTEIRA_DIGITAL" },
    { label: "Programa de fidelidade, Cashback, Crédito virtual", value: "PROGRAMA_FIDELIDADE_CASHBACK_CREDITO_VIRTUAL" },
];