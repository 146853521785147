import React from 'react';
import { Field } from 'formik';
import Grid from '../../../../../components/Grid';
import FormGroup from '../../../../../components/FormGoup';
import TextArea from '../../../../../components/TextArea';

export function renderizarFieldsObservacao(setFieldValue, informacoesPermissoes) {

    return (
        <FormGroup>
            <Grid>
                <Field sm="12" md="12" lg='12' xl='12'
                    component={TextArea}
                    placeholder="Escreva sua observação aqui"
                    label='Observação'
                    name="observacao"
                    onChange={e => setFieldValue('observacao', e.target.value)}
                    {...informacoesPermissoes}
                />
            </Grid>
        </FormGroup>
    )
}