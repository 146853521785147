import { tipoCampos } from "../../../../components/PesquisaAvancada/util/constantes";

export const optionsFiltroAvancado = [
    {
        label: 'Operação', name: 'operacao', type: tipoCampos.SELECT,
        optionSelect: [
            { label: "Saldo inicial", value: "SALDO_INICIAL" },
            { label: "Compra", value: "COMPRA" },
            { label: "Venda", value: "VENDA" },
        ]
    },
    {
        label: 'Mercado', name: 'mercado', type: tipoCampos.SELECT,
        optionSelect: [
            { label: "A Vista", value: "VISTA" },
            { label: "Fracionário", value: "FRACIONARIO" },
        ]
    },
    { label: 'Quantidade', name: 'quantidade', type: tipoCampos.DECIMAL },
    { label: 'Preço', name: 'preco', type: tipoCampos.DECIMAL },
    { label: 'Valor total', name: 'valorTotal', type: tipoCampos.DECIMAL },
]

export const ColorsCard = {
    COMPRA: {
        lightColor: '#dcedc8',
        strongColor: '#1b5e20',
    },
    VENDA: {
        lightColor: '#ffcdd2',
        strongColor: '#b71c1c',
    },
    TOTAL: {
        lightColor: '#e0e0e0',
        strongColor: '#000000de',
    }
}

export const operacaoTipo = {
    SALDO_INICIAL: 'SALDO_INICIAL',
    COMPRA: 'COMPRA',
    VENDA: 'VENDA'
};

