import React from 'react';
import propTypes from 'prop-types';
import { AiFillCloseCircle } from 'react-icons/ai';
import NotificacaoTotalRegistros from '../NotificacaoTotalRegistros';
import Grid from '../../../../../../../components/Grid';
import Col from '../../../../../../../components/Col';
import { formatarMonetario } from '../../../../../../../common/mascara';

const styleCardContent = {
    borderRadius: '5px',
    cursor: 'pointer',
    padding: '7px',
    height: '100%'
};

const styleCloseIcon = {
    float: 'right',
    margin: '-15px -15px 0px -15px',
    fontSize: '20px',
    backgroundColor: 'white',
    borderRadius: '50%',
    color: '#006095',
};

const styleValue = {
    fontSize: '18px',
    fontWeight: 'bold',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textAlign: 'end',
    paddingBottom: '0px',
};

const SelectableCard = ({ colors, title, value = 0, numberOfElements = 0, onSelect, name, selected, helpMessage, titleFiltro }) => {

    const getContentStyle = () => {
        if (selected) {
            return {
                ...styleCardContent,
                border: `2px solid #006095`,
                boxShadow: '2px 4px 10px 0px #999999',
                margin: '-2px'
            };
        }
        return styleCardContent;
    }

    const titleFiltrar = !selected ? titleFiltro : `Clique para limpar o filtro`;
    const valorFormatado = formatarMonetario(value);

    return (
        <div className="p-card p-total-list-cards" onClick={() => onSelect(name)} style={getContentStyle()}>
            {selected && <AiFillCloseCircle style={styleCloseIcon} />}
            <Grid justifyBetween verticalAlignCenter title={titleFiltrar}>
                <Col col="6" title="Total de registros da categoria">
                    <NotificacaoTotalRegistros colors={colors} style={{ cursor: 'default' }} numberOfElements={numberOfElements} />
                </Col>

                <Col col="6" style={{ textAlign: 'end' }}>
                    <span title={helpMessage} style={{ color: '#424242', fontSize: '14px' }}>
                        {title}
                    </span>
                </Col>
            </Grid>
            <Grid>
                <Col title={valorFormatado} style={{ ...styleValue, color: colors.strongColor }}>
                    {valorFormatado}
                </Col>
            </Grid>
        </div>
    )
}

SelectableCard.propTypes = {
    selectable: propTypes.bool,
    selected: propTypes.bool,
    numberOfElements: propTypes.number.isRequired,
    onSelect: propTypes.func,
    value: propTypes.number.isRequired,
    colors: propTypes.object.isRequired,
    title: propTypes.string.isRequired,
    name: propTypes.string.isRequired,
}

export default SelectableCard;
