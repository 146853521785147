import React, { Component } from 'react'
import Grid from '../../../../../components/Grid';
import Col from '../../../../../components/Col';
import ProdutosAbaixoEstoqueMinimo from './components/ProdutosAbaixoEstoqueMinimo';
import GraficoValorEstoquePorPeriodo from './components/GraficoValorEstoquePorPeriodo';
import { connect } from 'react-redux';
import { usuarioPossuiPermissao } from '../../../../../common/autenticacao';
import { recursos, permissoes } from '../../../../../common/constantes/autorizacao';
import If from '../../../../../components/If';
import ModalSaidaEstoque from '../../../../estoque/Movimentacoes/components/ModalSaidaEstoque';
import ModalEntradaEstoque from '../../../../estoque/Movimentacoes/components/ModalEntradaEstoque';
import { Button } from 'primereact/button';
import reactAutobind from 'react-autobind';

class DashboardEstoque extends Component {
    constructor(props) {
        super(props);

        reactAutobind(this);

        this.state = {
            atualizarGraficoNovamente: null,
            podeVisualizarEstoque: usuarioPossuiPermissao(recursos.ESTOQUE_DASHBOARD, permissoes.VISUALIZAR),
            exibirModalLancamentoEntrada: false,
            exibirModalLancamentoSaida: false
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.dataAtualizacaoMenuLateral !== this.props.dataAtualizacaoMenuLateral) {
            this.setState({
                atualizarGraficoNovamente: this.getNewTimestamp(),
                podeVisualizarEstoque: usuarioPossuiPermissao(recursos.ESTOQUE_DASHBOARD, permissoes.VISUALIZAR)
            })
        }
    }

    getNewTimestamp = () => new Date().getTime();

    onHideModal() {
        this.setState({
            exibirModalLancamentoEntrada: false,
            exibirModalLancamentoSaida: false
        });
        this.atualizarGraficos();
    }

    atualizarGraficos() {
        this.setState({ atualizarGraficoNovamente: this.getNewTimestamp() })
    }

    render() {

        const { dataInicial, dataFinal, isMobile } = this.props;
        const { exibirModalLancamentoEntrada, exibirModalLancamentoSaida } = this.state;

        const paddingCards = isMobile ? { padding: '0.5em 0em' } : { padding: '0.5em' };

        const styleButtons = {
            margin: isMobile ? '3px 5px' : '3px 7px',
            width: isMobile ? 'calc(50% - 10px)' : '185px'
        };

        const podeInserirMovimentacaoEstoque = usuarioPossuiPermissao(recursos.ESTOQUE_MOVIMENTACOES, permissoes.INSERIR);

        if (dataInicial && dataFinal) {
            return (
                <Grid style={{ margin: '0px' }}>

                    {this.state.podeVisualizarEstoque && (
                        <>
                            <Col style={{ padding: '0px' }}>
                                <Button
                                    label="Nova entrada"
                                    className="p-button-success"
                                    onClick={() => this.setState({ exibirModalLancamentoEntrada: true })}
                                    style={styleButtons}
                                    disabled={!podeInserirMovimentacaoEstoque}
                                    title={!podeInserirMovimentacaoEstoque ? "Você não possui permissão para movimentar estoque" : null}
                                />
                                <Button
                                    label="Nova saída"
                                    className="p-button-danger"
                                    onClick={() => this.setState({ exibirModalLancamentoSaida: true })}
                                    style={styleButtons}
                                    disabled={!podeInserirMovimentacaoEstoque}
                                    title={!podeInserirMovimentacaoEstoque ? "Você não possui permissão para movimentar estoque" : null}
                                />
                            </Col>
                            <Col xs="12" sm="12" md="12" lg="12" xl="12" style={paddingCards}>
                                <GraficoValorEstoquePorPeriodo
                                    dataInicial={dataInicial}
                                    dataFinal={dataFinal}
                                    atualizarGraficoNovamente={this.state.atualizarGraficoNovamente}
                                />
                            </Col>
                            <Col xs="12" sm="12" md="6" lg="6" xl="4" style={paddingCards}>
                                <ProdutosAbaixoEstoqueMinimo
                                    atualizarGraficoNovamente={this.state.atualizarGraficoNovamente}
                                    atualizarGraficos={() => this.setState({ atualizarGraficoNovamente: this.getNewTimestamp() })}
                                />
                            </Col>
                        </>
                    )}
                    <If test={exibirModalLancamentoSaida}>
                        <ModalSaidaEstoque
                            onNovoClick={() => this.setState({ produtoSelecionado: null })}
                            visible={exibirModalLancamentoSaida}
                            onHide={this.onHideModal}
                        />
                    </If>
                    <If test={exibirModalLancamentoEntrada}>
                        <ModalEntradaEstoque
                            onNovoClick={() => this.setState({ produtoSelecionado: null })}
                            visible={exibirModalLancamentoEntrada}
                            onHide={this.onHideModal}
                        />
                    </If>
                </Grid >
            )
        } else {
            return <span>Carregando informações...</span>
        }
    }
}

const mapStateToProps = state => ({
    isMobile: state.dispositivo.isMobile,
    dataAtualizacaoMenuLateral: state.menuLateral.dataAtualizacaoMenuLateral,
})
export default connect(mapStateToProps)(DashboardEstoque);
