import React, { Component } from 'react';
import { ProgressBar } from 'primereact/progressbar';
import FalhaAoCarregarAsInformacoes from '../../../FalhaAoCarregarAsInformacoes';
import { buscarProdutosMaisVendidos } from './requests';
import autoBind from "react-autobind"
import RankingMaisVendidos from '../components/RankingMaisVendidos'
import NenhumaInformacaoEncontrada from '../../../NenhumaInformacaoEncontrada';
import TituloCard from '../../../../../../../components/TituloCard';

const cardStyle = {
    padding: '7px',
    borderRadius: '5px',
    backgroundColor: 'white',
    boxShadow: '#00000033 0px 1px 3px 0px, #00000024 0px 1px 1px 0px, #0000001f 0px 2px 1px -1px',
    minHeight: '100%'
}

const dashboardContent = {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
}

export default class ListaProdutosMaisVendidos extends Component {

    constructor(props) {
        super(props)
        autoBind(this)
        this.state = {
            produtos: [],
            totalElements: 0,
            page: 0
        }
    }

    componentDidMount() {
        this.carregarInformacoes();
    }

    componentDidUpdate(prevProps) {
        const { dataInicial, dataFinal } = this.props;

        if (prevProps.dataInicial !== dataInicial || prevProps.dataFinal !== dataFinal) {
            this.carregarInformacoes();
        }

        if (prevProps.atualizarGraficoNovamente !== this.props.atualizarGraficoNovamente) {
            this.carregarInformacoes(false);
        }
    }

    carregarInformacoes(exibirLoading = true) {
        const { dataInicial, dataFinal } = this.props;

        this.setState({ loading: exibirLoading, error: false }, () => {
            buscarProdutosMaisVendidos(dataInicial, dataFinal, 0, ({ data }) => {
                this.setState({
                    totalElements: data.totalElements,
                    produtos: data.content,
                    error: false,
                    loading: false
                })
            }, () => {
                this.setState({ error: true, loading: false })
            });
        })
    }

    onVerMaisClick() {
        const { dataInicial, dataFinal } = this.props;
        const page = this.state.page + 1
        buscarProdutosMaisVendidos(dataInicial, dataFinal, page, ({ data }) => {
            this.setState({
                totalElements: data.totalElements,
                produtos: [...this.state.produtos, ...data.content],
                error: false,
                loading: false,
                page
            })
        }, () => {
            this.setState({ error: true, loading: false })
        });
    }

    render() {
        const { loading, error } = this.props
        const { page, totalElements, produtos } = this.state

        if (loading || error || produtos.length === 0) {
            return (
                <div style={cardStyle}>
                    <TituloCard
                        title={"Produtos mais vendidos"}
                        helpMessage="Lista os produtos mais vendidos no período selecionado"
                    />
                    <div style={dashboardContent}>
                        {loading && <ProgressBar mode="indeterminate" style={{ height: '3px', width: '100%', margin: '10px 0px' }} />}
                        {error && <FalhaAoCarregarAsInformacoes />}
                        {produtos.length === 0 && <NenhumaInformacaoEncontrada />}
                    </div>
                </div>
            )
        } else {
            const itens = produtos.map((item => {
                return {
                    descricao: item.produtoNome,
                    valor: item.valorVendas,
                }
            }))
            return (
                <div style={cardStyle}>
                    <TituloCard
                        title={"Produtos mais vendidos"}
                        helpMessage="Lista os produtos mais vendidos no período selecionado"
                    />
                    <RankingMaisVendidos
                        itens={itens}
                        exibirButtonVerMais={(page + 1) * 10 < totalElements}
                        onVerMaisClick={this.onVerMaisClick}
                    />
                </div>
            );
        }
    }
}
