import React, { Component } from 'react'

const style = {
    margin: 0,
    color: '#006095',
    fontSize: '12px'
}

class NfeLinkVerDados extends Component {
    render() {
        const { label, onClick } = this.props

        return <p style={style} onClick={onClick} className='link_to'>{label}</p>

    }
}

export default NfeLinkVerDados;