import React, { Component } from "react";
import FormGroup from "../../../components/FormGoup/index";
import Card from "../../../components/Card";
import { Checkbox } from "primereact/components/checkbox/Checkbox";
import logo from "../../../layout/images/logo_microsys.svg";
import { mensagensDeValidacao } from "../../../common/constantes/mensagens";
import InputField from "../../../components/Input//InputField";
import { fazerLogin, buscarDadosLoginLocalStorage } from "../../../common/autenticacao";
import HotKey from "react-shortcut";
import { cardStyle, imgStyle, errorStyle, subTitleStyle, contentStyle } from "../style";
import { withFormik, Field } from "formik";
import * as Yup from "yup";
import { prompt } from "../../../components/Toast";
import { asyncRecuperarSenha } from "./requests";
import Col from "../../../components/Col";
import Button from "../../../components/Button";
import { withRouter } from "react-router";
import { tratarMensagemErroLogin } from "./utils";
import autoBind from "react-autobind";
import Grid from "../../../components/Grid";
import { validarFormulario } from "../../util";
import If from "../../../components/If";
import AutoProgressBar from "../../../components/Loading/AutoProgressBar";
import { Link } from "react-router-dom";
import { buscarItemLocalStorage, removerItemLocalStorage } from "../../../common/localStorage";
import { DADOSLOCALSTORAGE } from "../../../common/constantes/localStorage";

export class Login extends Component {
    constructor(props) {
        super(props);
        autoBind(this);

        this.state = {
            mensagemErro: ""
        }
    }

    componentDidMount() {
        const dadosLogin = buscarDadosLoginLocalStorage();
        if (dadosLogin) {
            this.props.setFieldValue("email", dadosLogin.email);

            if (dadosLogin.manterConectado) {
                this.props.setFieldValue("senha", dadosLogin.senha);
                setTimeout(() => this.fazerLogin(), 5);
            }
        }
    }

    async fazerLogin() {
        const { handleSubmit, values } = this.props

        handleSubmit();
        if (await validarFormulario(this.props)) {
            await fazerLogin((values.email).toLowerCase(), values.senha, values.manterConectado, () => {
                const ultimaRota = buscarItemLocalStorage(DADOSLOCALSTORAGE.ultimaRotaNaoAutenticada);

                if (ultimaRota) {
                    removerItemLocalStorage(DADOSLOCALSTORAGE.ultimaRotaNaoAutenticada);
                    this.props.history.push(ultimaRota);
                } else {
                    this.props.history.push("/");
                }
            }, e => {
                this.setState({
                    mensagemErro: tratarMensagemErroLogin(e.response)
                })
            }, false);
        }
    }

    esqueciMinhaSenhaClick(e) {
        e.preventDefault();
        prompt(
            "Confirme seu e-mail",
            "Insira seu endereço de e-mail abaixo e enviaremos um link com as instruções.",
            this.props.values.email,
            async (evt, value) => {
                await asyncRecuperarSenha(value);
            }
        );
    }

    render() {
        const { values, setFieldValue } = this.props

        return (
            <React.Fragment>
                <AutoProgressBar />
                <div style={contentStyle}>
                    <Card style={cardStyle}>
                        <If test={this.state.mensagemErro}>
                            <div style={errorStyle}>
                                <center>
                                    <h4 style={{ margin: 0, color: "#751111" }} dangerouslySetInnerHTML={{ __html: this.state.mensagemErro }}>

                                    </h4>
                                </center>
                            </div>
                        </If>
                        <div className="right-side p-g-12 p-lg-12" style={{ justifyContent: 'center', display: 'flex' }}>
                            <img
                                alt="Logo Microsys"
                                style={imgStyle}
                                src={logo}
                            />
                        </div>
                        <div className="left-side p-g-12 p-lg-12">
                            <h3 style={subTitleStyle}>
                                Bem-vindo, por favor informe seus dados de acesso.
                            </h3>

                            <div className="content-section implementation">
                                <FormGroup>
                                    <Grid>
                                        <Field
                                            component={InputField}
                                            placeholder="E-mail"
                                            name="email"
                                        />
                                        <Field
                                            component={InputField}
                                            type="password"
                                            name="senha"
                                            placeholder="Senha"
                                        />
                                        <Col style={{ paddingTop: "0px", paddingBottom: "0px" }}   >
                                            <a href="²"
                                                onClick={this.esqueciMinhaSenhaClick}
                                                style={{ fontSize: "13px", color: "#2399e5" }}
                                            >
                                                Esqueci minha senha
                                            </a>
                                        </Col>
                                        <Col>
                                            <Checkbox
                                                name="manterConhectado"
                                                inputId="manterConhectado"
                                                checked={values.manterConectado}
                                                onChange={(e) => setFieldValue("manterConectado", e.checked)}
                                            />
                                            <label htmlFor="manterConhectado" className="p-checkbox-label">Manter-me conectado</label>
                                        </Col>
                                    </Grid>
                                    <Button
                                        label="Entrar"
                                        style={{
                                            marginTop: "25px",
                                            height: "40px",
                                            width: "100%"
                                        }}
                                        onClick={this.fazerLogin}
                                    />
                                    <div style={{ textAlign: "center", marginTop: '5px' }}>
                                        <span>Não possui conta?</span>
                                        <Link to="/registro" > Criar conta</Link>
                                    </div>
                                </FormGroup>
                            </div>
                        </div>
                    </Card>
                </div>
                <HotKey
                    keys={["enter"]}
                    simultaneous
                    onKeysCoincide={this.fazerLogin}
                />
            </React.Fragment>
        );
    }
}

Login = withFormik({
    mapPropsToValues() {
        const dados = buscarDadosLoginLocalStorage();
        return {
            email: dados ? dados.email : "",
            senha: dados && dados.manterConectado ? dados.senha : "",
            manterConectado: dados ? dados.manterConectado : true
        };
    },

    validationSchema: Yup.object().shape({
        email: Yup.string().nullable().required(mensagensDeValidacao.OBRIGATORIO),
        senha: Yup.string().nullable().required(mensagensDeValidacao.OBRIGATORIO)
            .test(
                "len",
                "A senha deve ter no mínimo 8 caracteres",
                val => val && val.length >= 8
            )
    }),
})(Login);

export default withRouter(Login);
