import React, { Component } from 'react'
import Form from '../../../components/Form'
import autoBind from 'react-autobind/lib/autoBind'
import { usuarioPossuiPermissao } from '../../../common/autenticacao'
import { permissoes, recursos } from '../../../common/constantes/autorizacao'
import { construirUrl } from '../../../common/rsql'
import ButtonNovo from '../../../components/Button/ButtonNovo'
import FormActions from '../../../components/FormActions'
import FormContent from '../../../components/FormContent'
import Grid from '../../../components/Grid'
import InputSearchAtivos from './components/InputSearchAtivos';
import { asyncDeleteProvento, asyncGetProventos, asyncGetProventoPorId, asyncGetContas, asyncGetTotalizadores } from './request'
import { services } from '../../../common/constantes/api'
import TabelaProventos from './components/TabelaProventos'
import { confirmarExclusao } from '../../util/exclusaoDeRegistros';
import { removerElemento } from '../../../common/array'
import If from '../../../components/If'
import { SelectButton } from 'primereact/selectbutton';
import ModalProvento from './components/ModalProvento'
import PesquisaAvancada from '../../../components/PesquisaAvancada'
import { optionsFiltroAvancado, ColorsCard, Tipo } from './utils/constantes'
import DescricaoFiltroAvancado from '../../../components/DescricaoFiltroAvancado'
import DateInterval from '../../../components/DateInterval'
import { formatISO } from 'date-fns'
import CardTotalizadorListagem from './components/CardTotalizadorListagem'
import Col from '../../../components/Col'
import { converterProventoParaFormulario } from './utils/proventoConverter'

const selectButtonStyle = {
    whiteSpace: 'nowrap',
    overflowY: 'hidden',
    width: '100%',
}

class Proventos extends Component {

    constructor(props) {
        super(props)
        autoBind(this)
        this.state = {
            podeInserir: usuarioPossuiPermissao(recursos.INVESTIDOR_PROVENTOS, permissoes.INSERIR),
            podeExcluir: usuarioPossuiPermissao(recursos.INVESTIDOR_PROVENTOS, permissoes.EXCLUIR),
            podeEditar: usuarioPossuiPermissao(recursos.INVESTIDOR_PROVENTOS, permissoes.EDITAR),
            valorPesquisa: [],
            filtroData: "",
            filtroConta: null,
            optionsContas: [],
            interval: {
                dataInicial: null,
                dataFinal: null
            },
            cardTotalSelected: "",
            valorCard: "",
            cards: {
                dividendo: {
                    valor: 0,
                    quantidade: 0
                },
                rendimento: {
                    valor: 0,
                    quantidade: 0
                },
                jcp: {
                    valor: 0,
                    quantidade: 0
                }
            },
            optionsFiltroAvancado: optionsFiltroAvancado,
            registros: [],
            descricaoFiltroAvancado: '',
            limparFiltroPesquisaAvancada: false,
            sortField: 'data',
            valorFiltroAvancado: '',
            sortOrder: 1,
            totalElements: 0,
            tutorialVisible: false,
            exibirModalProvento: false,
            exibirModalExclusao: false,
            registroSelecionado: null,
            page: 0,
            rows: 0,
            size: 10,
            first: 0
        }
    }

    componentDidMount() {
        const { location } = this.props;
        if (location && location.state) {
            this.setState({
                valorPesquisa: [
                    ...this.state.valorPesquisa,
                    {
                        label: location.state.codigo + ' - ' + location.state.nome,
                        value: location.state.id,
                        registro: location.state
                    },
                ]
            })
            if (location.state.contaSelecionadaId) {
                this.setState({ filtroConta: location.state.contaSelecionadaId })
            }
        }
        this.buscarInformacoesComplementaresFiltro();
    }

    buscarInformacoesComplementaresFiltro() {
        asyncGetContas(({ data: contas }) => {
            let optionsContas = contas && contas.content.map(conta => {
                return { label: `${conta.corretora.nome} - ${conta.codigo}`, value: conta.id }
            })
            this.setState({ optionsContas })
        });
    }

    async pesquisar() {
        const { page, size, sortField, sortOrder, interval, filtroConta, valorPesquisa } = this.state
        const filtro = this.buscarFiltro();
        const url = construirUrl(`${services.GESTOR}/v1/investidor/proventos`, filtro || "?", size, page, sortOrder > 0 ? `${sortField},asc&sort=data,desc` : `${sortField},desc&sort=data,desc`);

        asyncGetTotalizadores(interval, filtroConta, valorPesquisa, ({ data: totais }) => {
            let cards = {};

            totais && totais.forEach(element => {
                if (element.tipo === Tipo.DIVIDENDO) {
                    cards.dividendo = {
                        quantidade: element.quantidade,
                        valor: element.valor
                    }
                } else if (element.tipo === Tipo.RENDIMENTO) {
                    cards.rendimento = {
                        quantidade: element.quantidade,
                        valor: element.valor
                    }
                } else if (element.tipo === Tipo.JCP) {
                    cards.jcp = {
                        quantidade: element.quantidade,
                        valor: element.valor
                    }
                }
            });
            this.setState({ cards })
        });
        
        asyncGetProventos(url, ({ data: ativos }) => {
            this.setState({
                registros: ativos.content,
                totalElements: ativos.totalElements
            })
        });
    }

    handleChangeInterval(interval) {
        this.setState({ page: 0, first: 0, interval: interval, filtroData: `data>=${formatISO(interval.dataInicial, { representation: "date" })};data<=${formatISO(interval.dataFinal, { representation: "date" })}` }, () => {
            this.pesquisar();
        })
    }

    buscarFiltro() {
        const { valorFiltroAvancado, filtroData, valorPesquisa, filtroConta, valorCard } = this.state;

        let filtroRSQL = String(`?query=(${filtroData}`)

        if (valorPesquisa && valorPesquisa.length > 0) {
            filtroRSQL = filtroRSQL.concat(`;ativo.id=in=(${valorPesquisa.map(el => el.value)})`)
        }

        if (valorFiltroAvancado) {
            filtroRSQL = filtroRSQL.concat(`;(${valorFiltroAvancado})`)
        }

        if (filtroConta) {
            filtroRSQL = filtroRSQL.concat(`;(conta.id==${filtroConta})`)
        }

        if (valorCard) {
            filtroRSQL = filtroRSQL.concat(`;(${valorCard})`)
        }

        filtroRSQL = filtroRSQL.concat(`)`)

        return filtroRSQL;
    }

    async handleEditItem(item) {
        await asyncGetProventoPorId(item.id, ({ data: provento }) => {
            this.setState({ registroSelecionado: converterProventoParaFormulario(provento), exibirModalProvento: true })
        })
    }

    onHideModalProvento() {
        this.setState({
            exibirModalProvento: false,
            registroSelecionado: null
        })
        this.pesquisar()
    }

    async handleRemoveItem(item) {
        confirmarExclusao(() => this.asyncDeleteProvento(item))
    }

    async asyncDeleteProvento(registro) {
        await asyncDeleteProvento(registro.id, () => {
            this.setState({
                registros: removerElemento(this.state.registros, registro),
                totalElements: this.state.totalElements - 1
            })
            this.pesquisar()
        })
    }

    onPesquisarFiltroAvancado(e) {
        this.setState({ valorFiltroAvancado: e, limparFiltroPesquisaAvancada: false }, () => {
            this.pesquisar()
        });
    }

    onPesquisar() {
        this.setState({ page: 0, first: 0 }, () => {
            this.pesquisar();
        })
    }

    onPageChange(e) {
        this.setState({ page: e.page, rows: e.rows }, () => {
            this.pesquisar()
        })
    }

    handleSelectConta(e) {
        this.setState({ filtroConta: e.value }, () => this.pesquisar())
    }

    handleSelectCardRendimento() {
        const card = Tipo.RENDIMENTO;
        const filtroRSQL = 'tipo==RENDIMENTO';
        this.pesquisarRSQLCardTotais(card, filtroRSQL);
    }

    handleSelectCardDividendo() {
        const card = Tipo.DIVIDENDO;
        const filtroRSQL = 'tipo==DIVIDENDO';
        this.pesquisarRSQLCardTotais(card, filtroRSQL);
    }

    handleSelectCardJCP() {
        const card = Tipo.JCP;
        const filtroRSQL = 'tipo==JCP';
        this.pesquisarRSQLCardTotais(card, filtroRSQL);
    }

    pesquisarRSQLCardTotais(cardSelected, valueCard) {
        const { cardTotalSelected } = this.state;

        if (cardTotalSelected === cardSelected) {
            this.setState({
                cardTotalSelected: "",
                valorCard: "",
                page: 0,
                first: 0
            }, () => {
                this.pesquisar();
            });
        } else {
            this.setState({
                cardTotalSelected: cardSelected,
                valorCard: valueCard,
                page: 0,
                first: 0
            }, () => {
                this.pesquisar();
            });
        }
    };

    render() {
        const {
            cards,
            cardTotalSelected,
            registroSelecionado,
            descricaoFiltroAvancado,
            limparFiltroPesquisaAvancada,
            optionsFiltroAvancado,
            exibirModalProvento,
            size,
            first,
            podeInserir,
            totalRecords,
            sortField,
            sortOrder,
            registros,
            filtroConta,
            optionsContas,
            valorPesquisa
        } = this.state

        const { isMobile } = this.props;

        return (
            <>
                <Form header="Proventos">
                    <FormActions>
                        <ButtonNovo
                            label="Novo provento"
                            title="Inserir um novo provento"
                            onClick={() => this.setState({ exibirModalProvento: true, registroSelecionado: null })}
                            disabled={!podeInserir}
                        />
                    </FormActions>
                    <FormContent>
                        <Grid justifyCenter verticalAlignCenter>
                            <span style={{ padding: '12px' }}>
                                <DateInterval
                                    onChange={this.handleChangeInterval}
                                />
                            </span>
                            <InputSearchAtivos
                                onPesquisar={this.onPesquisar}
                                value={valorPesquisa}
                                onChange={value => this.setState({ valorPesquisa: value }, this.onPesquisar())}
                            />
                            <span style={{ padding: '12px' }}>
                                <PesquisaAvancada
                                    optionsFiltros={optionsFiltroAvancado}
                                    onPesquisarClick={this.onPesquisarFiltroAvancado}
                                    onChangeFiltroRsql={rsql => this.setState({ valorFiltroAvancado: rsql })}
                                    onChangeDescricaoFiltro={e => this.setState({ descricaoFiltroAvancado: e })}
                                    liparFiltro={limparFiltroPesquisaAvancada}
                                />
                            </span>
                        </Grid>
                        <If test={optionsContas.length > 0}>
                            <Grid
                                verticalAlignCenter
                                justifyCenter
                                style={{ marginBottom: '10px', width: '100%' }}
                                className='grid-select-filtro'
                            >
                                <SelectButton
                                    style={selectButtonStyle}
                                    className='select-button-light'
                                    value={filtroConta}
                                    options={optionsContas}
                                    onChange={this.handleSelectConta}
                                />
                            </Grid>
                        </If>
                        <Grid justifyBetween>
                            <Col xs="12" sm="6" md="6" lg="3" xl="3">
                                <CardTotalizadorListagem
                                    name="cardRendimento"
                                    title="Rendimentos"
                                    helpMessage={!isMobile ? "Exibe o valor total e o número de rendimentos no período selecionado" : ""}
                                    titleFiltro="Clique para filtrar pelos rendimentos"
                                    colors={ColorsCard.RENDIMENTO}
                                    value={cards.rendimento.valor}
                                    selectable
                                    numberOfElements={cards.rendimento.quantidade}
                                    selected={cardTotalSelected === Tipo.RENDIMENTO}
                                    onSelect={this.handleSelectCardRendimento}
                                />
                            </Col>
                            <Col xs="12" sm="6" md="6" lg="3" xl="3">
                                <CardTotalizadorListagem
                                    name="cardDividendo"
                                    title="Dividendos"
                                    helpMessage={!isMobile ? "Exibe o valor total e o número de dividendos no período informado" : ""}
                                    titleFiltro="Clique para filtrar pelos dividendos"
                                    colors={ColorsCard.DIVIDENDO}
                                    value={cards.dividendo.valor}
                                    selectable
                                    numberOfElements={cards.dividendo.quantidade}
                                    selected={cardTotalSelected === Tipo.DIVIDENDO}
                                    onSelect={this.handleSelectCardDividendo}
                                />
                            </Col>
                            <Col xs="12" sm="6" md="6" lg="3" xl="3">
                                <CardTotalizadorListagem
                                    name="cardJCP"
                                    title="JCP"
                                    helpMessage={!isMobile ? "Exibe o valor total e o número de juros sobre capital próprio (JCP) no período informado" : ""}
                                    titleFiltro="Clique para filtrar pelos juros sobre capital próprio (JCP)"
                                    colors={ColorsCard.JCP}
                                    value={cards.jcp.valor}
                                    selectable
                                    numberOfElements={cards.jcp.quantidade}
                                    selected={cardTotalSelected === Tipo.JCP}
                                    onSelect={this.handleSelectCardJCP}
                                />
                            </Col>
                            <Col xs="12" sm="6" md="6" lg="3" xl="3">
                                <CardTotalizadorListagem
                                    name="cardTotalizador"
                                    title="Total"
                                    helpMessage={!isMobile ? "Exibe o valor resultante da soma do valor líquido dos proventos" : ""}
                                    colors={ColorsCard.TOTAL}
                                    value={cards.rendimento.valor + cards.dividendo.valor + cards.jcp.valor}
                                    numberOfElements={cards.rendimento.quantidade + cards.dividendo.quantidade + cards.jcp.quantidade}
                                />
                            </Col>
                        </Grid>
                        <Grid style={{ paddingTop: '10px' }} justifyCenter verticalAlignCenter>
                            <DescricaoFiltroAvancado texto={descricaoFiltroAvancado} />
                            <TabelaProventos
                                registros={registros}
                                totalRecords={totalRecords}
                                rows={size}
                                first={first}
                                sortField={sortField}
                                sortOrder={sortOrder}
                                setSortField={sortField => this.setState({ sortField })}
                                setSortOrder={sortOrder => this.setState({ sortOrder }, () => this.pesquisar())}
                                onEditItem={this.handleEditItem}
                                onRemoveItem={this.handleRemoveItem}
                                onPageChange={e => this.setState({ first: e.first, size: e.rows, page: e.page }, () => this.pesquisar())}
                            />
                        </Grid>
                    </FormContent>
                </Form>
                <If test={exibirModalProvento}>
                    <ModalProvento
                        registroSelecionado={registroSelecionado}
                        onNovoClick={() => this.setState({ registroSelecionado: null })}
                        onHide={this.onHideModalProvento}
                        visible={exibirModalProvento}
                        atualizarListagem={this.pesquisar}
                    />
                </If>
            </>
        )
    }
}

export default Proventos;