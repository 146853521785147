import { colorsStatus, Status } from "../../util/constantes";

export const helpMessage = {
    codigo: 'Código de identificação do documento',
    cliente: 'Cliente da venda ou orçamento',
    emissao: 'Data de emissão da venda ou orçamento',
    validade: 'Data de vencimento do orçamento',
    status: '',
    condicao: '',
    quantidade: '',
    informacaoComplementar: 'Estas informações serão incluídas na impressão do pedido',
    observacaoInterna: 'Estas informações NÃO serão incluídas na impressão do pedido',
    vendedor: 'Usuário do sistema responsável pelo orçamento ou venda',
    previsaoEntrega: 'Data prevista para entrega da venda ou orçamento'
};

export const condicaoDropdown = [
    { label: 'À vista', value: 'A_VISTA' },
    { label: 'A prazo', value: 'A_PRAZO' },
    { label: 'Sem pagamento', value: 'SEM_PAGAMENTO' },
];

export const statusDropdown = [
    { label: 'Orçamento', value: 'ORCAMENTO', lightColor: colorsStatus[Status.ORCAMENTO].lightColor, strongColor: colorsStatus[Status.ORCAMENTO].strongColor },
    { label: 'Venda', value: 'VENDA', lightColor: colorsStatus[Status.VENDA].lightColor, strongColor: colorsStatus[Status.VENDA].strongColor },
    { label: 'Cancelada', value: 'CANCELADA', lightColor: colorsStatus[Status.CANCELADA].lightColor, strongColor: colorsStatus[Status.CANCELADA].strongColor },
];

export const quantidadeParcelasDropdown = [
    { label: '1x', value: 1 },
    { label: '2x', value: 2 },
    { label: '3x', value: 3 },
    { label: '4x', value: 4 },
    { label: '5x', value: 5 },
    { label: '6x', value: 6 },
    { label: '7x', value: 7 },
    { label: '8x', value: 8 },
    { label: '9x', value: 9 },
    { label: '10x', value: 10 },
    { label: '11x', value: 11 },
    { label: '12x', value: 12 },
    { label: '13x', value: 13 },
    { label: '14x', value: 14 },
    { label: '15x', value: 15 },
    { label: '16x', value: 16 },
    { label: '17x', value: 17 },
    { label: '18x', value: 18 },
    { label: '19x', value: 19 },
    { label: '20x', value: 20 },
    { label: '21x', value: 21 },
    { label: '22x', value: 22 },
    { label: '23x', value: 23 },
    { label: '24x', value: 24 },
    { label: '25x', value: 25 },
    { label: '26x', value: 26 },
    { label: '27x', value: 27 },
    { label: '28x', value: 28 },
    { label: '29x', value: 29 },
    { label: '30x', value: 30 },
    { label: '31x', value: 31 },
    { label: '32x', value: 32 },
    { label: '33x', value: 33 },
    { label: '34x', value: 34 },
    { label: '35x', value: 35 },
    { label: '36x', value: 36 },
    { label: '37x', value: 37 },
    { label: '38x', value: 38 },
    { label: '39x', value: 39 },
    { label: '40x', value: 40 },
    { label: '41x', value: 41 },
    { label: '42x', value: 42 },
    { label: '43x', value: 43 },
    { label: '44x', value: 44 },
    { label: '45x', value: 45 },
    { label: '46x', value: 46 },
    { label: '47x', value: 47 },
    { label: '48x', value: 48 },
];

export const contaReceberStatus = {
    NAO_RECEBIDA: 'NAO_RECEBIDA',
    PARCIALMENTE_RECEBIDA: 'PARCIALMENTE_RECEBIDA',
    RECEBIDA: 'RECEBIDA',
};

export const Condicoes = {
    SEM_PAGAMENTO: 'SEM_PAGAMENTO',
    A_VISTA: 'A_VISTA',
    A_PRAZO: 'A_PRAZO'
};
