import React, { Component } from 'react';
import ModalPessoas from '../../../views/cadastros/Pessoas/Modal';
import autoBind from 'react-autobind';
import { recursos, permissoes } from '../../../common/constantes/autorizacao';
import SingleSelect from '../SingleSelect';
import propTypes from 'prop-types'
import { usuarioPossuiPermissao } from '../../../common/autenticacao';
import { tabsCadastro } from '../../../views/cadastros/Pessoas/utils/constantes';

class SingleSelectIntermediador extends Component {
    constructor(props) {
        super(props);

        autoBind(this);

        this.state = {
            visible: false,
            podeInserir: usuarioPossuiPermissao(recursos.VENDAS_VENDAS, permissoes.INSERIR),
            podeVisualizar: usuarioPossuiPermissao(recursos.VENDAS_VENDAS, permissoes.VISUALIZAR),
        }
    }

    buscarUrlPesquisa(pesquisa, page) {
        return `${this.props.url}?query=nome=contains="*${pesquisa}*";situacao=="ATIVA";tipo=="JURIDICA"&page=${page}&size=50&sort=nome`;
    }

    mostrarModal() {
        this.setState({ visible: !this.state.visible });
    }

    esconderModal() {
        this.setState({ ...this.state, visible: false })
    }

    setarNovoRegistroNoSelect(novoRegistro) {
        if (novoRegistro) {
            this.props.onChange({
                label: this.montarLabel({
                    ...novoRegistro,
                    cnpj: novoRegistro.cnpj
                }),
                value: novoRegistro.id,
                registro: {
                    nome: novoRegistro.nome,
                    cnpj: novoRegistro.cnpj
                }
            })
        }
    }

    onHide(novoRegistro) {
        this.setarNovoRegistroNoSelect(novoRegistro)
        this.esconderModal()
    }

    montarLabel(row) {
        return `${row.nome} - ${row.cnpj}`;
    }

    render() {
        const { podeInserir, podeVisualizar } = this.state
        const podeInserirEVisualizar = podeInserir && podeVisualizar

        return (
            <React.Fragment>
                <ModalPessoas
                    service={this.props.service}
                    visible={this.state.visible}
                    onHide={this.onHide}
                    tipo="JURIDICA"
                    tabSelecionada={tabsCadastro.PESSOA_JURIDICA}
                    validarIntermediador
                />
                <SingleSelect
                    titleBotaoNovo="Adicionar novo intermediador"
                    buscarUrlPesquisa={this.buscarUrlPesquisa}
                    montarLabel={this.montarLabel}
                    onClickModal={this.mostrarModal}
                    {...this.props}
                    disabledButton={!podeInserirEVisualizar}
                    onChange={this.props.onChange}
                />
            </React.Fragment>
        );
    }
}

SingleSelectIntermediador.defaultProps = {
    label: 'Intermediador',
}

SingleSelectIntermediador.propTypes = {
    /** Url do servidor em que as requisições de busca deverão ser efetuadas: Ex:  `${services.GESTOR}/v1/usuarios/relacoes/pessoas`*/
    url: propTypes.string.isRequired
};



export default SingleSelectIntermediador;
