import React from 'react'
import { recursos, permissoes } from '../../../../../common/constantes/autorizacao';
import { usuarioPossuiPermissao } from '../../../../../common/autenticacao';
import { connect } from 'react-redux';
import Grid from '../../../../../components/Grid';
import { Button } from 'primereact/button';
import { withRouter } from 'react-router-dom';
import DocumentosExpiracaoPeriodo from './components/ListagemDocumentosExpiracaoPeriodo';
import Col from '../../../../../components/Col';
import DocumentosAssinadosPeriodo from './components/ListagemDocumentosAssinadosPeriodo';
import CardTotalDocumentosPorStatus from './components/CardTotalDocumentosPorStatus';
import GraficoDocumentosPorStatusPeriodo from './components/GraficoDocumentosPorStatusPeriodo';
import GraficoDocumentosArquivadosPeriodo from './components/GraficoDocumentosArquivadosPeriodo';

class DashboardDocumentosDigitais extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            podeInserirDocumentos: usuarioPossuiPermissao(recursos.DOCUMENTOS, permissoes.INSERIR) && usuarioPossuiPermissao(recursos.DOCUMENTOS, permissoes.VISUALIZAR)
        }
    }

    render() {
        const { dataInicial, dataFinal, isMobile } = this.props;

        const styleButtons = {
            margin: isMobile ? '3px 5px' : '3px 7px',
            width: isMobile ? '100%' : '185px',
        };

        const classNameButtons = isMobile ? 'p-grid p-justify-center' : 'p-grid p-justify-start';
        const paddingCards = isMobile ? { padding: '0.5em 0em' } : { padding: '0.5em' };

        const { podeInserirDocumentos } = this.state

        if (dataInicial && dataFinal) {
            return (
                <Grid style={{ margin: '0px' }}>
                    <div className="p-col-12" style={{ padding: '0px' }}>
                        <div className={classNameButtons} style={{ margin: '0px' }}>
                            <Button
                                color="primary"
                                label="Novo documento"
                                style={styleButtons}
                                disabled={!podeInserirDocumentos}
                                onClick={() => this.props.history.push('documentos/cadastro')}
                            />
                        </div>
                    </div>
                    <Col xs="12" sm="12" md="12" lg="12" xl="6" style={paddingCards}>
                        <CardTotalDocumentosPorStatus />
                    </Col>

                    <Col xs="12" sm="12" md="12" lg="12" xl="6" style={paddingCards}>
                        <DocumentosExpiracaoPeriodo
                            dataInicial={dataInicial}
                            dataFinal={dataFinal}
                        />
                    </Col>
                    <Col xs="12" sm="12" md="12" lg="12" xl="6" style={paddingCards}>
                        <DocumentosAssinadosPeriodo
                            dataInicial={dataInicial}
                            dataFinal={dataFinal}
                        />
                    </Col>
                    <Col xs="12" sm="12" md="12" lg="12" xl="6" style={paddingCards}>
                        <GraficoDocumentosPorStatusPeriodo
                            dataInicial={dataInicial}
                            dataFinal={dataFinal}
                        />
                    </Col>
                    <Col xs="12" sm="12" md="12" lg="12" xl="12" style={paddingCards}>
                        <GraficoDocumentosArquivadosPeriodo
                            dataInicial={dataInicial}
                            dataFinal={dataFinal}
                        />
                    </Col>
                </Grid>
            )
        } else {
            return <span>Carregando informações...</span>
        }
    }
}


const mapStateToProps = state => ({
    isMobile: state.dispositivo.isMobile,
})
export default connect(mapStateToProps)(withRouter(DashboardDocumentosDigitais));