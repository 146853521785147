import React, { Component } from 'react';
import autoBind from 'react-autobind';
import Grid from '../../../../../../../../../../components/Grid';
import Col from '../../../../../../../../../../components/Col';
import { InternalInputDouble } from '../../../../../../../../../../components/Input/InputDouble';
import { styleNumeracaoDefault } from './styles';
import AcoesMemorizados from './components/AcoesMemorizados';
import VencimentoMemorizado from './components/VencimentoMemorizado';
import { Condicoes } from '../../../../../../util/constantes';
import FormaPagamentoMemorizada from './components/FormaPagamentoMemorizada';
//import DescricaoMemorizada from './components/DescricaoMemorizada';

class ParcelasMemorizadas extends Component {
    constructor(props) {
        super(props)

        autoBind(this);
    }

    render() {

        const {
            onChangeVencimento,
            onChangeValor,
            //onChangeDescricao,
            labelParcela,
            valueVencimento,
            valueValor,
            //valueDescricao,
            exibirLabels,
            isMobile,
            backgroundColor,
            errors,
            excluirDisabled,
            onClickExcluir,
            onChangeFormaPagamento,
            valueFormaPagamento,
            informacoesPermissoes,
            condicaoPagamento,
            disabled
        } = this.props;

        const isAvista = condicaoPagamento === Condicoes.A_VISTA

        return (
            <>
                <Grid style={{ margin: '0px 0px', background: isMobile && backgroundColor, paddingTop: '7px', paddingBottom: errors ? '10px' : undefined }}>
                    <Col col="6" sm="6" md="4" lg='3' xl='3'
                        style={{ padding: '0px 5px' }}
                    >
                        <Grid>
                            <Col
                                style={{
                                    ...styleNumeracaoDefault,
                                    padding: '0px',
                                    margin: exibirLabels ? '10px 0px 0px 0px' : '0px 0px 10px 0px'
                                }}
                            >
                                {labelParcela}
                            </Col>
                            <VencimentoMemorizado
                                colStyle={{ width: 'calc(100% - 30px)', paddingLeft: isMobile ? "0px" : undefined, paddingRight: isMobile ? "0px" : undefined }}
                                disabled={isAvista || disabled}
                                exibirLabels={exibirLabels}
                                onChangeVencimento={onChangeVencimento}
                                valueVencimento={valueVencimento}
                                errors={errors}
                                isMobile={isMobile}
                                informacoesPermissoes={informacoesPermissoes}
                            />
                        </Grid>
                    </Col>
                    <InternalInputDouble
                        colStyle={{ padding: "0px 5px", marginBottom: errors && errors.vPag ? '12px' : undefined }}
                        col="6" sm="6" md="4" lg='3' xl='3'
                        label={exibirLabels ? 'Valor' : ""}
                        helpMessage={exibirLabels && !isMobile ? 'Valor da parcela' : ""}
                        name="vPag"
                        size={13}
                        disabled={isAvista || disabled}
                        obrigatorio={exibirLabels}
                        onChange={onChangeValor}
                        value={valueValor}
                        placeholder='R$ 0,00'
                        prefix='R$ '
                        errors={errors && errors.vPag}
                        touched
                        allowNegative={false}
                        {...informacoesPermissoes}
                    />

                    <Col
                        col="12" sm="12" md="4" lg='4' xl='4'
                        style={{ padding: '0px 5px' }}
                    >
                        <Grid verticalAlignCenter>
                            <FormaPagamentoMemorizada
                                isMobile={isMobile}
                                exibirLabels={exibirLabels}
                                valueFormaPagamento={valueFormaPagamento}
                                onChangeFormaPagamento={onChangeFormaPagamento}
                                informacoesPermissoes={informacoesPermissoes}
                                disabled={disabled}
                            />
                            
                            
                            {/* IMPORTANTE: componente do Primefaces desatualizado causando bug, aberto tarefa para ser visto depois de atualizar o Primefaces #13382
                            <DescricaoMemorizada
                                isMobile={isMobile}
                                valueDescricao={valueDescricao}
                                onChangeDescricao={onChangeDescricao}
                                exibirLabels={exibirLabels}
                                informacoesPermissoes={informacoesPermissoes}
                            /> */}
                            <AcoesMemorizados
                                isMobile={isMobile}
                                exibirLabels={exibirLabels}
                                excluirDisabled={excluirDisabled}
                                onClickExcluir={excluirDisabled ? null : onClickExcluir}
                                disabled={disabled}
                            />
                        </Grid>
                    </Col>
                </Grid>
            </>
        )
    }
}

function parcelasMemorizadasSaoIguais(renderAnterior, renderAtual) {
    return renderAnterior.valueVencimento === renderAtual.valueVencimento &&
        renderAnterior.valueValor === renderAtual.valueValor &&
        //renderAnterior.valueDescricao === renderAtual.valueDescricao &&
        renderAnterior.isMobile === renderAtual.isMobile &&
        renderAnterior.errors === renderAtual.errors &&
        renderAnterior.index === renderAtual.index &&
        renderAnterior.exibirLabels === renderAtual.exibirLabels &&
        renderAnterior.valueFormaPagamento === renderAtual.valueFormaPagamento &&
        renderAnterior.condicaoPagamento === renderAtual.condicaoPagamento
}

export default React.memo(ParcelasMemorizadas, parcelasMemorizadasSaoIguais);
