import React, { Component } from 'react'
import autoBind from 'react-autobind';
import InputSelectPercentualOrValor from '../../../../../../components/Input/InputSelectPercentualOrValor';

class InputSelectPercentualOrValorNfce extends Component {

    constructor(props) {
        super(props)
        autoBind(this)
    }

    render() {
        return (
            <InputSelectPercentualOrValor
                sizeValor={9}
                {...this.props}
            />
        )
    }
}

export default InputSelectPercentualOrValorNfce;