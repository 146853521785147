
export const tutorialSteps = [
    {
        target: 'body',
        content: `Esta é uma das telas de pesquisa. Nela você pode pesquisar, adicionar, editar e remover registros`,
        disableBeacon: true,
        placement: 'center',
    },{
        target: '.step-listagem-novo',
        content: 'No botão novo, você pode inserir novos registros',
    },{
        target: '.step-listagem-input-search',
        content: 'Neste campo, você pode pesquisar o registro desejado',
    },{
        target: '.step-listagem-filtro-avancado',
        content: 'Neste campo, você pode configurar filtros para otimizar a busca por registros específicos',
    },{
        target: '.step-listagem-order',
        content: 'Você pode clicar no título da coluna para ordenar',
    },{
        target: '.step-listagem-acoes',
        content: 'Na coluna "Ações", você pode executar ações para cada registro separadamente',
    }
]