import { get, post, exibirToast, del } from "../../../../common/requisicoes";
import { services } from "../../../../common/constantes/api";

export async function asyncGetPlano(onSuccess, onError) {
    return await get(`${services.GESTOR}/v1/planos`, null, onSuccess, onError);
}

export async function asyncGetInformacoesGeraisPlano(onSuccess, onError) {
    return await get(`${services.GESTOR}/v1/planos/informacoes_gerais`, null, onSuccess, onError, false);
}

export async function asyncAderirAoPlano(planoId, mensagem ,onSuccess, onError ) {
    return await post(`${services.GESTOR}/v1/planos/${planoId}/aderir`, null,null, exibirToast(onSuccess, mensagem) , onError);
}

export async function asyncGetCartao(onSuccess, onError) {
    return await get(`${services.GESTOR}/v1/planos/cartao`, null, onSuccess, onError, false);
}

export async function asyncRemoverCartao(onSuccess, onError) {
    return await del(`${services.GESTOR}/v1/planos/cartao`, null, exibirToast(onSuccess, "Seu cartão foi removido com sucesso!") , onError, false);
}

export async function asyncGetHistorico(url, onSuccess, onError) {
    return await get(url, null, onSuccess, onError, false);
}

