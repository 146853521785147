import { tipoCampos } from "../../../../components/PesquisaAvancada/util/constantes"

export const optionsFiltroAvancado = [
    { label: 'Data', name: 'data', type: tipoCampos.DATE },
    {
        label: 'Tipo', name: 'tipo', type: tipoCampos.SELECT,
        optionSelect: [
            { label: "Saída", value: "SAIDA" },
            { label: "Entrada", value: "ENTRADA" },
        ]
    },
    { label: 'Quantidade', name: 'quantidade', type: tipoCampos.DECIMAL },
    { label: 'Custo', name: 'custo', type: tipoCampos.DECIMAL },
    { label: 'Observação', name: 'observacao', type: tipoCampos.STRING },
]

export const tipoMovimentacao = {
    ENTRADA: 'ENTRADA',
    SAIDA: 'SAIDA'
}

export const TipoMovimentacaoDocumento = {
    VENDA:'VENDA',
    NFCE: 'NFCE',
}