import { Field } from 'formik';
import React, { Component } from 'react'
import autoBind from 'react-autobind';
import { copiarObjeto } from '../../../../../common/array';
import Col from '../../../../../components/Col';
import Grid from '../../../../../components/Grid';
import If from '../../../../../components/If';
import Checkbox from '../../../../../components/Input/Checkbox';
import TextArea from '../../../../../components/TextArea';
import { helpPreferenciasFinanceiras } from './help';

const initialValue = {
    enviarEmailAutomatico: null,
    mensagemEmailContasPagar: null,
    mensagemEmailContasReceber: null
}

class PreferenciasFinanceiras extends Component {
    constructor(props) {
        super(props);
        autoBind(this);
    }

    onChange(field, value) {
        let financas = copiarObjeto(this.props.financas)
        if(financas === undefined) financas = initialValue
        financas[field] = value
        this.props.onChange(financas)
    }

    onChangeMensagemEmailContasPagar(field, value) {
        let financas = copiarObjeto(this.props.financas)
        financas[field] = value
        this.props.onChange(financas)
    }

    onChangeMensagemEmailContasReceber(field, value) {
        let financas = copiarObjeto(this.props.financas)
        financas[field] = value
        this.props.onChange(financas)
    }

    render() {
        const { financas, podeEditarEmpresa } = this.props;
        return (
            <Col>
                <Grid>
                    <Field
                        helpMessage={helpPreferenciasFinanceiras.enviarEmailAutomatico}
                        sm="12" md="12" lg='12' xl='12'
                        component={Checkbox}
                        label="Enviar e-mail de alerta do contas a pagar e receber"
                        name="enviarEmailAutomatico"
                        onChange={e => this.onChange('enviarEmailAutomatico', e.checked)}
                        checked={financas?.enviarEmailAutomatico}
                        disable={!podeEditarEmpresa}
                    />
                    <If test={financas?.enviarEmailAutomatico}>
                        <Field
                            helpMessage={helpPreferenciasFinanceiras.mensagemEmailContasReceber}
                            sm="12" md="12" lg='6' xl='6'
                            component={TextArea}
                            label='Mensagem do corpo do e-mail contas a receber'
                            name="mensagemEmailContasReceber"
                            maxLength={255}
                            value={financas?.mensagemEmailContasReceber}
                            onChange={e => this.onChangeMensagemEmailContasReceber('mensagemEmailContasReceber', e.target.value)}
                            disable={!podeEditarEmpresa}
                        />
                        <Field
                            helpMessage={helpPreferenciasFinanceiras.mensagemEmailContasPagar}
                            sm="12" md="12" lg='6' xl='6'
                            component={TextArea}
                            label='Mensagem do corpo do e-mail contas a pagar'
                            name="mensagemEmailContasPagar"
                            maxLength={255}
                            value={financas?.mensagemEmailContasPagar}
                            onChange={e => this.onChangeMensagemEmailContasPagar('mensagemEmailContasPagar', e.target.value)}
                            disable={!podeEditarEmpresa}
                        />
                    </If>
                </Grid>
            </Col>
        )
    }
}

export default PreferenciasFinanceiras
