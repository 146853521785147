import React, { Component } from "react";
import Card from "../../../components/Card";
import propTypes from "prop-types";
import { Button } from "primereact/button";
import { MdSentimentVeryDissatisfied } from "react-icons/md";

class NoMatch extends Component {
    render() {
        const header = (
            <MdSentimentVeryDissatisfied style={{ margin: "5px" }} size="8em" />
        );

        const footer = (
            <Button
                label="Voltar"
                onClick={e => window.history.back()}
                icon="fa fa-arrow-left"
            />
        );

        return (
            <div className="p-panel p-component" style={{ paddingTop: "20px" }}>
                <Card
                    footer={footer}
                    header={header}
                    title={this.props.title}
                    subTitle={this.props.message}
                />
            </div>
        );
    }
}

NoMatch.propTypes = {
    errorCode: propTypes.string,
    title: propTypes.string,
    message: propTypes.string
};

NoMatch.defaultProps = {
    errorCode: "404",
    title: "Página não encontrada!",
    message: "O recurso que você está procurando não existe."
};

export default NoMatch;
