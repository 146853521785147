import React, { Component } from "react";
import autoBind from 'react-autobind';
import Grid from "../../../../../components/Grid";
import { buttonAlterarStyle, detalhesCardStyle } from "../../styles";
import { helpHistoricoPlano } from "./help";
import { withRouter } from 'react-router';
import ModalVisualizarHistorico from "./ModalVisualizarHistorico";
import TituloCard from "../../../../../components/TituloCard";
import { format, parseISO } from "date-fns";

const cardStyle = {
    padding: '7px',
    height: '100%'
}

class CardHistorico extends Component {

    constructor(props) {
        super(props)
        autoBind(this);
        this.state = {
            modalHistoricoVisible: false,
        }
    }

    onHideModalHistorico() {
        this.setState({ modalHistoricoVisible: false })
    }

    renderizarDetalhesCard() {
        const { registros } = this.props.historico;
        if (registros) {
            const pagamentosComSucesso = registros.filter(e => e.status === 'Pagamento confirmado e finalizado')

            if (pagamentosComSucesso.length > 0) {
                const ultimoPgto = pagamentosComSucesso[pagamentosComSucesso.length - 1];
                if (ultimoPgto.data) {
                    return (
                        <p style={detalhesCardStyle}>
                            {`Data do último pagamento: ${format(parseISO(ultimoPgto.data), 'dd/MM/yyyy')}`} <br />
                            {`Valor do último pagamento: R$ ${(ultimoPgto.valor / 100).toFixed(2).split('.').join(',')}`} <br />
                        </p>
                    )
                }
            } else {
                return (
                    <p style={detalhesCardStyle}>
                        Nenhum pagamento efetivado.
                    </p>
                )
            }
        }
    }

    render() {
        const { planoAtual } = this.props;
        const meioPagamentoCartao = planoAtual && planoAtual.meioPagamento === "CARTAO_CREDITO";

        return (
            <>
                <div className={"card-default " + this.props.className} style={(planoAtual && !meioPagamentoCartao) ? { ...cardStyle, opacity: 0.5 } : cardStyle}>
                    <TituloCard
                        title='Histórico de pagamentos'
                        helpMessage={helpHistoricoPlano.historico}
                    />
                    <div style={{ height: 'calc(100% - 23px)', display: 'flex', justifyContent: "space-between", flexDirection: 'column' }}>
                        {this.renderizarDetalhesCard()}
                        <Grid directionColumn justifyEnd style={{ height: '100%' }}>
                            <span style={buttonAlterarStyle} onClick={() => this.setState({ modalHistoricoVisible: true })}>
                                Visualizar histórico
                            </span>
                        </Grid>
                    </div>
                </div>
                <ModalVisualizarHistorico
                    visible={this.state.modalHistoricoVisible}
                    onHide={this.onHideModalHistorico}
                    registros={this.props.historico.registros}
                    totalElements={this.props.historico.totalElements}
                    atualizarInformacoesHistorico={this.props.atualizarInformacoesHistorico}
                />
            </>
        );
    }
}

export default withRouter(CardHistorico);
