import React, { Component } from 'react'
import Grid from '../../../../../components/Grid';
import Col from '../../../../../components/Col';
import GraficoDespesasPorCategoria from './components/GraficoDespesasPorCategoria';
import PrevisoesDeReceitas from './components/PrevisoesDeReceitas';
import GraficoReceitasPorCategoria from './components/GraficoReceitasPorCategoria';
import PrevisoesDeDespesas from './components/PrevisoesDeDespesas';
import GraficosDosResultadosFinanceiros from './components/GraficosDosResultadosFinanceiros';
import GraficoBalancoDiario from './components/GraficoBalancoDiario';
import { connect } from 'react-redux';
import ListagemContas from './components/ListagemContas';
import BotoesNovosLancamentos from './components/BotoesNovosLancamentos';
import { usuarioPossuiPermissao } from '../../../../../common/autenticacao';
import { recursos, permissoes } from '../../../../../common/constantes/autorizacao';

class DashboardFinancas extends Component {

    state = {
        atualizarGraficoNovamente: null,
        podeVisualizarContas: usuarioPossuiPermissao(recursos.FINANCAS_CONTAS, permissoes.VISUALIZAR),
        podeVisualizarContasReceber: usuarioPossuiPermissao(recursos.FINANCAS_CONTAS_RECEBER, permissoes.VISUALIZAR),
        podeVisualizarContasPagar: usuarioPossuiPermissao(recursos.FINANCAS_CONTAS_PAGAR, permissoes.VISUALIZAR),
        podeVisualizarCategoria: usuarioPossuiPermissao(recursos.FINANCAS_CATEGORIAS, permissoes.VISUALIZAR),
    }

    componentDidUpdate(prevProps) {
        if (prevProps.dataAtualizacaoMenuLateral !== this.props.dataAtualizacaoMenuLateral) {
            this.setState({
                atualizarGraficoNovamente: this.getNewTimestamp(),
                podeVisualizarContas: usuarioPossuiPermissao(recursos.FINANCAS_CONTAS, permissoes.VISUALIZAR),
                podeVisualizarContasReceber: usuarioPossuiPermissao(recursos.FINANCAS_CONTAS_RECEBER, permissoes.VISUALIZAR),
                podeVisualizarContasPagar: usuarioPossuiPermissao(recursos.FINANCAS_CONTAS_PAGAR, permissoes.VISUALIZAR),
                podeVisualizarCategoria: usuarioPossuiPermissao(recursos.FINANCAS_CATEGORIAS, permissoes.VISUALIZAR),
            })
        }
    }

    getNewTimestamp = () => new Date().getTime();

    render() {

        const { dataInicial, dataFinal, isMobile } = this.props;

        const paddingCards = isMobile ? { padding: '0.5em 0em' } : { padding: '0.5em' };

        if (dataInicial && dataFinal) {
            return (
                <Grid style={{ margin: '0px' }}>
                    <BotoesNovosLancamentos
                        isMobile={isMobile}
                        atualizarGraficos={() => this.setState({ atualizarGraficoNovamente: this.getNewTimestamp() })}
                    />

                    <GraficosDosResultadosFinanceiros
                        dataInicial={dataInicial}
                        dataFinal={dataFinal}
                        isMobile={isMobile}
                        atualizarGraficoNovamente={this.state.atualizarGraficoNovamente}
                    />

                    {this.state.podeVisualizarContasReceber && (
                        <Col xs="12" sm="6" md="6" lg="6" xl="4" style={paddingCards}>
                            <PrevisoesDeReceitas
                                dataInicial={dataInicial}
                                dataFinal={dataFinal}
                                atualizarGraficoNovamente={this.state.atualizarGraficoNovamente}
                                atualizarGraficos={() => this.setState({ atualizarGraficoNovamente: this.getNewTimestamp() })}
                            />
                        </Col>
                    )}
                    {this.state.podeVisualizarContasPagar && (
                        <Col xs="12" sm="6" md="6" lg="6" xl="4" style={paddingCards}>
                            <PrevisoesDeDespesas
                                dataInicial={dataInicial}
                                dataFinal={dataFinal}
                                atualizarGraficoNovamente={this.state.atualizarGraficoNovamente}
                                atualizarGraficos={() => this.setState({ atualizarGraficoNovamente: this.getNewTimestamp() })}
                            />
                        </Col>
                    )}
                    {this.state.podeVisualizarContas && (
                        <Col xs="12" sm="12" md="12" lg="12" xl="4" style={paddingCards}>
                            <ListagemContas
                                dataInicial={dataInicial}
                                dataFinal={dataFinal}
                                atualizarGraficoNovamente={this.state.atualizarGraficoNovamente}
                            />
                        </Col>
                    )}
                    {this.state.podeVisualizarCategoria && (
                        <Col xs="12" sm="6" md="6" lg="6" xl="6" style={paddingCards}>
                            <GraficoReceitasPorCategoria
                                dataInicial={dataInicial}
                                dataFinal={dataFinal}
                                atualizarGraficoNovamente={this.state.atualizarGraficoNovamente}
                            />
                        </Col>
                    )}
                    {this.state.podeVisualizarCategoria && (
                        <Col xs="12" sm="6" md="6" lg="6" xl="6" style={paddingCards}>
                            <GraficoDespesasPorCategoria
                                dataInicial={dataInicial}
                                dataFinal={dataFinal}
                                atualizarGraficoNovamente={this.state.atualizarGraficoNovamente}
                            />
                        </Col>
                    )}
                    <Col xs="12" sm="12" md="12" lg="12" xl="12" style={paddingCards}>
                        <GraficoBalancoDiario
                            dataInicial={dataInicial}
                            dataFinal={dataFinal}
                            atualizarGraficoNovamente={this.state.atualizarGraficoNovamente}
                        />
                    </Col>
                </Grid >
            )
        } else {
            return <span>Carregando informações...</span>
        }
    }
}


const mapStateToProps = state => ({
    isMobile: state.dispositivo.isMobile,
    dataAtualizacaoMenuLateral: state.menuLateral.dataAtualizacaoMenuLateral,
})
export default connect(mapStateToProps)(DashboardFinancas);
