import React, { Component } from "react";
import propTypes from "prop-types";
import Button from "../";

const style = {
    borderRadius: '50%',
    padding: '5px',
    width: '30px',
    height: '30px',
    marginLeft: '2.5px',
    marginRight: '2.5px'
};

class ButtonExcluirTable extends Component {
    getDisabled() {
        if (this.props.podeExcluir === false) return true;

        return this.props.disabled;
    }

    getTitle() {
        if (this.props.podeExcluir === false)
            return "Você não possui permissão para executar essa ação";
        return this.props.title;
    }

    render() {
        const { id, onClick, hidden } = this.props;

        return (
            <Button
                id={id}
                type="button"
                color="danger"
                size="icon"
                disabled={this.getDisabled()}
                onClick={onClick}
                hidden={hidden}
                icon="fa fa-trash-o"
                enableShortCut={false}
                style={style}
                title={this.getTitle()}
            />
        );
    }
}

ButtonExcluirTable.defaultProps = {
    hidden: false,
    disabled: false,
    title: "Excluir o registro"
};

ButtonExcluirTable.propTypes = {
    /** Identificador do componente */
    id: propTypes.string,
    /** Derfine o botão como invisível */
    hidden: propTypes.bool,
    /** Ação executada ao clicar no botão */
    onClick: propTypes.func,
    /** Define o botão como desabilitado */
    disabled: propTypes.bool,
    /** Diz se o usuário possui permissão de excluir*/
    podeExcluir: propTypes.bool,
    /** Title do componente */
    title: propTypes.string
};

export default ButtonExcluirTable;
