export function removerElemento(array, elemento) {
    let index = array.indexOf(elemento);
    let arrayCopiado = [...array]
    if (index > -1) {
        arrayCopiado.splice(index, 1);
    }
    return arrayCopiado
}

export function removerElementoPorId(array, elemento, nomeColunaId) {
    let index = 0
    let resultado = []

    for (index; index < array.length; index++) {
        if (array[index][nomeColunaId] !== elemento[nomeColunaId])
            resultado.push(array[index])
    }
    return resultado
}

export function elementoExiste(elemento, array) {
    for (const item in array) {
        if (array.hasOwnProperty(item)) {
            const element = this.state.options[item];
            if (element === elemento) {
                return true;
            }
        }
    }
}

export function capturarElementoPorId(array, id) {
    for (let index = 0; index < array.length; index++) {
        const element = array[index];
        if (element.id === id) {
            return element;
        }
    }
}

export function copiarObjeto(obj) {
    	// Se não for array ou objeto, retorna null
	if (typeof obj !== 'object' || obj === null) {
		return obj;
	}

	let cloned, i;

	// Handle: Date
	if (obj instanceof Date) {
		cloned = new Date(obj.getTime());
		return cloned;
	}

	// Handle: array
	if (obj instanceof Array) {
		let l;
		cloned = [];
		for (i = 0, l = obj.length; i < l; i++) {
			cloned[i] = copiarObjeto(obj[i]);
		}

		return cloned;
	}

	// Handle: object
	cloned = {};
	for (i in obj) if (obj.hasOwnProperty(i)) {
		cloned[i] = copiarObjeto(obj[i]);
	}

	return cloned;
}