export const helpNFeProd = {
    NCM: 'Nomenclatura Comum do Mercosul',
    CFOP: 'Código Fiscal de Operações e Prestações',
    CEST:  'Código Especificador da Substituição Tributária',
    cEAN: 'GTIN (Global Trade Item Number) do produto, antigo código EAN ou código de barras',
    unidadeMedida: 'Unidade de medida Tributável',
    xPed: 'Número do Pedido de Compra (Opcional)',
    nItemPed: 'Item do Pedido de Compra (Opcional)',
    orig: 'Origem da mercadoria',
    csosn: 'Código de Situação da Operação – Simples Nacional',
    pCredSN: 'Alíquota aplicável de cálculo do crédito (Simples Nacional)',
    pMVAST: 'A margem de lucro de toda a cadeia projetada pelo governo',
    pRedBCST: 'Percentual da Redução de BC do ICMS ST',
    pICMSST: 'Alíquota do ICMS aplicado dentro do estado de destino',
    pICMS: 'Alíquota do ICMS do estado de origem',
    pFCPST: 'Percentual relativo ao Fundo de Combate à Pobreza (FCP) retido por substituição tributária',
    modBCST: 'Modalidade de determinação da BC do ICMS ST',
    modBC: 'Modalidade de determinação da BC do ICMS',

    vICMSSTRet : 'Valor do ICMS ST retido',
    pFCPSTRet: 'Percentual do FCP retido anteriormente por Substituição Tributária',
    pICMSUFDest: 'Alíquota interna da UF de destino',
    pICMSInter: 'Alíquota interestadual das UFs envolvidas',
    pFCPUFDest: 'Percentual do ICMS relativo ao Fundo de Combate à Pobreza (FCP) na UF de destino',

    pRedBC: 'Percentual da Redução de BC (ICMS)',
    pMVASTReducao: 'Percentual Redução MVA (ICMS ST)',
    vBCSTRet: 'Valor da base de cálculo ICMS retido na operação anterior',
    vICMSSubstituto: 'Valor do ICMS próprio do Substituto',
    pST: 'Alíquota suportada pelo Consumidor Final',
    vBCFCPSTRet: 'Valor da Base de Cálculo do FCP retido anteriormente por ST',
    vFCPSTRet: 'Valor do FCP retido por Substituição Tributária',
}