import React, { Component } from 'react';
import autoBind from 'react-autobind/lib/autoBind';

import Col from '../../../components/Col';
import ButtonNovo from '../../../components/Button/ButtonNovo';

import { recursos, permissoes } from '../../../common/constantes/autorizacao';
import { usuarioPossuiPermissao } from '../../../common/autenticacao';
import Form from '../../../components/Form';
import FormActions from '../../../components/FormActions';
import FormContent from '../../../components/FormContent';
import DateInterval from '../../../components/DateInterval';
import Grid from '../../../components/Grid';
import { formatISO } from 'date-fns';
import DescricaoFiltroAvancado from '../../../components/DescricaoFiltroAvancado';
import { construirUrl } from '../../../common/rsql';
import { asyncDeleteNegociacao, asyncGetNegociacao, asyncGetPesquisaNegociacao, asyncGetTotalizadores, asyncGetContas } from './requests';
import { services } from '../../../common/constantes/api';
import { converterNegociacaoParaFormulario, converterNegociacoesParaListagem } from './util/negociacaoConverter'
import TableNegociacoes from './components/TableNegociacoes';
import CardTotalizadorListagem from './components/CardTotalizadorListagem';
import If from '../../../components/If';
import ModalNegociacao from './components/ModalNegociacao';
import { confirm } from '../../../components/Toast';
import { operacaoTipo, optionsFiltroAvancado, ColorsCard } from './util/constantes';
import InputSearchAtivos from '../Proventos/components/InputSearchAtivos';
import PesquisaAvancada from '../../../components/PesquisaAvancada';
import { SelectButton } from 'primereact/selectbutton';


const selectButtonStyle = {
    whiteSpace: 'nowrap',
    overflowY: 'hidden',
    width: '100%',
}


class Negociacoes extends Component {

    constructor(props) {

        super(props);

        autoBind(this);

        this.state = {
            podeInserir: usuarioPossuiPermissao(recursos.INVESTIDOR_NEGOCIACOES, permissoes.INSERIR),
            podeEditar: usuarioPossuiPermissao(recursos.INVESTIDOR_NEGOCIACOES, permissoes.EDITAR),
            podeExcluir: usuarioPossuiPermissao(recursos.INVESTIDOR_NEGOCIACOES, permissoes.EXCLUIR),
            exibirModalNegociacao: false,
            registroSelecionado: null,

            valorPesquisa: [],
            filtroData: "",
            filtroConta: null,
            optionsContas: [],
            interval: {
                dataInicial: null,
                dataFinal: null
            },
            cardTotalSelected: "",
            valorCard: "",
            cards: {
                compra: {
                    valor: 0,
                    quantidade: 0
                },
                venda: {
                    valor: 0,
                    quantidade: 0
                }
            },
            descricaoFiltroAvancado: '',
            optionsFiltroAvancado: optionsFiltroAvancado,
            registros: [],

            sortField: 'data',
            sortOrder: -1,

            page: 0,
            rows: 0,
            size: 10,
            first: 0
        }
    }


    componentDidMount() {
        const { location } = this.props;
        if (location && location.state) {
            this.setState({
                valorPesquisa: [
                    ...this.state.valorPesquisa,
                    {
                        label: location.state.codigo + ' - ' + location.state.nome,
                        value: location.state.id,
                        registro: location.state
                    },
                ]
            })
            if (location.state.contaSelecionadaId) {
                this.setState({ filtroConta: location.state.contaSelecionadaId })
            }
        }
        this.buscarInformacoesComplementaresFiltro();
    }

    buscarInformacoesComplementaresFiltro() {
        asyncGetContas(({ data: contas }) => {
            let optionsContas = contas && contas.content.map(conta => {
                return { label: `${conta.corretora.nome} - ${conta.codigo}`, value: conta.id }
            })
            this.setState({ optionsContas })
        });
    }

    async pesquisar() {
        const { page, size, sortField, sortOrder, interval, filtroConta, valorPesquisa } = this.state
        const filtro = this.buscarFiltro();

        const url = construirUrl(`${services.GESTOR}/v1/investidor/negociacoes/resumo`, filtro || "?", size, page, sortOrder > 0 ? `${sortField},asc&sort=data,desc` : `${sortField},desc&sort=data,desc`);


        asyncGetTotalizadores(interval, filtroConta, valorPesquisa, ({ data: totais }) => {
            let cards = {};

            totais && totais.forEach(element => {
                if (element.operacao === operacaoTipo.COMPRA) {
                    cards.compra = {
                        quantidade: element.quantidade,
                        valor: element.valor
                    }
                } else if (element.operacao === operacaoTipo.VENDA) {
                    cards.venda = {
                        quantidade: element.quantidade,
                        valor: element.valor
                    }
                } else if (element.operacao === operacaoTipo.SALDO_INICIAL) {
                    cards.saldoInicial = {
                        quantidade: element.quantidade,
                        valor: element.valor
                    }
                }
            });
            this.setState({ cards })
        });


        asyncGetPesquisaNegociacao(url, ({ data: negociacoes }) => {
            this.setState({
                registros: converterNegociacoesParaListagem(negociacoes.content),
                totalRecords: negociacoes.totalElements,
            })
        })

    }

    onPesquisar() {
        this.setState({ page: 0, first: 0 }, () => {
            this.pesquisar();
        })
    }

    handleChangeInterval(interval) {
        this.setState({ page: 0, first: 0, interval: interval, filtroData: `data>=${formatISO(interval.dataInicial, { representation: "date" })};data<=${formatISO(interval.dataFinal, { representation: "date" })}` }, () => {
            this.pesquisar();
        })
    }

    async handleEditItem(item) {
        asyncGetNegociacao(item.id, ({ data }) => {
            this.setState({ registroSelecionado: converterNegociacaoParaFormulario(data), exibirModalNegociacao: true })
        })
    }

    async handleRemoveItem(item) {
        confirm('Confirmação', 'Tem certeza que deseja excluir esta negociação?', async () => {
            await asyncDeleteNegociacao(item.id, () => this.pesquisar())
        })
    }

    onPesquisarFiltroAvancado(e) {
        this.setState({ valorFiltroAvancado: e, limparFiltroPesquisaAvancada: false }, () => {
            this.pesquisar()
        });
    }

    buscarFiltro() {
        const { valorFiltroAvancado, filtroData, valorPesquisa, filtroConta, valorCard } = this.state;

        let filtroRSQL = String(`?query=(${filtroData}`)

        if (valorPesquisa && valorPesquisa.length > 0) {
            filtroRSQL = filtroRSQL.concat(`;ativo.id=in=(${valorPesquisa.map(el => el.value)})`)
        }

        if (valorFiltroAvancado) {
            filtroRSQL = filtroRSQL.concat(`;(${valorFiltroAvancado})`)
        }

        if (filtroConta) {
            filtroRSQL = filtroRSQL.concat(`;(conta.id==${filtroConta})`)
        }

        if (valorCard) {
            filtroRSQL = filtroRSQL.concat(`;(${valorCard})`)
        }

        filtroRSQL = filtroRSQL.concat(`)`)

        return filtroRSQL;
    }

    handleSelectConta(e) {
        this.setState({ filtroConta: e.value }, () => this.pesquisar())
    }

    handleSelectCardCompra() {
        const card = operacaoTipo.COMPRA;
        const filtroRSQL = 'operacao==COMPRA';
        this.pesquisarRSQLCardTotais(card, filtroRSQL);
    }

    handleSelectCardVenda() {
        const card = operacaoTipo.VENDA;
        const filtroRSQL = 'operacao==VENDA';
        this.pesquisarRSQLCardTotais(card, filtroRSQL);
    }

    pesquisarRSQLCardTotais(cardSelected, valueCard) {
        const { cardTotalSelected } = this.state;

        if (cardTotalSelected === cardSelected) {
            this.setState({
                cardTotalSelected: "",
                valorCard: "",
                page: 0,
                first: 0
            }, () => {
                this.pesquisar();
            });
        } else {
            this.setState({
                cardTotalSelected: cardSelected,
                valorCard: valueCard,
                page: 0,
                first: 0
            }, () => {
                this.pesquisar();
            });
        }
    };



    render() {
        const {
            cards,
            cardTotalSelected,
            podeInserir,
            registroSelecionado,
            valorPesquisa,
            descricaoFiltroAvancado,
            exibirModalNegociacao,
            registros,
            totalRecords,
            optionsContas,
            filtroConta,
            first,
            size,
            sortField,
            sortOrder,
        } = this.state;

        const { isMobile } = this.props;


        const valorTotalCard = (cards.compra?.valor  + cards.venda?.valor  + cards.saldoInicial?.valor) || 0
        const quantidadeTotalCard = (cards.compra?.quantidade  + cards.venda?.quantidade  + cards.saldoInicial?.quantidade) || 0

        return (
            <>
                <Form header="Negociações">
                    <FormActions>
                        <ButtonNovo
                            label="Nova negociação"
                            title="Inserir uma nova negociação"
                            onClick={() => this.setState({ exibirModalNegociacao: true })}
                            disabled={!podeInserir}
                        />
                    </FormActions>

                    <FormContent>
                        <Grid justifyCenter verticalAlignCenter>
                            <span style={{ padding: '12px' }}>
                                <DateInterval onChange={this.handleChangeInterval} />
                            </span>

                            <InputSearchAtivos
                                onPesquisar={this.onPesquisar}
                                value={valorPesquisa}
                                onChange={value => this.setState({ valorPesquisa: value }, this.onPesquisar())}
                            />

                            <span style={{ padding: '12px' }}>
                                <PesquisaAvancada
                                    optionsFiltros={optionsFiltroAvancado}
                                    onPesquisarClick={this.onPesquisarFiltroAvancado}
                                    onChangeFiltroRsql={rsql => this.setState({ filtroAvancado: rsql })}
                                    onChangeDescricaoFiltro={e => this.setState({ descricaoFiltroAvancado: e })}
                                    limparFiltro={this.state.limparFiltroPesquisaAvancada}
                                />
                            </span>
                        </Grid>
                        <If test={optionsContas.length > 0}>
                            <Grid
                                verticalAlignCenter
                                justifyCenter
                                style={{ marginBottom: '10px', width: '100%' }}
                                className='grid-select-filtro'
                            >
                                <SelectButton
                                    style={selectButtonStyle}
                                    className='select-button-light'
                                    value={filtroConta}
                                    options={optionsContas}
                                    onChange={this.handleSelectConta}
                                />
                            </Grid>
                        </If>
                        <Grid justifyBetween>
                            <Col xs="12" sm="4" md="4" lg="4" xl="4">
                                <CardTotalizadorListagem
                                    name="cardCompras"
                                    title="Compras"
                                    helpMessage={!isMobile ? "Exibe o valor total e o número de compras no período selecionado" : ""}
                                    titleFiltro="Clique para filtrar pelas compras"
                                    colors={ColorsCard.COMPRA}
                                    value={cards.compra?.valor}
                                    selectable
                                    numberOfElements={cards.compra?.quantidade}
                                    selected={cardTotalSelected === operacaoTipo.COMPRA}
                                    onSelect={this.handleSelectCardCompra}
                                />
                            </Col>
                            <Col xs="12" sm="4" md="4" lg="4" xl="4">
                                <CardTotalizadorListagem
                                    name="cardVendas"
                                    title="Vendas"
                                    helpMessage={!isMobile ? "Exibe o valor total e o número de vendas no período selecionado" : ""}
                                    titleFiltro="Clique para filtrar pelas vendas"
                                    colors={ColorsCard.VENDA}
                                    value={cards.venda?.valor}
                                    selectable
                                    numberOfElements={cards.venda?.quantidade}
                                    selected={cardTotalSelected === operacaoTipo.VENDA}
                                    onSelect={this.handleSelectCardVenda}
                                />
                            </Col>

                            <Col xs="12" sm="4" md="4" lg="4" xl="4">
                                <CardTotalizadorListagem
                                    name="cardTotalizador"
                                    title="Total"
                                    helpMessage={!isMobile ? "Exibe o valor resultante da soma do valor líquido das negociações" : ""}
                                    colors={ColorsCard.TOTAL}
                                    value={valorTotalCard}
                                    numberOfElements={quantidadeTotalCard}
                                />
                            </Col>
                        </Grid>
                        <Grid style={{ paddingTop: '10px' }} justifyCenter verticalAlignCenter>
                            <DescricaoFiltroAvancado texto={descricaoFiltroAvancado} />

                            <TableNegociacoes
                                registros={registros}
                                totalRecords={totalRecords}
                                rows={size}
                                first={first}
                                sortField={sortField}
                                sortOrder={sortOrder}
                                setSortField={sortField => this.setState({ sortField })}
                                setSortOrder={sortOrder => this.setState({ sortOrder }, () => this.pesquisar())}
                                onEditItem={this.handleEditItem}
                                onRemoveItem={this.handleRemoveItem}
                                onPageChange={e => this.setState({ first: e.first, size: e.rows, page: e.page }, () => this.pesquisar())}
                            />

                        </Grid>

                    </FormContent>
                </Form>
                <If test={exibirModalNegociacao}>
                    <ModalNegociacao
                        registroSelecionado={registroSelecionado}
                        onNovoClick={() => this.setState({ registroSelecionado: null })}
                        onHide={() => {
                            this.setState({ exibirModalNegociacao: false, registroSelecionado: null })
                            this.pesquisar();
                        }}
                        visible={exibirModalNegociacao}
                    />
                </If>
            </>
        );
    }
}

export default Negociacoes;
