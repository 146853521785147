import React, { Component } from 'react';
import * as Yup from "yup";
import Modal from '../../../../../components/Modal';
import Grid from '../../../../../components/Grid';
import { withRouter } from 'react-router';
import ButtonNovo, { estadosBotaoNovo } from '../../../../../components/Button/ButtonNovo';
import ButtonSalvar, { estadosBotaoSalvar } from '../../../../../components/Button/ButtonSalvar';
import ButtonCancelar, { estadosBotaoCancelar } from '../../../../../components/Button/ButtonCancelar';
import { usuarioPossuiPermissao } from '../../../../../common/autenticacao';
import { recursos, permissoes, estadosCadastro } from '../../../../../common/constantes/autorizacao';
import ButtonExcluir from '../../../../../components/Button/ButtonExcluir';
import { mensagensDeValidacao } from '../../../../../common/constantes/mensagens';
import autoBind from 'react-autobind';
import { withFormik, Field } from 'formik';
import InputField from '../../../../../components/Input/InputField';
import { helpMessage } from './help';
import { confirm } from '../../../../../components/Toast';
import AutoProgressBar from '../../../../../components/Loading/AutoProgressBar';
import Form from '../../../../../components/Form';
import FormActions from '../../../../../components/FormActions';
import FormContent from '../../../../../components/FormContent';
import ToolbarItensLeft from '../../../../../components/Toolbar/ToolbarItensLeft';
import { DataTable } from 'primereact/components/datatable/DataTable';
import { Column } from 'primereact/column';
import NenhumRegistroEncontrado from '../../../../../components/NenhumRegistroEncontrado';
import ToolbarDetails from '../../../../../components/Toolbar';
import ButtonEditarTable from '../../../../../components/Button/ButtonEditarTable';
import ButtonExcluirTable from '../../../../../components/Button/ButtonExcluirTable';
import If from '../../../../../components/If';
import CorretoraConta from './components/ModalCorretoraConta';
import { copiarObjeto, removerElemento } from '../../../../../common/array';
import { criarNovaCorretora, atualizarCorretora, deletarCorretora } from '../requests';
import { validarFormulario } from '../../../../util';

const initialValue = {
    id: null,
    nome: null,
    codigo: null,
    contas: [],
};

class ModalCorretora extends Component {

    constructor(props) {
        super(props);
        autoBind(this);

        this.state = {
            podeInserir: usuarioPossuiPermissao(recursos.INVESTIDOR_CORRETORAS, permissoes.INSERIR),
            podeEditar: usuarioPossuiPermissao(recursos.INVESTIDOR_CORRETORAS, permissoes.EDITAR),
            podeExcluir: usuarioPossuiPermissao(recursos.INVESTIDOR_CORRETORAS, permissoes.EXCLUIR),
            estadoCadastro: this.buscarEstadoCadastro(),
            modalCorretoraContaVisible: false,
            contaSelecionada: null,
        }
    }

    novaConta() {
        this.setState({ modalCorretoraContaVisible: true });
    }

    novo() {
        this.props.resetForm({ values: initialValue })
        this.props.buscarCorretoras()
    }

    async salvar(e, novoOnSuccess) {
        this.props.handleSubmit();
        if (await validarFormulario(this.props)) {
            const { values } = this.props
            if (values.id) {
                this.asyncUpdateRegistro(values, novoOnSuccess);
            } else {
                this.asyncCreateRegistro(values, novoOnSuccess);
            }
        }
    }

    async asyncUpdateRegistro(data, novoOnSuccess) {
        await atualizarCorretora(data, () => {
            novoOnSuccess ? novoOnSuccess() : this.props.onHide()
        })
    }

    async asyncCreateRegistro(data, novoOnSuccess) {
        await criarNovaCorretora(data, () => {
            novoOnSuccess ? novoOnSuccess() : this.props.onHide()
        })
    }

    hideModal() {
        this.setState({ modalCorretoraContaVisible: false, registroSelecionado: null })
    }

    inserirCorretoraConta(dadosFormulario, novoOnSuccess) {
        let contas = this.props.values.contas
        this.props.setFieldValue('contas', [...contas, dadosFormulario])
        novoOnSuccess ? novoOnSuccess() : this.hideModal()
    }

    atualizarCorretoraConta(dadosFormulario, novoOnSuccess) {
        const contas = copiarObjeto(this.props.values.contas);

        const resultContas = contas && contas.map(conta => {
            if (conta.idTemporario && conta.idTemporario === dadosFormulario.idTemporario) {
                return dadosFormulario
            } else if (conta.id && conta.id === dadosFormulario.id) {
                return dadosFormulario
            }
            return conta;
        })

        this.props.setFieldValue('contas', resultContas)
        novoOnSuccess ? novoOnSuccess() : this.hideModal()
    }

    editar(contaRow) {
        this.setState({ registroSelecionado: contaRow, modalCorretoraContaVisible: true })
    }

    cancelar() {
        const { resetForm, initialValues } = this.props
        if (this.props.dirty) {
            resetForm(initialValues)
        } else {
            this.props.onHide()
        }
    }

    excluirRegistro(dadosFormulario) {
        if (dadosFormulario) {
            let contas = this.props.values?.contas
            this.props.setFieldValue('contas', removerElemento(contas, dadosFormulario))
            this.setState({ registroSelecionado: null })

        }
    }

    excluirCorretora(corretora) {
        confirm('Confirmação', 'Tem certeza que deseja excluir a corretora?', async () => {
            await deletarCorretora(corretora.id, () => {
                this.props.onHide()
            })
        })
    }

    renderOpcoes(contaRow) {
        const podeEditarCorretora = (this.state.estadoCadastro === estadosCadastro.EDICAO && !this.state.podeEditar)

        return (
            <>
                <ButtonEditarTable
                    onClick={() => this.editar(contaRow)}
                />
                <ButtonExcluirTable
                    onClick={() => this.excluirRegistro(contaRow)}
                    podeExcluir={!podeEditarCorretora} />
            </>
        )
    }

    buscarEstadoCadastro() {
        return this.props.values.id ? estadosCadastro.EDICAO : estadosCadastro.INCLUSAO
    }

    render() {
        const {
            visible,
            onHide,
            dirty,
            values,
            isModal,
            insertJustOne
        } = this.props;

        const informacoesPermissoes = {
            podeInserir: this.state.podeInserir,
            podeEditar: this.state.podeEditar,
            podeExcluir: this.state.podeExcluir,
            estadoCadastro: this.buscarEstadoCadastro(),
        };

        const estadoBotaoNovo = dirty ? estadosBotaoNovo.SALVAR_E_NOVO : estadosBotaoNovo.NOVO;

        const onClickNovo = dirty ? (e) => this.salvar(e, this.novo) : this.novo;

        const podeEditarCorretora = (informacoesPermissoes.estadoCadastro === estadosCadastro.EDICAO && !informacoesPermissoes.podeEditar)
        
        return (
            <Modal
                header={values.id ? "Editar corretora" : "Cadastrar nova corretora"}
                visible={visible}
                onHide={() => {
                    this.novo()
                    onHide(values)
                }}            >
                <AutoProgressBar />
                <Form>
                    <FormActions>
                        <ButtonCancelar
                            hidden={isModal && values.id && !dirty}
                            estadoBotao={dirty ? estadosBotaoCancelar.CANCELAR : estadosBotaoCancelar.VOLTAR}
                            onClick={this.cancelar}
                            {...informacoesPermissoes}
                            />
                        <ButtonSalvar
                            estadoBotao={estadosBotaoSalvar.SALVAR}
                            disabled={!dirty}
                            onClick={this.salvar}
                            {...informacoesPermissoes}
                        />
                        <ButtonNovo
                            onClick={onClickNovo}
                            hidden={(!dirty && !values.id) || insertJustOne}
                            estadoBotao={estadoBotaoNovo}
                            {...informacoesPermissoes}
                        />
                        <ButtonExcluir
                            hidden={!values.id || isModal}
                            onClick={() => this.excluirCorretora(this.props.values)}
                            {...informacoesPermissoes}
                        />
                    </FormActions>
                    <FormContent>
                        <Grid>
                            <Field sm="12" md="3" lg="3" xl="3"
                                component={InputField}
                                label="Código da corretora"
                                obrigatorio
                                name="codigo"
                                size={9}
                                onChange={e => this.props.setFieldValue('codigo', e.target.value?.replace(/\D+/g, ''))}
                                helpMessage={helpMessage.codigo}
                                {...informacoesPermissoes}
                            />
                            <Field sm="12" md="9" lg="9" xl="9"
                                component={InputField}
                                label="Nome da corretora"
                                obrigatorio
                                name="nome"
                                size={255}
                                helpMessage={helpMessage.nome}
                                {...informacoesPermissoes}
                            />
                        </Grid>
                    </FormContent>
                    <ToolbarDetails>
                        <ToolbarItensLeft style={{ paddingLeft: '0px' }}>
                            <ButtonNovo
                                label="Nova Conta"
                                enableShortCut={false}
                                onClick={this.novaConta}
                                podeInserir={!podeEditarCorretora}
                            />
                        </ToolbarItensLeft>
                    </ToolbarDetails>
                    <DataTable
                        className="table-light"
                        responsive
                        value={this.props.values.contas}
                        emptyMessage={<NenhumRegistroEncontrado message="Nenhuma conta encontrada" />}
                    >
                        <Column
                            field="codigo"
                            header="Conta"
                            sortable={true}
                        />
                        <Column
                            field="descricao"
                            header="Descrição"
                            sortable={true}
                        />
                        <Column
                            body={this.renderOpcoes}
                            header="Ações"
                            style={{ width: "7em" }}
                        />
                    </DataTable>
                </Form>
                <If test={this.state.modalCorretoraContaVisible}>
                    <CorretoraConta
                        registroSelecionado={this.state.registroSelecionado}
                        onNovoClick={() => this.setState({ registroSelecionado: null })}
                        inserirCorretoraConta={this.inserirCorretoraConta}
                        atualizarCorretoraConta={this.atualizarCorretoraConta}
                        excluirRegistro={this.excluirRegistro}
                        visible={this.state.modalCorretoraContaVisible}
                        onHide={this.hideModal}
                        podeEditarCorretora={podeEditarCorretora}
                        podeEditar={!podeEditarCorretora}
                    />
                </If>
            </Modal>
        )
    }
}
ModalCorretora = withFormik({
    enableReinitialize: true,
    validateOnChange: false,

    mapPropsToValues(props) {
        if (props.registroSelecionado) {
            return props.registroSelecionado
        } else {
            return initialValue;
        }
    },

    validationSchema: Yup.object().shape({
        codigo: Yup.number().nullable().required(mensagensDeValidacao.OBRIGATORIO),
        nome: Yup.string().nullable().required(mensagensDeValidacao.OBRIGATORIO)
    }),
    handleSubmit: () => { }
})(ModalCorretora);

export default withRouter(ModalCorretora);
