import React, { Component } from 'react';
import autoBind from "react-autobind/lib/autoBind";
import { usuarioPossuiPermissao } from '../../../common/autenticacao';
import { recursos, permissoes } from '../../../common/constantes/autorizacao';
import TabelaContasReceber from './components/TabelaContasReceber';
import Grid from '../../../components/Grid';
import DateInterval from '../../../components/DateInterval';
import { construirUrl } from '../../../common/rsql';
import { services } from '../../../common/constantes/api';
import InputSearch from '../../../components/Input/InputSearch';
import {
    asyncGetContasReceber, asyncGetContaReceber, asyncDeleteContaReceber,
    asyncGetCategoriasReceita, asyncGetTotalizadoresCards,
    asyncDeleteContaReceberProximasEmAberto, asyncDeleteContaReceberTodasEmAberto, asyncBaixarRelatorioContasAReceberPorPeriodo
} from './requests';
import PesquisaAvancada from '../../../components/PesquisaAvancada';
import Col from '../../../components/Col';
import CardTotalizadorListagem from '../components/CardTotalizadorListagem';
import If from '../../../components/If';
import ModalContaReceber from './components/ModalContaReceber';
import { confirm } from '../../../components/Toast';
import { optionsFiltroAvancado } from './utils/constantes';
import { tipoCampos } from '../../../components/PesquisaAvancada/util/constantes';
import DescricaoFiltroAvancado from '../../../components/DescricaoFiltroAvancado';
import Form from '../../../components/Form';
import FormActions from '../../../components/FormActions';
import FormContent from '../../../components/FormContent';
import ModalExclusaoContasReceber, { tiposExclusoesContasReceber } from './components/ModalExclusaoContasReceber';
import ModalRecebimentoTable from './components/ModalRecebimentoTable';
import { isWithinInterval, format, formatISO, startOfMonth, lastDayOfMonth } from 'date-fns';
import { FaCheckCircle, FaExclamationCircle, FaEquals, FaPrint } from 'react-icons/fa';
import ButtonNovo from '../../../components/Button/ButtonNovo';
import { baixarArquivo } from '../../../common/relatorios';
import { connect } from 'react-redux';
import { Financas } from '../../util/constantes';
import  montarMensagemExclusaoTitulos  from '../utils/functions'

const styleBotaoImprimir = {
    padding: '0px 10px',
    display: 'flex',
    background: '#fff0',
    color: Financas.cores.azul,
    fontWeight: 'bold',
    fontSize: '15px',
    alignItems: 'center',
    border: 'none',
    cursor: 'pointer'
};

class ContasReceber extends Component {

    constructor(props) {
        super(props);

        autoBind(this)

        this.state = {
            podeInserir: usuarioPossuiPermissao(recursos.FINANCAS_CONTAS_RECEBER, permissoes.INSERIR),

            exibirModalContaReceber: false,
            exibirModalExclusao: false,
            exibirModalConfirmarRecebimento: false,
            registroSelecionado: null,

            descricaoFiltroAvancado: '',
            cardSelected: '',

            valorPesquisa: "",
            valorCard: "",
            valorFiltroAvancado: "",

            optionsFiltroAvancado: optionsFiltroAvancado,
            limparFiltroPesquisaAvancada: false,

            cards: {
                recebido: 0,
                aReceber: 0,
                vencidas: 0,
                totalAReceber: 0
            },
            registros: [],
            totalRecords: 0,

            interval: {
                dataInicial: startOfMonth(new Date()),
                dataFinal: lastDayOfMonth(new Date())
            },

            sortField: 'vencimento',
            sortOrder: -1,

            page: 0,
            rows: 0,
            size: 10,
            first: 0
        }
    }


    handleChangeInterval(interval) {
        this.setState({ page: 0, first: 0, interval: interval, filtroData: `vencimento>=${formatISO(interval.dataInicial, { representation: "date" })};vencimento<=${formatISO(interval.dataFinal, { representation: "date" })}` }, () => {
            this.pesquisar();
        })
    }

    async pesquisar() {
        const { page, size, sortField, sortOrder, interval } = this.state
        const filtro = this.buscarFiltro();

        const url = construirUrl(`${services.GESTOR}/v1/contas_receber/resumo`, filtro || "?", size, page, sortOrder > 0 ? `${sortField},asc&sort=vencimento,desc` : `${sortField},desc&sort=vencimento,desc`);

        asyncGetContasReceber(url, ({ data: contasReceber }) => {
            this.setState({
                registros: contasReceber.content,
                totalRecords: contasReceber.totalElements,
            })
        })

        asyncGetTotalizadoresCards(interval, ({ data: totais }) => {
            this.setState({
                cards: {
                    recebido: totais.receitasRecebidas,
                    aReceber: totais.receitasAReceber,
                    vencidas: totais.receitasVencidas,
                    totalAReceber: totais.receitasTotal
                }
            })
        });
    }

    buscarFiltro() {
        const { valorFiltroAvancado, filtroData, valorPesquisa, valorCard, cardSelected } = this.state;

        let filtroRSQL = String("?query=(")
            .concat(`descricao=contains="*${valorPesquisa}*",`)
            .concat(`pessoaNome=contains="*${valorPesquisa}*",`)
            .concat(`categoriaNome=contains="*${valorPesquisa}*",`)
            .concat(`observacao=contains="*${valorPesquisa}*",`)
            .concat(`valorAReceber=contains="*${valorPesquisa}*",`)
            .concat(`valor=contains="*${valorPesquisa}*")`)

        if (cardSelected !== "cardVencidas") {
            filtroRSQL = filtroRSQL.concat(`;(${filtroData})`)
        }
        if (valorFiltroAvancado) {
            filtroRSQL = filtroRSQL.concat(`;(${valorFiltroAvancado})`)
        }
        if (valorCard) {
            filtroRSQL = filtroRSQL.concat(`;(${valorCard})`)
        }

        return filtroRSQL;
    }

    onPesquisar() {
        this.setState({ page: 0, first: 0 }, () => {
            this.pesquisar();
        })
    }

    onPesquisarFiltroAvancado(e) {
        this.setState({ valorFiltroAvancado: e, limparFiltroPesquisaAvancada: false }, () => {
            this.pesquisar()
        });
    }

    componentDidMount() {
        const { location } = this.props;

        if (location && location.state) {
            this.handleChangeInterval({
                dataInicial: location.state.dataInicial,
                dataFinal: location.state.dataFinal
            })
        }

        this.complementarFiltroAvancado();
    }

    complementarFiltroAvancado() {
        asyncGetCategoriasReceita(({ data: catReceita }) => {
            const categorias = {
                label: 'Categoria de receitas', name: 'categoriaNome', type: tipoCampos.SELECT,
                optionSelect: catReceita && catReceita.content.map(categoria => {
                    return { label: categoria.nome, value: categoria.nome }
                })
            }
            this.setState({ optionsFiltroAvancado: [...this.state.optionsFiltroAvancado, { ...categorias }] })
        })
    }

    onHideModalContaReceber() {
        this.pesquisar()
        this.setState({
            exibirModalContaReceber: false,
            registroSelecionado: null
        })
    }

    handleSelectCardRecebidas(cardName) {
        this.pesquisarRSQLTotaisCard(cardName, ["RECEBIDA", "PARCIALMENTE_RECEBIDA"])
    }

    handleSelectCardAReceber(cardName) {
        this.pesquisarRSQLTotaisCard(cardName, ["PARCIALMENTE_RECEBIDA", "NAO_RECEBIDA"])
    }

    handleSelectCardVencidas(cardName) {
        this.pesquisarRSQLTotaisCard(cardName, new Date())
    }

    pesquisarRSQLTotaisCard(cardName, valoresDoFiltro) {
        const { cardSelected } = this.state;


        if (cardSelected === cardName) {
            this.setState({
                cardSelected: "",
                valorCard: "",
                page: 0,
                first: 0
            }, () => {
                this.onPesquisar();
            })
        } else if (cardName === "cardVencidas") {
            const dataVencimento = format(valoresDoFiltro, 'yyyy-MM-dd');

            this.setState({
                cardSelected: cardName,
                valorCard: `vencimento<${dataVencimento};status!=RECEBIDA`,
                page: 0,
                first: 0
            }, () => {
                this.onPesquisar();
            });
        } else {
            this.setState({
                cardSelected: cardName,
                valorCard: `status==${valoresDoFiltro.join(' or status==')}`,
                page: 0,
                first: 0
            }, () => {
                this.onPesquisar();
            });
        }
    }

    async handleEditItem(item) {
        await asyncGetContaReceber(item.id, ({ data: contaReceber }) => {
            this.setState({ registroSelecionado: { ...contaReceber, vendaId: item.vendaId }, exibirModalContaReceber: true })
        })
    }

    async handleEfetuarRecebimentoItem(item) {
        this.setState({ exibirModalConfirmarRecebimento: true, registroSelecionado: item })
    }

    async handleRemoveItem(item) {
        if (item.itemRepeticao) {
            this.setState({ exibirModalExclusao: true, registroSelecionado: item })
        } else {
            confirm('Confirmação', montarMensagemExclusaoTitulos(item.status, item.origem), async () => {
                await asyncDeleteContaReceber(item.id, () => this.onPesquisar())
            });
        }
    }

    onHideModalExclusao(tipoExclusao) {
        if (tipoExclusao) {
            const { registroSelecionado } = this.state
            if (tipoExclusao === tiposExclusoesContasReceber.APENAS_SELECIONADA) {
                asyncDeleteContaReceber(registroSelecionado.id, () => {
                    this.onPesquisar()
                    this.setState({ exibirModalExclusao: false, registroSelecionado: null })
                })
            } else if (tipoExclusao === tiposExclusoesContasReceber.PROXIMAS_EM_ABERTO) {
                asyncDeleteContaReceberProximasEmAberto(registroSelecionado.id, () => {
                    this.onPesquisar()
                    this.setState({ exibirModalExclusao: false, registroSelecionado: null })
                })
            } else {
                asyncDeleteContaReceberTodasEmAberto(registroSelecionado.id, () => {
                    this.onPesquisar()
                    this.setState({ exibirModalExclusao: false, registroSelecionado: null })
                })
            }
        } else {
            this.setState({ exibirModalExclusao: false, registroSelecionado: null })
        }
    }

    onHideModalConfirmarRecebimento(recebimentoConfirmado) {
        if (recebimentoConfirmado) {
            this.pesquisar()
        }
        this.setState({ exibirModalConfirmarRecebimento: false, registroSelecionado: null })
    }

    montarHeader() {
        const { interval, registros, cardSelected } = this.state;
        const dataInicialFormatada = interval.dataInicial && format(interval.dataInicial, 'dd/MM/yyyy');
        const dataFinalFormatada = interval.dataFinal && format(interval.dataFinal, 'dd/MM/yyyy');
        const { isMobile } = this.props;

        let contaSituacao = "TODAS";

        if (Boolean(cardSelected === "cardVencidas")) {
            contaSituacao = "VENCIDAS";
        } else if (Boolean(cardSelected === "cardAReceber")) {
            contaSituacao = "A_RECEBER";
        } if (Boolean(cardSelected === "cardRecebidas")) {
            contaSituacao = "RECEBIDAS";
        }

        return (
            <span style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <span>Contas a receber</span>
                <span>
                    <If test={registros && registros.length > 0}>
                        <span
                            title={`Imprimir relatório de ${dataInicialFormatada} até ${dataFinalFormatada}`}
                            style={styleBotaoImprimir}
                            onClick={() => {
                                asyncBaixarRelatorioContasAReceberPorPeriodo(interval.dataInicial, interval.dataFinal, contaSituacao, document => {
                                    baixarArquivo(document.data, "Contas a receber por periodo");
                                })
                            }}
                        >
                            <FaPrint size="15px" style={!isMobile && { marginRight: '5px' }} />
                            {!isMobile && <span>Imprimir</span>}
                        </span>
                    </If>
                </span>
            </span>
        )
    }

    render() {
        const { registros,
            valorPesquisa,
            optionsFiltroAvancado,
            descricaoFiltroAvancado,
            limparFiltroPesquisaAvancada,
            registroSelecionado,
            cards,
            totalRecords,
            size,
            first,
            sortField,
            sortOrder,
            exibirModalContaReceber,
            cardSelected,
            podeInserir,
            interval,
            exibirModalExclusao,
            exibirModalConfirmarRecebimento
        } = this.state;
        const estaNoPeriodoAtual = interval.dataInicial && interval.dataFinal ? isWithinInterval(new Date(), { start: interval.dataInicial, end: interval.dataFinal }) : false
        return (
            <>
                <Form header={this.montarHeader()}>
                    <FormActions>
                        <ButtonNovo
                            label="Nova conta a receber"
                            title="Inserir uma nova conta a receber"
                            onClick={() => this.setState({ exibirModalContaReceber: true, registroSelecionado: null })}
                            disabled={!podeInserir}
                        />
                    </FormActions>
                    <FormContent>
                        <Grid justifyCenter verticalAlignCenter>
                            <span style={{ padding: '12px' }}>
                                <DateInterval
                                    interval={interval}
                                    onChange={this.handleChangeInterval}
                                />
                            </span>
                            <InputSearch
                                onPesquisar={this.onPesquisar}
                                value={valorPesquisa}
                                onChange={value => this.setState({ valorPesquisa: value })}
                            />

                            <span style={{ padding: '12px' }}>
                                <PesquisaAvancada
                                    optionsFiltros={optionsFiltroAvancado}
                                    onPesquisarClick={this.onPesquisarFiltroAvancado}
                                    onChangeFiltroRsql={rsql => this.setState({ valorFiltroAvancado: rsql })}
                                    onChangeDescricaoFiltro={e => this.setState({ descricaoFiltroAvancado: e })}
                                    liparFiltro={limparFiltroPesquisaAvancada}
                                />
                            </span>

                        </Grid>

                        <Grid justifyBetween>
                            <Col xs="12" sm="6" md="6" lg="3" xl="3">
                                <CardTotalizadorListagem
                                    name="cardAReceber"
                                    title="A receber no período"
                                    helpMessage="Soma dos valores a receber no período selecionado"
                                    icon={<FaCheckCircle size={22} color={Financas.cores.cinza} />}
                                    primaryColor={Financas.cores.cinza}
                                    value={cards.aReceber}
                                    selectable
                                    selected={cardSelected === "cardAReceber"}
                                    onSelect={this.handleSelectCardAReceber}
                                />
                            </Col>
                            <Col xs="12" sm="6" md="6" lg="3" xl="3">
                                <CardTotalizadorListagem
                                    name="cardRecebidas"
                                    title="Recebidas no período"
                                    helpMessage="Soma dos valores recebidos no período selecionado"
                                    icon={<FaCheckCircle size={22} color={Financas.cores.verde} />}
                                    primaryColor={Financas.cores.verde}
                                    value={cards.recebido}
                                    selectable
                                    selected={cardSelected === "cardRecebidas"}
                                    onSelect={this.handleSelectCardRecebidas}
                                />
                            </Col>
                            <Col xs="12" sm="6" md="6" lg="3" xl="3">
                                <CardTotalizadorListagem
                                    name="cardVencidas"
                                    title="Todas as vencidas"
                                    helpMessage="Soma dos valores de todas as contas a receber vencidas independente do período"
                                    icon={<FaExclamationCircle size={22} color={Financas.cores.vermelho} />}
                                    primaryColor={Financas.cores.vermelho}
                                    value={cards.vencidas}
                                    selectable
                                    selected={cardSelected === "cardVencidas"}
                                    onSelect={this.handleSelectCardVencidas}
                                />
                            </Col>

                            <Col xs="12" sm="6" md="6" lg="3" xl="3">
                                <CardTotalizadorListagem
                                    name="cardTotalAReceber"
                                    title="Total no período"
                                    helpMessage="Total de recebimentos dentro do período selecionado"
                                    icon={<FaEquals size={22} color={Financas.cores.azul} />}
                                    primaryColor={Financas.cores.azul}
                                    value={cards.totalAReceber}
                                />
                            </Col>
                        </Grid>
                        <Grid style={{ paddingTop: '10px' }} justifyCenter verticalAlignCenter>
                            <DescricaoFiltroAvancado texto={descricaoFiltroAvancado} />
                            <TabelaContasReceber
                                registros={registros}
                                totalRecords={totalRecords}
                                rows={size}
                                first={first}
                                sortField={sortField}
                                sortOrder={sortOrder}
                                setSortField={sortField => this.setState({ sortField })}
                                setSortOrder={sortOrder => this.setState({ sortOrder }, () => this.pesquisar())}
                                onEditItem={this.handleEditItem}
                                onEfetuarRecebimentoItem={this.handleEfetuarRecebimentoItem}
                                onRemoveItem={this.handleRemoveItem}
                                onPageChange={e => this.setState({ first: e.first, size: e.rows, page: e.page }, () => this.pesquisar())}
                            />
                        </Grid>
                    </FormContent>
                </Form>
                <If test={exibirModalContaReceber}>
                    <ModalContaReceber
                        onNovoClick={() => this.setState({ registroSelecionado: null })}
                        visible={exibirModalContaReceber}
                        onHide={this.onHideModalContaReceber}
                        registroSelecionado={registroSelecionado}
                        valorPadraoDataVencimento={estaNoPeriodoAtual ? new Date() : null}
                    />
                </If>
                <If test={exibirModalExclusao}>
                    <ModalExclusaoContasReceber
                        visible={exibirModalExclusao}
                        onHide={this.onHideModalExclusao}
                    />
                </If>
                <If test={exibirModalConfirmarRecebimento}>
                    <ModalRecebimentoTable
                        registroSelecionado={registroSelecionado}
                        visible={exibirModalConfirmarRecebimento}
                        onHide={this.onHideModalConfirmarRecebimento}
                    />
                </If>
            </>
        )
    }
}

const mapStateToProps = state => ({
    isMobile: state.dispositivo.isMobile,
})

export default connect(mapStateToProps)(ContasReceber);
