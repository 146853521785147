import { csosns } from "../../../utils/constantes"
import { infoStatusNfce } from "./constantes"

export function validarValorNegativo(valor){
    return valor < 0 ? 0.0 : valor 
}

export function buscarLabelCsosn(csosn) {
    switch (csosn) {
        case csosns.CSOSN_101:
            return '101 - Tributada pelo Simples Nacional com Permissão de Crédito'
        case csosns.CSOSN_102:
            return '102 - Tributada pelo Simples Nacional sem Permissão de Crédito'
        case csosns.CSOSN_103:
            return '103 - Isenção do ICMS no Simples Nacional para Faixa de Receita Bruta'
        case csosns.CSOSN_201:
            return '201 - Tributada pelo Simples Nacional com Permissão de Crédito e com Cobrança do ICMS por Substituição Tributária'
        case csosns.CSOSN_202:
            return '202 - Tributada pelo Simples Nacional sem Permissão de Crédito e com cobrança do ICMS por Substituição Tributária'
        case csosns.CSOSN_203:
            return '203 - Isenção do ICMS no Simples Nacional para Faixa de Receita Bruta e com Cobrança do ICMS por Substituição Tributária'
        case csosns.CSOSN_300:
            return '300 - Imune (Imunidade do ICMS)'
        case csosns.CSOSN_400:
            return '400 - Não Tributada pelo Simples Nacional'
        case csosns.CSOSN_500:
            return '500 - ICMS Cobrado Anteriormente por Substituição Tributária (Substituído) ou por Antecipação'
        case csosns.CSOSN_900:
            return '900 - Outros (Demais Operações)'
        default:
            return null
    }
}

export function getPrimaryColorOfSelectedItem(statusNfce) {
    if(statusNfce) {
        return infoStatusNfce[statusNfce]?.strongColor;
    }
    return "#006095";
}

