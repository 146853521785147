import React from 'react';
import { formatarMonetario } from '../../../../../../../common/mascara';
import Col from '../../../../../../../components/Col';
import Grid from '../../../../../../../components/Grid';
import DescritivoImposto from './components/DescritivoImposto';

const totalStyle = {
    color: '#006095',
    fontWeight: '600',
    fontSize: '24px',
}

const style = {
    marginTop: '30px',
}

const divider = {
    borderTop: '1px solid #a6a6a6',
    marginRight: '0px',
    marginLeft: '4px',
    marginTop: '20px'
}

export default function NfeTabelaTotalizadores({ icmsTot }) {
    const {
        vBC,
        vBCST,
        vDesc,
        vFCP,
        vFCPST,
        vFCPSTRet,
        vFCPUFDest,
        vFrete,
        vICMS,
        vICMSUFDest,
        vICMSUFRemet,
        vIPIDevol,
        vNF,
        vOutro,
        vProd,
        vST,
        vSeg,
        vTotTrib,
    } = icmsTot
    return (
        <Grid style={style}>
            <Col col="12" sm="12" md="6" lg="6" xl="6">
                <table style={{ display: 'grid' }}>
                    <tbody style={{ marginRight: '0px', marginLeft: '4px' }}>
                        <DescritivoImposto
                            descricaoImposto="Base de cálculo do ICMS"
                            valorImposto={vBC}
                        />
                        <DescritivoImposto
                            descricaoImposto="Valor do ICMS"
                            valorImposto={vICMS}
                        />
                        <DescritivoImposto
                            descricaoImposto="Valor do ICMS Interestadual para UF de destino"
                            valorImposto={vICMSUFDest}
                        />
                        <DescritivoImposto
                            descricaoImposto="Valor do ICMS Interestadual para UF do remetente"
                            valorImposto={vICMSUFRemet}
                        />
                        <DescritivoImposto
                            descricaoImposto="Base de cálculo do ICMS-ST"
                            valorImposto={vBCST}
                        />
                        <DescritivoImposto
                            descricaoImposto="Valor do ICMS-ST"
                            valorImposto={vST}
                        />
                        <DescritivoImposto
                            descricaoImposto="Valor Total do IPI devolvido"
                            valorImposto={vIPIDevol}
                        />
                        <DescritivoImposto
                            descricaoImposto="FCP da UF de destino"
                            valorImposto={vFCPUFDest}
                        />
                        <DescritivoImposto
                            descricaoImposto="Total do FCP retido por ST"
                            valorImposto={vFCPST}
                        />
                        <DescritivoImposto
                            descricaoImposto="Total do FCP retido anteriormente por ST"
                            valorImposto={vFCPSTRet}
                        />
                        <DescritivoImposto
                            descricaoImposto="Valor Total do FCP"
                            valorImposto={vFCP}
                        />
                    </tbody>
                </table>
            </Col>
            <Col col="12" sm="12" md="6" lg="6" xl="6">
                <Grid style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginRight: '0px', marginLeft: '4px' }}>
                    <div style={{ textAlign: 'start' }}>
                        <div>Tributos fed., est. e mun.</div>
                        <div>Valor aprox. tributos</div>
                        <div>Valor dos produtos</div>
                    </div>
                    <div style={{ textAlign: 'end' }}>
                        <div>{formatarMonetario(vTotTrib)}</div>
                        <div>{formatarMonetario(vProd)}</div>
                    </div>
                </Grid>

                <hr style={divider} />

                <Grid style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginRight: '0px', marginLeft: '4px' }}>
                    <div style={{ textAlign: 'start' }}>
                        <div>Valor do frete</div>
                        <div>Valor do seguro</div>
                        <div>Despesas acessórias</div>
                        <div>Valor de desconto</div>
                    </div>
                    <div style={{ textAlign: 'end' }}>
                        <div>{formatarMonetario(vFrete)}</div>
                        <div>{formatarMonetario(vSeg)}</div>
                        <div>{formatarMonetario(vOutro)}</div>
                        <div>{formatarMonetario(vDesc)}</div>
                    </div>
                </Grid>

                <hr style={divider} />

                <Grid style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginRight: '0px', marginLeft: '4px' }}>
                    <div style={{ textAlign: 'start' }}>
                        <div style={totalStyle}>Valor total</div>
                    </div>
                    <div style={{ textAlign: 'end' }}>
                        <div style={totalStyle}>{formatarMonetario(vNF)}</div>
                    </div>
                </Grid>
            </Col>
        </Grid>
    )
}
