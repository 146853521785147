import React, { Component } from 'react';
import * as Yup from 'yup';
import autoBind from 'react-autobind';
import { withRouter } from 'react-router';
import { Field, withFormik } from 'formik';
import { formatISO } from 'date-fns';


import { usuarioPossuiPermissao } from '../../../../../common/autenticacao';
import { services } from '../../../../../common/constantes/api';
import { recursos, permissoes, estadosCadastro } from '../../../../../common/constantes/autorizacao';
import { mensagensDeValidacao } from '../../../../../common/constantes/mensagens';
import { formatarMonetario } from '../../../../../common/mascara';
import { renderizarValidacao } from '../../../../../common/tratamentoDeErro/validacoesDeCampos';
import ButtonCancelar, { estadosBotaoCancelar } from '../../../../../components/Button/ButtonCancelar';
import ButtonExcluir from '../../../../../components/Button/ButtonExcluir';
import ButtonNovo, { estadosBotaoNovo } from '../../../../../components/Button/ButtonNovo';
import ButtonSalvar, { estadosBotaoSalvar } from '../../../../../components/Button/ButtonSalvar';
import Col from '../../../../../components/Col';
import Form from '../../../../../components/Form';
import FormActions from '../../../../../components/FormActions';
import FormContent from '../../../../../components/FormContent';
import Grid from '../../../../../components/Grid';
import InputDate from '../../../../../components/Input/InputDate';
import InputDouble from '../../../../../components/Input/InputDouble';
import AutoProgressBar from '../../../../../components/Loading/AutoProgressBar';
import Modal from '../../../../../components/Modal';
import Dropdown from '../../../../../components/Select/Dropdown';
import SingleSelectAtivo from '../../../../../components/Select/SingleSelectAtivo';
import SingleSelectCorretoraConta from '../../../../../components/Select/SingleSelectCorretoraConta';
import { confirm } from '../../../../../components/Toast';
import { validarFormulario } from '../../../../util';
import { asyncDeleteNegociacao } from '../../requests';
import { converterNegociacaoParaApi } from '../../util/negociacaoConverter';
import { asyncCreateNegociacao, asyncUpdateNegociacao } from './requests';
import InputMoney from '../../../../../components/Input/InputMoney';
import { helpMessageNegociacao } from '../../util/utils';


const partitialStyle = {
    fontSize: '13px',
    color: '#000000',
    margin: '0px 0px',
}

const totalStyle = {
    fontSize: '20px',
    color: '#000000',
    margin: '10px 0px 0px 0px',
}

const tipoOperacao = [
    { label: 'Saldo Inicial', value: 'SALDO_INICIAL' },
    { label: 'Compra', value: 'COMPRA' },
    { label: 'Venda', value: 'VENDA' }
];

const tipoMercado = [
    { label: 'A Vista', value: 'VISTA' },
    { label: 'Fracionário', value: 'FRACIONARIO' },
];

const initialValue = {
    id: '',
    data: formatISO(new Date()),
    operacao: 'COMPRA',
    mercado: 'VISTA',
    quantidade: 1,
    preco: 0,
    subtotal: 0,
    cotacao: 1,
    custos: 0,
    valorTotal: 0,
    ativo: null,
    conta: null,
};

class ModalNegociacao extends Component {
    constructor(props) {
        super(props);
        autoBind(this);

        this.state = {
            podeInserir: usuarioPossuiPermissao(recursos.INVESTIDOR_NEGOCIACOES, permissoes.INSERIR),
            podeEditar: usuarioPossuiPermissao(recursos.INVESTIDOR_NEGOCIACOES, permissoes.EDITAR),
            podeExcluir: usuarioPossuiPermissao(recursos.INVESTIDOR_NEGOCIACOES, permissoes.EXCLUIR),
        }
    }


    async novo() {
        await this.props.resetForm({ values: initialValue })
        this.props.onNovoClick()
    }

    async salvar(e, novoOnSuccess) {
        await this.props.handleSubmit();
        if (await validarFormulario(this.props)) {
            if (this.props.values.id) {
                this.asyncUpdateRegistro({ ...this.props.values }, novoOnSuccess);
            } else {
                this.asyncCreateRegistro({ ...this.props.values }, novoOnSuccess);
            }
        }
    }

    async asyncCreateRegistro(values, novoOnSuccess) {
        if (values) {
            await asyncCreateNegociacao(converterNegociacaoParaApi(this.props.values), () => {
                if (novoOnSuccess) {
                    novoOnSuccess();
                } else {
                    this.props.onHide()
                }
            })
        }
    }

    async asyncUpdateRegistro(values, novoOnSuccess) {
        if (values) {
            await asyncUpdateNegociacao(converterNegociacaoParaApi(this.props.values), () => {
                if (novoOnSuccess) {
                    novoOnSuccess();
                } else {
                    this.props.onHide()
                }
            })
        }
    }

    excluir() {
        confirm('Confirmação', 'Deseja excluir o registro?', async () => {
            await asyncDeleteNegociacao(this.props.values.id, () => {
                this.props.onHide()
            })
        })
    }

    cancelar() {
        const { dirty, resetForm, onHide, initialValues } = this.props
        if (dirty) {
            resetForm({ values: initialValues });
        } else {
            onHide();
        }
    }

    calcularTotais(quantidade, preco, custos) {
        const { setFieldValue } = this.props

        let subtotal = quantidade * preco;
        let valorTotal = subtotal - custos;

        setFieldValue("subtotal", parseFloat(subtotal.toFixed(2)));
        setFieldValue("valorTotal", parseFloat(valorTotal.toFixed(2)));
    }

    renderValor(valor){
        if(valor.toString().length > 13){
            return "Valor muito grande!"
        }
        return formatarMonetario(valor)
    }

    render() {

        const { values, onHide, dirty, visible, setFieldValue, errors } = this.props;

        const informacoesPermissoes = {
            podeInserir: this.state.podeInserir,
            podeEditar: this.state.podeEditar,
            podeExcluir: this.state.podeExcluir,
            estadoCadastro: values.id ? estadosCadastro.EDICAO : estadosCadastro.INCLUSAO
        }

        const estadoBotaoNovo = dirty ? estadosBotaoNovo.SALVAR_E_NOVO : estadosBotaoNovo.NOVO;
        const onClickNovo = dirty ? (e) => this.salvar(e, this.novo) : this.novo;


        return (
            <>
                <Modal
                    header={values.id ? "Editar negociação" : "Nova negociação"}
                    visible={visible}
                    onHide={() => onHide()}
                >
                    <AutoProgressBar />
                    <Form>
                        <FormActions>
                            <ButtonCancelar
                                {...informacoesPermissoes}
                                estadoBotao={dirty ? estadosBotaoCancelar.CANCELAR : estadosBotaoCancelar.VOLTAR}
                                onClick={this.cancelar}
                            />
                            <ButtonSalvar
                                {...informacoesPermissoes}
                                estadoBotao={estadosBotaoSalvar.SALVAR}
                                disabled={!dirty}
                                onClick={this.salvar}
                            />
                            <ButtonNovo
                                onClick={onClickNovo}
                                hidden={!dirty && !values.id}
                                estadoBotao={estadoBotaoNovo}
                                {...informacoesPermissoes}
                            />
                            <ButtonExcluir
                                hidden={!values.id}
                                {...informacoesPermissoes}
                                onClick={this.excluir}
                            />
                        </FormActions>
                        <FormContent>
                            <Grid>

                                <Field sm="12" md="6" lg="4" xl="4"
                                    component={InputDate}
                                    helpMessage={helpMessageNegociacao.data}
                                    obrigatorio
                                    label="Data"
                                    name="data"
                                    onChange={e => setFieldValue('data', e.target.value)}
                                    value={values.data}
                                    {...informacoesPermissoes}
                                />

                                <Field
                                    sm="12" md="6" lg="4" xl="4"
                                    component={Dropdown}
                                    helpMessage={helpMessageNegociacao.mercado}
                                    label='Mercado'
                                    obrigatorio
                                    name="mercado"
                                    showClear={false}
                                    onChange={(e) => setFieldValue('mercado', e.value)}
                                    options={tipoMercado}
                                    {...informacoesPermissoes}
                                />

                                <Field
                                    sm="12" md="6" lg="4" xl="4"
                                    component={Dropdown}
                                    helpMessage={helpMessageNegociacao.operacao}
                                    label='Operação'
                                    obrigatorio
                                    name="operacao"
                                    showClear={false}
                                    onChange={(e) => setFieldValue('operacao', e.value)}
                                    options={tipoOperacao}
                                    {...informacoesPermissoes}
                                />

                                <Field sm="12" md="6" lg="6" xl="6"
                                    name="conta"
                                    helpMessage={helpMessageNegociacao.conta}
                                    label="Corretora (conta)"
                                    obrigatorio
                                    component={SingleSelectCorretoraConta}
                                    value={values.conta}
                                    onChange={e => setFieldValue('conta', e)}
                                    url={`${services.GESTOR}/v1/investidor/negociacoes/relacoes/contas`}
                                    {...informacoesPermissoes}
                                />

                                <Field sm="12" md="6" lg="6" xl="6"
                                    name="ativo"
                                    helpMessage={helpMessageNegociacao.ativo}
                                    label="Ativo"
                                    obrigatorio
                                    component={SingleSelectAtivo}
                                    value={values.ativo}
                                    onChange={e => setFieldValue('ativo', e)}
                                    url={`${services.GESTOR}/v1/investidor/negociacoes/relacoes/ativos`}
                                    {...informacoesPermissoes}
                                />

                                <Field sm="12" md="6" lg="4" xl="4"
                                    component={InputDouble}
                                    helpMessage={helpMessageNegociacao.quantidade}
                                    obrigatorio
                                    label='Quantidade'
                                    name="quantidade"
                                    allowNegative={false}
                                    onChange={e => {
                                        const quantidade = e.target.value 
                                        setFieldValue('quantidade', quantidade);
                                        this.calcularTotais(quantidade, values.preco, values.custos)

                                    }}
                                    value={values.quantidade}
                                    size={12}
                                    {...informacoesPermissoes}
                                />

                                <Field sm="12" md="6" lg="4" xl="4"
                                    component={InputMoney}
                                    helpMessage={helpMessageNegociacao.preco}
                                    obrigatorio
                                    label='Valor unitário do ativo'
                                    name="preco"
                                    allowNegative={false}
                                    onChange={e => {
                                        const preco = e.target.value
                                        setFieldValue('preco', preco);
                                        this.calcularTotais(values.quantidade, preco, values.custos)
                                    }}
                                    value={values.preco}
                                    size={13}
                                    {...informacoesPermissoes}
                                />

                                <Field sm="12" md="6" lg="4" xl="4"
                                    component={InputMoney}
                                    helpMessage={helpMessageNegociacao.custos}
                                    obrigatorio
                                    label='Custos'
                                    name="custos"
                                    allowNegative={false}
                                    onChange={e => {
                                        const custos = e.target.value
                                        setFieldValue('custos', custos)
                                        this.calcularTotais(values.quantidade, values.preco, custos)
                                    }}
                                    value={values.custos}
                                    size={13}
                                    {...informacoesPermissoes}
                                />

                                <Grid justifyEnd style={{ width: '100%', textAlign: 'end' }}>
                                    <Col>
                                        <p style={partitialStyle}>{`Subtotal: ${this.renderValor(values.subtotal)} `}</p>
                                        <p style={partitialStyle}>{`Custos: ${this.renderValor(values.custos)}`}</p>
                                        <p style={totalStyle}>{`Total: ${this.renderValor(values.valorTotal)}`}</p>
                                        {errors.valorTotal && renderizarValidacao(errors.valorTotal, true)}
                                    </Col>
                                </Grid>
                            </Grid>
                        </FormContent>
                    </Form>
                </Modal>
            </>
        )
    }
}

ModalNegociacao = withFormik({
    validateOnChange: true,

    mapPropsToValues(props) {
        if (props.registroSelecionado) {
            return props.registroSelecionado
        }
        return initialValue
    },

    validate(values) {
        let errors = {}

        if (values.valorTotal <= 0) {
            errors.valorTotal = "O Valor total deve ser maior ou igual a R$ 00.01"
        }else if(values.valorTotal.toString().length > 13){
            errors.valorTotal = "Sua consquista é tão grande que o sistema não suporta ela!!! O sistema suporta apenas valores menores que R$ 9.999.999.999,99."
        }

        if(values.preco <= 0) {
            errors.preco = "Valor unitário não pode ser zero"
        } 

        return errors
    },

    validationSchema: Yup.object().shape({
        data: Yup.string().nullable().required(mensagensDeValidacao.OBRIGATORIO),
        operacao: Yup.string().nullable().required(mensagensDeValidacao.OBRIGATORIO),
        mercado: Yup.string().nullable().required(mensagensDeValidacao.OBRIGATORIO),
        quantidade: Yup.string().nullable().required(mensagensDeValidacao.OBRIGATORIO),
        preco: Yup.string().nullable().required(mensagensDeValidacao.OBRIGATORIO),
        subtotal: Yup.string().nullable().required(mensagensDeValidacao.OBRIGATORIO),
        custos: Yup.string().nullable().required(mensagensDeValidacao.OBRIGATORIO),
        valorTotal: Yup.string().nullable().required(mensagensDeValidacao.OBRIGATORIO),
        ativo: Yup.object().nullable().required(mensagensDeValidacao.OBRIGATORIO),
        conta: Yup.object().nullable().required(mensagensDeValidacao.OBRIGATORIO),
    }),

    handleSubmit: () => { }
})(ModalNegociacao);

export default withRouter(ModalNegociacao);

