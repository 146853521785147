import parse from 'date-fns/parse';

function converterProdutoParaFormulario(produto) {
    return {
        id: produto.id,
        nome: produto.nome,
        sku: produto.sku,
        situacao: produto.situacao,
        tipo: produto.tipo,
        controlarEstoque: produto.controlarEstoque,
        preco: produto.preco,
        codigoBarras: produto.codigoBarras,
        unidadeMedida: converterUnidadMedidaParaFormulario(produto.unidadeMedida),
        cadastro: parse(produto.cadastro, 'yyyy-MM-dd', new Date()),
        estoqueSaldo: produto.estoqueSaldo,
        estoqueMinimo: produto.estoqueMinimo,
        ultimoCusto: produto.ultimoCusto,
        observacao: produto.observacao,

        origem: produto.origem,
        ncm: converterNcmParaFormulario(produto),
        cest: converterCestParaFormulario(produto),
        pesoBruto: produto.pesoBruto,
        pesoLiquido: produto.pesoLiquido,
    }
}

function converterProdutoParaApi(produto) {
    return {
        id: produto.id,
        nome: produto.nome,
        sku: produto.sku,
        situacao: produto.situacao,
        tipo: produto.tipo,
        controlarEstoque: produto.tipo === 'PRODUTO' ? produto.controlarEstoque : false,
        preco: produto.preco,
        ultimoCusto: produto.ultimoCusto,
        codigoBarras: produto.codigoBarras,
        unidadeMedida: produto.unidadeMedida ? {
            id: produto.unidadeMedida.value
        } : null,
        cadastro: produto.cadastro,
        estoqueSaldo: produto.id ? null : produto.estoqueSaldo,
        estoqueMinimo: produto.estoqueMinimo,
        observacao: produto.observacao,

        origem: produto.origem,
        ncm: produto.ncm ? { id: produto.ncm.value } : null,
        cest: produto.cest ? { id: produto.cest.value } : null,
        pesoBruto: produto.pesoBruto,
        pesoLiquido: produto.pesoLiquido,
    }
}

function converterUnidadMedidaParaFormulario(produto) {
    if (produto && produto.unidade) {
        return {
            label: produto.unidade + ' - ' + produto.descricao,
            value: produto.id
        }
    }
    return null
}

function converterCestParaFormulario(produto) {
    if (produto && produto.cest) {
        return {
            label: produto.cest.codigo + ' - ' + produto.cest.descricao,
            value: produto.cest.id
        }
    }
    return null
}

function converterNcmParaFormulario(produto) {
    if (produto && produto.ncm) {
        return {
            label: produto.ncm.codigo + ' - ' + produto.ncm.descricao,
            value: produto.ncm.id
        }
    }
    return null
}

export { converterProdutoParaFormulario, converterProdutoParaApi }