export function montarLabelPessoa(pessoa) {
    let complemento = "";
    if (pessoa.cnpj) {
        complemento = ` - ${pessoa.cnpj}`
    } else if (pessoa.cpf) {
        complemento = ` - ${pessoa.cpf}`
    } else if (pessoa.identificacao) {
        complemento = ` - ${pessoa.identificacao}`
    }

    return `${pessoa.nome}${complemento}`
}
