import { confirm } from "../../../../../../components/Toast"

export function exibirConfirmacaoCodigo(onOk){
    confirm("Confirmação", `Já existe um cadastro de ativo com o mesmo código. Tem certeza que deseja salvar?`, onOk)
}

export const helpMessageProventos = {
    data: 'Data de recebimento do provento',
    tipo: 'Tipo de provento recebido',
    conta: 'Corretora/Conta a qual você recebeu o seu provento',
    ativo: 'Empresa que pagou os proventos recebidos',
    valorBruto: 'Valor antes de aplicar qualquer desconto no total do provento recebido',
    valorLiquido: 'Valor após descontar o imposto de renda pago. Quando aplicável',
}
