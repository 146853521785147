import React, { Component } from 'react';
import autoBind from 'react-autobind';
import { withRouter } from 'react-router';
import { Column } from 'primereact/column';
import { format } from 'date-fns/esm';
import { DataTable } from 'primereact/components/datatable/DataTable';
import Button from '../../../../../../../components/Button';
import { formatarMonetario } from '../../../../../../../common/mascara';
import { parseISO } from 'date-fns';
import NenhumRegistroEncontrado from '../../../../../../../components/NenhumRegistroEncontrado';

const styleButton = {
    borderRadius: '50%',
    padding: '5px',
    width: '30px',
    height: '30px',
    margin: '2px'
};

const styleValorField = {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
};


class TabelaPagamentos extends Component {

    constructor(props) {
        super(props);
        autoBind(this);
    }

    renderData(el) {
        return <span>{format(parseISO(el.data), 'dd/MM/yyyy')}</span>
    }

    renderValor(el) {
        const valor = formatarMonetario(el.valor)
        return <span title={valor}>{valor}</span>
    }

    renderConta(el) {
        return <span >{el.conta.label}</span>
    }

    renderJurosMulta(el) {
        const valor = formatarMonetario(el.juros + el.multa)
        return <span title={valor}>{valor}</span>
    }

    renderTotal(el) {
        const valor = formatarMonetario(el.valor + el.juros + el.multa - el.desconto)
        return <span style={{ fontWeight: 'bold' }} title={valor}>{valor}</span>
    }

    renderDesconto(el) {
        const valor = formatarMonetario(el.desconto)
        return <span title={valor}>{valor}</span>
    }


    renderAcoes(el) {
        const { podeExcluir, podeEditar } = this.props;
        let titleEditar = "Editar";
        let titleExcluir = "Excluir";
        let disableBtnEditar = false;
        let disableBtnExcluir = false;

        if (!podeEditar) {
            titleEditar = "Você não possuir permissão para executar essa ação"
            disableBtnEditar = true;
        }

        if (!podeExcluir) {
            titleExcluir = "Você não possui permissão para executar essa ação"
            disableBtnExcluir = true;
        };

        return (
            <span>
                <Button
                    style={styleButton}
                    className="p-button p-button-primary"
                    icon="fa fa-pencil"
                    title={titleEditar}
                    disabled={disableBtnEditar}
                    onClick={() => this.props.onEditar(el)}
                />
                <Button
                    style={styleButton}
                    className="p-button p-button-danger"
                    icon="fa fa-trash"
                    title={titleExcluir}
                    disabled={disableBtnExcluir}
                    onClick={() => this.props.onExcluir(el)}
                />
            </span>
        );
    }
    render() {

        const { registros } = this.props;

        if (registros && registros.length > 0) {
            return (
                <DataTable
                    className="table-light"
                    responsive
                    value={registros}
                    emptyMessage={<NenhumRegistroEncontrado />}
                >
                    <Column
                        header="Data"
                        field="data"
                        body={this.renderData}
                    />
                    <Column
                        header="Conta"
                        field="conta"
                        body={this.renderConta}
                    />
                    <Column
                        style={styleValorField}
                        header="Valor"
                        field="valor"
                        body={this.renderValor}
                    />
                    <Column
                        style={styleValorField}
                        header="Juros + Multa"
                        field="juros"
                        body={this.renderJurosMulta}
                    />
                    <Column
                        style={styleValorField}
                        header="Descontos"
                        field="desconto"
                        body={this.renderDesconto}
                    />
                    <Column
                        style={styleValorField}
                        header="Total"
                        field="total"
                        body={this.renderTotal}
                    />
                    <Column
                        header="Ações"
                        style={{ width: '100px' }}
                        body={this.renderAcoes}
                    />
                </DataTable>
            )
        } else {
            return (
                <div></div>
            )
        }
    }
}


export default withRouter(TabelaPagamentos);
