import React, { Component } from 'react';
import MultipleSelect from '../MultipleSelect';
import autoBind from "react-autobind";
import propTypes from 'prop-types';

class MultipleSelectAtivos extends Component {
    constructor(props) {
        super(props);
        autoBind(this);
    }

    buscarUrlPesquisa(pesquisa, page) {
        return `${this.props.url}?query=(codigo=contains="*${pesquisa}*",tipo=contains="*${pesquisa}*",nome=contains="*${pesquisa}*",segmento=contains="*${pesquisa}*",cnpj=contains="*${pesquisa}*")&page=${page}&size=50&sort=nome`;
    }

    setarNovoRegistroNoSelect(novoRegistro) {
        if (novoRegistro) {
            this.props.onChange(novoRegistro)
        }
    }

    montarLabel(row) {
        if (row) {
            return row.codigo + " - " + row.nome;
        }
    }

    render() {
        return (
            <MultipleSelect
                montarLabel={this.montarLabel}
                buscarUrlPesquisa={this.buscarUrlPesquisa}
                onChange={this.props.onChange}
                {...this.props}
            />
        );
    }
}

MultipleSelectAtivos.propTypes = {
    /** Url do servidor em que as requisições de busca deverão ser efetuadas: Ex:  `${services.GESTOR}/v1/usuarios/relacoes/setores`*/
    url: propTypes.string.isRequired
};

MultipleSelectAtivos.defaultProps = {
    label: ''
};

export default MultipleSelectAtivos;