import { manterApenasNumeros } from "../../../../../../../common/mascara"

export function converterPessoaContatoParaApi(values) {
    return {
        ...values,
        telefone: manterApenasNumeros(values.telefone)
    }
}

export function converterPessoaContatoParaFormulario(values) {
    return {
        ...values,
        telefone: manterApenasNumeros(values.telefone)
    }
}
