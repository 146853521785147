import { Field } from 'formik';
import React from 'react';
import InputDouble from '../../../../../../../../components/Input/InputDouble';
import InputMoney from '../../../../../../../../components/Input/InputMoney';
import InputMoneyGroup from '../../../../../../../../components/Input/InputMoneyGroup';
import Dropdown from '../../../../../../../../components/Select/Dropdown';
import { optionsModalidadeDeterminacaoICMS } from '../../../../../../utils/constantes';
import { helpNFeProd } from '../help';

export default function CalculoICMS(props) {
    const { informacoesPermissoes, values, onChangeImpostoIcms, dest } = props;

    return (
        <>
            <Field
                sm="12" md="4" lg='4' xl='4'
                label='Alíquota do ICMS'
                name="pICMS"
                value={values.imposto.icms.pICMS}
                onChange={e => onChangeImpostoIcms('pICMS', e.target.value)}
                size={8}
                component={InputMoneyGroup}
                decimalScale={2}
                prefix=''
                suffix='%'
                maxValue={100}
                placeholder='0,00 %'
                iconButton="fa fa-flash"
                titleButton={false ? "Você não pode buscar a alíquota do estado de destino" : "Buscar alíquota do estado de destino"}
                colorIconButton="success"
                disabledButton={false}
                onClickButton={() => onChangeImpostoIcms('pICMS', dest.enderDest.estAliqInt)}
                helpMessage={helpNFeProd.pICMS}
                {...informacoesPermissoes}
            />
            <Field sm="12" md="4" lg='4' xl='4'
                label='Alíquota de crédito de ICMS'
                name="pCredSN"
                value={values.imposto.icms.pCredSN}
                onChange={e => onChangeImpostoIcms('pCredSN', e.target.value)}
                size={8}
                component={InputDouble}
                decimalScale={2}
                allowNegative={false}
                helpMessage={helpNFeProd.pCredSN}
                suffix=' %'
                maxValue={100}
                {...informacoesPermissoes}
            />
            <Field sm="12" md="4" lg='4' xl='4'
                component={Dropdown}
                label='Modalidade do ICMS'
                name="modBC"
                onChange={e => onChangeImpostoIcms(e.value)}
                options={optionsModalidadeDeterminacaoICMS}
                value={values.imposto.icms.modBC}
                showClear={false}
                helpMessage={helpNFeProd.modBC}
                {...informacoesPermissoes}
            />
            <Field sm="12" md="4" lg='4' xl='4'
                label='Percentual da Redução de BC'
                name="pRedBC"
                value={values.imposto.icms.pRedBC}
                onChange={e => onChangeImpostoIcms('pRedBC', e.target.value)}
                size={8}
                component={InputMoney}
                decimalScale={2}
                prefix=''
                suffix='%'
                maxValue={100}
                placeholder='0,00 %'
                helpMessage={helpNFeProd.pRedBC}
                {...informacoesPermissoes}
            />
        </>
    )
}