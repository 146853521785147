import React, { Component } from 'react';
import { estadosCadastro } from '../../../../../common/constantes/autorizacao';
import { removerElemento, copiarObjeto } from '../../../../../common/array';
import { Column } from 'primereact/column';
import ButtonExcluirTable from '../../../../../components/Button/ButtonExcluirTable';
import ToolbarDetails from '../../../../../components/Toolbar';
import ButtonNovo from '../../../../../components/Button/ButtonNovo';
import autoBind from 'react-autobind';
import ToolbarItensLeft from '../../../../../components/Toolbar/ToolbarItensLeft';
import Modal from '../../../../../components/Modal';
import PessoaContatoForm from './Form';
import If from '../../../../../components/If';
import ButtonEditarTable from '../../../../../components/Button/ButtonEditarTable';
import { GoBookmark } from 'react-icons/go';
import { formatarTelefone } from '../../../../../common/mascara';
import { DataTable } from 'primereact/components/datatable/DataTable';
import NenhumRegistroEncontrado from '../../../../../components/NenhumRegistroEncontrado';

class PessoaContato extends Component {

    constructor(props) {
        super(props);
        autoBind(this);

        this.state = {
            registroSelecionado: null,
            modalFormVisible: false
        }
    }

    novo() {
        this.setState({ modalFormVisible: true, registroSelecionado: null })
    }

    editar(row) {
        this.setState({ registroSelecionado: row, modalFormVisible: true })
    }

    renderOpcoes(row) {
        const { estadoCadastro } = this.props;

        const desabilitarCampos = (estadoCadastro === estadosCadastro.EDICAO && !this.props.podeEditar);
        return (
            <>
                <ButtonEditarTable
                    onClick={() => this.editar(row)}
                />
                <ButtonExcluirTable
                    onClick={() => this.excluirRegistro(row)}
                    podeExcluir={!desabilitarCampos} />
            </>
        )
    }

    hideModal() {
        this.setState({ modalFormVisible: false, registroSelecionado: null })
    }

    atualizarRegistro(dadosFormulario) {
        const contatos = copiarObjeto(this.props.value);
        const result = contatos && contatos.map(item => {
            if (item.idTemporario && item.idTemporario === dadosFormulario.idTemporario) {
                return dadosFormulario
            } else if (item.id && item.id === dadosFormulario.id) {
                return dadosFormulario
            }

            if (dadosFormulario.favorito) {
                item.favorito = false
            }

            return item;
        })
        this.setState({ registroSelecionado: dadosFormulario })
        this.props.onChange(result)
    }

    inserirRegistro(dadosFormulario) {
        let contatos = copiarObjeto(this.props.value)
        this.props.onChange(this.mergeContatoIntoContatos(dadosFormulario, contatos));
        this.setState({ registroSelecionado: dadosFormulario })
    }

    mergeContatoIntoContatos(contato, contatos) {
        if (contato.favorito) {
            contatos.map((tempContato) => {
                return tempContato.favorito = false
            })
        }
        return [...contatos, contato]
    }

    excluirRegistro(dadosFormulario) {
        this.props.onChange(removerElemento(this.props.value, dadosFormulario))
        this.setState({ registroSelecionado: null })
    }

    marcarComoFavorito() {
        const contatos = copiarObjeto(this.props.value)
        let existemFavoritos = false;

        contatos.forEach(contato => {
            if (contato.favorito) {
                existemFavoritos = true
            }
        });

        return !existemFavoritos
    }

    renderNome(row) {
        return (
            <span style={{ display: "flex", alignItems: "center" }}>
                {row.favorito ? <span> <GoBookmark size={22} color="#fbc02d" title="Contato favorito" /> </span> : null}
                {row.nome}
            </span>
        )
    }

    render() {
        const { estadoCadastro } = this.props;

        const desabilitarCampos = (estadoCadastro === estadosCadastro.EDICAO && !this.props.podeEditar);

        return (
            <>
                <Modal
                    visible={this.state.modalFormVisible}
                    header='Cadastro de contato'
                    onHide={this.hideModal}
                >
                    <If test={this.state.modalFormVisible}>
                        <PessoaContatoForm
                            registroSelecionado={this.state.registroSelecionado}
                            onNovoClick={() => this.setState({ registroSelecionado: null })}
                            inserirRegistro={this.inserirRegistro}
                            atualizarRegistro={this.atualizarRegistro}
                            excluirRegistro={this.excluirRegistro}
                            marcarComoFavorito={this.marcarComoFavorito}
                            onHide={this.hideModal}
                            podeEditar={!desabilitarCampos}
                            podeExcluir={!desabilitarCampos}
                        />
                    </If>
                </Modal>

                <ToolbarDetails>
                    <ToolbarItensLeft style={{ paddingLeft: '0px' }}>
                        <ButtonNovo
                            label="Novo contato"
                            enableShortCut={false}
                            onClick={this.novo}
                            podeInserir={!desabilitarCampos}
                        />
                    </ToolbarItensLeft>
                </ToolbarDetails>

                <DataTable
                    className="table-light"
                    responsive
                    value={this.props.value}
                    emptyMessage={<NenhumRegistroEncontrado message="Nenhum contato encontrado" />}
                >
                    <Column
                        field="nome"
                        body={this.renderNome}
                        header="Nome"
                        sortable={true}
                    />
                    <Column
                        field="email"
                        header="E-mail"
                        sortable={true}
                    />
                    <Column
                        field="telefone"
                        header="Telefone"
                        body={row => formatarTelefone(row.telefone)}
                        sortable={true}
                    />
                    <Column
                        body={this.renderOpcoes}
                        header="Ações"
                        style={{ width: '7em' }}
                    />
                </DataTable>
            </>
        )
    }
}

export default PessoaContato
