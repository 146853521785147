import { Field } from 'formik';
import { Fieldset } from 'primereact/fieldset';
import React, { Component } from 'react';
import autoBind from 'react-autobind'
import { copiarObjeto } from '../../../../../../../common/array';
import { services } from '../../../../../../../common/constantes/api';
import Col from '../../../../../../../components/Col';
import Grid from '../../../../../../../components/Grid';
import If from '../../../../../../../components/If';
import Checkbox from '../../../../../../../components/Input/Checkbox';
import InputDouble from '../../../../../../../components/Input/InputDouble';
import InputField from '../../../../../../../components/Input/InputField';
import SingleSelectEstado from '../../../../../../../components/Select/SingleSelectEstado';
import SingleSelectPessoa from '../../../../../../../components/Select/SingleSelectPessoa';
import ModalDadosTransportador from '../ModalDadosTransportador';
import NfeLinkVerDados from '../NfeLinkVerDados';
import { helpNFeTransporte } from './help';

export default class NfeTransporte extends Component {

    constructor(props) {
        super(props)
        autoBind(this)

        this.state = {
            modalTransportadorVisible: false,
        }
    }
    onChangeVol(field, value) {
        let transp = copiarObjeto(this.props.transp)
        transp.vol[field] = value
        this.props.onChange(transp)
    }

    onChangeTransporta(field, value) {
        let transp = copiarObjeto(this.props.transp)
        transp.transporta[field] = value
        this.props.onChange(transp)
        if(transp.pessoa && transp.pessoa.label !== transp.transporta.xNome){
            transp.transportadorCadastrado = false
            transp.pessoa = null
        }
    }

    onChangePessoaTransp(value) {
        if(value){
            
            let transp = copiarObjeto(this.props.transp)
            
            transp.pessoa = value
            if(value.registro){
                transp.transporta.CPF = value.registro.cpf
                transp.transporta.CNPJ = value.registro.cnpj
                transp.transporta.xNome = value.registro.nome
            }else{
                transp.transporta.CPF = ''
                transp.transporta.CNPJ = ''
                transp.transporta.xNome = value.label
            }
            this.props.onChange(transp)
        }else{
            let transp = copiarObjeto(this.props.transp)
            transp.pessoa = null
            transp.transporta.CPF = ''
            transp.transporta.CNPJ = ''
            transp.transporta.xNome = ''
            this.props.onChange(transp)
        }
    }

    onChangeVeicTransp(field, value) {
        let transp = copiarObjeto(this.props.transp)
        transp.veicTransp[field] = value
        this.props.onChange(transp)
    }

    onChange(field, value) {
        let transp = copiarObjeto(this.props.transp)
        transp[field] = value
        this.props.onChange(transp)
        
        if(!transp.transportadorCadastrado){
            
            transp.pessoa = null
            transp.transporta.CPF = null
            transp.transporta.CNPJ = null
            transp.transporta.xNome = null
        }
    }

    onChangePlaca(e) {
        if (e.target.value) {
            this.onChangeVeicTransp('placa', e.target.value.toUpperCase());
        } else {
            this.onChangeVeicTransp('placa', '');
        }
    }

    render() {
        const { transp, informacoesPermissoes, errors } = this.props;
        
        return (
            <>
                <If test={transp.utilizaTransportador}>
                    <Fieldset legend="Dados do transportador" className='fieldset-light'>
                        <Grid>
                            <Field sm="12" md="12" lg="12" xl="12"
                                component={Checkbox}
                                label="Transportador já cadastrado"
                                name="transportadorCadastrado"
                                onChange={e => this.onChange('transportadorCadastrado', e.checked)}
                                checked={transp.transportadorCadastrado}
                                {...informacoesPermissoes}
                            />
                            <If test={transp.transportadorCadastrado}>
                                <Col sm="6" md="4" lg="4" xl="4">
                                    <Field
                                        component={SingleSelectPessoa}
                                        colStyle={{ padding: '0px' }}
                                        label='Transportador'
                                        name="pessoa"
                                        url={`${services.GESTOR}/v1/vendas/relacoes/clientes`}
                                        value={transp.pessoa}
                                        onChange={this.onChangePessoaTransp}
                                        hidden={!transp.transportadorCadastrado}
                                        {...informacoesPermissoes}
                                    />
                                    <NfeLinkVerDados
                                        label='Ver dados do transportador'
                                        onClick={() => this.setState({ modalTransportadorVisible: true })}
                                    />
                                </Col>
                            </If>

                            <If test={!transp.transportadorCadastrado}>
                                <Col sm="6" md="4" lg="4" xl="4">
                                    <Field
                                        colStyle={{ padding: '0px' }}
                                        component={InputField}
                                        size={60}
                                        label='Nome do transportador'
                                        name="xNome"
                                        value={transp.transporta.xNome}
                                        onChange={(e) => this.onChangeTransporta('xNome', e.target.value)}
                                        helpMessage={helpNFeTransporte.xNome}
                                        {...informacoesPermissoes}
                                    />
                                    <NfeLinkVerDados
                                        label='Ver dados do transportador'
                                        onClick={() => this.setState({ modalTransportadorVisible: true })}
                                    />
                                </Col>
                            </If>

                            <Field sm="6" md="4" lg="4" xl="4"
                                component={InputField}
                                size={7}
                                label='Placa do veículo'
                                name="placa"
                                value={transp.veicTransp.placa}
                                onChange={this.onChangePlaca}
                                helpMessage={helpNFeTransporte.placa}
                                {...informacoesPermissoes}
                            />
                            <Field sm="6" md="4" lg="4" xl="4"
                                component={SingleSelectEstado}
                                label="UF do veículo"
                                onChange={(e) => this.onChangeVeicTransp('UF', e)}
                                name="UF"
                                value={transp.veicTransp.UF}
                                errors={errors.UF}
                                {...informacoesPermissoes}
                            />
                        </Grid>
                    </Fieldset>
                </If>
                <Fieldset legend="Volumes transportados" className='fieldset-light'>
                    <Grid>
                        <Field sm="6" md="4" lg='4' xl='4'
                            component={InputDouble}
                            decimalScale={0}
                            size={15}
                            label='Quantidade de volumes'
                            name="qVol"
                            value={transp.vol.qVol}
                            onChange={e => this.onChangeVol('qVol', e.target.value)}
                            helpMessage={helpNFeTransporte.qVol}
                            {...informacoesPermissoes}
                        />
                        <Field sm="6" md="4" lg='4' xl='4'
                            component={InputField}
                            size={60}
                            label='Tipos dos volumes'
                            name="esp"
                            value={transp.vol.esp}
                            onChange={e => this.onChangeVol('esp', e.target.value)}
                            helpMessage={helpNFeTransporte.esp}
                            {...informacoesPermissoes}
                        />
                        <Field sm="6" md="4" lg='4' xl='4'
                            component={InputField}
                            size={60}
                            label='Marca dos volumes'
                            name="marca"
                            value={transp.vol.marca}
                            onChange={e => this.onChangeVol('marca', e.target.value)}
                            helpMessage={helpNFeTransporte.marca}
                            {...informacoesPermissoes}
                        />
                        <Field sm="6" md="4" lg='4' xl='4'
                            component={InputField}
                            size={60}
                            label='Numeração dos volumes'
                            name="nVol"
                            value={transp.vol.nVol}
                            onChange={e => this.onChangeVol('nVol', e.target.value)}
                            helpMessage={helpNFeTransporte.nVol}
                            {...informacoesPermissoes}
                        />
                        <Field sm="6" md="4" lg='4' xl='4'
                            component={InputDouble}
                            decimalScale={3}
                            size={15}
                            label='Peso bruto total'
                            name="pesoB"
                            value={transp.vol.pesoB}
                            onChange={e => this.onChangeVol('pesoB', e.target.value)}
                            helpMessage={helpNFeTransporte.pesoB}
                            {...informacoesPermissoes}
                        />
                        <Field sm="6" md="4" lg='4' xl='4'
                            component={InputDouble}
                            decimalScale={3}
                            size={15}
                            label='Peso líquido total'
                            name="pesoL"
                            value={transp.vol.pesoL}
                            onChange={e => this.onChangeVol('pesoL', e.target.value)}
                            helpMessage={helpNFeTransporte.pesoL}
                            {...informacoesPermissoes}
                        />
                    </Grid>
                </Fieldset>
                <If test={transp.utilizaTransportador}>
                    <ModalDadosTransportador
                        visible={this.state.modalTransportadorVisible}
                        transp={transp}
                        onHide={() => this.setState({ modalTransportadorVisible: false })}
                        onChangeTransporta={this.onChangeTransporta}
                        onChangeVeicTransp={this.onChangeVeicTransp}
                        informacoesPermissoes={informacoesPermissoes}
                    />
                </If>
            </>
        )
    }
}