import { del, get, post, put, exibirToast } from "../../../../../common/requisicoes";
import { services } from "../../../../../common/constantes/api";

export async function asyncGetUsuario(idUsuario, onSuccess, onError) {
    return get(`${services.GESTOR}/v1/usuarios/${idUsuario}`, null, onSuccess, onError, false);
}

export async function asyncDeleteUsuario(idUsuario, onSuccess, onError) {
    return del(`${services.GESTOR}/v1/usuarios/${idUsuario}`, null, exibirToast(onSuccess, "Usuário excluído com sucesso"), onError, false);
}

export async function asyncGetUsuarioPapeis(idUsuario, onSuccess, onError) {
    return get(`${services.GESTOR}/v1/usuarios/${idUsuario}`, null, (({ data: usuario }) => {
        onSuccess(usuario.papeis)
    }), onError, false);
}

export async function atualizarUsuario(form, onSuccess, onError) {
    return put(`${services.GESTOR}/v1/usuarios/${form.id}`, form, null, exibirToast(onSuccess, "Usuário atualizado com sucesso"), onError);
}

export async function registrarNovoUsuario(values, onSuccess, onError) {
    return post(`${services.GESTOR}/v1/usuarios`, values, null, exibirToast(onSuccess, `Um e-mail foi enviado para ${values.credencial.email}, verifique e cadastre suas credenciais.`), onError);
}

export async function asyncDesbloquearCredencial(idUsuario, onSuccess, onError) {
    return post(`${services.GESTOR}/v1/usuarios/${idUsuario}/desbloquear`, null, null, exibirToast(onSuccess, 'Usuário desbloqueado com sucesso!'), onError);
}

export async function asyncBloquearCredencial(idUsuario, onSuccess, onError) {
    return post(`${services.GESTOR}/v1/usuarios/${idUsuario}/bloquear`, null, null, exibirToast(onSuccess, 'Usuário bloqueado com sucesso!'), onError);
}
