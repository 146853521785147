import React, { Component } from 'react';
import Chart from "chart.js";
import autoBind from 'react-autobind';
import { asyncGetVendasUltimosDozeMeses } from './requests';
import { ProgressBar } from 'primereact/progressbar';
import { formatarMonetario } from '../../../../../../../common/mascara';
import FalhaAoCarregarAsInformacoes from '../../../FalhaAoCarregarAsInformacoes';
import TituloCard from '../../../../../../../components/TituloCard';
import { Vendas } from '../../../../../../util/constantes'

const cardStyle = {
    padding: '7px',
    borderRadius: '5px',
    backgroundColor: 'white',
    boxShadow: '#00000033 0px 1px 3px 0px, #00000024 0px 1px 1px 0px, #0000001f 0px 2px 1px -1px',
    minHeight: '100%'
}

const dashboardContent = {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
}

let graficoVendas;

export default class GraficoVendasUltimosDozeMeses extends Component {
    graficoVendasUltimosDozeMeses = React.createRef();

    constructor(props) {
        super(props);

        autoBind(this);

        this.state = {
            loading: true,
            error: false,
            competencia: [],
            valorVendas: [],
        }
    }

    componentDidMount() {
        this.carregarInformacoes();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.atualizarGraficoNovamente !== this.props.atualizarGraficoNovamente) {
            this.carregarInformacoes(false);
        }
    }

    carregarInformacoes(exibirLoading = true) {
        this.setState({ loading: exibirLoading, error: false }, () => {
            asyncGetVendasUltimosDozeMeses(({ data }) => {
                let competencia = []
                let valorVendas = []

                data.forEach(elemento => {
                    competencia.push(elemento.competencia);
                    valorVendas.push(elemento.valorVendas);
                });

                this.setState({ competencia, valorVendas, loading: false, error: false }, () => {
                    this.buildChart();
                });
            }, () => {
                this.setState({ error: true, loading: false })
            });
        })
    }

    buildChart = () => {
        if (!this.graficoVendasUltimosDozeMeses.current) {
            return
        }
        const myChartRef = this.graficoVendasUltimosDozeMeses.current.getContext("2d");

        if (typeof graficoVendas !== "undefined") graficoVendas.destroy(); //Necessário para atualizar o gráfico sem bugs

        graficoVendas = new Chart(myChartRef, {
            type: 'bar',
            data: {
                labels: this.state.competencia,
                datasets: [{
                    label: 'Total de vendas no mês',
                    data: this.state.valorVendas,
                    backgroundColor: Vendas.cores.verde
                }],
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                legend: {
                    display: true,
                    position: 'bottom',
                    labels: {
                        usePointStyle: true
                    },
                    onHover: function (e) {
                        e.target.style.cursor = 'pointer';
                    },
                },
                hover: {
                    onHover: function (e) {
                        var point = this.getElementAtEvent(e);
                        if (point.length){ 
                            e.target.style.cursor = 'pointer';
                        }else {
                            e.target.style.cursor = 'default';
                        }
                    },
                },
                aspectRatio: 1,
                animation: {
                    animateScale: true,
                    animateRotate: true
                },
                tooltips: {
                    callbacks: {
                        title: tooltipItem => {
                            return `Competência: ${tooltipItem[0].xLabel}`;
                        },
                        label: (tooltipItem, data) => {
                            const label = data.datasets[tooltipItem.datasetIndex].label;
                            const valor = formatarMonetario(tooltipItem.yLabel);
                            return `${label}: ${valor}`;
                        },
                    }
                },
                plugins: {
                    datalabels: {
                        color: Vendas.cores.preto,
                    }
                },
                scales: {
                    xAxes: [{
                        ticks: {
                            callback: value => value
                        }

                    }],
                    yAxes: [{
                        ticks: {
                            callback: value => formatarMonetario(value),
                            beginAtZero: true
                        }
                    }],
                },
            }
        });
    }

    render() {
        const { loading, error } = this.state
        if (loading || error) {
            return (
                <div style={cardStyle}>
                    <TituloCard
                        title={"Vendas nos últimos 12 meses"}
                        helpMessage="Lista o valor total das vendas agrupadas por mês nos últimos doze meses"
                    />
                    <div style={dashboardContent}>
                        {loading && <ProgressBar mode="indeterminate" style={{ height: '3px', width: '100%', margin: '10px 0px' }} />}
                        {error && <FalhaAoCarregarAsInformacoes />}
                    </div>
                </div>
            )
        } else {
            return (
                <div style={cardStyle}>
                    <TituloCard
                        title={"Vendas nos últimos 12 meses"}
                        helpMessage="Lista o valor total das vendas agrupadas por mês nos últimos doze meses"
                    />
                    <div style={{marginTop: '20px'}}>
                        <canvas id="graficoVendasUltimosDozeMeses" ref={this.graficoVendasUltimosDozeMeses} />
                    </div>
                </div>
            )
        }
    }
}
