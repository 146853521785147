import React, { Component } from 'react'
import { withFormik, Field } from 'formik';
import * as Yup from 'yup';
import { mensagensDeValidacao } from '../../../../../common/constantes/mensagens'
import FormGroup from '../../../../../components/FormGoup/index';
import Grid from '../../../../../components/Grid/index';
import Button from '../../../../../components/Button'
import InputDouble from '../../../../../components/Input/InputDouble';
import Col from '../../../../../components/Col';
import autoBind from 'react-autobind'
import Modal from '../../../../../components/Modal';
import Dropdown from '../../../../../components/Select/Dropdown';
import { helpConfiguracoesForm } from '../../help';
import { asyncBuscarAliquotaSimples, asyncSalvarAliquotaSimples } from '../../requests';
import { converterAliquotaSimplesParaApi } from '../../utils/configuracoesGeraisConverter';
import { notify, ToastTypes } from '../../../../../components/Toast';
import { validarFormulario } from '../../../../util';
import { ProgressSpinner } from 'primereact/progressspinner';
import AutoProgressBar from '../../../../../components/Loading/AutoProgressBar';

const initialValues = {
	aliquotaSimples: null,
	mesVigencia: null,
	loading: true,
	salvando: false
}

class ModalAliquotaSimples extends Component {

	constructor(props) {
		super(props);
		autoBind(this)

		this.state = {
			loading: true,
		}
	}

	buscarAliquota() {
		asyncBuscarAliquotaSimples(({ data }) => {
			this.setState({ loading: false })
			this.props.resetForm({
				values: {
					...data,
					aliquotaSimples: data.percentualAliquota,
					mesVigencia: data.mesVigencia,
				}
			})
		}, () => {
			this.setState({ loading: false })
		})
	}

	componentDidMount() {
		this.buscarAliquota()
	}

	async salvarAliquota() {
		if (await validarFormulario(this.props)) {
			asyncSalvarAliquotaSimples(converterAliquotaSimplesParaApi(this.props.values), () => {
				this.props.onHide();
				notify("Alíquota atualizada", ToastTypes.SUCCESS)
			})
		}
	}

	render() {
		return (
			<Modal
				header='Aliquota do simples nacional'
				visible={this.props.visible}
				onHide={() => this.props.onHide()}
			>
				{this.state.loading ?
					<ProgressSpinner style={{ display: 'flex' }} />
					:
					<FormGroup>
						<Col>
							<AutoProgressBar />
						</Col>
						<Col sm="12" md="12" lg='12' xl='12'>
							A lei complementar 155/2016 determina que a alíquota do simples nacional pode sofrer alteração mensal de acordo com o faturamento
							da empresa.<br /> Caso tenha dúvidas em relação ao percentual da sua alíquota entre em contato com sua contabilidade!
						</Col>
						<Col sm="12" md="12" lg='12' xl='12'>
							<Grid>
								<Field sm="12" md="4" lg='4' xl='4'
									label='Alíquota do simples nacional'
									name='aliquotaSimples'
									value={this.props.values.aliquotaSimples}
									onChange={e => this.props.setFieldValue('aliquotaSimples', e.target.value)}
									size={6}
									component={InputDouble}
									decimalScale={2}
									allowNegative={false}
									helpMessage={helpConfiguracoesForm.aliquotaSimples}
									suffix=' %'
									maxValue={100}
								/>
								<Field sm="12" md="6" lg="6" xl="6"
									component={Dropdown}
									label='Mês de vigência'
									obrigatorio
									value={this.props.values.mesVigencia}
									showClear={false}
									name='mesVigencia'
									onChange={(e) => this.props.setFieldValue("mesVigencia", e.value)}
									helpMessage={helpConfiguracoesForm.mesVigencia}
									options={[
										{ label: 'Janeiro', value: 1 },
										{ label: 'Fevereiro', value: 2 },
										{ label: 'Março', value: 3 },
										{ label: 'Abril', value: 4 },
										{ label: 'Maio', value: 5 },
										{ label: 'Junho', value: 6 },
										{ label: 'Julho', value: 7 },
										{ label: 'Agosto', value: 8 },
										{ label: 'Setembro', value: 9 },
										{ label: 'Outubro', value: 10 },
										{ label: 'Novembro', value: 11 },
										{ label: 'Dezembro', value: 12 },
									]}
								/>
							</Grid>
						</Col>
						<Col sm="12" md="12" lg='12' xl='12'>
							<Button
								color='success'
								label='Salvar nova alíquota'
								icon='fa fa-check'
								disabled={!this.props.dirty}
								onClick={this.salvarAliquota}
							/>
						</Col>
					</FormGroup>
				}
			</Modal>
		)
	}
}

ModalAliquotaSimples = withFormik({
	validateOnChange: false,

	mapPropsToValues() {
		return initialValues;
	},

	validationSchema: Yup.object().shape({
		aliquotaSimples: Yup.number().nullable().required(mensagensDeValidacao.OBRIGATORIO),
		mesVigencia: Yup.string().nullable().required(mensagensDeValidacao.OBRIGATORIO)
	}),

	handleSubmit: () => { },

})(ModalAliquotaSimples);

export default ModalAliquotaSimples