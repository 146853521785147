export const customStyles = {
    control: (provided, state) => {
        const { value } = state.selectProps;
        return {
            ...provided,
            width: '100%',
            borderRadius: '28px',
            boxShadow: 'none',
            border: 'none',
            backgroundColor: value.lightColor,
            '&:hover': {
                cursor: 'pointer',
            }
        }
    },
    singleValue: (provided, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const { value } = state.selectProps;
        return {
            ...provided,
            opacity,
            color: value.strongColor,
            fontWeight: 'bold',
            fontSize: 15,
            width: '100%',
            textAlign: 'center'
        };
    },
    dropdownIndicator: (provided, state) => {
        const { value } = state.selectProps;
        return {
            ...provided,
            color: value.strongColor,
            padding: 12,
            '&:hover': {
                color: value.strongColor,
            }
        }
    },
    menu: provided => {
        return {
            ...provided,
            borderRadius: 5,
            margin: 0,
        }
    },
    option: (provided, state) => {
        const { data, selectProps } = state;
        const isSelectedOption = Boolean(data.value === selectProps.value.value);
        return {
            ...provided,
            backgroundColor: isSelectedOption ? data.lightColor : "#fff00",
            color: data.strongColor,
            cursor: 'pointer',
            borderRadius: 28,
            fontWeight: 'bold',
            textAlign: 'center',
            '&:active': {
                backgroundColor: data.lightColor,
                color: data.strongColor,
            },
            '&:hover': {
                backgroundColor: data.lightColor,
                color: data.strongColor,
            }
        }
    },
    container: provided => ({
        ...provided,
        width: '100%',
    })
}