import { manterApenasNumeros } from "../../../../../common/mascara";

export function converterParaFormulario(values) {
    return {
        id: values.id,
        nome: values.nome,
        situacao: values.situacao,
        tipo: values.tipo,
        cpf: values.cpf,
        cnpj: values.cnpj,
        email: values.email,
        percentualComissao: values.percentualComissao,
        telefone: manterApenasNumeros(values.telefone),

        observacao: values.observacao,
        logradouro: values.endereco ? values.endereco.logradouro : null,
        numero: values.endereco ? values.endereco.numero : null,
        bairro: values.endereco ? values.endereco.bairro : null,
        complemento: values.endereco ? values.endereco.complemento : null,
        cep: values.endereco ? values.endereco.cep : null,
        municipio: converterMunicipioParaFormulario(values.endereco),
        municipioExterior: values.endereco ? values.endereco.municipioExterior : null,
        estadoExterior: values.endereco ? values.endereco.estadoExterior : null,
        pais: converterPaisParaFormulario(values.endereco),
    }
}

function converterPaisParaFormulario(endereco) {
    if (endereco && endereco.pais) {
        return {
            label: endereco.pais.nome,
            value: endereco.pais.id
        }
    }
    return null
}

function converterMunicipioParaFormulario(endereco) {
    if (endereco && endereco.municipio) {
        return {
            label: endereco.municipio.nome + ' - ' + endereco.municipio.estadoSigla,
            value: endereco.municipio.id
        }
    }
    return null
}


export function converterParaApi(values) {

    if (values.tipo === 'JURIDICA') {
        values.cpf = null;
    }
    if (values.tipo === 'FISICA') {
        values.cnpj = null;
    }

    return {
        id: values.id,
        nome: values.nome,
        tipo: values.tipo,
        situacao: values.situacao,
        cpf: manterApenasNumeros(values.cpf),
        cnpj: manterApenasNumeros(values.cnpj),
        email: values.email,
        telefone: manterApenasNumeros(values.telefone),
        percentualComissao: values.percentualComissao,
        endereco: {
            logradouro: values.logradouro,
            numero: values.numero,
            bairro: values.bairro,
            complemento: values.complemento,
            cep: manterApenasNumeros(values.cep),
            municipio: values.municipio ? {
                id: values.municipio.value
            } : null,
            municipioExterior: values.municipioExterior,
            estadoExterior: values.estadoExterior,
            pais: values.pais ? {
                id: values.pais.value
            } : null
        },
        observacao: values.observacao
    }
}
