import React, { Component } from 'react';
import autoBind from 'react-autobind';
import Modal from '../../../../../../../components/Modal';
import { Field, withFormik } from 'formik';
import * as Yup from 'yup';
import Button from '../../../../../../../components/Button';
import { asyncCorrigirNFe } from '../../../requests';
import { mensagensDeValidacao } from '../../../../../../../common/constantes/mensagens';
import { validarFormulario } from '../../../../../../util';
import Grid from '../../../../../../../components/Grid';
import TextArea from '../../../../../../../components/TextArea';
import ModalLoadingTransmissao from '../ModalLoadingTransmissao';

class ModalCartaCorrecao extends Component {
    constructor(props) {
        super(props);
        autoBind(this);

        this.state = {
            mostrarLoadingTransmissao: false,
        }
    }

    cancelar() {
        this.props.onHide();
    }

    async confirmar() {
        this.props.handleSubmit();

        if (await validarFormulario(this.props)) {
            this.setState({ mostrarLoadingTransmissao: true, }, () => {
                asyncCorrigirNFe(this.props.idNfe, this.converterParaApi(), () => {
                    this.props.onHide(this.props.values)
                }, () => {
                    this.props.onHide(this.props.values)
                })
            })
        }
    }

    converterParaApi() {
        const { values } = this.props;

        if (values.xCorrecao) {
            return {
                xCorrecao: String(values.xCorrecao).trim()
            };
        }
        return { ...values };
    }


    render() {
        const { visible, values, setFieldValue, onHide } = this.props;
        const { mostrarLoadingTransmissao } = this.state;

        return (
            <>
                <ModalLoadingTransmissao visible={mostrarLoadingTransmissao} message="Transmitindo carta de correção..." />
                <Modal
                    header="Corrigir nota fiscal"
                    visible={visible}
                    onHide={() => onHide()}
                >
                    <div style={{ marginBottom: '7px' }}>
                        <span>“A Carta de Correção é disciplinada pelo parágrafo 1º-A do art. 7º do Convenio S/N, de 15 de dezembro de 1970 e pode ser utilizada para regularização de erro ocorrido na emissão de documento fiscal, desde que o erro não esteja relacionado com:</span>
                        <div>I - as variáveis que determinam o valor do imposto tais como: base de cálculo, alíquota, diferença de preço, quantidade, valor da operação ou da prestação;</div>
                        <div>II - a correção de dados cadastrais que implique mudança do remetente ou do destinatário;</div>
                        <div>III - a data de emissão ou de saída.”</div>
                    </div>
                    <Grid>
                        <Field sm="12"
                            component={TextArea}
                            label='Descrição do que está sendo corrigido'
                            name="xCorrecao"
                            maxLength={255}
                            value={values.xCorrecao}
                            onChange={e => setFieldValue('xCorrecao', e.target.value)}
                        />
                    </Grid>
                    <Grid justifyEnd style={{ marginTop: '10px', marginRight: '0px' }}>
                        <Button
                            color="secondary"
                            icon="fa fa-arrow-left"
                            label="Voltar"
                            onClick={() => onHide()}
                        />
                        <Button
                            color="primary"
                            icon="fa fa-send"
                            label="Corrigir nota fiscal"
                            onClick={this.confirmar}
                            style={{ marginLeft: '10px' }}
                        />
                    </Grid>
                </Modal>
            </>
        )
    }
}

ModalCartaCorrecao = withFormik({

    enableReinitialize: true,
    validateOnChange: false,

    mapPropsToValues(props) {
        return {
            xCorrecao: props.xCorrecao || ""
        }
    },

    validationSchema: Yup.object().shape({
        xCorrecao: Yup.string().nullable().trim().min(15, "A descrição deve ter mais de 15 caracteres").required(mensagensDeValidacao.OBRIGATORIO)
    }),

})(ModalCartaCorrecao);

export default ModalCartaCorrecao;
