import React, { Component } from 'react';
import Form from '../../../../../components/Form';
import FormActions from '../../../../../components/FormActions';
import FormContent from '../../../../../components/FormContent';
import ButtonNovo, { estadosBotaoNovo } from '../../../../../components/Button/ButtonNovo';
import ButtonSalvar from '../../../../../components/Button/ButtonSalvar';
import ButtonCancelar, { estadosBotaoCancelar } from '../../../../../components/Button/ButtonCancelar';
import ButtonExcluir from '../../../../../components/Button/ButtonExcluir';
import NormalButton from '../../../../../components/Button';
import Grid from '../../../../../components/Grid';
import * as Yup from 'yup';
import autoBind from 'react-autobind';
import { buscarDadosLoginLocalStorage, usuarioPossuiPermissao } from '../../../../../common/autenticacao';
import { recursos, permissoes, estadosCadastro } from '../../../../../common/constantes/autorizacao';
import { Field, withFormik } from 'formik';
import SingleSelectPessoa from '../../../../../components/Select/SingleSelectPessoa';
import { helpMessage, Condicoes } from './utils/constantes';
import { services } from '../../../../../common/constantes/api';
import InputDate from '../../../../../components/Input/InputDate';
import ProdutosVendas from './components/ProdutosVendas';
import ParcelasVendas from './components/ParcelasVendas';
import { statusDropdown } from './utils/constantes';
import SingleSelectUsuario from '../../../../../components/Select/SingleSelectUsuario';
import TextArea from '../../../../../components/TextArea';
import { validarUUID, gerarUUID } from '../../../../../common/manipulacaoDeString';
import { atualizarUrl, metodosAtualizarUrl, voltarParaAPesquisa } from '../../../../util';
import { CADASTROURL, PESQUISAURL, Status, Operacao } from '../util/constantes';
import {
    buscarProximoNumero,
    buscarVendedorInicial,
    asyncDeleteVenda,
    asyncGetVenda,
    asyncCreateVenda,
    asyncUpdateVenda,
    asyncGerarNfe
} from '../requests';
import { confirm, notify } from '../../../../../components/Toast';
import { converterVendaParaFormulario, converterVendaParaApi, converterParaDuplicar } from './utils/vendasOrcamentosConverter';
import Button from '../../../../../components/Button';
import VendasHistorico from '../components/VendasHistorico';
import { mensagensDeValidacao } from '../../../../../common/constantes/mensagens';
import { addMonths, formatISO, parseISO, isValid } from 'date-fns';
import StatusVenda from './components/StatusVenda';
import { connect } from 'react-redux';
import If from '../../../../../components/If';
import { copiarObjeto } from '../../../../../common/array';
import Col from '../../../../../components/Col';
import { getPrimaryColorOfSelectedItem } from './utils/functions';
import { FaPrint, FaShareAlt } from 'react-icons/fa';
import { montarMensagemToast } from '../util/funcoes';
import Prompt from '../../../../../components/Route/Prompt';
import Message from '../../../../../components/Message';
import { buscarItemLocalStorage } from '../../../../../common/localStorage';
import { DADOSLOCALSTORAGE } from '../../../../../common/constantes/localStorage';
import { Menu } from 'primereact/menu';
import { copiarParaAreaTransferencia } from '../../../../../common/areaDeTransferencia';
import ModalCompartilharPorEmail from './components/ModalCompartilharPorEmail';
import Fieldset from '../../../../../components/FieldSet';

const styleBotaoImprimir = {
    padding: '0px 10px',
    color: '#333333',
    fontWeight: 'bold',
    fontSize: '15px',
    display: 'flex',
    alignItems: 'center',
    border: 'none'
};

const initialValue = {
    numero: "",
    status: "ORCAMENTO",
    cliente: null,
    emissao: formatISO(new Date()),
    validade: null,
    previsaoEntrega: "",
    vendedor: null,
    informacaoComplementar: "",
    observacaoInterna: "",
    condicao: Condicoes.SEM_PAGAMENTO,
    quantidadeParcelas: 1,

    parcelas: [
        {
            vencimento: formatISO(addMonths(new Date(), 1)),
            valor: 0.0,
            forma: 'DINHEIRO',
            descricao: "",
            status: null
        }
    ],
    itens: [
        {
            id: gerarUUID(),
            produto: null,
            descricao: null,
            quantidade: 1,
            valor: null,
            subtotal: null,
            novo: true
        },
        {
            id: gerarUUID(),
            produto: null,
            descricao: null,
            quantidade: 1,
            valor: null,
            subtotal: null,
            novo: true
        }
    ],
    totalItens: 0,
    valorFrete: 0,

    valorSeguro: 0,
    valorDespesasAcessorias: 0,
    totalIcmsSt: 0,
    totalFcpSt: 0,

    tipoDesconto: "VALOR",
    valorDesconto: 0,
    percentualDesconto: 0,
    totalLiquido: 0,

    historico: [],
    sujo: null
};

const styleNumeroVenda = {
    fontSize: '24px',
    fontWeight: 'bold'
}

class VendasOrcamentosForm extends Component {

    constructor(props) {
        super(props);

        autoBind(this);

        this.state = {
            podeInserir: usuarioPossuiPermissao(recursos.VENDAS_VENDAS, permissoes.INSERIR),
            podeEditar: usuarioPossuiPermissao(recursos.VENDAS_VENDAS, permissoes.EDITAR),
            podeExcluir: usuarioPossuiPermissao(recursos.VENDAS_VENDAS, permissoes.EXCLUIR),
            organizacaoId: buscarItemLocalStorage(DADOSLOCALSTORAGE.dadosLogin).organizacao.id,
            initialValues: copiarObjeto(initialValue),
            modalHistoricoVisible: false,
            modalEnviarPorEmailVisible: false,
            existeParcelaRecebida: false,
            tabPagamentoSelecionada: 0,
            optionsSelectProdutos: []
        }
    }


    componentDidMount() {
        const idVenda = this.props.location.state?.idVenda;

        if (idVenda) {
            this.duplicar(idVenda)
        } else {

            const uuid = this.props.match.params.id;

            if (validarUUID(uuid)) {
                this.asyncSelectRegistro(uuid);
            } else {
                this.novo();
            }
        }
    }

    componentDidUpdate(prevProps) {
        const { dirty } = this.props
        const isDiffAndDirty = prevProps.dirty !== dirty && dirty;

        if (isDiffAndDirty && this.state.tabPagamentoSelecionada === 1) {

            if (this.props.values.condicao !== Condicoes.A_VISTA) {
                this.setState({ tabPagamentoSelecionada: 0 })
            }
        }
    }

    novo() {
        const { location, values } = this.props;
        var statusRegistro = Status.ORCAMENTO;
        atualizarUrl(this.props.history, CADASTROURL, null, metodosAtualizarUrl.POP);

        //caso já existia um registro quando o usuário clicou no "Novo", ele vai manter o status do registro que existia, ao invés de assumir Orçamento
        if (location.state && location.state.venda) {
            statusRegistro = Status.VENDA;
        } else if (values.id && values.status !== Status.CANCELADA) {
            statusRegistro = values.status;
        }

        buscarProximoNumero(numero => {
            buscarVendedorInicial(vendedor => {
                const values = {
                    ...copiarObjeto(initialValue),
                    numero,
                    vendedor,
                    status: statusRegistro
                }
                this.setState({ initialValues: copiarObjeto(values), existeParcelaRecebida: false })
                this.props.resetForm({ values });
            });
        });
    }

    async salvar(e, novoOnSuccess) {
        await this.props.handleSubmit();
        await this.props.validateForm();

        if (this.props.isValid) {
            if (this.props.values.id) {
                this.updateVenda(this.props.values, novoOnSuccess)
            } else {
                this.createVenda(this.props.values, novoOnSuccess)
            }
        }
    }

    updateVenda(values, novoOnSuccess) {
        if (values) {
            const mensagem = montarMensagemToast(this.props.initialValues.status, Operacao.EDITAR);
            asyncUpdateVenda(converterVendaParaApi(values), mensagem, () => {
                if (novoOnSuccess) {
                    novoOnSuccess();
                } else {
                    this.asyncSelectRegistro(values.id);
                }
            })
        }
    }

    createVenda(values, novoOnSuccess) {
        if (values) {
            const mensagem = montarMensagemToast(this.props.initialValues.status, Operacao.INSERIR);

            asyncCreateVenda(converterVendaParaApi(values), mensagem, ({ data: venda }) => {
                if (novoOnSuccess) {
                    novoOnSuccess();
                } else {
                    this.asyncSelectRegistro(venda.id);
                }
            })
        }
    }

    cancelar() {
        const { condicao } = this.state.initialValues;

        if (this.props.dirty) {
            this.props.resetForm({ values: copiarObjeto(this.state.initialValues) });
        } else {
            this.props.history.goBack();
        }

        if (condicao === Condicoes.A_VISTA) {
            this.setState({ tabPagamentoSelecionada: 1 })
        }
    }

    excluir() {
        const mensagem = montarMensagemToast(this.props.initialValues.status, Operacao.EXCLUIR);
        confirm('Atenção', 'Tem certeza que deseja excluir?', () => {
            asyncDeleteVenda(this.props.values.id, mensagem, () => {
                this.props.resetForm()
                voltarParaAPesquisa(this.props.history, PESQUISAURL);
            });
        });
    }

    asyncSelectRegistro(idVenda) {
        asyncGetVenda(idVenda, async venda => {
            const values = converterVendaParaFormulario(venda.data)

            if (values.status === Status.VENDA) {
                this.setState({ initialValues: copiarObjeto(values), tabPagamentoSelecionada: 1 })
            } else {
                this.setState({ initialValues: copiarObjeto(values) })
            }

            this.props.dirty && this.atualizarOpcoesDoSelectDeProdutos(values.itens);

            await this.props.resetForm({ values });

            atualizarUrl(this.props.history, CADASTROURL, idVenda, metodosAtualizarUrl.POP);
        }, () => {
            this.novo();
        });
    }

    atualizarOpcoesDoSelectDeProdutos(itens) {
        const { optionsSelectProdutos } = this.state;
        for (let i = 0; i < itens.length; i++) {
            const item = itens[i];
            var novasOpcoesDoSelect = optionsSelectProdutos.map(option => {
                if (option.value === item.produto.value) {

                    return {
                        ...option,
                        registro: item.produto.registro
                    };
                }
                return option;
            });
        }
        this.setState({ optionsSelectProdutos: novasOpcoesDoSelect })
    }

    isVenda() {
        return Boolean(this.props.values.status === Status.VENDA);
    }

    async onChangeTotalLiquido(totalLiquido) {
        const valorMaximo = 99999999999.99;

        if (totalLiquido !== this.props.values.totalLiquido) {
            if (totalLiquido >= valorMaximo) {
                await this.props.setFieldValue('totalLiquido', valorMaximo);
            } else {
                await this.props.setFieldValue('totalLiquido', totalLiquido);
            }
        }
    }

    buscarTitle() {
        const { values, isMobile, dirty } = this.props;
        const { organizacaoId, initialValues } = this.state;
        const { id, numero } = values;
        const isVenda = this.isVenda();
        const strongColor = getPrimaryColorOfSelectedItem(values);


        if (id) {
            let title = (
                <span>
                    {initialValues.status === Status.VENDA ? 'Venda' : 'Orçamento'}
                    {numero && <span style={{ ...styleNumeroVenda, color: strongColor }}> Nº {numero}</span>}
                </span>
            );
            if (initialValues.status === Status.CANCELADA) {
                title = (
                    <span>
                        Cancelamento {numero && <span style={{ ...styleNumeroVenda, color: strongColor }}>Nº {numero}</span>}
                    </span>
                )
            }
            const botoesDisabled = dirty;

            const menuCompartilharModel = [
                {
                    label: "Enviar por e-mail",
                    icon: 'fa fa-send',
                    command: () => this.setState({ modalEnviarPorEmailVisible: true })
                },
                {
                    label: "Copiar link compartilhável",
                    icon: 'fa fa-link',
                    command: this.copiarLinkCompartilhavel
                }
            ]

            if (botoesDisabled) {
                return (
                    <span style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        {title}
                        <span style={{ display: 'flex', alignItems: 'center', fontSize: '16px', cursor: 'default' }}>
                            <span style={{ ...styleBotaoImprimir, opacity: '0.5' }}>
                                <FaShareAlt size="15px" style={!isMobile && { marginRight: '5px' }} />
                                {!isMobile && <span>Compartilhar</span>}
                            </span>
                            <span
                                style={{ ...styleBotaoImprimir, opacity: '0.5' }}
                                title='Salve a venda ou orçamento para imprimir'
                            >
                                <FaPrint size="15px" style={!isMobile && { marginRight: '5px' }} />
                                {!isMobile && <span>Imprimir</span>}
                            </span>
                        </span>
                    </span>
                )
            } else {
                return (
                    <span style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        {title}
                        <span style={{ display: 'flex', alignItems: 'center', fontSize: '16px', cursor: 'pointer' }}>
                            <span style={{ ...styleBotaoImprimir, cursor: 'pointer' }} onClick={(event) => this.menuCompartilhar.toggle(event)} >
                                <Menu style={{ width: '220px' }} model={menuCompartilharModel} popup ref={el => this.menuCompartilhar = el} id="menu_compartilhar" />
                                <FaShareAlt size="15px" style={!isMobile && { marginRight: '5px' }} />
                                {!isMobile && <span>Compartilhar</span>}
                            </span>
                            <a
                                style={{ ...styleBotaoImprimir }}
                                target='_blank'
                                rel="noopener noreferrer"
                                href={`${services.GESTOR}/v1/vendas/${id}/organizacoes/${organizacaoId}/imprimir`}
                            >
                                <FaPrint size="15px" style={!isMobile && { marginRight: '5px' }} />
                                {!isMobile && <span>Imprimir</span>}
                            </a>
                        </span>
                    </span>
                )
            }
        } else {
            return (
                <>
                    {isVenda ? "Nova venda" : "Novo orçamento"} {numero && <span style={{ ...styleNumeroVenda, color: strongColor }}>(Nº {numero})</span>}
                </>
            )
        }
    }

    copiarLinkCompartilhavel() {
        const { values } = this.props
        const organizacaoId = buscarDadosLoginLocalStorage().organizacao.id
        copiarParaAreaTransferencia(`${services.GESTOR}/v1/vendas/${values.id}/organizacoes/${organizacaoId}/imprimir`, () => {
            notify("Link copiado para área de transferência")
        })
    }

    adicionarNovoProduto() {
        const novoProduto = {
            id: gerarUUID(),
            produto: null,
            descricao: "",
            quantidade: 1,
            valor: null,
            subtotal: null,
            novo: true
        };
        this.props.setFieldValue('itens', [...this.props.values.itens, novoProduto])
    }

    gerarNfe() {
        asyncGerarNfe(this.props.values.id, ({ data }) => {
            atualizarUrl(this.props.history, `/nfes/cadastro/${data.id}`)
        })
    }

    acessarNfe() {
        atualizarUrl(this.props.history, `/nfes/cadastro/${this.props.values.nfe.id}`)
    }

    duplicar(idVenda) {
        asyncGetVenda(idVenda, response => {
            atualizarUrl(this.props.history, '/vendas/cadastro/', null, metodosAtualizarUrl.POP)
            const informacoesVenda = converterVendaParaFormulario(response.data)
            this.props.resetForm({ values: converterParaDuplicar(informacoesVenda) })
            this.setState({ existeParcelaRecebida: false, tabPagamentoSelecionada: 0 })
        })
    }

    buscaritensOpcoes() {

        const { values, dirty } = this.props;

        let res = [];

        if (values.id) {
            const historico = {
                label: "Histórico",
                icon: "fa fa-history",
                command: () => this.setState({ modalHistoricoVisible: true })
            };
            res.push(historico);
        }

        if (values.id && !dirty) {
            const duplicar = {
                label: "Duplicar",
                icon: "fa fa-copy",
                command: () => this.duplicar(values.id)
            };
            res.push(duplicar);
        }

        return res;
    }


    renderBotaoNotaFiscal() {
        const { podeInserir } = this.state;
        const { values, dirty } = this.props;

        if (this.props.values.nfe) {
            return (
                <Button
                    label="Acessar nota fiscal"
                    className="p-button-success"
                    icon="fa fa-link"
                    hidden={dirty || values.status !== Status.VENDA || !values.id}
                    onClick={() => this.acessarNfe()}
                />
            )
        } else {
            return (
                <Button
                    label="Gerar nota fiscal"
                    className="p-button-success"
                    icon="fa fa-file-text-o"
                    hidden={dirty || values.status !== Status.VENDA || !values.id}
                    onClick={() => this.gerarNfe()}
                    disabled={!podeInserir}
                    title={(!podeInserir && !values.nfe) ? "Você não possui permissão para executar essa ação" : ""}
                />
            )
        }
    }

    render() {

        const { dirty, values, setFieldValue, setFieldTouched, errors } = this.props;
        const { podeInserir, podeEditar, podeExcluir, modalHistoricoVisible, modalEnviarPorEmailVisible, existeParcelaRecebida } = this.state;


        const itensOpcoes = this.buscaritensOpcoes();
        const informacoesPermissoes = {
            podeInserir: podeInserir,
            podeEditar: (podeEditar && !values.nfe),
            podeExcluir: (podeExcluir && !values.nfe),
            estadoCadastro: values.id ? estadosCadastro.EDICAO : estadosCadastro.INCLUSAO
        };

        const estadoBotaoNovo = dirty ? estadosBotaoNovo.SALVAR_E_NOVO : estadosBotaoNovo.NOVO;



        const onClickNovo = dirty ? (e) => this.salvar(e, this.novo) : this.novo;
        const isVenda = this.isVenda();
        return (
            <>
                <Prompt dirty={dirty} />

                <Menu
                    model={itensOpcoes}
                    style={{ minWidth: '230px' }}
                    popup={true}
                    ref={el => (this.menuOpcoes = el)}
                />

                <Form header={this.buscarTitle()} className="card-default screen-max-width">
                    <FormActions className="screen-max-width">
                        <ButtonCancelar
                            estadoBotao={dirty ? estadosBotaoCancelar.CANCELAR : estadosBotaoCancelar.VOLTAR}
                            onClick={this.cancelar}
                            {...informacoesPermissoes}
                        />
                        <ButtonSalvar
                            disabled={(!dirty || existeParcelaRecebida) && Boolean(values.id)}
                            onClick={this.salvar}
                            {...informacoesPermissoes}
                        />
                        <ButtonNovo
                            onClick={onClickNovo}
                            hidden={!dirty && !values.id}
                            estadoBotao={estadoBotaoNovo}
                            {...informacoesPermissoes}
                        />
                        <ButtonExcluir
                            hidden={!values.id}
                            onClick={this.excluir}
                            {...informacoesPermissoes}
                        />

                        <NormalButton
                            className="p-button-primary"
                            type="button"
                            label="Opções"
                            icon="fa fa-list"
                            style={{ margin: '5px' }}
                            hidden={itensOpcoes.length === 0}
                            onClick={event => this.menuOpcoes.toggle(event)}
                        />
                        {this.renderBotaoNotaFiscal()}
                    </FormActions>
                    <FormContent>
                        <If test={existeParcelaRecebida}>
                            <Message
                                severity='info'
                                text='Não é possível editar uma venda com valores já recebidos'
                            />
                        </If>
                        <If test={values.nfe && (values.nfe.status === "REJEITADA" || values.nfe.status === "NAO_ENVIADA")}>
                            <Message
                                colStyle={{ padding: '0px' }}
                                severity='info'
                                text={
                                    <>
                                        <span>
                                            Venda com Nota Fiscal gerada, para editar será necessário excluir a Nota Fiscal.
                                        </span>
                                        <div onClick={this.acessarNfe} style={{ fontWeight: 'bold', cursor: 'pointer' }}>
                                            Clique para acessar a nota
                                        </div>
                                    </>
                                }
                            />
                        </If>
                        <Grid justifyBetween verticalAlignCenter style={{ margin: '20px 0px' }}>
                            <Col sm="12" md="4" lg="4" xl="4" style={{ padding: '0px' }}>
                                <StatusVenda
                                    options={statusDropdown}
                                    value={values.status}
                                    idRegistro={values.id}
                                    existeParcelaRecebida={existeParcelaRecebida}
                                    initialStatus={this.props.initialValues.status}
                                    onChange={e => setFieldValue("status", e.value)}
                                    {...informacoesPermissoes}
                                />
                            </Col>
                        </Grid>
                        <Grid>
                            <Field sm="12"
                                md={isVenda ? "9" : "6"}
                                lg={isVenda ? "9" : "6"}
                                xl={isVenda ? "9" : "6"}
                                name="cliente"
                                label="Cliente"
                                disabled={existeParcelaRecebida}
                                component={SingleSelectPessoa}
                                value={values.cliente}
                                helpMessage={helpMessage.cliente}
                                onChange={e => setFieldValue("cliente", e)}
                                url={`${services.GESTOR}/v1/vendas/relacoes/clientes`}
                                {...informacoesPermissoes}
                            />

                            <Field sm="12" md="3" lg="3" xl="3"
                                component={InputDate}
                                label={isVenda ? "Data da venda" : "Data do orçamento"}
                                name="emissao"
                                obrigatorio
                                disabled={existeParcelaRecebida}
                                helpMessage={helpMessage.emissao}
                                onChange={async e => {
                                    await setFieldValue("emissao", e.target.value, true)
                                    setFieldTouched('emissao', true)
                                }}
                                value={values.emissao}
                                errors={errors && errors.emissao}
                                {...informacoesPermissoes}
                            />

                            <If test={!isVenda}>
                                <Field sm="12" md="3" lg="3" xl="3"
                                    component={InputDate}
                                    label="Validade do orçamento"
                                    name="validade"
                                    disabled={existeParcelaRecebida}
                                    helpMessage={helpMessage.validade}
                                    onChange={e => setFieldValue("validade", e.target.value)}
                                    value={values.validade}
                                    {...informacoesPermissoes}
                                />
                            </If>
                        </Grid>

                        <Grid>
                            <Col>
                                <Fieldset legend='Produtos e serviços' className='fieldset-light'>
                                    <ProdutosVendas
                                        informacoesPermissoes={informacoesPermissoes}
                                        valorFrete={values.valorFrete}
                                        onChangeValorFrete={value => setFieldValue('valorFrete', value)}
                                        totalItens={values.totalItens}
                                        onChangeTotalItens={async value => await setFieldValue('totalItens', value)}
                                        totalLiquido={values.totalLiquido}
                                        onChangeTotalLiquido={this.onChangeTotalLiquido}
                                        itens={values.itens}
                                        primaryColor={getPrimaryColorOfSelectedItem(values)}
                                        onChangeItens={async value => {
                                            await setFieldValue('sujo', gerarUUID())
                                            await setFieldValue('itens', copiarObjeto(value), true)
                                        }}
                                        options={this.state.optionsSelectProdutos}
                                        onChangeOptions={options => this.setState({ optionsSelectProdutos: options })}
                                        initialItens={this.state.initialValues.itens || []}
                                        status={values.status}
                                        dirty={dirty}
                                        tipoDesconto={values.tipoDesconto}
                                        onChangeTipoDesconto={value => setFieldValue('tipoDesconto', value)}
                                        valorDesconto={values.valorDesconto}
                                        onChangeValorDesconto={value => setFieldValue('valorDesconto', value)}
                                        percentualDesconto={values.percentualDesconto}
                                        onChangePercentualDesconto={value => setFieldValue('percentualDesconto', value)}
                                        onAdicionarNovoProduto={this.adicionarNovoProduto}
                                        existeParcelaRecebida={existeParcelaRecebida}
                                        errors={errors}
                                        valorSeguro={values.valorSeguro}
                                        onChangeValorSeguro={value => setFieldValue('valorSeguro', value)}
                                        valorDespesasAcessorias={values.valorDespesasAcessorias}
                                        onChangeValorDespesasAcessorias={value => setFieldValue('valorDespesasAcessorias', value)}
                                        totalIcmsSt={values.totalIcmsSt}
                                        totalFcpSt={values.totalFcpSt}
                                    />
                                </Fieldset>
                            </Col>
                        </Grid>

                        <Grid>
                            <Col>
                                <Fieldset legend='Pagamentos' className='fieldset-light'>
                                    <ParcelasVendas
                                        dirty={dirty}
                                        idVenda={values.id}
                                        totalLiquido={values.totalLiquido}
                                        emissao={values.emissao}
                                        condicao={values.condicao}
                                        onChangeCondicao={value => setFieldValue("condicao", value)}
                                        quantidadeParcelas={values.quantidadeParcelas}
                                        onChangeQuantidadeParcelas={value => setFieldValue("quantidadeParcelas", value)}
                                        categoria={values.categoria}
                                        onChangeCategoria={value => setFieldValue("categoria", value)}
                                        parcelas={values.parcelas}
                                        onChangeParcelas={value => setFieldValue('parcelas', value, true)}
                                        existeParcelaRecebida={this.state.existeParcelaRecebida}
                                        tabParcelasDisabled={this.state.existeParcelaRecebida}
                                        tabPagamentoSelecionada={this.state.tabPagamentoSelecionada}
                                        handleExistParcelaRecebida={existeParcelaRecebida => this.setState({ existeParcelaRecebida })}
                                        tabRecebimentoDisabled={dirty || !values.id || this.state.initialValues.status !== Status.VENDA}
                                        onChangeTabPagamentos={({ index }) => this.setState({ tabPagamentoSelecionada: index })}
                                        informacoesPermissoes={informacoesPermissoes}
                                        errors={errors}
                                    />
                                </Fieldset>
                            </Col>
                        </Grid>

                        <Grid>
                            <Col>
                                <Fieldset legend='Informações complementares' className='fieldset-light'>
                                    <Grid>
                                        <Field sm="6" md="6" lg="6" xl="6"
                                            component={InputDate}
                                            label='Previsão de entrega'
                                            name="previsaoEntrega"
                                            disabled={existeParcelaRecebida}
                                            helpMessage={helpMessage.previsaoEntrega}
                                            onChange={e => setFieldValue("previsaoEntrega", e.target.value)}
                                            value={values.previsaoEntrega}
                                            {...informacoesPermissoes}
                                        />
                                        <Field sm="6" md="6" lg="6" xl="6"
                                            component={SingleSelectUsuario}
                                            name="vendedor"
                                            label="Vendedor responsável"
                                            obrigatorio
                                            disabled={existeParcelaRecebida}
                                            helpMessage={helpMessage.vendedor}
                                            url={`${services.GESTOR}/v1/vendas/relacoes/vendedores`}
                                            value={values.vendedor}
                                            onChange={(e) => setFieldValue("vendedor", e)}
                                            esconderBotao={true}
                                            {...informacoesPermissoes}
                                        />
                                        <Field sm="12" md="6" lg="6" xl="6"
                                            component={TextArea}
                                            placeholder="Escreva informações que sejam importantes para você e seu cliente"
                                            label="Informações complementares"
                                            name="informacaoComplementar"
                                            helpMessage={helpMessage.informacaoComplementar}
                                            maxLength={4096}
                                            disabled={existeParcelaRecebida}
                                            rows={4}
                                            onChange={e => setFieldValue("informacaoComplementar", e.target.value)}
                                            {...informacoesPermissoes}
                                        />
                                        <Field sm="12" md="6" lg="6" xl="6"
                                            component={TextArea}
                                            placeholder="Escreva observações para uso interno"
                                            label="Observações internas"
                                            name="observacaoInterna"
                                            disabled={existeParcelaRecebida}
                                            helpMessage={helpMessage.observacaoInterna}
                                            maxLength={4096}
                                            rows={4}
                                            value={values.observacaoInterna}
                                            onChange={e => setFieldValue("observacaoInterna", e.target.value)}
                                            {...informacoesPermissoes}
                                        />
                                    </Grid>
                                </Fieldset>
                            </Col>
                        </Grid>
                    </FormContent>
                </Form>
                <If test={modalHistoricoVisible}>
                    <VendasHistorico
                        status={this.state.initialValues.status}
                        idVenda={values.id}
                        visible={modalHistoricoVisible}
                        onHide={() => this.setState({ modalHistoricoVisible: false })}
                    />
                </If>
                <If test={modalEnviarPorEmailVisible}>
                    <ModalCompartilharPorEmail
                        idVenda={values.id}
                        idPessoa={values.cliente && values.cliente.value}
                        numero={values.numero}
                        statusVenda={values.status}
                        visible={modalEnviarPorEmailVisible}
                        onHide={() => this.setState({ modalEnviarPorEmailVisible: false })}
                    />

                </If>
            </>
        )
    }
}

VendasOrcamentosForm = withFormik({
    validateOnChange: false,
    enableReinitialize: true,

    mapPropsToValues(props) {
        return initialValue;
    },
    validate(values) {
        let errors = {};
        let errorsItens = []
        let errorsParcelas = []
        let encontrouProduto = false

        values.itens.forEach((item, index) => {
            if (item.produto) {
                encontrouProduto = true
                if (!item.quantidade) {
                    errorsItens[index] = { quantidade: "Quantidade inválida" }
                }
                if (item.valor === null) {
                    errorsItens[index] = { ...errorsItens[index], valor: "Valor inválido" }
                }
            }
        })

        if (values.condicao !== Condicoes.SEM_PAGAMENTO && values.totalLiquido > 0) {
            values.parcelas && values.parcelas.forEach((parcela, index) => {

                let errorParcela = null
                if (!parcela.valor > 0) {
                    errorParcela = { ...errorParcela, valor: "Valor inválido" }
                }

                if (!parcela.vencimento || (parcela.vencimento && !isValid(parseISO(parcela.vencimento)))) {
                    errorParcela = { ...errorParcela, vencimento: mensagensDeValidacao.DATA_INVALIDA }
                }

                if (errorParcela !== null) {
                    errorsParcelas[index] = errorParcela
                }

            })
        }

        if (!encontrouProduto) {
            errorsItens[0] = { ...errorsItens[0], produto: "Selecione ao menos um produto" }
        }
        if (errorsItens.length > 0) {
            errors.itens = errorsItens
        }

        if (errorsParcelas.length > 0) {
            errors.parcelas = errorsParcelas
        }

        if (values.totalLiquido < 0) {
            errors.totalLiquido = "O valor líquido não pode ser negativo"
        }
        if (!values.emissao || (values.emissao && !isValid(parseISO(values.emissao)))) {
            errors.emissao = mensagensDeValidacao.DATA_INVALIDA
        }

        if (values.validade && !isValid(parseISO(values.validade))) {
            errors.validade = mensagensDeValidacao.DATA_INVALIDA
        }
        return errors
    },

    validationSchema: Yup.object().shape({
        emissao: Yup.string().nullable().required(mensagensDeValidacao.DATA_INVALIDA),
        vendedor: Yup.object().nullable().required(mensagensDeValidacao.OBRIGATORIO),
    }),
    handleSubmit: () => { }
})(VendasOrcamentosForm);

const mapStateToProps = state => ({
    isMobile: state.dispositivo.isMobile,
})

export default connect(mapStateToProps)(VendasOrcamentosForm);
