import React, { Component } from 'react';
import autoBind from 'react-autobind/lib/autoBind';
import Col from '../../../../components/Col';
import Grid from '../../../../components/Grid';
import CardTotalizadorListagem from './components/CardTotalizadorListagem';
import PesquisaAvancada from '../../../../components/PesquisaAvancada';
import { salvarConfiguracaoUsuario, configuracoesUsuario } from '../../../../common/configuracaoUsuario';
import { asyncGetResumoNfce, asyncDeleteNFce, asyncGetTotalizadoresCard, imprimirXMLNfce, imprimirDANFE } from './requests';
import { construirUrl } from '../../../../common/rsql';
import { services } from '../../../../common/constantes/api';
import { optionsFiltroAvancado, ColorsCard, StatusNfceCards } from './util/constantes';
import DateInterval from '../../../../components/DateInterval';
import InputSearch from '../../../../components/Input/InputSearch';
import DescricaoFiltroAvancado from '../../../../components/DescricaoFiltroAvancado';
import Form from '../../../../components/Form';
import FormContent from '../../../../components/FormContent';
import { formatISO } from 'date-fns';
import { connect } from 'react-redux';
import FormActions from '../../../../components/FormActions';
import TabelaNFCe from './components/TabelaNFCe';
import { atualizarUrl } from '../../../util';
import { usuarioPossuiPermissao } from '../../../../common/autenticacao';
import { permissoes, recursos } from '../../../../common/constantes/autorizacao';
import { Link } from 'react-router-dom';
import { formatarParaPesquisarTiposEnumerados } from '../../../../common/manipulacaoDeString';
import { confirmarExclusao } from '../../../util/exclusaoDeRegistros';
import { baixarArquivo } from '../../../../common/relatorios';
import { BiRefresh } from 'react-icons/bi';
import Button from '../../../../components/Button';
import { CADASTROURL, Status } from '../NFCe/util/constantes';

class NFCe extends Component {

    constructor(props) {

        super(props);

        autoBind(this);

        this.state = {
            podeInserir: usuarioPossuiPermissao(recursos.VENDAS_VENDAS, permissoes.INSERIR),
            tutorialVisible: false,
            cardTotalSelected: "",
            descricaoFiltroAvancado: '',
            valorPesquisa: "",
            valorCard: "",
            filtroData: "",
            filtroAvancado: "",
            cards: {
                nfceNaoEnviadas: {
                    valor: 0,
                    quantidade: 0
                },
                nfceRejeitadas: {
                    valor: 0,
                    quantidade: 0
                },
                nfceCanceladas: {
                    valor: 0,
                    quantidade: 0
                },
                nfceAutorizadas: {
                    valor: 0,
                    quantidade: 0
                },
            },
            optionsFiltroAvancado: optionsFiltroAvancado,
            limparFiltroPesquisaAvancada: false,
            registros: [],
            totalRecords: 0,

            interval: {
                dataInicial: null,
                dataFinal: null
            },

            sortField: 'criadoEm',
            sortOrder: -1,

            page: 0,
            rows: 0,
            size: 10,
            first: 0
        }
    }

    onPesquisarFiltroAvancado(e) {
        this.setState({ filtroAvancado: e, limparFiltroPesquisaAvancada: false }, () => {
            this.pesquisar();
        });
    }

    componentDidMount() {
        if (this.state.deveExibirTutorial !== false) {
            this.setState({ tutorialVisible: true })
            salvarConfiguracaoUsuario(configuracoesUsuario.EXIBIR_TUTORIAL_LISTAGENS, false, null, false)
        }
    }

    buscarFiltro() {
        const { filtroAvancado, filtroData, valorPesquisa, valorCard } = this.state;

        const valorTiposEnumerados = formatarParaPesquisarTiposEnumerados(valorPesquisa);

        let filtroRSQL = String("?query=(")
            .concat(`nro=contains="*${valorPesquisa}*",`)
            .concat(`numeroVenda=contains="*${valorPesquisa}*",`)
            .concat(`serie=contains="*${valorPesquisa}*",`)
            .concat(`destNome=contains="*${valorPesquisa}*",`)
            .concat(`destCpf=contains="*${valorPesquisa}*",`)
            .concat(`destCnpj=contains="*${valorPesquisa}*",`)
            .concat(`status=contains="*${valorTiposEnumerados}*")`)
            .concat(`;(${filtroData})`)

        if (filtroAvancado) {
            filtroRSQL = filtroRSQL.concat(`;(${filtroAvancado})`)
        }
        if (valorCard) {
            filtroRSQL = filtroRSQL.concat(`;(${valorCard})`)
        }

        return filtroRSQL;
    }

    async pesquisar() {
        const { page, size, sortField, sortOrder, interval } = this.state;
        const filtro = this.buscarFiltro();

        const url = construirUrl(`${services.GESTOR}/v1/nfce/resumo`, filtro || "?", size, page, sortOrder > 0 ? `${sortField},asc` : `${sortField},desc`);

        asyncGetTotalizadoresCard(interval, ({ data: totais }) => {
            let cards = {};

            totais && totais.forEach(element => {
                if (element.status === StatusNfceCards.CANCELADA) {
                    cards.nfceCanceladas = {
                        quantidade: element.quantidade,
                        valor: element.valor
                    }
                } else if (element.status === StatusNfceCards.AUTORIZADA) {
                    cards.nfceAutorizadas = {
                        quantidade: element.quantidade,
                        valor: element.valor
                    }
                } else if (element.status === StatusNfceCards.REJEITADA) {
                    cards.nfceRejeitadas = {
                        quantidade: element.quantidade,
                        valor: element.valor
                    }
                } else if (element.status === StatusNfceCards.NAO_ENVIADA) {
                    cards.nfceNaoEnviadas = {
                        quantidade: element.quantidade,
                        valor: element.valor
                    }
                }
            });
            this.setState({ cards })
        });

        asyncGetResumoNfce(url, ({ data: nfce }) => {
            this.setState({
                registros: nfce.content,
                totalRecords: nfce.totalElements,
            })
        })
    }

    handleChangeInterval(interval) {
        this.setState({ page: 0, first: 0, interval: interval, filtroData: `criadoEm>=${formatISO(interval.dataInicial, { representation: "date" })};criadoEm<=${formatISO(interval.dataFinal, { representation: "date" })}` }, () => {
            this.pesquisar();
        })
    }

    onPesquisar() {
        this.setState({ page: 0, first: 0 }, () => {
            this.pesquisar();
        })
    }

    handleSelectCardNfceNaoEnviadas() {
        const card = Status.NAO_ENVIADA;
        const filtroRSQL = 'status==NAO_ENVIADA';
        this.pesquisarRSQLCardTotais(card, filtroRSQL);
    }

    handleSelectCardNfceRejeitadas() {
        const card = Status.REJEITADA;
        const filtroRSQL = 'status==REJEITADA';
        this.pesquisarRSQLCardTotais(card, filtroRSQL);
    }

    handleSelectCardNfceAutorizadas() {
        const card = Status.TRANSMITIDA;
        const filtroRSQL = 'status==TRANSMITIDA,status==CORRIGIDA';
        this.pesquisarRSQLCardTotais(card, filtroRSQL);
    }

    handleSelectCardNfceCancelada() {
        const card = Status.CANCELADA;
        const filtroRSQL = 'status==CANCELADA,status==DENEGADA';
        this.pesquisarRSQLCardTotais(card, filtroRSQL);
    }

    pesquisarRSQLCardTotais(cardSelected, valueCard) {
        const { cardTotalSelected } = this.state;

        if (cardTotalSelected === cardSelected) {
            this.setState({
                cardTotalSelected: "",
                valorCard: "",
                page: 0,
                first: 0
            }, () => {
                this.pesquisar();
            });
        } else {
            this.setState({
                cardTotalSelected: cardSelected,
                valorCard: valueCard,
                page: 0,
                first: 0
            }, () => {
                this.pesquisar();
            });
        }
    }

    editarNfce(item) {
        atualizarUrl(this.props.history, `${CADASTROURL}/${item.id}`)
    }

    removerNfce(item) {
        confirmarExclusao(async () => {
            await asyncDeleteNFce(item.id, () => this.pesquisar())
        })
    }

    imprimirDANFE(item) {
        const nomeArquivo = (item.status === Status.CANCELADA) ? `NFCe ${item.nro} Cancelada.pdf` : `NFCe ${item.nro}.pdf`
        imprimirDANFE(item.id, ({ data: file }) => {
            baixarArquivo(file, nomeArquivo)
        });
    }

    baixarXMLNfce(item) {
        const nomeArquivo = (item.status === Status.CANCELADA) ? `NFCe ${item.nro} Cancelada` : `NFCe ${item.nro}`
        imprimirXMLNfce(item.id, ({ data: file }) => {
            baixarArquivo(file, item.chave || nomeArquivo, "application/xml");
        });
    }

    montarHeaderListagemNfce() {
        const { isMobile } = this.props
        return (
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <span>{isMobile ? 'NFC-e' : 'Notas fiscais de consumidor'}</span>
                <span
                    title="Atualizar informações da tabela"
                    style={{ cursor: 'pointer' }}
                    onClick={this.pesquisar}
                >
                    <BiRefresh />
                </span>
            </div>
        )
    }

    render() {

        const {
            cards,
            registros,
            totalRecords,
            first,
            size,
            sortField,
            sortOrder,
            valorPesquisa,
            optionsFiltroAvancado,
            descricaoFiltroAvancado,
            cardTotalSelected,
            podeInserir
        } = this.state;

        const { isMobile, isTablet } = this.props;

        return (
            <Form header={this.montarHeaderListagemNfce()}>
                <FormActions>
                    <Link to={podeInserir ? { pathname: "/nfces/cadastro" } : "/nfces"}>
                        <Button
                            label="Nova NFC-e"
                            className="p-button-success"
                            icon="fa fa-plus"
                            title='Inserir uma nova Nota de Consumidor'
                            podeInserir={podeInserir}
                            disabled={!podeInserir}
                            style={{ margin: '5px' }}
                        />
                    </Link>
                </FormActions>
                <FormContent>
                    <Grid justifyCenter verticalAlignCenter>
                        <span style={{ padding: '12px' }}>
                            <DateInterval
                                onChange={this.handleChangeInterval}
                            />
                        </span>
                        <InputSearch
                            onPesquisar={this.onPesquisar}
                            value={valorPesquisa}
                            onChange={value => this.setState({ valorPesquisa: value })}
                        />
                        <span style={{ padding: '12px' }}>
                            <PesquisaAvancada
                                optionsFiltros={optionsFiltroAvancado}
                                onPesquisarClick={this.onPesquisarFiltroAvancado}
                                onChangeFiltroRsql={rsql => this.setState({ filtroAvancado: rsql })}
                                onChangeDescricaoFiltro={e => this.setState({ descricaoFiltroAvancado: e })}
                                liparFiltro={this.state.limparFiltroPesquisaAvancada}
                            />
                        </span>
                    </Grid>
                    <Grid justifyBetween>
                        <Col xs="12" sm="6" md="6" lg="3" xl="3">
                            <CardTotalizadorListagem
                                name="cardNfceNaoEnviadas"
                                title="Não enviadas"
                                helpMessage={isMobile ? "" : "Exibe o valor total e o número de NFC-es ainda não enviadas para o sefaz"}
                                titleFiltro="Clique para filtrar pelas NFC-es não enviadas"
                                colors={ColorsCard.NAO_ENVIADA}
                                value={cards.nfceNaoEnviadas.valor}
                                selectable
                                numberOfElements={cards.nfceNaoEnviadas.quantidade}
                                selected={cardTotalSelected === Status.NAO_ENVIADA}
                                onSelect={this.handleSelectCardNfceNaoEnviadas}

                            />
                        </Col>
                        <Col xs="12" sm="6" md="6" lg="3" xl="3">
                            <CardTotalizadorListagem
                                name="cardNfceRejeitadas"
                                title="Rejeitadas"
                                helpMessage={isMobile ? "" : "Exibe o valor total e o número de NFC-es que tiveram tentativa de transmissão mas foram rejeitadas pelo sefaz"}
                                titleFiltro="Clique para filtrar pelas NFC-es rejeitadas"
                                colors={ColorsCard.REJEITADA}
                                value={cards.nfceRejeitadas.valor}
                                selectable
                                numberOfElements={cards.nfceRejeitadas.quantidade}
                                selected={cardTotalSelected === Status.REJEITADA}
                                onSelect={this.handleSelectCardNfceRejeitadas}
                            />
                        </Col>
                        <Col xs="12" sm="6" md="6" lg="3" xl="3">
                            <CardTotalizadorListagem
                                name="cardNfceCanceladas"
                                title="Canceladas"
                                helpMessage={isMobile ? "" : "Exibe o valor total e o número de NFC-es canceladas ou denegadas"}
                                titleFiltro="Clique para filtrar pelas NFC-es canceladas/denegadas"
                                colors={ColorsCard.CANCELADA}
                                value={cards.nfceCanceladas.valor}
                                selectable
                                numberOfElements={cards.nfceCanceladas.quantidade}
                                selected={cardTotalSelected === Status.CANCELADA}
                                onSelect={this.handleSelectCardNfceCancelada}
                            />
                        </Col>
                        <Col xs="12" sm="6" md="6" lg="3" xl="3">
                            <CardTotalizadorListagem
                                name="cardNfceAutorizadas"
                                title="Autorizadas"
                                helpMessage={isMobile ? "" : "Exibe o valor total e o número de NFC-es transmitidas com sucesso"}
                                titleFiltro="Clique para filtrar pelas NFC-es transmitidas"
                                colors={ColorsCard.AUTORIZADA}
                                value={cards.nfceAutorizadas.valor}
                                selectable
                                numberOfElements={cards.nfceAutorizadas.quantidade}
                                selected={cardTotalSelected === Status.TRANSMITIDA}
                                onSelect={this.handleSelectCardNfceAutorizadas}
                            />
                        </Col>
                    </Grid>
                    <Grid style={{ paddingTop: '10px' }} justifyCenter verticalAlignCenter>
                        <DescricaoFiltroAvancado texto={descricaoFiltroAvancado} />
                        <TabelaNFCe
                            registros={registros}
                            totalRecords={totalRecords}
                            rows={size}
                            first={first}
                            sortField={sortField}
                            sortOrder={sortOrder}
                            setSortField={sortField => this.setState({ sortField })}
                            setSortOrder={sortOrder => this.setState({ sortOrder }, () => this.pesquisar())}
                            isTablet={isTablet}

                            editarNfce={this.editarNfce}
                            removerNfce={this.removerNfce}
                            onPageChange={e => this.setState({ first: e.first, size: e.rows, page: e.page }, () => this.pesquisar())}

                            imprimirDANFE={this.imprimirDANFE}
                            baixarXMLNfce={this.baixarXMLNfce}
                        />
                    </Grid>
                </FormContent>
            </Form>
        );
    }
}

const mapStateToProps = state => ({
    isMobile: state.dispositivo.isMobile,
    isTablet: state.dispositivo.isTablet,
})


export default connect(mapStateToProps)(NFCe);
