import React, { Component } from 'react';
import * as Yup from "yup";
import Modal from '../../../../../components/Modal';
import Grid from '../../../../../components/Grid';
import { withRouter } from 'react-router';
import ButtonNovo, { estadosBotaoNovo } from '../../../../../components/Button/ButtonNovo';
import ButtonSalvar, { estadosBotaoSalvar } from '../../../../../components/Button/ButtonSalvar';
import ButtonCancelar, { estadosBotaoCancelar } from '../../../../../components/Button/ButtonCancelar';
import { usuarioPossuiPermissao } from '../../../../../common/autenticacao';
import { recursos, permissoes, estadosCadastro } from '../../../../../common/constantes/autorizacao';
import ButtonExcluir from '../../../../../components/Button/ButtonExcluir';
import { mensagensDeValidacao } from '../../../../../common/constantes/mensagens';
import autoBind from 'react-autobind';
import { withFormik, Field } from 'formik';
import InputField from '../../../../../components/Input/InputField';
import { helpMessage } from './help';
import Dropdown from '../../../../../components/Select/Dropdown';
import InputMoney from '../../../../../components/Input/InputMoney';
import Checkbox from '../../../../../components/Input/Checkbox';
import { confirm } from '../../../../../components/Toast';
import { asyncGetRegistro, asyncDeleteRegistro, asyncCreateRegistro, asyncUpdateRegistro, asyncAtualizarSaldoInicial, asyncGetContaMesmoNome } from './requests';
import AutoProgressBar from '../../../../../components/Loading/AutoProgressBar';
import { converterContaParaApi } from './utils/contaConverter';
import { keyFilterConsultaRsql } from '../../../../../common/rsql';
import Form from '../../../../../components/Form';
import FormActions from '../../../../../components/FormActions';
import FormContent from '../../../../../components/FormContent';

const initialValue = {
    id: '',
    nome: '',
    tipo: 'DINHEIRO',
    saldoInicial: 0,
    favoritaReceita: false,
    favoritaDespesa: false,
    saldoAtual: null,
    ativa: true,
};

class ModalConta extends Component {

    constructor(props) {
        super(props);
        autoBind(this);

        this.state = {
            podeInserir: usuarioPossuiPermissao(recursos.FINANCAS_CONTAS, permissoes.INSERIR),
            podeEditar: usuarioPossuiPermissao(recursos.FINANCAS_CONTAS, permissoes.EDITAR),
            podeExcluir: usuarioPossuiPermissao(recursos.FINANCAS_CONTAS, permissoes.EXCLUIR)
        }
    }

    async asyncSelectRegistro(idConta) {
        await asyncGetRegistro(idConta, ({ data: conta }) => {
            this.props.resetForm({ values: conta });
        })
    }

    novo() {
        this.props.resetForm({ values: initialValue });
    }

    async salvar(e, novoOnSuccess) {
        this.props.handleSubmit();

        if (this.props.isValid) {
            if (await this.validarNomeDaConta()) {
                const { values } = this.props
                if (values.id) {
                    this.asyncUpdateRegistro(converterContaParaApi(values), novoOnSuccess);
                } else {
                    this.asyncCreateRegistro(converterContaParaApi(values), novoOnSuccess);
                }
            } else {
                this.props.setFieldError('nome', 'O nome informado já existe');
            }
        }
    }

    async validarNomeDaConta() {
        let nomeJaExiste = false;
        const { values } = this.props;
        await asyncGetContaMesmoNome(values.nome, (e) => {
            if (e.data.content.length > 0 && e.data.content[0].id !== values.id) {
                nomeJaExiste = true
            }
        })
        return !nomeJaExiste
    }

    async asyncUpdateRegistro(values, novoOnSuccess) {
        if (values.id && values.saldoInicial !== this.props.initialValues.saldoInicial) {
            await asyncAtualizarSaldoInicial(values.id, { saldoInicial: values.saldoInicial }, () => {
                asyncUpdateRegistro(values, () => {
                    if (novoOnSuccess) {
                        novoOnSuccess();
                    } else {
                        this.props.resetForm({ values: this.props.initialValues });
                        this.props.onHide(values);
                    }
                })
            })
        } else {
            await asyncUpdateRegistro(values, () => {
                if (novoOnSuccess) {
                    novoOnSuccess();
                } else {
                    this.props.onHide(values);
                }
            })
        }
    }

    async asyncCreateRegistro(values, novoOnSuccess) {
        if (values) {
            await asyncCreateRegistro(values, ({ data: conta }) => {
                if (novoOnSuccess) {
                    novoOnSuccess();
                } else { 
                    this.props.onHide({ ...values, id: conta.id });
                    this.novo()
                }
            })
        }
    }

    cancelar() {
        if (this.props.dirty) {
            this.props.resetForm({ values: this.props.initialValues });
        } else {
            this.props.onHide();
        }
    }

    excluir() {
        confirm('Confirmação', 'Deseja excluir o registro?', async () => {
            await asyncDeleteRegistro(this.props.values.id, () => {
                this.props.onHide(this.props.values);
            })
        })
    }

    render() {

        const {
            visible,
            onHide,
            dirty,
            values,
            isModal,
            esconderBotaoExcluir, 
            insertJustOne
        } = this.props;

        const informacoesPermissoes = {
            podeInserir: this.state.podeInserir,
            podeEditar: this.state.podeEditar,
            podeExcluir: this.state.podeExcluir,
            estadoCadastro: values.id ? estadosCadastro.EDICAO : estadosCadastro.INCLUSAO
        };

        const estadoBotaoNovo = dirty ? estadosBotaoNovo.SALVAR_E_NOVO : estadosBotaoNovo.NOVO;
        
        const onClickNovo = dirty ? (e) => this.salvar(e, this.novo) : this.novo;

        return (
            <Modal
                header={values.id ? "Editar conta" : "Cadastrar nova conta"}
                visible={visible}
                onHide={() => {
                    this.novo()
                    onHide(values)
                } }
            >
                <AutoProgressBar />
                <Form>
                    <FormActions>
                        <ButtonCancelar
                            {...informacoesPermissoes}
                            hidden={isModal && values.id && !dirty}
                            estadoBotao={dirty ? estadosBotaoCancelar.CANCELAR : estadosBotaoCancelar.VOLTAR}
                            onClick={this.cancelar}
                        />
                        <ButtonSalvar
                            {...informacoesPermissoes}
                            estadoBotao={estadosBotaoSalvar.SALVAR}
                            disabled={!dirty}
                            onClick={this.salvar}
                        />
                        <ButtonNovo
                            onClick={onClickNovo}
                            hidden={(!dirty && !values.id) || insertJustOne}
                            estadoBotao={estadoBotaoNovo}
                            {...informacoesPermissoes}
                        />
                        <ButtonExcluir
                            hidden={!values.id || isModal || esconderBotaoExcluir}
                            {...informacoesPermissoes}
                            onClick={this.excluir}
                        />
                    </FormActions>
                    <FormContent>
                        <Grid>
                            <Field sm="12" md="12" lg="12" xl="12"
                                component={InputField}
                                label='Nome da conta'
                                autoFocus
                                obrigatorio
                                keyfilter={keyFilterConsultaRsql}
                                name="nome"
                                size={255}
                                helpMessage={helpMessage.nome}
                                {...informacoesPermissoes}
                            />
                            <Field sm="12" md="6" lg="6" xl="6"
                                component={InputMoney}
                                label='Saldo inicial'
                                name="saldoInicial"
                                size={12}
                                disabled={!values.ativa}
                                title={!values.ativa ? "Não é possível atualizar o saldo de uma conta inativa." : null}
                                helpMessage={helpMessage.saldoInicial}
                                onChange={e => this.props.setFieldValue('saldoInicial', e.target.value)}
                                value={values.saldoInicial}
                                {...informacoesPermissoes}
                            />

                            <Field sm="12" md="6" lg='6' xl='6'
                                component={Dropdown}
                                options={[
                                    { label: 'Dinheiro', value: 'DINHEIRO' },
                                    { label: 'Conta Corrente', value: 'CORRENTE' },
                                    { label: 'Conta Poupança', value: 'POUPANCA' },
                                    { label: 'Investimento', value: 'INVESTIMENTO' },
                                    { label: 'Outro', value: 'OUTRO' }
                                ]}
                                label='Tipo da conta'
                                obrigatorio
                                name="tipo"
                                showClear={false}
                                helpMessage={helpMessage.tipoConta}
                                value={values.tipo}
                                onChange={e => this.props.setFieldValue('tipo', e.value)}
                                {...informacoesPermissoes}
                            />
                            <Field sm="12" md="12" lg="12" xl="12"
                                component={Checkbox}
                                name="favoritaReceita"
                                label="Conta favorita para receitas"
                                helpMessage={helpMessage.favoritaReceita}
                                checked={values.favoritaReceita}
                                disabled={!values.ativa}
                                title={!values.ativa ? "Não é possível favoritar uma conta inativa" : null}
                                onChange={() => this.props.setFieldValue("favoritaReceita", !values.favoritaReceita)}
                                {...informacoesPermissoes}
                            />
                            <Field sm="12" md="12" lg="12" xl="12"
                                component={Checkbox}
                                name="favoritaDespesa"
                                label="Conta favorita para despesas"
                                checked={values.favoritaDespesa}
                                helpMessage={helpMessage.favoritaDespesa}
                                disabled={!values.ativa}
                                title={!values.ativa ? "Não é possível favoritar uma conta inativa" : null}
                                onChange={() => this.props.setFieldValue("favoritaDespesa", !values.favoritaDespesa)}
                                {...informacoesPermissoes}
                            />
                        </Grid>
                    </FormContent>
                </Form>
            </Modal>
        )
    }
}
ModalConta = withFormik({
    enableReinitialize: true,
    validateOnChange: false,

    mapPropsToValues(props) {
        if (props.registroSelecionado) {
            return props.registroSelecionado
        } else {
            return initialValue;
        }
    },

    validationSchema: Yup.object().shape({
        nome: Yup.string().nullable().required(mensagensDeValidacao.OBRIGATORIO),
    }),
    handleSubmit: () => { }
})(ModalConta);

export default withRouter(ModalConta);
