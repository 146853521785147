import React, { Component } from 'react';
import { DataTable } from 'primereact/components/datatable/DataTable';
import { Column } from 'primereact/column';
import { formatarDecimais, formatarMonetario } from '../../../../../common/mascara';
import Button from '../../../../../components/Button';
import format from 'date-fns/format';
import { parse } from 'date-fns';
import autoBind from 'react-autobind';
import { Paginator } from 'primereact/paginator';
import { recursos, permissoes } from '../../../../../common/constantes/autorizacao';
import { usuarioPossuiPermissao } from '../../../../../common/autenticacao';
import NenhumRegistroEncontrado from '../../../../../components/NenhumRegistroEncontrado';
import { tipoMovimentacao, TipoMovimentacaoDocumento } from '../../util/constantes'
import { Link } from 'react-router-dom';
import If from '../../../../../components/If';
import { Badge } from '../../../../../components/Badge';

const styleButton = {
    borderRadius: '50%',
    padding: '5px',
    width: '30px',
    height: '30px',
    margin: '2px'
};

const Colors = {
    kBgLightGreen: '#dcedc8',
    kTextGreen: '#1b5e20',
    kBgLightRed: '#ffcdd2',
    kTextRed: '#b71c1c'
}

class TabelaMovimentacoes extends Component {

    constructor(props) {
        super(props);
        autoBind(this);

        this.state = {
            podeExcluirLancamento: usuarioPossuiPermissao(recursos.ESTOQUE_MOVIMENTACOES, permissoes.EXCLUIR),
            podeVisualizarVenda: usuarioPossuiPermissao(recursos.VENDAS_VENDAS, permissoes.VISUALIZAR),
        }
    }

    atualizarOrdenacao(e) {
        this.props.setSortField(e.sortField);
        this.props.setSortOrder(e.sortOrder);
    }

    renderDataField(elemento) {
        const dataFormatada = format(parse(elemento.data, 'yyyy-MM-dd', new Date()), 'dd/MM/yyyy');
        return <span title={dataFormatada}>{dataFormatada}</span>
    }

    renderProdutoField(elemento) {
        const { podeVisualizarVenda } = this.state
        let descricaoDocumento
        let documentoTipo

        if(elemento.documento?.documentoTipo === TipoMovimentacaoDocumento.NFCE){
            documentoTipo = 'NFC-e'
        }else{
            documentoTipo = 'Venda'
        }

        if (elemento.documento) {
            descricaoDocumento = `${documentoTipo} número ${elemento.documento.numero}`
            if (elemento.documento.clienteNome) {
                descricaoDocumento += ` (${elemento.documento.clienteNome})`
            }
        }

        return (
            <span title={elemento.produtoSku + " - " + elemento.produtoNome} style={{ display: 'inline-block' }}>
                <Link to={{ pathname: `/produtos/cadastro/${elemento.produtoId}` }} title="Clique para acessar o produto">
                    <div>{elemento.produtoSku + " - " + elemento.produtoNome}</div>
                </Link>
                {descricaoDocumento && (
                    <div
                        style={{ fontSize: '11px', textOverflow: 'ellipsis', overflow: 'hidden', display: '-webkit-box', WebkitBoxOrient: 'vertical', WebkitLineClamp: 2 }}
                        title={descricaoDocumento}
                    >
                        <If test={podeVisualizarVenda}>
                            <If test={elemento.documento?.documentoTipo === TipoMovimentacaoDocumento.VENDA}>
                            <Link to={{ pathname: `/vendas/cadastro/${elemento.documento.id}` }}>{descricaoDocumento}</Link>
                            </If>
                        </If>
                        <If test={podeVisualizarVenda}>
                            <If test={elemento.documento?.documentoTipo === TipoMovimentacaoDocumento.NFCE}>
                            <Link to={{ pathname: `/nfces/cadastro/${elemento.documento.id}` }}>{descricaoDocumento}</Link>
                            </If>
                        </If>
                        <If test={!podeVisualizarVenda}>
                            <span>{descricaoDocumento}</span>
                        </If>

                    </div>
                )
                }
            </span>
        )
    }

    renderTipoField(elemento) {
        if (elemento.tipo === tipoMovimentacao.SAIDA) {
            return Badge(Colors.kTextRed, Colors.kBgLightRed, "Saída");
        } else {
            return Badge(Colors.kTextGreen, Colors.kBgLightGreen, "Entrada");
        }
    }



    renderQuantidadeField(elemento) {
        const quantidadeFormatado = formatarDecimais(elemento.quantidade);
        const unidadeMedida = elemento.produtoUnidadeMedidaUnidade ? ` (${elemento.produtoUnidadeMedidaUnidade})` : "";

        return (
            <span title={quantidadeFormatado} style={{ display: 'inline-flex', justifyContent: 'center' }}>
                {quantidadeFormatado}{unidadeMedida}
            </span>
        );
    }

    renderCustoField(elemento) {
        if (elemento.tipo === tipoMovimentacao.ENTRADA && elemento.custo > 0) {
            const custoFormatado = formatarMonetario(elemento.custo);
            return <span title={custoFormatado}>{custoFormatado}</span>
        }
    }

    renderTotalField(elemento) {
        if (elemento.tipo === tipoMovimentacao.ENTRADA && elemento.custo > 0) {
            const totalFormatado = formatarMonetario(elemento.custo * elemento.quantidade);
            return <span title={totalFormatado}>{totalFormatado}</span>;
        }
    }

    renderAcoesField(elemento) {
        const { podeExcluirLancamento } = this.state;

        let titleExcluir = "Excluir";
        let disableBtnExcluir = false;

        if (!podeExcluirLancamento) {
            titleExcluir = "Você não possui permissão para executar essa ação"
            disableBtnExcluir = true;
        };

        if (elemento.documento) {
            titleExcluir = "Esta movimentação foi gerada automaticamente a partir de uma venda e não pode ser removida."
            disableBtnExcluir = true;
        }

        return (
            <span>
                <Button
                    style={styleButton}
                    className="p-button p-button-primary"
                    icon="fa fa-pencil"
                    title="Editar"
                    onClick={() => this.props.onEditItem(elemento)}
                />
                <Button
                    style={styleButton}
                    className="p-button p-button-danger"
                    icon="fa fa-trash"
                    title={titleExcluir}
                    disabled={disableBtnExcluir}
                    onClick={() => this.props.onRemoveItem(elemento)}
                />
            </span>
        );
    }

    render() {
        const {
            registros,
            sortField,
            sortOrder,
            totalRecords,
            first,
            onPageChange,
            rows } = this.props;

        return (
            <>
                <DataTable
                    className="table-light"
                    responsive
                    value={registros}
                    sortField={sortField}
                    sortOrder={sortOrder}
                    onSort={this.atualizarOrdenacao}
                    emptyMessage={<NenhumRegistroEncontrado />}
                >
                    <Column
                        header="Data"
                        field="data"
                        sortable={true}
                        body={this.renderDataField}
                        style={{ color: '#333333', width: '90px' }}
                    />
                    <Column
                        header="Produto"
                        field="produto"
                        body={this.renderProdutoField}
                    />
                    <Column
                        header="Tipo"
                        field="tipo"
                        sortable={true}
                        body={this.renderTipoField}
                        style={{ color: '#333333', wordBreak: 'break-all', width: '100px' }}
                    />
                    <Column
                        header="Quantidade"
                        field="quantidade"
                        sortable={true}
                        body={this.renderQuantidadeField}
                        style={{ color: '#333333' }}
                    />
                    <Column
                        header="Custo"
                        field="custo"
                        sortable={true}
                        body={this.renderCustoField}
                        style={{ color: '#333333' }}
                    />
                    <Column
                        header="Total"
                        field="total"
                        body={this.renderTotalField}
                        style={{ color: '#333333' }}
                    />
                    <Column
                        header="Ações"
                        body={this.renderAcoesField}
                        style={{ width: '100px' }}
                    />
                </DataTable>
                <Paginator
                    className='paginator-light'
                    rowsPerPageOptions={[10, 25, 50]}
                    totalRecords={totalRecords}
                    rows={rows}
                    first={first}
                    onPageChange={onPageChange}
                    rightContent={<div style={{ margin: '5px' }}>{totalRecords > 0 ? `${totalRecords} registro(s) encontrado(s)` : null}</div>}
                />
            </>
        );

    }
}

export default TabelaMovimentacoes;
