import { buscarDadosLoginLocalStorage } from '../../autenticacao'
import axios from 'axios';

//Interceptor que pega o token para usar nas requisições
axios.interceptors.request.use(
    config => {
        if (config)
            config.headers.Authorization = 'Bearer ' + buscarDadosLoginLocalStorage().access_token
        return config;
    },
    error => Promise.reject(error)
);


