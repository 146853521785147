import React, { Component } from 'react';
import propTypes from 'prop-types'
import Col from '../../Col';
import { buscarDisabledDeAcordoComAsPermissoes, buscarHiddenDeAcordoComAsPermissoes, buscarTitleCampoDeAcordoComAsPermissoes } from '../../../common/autorizacao/manipulacaoDeComponentes';
import { renderizarValidacao, buscarClassNamePelosErros } from '../../../common/tratamentoDeErro/validacoesDeCampos';
import autoBind from 'react-autobind'
import Button from '../../Button';
import NumberFormat from 'react-number-format';

export default class InputMoneyGroup extends React.Component {

    render() {
        const { field, form, ...rest } = this.props;
        return (
            <React.Fragment>
                <InternalInputMoneyGroup
                    {...field}
                    {...rest}
                    errors={form.errors[field.name]}
                    touched={form.touched[field.name]}
                />
            </React.Fragment>
        );
    }
}

export class InternalInputMoneyGroup extends Component {

    constructor(props) {
        super(props)
        autoBind(this)
    }

    getColStyle() {
        if (buscarHiddenDeAcordoComAsPermissoes(this.props.podeVisualizar, this.props.hidden))
            return { display: 'none', ...this.props.colStyle }
        return this.props.colStyle
    }

    onChange(e) {
        let valor = e.floatValue;

        if (valor !== undefined && valor > this.props.maxValue) {
            valor = this.props.maxValue;
        } else if (valor === undefined) {
            valor = null;
        }

        this.props.onChange({
            target: {
                //value: e.floatValue === undefined ? null : e.floatValue,
                value: valor,
                name: this.props.name
            }
        })
    }

    getValue() {
        return this.props.value ? this.props.value : ''
    }

    onFocusable() {
        if (this.props.onFocus) {
            this.props.onFocus();
        } else {
            if (this.inputElem) {
                this.inputElem.setSelectionRange(0, this.inputElem.value.length)
            }

        }
    }

    render() {

        const { id, name, placeholder, className,
            style, onBlur, onKeyDown, errors, touched, sm, md, lg, xl,
            podeVisualizar, podeInserir, podeEditar, estadoCadastro, colId,
            thousandSeparator,
            decimalScale,
            fixedDecimalScale,
            decimalSeparator,
            prefix,
            suffix,
            onMouseUp,
            iconButton,
            autofocus,
            titleButton,
            allowNegative,
            onClickButton,
            disabledButton,
            label,
            colorIconButton,
            warnings
        } = this.props

        const disabled = buscarDisabledDeAcordoComAsPermissoes(podeInserir, podeEditar, estadoCadastro, this.props.disabled);
        const colStyle = this.getColStyle()
        const title = buscarTitleCampoDeAcordoComAsPermissoes(podeVisualizar, podeInserir, podeEditar, estadoCadastro, this.props.title)
        const classNameDisabled = disabled ? 'p-disabled' : '';

        return (
            <Col id={colId} sm={sm} md={md} lg={lg} xl={xl} style={colStyle}>
                <label title={this.props.helpMessage}>{label}</label>
                <span className="p-fluid" title={title}>
                    <div className="p-inputgroup">
                        <NumberFormat
                            title={buscarTitleCampoDeAcordoComAsPermissoes(podeVisualizar, podeInserir, podeEditar, estadoCadastro, title)}
                            id={id}
                            getInputRef={el => this.inputElem = el}
                            name={name}
                            displayType="input"
                            value={this.props.value}
                            className={buscarClassNamePelosErros(`p-inputtext ${classNameDisabled} ${className}`, errors, touched, warnings)}
                            disabled={disabled}
                            style={{ borderRadius: '3px', height: '32px', ...style }}
                            onBlur={onBlur}
                            onKeyDown={onKeyDown}
                            placeholder={placeholder}
                            thousandSeparator={thousandSeparator}
                            prefix={prefix}
                            onMouseUp={onMouseUp}
                            decimalScale={decimalScale}
                            fixedDecimalScale={fixedDecimalScale}
                            onValueChange={this.onChange}
                            onFocus={this.onFocusable}
                            decimalSeparator={decimalSeparator}
                            suffix={suffix}
                            autoFocus={autofocus}
                            inputMode="decimal"
                            allowNegative={allowNegative}
                            isAllowed={(e) => e.value ? e.value.length <= this.props.size : true}
                        />
                        <Button
                            color={colorIconButton}
                            style={{ margin: '0px', borderTopLeftRadius: '0px', borderBottomLeftRadius: '0px' }}
                            icon={iconButton}
                            title={titleButton}
                            disabled={disabledButton || disabled}
                            onClick={onClickButton}
                        />
                    </div>
                </span>
                {renderizarValidacao(errors, touched)}
            </Col>
        )
    }
}


InternalInputMoneyGroup.defaultProps = {
    value: '',
    onChange: () => { },
    size: 99999,
    className: '',
    decimalScale: 2,
    fixedDecimalScale: true,
    thousandSeparator: '.',
    decimalSeparator: ',',
    iconButton: 'fa fa-search',
    disabledButton: false,
    onClickButton: () => { }
}

InternalInputMoneyGroup.propTypes = {

    /** Evento disparado ao modificar o componente do componente */
    onChange: propTypes.func,
    /** Label do componente */
    label: propTypes.string,
    /** Identificador do componente */
    id: propTypes.string,
    /** Placeholder do componente */
    placeholder: propTypes.string,
    /** Valor do componente do componente */
    value: propTypes.any,
    /** Nome da classe do componente */
    className: propTypes.string,
    /** Define se o componente está desabilitado*/
    disabled: propTypes.bool,
    /** Especifica os erros de validação que o componente possui (geralmente vindos do Yup)*/
    errors: propTypes.oneOfType([propTypes.string, propTypes.bool]),
    warnings: propTypes.any,
    /** Especifica se o componente foi 'tocado'*/
    touched: propTypes.bool,
    /** Evento executado ao sair do campo*/
    onBlur: propTypes.any,
    /** Tamanho padrão da coluna utilizado em dispositivos muito pequenos (0 a 12) */
    col: propTypes.string,
    /** Tamanho do campo em small devices*/
    sm: propTypes.string,
    /** Tamanho do campo em medium devices*/
    md: propTypes.string,
    /** Tamanho do campo em large devices*/
    lg: propTypes.string,
    /** Tamanho do campo em extra large devices*/
    xl: propTypes.string,
    /** Estilo da coluna*/
    colStyle: propTypes.object,
    /** Estado em que o cadastro se encontra*/
    estadoCadastro: propTypes.string,
    /** Diz se o usuário possui permissão de visualizar*/
    podeVisualizar: propTypes.bool,
    /** Diz se o usuário possui permissão de editar*/
    podeEditar: propTypes.bool,
    /** Diz se o usuário possui permissão de excluir*/
    podeInserir: propTypes.bool,
    /** Title do componente*/
    title: propTypes.string,
    /** Id da coluna*/
    colId: propTypes.string,
    /** Esconde o componente*/
    hidden: propTypes.bool,
    /** Tamanho máximo do texto do componente*/
    size: propTypes.number,
    /** Separador de milhares */
    thousandSeparator: propTypes.string,
    /** Prefixo: Ex: R$ */
    prefix: propTypes.string,
    /** quantos dígitos decimais deverão ser digitados */
    decimalScale: propTypes.number,
    /** Sempre mostrar os decimais */
    fixedDecimalScale: propTypes.bool,
    /** Separador de dígitos decimais */
    decimalSeparator: propTypes.string,
    /** Campo destinado a uma breve  explicação sobre o campo. Irá renderizar um ícone de pergunta caso a propriedade for alimentada.*/
    helpMessage: propTypes.string,
    /** Define os filhos do componente */
    children: propTypes.any,
    allowNegative: propTypes.bool,
    /**Valor máximo para o input */
    maxValue: propTypes.number

}
