import React, { Component } from 'react';
import autoBind from 'react-autobind';
import Dropdown from '../../../../../../../components/Select/Dropdown';
import { condicaoDropdown, quantidadeParcelasDropdown, Condicoes, contaReceberStatus } from '../../utils/constantes';
import Grid from '../../../../../../../components/Grid';
import { Field } from 'formik';
import SingleSelectCategoria, { tiposCategoria } from '../../../../../../../components/Select/SingleSelectCategoria';
import { services } from '../../../../../../../common/constantes/api';
import { addMonths, formatISO, parseISO, isValid } from 'date-fns';
import ParcelasMemorizadas from './ParcelasMemorizadas';
import { connect } from 'react-redux';
import If from '../../../../../../../components/If';
import { validarValorNegativo } from '../../utils/functions';
import Col from '../../../../../../../components/Col';
import { InternalInputDate } from '../../../../../../../components/Input/InputDate';
import { InternalInputField } from '../../../../../../../components/Input/InputField';
import { copiarObjeto } from '../../../../../../../common/array';
import { TabView, TabPanel } from 'primereact/tabview';
import TabelaRecebimentos from './TabelaRecebimentos';
import { converterParcelaDoModalParaFormulario } from './utils/parcelaConverter';
import { asyncEstornarParcelaRecebida } from './request';
import { formaPagamento } from './utils/constantes';

class ParcelasVendas extends Component {
    constructor(props) {
        super(props);
        autoBind(this);

        this.state = {
            parcelasContaAReceber: [],
            modalRecebimentoVisible: false,
            registroSelecionado: null
        }
    }

    componentDidUpdate(prevProps) {
        const { totalLiquido, emissao, dirty, parcelas, condicao } = this.props;

        if (dirty && prevProps.totalLiquido !== totalLiquido && totalLiquido >= 0) {
            this.recalcularParcelas()
        }

        if (dirty && isValid(parseISO(emissao)) && prevProps.emissao !== emissao) {

            let parcelasTemp = copiarObjeto(parcelas)
            const isAVista = condicao === Condicoes.A_VISTA;

            parcelasTemp = parcelasTemp.map((parcela, index) => {

                let vencimento = isAVista ? emissao : formatISO(addMonths(parseISO(emissao), index + 1));
                return { ...parcela, vencimento: vencimento };
            })
            this.props.onChangeParcelas(parcelasTemp)
        }

        if (prevProps.parcelas !== this.props.parcelas && !dirty) {
            this.setState({ parcelasContaAReceber: this.props.parcelas });
            this.props.handleExistParcelaRecebida(this.existeParcelaRecebida(this.props.parcelas));
        }

    }

    recalcularParcelas() {
        let parcelas = copiarObjeto(this.props.parcelas)
        const rateioParcelas = this.rateioParcelas();

        let parcelasTemp = parcelas.map((parcela, index) => {
            if (index === (parcelas.length - 1)) {
                return { ...parcela, valor: rateioParcelas.valorUltimaParcela }
            } else {
                return { ...parcela, valor: rateioParcelas.valorPorParcela }
            }
        })

        this.props.onChangeParcelas(parcelasTemp)
    }

    async onChangeValor(value, index) {
        let parcelas = copiarObjeto(this.props.parcelas)

        if (parcelas[index].valor !== value) {
            parcelas[index].valor = validarValorNegativo(value)

            await this.props.onChangeParcelas(parcelas)
            this.recalcularRateioParcela(index)
        }
    }

    onChangeVencimentoParcela(data, index) {
        let parcelas = copiarObjeto(this.props.parcelas)

        if (index === 0 && isValid(parseISO(data))) {
            parcelas = parcelas.map((parcela, index) => {
                return { ...parcela, vencimento: formatISO(addMonths(parseISO(data), index)) }
            })
            this.props.onChangeParcelas(parcelas)
        } else {
            parcelas[index] = { ...parcelas[index], vencimento: data }
            this.props.onChangeParcelas(parcelas)
        }
    }

    onChangeDescricao(value, index) {
        let parcelas = copiarObjeto(this.props.parcelas)

        parcelas[index] = { ...parcelas[index], descricao: value }

        this.props.onChangeParcelas(parcelas)
    }

    onChangeFormaPagamento(value, index) {
        let parcelas = copiarObjeto(this.props.parcelas)

        parcelas[index] = { ...parcelas[index], forma: value }

        this.props.onChangeParcelas(parcelas)
    }

    async onClickExcluir(index) {
        const { parcelas, quantidadeParcelas } = this.props
        let parcelasTemp = copiarObjeto(parcelas);

        parcelasTemp.splice(index, 1)

        await this.props.onChangeQuantidadeParcelas(quantidadeParcelas - 1)
        await this.props.onChangeParcelas(parcelasTemp)

        this.recalcularRateioParcelaAoExcluir(index)
    }


    async onChangeCondicaoParcelas(e) {
        const { onChangeCondicao, onChangeQuantidadeParcelas, onChangeParcelas, parcelas, emissao } = this.props;

        if (e.value === Condicoes.SEM_PAGAMENTO) {
            await onChangeCondicao(e.value)
        } else {
            let apenasPrimeiraParcela = null;
            if (isValid(parseISO(emissao))) {
                let vencimentoPrimeiraParcela = e.value === Condicoes.A_VISTA ? parseISO(emissao) : addMonths(parseISO(emissao), 1);
                apenasPrimeiraParcela = [{ ...parcelas[0], vencimento: formatISO(vencimentoPrimeiraParcela), forma: 'DINHEIRO' }]
            } else {
                apenasPrimeiraParcela = [{ ...parcelas[0], vencimento: null, forma: 'DINHEIRO' }]
            }

            await onChangeCondicao(e.value)
            await onChangeQuantidadeParcelas(1)
            await onChangeParcelas(apenasPrimeiraParcela)

            this.gerarParcelas();
        }
    }

    gerarParcelas() {

        const { parcelas, quantidadeParcelas, emissao } = this.props;

        let parcelasTemp = [];

        const rateioParcelas = this.rateioParcelas();

        for (let i = 0; i < quantidadeParcelas; i++) {
            let oldParcela = parcelas && parcelas[i];
            let valorParcela = rateioParcelas.valorPorParcela;

            if (quantidadeParcelas - 1 === i) {
                valorParcela = rateioParcelas.valorUltimaParcela
            }

            let idParcela = oldParcela ? oldParcela.id : null
            let vencimento = oldParcela ? oldParcela.vencimento : emissao && formatISO(addMonths(parseISO(emissao), i + 1))
            let descricao = oldParcela ? oldParcela.descricao : ""
            let forma = oldParcela ? oldParcela.forma : "DINHEIRO"

            parcelasTemp.push({
                id: idParcela,
                vencimento: vencimento,
                valor: valorParcela,
                descricao: descricao,
                forma: forma
            })
        }

        this.props.onChangeParcelas(parcelasTemp)
    }

    valorSomadoDasParcelasAnteriores(parcelas, index) {
        let valorParcelasAnteriores = 0.0;
        for (let i = 0; i < parcelas.length; i++) {
            if (i < index) {
                valorParcelasAnteriores += parcelas[i].valor
            }
        }
        return valorParcelasAnteriores
    }

    recalcularRateioParcela(indexItem) {

        let parcelas = this.props.parcelas

        const valorInformado = parcelas[indexItem].valor

        const valorParcelasAnteriores = this.valorSomadoDasParcelasAnteriores(parcelas, indexItem);
        const valorASerRatiado = this.props.totalLiquido - (valorParcelasAnteriores + valorInformado)
        const quantidadeParcelasRestantes = (parcelas.length - 1) - indexItem
        const valorRatiadoParaCadaParcelaRestante = parseFloat((valorASerRatiado / quantidadeParcelasRestantes).toFixed(2))

        let somaDasParcelas = 0;

        parcelas = parcelas.map((parcela, iterador) => {
            let valor = parcela.valor;

            if (iterador > indexItem) {
                somaDasParcelas += valorRatiadoParaCadaParcelaRestante
                if (iterador === (parcelas.length - 1)) {
                    valor = validarValorNegativo(parseFloat((valorRatiadoParaCadaParcelaRestante + (this.props.totalLiquido - somaDasParcelas)).toFixed(2)));
                } else {
                    valor = validarValorNegativo(valorRatiadoParaCadaParcelaRestante)
                }
            } else {
                somaDasParcelas += parcela.valor
            }
            parcela.valorAReceber = valor;
            parcela.valor = valor;
            return parcela
        })

        this.props.onChangeParcelas(parcelas)
    }

    recalcularRateioParcelaAoExcluir(indexItem) {
        let parcelas = this.props.parcelas

        const valorSomadoDasParcelasAnteriores = this.valorSomadoDasParcelasAnteriores(parcelas, indexItem);
        const valorASerRatiado = this.props.totalLiquido - valorSomadoDasParcelasAnteriores
        const quantidadeParcelasRestantes = parcelas.length - indexItem
        const valorRatiadoParaCadaParcelaRestante = parcelas.length === indexItem ? 0 : parseFloat((valorASerRatiado / quantidadeParcelasRestantes).toFixed(2))

        let somaDasParcelas = 0;

        parcelas = parcelas.map((parcela, iterador) => {
            if (iterador === parcelas.length - 1) {
                return { ...parcela, valor: this.props.totalLiquido - somaDasParcelas }
            } else if (iterador >= indexItem) {
                somaDasParcelas += valorRatiadoParaCadaParcelaRestante
                return { ...parcela, valor: validarValorNegativo(valorRatiadoParaCadaParcelaRestante) }
            } else {
                somaDasParcelas += parcela.valor
            }

            return parcela
        })

        this.props.onChangeParcelas(parcelas)
    }

    rateioParcelas() {
        const { totalLiquido, quantidadeParcelas } = this.props

        const valorPorParcela = parseFloat((totalLiquido / quantidadeParcelas).toFixed(2))
        const valorUltimaParcela = parseFloat((totalLiquido - (valorPorParcela * (quantidadeParcelas - 1))).toFixed(2))

        return { valorPorParcela: valorPorParcela, valorUltimaParcela: valorUltimaParcela }
    }

    existeParcelaRecebida(parcelasContaAReceber) {
        const { RECEBIDA, PARCIALMENTE_RECEBIDA } = contaReceberStatus;

        const indexRecebimento = parcelasContaAReceber.findIndex(parcela => parcela.status === RECEBIDA || parcela.status === PARCIALMENTE_RECEBIDA);

        return Boolean(indexRecebimento !== -1);
    }

    handleClickEfetuarBaixaContaReceber(el) {
        const { idVenda } = this.props;

        this.setState({
            registroSelecionado: { ...el, idVenda },
            modalRecebimentoVisible: true
        });
    }

    handleClickEstornarContaReceber(el) {
        const { idVenda } = this.props;
        const { parcelasContaAReceber } = this.state;

        asyncEstornarParcelaRecebida(idVenda, el.id, () => {
            const novasParcelas = parcelasContaAReceber.map(parcela => {
                if (parcela.id === el.id) {
                    return {
                        ...parcela,
                        valor: el.valor,
                        valorAReceber: el.valor,
                        status: contaReceberStatus.NAO_RECEBIDA
                    }
                }
                return parcela;
            })
            this.setState({ parcelasContaAReceber: novasParcelas });
            this.props.handleExistParcelaRecebida(this.existeParcelaRecebida(novasParcelas));
        });
    }

    handleCloseModalContaReceber(values) {
        const { parcelasContaAReceber, registroSelecionado } = this.state;

        if (values) {
            const novasParcelas = parcelasContaAReceber.map(parcela => {
                if (parcela.id === registroSelecionado.id) {
                    return converterParcelaDoModalParaFormulario(registroSelecionado, values);
                }
                return parcela;
            });
            this.setState({ parcelasContaAReceber: novasParcelas, registroSelecionado: null, modalRecebimentoVisible: false });
            this.props.handleExistParcelaRecebida(this.existeParcelaRecebida(novasParcelas));

        } else {
            this.setState({
                modalRecebimentoVisible: false,
                registroSelecionado: null
            });
        }
    }

    calcularTotalAReceber() {
        return this.state.parcelasContaAReceber
            .reduce((accumulator, currentValue) => Number(parseFloat(accumulator) + parseFloat(currentValue.valorAReceber)).toFixed(2), 0)
    }

    calcularTotalRecebido() {
        return this.state.parcelasContaAReceber
            .reduce((accumulator, currentValue) => Number(parseFloat(accumulator) + parseFloat(currentValue.valor - currentValue.valorAReceber)).toFixed(2), 0)
    }

    render() {
        const {
            parcelas,
            condicao,
            isMobile,
            errors,
            informacoesPermissoes,
            idVenda,
            existeParcelaRecebida,
            tabRecebimentoDisabled,
            tabParcelasDisabled,
            quantidadeParcelas,
            categoria,
            onChangeQuantidadeParcelas,
        } = this.props;

        const { parcelasContaAReceber } = this.state;

        const isAVista = condicao === Condicoes.A_VISTA;
        const isAPrazo = condicao === Condicoes.A_PRAZO;

        return (
            <Grid>
                <Field xs="6" sm="6" md="4" lg='4' xl='4'
                    component={Dropdown}
                    label='Condição'
                    name="condicao"
                    helpMessage="Condição de pagamento"
                    obrigatorio
                    disabled={existeParcelaRecebida}
                    value={condicao}
                    showClear={false}
                    options={condicaoDropdown}
                    onChange={this.onChangeCondicaoParcelas}
                    {...informacoesPermissoes}
                />

                <If test={isAPrazo}>
                    <Field xs="6" sm="6" md="4" lg='4' xl='4'
                        component={Dropdown}
                        label='Qtd. Parcelas'
                        name="quantidadeParcelas"
                        helpMessage="Quantidade de parcelas que serão geradas para esta venda"
                        obrigatorio
                        value={quantidadeParcelas}
                        disabled={existeParcelaRecebida}
                        showClear={false}
                        options={quantidadeParcelasDropdown}
                        onChange={async (e) => {
                            await onChangeQuantidadeParcelas(e.value)
                            this.gerarParcelas()
                        }}
                        {...informacoesPermissoes}
                    />
                </If>
                <If test={isAVista}>
                    <Field xs="12" sm="6" md="4" lg='4' xl='4'
                        component={InternalInputDate}
                        label='Vencimento'
                        name="vencimento"
                        helpMessage="Data do vencimento do pagamento"
                        obrigatorio
                        disabled={existeParcelaRecebida}
                        onChange={(e) => this.onChangeVencimentoParcela(e.target.value, 0)}
                        value={parcelas[0] && parcelas[0].vencimento}
                        touched
                        errors={errors && errors.parcelas && errors.parcelas[0] && errors.parcelas[0].vencimento}
                        {...informacoesPermissoes}
                    />
                </If>
                <If test={isAVista}>
                    <Field
                        xs="12" sm="6" md="4" lg='4' xl='4'
                        component={Dropdown}
                        label="Forma de pagamento"
                        helpMessage="Forma de pagamento utilizada. Essa informação é utilizada na Nota Fiscal"
                        name="forma"
                        showClear={false}
                        value={parcelas[0] && parcelas[0].forma}
                        onChange={e => this.onChangeFormaPagamento(e.value, 0)}
                        options={formaPagamento}
                        errors={errors}
                        disabled={existeParcelaRecebida}
                        {...informacoesPermissoes}
                    />
                </If>

                <Field xs="12" sm="6" md="4" lg='4' xl='4'
                    name="categoria"
                    label="Categoria"
                    helpMessage="Categoria financeira da venda. Útil para visualização das receitas por categoria no dashboard financeiro"
                    component={SingleSelectCategoria}
                    tipoCategoria={tiposCategoria.RECEITA}
                    value={categoria}
                    disabled={existeParcelaRecebida}
                    onChange={this.props.onChangeCategoria}
                    url={`${services.GESTOR}/v1/vendas/relacoes/categorias/receitas`}
                    {...informacoesPermissoes}
                />

                <If test={isAVista}>
                    <Field sm="12" md="8" lg='8' xl='8'
                        component={InternalInputField}
                        id='descricaoParcela'
                        helpMessage="Detalhes gerais da parcela."
                        type="text"
                        label='Detalhes da parcela'
                        name="descricao"
                        value={parcelas[0] && parcelas[0].descricao}
                        disabled={existeParcelaRecebida}
                        size={200}
                        onChange={(e) => this.onChangeDescricao(e.target.value, 0)}
                        {...informacoesPermissoes}
                    />
                </If>

                {condicao !== Condicoes.SEM_PAGAMENTO && <TabView
                    className="tab-view-light-no-border"
                    activeIndex={this.props.tabPagamentoSelecionada}
                    onTabChange={this.props.onChangeTabPagamentos}
                    renderActiveOnly={true}
                >
                    <TabPanel
                        disabled={tabParcelasDisabled}
                        headerStyle={{ display: isAVista ? "none" : "unset" }}
                        header="Parcelas"
                    >
                        {!isAVista && <Col>
                            {parcelas.map((parcela, index) => {
                                return (
                                    <ParcelasMemorizadas
                                        key={index}
                                        index={index}
                                        isMobile={isMobile}
                                        labelParcela={(index + 1) + "ª "}
                                        valueVencimento={parcela.vencimento}
                                        onChangeVencimento={(e) => this.onChangeVencimentoParcela(e.target.value, index)}
                                        valueValor={parcela.valor}
                                        onChangeValor={(e) => this.onChangeValor(e.target.value, index)}
                                        onChangeFormaPagamento={(e) => this.onChangeFormaPagamento(e.value, index)}
                                        valueFormaPagamento={parcela.forma}
                                        valueDescricao={parcela.descricao}
                                        onChangeDescricao={(e) => this.onChangeDescricao(e.target.value, index)}
                                        recalcularRateioParcela={() => this.recalcularRateioParcela(index, parcela.valor)}
                                        onClickExcluir={() => this.onClickExcluir(index)}
                                        backgroundColor={isMobile && index % 2 === 0 ? "#f1f1f1" : null}
                                        exibirLabels={index === 0}
                                        errors={errors && errors.parcelas && errors.parcelas[index]}
                                        excluirDisabled={parcelas.length === 1 || !informacoesPermissoes.podeEditar}
                                        informacoesPermissoes={informacoesPermissoes}
                                    />
                                )
                            })}
                        </Col>}
                    </TabPanel>

                    <TabPanel
                        headerStyle={{ display: tabRecebimentoDisabled ? "none" : "unset" }}
                        disabled={tabRecebimentoDisabled}
                        header="Contas a receber"
                    >
                        {!tabRecebimentoDisabled && <TabelaRecebimentos
                            idVenda={idVenda}
                            parcelas={parcelasContaAReceber}
                            isMobile={isMobile}
                            totalAReceber={this.calcularTotalAReceber()}
                            totalRecebido={this.calcularTotalRecebido()}
                            modalRecebimentoVisible={this.state.modalRecebimentoVisible}
                            registroSelecionado={this.state.registroSelecionado}
                            handleCloseModalRecebimento={this.handleCloseModalContaReceber}
                            handleClickEstornarRecebimento={this.handleClickEstornarContaReceber}
                            handleClickEfetuarBaixa={this.handleClickEfetuarBaixaContaReceber}
                        />}
                    </TabPanel>
                </TabView>
                }
            </Grid>
        )
    }
}

const mapStateToProps = state => ({
    isMobile: state.dispositivo.isMobile,
})

export default connect(mapStateToProps)(ParcelasVendas);
