import React, { Component } from "react";
import Button from "../";
import propTypes from "prop-types";

export const estadosBotaoCancelar = {
    CANCELAR: "CANCELAR",
    VOLTAR: "VOLTAR"
};

const styleButton = {
    border: "1px solid #0000001a",
    margin: "5px"
};

class ButtonCancelar extends Component {
    render() {
        const {
            estadoBotao,
            disabled,
            title,
            label,
            id,
            onClick,
            hidden,
            enableShortCut,
            raised,
            rounded
        } = this.props;

        let defaultLabel =
            estadoBotao === estadosBotaoCancelar.VOLTAR ? "Voltar" : "Cancelar";

        let icon =
            estadoBotao === estadosBotaoCancelar.VOLTAR
                ? "fa fa-arrow-left"
                : "fa fa-ban";

        return (
            <Button
                label={label || defaultLabel}
                title={title}
                id={id}
                color="secondary"
                type="button"
                style={styleButton}
                onClick={onClick}
                icon={icon}
                disabled={disabled}
                raised={raised}
                rounded={rounded}
                hidden={hidden}
                hotKey={["alt", "c"]}
                enableShortCut={enableShortCut}
            />
        );
    }
}

ButtonCancelar.defaultProps = {
    hidden: false,
    disabled: false
};

ButtonCancelar.propTypes = {
    /** Id do componente */
    id: propTypes.string,
    /** Derfine o botão como invisível */
    hidden: propTypes.bool,
    /** Ação executada ao clicar no botão */
    onClick: propTypes.func,
    /** Define o botão como desabilitado */
    disabled: propTypes.bool,
    /** Habilitar atalho */
    enableShortCut: propTypes.bool,
    /** Especifica o label do botão*/
    estadoBotao: propTypes.oneOf([
        estadosBotaoCancelar.VOLTAR,
        estadosBotaoCancelar.CANCELAR
    ])
};

export default ButtonCancelar;
