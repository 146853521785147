import { TiposDesconto } from "../../util/constantes"

export function converterProdutoIncompletoParaModal(informacoesProduto) {

    const desconto = informacoesProduto.produto?.desconto
    const produto = informacoesProduto.produto

    return {
        idTemporario: produto.idTemporario,
        cest: produto.cest?.value ? produto.cest : '',
        cfop: produto.cfop ? produto.cfop : '',
        codigoBarras: produto.codigoBarras,
        csosn: produto.csosn ? produto.csosn : '',
        idProd: produto.idProd,
        ncm: produto.ncm.value ? produto.ncm : '',
        origem: produto.origem ?? '',
        produto: produto.produto,
        quantidade: produto.quantidade ?? '',
        unidadeMedida: produto.unidadeMedida.value ? produto.unidadeMedida : '',
        valorUnitario: produto.valorUnitario,
        desconto: {
            tipoDesconto: desconto.tipoDesconto,
            valorDesconto: desconto.valorDesconto ?? 0
        },
        descontoTotalProduto: calcularDesconto(desconto, produto.quantidade, produto.valorUnitario),
        totalProduto: calcularTotal(desconto, produto.quantidade, produto.valorUnitario),

    }
}

export function converterProdutoNovoParaListagemDaNfce(produto) {
    if (produto) {
        return {
            cest: produto.cest ? {
                codigo: produto.cest.registro.codigo,
                descricao: produto.cest.registro.descricao,
                id: produto.cest.registro.id
            } : '',
            id: produto.id,
            ncm: produto.ncm ? {
                codigo: produto.ncm.registro.codigo,
                descricao: produto.ncm.registro.descricao,
                id: produto.ncm.registro.id
            } : '',
            nome: produto.nome,
            origem: produto.origem,
            sku: produto.sku,
            unidadeMedida: produto.unidadeMedida ? {
                descricao: produto.unidadeMedida.registro.descricao,
                id: produto.unidadeMedida.registro.id, unidade:
                    produto.unidadeMedida.registro.unidade
            } : '',
            valorUnitario: produto.preco,
        }
    } else {
        return null
    }

}

export function converterProdutoEdicaoParaModal(produto) {
    return {
        id: produto.id,
        idTemporario: produto.idTemporario,
        produto: produto.produto,
        idProd: produto.idProd,
        codigoBarras: produto.codigoBarras,
        quantidade: produto.quantidade,
        valorUnitario: produto.valorUnitario,
        unidadeMedida: produto.unidadeMedida,
        ncm: produto.ncm,
        cest: produto.cest?.value ? produto.cest : '',
        cfop: produto.cfop,
        csosn: produto.csosn,
        origem: produto.origem,
        desconto: {
            tipoDesconto: TiposDesconto.VALOR,
            valorDesconto: produto.descontoTotalProduto ?? 0
        },
        descontoTotalProduto: produto.descontoTotalProduto,
        acessorias: produto.acessoriasTotalProduto,
        totalProduto: produto.totalProduto,
    }
}

function calcularDesconto(desconto, quantidade, valorUnitario) {
    if (desconto.tipoDesconto === TiposDesconto.PERCENTUAL) {
        const valorProduto = quantidade * valorUnitario

        return (valorProduto * desconto.valorDesconto) / 100

    } else if (desconto.tipoDesconto === TiposDesconto.VALOR) {
        return desconto.valorDesconto
    }
    return 0
}

function calcularTotal(desconto, quantidade, valorUnitario) {
    const valorProduto = quantidade * valorUnitario
    const totalCalculado = valorProduto - calcularDesconto(desconto, quantidade, valorUnitario)

    if (totalCalculado < 0) {
        return 0
    }
    return totalCalculado
}