import React, { Component } from 'react';
import * as Yup from "yup";
import autoBind from 'react-autobind';
import Modal from '../../../../../components/Modal';
import { withRouter } from 'react-router';
import { usuarioPossuiPermissao } from '../../../../../common/autenticacao';
import { recursos, permissoes, estadosCadastro } from '../../../../../common/constantes/autorizacao';
import AutoProgressBar from '../../../../../components/Loading/AutoProgressBar';
import { withFormik, Field } from 'formik';
import { mensagensDeValidacao } from '../../../../../common/constantes/mensagens';
import ButtonNovo, { estadosBotaoNovo } from '../../../../../components/Button/ButtonNovo';
import ButtonSalvar from '../../../../../components/Button/ButtonSalvar';
import ButtonCancelar, { estadosBotaoCancelar } from '../../../../../components/Button/ButtonCancelar';
import ButtonExcluir from '../../../../../components/Button/ButtonExcluir';
import InputField from '../../../../../components/Input/InputField';
import { helpAtivosForm } from './help/index';
import Grid from '../../../../../components/Grid';
import Dropdown from '../../../../../components/Select/Dropdown';
import { confirm } from '../../../../../components/Toast';
import Form from '../../../../../components/Form';
import FormActions from '../../../../../components/FormActions';
import FormContent from '../../../../../components/FormContent';
import { exibirConfirmacaoCodigo, jaExisteAtivosComMesmoCodigo } from './utils/utils';
import { keyFilterConsultaRsql } from '../../../../../common/rsql';
import InputMask from '../../../../../components/Input/InputMask';
import { asyncDeleteAtivo, asyncCreateAtivo, asyncUpdateAtivo } from '../../request';
import { isValidCnpj } from '@brazilian-utils/validators';
import { manterApenasNumeros } from '../../../../../common/mascara';

const tipo = [
    { label: 'Ação', value: 'ACAO' },
    { label: 'Criptomoeda', value: 'CRIPTOMOEDA' },
    { label: 'Fundos imobiliários', value: 'FII' },
];

const initialValue = {
    id: null,
    nome: null,
    codigo: null,
    tipo: 'ACAO',
    segmento: null,
    cnpj: null,

    activeIndex: null,
};

class ModalAtivo extends Component {

    constructor(props) {
        super(props);
        autoBind(this);

        this.state = {
            podeInserir: usuarioPossuiPermissao(recursos.INVESTIDOR_ATIVOS, permissoes.INSERIR),
            podeEditar: usuarioPossuiPermissao(recursos.INVESTIDOR_ATIVOS, permissoes.EDITAR),
            podeExcluir: usuarioPossuiPermissao(recursos.INVESTIDOR_ATIVOS, permissoes.EXCLUIR),

            exibirModalConfirmacaoEdicao: false
        }
    }

    novo() {
        this.props.onNovoClick && this.props.onNovoClick()
        this.props.resetForm({ values: initialValue });
        this.setState({
            registroSelecionado: null
        })
    }

    async salvar(e, novoOnSuccess) {
        const { values, errors, initialValues } = this.props;
        await this.props.handleSubmit();
        await this.props.validateForm();

        if (this.props.isValid) {
            if (values) {
                if (values.codigo && values.codigo !== initialValues.codigo && await jaExisteAtivosComMesmoCodigo(values.id, values.codigo)) {
                    exibirConfirmacaoCodigo(() => {
                        this.salvarOuAtualizar(values, novoOnSuccess)
                    })
                } else {
                    this.salvarOuAtualizar(values, novoOnSuccess)
                }
            }
        } else {
            this.setState({ activeIndex: errors.activeIndex })
        }
    }

    async salvarOuAtualizar(values, novoOnSuccess) {
        if (values.id) {
            this.setState({ exibirModalConfirmacaoEdicao: true })
            await asyncUpdateAtivo(values, ({ data: ativo }) => {
                if (novoOnSuccess) {
                    this.props.atualizarListagem();
                    novoOnSuccess();
                } else {
                    this.props.onHide({ ...values, id: ativo.id });
                }
            })
        } else {
            await asyncCreateAtivo(values, ({ data: ativo }) => {
                if (novoOnSuccess) {
                    this.props.atualizarListagem();
                    novoOnSuccess();
                } else {
                    this.props.onHide({ ...values, id: ativo.id });
                }
            })
        }
    }

    excluir() {
        const { id } = this.props.values
        if (id) {
            confirm('Confirmação', "Deseja realmente excluir o ativo?", async () => {
                await asyncDeleteAtivo(id, () => this.props.onHide())
            });
        }
    }

    cancelar() {
        if (this.props.dirty) {
            this.props.resetForm({ values: this.props.initialValues });
        } else {
            this.props.onHide();
        }
    }

    renderModalHeader(values) {
        return values.id ? 'Editar ativo' : 'Novo ativo';
    }

    render() {
        const { onHide, visible, dirty, values, isModal, setFieldValue, insertJustOne } = this.props;
        const { podeInserir, podeEditar, podeExcluir } = this.state;

        const informacoesPermissoes = {
            podeInserir: podeInserir,
            podeEditar: podeEditar,
            podeExcluir: podeExcluir,
            estadoCadastro: values.id ? estadosCadastro.EDICAO : estadosCadastro.INCLUSAO
        };

        const estadoBotaoNovo = dirty ? estadosBotaoNovo.SALVAR_E_NOVO : estadosBotaoNovo.NOVO;
        const onClickNovo = dirty ? (e) => this.salvar(e, this.novo) : this.novo;

        return (
            <Modal
                header={this.renderModalHeader(values)}
                visible={visible}
                onHide={() => {
                    this.novo()
                    onHide(values)
                }}
            >
                <AutoProgressBar />
                <Form>
                    <FormActions>
                        <ButtonCancelar
                            {...informacoesPermissoes}
                            hidden={isModal && values.id && !dirty}
                            estadoBotao={dirty ? estadosBotaoCancelar.CANCELAR : estadosBotaoCancelar.VOLTAR}
                            onClick={this.cancelar}
                        />
                        <ButtonSalvar
                            {...informacoesPermissoes}
                            disabled={!dirty}
                            onClick={this.salvar}
                        />
                        <ButtonNovo
                            onClick={onClickNovo}
                            hidden={(!dirty && !values.id) || insertJustOne}
                            estadoBotao={estadoBotaoNovo}
                            {...informacoesPermissoes}
                        />
                        <ButtonExcluir
                            hidden={!values.id || isModal}
                            {...informacoesPermissoes}
                            onClick={this.excluir}
                        />
                    </FormActions>
                    <FormContent>
                        <Grid>
                            <Field sm="12" md="6" lg='4' xl='4'
                                component={InputField}
                                label="Código (ticker)"
                                obrigatorio
                                name="codigo"
                                value={values.codigo}
                                onChange={e => setFieldValue('codigo', e.target.value?.toUpperCase())}
                                keyfilter={keyFilterConsultaRsql}
                                size={15}
                                helpMessage={helpAtivosForm.codigo}
                                {...informacoesPermissoes}
                            />
                            <Field sm="12" md="6" lg='8' xl='8'
                                component={InputField}
                                label='Nome da empresa'
                                obrigatorio
                                name="nome"
                                helpMessage={helpAtivosForm.nome}
                                size={255}
                                {...informacoesPermissoes}
                            />
                            <Field sm="12" md="6" lg='4' xl='4'
                                component={InputField}
                                label='Segmento'
                                name="segmento"
                                helpMessage={helpAtivosForm.segmento}
                                size={255}
                                {...informacoesPermissoes}
                            />
                            <Field sm="12" md="6" lg='4' xl='4'
                                component={InputMask}
                                mask="00.000.000/0000-00"
                                placeholder="  .   .   /    -  "
                                label='CNPJ'
                                name="cnpj"
                                onChange={e => setFieldValue('cnpj', e.target.value)}
                                value={values.cnpj}
                                helpMessage={helpAtivosForm.cnpj}
                                {...informacoesPermissoes}
                            />
                            <Field
                                sm="12" md="6" lg='4' xl='4'
                                component={Dropdown}
                                label='Tipo'
                                obrigatorio
                                name="tipo"
                                showClear={false}
                                value={values.tipo}
                                helpMessage={helpAtivosForm.tipo}
                                onChange={e => this.props.setFieldValue('tipo', e.value)}
                                options={tipo}
                                {...informacoesPermissoes}
                            />
                        </Grid>
                    </FormContent>
                </Form>
            </Modal>
        )
    }
}

ModalAtivo = withFormik({
    validateOnChange: false,
    validateOnBlur: false,

    mapPropsToValues(props) {
        if (props.registroSelecionado) {
            return props.registroSelecionado
        } else {
            return initialValue;
        }
    },

    validationSchema: Yup.object().shape({
        codigo: Yup.string().nullable().required(mensagensDeValidacao.OBRIGATORIO),
        nome: Yup.string().nullable().required(mensagensDeValidacao.OBRIGATORIO),
        tipo: Yup.string().required(mensagensDeValidacao.OBRIGATORIO)
    }),

    validate(values) {

        let errors = {};

        if (values.cnpj && !isValidCnpj(manterApenasNumeros(values.cnpj))) {
            errors.cnpj = 'Digite um CNPJ válido.';
            return errors
        }
    },

    handleSubmit: () => { }
})(ModalAtivo);


export default withRouter(ModalAtivo);
