import React, { Component } from 'react'
import { Prompt as InternalPrompt } from 'react-router-dom'
import PropTypes from 'prop-types'
import { alterouEstadoFormulario } from '../action';

class Prompt extends Component {

    componentDidUpdate(prevProps) {
        if (prevProps.dirty !== this.props.dirty) {
            alterouEstadoFormulario(this.props.dirty);
        }
    }

    render() {
        if (this.props.isModal) {
            return null
        }
        return (
            <InternalPrompt
                when={this.props.dirty}
                message={(params) =>
                    !params.pathname.includes('/cadastro') ? this.props.message : true}
            />
        )
    }
}

Prompt.propTypes = {
    /** Mensagem  */
    message: PropTypes.string,
    /** Se for modal, não renderiza o prompt  */
    isModal: PropTypes.bool,
    dirty: PropTypes.bool
}

Prompt.defaultProps = {
    dirty: false,
    message: `Tem certeza que deseja sair? \nAs alterações serão perdidas.`
}

export default Prompt;