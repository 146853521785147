import React, { Component } from 'react';
import autoBind from "react-autobind/lib/autoBind";
import { DataTable } from 'primereact/components/datatable/DataTable';
import { Column } from 'primereact/column';
import { Paginator } from 'primereact/paginator';
import { inserirMascara } from '../../../../../common/mascara';
import { recursos, permissoes } from '../../../../../common/constantes/autorizacao';
import { usuarioPossuiPermissao } from '../../../../../common/autenticacao';
import Button from '../../../../../components/Button';
import { connect } from 'react-redux';
import NenhumRegistroEncontrado from '../../../../../components/NenhumRegistroEncontrado';

import './style/index.css'
import { formatos } from '../../../../../common/constantes/mascaras';

const styleColumnOverflowElipsis = {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
}

const styleButton = {
    borderRadius: '50%',
    padding: '5px',
    width: '30px',
    height: '30px',
    margin: '2px'
};

class TabelaAtivos extends Component {

    constructor(props) {
        super(props);

        autoBind(this)

        this.state = {
            podeExcluirAtivos: usuarioPossuiPermissao(recursos.INVESTIDOR_ATIVOS, permissoes.EXCLUIR),
            podeVisualizarAtivos: usuarioPossuiPermissao(recursos.INVESTIDOR_ATIVOS, permissoes.VISUALIZAR),
            podeEditarAtivos: usuarioPossuiPermissao(recursos.INVESTIDOR_ATIVOS, permissoes.EDITAR),
        }
    }

    renderTipo(row) {
        switch (row.tipo) {
            case 'ACAO': {
                return 'Ação'
            }
            case 'FII': {
                return 'Fundos imobiliários'
            }
            case 'CRIPTOMOEDA': {
                return 'Criptomoeda'
            }
            default: return row.tipo
        }
    }

    renderCnpj(row) {
        if (row) {
            return inserirMascara(row.cnpj, formatos.CNPJ);
        } 
    }

    renderNome(row) {
        return <span title={row.nome}>{row.nome}</span>
    }

    renderOpcoes(el) {
        const { podeExcluirAtivos, podeVisualizarAtivos } = this.state;
        let titleEditar = "Editar";
        let titleExcluir = "Excluir";
        let disableBtnExcluir = false;

        if (!podeVisualizarAtivos) {
            titleEditar = "Você não possuir permissão para executar essa ação"
        }

        if (!podeExcluirAtivos) {
            titleExcluir = "Você não possui permissão para executar essa ação"
            disableBtnExcluir = true;
        };

        return (
            <>
                <Button
                    style={styleButton}
                    className="p-button p-button-primary"
                    icon="fa fa-pencil"
                    title={titleEditar}
                    onClick={() => this.props.onEditItem(el)}
                />
                <Button
                    style={styleButton}
                    className="p-button p-button-danger"
                    icon="fa fa-trash"
                    title={titleExcluir}
                    disabled={disableBtnExcluir}
                    onClick={() => this.props.onRemoveItem(el)}
                />
            </>
        );
    }

    render() {
        const { registros, sortField, sortOrder, isMobile, rows, totalRecords, first, onPageChange } = this.props;

        const acoesStyle = !isMobile && { textAlign: 'end' };
        return (
            <>
                 <DataTable
                    className="table-light"
                    responsive
                    value={registros}
                    sortField={sortField}
                    sortOrder={sortOrder}
                    onSort={this.onSort}
                    emptyMessage={<NenhumRegistroEncontrado />}
                >
                    <Column
                        className="step-listagem-order"
                        field="codigo"
                        header="Código (ticker)"
                        sortable={true}
                        style={styleColumnOverflowElipsis}
                    />
                    <Column
                        className="step-listagem-order"
                        field="nome"
                        header="Nome da empresa"
                        sortable={true}
                        body={this.renderNome}
                        style={styleColumnOverflowElipsis}
                    />
                    <Column
                        field="tipo"
                        header="Tipo"
                        sortable={true}
                        body={this.renderTipo}
                        style={{ width: '170px' }}
                    />
                    <Column
                        field="segmento"
                        header="Segmento"
                        sortable={true}
                        style={styleColumnOverflowElipsis}
                    />
                    <Column
                        field="cnpj"
                        header="CNPJ"
                        body={this.renderCnpj}
                        style={{ width: '170px' }}
                    />
                     <Column
                        header="Ações"
                        headerClassName='coluna-acoes-end'
                        body={this.renderOpcoes}
                        style={{ width: '130px', ...acoesStyle }}
                    />
                </DataTable>
                <Paginator
                    className='paginator-light'
                    rowsPerPageOptions={[10, 25, 50]}
                    totalRecords={totalRecords}
                    rows={rows}
                    first={first}
                    onPageChange={onPageChange}
                    rightContent={<div style={{ margin: '5px' }}>{totalRecords > 0 ? `${totalRecords} registro(s) encontrado(s)` : null}</div>}
                />
            </>
        )
    }
}

const mapStateToProps = state => ({
    isMobile: state.dispositivo.isMobile,
})

export default connect(mapStateToProps)(TabelaAtivos)