import { DADOSLOCALSTORAGE } from '../constantes/localStorage'
import { asyncfazerLogin, asyncFazerLogout, asyncBuscarInformacoesCredencial } from './actions'
import { buscarItemLocalStorage, salvarItemLocalStorage, removerItemLocalStorage } from '../localStorage';
import { alert, prompt, notify, ToastTypes } from '../../components/Toast';
import { dispatchAction } from '../redux';
import { actionTypes } from '../constantes/reduxTypes';


export async function fazerLogin(email, senha, manterConectado, onSuccess, onError, tratarErros) {
    await asyncfazerLogin(email, senha, async e => {
        await salvarDadosLoginLocalStorage({ ...e.data, email, senha, manterConectado })
        await sincronizarInformacoesLogin(() => {
	    dispatchAction(actionTypes.MENU_LATERAL_ATUALIZAR)
            onSuccess && onSuccess(e)
        }, onError)
    }, onError, tratarErros)
}

export async function fazerLogout() {
    const deslogar = () => {
        fazerLogoutLocalStorange()
        window.location = "#/login"
    }
    return await asyncFazerLogout(() => deslogar(), () => deslogar())
}


export async function sincronizarInformacoesLogin(onSuccess, onError) {
    await asyncBuscarInformacoesCredencial(async e => {
        let dadosLogin = buscarDadosLoginLocalStorage();
        dadosLogin = {
            ...e.data,
            ...dadosLogin,
            setores: e.data.setores,
            configuracoes: e.data.configuracoes,
            organizacao: e.data.organizacao,
            recursos: e.data.recursos,
        }
        await salvarDadosLoginLocalStorage(dadosLogin)
        onSuccess && onSuccess(e)
    }, onError)
}

export function fazerLogoutLocalStorange() {
    removerItemLocalStorage(DADOSLOCALSTORAGE.dadosLogin);
    removerItemLocalStorage(DADOSLOCALSTORAGE.dataUltimoAcesso);
    removerItemLocalStorage(DADOSLOCALSTORAGE.instalacaoAplicativoJaSolicitada);
}

export async function salvarDadosLoginLocalStorage(dadosLogin) {
    await salvarItemLocalStorage(DADOSLOCALSTORAGE.dadosLogin, dadosLogin)
}

export function buscarDadosLoginLocalStorage() {
    return buscarItemLocalStorage(DADOSLOCALSTORAGE.dadosLogin)
}

export function salvarDataUltimoAcessoLocalStorage(dataUltimoAcesso) {
    salvarItemLocalStorage(DADOSLOCALSTORAGE.dataUltimoAcesso, dataUltimoAcesso, false)
}

export function buscarDataUltimoAcessoLocalStorage() {
    return buscarItemLocalStorage(DADOSLOCALSTORAGE.dataUltimoAcesso, false)
}


export function usuarioPossuiModulo(modulo) {
    const modulos = buscarDadosLoginLocalStorage().organizacao.modulos;
    return modulos.includes(modulo)
}

export function usuarioPossuiModulos(modulos) {
    if (!modulos) {
        return true
    }
    for (const modulo of modulos) {
        if (usuarioPossuiModulo(modulo)) {
            return true
        }
    }
    return false
}

export function usuarioPossuiPermissao(recurso, permissao) {
    const recursos = buscarDadosLoginLocalStorage().recursos;
    for (const itemRecurso of recursos) {
        if (itemRecurso.identificacao === recurso) {
            for (const itemPermissao of itemRecurso.permissoes) {
                if (itemPermissao.identificacao === permissao) {
                    return true
                }
            }
        }
    }
    return false
}

export function solicitarLoginDoUsuario(onSuccess, onError, numeroTentativa) {
    const dadosLogin = buscarDadosLoginLocalStorage()
    let mensagem = "Informe sua senha para prosseguir"
    if (numeroTentativa && numeroTentativa > 1) {
        mensagem += " (Tentativa número " + numeroTentativa + " de 5)"
    }


    prompt('Sua sessão expirou!', mensagem, "", (evt, senha) => {
        fazerLogin(dadosLogin.email, senha, dadosLogin.manterConectado, onSuccess, (e) => {
            if (contaFoibloqueada(e.response)) {
                notify("Conta bloqueada", ToastTypes.ERROR)
                fazerLogoutLocalStorange()
                window.location = "#/login"
            } else if (!numeroTentativa || numeroTentativa < 5) {
                solicitarLoginDoUsuario(onSuccess, onError, numeroTentativa ? numeroTentativa + 1 : 2)
            } else {
                fazerLogoutSenhaIncorreta()
                onError && onError()
            }
        }, false)
    }, () => {
        onError && onError()
        fazerLogoutLocalStorange()
        window.location = "#/login"
    }).set('type', 'password').set('closable', false).set('overflow', false);
}

export function fazerLogoutSenhaIncorreta() {
    alert('Atenção', 'Para prosseguir, é necessário fazer login novamente.');
    fazerLogoutLocalStorange();
    window.location = "#/login"
}

function contaFoibloqueada(response) {
    return response && response.data && response.data.error_description && response.data.error_description === "Conta bloqueada"
}