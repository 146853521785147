import React, { Component } from 'react';
import propTypes from 'prop-types'
import Col from '../Col';
import { buscarDisabledDeAcordoComAsPermissoes, buscarHiddenDeAcordoComAsPermissoes, buscarTitleCampoDeAcordoComAsPermissoes } from '../../common/autorizacao/manipulacaoDeComponentes';
import autoBind from 'react-autobind';
import { renderizarValidacao } from '../../common/tratamentoDeErro/validacoesDeCampos';

export default class TextArea extends React.Component {

    render() {
        const { field, form, ...rest } = this.props;
        return (
            <React.Fragment>
                <InternalTextArea
                    {...field}
                    {...rest}
                    errors={form.errors[field.name]}
                    touched={form.touched[field.name]}
                />
            </React.Fragment>
        );
    }
}


export class InternalTextArea extends Component {

    constructor(props) {
        super(props)

        autoBind(this);
    }

    getColStyle() {
        if (buscarHiddenDeAcordoComAsPermissoes(this.props.podeVisualizar, this.props.hidden))
            return { display: 'none', ...this.props.colStyle }
        return this.props.colStyle
    }

    modificarOnChangeParaNull(e) {
        return {
            target: {
                value: null,
                name: e.target.name
            }
        }
    }

    onChange(e) {
        if (!e.target.value || e.target.value.length <= this.props.size) {
            if (e.target.value === '')
                this.props.onChange(this.modificarOnChangeParaNull(e))
            else
                this.props.onChange(e)
        }
    }

    getValue() {
        return this.props.value ? this.props.value : ''
    }

    montarLabel() {
        const { obrigatorio, label } = this.props;
        if (obrigatorio) {
            return <label> {label} <b style={{ fontSize: '18px', lineHeight: '5px' }} > *</b> </label>
        }
        return <label> {label} </label>
    }

    render() {
        const { name, placeholder, className, onBlur, id, rows, cols,
            colId, sm, md, lg, xl, col, podeInserir, podeEditar,
            estadoCadastro, title, disabled, errors, touched, podeVisualizar,
            readOnly, maxLength, autoFocus, minLength, style
        } = this.props


        const internalStyle = {
            fontFamily: 'Open Sans, Helvetica Neue, sans-serif',
            resize: 'none',
            width: '100%',
            fontSize: '14px',
            color: '#333333',
            background: '#ffffff',
            padding: '0.429em',
            border: '1px solid #a6a6a6',
            borderColor: (errors && touched) ?'red' : '#a6a6a6',
            appearance: 'none',
            borderRadius: '3px'
        }

        const desabilitar = buscarDisabledDeAcordoComAsPermissoes(podeInserir, podeEditar, estadoCadastro, disabled);

        return (
            <Col id={colId} col={col} sm={sm} md={md} lg={lg} xl={xl} style={this.getColStyle()}>
                <label title={this.props.helpMessage}>{this.montarLabel()}</label>
                <textarea
                    title={buscarTitleCampoDeAcordoComAsPermissoes(podeVisualizar, podeInserir, podeEditar, estadoCadastro, title)}
                    onChange={this.onChange}
                    disabled={desabilitar}
                    name={name}
                    rows={rows}
                    cols={cols}
                    style={{...internalStyle, ...style}}
                    placeholder={placeholder}
                    className={desabilitar ? className + ' p-disabled' : className}
                    value={this.getValue()}
                    onBlur={onBlur}
                    id={id}
                    readOnly={readOnly}
                    maxLength={maxLength}
                    minLength={minLength}
                    autoFocus={autoFocus}
                />
                {renderizarValidacao(errors, touched)}
            </Col>
        )
    }
}

InternalTextArea.defaultProps = {
    value: '',
    autoResize: false,
    rows: 5,
    cols: 30,
    podeVisualizar: true,
    size: 9999999,
}

InternalTextArea.propTypes = {

    /** Estilo dO componente*/
    style: propTypes.object,
    /** Especifica o tipo do campo */
    type: propTypes.string,
    /** Label do componente */
    label: propTypes.string,
    /** Nome do componente */
    name: propTypes.string,
    /** Placeholder do componente */
    placeholder: propTypes.string,
    /** Nome da classe do componente */
    className: propTypes.string,
    /** Identificador do componente */
    id: propTypes.string,
    /** Valor do componente do componente */
    value: propTypes.any,
    /** Especifica os erros de validação que o componente possui (geralmente vindos do Yup)*/
    errors: propTypes.oneOfType([propTypes.string, propTypes.bool]),
    /** Especifica se o componente foi 'tocado'*/
    touched: propTypes.bool,
    /** Evento executado ao sair do campo*/
    onBlur: propTypes.any,
    /** Tamanho do campo em small devices*/
    sm: propTypes.string,
    /** Tamanho do campo em medium devices*/
    md: propTypes.string,
    /** Tamanho do campo em large devices*/
    lg: propTypes.string,
    /** Tamanho do campo em extra large devices*/
    xl: propTypes.string,
    /** Estado em que o cadastro se encontra*/
    estadoCadastro: propTypes.string,
    /** Diz se o usuário possui permissão de visualizar*/
    podeVisualizar: propTypes.bool,
    /** Diz se o usuário possui permissão de editar*/
    podeEditar: propTypes.bool,
    /** Diz se o usuário possui permissão de excluir*/
    podeInserir: propTypes.bool,
    /** Title do componente*/
    title: propTypes.string,
    /** Id da coluna*/
    colId: propTypes.string,
    /** Esconde o componente*/
    hidden: propTypes.bool,
    readOnly: propTypes.bool
}
