import React, { Component } from 'react';
import Modal from '../../../../../../../components/Modal';
import ButtonCancelar, { estadosBotaoCancelar } from '../../../../../../../components/Button/ButtonCancelar';
import autoBind from 'react-autobind';
import AutoProgressBar from '../../../../../../../components/Loading/AutoProgressBar';
import Form from '../../../../../../../components/Form';
import FormActions from '../../../../../../../components/FormActions';
import FormContent from '../../../../../../../components/FormContent';
import { formatarMonetario } from '../../../../../../../common/mascara';
import Col from '../../../../../../../components/Col';
import { validarFormulario } from '../../../../../../util';
import { withFormik } from 'formik';
import { AcoesBtnSalvarNfce, Condicoes } from '../../../util/constantes';
import { format, isValid, parseISO, set } from 'date-fns';
import { mensagensDeValidacao } from '../../../../../../../common/constantes/mensagens';
import Button from '../../../../../../../components/Button';
import ParcelasNfce from './components/ParcelasNfce'
import { validarValorNegativo } from '../../../util/functions';
import { converterParcelasParaModal } from '../../utils/converterParcelasParaModal';
import { Fieldset } from 'primereact/fieldset';
import Grid from '../../../../../../../components/Grid';
import { InternalInputDouble } from '../../../../../../../components/Input/InputDouble';
import Card from '../../../../../../../components/Card';
import If from '../../../../../../../components/If';

const descontoAcrescimoStyle = {
    fontSize: '17px',
    color: '#000000',
    margin: '0px',
    justifyContent: 'flex-end',
    display: 'flex',
}

const totalStyle = {
    fontSize: '28px',
    color: '#006095',
    margin: '11px 0px 0px 0px',
    justifyContent: 'flex-end',
    display: 'flex',
}

const totalTrocoStyle = {
    fontSize: '28px',
    color: '#1b5e20',
    margin: '15px 0px 0px 0px',
    display: 'flex',
}

const styleErroTotalParcela = {
    fontSize: '12px',
    color: 'red',
    margin: '0px',
    justifyContent: 'flex-end',
    display: 'flex',
}

const initialValue = {
    pag: {
        id: null,
        valorRecebido: 0,
        valorTroco: 0,
        condicao: Condicoes.A_VISTA,
        quantidadeParcelas: 0,
        categoria: null,
        parcelas: []
    }
};

class ModalFormaPagamentoNfce extends Component {

    constructor(props) {
        super(props);
        autoBind(this);

        this.state = {
            totaisNfceCalculados: {}
        }

    }

    componentDidMount() {
        this.calcularOsTotaisDaNfce()
    }

    calcularOsTotaisDaNfce() {
        const { descontoPendenteRateio, totaisNfce } = this.props
        const totais = {
            vAcres: totaisNfce.vAcres,
            vDesc: totaisNfce.vDesc + descontoPendenteRateio,
            vOutro: totaisNfce.vOutro,
            vProd: totaisNfce.vProd,
            vNF: validarValorNegativo((totaisNfce.vProd + totaisNfce.vOutro) - (totaisNfce.vDesc + descontoPendenteRateio)),
        }
        this.setState({ totaisNfceCalculados: totais })
    }

    cancelar() {
        this.props.dirty ? this.props.resetForm(initialValue) : this.props.onHide();
    }

    async salvarParcelas(acoesBtnSalvarNfce) {
        this.props.handleSubmit();
        if (await validarFormulario(this.props)) {
            const { values } = this.props
            this.props.salvarParcelas(values, acoesBtnSalvarNfce)
        }
    }

    onChangeValorRecebido(valorRecebido) {
        this.props.setFieldValue('pag.valorRecebido', valorRecebido)
        this.props.setFieldValue('pag.valorTroco', this.calcularTroco(valorRecebido))

    }

    calcularTroco(valorRecebido) {
        const { totaisNfceCalculados } = this.state

        return validarValorNegativo(valorRecebido - totaisNfceCalculados.vNF)
    }

    render() {
        const {
            visible, onHide, dirty, values, isModal, errors, setFieldValue,
            isMobile, disabled, informacoesPermissoes
        } = this.props;

        const { totaisNfceCalculados } = this.state
        const isAvista = values.pag?.condicao === Condicoes.A_VISTA
        const valorResponsivo = isAvista ? "6" : "12"

        return (
            <Modal
                header={"Forma de pagamento"}
                visible={visible}
                onHide={() => {
                    onHide(values)
                }}
            >
                <AutoProgressBar />
                <Form>
                    <FormActions>
                        <ButtonCancelar
                            hidden={isModal && values.id && !dirty}
                            estadoBotao={dirty ? estadosBotaoCancelar.CANCELAR : estadosBotaoCancelar.VOLTAR}
                            onClick={this.cancelar}
                        />
                        <Button
                            className='p-button-success'
                            label='Salvar'
                            icon='fa fa-check'
                            onClick={() => this.salvarParcelas(AcoesBtnSalvarNfce.SALVAR)}
                            disabled={!dirty || disabled}
                        />
                        <Button
                            className='p-button-info'
                            style={{ marginLeft: '5px' }}
                            label={dirty ? 'Salvar e transmitir NFC-e' : 'Transmitir NFC-e'}
                            icon='fa fa-send'
                            disabled={disabled}
                            onClick={() => this.salvarParcelas(AcoesBtnSalvarNfce.SALVAR_E_TRANSMITIR)}
                        />
                    </FormActions>
                    <FormContent>
                        <Grid>
                            <Col sm="12" md="12" lg='12' xl='12'>
                                <ParcelasNfce
                                    totalLiquido={totaisNfceCalculados.vNF}
                                    condicao={values.pag?.condicao}
                                    emissao={format(new Date(), 'yyyy-MM-dd')}
                                    onChangeCondicao={condicao => {
                                        setFieldValue('pag.condicao', condicao)
                                        setFieldValue('pag.valorRecebido', 0)
                                        setFieldValue('pag.valorTroco', 0)
                                    }}
                                    onChangeQuantidadeParcelas={quantidadeParcelas => setFieldValue('pag.quantidadeParcelas', quantidadeParcelas)}
                                    onChangeParcelas={parcelas => setFieldValue('pag.parcelas', parcelas)}
                                    quantidadeParcelas={values.pag?.quantidadeParcelas}
                                    categoria={values.pag?.categoria}
                                    onChangeCategoria={categoria => setFieldValue('pag.categoria', categoria)}
                                    parcelas={values.pag?.parcelas}
                                    informacoesPermissoes={informacoesPermissoes}
                                    disabled={disabled}
                                    errors={errors}
                                />
                            </Col>
                            <If test={isAvista}>
                                <Col sm="12" md="6" lg='6' xl='6'>
                                    <Card>
                                        <Fieldset legend="Calcular troco" className='fieldset-light'>
                                            <InternalInputDouble
                                                col="12" sm="12" md="12" lg='12' xl='12'
                                                label='Valor recebido'
                                                helpMessage='Valor em R$ recebido no momento do pagamento'
                                                name='valorRecebido'
                                                size={13}
                                                onChange={valorRecebido => { this.onChangeValorRecebido(valorRecebido.target.value) }}
                                                value={values.pag?.valorRecebido}
                                                placeholder='R$ 0,00'
                                                prefix='R$ '
                                                errors={errors?.valorRecebido}
                                                allowNegative={false}
                                                touched
                                                disabled={disabled}
                                                {...informacoesPermissoes}
                                            />
                                            <Col>
                                                <p style={{ ...totalTrocoStyle, justifyContent: isMobile ? 'flex-end' : 'flex-start' }}><b>{`Valor de troco: ${formatarMonetario(values.pag?.valorTroco)}`}</b></p>
                                            </Col>
                                        </Fieldset>
                                    </Card>
                                </Col>
                            </If>
                            <Col
                                sm="12"
                                md={valorResponsivo}
                                lg={valorResponsivo}
                                xl={valorResponsivo}
                            >
                                <Card>
                                    <Fieldset legend={isAvista ? " Totais da NFC-e" : ""} className='fieldset-light'>
                                        <Col>
                                            <p style={descontoAcrescimoStyle}><b>{`Total dos produtos: ${formatarMonetario(totaisNfceCalculados.vProd)}`}</b></p>
                                            <p style={descontoAcrescimoStyle}><b>{`Acessórias: ${formatarMonetario(totaisNfceCalculados.vOutro)}`}</b></p>
                                            <p style={descontoAcrescimoStyle}><b>{`Desconto: ${formatarMonetario(totaisNfceCalculados.vDesc)}`}</b></p>
                                            <p style={totalStyle}><b>{`Total Líquido: ${formatarMonetario(totaisNfceCalculados.vNF)}`}</b></p>
                                            {errors.total && <p style={styleErroTotalParcela}>{errors.total}</p>}
                                        </Col>
                                    </Fieldset>
                                </Card>
                            </Col>
                        </Grid>
                    </FormContent>
                </Form>
            </Modal>
        )
    }
}
ModalFormaPagamentoNfce = withFormik({
    enableReinitialize: true,
    validateOnChange: true,

    mapPropsToValues(props) {
        if (props.totaisNfce && !props.pag.Parcelas) {
            return converterParcelasParaModal(props)
        } else {
            return { ...initialValue, pag: props.pag }
        }
    },

    validate(values) {

        let errors = {};
        let errorsParcelas = []
        let valorTotalParcelas = 0
        const valorTotalNfce = values.totaisNfce.vNF + values.totaisNfce.vOutro

        values.pag && values.pag.parcelas && values.pag.parcelas.forEach((parcela, index) => {
            valorTotalParcelas += parcela.valor

            let errorParcela = null
            if (parcela.valor <= 0) {
                errorParcela = { ...errorParcela, vPag: "Valor inválido" }
            }

            if (!parcela.vencimento || (parcela.vencimento && !isValid(parseISO(parcela.vencimento)))) {
                errorParcela = { ...errorParcela, vencimento: mensagensDeValidacao.DATA_INVALIDA }
            }

            if (errorParcela !== null) {
                errorsParcelas[index] = errorParcela
            }
        })

        if (parseFloat(valorTotalParcelas.toFixed(2)) > parseFloat(valorTotalNfce.toFixed(2))) {
            errors.total = 'Valor das parcelas é maior que o valor da NFC-e'
        }

        if (parseFloat(valorTotalParcelas.toFixed(2)) < parseFloat(valorTotalNfce.toFixed(2))) {
            errors.total = 'Valor das parcelas é menor que o valor da NFC-e'
        }

        if (errorsParcelas.length > 0) {
            errors.pag = errorsParcelas
        }

        if (values.pag?.valorRecebido > 0 && values.pag?.valorRecebido < valorTotalNfce) {
            errors.valorRecebido = 'Valor recebido não pode ser menor que o valor líquido da NFC-e'
        }

        return errors
    },

    handleSubmit: () => { }
})(ModalFormaPagamentoNfce);

export default ModalFormaPagamentoNfce;
