import { post, exibirToast } from '../../../../../../../../common/requisicoes';
import { services } from '../../../../../../../../common/constantes/api';

export async function asyncEfetuarRecebimento(idVenda, parcela, onSuccess, onError) {
    await post(`${services.GESTOR}/v1/vendas/${idVenda}/parcelas/${parcela.id}/receber`, parcela, null, exibirToast(onSuccess, "Recebimento efetuado com sucesso"), onError);
}

export async function asyncEstornarParcelaRecebida(idVenda, parcelaId, onSuccess, onError) {
    await post(`${services.GESTOR}/v1/vendas/${idVenda}/parcelas/${parcelaId}/estornar`, null, null, exibirToast(onSuccess, "Recebimento estornado com sucesso"), onError);
}
