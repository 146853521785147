import React, { Component } from 'react'
import Grid from '../../../../../components/Grid';
import Col from '../../../../../components/Col';
import { connect } from 'react-redux';
import { usuarioPossuiPermissao } from '../../../../../common/autenticacao';
import { recursos, permissoes } from '../../../../../common/constantes/autorizacao';
import { Button } from 'primereact/button';
import { withRouter } from 'react-router-dom';
import autoBind from 'react-autobind'
import CardsResultadoNoPeriodo from './components/CardsResultadoNoPeriodo'
import ListaProdutosMaisVendidos from './components/ListaProdutosMaisVendidos';
import ListaClientesMaisCompram from './components/ListaClientesMaisCompram';
import ListaRankingVendedores from './components/ListaRankingVendedores';
import GraficoVendasUltimosDozeMeses from './components/GraficoVendasUltimosDozeMeses';
import GraficoVendasPorDia from './components/GraficoVendasPorDia';

class DashboardVendas extends Component {
    constructor(props) {
        super(props)
        autoBind(this)
        this.state = {
            atualizarGraficoNovamente: null,
            podeInserirVenda: usuarioPossuiPermissao(recursos.VENDAS_VENDAS, permissoes.INSERIR)
        }
    }


    componentDidUpdate(prevProps) {
        if (prevProps.dataAtualizacaoMenuLateral !== this.props.dataAtualizacaoMenuLateral) {
            this.setState({
                atualizarGraficoNovamente: this.getNewTimestamp(),
                podeInserirVenda: usuarioPossuiPermissao(recursos.VENDAS_VENDAS, permissoes.INSERIR),
            })
        }
    }

    getNewTimestamp = () => new Date().getTime();

    render() {
        const { dataInicial, dataFinal, isMobile, atualizarGraficoNovamente } = this.props;
        const { podeInserirVenda } = this.state
        const paddingCards = isMobile ? { padding: '0.5em 0em' } : { padding: '0.5em' };

        const styleButtons = {
            margin: isMobile ? '3px 5px' : '3px 7px',
            width: isMobile ? 'calc(50% - 10px)' : '185px'
        };

        if (dataInicial && dataFinal) {
            return (
                <Grid style={{ margin: '0px' }}>

                    <Col style={{ padding: '0px' }}>
                        <Button
                            label="Nova venda"
                            className="p-button-success"
                            onClick={() => this.props.history.push({ pathname: "/vendas/cadastro", state: { venda: true } })}
                            style={styleButtons}
                            disabled={!podeInserirVenda}
                            title={!podeInserirVenda ? "Você não possui permissão para inserir uma venda" : null}
                        />
                        <Button
                            label="Novo orçamento"
                            className="p-button-primary"
                            onClick={() => this.props.history.push({ pathname: "/vendas/cadastro", state: { venda: false } })}
                            style={styleButtons}
                            disabled={!podeInserirVenda}
                            title={!podeInserirVenda ? "Você não possui permissão para inserir um orçamento" : null}
                        />
                    </Col>
                    <CardsResultadoNoPeriodo
                        dataInicial={dataInicial}
                        dataFinal={dataFinal}
                        isMobile={isMobile}
                        atualizarGraficoNovamente={atualizarGraficoNovamente}
                    />
                    <Col xs="12" sm="6" md="4" lg="4" xl="4" style={paddingCards}>
                        <ListaProdutosMaisVendidos
                            dataInicial={dataInicial}
                            dataFinal={dataFinal}
                            atualizarGraficoNovamente={this.state.atualizarGraficoNovamente}
                        />
                    </Col>
                    <Col xs="12" sm="6" md="4" lg="4" xl="4" style={paddingCards}>
                        <ListaClientesMaisCompram
                            dataInicial={dataInicial}
                            dataFinal={dataFinal}
                            atualizarGraficoNovamente={this.state.atualizarGraficoNovamente}
                        />
                    </Col>
                    <Col xs="12" sm="6" md="4" lg="4" xl="4" style={paddingCards}>
                        <ListaRankingVendedores
                            dataInicial={dataInicial}
                            dataFinal={dataFinal}
                            atualizarGraficoNovamente={this.state.atualizarGraficoNovamente}
                        />
                    </Col>
                    <Col style={paddingCards}>
                        <GraficoVendasPorDia
                            dataInicial={dataInicial}
                            dataFinal={dataFinal}
                            atualizarGraficoNovamente={this.state.atualizarGraficoNovamente}
                        />
                    </Col>
                    <Col style={paddingCards}>
                        <GraficoVendasUltimosDozeMeses
                            dataInicial={dataInicial}
                            dataFinal={dataFinal}
                            atualizarGraficoNovamente={this.state.atualizarGraficoNovamente}
                        />
                    </Col>
                </Grid >
            )
        } else {
            return <span>Carregando informações...</span>
        }
    }
}


const mapStateToProps = state => ({
    isMobile: state.dispositivo.isMobile,
    dataAtualizacaoMenuLateral: state.menuLateral.dataAtualizacaoMenuLateral,
})
export default connect(mapStateToProps)(withRouter(DashboardVendas));
