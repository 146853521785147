import { confirm } from "../../../../../../components/Toast"
import { buscarContaPagarPeloNumeroDocumento } from "../../../requests"

export function saoIguais(obj1, obj2) {

    if (obj1.id !== obj2.id) return false
    if (obj1.descricao !== obj2.descricao) return false
    if (obj1.valor !== obj2.valor) return false
    if (obj1.observacao !== obj2.observacao) return false

    if ((obj1.conta && obj1.conta.value) !== (obj2.conta && obj2.conta.value)) return false
    if ((obj1.categoria && obj1.categoria.value) !== (obj2.categoria && obj2.categoria.value)) return false
    if ((obj1.pessoa && obj1.pessoa.value) !== (obj2.pessoa && obj2.pessoa.value)) return false

    if (obj1.emissao !== obj2.emissao) return false
    if (obj1.vencimento !== obj2.vencimento) return false

    return true;
}

export async function jaExisteContaPagarComMesmoNumeroDocumentoOrigem(contaPagarId, numeroDocumentoOrigem) {
    let existeMesmoDocumentoOrigem = false
    await buscarContaPagarPeloNumeroDocumento(numeroDocumentoOrigem, (e) => {
        if (e.data.content.length > 0 && e.data.content[0].id !== contaPagarId) {
            existeMesmoDocumentoOrigem = true
        }
    })
    return existeMesmoDocumentoOrigem
}

export function exibirConfirmacaoMesmoNumeroDocumento(onOk){
    confirm("Confirmação", `Já existe outra conta a pagar com este número do documento de origem. Tem certeza que deseja salvar?`, onOk)
}
