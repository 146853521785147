import React from 'react';
import { recursos, permissoes } from '../../../common/constantes/autorizacao'
import { usuarioPossuiPermissao } from '../../../common/autenticacao';
import { MdBusiness, MdAttachMoney } from 'react-icons/md';
import { GrDocumentUser, GrDocumentText } from 'react-icons/gr';
import { GiReceiveMoney, GiPayMoney, GiMoneyStack } from 'react-icons/gi';
import { FaBoxOpen, FaCubes, FaUsersCog, FaRegChartBar } from 'react-icons/fa';
import { RiArrowLeftRightLine, RiBankFill } from 'react-icons/ri';
//import { BiImport } from 'react-icons/bi'
import { BiImport } from 'react-icons/bi';

const iconStyle = {
    minWidth: '20px'
}

export function buscarMenu(alterarRota) {
    const menu = [
        {
            label: 'Início', icon: <i className='fa fa-fw fa-home' />, command: () => { alterarRota('/') }, isValid: true
        },
        {
            label: 'Administração', icon: <i className='fa fa-fw fa-key' />, isValid: true,
            items: [
                { label: 'Agentes autorizados', icon: <FaUsersCog size={20} />, command: () => alterarRota('/agentes_autorizados'), isValid: usuarioPossuiPermissao(recursos.AGENTES_AUTORIZADOS, permissoes.VISUALIZAR) },
                { label: 'Organizações', icon: <i className='fa fa-building-o' />, command: () => alterarRota('/organizacoes'), isValid: usuarioPossuiPermissao(recursos.ORGANIZACOES, permissoes.VISUALIZAR) },
                { label: 'Planos', icon: <FaCubes size={20} />, command: () => alterarRota('/administracao_planos'), isValid: usuarioPossuiPermissao(recursos.PLANOS_ADMINISTRACAO, permissoes.VISUALIZAR) },
            ]
        },
        {
            label: 'Cadastros', icon: <i className='fa fa-fw fa-wpforms' />, validate: false,
            items: [
                {
                    label: 'Pessoas', command: () => alterarRota('/pessoas'), icon: <i className='fa fa-address-card-o' />, isValid: usuarioPossuiPermissao(recursos.PESSOAS, permissoes.VISUALIZAR)
                },
                {
                    label: 'Produtos e serviços', icon: <i className='fa fa-archive' />, command: () => alterarRota('/produtos'), isValid: usuarioPossuiPermissao(recursos.PRODUTOS, permissoes.VISUALIZAR)
                },
                {
                    label: 'Setores', icon: <i className='fa fa-sitemap' />, command: () => alterarRota('/setores'), isValid: usuarioPossuiPermissao(recursos.SETORES, permissoes.VISUALIZAR)
                },
            ]
        },
        {
            label: 'Movimentações de estoque',
            icon: <FaBoxOpen size={20} />,
            command: () => alterarRota('/movimentacoes_estoque'),
            isValid: usuarioPossuiPermissao(recursos.ESTOQUE_MOVIMENTACOES, permissoes.VISUALIZAR),
        },
        {
            label: 'Assinatura eletrônica',
            icon: <i className='pi pi-check-circle' />,
            isValid: usuarioPossuiPermissao(recursos.DOCUMENTOS, permissoes.VISUALIZAR),
            command: () => alterarRota('/documentos')
        },
        {
            label: 'Finanças',
            icon: <i className='fa fa-money' />,
            isValid: true,
            items: [
                { label: 'Categorias', icon: <i className='fa fa-bookmark' style={iconStyle} />, command: () => alterarRota('/categorias'), isValid: usuarioPossuiPermissao(recursos.FINANCAS_CATEGORIAS, permissoes.VISUALIZAR) },
                { label: 'Contas', icon: <i className='fa fa-university' style={iconStyle} />, command: () => alterarRota('/contas'), isValid: usuarioPossuiPermissao(recursos.FINANCAS_CONTAS, permissoes.VISUALIZAR) },
                { label: 'Contas a pagar', icon: <GiPayMoney size={20} />, command: () => alterarRota('/contas_pagar'), isValid: usuarioPossuiPermissao(recursos.FINANCAS_CONTAS_PAGAR, permissoes.VISUALIZAR) },
                { label: 'Contas a receber', icon: <GiReceiveMoney size={20} />, command: () => alterarRota('/contas_receber'), isValid: usuarioPossuiPermissao(recursos.FINANCAS_CONTAS_RECEBER, permissoes.VISUALIZAR) },
                { label: 'Movimentações', icon: <i className='fa fa-usd' style={iconStyle} />, command: () => alterarRota('/movimentacoes'), isValid: usuarioPossuiPermissao(recursos.FINANCAS_LANCAMENTOS, permissoes.VISUALIZAR) },
            ]
        },
        {
            label: 'Vendas',
            icon: <i className='fa fa-handshake-o' />,
            isValid: usuarioPossuiPermissao(recursos.VENDAS_VENDAS, permissoes.VISUALIZAR),
            items: [
                { label: 'Vendas e orçamentos', icon: <MdAttachMoney size={25} />, command: () => alterarRota('/vendas'), isValid: usuarioPossuiPermissao(recursos.VENDAS_VENDAS, permissoes.VISUALIZAR) },
                { label: 'NF-e', title: 'Nota fiscal eletrônica', icon: <GrDocumentText size={20} />, command: () => alterarRota('/nfes'), isValid: usuarioPossuiPermissao(recursos.VENDAS_VENDAS, permissoes.VISUALIZAR) },
                { label: 'NFC-e', title: 'Nota fiscal de consumidor', icon: <GrDocumentUser size={20} />, command: () => alterarRota('/nfces'), isValid: usuarioPossuiPermissao(recursos.VENDAS_VENDAS, permissoes.VISUALIZAR) },
            ]
        },
        {
            label: 'Investimentos', icon: <FaRegChartBar size={20} />, validate: false,
            items: [
                { label: 'Ativos', command: () => alterarRota('/ativos'), icon: <MdBusiness size={20} />, isValid: usuarioPossuiPermissao(recursos.INVESTIDOR_ATIVOS, permissoes.VISUALIZAR) },
                { label: 'Corretoras', command: () => alterarRota('/corretoras'), icon: <RiBankFill size={20} />, isValid: usuarioPossuiPermissao(recursos.INVESTIDOR_CORRETORAS, permissoes.VISUALIZAR) },
                { label: 'Proventos', command: () => alterarRota('/proventos'), icon: <GiMoneyStack size={20} />, isValid: usuarioPossuiPermissao(recursos.INVESTIDOR_PROVENTOS, permissoes.VISUALIZAR) },                
                { label: 'Negociações', command: () => alterarRota('/negociacoes'), icon: <RiArrowLeftRightLine size={20} />, isValid: usuarioPossuiPermissao(recursos.INVESTIDOR_NEGOCIACOES, permissoes.VISUALIZAR) },
                { label: 'Importações', command: () => alterarRota('/importacoes'), icon: <BiImport size={20} />, isValid: usuarioPossuiPermissao(recursos.INVESTIDOR_IMPORTACOES, permissoes.VISUALIZAR) }
            ]
        }
    ]
    return menu
}
