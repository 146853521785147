import { Condicoes } from '../utils/constantes'
import { montarLabelPessoa } from '../../../../../../components/Select/SingleSelectPessoa/utils';
import { formatISO } from 'date-fns';
import { Status } from '../../util/constantes';

export function converterVendaParaFormulario(values) {
    return {
        ...values,

        condicao: values.pagamento.condicao,
        cliente: converterPessoaParaFormulario(values.cliente),
        vendedor: converterPessoaParaFormulario(values.vendedor),


        itens: converterItensParaFormulario(values.itens),
        historico: values.historico,
        tipoDesconto: values.tipoDesconto === 'NAO_APLICADO' ? 'VALOR' : values.tipoDesconto,
        valorDesconto: values.tipoDesconto === 'VALOR' ? values.desconto : null,
        percentualDesconto: values.tipoDesconto === 'PERCENTUAL' ? values.desconto : null,

        pagamento: converterPagamentoParaFormulario(values.pagamento),
        parcelas: values.pagamento && converterParcelasParaFormulario(values.pagamento.parcelas),
        quantidadeParcelas: values.pagamento ? values.pagamento.quantidadeParcelas : null,
        categoria: values.pagamento && converterCategoriaParaFormulario(values.pagamento.categoria),
    }
}

export function converterVendaParaApi(values) {
    return {
        ...values,
        emissao: values.emissao,
        cliente: values.cliente ? { id: values.cliente.value } : null,
        vendedor: values.vendedor ? { id: values.vendedor.value } : null,
        pagamento: converterPagamentoParaApi(values),
        itens: converterItensParaApi(values.itens),
        tipoDesconto: !values.valorDesconto && !values.percentualDesconto ? 'NAO_APLICADO' : values.tipoDesconto,
        desconto: values.tipoDesconto === 'VALOR' ? values.valorDesconto : values.percentualDesconto,
    }

}

export function converterParaDuplicar(values) {
    return {
        ...values,
        id: null,
        status: values.status === Status.CANCELADA ? Status.ORCAMENTO : values.status,
        criadoEm: null,
        numero: null,
        emissao: formatISO(new Date()),
        previsaoEntrega: null,
        observacaoInterna: '',
        informacaoComplementar: '',
        parcelas: values.parcelas?.map(parcela => {
            return {
                ...parcela,
                id: null,
                status: null,
                valorAReceber: parcela.valor
            }
        }),
        pagamento: {
            ...values.pagamento,
            parcelas: [],
        },
        nfe: null
    }
}

function converterPagamentoParaFormulario(pagamento) {
    return {
        ...pagamento
    }
}

function converterPessoaParaFormulario(pessoaApi) {
    if (pessoaApi) {
        return {
            label: montarLabelPessoa(pessoaApi),
            value: pessoaApi.id,
            registro: pessoaApi
        }
    }
    return null;
}

function converterCategoriaParaFormulario(categoriaApi) {
    if (categoriaApi) {
        return {
            label: categoriaApi.nome,
            value: categoriaApi.id,
            registro: categoriaApi
        }
    }
    return null;
}

function converterParcelasParaFormulario(parcelas) {
    if (parcelas.length > 0) {
        return parcelas
    } else {
        return [];
    }
}

function converterItensParaFormulario(itensApi) {
    const itensFormulario = itensApi.map(item => {
        return {
            ...item,
            produto: converterProdutoParaFormulario(item.produto),
        }
    });
    return itensFormulario;
}


function converterProdutoParaFormulario(produtoApi) {
    if (produtoApi) {
        return {
            value: produtoApi.id,
            label: produtoApi.sku + ' - ' + produtoApi.nome,
            registro: produtoApi
        }
    }
    return null;
}


function converterPagamentoParaApi(values) {
    if (!values.totalLiquido || values.condicao === Condicoes.SEM_PAGAMENTO) {
        return {
            condicao: Condicoes.SEM_PAGAMENTO,
            categoria: values.categoria ? { id: values.categoria.value } : null,
        }
    }

    return {
        condicao: values.condicao,
        quantidadeParcelas: values.quantidadeParcelas,
        parcelas: values.parcelas,
        categoria: values.categoria ? { id: values.categoria.value } : null,
    }
}

function converterItensParaApi(itens) {
    const itensApi = itens.filter(item => item.produto).map((item, index) => {
        return {
            ...item,
            item: index + 1,
            produto: {
                id: item.produto.value,
                tipo: item.produto.registro.tipo,
                controlarEstoque: item.produto.registro.controlarEstoque
            },
        }
    });
    return itensApi;
}
