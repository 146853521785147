import { get, del, exibirToast, put, post } from "../../../../common/requisicoes";
import { services } from "../../../../common/constantes/api";
import { formatISO, format } from "date-fns";

export async function asyncGetContasReceber(url, onSuccess, onError) {
    return await get(url, null, onSuccess, onError, true)
}

export async function asyncGetContaReceber(idContaReceber, onSuccess, onError, exibirLoading = true) {
    return await get(`${services.GESTOR}/v1/contas_receber/${idContaReceber}`, null, onSuccess, onError, exibirLoading);
}

export async function asyncCreateContaReceber(data, onSuccess, onError) {
    await post(`${services.GESTOR}/v1/contas_receber`, data, null, exibirToast(onSuccess, "Conta a receber criada com sucesso"), onError);
}

export async function asyncUpdateContaReceber(contaReceber, onSuccess, onError) {
    await put(`${services.GESTOR}/v1/contas_receber/${contaReceber.id}`, contaReceber, null, exibirToast(onSuccess, "Conta a receber atualizada com sucesso"), onError);
}

export async function asyncDeleteContaReceber(contaReceberId, onSuccess, onError) {
    await del(`${services.GESTOR}/v1/contas_receber/${contaReceberId}`, null, exibirToast(onSuccess, "Conta a receber removida com sucesso"), onError);
}

export async function asyncEditarContaReceberProximasEmAberto(contaReceber, onSuccess, onError) {
    await put(`${services.GESTOR}/v1/contas_receber/${contaReceber.id}/proximas_repeticoes_em_aberto`, contaReceber,  null, exibirToast(onSuccess, "Contas a receber alteradas com sucesso"), onError);
}

export async function asyncEditarContaReceberTodasEmAberto(contaReceber, onSuccess, onError) {
    await put(`${services.GESTOR}/v1/contas_receber/${contaReceber.id}/todas_repeticoes_em_aberto`, contaReceber, null, exibirToast(onSuccess, "Contas a receber alteradas com sucesso"), onError);
}

export async function asyncDeleteContaReceberProximasEmAberto(idContaReceber, onSuccess, onError) {
    await del(`${services.GESTOR}/v1/contas_receber/${idContaReceber}/proximas_repeticoes_em_aberto`, null, exibirToast(onSuccess, "Contas a receber removidas com sucesso"), onError);
}

export async function asyncDeleteContaReceberTodasEmAberto(idContaReceber, onSuccess, onError) {
    await del(`${services.GESTOR}/v1/contas_receber/${idContaReceber}/todas_repeticoes_em_aberto`, null, exibirToast(onSuccess, "Contas a receber removidas com sucesso"), onError);
}

export async function asyncGetCategoriasReceita(onSuccess, onError) {
    return await get(`${services.GESTOR}/v1/contas_receber/relacoes/categorias/receitas?size=500`, null, onSuccess, onError, true)
}

export async function buscarCategoriaFavoritaReceita(onSuccess, onError) {
    await get(`${services.GESTOR}/v1/contas_receber/relacoes/categorias/receitas?query=favoritaReceita=="true";ativa=="true"`, null, onSuccess, onError, false)
}

export async function asyncGetContas(onSuccess, onError) {
    return await get(`${services.GESTOR}/v1/contas_receber/relacoes/contas?size=500`, null, onSuccess, onError, true)
}

export async function asyncGetTotalizadoresCards(filtro, onSuccess, onError){
    return await get(`${services.GESTOR}/v1/contas_receber/totais?dataInicial=${formatISO(filtro.dataInicial, {representation: 'date'})}&dataFinal=${formatISO(filtro.dataFinal, {representation: 'date'})}`, null, onSuccess, onError, true)
}

export async function asyncEfetuarRecebimento(id,onSuccess, onError) {
    return await put(`${services.GESTOR}/v1/contas_receber/${id}/receber`, null, onSuccess, exibirToast(onSuccess, "Recebimento efetuado com sucesso"), onError)
}

export async function buscarContaFavoritaReceita(onSuccess, onError) {
    await get(`${services.GESTOR}/v1/contas_receber/relacoes/contas?query=favoritaReceita=="true";ativa=="true"`, null, onSuccess, onError, false)
}

export async function asyncBaixarRelatorioContasAReceberPorPeriodo(dataInicial, dataFinal, contaSituacao, onSuccess, onError) {
    const dataInicialFormatada = format(dataInicial, 'yyyy-MM-dd');
    const dataFinalFormatada = format(dataFinal, 'yyyy-MM-dd');
    return await get(`${services.GESTOR}/v1/contas_receber/imprimir/contas_a_receber_por_periodo?dataInicial=${dataInicialFormatada}&dataFinal=${dataFinalFormatada}&contaSituacao=${contaSituacao}`, { responseType: "arraybuffer" }, onSuccess, onError);
}

export async function buscarContaReceberPeloNumeroDocumento(numeroDocumento, onSuccess) {
    await get(`${services.GESTOR}/v1/contas_receber?query=numeroDocumento=='${numeroDocumento}'`, null, data => onSuccess(data));
}
