import React, { Component } from 'react'
import ButtonNovo from '../../../components/Button/ButtonNovo'
import { recursos, permissoes } from '../../../common/constantes/autorizacao';
import { formatarParaPesquisarParaCamposBoolean, removerCaracteres } from '../../../common/manipulacaoDeString';
import Grid from '../../../components/Grid';
import autoBind from 'react-autobind'
import { asyncGetPesquisaOrganizacoes, asyncDeleteOrganizacao } from './requests'
import ButtonEditarTable from '../../../components/Button/ButtonEditarTable';
import ButtonExcluirTable from '../../../components/Button/ButtonExcluirTable';
import { atualizarUrl } from '../../util';
import InputSearch from '../../../components/Input/InputSearch';
import { inserirMascara } from '../../../common/mascara';
import { formatos } from '../../../common/constantes/mascaras';
import { Column } from 'primereact/column';
import { usuarioPossuiPermissao, buscarDadosLoginLocalStorage } from '../../../common/autenticacao';
import { construirUrl } from '../../../common/rsql';
import { services } from '../../../common/constantes/api';
import { confirm } from '../../../components/Toast';
import Form from '../../../components/Form';
import FormActions from '../../../components/FormActions';
import FormContent from '../../../components/FormContent';
import { DataTable } from 'primereact/components/datatable/DataTable';
import { Checkbox } from 'primereact/checkbox';
import NenhumRegistroEncontrado from '../../../components/NenhumRegistroEncontrado';
import { Paginator } from 'primereact/paginator';

class Organizacoes extends Component {

    constructor(props) {
        super(props)

        autoBind(this)

        this.state = {
            podeInserir: usuarioPossuiPermissao(recursos.ORGANIZACOES, permissoes.INSERIR),
            podeExcluir: usuarioPossuiPermissao(recursos.ORGANIZACOES, permissoes.EXCLUIR),
            valorPesquisa: '',
            registros: [],
            sortField: 'nome',
            sortOrder: 1,
            page: 0,
            rows: 10,
            totalElements: 0,
            idOrganizacaoLogada: null,
            exibirBloqueadas: false
        }
    }

    componentDidMount() {
        this.setState({ idOrganizacaoLogada: buscarDadosLoginLocalStorage().organizacao.id })
        this.pesquisar()
    }

    buscarFiltro() {
        const { valorPesquisa } = this.state;

        const pesquisaCodigo = removerCaracteres(valorPesquisa, ['.', '/', '-'])
        const valorCredencialBloqueada = formatarParaPesquisarParaCamposBoolean(valorPesquisa, 'BLOQUEADA', 'DESBLOQUEADA');

        let filtroRSQL = String("?query=(")
            .concat(`nome=contains="*${valorPesquisa}*",`)
            .concat(`cpf=contains="*${pesquisaCodigo}*",`)
            .concat(`cnpj=contains="*${pesquisaCodigo}*",`)
            .concat(`email=contains="*${valorPesquisa}*",`)
            .concat(`bloqueada==${valorCredencialBloqueada})`)
        return filtroRSQL;
    }

    pesquisar() {
        const { page, sortField, sortOrder, rows } = this.state
        const filtro = this.buscarFiltro();
        const url = construirUrl(`${services.GESTOR}/v1/organizacoes/resumo`,
            filtro, rows, page, sortOrder > 0 ? `${sortField},asc` : `${sortField},desc`)

        asyncGetPesquisaOrganizacoes(url, ({ data: organizacao }) => {
            this.setState({
                registros: organizacao.content,
                totalElements: organizacao.totalElements
            })
        })
    }

    onPesquisar() {
        this.setState({ page: 0 }, () => {
            this.pesquisar();
        })
    }

    onPageChange(e) {
        this.setState({ page: e.page, rows: e.rows }, () => {
            this.pesquisar()
        })
    }

    onSort(e) {
        this.setState({ sortField: e.sortField, sortOrder: e.sortOrder }, () => {
            this.pesquisar()
        })
    }

    onEditar(row) {
        atualizarUrl(this.props.history, '/organizacoes/cadastro', row.id)
    }

    onExcluir(row) {
        confirm('Confirmação', 'Tem certeza que deseja excluir a organização? Este processo não pode ser revertido.', () => {
            this.asyncExcluirRegistro(row);
        })
    }

    asyncExcluirRegistro(registro) {
        asyncDeleteOrganizacao(registro.id, () => {
            this.pesquisar();
        })
    }

    renderOpcoes(row) {
        let mesmaOrganizacao = this.state.idOrganizacaoLogada === row.id;

        let msgTitleExcluir = null;

        if (mesmaOrganizacao) {
            msgTitleExcluir = "Não é possível excluir a própria organização.";
        } else {
            msgTitleExcluir = "Excluir a organização.";
        }

        return (
            <>
                <ButtonEditarTable onClick={() => this.onEditar(row)} />
                <ButtonExcluirTable
                    title={msgTitleExcluir}
                    disabled={mesmaOrganizacao}
                    podeExcluir={this.state.podeExcluir}
                    onClick={() => this.onExcluir(row)}
                />
            </>
        )
    }

    renderCpfCnpj(row) {
        const cpfCnpj = row.cpf ?
            inserirMascara(row.cpf, formatos.CPF) :
            inserirMascara(row.cnpj, formatos.CNPJ);

        return (
            <span title={cpfCnpj}>
                {cpfCnpj}
            </span>
        )
    }

    renderEmail(row) {
        return (
            <span title={row.email}>
                {row.email}
            </span>
        )
    }

    renderStatus(row) {
        const label = row.bloqueada ? 'Bloqueado' : 'Desbloqueado';
        const strongColor = row.bloqueada ? '#da1f1f' : 'green';
        const lightColor = row.bloqueada ? '#ffcdd2' : '#dcedc8';

        return (
            <span style={{
                backgroundColor: lightColor,
                color: strongColor,
                fontWeight: 'bold',
                fontSize: '13px',
                borderRadius: '3px',
                padding: '3px 10px',
            }}>
                <span>{label}</span>
            </span>
        )
    }

    render() {

        const {
            page,
            rows,
            totalElements,
            podeInserir,
            sortField,
            sortOrder,
            valorPesquisa,
            registros,
            exibirBloqueadas } = this.state;

        const registrosFiltrados = registros.filter(registro => exibirBloqueadas ? true : !registro.bloqueada)

        return (
            <>
                <Form header="Organizações">
                    <FormActions>
                        <ButtonNovo
                            label='Nova organização'
                            onClick={() => this.props.history.push('/organizacoes/cadastro')}
                            podeInserir={podeInserir}
                        />
                    </FormActions>
                    <FormContent>
                        <Grid justifyCenter>
                            <InputSearch
                                onPesquisar={this.onPesquisar}
                                value={valorPesquisa}
                                onChange={value => this.setState({ valorPesquisa: value })}
                            />
                        </Grid>
                        <DataTable
                            className="table-light"
                            responsive
                            value={registrosFiltrados}
                            sortField={sortField}
                            sortOrder={sortOrder}
                            onSort={this.onSort}
                            emptyMessage={<NenhumRegistroEncontrado />}
                            header={(
                                <span style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <Checkbox
                                        name="exibirBloqueadas"
                                        inputId="exibirBloqueadas"
                                        title="Marque esta opção caso queira ver as organizações bloqueadas"
                                        onChange={() => this.setState({ exibirBloqueadas: !exibirBloqueadas })}
                                        checked={exibirBloqueadas}
                                    />
                                    <label htmlFor="exibirBloqueadas" className="p-checkbox-label">Exibir bloqueadas</label>
                                </span>
                            )}
                        >
                            <Column
                                field="nome"
                                header="Nome"
                                sortable={true}
                                style={{ overflow: 'hidden' }}
                            />
                            <Column
                                field="cpf"
                                header="CPF/CNPJ"
                                body={row => this.renderCpfCnpj(row)}
                                sortable={true}
                            />
                            <Column
                                field="email"
                                header="E-mail"
                                sortable={true}
                                body={row => this.renderEmail(row)}
                                style={{ overflow: 'hidden' }}
                            />
                            <Column
                                field="bloqueada"
                                header="Status"
                                body={row => this.renderStatus(row)}
                                style={{ width: '155px' }}
                            />
                            <Column
                                body={this.renderOpcoes}
                                header="Ações"
                                style={{ width: '100px' }}
                            />
                        </DataTable>
                    </FormContent>
                    <Paginator
                        className='paginator-light'
                        rowsPerPageOptions={[10, 25, 50]}
                        totalRecords={totalElements}
                        rows={rows}
                        first={page * rows}
                        onPageChange={this.onPageChange}
                        rightContent={<div style={{ margin: '5px' }}>{totalElements > 0 ? `${totalElements} registro(s) encontrado(s)` : null}</div>}
                    />
                </Form>
            </>
        )
    }
}

export default Organizacoes