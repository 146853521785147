import React, { Component } from 'react';
import * as Yup from "yup";
import autoBind from 'react-autobind';
import { withRouter } from 'react-router';
import Modal from '../../../../../components/Modal';
import { withFormik, Field } from 'formik';
import AutoProgressBar from '../../../../../components/Loading/AutoProgressBar';
import ButtonSalvar, { estadosBotaoSalvar } from '../../../../../components/Button/ButtonSalvar';
import ButtonCancelar, { estadosBotaoCancelar } from '../../../../../components/Button/ButtonCancelar';
import InputDate from '../../../../../components/Input/InputDate';
import Grid from '../../../../../components/Grid';
import InputMoney from '../../../../../components/Input/InputMoney';
import { mensagensDeValidacao } from '../../../../../common/constantes/mensagens';
import { helpMessage } from './util/constantes';
import Form from '../../../../../components/Form';
import FormActions from '../../../../../components/FormActions';
import FormContent from '../../../../../components/FormContent';
import Fieldset from '../../../../../components/FieldSet';
import { aplicarPercentual, validarFormulario } from '../../../../util';
import { asyncGetContaReceber } from '../../requests';
import { converterContaReceberParaFormulario, converterContaReceberParaApi } from '../ModalContaReceber/util/contaReceberConverter';
import { asyncUpdateContaReceber } from '../../requests';
import Col from '../../../../../components/Col';
import SingleSelectConta from '../../../../../components/Select/SingleSelectConta';
import { services } from '../../../../../common/constantes/api';
import { buscarContaFavoritaReceita } from '../../requests'
import { formatISO, isValid, parseISO } from 'date-fns';
import TextArea from '../../../../../components/TextArea';
import { formatarMonetario } from '../../../../../common/mascara';
import InputSelectPercentualOrValor, { tipos } from '../../../../../components/Input/InputSelectPercentualOrValor';
import { renderizarValidacao } from '../../../../../common/tratamentoDeErro/validacoesDeCampos';



const initialValue = {
    id: null,
    idTemporario: null,
    data: formatISO(new Date()),
    desconto: 0,
    juros: 0,
    multa: 0,
    conta: null,
    valor: null,
    observacao: null
}

const parteStyle = {
    fontSize: '13px',
    color: '#000000',
    margin: '0px 0px',
}

const totalStyle = {
    fontSize: '20px',
    color: '#000000',
    margin: '10px 0px 0px 0px',
}

class ModalRecebimentoTable extends Component {

    constructor(props) {
        super(props);
        autoBind(this);

        this.state = {
            exibirModalRecebimentoTable: false,
            registroCompleto: null,
            tipoDesconto: tipos.VALOR,
            percentualDesconto: 0,
            tipoJuros: tipos.VALOR,
            percentualJuros: 0,
            tipoMulta: tipos.VALOR,
            percentualMulta: 0
        }
    }

    componentDidMount() {
        document.getElementById('valorRecebimento').focus()
        buscarContaFavoritaReceita(({ data }) => {
            if (data.totalElements > 0) {
                this.props.resetForm({
                    values: {
                        ...this.props.initialValues,
                        conta: {
                            label: data.content[0].nome,
                            value: data.content[0].id,
                            registro: data.content[0]
                        }
                    }
                });
            }
        })
        asyncGetContaReceber(this.props.registroSelecionado.id, ({ data }) => {
            this.setState({ registroCompleto: converterContaReceberParaFormulario(data) })
        }, null, false)
    }

    onChangeValor(e) {
        const { setFieldValue } = this.props;
        const { tipoDesconto, percentualDesconto,
            tipoJuros, percentualJuros,
            tipoMulta, percentualMulta } = this.state;

        const valor = e.target.value
        setFieldValue('valor', valor);

        // CALCULAR  PERCENTUAIS
        if (tipoDesconto === tipos.PERCENTUAL) {
            setFieldValue('desconto', aplicarPercentual(valor, percentualDesconto));
        }

        if (tipoJuros === tipos.PERCENTUAL) {
            setFieldValue('juros', aplicarPercentual(valor, percentualJuros));
        }

        if (tipoMulta === tipos.PERCENTUAL) {
            setFieldValue('multa', aplicarPercentual(valor, percentualMulta));
        }

    }

    async salvar() {
        const { values, isValid, handleSubmit } = this.props;

        await handleSubmit();

        if (await validarFormulario(this.props)) {
            if (values && isValid) {

                let valoresParaSalvar = { ...this.state.registroCompleto }
                valoresParaSalvar.recebimentos.push(values)
                asyncUpdateContaReceber(converterContaReceberParaApi(valoresParaSalvar), () => {
                    this.props.onHide(true)
                })
            }
        }
    }

    render() {
        const { values, visible, onHide, setFieldValue, errors } = this.props;
        const total = values.valor + values.juros + values.multa - values.desconto

        return (
            <Modal
                header='Confirmar recebimento'
                visible={visible}
                onHide={onHide}
            >
                <AutoProgressBar />
                <Form>
                    <FormActions>
                        <ButtonCancelar
                            estadoBotao={estadosBotaoCancelar.VOLTAR}
                            onClick={() => this.props.onHide()}
                        />
                        <ButtonSalvar
                            estadoBotao={estadosBotaoSalvar.SALVAR}
                            onClick={this.salvar}
                        />
                    </FormActions>
                    <FormContent>
                        <Grid>
                            <Field sm="12" md="6" lg="4" xl="4"
                                id='valorRecebimento'
                                component={InputMoney}
                                label="Valor"
                                name="valor"
                                obrigatorio
                                value={values.valor}
                                helpMessage={helpMessage.valor}
                                onChange={this.onChangeValor}
                                size={15}
                            />
                            <Field sm="12" md="6" lg="4" xl="4"
                                component={InputDate}
                                label="Data do recebimento"
                                name="data"
                                obrigatorio
                                onChange={e => setFieldValue('data', e.target.value)}
                                value={values.data}
                            />
                            <Field sm="12" md="6" lg='4' xl='4'
                                name="conta"
                                label="Conta"
                                obrigatorio
                                component={SingleSelectConta}
                                value={values.conta}
                                helpMessage={helpMessage.conta}
                                onChange={e => setFieldValue('conta', e)}
                                url={`${services.GESTOR}/v1/contas_receber/relacoes/contas`}
                            />
                            <Fieldset legend="Informações complementares" style={{ width: '100%' }} className='fieldset-light'>
                                <Grid col="12">
                                    <Col sm="12" md="6" lg="4" xl="4">
                                        <Field
                                            component={InputSelectPercentualOrValor}
                                            label="Descontos"
                                            name="desconto"
                                            value={values.desconto}
                                            valueBase={values.valor}
                                            errors={errors.desconto}
                                            helpMessage={helpMessage.desconto}
                                            onChange={(tipo, value, percentual) => {
                                                this.setState({ tipoDesconto: tipo, percentualDesconto: percentual })
                                                setFieldValue('desconto', value)
                                            }}
                                            size={15}
                                        />
                                    </Col>
                                    <Col sm="12" md="6" lg="4" xl="4">
                                        <Field
                                            component={InputSelectPercentualOrValor}
                                            label="Juros"
                                            name="juros"
                                            value={values.juros}
                                            valueBase={values.valor}
                                            helpMessage={helpMessage.juros}
                                            onChange={(tipo, value, percentual) => {
                                                this.setState({ tipoJuros: tipo, percentualJuros: percentual })
                                                setFieldValue('juros', value)
                                            }}
                                            size={15}
                                        />
                                    </Col>
                                    <Col sm="12" md="6" lg="4" xl="4">
                                        <Field
                                            component={InputSelectPercentualOrValor}
                                            label="Multa"
                                            name="multa"
                                            value={values.multa}
                                            valueBase={values.valor}
                                            helpMessage={helpMessage.multa}
                                            onChange={(tipo, value, percentual) => {
                                                this.setState({ tipoMulta: tipo, percentualMulta: percentual })
                                                setFieldValue('multa', value)
                                            }}
                                            size={15}
                                        />
                                    </Col>
                                    <Field sm="12"
                                        component={TextArea}
                                        placeholder="Escreva sua observação aqui"
                                        label='Observação'
                                        name="observacao"
                                        helpMessage={helpMessage.observacao}
                                        maxLength={4096}
                                        onChange={e => setFieldValue('observacao', e.target.value)}
                                    />
                                </Grid>
                            </Fieldset>
                            <Col>
                                <p style={parteStyle}>{`Descontos: ${formatarMonetario(values.desconto)}`}</p>
                                <p style={parteStyle}>{`Juros: ${formatarMonetario(values.juros)}`}</p>
                                <p style={parteStyle}>{`Multa: ${formatarMonetario(values.multa)}`}</p>
                                <p style={totalStyle}>{`Total: ${formatarMonetario(total)}`}</p>
                                {errors.total && renderizarValidacao(errors.total, true)}
                            </Col>
                        </Grid>
                    </FormContent>
                </Form>
            </Modal>
        )
    }
}


ModalRecebimentoTable = withFormik({
    validateOnChange: false,

    mapPropsToValues(props) {
        if (props.registroSelecionado) {
            return { ...initialValue, valor: props.registroSelecionado.valorAReceber };
        } else {
            return { ...initialValue }
        }
    },

    validate(values) {
        let errors = {}
        const valorTotal = values.valor + values.juros + values.multa - values.desconto;

        if (values.valor <= 0) {
            errors.valor = "O valor deve ser maior que zero"
        }

        if (valorTotal < 0) {
            errors.desconto = "O desconto não pode exceder o valor total"
        }

        if (valorTotal === 0) {
            errors.total = "O campo valor deve ser maior ou igual a R$ 00.01"
        }

        if (values.data && !isValid(parseISO(values.data))) {
            errors.data = mensagensDeValidacao.DATA_INVALIDA
        }

        return errors
    },

    validationSchema: Yup.object().shape({
        data: Yup.string().nullable().required(mensagensDeValidacao.DATA_INVALIDA),
        valor: Yup.string().nullable().required(mensagensDeValidacao.OBRIGATORIO),
        conta: Yup.string().nullable().required(mensagensDeValidacao.OBRIGATORIO),
    }),
    handleSubmit: () => { }
})(ModalRecebimentoTable);


export default withRouter(ModalRecebimentoTable);
