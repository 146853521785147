import { statusDocumento } from "../../util/constantes";

export const etapas = [
    {
        name: 'Pendente',
        value: statusDocumento.PENDENTE,
        index: 0
    },
    {
        name: 'Arquivo adicionado',
        value: statusDocumento.ARQUIVO_ADICIONADO,
        index: 1
    },
    {
        name: 'Aguardando assinaturas',
        value: statusDocumento.AGUARDANDO_ASSINATURAS,
        index: 2
    }
];

export function buscarEtapas(status) {
    let etapasPadrao = [...etapas]

    if (status === statusDocumento.REJEITADO) {
        etapasPadrao.push({
            name: 'Documento rejeitado',
            value: statusDocumento.REJEITADO,
            index: 3
        })
    } else if (status === statusDocumento.EXPIRADO) {
        etapasPadrao.push({
            name: 'Documento expirado',
            value: statusDocumento.EXPIRADO,
            index: 3
        })
    }else{
        etapasPadrao.push({
            name: 'Documento assinado',
            value: statusDocumento.ASSINADO,
            index: 3
        })
    }
    return etapasPadrao
}


export const helpMessage = {
    identificacao: 'Tem como objetivo identificar de maneira simples e rápida um documento. É possível informar qualquer valor que caracteriza um documento em específico.',
    observacao: 'Observações de qualquer natureza sobre o documento.',
    pessoa: 'Identificação da pessoa a quem se destina, relaciona ou pertence o documento. Por exemplo, uma proposta comercial será enviada a um cliente, um documento do RH pode ser de um funcionário em específico, um contrato se destina a um cliente para assinatura.',
    usuario: 'Usuário responsável pelo documento.',
    setor: 'Setor responsável pelo documento. Todo usuário do sistema está vinculado a um ou mais setores, isto faz com que  determinado usuário visualize apenas documentos relacionados ao seu setor.',
    expiracaoAssinaturas: 'Data limite para os signatários realizarem a assinatura do documento. Caso algum signatário não assinar até a data especificada o sistema irá modificar o status do documento para expirado.'
};

export const initialValue = {
    id: '',
    identificacao: '',
    status: statusDocumento.PENDENTE,
    pessoa: null,
    hash256: '',
    criadoEm: '',
    arquivado: false,
    expiracaoAssinaturas: null,
    usuario: null,
    setor: null,
    confirmacoes: [],
    signatarios: [],
    observacao: '',
    arquivo: null
};

export const CADASTROURL = '/documentos/cadastro';
export const PESQUISAURL = '/documentos';
