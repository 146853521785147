import { operacoesString, operacoesSelectOptions, operacoesNumericos, operacoesDatas, tipoCampos, operacoesMultiSelectOptions } from './constantes.js';
import format from 'date-fns/format';
import { parseISO, isValid } from 'date-fns';

export function diferenciarArrays(array1, array2) {

    var result = array1.filter(
        ({ name: id1 }) => !array2.some(
            ({ name: id2 }) => id2 === id1
        )
    );

    return result;
}

export function buscarOpcoes(filtro) {
    switch (filtro.type) {
        case tipoCampos.DATE:
            return operacoesDatas
        case tipoCampos.INTEGER:
            return operacoesNumericos
        case tipoCampos.DECIMAL:
            return operacoesNumericos
        case tipoCampos.SELECT:
            return operacoesSelectOptions
        case tipoCampos.MULTI_SELECT:
            return operacoesMultiSelectOptions
        default:
            return operacoesString
    }
}

export function buscarOptionsSelect(optionsFiltros, filtros) {
    const teste = diferenciarArrays(optionsFiltros, filtros)
    return teste.map(item => {
        return {
            ...item,
            value: item.name
        }
    })
}

export function convertToRSQL(filtros) {
    var result = ''
    var indexItensValidos = 0;
    filtros.forEach((filtro) => {
        if (validarFiltro(filtro)) {
            indexItensValidos++
            if (indexItensValidos > 1) {
                result += `;`
            }
            if (filtro.operacao === 'entre') {
                const valorInicial = formatarValorFiltroParaPesquisaRsql(filtro.valueInicial)
                const valorFinal = formatarValorFiltroParaPesquisaRsql(filtro.valueFinal)
                result += `${filtro.name}>="${valorInicial}";${filtro.name}<="${valorFinal}"`
            } else if (filtro.operacao === '=contains=') {
                result += `${filtro.name}${filtro.operacao}"*${(formatarValorFiltroParaPesquisaRsql(filtro.value))}*"`
            } else {
                result += `${filtro.name}${filtro.operacao}"${(formatarValorFiltroParaPesquisaRsql(filtro.value))}"`
            }
        }
    })
    return result
}


export function buscarDescricaoFiltro(filtro) {
    let result = ''

    filtro && filtro.forEach(item => {
        if (item.label && item.operacaoLabel && (item.value || item.valueInicial)) {
            result += `${item.label} ${item.operacaoLabel.toLowerCase()} "${buscarDescricaoItem(item)}"\\n`
        }
    })

    return result.charAt(0).toUpperCase() + result.slice(1);
}


function buscarDescricaoItem(item) {
    let result = item.value

    if (item.type === "date" && item.value && isValid(parseISO(item.value))) {
        result = format(parseISO(item.value), 'dd/MM/yyyy')
    }

    if (item.optionSelect) {
        item.optionSelect.forEach(option => {
            if (option.value === item.value) {
                result = option.label
            }
        })
    } else if (item.operacao === 'entre') {
        let valueInicial = item.valueInicial
        if (valueInicial && isValid(parseISO(valueInicial))) {
            valueInicial = format(parseISO(valueInicial), 'dd/MM/yyyy')
        }
        let valueFinal = item.valueFinal
        if (valueFinal && isValid(parseISO(valueFinal))) {
            valueFinal = format(parseISO(valueFinal), 'dd/MM/yyyy')
        }
        result = `${valueInicial || ''}" e "${valueFinal || ''}`
    }

    return result
}

function validarFiltro(filtro) {
    return Boolean(filtro.operacao && ((filtro.value || filtro.value === 0) || ((filtro.valueInicial || filtro.valueInicial === 0) && (filtro.valueFinal || filtro.valueFinal === 0))))
}

function formatarValorFiltroParaPesquisaRsql(value) {
    if (value) {
        return value.toString().trim()
    } else {
        return value
    }

}