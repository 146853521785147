import React, { Component } from 'react';
import * as Yup from "yup";
import Modal from '../../../../../../../components/Modal';
import Grid from '../../../../../../../components/Grid';
import { withRouter } from 'react-router';
import ButtonNovo, { estadosBotaoNovo } from '../../../../../../../components/Button/ButtonNovo';
import ButtonSalvar, { estadosBotaoSalvar } from '../../../../../../../components/Button/ButtonSalvar';
import ButtonCancelar, { estadosBotaoCancelar } from '../../../../../../../components/Button/ButtonCancelar';
import ButtonExcluir from '../../../../../../../components/Button/ButtonExcluir';
import { mensagensDeValidacao } from '../../../../../../../common/constantes/mensagens';
import autoBind from 'react-autobind';
import { withFormik, Field } from 'formik';
import InputField from '../../../../../../../components/Input/InputField';
import { helpCorretoraConta } from '../help';
import AutoProgressBar from '../../../../../../../components/Loading/AutoProgressBar';
import { keyFilterConsultaRsql } from '../../../../../../../common/rsql';
import Form from '../../../../../../../components/Form';
import FormActions from '../../../../../../../components/FormActions';
import FormContent from '../../../../../../../components/FormContent';
import { gerarUUID } from '../../../../../../../common/manipulacaoDeString';
import { estadosCadastro } from '../../../../../../../common/constantes/autorizacao';

const initialValue = {
    codigo: null,
    descricao: null,
};

class CorretoraConta extends Component {

    constructor(props) {
        super(props);
        autoBind(this);

        this.state = {
            inserindoNovoRegistro: false,
        }
    }

    componentDidMount() {
        this.setState({ inserindoNovoRegistro: !this.props.registroSelecionado });
    }

    cancelar() {
        this.props.dirty ? this.props.resetForm(initialValue) : this.props.onHide();
    }

    async salvar(_, novoOnSuccess) {
        this.props.handleSubmit();
        if (this.props.isValid) {
            if (this.state.inserindoNovoRegistro) {
                this.criarRegistro(novoOnSuccess)
            } else {
                this.atualizarCorretoraConta(novoOnSuccess)
            }
        }
    }

    novo() {
        this.setState({ inserindoNovoRegistro: true })
        this.props.resetForm({ values: initialValue })
    }

    criarRegistro(novoOnSuccess) {
        const dadosFormulario = this.props.values;
        this.props.inserirCorretoraConta({ ...dadosFormulario, idTemporario: gerarUUID() }, novoOnSuccess)
    }

    atualizarCorretoraConta(novoOnSuccess) {
        const dadosFormulario = this.props.values;
        this.props.resetForm({ values: initialValue })
        this.props.atualizarCorretoraConta(dadosFormulario, novoOnSuccess);
    }

    excluir() {
        const dadosFormulario = this.props.values;
        this.props.excluirRegistro(dadosFormulario)
        this.props.onHide()
    }

    render() {

        const {
            visible,
            onHide,
            dirty,
            values,
            isModal,
            insertJustOne,
            podeEditarCorretora,
        } = this.props;

        const informacoesPermissoes = {
            estadoCadastro: this.props.values.id ? estadosCadastro.EDICAO : estadosCadastro.INCLUSAO,
            podeInserir: this.props.podeEditar,
            podeEditar: this.props.podeEditar,
            podeExcluir: this.props.podeEditar,
        };

        const estadoBotaoNovo = dirty ? estadosBotaoNovo.SALVAR_E_NOVO : estadosBotaoNovo.NOVO;

        const onClickNovo = dirty ? (e) => this.salvar(e, this.novo) : this.novo;


        return (
            <Modal
                header={values.id || values.idTemporario ? "Editar conta" : "Cadastrar nova conta"}
                visible={visible}
                onHide={() => {
                    onHide(values)
                }}
            >
                <AutoProgressBar />
                <Form>
                    <FormActions>
                        <ButtonCancelar
                            hidden={isModal && values.id && !dirty}
                            estadoBotao={dirty ? estadosBotaoCancelar.CANCELAR : estadosBotaoCancelar.VOLTAR}
                            onClick={this.cancelar}
                        />
                        <ButtonSalvar
                            estadoBotao={estadosBotaoSalvar.SALVAR}
                            disabled={!dirty}
                            onClick={this.salvar}
                        />
                        <ButtonNovo
                            onClick={onClickNovo}
                            hidden={(!dirty && !values.id) || insertJustOne}
                            estadoBotao={estadoBotaoNovo}
                            podeInserir={!podeEditarCorretora}
                        />
                        <ButtonExcluir
                            hidden={!values.idTemporario && !values.id}
                            onClick={this.excluir}
                            podeExcluir={!podeEditarCorretora}
                        />
                    </FormActions>
                    <FormContent>
                        <Grid>
                            <Field sm="12" md="3" lg="3" xl="3"
                                component={InputField}
                                label="Conta"
                                size={20}
                                obrigatorio
                                onChange={e => this.props.setFieldValue('codigo', e.target.value?.replace(/\D+/g, ''))}
                                keyfilter={keyFilterConsultaRsql}
                                name="codigo"
                                helpMessage={helpCorretoraConta.codigo}
                                {...informacoesPermissoes}
                            />
                            <Field sm="12" md="9" lg="9" xl="9"
                                component={InputField}
                                label="Descrição da conta"
                                name="descricao"
                                helpMessage={helpCorretoraConta.descricao}
                                size={255}
                                {...informacoesPermissoes}
                            />
                        </Grid>
                    </FormContent>
                </Form>
            </Modal>
        )
    }
}
CorretoraConta = withFormik({
    enableReinitialize: true,
    validateOnChange: false,

    mapPropsToValues(props) {
        if (props.registroSelecionado) {
            return props.registroSelecionado
        } else {
            return initialValue;
        }
    },

    validationSchema: Yup.object().shape({
        codigo: Yup.number().nullable().required(mensagensDeValidacao.OBRIGATORIO).positive("Não é permitido valores negativos"),
    }),
    handleSubmit: () => { }
})(CorretoraConta);

export default withRouter(CorretoraConta);
