import { services } from "../../../../common/constantes/api";
import { del, get, post, put, exibirToast } from "../../../../common/requisicoes";

export async function asyncDeletePessoa(idPessoa, onSuccess, onError) {
    await del(`${services.GESTOR}/v1/pessoas/${idPessoa}`, null, exibirToast(onSuccess, "Pessoa excluída com sucesso"), onError);
}

export async function asyncGetPesquisaPessoas(url, onSuccess, onError) {
    return await get(url, null, onSuccess, onError, true)
}

export async function asyncCreatePessoa(pessoa, onSuccess, onError) {
    await post(`${services.GESTOR}/v1/pessoas`, pessoa, null, exibirToast(onSuccess, "Pessoa criada com sucesso"), onError);
}

export async function asyncUpdatePessoa(pessoa, onSuccess, onError) {
    await put(`${services.GESTOR}/v1/pessoas/${pessoa.id}`, pessoa, null, exibirToast(onSuccess, "Pessoa atualizada com sucesso"), onError);
}

export async function asyncGetPessoa(idPessoa, onSuccess, onError) {
    await get(`${services.GESTOR}/v1/pessoas/${idPessoa}`, null, onSuccess, onError);
}

export async function asyncGetPessoaFisica(pessoa, onSuccess, onError) {
    await get(`${services.GESTOR}/v1/pessoas?query=pessoaFisica.cpf=contains="*${pessoa}*"`, null, onSuccess, onError);
}

export async function asyncGetPessoaJuridica(pessoa, onSuccess, onError) {
    await get(`${services.GESTOR}/v1/pessoas?query=pessoaJuridica.cnpj=contains="*${pessoa}*"`, null, onSuccess, onError);
}

export async function asyncGetPessoaEstrangeira(identificacao, onSuccess, onError) {
    await get(`${services.GESTOR}/v1/pessoas?query=pessoaEstrangeira.identificacao==${identificacao}`, null, onSuccess, onError);
}