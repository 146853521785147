import React, { Component } from 'react';
import propTypes from 'prop-types'


class LayoutMenuSuperior extends Component {
    render() {
        return (
            <div
                id={this.props.id}
                style={{ paddingTop: this.props.isModal ? '0px' : '45px' }}
                className={this.props.className}
            >
                {this.props.children}
            </div>
        );
    }
}

LayoutMenuSuperior.LropTypes = {
    /** Identificador do componente */
    id: propTypes.string,
    /** Define se o componente é modal */
    isModal: propTypes.bool,
    /** Nome da classe do componente */
    className: propTypes.any,
    /** Filhos do componente */
    children: propTypes.any,
}


export default LayoutMenuSuperior;