import React, { Component } from 'react';
import Chart from "chart.js";
import { Card } from 'primereact/card';
import autoBind from 'react-autobind';
import { asyncImprimirReceitasPorCategorias, buscarReceitasPorCategorias } from './requests';
import { ProgressBar } from 'primereact/progressbar';
import { formatarMonetario } from '../../../../../../../common/mascara';
import 'chartjs-plugin-datalabels';
import NenhumaInformacaoEncontrada from '../../../NenhumaInformacaoEncontrada'

import '../../styles/index.css';
import FalhaAoCarregarAsInformacoes from '../../../FalhaAoCarregarAsInformacoes';
import If from '../../../../../../../components/If';
import { baixarArquivo } from '../../../../../../../common/relatorios';
import { FaPrint } from 'react-icons/fa';

const cardHeader = {
    padding: '14px',
    display: 'flex',
    justifyContent: 'space-between'
}

const styleBotaoImprimir = {
    fontWeight: 'bold',
    fontSize: '15px',
    cursor: 'pointer'
}

let receitasPorCategoria;

export default class GraficoReceitasPorCategoria extends Component {
    graficoDeReceitaAgrupadoPorCategoria = React.createRef();

    constructor(props) {
        super(props);

        autoBind(this);

        this.state = {
            loading: true,
            error: false,
            categorias: [],
            valorTotal: [],
            cores: []
        }
    }

    componentDidMount() {
        this.carregarInformacoes();
    }

    componentDidUpdate(prevProps) {
        const { dataInicial, dataFinal } = this.props;

        if (prevProps.dataInicial !== dataInicial || prevProps.dataFinal !== dataFinal) {
            this.carregarInformacoes();
        }

        if (prevProps.atualizarGraficoNovamente !== this.props.atualizarGraficoNovamente) {
            this.carregarInformacoes(false);
        }
    }

    carregarInformacoes(exibirLoading = true) {
        const { dataInicial, dataFinal } = this.props;

        this.setState({ loading: exibirLoading, error: false }, () => {
            buscarReceitasPorCategorias(dataInicial, dataFinal, ({ data: receitasPorCategoria }) => {
                const loading = false;
                const error = false;

                let categorias = [];
                let cores = [];
                let valorTotal = [];

                receitasPorCategoria.filter(e => e.valorTotal > 0).forEach(elemento => {
                    categorias.push(elemento.categoria.nome);
                    cores.push(elemento.categoria.aparencia);
                    valorTotal.push(elemento.valorTotal);

                });

                this.setState({ categorias, cores, valorTotal, loading, error }, () => {
                    this.buildChart();
                });
            }, () => {
                this.setState({ error: true, loading: false })
            });
        })
    }

    buildChart = () => {
        if (!this.graficoDeReceitaAgrupadoPorCategoria.current) {
            return
        }
        const myChartRef = this.graficoDeReceitaAgrupadoPorCategoria.current.getContext("2d");

        if (typeof receitasPorCategoria !== "undefined") receitasPorCategoria.destroy(); //Necessário para atualizar o gráfico sem bugs
        receitasPorCategoria = new Chart(myChartRef, {
            type: 'doughnut',
            data: {
                datasets: [{
                    data: this.state.valorTotal,
                    backgroundColor: this.state.cores,
                }],
                labels: this.state.categorias,


            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                aspectRatio: 1.1,
                legend: {
                    position: 'bottom',
                    labels: {
                        usePointStyle: true
                    },
                    onHover: function (e) {
                        e.target.style.cursor = 'pointer';
                    },
                },
                onHover: function (e) {
                    var point = this.getElementAtEvent(e);
                    if (point.length) {
                        e.target.style.cursor = 'pointer';
                    } else {
                        e.target.style.cursor = 'default';
                    }
                },
                plugins: {
                    datalabels: {
                        color: "#fff",
                        font: {
                            size: 11
                        },
                        formatter: (value, ctx) => {
                            let total = 0;
                            let dataArr = ctx.chart.data.datasets[0].data;
                            dataArr.forEach(data => { total += data; });
                            return (value * 100 / total).toFixed(1) + "%";
                        },
                    }
                },
                tooltips: {
                    callbacks: {
                        label: (tooltipItem, data) => {
                            return formatarMonetario(data.datasets[0].data[tooltipItem.index]);
                        },
                        title: (tooltipItem, data) => {
                            const label = data.labels[tooltipItem[0].index];
                            let total = 0;
                            let dataArr = data.datasets[0].data;
                            dataArr.forEach(data => total += data);
                            const value = dataArr[tooltipItem[0].index];
                            return label + " (" + (value * 100 / total).toFixed(1) + "%)";
                        }
                    }
                }
            }
        });
    }

    getHeaderCard() {
        return (
            <div style={cardHeader}>
                <label
                    title="Lista o total de receitas realizadas e previstas de cada categoria no período informado"
                    style={{ fontSize: '17px', color: '#333333' }}
                >
                    Receitas por categoria
                </label>
                <span style={{ marginTop: '3px' }}>
                    <If test={this.state.categorias && this.state.categorias.length > 0}>
                        <span
                            title="Imprimir receitas por categorias"
                            style={styleBotaoImprimir}
                            onClick={() => asyncImprimirReceitasPorCategorias(this.props.dataInicial, this.props.dataFinal, document => {
                                baixarArquivo(document.data, "Receitas por categorias.pdf");
                            })}
                        >
                            <FaPrint size="15px" />
                        </span>
                    </If>
                </span>
            </div>
        );
    }

    render() {
        const { loading, categorias } = this.state


        if (loading) {
            return (
                <Card header={this.getHeaderCard()} style={{ borderRadius: '5px', height: '100%' }}>
                    <ProgressBar mode="indeterminate" style={{ height: '3px' }} />
                </Card>
            )
        } else if (this.state.error) {
            return (
                <Card header={this.getHeaderCard()} style={{ borderRadius: '5px', height: '100%' }}>
                    <FalhaAoCarregarAsInformacoes />
                </Card>
            )
        } else if (categorias.length === 0) {
            return (
                <Card header={this.getHeaderCard()} style={{ borderRadius: '5px', height: '100%', }}>
                    <NenhumaInformacaoEncontrada />
                </Card>
            )
        } else {
            return (
                <Card className="card-canvas-dashboard-financas" header={this.getHeaderCard()} style={{ borderRadius: '5px', height: '100%' }}>
                    <canvas id="graficoDeReceitaAgrupadoPorCategoria" ref={this.graficoDeReceitaAgrupadoPorCategoria} />
                </Card>
            )
        }
    }
}
