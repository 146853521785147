const regexPlaca = /^[a-zA-Z]{3}[0-9]{4}$/;
const regexPlacaMerc = /^[a-zA-Z]{3}[0-9]{1}[a-zA-Z]{1}[0-9]{2}$/;

export function validarPlaca(placa) {
    if (regexPlaca.test(placa)) {
        return true
    } else if (regexPlacaMerc.test(placa)) {
        return true
    }
    return false

}