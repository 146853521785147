import React, { Component } from 'react'
import { withFormik, Field } from 'formik';
import * as Yup from 'yup';
import { mensagensDeValidacao } from '../../../../common/constantes/mensagens'
import FormGroup from '../../../../components/FormGoup/index';
import Grid from '../../../../components/Grid/index';
import Button from '../../../../components/Button'
import PasswordField from '../../../../components/Input/InputPassword';
import { notify } from '../../../../components/Toast';
import { asyncAtualizarSenha } from './requests'
import { fazerLogin, buscarDadosLoginLocalStorage, fazerLogoutLocalStorange } from '../../../../common/autenticacao';
import Col from '../../../../components/Col';
import AutoProgressBar from '../../../../components/Loading/AutoProgressBar';
import autoBind from 'react-autobind'

const initialValues = {
	senhaAtual: '',
	senhaNova: '',
	confirmacaoSenha: ''
}

class AlterarSenha extends Component {

	constructor(props) {
		super(props);
		autoBind(this)
	}

	async salvar() {
		const {handleSubmit, validateForm, values, handleReset, onHide} = this.props

		await validateForm()
		await handleSubmit()
		if (this.props.isValid) {
			await asyncAtualizarSenha(values, () => {

				const dadosLogin = buscarDadosLoginLocalStorage()
				fazerLogin(dadosLogin.email, values.senhaNova, dadosLogin.manterConectado, () => {
					onHide()
				}, () => {
					notify('Não foi possível efetuar login com a nova senha!')
					fazerLogoutLocalStorange()
				})
				handleReset();
			})
		}
	}

	render() {

		return (
			<FormGroup>
				<AutoProgressBar/>
				<Grid>
					<Col>
						<Field lg='12' xl='12'
							colStyle={{ padding: 0 }}
							component={PasswordField}
							label='Senha atual'
							feedback={false}
							name="senhaAtual"
						/>
					</Col>
					<Col>
						<Field lg='12' xl='12'
							colStyle={{ padding: 0 }}
							component={PasswordField}
							label='Nova senha'
							feedback={false}
							name="senhaNova"
						/>
					</Col>
					<Col>
						<Field lg='12' xl='12'
							colStyle={{ padding: 0 }}
							component={PasswordField}
							label='Confirmação da nova senha'
							name="confirmacaoSenha"
							feedback={false}
						/>
					</Col>
					<Col>
						<Button
							type='button'
							label='Confirmar'
							icon='fa fa-key'
							width='140px'
							onClick={this.salvar}
						/>
					</Col>
				</Grid>
			</FormGroup>
		)
	}
}

AlterarSenha = withFormik({
	validateOnChange: false,
	mapPropsToValues(props) {
		return initialValues
	},

	validate: (e) => {
		let errors = {}
		if (e.senhaNova !== e.confirmacaoSenha) {
			errors.confirmacaoSenha = 'As senhas não coincidem!'
		}
		return errors
	},

	validationSchema: Yup.object().shape({
		senhaAtual: Yup.string().nullable().required(mensagensDeValidacao.OBRIGATORIO).test('len', 'A senha deve ter no mínimo 8 caracteres', val => val && val.length >= 8),
		senhaNova: Yup.string().nullable().required(mensagensDeValidacao.OBRIGATORIO).test('len', 'A senha deve ter no mínimo 8 caracteres', val => val && val.length >= 8),
		confirmacaoSenha: Yup.string().nullable().required(mensagensDeValidacao.OBRIGATORIO).test('len', 'A senha deve ter no mínimo 8 caracteres', val => val && val.length >= 8)
	}),

	handleSubmit: () => { },

})(AlterarSenha);

export default AlterarSenha