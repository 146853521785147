import React, { Component } from 'react';
import { Card } from 'primereact/card';
import autoBind from 'react-autobind';
import { buscarReceitasPrevistas } from './requests';
import { ProgressBar } from 'primereact/progressbar';
import { formatarMonetario } from '../../../../../../../common/mascara';
import { format, parse } from 'date-fns';
import { Button } from 'primereact/button';
import { usuarioPossuiPermissao } from '../../../../../../../common/autenticacao';
import { recursos, permissoes } from '../../../../../../../common/constantes/autorizacao';
import NenhumaInformacaoEncontrada from '../../../NenhumaInformacaoEncontrada';

import './styles/index.css';
import { Link } from 'react-router-dom';
import If from '../../../../../../../components/If';
import ModalRecebimentoTable from '../../../../../../financas/ContasReceber/components/ModalRecebimentoTable';
import FalhaAoCarregarAsInformacoes from '../../../FalhaAoCarregarAsInformacoes';
import { Financas } from '../../../../../../util/constantes'

const Divider = () => {
    return <hr id="divider-previsoes-receitas" />
}

const List = props => {
    return (
        <div id="previsoes-receitas-content" className='dashboard-scroll'>
            <ul style={{ listStyle: "none", padding: '0px 3px', margin: '0px' }}>
                {props.children}
            </ul>
        </div>
    );
}

const ListItem = props => {
    return (
        <li style={{ margin: '6px 0px' }}>
            <div className="p-grid" style={{ margin: '0px 6px', }}>
                <span id="previsoes-receitas-title" title={props.descricao}>
                    {props.itemRepeticao && props.quantidadeRepeticoes ? `${props.descricao || "Nenhuma descrição informada"} (${props.itemRepeticao}/${props.quantidadeRepeticoes})` : `${props.descricao || "Nenhuma descrição informada"}`}
                </span>
            </div>
            <div className="p-grid p-align-center vertical-container" style={{ margin: '0px' }}>
                <div className="p-col" style={{ padding: '0px 6px' }}>
                    <p id="previsoes-receitas-coluna-data">
                        {format(parse(props.vencimento, 'yyyy-MM-dd', new Date()), 'dd/MM/yyyy')}
                    </p>
                </div>
                <div className="p-col" id="previsoes-receitas-coluna-valor" style={{color: `${Financas.cores.verde}`}}>
                    {formatarMonetario(props.valor)}
                </div>
                {props.podeEfetuarRecebimento ? (
                    <div className="p-col-fixed" style={{ padding: '0px 6px', color: Financas.cores.vermelho }}>
                        <div id="previsoes-receitas-button-content">
                            <Button
                                className="p-button p-button-secondary"
                                icon="fa fa-check"
                                title="Efetuar recebimento da receita"
                                onClick={props.efetuarPagamento}
                            />
                        </div>
                    </div>
                ) : null}
            </div>
        </li>
    );
}

export default class PrevisoesDeReceitas extends Component {

    constructor(props) {
        super(props);

        autoBind(this);

        this.state = {
            loading: true,
            error: false,
            receitasPrevistas: [],
            registroSelecionado: null,
            exibirModalConfirmarRecebimento: false,
            podeVisualizarContasReceber: usuarioPossuiPermissao(recursos.FINANCAS_CONTAS_RECEBER, permissoes.VISUALIZAR),
            podeEditarContasReceber: usuarioPossuiPermissao(recursos.FINANCAS_CONTAS_RECEBER, permissoes.EDITAR)
        }
    }

    componentDidMount() {
        this.carregarInformacoes();
    }

    componentDidUpdate(prevProps) {
        const { dataInicial, dataFinal } = this.props;

        if (prevProps.dataInicial !== dataInicial || prevProps.dataFinal !== dataFinal) {
            this.carregarInformacoes();
        }

        if (prevProps.atualizarGraficoNovamente !== this.props.atualizarGraficoNovamente) {
            this.carregarInformacoes(false);
        }
    }

    carregarInformacoes(exibirLoading = true) {
        const { dataInicial, dataFinal } = this.props;

        this.setState({ loading: exibirLoading, error: false }, () => {
            buscarReceitasPrevistas(dataInicial, dataFinal, ({ data: receitasPrevistas }) => {
                this.setState({ receitasPrevistas, loading: false, error: false });
            }, () => {
                this.setState({ error: true, loading: false });
            });
        })

    }

    getHeaderCard() {
        return (
            <div id="card-header-previsoes-receitas">
                <label title="Lista as receitas pendentes para o período informado">
                    Receitas pendentes do período
                </label>
            </div>
        );
    }

    onHideModalRecebimentoTable(atualizou) {
        if (atualizou) {
            this.setState({ exibirModalConfirmarRecebimento: false });
            this.props.atualizarGraficos();
        } else {
            this.setState({ exibirModalConfirmarRecebimento: false });
        }
    }

    onEfetuarPagamento(receitaPrevista) {
        this.setState({
            exibirModalConfirmarRecebimento: true,
            registroSelecionado: { ...receitaPrevista, valorAReceber: receitaPrevista.valor }
        })
    }

    render() {
        if (this.state.loading) {
            return (
                <Card header={this.getHeaderCard()} style={{ borderRadius: '5px', height: '100%' }}>
                    <ProgressBar mode="indeterminate" style={{ height: '3px' }} />
                </Card>
            )
        } else if (this.state.error) {
            return (
                <Card header={this.getHeaderCard()} style={{ borderRadius: '5px', height: '100%' }}>
                    <FalhaAoCarregarAsInformacoes />
                </Card>
            )
        } else {
            const { receitasPrevistas, exibirModalConfirmarRecebimento, registroSelecionado } = this.state;
            return (
                <>
                    <Card header={this.getHeaderCard()} style={{ borderRadius: '5px', height: '100%' }}>
                        {receitasPrevistas.length > 0 ? (
                            <>
                                <List>
                                    {receitasPrevistas.map((receitaPrevista, idx, array) => {
                                        return (
                                            <React.Fragment key={receitaPrevista.id}>
                                                <ListItem
                                                    id={receitaPrevista.id}
                                                    descricao={receitaPrevista.descricao}
                                                    vencimento={receitaPrevista.vencimento}
                                                    valor={receitaPrevista.valor}
                                                    quantidadeRepeticoes={receitaPrevista.quantidadeRepeticoes}
                                                    itemRepeticao={receitaPrevista.itemRepeticao}
                                                    efetuarPagamento={() => this.onEfetuarPagamento(receitaPrevista)}
                                                    podeEfetuarRecebimento={this.state.podeEditarContasReceber && this.state.podeVisualizarContasReceber}
                                                />
                                                {++idx !== array.length ? <Divider /> : null}
                                            </React.Fragment>
                                        )
                                    })}
                                </List>
                                <Divider />
                                {this.state.podeVisualizarContasReceber && (
                                    <div style={{ textAlign: 'center', paddingTop: '10px' }}>
                                        <Link to={{ pathname: "/contas_receber", state: { dataInicial: this.props.dataInicial, dataFinal: this.props.dataFinal } }}>
                                            Listar receitas
                                        </Link>
                                    </div>
                                )}
                            </>
                        ) : <NenhumaInformacaoEncontrada />}
                    </Card>
                    <If test={exibirModalConfirmarRecebimento}>
                        <ModalRecebimentoTable
                            registroSelecionado={registroSelecionado}
                            visible={exibirModalConfirmarRecebimento}
                            onHide={this.onHideModalRecebimentoTable}
                        />
                    </If>
                </>
            )
        }
    }
}
