import React, { Component } from 'react'
import propTypes from 'prop-types';
import autoBind from 'react-autobind'
import Joyride, { STATUS, ACTIONS, LIFECYCLE } from 'react-joyride';

class Tutorial extends Component {

    constructor(props) {
        super(props)
        autoBind(this)

        this.state = {
            stepIndex: 0
        }
    }


    callback(e) {

        if (e.status === STATUS.SKIPPED) {
            this.props.onHide(e)
        }


        if ([STATUS.FINISHED].includes(e.status) || [ACTIONS.CLOSE].includes(e.action)) {
            this.props.onHide(e)
            this.setState({ stepIndex: 0 })
        } else {

            if (e.action === ACTIONS.NEXT && e.lifecycle === LIFECYCLE.COMPLETE)
                this.setState({ stepIndex: e.index + 1 })

            if (e.action === ACTIONS.PREV && e.lifecycle === LIFECYCLE.COMPLETE)
                this.setState({ stepIndex: e.index - 1 })
        }
    };

    getSteps() {

        let result = this.props.steps.map(step => {

            const res = { ...step }

            if (res.placement === undefined) {
                res.placement = 'auto'
            }

            if (res.disableBeacon === undefined) {
                res.disableBeacon = true
            }

            return res
        })

        //pular etapa caso não classe ou id não esteja na dom
        return result.filter((item) => {
            let target = item.target.replace('.', '');
            target = target.replace('#', '');

            if (target === 'body' || document.getElementById(target) != null || document.getElementsByClassName(target).length > 0) {
                return item;
            }
            return null
        })
    }

    render() {

        const locale = {
            back: 'Voltar',
            close: 'Fechar',
            last: 'Pronto!',
            next: 'Próximo',
            skip: 'Pular'
        }

        return (
            <Joyride
                disableCloseOnEsc
                disableOverlayClose
                {...this.props}
                locale={locale}
                scrollOffset={50}
                callback={this.callback}
                run={this.props.visible}
                stepIndex={this.state.stepIndex}
                steps={this.getSteps()}
                styles={{
                    options: {
                        primaryColor: '#006095',
                        zIndex: 10000,
                    }, overlay: {
                        zIndex: 10000,
                    },
                }}
            />
        )
    }
}

Tutorial.propTypes = {
    visible: propTypes.bool,
}

export default Tutorial
