import React, { Component } from 'react';
import { Card } from 'primereact/card';
import Col from '../../../../../components/Col';
import Grid from '../../../../../components/Grid';
import { Button } from 'primereact/button';
import autoBind from 'react-autobind';
import { usuarioPossuiPermissao } from '../../../../../common/autenticacao';
import { recursos, permissoes } from '../../../../../common/constantes/autorizacao';
import cei from "./images/cei.svg";
import If from '../../../../../components/If';

const styleTipoImportacao = {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    padding: '0px 0px 0px 6px',
    fontSize: '20px',
    color: '#333333',
    fontWeight: 'bold'
};

const styleBotaoOpcoesCard = {
    padding: '0px',
    display: 'flex',
    justifyContent: 'flex-end'
};

const styleSubtitleImportacao = {
    display: 'grid',
    fontSize: '13px',
    color: '#333333a6',
    fontWeight: 'bold',
    padding: '3px 0px 0px 6px'
};

const styleButton = {
    borderRadius: '50%',
    padding: '8px',
    width: '30px',
    height: '30px',
    margin: '2px'
};

const styleLogoImportacao = {
    display: 'flex',
    height: '44px',
    margin: '-6px -11px 11px'
}

class CardImportacao extends Component {

    constructor(props) {

        super(props);

        autoBind(this);

        this.state = {
            podeExcluir: usuarioPossuiPermissao(recursos.INVESTIDOR_IMPORTACOES, permissoes.EXCLUIR),
            podeEditar: usuarioPossuiPermissao(recursos.INVESTIDOR_IMPORTACOES, permissoes.EDITAR),
        }
    }

    render() {
        const { tipo, usuario } = this.props.importacao
        const { podeExcluir } = this.state

        return (
            <Col col='12' sm='12' md='4' lg='4' xl='4'>
                <Card
                    style={{ borderRadius: '5px' }}
                >
                    <Grid >
                        <Col col='8' lg='9' style={styleTipoImportacao}>
                            <If test={tipo}>
                                <Col col="12" style={styleLogoImportacao}>
                                    <img
                                        alt="CEI"
                                        style={{ width: '100' }}
                                        src={cei}
                                    />
                                </Col>
                            </If>
                        </Col>
                        <Col col='4' lg='3' style={styleBotaoOpcoesCard}>
                            <Button
                                title="Sincronizar negociações"
                                className="p-button-primary"
                                style={styleButton}
                                icon="fa fa-refresh"
                                onClick={() => this.props.importar(this.props.importacao)}
                            />
                            <Button
                                title="Editar importação"
                                className="p-button-primary"
                                style={styleButton}
                                icon="fa fa-pencil"
                                onClick={() => this.props.editarimportacao(this.props.importacao)}
                            />
                            <If test={podeExcluir}>
                                <Button
                                    style={styleButton}
                                    className="p-button p-button-danger"
                                    icon="fa fa-trash"
                                    title="Excluir importação"
                                    onClick={() => this.props.excluirimportacao(this.props.importacao)}
                                />
                            </If>
                        </Col>
                    </Grid>
                    <Grid justifyEnd>
                        <Col col='12' style={styleSubtitleImportacao}>
                            <span
                                title={usuario}>{`Usuário: ${usuario}`}
                            </span>
                            <span
                                title='Senha'>{`Senha: ••••••••••`}
                            </span>
                        </Col>
                    </Grid>
                </Card>
            </Col>
        )
    }
}
export default CardImportacao;
