import React, {Component} from 'react';
import MultipleSelect from '../MultipleSelect';
import autoBind from "react-autobind";
import propTypes from 'prop-types'

class MultipleSelectSetores extends Component {
    constructor(props) {
        super(props);
        autoBind(this);
    }

    buscarUrlPesquisa(pesquisa, page) {
        return `${this.props.url}?query=nome=contains="*${pesquisa}*"&page=${page}&size=50&sort=nome`;
    }

    setarNovoRegistroNoSelect(novoRegistro) {
        if (novoRegistro) {
            this.props.onChange(novoRegistro)
        }
    }

    montarLabel(row) {
        if (row) {
            return row.nome;
        }
    }

    render() {
        return (
            <React.Fragment>
                <MultipleSelect
                    montarLabel={this.montarLabel}
                    buscarUrlPesquisa={this.buscarUrlPesquisa}
                    onChange={this.props.onChange}
                    {...this.props}
                />
            </React.Fragment>
        );
    }
}

MultipleSelectSetores.propTypes = {
    /** Url do servidor em que as requisições de busca deverão ser efetuadas: Ex:  `${services.GESTOR}/v1/usuarios/relacoes/setores`*/
    url: propTypes.string.isRequired
};


MultipleSelectSetores.defaultProps = {
    label: 'Setores ',
};

export default MultipleSelectSetores;