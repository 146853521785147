import { tratarErroRequisicoes } from "../../../../common/tratamentoDeErro/tratamentoDeMensagens";

export function tratarMensagemErroLogin(response) {
    if (!response)
        return 'Erro de conexão com a internet'

    if (response.data && response.data.error_description) {
        return response.data.error_description

    } else {
        tratarErroRequisicoes(response)
    }
}