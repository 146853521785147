/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import propTypes from 'prop-types';
import { container } from './const';

export default function Grid(props) {
    function buscarClassName() {
        let resultado = 'p-grid';

        if (props.gridReverse) {
            resultado += ` ${container.gridReverse}`;
        }
        if (props.nestedGrid) {
            resultado += ` ${container.nestedGrid}`;
        }
        if (props.directionColumn) {
            resultado += ` ${container.directionColumn}`;
        }
        if (props.directionColumnReverse) {
            resultado += ` ${container.directionColumnReverse}`;
        }
        if (props.justifyStart) {
            resultado += ` ${container.justifyStart}`;
        }
        if (props.justifyEnd) {
            resultado += ` ${container.justifyEnd}`;
        }
        if (props.justifyCenter) {
            resultado += ` ${container.justifyCenter}`;
        }
        if (props.justifyBetween) {
            resultado += ` ${container.justifyBetween}`;
        }
        if (props.justifyAround) {
            resultado += ` ${container.justifyAround}`;
        }
        if (props.justifyEven) {
            resultado += ` ${container.justifyEven}`;
        }
        if (props.verticalAlignStart) {
            resultado += ` ${container.verticalAlignStart}`;
        }
        if (props.verticalAlignEnd) {
            resultado += ` ${container.verticalAlignEnd}`;
        }
        if (props.verticalAlignCenter) {
            resultado += ` ${container.verticalAlignCenter}`;
        }
        if (props.verticalAlignStretch) {
            resultado += ` ${container.verticalAlignStretch}`;
        }
        if (props.verticalAlignContainer) {
            resultado += ` ${container.verticalAlignContainer}`;
        }
        if (props.className) {
            resultado += ` ${props.className}`;
        }
        return resultado;
    }

    function buscarStyle() {
        if (props.hidden) {
            return { display: 'none', ...props.style };
        }
        return props.style;
    }

    const { children, id, onClick, title, key } = props;
    const classe = buscarClassName(props).trim();
    const style = buscarStyle();

    return (
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions
        <div
            id={id}
            key={key}
            style={style}
            className={classe}
            onClick={onClick}
            title={title}
        >
            {children}
        </div>
    );
}

Grid.propTypes = {
    /** Ação ao clicar no elemento */
    onClick: propTypes.func,
    /** ID do elemento */
    id: propTypes.string,
    /** Filhos do componente */
    children: propTypes.any,
    /** Estilo do componente */
    style: propTypes.object,
    /** Organiza os itens do grid de traz pra frente */
    gridReverse: propTypes.bool,
    /** Organiza os itens do grid um embaixo do outro como se fosse uma coluna */
    directionColumn: propTypes.bool,
    /** Organiza os itens do grid um embaixo do outro como se fosse uma coluna e traz os itens de baixo para cima */
    directionColumnReverse: propTypes.bool,
    /** Os itens do grid vão da esquerda para a direita (Padrão) */
    justifyStart: propTypes.bool,
    /** Os itens do grid vão da direita para a esqquerda */
    justifyEnd: propTypes.bool,
    /** Os itens do grid são centralizados */
    justifyCenter: propTypes.bool,
    /** O espaçamento entre cada item do grid é o mesmo iniciando pelo componente */
    justifyBetween: propTypes.bool,
    /** O espaçamento entre cada item do grid é o mesmo iniciando pelo espaçamento */
    justifyAround: propTypes.bool,
    /** O espaçamento entre cada item do grid é o mesmo iniciando pelo componente (Com mais espaçamento nas laterais) */
    justifyEven: propTypes.bool,
    /** Os itens são alinhadosverticalmente */
    verticalAlignContainer: propTypes.bool,
    /** Os itens são alinhados de cima para baixo verticalmente */
    verticalAlignStart: propTypes.bool,
    /** Os itens são alinhados de baixo para cima verticalmente */
    verticalAlignEnd: propTypes.bool,
    /** Os itens são alinhados no centro verticalmente */
    verticalAlignCenter: propTypes.bool,
    /** Os itens ocupam todo o espaço disponível verticalmente */
    verticalAlignStretch: propTypes.bool,
    /** Esconde o componente */
    hidden: propTypes.bool,
    /** Nome da classe do componente */
    className: propTypes.string,
    /** Utilizado para quando algum item do grid ocupar mais de uma coluna */
    nestedGrid: propTypes.bool,
};
