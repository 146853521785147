import React, { Component } from 'react';
import { withFormik, Field } from 'formik';
import * as Yup from 'yup';
import ButtonNovo, { estadosBotaoNovo } from '../../../../components/Button/ButtonNovo';
import ButtonCancelar, { estadosBotaoCancelar } from '../../../../components/Button/ButtonCancelar';
import ButtonSalvar, { estadosBotaoSalvar } from '../../../../components/Button/ButtonSalvar';
import ButtonExcluir from '../../../../components/Button/ButtonExcluir';
import { mensagensDeValidacao } from '../../../../common/constantes/mensagens';
import InputField from '../../../../components/Input//InputField';
import Grid from '../../../../components/Grid';
import Dropdown from '../../../../components/Select/Dropdown';
import { recursos, permissoes, estadosCadastro } from '../../../../common/constantes/autorizacao';
import Prompt from '../../../../components/Route/Prompt';
import { buscarDescricaoTamanhoArquivo, validarUUID } from '../../../../common/manipulacaoDeString';
import { atualizarUrl, metodosAtualizarUrl, validarFormulario, voltarParaAPesquisa } from '../../../util';
import autoBind from 'react-autobind';
import { helpPlanosForm } from './help';
import { asyncCreateAdmPlano, asyncDeleteAdmPlano, asyncGetAdmPlano, asyncUpdateAdmPlano } from './requests';
import { usuarioPossuiPermissao } from '../../../../common/autenticacao';
import { confirm } from '../../../../components/Toast';
import FormActions from '../../../../components/FormActions';
import Checkbox from '../../../../components/Input/Checkbox'
import FormContent from '../../../../components/FormContent';
import Form from '../../../../components/Form';
import { connect } from 'react-redux';
import { CADASTROURL, PESQUISAURL } from '../utils/constantes';
import InputMoney from '../../../../components/Input/InputMoney';
import Col from '../../../../components/Col';

const initialValues = {
	id: null,
	descricao: "",
	ilimitado: false,
	tipo: "EXCLUSIVO",
	usuarios: 0,
	armazenamento: 0,
	documentosMensais: 0,
	contasFinanceiras: 0,
	vendasMensais: 0,
	nfeMensais: 0,
	boletosMensais: 0,
	suporteChat: false,
	valor: 0,
};

class AdministracaoPlanosForm extends Component {

	constructor(props) {
		super(props);

		autoBind(this);

		this.state = {
			podeInserir: usuarioPossuiPermissao(recursos.PLANOS_ADMINISTRACAO, permissoes.INSERIR),
			podeEditar: usuarioPossuiPermissao(recursos.PLANOS_ADMINISTRACAO, permissoes.EDITAR),
			podeExcluir: usuarioPossuiPermissao(recursos.PLANOS_ADMINISTRACAO, permissoes.EXCLUIR),
			usuarios: false,
			armazenamento: false,
			documentosMensais: false,
			contasFinanceiras: false,
			vendasMensais: false,
			nfeMensais: false
		};
	}

	async componentDidMount() {
		const id = this.props.match && this.props.match.params.id;
		if (validarUUID(id)) {
			await this.asyncSelectRegistro(id);
		}
		this.loadCheckboxes();
	}

	novo() {
		atualizarUrl(this.props.history, CADASTROURL, null, metodosAtualizarUrl.POP);
		this.props.resetForm({ values: initialValues });
		this.desmarcarCheckboxes();
	}

	desmarcarCheckboxes() {
		this.setState({
			armazenamento: false,
			usuarios: false,
			documentosMensais: false,
			contasFinanceiras: false,
			nfeMensais: false,
			vendasMensais: false
		})
	}

	excluir() {
		confirm('Confirmação', 'Tem certeza que deseja excluir o registro?', async () => {
			await this.asyncDeleteRegistro();
		})
	}

	fecharModal() {
		if (this.props.values.id) {
			this.props.hideModal(this.props.values);
		} else {
			this.props.hideModal();
		}
	}

	async salvar(e, novoOnSuccess) {
		this.props.handleSubmit();

		if (await validarFormulario(this.props)) {
			if (this.props.values.id) {
				await this.asyncUpdateRegistro(this.validarValor(this.props.values), novoOnSuccess);
			} else {
				await this.asyncCreateRegistro(this.validarValor(this.props.values), novoOnSuccess);
			}
			if (this.props.hideModal) {
				this.fecharModal()
			}
		}
	}

	validarValor(values) {
		if (values.valor === null || values.valor < 0) {
			return {
				...values,
				valor: 0
			}
		}
		return values
	}

	cancelar() {
		if (this.props.dirty) {
			this.props.resetForm({ values: this.props.initialValues });
		} else if (this.props.isModal) {
			this.fecharModal()
		} else {
			voltarParaAPesquisa(this.props.history, PESQUISAURL);
		}
	}

	async asyncDeleteRegistro() {
		await asyncDeleteAdmPlano(this.props.values.id, () => {
			this.props.resetForm()
			voltarParaAPesquisa(this.props.history, PESQUISAURL)
		})
	}

	async asyncUpdateRegistro(values, novoOnSuccess) {
		await asyncUpdateAdmPlano(values, () => {
			if (novoOnSuccess) {
				novoOnSuccess()
			} else {
				this.props.resetForm({ values: values });
			}
		})
	}

	async asyncCreateRegistro(values, novoOnSuccess) {
		if (values) {
			await asyncCreateAdmPlano(values, async ({ data: plano }) => {
				if (novoOnSuccess) {
					novoOnSuccess();
				} else {
					this.props.resetForm({ values: { ...values, id: plano.id } });
					if (!this.props.isModal) {
						atualizarUrl(this.props.history, CADASTROURL, plano.id, metodosAtualizarUrl.POP);
					}
				}
			})
		}
	}

	async asyncSelectRegistro(idPlano) {
		await asyncGetAdmPlano(idPlano, ({ data: plano }) => {
			this.props.resetForm({ values: plano })
			if (!this.props.isModal) {
				atualizarUrl(this.props.history, CADASTROURL, plano.id, metodosAtualizarUrl.POP);
			}
		})
	}

	onChangeCampoIlimitado(field, value) {
		this.setState({ [field]: value });

		if (value === true) {
			if (field === "armazenamento") {
				this.props.setFieldValue([field], Number.parseInt("99999999999999999"))
			} else {
				this.props.setFieldValue([field], 999999999)
			}
		} else {
			this.props.setFieldValue([field], this.props.initialValues[field])
		}
	}

	loadCheckboxes() {
		const { values } = this.props;

		if (values.usuarios === 999999999) {
			this.setState({ usuarios: true })
		}
		if (values.documentosMensais === 999999999) {
			this.setState({ documentosMensais: true })
		}
		if (values.contasFinanceiras === 999999999) {
			this.setState({ contasFinanceiras: true })
		}
		if (values.vendasMensais === 999999999) {
			this.setState({ vendasMensais: true })
		}
		if (values.nfeMensais === 999999999) {
			this.setState({ nfeMensais: true })
		}
		if (values.armazenamento === Number.parseInt("99999999999999999")) {
			this.setState({ armazenamento: true })
		}
	}

	render() {
		const { values, dirty, isModal, setFieldValue } = this.props;

		const { armazenamento, usuarios, documentosMensais, contasFinanceiras, nfeMensais, vendasMensais } = this.state;

		const planoIlimitado = values.ilimitado === true;

		const informacoesPermissoes = {
			podeInserir: this.state.podeInserir,
			podeEditar: this.state.podeEditar,
			podeExcluir: this.state.podeExcluir,
			estadoCadastro: this.props.values.id ? estadosCadastro.EDICAO : estadosCadastro.INCLUSAO
		};

		const estadoBotaoNovo = dirty ? estadosBotaoNovo.SALVAR_E_NOVO : estadosBotaoNovo.NOVO;

        const onClickNovo = dirty ? (e) => this.salvar(e, this.novo) : this.novo;

		return (
			<>
				<Prompt dirty={dirty} />
				<Form
					header="Cadastro de plano"
					isModal={isModal}
					className="card-default screen-max-width"
				>
					<FormActions className="screen-max-width">
						<ButtonCancelar
							estadoBotao={dirty ? estadosBotaoCancelar.CANCELAR : estadosBotaoCancelar.VOLTAR}
							onClick={this.cancelar}
							{...informacoesPermissoes}
						/>
						<ButtonSalvar
							estadoBotao={estadosBotaoSalvar.SALVAR}
							disabled={!dirty}
							onClick={this.salvar}
							{...informacoesPermissoes}
						/>
						<ButtonNovo
                            onClick={onClickNovo}
                            hidden={(!dirty && !values.id) || isModal}
                            estadoBotao={estadoBotaoNovo}
                            {...informacoesPermissoes}
                        />
						<ButtonExcluir
							hidden={!values.id}
							onClick={this.excluir}
							{...informacoesPermissoes}
						/>
					</FormActions>
					<FormContent>
						<Grid>
							<Field sm="12" md="12" lg="12" xl="12"
								component={Checkbox}
								label="O plano é ilimitado"
								name="ilimitado"
								helpMessage={helpPlanosForm.ilimitado}
								onChange={e => {
									const { descricao, tipo, valor } = this.props.values
									setFieldValue('ilimitado', e.checked);
									setFieldValue('tipo', tipo);
									setFieldValue('descricao', descricao);
									setFieldValue('valor', valor);
									this.desmarcarCheckboxes();
								}}
								checked={values.ilimitado === true}
								{...informacoesPermissoes}
							/>
							<Field sm="12" md="12" lg="12" xl="12"
								component={Checkbox}
								label="Suporte via chat"
								name="suporteChat"
								disabled={planoIlimitado}
								helpMessage={helpPlanosForm.suporteChat}
								onChange={e => setFieldValue('suporteChat', e.checked)}
								checked={values.suporteChat === true}
								{...informacoesPermissoes}
							/>
							<Field sm="12" md="4" lg="4" xl="4"
								component={InputField}
								label='Descrição'
								name="descricao"
								size={128}
								obrigatorio
								helpMessage={helpPlanosForm.descricao}
								{...informacoesPermissoes}
							/>
							<Field sm="12" md="4" lg="4" xl="4"
								component={Dropdown}
								label='Tipo'
								name="tipo"
								showClear={false}
								onChange={(e) => setFieldValue('tipo', e.value)}
								options={[
									{ label: "Normal", value: "NORMAL" },
									{ label: "Exclusivo", value: "EXCLUSIVO" }
								]}
								{...informacoesPermissoes}
							/>
							<Field sm="12" md="4" lg="4" xl="4"
								component={InputMoney}
								label='Valor'
								name="valor"
								obrigatorio
								size={15}
								min={0}
								helpMessage={helpPlanosForm.valor}
								onChange={e => setFieldValue('valor', e.target.value)}
								value={values.valor}
								{...informacoesPermissoes}
							/>

							<Col sm="6" md="6" lg='6' xl='6'>
								<Field sm="12" md="12" lg="12" xl="12"
									component={Checkbox}
									colStyle={{ padding: '0px 0px 3px 1px' }}
									label="Usuários ilimitados?"
									disabled={planoIlimitado}
									name="usuarios"
									helpMessage={helpPlanosForm.usuarios}
									onChange={(e) => this.onChangeCampoIlimitado('usuarios', e.checked)}
									checked={usuarios}
									{...informacoesPermissoes}
								/>
								<Field sm="12" md="12" lg='12' xl='12'
									component={InputField}
									colStyle={{ padding: '0px' }}
									name="usuarios"
									disabled={planoIlimitado || usuarios}
									type="number"
									size={9}
									min={0}
									{...informacoesPermissoes}
								/>
							</Col>
							<Col sm="6" md="6" lg='6' xl='6'>
								<Field sm="12" md="12" lg="12" xl="12"
									component={Checkbox}
									colStyle={{ padding: '0px 0px 3px 1px' }}
									label="Armazenamento ilimitado?"
									disabled={planoIlimitado}
									name="armazenamento"
									helpMessage={helpPlanosForm.armazenamento}
									onChange={(e) => this.onChangeCampoIlimitado('armazenamento', e.checked)}
									checked={armazenamento}
									{...informacoesPermissoes}
								/>
								<Field sm="12" md="12" lg='12' xl='12'
									component={InputField}
									colStyle={{ padding: '0px' }}
									name="armazenamento"
									warning={(values.armazenamento && values.armazenamento >= 0 && !armazenamento) ? buscarDescricaoTamanhoArquivo(values.armazenamento) : null}
									disabled={planoIlimitado || armazenamento}
									type="number"
									size={18}
									min={0}
									{...informacoesPermissoes}
								/>

							</Col>
							<Col sm="6" md="6" lg='6' xl='6'>
								<Field sm="12" md="12" lg="12" xl="12"
									component={Checkbox}
									colStyle={{ padding: '0px 0px 3px 1px' }}
									label="Documentos ilimitado?"
									name="documentosMensais"
									disabled={planoIlimitado}
									helpMessage={helpPlanosForm.documentosMensais}
									onChange={(e) => this.onChangeCampoIlimitado('documentosMensais', e.checked)}
									checked={documentosMensais}
									{...informacoesPermissoes}
								/>
								<Field sm="12" md="12" lg='12' xl='12'
									component={InputField}
									colStyle={{ padding: '0px' }}
									name="documentosMensais"
									disabled={planoIlimitado || documentosMensais}
									size={9}
									min={0}
									type="number"
									{...informacoesPermissoes}
								/>
							</Col>
							<Col sm="6" md="6" lg='6' xl='6'>
								<Field sm="12" md="12" lg="12" xl="12"
									component={Checkbox}
									colStyle={{ padding: '0px 0px 3px 1px' }}
									label="Contas financeiras ilimitadas?"
									disabled={planoIlimitado}
									name="contasFinanceiras"
									helpMessage={helpPlanosForm.contasFinanceiras}
									onChange={(e) => this.onChangeCampoIlimitado('contasFinanceiras', e.checked)}
									checked={contasFinanceiras}
									{...informacoesPermissoes}
								/>
								<Field sm="12" md="12" lg='12' xl='12'
									component={InputField}
									colStyle={{ padding: '0px' }}
									name="contasFinanceiras"
									disabled={planoIlimitado || contasFinanceiras}
									size={9}
									min={0}
									type="number"
									{...informacoesPermissoes}
								/>
							</Col>
							<Col sm="6" md="6" lg='6' xl='6'>
								<Field sm="12" md="12" lg="12" xl="12"
									component={Checkbox}
									colStyle={{ padding: '0px 0px 3px 1px' }}
									label="Vendas ilimitadas?"
									disabled={planoIlimitado}
									name="vendasMensais"
									helpMessage={helpPlanosForm.vendasMensais}
									onChange={(e) => this.onChangeCampoIlimitado('vendasMensais', e.checked)}
									checked={vendasMensais}
									{...informacoesPermissoes}
								/>
								<Field sm="12" md="12" lg='12' xl='12'
									component={InputField}
									colStyle={{ padding: '0px' }}
									name="vendasMensais"
									disabled={planoIlimitado || vendasMensais}
									size={9}
									min={0}
									type="number"
									{...informacoesPermissoes}
								/>
							</Col>
							<Col sm="6" md="6" lg='6' xl='6'>
								<Field sm="12" md="12" lg="12" xl="12"
									component={Checkbox}
									colStyle={{ padding: '0px 0px 3px 1px' }}
									label="Notas fiscais ilimitadas?"
									disabled={planoIlimitado}
									name="nfeMensais"
									helpMessage={helpPlanosForm.nfeMensais}
									onChange={(e) => this.onChangeCampoIlimitado('nfeMensais', e.checked)}
									checked={nfeMensais}
									{...informacoesPermissoes}
								/>
								<Field sm="12" md="12" lg='12' xl='12'
									component={InputField}
									colStyle={{ padding: '0px' }}
									name="nfeMensais"
									disabled={planoIlimitado || nfeMensais}
									size={9}
									min={0}
									type="number"
									{...informacoesPermissoes}
								/>
							</Col>
						</Grid>
					</FormContent>
				</Form>
			</>
		)
	}
}

AdministracaoPlanosForm = withFormik({
	enableReinitialize: true,
	validateOnChange: false,

	mapPropsToValues() {
		return initialValues
	},

	validationSchema: Yup.object().shape({
		descricao: Yup.string().nullable().required(mensagensDeValidacao.OBRIGATORIO),
		armazenamento: Yup.number().nullable('abc').required(mensagensDeValidacao.OBRIGATORIO),
		usuarios: Yup.number().nullable().required(mensagensDeValidacao.OBRIGATORIO),
		documentosMensais: Yup.number().nullable().required(mensagensDeValidacao.OBRIGATORIO),
		contasFinanceiras: Yup.number().nullable().required(mensagensDeValidacao.OBRIGATORIO),
		vendasMensais: Yup.number().nullable().required(mensagensDeValidacao.OBRIGATORIO),
		nfeMensais: Yup.number().nullable().required(mensagensDeValidacao.OBRIGATORIO),
	}),

})(AdministracaoPlanosForm);

const mapStateToProps = state => ({
	isMobile: state.dispositivo.isMobile,
})

export default connect(mapStateToProps)(AdministracaoPlanosForm);
