import React, { Component } from 'react'
import { Tree as InternalTree } from 'primereact/tree'
import propTypes from 'prop-types'
import { buscarDisabledDeAcordoComAsPermissoes } from '../../common/autorizacao/manipulacaoDeComponentes';
import { renderizarValidacao } from '../../common/tratamentoDeErro/validacoesDeCampos';

export default class Tree extends Component {

    getOnSelectionChange(disabled) {
        if (disabled)
            return e => { }
        return this.props.onSelectionChange
    }

    render() {
        const { podeInserir, podeEditar, estadoCadastro, errors, className } = this.props

        const disabled = buscarDisabledDeAcordoComAsPermissoes(podeInserir, podeEditar, estadoCadastro, this.props.disabled)

        return (
            <>
                <InternalTree
                    className={`${disabled ? 'p-disabled' : undefined} ${className}`}
                    {...this.props}
                    onSelectionChange={this.getOnSelectionChange(disabled)}
                />
                {renderizarValidacao(errors, true)}
            </>
        )
    }
}

Tree.propTypes = {
    /** Identificador do componente */
    id: propTypes.string,
    /** Estado em que o cadastro se encontra*/
    estadoCadastro: propTypes.string,
    /** Diz se o usuário possui permissão de inserir */
    podeVisualizar: propTypes.bool,
    /** Diz se o usuário possui permissão de editar */
    podeEditar: propTypes.bool,
    /** Diz se o usuário possui permissão de excluir */
    podeInserir: propTypes.bool,
    /** Esconde o componente */
    hidden: propTypes.bool,
    /** Estilo do componente */
    style: propTypes.object
}
