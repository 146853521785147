import React from 'react';
import { formatarMonetario } from '../../../../../../../../../common/mascara';
import './index.css'

export default function DescritivoImposto({ descricaoImposto, valorImposto, divider }) {
    return valorImposto > 0 ?
        <tr className="impostos" style={{ justifyContent: 'space-between', display: 'flex' }}>
            <td>{descricaoImposto}</td>
            <td style={{whiteSpace: 'nowrap'}}>{formatarMonetario(valorImposto)}</td>
        </tr> : null
}