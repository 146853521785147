export const papelSignatarios = [
    { label: 'Parte', value: 'PARTE' },
    { label: 'Testemunha', value: 'TESTEMUNHA' },
    { label: 'Solicitante', value: 'SOLICITANTE' },
    { label: 'Avalista', value: 'AVALISTA' },
    { label: 'Contratante', value: 'CONTRATANTE' },
    { label: 'Contratada', value: 'CONTRATADA' },
    { label: 'Cedente', value: 'CEDENTE' },
    { label: 'Cessionário', value: 'CESSIONARIO' },
    { label: 'Devedor solidário', value: 'DEVEDOR_SOLIDARIO' },
    { label: 'Emitente', value: 'EMITENTE' },
    { label: 'Endossante', value: 'ENDOSSANTE' },
    { label: 'Endossatário', value: 'ENDOSSATARIO' },
    { label: 'Gestor', value: 'GESTOR' },
    { label: 'Interveniente', value: 'INTERVENIENTE' },
    { label: 'Parte compradora', value: 'PARTE_COMPRADORA' },
    { label: 'Parte vendedora', value: 'PARTE_VENDEDORA' },
    { label: 'Parte e representante legal', value: 'PARTE_REPRESENTANTE_LEGAL' },
    { label: 'Procurador', value: 'PROCURADOR' },
    { label: 'Representante legal', value: 'REPRESENTANTE_LEGAL' },
    { label: 'Responsável solidário', value: 'RESPONSAVEL_SOLIDARIO' },
    { label: 'Validador', value: 'VALIDADOR' },
    { label: 'Acusar recebimento', value: 'ACUSAR_RECEBIMENTO' },
]
