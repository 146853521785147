import React, { Component } from 'react';
import { formaPagamento } from '../../../../../../../../util/constantes';
import { InternalDropdown } from '../../../../../../../../../../../../components/Select/Dropdown';

class FormaPagamentoMemorizada extends Component {

    render() {
        const { exibirLabels, isMobile, errors, valueFormaPagamento, onChangeFormaPagamento, informacoesPermissoes, disabled} = this.props;

        return (
            <InternalDropdown
                col="12"
                colStyle={{ width: 'calc(100% - 60px)' }}
                label={exibirLabels && !isMobile ? 'Forma de pagamento' : null}
                helpMessage={"Forma de pagamento utilizada. Essa informação é utilizada na Nota Fiscal"}
                name="forma"
                showClear={false}
                value={valueFormaPagamento}
                onChange={onChangeFormaPagamento}
                options={formaPagamento}
                errors={errors}
                disabled={disabled}
                {...informacoesPermissoes}
            />
        )
    }
}

function formaPagamentoMemorizadaSaoIguais(renderAnterior, renderAtual) {
    return renderAnterior.exibirLabels === renderAtual.exibirLabels &&
        renderAnterior.valueFormaPagamento === renderAtual.valueFormaPagamento &&
        renderAnterior.isMobile === renderAtual.isMobile &&
        renderAnterior.errors === renderAtual.errors
}

export default React.memo(FormaPagamentoMemorizada, formaPagamentoMemorizadaSaoIguais);
