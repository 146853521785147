import React, { Component } from 'react';
import Prompt from '../../../../../components/Route/Prompt';
import { removerElementoPorId } from '../../../../../common/array';
import { Column } from 'primereact/column';
import ButtonExcluirTable from '../../../../../components/Button/ButtonExcluirTable';
import ToolbarDetails from '../../../../../components/Toolbar';
import ButtonNovo from '../../../../../components/Button/ButtonNovo';
import autoBind from 'react-autobind';
import ToolbarItensLeft from '../../../../../components/Toolbar/ToolbarItensLeft';
import Modal from '../../../../../components/Modal';
import DocumentoSignatariosForm from './Form';
import If from '../../../../../components/If';
import ButtonEditarTable from '../../../../../components/Button/ButtonEditarTable';
import { papelSignatarios } from './Form/util/constantes';
import Message from '../../../../../components/Message';
import { estadosCadastro } from '../../../../../common/constantes/autorizacao';
import { DataTable } from 'primereact/components/datatable/DataTable';
import NenhumRegistroEncontrado from '../../../../../components/NenhumRegistroEncontrado';

class DocumentoSignatarios extends Component {

    constructor(props) {
        super(props);

        autoBind(this);

        this.state = {
            registroSelecionado: null,
            modalFormVisible: false,
            modalEmailVisible: false
        }
    }

    novo() {
        this.setState({ modalFormVisible: true, registroSelecionado: null })
    }

    onEditar(row) {
        this.setState({ modalFormVisible: true, registroSelecionado: row })
    }

    renderOpcoes(row) {
        const { informacoesPermissoes, disabled } = this.props
        return (
            <React.Fragment>
                <ButtonEditarTable
                    onClick={() => this.onEditar(row)}
                    {...informacoesPermissoes}
                />
                <ButtonExcluirTable
                    onClick={() => this.excluirRegistro(row)}
                    disabled={disabled}
                    podeExcluir={informacoesPermissoes.podeEditar}
                />
            </React.Fragment>
        )
    }

    hideModal() {
        this.setState({ modalFormVisible: false, registroSelecionado: null })
    }

    atualizarRegistro(dadosFormulario) {
        const { value } = this.props;

        const result = value && value.map(item => {
            if (item.idTemporario && item.idTemporario === dadosFormulario.idTemporario) {
                return dadosFormulario
            } else if (item.id && item.id === dadosFormulario.id) {
                return dadosFormulario
            }
            return item
        })

        this.setState({ registroSelecionado: dadosFormulario })
        this.props.onChange(result)

    }

    inserirRegistro(dadosFormulario) {
        this.props.onChange([...this.props.value, dadosFormulario]);
        this.setState({ registroSelecionado: dadosFormulario })
    }

    excluirRegistro(dadosFormulario) {
        let nomeColunaId = 'id'
        if (dadosFormulario.idTemporario) {
            nomeColunaId = 'idTemporario'
        }
        this.props.onChange(removerElementoPorId(this.props.value, dadosFormulario, nomeColunaId))
        this.setState({ registroSelecionado: null })
    }

    atualizarRegistroSelecionado(registroSelecionado) {
        this.setState({ registroSelecionado })
    }

    renderPapel(row) {
        return papelSignatarios.find(papel => papel.value === row.papel).label;
    }

    esconderMessageEmail() {
        const { value } = this.props;

        if (!value) {
            return true
        }

        for (let i = 0; i < value.length; i++) {
            if (!value[i].email) {
                return false
            }
        }
        return true
    }

    render() {

        const { dirty, value, informacoesPermissoes, disabled, estadoCadastro } = this.props;

        const { modalFormVisible, registroSelecionado } = this.state;

        const podeInserir = estadoCadastro === estadosCadastro.INCLUSAO ? informacoesPermissoes.podeInserir : informacoesPermissoes.podeEditar

        return (
            <React.Fragment>
                <Modal
                    visible={modalFormVisible}
                    header='Cadastro de signatário'
                    onHide={this.hideModal}
                >
                    <If test={modalFormVisible}>
                        <DocumentoSignatariosForm
                            registros={value}
                            registroSelecionado={registroSelecionado}
                            onNovoClick={() => this.setState({ registroSelecionado: null })}
                            excluirRegistro={this.excluirRegistro}
                            inserirRegistro={this.inserirRegistro}
                            atualizarRegistro={this.atualizarRegistro}
                            onHide={this.hideModal}
                            disabled={disabled}
                            informacoesPermissoes={informacoesPermissoes}
                            atualizarRegistroSelecionado={this.atualizarRegistroSelecionado}
                            estadoCadastro={estadoCadastro}
                        />
                    </If>
                </Modal>

                <Prompt dirty={dirty} />
                <ToolbarDetails>
                    <ToolbarItensLeft style={{ paddingLeft: '0px' }}>
                        <ButtonNovo
                            label="Novo signatário"
                            enableShortCut={false}
                            onClick={this.novo}
                            disabled={disabled}
                            podeInserir={podeInserir}
                        />
                    </ToolbarItensLeft>
                </ToolbarDetails>
                <Message
                    hidden={this.esconderMessageEmail()}
                    severity="error"
                    text="Informe um e-mail válido para todos os signatários"
                />
                <DataTable
                    className="table-light"
                    responsive
                    value={value}
                    emptyMessage={<NenhumRegistroEncontrado message="Nenhum signatário encontrado" />}
                >
                    <Column
                        field="nome"
                        header="Nome"
                        sortable={true}
                    />
                    <Column
                        field="email"
                        header="E-mail"
                        sortable={true}
                    />
                    <Column
                        field="papel"
                        body={this.renderPapel}
                        header="Assinar como"
                        sortable={true}
                    />
                    <Column
                        body={this.renderOpcoes}
                        header="Ações"
                        style={{ width: '7em' }}
                    />
                </DataTable>
            </React.Fragment>
        )
    }
}

export default DocumentoSignatarios;
