import React, { Component } from 'react';
import * as Yup from "yup";
import autoBind from 'react-autobind';
import { withRouter } from 'react-router';
import { withFormik, Field } from 'formik';
import { formatISO } from 'date-fns';


import Modal from '../../../../../components/Modal';
import { usuarioPossuiPermissao } from '../../../../../common/autenticacao';
import { recursos, permissoes, estadosCadastro } from '../../../../../common/constantes/autorizacao';
import AutoProgressBar from '../../../../../components/Loading/AutoProgressBar';
import { mensagensDeValidacao } from '../../../../../common/constantes/mensagens';
import ButtonNovo, { estadosBotaoNovo } from '../../../../../components/Button/ButtonNovo';
import ButtonSalvar from '../../../../../components/Button/ButtonSalvar';
import ButtonCancelar, { estadosBotaoCancelar } from '../../../../../components/Button/ButtonCancelar';
import ButtonExcluir from '../../../../../components/Button/ButtonExcluir';
import Grid from '../../../../../components/Grid';
import Dropdown from '../../../../../components/Select/Dropdown';
import { confirm } from '../../../../../components/Toast';
import Form from '../../../../../components/Form';
import FormActions from '../../../../../components/FormActions';
import FormContent from '../../../../../components/FormContent';
import { asyncDeleteProvento, asyncCreateProvento, asyncUpdateProvento } from '../../request';
import { validarFormulario } from '../../../../util';
import { converterProventoParaApi } from '../../utils/proventoConverter';
import InputDate from '../../../../../components/Input/InputDate';
import SingleSelectCorretoraConta from '../../../../../components/Select/SingleSelectCorretoraConta';
import SingleSelectAtivo from '../../../../../components/Select/SingleSelectAtivo';
import { services } from '../../../../../common/constantes/api';
import { formatarMonetario } from '../../../../../common/mascara';
import Col from '../../../../../components/Col';
import { renderizarValidacao } from '../../../../../common/tratamentoDeErro/validacoesDeCampos';
import InputMoney from '../../../../../components/Input/InputMoney';
import { helpMessageProventos } from './utils/utils';

const totalStyle = {
    fontSize: '20px',
    color: '#000000',
    margin: '10px 0px 0px 0px',
}


const tipo = [
    { label: 'Dividendo', value: 'DIVIDENDO' },
    { label: 'Rendimento', value: 'RENDIMENTO' },
    { label: 'Juros sobre Capital Próprio (JCP)', value: 'JCP' },
];


const initialValue = {
    id: '',
    data: formatISO(new Date()),
    tipo: 'DIVIDENDO',
    quantidade: 1.000,
    fatorCotacao: 0.00,
    valorBruto: 0.00,
    valorLiquido: 0.00,
    valorIrFonte: 0.00,
    ativo: null,
    conta: null,
};

class ModalProvento extends Component {

    constructor(props) {
        super(props);
        autoBind(this);

        this.state = {
            podeInserir: usuarioPossuiPermissao(recursos.INVESTIDOR_PROVENTOS, permissoes.INSERIR),
            podeEditar: usuarioPossuiPermissao(recursos.INVESTIDOR_PROVENTOS, permissoes.EDITAR),
            podeExcluir: usuarioPossuiPermissao(recursos.INVESTIDOR_PROVENTOS, permissoes.EXCLUIR),
        }
    }

    novo() {
        this.props.resetForm({ values: initialValue });
        this.props.onNovoClick()
    }

    async salvar(e, novoOnSuccess) {
        await this.props.handleSubmit();
        if (await validarFormulario(this.props)) {
            if (this.props.values.id) {
                this.asyncUpdateRegistro({ ...this.props.values }, novoOnSuccess);
            } else {
                this.asyncCreateRegistro({ ...this.props.values }, novoOnSuccess);
            }
        }
    }

    async asyncCreateRegistro(values, novoOnSuccess) {
        if (values) {
            await asyncCreateProvento(converterProventoParaApi(this.props.values), () => {
                if (novoOnSuccess) {
                    novoOnSuccess();
                } else {
                    this.props.onHide()
                }
            })
        }
    }

    async asyncUpdateRegistro(values, novoOnSuccess) {
        if (values) {
            await asyncUpdateProvento(converterProventoParaApi(this.props.values), () => {
                if (novoOnSuccess) {
                    novoOnSuccess();
                } else {
                    this.props.onHide()
                }
            })
        }
    }

    excluir() {
        confirm('Confirmação', 'Deseja excluir o registro?', async () => {
            await asyncDeleteProvento(this.props.values.id, () => {
                this.props.onHide()
            })
        })
    }

    cancelar() {
        const { dirty, resetForm, onHide, initialValues } = this.props
        if (dirty) {
            resetForm({ values: initialValues });
        } else {
            onHide();
        }
    }


    calcularTotais(valorBruto, valorLiquido) {
        const { setFieldValue } = this.props

        let valorIrFonte = valorBruto - valorLiquido;

        setFieldValue("valorIrFonte", parseFloat(valorIrFonte.toFixed(2)));
    }



    render() {
        const { onHide, visible, dirty, values, isModal, setFieldValue, errors } = this.props;
        const { podeInserir, podeEditar, podeExcluir } = this.state;

        const informacoesPermissoes = {
            podeInserir: podeInserir,
            podeEditar: podeEditar,
            podeExcluir: podeExcluir,
            estadoCadastro: values.id ? estadosCadastro.EDICAO : estadosCadastro.INCLUSAO
        };

        const estadoBotaoNovo = dirty ? estadosBotaoNovo.SALVAR_E_NOVO : estadosBotaoNovo.NOVO;
        const onClickNovo = dirty ? (e) => this.salvar(e, this.novo) : this.novo;

        return (
            <Modal
                header={values.id ? "Editar provento" : "Novo provento"}
                visible={visible}
                onHide={() => onHide()}
            >
                <AutoProgressBar />
                <Form>
                    <FormActions>
                        <ButtonCancelar
                            {...informacoesPermissoes}
                            hidden={isModal && values.id && !dirty}
                            estadoBotao={dirty ? estadosBotaoCancelar.CANCELAR : estadosBotaoCancelar.VOLTAR}
                            onClick={this.cancelar}
                        />
                        <ButtonSalvar
                            {...informacoesPermissoes}
                            disabled={!dirty}
                            onClick={this.salvar}
                        />
                        <ButtonNovo
                            onClick={onClickNovo}
                            hidden={!dirty && !values.id}
                            estadoBotao={estadoBotaoNovo}
                            {...informacoesPermissoes}
                        />
                        <ButtonExcluir
                            hidden={!values.id || isModal}
                            {...informacoesPermissoes}
                            onClick={this.excluir}
                        />
                    </FormActions>
                    <FormContent>
                        <Grid>
                            <Field sm="12" md="6" lg="4" xl="4"
                                component={InputDate}
                                helpMessage={helpMessageProventos.data}
                                obrigatorio
                                label="Data"
                                name="data"
                                onChange={e => setFieldValue('data', e.target.value)}
                                value={values.data}
                                {...informacoesPermissoes}
                            />

                            <Field
                                sm="12" md="6" lg="4" xl="4"
                                component={Dropdown}
                                helpMessage={helpMessageProventos.tipo}
                                label='Tipo'
                                obrigatorio
                                name="tipo"
                                showClear={false}
                                onChange={(e) => setFieldValue('tipo', e.value)}
                                options={tipo}
                                {...informacoesPermissoes}
                            />

                            <Field sm="12" md="6" lg="4" xl="4"
                                name="conta"
                                helpMessage={helpMessageProventos.conta}
                                label="Corretora (conta)"
                                obrigatorio
                                component={SingleSelectCorretoraConta}
                                value={values.conta}
                                onChange={e => setFieldValue('conta', e)}
                                url={`${services.GESTOR}/v1/investidor/proventos/relacoes/contas`}
                                {...informacoesPermissoes}
                            />

                            <Field sm="12" md="6" lg="4" xl="4"
                                name="ativo"
                                helpMessage={helpMessageProventos.ativo}
                                label="Ativo"
                                obrigatorio
                                component={SingleSelectAtivo}
                                value={values.ativo}
                                onChange={e => setFieldValue('ativo', e)}
                                url={`${services.GESTOR}/v1/investidor/proventos/relacoes/ativos`}
                                {...informacoesPermissoes}
                            />

                            <Field sm="12" md="6" lg="4" xl="4"
                                component={InputMoney}
                                helpMessage={helpMessageProventos.valorBruto}
                                obrigatorio
                                prefix="R$ "
                                label='Valor bruto'
                                name="valorBruto"
                                touched={true}
                                allowNegative={false}
                                onChange={e => {
                                    const valorBruto = e.target.value 
                                    setFieldValue('valorBruto', valorBruto)
                                    this.calcularTotais(valorBruto, values.valorLiquido)
                                }}
                                value={values.valorBruto}
                                size={13}
                                {...informacoesPermissoes}
                            />

                            <Field sm="12" md="6" lg="4" xl="4"
                                component={InputMoney}
                                helpMessage={helpMessageProventos.valorLiquido}
                                obrigatorio
                                label='Valor líquido'
                                name="valorLiquido"
                                touched={true}
                                allowNegative={false}
                                onChange={e => {
                                    const valorLiquido = e.target.value 
                                    setFieldValue('valorLiquido', valorLiquido)
                                    this.calcularTotais(values.valorBruto, valorLiquido)
                                }}
                                value={values.valorLiquido}
                                size={13}
                                {...informacoesPermissoes}
                            />

                            <Grid justifyEnd style={{ width: '100%', textAlign: 'end' }}>
                                <Col>
                                    <p style={totalStyle}>{`Valor de IR retido na fonte: ${formatarMonetario(values.valorIrFonte)}`}</p>
                                    {errors.valorIrFonte && renderizarValidacao(errors.valorIrFonte, true)}
                                </Col>
                            </Grid>
                        </Grid>
                    </FormContent>
                </Form>
            </Modal >
        )
    }
}

ModalProvento = withFormik({
    validateOnChange: false,
    validateOnBlur: false,

    mapPropsToValues(props) {
        if (props.registroSelecionado) {
            return props.registroSelecionado
        } else {
            return initialValue;
        }
    },

    validate(values) {
        let errors = {}

        if (values.valorLiquido <= 0) {
            errors.valorLiquido = "O Valor líquido deve ser maior ou igual a R$ 00.01"
        }

        return errors
    },

    validationSchema: Yup.object().shape({
        tipo: Yup.string().nullable().required(mensagensDeValidacao.OBRIGATORIO),
        data: Yup.string().nullable().required(mensagensDeValidacao.OBRIGATORIO),
        valorBruto: Yup.string().nullable().required(mensagensDeValidacao.OBRIGATORIO),
        valorLiquido: Yup.string().nullable().required(mensagensDeValidacao.OBRIGATORIO),
        valorIrFonte: Yup.string().nullable().required(mensagensDeValidacao.OBRIGATORIO),
        ativo: Yup.object().nullable().required(mensagensDeValidacao.OBRIGATORIO),
        conta: Yup.object().nullable().required(mensagensDeValidacao.OBRIGATORIO),
    }),
    handleSubmit: () => { }
})(ModalProvento);


export default withRouter(ModalProvento);
