import { tipoCampos } from "../../../components/PesquisaAvancada/util/constantes";

export const statusDocumento = {
    PENDENTE: 'PENDENTE',
    ARQUIVO_ADICIONADO: 'ARQUIVO_ADICIONADO',
    AGUARDANDO_ASSINATURAS: 'AGUARDANDO_ASSINATURAS',
    ASSINADO: 'ASSINADO',
    REJEITADO: 'REJEITADO',
    EXPIRADO: 'EXPIRADO'
};

export const rotaDocumento = 'documentos/'

export const optionsFiltroAvancado = [
    { label: 'Identificação', name: 'identificacao', type: tipoCampos.STRING },
    { label: 'Nome da pessoa', name: 'pessoaNome', type: tipoCampos.STRING },
    {
        label: 'Status', name: 'status', type: tipoCampos.SELECT,
        optionSelect: [
            { label: "Pendente", value: statusDocumento.PENDENTE },
            { label: "Arquivo adicionado", value: statusDocumento.ARQUIVO_ADICIONADO },
            { label: "Aguardando assinaturas", value: statusDocumento.AGUARDANDO_ASSINATURAS },
            { label: "Documento assinado", value: statusDocumento.ASSINADO },
            { label: "Documento rejeitado", value: statusDocumento.REJEITADO },
            { label: "Documento expirado", value: statusDocumento.EXPIRADO },
        ]
    },
    { label: 'Data de criação', name: 'criadoEm', type: tipoCampos.DATE }
]

export const ColorsCard = {
    AGUARDANDO_ASSINATURAS: {
        lightColor: '#fff7cd',
        strongColor: '#c16b13',
    },
    ASSINADO: {
        lightColor: '#c8facd',
        strongColor: '#008013',
    },
    REJEITADO: {
        lightColor: '#ffcdd2',
        strongColor: '#da1f1f',
    },
    EXPIRADO: {
        lightColor: '#e6e6e6',
        strongColor: '#666666',
    },
    ARQUIVO_ADICIONADO: {
        strongColor: "#01579b",
        lightColor: "#b3e5fc"
    },
    PENDENTE: {
        strongColor: '#721358',
        lightColor: '#BFA8C5'

    }
}
