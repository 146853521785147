import { services } from "../../../../common/constantes/api";
import { get } from "../../../../common/requisicoes";

export async function buscarContas(onSuccess, onError) {
    await get(`${services.GESTOR}/v1/contas?size=500&sort=nome`, null, onSuccess, onError);
}

export async function buscarConta(idConta, onSuccess, onError) {
    await get(`${services.GESTOR}/v1/contas/${idConta}`, null, onSuccess, onError);
}
