import { services } from "../../../../../common/constantes/api";
import { del, exibirToast, post, put } from "../../../../../common/requisicoes";

export async function criarNovaCorretora(corretora, onSuccess, onError) {
    await post(`${services.GESTOR}/v1/investidor/corretoras`, corretora, null, exibirToast(onSuccess, "Corretora criada com sucesso"), onError);
}

export async function atualizarCorretora(corretora, onSuccess, onError) {
    await put(`${services.GESTOR}/v1/investidor/corretoras/${corretora.id}`, corretora, null, exibirToast(onSuccess, "Corretora atualizada com sucesso"), onError);
}

export async function deletarCorretora(corretoraId, onSuccess, onError) {
    await del(`${services.GESTOR}/v1/investidor/corretoras/${corretoraId}`, null, exibirToast(onSuccess, "Corretora excluída com sucesso"), onError);
}