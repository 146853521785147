import { actionTypes } from "../../constantes/reduxTypes";

export default (state = {}, action) => {
    switch (action.type) {
        case actionTypes.MOSTRAR_ERRO_TRATADO: {
            return {
                ...state,
                mostrarModalTratamentoErro: new Date(),
                erros: action.payload
            }
        }
        default:
            return state
    }
}