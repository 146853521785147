import React, { Component } from 'react';
import { itensBreadCrumb } from '../../../config/rotas/index'
import { withRouter } from 'react-router';
import { AutoComplete } from 'primereact/autocomplete';
import { connect } from 'react-redux';
import autoBind from 'react-autobind/lib/autoBind';

class SelectPesquisaTela extends Component {

    constructor(props) {
        super(props)

        autoBind(this);
        
        this.state = {
            descricaoSelecionada: '',
            descricoes: [],
            descricoesFiltradas: []
        }
    }

    componentDidMount() {
        this.atualizarDescricoes()
    }

    atualizarDescricoes() {
        let descricoes = []

        for (const item in itensBreadCrumb) {
            if (itensBreadCrumb.hasOwnProperty(item)) {
                const element = itensBreadCrumb[item];
                if (element.podeAcessar && element.podeAcessar() !== false) {
                    descricoes.push(element.descricao)
                }
            }
        }

        this.setState({ ...this.state, descricoesFiltradas: descricoes, descricoes: descricoes })

        return descricoes
    }

    onTextoChange(e) {
        if ((this.state.descricoes.indexOf(e.value) > -1)) {
            itensBreadCrumb.forEach(element => {
                if (e.value && element.descricao && e.value.includes(element.descricao) && element.podeAcessar() !== false) {
                    this.props.history.push('/' + element.valor);
                }
            });
        }

        this.setState({ ...this.state, descricaoSelecionada: e.value });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.dataAtualizacaoMenuLateral !== this.props.dataAtualizacaoMenuLateral) {
            this.atualizarDescricoes()
        }
    }

    filtrarRegistros(event) {
        let results = this.state.descricoes.filter((registro) => {
            return registro.toLowerCase().includes(event.query.toLowerCase());
        });

        this.setState({ ...this.state, descricoesFiltradas: results });
    }

    focus() {
        document.getElementById('descricoes').focus()
    }

    render() {

        const autoCompleteStyle = {
            border: 0,
            borderRadius: 0,
            height: '40px',
            width:'100%',
            padding: '10px',
            fontFamily: 'FontAwesome, sans-serif',
            ...this.props.inputStyle
        }
        return (
            <React.Fragment>
                <span
                >
                    <AutoComplete
                        inputClassName={`autocompletePesquisa  ${this.props.className}`}
                        inputId='descricoes'
                        value={this.state.descricaoSelecionada}
                        onChange={this.onTextoChange}
                        suggestions={this.state.descricoesFiltradas}
                        size={30}
                        inputStyle={autoCompleteStyle}
                        style={this.props.style}
                        scrollHeight='300px'
                        delay={0}
                        completeMethod={this.filtrarRegistros}
                        placeholder=" &#xF002; Pesquisar"
                    />
                </span>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    dataAtualizacaoMenuLateral: state.menuLateral.dataAtualizacaoMenuLateral,
})

export default withRouter(connect(mapStateToProps)(SelectPesquisaTela))