import React, { Component } from 'react';
import { Column } from 'primereact/column';
import autoBind from 'react-autobind';
import format from 'date-fns/format';
import isValid from 'date-fns/isValid';
import ToolbarDetails from '../../../../../../../../components/Toolbar';
import { DataTable } from 'primereact/components/datatable/DataTable';
import { removerElemento } from '../../../../../../../../common/array';
import { estadosCadastro } from '../../../../../../../../common/constantes/autorizacao';
import ButtonEditarTable from '../../../../../../../../components/Button/ButtonEditarTable';
import ButtonExcluirTable from '../../../../../../../../components/Button/ButtonExcluirTable';
import Modal from '../../../../../../../../components/Modal';
import If from '../../../../../../../../components/If';
import ToolbarItensLeft from '../../../../../../../../components/Toolbar/ToolbarItensLeft';
import ButtonNovo from '../../../../../../../../components/Button/ButtonNovo';
import NenhumRegistroEncontrado from '../../../../../../../../components/NenhumRegistroEncontrado';
import DocumentoConfirmacaoSignatarioForm from './Form';
import Prompt from '../../../../../../../../components/Route/Prompt';
import Fieldset from '../../../../../../../../components/FieldSet';

class DocumentoConfirmacaoSignatario extends Component {

    constructor(props) {
        super(props);

        autoBind(this);

        this.state = {
            registroSelecionado: null,
            modalFormVisible: false,
        }
    }

    novo() {
        this.setState({ modalFormVisible: true, registroSelecionado: null })
    }

    onEditar(row) {
        this.setState({ modalFormVisible: true, registroSelecionado: row })
    }

    renderOpcoes(row) {
        const { informacoesPermissoes, disabled } = this.props
        return (
            <React.Fragment>
                <ButtonEditarTable
                    onClick={() => this.onEditar(row)}
                    {...informacoesPermissoes}
                />
                <ButtonExcluirTable
                    onClick={() => this.excluirRegistro(row)}
                    disabled={disabled}
                    podeExcluir={informacoesPermissoes.podeEditar}
                />
            </React.Fragment>
        )
    }

    hideModal() {
        this.setState({ modalFormVisible: false, registroSelecionado: null })
    }

    atualizarRegistro(dadosFormulario) {
        const { value } = this.props;

        const result = value && value.map(item => {
            if (item.idTemporario && item.idTemporario === dadosFormulario.idTemporario) {
                return dadosFormulario
            } else if (item.id && item.id === dadosFormulario.id) {
                return dadosFormulario
            }
            return item
        })
        this.setState({ registroSelecionado: dadosFormulario })
        this.props.onChange(result)
    }

    inserirRegistro(dadosFormulario) {
        this.props.onChange([...this.props.value, dadosFormulario]);
        this.setState({ registroSelecionado: dadosFormulario })
    }

    excluirRegistro(dadosFormulario) {
        this.props.onChange(removerElemento(this.props.value, dadosFormulario))
        this.setState({ registroSelecionado: null })
    }

    renderRespostaEsperada(row) {
        if (!row.respostaEsperada) {
            return <p style={{ color: 'red' }}>Nenhum valor informado</p>;
        }

        if (row.respostaTipo === "SIM_NAO") {
            if (row.respostaEsperada === "true") {
                return 'Sim'
            } else if (row.respostaEsperada === "false") {
                return 'Não'
            }
        }

        if (row.respostaTipo === "DATA" && isValid(new Date(row.respostaEsperada))) {
            return format(new Date(row.respostaEsperada), 'dd/MM/yyyy');
        }

        return row.respostaEsperada;
    }

    render() {
        const { dirty, value, informacoesPermissoes, estadoCadastro, disabled } = this.props;

        const { modalFormVisible, registroSelecionado } = this.state;

        const podeInserir = estadoCadastro === estadosCadastro.INCLUSAO ? informacoesPermissoes.podeInserir : informacoesPermissoes.podeEditar

        return (
            <React.Fragment>
                <Prompt dirty={dirty} />
                <Modal
                    visible={modalFormVisible}
                    header="Cadastro de confirmação para assinatura"
                    onHide={() => this.setState({ modalFormVisible: false })}
                >
                    <If test={modalFormVisible}>
                        <DocumentoConfirmacaoSignatarioForm
                            disabled={disabled}
                            onNovoClick={() => this.setState({ registroSelecionado: null })}
                            registroSelecionado={registroSelecionado}
                            excluirRegistro={this.excluirRegistro}
                            inserirRegistro={this.inserirRegistro}
                            atualizarRegistro={this.atualizarRegistro}
                            onHide={this.hideModal}
                            estadoCadastro={estadoCadastro}
                            informacoesPermissoes={informacoesPermissoes}
                        />
                    </If>
                </Modal>

                <ToolbarDetails>
                    <ToolbarItensLeft style={{ paddingLeft: '0px' }}>
                        <Fieldset legend='Confirmações específicas do signatário' className='fieldset-light'/>
                            <ButtonNovo
                                label="Nova confirmação"
                                enableShortCut={false}
                                onClick={this.novo}
                                disabled={disabled}
                                podeInserir={podeInserir}
                            />
                    </ToolbarItensLeft>
                </ToolbarDetails>
                <DataTable
                    className="table-light"
                    responsive
                    value={value}
                    emptyMessage={<NenhumRegistroEncontrado message="Nenhuma confirmação encontrada" />}
                >
                    <Column
                        field="pergunta"
                        header="Pergunta para confirmação"
                        sortable={true}
                    />
                    <Column
                        field="respostaEsperada"
                        header="Resposta esperada"
                        body={this.renderRespostaEsperada}
                        sortable={true}
                    />
                    <Column
                        body={this.renderOpcoes}
                        header="Ações"
                        style={{ width: '7em' }}
                    />
                </DataTable>
            </React.Fragment>
        )
    }
}

export default DocumentoConfirmacaoSignatario
