export function converterSaidaEstoqueParaApi(values) {
    return {
        ...values,
        produto: values.produto ? {
            id: values.produto.value,
            tipo: values.produto.registro.tipo,
            controlarEstoque: values.produto.registro.controlarEstoque,
        } : null,
    }
}
