import { get, del, post, put, exibirToast } from "../../../../common/requisicoes";
import { services } from "../../../../common/constantes/api";

export async function asyncGetImportacao(idImportacao, onSuccess, onError) {
    await get(`${services.GESTOR}/v1/investidor/importacoes${idImportacao}`, null, onSuccess, onError);
}

export async function asyncGetImportacoes(onSuccess, onError) {
    await get(`${services.GESTOR}/v1/investidor/importacoes?size=500&sort=tipo`, null, onSuccess, onError);
}

export async function asyncDeleteRegistro(idImportacao, onSuccess, onError) {
    await del(`${services.GESTOR}/v1/investidor/importacoes/${idImportacao}`, null, exibirToast(onSuccess, "Importação removida com sucesso"), onError);
}

export async function asyncCreateRegistro(data, onSuccess, onError) {
    await post(`${services.GESTOR}/v1/investidor/importacoes`, data, null, exibirToast(onSuccess, "Importação criada com sucesso"), onError);
}

export async function asyncUpdateRegistro(data, onSuccess, onError) {
    await put(`${services.GESTOR}/v1/investidor/importacoes/${data.id}`, data, null, exibirToast(onSuccess, "Importação atualizada com sucesso"), onError);
}

export async function asyncImportar(idImportacao, onSuccess, onError) {
    await post(`${services.GESTOR}/v1/investidor/importacoes/${idImportacao}/importar`,null, null, exibirToast(onSuccess, "Sincronização de negociações realizada com sucesso"), onError);
}