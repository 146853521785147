import React from 'react';

const styleEstoqueDisponivel = {
    padding: '5px',
    cursor: 'pointer'
}

export default function BotaoTabelaEstoque({ icon, color, onClick, style, title = "", disabled }) {
    return (
        <span
            title={title}
            style={{ ...styleEstoqueDisponivel, color, ...style, opacity: disabled ? '0.5' : '1', cursor: disabled ? 'default' : 'pointer' }}
            onClick={disabled ? null : onClick}
        >
            <i className={icon || "fa fa-pencil"} />
        </span>
    )
}