import { tipoCampos } from "../../../../components/PesquisaAvancada/util/constantes";

export const optionsFiltroAvancado = [
    {
        label: 'Status', name: 'status', type: tipoCampos.SELECT,
        optionSelect: [
            { label: "Não recebida", value: "NAO_RECEBIDA" },
            { label: "Parcialmente recebida", value: "PARCIALMENTE_RECEBIDA" },
            { label: "Recebida", value: "RECEBIDA" },
        ]
    },
    { label: 'Descrição', name: 'descricao', type: tipoCampos.STRING },
    { label: 'Valor', name: 'valor', type: tipoCampos.DECIMAL },
    { label: 'Observação', name: 'observacao', type: tipoCampos.STRING },
]
