import React, { Component } from 'react'
import Modal from '../../../../../components/Modal';
import ButtonCancelar, { estadosBotaoCancelar } from '../../../../../components/Button/ButtonCancelar';
import autoBind from 'react-autobind';
import { asyncGetDocumentoHistorico } from './requests';
import { Column } from 'primereact/column';
import { buscarIconeHistorico } from './util';
import format from 'date-fns/format';
import Form from '../../../../../components/Form';
import FormActions from '../../../../../components/FormActions';
import FormContent from '../../../../../components/FormContent';
import { DataTable } from 'primereact/components/datatable/DataTable';
import { construirUrl } from '../../../../../common/rsql';
import { services } from '../../../../../common/constantes/api';
import { Paginator } from 'primereact/paginator';
import NenhumRegistroEncontrado from '../../../../../components/NenhumRegistroEncontrado';

export default class DocumentoHistorico extends Component {

    constructor(props) {
        super(props)
        autoBind(this);

        this.state = {
            registros: [],
            totalElements: 0,
            sortField: 'dataHora',
            sortOrder: -1,
            rows: 10,
            page: 0
        }
    }

    componentDidMount() {
        const { idDocumento } = this.props;

        if (idDocumento) {
            this.pesquisar()
        }
    }

    pesquisar() {
        const { idDocumento } = this.props;
        const { page, sortField, sortOrder, rows } = this.state;

        const url = construirUrl(`${services.GESTOR}/v1/documentos/${idDocumento}/historico`, '?query=()', rows, page, sortOrder > 0 ? `${sortField},asc` : `${sortField},desc`)

        asyncGetDocumentoHistorico(url, ({ data: documentos }) => {

            this.setState({
                registros: documentos.content,
                totalElements: documentos.totalElements
            })
        })
    }

    onPesquisar() {
        this.setState({ page: 0 }, () => {
            this.pesquisar();
        })
    }

    onPageChange(e) {
        this.setState({ page: e.page, rows: e.rows }, () => {
            this.pesquisar()
        })
    }

    onSort(e) {
        this.setState({ sortField: e.sortField, sortOrder: e.sortOrder }, () => {
            this.pesquisar()
        })
    }

    renderUsuario(row) {
        if (row.nome && row.nome) {
            if (row.nome) {
                return `${row.nome} (${row.email})`
            } else {
                return `${row.email}`
            }
        } else {
            return `Usuário não cadastrado no sistema`
        }
    }

    renderData(row) {
        if (row.dataHora) {
            return <span>{format(new Date(row.dataHora), 'dd/MM/yyyy HH:mm:ss')}</span>
        }
        return "";
    }

    renderOperacao(row) {
        let icon = buscarIconeHistorico(row.operacao)
        return (
            <>
                <i className={icon}></i>
                &nbsp;&nbsp;&nbsp;{row.operacao}
            </>
        )
    }

    atualizarOrdenacao(e) {
        this.setState({ sortField: e.sortField, sortOrder: e.sortOrder })
    }

    render() {

        const { visible, onHide } = this.props
        const { rows, page, registros, sortField, sortOrder, totalElements } = this.state;

        return (
            <>
                <Modal
                    header='Histórico do documento'
                    visible={visible}
                    onHide={onHide}
                >
                    <Form>
                        <FormActions>
                            <ButtonCancelar
                                estadoBotao={estadosBotaoCancelar.VOLTAR}
                                onClick={onHide}
                            />
                        </FormActions>
                        <FormContent>
                            <DataTable
                                className='table-light'
                                responsive
                                value={registros}
                                sortField={sortField}
                                sortOrder={sortOrder}
                                onSort={this.onSort}
                                emptyMessage={<NenhumRegistroEncontrado />}
                            >
                                <Column
                                    field="dataHora"
                                    header="Data"
                                    body={row => this.renderData(row)}
                                    sortable
                                />
                                <Column
                                    field="nome"
                                    header="Usuário"
                                    body={this.renderUsuario}
                                />
                                <Column
                                    field="operacao"
                                    header="Operação"
                                    body={this.renderOperacao}
                                />
                            </DataTable>
                            <Paginator
                                className='paginator-light'
                                rowsPerPageOptions={[10, 25, 50]}
                                totalRecords={totalElements}
                                rows={rows}
                                first={page * rows}
                                onPageChange={this.onPageChange}
                                rightContent={<div style={{ margin: '5px' }}>{totalElements > 0 ? `${totalElements} registro(s) encontrado(s)` : null}</div>}
                            />
                        </FormContent>
                    </Form>
                </Modal>
            </>
        )
    }
}
