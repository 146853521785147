import { get, del, exibirToast } from "../../../../common/requisicoes";
import { services } from "../../../../common/constantes/api";
import { format } from "date-fns";

export async function asyncGetLancamentos(url, onSuccess, onError) {
    return await get(url, null, onSuccess, onError, true)
}

export async function asyncRemoveLancamento(idLancamento, onSuccess, onError) {
    return await del(`${services.GESTOR}/v1/estoque/movimentacoes/${idLancamento}`, null, exibirToast(onSuccess, "Movimentação excluída com sucesso"), onError, true)
}

export async function asyncGetLancamento(idLancamento, onSuccess, onError) {
    await get(`${services.GESTOR}/v1/estoque/movimentacoes/${idLancamento}`, null, onSuccess, onError);
}

export async function asyncGetTotalizadores(interval, produtos, onSuccess, onError) {
    const dataInicialFormatada = format(interval.dataInicial, 'yyyy-MM-dd');
    const dataFinalFormatada = format(interval.dataFinal, 'yyyy-MM-dd');
    let produtosIds = "";
    
    if(produtos && produtos.length > 0) {
        produtosIds = montarProdutosSelecionados(produtos);
    }

    await get(`${services.GESTOR}/v1/estoque/movimentacoes/totais?dataInicial=${dataInicialFormatada}&dataFinal=${dataFinalFormatada}&produtoIds=${produtosIds}`, null, onSuccess, onError);
}

export async function asyncBaixarRelatorioMovimentacoesPorPeriodo(produtos, dataInicial, dataFinal, onSuccess, onError) {
    const dataInicialFormatada = format(dataInicial, 'yyyy-MM-dd');
    const dataFinalFormatada = format(dataFinal, 'yyyy-MM-dd');
    let produtosIds = "";
    
    if(produtos && produtos.length > 0) {
        produtosIds = montarProdutosSelecionados(produtos);
    }
    return await get(`${services.GESTOR}/v1/estoque/movimentacoes/produtos/imprimir?dataInicial=${dataInicialFormatada}&dataFinal=${dataFinalFormatada}&produtoIds=${produtosIds}`, { responseType: "arraybuffer" }, onSuccess, onError);
}

function montarProdutosSelecionados(produtos) {
    return produtos.map(produto => produto.value)
}
