import React, { Component } from 'react';
import { InputText } from 'primereact/components/inputtext/InputText';
import propTypes from 'prop-types'
import Col from '../../Col';
import { buscarDisabledDeAcordoComAsPermissoes, buscarHiddenDeAcordoComAsPermissoes, buscarTitleCampoDeAcordoComAsPermissoes } from '../../../common/autorizacao/manipulacaoDeComponentes';
import { renderizarValidacao, buscarClassNamePelosErros } from '../../../common/tratamentoDeErro/validacoesDeCampos';
import autoBind from 'react-autobind'
import { usuarioPossuiModulos } from '../../../common/autenticacao';
import If from '../../If';

export default class InputField extends React.Component {
    render() {
        const { field, form, ...rest } = this.props;
        return (
            <React.Fragment>
                <InternalInputField
                    {...field}
                    {...rest}
                    errors={form.errors[field.name]}
                    touched={form.touched[field.name]}
                />
            </React.Fragment>
        );
    }
}


export class InternalInputField extends Component {

    constructor(props) {
        super(props);

        autoBind(this);

        this.state = {
            validouModulo: true
        };
    }

    componentDidMount() {
        if (this.props.modulosEspecificos)
            this.setState({ validouModulo: usuarioPossuiModulos(this.props.modulosEspecificos) })
    }

    getColStyle() {
        if (buscarHiddenDeAcordoComAsPermissoes(this.props.podeVisualizar, this.props.hidden)) {
            return { display: 'none', ...this.props.colStyle }
        }
        return { ...this.props.colStyle, textOverflow: 'ellipsis', overflow: 'hidden' }
    }

    modificarOnChangeParaNull(e) {
        return {
            target: {
                value: null,
                name: e.target.name
            }
        }
    }

    onChange(e) {
        const { size, onChange } = this.props;

        if (e.target.value === '') {
            onChange(this.modificarOnChangeParaNull(e));
        } else if (e.target.value.length > size) {
            const value = String(e.target.value).substr(0, size)
            onChange({
                target: {
                    value: value,
                    name: e.target.name
                }
            });
        } else {
            onChange(e);
        }
    }

    getValue() {
        return this.props.value || this.props.value === 0 ? this.props.value : ''
    }

    montarLabel() {
        const { obrigatorio, labelSize } = this.props;

        const label = this.props.floatLabel || this.props.label
        if (obrigatorio) {
            return <label style={{ fontSize: labelSize }} title={this.props.helpMessage}> {label} <b style={{ fontSize: labelSize ? labelSize : '18px', lineHeight: '5px' }} > *</b> </label>
        }
        return <label style={{ fontSize: labelSize }} title={this.props.helpMessage}> {label} </label>
    }

    getStyle() {
        const { style } = this.props
        return {
            borderRadius: '3px',
            height: style?.height ? style.height : '32px',
            ...style
        }
    }

    render() {
        if (!this.state.validouModulo) {
            return null;
        }

        const { id, type, name, title, placeholder, className,
            onBlur, onKeyDown, errors, touched, sm, md, lg, xl, keyfilter,
            podeVisualizar, podeInserir, podeEditar, estadoCadastro, disabled, colId, col, onFocus, step, min, autoFocus, floatLabel,
            colClassName, warning } = this.props

        return (
            <Col className={colClassName} id={colId} col={col} sm={sm} md={md} lg={lg} xl={xl} style={this.getColStyle()}>

                <If test={!floatLabel}>
                    {this.montarLabel()}
                </If>

                <span
                    title={buscarTitleCampoDeAcordoComAsPermissoes(podeVisualizar, podeInserir, podeEditar, estadoCadastro, title)}
                    className="p-fluid"
                >
                    <div className={"p-inputgroup" + floatLabel ? " p-float-label " : ""}>
                        <InputText
                            id={id}
                            keyfilter={keyfilter}
                            type={type}
                            name={name}
                            step={step}
                            min={min}
                            placeholder={placeholder}
                            value={this.getValue()}
                            onChange={this.onChange}
                            onFocus={onFocus}
                            className={buscarClassNamePelosErros(className, errors, touched)}
                            disabled={buscarDisabledDeAcordoComAsPermissoes(podeInserir, podeEditar, estadoCadastro, disabled)}
                            style={this.getStyle()}
                            onBlur={onBlur}
                            onKeyDown={onKeyDown}
                            autoFocus={autoFocus}
                        />
                        <If test={floatLabel}>
                            <label htmlFor={id}>{this.montarLabel()}</label>
                        </If>
                    </div>
                </span>
                {renderizarValidacao(errors, touched, warning)}
            </Col>
        )
    }
}

InternalInputField.defaultProps = {
    value: '',
    onChange: () => { },
    size: 99999,
    obrigatorio: false
}

InternalInputField.propTypes = {

    /** Evento disparado ao modificar o componente do componente */
    onChange: propTypes.func,
    /** Especifica o tipo do campo */
    type: propTypes.string,
    /** Label do componente */
    label: propTypes.string,
    /** Identificador do componente */
    id: propTypes.string,
    /** Placeholder do componente */
    placeholder: propTypes.string,
    /** Valor do componente do componente */
    value: propTypes.any,
    /** Nome da classe do componente */
    className: propTypes.string,
    /** Define se o componente está desabilitado*/
    disabled: propTypes.bool,
    /** Especifica os erros de validação que o componente possui (geralmente vindos do Yup)*/
    errors: propTypes.oneOfType([propTypes.string, propTypes.bool]),
    /** Especifica se o componente foi 'tocado'*/
    touched: propTypes.bool,
    /** Evento executado ao sair do campo*/
    onBlur: propTypes.any,
    /** Evento executado ao pressionar uma tecla do campo*/
    onKeyDown: propTypes.any,
    /** Tamanho padrão da coluna utilizado em dispositivos muito pequenos (0 a 12) */
    col: propTypes.string,
    /** Tamanho do campo em small devices*/
    sm: propTypes.string,
    /** Tamanho do campo em medium devices*/
    md: propTypes.string,
    /** Tamanho do campo em large devices*/
    lg: propTypes.string,
    /** Tamanho do campo em extra large devices*/
    xl: propTypes.string,
    /** Estilo da coluna*/
    colStyle: propTypes.object,
    /** Estado em que o cadastro se encontra*/
    estadoCadastro: propTypes.string,
    /** Diz se o usuário possui permissão de visualizar*/
    podeVisualizar: propTypes.bool,
    /** Diz se o usuário possui permissão de editar*/
    podeEditar: propTypes.bool,
    /** Diz se o usuário possui permissão de excluir*/
    podeInserir: propTypes.bool,
    /** Title do componente*/
    title: propTypes.string,
    /** Id da coluna*/
    colId: propTypes.string,
    /** Esconde o componente*/
    hidden: propTypes.bool,
    /** Filtra os caracteres aceitos pelo campo, pode aceitar expressões regulares também além de:
     * pint: Positive integers
     * int: Integers
     * pnum: Positive numbers
     * num: Numbers
     * hex: Hexadecimal
     * email: Email
     * alpha: Alphabetic
     * alphanum: Alphanumeric
     */
    keyfilter: propTypes.any,
    /** Tamanho máximo do texto do componente*/
    size: propTypes.number,
    /** Campo destinado a uma breve  explicação sobre o campo. Irá renderizar um ícone de pergunta caso a propriedade for alimentada.*/
    helpMessage: propTypes.string,
    /** Evento disparado ao componente receber foco*/
    onFocus: propTypes.func,
    /** Define se o campo será brigatório*/
    obrigatorio: propTypes.bool,
    /** Define o label flututante. Caso essa propriedade for passada, o label padrão é ignorado */
    floatLabel: propTypes.string
}
