import React, { Component } from 'react'
import Modal from '../../../../../../components/Modal';
import ButtonCancelar, { estadosBotaoCancelar } from '../../../../../../components/Button/ButtonCancelar';
import MenuSuperior from '../../../../../../components/MenuSuperior';
import LayoutMenuSuperior from '../../../../../../components/Layout/LayoutMenuSuperior';
import autoBind from 'react-autobind';
import { Column } from 'primereact/column';
import format from 'date-fns/format';
import parseJSON from 'date-fns/parseJSON';
import { DataTable } from 'primereact/components/datatable/DataTable';
import NenhumRegistroEncontrado from '../../../../../../components/NenhumRegistroEncontrado';
import { Paginator } from 'primereact/paginator';

export default class ModalVisualizarHistorico extends Component {

    constructor(props) {
        super(props)
        autoBind(this);

        this.state = {
            sortField: 'receivedDate',
            sortOrder: -1,
            page: 0,
            rows: 10
        }
    }

    onPesquisar() {
        this.setState({ page: 0 }, () => {
            this.props.atualizarInformacoesHistorico(
                this.state.page,
                this.state.sortField,
                this.state.sortOrder,
                this.state.rows,
            );
        })
    }

    onPageChange(e) {
        this.setState({ page: e.page, rows: e.rows }, () => {
            this.props.atualizarInformacoesHistorico(
                this.state.page,
                this.state.sortField,
                this.state.sortOrder,
                this.state.rows,
            );
        })
    }

    onSort(e) {
        this.setState({ sortField: e.sortField, sortOrder: e.sortOrder }, () => {
            this.props.atualizarInformacoesHistorico(
                this.state.page,
                this.state.sortField,
                this.state.sortOrder,
                this.state.rows,
            );
        })
    }

    renderData(row) {
        const data = parseJSON(row.data)
        return (
            <strong>{
                format(data, 'dd/MM/yyyy')
            }</strong>
        )
    }

    renderValor(row) {
        if (row.valor) {
            return `R$ ${(row.valor / 100).toFixed(2).split('.').join(',')}`
        } else {
            return ''
        }
    }

    renderStatus(row) {
        if (row.status === 'Pagamento confirmado e finalizado') {
            return (
                <>
                    <i className='fa fa-check' style={{ color: 'green' }}></i>
                    &nbsp;&nbsp;&nbsp;{row.status}
                </>
            )
        } else {
            return (
                <>
                    <i className='fa fa-close' style={{ color: 'red' }}></i>
                    &nbsp;&nbsp;&nbsp;{row.status}
                </>
            )
        }
    }

    atualizarOrdenacao(e) {
        this.setState({ sortField: e.sortField, sortOrder: e.sortOrder })
    }

    render() {

        const { visible, onHide, totalElements, registros } = this.props
        const { rows, page, sortField, sortOrder } = this.state;

        return (
            <>
                <Modal
                    header='Histórico de pagamentos'
                    visible={visible}
                    onHide={onHide}
                >
                    <MenuSuperior isModal>
                        <ButtonCancelar
                            estadoBotao={estadosBotaoCancelar.VOLTAR}
                            onClick={onHide}
                        />
                    </MenuSuperior>
                    <LayoutMenuSuperior isModal={true}>
                        <DataTable
                            className='table-light'
                            responsive
                            value={registros}
                            sortField={sortField}
                            sortOrder={sortOrder}
                            onSort={this.atualizarOrdenacao}
                            emptyMessage={<NenhumRegistroEncontrado />}
                        >
                            <Column field="data" header="Data" body={this.renderData} sortable />
                            <Column field="valor" header="Valor" body={this.renderValor} sortable />
                            <Column field="status" header="Descrição" body={this.renderStatus} />
                        </DataTable>
                        <Paginator
                            className='paginator-light'
                            rowsPerPageOptions={[10, 25, 50]}
                            totalRecords={totalElements}
                            rows={rows}
                            first={page * rows}
                            onPageChange={this.onPageChange}
                            rightContent={<div style={{ margin: '5px' }}>{totalElements > 0 ? `${totalElements} registro(s) encontrado(s)` : null}</div>}
                        />
                    </LayoutMenuSuperior>
                </Modal>
            </>
        )
    }
}
