import React, { Component } from 'react';
import { recursos, permissoes } from '../../../common/constantes/autorizacao';
import { usuarioPossuiPermissao } from '../../../common/autenticacao';
import autoBind from 'react-autobind/lib/autoBind';
import Col from '../../../components/Col';
import Grid from '../../../components/Grid';
import CardTotalizadorListagem from './components/CardTotalizadorListagem';
import PesquisaAvancada from '../../../components/PesquisaAvancada';
import { optionsFiltroAvancado } from './util/constantes';
import { construirUrl } from '../../../common/rsql';
import { services } from '../../../common/constantes/api';
import { salvarConfiguracaoUsuario, configuracoesUsuario } from '../../../common/configuracaoUsuario';
import { asyncGetLancamento, asyncGetLancamentos, asyncGetTotalizadores, asyncRemoveLancamento } from './requests';
import DateInterval from '../../../components/DateInterval';
import If from '../../../components/If';
import DescricaoFiltroAvancado from '../../../components/DescricaoFiltroAvancado';
import Form from '../../../components/Form';
import FormActions from '../../../components/FormActions';
import FormMobileActions from '../../../components/FormMobileActions';
import FormContent from '../../../components/FormContent';
import { Menu } from 'primereact/menu';
import { format, formatISO } from 'date-fns';
import { tipoMovimentacao } from './util/constantes';
import TabelaMovimentacoes from './components/TabelaMovimentacoes';
import ModalSaidaEstoque from './components/ModalSaidaEstoque';
import ModalEntradaEstoque from './components/ModalEntradaEstoque';
import { confirm } from '../../../components/Toast';
import InputSearchProdutos from './components/InputSearchProdutos';
import { asyncBaixarRelatorioMovimentacoesPorPeriodo } from './requests';
import { baixarArquivo } from '../../../common/relatorios';
import { FaPrint } from 'react-icons/fa';
import Button from '../../../components/Button';

const COLORS = {
    blue: "#006095",
    red: "#ff0000cc",
    green: "#36a536"
}

const styleBotaoImprimir = {
    padding: '0px 10px',
    display: 'flex',
    background: '#fff0',
    fontWeight: 'bold',
    fontSize: '15px',
    alignItems: 'center',
    border: 'none',
    cursor: 'pointer'
};

class MovimentacoesEstoque extends Component {

    constructor(props) {

        super(props);

        autoBind(this);

        this.state = {
            produtoSelecionado: null,
            geradoAPartirDeUmaVenda: null,
            podeInserirLancamento: usuarioPossuiPermissao(recursos.ESTOQUE_MOVIMENTACOES, permissoes.INSERIR),

            exibirModalLancamentoSaida: false,
            exibirModalLancamentoEntrada: false,
            exibirModalSaldoInicial: false,
            tutorialVisible: false,
            cardTotalSelected: "",
            descricaoFiltroAvancado: '',
            valorPesquisa: [],
            valorCard: "",
            filtroData: "",
            filtroConta: null,
            filtroAvancado: "",
            cards: {
                saldoAnterior: 0,
                entradas: 0,
                saidas: 0,
                saldo: 0
            },
            optionsFiltroAvancado: optionsFiltroAvancado,
            limparFiltroPesquisaAvancada: false,
            registros: [],
            totalRecords: 0,

            interval: {
                dataInicial: null,
                dataFinal: null
            },

            sortField: 'data',
            sortOrder: -1,

            page: 0,
            rows: 0,
            size: 10,
            first: 0
        }
    }

    onPesquisarFiltroAvancado(e) {
        this.setState({ filtroAvancado: e, limparFiltroPesquisaAvancada: false }, () => {
            this.pesquisar()
        });
    }

    componentDidMount() {
        const { location } = this.props;

        if (this.state.deveExibirTutorial !== false) {
            this.setState({ tutorialVisible: true })
            salvarConfiguracaoUsuario(configuracoesUsuario.EXIBIR_TUTORIAL_LISTAGENS, false, null, false)
        }
        if (location && location.state) {
            this.setState({
                valorPesquisa: [
                    ...this.state.valorPesquisa,
                    {
                        label: location.state.sku + ' - ' + location.state.nome,
                        value: location.state.id,
                        registro: location.state
                    },
                ]
            })
        }
    }

    buscarFiltro() {
        const { filtroAvancado, filtroData, valorCard, valorPesquisa } = this.state;

        let filtroRSQL = String(`?query=(${filtroData}`)

        if (valorPesquisa && valorPesquisa.length > 0) {
            filtroRSQL = filtroRSQL.concat(`;produto.id=in=(${valorPesquisa.map(elemento => elemento.value)})`)
        }
        if (filtroAvancado) {
            filtroRSQL = filtroRSQL.concat(`;(${filtroAvancado})`)
        }
        if (valorCard) {
            filtroRSQL = filtroRSQL.concat(`;(${valorCard})`)
        }

        filtroRSQL = filtroRSQL.concat(`)`)

        return filtroRSQL;
    }

    async pesquisar() {
        const { page, size, sortField, sortOrder, interval, valorPesquisa } = this.state
        const filtro = this.buscarFiltro();

        const url = construirUrl(`${services.GESTOR}/v1/estoque/movimentacoes/resumo`, filtro || "?", size, page, sortOrder > 0 ? `${sortField},asc&sort=criadoEm,desc` : `${sortField},desc&sort=criadoEm,desc`);

        asyncGetTotalizadores(interval, valorPesquisa, ({ data: totais }) => {
            this.setState({
                cards: {
                    saldoAnterior: totais.saldoAnterior,
                    entradas: totais.totalEntradas,
                    saidas: totais.totalSaidas,
                    saldo: totais.saldoTotal
                }
            })
        });

        asyncGetLancamentos(url, ({ data: lancamentos }) => {
            this.setState({
                registros: lancamentos.content,
                totalRecords: lancamentos.totalElements,
            })
        })
    }

    handleChangeInterval(interval) {
        this.setState({ page: 0, first: 0, interval: interval, filtroData: `data>=${formatISO(interval.dataInicial, { representation: "date" })};data<=${formatISO(interval.dataFinal, { representation: "date" })}` }, () => {
            this.pesquisar();
        })
    }

    async handleEditItem(item) {
        await asyncGetLancamento(item.id, ({ data: lancamento }) => {
            if (lancamento.tipo === tipoMovimentacao.ENTRADA) {
                this.setState({
                    registroSelecionado: {
                        ...lancamento,
                        produto: {
                            label: lancamento.produto.sku + " - " + lancamento.produto.nome,
                            value: lancamento.produto.id,
                            registro: {
                                ...lancamento.produto,
                                estoqueSaldo: lancamento.quantidade + lancamento.produto.estoqueSaldo
                            }
                        }
                    },
                    exibirModalLancamentoEntrada: true
                })
            } else {
                this.setState({
                    registroSelecionado: {
                        ...lancamento,
                        produto: {
                            label: lancamento.produto.sku + " - " + lancamento.produto.nome,
                            value: lancamento.produto.id,
                            registro: {
                                ...lancamento.produto,
                                estoqueSaldo: lancamento.quantidade + lancamento.produto.estoqueSaldo
                            }
                        }
                    },
                    exibirModalLancamentoSaida: true,
                    geradoAPartirDeUmaVenda: item.documento && item.documento.id
                })
            }
        })
    }

    async handleRemoveItem(item) {
        confirm('Confirmação', 'Tem certeza que deseja excluir esta movimentação?', async () => {
            await asyncRemoveLancamento(item.id, () => this.pesquisar());
        });
    }

    onHideModalSaidaEstoque(values) {
        const { valorPesquisa } = this.state;

        if (values) {
            this.pesquisar();

            let novoValorPesquisa = valorPesquisa?.map(produto => {
                if (produto.value === values.produto.value) {
                    return {
                        ...produto,
                        registro: {
                            ...produto.registro,
                            estoqueSaldo: (produto.registro.estoqueSaldo - values.quantidade)
                        }
                    }
                }
                return produto;
            })
            this.setState({
                exibirModalLancamentoSaida: false,
                produtoSelecionado: null,
                geradoAPartirDeUmaVenda: null,
                valorPesquisa: novoValorPesquisa
            })
        } else {
            this.setState({
                exibirModalLancamentoSaida: false,
                produtoSelecionado: null,
                registroSelecionado: null,
                geradoAPartirDeUmaVenda: null,
            })
        }
    }

    onHideModalEntradaEstoque(values) {
        if (values) {
            this.pesquisar();
        }

        this.setState({
            exibirModalLancamentoEntrada: false,
            produtoSelecionado: null,
            registroSelecionado: null,
            geradoAPartirDeUmaVenda: null,
        })
    }

    onPesquisar() {
        this.setState({ page: 0, first: 0 }, () => {
            this.pesquisar();
        })
    }

    handleSelectCardEntrada(cardName) {
        let filtro = "tipo==ENTRADA";
        this.pesquisarRSQLCardTotais(cardName, filtro);
    }

    handleSelectCardSaida(cardName) {
        let filtro = "tipo==SAIDA";
        this.pesquisarRSQLCardTotais(cardName, filtro);
    }

    pesquisarRSQLCardTotais(cardName, valorAplicadoNoFiltro) {
        const { cardTotalSelected } = this.state;

        if (cardTotalSelected === cardName) {
            this.setState({
                cardTotalSelected: "",
                valorCard: "",
                page: 0,
                first: 0
            }, () => {
                this.pesquisar();
            });
        } else {
            this.setState({
                cardTotalSelected: cardName,
                valorCard: valorAplicadoNoFiltro,
                page: 0,
                first: 0
            }, () => {
                this.pesquisar();
            });
        }
    }

    montarHeader() {
        const { interval, registros, valorPesquisa } = this.state;
        const { isMobile } = this.props;

        const dataInicialFormatada = interval.dataInicial && format(interval.dataInicial, 'dd/MM/yyyy');
        const dataFinalFormatada = interval.dataFinal && format(interval.dataFinal, 'dd/MM/yyyy');
        return (
            <span style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <span>Movimentações de estoque</span>
                <span>
                    <If test={registros && registros.length > 0}>
                        <span
                            title={`Imprimir relatório de ${dataInicialFormatada} até ${dataFinalFormatada}`}
                            style={styleBotaoImprimir}
                            onClick={() => {
                                asyncBaixarRelatorioMovimentacoesPorPeriodo(valorPesquisa, interval.dataInicial, interval.dataFinal, document => {
                                    baixarArquivo(document.data, `Movimentações de estoque de ${dataInicialFormatada} até ${dataFinalFormatada}`);
                                })
                            }}
                        >
                            <FaPrint size="15px" style={!isMobile && { marginRight: '5px' }} />
                            {!isMobile && <span>Imprimir</span>}
                        </span>
                    </If>
                </span>
            </span>
        )
    }

    handleClickExibirModalLancamentoEntrada() {
        const { valorPesquisa } = this.state;
        let produtoSelecionado = null;

        if (valorPesquisa && valorPesquisa.length === 1) {
            produtoSelecionado = valorPesquisa[0];
        }
        this.setState({ exibirModalLancamentoEntrada: true, produtoSelecionado })
    }

    handleClickExibirModalLancamentoSaida() {
        const { valorPesquisa } = this.state;
        let produtoSelecionado = null;

        if (valorPesquisa && valorPesquisa.length === 1) {
            produtoSelecionado = valorPesquisa[0];
        }
        this.setState({ exibirModalLancamentoSaida: true, produtoSelecionado })
    }

    render() {

        const {
            exibirModalLancamentoSaida,
            exibirModalLancamentoEntrada,
            cards,
            registros,
            totalRecords,
            first,
            size,
            sortField,
            sortOrder,
            valorPesquisa,
            optionsFiltroAvancado,
            descricaoFiltroAvancado,
            produtoSelecionado,
            registroSelecionado,
            geradoAPartirDeUmaVenda,
            cardTotalSelected,
        } = this.state;

        const permissoesLancamento = {
            podeInserir: this.state.podeInserirLancamento,
        };

        return (
            <>
                <Menu
                    model={[
                        {
                            label: 'Nova entrada',
                            icon: 'fa fa-plus',
                            command: () => this.handleClickExibirModalLancamentoEntrada(),
                            disabled: !permissoesLancamento.podeInserir
                        },
                        {
                            label: 'Nova saída',
                            icon: 'fa fa-plus',
                            command: () => this.handleClickExibirModalLancamentoSaida(),
                            disabled: !permissoesLancamento.podeInserir
                        }
                    ]}
                    popup={true}
                    ref={elemento => this.novoLacamento = elemento}
                />
                <Form header={this.montarHeader()}>
                    <FormActions>
                        <Button
                            label="Nova entrada"
                            className="p-button-success"
                            icon="fa fa-plus"
                            title='Inserir uma nova movimentação de entrada'
                            onClick={() => this.handleClickExibirModalLancamentoEntrada()}
                            podeInserir={permissoesLancamento.podeInserir}
                            style={{ margin: '5px' }}
                        />
                        <Button
                            label="Nova saída"
                            className="p-button-danger"
                            icon="fa fa-plus"
                            title='Inserir uma nova movimentação de saída'
                            onClick={() => this.handleClickExibirModalLancamentoSaida()}
                            podeInserir={permissoesLancamento.podeInserir}
                            style={{ margin: '5px' }}
                        />
                    </FormActions>

                    <FormMobileActions>
                        <Button
                            className='p-button-primary'
                            type='button'
                            label="Nova movimentação"
                            icon="fa fa-angle-down"
                            iconPos="right"
                            hidden={true}
                            style={{ marginBottom: '5px', marginLeft: '5px' }}
                            onClick={event => this.novoLacamento.toggle(event)}
                        />
                    </FormMobileActions>

                    <FormContent>
                        <Grid justifyCenter verticalAlignCenter>
                            <span style={{ padding: '12px' }}>
                                <DateInterval
                                    onChange={this.handleChangeInterval}
                                />
                            </span>

                            <InputSearchProdutos
                                onPesquisar={this.onPesquisar}
                                value={valorPesquisa}
                                onChange={value => this.setState({ valorPesquisa: value }, this.onPesquisar())}
                            />

                            <span style={{ padding: '12px' }}>
                                <PesquisaAvancada
                                    optionsFiltros={optionsFiltroAvancado}
                                    onPesquisarClick={this.onPesquisarFiltroAvancado}
                                    onChangeFiltroRsql={rsql => this.setState({ filtroAvancado: rsql })}
                                    onChangeDescricaoFiltro={e => this.setState({ descricaoFiltroAvancado: e })}
                                    liparFiltro={this.state.limparFiltroPesquisaAvancada}
                                />
                            </span>
                        </Grid>
                        <Grid justifyBetween>
                            <Col xs="12" sm="6" md="6" lg="3" xl="3">
                                <CardTotalizadorListagem
                                    name="cardSaldoAnterior"
                                    title="Saldo anterior"
                                    helpMessage="Soma de todas as movimentações antes do período selecionado"
                                    primaryColor={COLORS.blue}
                                    value={cards.saldoAnterior}
                                />
                            </Col>
                            <Col xs="12" sm="6" md="6" lg="3" xl="3">
                                <CardTotalizadorListagem
                                    name="cardEntrada"
                                    title="Quantidade de entradas"
                                    helpMessage="Soma de todas as movimentações de entrada realizadas no período selecionado"
                                    titleFiltro="Clique para filtrar pelas entradas realizadas"
                                    primaryColor={COLORS.green}
                                    value={cards.entradas}
                                    selectable
                                    selected={cardTotalSelected === "cardEntrada"}
                                    onSelect={this.handleSelectCardEntrada}
                                />
                            </Col>
                            <Col xs="12" sm="6" md="6" lg="3" xl="3">
                                <CardTotalizadorListagem
                                    name="cardSaida"
                                    title="Quantidade de saídas"
                                    helpMessage="Soma de todas as movimentações de saída realizadas no período selecionado"
                                    titleFiltro="Clique para filtrar pelas saídas realizadas"
                                    primaryColor={COLORS.red}
                                    value={cards.saidas}
                                    selectable
                                    selected={cardTotalSelected === "cardSaida"}
                                    onSelect={this.handleSelectCardSaida}
                                />
                            </Col>
                            <Col xs="12" sm="6" md="6" lg="3" xl="3">
                                <CardTotalizadorListagem
                                    name="cardSaldo"
                                    title="Saldo em estoque"
                                    helpMessage="Soma dos saldos de todas as movimentações. Esse valor é calculado somando todas as movimentações até o fim do período selecionado"
                                    primaryColor={COLORS.blue}
                                    value={cards.saldo}
                                />
                            </Col>
                        </Grid>
                        <Grid style={{ paddingTop: '10px' }} justifyCenter verticalAlignCenter>
                            <DescricaoFiltroAvancado texto={descricaoFiltroAvancado} />
                            <TabelaMovimentacoes
                                registros={registros}
                                totalRecords={totalRecords}
                                rows={size}
                                first={first}
                                sortField={sortField}
                                sortOrder={sortOrder}
                                setSortField={sortField => this.setState({ sortField })}
                                setSortOrder={sortOrder => this.setState({ sortOrder }, () => this.pesquisar())}
                                onEditItem={this.handleEditItem}
                                onRemoveItem={this.handleRemoveItem}
                                onPageChange={e => this.setState({ first: e.first, size: e.rows, page: e.page }, () => this.pesquisar())}
                            />
                        </Grid>
                    </FormContent>
                </Form>
                <If test={exibirModalLancamentoSaida}>
                    <ModalSaidaEstoque
                        atualizarListagem={this.pesquisar}
                        produto={produtoSelecionado}
                        registroSelecionado={registroSelecionado}
                        geradoAPartirDeUmaVenda={Boolean(geradoAPartirDeUmaVenda)}
                        onNovoClick={() => this.setState({ produtoSelecionado: null, geradoAPartirDeUmaVenda: null, registroSelecionado: null })}
                        visible={exibirModalLancamentoSaida}
                        onHide={this.onHideModalSaidaEstoque}
                    />
                </If>
                <If test={exibirModalLancamentoEntrada}>
                    <ModalEntradaEstoque
                        atualizarListagem={this.pesquisar}
                        produto={produtoSelecionado}
                        registroSelecionado={registroSelecionado}
                        onNovoClick={() => this.setState({ produtoSelecionado: null, registroSelecionado: null })}
                        visible={exibirModalLancamentoEntrada}
                        onHide={this.onHideModalEntradaEstoque}
                    />
                </If>
            </>
        );
    }
}

export default MovimentacoesEstoque;
