import React, { Component } from 'react';
import autoBind from 'react-autobind';
import ButtonCancelar, { estadosBotaoCancelar } from '../../../../../components/Button/ButtonCancelar';
import ButtonSalvar, { estadosBotaoSalvar } from '../../../../../components/Button/ButtonSalvar';
import Modal from '../../../../../components/Modal';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';


class ModalCropImage extends Component {

    constructor(props) {
        super(props);
        autoBind(this);
        this.state = {
            crop: {
                unit: 'px',
                width: 200,
                height: 127,
                aspect: 1.57 / 1,
            }
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.visible !== this.props.visible) {

        }
    }

    async confirmar() {
        this.props.onHide(await this.getCroppedImg(this.imageRef, this.state.crop, 'teste.png'))
    }

    getCroppedImg(image, crop, fileName) {
        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext('2d');

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );

        return new Promise((resolve, reject) => {
            canvas.toBlob(blob => {
                blob.name = fileName;
                resolve(blob);
            }, 'image/png', 1);
        });
    }

    render() {
        return (
            <Modal
                header='Ajustar imagem'
                visible={this.props.visible}
                onHide={this.props.onHide}
                styleModal={{ maxWidth: '800px' }}
            >
                <ButtonSalvar
                    estadoBotao={estadosBotaoSalvar.CONFIRMAR}
                    onClick={this.confirmar}
                />
                <ButtonCancelar
                    estadoBotao={estadosBotaoCancelar.VOLTAR}
                    onClick={() => this.props.onHide()}
                />
                <div style={{ display: "flex", justifyContent: "center" }}>
                    <ReactCrop
                        style={{ borderRadius: '5px' }}
                        src={this.props.imagemUrl}
                        crop={this.state.crop}
                        ruleOfThirds
                        onChange={(crop) => this.setState({ crop })}
                        onImageLoaded={(image) => this.imageRef = image}
                        keepSelection
                    />
                </div>
            </Modal>
        )
    }
}

export default ModalCropImage