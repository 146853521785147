import React, { Component } from 'react';
import { recursos, permissoes } from '../../../../common/constantes/autorizacao';
import { usuarioPossuiPermissao, buscarDadosLoginLocalStorage } from '../../../../common/autenticacao';
import autoBind from 'react-autobind/lib/autoBind';
import Col from '../../../../components/Col';
import Grid from '../../../../components/Grid';
import CardTotalizadorListagem from './components/CardTotalizadorListagem';
import PesquisaAvancada from '../../../../components/PesquisaAvancada';
import { optionsFiltroAvancado, ColorsCard } from './util/constantes';
import { construirUrl } from '../../../../common/rsql';
import { services } from '../../../../common/constantes/api';
import { salvarConfiguracaoUsuario, configuracoesUsuario } from '../../../../common/configuracaoUsuario';
import DateInterval from '../../../../components/DateInterval';
import TabelaVendasOrcamentos from './components/TabelaVendasOrcamentos';
import InputSearch from '../../../../components/Input/InputSearch';
import DescricaoFiltroAvancado from '../../../../components/DescricaoFiltroAvancado';
import { formatarParaPesquisarTiposEnumerados } from '../../../../common/manipulacaoDeString';
import Form from '../../../../components/Form';
import FormActions from '../../../../components/FormActions';
import FormContent from '../../../../components/FormContent';
import { formatISO } from 'date-fns';
import { confirm } from '../../../../components/Toast';
import { Link } from 'react-router-dom';
import { atualizarUrl } from '../../../util';
import { CADASTROURL, Status, Operacao } from './util/constantes';
import { montarMensagemToast } from './util/funcoes';
import { connect } from 'react-redux';
import { asyncDeleteVenda, asyncGetTotalizadores, asyncGetVenda, asyncGetVendasOrcamentos, asyncUpdateVenda } from './requests';
import Button from '../../../../components/Button';

class VendasOrcamentos extends Component {

    constructor(props) {

        super(props);

        autoBind(this);

        this.state = {
            registroSelecionado: null,
            podeInserir: usuarioPossuiPermissao(recursos.VENDAS_VENDAS, permissoes.INSERIR),

            tutorialVisible: false,
            cardTotalSelected: "",
            descricaoFiltroAvancado: '',
            valorPesquisa: "",
            valorCard: "",
            filtroData: "",
            filtroAvancado: "",
            cards: {
                orcamento: {
                    valor: 0,
                    quantidade: 0
                },
                cancelada: {
                    valor: 0,
                    quantidade: 0
                },
                venda: {
                    valor: 0,
                    quantidade: 0
                },
            },
            optionsFiltroAvancado: optionsFiltroAvancado,
            limparFiltroPesquisaAvancada: false,
            registros: [],
            totalRecords: 0,

            interval: {
                dataInicial: null,
                dataFinal: null
            },

            sortField: 'numero',
            sortOrder: -1,

            page: 0,
            rows: 0,
            size: 10,
            first: 0
        }
    }

    onPesquisarFiltroAvancado(e) {
        this.setState({ filtroAvancado: e, limparFiltroPesquisaAvancada: false }, () => {
            this.pesquisar();
        });
    }

    componentDidMount() {
        if (this.state.deveExibirTutorial !== false) {
            this.setState({ tutorialVisible: true })
            salvarConfiguracaoUsuario(configuracoesUsuario.EXIBIR_TUTORIAL_LISTAGENS, false, null, false)
        }
    }

    buscarFiltro() {
        const { filtroAvancado, filtroData, valorPesquisa, valorCard } = this.state;

        const valorTiposEnumerados = formatarParaPesquisarTiposEnumerados(valorPesquisa);

        let filtroRSQL = String("?query=(")
            .concat(`numero=contains="*${valorPesquisa}*",`)
            .concat(`status=contains="*${valorTiposEnumerados}*",`)
            .concat(`clienteNome=contains="*${valorPesquisa}*",`)
            .concat(`totalLiquido=contains="*${valorPesquisa}*")`)
            .concat(`;(${filtroData})`)

        if (filtroAvancado) {
            filtroRSQL = filtroRSQL.concat(`;(${filtroAvancado})`)
        }
        if (valorCard) {
            filtroRSQL = filtroRSQL.concat(`;(${valorCard})`)
        }

        return filtroRSQL;
    }

    async pesquisar() {
        const { page, size, sortField, sortOrder, interval } = this.state;
        const filtro = this.buscarFiltro();

        const url = construirUrl(`${services.GESTOR}/v1/vendas/resumo`, filtro || "?", size, page, sortOrder > 0 ? `${sortField},asc` : `${sortField},desc`);

        asyncGetTotalizadores(interval, ({ data: totais }) => {
            let cards = {};

            totais && totais.forEach(element => {
                if (element.status === Status.ORCAMENTO) {
                    cards.orcamento = {
                        quantidade: element.quantidade,
                        valor: element.valor
                    }
                } else if (element.status === Status.CANCELADA) {
                    cards.cancelada = {
                        quantidade: element.quantidade,
                        valor: element.valor
                    }
                } else if (element.status === Status.VENDA) {
                    cards.venda = {
                        quantidade: element.quantidade,
                        valor: element.valor
                    }
                }
            });
            this.setState({ cards })
        });

        asyncGetVendasOrcamentos(url, ({ data: vendas }) => {
            this.setState({
                registros: vendas.content,
                totalRecords: vendas.totalElements,
            })
        })
    }

    handleChangeInterval(interval) {
        this.setState({ page: 0, first: 0, interval: interval, filtroData: `emissao>=${formatISO(interval.dataInicial, { representation: "date" })};emissao<=${formatISO(interval.dataFinal, { representation: "date" })}` }, () => {
            this.pesquisar();
        })
    }

    onPesquisar() {
        this.setState({ page: 0, first: 0 }, () => {
            this.pesquisar();
        })
    }

    handleSelectCardOrcamento() {
        const card = Status.ORCAMENTO;
        const filtroRSQL = 'status==ORCAMENTO';
        this.pesquisarRSQLCardTotais(card, filtroRSQL);
    }

    handleSelectCardVenda() {
        const card = Status.VENDA;
        const filtroRSQL = 'status==VENDA';
        this.pesquisarRSQLCardTotais(card, filtroRSQL);
    }

    handleSelectCardCancelada() {
        const card = Status.CANCELADA;
        const filtroRSQL = 'status==CANCELADA';
        this.pesquisarRSQLCardTotais(card, filtroRSQL);
    }

    pesquisarRSQLCardTotais(cardSelected, valueCard) {
        const { cardTotalSelected } = this.state;

        if (cardTotalSelected === cardSelected) {
            this.setState({
                cardTotalSelected: "",
                valorCard: "",
                page: 0,
                first: 0
            }, () => {
                this.pesquisar();
            });
        } else {
            this.setState({
                cardTotalSelected: cardSelected,
                valorCard: valueCard,
                page: 0,
                first: 0
            }, () => {
                this.pesquisar();
            });
        }
    };

    handleEditItem(item) {
        atualizarUrl(this.props.history, `${CADASTROURL}/${item.id}`)
    }

    isVenda(item) {
        return String(item.status) === Status.VENDA;
    }

    handleRemoveItem(item) {
        let isVenda = this.isVenda(item);
        let numero = item.numero;

        confirm('Atenção', `Você tem certeza que deseja <b>excluir</b> ${isVenda ? 'a venda ' : 'o orçamento '} número ${numero}?`, () => {
            const mensagem = montarMensagemToast(item.status, Operacao.EXCLUIR);
            asyncDeleteVenda(item.id, mensagem, () => {
                this.pesquisar();
            });
        });
    }

    handlePrintItem(item) {
        const organizacaoId = buscarDadosLoginLocalStorage().organizacao.id
        window.open(`${services.GESTOR}/v1/vendas/${item.id}/organizacoes/${organizacaoId}/imprimir`, "_blank")
    }

    handleCancelItem(item) {
        confirm('Atenção', `Você tem certeza que deseja <b>cancelar</b> ${this.isVenda(item) ? 'a venda' : 'o orçamento'} número ${item.numero}?`, () => {
            asyncGetVenda(item.id, ({ data: venda }) => {
                const mensagem = montarMensagemToast(item.status, Operacao.EDITAR);
                asyncUpdateVenda({ ...venda, status: Status.CANCELADA }, mensagem, () => {
                    this.pesquisar();
                })
            });
        });

    }

    handleGenerateSale(item) {
        asyncGetVenda(item.id, ({ data: venda }) => {
            const mensagem = montarMensagemToast(item.status, Operacao.EDITAR);
            asyncUpdateVenda({ ...venda, status: Status.VENDA }, mensagem, () => {
                this.pesquisar();
            })
        });
    }

    handleGenerateBudget(item) {
        asyncGetVenda(item.id, ({ data: venda }) => {
            const mensagem = montarMensagemToast(item.status, Operacao.EDITAR);
            asyncUpdateVenda({ ...venda, status: Status.ORCAMENTO }, mensagem, () => {
                this.pesquisar();
            })
        });
    }

    render() {

        const {
            cards,
            registros,
            totalRecords,
            first,
            size,
            sortField,
            sortOrder,
            valorPesquisa,
            optionsFiltroAvancado,
            descricaoFiltroAvancado,
            cardTotalSelected,
            podeInserir,
        } = this.state;

        const { isMobile } = this.props;

        return (
            <Form header="Vendas e orçamentos">
                <FormActions>
                    <Link to={podeInserir ? { pathname: "/vendas/cadastro", state: { venda: true } } : { pathname: "/vendas" }}>
                        <Button
                            label="Nova venda"
                            className="p-button-success"
                            icon="fa fa-plus"
                            title='Inserir uma nova venda'
                            onClick={null}
                            podeInserir={podeInserir}
                            style={{ margin: '5px' }}
                        />
                    </Link>
                    <Link to={podeInserir ? { pathname: "/vendas/cadastro", state: { venda: false } } : { pathname: "/vendas" }}>
                        <Button
                            label="Novo orçamento"
                            className="p-button-primary"
                            icon="fa fa-plus"
                            title='Inserir um novo orçamento'
                            onClick={null}
                            podeInserir={podeInserir}
                            style={{ margin: '5px' }}
                        />
                    </Link>
                </FormActions>

                <FormContent>
                    <Grid justifyCenter verticalAlignCenter>
                        <span style={{ padding: '12px' }}>
                            <DateInterval
                                onChange={this.handleChangeInterval}
                            />
                        </span>
                        <InputSearch
                            onPesquisar={this.onPesquisar}
                            value={valorPesquisa}
                            onChange={value => this.setState({ valorPesquisa: value })}
                        />
                        <span style={{ padding: '12px' }}>
                            <PesquisaAvancada
                                optionsFiltros={optionsFiltroAvancado}
                                onPesquisarClick={this.onPesquisarFiltroAvancado}
                                onChangeFiltroRsql={rsql => this.setState({ filtroAvancado: rsql })}
                                onChangeDescricaoFiltro={e => this.setState({ descricaoFiltroAvancado: e })}
                                liparFiltro={this.state.limparFiltroPesquisaAvancada}
                            />
                        </span>
                    </Grid>
                    <Grid justifyBetween>
                        <Col xs="12" sm="6" md="6" lg="3" xl="3">
                            <CardTotalizadorListagem
                                name="cardEmOrcamento"
                                title="Orçamentos"
                                helpMessage={isMobile ? "" : "Exibe o valor total e o número de orçamentos em aberto no período selecionado"}
                                titleFiltro="Clique para filtrar pelos orçamentos abertos"
                                colors={ColorsCard.ORCAMENTO}
                                value={cards.orcamento.valor}
                                selectable
                                numberOfElements={cards.orcamento.quantidade}
                                selected={cardTotalSelected === Status.ORCAMENTO}
                                onSelect={this.handleSelectCardOrcamento}
                            />
                        </Col>
                        <Col xs="12" sm="6" md="6" lg="3" xl="3">
                            <CardTotalizadorListagem
                                name="cardVendas"
                                title="Vendas"
                                helpMessage={isMobile ? "" : "Exibe o valor total e o número de vendas no período informado"}
                                titleFiltro="Clique para filtrar pelas vendas"
                                colors={ColorsCard.VENDA}
                                value={cards.venda.valor}
                                selectable
                                numberOfElements={cards.venda.quantidade}
                                selected={cardTotalSelected === Status.VENDA}
                                onSelect={this.handleSelectCardVenda}
                            />
                        </Col>
                        <Col xs="12" sm="6" md="6" lg="3" xl="3">
                            <CardTotalizadorListagem
                                name="cardCanceladas"
                                title="Cancelados"
                                helpMessage={isMobile ? "" : "Exibe o valor total e o número de orçamentos e vendas canceladas no período"}
                                titleFiltro="Clique para filtrar pelos orçamentos e vendas canceladas"
                                colors={ColorsCard.CANCELADA}
                                value={cards.cancelada.valor}
                                selectable
                                numberOfElements={cards.cancelada.quantidade}
                                selected={cardTotalSelected === Status.CANCELADA}
                                onSelect={this.handleSelectCardCancelada}
                            />
                        </Col>
                        <Col xs="12" sm="6" md="6" lg="3" xl="3">
                            <CardTotalizadorListagem
                                name="cardTotalizador"
                                title="Previsão"
                                helpMessage={isMobile ? "" : "Exibe o valor resultante da soma dos orçamentos e vendas do período"}
                                colors={ColorsCard.TOTAL}
                                value={cards.venda.valor + cards.orcamento.valor}
                                numberOfElements={cards.venda.quantidade + cards.orcamento.quantidade}
                            />
                        </Col>
                    </Grid>
                    <Grid style={{ paddingTop: '10px' }} justifyCenter verticalAlignCenter>
                        <DescricaoFiltroAvancado texto={descricaoFiltroAvancado} />
                        <TabelaVendasOrcamentos
                            registros={registros}
                            totalRecords={totalRecords}
                            rows={size}
                            first={first}
                            sortField={sortField}
                            sortOrder={sortOrder}
                            setSortField={sortField => this.setState({ sortField })}
                            setSortOrder={sortOrder => this.setState({ sortOrder }, () => this.pesquisar())}
                            onEditItem={this.handleEditItem}
                            onRemoveItem={this.handleRemoveItem}
                            onPrint={this.handlePrintItem}
                            onReject={this.handleCancelItem}
                            onGenerateSale={this.handleGenerateSale}
                            onGenerateBudget={this.handleGenerateBudget}
                            onPageChange={e => this.setState({ first: e.first, size: e.rows, page: e.page }, () => this.pesquisar())}
                            history={this.props.history}
                        />
                    </Grid>
                </FormContent>
            </Form>
        );
    }
}

const mapStateToProps = state => ({
    isMobile: state.dispositivo.isMobile,
})

export default connect(mapStateToProps)(VendasOrcamentos);
