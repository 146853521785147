import { montarLabelPessoa } from "../../../../../../components/Select/SingleSelectPessoa/utils";

export function converterNFeParaFormulario(valuesApi, credencial) {
    const values = removerPropriedadesNaoUtilizadas(valuesApi)
    return {
        ...values,
        serie: values.serie === 0 ? credencial.organizacao.fiscalNfe?.serieNfe : values.serie,
        dest: converterDestParaFormulario(values),
        det: converterDetParaFormulario(values),
        transp: converterTranspParaFormulario(values),
        total: converterTotalParaFormulario(values),
        exporta: converterExportaParaFormulario(values),
        infIntermed: converterInfIntermedParaFormulario(values)
    }
}

function converterInfIntermedParaFormulario(values) {
    if (!values.infIntermed) {
        return {};
    }
    return {
        ...values.infIntermed,
        pessoa: values.infIntermed.pessoa ? { label: values.infIntermed.pessoa.nome + ' - ' + values.infIntermed.pessoa.cnpj, value: values.infIntermed.pessoa.id } : null,
    }
}

function converterDestParaFormulario(values) {
    if (!values.dest) {
        return {};
    }
    return {
        ...values.dest,
        enderDest: converterEnderDestParaFormulario(values)
    }
}

function converterExportaParaFormulario(values) {

    if (!values.exporta) {
        return {};
    }
    const exporta = values.exporta
    return {
        ...values.exporta,
        ufSaidaPais: exporta.ufSaidaPais ? { label: exporta.ufSaidaPais.nome + ' - ' + exporta.ufSaidaPais.sigla, value: exporta.ufSaidaPais.id } : null,
    }
}

function converterEnderDestParaFormulario(values) {
    let enderDest = null;
    let isBrasil = false;

    if (values.dest && values.dest.enderDest) {
        enderDest = values.dest.enderDest
        isBrasil = enderDest.idPais && enderDest.idPais === "34e63b2e-c596-f34f-824d-bfd27eb62fa8"
    }

    if (!values.dest || !enderDest) {
        return {};
    }

    if (isBrasil) {
        return {
            ...enderDest,
            municipio: enderDest.idMun ? { label: enderDest.xMun + ' - ' + enderDest.UF, value: enderDest.idMun } : null,
            pais: enderDest.idPais ? { label: enderDest.xPais, value: enderDest.idPais } : null,
        }
    } else {
        return {
            ...enderDest,
            municipio: { label: 'EXTERIROR - EX', value: '8d1f5be6-89b3-479b-9f84-8d9b9a1f0ced' },
            pais: enderDest.idPais ? { label: enderDest.xPais, value: enderDest.idPais } : null,
        }
    }

}

function converterDetParaFormulario(values) {
    return values.det.map(item => {
        return {
            ...item,
            prod: converterProdParaFormulario(item.prod),
            imposto: converterImpostoParaFormulario(item.imposto)
        }
    })
}

function converterProdParaFormulario(prod) {
    return {
        ...prod,
        NCM: prod.idNCM ? { label: prod.NCM + ' - ' + prod.xNCM, value: prod.idNCM } : null,
        CFOP: prod.idCFOP ? { label: prod.CFOP + ' - ' + prod.xCFOP, value: prod.idCFOP } : null,
        CEST: prod.idCEST ? { label: prod.CEST + ' - ' + prod.xCEST, value: prod.idCEST } : null,
        unidadeMedida: prod.idCom ? { label: prod.uCom + ' - ' + prod.xCom, value: prod.idCom } : null,
    }
}

function converterImpostoParaFormulario(imposto) {
    return {
        ...imposto,
        ICMSUFDest: converterICMSUFDestParaFormulario(imposto.ICMSUFDest),
        icms: converterIcmsParaFormulario(imposto.icms)
    }
}

function converterICMSUFDestParaFormulario(ICMSUFDest) {
    if (!ICMSUFDest) {
        return {};
    }

    return {
        ...ICMSUFDest
    }
}


function converterIcmsParaFormulario(icms) {
    if (!icms) {
        return {};
    }

    return {
        ...icms,
        modBCST: icms.modBCST ? icms.modBCST : "MARGEM_VALOR_AGREGADO",
        modBC: icms.modBC ? icms.modBC : "MARGEM_VALOR_AGREGADO"
    }
}

function converterTranspParaFormulario(values) {
    if (!values.transp) {
        return {
            utilizaTransportador: false
        }
    }

    return {
        ...values.transp,
        vol: converterVolParaFormulario(values.transp.vol),
        transporta: converterTransportaParaFormulario(values.transp.transporta),
        veicTransp: converterVeicTranspParaFormulario(values.transp.veicTransp),
        pessoa: values.transp.pessoa ? { label: montarLabelPessoa(values.transp.pessoa), value: values.transp.pessoa.id } : null
    }
}


function converterVolParaFormulario(vol) {
    if (!vol || vol.length === 0) {
        return {};
    }

    return {
        ...vol[0]
    }
}

function converterTransportaParaFormulario(transporta) {
    if (!transporta) {
        return {};
    }

    return {
        ...transporta,
        municipio: transporta.idMun ? { label: transporta.xMun + ' - ' + transporta.UF, value: transporta.idMun } : null
    }
}

function converterVeicTranspParaFormulario(veicTransp) {
    if (!veicTransp) {
        return {};
    }

    return {
        ...veicTransp,
        UF: veicTransp.idUf ? { label: veicTransp.xUf + ' - ' + veicTransp.UF, value: veicTransp.idUf } : null
    }
}

function converterTotalParaFormulario(values) {
    if (!values.total) {
        return {};
    }

    return {
        ...values.total,
        ICMSTot: converterICMSTotParaFormulario(values.total.ICMSTot)
    }
}

function converterICMSTotParaFormulario(ICMSTot) {
    if (!ICMSTot) {
        return {};
    }

    return {
        ...ICMSTot
    }
}

function removerPropriedadesNaoUtilizadas(values) {
    return values
}