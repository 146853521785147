import React, { Component } from 'react'
import Col from '../Col';
import propTypes from "prop-types";

const style = {
    padding: '0px 10px',
    fontSize: '11px',
    color: 'red'
}

export default class DescricaoFiltroAvancado extends Component {

    renderTexto() {

        const { texto } = this.props

        return texto && texto.split(`\\n`).map((item, i) => {
            if(item.trim().length > 0){
                return <li key={i}>{item}</li>;
            }
            return null
            
        });
    }

    render() {
        return (
            <Col style={style}>
                <ul style={{margin: '0px', paddingLeft: '15px'}}>
                    {this.renderTexto()}
                </ul>
            </Col>
        )
    }
}

DescricaoFiltroAvancado.propTypes = {

    /** Texto do componente */
    texto: propTypes.string
}
