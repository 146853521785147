import React, { Component } from 'react'

class ToolbarItensLeft extends Component {
    render() {

        return (
            <div className={`p-toolbar-group-left`} style={this.props.style}>
                {this.props.children}
            </div>
        )
    }
}

export default ToolbarItensLeft