import React, { Component } from 'react';
import autoBind from 'react-autobind'

import { DataTable } from 'primereact/components/datatable/DataTable';
import { Column } from 'primereact/column';
import { Paginator } from 'primereact/paginator';

import NenhumRegistroEncontrado from '../../../../../components/NenhumRegistroEncontrado';

import { formatarMonetario } from '../../../../../common/mascara';
import { permissoes, recursos } from '../../../../../common/constantes/autorizacao';
import Button from '../../../../../components/Button';
import { usuarioPossuiPermissao } from '../../../../../common/autenticacao';
import { Badge } from '../../../../../components/Badge';

const styleColumnOverflowElipsis = {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
}

const styleButton = {
    borderRadius: '50%',
    padding: '5px',
    width: '30px',
    height: '30px',
    margin: '2px'
};

const Colors = {
    bgCompra: '#dcedc8',
    textCompra: '#1b5e20',
    bgVenda: '#ffcdd2',
    textVenda: '#b71c1c',
    bgSaldoInicial: '#f6fab9',
    textSaldoInicial: '#996a13'
}

class TableNegociacoes extends Component {

    constructor(props) {
        super(props);
        autoBind(this);

        this.state = {
            podeExcluirNegociacao: usuarioPossuiPermissao(recursos.INVESTIDOR_NEGOCIACOES, permissoes.EXCLUIR),
        }
    }

    atualizarOrdenacao(e) {
        this.props.setSortField(e.sortField);
        this.props.setSortOrder(e.sortOrder);
    }

    renderAcoesField(el) {
        const { podeExcluirNegociacao } = this.state;
        let titleEditar = "Editar";
        let titleExcluir = "Excluir";
        let disableBtnEditar = false;
        let disableBtnExcluir = false;

        if (!podeExcluirNegociacao) {
            titleExcluir = "Você não possui permissão para executar essa ação"
            disableBtnExcluir = true;
        };

        return (
            <span>
                <Button
                    style={styleButton}
                    className="p-button p-button-primary"
                    icon="fa fa-pencil"
                    title={titleEditar}
                    disabled={disableBtnEditar}
                    onClick={() => this.props.onEditItem(el)}
                />
                <Button
                    style={styleButton}
                    className="p-button p-button-danger"
                    icon="fa fa-trash"
                    title={titleExcluir}
                    disabled={disableBtnExcluir}
                    onClick={() => this.props.onRemoveItem(el)}
                />
            </span>
        );
    }

    renderMercado(row) {
        switch (row.mercado) {
            case 'VISTA': {
                return 'A Vista'
            }
            case 'FRACIONARIO': {
                return 'Fracionário'
            }
            default: return row.mercado
        }
    }

    renderOperacao(row) {
        switch (row.operacao) {
            case 'COMPRA': {
                return Badge(Colors.textCompra, Colors.bgCompra, "Compra");
            }
            case 'VENDA': {
                return Badge(Colors.textVenda, Colors.bgVenda, "Venda");
            }
            case 'SALDO_INICIAL': {
                return Badge(Colors.textSaldoInicial, Colors.bgSaldoInicial, "Saldo Inicial");
            }
            default: return row.operacao
        }
    }

    render() {

        const { registros, sortField, sortOrder, onPageChange, first, rows, totalRecords } = this.props;

        return (
            <>
                <DataTable
                    className="table-light"
                    value={registros}
                    responsive
                    sortField={sortField}
                    sortOrder={sortOrder}
                    onSort={this.atualizarOrdenacao}
                    emptyMessage={<NenhumRegistroEncontrado message="Nenhuma negociação encontrada" />}
                >
                    <Column
                        header="Data"
                        field="data"
                        sortable={true}
                        body={(row) => <span title={row.data}>{row.data}</span>}
                        style={styleColumnOverflowElipsis}
                    />

                    <Column
                        header="Ativo"
                        field="ativoCodigo"
                        sortable={true}
                        body={(row) => <b title={row.ativoCodigo} >{row.ativoCodigo}</b>}
                        style={{width: '110px' }}
                    />

                    <Column
                        header="Corretora (conta)"
                        field="corretoraConta"
                        sortable={true}
                        body={(row) => <span title={row.corretoraConta}>{row.corretoraConta}</span>}
                        style={styleColumnOverflowElipsis}
                    />

                    <Column
                        header="Operacao"
                        field="operacao"
                        body={this.renderOperacao}
                        sortable={true}
                    />

                    <Column
                        header={"Quantidade"}
                        field="quantidade"
                        sortable={true}
                        body={(row) => <b title={row.quantidade}>{row.quantidade}</b>}
                        style={styleColumnOverflowElipsis}
                    />

                    <Column
                        header="Preço"
                        field="preco"
                        sortable={true}
                        body={(row) => <span title={formatarMonetario(row.preco)}>{formatarMonetario(row.preco)} </span>}
                        style={styleColumnOverflowElipsis}
                    />

                    <Column
                        header="Total"
                        field="valorTotal"
                        sortable={true}
                        body={(row) => <b title={formatarMonetario(row.valorTotal)}>{formatarMonetario(row.valorTotal)}</b>}
                        style={styleColumnOverflowElipsis}
                    />
                    <Column
                        header="Ações"
                        body={this.renderAcoesField}
                        style={{ width: '100px' }}
                    />

                </DataTable>
                <Paginator
                    className='paginator-light'
                    rowsPerPageOptions={[10, 25, 50]}
                    totalRecords={totalRecords}
                    rows={rows}
                    first={first}
                    onPageChange={onPageChange}
                    rightContent={<div style={{ margin: '5px' }}>{totalRecords > 0 ? `${totalRecords} registro(s) encontrado(s)` : null}</div>}
                />
            </>
        )
    }

}





export default TableNegociacoes;