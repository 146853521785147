import React, { Component } from 'react';
import Select from 'react-select';
import autoBind from 'react-autobind';
import propTypes from 'prop-types';
import { getColStyle, buscarTitle, buscarPlaceholder } from './utils/validacoes';
import Col from '../../Col';
import { renderizarValidacao } from '../../../common/tratamentoDeErro/validacoesDeCampos';
import { buscarDisabledDeAcordoComAsPermissoes } from '../../../common/autorizacao/manipulacaoDeComponentes';
import If from '../../If';
import NormalButton from '../../Button';
import { usuarioPossuiModulos } from '../../../common/autenticacao';
import { connect } from 'react-redux';

const customStyles = {
    control: (provided, state) => {
        return {
            ...provided,
            height: !state.hasValue || !state.isMulti ? 33 : undefined,
            border: state.selectProps.errors && state.selectProps.touched ? '1px solid #ff0000 !important' : '1px solid #a6a6a6 !important',
            backgroundColor: '#fff',
            opacity: state.isDisabled ? '0.5' : '1',
            ...dot(state.selectProps.errors && state.selectProps.touched)
        }
    },
    menu: style => ({
        ...style,
        marginTop: 0,
        marginBottom: 0
    }),
    menuList: (styles) => ({
        ...styles,
        maxHeight: 200
    }),

    multiValue: (base, state) => {
        return state.data.isFixed ? { ...base, backgroundColor: 'gray' } : base;
    },

    multiValueLabel: (styles, state) => {
        return state.data.isFixed ?
            { ...styles, fontWeight: 'bold', color: 'white', paddingRight: 6 } :
            {
                ...styles,
                width: state.data.label && (state.data.label.length >= 20) ? '225px' : '100%',
                wordWrap: 'break-word',
                textOverflow: 'ellipsis'
            }
    },
    multiValueRemove: (base, state) => {
        return state.data.isFixed ? { ...base, display: 'none' } : base;
    },
}

const buttonStyle = {
    margin: "5px",
    width: "23px",
    height: "23px",
    borderRadius: "3px",
};

const dot = (errors) => ({
    ':hover': {
        border: errors ? '1px solid #ff0000 !important' : '1px solid black !important',
        cursor: 'pointer'
    },
});

class Dropdown extends Component {
    render() {

        const { field, form, ...rest } = this.props;
        return (
            <InternalDropdown
                {...field}
                {...rest}
                dirty={form.dirty}
                errors={form.errors[field.name]}
                touched={Boolean(form.touched[field.name])}
            />
        );
    }
}

export class InternalDropdown extends Component {

    constructor(props) {
        super(props);

        autoBind(this);

        this.state = {
            inputValue: '',
            validouModulo: true
        };
    }

    componentDidMount() {
        if (this.props.modulosEspecificos)
            this.setState({ validouModulo: usuarioPossuiModulos(this.props.modulosEspecificos) })
    }

    async handleInputChange(inputValue) {
        await this.setState({ inputValue });
    }

    onBlur(e) {
        this.props.onBlur && this.props.onBlur({ target: this.props })
    }

    async onChange(value) {

        const { isMulti } = this.props

        if (isMulti) {
            await this.onChangeMulti(value)
        } else {
            return this.onChangeSingle(value)
        }
    }

    async onChangeMulti(value) {

        if (value) {
            const valorExportar = value.map(valor => valor.value)

            await this.props.onChange(valorExportar);
        } else {
            await this.props.onChange([]);
        }
        this.onBlur()
    }

    async onChangeSingle(value) {
        await this.props.onChange(value || { value: null });
        this.onBlur()
    }

    getValue() {
        const { isMulti, value, options } = this.props

        if (isMulti)
            return this.getValueMulti(value, options)
        else
            return this.getValueSingle(value, options)
    }

    getValueMulti(value, options) {
        if (value && options) {
            let novoArrayOpcoes = options.filter(element => {
                return value.includes(element.value);
            })
            return novoArrayOpcoes;
        }
    }

    getValueSingle(value, options) {
        for (let i = 0; i < options.length; i++) {
            const opcao = options[i];
            if (opcao.value === value) {
                return opcao;
            }
        }
        return value;
    }

    montarLabel() {
        const { obrigatorio, label } = this.props;

        if (obrigatorio) {
            return <label title={this.props.helpMessage}> {label} <b style={{ fontSize: '18px', lineHeight: '5px' }} > *</b> </label>
        }

        return <label title={this.props.helpMessage}> {label} </label>
    }


    render() {

        if (!this.state.validouModulo)
            return null

        const { sm, md, lg, xl, showClear, errors, touched, podeInserir, podeEditar,
            estadoCadastro, disabled, podeVisualizar, placeholder, name, options, isMulti,
            noOptionsMessage,
            menuPlacement,
            className,
            classNamePrefix,
            col,
            esconderBotao,
            botaoIcon,
            titleBotao,
            desabilitarBotao,
            botaoColor,
            onClickModal,
            filterOption,
            autoFocus,
            colClassName,
            isSearchable,
            isSearchableOnMobile,
            isMobile,
            warning,
            backspaceRemovesValue,
            closeMenuOnSelect,
        } = this.props;

        const { inputValue } = this.state;

        const desabilitarSelect = buscarDisabledDeAcordoComAsPermissoes(podeInserir, podeEditar, estadoCadastro, disabled)
        const inputPlaceholder = buscarPlaceholder(podeVisualizar, placeholder);
        const value = this.getValue()

        return (
            <Col col={col} sm={sm} md={md} lg={lg} xl={xl}
                className={colClassName}
                style={getColStyle(this.props)}
            >
                {this.montarLabel()}

                <span title={buscarTitle(this.props)}>
                    <div className="p-inputgroup">
                        <Select
                            styles={customStyles}
                            inputValue={inputValue}
                            isClearable={showClear}
                            backspaceRemovesValue={backspaceRemovesValue}
                            options={options}
                            closeMenuOnSelect={closeMenuOnSelect}
                            onInputChange={this.handleInputChange}
                            isDisabled={desabilitarSelect}
                            placeholder={inputPlaceholder}
                            isMulti={isMulti}
                            value={value}
                            name={name}
                            onChange={this.onChange}
                            onBlur={this.onBlur}
                            noOptionsMessage={noOptionsMessage}
                            menuPlacement={menuPlacement}
                            className={className}
                            classNamePrefix={classNamePrefix}
                            errors={errors}
                            touched={touched}
                            filterOption={filterOption}
                            autoFocus={autoFocus}
                            isSearchable={!isSearchableOnMobile && isMobile ? false : isSearchable}
                        />
                        <If test={!esconderBotao}>
                            <span style={{ alignItems: 'center', display: 'flex' }}>
                                <NormalButton
                                    icon={botaoIcon}
                                    title={titleBotao}
                                    style={buttonStyle}
                                    disabled={desabilitarBotao}
                                    color={botaoColor}
                                    onClick={onClickModal}
                                />
                            </span>
                        </If>
                    </div>
                    {renderizarValidacao(errors, touched, warning)}
                </span>
            </Col>
        )
    }
}

InternalDropdown.defaultProps = {
    noOptionsMessage: () => 'Nenhum elemento encontrado',
    menuPlacement: 'auto',
    className: 'react-select-base',
    classNamePrefix: 'reactSelect',
    podeVisualizar: true,
    showClear: true,
    isMulti: false,
    esconderBotao: true,
    touched: true,
    backspaceRemovesValue: true,
    closeMenuOnSelect: true
}

InternalDropdown.propTypes = {
    /** Quando definido como true, os resultados de loadOptions() serão carregados automaticamente antes do usuário clicar para pesquisar.  */
    defaultOptions: propTypes.bool,

    /** Quando definido como true, os dados carregados serão armazenados em cache. O cache permanecerá até o valor cacheOptions sofrer alterações. */
    cacheOptions: propTypes.bool,

    /** Função executada quando o valor do input altera de estado. */
    onInputChange: propTypes.func,

    /** Função que retorna uma Promisse, que é o conjunto de opções a ser usado quando esta é resolvida. É executada assim que o componente é montado e a cada vez que o usuário filtrar a pesqusia. */
    loadOptions: propTypes.func,

    /** Tamanho padrão da coluna utilizado em dispositivos muito pequenos (0 a 12) */
    col: propTypes.string,

    /** Desabilita a pesquisa no mobile */
    isSearchableOnMobile: propTypes.bool
}

const mapStateToProps = state => ({
    isMobile: state.dispositivo.isMobile,
})

export default connect(mapStateToProps)(Dropdown);
