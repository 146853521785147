import React, { Component } from 'react'
import ButtonNovo from '../../../components/Button/ButtonNovo'
import { recursos, permissoes } from '../../../common/constantes/autorizacao';
import { formatarParaPesquisarTiposEnumerados, removerCaracteres } from '../../../common/manipulacaoDeString';
import Grid from '../../../components/Grid';
import autoBind from 'react-autobind';
import { asyncGetPesquisaOrganizacoes, asyncDeleteOrganizacao } from './requests'
import ButtonEditarTable from '../../../components/Button/ButtonEditarTable';
import ButtonExcluirTable from '../../../components/Button/ButtonExcluirTable';
import { atualizarUrl } from '../../util';
import InputSearch from '../../../components/Input/InputSearch';
import { inserirMascara } from '../../../common/mascara';
import { formatos } from '../../../common/constantes/mascaras';
import { Column } from 'primereact/column';
import { usuarioPossuiPermissao, buscarDadosLoginLocalStorage } from '../../../common/autenticacao';
import { construirUrl } from '../../../common/rsql';
import { services } from '../../../common/constantes/api';
import { confirm } from '../../../components/Toast';
import Form from '../../../components/Form';
import FormActions from '../../../components/FormActions';
import FormContent from '../../../components/FormContent';
import { DataTable } from 'primereact/components/datatable/DataTable';
import { Paginator } from 'primereact/paginator';
import NenhumRegistroEncontrado from '../../../components/NenhumRegistroEncontrado';

class AgentesAutorizados extends Component {

    constructor(props) {
        super(props)

        autoBind(this)

        this.state = {
            podeInserir: usuarioPossuiPermissao(recursos.AGENTES_AUTORIZADOS, permissoes.INSERIR),
            podeExcluir: usuarioPossuiPermissao(recursos.AGENTES_AUTORIZADOS, permissoes.EXCLUIR),
            valorPesquisa: '',
            registros: [],
            sortField: 'nome',
            sortOrder: 1,
            page: 0,
            rows: 10,
            totalElements: 0,
            idOrganizacaoLogada: null
        }
    }

    componentDidMount() {
        this.setState({ idOrganizacaoLogada: buscarDadosLoginLocalStorage().organizacao.id })
        this.pesquisar()
    }

    buscarFiltro() {
        const { valorPesquisa } = this.state
        const pesquisaCodigo = removerCaracteres(valorPesquisa, ['.', '/', '-'])
        const pesquisarSituacao = formatarParaPesquisarTiposEnumerados(valorPesquisa);

        return `?query=(nome=contains="*${valorPesquisa}*",cpf=contains="*${pesquisaCodigo}*",cnpj=contains="*${pesquisaCodigo}*",email=contains="*${valorPesquisa}*",situacao=contains="${pesquisarSituacao}*")`
    }

    async pesquisar() {
        const { page, sortField, sortOrder, rows } = this.state
        const filtro = this.buscarFiltro();
        const url = construirUrl(`${services.GESTOR}/v1/agentes_autorizados/resumo`,
            filtro, rows, page, sortOrder > 0 ? `${sortField},asc` : `${sortField},desc`)

        asyncGetPesquisaOrganizacoes(url, ({ data: agentes }) => {

            this.setState({
                registros: agentes.content,
                totalElements: agentes.totalElements
            })
        })
    }

    onPesquisar() {
        this.setState({ page: 0 }, () => {
            this.pesquisar();
        })
    }

    onPageChange(e) {
        this.setState({ page: e.page, rows: e.rows }, () => {
            this.pesquisar()
        })
    }

    onSort(e) {
        this.setState({ sortField: e.sortField, sortOrder: e.sortOrder }, () => {
            this.pesquisar()
        })
    }

    onEditar(row) {
        atualizarUrl(this.props.history, '/agentes_autorizados/cadastro', row.id)
    }

    onExcluir(row) {
        confirm('Confirmação', 'Deseja excluir o registro?', () => {
            this.asyncExcluirRegistro(row);
        })
    }

    asyncExcluirRegistro(registro) {
        asyncDeleteOrganizacao(registro.id, () => {
            this.pesquisar();
        })
    }

    renderOpcoes(row) {
        return (
            <>
                <ButtonEditarTable
                    onClick={() => this.onEditar(row)}
                />
                <ButtonExcluirTable
                    title={null}
                    disabled={false}
                    podeExcluir={this.state.podeExcluir}
                    onClick={() => this.onExcluir(row)}
                />
            </>
        )
    }

    renderInativado(row, content) {
        const style = { fontStyle: 'italic', opacity: '0.8' };

        if (row.inativacao) {
            return <span style={style}>{content} </span>
        }
        return content;
    }

    renderNome(row) {
        return this.renderInativado(row, row.nome);
    }

    renderCpfCnpj(row) {
        let content = '';
        if (row.cpf) {
            content = inserirMascara(row.cpf, formatos.CPF);
        }
        if (row.cnpj) {
            content = inserirMascara(row.cnpj, formatos.CNPJ);
        }

        return this.renderInativado(row, content);
    }

    renderEmail(row) {
        return this.renderInativado(row, row.email);
    }

    renderSituacao(row) {
        const label = row.situacao === 'ATIVO' ? 'Ativo' : 'Inativo'
        const strongColor = row.situacao === 'ATIVO' ? 'green' : '#da1f1f'
        const lightColor = row.situacao === 'ATIVO' ? '#dcedc8' : '#ffcdd2'

        return (
            <span style={{
                backgroundColor: lightColor,
                color: strongColor,
                fontWeight: 'bold',
                fontSize: '13px',
                borderRadius: '3px',
                padding: '3px 10px',
            }}>
                <span style={{ width: '100%', textAlign: 'center' }}>{label}</span>
            </span>
        )
    }

    render() {

        const { page, totalElements, podeInserir, sortField, sortOrder, valorPesquisa, registros, rows } = this.state

        return (
            <Form header="Agentes autorizados">
                <FormActions>
                    <ButtonNovo
                        label='Novo agente'
                        onClick={() => this.props.history.push('/agentes_autorizados/cadastro')}
                        podeInserir={podeInserir}
                    />
                </FormActions>
                <FormContent>
                    <Grid justifyCenter>
                        <InputSearch
                            onPesquisar={this.onPesquisar}
                            value={valorPesquisa}
                            onChange={value => this.setState({ valorPesquisa: value })}
                        />
                    </Grid>
                    <DataTable
                        className="table-light"
                        responsive
                        value={registros}
                        sortField={sortField}
                        sortOrder={sortOrder}
                        onSort={this.onSort}
                        emptyMessage={<NenhumRegistroEncontrado />}
                    >
                        <Column
                            field="nome"
                            header="Nome"
                            body={row => this.renderNome(row)}
                            sortable={true}
                        />
                        <Column
                            field="email"
                            header="E-mail"
                            sortable={true}
                            body={row => this.renderEmail(row)}
                            style={{ overflow: 'hidden' }}
                        />
                        <Column
                            field="cpf"
                            header="CPF/CNPJ"
                            body={row => this.renderCpfCnpj(row)}
                            sortable={true}
                            style={{ width: '160px' }}
                        />
                        <Column
                            field="situacao"
                            header="Situação"
                            sortable={true}
                            body={row => this.renderSituacao(row)}
                            style={{ width: '110px' }}
                        />
                        <Column
                            body={this.renderOpcoes}
                            header="Ações"
                            style={{ width: '7em' }}
                        />
                    </DataTable>
                    <Paginator
                        className='paginator-light'
                        rowsPerPageOptions={[10, 25, 50]}
                        totalRecords={totalElements}
                        rows={rows}
                        first={page * rows}
                        onPageChange={this.onPageChange}
                        rightContent={<div style={{ margin: '5px' }}>{totalElements > 0 ? `${totalElements} registro(s) encontrado(s)` : null}</div>}
                    />
                </FormContent>
            </Form>
        )
    }
}

export default AgentesAutorizados;