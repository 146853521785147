
export const metodosAtualizarUrl = {
    PUSH: 'PUSH',
    POP: 'POP',
}

export async function validarFormulario(props) {
    const validacao = await props.validateForm(props.values);
    return Object.getOwnPropertyNames(validacao).length === 0;
}

export function atualizarUrl(history, urlBase, idSelecionado, metodo = metodosAtualizarUrl.PUSH) {
    let url = urlBase;

    if (idSelecionado) {
        url += '/' + idSelecionado
    }

    if (url !== history.location.pathname) {
        if (metodo === metodosAtualizarUrl.POP) {
            history.replace(url)
        } else {
            history.push(url)
        }
    }
}

export function voltarParaAPesquisa(history, pesquisaUrl) {
    history.push(pesquisaUrl)
}

export function buscarUUIDDaUrl(history) {
    return history ? history.location.search.substring(1) : ''
}

export function aplicarPercentual(valorTotal, percentual) {
    let valor = (percentual / 100) * valorTotal
    valor = parseFloat(valor.toFixed(2));
    
    return valor;
}