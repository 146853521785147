import React, { Component } from 'react';

export default class TituloCard extends Component {

    render() {
        const tituloStyle = {
            color: '#333333',
            fontSize: '17px',
            paddingLeft: '5px',
            paddingRight: '15px',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        }

        return (
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={tituloStyle} title={this.props.helpMessage}>
                    {this.props.title}
                </div>
            </div>
        );
    }

    
    
}
