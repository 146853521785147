import React, { Component } from "react";
import autoBind from 'react-autobind';
import { buscarHiddenDeAcordoComAsPermissoes } from "../../../common/autorizacao/manipulacaoDeComponentes";
import { renderizarValidacao } from "../../../common/tratamentoDeErro/validacoesDeCampos";
import { aplicarPercentual } from "../../../views/util";
import Col from "../../Col";
import If from "../../If";
import { InternalInputDouble } from "../InputDouble";
import './styles/index.css'

export const tipos = {
    VALOR: 'VALOR',
    PERCENTUAL: 'PERCENTUAL'
}

const inputStyle = {
    width: 'calc(100% - 45px)',
    padding: '0px',
}

const alteraPercentualOuValor = {
    fontSize: '18px',
    height: '32px',
}

const styleDiv = {
    alignItems: 'flex-end'
}

class InputSelectPercentualOrValor extends Component {

    constructor(props) {
        super(props);
        autoBind(this);

        this.state = {
            tipo: tipos.VALOR,
            percentual: 0,
        }
    }

    componentDidMount() {
        if (this.props.tipo) {
            this.setState({ tipo: this.props.tipo, percentual: this.props.value })
        }
    }

    componentDidUpdate() {

        const { restart, onRestart } = this.props;

        if (restart && onRestart) {
            this.setState({ tipo: tipos.VALOR, percentual: 0 })
            onRestart();
        }
    }
    getColStyle() {
        if (buscarHiddenDeAcordoComAsPermissoes(this.props.podeVisualizar, this.props.hidden))
            return { display: 'none', ...this.props.colStyle }
        return this.props.colStyle
    }

    onChangeTipo(e) {
        if (this.state.tipo === tipos.VALOR) {
            this.setState({
                tipo: tipos.PERCENTUAL,
                percentual: 0
            }, () => {
                this.props.onChange(tipos.PERCENTUAL, 0, 0);
                this.inputPercentual.inputElem.focus();
            })
        } else {
            this.setState({
                tipo: tipos.VALOR,
                percentual: 0
            }, () => {
                this.props.onChange(tipos.VALOR, 0, 0);
                this.inputValor.inputElem.focus();
            })
        }
    }

    changeValor(e) {
        const valor = e.target.value ?? 0
        this.props.onChange(this.state.tipo, valor, 0)
    }

    changePercentual(e) {
        const percentual = e.target.value ?? 0
        let valor = aplicarPercentual(this.props.valueBase, percentual);

        this.props.onChange(this.state.tipo, valor, percentual)
        this.setState({ percentual: percentual })
    }

    render() {
        const { tipo, percentual } = this.state;
        const { label, name, value, errors, colId, colClassName, col, sm, md, lg, xl, style, helpMessage, labelSize, obrigatorio, disabled, sizeValor, sizePercentual } = this.props;
        return (
            <Col id={colId} className={colClassName} col={col} sm={sm} md={md} lg={lg} xl={xl} style={this.getColStyle()}>
                <div className="p-inputgroup" style={styleDiv} >
                    <If test={tipo === tipos.VALOR}>
                        <InternalInputDouble
                            colStyle={inputStyle}
                            name={name}
                            obrigatorio={obrigatorio}
                            label={label}
                            labelSize={labelSize}
                            value={value}
                            onChange={this.changeValor}
                            style={style}
                            size={sizeValor}
                            helpMessage={helpMessage}
                            prefix='R$ '
                            placeholder='R$ 0,00'
                            touched
                            allowNegative={false}
                            ref={(elem) => (this.inputValor = elem)}
                            disabled={disabled}
                        />
                    </If>
                    <If test={tipo === tipos.PERCENTUAL}>
                        <InternalInputDouble
                            colStyle={inputStyle}
                            name={name}
                            label={label}
                            obrigatorio={obrigatorio}
                            labelSize={labelSize}
                            value={percentual}
                            maxValue={100}
                            onChange={this.changePercentual}
                            style={style}
                            size={sizePercentual}
                            helpMessage={helpMessage}
                            suffix=' %'
                            placeholder='0,00 %'
                            touched
                            allowNegative={false}
                            ref={(elem) => (this.inputPercentual = elem)}
                            disabled={disabled}
                        />
                    </If>
                    <span
                        color='secondary'
                        onClick={disabled ? null : this.onChangeTipo}
                        style={style ? style : alteraPercentualOuValor}
                        className='altera-percentual-ou-valor'
                        title="Clique aqui para alternar modo de entrada"
                    >
                        <>
                            {tipo === tipos.VALOR ?
                                <span className='altera-percentual-ou-valor-text'>R$</span>
                                :
                                <span className='altera-percentual-ou-valor-text'>%</span>
                            }
                            <i className="fa fa-refresh" aria-hidden="true" />
                        </>
                    </span >
                </div >
                {renderizarValidacao(errors, errors ? true : false)}
            </Col>
        )
    }
}

InputSelectPercentualOrValor.defaultProps = {
    sizePercentual: 6,
    sizeValor: 15
}

export default InputSelectPercentualOrValor;