import React, { Component } from 'react';
import { DataTable } from 'primereact/components/datatable/DataTable';
import { Column } from 'primereact/column';
import { formatarMonetario } from '../../../../../../common/mascara';
import Button from '../../../../../../components/Button';
import autoBind from 'react-autobind';
import { Paginator } from 'primereact/paginator';
import { recursos, permissoes } from '../../../../../../common/constantes/autorizacao';
import { usuarioPossuiPermissao } from '../../../../../../common/autenticacao';
import NenhumRegistroEncontrado from '../../../../../../components/NenhumRegistroEncontrado';
import { format, parse } from 'date-fns';
import { Menu } from 'primereact/menu';
import {
    Status,
    colorsStatus as StatusTable
} from '../../util/constantes';
import If from '../../../../../../components/If';
import VendasHistorico from '../VendasHistorico';
import { atualizarUrl } from '../../../../../util';
import { asyncGerarNfe } from '../../requests';

const styleButton = {
    borderRadius: '50%',
    padding: '5px',
    width: '30px',
    height: '30px',
    margin: '2px'
};

const makeStatus = (strongColor, lightColor, description = "") => {
    return (
        <span style={{
            backgroundColor: lightColor,
            color: strongColor,
            fontWeight: 'bold',
            fontSize: '13px',
            borderRadius: '3px',
            padding: '3px 10px',
        }}>
            <span style={{ width: '100%', textAlign: 'center' }}>{description}</span>
        </span>
    )
}

class TabelaVendasOrcamentos extends Component {

    constructor(props) {
        super(props);
        autoBind(this);

        this.state = {
            podeExcluir: usuarioPossuiPermissao(recursos.VENDAS_VENDAS, permissoes.EXCLUIR),
            podeEditar: usuarioPossuiPermissao(recursos.VENDAS_VENDAS, permissoes.EDITAR),
            podeInserir: usuarioPossuiPermissao(recursos.VENDAS_VENDAS, permissoes.INSERIR),
            registroSelecionado: null,
            modalHistoricoVisible: false
        }
    }

    atualizarOrdenacao(e) {
        this.props.setSortField(e.sortField);
        this.props.setSortOrder(e.sortOrder);
    }

    renderEmissaoField(el) {
        return <span>{format(parse(el.emissao, 'yyyy-MM-dd', new Date()), 'dd/MM/yyyy')}</span>
    }

    renderNumeroField(el) {
        return <span title={el.numero}>{el.numero}</span>
    }

    renderClienteField(el) {
        return (
            <span title={el.clienteNome} style={{ maxHeight: '60px', overflow: 'hidden', display: 'inline-flex' }}>
                {el.clienteNome}
            </span>
        );
    }

    renderStatusField(el) {
        let status = "";
        let lightColor = "";
        let strongColor = "";

        if (el.status === Status.ORCAMENTO) {
            status = "Orçamento";
            lightColor = StatusTable.ORCAMENTO.lightColor;
            strongColor = StatusTable.ORCAMENTO.strongColor;
        } else if (el.status === Status.VENDA) {
            status = "Venda";
            lightColor = StatusTable.VENDA.lightColor;
            strongColor = StatusTable.VENDA.strongColor;
        } else if (el.status === Status.CANCELADA) {
            status = "Cancelada";
            lightColor = StatusTable.CANCELADA.lightColor;
            strongColor = StatusTable.CANCELADA.strongColor;
        }
        return makeStatus(strongColor, lightColor, status);
    }

    renderValorField(el) {
        const valor = formatarMonetario(el.totalLiquido)
        return <span title={valor} style={{ color: '#424242' }}>{valor}</span>
    }

    renderValorAReceberField(el) {
        const valor = formatarMonetario(el.valorAReceber)
        if (valor !== formatarMonetario(0)) {
            return <span title={valor} style={{ fontWeight: 'bold', color: '#424242' }}>{valor}</span>
        }
    }

    renderAcoesField(el) {
        const { podeExcluir } = this.state;

        let titleEditar = "Editar";
        let titleExcluir = "Excluir";
        let disableBtnEditar = false;
        let disableBtnExcluir = false;

        if (!podeExcluir) {
            titleExcluir = "Você não possui permissão para executar essa ação"
            disableBtnExcluir = true;
        };

        if (el.nfe && el.nfe.id) {
            titleExcluir = "Você não pode excluir uma venda com nota fiscal"
            disableBtnExcluir = true;
        }

        return (
            <>
                <Button
                    style={styleButton}
                    className="p-button p-button-primary"
                    icon="fa fa-pencil"
                    title={titleEditar}
                    disabled={disableBtnEditar}
                    onClick={() => this.props.onEditItem(el)}
                />
                <Button
                    style={styleButton}
                    className="p-button p-button-danger"
                    icon="fa fa-trash"
                    title={titleExcluir}
                    disabled={disableBtnExcluir}
                    onClick={() => this.props.onRemoveItem(el)}
                />
                <Button
                    title="Opções"
                    className="p-button-secondary"
                    icon="fa fa-ellipsis-v"
                    style={styleButton}
                    aria-controls="popup_menu"
                    aria-haspopup={true}
                    onClick={event => {
                        this.setState({ registroSelecionado: el })
                        this.menu.toggle(event)
                    }}
                />
            </>
        );
    }

    renderNotaFiscal(el) {
        if (el.nfe && el.nfe.id) {
            const numero = el.nfe.numero;
            const serie = el.nfe.serie;

            return (
                <span
                    onClick={() => this.acessarNfe(el.nfe.id)}
                    style={{ fontSize: '12px', color: '#006095' }}
                    className="link_to"
                    title={numero === 0 ? `Nota fiscal não transmitida` : `Nota fiscal número ${numero}`}
                >
                    NF {numero ? numero : "-"}/{serie ? serie : "?"}
                </span>
            )
        }
        return "";
    }

    acessarNfe(NfeId) {
        atualizarUrl(this.props.history, `/nfes/cadastro/${NfeId}`)
    }

    duplicar(idVenda) {
        this.props.history.push({
            pathname: `/vendas/cadastro/${idVenda}`,
            state: { idVenda }
        })
    }

    gerarNfe(VendaId) {
        asyncGerarNfe(VendaId, ({ data }) => {
            this.acessarNfe(data.id)
        })
    }

    montarItensMenu() {
        let itens = [];
        const { registroSelecionado, podeEditar, podeInserir } = this.state;

        if (registroSelecionado) {
            itens.push({ label: 'Duplicar', icon: 'fa fa-copy', command: () => this.duplicar(registroSelecionado.id) });
            if (registroSelecionado.status === Status.ORCAMENTO) {
                itens.push({ label: 'Imprimir', icon: 'fa fa-print', command: () => this.props.onPrint(registroSelecionado) });
                itens.push({ label: 'Histórico', icon: 'fa fa-history', command: () => this.visualizarHistorico() });
                if (podeEditar) {
                    itens.push({ label: 'Gerar venda', icon: 'fa fa-dollar', command: () => this.props.onGenerateSale(registroSelecionado) });
                    itens.push({ label: 'Cancelar orçamento', icon: 'fa fa-times', command: () => this.props.onReject(registroSelecionado) });
                }
            } else if (registroSelecionado.status === Status.VENDA) {
                itens.push({ label: 'Imprimir', icon: 'fa fa-print', command: () => this.props.onPrint(registroSelecionado) });
                itens.push({ label: 'Histórico', icon: 'fa fa-history', command: () => this.visualizarHistorico() });
                if (podeEditar && !registroSelecionado.nfe && (registroSelecionado.pagamentoCondicao === 'SEM_PAGAMENTO' || registroSelecionado.totalLiquido === registroSelecionado.valorAReceber)) {
                    itens.push({ label: 'Cancelar venda', icon: 'fa fa-times', command: () => this.props.onReject(registroSelecionado) });
                }
                if (podeInserir && registroSelecionado.nfe) {
                    itens.push({ label: 'Acessar nota fiscal', icon: 'fa fa-link', command: () => { this.acessarNfe(registroSelecionado.nfe.id) } })
                } else {
                    itens.push({ label: 'Gerar nota fiscal', icon: 'fa fa-file-text-o', command: () => { this.gerarNfe(registroSelecionado.id) } })
                }
            } else if (registroSelecionado.status === Status.CANCELADA) {
                itens.push({ label: 'Imprimir', icon: 'fa fa-print', command: () => this.props.onPrint(registroSelecionado) });
                itens.push({ label: 'Histórico', icon: 'fa fa-history', command: () => this.visualizarHistorico() });
            }
        }
        return itens;
    };

    visualizarHistorico() {
        this.setState({ modalHistoricoVisible: true });
    }

    render() {
        const { registros } = this.props;
        const { modalHistoricoVisible, registroSelecionado } = this.state;

        return (
            <>
                <Menu
                    model={this.montarItensMenu()}
                    popup={true}
                    ref={el => this.menu = el}
                />
                <DataTable
                    className="table-light"
                    value={registros}
                    responsive
                    sortField={this.props.sortField}
                    sortOrder={this.props.sortOrder}
                    onSort={this.atualizarOrdenacao}
                    emptyMessage={<NenhumRegistroEncontrado />}
                >
                    <Column
                        header="N°"
                        field="numero"
                        sortable={true}
                        body={this.renderNumeroField}
                        style={{ color: '#333333', width: '60px' }}
                    />
                    <Column
                        header="Data"
                        field="emissao"
                        sortable={true}
                        body={this.renderEmissaoField}
                        style={{ color: '#333333', width: '100px' }}
                    />
                    <Column
                        header="Cliente"
                        field="clienteNome"
                        sortable={true}
                        body={this.renderClienteField}
                        style={{ color: '#333333', wordBreak: 'break-all' }}
                    />
                    <Column
                        header="NF"
                        field="nfe"
                        body={this.renderNotaFiscal}
                        style={{ color: '#333333', width: '100px' }}
                    />
                    <Column
                        header="Status"
                        field="status"
                        sortable={true}
                        body={this.renderStatusField}
                        style={{ color: '#333333', width: '150px' }}
                    />
                    <Column
                        header="Valor"
                        field="totalLiquido"
                        sortable={true}
                        body={this.renderValorField}
                        style={{ color: '#333333', width: '116px', textOverflow: 'ellipsis', overflow: 'hidden' }}
                    />
                    <Column
                        header="A receber"
                        field="valorAReceber"
                        body={this.renderValorAReceberField}
                        style={{ color: '#333333', width: '120px', textOverflow: 'ellipsis', overflow: 'hidden' }}
                    />
                    <Column
                        header="Ações"
                        body={this.renderAcoesField}
                        style={{ width: '130px' }}
                    />
                </DataTable>
                <Paginator
                    className='paginator-light'
                    rowsPerPageOptions={[10, 25, 50]}
                    totalRecords={this.props.totalRecords}
                    rows={this.props.rows}
                    first={this.props.first}
                    onPageChange={this.props.onPageChange}
                    rightContent={<div style={{ margin: '5px' }}>{this.props.totalRecords > 0 ? `${this.props.totalRecords} registro(s) encontrado(s)` : null}</div>}
                />
                <If test={modalHistoricoVisible}>
                    <VendasHistorico
                        status={registroSelecionado && registroSelecionado.status}
                        idVenda={registroSelecionado && registroSelecionado.id}
                        visible={modalHistoricoVisible}
                        onHide={() => this.setState({ modalHistoricoVisible: false })}
                    />
                </If>
            </>
        );

    }
}

export default TabelaVendasOrcamentos;
