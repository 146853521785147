import React, { Component } from 'react';
import { Field, withFormik } from 'formik';
import Grid from '../../../../../components/Grid';
import { withRouter } from 'react-router';
import ButtonCancelar, { estadosBotaoCancelar } from '../../../../../components/Button/ButtonCancelar';
import { asyncUpdateNfce, asyncTransmitirNFce, asyncCreateNfce, asyncGetNfce, asyncDeleteNFce, imprimirXMLNfce, imprimirDANFE } from '../requests';
import { recursos, permissoes, estadosCadastro } from '../../../../../common/constantes/autorizacao';
import Prompt from '../../../../../components/Route/Prompt';
import ButtonExcluir from '../../../../../components/Button/ButtonExcluir';
import Button from '../../../../../components/Button';
import autoBind from "react-autobind";
import { gerarUUID, validarUUID } from '../../../../../common/manipulacaoDeString';
import { buscarDadosLoginLocalStorage, usuarioPossuiPermissao } from '../../../../../common/autenticacao';
import Form from '../../../../../components/Form';
import FormActions from '../../../../../components/FormActions';
import FormContent from '../../../../../components/FormContent';
import Col from '../../../../../components/Col';
import {
    AcoesBtnSalvarNfce,
    Condicoes,
    DocumentosTipos,
    MensagemToast,
    mostrarToast,
    PESQUISAURL,
    Status,
    TipoDestinatario,
    TiposAcessorias,
    TiposDesconto,
    infoStatusNfce,
    CADASTROURL
} from '../util/constantes';
import If from '../../../../../components/If';
import { Fieldset } from 'primereact/fieldset';
import Checkbox from '../../../../../components/Input/Checkbox';
import { connect } from 'react-redux';
import ModalLoadingTransmissao from './components/ModalLoadingTransmissao';
import Message from '../../../../../components/Message';
import { actionTypes } from '../../../../../common/constantes/reduxTypes';
import { dispatchAction } from '../../../../../common/redux';
import { DataTable } from 'primereact/components/datatable/DataTable';
import { Column } from 'primereact/column';
import ProdutoNfceForm from './components/ProdutoForm';
import NenhumRegistroEncontrado from '../../../../../components/NenhumRegistroEncontrado';
import ModalProdutoNfce from './components/ModalProdutoNfce';
import ButtonEditarTable from '../../../../../components/Button/ButtonEditarTable';
import ButtonExcluirTable from '../../../../../components/Button/ButtonExcluirTable';
import InputSelectCpfOrCnpj from '../../../../../components/Input/InputSelectCpfOrCnpj';
import InputField from '../../../../../components/Input/InputField';
import SingleSelectPessoa from '../../../../../components/Select/SingleSelectPessoa';
import { services } from '../../../../../common/constantes/api';
import { formatarMonetario, manterApenasNumeros } from '../../../../../common/mascara';
import validarInformacoesObrigatoriasProduto from './utils/validacoes/validarInformacoesObrigatoriasProduto';
import Card from '../../../../../components/Card';
import ModalFormaPagamentoNfce from './components/ModalFormaPagamentoNfce';
import ButtonSalvar from '../../../../../components/Button/ButtonSalvar';
import { converterNFceParaApi } from './utils/converterNFceParaApi';
import { atualizarUrl, metodosAtualizarUrl, aplicarPercentual, voltarParaAPesquisa } from '../../../../util';
import { converterNfceParaFormulario } from './utils/converterNfceParaFormulario';
import { validarValorNegativo } from '../util/functions';
import NfceCardValidacao from './components/NfceCardValidacao';
import { isValidCnpj, isValidCpf } from '@brazilian-utils/validators';
import { baixarArquivo } from '../../../../../common/relatorios';
import { FaDownload } from 'react-icons/fa';
import { Menu } from 'primereact/menu';
import ModalHistorico from './components/ModalHistorico';
import ModalCancelamento from './components/ModalCancelamento';
import NfceStatus from './components/NfceStatus';
import NfceSerieNumero from './components/NfceSerieNumero';
import { confirmarExclusao } from '../../../../util/exclusaoDeRegistros';
import { helpMessageNFCe } from '../Form/help';
import { copiarObjeto } from '../../../../../common/array';
import ButtonNovo, { estadosBotaoNovo } from '../../../../../components/Button/ButtonNovo';
import InputSelectPercentualOrValorNfce from '../components/InputSelectPercentualOrValorNfce';
import { getPrimaryColorOfSelectedItem } from '../util/functions';
import { copiarParaAreaTransferencia } from '../../../../../common/areaDeTransferencia';
import { notify, ToastTypes } from '../../../../../components/Toast';

const style = {
    fontSize: '13px',
    color: '#000000',
    margin: '0px',
    justifyContent: 'flex-end',
    display: 'flex',
}

const totalStyle = {
    fontSize: '24px',
    color: '#000000',
    margin: '10px 0px 0px 0px',
    justifyContent: 'flex-end',
    display: 'flex',
}

const styleDivider = {
    border: 'none',
    height: '1px',
    margin: '0px',
    flexShrink: '0px',
    backgroundColor: '#c3c3c3',
    marginTop: '10px'
}

const styleBotaoImprimir = {
    padding: '0px 10px',
    background: '#fff0',
    fontWeight: 'bold',
    fontSize: '15px',
    alignItems: 'center',
    border: 'none'
};

const styleNumeroVenda = {
    fontSize: '24px',
    fontWeight: 'bold'
}

const Divider = () => {
    return <hr style={styleDivider} />
}


const initialValues = {
    id: null,
    statusNfce: null,
    produtos: [],
    documentoTipo: DocumentosTipos.CPF,
    cliente: null,
    dest: null,
    pag: {
        id: null,
        valorRecebido: 0,
        valorTroco: 0,
        condicao: Condicoes.A_VISTA,
        quantidadeParcelas: 0,
        categoria: null,
        parcelas: []
    },
    descontoPendenteRateio: 0,
    total: {
        id: null,
        vProd: 0,
        vDesc: 0,
        vOutro: 0,
        vNF: 0
    },

}

class NFCeForm extends Component {

    constructor(props) {
        super(props);

        autoBind(this);

        this.state = {
            podeInserir: usuarioPossuiPermissao(recursos.VENDAS_VENDAS, permissoes.INSERIR),
            podeEditar: usuarioPossuiPermissao(recursos.VENDAS_VENDAS, permissoes.EDITAR),
            podeExcluir: usuarioPossuiPermissao(recursos.VENDAS_VENDAS, permissoes.EXCLUIR),
            credencial: buscarDadosLoginLocalStorage(),

            exibirLoadingTransmissao: false,
            modalProdutoNfceVisible: false,
            modalFormaPagamentoVisible: false,
            modalHistoricoVisible: false,
            modalCancelamento: false,

            produtoIncompleto: null,
            produtoParaEdicao: null,

            acessorias: {
                tipoAcessorias: TiposAcessorias.VALOR,
                valorAcessorias: 0,
            },
            desconto: {
                tipoDesconto: TiposDesconto.VALOR,
                valorDesconto: 0
            },

            restart: false,

            consumidorCadastrado: false,

            desabilitarEdicao: false,

            initialValues: copiarObjeto(initialValues),

            validacoes: [],
        }
    }

    componentDidMount() {
        dispatchAction(actionTypes.MENU_LATERAL_FECHADO, true)

        const id = this.props.match.params.id;
        if (validarUUID(id)) {
            this.asyncSelectNfce(id);
        }

    }

    hideModalProduto() {
        this.setState({ produtoIncompleto: null, produtoParaEdicao: null, modalProdutoNfceVisible: false })
    }

    hideModalFormaPagamento() {
        this.setState({ modalFormaPagamentoVisible: false })
    }

    renderOpcoes(itemRow) {
        return (
            <>
                <ButtonEditarTable
                    onClick={() => this.editarProduto(itemRow)}
                />
                <ButtonExcluirTable
                    onClick={() => this.excluirRegistro(itemRow)}
                    disabled={this.state.desabilitarEdicao}
                />
            </>
        )
    }

    montarHeader() {
        const { statusNfce } = this.props.values;
        const { isMobile } = this.props;

        if (statusNfce === Status.TRANSMITIDA || statusNfce === Status.CANCELADA || statusNfce === Status.DENEGADA) {
            return (
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    {this.buscarSequencialInternoNfce()}
                    <span style={{ display: 'flex', alignItems: 'center', fontSize: '16px', cursor: 'pointer' }}>
                        <div
                            style={styleBotaoImprimir}
                            onClick={() => this.imprimirDANFE()}
                        >
                            <FaDownload size="15px" style={!isMobile && { marginRight: '5px' }} />
                            <span>DANFE</span>
                        </div>
                        <div
                            style={styleBotaoImprimir}
                            onClick={() => this.baixarXMLNfce()}
                        >
                            <FaDownload size="15px" style={!isMobile && { marginRight: '5px' }} />
                            <span>XML</span>
                        </div>
                    </span>
                </div>
            )
        } else {
            return (
                <div>
                    {this.buscarSequencialInternoNfce()}
                </div>
            )
        }
    }

    buscarSequencialInternoNfce() {
        const { statusNfce, numeroVenda } = this.props.values
        const { isMobile } = this.props;
        const strongColor = getPrimaryColorOfSelectedItem(statusNfce);

        const sequencialInterno = numeroVenda ?? '-'

        return (
            <span>
                {isMobile ?
                    <>
                        <span>NFC-e </span>
                        <span style={{ ...styleNumeroVenda, color: strongColor }}>{`N° ${sequencialInterno}`}</span>
                    </>
                    :
                    <>
                        <span>Nota fiscal de consumidor </span>
                        <span style={{ ...styleNumeroVenda, color: strongColor }}>{`N° ${sequencialInterno}`}</span>
                    </>
                }
            </span>
        )
    }


    validarInformacoesObrigatorias(informacoesItemDaNfce) {
        const { produto } = informacoesItemDaNfce

        const erros = validarInformacoesObrigatoriasProduto(produto)

        if (erros.length > 0) {
            this.setState({ produtoIncompleto: informacoesItemDaNfce, modalProdutoNfceVisible: true })
        } else {
            const produtoComTotais = this.calcularTotaisProduto(produto)
            this.adicionarProdutoNaVenda(produtoComTotais)
        }
    }

    calcularTotaisProduto(produto) {
        const desconto = this.calcularDescontoProduto(produto.desconto, produto.valorUnitario, produto.quantidade)
        const total = (produto.quantidade * produto.valorUnitario) - desconto
        return {
            ...produto,
            descontoTotalProduto: desconto,
            acessoriasTotalProduto: 0,
            totalProduto: total,
        }
    }

    calcularDescontoProduto(desconto, valorUnitario, quantidade) {
        const valorTotalProduto = valorUnitario * quantidade

        if (desconto.tipoDesconto && desconto.valorDesconto) {
            if (desconto.tipoDesconto === TiposDesconto.PERCENTUAL) {
                return aplicarPercentual(valorTotalProduto, desconto.valorDesconto)

            } else if (desconto.tipoDesconto === TiposDesconto.VALOR) {
                return desconto.valorDesconto
            }
        }
        return 0
    }

    adicionarProdutoNaVenda(produto) {
        const produtos = [produto, ...this.props.values.produtos]
        const total = this.props.values.total

        this.props.setFieldValue('produtos', produtos)

        const totalAtualizado = {
            ...total,
            vProd: total.vProd + (produto.valorUnitario * produto.quantidade),
            vDesc: total.vDesc + produto.descontoTotalProduto,
            vNF: total.vNF + produto.totalProduto
        }

        this.props.setFieldValue('total', totalAtualizado)

        if (this.state.modalProdutoNfceVisible) {
            this.setState({ produtoIncompleto: null })
            this.hideModalProduto()
        }
    }

    editarProduto(itemRow) {
        const produtos = this.props.values.produtos
        const idProduto = itemRow.id ? itemRow.id : itemRow.idTemporario

        produtos.filter(produto => {
            if (produto.id === idProduto || produto.idTemporario === idProduto) {
                this.setState({ produtoParaEdicao: produto, modalProdutoNfceVisible: true })
            }
            return null
        })
    }

    atualizarProduto(dadosFormulario) {
        const produtos = this.props.values.produtos
        const total = this.props.values.total

        const produtosAtualizados = []

        produtos.forEach(produto => {
            if (produto.id && dadosFormulario.id === produto.id) {
                produtosAtualizados.push(dadosFormulario)
            } else if (produto.idTemporario && dadosFormulario.idTemporario === produto.idTemporario) {
                produtosAtualizados.push(dadosFormulario)
            } else {
                produtosAtualizados.push(produto)
            }
        })


        this.props.setFieldValue('produtos', produtosAtualizados)

        const totalCalculado = this.calcularTotalAposAtualizarProduto(produtosAtualizados)

        const totalAtualizado = {
            ...total,
            vProd: totalCalculado.vProd,
            vDesc: totalCalculado.vDesc,
            vNF: totalCalculado.vNF,
        }

        this.props.setFieldValue('total', totalAtualizado)

        this.setState({ produtoParaEdicao: null })
        this.hideModalProduto()

    }

    calcularTotalAposAtualizarProduto(produtos) {
        const totalAposAtualizarProduto = {
            vProd: 0,
            vDesc: 0,
            vNF: 0,
        }

        produtos.forEach(produto => {
            totalAposAtualizarProduto.vProd += produto.valorUnitario * produto.quantidade
            totalAposAtualizarProduto.vDesc += produto.descontoTotalProduto
            totalAposAtualizarProduto.vNF += (produto.valorUnitario * produto.quantidade) - (produto.descontoTotalProduto)
        })

        return totalAposAtualizarProduto
    }

    excluirRegistro(ItemRow) {
        const produtos = this.props.values?.produtos
        const total = this.props.values.total

        produtos.forEach((produto, index) => {
            if ((produto.idTemporario && produto.idTemporario === ItemRow.idTemporario) || (produto.id && produto.id === ItemRow.id)) {
                const totalAtualizado = {
                    ...total,
                    vProd: total.vProd - (produto.valorUnitario * produto.quantidade),
                    vDesc: total.vDesc - produto.descontoTotalProduto,
                    vOutro: total.vOutro - produto.acessoriasTotalProduto,
                    vNF: total.vNF - produto.totalProduto
                }
                this.props.setFieldValue('total', totalAtualizado)
                produtos.splice(index, 1)
            }
        });

        this.props.setFieldValue('produtos', produtos)

        if (this.state.modalProdutoNfceVisible) {
            this.setState({ produtoParaEdicao: null })
            this.hideModalProduto()
        }
    }

    trocarTipoDescontoNfce(tipo, value, percentual) {
        const desconto = {
            tipoDesconto: tipo,
            valorDesconto: tipo === TiposDesconto.PERCENTUAL ? percentual : value
        }
        this.setState({ desconto: desconto }, () => {
            this.calcularDescontoNfce()
        })
    }

    calcularDescontoNfce() {
        const { desconto } = this.state
        const { vNF } = this.props.values.total

        if (desconto.tipoDesconto === TiposDesconto.PERCENTUAL) {
            this.props.setFieldValue('descontoPendenteRateio', aplicarPercentual(vNF, desconto.valorDesconto))
        } else {
            this.props.setFieldValue('descontoPendenteRateio', desconto.valorDesconto)
        }
    }

    trocarTipoAcessorias(tipo, value, percentual) {
        const acessorias = {
            tipoAcessorias: tipo,
            valorAcessorias: tipo === TiposAcessorias.PERCENTUAL ? percentual : value
        }
        this.setState({ acessorias: acessorias }, () => {
            this.calcularAcessoriasNfce()
        })
    }

    calcularAcessoriasNfce() {
        const { acessorias } = this.state
        const { vNF } = this.props.values.total

        if (acessorias.tipoAcessorias === TiposAcessorias.PERCENTUAL) {
            this.props.setFieldValue('total.vOutro', aplicarPercentual(vNF, acessorias.valorAcessorias))
        } else {
            this.props.setFieldValue('total.vOutro', acessorias.valorAcessorias)
        }
    }

    montarInformacoesDestinatarioCadastrado(informacoesDestinatario) {
        if (informacoesDestinatario) {
            const { registro } = informacoesDestinatario
            const { dest } = this.state.initialValues
            const { consumidorCadastrado } = this.state

            if (registro.cnpj) {
                this.props.setFieldValue('documentoTipo', DocumentosTipos.CNPJ)
            } else if (registro.cpf) {
                this.props.setFieldValue('documentoTipo', DocumentosTipos.CPF)
            }

            if (consumidorCadastrado) {
                this.props.setFieldValue('cliente', informacoesDestinatario)
                const destAtualizado = {
                    ...dest,
                    cliente: registro.id ?? null,
                    tipo: registro.cpf ? TipoDestinatario.FISICA : TipoDestinatario.JURIDICA,
                    CNPJ: registro.cnpj ?? null,
                    CPF: registro.cpf ?? null,
                    xNome: registro.nome
                }
                this.props.setFieldValue('dest', destAtualizado)
            }
        } else {
            this.props.setFieldValue('cliente', null)
            this.props.setFieldValue('dest', null)
            this.props.setFieldValue('documentoTipo', DocumentosTipos.CPF)
        }
    }

    pegarValuesDocumento() {
        const { documentoTipo, dest } = this.props.values
        if (documentoTipo === DocumentosTipos.CPF) {
            return dest?.CPF
        } else {
            return dest?.CNPJ
        }
    }

    changeCpfOuCnpj(CpfOuCnpj) {
        const { documentoTipo } = this.props.values

        if (documentoTipo === DocumentosTipos.CNPJ) {
            this.props.setFieldValue('dest.CNPJ', CpfOuCnpj)
            this.props.setFieldValue('dest.tipo', TipoDestinatario.JURIDICA)
        } else {
            this.props.setFieldValue('dest.CPF', CpfOuCnpj)
            this.props.setFieldValue('dest.tipo', TipoDestinatario.FISICA)
        }
    }

    trocarTipoDocumento() {
        const documentoTipo = this.props.values.documentoTipo

        if (documentoTipo === DocumentosTipos.CPF) {
            this.props.setFieldValue('documentoTipo', DocumentosTipos.CNPJ)
            this.props.setFieldValue('dest.tipo', TipoDestinatario.JURIDICA)
            this.props.setFieldValue('dest.CPF', null)
        } else {
            this.props.setFieldValue('documentoTipo', DocumentosTipos.CPF)
            this.props.setFieldValue('dest.tipo', TipoDestinatario.FISICA)
            this.props.setFieldValue('dest.CNPJ', null)
        }
    }

    changeConsumidorCadastrado(valueChecked) {
        this.setState({ consumidorCadastrado: valueChecked })

        this.props.setFieldValue('dest', null)
        this.props.setFieldValue('documentoTipo', DocumentosTipos.CPF)
    }

    buscarLabelEPlaceholderDestinatarioNfce() {
        const documentoTipo = this.props.values.documentoTipo

        return (
            documentoTipo === DocumentosTipos.CPF ? 'Nome' : 'Razão Social'
        )
    }

    organizacaoPodeTransmitirNfce() {
        const { credencial } = this.state;
        if (credencial.organizacao && credencial.organizacao.fiscalNfce) {
            if (!credencial.organizacao.certificado || !credencial.organizacao.fiscalNfce.serieNfce) {
                return false;
            }
        }
        return true;
    }

    buscarMensagemOrganizacaoPodeTransmitirNfe() {
        return (
            <span>
                {this.getTitleButtonFinalizar()}
                <div>
                    <b
                        style={{ cursor: 'pointer' }}
                        onClick={() => this.props.history.push({ pathname: "/configuracoes_gerais", state: { configurarSerieNfce: true } })}
                    >
                        Clique aqui para configurar
                    </b>
                </div>
            </span>
        )
    }

    getTitleButtonFinalizar() {
        const { credencial } = this.state;

        if (credencial.organizacao && credencial.organizacao.fiscalNfce) {
            if (!credencial.organizacao.certificado) {
                return "Organização sem certificado digital configurado para transmissão de NFC-e"
            } else if (!credencial.organizacao.fiscalNfce.serieNfce) {
                return "Série da NFC-e não configurada"
            }
        }
        return "";
    }

    cancelar() {
        if (this.props.dirty) {
            this.props.resetForm({ values: copiarObjeto(this.state.initialValues) })
            this.setState({
                consumidorCadastrado: false,
                acessorias: {
                    tipoAcessorias: TiposAcessorias.VALOR,
                    valorAcessorias: this.state.initialValues.total.vOutro,
                },
                desconto: {
                    tipoDesconto: TiposDesconto.VALOR,
                    valorDesconto: 0
                },
                restart: true,
            })

        } else {
            this.props.history.goBack();
        }
    }

    excluir() {
        confirmarExclusao(this.asyncDeleteRegistro)
    }

    async asyncDeleteRegistro() {
        await asyncDeleteNFce(this.props.values.id, () => {
            voltarParaAPesquisa(this.props.history, PESQUISAURL)
        })
    }


    updateNfce(dadosFormulario, mensagemToast, calback, novoOnSuccess) {
        if (dadosFormulario) {
            asyncUpdateNfce(dadosFormulario, mensagemToast, () => {
                if (novoOnSuccess) {
                    novoOnSuccess()
                } else {
                    this.asyncSelectNfce(dadosFormulario.id);
                    calback && calback(dadosFormulario.id);
                }
            })
        }
    }

    createNfce(dadosFormulario, mensagemToast, calback, novoOnSuccess) {
        if (dadosFormulario) {
            asyncCreateNfce(dadosFormulario, mensagemToast, ({ data: nfce }) => {
                if (novoOnSuccess) {
                    novoOnSuccess()
                } else {
                    this.asyncSelectNfce(nfce.id);
                    calback && calback(nfce.id);
                }
            })
        }
    }

    async asyncSelectNfce(idNfce) {
        await asyncGetNfce(idNfce, nfce => {
            const values = converterNfceParaFormulario(nfce.data)

            this.setState({ initialValues: copiarObjeto(values) })

            this.props.resetForm({ values });
            this.setState({
                acessorias: {
                    tipoAcessorias: TiposAcessorias.VALOR,
                    valorAcessorias: values.total?.vOutro,
                },
            })

            if (!this.state.podeEditar && values.id) {
                this.setState({
                    desabilitarEdicao: true
                })
            }

            const statusFinaisDaNfce = [Status.CANCELADA, Status.DENEGADA, Status.AGUARDANDO_AUTORIZACAO, Status.TRANSMITIDA]
            if (statusFinaisDaNfce.includes(this.props.values.statusNfce)) {
                this.setState({
                    desabilitarEdicao: true
                })
            }
            atualizarUrl(this.props.history, '/nfces/cadastro', idNfce, metodosAtualizarUrl.POP);
        }, () => {
            atualizarUrl(this.props.history, '/nfces/cadastro', null, metodosAtualizarUrl.PUSH);
        });
    }

    async finalizarNfce() {
        await this.props.validateForm();
        if (this.props.isValid) {
            if (this.props.dirty) {
                this.salvar(mostrarToast.FALSE)
            }
            this.setState({ modalFormaPagamentoVisible: true })
        }
    }

    async salvar(mostrarToast, calback, e, novoOnSuccess) {
        await this.props.validateForm();

        if (this.props.isValid) {
            let dadosFormulario = converterNFceParaApi({ ...this.props.values });
            if (this.props.values.id) {
                this.updateNfce(dadosFormulario, mostrarToast ? MensagemToast.EDITAR : null, calback, novoOnSuccess)
            } else {
                this.createNfce(dadosFormulario, mostrarToast ? MensagemToast.CRIAR : null, calback, novoOnSuccess);
            }
            this.setState({ desconto: { tipoDesconto: 'VALOR', valorDesconto: 0 } })
            this.setState({ acessorias: { tipoAcessorias: 'VALOR', valorAcessorias: 0 } })
        } else {
            const errors = Object.values(this.props.errors)
            this.setState({ validacoes: [] })

            if (errors) {
                errors.forEach(error => {
                    this.setState({ validacoes: [...this.state.validacoes, { id: gerarUUID(), mensagem: error }] })
                })
            }
        }
    }

    novo() {
        this.setState({
            consumidorCadastrado: false,
            acessorias: {
                tipoAcessorias: TiposAcessorias.VALOR,
                valorAcessorias: 0,
            },
            desconto: {
                tipoDesconto: TiposDesconto.VALOR,
                valorDesconto: 0
            },
            desabilitarEdicao: false,
            validacoes: [],
            restart: true
        }, () => {
            this.props.resetForm({ values: copiarObjeto(initialValues) })
            this.setState({ initialValues: copiarObjeto(initialValues) })
        })
        atualizarUrl(this.props.history, CADASTROURL, null, metodosAtualizarUrl.POP);
    }

    salvarParcelas(parcelas, acoesBtnSalvarNfce) {
        const { setFieldValue } = this.props
        setFieldValue('pag', parcelas.pag)

        this.hideModalFormaPagamento()

        if (acoesBtnSalvarNfce === AcoesBtnSalvarNfce.SALVAR) {
            this.salvar(mostrarToast.TRUE)
        }

        if (acoesBtnSalvarNfce === AcoesBtnSalvarNfce.SALVAR_E_TRANSMITIR) {
            this.salvar(mostrarToast.FALSE, (id) => {
                this.transmitirNfce(id)
            })
        }
    }

    async transmitirNfce(id) {
        this.setState({ exibirLoadingTransmissao: true }, () => {
            asyncTransmitirNFce(id, () => {
                this.setState({ exibirLoadingTransmissao: false }, () => {
                    this.asyncSelectNfce(id)
                })
            }, () => {
                this.setState({ exibirLoadingTransmissao: false }, () => {
                    this.asyncSelectNfce(id)
                })
            })
        })
    }

    onChangeXnome(xNome) {
        if (xNome) {
            this.props.setFieldValue('dest.xNome', xNome)
        } else {
            this.props.setFieldValue('dest', null)
        }
    }

    calcularValorLíquidoNfce() {
        const { descontoPendenteRateio, total } = this.props.values

        const valorLiquidoNfce = (total.vNF + total.vOutro) - descontoPendenteRateio

        return validarValorNegativo(valorLiquidoNfce)
    }

    calcularDescontoTotal() {
        const { total, descontoPendenteRateio } = this.props.values
        return total.vDesc + descontoPendenteRateio
    }

    imprimirDANFE() {
        const nomeArquivo = (this.props.values.statusNfce === Status.CANCELADA) ? `NFCe ${this.props.values.nNfce} Cancelada.pdf` : `NFCe ${this.props.values.nNfce}.pdf`;

        imprimirDANFE(this.props.values.id, ({ data: file }) => {
            baixarArquivo(file, nomeArquivo);
        });
    }

    baixarXMLNfce() {
        const nomeArquivo = (this.props.values.statusNfce === Status.CANCELADA) ? `NFCe ${this.props.values.nNfce} Cancelada` : `NFCe ${this.props.values.nNfce}`;

        imprimirXMLNfce(this.props.values.id, ({ data: file }) => {
            baixarArquivo(file, nomeArquivo, "application/xml");
        });
    }

    buscarItensOpcoes() {
        const { values } = this.props;
        const notaFiscalCancelada = (values.statusNfce === Status.CANCELADA);
        const notaFiscalRejeitada = (values.statusNfce === Status.REJEITADA);
        const notaFiscalAguardando = (values.statusNfce === Status.AGUARDANDO_AUTORIZACAO);

        let res = [];

        const verHistoricoNfce = {
            label: "Histórico",
            icon: "fa fa-history",
            command: () => this.setState({ modalHistoricoVisible: true })
        };

        const verMotivoCancelamento = {
            label: "Ver motivo do cancelamento",
            icon: "fa fa-eye",
            command: () => this.setState({ modalCancelamento: true })
        };

        const copiarPlugnotasId = {
            label: "Copiar identificador da NFC-e",
            icon: "fa fa-copy",
            command: () => {
                copiarParaAreaTransferencia(values.plugnotasId || "", () => {
                    notify("Identificador da NFC-e copiado para área de transferência", ToastTypes.SUCCESS)
                })
            }
        };

        if (values.id && notaFiscalRejeitada) {
            res.push(copiarPlugnotasId)
        }

        if (values.id && notaFiscalAguardando) {
            res.push(copiarPlugnotasId)
        }

        if (values.id && notaFiscalCancelada) {
            res.push(verMotivoCancelamento);
        }

        if (values.id) {
            res.push(verHistoricoNfce);
        }
        return res;
    }

    buscarMensagemNFeAguardandoTransmissao() {
        return (
            <span>
                NFC-e em processamento
                <div>
                    A sefaz está processando a NFC-e. Por favor, aguarde alguns instantes e atualize a nota
                </div>
                <b
                    style={{ cursor: 'pointer' }}
                    onClick={() => this.asyncSelectNfce(this.props.values.id)}
                >
                    Clique aqui para atualizar
                </b>
            </span>
        )
    }

    renderNomeProduto(produtoNome) {
        const nomeResumido = produtoNome.slice(0, 50)

        return (
            <span
                title={produtoNome}
            >
                {produtoNome.length <= 50 ? nomeResumido : `${nomeResumido}...`}
            </span>
        )
    }

    render() {
        const {
            podeEditar,
            podeExcluir,
            podeInserir,
            exibirLoadingTransmissao,
            modalProdutoNfceVisible,
            modalFormaPagamentoVisible,
            modalHistoricoVisible,
            modalCancelamento,
            consumidorCadastrado,
            acessorias,
            desconto,
            desabilitarEdicao,
            validacoes,
            restart,
        } = this.state;

        const { values, isModal, dirty, isMobile } = this.props;

        const notaFiscalStatusBloqueado = (values.statusNfce === Status.TRANSMITIDA || values.statusNfce === Status.CANCELADA || values.statusNfce === Status.DENEGADA || values.statusNfce === Status.AGUARDANDO_AUTORIZACAO);
        const podeEditarForm = (values.statusNfce === Status.NAO_ENVIADA || values.statusNfce === Status.REJEITADA || values.statusNfce === null);

        const desabilitarBotaoFinalizar = (values.total.vNF <= 0 || !this.organizacaoPodeTransmitirNfce());
        const desabilitarBotaoExcluir = (!podeExcluir || notaFiscalStatusBloqueado);
        const desabilitarBotaoCancelarNfce = (!podeEditar || (values.statusNfce !== Status.TRANSMITIDA));

        const estadoBotaoNovo = dirty ? estadosBotaoNovo.SALVAR_E_NOVO : estadosBotaoNovo.NOVO;
        const onClickNovo = dirty ? (e) => this.salvar(null, null, e, this.novo) : this.novo;

        const informacoesPermissoes = {
            podeInserir: podeInserir,
            podeEditar: podeEditar,
            podeExcluir: podeExcluir,
            estadoCadastro: values.id ? estadosCadastro.EDICAO : estadosCadastro.INCLUSAO
        };
        const itensOpcoes = this.buscarItensOpcoes();

        return (
            <>
                <Prompt dirty={dirty} isModal={isModal} />
                <Menu model={itensOpcoes} style={{ minWidth: '230px' }} popup={true} ref={el => (this.menuOpcoes = el)} />

                <ModalLoadingTransmissao visible={exibirLoadingTransmissao} message="Transmitindo nota de consumidor..." />
                <Form className="card-default screen-max-width" header={this.montarHeader()} isModal={isModal}>
                    <If test={values.statusNfce === Status.AGUARDANDO_AUTORIZACAO}>
                        <Message severity="warn" text={this.buscarMensagemNFeAguardandoTransmissao()} colStyle={{ padding: '5px 0px' }} />
                    </If>
                    <FormActions className="screen-max-width">
                        <ButtonCancelar
                            hidden={isModal && values.id && !dirty}
                            estadoBotao={dirty ? estadosBotaoCancelar.CANCELAR : estadosBotaoCancelar.VOLTAR}
                            onClick={this.cancelar}
                            {...informacoesPermissoes}
                        />
                        <ButtonSalvar
                            disabled={!dirty}
                            onClick={this.salvar}
                            {...informacoesPermissoes}
                        />
                        <ButtonNovo
                            onClick={onClickNovo}
                            hidden={!dirty && !values.id}
                            estadoBotao={estadoBotaoNovo}
                            {...informacoesPermissoes}
                        />
                        <ButtonExcluir
                            hidden={!values.id}
                            onClick={this.excluir}
                            disabled={desabilitarBotaoExcluir}
                            title={desabilitarBotaoExcluir ? "Impossível excluir uma NFCe Finalizada" : ""}
                            {...informacoesPermissoes}
                        />
                        <Button
                            className="p-button-primary"
                            type="button"
                            label="Opções"
                            icon="fa fa-list"
                            style={{ margin: '5px' }}
                            hidden={itensOpcoes.length === 0}
                            onClick={event => this.menuOpcoes.toggle(event)}
                        />
                    </FormActions>
                    <NfceCardValidacao
                        erros={values.erros}
                        validacoes={validacoes}
                        isMobile={isMobile}
                    />
                    <If test={this.organizacaoPodeTransmitirNfce() === false}>
                        <Message severity="error" text={this.buscarMensagemOrganizacaoPodeTransmitirNfe()} />
                    </If>
                    <FormContent>
                        <Grid style={
                            {
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: (values.erros && values.erros.length > 0) || (validacoes && validacoes.length > 0) ? 'flex-start' : 'center'
                            }
                        }>
                            <Col sm='7' lg='6' xl='5'>
                                <NfceStatus
                                    backgroundColor={values.statusNfce ? infoStatusNfce[values.statusNfce].lightColor : null}
                                    color={values.statusNfce ? infoStatusNfce[values.statusNfce].strongColor : null}
                                    name={values.statusNfce ? infoStatusNfce[values.statusNfce].name : null}
                                    description={values.statusNfce ? infoStatusNfce[values.statusNfce].description : null}
                                />
                            </Col>
                            <Col sm='5' lg='6' xl='5'>
                                <NfceSerieNumero
                                    isMobile={isMobile}
                                    serie={values.serie}
                                    numero={values.nNfce}
                                    status={values.statusNfce}
                                />
                            </Col>
                        </Grid>
                        <Grid>
                            <Col sm="12" md="12" lg='12' xl='12'>
                                <ProdutoNfceForm
                                    restart={restart}
                                    disabled={desabilitarEdicao}
                                    onInsertProduto={informacoesProduto => this.validarInformacoesObrigatorias(informacoesProduto)}
                                    {...informacoesPermissoes}
                                />
                                <Divider />
                                <DataTable
                                    className="table-light"
                                    responsive
                                    scrollable
                                    scrollHeight="180px"
                                    value={values.produtos}
                                    emptyMessage={<NenhumRegistroEncontrado message="Nenhum produto incluso na NFC-e" />}
                                >
                                    <Column
                                        field="produto"
                                        header="Produto"
                                        body={(rowData) => this.renderNomeProduto(rowData.produto)}
                                        sortable={true}
                                        style={{ width: "17em" }}
                                    />
                                    <Column
                                        field="quantidade"
                                        header="Quantidade"
                                        sortable={true}
                                    />
                                    <Column
                                        field="valorUnitario"
                                        header="Unitário"
                                        body={(rowData) => formatarMonetario(rowData.valorUnitario)}
                                        sortable={true}
                                    />
                                    <Column
                                        field="descontoTotalProduto"
                                        header="Desconto"
                                        body={(rowData) => formatarMonetario(rowData.descontoTotalProduto)}
                                        sortable={true}
                                        style={{ width: "10em" }}
                                    />
                                    <Column
                                        field="totalProduto"
                                        header="Total"
                                        body={(rowData) => formatarMonetario(rowData.totalProduto)}
                                        sortable={true}
                                    />
                                    <Column
                                        body={this.renderOpcoes}
                                        header="Ações"
                                        style={{ width: "7em" }}
                                    />
                                </DataTable>
                                <Divider />
                            </Col>
                            <Col sm="12" md="6" lg='6' xl='6'>
                                <Card>
                                    <Fieldset legend="Identificação do consumidor" className='fieldset-light'>
                                        <Grid
                                            style={{ border: '1px' }}
                                        >
                                            <Field
                                                component={Checkbox}
                                                label="Consumidor já cadastrado"
                                                name="consumidorCadastrado"
                                                onChange={e => this.changeConsumidorCadastrado(e.checked)}
                                                checked={consumidorCadastrado}
                                                disabled={desabilitarEdicao}
                                                helpMessage={helpMessageNFCe.consumidorCadastrado}
                                                {...informacoesPermissoes}
                                            />
                                            <If test={!consumidorCadastrado}>
                                                <Field
                                                    sm="12" md="12" lg='12' xl='12'
                                                    component={InputField}
                                                    name="xNome"
                                                    type="text"
                                                    label={this.buscarLabelEPlaceholderDestinatarioNfce()}
                                                    placeholder={this.buscarLabelEPlaceholderDestinatarioNfce()}
                                                    value={values.dest?.xNome}
                                                    onChange={e => this.onChangeXnome(e.target.value)}
                                                    size={255}
                                                    disabled={desabilitarEdicao}
                                                    helpMessage={helpMessageNFCe.nomeConsumidor}
                                                    {...informacoesPermissoes}
                                                />
                                            </If>
                                            <If test={consumidorCadastrado}>
                                                <Field
                                                    sm="12" md="12" lg='12' xl='12'
                                                    name="cliente"
                                                    label="Cliente"
                                                    component={SingleSelectPessoa}
                                                    onChange={e => this.montarInformacoesDestinatarioCadastrado(e)}
                                                    url={`${services.GESTOR}/v1/nfce/relacoes/pessoas`}
                                                    disabled={desabilitarEdicao}
                                                    value={values.cliente}
                                                    helpMessage={helpMessageNFCe.cliente}
                                                    {...informacoesPermissoes}
                                                />
                                            </If>
                                            <Field
                                                sm="12" md="12" lg='12' xl='12'
                                                name="documento"
                                                component={InputSelectCpfOrCnpj}
                                                value={this.pegarValuesDocumento()}
                                                valueDocumentoTipo={values.documentoTipo}
                                                onChange={e => this.changeCpfOuCnpj(e)}
                                                onChangeDocumentoTipo={e => this.trocarTipoDocumento(e)}
                                                disabled={desabilitarEdicao}
                                                helpMessage={helpMessageNFCe.cpfOuCnpj}
                                                {...informacoesPermissoes}
                                            />
                                        </Grid>
                                    </Fieldset>
                                </Card>
                            </Col>
                            <Col sm="12" md="6" lg='6' xl='6'>
                                <Card>
                                    <Fieldset legend="Totais da NFC-e" className='fieldset-light'>
                                        <Grid>
                                            <Field
                                                sm="12" md="6" lg='6' xl='6'
                                                name="desconto"
                                                component={InputSelectPercentualOrValorNfce}
                                                label="Desconto NFC-e"
                                                value={desconto.valorDesconto}
                                                restart={this.state.restart}
                                                onRestart={() => { this.setState({ restart: false }) }}
                                                onChange={(tipo, value, percentual) => this.trocarTipoDescontoNfce(tipo, value, percentual)}
                                                sizeValor={9}
                                                placeholder='0,00'
                                                allowNegative={false}
                                                disabled={desabilitarEdicao || values.produtos.length === 0}
                                                helpMessage={helpMessageNFCe.descontoTotalNFCe}
                                                touched
                                                {...informacoesPermissoes}
                                            />
                                            <Field
                                                sm="12" md="6" lg='6' xl='6'
                                                name="acessorias"
                                                component={InputSelectPercentualOrValorNfce}
                                                label="Acessórias NFC-e"
                                                value={acessorias.valorAcessorias}
                                                restart={this.state.restart}
                                                onRestart={() => { this.setState({ restart: false }) }}
                                                onChange={(tipo, value, percentual) => this.trocarTipoAcessorias(tipo, value, percentual)}
                                                sizeValor={9}
                                                placeholder='0,00'
                                                allowNegative={false}
                                                disabled={desabilitarEdicao || values.produtos.length === 0}
                                                helpMessage={helpMessageNFCe.acessoriasTotalNFCe}
                                                {...informacoesPermissoes}
                                            />
                                            <Col>
                                                <p style={style}>{`Valor dos produtos: ${formatarMonetario(values.total.vProd)}`}</p>
                                                <p style={style}>{`Desconto dos produtos + Desconto NFC-e: ${formatarMonetario(this.calcularDescontoTotal())}`}</p>
                                                <p style={style}>{`Acessórias NFC-e: ${formatarMonetario(values.total.vOutro)}`}</p>
                                                <p style={totalStyle}>{`Total: ${formatarMonetario(this.calcularValorLíquidoNfce())}`}</p>
                                            </Col>
                                            <Col style={{ display: "flex", justifyContent: "flex-end" }}>
                                                <Button
                                                    style={{ marginRight: '10px' }}
                                                    className='p-button-danger'
                                                    label='Cancelar NFC-e'
                                                    icon='fa fa-send'
                                                    title={this.getTitleButtonFinalizar()}
                                                    onClick={() => this.setState({ modalCancelamento: true })}
                                                    disabled={desabilitarBotaoCancelarNfce}
                                                />
                                                <Button
                                                    className='p-button-success'
                                                    label={notaFiscalStatusBloqueado ? 'Visualizar pagamento' : 'Finalizar NFC-e'}
                                                    icon='fa fa-check'
                                                    title={this.getTitleButtonFinalizar()}
                                                    onClick={this.finalizarNfce}
                                                    disabled={desabilitarBotaoFinalizar}
                                                />
                                            </Col>
                                        </Grid>
                                    </Fieldset>
                                </Card>
                            </Col>
                        </Grid>
                        <If test={modalProdutoNfceVisible}>
                            <ModalProdutoNfce
                                salvar={produto => this.adicionarProdutoNaVenda(produto)}
                                atualizar={this.atualizarProduto}
                                ajustarProdutoIncompleto={this.state.produtoIncompleto}
                                editarProdutoDaNfce={this.state.produtoParaEdicao}
                                excluirProdutoDaNfce={produto => this.excluirRegistro(produto)}
                                visible={this.state.modalProdutoNfceVisible}
                                disabled={!podeEditarForm}
                                onHide={this.hideModalProduto}
                                {...informacoesPermissoes}
                            />
                        </If>
                        <If test={modalFormaPagamentoVisible}>
                            <ModalFormaPagamentoNfce
                                isMobile={isMobile}
                                totaisNfce={values.total}
                                pag={values.pag}
                                descontoPendenteRateio={values.descontoPendenteRateio}
                                salvarParcelas={(parcelas, acoesBtnSalvarNfce) => this.salvarParcelas(parcelas, acoesBtnSalvarNfce)}
                                disabled={desabilitarEdicao}
                                visible={this.state.modalFormaPagamentoVisible}
                                onHide={this.hideModalFormaPagamento}
                                informacoesPermissoes={informacoesPermissoes}
                            />
                        </If>
                        <If test={modalHistoricoVisible}>
                            <ModalHistorico
                                idNfce={values.id}
                                visible={modalHistoricoVisible}
                                onHide={() => this.setState({ modalHistoricoVisible: false })}
                            />
                        </If>
                        <If test={modalCancelamento}>
                            <ModalCancelamento
                                idNfce={values.id}
                                visible={modalCancelamento}
                                xJust={values.xJustCancelamento}
                                dhCancelamento={values.dhCancelamento}
                                statusNfce={values.statusNfce}
                                onHide={(confirmou) => {
                                    this.setState({ modalCancelamento: false }, () => {
                                        if (confirmou) this.asyncSelectNfce(values.id);
                                    })
                                }}
                            />
                        </If>
                    </FormContent>
                </Form>
            </>
        )
    }
}

NFCeForm = withFormik({

    enableReinitialize: true,
    validateOnChange: false,

    mapPropsToValues(props) {
        return initialValues;
    },

    validate(values) {

        let errors = {};

        if (values.documentoTipo === 'CPF') {
            if (values.dest?.xNome && !values.dest?.CPF)
                errors.documento = 'CPF obrigatório quando o Nome for preenchido.'

            if (values.dest?.CPF && !values.dest?.xNome)
                errors.documento = 'Nome obrigatório quando o CPF for preenchido.'

            if (values.dest?.CPF && !isValidCpf(manterApenasNumeros(values.dest?.CPF)))
                errors.documento = 'Digite um CPF Válido.'

        } else if (values.documentoTipo === 'CNPJ') {
            if (values.dest?.xNome && !values.dest?.CNPJ)
                errors.documento = 'CNPJ obrigatório quando a Razão Social for preenchida.'

            if (values.dest?.CNPJ && !values.dest?.xNome)
                errors.documento = 'Razão Social obrigatória quando o CNPJ for preenchido.'

            if (values.dest?.CNPJ && !isValidCnpj(manterApenasNumeros(values.dest?.CNPJ)))
                errors.documento = 'Digite um CNPJ Válido.'

        }

        if (values.produtos && values.produtos.length > 0 && (values.total.vDesc + values.descontoPendenteRateio) >= values.total.vProd)
            errors.desconto = 'Desconto maior que o valor da NFC-e.'

        if (!values.produtos || values.produtos.length === 0) {
            errors.produto = 'NFC-e deve conter pelo menos um produto.'
        }

        return errors

    },

    handleSubmit: () => { },
})(NFCeForm);

const mapStateToProps = state => ({
    isMobile: state.dispositivo.isMobile,
})

export default connect(mapStateToProps)(withRouter(NFCeForm));