import React, { Component } from 'react';
import autoBind from 'react-autobind';
import SingleSelect from '../SingleSelect';
import propTypes from 'prop-types'

class SingleSelectUsuario extends Component {

    constructor(props) {
        super(props);
        autoBind(this);
    }

    buscarUrlPesquisa(pesquisa, page) {
        const urlpesquisa = `${this.props.url}?query=nome=contains="*${pesquisa}*"&page=${page}&size=50&sort=nome`;
        return urlpesquisa;
    }

    montarLabel(row) {
        return row.nome
    }

    render() {

        return (
            <React.Fragment>
                <SingleSelect
                    esconderBotao={true}
                    montarLabel={this.montarLabel}
                    buscarUrlPesquisa={this.buscarUrlPesquisa}
                    onChange={this.props.onChange}
                    {...this.props}
                />
            </React.Fragment>
        );
    }
}

SingleSelectUsuario.defaultProps = {
    label: 'Usuário',
}

SingleSelectUsuario.propTypes = {
    /** Url do servidor em que as requisições de busca deverão ser efetuadas: Ex:  `${services.GESTOR}/v1/usuarios/relacoes/pessoas`*/
    url: propTypes.string.isRequired,
}

export default SingleSelectUsuario;
