import { TiposDesconto } from "../../../util/constantes"

export default function validarInformacoesObrigatoriasProduto(produto) {
    
    let validacoes = []

    if (!produto.cfop) {
        validacoes.push({ message: "CFOP não informada" })
    }

    if (!produto.csosn) {
        validacoes.push({ message: "CSOSN não informada" })
    }

    if (!produto.ncm) {
        validacoes.push({ message: "NCM não informada" })
    }

    if (!produto.origem) {
        validacoes.push({ message: "Origem não informada" })
    }

    if (!produto.unidadeMedida) {
        validacoes.push({ message: "Unidade de medida não informada" })
    }

    if (produto.quantidade && produto.valorUnitario) {
        if (produto.desconto) {
            const valorProduto = produto.quantidade * produto.valorUnitario

            if (produto.desconto.tipoDesconto === TiposDesconto.VALOR) {
                if (valorProduto <= produto.desconto.valorDesconto) {
                    validacoes.push({ message: "Valor de desconto maior que o valor do produto" })
                }
            }

            if(produto.desconto.tipoDesconto === TiposDesconto.PERCENTUAL) {
                const desconto = (valorProduto * produto.desconto.valorDesconto) / 100
                if(valorProduto <= desconto) {
                    validacoes.push({ message: "Valor de desconto maior que o valor do produto" })
                }
            }
        }
    } else {
        validacoes.push({ message: "Valor ou quantidade do produto não informados" })
    }

    return validacoes
}