import React, { Component } from 'react'
import Form from '../../../components/Form'
import autoBind from 'react-autobind/lib/autoBind'
import { usuarioPossuiPermissao } from '../../../common/autenticacao'
import { permissoes, recursos } from '../../../common/constantes/autorizacao'
import { construirUrl } from '../../../common/rsql'
import ButtonNovo from '../../../components/Button/ButtonNovo'
import FormActions from '../../../components/FormActions'
import FormContent from '../../../components/FormContent'
import Grid from '../../../components/Grid'
import InputSearch from '../../../components/Input/InputSearch'
import { asyncDeleteAtivo, asyncGetAtivos, asyncGetAtivoPorId } from './request'
import { services } from '../../../common/constantes/api'
import { formatarParaPesquisarTiposEnumerados } from '../../../common/manipulacaoDeString'
import TabelaAtivos from './components/TabelaAtivos'
import { removerElemento } from '../../../common/array'
import If from '../../../components/If'
import ModalAtivo from './components/ModalAtivo'
import PesquisaAvancada from '../../../components/PesquisaAvancada'
import { optionsFiltroAvancado } from './utils/constantes';
import DescricaoFiltroAvancado from '../../../components/DescricaoFiltroAvancado'
import { confirm } from '../../../components/Toast'

class Ativos extends Component {

    constructor(props) {
        super(props)
        autoBind(this)
        this.state = {
            podeInserir: usuarioPossuiPermissao(recursos.INVESTIDOR_ATIVOS, permissoes.INSERIR),
            podeExcluir: usuarioPossuiPermissao(recursos.INVESTIDOR_ATIVOS, permissoes.EXCLUIR),
            podeEditar: usuarioPossuiPermissao(recursos.INVESTIDOR_ATIVOS, permissoes.EDITAR),
            valorPesquisa: "",
            optionsFiltroAvancado: optionsFiltroAvancado,
            registros: [],
            descricaoFiltroAvancado: '',
            limparFiltroPesquisaAvancada: false,
            sortField: 'nome',
            valorFiltroAvancado: '',
            sortOrder: 1,
            totalElements: 0,
            tutorialVisible: false,
            exibirModalAtivo: false,
            exibirModalExclusao: false,
            registroSelecionado: null,
            page: 0,
            rows: 0,
            size: 10,
            first: 0
        }
    }

    componentDidMount() {
        this.pesquisar()
    }

    async pesquisar() {
        const { page, size, sortField, sortOrder } = this.state
        const filtro = this.buscarFiltro()
        const url = construirUrl(`${services.GESTOR}/v1/investidor/ativos`, filtro || "?", size, page, sortOrder > 0 ? `${sortField},asc&sort=codigo,desc` : `${sortField},desc&sort=nome,desc`);

        await asyncGetAtivos(url, ({ data: ativos }) => {
            this.setState({
                registros: ativos.content,
                totalElements: ativos.totalElements
            })
        })
    }

    buscarFiltro() {
        const { valorFiltroAvancado, valorPesquisa, } = this.state;

        const valorTiposEnumerados = formatarParaPesquisarTiposEnumerados(valorPesquisa);

        let filtroRSQL = String("?query=(")
            .concat(`nome=contains="*${valorPesquisa}*",`)
            .concat(`tipo=contains="*${valorTiposEnumerados}*",`)
            .concat(`segmento=contains="*${valorPesquisa}*",`)
            .concat(`cnpj=contains="*${valorPesquisa}*",`)
            .concat(`codigo=contains="*${valorPesquisa}*")`)

        if (valorFiltroAvancado) {
            filtroRSQL = filtroRSQL.concat(`;(${valorFiltroAvancado})`)
        }

        return filtroRSQL;
    }

    async handleEditItem(item) {
        await asyncGetAtivoPorId(item.id, ({ data: ativo }) => {
            this.setState({ registroSelecionado: ativo, exibirModalAtivo: true })
        })
    }

    onHideModalAtivo() {
        this.pesquisar()
        this.setState({
            exibirModalAtivo: false,
            registroSelecionado: null
        })
    }

    async handleRemoveItem(item) {
        const idAtivo = item.id
        if (idAtivo) {
            confirm('Confirmação', "Deseja realmente excluir o ativo?", async () => {
                await asyncDeleteAtivo(idAtivo, () =>  this.pesquisar())
            });
        }
    }

    async asyncDeleteAtivo(registro) {
        await asyncDeleteAtivo(registro.id, () => {
            this.setState({
                registros: removerElemento(this.state.registros, registro),
                totalElements: this.state.totalElements - 1
            })
        })
    }

    onPesquisarFiltroAvancado(e) {
        this.setState({ valorFiltroAvancado: e, limparFiltroPesquisaAvancada: false }, () => {
            this.pesquisar()
        });
    }

    onPesquisar() {
        this.setState({ page: 0, first: 0 }, () => {
            this.pesquisar();
        })
    }

    onPageChange(e) {
        this.setState({ page: e.page, rows: e.rows }, () => {
            this.pesquisar()
        })
    }

    render() {
        const {
            registroSelecionado,
            descricaoFiltroAvancado,
            limparFiltroPesquisaAvancada,
            optionsFiltroAvancado,
            exibirModalAtivo,
            size,
            first,
            podeInserir,
            totalRecords,
            sortField,
            sortOrder,
            registros,
            valorPesquisa
        } = this.state

        return (
            <>
                <Form header="Ativos">
                    <FormActions>
                        <ButtonNovo
                            label="Novo ativo"
                            title="Inserir um novo ativo"
                            onClick={() => this.setState({ exibirModalAtivo: true, registroSelecionado: null })}
                            disabled={!podeInserir}
                        />
                    </FormActions>
                    <FormContent>
                        <Grid justifyCenter verticalAlignCenter>
                            <InputSearch
                                className="step-listagem-input-search"
                                onPesquisar={this.onPesquisar}
                                value={valorPesquisa}
                                onChange={value => this.setState({ valorPesquisa: value })}
                            />
                            <span style={{ padding: '12px' }}>
                                <PesquisaAvancada
                                    optionsFiltros={optionsFiltroAvancado}
                                    onPesquisarClick={this.onPesquisarFiltroAvancado}
                                    onChangeFiltroRsql={rsql => this.setState({ valorFiltroAvancado: rsql })}
                                    onChangeDescricaoFiltro={e => this.setState({ descricaoFiltroAvancado: e })}
                                    liparFiltro={limparFiltroPesquisaAvancada}
                                />
                            </span>
                        </Grid>
                        <Grid style={{ paddingTop: '10px' }} justifyCenter verticalAlignCenter>
                            <DescricaoFiltroAvancado texto={descricaoFiltroAvancado} />
                            <TabelaAtivos
                                registros={registros}
                                totalRecords={totalRecords}
                                rows={size}
                                first={first}
                                sortField={sortField}
                                sortOrder={sortOrder}
                                setSortField={sortField => this.setState({ sortField })}
                                setSortOrder={sortOrder => this.setState({ sortOrder }, () => this.pesquisar())}
                                onEditItem={this.handleEditItem}
                                onRemoveItem={this.handleRemoveItem}
                                onPageChange={e => this.setState({ first: e.first, size: e.rows, page: e.page }, () => this.pesquisar())}
                            />
                        </Grid>
                    </FormContent>
                </Form>
                <If test={exibirModalAtivo}>
                    <ModalAtivo
                        onNovoClick={() => this.setState({ registroSelecionado: null })}
                        visible={exibirModalAtivo}
                        onHide={this.onHideModalAtivo}
                        atualizarListagem={this.pesquisar}
                        registroSelecionado={registroSelecionado}
                    />
                </If>
            </>
        )
    }
}

export default Ativos