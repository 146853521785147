import { tipoCampos } from "../../../../components/PesquisaAvancada/util/constantes";

export const optionsFiltroAvancado = [
    {
        label: 'Tipo', name: 'tipo', type: tipoCampos.SELECT,
        optionSelect: [
            { label: "Dividendo", value: "DIVIDENDO" },
            { label: "Rendimento", value: "RENDIMENTO" },
            { label: "JCP", value: "JCP" },
        ]
    },
    { label: 'Quantidade', name: 'quantidade', type: tipoCampos.DECIMAL },
    { label: 'Valor bruto', name: 'valorBruto', type: tipoCampos.DECIMAL },
    { label: 'Valor líquido', name: 'valorLiquido', type: tipoCampos.DECIMAL },
    { label: 'Fator cotação', name: 'fatorCotacao', type: tipoCampos.DECIMAL },
    { label: 'Valor IR fonte', name: 'valorIrFonte', type: tipoCampos.DECIMAL }
]

export const ColorsCard = {
    DIVIDENDO: {
        lightColor: '#a2e2ff',
        strongColor: '#145383',
    },
    RENDIMENTO: {
        lightColor: '#dcedc8',
        strongColor: '#1b5e20',
    },
    JCP: {
        lightColor: '#f6fab9',
        strongColor: '#996a13',
    },
    TOTAL: {
        lightColor: '#e0e0e0',
        strongColor: '#000000de',
    }
}

export const Tipo = {
    RENDIMENTO: 'RENDIMENTO',
    DIVIDENDO: 'DIVIDENDO',
    JCP: 'JCP'
};