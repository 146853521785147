import React, { Component } from 'react';
import { DataTable } from 'primereact/components/datatable/DataTable';
import { Column } from 'primereact/column';
import { formatarMonetario } from '../../../../../common/mascara';
import Button from '../../../../../components/Button';
import format from 'date-fns/format';
import { parse } from 'date-fns';
import autoBind from 'react-autobind';
import { Paginator } from 'primereact/paginator';
import { recursos, permissoes } from '../../../../../common/constantes/autorizacao';
import { usuarioPossuiPermissao } from '../../../../../common/autenticacao';
import NenhumRegistroEncontrado from '../../../../../components/NenhumRegistroEncontrado';
import { Financas } from '../../../../util/constantes'

const styleButton = {
    borderRadius: '50%',
    padding: '5px',
    width: '30px',
    height: '30px',
    margin: '2px'
};

class TabelaLancamentos extends Component {

    constructor(props) {
        super(props);
        autoBind(this);

        this.state = {
            podeExcluirLancamento: usuarioPossuiPermissao(recursos.FINANCAS_LANCAMENTOS, permissoes.EXCLUIR),
            podeEditarContas: usuarioPossuiPermissao(recursos.FINANCAS_CONTAS, permissoes.VISUALIZAR) && usuarioPossuiPermissao(recursos.FINANCAS_CONTAS, permissoes.EDITAR),
            podeEditarContaReceber: usuarioPossuiPermissao(recursos.FINANCAS_CONTAS_RECEBER, permissoes.VISUALIZAR) && usuarioPossuiPermissao(recursos.FINANCAS_CONTAS_RECEBER, permissoes.EDITAR),
            podeEditarContaPagar: usuarioPossuiPermissao(recursos.FINANCAS_CONTAS_PAGAR, permissoes.VISUALIZAR) && usuarioPossuiPermissao(recursos.FINANCAS_CONTAS_PAGAR, permissoes.EDITAR),
        }
    }

    atualizarOrdenacao(e) {
        this.props.setSortField(e.sortField);
        this.props.setSortOrder(e.sortOrder);
    }

    renderDataField(el) {
        return <span>{format(parse(el.data, 'yyyy-MM-dd', new Date()), 'dd/MM/yyyy')}</span>
    }

    renderDescricaoField(el) {
        return <span title={el.descricao}>{el.descricao}</span>
    }

    renderCategoriaField(el) {
        if (el.origem === "TRANSFERENCIA") {
            return <span>Transferência</span>;
        } else if (el.origem === "SALDO_INICIAL") {
            return <span>Saldo inicial</span>;
        }
        return (
            <span title={el.categoriaNome} style={{ color: el.categoriaAparencia, fontWeight: 'bold' }}>
                {String(el.categoriaNome).length > 50 ? String(el.categoriaNome).slice(0, 50).concat("...") : el.categoriaNome || ""}
            </span>
        );
    }

    renderContaNomeField(el) {
        return <span title={el.contaNome} style={{ display: 'inline-flex', justifyContent: 'center' }}>{el.contaNome}</span>
    }

    renderValorField(el) {
        const valor = formatarMonetario(el.valor)

        if (el.tipo === "DESPESA") {
            return (
                <span style={{
                    backgroundColor: Financas.cores.vermelhoClaro,
                    color: Financas.cores.vermelho,
                    fontWeight: 'bold',
                    fontSize: '13px',
                    borderRadius: '3px',
                    padding: '3px 10px',
                }}>
                    <span style={{ width: '100%', textAlign: 'center' }}>{valor}</span>
                </span>
            )
        } else {
            return (
                <span style={{
                    backgroundColor: Financas.cores.verdeClaro,
                    color: Financas.cores.verde,
                    fontWeight: 'bold',
                    fontSize: '13px',
                    borderRadius: '3px',
                    padding: '3px 10px',
                }}>
                    <span style={{ width: '100%', textAlign: 'center' }}>{valor}</span>
                </span>
            )
        }
    }

    renderAcoesField(el) {
        const { podeExcluirLancamento, podeEditarContas, podeEditarContaPagar, podeEditarContaReceber } = this.state;
        let titleEditar = "Editar";
        let titleExcluir = "Excluir";
        let disableBtnEditar = false;
        let disableBtnExcluir = false;

        if (el.origem === "RECEBIMENTO" || el.origem === "PAGAMENTO") {
            if ((el.origem === "RECEBIMENTO" && !podeEditarContaReceber) || (el.origem === "PAGAMENTO" && !podeEditarContaPagar)) {
                titleEditar = "Você não possui permissão para executar essa ação"
                disableBtnEditar = true;
            }
            titleExcluir = "Para excluir uma movimentação vindo da contas a receber ou a pagar, clique no botão de editar e em seguinda em 'Excluir'"
            disableBtnExcluir = true;
        };

        if (el.origem === "SALDO_INICIAL") {
            if (!podeEditarContas) {
                titleEditar = "Você não possui permissão para executar essa ação"
                disableBtnEditar = true;
            };
            titleExcluir = "Não é possível excluir uma movimentação de saldo inicial. Caso a movimentação estiver incorreta, edite-a."
            disableBtnExcluir = true;
        }
        if (!podeExcluirLancamento) {
            titleExcluir = "Você não possui permissão para executar essa ação"
            disableBtnExcluir = true;
        };

        return (
            <span>
                <Button
                    style={styleButton}
                    className="p-button p-button-primary"
                    icon="fa fa-pencil"
                    title={titleEditar}
                    disabled={disableBtnEditar}
                    onClick={() => this.props.onEditItem(el)}
                />
                <Button
                    style={styleButton}
                    className="p-button p-button-danger"
                    icon="fa fa-trash"
                    title={titleExcluir}
                    disabled={disableBtnExcluir}
                    onClick={() => this.props.onRemoveItem(el)}
                />
            </span>
        );
    }

    render() {
        const { registros, sortField, sortOrder, onPageChange, first, rows, totalRecords } = this.props;

        return (
            <>
                <DataTable
                    className="table-light"
                    value={registros}
                    responsive
                    sortField={sortField}
                    sortOrder={sortOrder}
                    onSort={this.atualizarOrdenacao}
                    emptyMessage={<NenhumRegistroEncontrado message="Nenhuma movimentação encontrada" />}
                >
                    <Column
                        header="Data"
                        field="data"
                        sortable={true}
                        body={this.renderDataField}
                        style={{ color: '#333333', width: '90px' }}
                    />
                    <Column
                        header="Descrição"
                        field="descricao"
                        body={this.renderDescricaoField}
                    />
                    <Column
                        header="Categoria"
                        field="categoriaNome"
                        sortable={true}
                        body={this.renderCategoriaField}
                        style={{ color: '#333333', wordBreak: 'break-all' }}
                    />
                    <Column
                        header="Conta"
                        field="contaNome"
                        sortable={true}
                        body={this.renderContaNomeField}
                        style={{ color: '#333333' }}
                    />
                    <Column
                        header="Valor"
                        field="valor"
                        sortable={true}
                        body={this.renderValorField}
                        style={{ color: '#333333' }}
                    />
                    <Column
                        header="Ações"
                        body={this.renderAcoesField}
                        style={{ width: '100px' }}
                    />
                </DataTable>
                <Paginator
                    className='paginator-light'
                    rowsPerPageOptions={[10, 25, 50]}
                    totalRecords={totalRecords}
                    rows={rows}
                    first={first}
                    onPageChange={onPageChange}
                    rightContent={<div style={{ margin: '5px' }}>{totalRecords > 0 ? `${totalRecords} registro(s) encontrado(s)` : null}</div>}
                />
            </>
        );

    }
}

export default TabelaLancamentos;
