import React, { Component } from 'react'
import autoBind from 'react-autobind';
import { buscarResultadoFinanceiro } from './requests';
import Col from '../../../../../../../components/Col';
import CardReceitas from './components/CardReceitas';
import CardDespesas from './components/CardDespesas';
import CardBalanco from './components/CardBalanco';

export default class GraficosDosResultadosFinanceiros extends Component {

    constructor(props) {
        super(props);

        autoBind(this);

        this.state = {
            loading: true,
            error: false,
            receitasRecebidas: 0,
            receitasAReceber: 0,
            receitasTotal: 0,
            despesasPagas: 0,
            despesasAPagar: 0,
            despesasTotal: 0,
            saldoTotal: 0,
            saldoRealizado: 0,
            saldoPendente: 0,
        }
    }

    componentDidMount() {
        this.carregarInformacoes();
    }

    componentDidUpdate(prevProps) {
        const { dataInicial, dataFinal } = this.props;

        if (prevProps.dataInicial !== dataInicial || prevProps.dataFinal !== dataFinal) {
            this.carregarInformacoes();
        }

        if (prevProps.atualizarGraficoNovamente !== this.props.atualizarGraficoNovamente) {
            this.carregarInformacoes(false);
        }
    }

    carregarInformacoes(exibirLoading = true) {
        const { dataInicial, dataFinal } = this.props;

        this.setState({ loading: exibirLoading, error: false }, () => {
            buscarResultadoFinanceiro(dataInicial, dataFinal, ({ data: resultadoFinanceiro }) => {
                this.setState({
                    receitasRecebidas: resultadoFinanceiro.receitasRecebidas,
                    receitasAReceber: resultadoFinanceiro.receitasAReceber,
                    receitasTotal: resultadoFinanceiro.receitasTotal,
                    despesasPagas: resultadoFinanceiro.despesasPagas,
                    despesasAPagar: resultadoFinanceiro.despesasAPagar,
                    despesasTotal: resultadoFinanceiro.despesasTotal,
                    saldoTotal: resultadoFinanceiro.saldoTotal,
                    saldoRealizado: resultadoFinanceiro.saldoRealizado,
                    saldoPendente: resultadoFinanceiro.saldoPendente,
                    loading: false,
                    error: false
                })
            }, () => {
                this.setState({ error: true, loading: false })
            });
        })
    }

    render() {
        const {
            receitasRecebidas,
            receitasAReceber,
            receitasTotal,
            despesasPagas,
            despesasAPagar,
            despesasTotal,
            saldoTotal,
            saldoRealizado,
            saldoPendente,
        } = this.state;

        //Remove o padding lateral dos cards quando for dispositivo móvel
        const paddingCards = this.props.isMobile ? { padding: '0.5em 0em' } : { padding: '0.5em' };

        return (
            <>
                <Col xs="12" sm="6" md="4" lg="4" xl="4" style={paddingCards}>
                    <CardReceitas
                        receitasRecebidas={receitasRecebidas}
                        receitasAReceber={receitasAReceber}
                        receitasTotal={receitasTotal}
                        loading={this.state.loading}
                        error={this.state.error}
                    />
                </Col>
                <Col xs="12" sm="6" md="4" lg="4" xl="4" style={paddingCards}>
                    <CardDespesas
                        despesasPagas={despesasPagas}
                        despesasAPagar={despesasAPagar}
                        despesasTotal={despesasTotal}
                        loading={this.state.loading}
                        error={this.state.error}
                    />
                </Col>
                <Col xs="12" sm="12" md="4" lg="4" xl="4" style={paddingCards}>
                    <CardBalanco
                        saldoTotal={saldoTotal}
                        saldoRealizado={saldoRealizado}
                        saldoPendente={saldoPendente}
                        loading={this.state.loading}
                        error={this.state.error}
                    />
                </Col>
            </>
        )
    }
}

