export const helpMessageNFCe = {
    produto: 'Produto vendido na NFC-e',
    descontoProduto: 'Desconto aplicado no item da venda',
    acessoriasProduto: 'Valor de acessórias da NFC-e',
    quantidade: 'Quantidade ou peso do produto vendido',
    consumidorCadastrado: 'Marque para buscar um Consumidor já cadastrado',
    nomeConsumidor: 'Nome ou Razão Social do Consumidor',
    cliente: 'Cliente cadastrado no sistema',
    cpfOuCnpj: 'CPF ou CNPJ do Consumidor',
    descontoTotalNFCe:'Desconto aplicado na NFC-e',
    acessoriasTotalNFCe:'Acessórias aplicada na NFC-e',
    justificativaCancelamento: 'Motivo do cancelamento da NFC-e',
    valorUnitario: 'Valor unitário do produto vendido',
    unidadeMedida: 'Unidade de medida do produto vendido',
    ncm: 'NCM do produto vendido',
    cest: 'CEST do produto vendido',
    cfop: 'CFOP do produto vendido',
    csosn: 'CSOSN do produto vendido',
    origemProduto: 'Origem do produto vendido',

}
