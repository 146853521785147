import { tipoCampos } from "../../../../components/PesquisaAvancada/util/constantes";

export const optionsFiltroAvancado = [
    {
        label: 'Tipo', name: 'tipo', type: tipoCampos.SELECT,
        optionSelect: [
            { label: "Ação", value: "ACAO" },
            { label: "Criptomoeda", value: "CRIPTOMOEDA" },
            { label: "Fundos imobiliários", value: "FII" },
        ]
    },
    { label: 'Código', name: 'codigo', type: tipoCampos.STRING },
    { label: 'Nome', name: 'nome', type: tipoCampos.STRING },
    { label: 'Segmento', name: 'segmento', type: tipoCampos.STRING },
]