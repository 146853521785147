import React from 'react';
import Modal from '../../../../../../../components/Modal';
import Grid from '../../../../../../../components/Grid';
import Lottie from 'react-lottie';

import animation from '../../../../../../../app/animation/transmitir-nfe.json';

export default function ModalLoadingTransmissao({ visible, onFinish, message }) {
    return (
        <Modal visible={visible} showCloseIcon={false} styleModal={{ background: 'unset', boxShadow: 'unset', padding: '0px' }}>
            <Grid justifyCenter>
                <Lottie
                    style={{ width: '100%' }}
                    options={{
                        animationData: animation,
                        autoplay: true,
                        loop: true
                    }}
                    isClickToPauseDisabled
                    height={200}
                    width={200}
                    eventListeners={[
                        {
                            eventName: 'loopComplete',
                            callback: () => onFinish && onFinish(),
                        },
                    ]}
                />
            </Grid>
            <Grid justifyCenter style={{ fontSize: '16px', color: '#fff' }}>
                {message || "Transmitindo nota fiscal..."}
            </Grid>
        </Modal>
    )
}

