import React, { Component } from 'react';
import { Card } from 'primereact/card';
import autoBind from 'react-autobind';
import { buscarDespesasPrevistas } from './requests';
import { ProgressBar } from 'primereact/progressbar';
import { formatarMonetario } from '../../../../../../../common/mascara';
import { format, parse } from 'date-fns';
import { Button } from 'primereact/button';

import './styles/index.css';
import { usuarioPossuiPermissao } from '../../../../../../../common/autenticacao';
import { recursos, permissoes } from '../../../../../../../common/constantes/autorizacao';
import NenhumaInformacaoEncontrada from '../../../NenhumaInformacaoEncontrada';
import { Link } from 'react-router-dom';
import FalhaAoCarregarAsInformacoes from '../../../FalhaAoCarregarAsInformacoes';
import ModalPagamentoTable from '../../../../../../financas/ContasPagar/components/ModalPagamentoTable';
import If from '../../../../../../../components/If';
import { Financas } from '../../../../../../util/constantes'

const Divider = () => {
    return <hr id="divider-previsoes-despesas" />
}

const List = props => {
    return (
        <div id="previsoes-despesas-content" className='dashboard-scroll'>
            <ul style={{ listStyle: "none", padding: '0px 3px', margin: '0px' }}>
                {props.children}
            </ul>
        </div>
    );
}

const ListItem = props => {
    return (
        <li style={{ margin: '6px 0px' }}>
            <div className="p-grid" style={{ margin: '0px 6px', }}>
                <span id="previsoes-despesas-title" title={props.descricao}>
                    {props.itemRepeticao && props.quantidadeRepeticoes ? `${props.descricao || "Nenhuma descrição informada"} (${props.itemRepeticao}/${props.quantidadeRepeticoes})` : `${props.descricao || "Nenhuma descrição informada"}`}
                </span>
            </div>
            <div className="p-grid p-align-center vertical-container" style={{ margin: '0px' }}>
                <div className="p-col" style={{ padding: '0px 6px' }}>
                    <p id="previsoes-despesas-coluna-data">
                        {format(parse(props.vencimento, 'yyyy-MM-dd', new Date()), 'dd/MM/yyyy')}
                    </p>
                </div>
                <div id="previsoes-despesas-coluna-valor" className="p-col" style={{color: `${Financas.cores.vermelho}`}}>
                    {formatarMonetario(props.valor)}
                </div>
                {props.podeEfetuarPagamento ? (
                    <div className="p-col-fixed" style={{ padding: '0px 6px', color: Financas.cores.vermelho }}>
                        <div id="previsoes-despesas-button-content">
                            <Button
                                className="p-button p-button-secondary"
                                icon="fa fa-check"
                                title="Efetuar pagamento da despesa"
                                onClick={props.efetuarPagamento}
                            />
                        </div>
                    </div>
                ) : null}
            </div>
        </li>
    );
}

export default class PrevisoesDeDespesas extends Component {

    constructor(props) {
        super(props);

        autoBind(this);

        this.state = {
            loading: true,
            error: false,
            despesasPrevistas: [],
            exibirModalConfirmarRecebimento: false,
            podeVisualizarContasPagar: usuarioPossuiPermissao(recursos.FINANCAS_CONTAS_PAGAR, permissoes.VISUALIZAR),
            podeEditarContasPagar: usuarioPossuiPermissao(recursos.FINANCAS_CONTAS_PAGAR, permissoes.EDITAR)
        }
    }

    componentDidMount() {
        this.carregarInformacoes();
    }

    componentDidUpdate(prevProps) {
        const { dataInicial, dataFinal } = this.props;

        if (prevProps.dataInicial !== dataInicial || prevProps.dataFinal !== dataFinal) {
            this.carregarInformacoes();
        }

        if (prevProps.atualizarGraficoNovamente !== this.props.atualizarGraficoNovamente) {
            this.carregarInformacoes(false);
        }
    }

    carregarInformacoes(exibirLoading = true) {
        const { dataInicial, dataFinal } = this.props;

        this.setState({ loading: exibirLoading, error: false }, () => {
            buscarDespesasPrevistas(dataInicial, dataFinal, ({ data: despesasPrevistas }) => {
                this.setState({ despesasPrevistas, loading: false, error: false });
            }, () => {
                this.setState({ error: true, loading: false })
            });
        })
    }

    getHeaderCard() {
        return (
            <div id="card-header-previsoes-despesas">
                <label title="Lista as despesas pendentes para o período informado">
                    Despesas pendentes do período
                </label>
            </div>
        );
    }

    onHideModalRecebimentoTable(atualizou) {
        if (atualizou) {
            this.setState({ exibirModalConfirmarRecebimento: false });
            this.props.atualizarGraficos();
        } else {
            this.setState({ exibirModalConfirmarRecebimento: false });
        }
    }

    onEfetuarPagamento(despesaPrevista) {
        this.setState({
            exibirModalConfirmarRecebimento: true,
            registroSelecionado: { ...despesaPrevista, valorAPagar: despesaPrevista.valor }
        })
    }

    render() {
        if (this.state.loading) {
            return (
                <Card header={this.getHeaderCard()} style={{ borderRadius: '5px' }}>
                    <ProgressBar mode="indeterminate" style={{ height: '3px' }} />
                </Card>
            )
        } else if (this.state.error) {
            return (
                <Card header={this.getHeaderCard()} style={{ borderRadius: '5px', height: '100%' }}>
                    <FalhaAoCarregarAsInformacoes />
                </Card>
            )
        } else {
            const { despesasPrevistas, exibirModalConfirmarRecebimento, registroSelecionado } = this.state;
            return (
                <>
                    <Card header={this.getHeaderCard()} style={{ borderRadius: '5px', height: '100%' }}>
                        {despesasPrevistas.length > 0 ? (
                            <>
                                <List>
                                    {despesasPrevistas.map((despesaPrevista, idx, array) => {
                                        return (
                                            <React.Fragment key={despesaPrevista.id}>
                                                <ListItem
                                                    id={despesaPrevista.id}
                                                    descricao={despesaPrevista.descricao}
                                                    vencimento={despesaPrevista.vencimento}
                                                    itemRepeticao={despesaPrevista.itemRepeticao}
                                                    quantidadeRepeticoes={despesaPrevista.quantidadeRepeticoes}
                                                    valor={despesaPrevista.valor}
                                                    carregarInformacoes={this.carregarInformacoes}
                                                    efetuarPagamento={() => this.onEfetuarPagamento(despesaPrevista)}
                                                    podeEfetuarPagamento={this.state.podeEditarContasPagar && this.state.podeVisualizarContasPagar}
                                                />
                                                {++idx !== array.length ? <Divider /> : null}
                                            </React.Fragment>
                                        )
                                    })}
                                </List>
                                <Divider />
                                {this.state.podeVisualizarContasPagar && (
                                    <div style={{ textAlign: 'center', paddingTop: '10px' }}>
                                        <Link to={{ pathname: "/contas_pagar", state: { dataInicial: this.props.dataInicial, dataFinal: this.props.dataFinal } }}>Listar despesas</Link>
                                    </div>
                                )}
                            </>
                        ) : (
                                <NenhumaInformacaoEncontrada />
                            )}
                    </Card>
                    <If test={exibirModalConfirmarRecebimento}>
                        <ModalPagamentoTable
                            registroSelecionado={registroSelecionado}
                            visible={exibirModalConfirmarRecebimento}
                            onHide={this.onHideModalRecebimentoTable}
                        />
                    </If>
                </>
            )
        }
    }
}
