import React, { Component } from 'react';
import autoBind from 'react-autobind';
import { recursos, permissoes } from '../../../common/constantes/autorizacao';
import SingleSelect from '../SingleSelect';
import { usuarioPossuiPermissao } from '../../../common/autenticacao';
import { services } from '../../../common/constantes/api';

class SingleSelectNcm extends Component {
    constructor(props) {
        super(props);

        autoBind(this);

        this.state = {
            visible: false,
            podeVisualizar: usuarioPossuiPermissao(recursos.PRODUTOS, permissoes.VISUALIZAR),
        }
    }

    buscarUrlPesquisa(pesquisa, page) {
        return `${services.GESTOR}/v1/ncms?query=codigo=contains="${pesquisa}",descricao=contains="*${pesquisa}*"&page=${page}&size=50&sort=codigo`;
    }

    montarLabel(row) {
        return row.codigo + ' - ' + row.descricao
    }

    render() {

        const { podeVisualizar } = this.state;

        return (
            <React.Fragment>
                <SingleSelect
                    esconderBotao
                    buscarUrlPesquisa={this.buscarUrlPesquisa}
                    montarLabel={this.montarLabel}
                    {...this.props}
                    disabledButton={!podeVisualizar}
                    onChange={this.props.onChange}
                />
            </React.Fragment>
        );
    }
}

SingleSelectNcm.defaultProps = {
    label: 'NCM',

}

export default SingleSelectNcm;
