import React, { Component } from "react";
import { usuarioPossuiPermissao } from "../../../common/autenticacao";
import { recursos, permissoes } from "../../../common/constantes/autorizacao";
import autoBind from "react-autobind/lib/autoBind";
import { TabView, TabPanel } from 'primereact/tabview';
import ModalCategoria from "./components/ModalCategoria";
import TreeTabelaCategorias from "./components/TreeTabelaCategorias";
import { services } from "../../../common/constantes/api";
import { confirm } from "../../../components/Toast";
import {
    asyncBuscarCategorias,
    asyncDeleteCategoria,
    asyncInativarCategoria,
    asyncAtivarCategoria,
    buscarCategoria
} from "./requests";
import './style/tab-view.css'
import If from "../../../components/If";
import FormActions from "../../../components/FormActions";
import Form from "../../../components/Form";
import FormContent from "../../../components/FormContent";
import ButtonNovo from "../../../components/Button/ButtonNovo";
import Grid from "../../../components/Grid";
import { connect } from "react-redux";

class Categorias extends Component {

    constructor(props) {
        super(props);

        autoBind(this)

        this.state = {
            podeVisualizar: usuarioPossuiPermissao(recursos.FINANCAS_CATEGORIAS, permissoes.VISUALIZAR),
            podeEditar: usuarioPossuiPermissao(recursos.FINANCAS_CATEGORIAS, permissoes.EDITAR),
            podeInserir: usuarioPossuiPermissao(recursos.FINANCAS_CATEGORIAS, permissoes.INSERIR),
            podeExcluir: usuarioPossuiPermissao(recursos.FINANCAS_CATEGORIAS, permissoes.EXCLUIR),

            tabSelecionada: 0,

            categorias: [],

            exibirModalCategoria: false,
            categoriaSelecionada: null,

            tituloTabReceita: "Categorias de receitas",
            tituloTabDespesa: "Categorias de despesas",
        }
    }

    componentDidMount() {
        this.buscarCategorias();
        this.renderizarTituloTabView();
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.atualizarTituloTabView);
    }

    renderizarTituloTabView() {
        const larguraInicial = window.innerWidth;

        if (larguraInicial < 500) {
            this.setState({ tituloTabReceita: "Receitas", tituloTabDespesa: "Despesas" })
        }

        window.addEventListener("resize", this.atualizarTituloTabView);
    }

    atualizarTituloTabView() {
        if ((window.innerWidth < 500) && (this.state.tituloTabReceita.length > 20)) {
            this.setState({
                tituloTabReceita: "Receitas",
                tituloTabDespesa: "Despesas"
            })
        } else if ((window.innerWidth > 500) && (this.state.tituloTabDespesa.length < 20)) {
            this.setState({
                tituloTabReceita: "Categorias de receitas",
                tituloTabDespesa: "Categorias de despesas"
            })
        }
    }

    pesquisar() {
        this.buscarCategorias();
    }

    buscarCategorias() {
        asyncBuscarCategorias(`${services.GESTOR}/v1/categorias`, ({ data: categorias }) => {
            this.setState({ categorias })
        })
    }


    excluirCategoria(categoria) {
        confirm("Confirmação", `Tem certeza que deseja excluir a categoria?`, () => {
            asyncDeleteCategoria(categoria.id, () => {
                this.setState({ categoriaSelecionada: null }, () => {
                    this.pesquisar();
                });
            })
        })
    }


    inativarCategoria(categoria) {
        buscarCategoria(categoria.id, ({ data: categoria }) => {
            confirm("Confirmação", `Tem certeza que deseja inativar a categoria?`, () => {
                asyncInativarCategoria(categoria, () => {
                    this.setState({ categoriaSelecionada: null }, () => {
                        this.pesquisar();
                    });
                })
            })
        })


    }

    ativarCategoria(categoria) {
        buscarCategoria(categoria.id, ({ data: categoria }) => {
            confirm("Confirmação", `Tem certeza que deseja ativar a categoria?`, () => {
                asyncAtivarCategoria(categoria, () => {
                    this.setState({ categoriaSelecionada: null }, () => {
                        this.pesquisar();
                    });
                })
            })
        })
    }

    selecionarCategoria(categoria) {
        this.setState({ categoriaSelecionada: categoria });
    }

    onHideModalCategoria() {
        this.setState({ exibirModalCategoria: false, categoriaSelecionada: null })
        this.pesquisar()
    }

    render() {

        const informacoesPermissoes = {
            podeInserir: this.state.podeInserir,
            podeVisualizar: this.state.podeVisualizar,
            podeEditar: this.state.podeEditar,
            podeExcluir: this.state.podeExcluir
        };

        const tipoCategoria = this.state.tabSelecionada === 0 ? "RECEITA" : "DESPESA";

        const { categorias } = this.state;
        const { isMobile } = this.props;

        return (
            <>
                <Form header="Categorias">
                    <FormActions>
                        <ButtonNovo
                            label={`Nova categoria de ${this.state.tabSelecionada === 0 ? "receita" : "despesa"}`}
                            title={`Inserir uma nova categoria de ${this.state.tabSelecionada === 0 ? "receita" : "despesa"}`}
                            onClick={() => this.setState({ exibirModalCategoria: true })}
                            disabled={!this.state.podeInserir}
                        />
                    </FormActions>
                    <FormContent>
                        <TabView
                            className="tab-view-categorias"
                            activeIndex={this.state.tabSelecionada}
                            onTabChange={({ index }) => this.setState({ tabSelecionada: index })}
                            renderActiveOnly={false}
                        >
                            <TabPanel header={this.state.tituloTabReceita}>
                                <Grid justifyCenter verticalAlignCenter>
                                    <TreeTabelaCategorias
                                        isMobile={isMobile}
                                        pesquisar={this.pesquisar}
                                        categoriaSelecionada={this.state.categoriaSelecionada}
                                        excluirCategoria={this.excluirCategoria}
                                        inativarCategoria={this.inativarCategoria}
                                        ativarCategoria={this.ativarCategoria}
                                        selecionarCategoria={this.selecionarCategoria}
                                        informacoesPermissoes={informacoesPermissoes}
                                        exibirModalCategoria={() => this.setState({ exibirModalCategoria: true })}
                                        categorias={categorias.filter((categoria) => categoria.receita)}
                                        tipoCategoria={tipoCategoria}
                                        tabName="categoriaReceita"
                                    />
                                </Grid>
                            </TabPanel>
                            <TabPanel header={this.state.tituloTabDespesa}>
                                <Grid justifyCenter verticalAlignCenter>
                                    <TreeTabelaCategorias
                                        isMobile={isMobile}
                                        pesquisar={this.pesquisar}
                                        categoriaSelecionada={this.state.categoriaSelecionada}
                                        excluirCategoria={this.excluirCategoria}
                                        inativarCategoria={this.inativarCategoria}
                                        ativarCategoria={this.ativarCategoria}
                                        selecionarCategoria={this.selecionarCategoria}
                                        informacoesPermissoes={informacoesPermissoes}
                                        exibirModalCategoria={() => this.setState({ exibirModalCategoria: true })}
                                        categorias={categorias.filter((categoria) => categoria.despesa)}
                                        tipoCategoria={tipoCategoria}
                                        tabName="categoriaDespesa"
                                    />
                                </Grid>
                            </TabPanel>
                        </TabView>
                    </FormContent>
                </Form>
                <If test={this.state.exibirModalCategoria}>
                    <ModalCategoria
                        visible={this.state.exibirModalCategoria}
                        onHide={this.onHideModalCategoria}
                        tipoCategoria={tipoCategoria}
                        categoriaSelecionada={this.state.categoriaSelecionada}
                        informacoesPermissoes={informacoesPermissoes}
                    />
                </If>
            </>
        )
    }
}

const mapStateToProps = state => ({
    isMobile: state.dispositivo.isMobile
})

export default connect(mapStateToProps)(Categorias);
