import React from 'react'
import { FixedSizeList } from "react-window";

export default function FastList({ options, children, getValue, maxHeight }) {

    function getHeight() {
        const totalItensHeight = 35 * children.length || 35

        if (totalItensHeight > maxHeight) {
            return maxHeight
        } else {
            return totalItensHeight + 20
        }
    }

    function getInitiaScrollOffset() {
        const height = getHeight()
        const heightTotalOptions = children.length * 35
        if (height >= heightTotalOptions) {
            return 0
        } else {
            return options.indexOf(value) * 35;
        }

    }

    const [value] = getValue();
    return (
        <FixedSizeList
            height={getHeight()}
            itemCount={children.length || 0}
            itemSize={35}
            initialScrollOffset={getInitiaScrollOffset()}
        >
            {({ index, style }) => <div style={style}>{children[index]}</div>}
        </FixedSizeList>
    );
}