import React, { Component } from 'react';

import Steps from "rc-steps";
import propTypes from 'prop-types';
import autoBind from 'react-autobind';

import { renderizarEtapas, Icon } from './util';

class ReactSteps extends Component {

    constructor(props) {
        super(props);

        autoBind(this);

        this.state = {
            larguraTela: null
        }
    }

    componentDidMount() {
        window.addEventListener("resize", this.alterarLarguraTela);

        this.alterarLarguraTela();
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.alterarLarguraTela);
    }

    alterarLarguraTela() {
        this.setState({ larguraTela: window.innerWidth });
    }

    render() {
        const { etapas, current, style, status, className } = this.props;

        return (
            <Steps
                className={className}
                direction={this.state.larguraTela < 650 ? "vertical" : "horizontal"}
                status={status}
                style={style}
                current={Number(current)}
                labelPlacement='vertical'
                icons={{ finish: <Icon name="check" />, error: <Icon name="close" /> }}
            >
                {renderizarEtapas(etapas)}
            </Steps>
        );
    }
}

ReactSteps.defaultProps = {
    status: 'process',
    direction: 'horizontal'
}

ReactSteps.propTypes = {
    status: propTypes.oneOf(['error', 'process', 'finish', 'wait'])
}

export default ReactSteps;
