import React, { Component } from 'react';
import * as Yup from "yup";
import { withRouter } from 'react-router';
import autoBind from 'react-autobind';
import { withFormik, Field } from 'formik';
import Modal from '../../../../../components/Modal';
import AutoProgressBar from '../../../../../components/Loading/AutoProgressBar';
import { mensagensDeValidacao } from '../../../../../common/constantes/mensagens';
import ButtonNovo, { estadosBotaoNovo } from '../../../../../components/Button/ButtonNovo';
import ButtonSalvar, { estadosBotaoSalvar } from '../../../../../components/Button/ButtonSalvar';
import ButtonCancelar, { estadosBotaoCancelar } from '../../../../../components/Button/ButtonCancelar';
import ButtonExcluir from '../../../../../components/Button/ButtonExcluir';
import Checkbox from '../../../../../components/Input/Checkbox';
import InputField from '../../../../../components/Input/InputField';
import { helpMessage } from './help';
import Grid from '../../../../../components/Grid';
import Col from '../../../../../components/Col';
import ColorPicker, { coresPadrao } from '../../../../../components/ColorPicker';
import { confirm } from '../../../../../components/Toast';
import { validarFormulario } from '../../../../util';
import {
    asyncCreateRegistro,
    asyncDeleteRegistro,
    asyncUpdateRegistro,
    asyncGetCategoriaMesmoNome,
    asyncGetRegistro
} from './requests';
import { keyFilterConsultaRsql } from '../../../../../common/rsql';
import Form from '../../../../../components/Form';
import FormActions from '../../../../../components/FormActions';
import FormContent from '../../../../../components/FormContent';
import If from '../../../../../components/If';
import { converterCategoriaParaApi, converterCategoriaParaFormulario } from './utils/categoriaConverter';
import SingleSelectCategoria, { tiposCategoria } from '../../../../../components/Select/SingleSelectCategoria';
import { services } from '../../../../../common/constantes/api';
import { BsArrowReturnRight } from 'react-icons/bs';
import { connect } from 'react-redux';

const errorStyle = {
    fontSize: '12px',
    color: 'red',
    margin: '0px 8px 20px',
    height: '0px',
}

const initialValue = {
    id: '',
    nome: '',
    favoritaReceita: false,
    favoritaDespesa: false,
    receita: false,
    despesa: false,
    ativa: true,
    aparencia: "",
    categoriaSuperior: null
};


class ModalCategoria extends Component {

    constructor(props) {
        super(props);
        autoBind(this);
    }

    componentDidMount() {
        if (this.props.values.id) {
            asyncGetRegistro(this.props.values.id, ({ data }) => {
                this.props.resetForm({ values: converterCategoriaParaFormulario(data) });
            })

        } else {
            setTimeout(() => {
                this.novo()
            }, 1)
        }
    }


    novo() {
        let valorInicial = initialValue;

        if (this.props.tipoCategoria === 'RECEITA') {
            valorInicial = { ...valorInicial, receita: true }
        } else if (this.props.tipoCategoria === 'DESPESA') {
            valorInicial = { ...valorInicial, despesa: true }
        }

        this.props.resetForm({ values: { ...valorInicial, aparencia: coresPadrao[Math.floor(Math.random() * coresPadrao.length)] } });
    }

    excluir() {
        confirm("Confirmação", `Tem certeza que deseja excluir a categoria ${this.props.values.nome}?`, () => {
            asyncDeleteRegistro(this.props.values.id, () => {
                this.props.onHide(this.props.values);
            })
        })
    }

    async salvar(e, novoOnSuccess) {
        this.props.handleSubmit();

        if (await validarFormulario(this.props)) {
            if (await this.validarNome()) {
                if (this.props.values.id) {
                    this.asyncUpdateRegistro(converterCategoriaParaApi(this.props.values), novoOnSuccess);
                } else {
                    this.asyncCreateRegistro(converterCategoriaParaApi(this.props.values), novoOnSuccess);
                }
            } else {
                this.props.setFieldError('nome', 'O nome informado já existe');
            }
        }
    }

    async validarNome() {
        let nomeJaExiste = false;
        const { values } = this.props;
        await asyncGetCategoriaMesmoNome(values.nome, (e) => {
            if (e.data.content.length > 0 && e.data.content[0].id !== values.id) {
                nomeJaExiste = true
            }
        })
        return !nomeJaExiste
    }

    asyncUpdateRegistro(values, novoOnSuccess) {
        asyncUpdateRegistro(values, () => {
            if (novoOnSuccess) {
                novoOnSuccess();
            } else {
                this.props.onHide(values);
            }
        })
    }

    asyncCreateRegistro(values, novoOnSuccess) {
        if (values) {
            asyncCreateRegistro(values, ({ data: categoria }) => {
                if (novoOnSuccess) {
                    novoOnSuccess();
                } else {
                    this.props.onHide({ ...values, id: categoria.id });
                }
            })
        }
    }

    cancelar() {
        if (this.props.dirty) {
            this.props.resetForm({ values: this.props.initialValues })
        } else {
            this.props.onHide();
        }
    }

    handleChangeColor(color) {
        if (!this.props.dirty && !this.props.values.aparencia) {
            this.props.resetForm({ values: { ...this.props.values, aparencia: color } });
        } else {
            this.props.setFieldValue("aparencia", color)
        }
    }

    onChangeTipoReceita() {
        const { values } = this.props

        if (values.receita) {
            this.props.setFieldValue("favoritaReceita", false)
        }

        this.props.setFieldValue("receita", !values.receita)

    }

    onChangeTipoDespesa() {
        const { values } = this.props

        if (values.despesa) {
            this.props.setFieldValue("favoritaDespesa", false)
        }

        this.props.setFieldValue("despesa", !values.despesa)
    }

    render() {
        const { visible, onHide, dirty, values, informacoesPermissoes, errors, isMobile, insertJustOne } = this.props;

        const prefixoHeader = values.id ? "Editar categoria" : "Cadastrar nova categoria";

        const isEdicao = values.id ? true : false;

        const valueDespesa = values.categoriaSuperior ? values.categoriaSuperior.registro.despesa : values.despesa
        const valueReceita = values.categoriaSuperior ? values.categoriaSuperior.registro.receita : values.receita

        const podeEditarTipoCategoria = ((isEdicao && !informacoesPermissoes.podeEditar) || Boolean(values.categoriaSuperior))

        const estadoBotaoNovo = dirty ? estadosBotaoNovo.SALVAR_E_NOVO : estadosBotaoNovo.NOVO;
        const onClickNovo = dirty ? (e) => this.salvar(e, this.novo) : this.novo;

        return (
            <Modal
                header={prefixoHeader}
                visible={visible}
                onHide={onHide}
            >
                <AutoProgressBar />
                <Form>
                    <FormActions>
                        <ButtonCancelar
                            estadoBotao={dirty ? estadosBotaoCancelar.CANCELAR : estadosBotaoCancelar.VOLTAR}
                            onClick={this.cancelar}
                            {...informacoesPermissoes}
                        />
                        <ButtonSalvar
                            estadoBotao={estadosBotaoSalvar.SALVAR}
                            disabled={!dirty}
                            onClick={this.salvar}
                            {...informacoesPermissoes}
                        />
                        <ButtonNovo
                            onClick={onClickNovo}
                            hidden={(!dirty && !values.id) || insertJustOne}
                            estadoBotao={estadoBotaoNovo}
                            {...informacoesPermissoes}
                        />
                        <ButtonExcluir
                            hidden={!values.id}
                            onClick={this.excluir}
                            {...informacoesPermissoes}
                        />
                    </FormActions>
                    <FormContent>
                        <Grid>
                            <Field sm="6" md="6" lg="8" xl="8"
                                component={InputField}
                                label='Nome da categoria'
                                autoFocus
                                obrigatorio
                                disabled={isEdicao && !informacoesPermissoes.podeEditar}
                                keyfilter={keyFilterConsultaRsql}
                                name="nome"
                                size={255}
                                helpMessage={helpMessage.nome}
                                {...informacoesPermissoes}
                            />
                            <Field sm="6" md="6" lg='4' xl='4'
                                name="categoria"
                                label="Mostrar esta categoria dentro de"
                                helpMessage={"Caso esta opção estiver preenchida, a categoria ficará disponível como subcategoria " + (values.categoriaSuperior ? `de ${values.categoriaSuperior.label}` : "do valor deste campo")}
                                component={SingleSelectCategoria}
                                esconderBotao
                                value={values.categoriaSuperior}
                                onChange={e => this.props.setFieldValue('categoriaSuperior', e)}
                                url={`${services.GESTOR}/v1/categorias/relacoes/categorias_superiores`}
                                disabled={isEdicao && !informacoesPermissoes.podeEditar}
                            />
                            <Grid style={{ width: '100%' }} >
                                <Col sm="12" md="6" lg="6" xl="6">
                                    <Field
                                        component={Checkbox}
                                        label="Disponível para receitas"
                                        name="receita"
                                        disabled={podeEditarTipoCategoria || (this.props.tipoCategoria === tiposCategoria.RECEITA)}
                                        helpMessage={helpMessage.disponivelReceita}
                                        checked={valueReceita}
                                        onChange={this.onChangeTipoReceita}
                                    />
                                    <Col style={{ display: 'inline-flex', padding: '0px 0.5em' }}>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <BsArrowReturnRight size="22" />
                                        </div>
                                        <Field
                                            component={Checkbox}
                                            label="Categoria favorita para receitas"
                                            name="categoriaFavoritaParaReceita"
                                            helpText="Marque para usar como favorita"
                                            disabled={!valueReceita || (isEdicao && !informacoesPermissoes.podeEditar)}
                                            onChange={() => this.props.setFieldValue("favoritaReceita", !values.favoritaReceita)}
                                            helpMessage={!isMobile ? helpMessage.favoritaReceita : null}
                                            checked={values.favoritaReceita}
                                        />
                                    </Col>
                                </Col>
                                <Col sm="12" md="6" lg="6" xl="6" >
                                    <Field
                                        component={Checkbox}
                                        label="Disponível para despesas"
                                        name="despesa"
                                        disabled={podeEditarTipoCategoria || (this.props.tipoCategoria === tiposCategoria.DESPESA)}
                                        onChange={this.onChangeTipoDespesa}
                                        helpMessage={!isMobile ? helpMessage.disponivelDespesa : null}
                                        checked={valueDespesa}
                                    />
                                    <Col style={{ display: 'inline-flex', padding: '0px 0.5em' }}>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <BsArrowReturnRight size="22" />
                                        </div>
                                        <Field
                                            component={Checkbox}
                                            label="Categoria favorita para despesas"
                                            name="categoriaFavoritaParaDespesas"
                                            helpText="Marque para usar como favorita"
                                            disabled={!valueDespesa || (isEdicao && !informacoesPermissoes.podeEditar)}
                                            onChange={() => this.props.setFieldValue("favoritaDespesa", !values.favoritaDespesa)}
                                            helpMessage={!isMobile ? helpMessage.favoritaDespesa : null}
                                            checked={values.favoritaDespesa}
                                        />
                                    </Col>
                                </Col>
                            </Grid>
                            <If test={(errors && errors.receitaDespesa) || (!values.receita && !values.despesa)} >
                                <span style={{ ...errorStyle }} >A categoria deve pertencer a um tipo</span>
                            </If>

                            <Col sm="12" md="12" lg="12" xl="12">
                                <ColorPicker
                                    disabled={(isEdicao && !informacoesPermissoes.podeEditar) || Boolean(values.categoriaSuperior)}
                                    value={values.categoriaSuperior ? values.categoriaSuperior.registro.aparencia : values.aparencia}
                                    onChange={this.handleChangeColor}
                                />
                            </Col>
                        </Grid>
                    </FormContent>
                </Form>
            </Modal>
        )
    }
}
ModalCategoria = withFormik({
    validateOnChange: false,

    mapPropsToValues(props) {
        if (props.categoriaSelecionada) {
            return props.categoriaSelecionada;
        }

        return initialValue;
    },

    validate(values) {
        let errors = {};

        if (!values.receita && !values.despesa) {
            errors.receitaDespesa = "A categoria deve pertencer a aomenos um tipo"
        }

        return errors
    },

    validationSchema: Yup.object().shape({
        nome: Yup.string().max(255, "O nome da categoria não pode ultrapassar 255 caracteres").nullable().required(mensagensDeValidacao.OBRIGATORIO),
    }),
    handleSubmit: () => { }
})(ModalCategoria);

const mapStateToProps = state => ({
    isMobile: state.dispositivo.isMobile,
})

export default connect(mapStateToProps)(withRouter(ModalCategoria));

// export default withRouter(ModalCategoria);
