import React, { Component } from 'react';
import { withFormik, Field } from 'formik';
import * as Yup from 'yup';
import ButtonNovo, { estadosBotaoNovo } from '../../../../components/Button/ButtonNovo';
import ButtonCancelar, { estadosBotaoCancelar } from '../../../../components/Button/ButtonCancelar';
import ButtonSalvar, { estadosBotaoSalvar } from '../../../../components/Button/ButtonSalvar';
import ButtonExcluir from '../../../../components/Button/ButtonExcluir';
import { mensagensDeValidacao } from '../../../../common/constantes/mensagens';
import InputField from '../../../../components/Input//InputField';
import Grid from '../../../../components/Grid';
import InputMask from '../../../../components/Input//InputMask';
import { buscarMascaraTelefone, manterApenasNumeros } from '../../../../common/mascara/';
import DropDown from '../../../../components/Select/Dropdown';
import Col from '../../../../components/Col';
import { recursos, permissoes, estadosCadastro } from '../../../../common/constantes/autorizacao';
import Prompt from '../../../../components/Route/Prompt';
import { validarUUID } from '../../../../common/manipulacaoDeString';
import { atualizarUrl, metodosAtualizarUrl, validarFormulario, voltarParaAPesquisa } from '../../../util';
import autoBind from 'react-autobind';
import { helpOrganizacoesForm } from './help';
import { converterParaFormulario, converterParaApi } from './util/agenteAutorizadoConverter';
import { asyncCreateAgenteAutorizado, asyncDeleteAgenteAutorizado, asyncGetAgenteAutorizado, asyncUpdateAgenteAutorizado } from './requests';
import { usuarioPossuiPermissao } from '../../../../common/autenticacao';
import SingleSelectMunicipio from '../../../../components/Select/SingleSelectMunicipio';
import SingleSelectPais from '../../../../components/Select/SingleSelectPais';
import { isValidCpf, isValidCnpj } from '@brazilian-utils/validators';
import { confirm, notify } from '../../../../components/Toast';
import FormActions from '../../../../components/FormActions';
import FormContent from '../../../../components/FormContent';
import Form from '../../../../components/Form';
import TextArea from '../../../../components/TextArea';
import { InternalInputDouble } from '../../../../components/Input/InputDouble';
import { connect } from 'react-redux';
import { FaCopy } from 'react-icons/fa';
import If from '../../../../components/If';
import { copiarParaAreaTransferencia } from '../../../../common/areaDeTransferencia';
import InputCep from '../../../../components/Input/InputCep';
import stringMask from 'string-mask';

export const tipoAgenteAutorizado = [
	{ label: 'Física', value: 'FISICA' },
	{ label: 'Jurídica', value: 'JURIDICA' }
];

export const situacaoAgenteAutorizado = [
	{ label: 'Ativo', value: 'ATIVO' },
	{ label: 'Inativo', value: 'INATIVO' }
];

const initialValues = {
	id: null,
	nome: null,
	situacao: 'ATIVO',
	tipo: 'JURIDICA',
	percentualComissao: null,
	cpf: null,
	cnpj: null,
	email: null,
	telefone: null,
	logradouro: null,
	numero: null,
	bairro: null,
	complemento: null,
	cep: null,
	municipio: null,
	municipioExterior: null,
	pais: null,
	observacao: '',
};

const styleBotaoCopiar = {
	padding: '0px 10px',
	display: 'flex',
	background: '#fff0',
	color: '#006095',
	fontWeight: 'bold',
	fontSize: '15px',
	alignItems: 'center',
	border: 'none',
	cursor: 'pointer'
};

const idPaisBrasil = "34e63b2e-c596-f34f-824d-bfd27eb62fa8"
const CADASTROURL = '/agentes_autorizados/cadastro';
const PESQUISAURL = '/agentes_autorizados';

class AgentesAutorizadosForm extends Component {

	constructor(props) {
		super(props);

		autoBind(this);

		this.state = {
			podeInserir: usuarioPossuiPermissao(recursos.AGENTES_AUTORIZADOS, permissoes.INSERIR),
			podeEditar: usuarioPossuiPermissao(recursos.AGENTES_AUTORIZADOS, permissoes.EDITAR),
			podeExcluir: usuarioPossuiPermissao(recursos.AGENTES_AUTORIZADOS, permissoes.EXCLUIR),
		};
	}

	componentDidMount() {
		const id = this.props.match && this.props.match.params.id;
		if (validarUUID(id)) {
			this.asyncSelectRegistro(id);
		}
	}

	buscarEstadoCadastro() {
		return this.props.values.id ? estadosCadastro.EDICAO : estadosCadastro.INCLUSAO;
	}

	novo() {
		atualizarUrl(this.props.history, CADASTROURL, null, metodosAtualizarUrl.POP);
		this.props.resetForm({ values: initialValues });
	}

	excluir() {
		confirm('Confirmação', 'Tem certeza que deseja excluir o registro?', async () => {
			await this.asyncDeleteRegistro();
		})
	}

	fecharModal() {
		if (this.props.values.id) {
			this.props.hideModal(this.props.values);
		} else {
			this.props.hideModal();
		}
	}

	async salvar(e, novoOnSuccess) {
		this.props.handleSubmit();

		if (await validarFormulario(this.props)) {
			if (this.props.values.id) {
				await this.asyncUpdateRegistro(this.props.values, novoOnSuccess);
			} else {
				await this.asyncCreateRegistro(this.props.values, novoOnSuccess);
			}
			if (this.props.hideModal) {
				this.fecharModal()
			}
		}
	}

	cancelar() {
		if (this.props.dirty) {
			this.props.resetForm({ values: this.props.initialValues });
		} else if (this.props.isModal) {
			this.fecharModal()
		} else {
			voltarParaAPesquisa(this.props.history, PESQUISAURL);
		}
	}

	async asyncDeleteRegistro() {
		await asyncDeleteAgenteAutorizado(this.props.values.id, () => {
			this.props.resetForm()
			voltarParaAPesquisa(this.props.history, PESQUISAURL)
		})
	}

	async asyncUpdateRegistro(values, novoOnSuccess) {
		await asyncUpdateAgenteAutorizado(converterParaApi(values), () => {
			if (novoOnSuccess) {
				novoOnSuccess()
			} else {
				this.props.resetForm({ values: values });
				novoOnSuccess && novoOnSuccess();
			}
		})
	}

	async asyncCreateRegistro(values, novoOnSuccess) {
		if (values) {
			await asyncCreateAgenteAutorizado(converterParaApi(values), async ({ data: agenteAutorizado }) => {
				if (novoOnSuccess) {
					novoOnSuccess();
				} else {
					this.props.resetForm({ values: { ...values, id: agenteAutorizado.id } });
					if (!this.props.isModal) {
						atualizarUrl(this.props.history, CADASTROURL, agenteAutorizado.id, metodosAtualizarUrl.POP);
					}
				}
			})
		}
	}

	async asyncSelectRegistro(idAgenteAutorizado) {
		await asyncGetAgenteAutorizado(idAgenteAutorizado, ({ data: agenteAutorizado }) => {
			this.props.resetForm({ values: converterParaFormulario(agenteAutorizado) })
			if (!this.props.isModal) {
				atualizarUrl(this.props.history, CADASTROURL, agenteAutorizado.id, metodosAtualizarUrl.POP);
			}
		})
	}

	habilitarTipoOrganizacao(e) {
		this.props.setFieldValue("tipo", e);
		switch (e) {
			case 'FISICO':
				this.setState({ ...this.state });
				break;
			case 'JURIDICO':
				this.setState({ ...this.state });
				break;
			default:
		}
	}

	onChangeComissao(e) {
		const { setFieldValue } = this.props;

		if (e.target.value <= 100) {
			setFieldValue('percentualComissao', e.target.value)
		} else {
			setFieldValue('percentualComissao', this.props.values.percentualComissao)
		}
	}

	montarCabecalho() {
		const { isMobile, values } = this.props;
		return (
			<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
				<span>Cadastro de agente autorizado</span>
				<span>
					<If test={values.id}>
						<span
							title="Copiar link de criação de conta"
							style={styleBotaoCopiar}
							onClick={() => copiarParaAreaTransferencia(`${window.location.href.split("/#/")[0]}/#/registro?agenteId=${values.id}`, () => {
								notify("Link copiado para área de transferência")
							})}
						>
							<FaCopy size="15px" style={!isMobile && { marginRight: '5px' }} />
							{!isMobile && <span>Copiar link de criação de conta</span>}
						</span>
					</If>
				</span>

			</div>
		)
	}

	onChangeCep(e) {
		if (e.pesquisou) {
			const { setFieldValue } = this.props;
			e.bairro && setFieldValue('bairro', e.bairro);
			e.complemento && setFieldValue('complemento', e.complemento);
			e.logradouro && setFieldValue('logradouro', e.logradouro);
			e.municipio && setFieldValue('municipio', { label: e.municipio.nome + ' - ' + e.municipio.estado.sigla, value: e.municipio.id });
			e.pais && setFieldValue('pais', { label: e.pais.nome, value: e.pais.id });
		} else {
			this.props.setFieldValue('cep', e.value);
		}
	}

	render() {
		const { values, dirty, isModal, setFieldValue } = this.props;

		const informacoesPermissoes = {
			podeInserir: this.state.podeInserir,
			podeEditar: this.state.podeEditar,
			podeExcluir: this.state.podeExcluir,
			estadoCadastro: this.props.values.id ? estadosCadastro.EDICAO : estadosCadastro.INCLUSAO
		};

		const estadoBotaoNovo = dirty ? estadosBotaoNovo.SALVAR_E_NOVO : estadosBotaoNovo.NOVO;

		const onClickNovo = dirty ? (e) => this.salvar(e, this.novo) : this.novo;


		return (
			<>
				<Prompt dirty={dirty} />
				<Form
					header={this.montarCabecalho()}
					isModal={isModal}
					className="card-default screen-max-width"
				>
					<FormActions className="screen-max-width">
						<ButtonCancelar
							estadoBotao={dirty ? estadosBotaoCancelar.CANCELAR : estadosBotaoCancelar.VOLTAR}
							onClick={this.cancelar}
							{...informacoesPermissoes}
						/>
						<ButtonSalvar
							estadoBotao={estadosBotaoSalvar.SALVAR}
							disabled={!dirty}
							onClick={this.salvar}
							{...informacoesPermissoes}
						/>
						<ButtonNovo
							onClick={onClickNovo}
							hidden={(!dirty && !values.id) || isModal}
							estadoBotao={estadoBotaoNovo}
							{...informacoesPermissoes}
						/>
						<ButtonExcluir
							hidden={!values.id}
							onClick={this.excluir}
							{...informacoesPermissoes}
						/>
					</FormActions>
					<FormContent>
						<Grid>
							<Field sm="12" md="4" lg='4' xl='4'
								label='Nome'
								name="nome"
								size={60}
								obrigatorio
								helpMessage={helpOrganizacoesForm.nome}
								component={InputField}
								{...informacoesPermissoes}
							/>
							<Field sm="12" md="4" lg='4' xl='4'
								component={DropDown}
								label='Situação'
								name="situacao"
								obrigatorio
								showClear={false}
								onChange={(e) => setFieldValue('situacao', e.value)}
								options={situacaoAgenteAutorizado}
								{...informacoesPermissoes}
							/>
							<Field sm="12" md="4" lg='4' xl='4'
								component={DropDown}
								label='Tipo'
								name="tipo"
								obrigatorio
								showClear={false}
								onChange={(e) => this.habilitarTipoOrganizacao(e.value)}
								options={tipoAgenteAutorizado}
								{...informacoesPermissoes}
							/>
							<Field sm="12" md="4" lg='4' xl='4'
								hidden={values.tipo !== 'FISICA'}
								component={InputMask}
								mask="000.000.000-00"
								placeholder="   .   .   -  "
								label='CPF'
								name="cpf"
								onChange={e => this.props.setFieldValue('cpf', e.target.value)}
								obrigatorio
								{...informacoesPermissoes}
							/>
							<Field sm="12" md="4" lg='4' xl='4'
								hidden={values.tipo !== 'JURIDICA'}
								component={InputMask}
								mask="00.000.000/0000-00"
								placeholder="  .   .   /    -  "
								label='CNPJ'
								name="cnpj"
								obrigatorio
								onChange={e => this.props.setFieldValue('cnpj', e.target.value)}
								{...informacoesPermissoes}
							/>
							<Field sm="12" md="4" lg='4' xl='4'
								label='E-mail'
								estadoCadastro={this.buscarEstadoCadastro()}
								name="email"
								obrigatorio
								size={255}
								component={InputField}
								{...informacoesPermissoes}
							/>
							<Field sm="12" md="4" lg='4' xl='4'
								component={InputMask}
								mask={buscarMascaraTelefone(values.telefone)}
								placeholder={"(  )      -    "}
								label='Telefone'
								obrigatorio
								name="telefone"
								size={16}
								onChange={e => this.props.setFieldValue('telefone', e.target.value)}
								{...informacoesPermissoes}
							/>
							<InternalInputDouble sm="12" md="4" lg='4' xl='4'
								label='Comissão'
								name="percentualComissao"
								onChange={this.onChangeComissao}
								size={15}
								helpMessage={helpOrganizacoesForm.percentualComissao}
								prefix=''
								suffix=" %"
								value={values.percentualComissao}
								placeholder='0,00 %'
								allowNegative={false}
								{...informacoesPermissoes}
							/>
							<Col sm="12" md="12" lg='12' xl='12'>
								<h3>Endereço</h3>
								<Grid>
									<Field sm="12" md="4" lg="4" xl="4"
										component={InputCep}
										label="CEP"
										name="cep"
										type="tel"
										value={values.cep}
										onChange={e => this.onChangeCep(e)}
										{...informacoesPermissoes}
									/>
									<Field sm="12" md="4" lg='4' xl='4'
										label='Logradouro'
										name="logradouro"
										size={255}
										component={InputField}
										{...informacoesPermissoes}
									/>
									<Field sm="12" md="4" lg='4' xl='4'
										label='Número'
										size={10}
										name="numero"
										component={InputField}
										{...informacoesPermissoes}
									/>
									<Field sm="12" md="4" lg='4' xl='4'
										label='Bairro'
										name="bairro"
										size={60}
										component={InputField}
										{...informacoesPermissoes}
									/>
									<Field sm="12" md="4" lg='4' xl='4'
										label='Complemento'
										name="complemento"
										size={255}
										component={InputField}
										{...informacoesPermissoes}
									/>

									<Field sm="12" md="4" lg='4' xl='4'
										label='País'
										name="pais"
										component={SingleSelectPais}
										value={values.pais}
										onChange={e => this.props.setFieldValue('pais', e)}
										{...informacoesPermissoes}
									/>
									<Field sm="12" md="4" lg='4' xl='4'
										label='Município'
										name="municipio"
										component={SingleSelectMunicipio}
										value={values.municipio}
										onChange={e => this.props.setFieldValue('municipio', e)}
										hidden={(values.pais ? values.pais.value : null) !== idPaisBrasil}
										{...informacoesPermissoes}
									/>
									<Field sm="12" md="4" lg='4' xl='4'
										component={InputField}
										type="text"
										label="Estado exterior"
										helpMessage={helpOrganizacoesForm.estadoExterior}
										name="estadoExterior"
										value={values.estadoExterior}
										size={128}
										hidden={(values.pais ? values.pais.value : null) === idPaisBrasil}
										{...informacoesPermissoes}
									/>
									<Field sm="12" md="4" lg='4' xl='4'
										component={InputField}
										type="text"
										label="Município exterior"
										helpMessage={helpOrganizacoesForm.municipioExterior}
										name="municipioExterior"
										value={values.municipioExterior}
										size={128}
										hidden={(values.pais ? values.pais.value : null) === idPaisBrasil}
										{...informacoesPermissoes}
									/>
									<Field sm="12" md="12" lg='12' xl='12'
										component={TextArea}
										placeholder="Escreva sua observação aqui"
										label='Observação'
										name="observacao"
										maxLength={512}
										onChange={e => this.props.setFieldValue('observacao', e.target.value)}
										{...informacoesPermissoes}
									/>
								</Grid>
							</Col>
						</Grid>
					</FormContent>
				</Form>
			</>
		)
	}
}

AgentesAutorizadosForm = withFormik({
	enableReinitialize: true,
	validateOnChange: false,

	mapPropsToValues() {
		return initialValues
	},

	validationSchema: Yup.object().shape({
		nome: Yup.string().nullable().required(mensagensDeValidacao.OBRIGATORIO),
		email: Yup.string().nullable().required(mensagensDeValidacao.OBRIGATORIO).email('E-mail inválido'),
		telefone: Yup.string().nullable().required(mensagensDeValidacao.OBRIGATORIO),
	}),

	validate(values) {
		let errors = {};

		if (values.tipo === 'FISICA') {
			if (!values.cpf)
				errors.cpf = mensagensDeValidacao.OBRIGATORIO;

			if (values.cpf && !isValidCpf(manterApenasNumeros(values.cpf)))
				errors.cpf = 'Digite um CPF válido.';
		}

		if (!new stringMask("(00) 00000-0000").validate(manterApenasNumeros(values.telefone)) && !new stringMask("(00) 0000-0000").validate(manterApenasNumeros(values.telefone))) {
			errors.telefone = mensagensDeValidacao.TELEFONE_INVALIDO;
		}

		if (values.tipo === 'JURIDICA') {
			if (!values.cnpj)
				errors.cnpj = mensagensDeValidacao.OBRIGATORIO;

			if (values.cnpj && !isValidCnpj(manterApenasNumeros(values.cnpj)))
				errors.cnpj = 'Digite um CNPJ válido.';
		}

		if (values.percentualComissao > 100) {
			errors.percentualComissao = "O percentual de comissão não pode ser maior que 100%"
		}
		return errors;
	},

})(AgentesAutorizadosForm);

const mapStateToProps = state => ({
	isMobile: state.dispositivo.isMobile,
})

export default connect(mapStateToProps)(AgentesAutorizadosForm);
