import React from 'react';
import { MdWarning } from 'react-icons/md';
import Col from '../../../../../../../../../../components/Col';
import { InternalInputDouble } from '../../../../../../../../../../components/Input/InputDouble';

export default function QuantidadeComEstoqueDisponivel(props) {
    const {
        colStyle,
        label,
        size,
        className,
        helpMessage,
        value,
        onChange,
        decimalScale,
        allowNegative,
        touched,
        disabled,
        errors,
        informacoesPermissoes,
        produtoExcedeuEstoque,
        col, sm, md, lg, xl,
        exibirLabels
    } = props;

    function montarMensagemSaldoExcedido() {
        if (produtoExcedeuEstoque && !errors) {
            return (
                <div
                    style={{ color: '#ff9800', fontSize: '12px', padding: '0px', display: 'inline-flex', alignItems: 'center' }}
                    title="A quantidade informada é maior que a quantidade em estoque disponível deste produto"
                >
                    <MdWarning style={{ fontSize: '13px' }} /> Estoque excedido
                </div>
            )
        }
    }

    return (
        <Col col={col} sm={sm} md={md} lg={lg} xl={xl} style={{ padding: '0px 4px', minHeight: exibirLabels ? '73px' : '55px' }}>
            <InternalInputDouble
                colStyle={colStyle}
                label={label}
                size={size}
                className={className}
                helpMessage={helpMessage}
                value={value}
                disabled={disabled}
                onChange={onChange}
                decimalScale={decimalScale}
                allowNegative={allowNegative}
                touched={touched}
                errors={errors}
                {...informacoesPermissoes}
            />
            {montarMensagemSaldoExcedido()}
        </Col>
    )
}