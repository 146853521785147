import { parse } from "date-fns/esm"
import { formatarMonetario } from "../../../../../../common/mascara"
import { format } from "date-fns"
import { montarLabelPessoa } from "../../../../../../components/Select/SingleSelectPessoa/utils"

export function converterContaReceberParaFormulario(values) {

    return {
        ...values,
        pessoa: converterPessoaParaFormulario(values.pessoa),
        categoria: converterCategoriaParaFormulario(values.categoria),
        recebimentos: converterRecebimentosParaFormulario(values.recebimentos),
        emissao: values.emissao,
        competencia: values.competencia,
        vencimento: values.vencimento,
        origem: values.origem,
        dataDocumento: values.dataDocumento,
        repetir: values.quantidadeRepeticoes > 0 ? true : false,
        vendaId: values.vendaId
    }

}

function converterPessoaParaFormulario(pessoaApi) {
    if (pessoaApi) {
        return {
            label: montarLabelPessoa(pessoaApi),
            value: pessoaApi.id,
            registro: pessoaApi
        }
    }
    return null
}

function converterCategoriaParaFormulario(categoriaApi) {
    if (categoriaApi) {
        return {
            label: categoriaApi.nome,
            value: categoriaApi.id,
            registro: categoriaApi
        }
    }
    return null
}

function converterRecebimentosParaFormulario(recebimentosApi) {
    let recebimentosFormulario = recebimentosApi.map(recebimentoApi => {
        const data = recebimentoApi.data ? parse(recebimentoApi.data, 'yyyy-MM-dd', new Date()) : null
        return {
            ...recebimentoApi,
            data: recebimentoApi.data,
            dataLabel: data ? format(data, 'dd/MM/yyyy') : null,
            valorLabel: formatarMonetario(recebimentoApi.valor),
            jurosLabel:  formatarMonetario(recebimentoApi.juros),
            descontosLabel:  formatarMonetario(recebimentoApi.descontos),
            multaLabel:  formatarMonetario(recebimentoApi.multa),
            totalLabel:  formatarMonetario(recebimentoApi.total),
            conta: converterContaParaFormulario(recebimentoApi.conta)
        }
    });
    return recebimentosFormulario;
}

function converterContaParaFormulario(contaApi) {
    if (contaApi) {
        return {
            label: contaApi.nome,
            value: contaApi.id,
            registro: contaApi
        }
    }
    return null
}

function converterRecebimentosParaApi(values) {
    let recebimentosAPI = []
    if (values.recebido) {
        recebimentosAPI.push({
            valor: values.valorRecebido,
            data: values.dataRecebido,
            conta: values.contaRecebido ? { id: values.contaRecebido.value } : null,
            juros: 0,
            multa: 0,
            desconto: 0,
        })
    } else {
        recebimentosAPI = values.recebimentos.map(recebimentoFormulario => {
            return {
                ...recebimentoFormulario,
                conta: recebimentoFormulario.conta ? { id: recebimentoFormulario.conta.value } : null,
                juros: recebimentoFormulario.juros || 0,
                multa: recebimentoFormulario.multa || 0,
                desconto: recebimentoFormulario.desconto || 0,
            }
        });
    }
    return recebimentosAPI;
}



export function converterContaReceberParaApi(values) {
    return {
        ...values,
        categoria: values.categoria ? { id: values.categoria.value } : null,
        pessoa: values.pessoa ? { id: values.pessoa.value } : null,
        recebimentos: values.recebimentos || values.recebido ? converterRecebimentosParaApi(values) : null,
    }
}