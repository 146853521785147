import React, { Component } from 'react'
import ButtonNovo from '../../../components/Button/ButtonNovo'
import { recursos, permissoes } from '../../../common/constantes/autorizacao';
import Grid from '../../../components/Grid';
import autoBind from 'react-autobind';
import { asyncDeletePlano, asyncGetPesquisaPlanos } from './requests'
import ButtonEditarTable from '../../../components/Button/ButtonEditarTable';
import ButtonExcluirTable from '../../../components/Button/ButtonExcluirTable';
import { atualizarUrl } from '../../util';
import InputSearch from '../../../components/Input/InputSearch';
import { Column } from 'primereact/column';
import { usuarioPossuiPermissao } from '../../../common/autenticacao';
import { construirUrl } from '../../../common/rsql';
import { services } from '../../../common/constantes/api';
import { confirm } from '../../../components/Toast';
import Form from '../../../components/Form';
import FormActions from '../../../components/FormActions';
import FormContent from '../../../components/FormContent';
import { CADASTROURL } from './utils/constantes';
import { DataTable } from 'primereact/components/datatable/DataTable';
import { formatarMonetario } from '../../../common/mascara';
import NenhumRegistroEncontrado from '../../../components/NenhumRegistroEncontrado';
import { Paginator } from 'primereact/paginator';

class AdministracaoPlanos extends Component {

    constructor(props) {
        super(props)

        autoBind(this)

        this.state = {
            podeInserir: usuarioPossuiPermissao(recursos.AGENTES_AUTORIZADOS, permissoes.INSERIR),
            podeExcluir: usuarioPossuiPermissao(recursos.AGENTES_AUTORIZADOS, permissoes.EXCLUIR),
            valorPesquisa: '',
            registros: [],
            sortField: 'descricao',
            sortOrder: 1,
            page: 0,
            rows: 10,
            totalElements: 0,
        }
    }

    componentDidMount() {
        this.pesquisar()
    }

    buscarFiltro() {
        const { valorPesquisa } = this.state

        return `?query=(descricao=contains="*${valorPesquisa}*",tipo=contains="*${valorPesquisa}*",valor=contains="*${valorPesquisa}*")`
    }

    pesquisar() {
        const { page, rows, sortField, sortOrder } = this.state
        const filtro = this.buscarFiltro();
        const url = construirUrl(`${services.GESTOR}/v1/administracao/planos`, filtro, rows, page, sortOrder > 0 ? `${sortField},asc` : `${sortField},desc`)

        asyncGetPesquisaPlanos(url, ({ data: agentes }) => {
            this.setState({
                registros: agentes.content,
                totalElements: agentes.totalElements
            })
        })
    }

    onPesquisar() {
        this.setState({ page: 0 }, () => {
            this.pesquisar();
        })
    }

    onPageChange(e) {
        this.setState({ page: e.page, rows: e.rows }, () => {
            this.pesquisar()
        })
    }

    onSort(e) {
        this.setState({ sortField: e.sortField, sortOrder: e.sortOrder }, () => {
            this.pesquisar()
        })
    }

    onEditar(row) {
        atualizarUrl(this.props.history, '/administracao_planos/cadastro', row.id)
    }

    onExcluir(row) {
        confirm('Confirmação', 'Deseja excluir o registro?', () => {
            this.asyncExcluirRegistro(row);
        })
    }

    asyncExcluirRegistro(registro) {
        asyncDeletePlano(registro.id, () => {
            this.pesquisar();
        })
    }

    renderOpcoes(row) {
        return (
            <>
                <ButtonEditarTable
                    onClick={() => this.onEditar(row)}
                />
                <ButtonExcluirTable
                    disabled={false}
                    podeExcluir={this.state.podeExcluir}
                    onClick={() => this.onExcluir(row)}
                />
            </>
        )
    }

    renderDescricao(row) {
        return row.descricao;
    }

    renderValor(row) {
        return formatarMonetario(row.valor);
    }

    renderTipo(row) {
        const label = row.tipo === 'NORMAL' ? 'Normal' : 'Exclusivo';

        return label;
    }

    render() {

        const { page, rows, totalElements, podeInserir, sortField, sortOrder, valorPesquisa, registros } = this.state

        return (
            <Form header="Planos">
                <FormActions>
                    <ButtonNovo
                        label='Novo plano'
                        onClick={() => this.props.history.push(CADASTROURL)}
                        podeInserir={podeInserir}
                    />
                </FormActions>
                <FormContent>
                    <Grid justifyCenter>
                        <InputSearch
                            onPesquisar={this.onPesquisar}
                            value={valorPesquisa}
                            onChange={value => this.setState({ valorPesquisa: value })}
                        />
                    </Grid>
                    <DataTable
                        className="table-light"
                        responsive
                        value={registros}
                        sortField={sortField}
                        sortOrder={sortOrder}
                        onSort={this.onSort}
                        emptyMessage={<NenhumRegistroEncontrado />}
                    >
                        <Column
                            field="descricao"
                            header="Descrição"
                            body={row => this.renderDescricao(row)}
                            sortable={true}
                        />
                        <Column
                            field="tipo"
                            header="Tipo"
                            body={row => this.renderTipo(row)}
                            sortable={true}
                        />
                        <Column
                            field="valor"
                            header="Valor"
                            sortable={true}
                            body={row => this.renderValor(row)}
                        />
                        <Column
                            body={this.renderOpcoes}
                            header="Ações"
                            style={{ width: '7em' }}
                        />
                    </DataTable>
                    <Paginator
                        className='paginator-light'
                        rowsPerPageOptions={[10, 25, 50]}
                        totalRecords={totalElements}
                        rows={rows}
                        first={page * rows}
                        onPageChange={this.onPageChange}
                        rightContent={<div style={{ margin: '5px' }}>{totalElements > 0 ? `${totalElements} registro(s) encontrado(s)` : null}</div>}
                    />
                </FormContent>
            </Form>
        )
    }
}

export default AdministracaoPlanos;