import React, { Component } from 'react';
import { DataTable } from 'primereact/components/datatable/DataTable';
import { Column } from 'primereact/column';
import { formatarMonetario } from '../../../../../../common/mascara';
import Button from '../../../../../../components/Button';
import autoBind from 'react-autobind';
import { Paginator } from 'primereact/paginator';
import { recursos, permissoes } from '../../../../../../common/constantes/autorizacao';
import { usuarioPossuiPermissao } from '../../../../../../common/autenticacao';
import NenhumRegistroEncontrado from '../../../../../../components/NenhumRegistroEncontrado';
import { format, parseISO } from 'date-fns';
import { Menu } from 'primereact/menu';
import { Status, infoStatusNfe } from '../../util/constantes';
import { Link } from 'react-router-dom';
import NotasHistorico from '../../Form/components/NotasHistorico';
import If from '../../../../../../components/If';

const styleButton = {
    borderRadius: '50%',
    padding: '5px',
    width: '30px',
    height: '30px',
    margin: '2px'
};

class TabelaNFe extends Component {

    constructor(props) {
        super(props);

        autoBind(this);

        this.state = {
            podeExcluir: usuarioPossuiPermissao(recursos.VENDAS_VENDAS, permissoes.EXCLUIR),
            podeEditar: usuarioPossuiPermissao(recursos.VENDAS_VENDAS, permissoes.EDITAR),
            registroSelecionado: null,
            modalHistoricoVisible: false,
        }
    }

    atualizarOrdenacao(e) {
        this.props.setSortField(e.sortField);
        this.props.setSortOrder(e.sortOrder);
    }

    renderEmissaoField(elemento) {
        if (elemento.criadoEm)
            return <span>{format(parseISO(elemento.criadoEm, new Date()), 'dd/MM/yyyy')}</span>
        return "";
    }

    renderNumeroField(elemento) {
        return (
            <div title={elemento.serie && elemento.nro ? `Série ${elemento.serie} / Número ${elemento.nro}` : null}>
                <span>{elemento.serie}</span>/
                <span title={!elemento.nro ? "O número da nota é gerados automaticamente pelo sistema" : "Número da nota fiscal"} style={{ fontWeight: 'bolder' }}>{elemento.nro || '-'}</span>
            </div>
        )
    }

    renderClienteField(elemento) {
        return (
            <span title={elemento.destNome} style={{ maxHeight: '60px', overflow: 'hidden', display: 'inline-flex' }}>
                {elemento.destNome}
            </span>
        );
    }

    renderStatusField(elemento) {
        if (!elemento.status) {
            return null;
        }

        return (
            <span title={infoStatusNfe[elemento.status].description} style={{
                backgroundColor: infoStatusNfe[elemento.status].lightColor,
                color: infoStatusNfe[elemento.status].strongColor,
                fontWeight: 'bold',
                fontSize: '13px',
                borderRadius: '3px',
                padding: '3px 10px',
            }}>
                <span style={{ width: '100%', textAlign: 'center' }}>{infoStatusNfe[elemento.status].name}</span>
            </span>
        )
    }

    renderValorField(elemento) {
        const valor = formatarMonetario(elemento.valor)
        return <span title={valor}>{valor}</span>
    }

    renderVenda(elemento) {
        return (
            <span style={{ fontSize: '12px' }} className="link_to" title={elemento.vendaNumero && "Nota fiscal gerada através da venda número " + elemento.vendaNumero}>
                <Link to={`/vendas/cadastro/${elemento.vendaId}`}>
                    {elemento.vendaNumero && `Nº ${elemento.vendaNumero}`}
                </Link>
            </span>
        )
    }

    renderAcoesField(elemento) {
        const { podeExcluir } = this.state;

        let titleEditar = "Editar";
        let titleExcluir = "Excluir";
        let titleBtnOpcoes = "Opções";
        let disableBtnEditar = false;
        let disableBtnExcluir = false;
        let disableBtnOpcoes = false;

        if (!podeExcluir) {
            titleExcluir = "Você não possui permissão para executar essa ação"
        };


        if (elemento.status === Status.CANCELADA || elemento.status === Status.TRANSMITIDA || elemento.status === Status.CORRIGIDA || elemento.status === Status.DENEGADA ) {
            titleExcluir = "Você só pode excluir uma nota fiscal caso ela ainda não foi transmitida"
            disableBtnExcluir = true;
        }

        if (elemento.status === Status.AGUARDANDO_AUTORIZACAO) {
            titleBtnOpcoes = "Aguarde até que a nota seja transmitida"
            titleExcluir = "Aguarde até que a nota seja transmitida"
            disableBtnExcluir = true;
            disableBtnOpcoes = true;
        }

        if (elemento.status === Status.REJEITADA) {
            titleBtnOpcoes = "Nota fiscal rejeitada"
            disableBtnOpcoes = true;
        }
        if (elemento.status === Status.NAO_ENVIADA) {
            titleBtnOpcoes = "Nota fiscal não transmitida"
            disableBtnOpcoes = true;
        }


        if (elemento.status === Status.DENEGADA) {
            titleBtnOpcoes = "Nota fiscal denegada"
            disableBtnOpcoes = true;
        }

        if (!podeExcluir) {
            disableBtnExcluir = true;
        }

        return (
            <>
                <Button
                    style={styleButton}
                    className="p-button p-button-primary"
                    icon="fa fa-pencil"
                    title={titleEditar}
                    disabled={disableBtnEditar}
                    onClick={() => this.props.editarNotaFiscal(elemento)}
                />
                <Button
                    style={styleButton}
                    className="p-button p-button-danger"
                    icon="fa fa-trash"
                    title={titleExcluir}
                    disabled={disableBtnExcluir}
                    onClick={() => this.props.removerNotaFiscal(elemento)}
                />
                <Button
                    className="p-button-secondary"
                    icon="fa fa-ellipsis-v"
                    style={styleButton}
                    title={titleBtnOpcoes}
                    disabled={disableBtnOpcoes}
                    aria-controls="popup_menu"
                    aria-haspopup={true}
                    onClick={event => {
                        this.setState({ registroSelecionado: elemento })
                        this.menu.toggle(event)
                    }}
                />
            </>
        );
    }

    montarItensMenu() {
        let itens = [];
        const { registroSelecionado } = this.state;

        if (registroSelecionado) {
            if (registroSelecionado.status === Status.TRANSMITIDA || registroSelecionado.status === Status.CANCELADA || registroSelecionado.status === Status.CORRIGIDA) {
                itens.push({ label: 'Histórico', icon: 'fa fa-history', command: () => this.visualizarHistorico() });
                itens.push({ label: 'Imprimir DANF-e', icon: 'fa fa-print', command: () => this.props.imprimirDANFE(registroSelecionado) });
                itens.push({ label: 'Baixar XML da NF-e', icon: 'fa fa-download', command: () => this.props.baixarXMLNotaFiscal(registroSelecionado) });
                if (registroSelecionado.status === Status.CORRIGIDA) {
                    itens.push({ label: 'Imprimir correção', icon: 'fa fa-print', command: () => this.props.imprimirCorrecao(registroSelecionado) });
                    itens.push({ label: 'Baixar XML correção', icon: 'fa fa-download', command: () => this.props.baixarXMLCorrecao(registroSelecionado) });
                }
            }
        }
        return itens;
    }

    visualizarHistorico() {
        this.setState({ modalHistoricoVisible: true });
    }

    render() {
        const {
            registros,
            sortField,
            sortOrder,
            totalRecords,
            rows,
            first,
            onPageChange } = this.props;
        const { modalHistoricoVisible, registroSelecionado } = this.state

        const itensMenu = this.montarItensMenu();

        return (
            <>
                <Menu
                    model={itensMenu}
                    popup={true}
                    style={{ width: '200px' }}
                    ref={elemento => this.menu = elemento}
                />
                <DataTable
                    className="table-light"
                    value={registros}
                    responsive
                    sortField={sortField}
                    sortOrder={sortOrder}
                    onSort={this.atualizarOrdenacao}
                    emptyMessage={<NenhumRegistroEncontrado message="Nenhuma nota fiscal encontrada" />}
                >
                    <Column
                        header="Série/N°"
                        field="nro"
                        sortable={true}
                        body={this.renderNumeroField}
                        style={{ color: '#333333', width: '100px' }}
                    />
                    <Column
                        header="Data"
                        field="criadoEm"
                        sortable={true}
                        body={this.renderEmissaoField}
                        style={{ color: '#333333', width: '100px' }}
                    />
                    <Column
                        header="Cliente"
                        field="destNome"
                        sortable={true}
                        body={this.renderClienteField}
                        style={{ color: '#333333', wordBreak: 'break-all' }}
                    />
                    <Column
                        header="Venda"
                        field="venda"
                        sortable={true}
                        body={this.renderVenda}
                        style={{ color: '#333333', width: '100px' }}
                    />
                    <Column
                        header="Status"
                        field="status"
                        sortable={true}
                        body={this.renderStatusField}
                        style={{ color: '#333333', width: '130px' }}
                    />
                    <Column
                        header="Valor"
                        field="valor"
                        sortable={true}
                        body={this.renderValorField}
                        style={{ color: '#333333', width: '116px', textOverflow: 'ellipsis', overflow: 'hidden', fontWeight: 'bold' }}
                    />
                    <Column
                        header="Ações"
                        body={this.renderAcoesField}
                        style={{ width: '130px' }}
                    />
                </DataTable>
                <Paginator
                    className='paginator-light'
                    rowsPerPageOptions={[10, 25, 50]}
                    totalRecords={totalRecords}
                    rows={rows}
                    first={first}
                    onPageChange={onPageChange}
                    rightContent={<div style={{ margin: '5px' }}>{totalRecords > 0 ? `${totalRecords} registro(s) encontrado(s)` : null}</div>}
                />
                <If test={modalHistoricoVisible}>
                    <NotasHistorico
                        idNota={registroSelecionado && registroSelecionado.id}
                        visible={modalHistoricoVisible}
                        onHide={() => this.setState({ modalHistoricoVisible: false })}
                    />
                </If>
            </>
        )

    }
}

export default TabelaNFe;
