import React, { Component } from 'react'
//Formik imports
import { withFormik, Field } from 'formik';
import * as Yup from 'yup';
//components
import { mensagensDeValidacao } from '../../../../common/constantes/mensagens'
import FormGroup from '../../../../components/FormGoup/index';
import Grid from '../../../../components/Grid/index';
import Button from '../../../../components/Button'
import InputField from '../../../../components/Input//InputField';
import { confirm } from '../../../../components/Toast';
import { asyncAtualizarEmail } from './requests'
import Col from '../../../../components/Col';
import AutoProgressBar from '../../../../components/Loading/AutoProgressBar';
import autoBind from 'react-autobind'

const initialValues = {
	email: '',
	senha: ''
}

class AlterarEmail extends Component {

	constructor(props) {
		super(props);
		autoBind(this)
	}

	async salvar() {
		const {handleSubmit, validateForm, values, handleReset, onHide} = this.props
		await validateForm()
		await handleSubmit()
		if (this.props.isValid) {

			confirm('Confirmação', 'Será enviado um e-mail de confirmação para seu novo endereço de e-mail. Deseja prosseguir?',
				async () => {
					await asyncAtualizarEmail(values, () => {
						handleReset();
						onHide();
					})
				}
			)
		}
	}

	render() {
		return (
			<FormGroup>
				<AutoProgressBar/>
				<Grid>
					<Col>
						<Field lg='12' xl='12'
							colStyle={{ padding: 0 }}
							component={InputField}
							label='Novo e-mail'
							name="email"
						/>
					</Col>
					<Col>
						<Field lg='12' xl='12'
							colStyle={{ padding: 0 }}
							component={InputField}
							type="password"
							label='Senha atual'
							name="senha"
						/>
					</Col>
					<Col lg='12' xl='12'>
						<Button
							type='button'
							label='Confirmar'
							icon='fa fa-envelope'
							width='135px'
							onClick={this.salvar}
							style={{ margin: 0 }}
						/>
					</Col>
				</Grid>
			</FormGroup>
		)
	}
}

AlterarEmail = withFormik({

	mapPropsToValues(props) {
		return initialValues
	},

	validationSchema: Yup.object().shape({
		email: Yup.string().email('E-mail inválido').nullable().required(mensagensDeValidacao.OBRIGATORIO),
		senha: Yup.string().nullable().required(mensagensDeValidacao.OBRIGATORIO).test('len', 'A senha deve ter no mínimo 8 caracteres', val => val && val.length >= 8)
	}),

	handleSubmit: () => { },

})(AlterarEmail);

export default AlterarEmail