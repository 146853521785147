import React, { Component } from 'react';
import Chart from "chart.js";
import { Card } from 'primereact/card';
import autoBind from 'react-autobind';
import { buscarDocumentosArquivadosPeriodo } from './requests';
import { ProgressBar } from 'primereact/progressbar';
import 'chartjs-plugin-datalabels';
import NenhumaInformacaoEncontrada from '../../../NenhumaInformacaoEncontrada'

import FalhaAoCarregarAsInformacoes from '../../../FalhaAoCarregarAsInformacoes';
import { ColorsCard } from '../../../../../../assinaturaeletronica/util/constantes';
import { connect } from 'react-redux';

const cardHeader = {
    padding: '14px',
    display: 'flex',
    justifyContent: 'space-between'
}

const NomeECorDocumento = {
    PENDENTE: {
        nome: 'Pendente',
        cor: ColorsCard['PENDENTE'].strongColor
    },
    ARQUIVO_ADICIONADO: {
        nome: 'Arquivo adicionado',
        cor: ColorsCard['ARQUIVO_ADICIONADO'].strongColor
    },
    AGUARDANDO_ASSINATURAS: {
        nome: 'Aguardando assinaturas',
        cor: ColorsCard['AGUARDANDO_ASSINATURAS'].strongColor
    },
    ASSINADO: {
        nome: 'Assinado',
        cor: ColorsCard['ASSINADO'].strongColor
    },
    REJEITADO: {
        nome: 'Rejeitado',
        cor: ColorsCard['REJEITADO'].strongColor
    },
    EXPIRADO: {
        nome: 'Expirado',
        cor: ColorsCard['EXPIRADO'].strongColor
    },
}

let documentosArquivadosPorStatus;

export class GraficoDocumentosArquivadosPeriodo extends Component {
    graficoDeDocumentosArquivadosNoPeriodo = React.createRef();

    constructor(props) {
        super(props);

        autoBind(this);

        this.state = {
            loading: true,
            error: false,
            status: [],
            corDocumentos: [],
            quantidade: [],
            documentos: [],
        }
    }

    componentDidMount() {
        this.carregarInformacoes();
    }

    componentDidUpdate(prevProps) {
        const { dataInicial, dataFinal } = this.props;

        if (prevProps.dataInicial !== dataInicial || prevProps.dataFinal !== dataFinal) {
            this.carregarInformacoes();
        }

        if (prevProps.atualizarGraficoNovamente !== this.props.atualizarGraficoNovamente) {
            this.carregarInformacoes(false);
        }
    }

    carregarInformacoes(exibirLoading = true) {
        const { dataInicial, dataFinal } = this.props;

        this.setState({ loading: exibirLoading, error: false }, () => {
            buscarDocumentosArquivadosPeriodo(dataInicial, dataFinal, ({ data: documentos }) => {

                let status = []
                let quantidade = []
                let corDocumentos = []

                documentos.forEach(documento => {
                    status.push(NomeECorDocumento[documento.status].nome)
                    corDocumentos.push(NomeECorDocumento[documento.status].cor)
                    quantidade.push(documento.quantidade)
                })

                this.setState({ status, quantidade, corDocumentos, documentos, loading: false }, () => {
                    this.buildChart();
                });
            }, () => {
                this.setState({ error: true, loading: false })
            });
        })
    }

    buildChart = () => {

        if (!this.graficoDeDocumentosArquivadosNoPeriodo.current) {
            return
        }
        const myChartRef = this.graficoDeDocumentosArquivadosNoPeriodo.current.getContext("2d");

        if (typeof documentosArquivadosPorStatus !== "undefined") documentosArquivadosPorStatus.destroy(); //Necessário para atualizar o gráfico sem bugs
        documentosArquivadosPorStatus = new Chart(myChartRef, {
            type: 'bar',
            data: {
                datasets: [{
                    data: this.state.quantidade,
                    backgroundColor: this.state.corDocumentos,
                }],
                labels: this.state.status,
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                aspectRatio: 1.1,
                scales: {
                    yAxes: [{
                        ticks: {
                            callback: value => value,
                            beginAtZero: true,
                            suggestedMax: 10
                        },
                        
                    }],
                    xAxes: [{
                        ticks: {
                            display: !this.props.isMobile
                        }
                    }]
                },
                legend: {
                    display: false
                },

                plugins: {
                    datalabels: {
                        color: "#fff",
                        font: {
                            size: 11
                        },
                        formatter: (value, ctx) => {
                            let total = 0;
                            let dataArr = ctx.chart.data.datasets[0].data;
                            dataArr.forEach(data => { total += data; });
                            return (value * 100 / total).toFixed(1) + "%";
                        },
                    }
                },
                tooltips: {
                    callbacks: {
                        label: (tooltipItem, data) => {
                            const quantidade = data.datasets[0].data[tooltipItem.index]
                            if (quantidade === 1) {
                                return ` ${quantidade} Documento`;
                            }
                            return ` ${quantidade} Documentos`;
                        },
                        title: (tooltipItem, data) => {
                            const label = data.labels[tooltipItem[0].index];
                            let total = 0;
                            let dataArr = data.datasets[0].data;
                            dataArr.forEach(data => total += data);
                            const value = dataArr[tooltipItem[0].index];
                            return label + " (" + (value * 100 / total).toFixed(1) + "%)";
                        }
                    }
                }
            }
        });
    }

    getHeaderCard() {
        return (
            <div style={cardHeader}>
                <label
                    title="Mostra os documentos arquivados no período e agrupa por status"
                    style={{ fontSize: '17px', color: '#333333' }}
                >
                    Documentos arquivados no período
                </label>
            </div>
        );
    }

    render() {
        const { loading, documentos } = this.state


        if (loading) {
            return (
                <Card header={this.getHeaderCard()} style={{ borderRadius: '5px', height: '100%' }}>
                    <ProgressBar mode="indeterminate" style={{ height: '3px' }} />
                </Card>
            )
        } else if (this.state.error) {
            return (
                <Card header={this.getHeaderCard()} style={{ borderRadius: '5px', height: '100%' }}>
                    <FalhaAoCarregarAsInformacoes />
                </Card>
            )
        } else if (documentos.length === 0) {
            return (
                <Card header={this.getHeaderCard()} style={{ borderRadius: '5px', height: '100%', }}>
                    <NenhumaInformacaoEncontrada />
                </Card>
            )
        } else {
            return (
                <Card header={this.getHeaderCard()} style={{ borderRadius: '5px', height: '100%', margin: '0px' }}>
                    <canvas id="graficoDeDocumentosArquivadosNoPeriodo" ref={this.graficoDeDocumentosArquivadosNoPeriodo} />
                </Card>
            )
        }
    }

}

const mapStateToProps = state => ({
    isMobile: state.dispositivo.isMobile,
})

export default connect(mapStateToProps)(GraficoDocumentosArquivadosPeriodo);