import React, { Component } from 'react';
import Modal from '../../../../../../../components/Modal';
import Grid from '../../../../../../../components/Grid';
import ButtonSalvar, { estadosBotaoSalvar } from '../../../../../../../components/Button/ButtonSalvar';
import ButtonCancelar, { estadosBotaoCancelar } from '../../../../../../../components/Button/ButtonCancelar';
import ButtonExcluir from '../../../../../../../components/Button/ButtonExcluir';
import { mensagensDeValidacao } from '../../../../../../../common/constantes/mensagens';
import autoBind from 'react-autobind';
import { withFormik, Field } from 'formik';
import InputField from '../../../../../../../components/Input/InputField';
import AutoProgressBar from '../../../../../../../components/Loading/AutoProgressBar';
import Form from '../../../../../../../components/Form';
import FormActions from '../../../../../../../components/FormActions';
import FormContent from '../../../../../../../components/FormContent';
import InputDouble from '../../../../../../../components/Input/InputDouble';
import InputMoney from '../../../../../../../components/Input/InputMoney';
import SingleSelectUnidadeMedida from '../../../../../../../components/Select/SingleSelectUnidadeMedida';
import Dropdown from '../../../../../../../components/Select/Dropdown';
import SingleSelectNcm from '../../../../../../../components/Select/SingleSelectNcm';
import SingleSelectCest from '../../../../../../../components/Select/SingleSelectCest';
import { services } from '../../../../../../../common/constantes/api';
import { optionsOrigemIcms } from '../../../../../utils/constantes'
import { optionsCsosn } from '../../utils/constantes'
import { formatarMonetario } from '../../../../../../../common/mascara';
import Col from '../../../../../../../components/Col';
import { converterProdutoIncompletoParaModal, converterProdutoEdicaoParaModal } from '../../utils/converterProdutosParaModal';
import { TiposDesconto } from '../../../util/constantes'
import { validarFormulario } from '../../../../../../util';
import If from '../../../../../../../components/If';
import { buscarDadosLoginLocalStorage } from '../../../../../../../common/autenticacao';
import Message from '../../../../../../../components/Message';
import { withRouter } from 'react-router-dom';
import { validarValorNegativo } from '../../../../VendasOrcamentos/Form/utils/functions';
import { helpMessageNFCe } from '../../help';
import { buscarDisabledDeAcordoComAsPermissoes } from '../../../../../../../common/autorizacao/manipulacaoDeComponentes';
import SingleSelectCfop from '../../../../../../../components/Select/SingleSelectCfop';
import InputSelectPercentualOrValorNfce from '../../../components/InputSelectPercentualOrValorNfce';

const initialValue = {
    idTemporario: '',
    produto: '',
    quantidade: 1,
    valorUnitario: 0,
    unidadeMedida: '',
    ncm: '',
    cest: '',
    cfop: '',
    csosn: '',
    origem: '',
    desconto: {
        tipoDesconto: TiposDesconto.VALOR,
        valorDesconto: 0
    },
    descontoTotalProduto: 0,
    totalProduto: 0,

};

const descontoStyle = {
    fontSize: '13px',
    color: '#000000',
    margin: '0px',
    justifyContent: 'flex-end',
    display: 'flex',
}

const styleErroProduto = {
    fontSize: '12px',
    color: 'red',
    margin: '0px',
    justifyContent: 'flex-end',
    display: 'flex',
}

const totalStyle = {
    fontSize: '24px',
    color: '#000000',
    margin: '10px 0px 0px 0px',
    justifyContent: 'flex-end',
    display: 'flex',
}


class ModalProdutoNfce extends Component {

    constructor(props) {
        super(props);
        autoBind(this);

        this.state = {
            restart: false
        }
    }

    cancelar() {
        if (this.props.dirty) {
            this.setState({restart: true})
            this.props.resetForm(initialValue)
        } else {
            this.props.onHide()
        }
    }

    async salvar() {
        this.props.handleSubmit();
        if (await validarFormulario(this.props)) {
            if (this.props.ajustarProdutoIncompleto) {
                this.props.salvar(this.props.values)
            } else if (this.props.editarProdutoDaNfce) {
                this.props.atualizar(this.props.values)
            }
        }
    }

    excluir(produto) {
        this.props.excluirProdutoDaNfce(produto)
    }

    trocarTipoDesconto(tipo, value, percentual) {
        const desconto = {
            tipoDesconto: tipo,
            valorDesconto: tipo === TiposDesconto.PERCENTUAL ? percentual : value
        }
        this.props.setFieldValue('desconto', desconto)
        this.calcularDesconto(desconto)
    }

    calcularDesconto(desconto) {
        const { quantidade, valorUnitario } = this.props.values
        const valorProduto = quantidade * valorUnitario

        let novoDesconto = 0

        if (desconto.tipoDesconto === TiposDesconto.PERCENTUAL) {
            novoDesconto = parseFloat(((valorProduto * desconto.valorDesconto) / 100).toFixed(2)) //Necessário para evitar arredondamento nos valores de desconto. Gerando erro na transmissão na NFC-e
            this.props.setFieldValue('descontoTotalProduto', novoDesconto)
        } else {
            novoDesconto = desconto.valorDesconto
            this.props.setFieldValue('descontoTotalProduto', novoDesconto)
        }

        const totalProdutoAtualizado = valorProduto - novoDesconto
        this.props.setFieldValue('totalProduto', validarValorNegativo(totalProdutoAtualizado))
    }

    calcularTotal() {
        const { descontoTotalProduto, quantidade, valorUnitario } = this.props.values

        const valorProduto = quantidade * valorUnitario
        const valorTotal = valorProduto - descontoTotalProduto

        this.props.setFieldValue('totalProduto', validarValorNegativo(valorTotal))
    }

    preferenciasNfceConfiguradas() {
        let { organizacao } = buscarDadosLoginLocalStorage();

        if (organizacao.fiscalNfce) {
            if (!organizacao.fiscalNfce.cfopPadraoNfce || !organizacao.fiscalNfce.csosnPadraoNfce) {
                return true
            }
        }
        return false
    }

    buscarMensagemPreferenciasNfceNaoConfiguradas() {
        return (
            <span>
                Preferências fiscais da NFC-e não configuradas.
                <div>
                    As informações de CFOP e CSOSN podem ser configuradas como padrão, para realizar essa configuração basta
                </div>
                <b
                    style={{ cursor: 'pointer' }}
                    onClick={() => this.props.history.push({ pathname: "/configuracoes_gerais", state: { configurarPreferencias: true } })}
                >
                    Clicar aqui
                </b>
            </span>
        )
    }

    trocarValorUnitario(valor) {
        const { descontoTotalProduto, quantidade } = this.props.values

        const valorProduto = quantidade * valor
        const valorTotal = (valorProduto) - descontoTotalProduto

        this.props.setFieldValue('valorUnitario', valor)
        this.props.setFieldValue('totalProduto', valorTotal)
    }

    async trocarQuantidade(quantidade) {
        const { descontoTotalProduto, valorUnitario } = this.props.values

        const valorProduto = quantidade * valorUnitario
        const valorTotal = valorProduto - descontoTotalProduto

        await this.props.setFieldValue('quantidade', quantidade)
        this.props.setFieldValue('totalProduto', valorTotal)
    }

    buscarHeader() {
        if (this.props.ajustarProdutoIncompleto) {
            return "Ajustar informações obrigatórias"
        }
        return "Editar produto da NFC-e"
    }

    render() {
        const {
            visible,
            onHide,
            dirty,
            values,
            isModal,
            podeInserir,
            podeEditar,
            estadoCadastro,
            errors,
            editarProdutoDaNfce,
            disabled,
            setFieldValue
        } = this.props;

        const disabledFormatado = buscarDisabledDeAcordoComAsPermissoes(
            podeInserir,
            podeEditar,
            estadoCadastro,
            disabled
        );

        return (
            <Modal
                header={this.buscarHeader()}
                visible={visible}
                onHide={() => {
                    onHide(values)
                }}
            >
                <AutoProgressBar />
                <Form>
                    <FormActions>

                        <ButtonCancelar
                            hidden={isModal && values.id && !dirty}
                            estadoBotao={dirty ? estadosBotaoCancelar.CANCELAR : estadosBotaoCancelar.VOLTAR}
                            onClick={this.cancelar}
                        />
                        <ButtonSalvar
                            estadoBotao={estadosBotaoSalvar.SALVAR}
                            disabled={!dirty || disabledFormatado}
                            onClick={this.salvar}
                        />
                        <ButtonExcluir
                            hidden={!editarProdutoDaNfce}
                            onClick={() => this.excluir(values)}
                            disabled={disabledFormatado}
                        />
                    </FormActions>
                    <If test={this.preferenciasNfceConfiguradas()}>
                        <Message severity="warn" text={this.buscarMensagemPreferenciasNfceNaoConfiguradas()} colStyle={{ padding: '5px 0px' }} />
                    </If>
                    <FormContent>
                        <Grid>
                            <Field
                                sm="12" md="8" lg="8" xl="8"
                                component={InputField}
                                label="Produto"
                                size={20}
                                value={values.produto}
                                name="produto"
                                disabled
                                helpMessage={helpMessageNFCe.produto}
                            />
                            <Field
                                sm="12" md="4" lg='4' xl='4'
                                component={InputDouble}
                                name="quantidade"
                                obrigatorio
                                label='Quantidade/Peso'
                                placeholder='0,00'
                                onChange={e => this.trocarQuantidade(e.target.value)}
                                value={values.quantidade}
                                size={10}
                                disabled={disabledFormatado}
                                helpMessage={helpMessageNFCe.quantidade}
                            />
                            <Field
                                sm="12" md="6" lg="6" xl="6"
                                component={InputMoney}
                                label='Valor unitário'
                                name="valorUnitario"
                                obrigatorio
                                touched={true}
                                allowNegative={false}
                                onChange={e => this.trocarValorUnitario(e.target.value)}
                                value={values.valorUnitario}
                                size={13}
                                disabled={disabledFormatado}
                                helpMessage={helpMessageNFCe.valorUnitario}
                            />
                            <Field
                                sm="12" md="6" lg="6" xl="6"
                                label='Unidade de medida'
                                name="unidadeMedida"
                                obrigatorio
                                component={SingleSelectUnidadeMedida}
                                value={values.unidadeMedida}
                                onChange={e => setFieldValue('unidadeMedida', e)}
                                disabled={disabledFormatado}
                                helpMessage={helpMessageNFCe.unidadeMedida}
                            />
                            <Field
                                sm="12" md="6" lg="6" xl="6"
                                label='NCM'
                                obrigatorio
                                name="ncm"
                                component={SingleSelectNcm}
                                url={`${services.GESTOR}/v1/produtos/relacoes/ncms`}
                                value={values.ncm}
                                onChange={e => setFieldValue('ncm', e)}
                                disabled={disabledFormatado}
                                helpMessage={helpMessageNFCe.ncm}
                            />
                            <Field
                                sm="12" md="6" lg="6" xl="6"
                                label='CEST'
                                name="cest"
                                component={SingleSelectCest}
                                value={values.cest ?? ''}
                                onChange={e => setFieldValue('cest', e)}
                                disabled={disabledFormatado}
                                helpMessage={helpMessageNFCe.cest}
                            />
                            <Field sm="12" md="6" lg='6' xl='6'
                                label='CFOP'
                                name="cfop"
                                obrigatorio
                                isClearable={false}
                                component={SingleSelectCfop}
                                value={values.cfop}
                                onChange={e => setFieldValue('cfop', e)}
                                disabled={disabledFormatado}
                                helpMessage={helpMessageNFCe.cfop}
                            />
                            <Field sm="6" md="6" lg="6" xl="6"
                                component={Dropdown}
                                label='CSOSN'
                                options={optionsCsosn}
                                name="csosn"
                                obrigatorio
                                showClear={false}
                                value={values.csosn}
                                onChange={e => setFieldValue('csosn', e.value)}
                                disabled={disabledFormatado}
                                helpMessage={helpMessageNFCe.csosn}
                            />
                            <Field
                                sm="12" md="6" lg='6' xl='6'
                                component={Dropdown}
                                label='Origem do produto'
                                name="origem"
                                obrigatorio
                                showClear={false}
                                value={values.origem}
                                onChange={(e) => setFieldValue('origem', e.value)}
                                options={optionsOrigemIcms}
                                disabled={disabledFormatado}
                                helpMessage={helpMessageNFCe.origemProduto}
                            />
                            <Field
                                sm="12" md="6" lg='6' xl='6'
                                label="Desconto"
                                name="desconto"
                                component={InputSelectPercentualOrValorNfce}
                                restart={this.state.restart}
                                onRestart={() => { this.setState({ restart: false }) }}
                                tipo={values.desconto.tipoDesconto}
                                value={values.desconto.valorDesconto}
                                onChange={(tipoDesconto, value, percentual) => { this.trocarTipoDesconto(tipoDesconto, value, percentual) }}
                                placeholder='0,00'
                                allowNegative={false}
                                disabled={disabledFormatado}
                                helpMessage={helpMessageNFCe.descontoProduto}
                            />
                        </Grid>
                        <Col>
                            <p style={descontoStyle}>{`Desconto: ${formatarMonetario(this.props.values.descontoTotalProduto)}`}</p>
                            <p style={totalStyle}>{`Total: ${formatarMonetario(this.props.values.totalProduto)}`}</p>
                            {errors.totalProduto && <p style={styleErroProduto}>{errors.totalProduto}</p>}
                        </Col>
                    </FormContent>
                </Form>
            </Modal>
        )
    }
}
ModalProdutoNfce = withFormik({
    validateOnMount: true,
    validateOnChange: true,
    enableReinitialize: true,

    mapPropsToValues(props) {
        if (props.ajustarProdutoIncompleto) {
            return converterProdutoIncompletoParaModal(props.ajustarProdutoIncompleto)
        } else if (props.editarProdutoDaNfce) {
            const values = converterProdutoEdicaoParaModal(props.editarProdutoDaNfce)
            return values
        } else {
            return initialValue;
        }
    },

    validate(values) {
        let errors = {};

        if (!values.produto) {
            errors.nome = mensagensDeValidacao.OBRIGATORIO
        }

        if (!values.quantidade) {
            errors.quantidade = mensagensDeValidacao.OBRIGATORIO
        }

        if (values.quantidade <= 0) {
            errors.quantidade = 'Quantidade não pode ser menor ou igual a zero'
        }

        if (!values.valorUnitario) {
            errors.valorUnitario = mensagensDeValidacao.OBRIGATORIO
        }
        if (values.valorUnitario <= 0) {
            errors.valorUnitario = 'Valor unitário não pode ser menor ou igual a zero'
        }

        if (!values.unidadeMedida || values.unidadeMedida === null) {
            errors.unidadeMedida = mensagensDeValidacao.OBRIGATORIO
        }

        if (!values.ncm) {
            errors.ncm = mensagensDeValidacao.OBRIGATORIO
        }

        if (!values.csosn) {
            errors.csosn = mensagensDeValidacao.OBRIGATORIO
        }

        if (!values.origem) {
            errors.origem = mensagensDeValidacao.OBRIGATORIO
        }

        if (!values.cfop || values.cfop.value === null) {
            errors.cfop = mensagensDeValidacao.OBRIGATORIO
        }

        if (values.descontoTotalProduto < 0) {
            errors.valorDesconto = 'Valor de desconto não pode ser menor ou igual a zero'
        }

        if (values.totalProduto <= 0) {
            errors.totalProduto = 'Valor total não pode ser menor ou igual a zero'
        }
        return errors
    },

    handleSubmit: () => { }
})(ModalProdutoNfce);

export default withRouter(ModalProdutoNfce);
