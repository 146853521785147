import React, { Component } from 'react';
import autoBind from "react-autobind/lib/autoBind";
import { DataTable } from 'primereact/components/datatable/DataTable';
import { Column } from 'primereact/column';
import { Paginator } from 'primereact/paginator';
import { formatarMonetario } from '../../../../../common/mascara';
import { recursos, permissoes } from '../../../../../common/constantes/autorizacao';
import { usuarioPossuiPermissao } from '../../../../../common/autenticacao';
import Button from '../../../../../components/Button';
import { format, parseISO } from 'date-fns/esm';
import { connect } from 'react-redux';
import NenhumRegistroEncontrado from '../../../../../components/NenhumRegistroEncontrado';
import { FaCheckCircle, FaExclamationCircle } from 'react-icons/fa';
import isEqual from 'date-fns/isEqual';
import isPast from 'date-fns/isPast';
import { Financas } from '../../../../util/constantes'

import './style/index.css'

const styleColumnOverflowElipsis = {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
}

const styleDestakFiend = {
    display: 'inline-flex',
    justifyContent: 'center',
};

const styleButton = {
    borderRadius: '50%',
    padding: '5px',
    width: '30px',
    height: '30px',
    margin: '2px'
};

const dataAtual = new Date().setHours(0, 0, 0, 0);

class TabelaContasPagar extends Component {

    constructor(props) {
        super(props);

        autoBind(this)

        this.state = {
            podeExcluirContasAPagar: usuarioPossuiPermissao(recursos.FINANCAS_CONTAS_PAGAR, permissoes.EXCLUIR),
            podeVisualizarContasAPagar: usuarioPossuiPermissao(recursos.FINANCAS_CONTAS_PAGAR, permissoes.VISUALIZAR),
            podeEditarContasAPagar: usuarioPossuiPermissao(recursos.FINANCAS_CONTAS_PAGAR, permissoes.EDITAR),
        }
    }

    atualizarOrdenacao(e) {
        this.props.setSortField(e.sortField);
        this.props.setSortOrder(e.sortOrder);
    }

    renderStatus(el) {
        const dataVencimento = parseISO(el.vencimento)

        if (el.status !== "PAGA" && isPast(dataVencimento) && !isEqual(dataVencimento, dataAtual)) {
            return <FaExclamationCircle title="Vencida" size={18} color={Financas.cores.vermelho} />
        } else {
            if (el.status === "NAO_PAGA" || el.status === 'PARCIALMENTE_PAGA') {
                return <FaCheckCircle title="Não paga" size={18} color={Financas.cores.cinza} />
            } else if (el.status === "PAGA") {
                return <FaCheckCircle title="Paga" size={18} color={Financas.cores.verde} />
            }
        }
    }

    renderDataVencimento(el) {
        const dataVencimento = parseISO(el.vencimento)
        const dataVencimentoTexto = format(dataVencimento, 'dd/MM/yyyy');

        if (isPast(dataVencimento) && !isEqual(dataVencimento, dataAtual)) {
            return <span title={`Conta a pagar vencida em: ${dataVencimentoTexto}`}>{dataVencimentoTexto}</span>
        }

        return <span title={`Data de vencimento da conta a pagar: ${dataVencimentoTexto}`}>{dataVencimentoTexto}</span>
    }


    renderDescricaoField(el) {
        let descricao = el.descricao;

        if (el.itemRepeticao && el.quantidadeRepeticoes) {
            descricao = <>
                <span style={{ color: 'gray', fontWeight: 'bold' }}>
                    {`${el.itemRepeticao}/${el.quantidadeRepeticoes} `}
                </span>
                {el.descricao}
            </>;
        }

        return <span title={el.descricao}>{descricao}</span>
    }

    renderNomeField(el) {
        return (
            <span title={el.pessoaNome} >
                {el.pessoaNome}
            </span>
        );
    }

    renderCategoriaField(el) {
        return (
            <span title={el.categoriaNome} style={{ color: el.categoriaAparencia, fontWeight: 'bold' }}>
                {el.categoriaNome}
            </span>
        );
    }

    renderValorField(el) {
        const valor = formatarMonetario(el.valor);

        return <span title={valor} >{valor}</span>
    }

    renderPagoAPagarField(el) {
        const valorAPagar = formatarMonetario(el.valorAPagar)
        if (el.valorAPagar > 0) {
            return <span title={valorAPagar} style={{ ...styleDestakFiend, fontWeight: 'bold' }}>{valorAPagar}</span>
        }
        return
    }

    renderAcoesField(el) {
        const { podeExcluirContasAPagar, podeVisualizarContasAPagar, podeEditarContasAPagar } = this.state;
        let titleEditar = "Editar";
        let titleEfetuarPagamento = "Efetuar pagamento";
        let titleExcluir = "Excluir";
        let disableBtnEditar = false;
        let disableBtnEfetuarPagamento = false;
        let disableBtnExcluir = false;

        if (!podeVisualizarContasAPagar) {
            titleEditar = "Você não possuir permissão para executar essa ação"
            disableBtnEditar = true;
        }


        if (!podeEditarContasAPagar) {
            titleEfetuarPagamento = "Você não possuir permissão para executar essa ação"
            disableBtnEfetuarPagamento = true;
        }


        if (!podeExcluirContasAPagar) {
            titleExcluir = "Você não possui permissão para executar essa ação"
            disableBtnExcluir = true;
        };

        return (
            <>
                <Button
                    style={styleButton}
                    className="p-button p-button-secondary"
                    icon="fa fa-check"
                    hidden={el.status === 'PAGA'}
                    title={titleEfetuarPagamento}
                    disabled={disableBtnEfetuarPagamento}
                    onClick={() => this.props.onEfetuarPagamentoItem(el)}
                />
                <Button
                    style={styleButton}
                    className="p-button p-button-primary"
                    icon="fa fa-pencil"
                    title={titleEditar}
                    disabled={disableBtnEditar}
                    onClick={() => this.props.onEditItem(el)}
                />
                <Button
                    style={styleButton}
                    className="p-button p-button-danger"
                    icon="fa fa-trash"
                    title={titleExcluir}
                    disabled={disableBtnExcluir}
                    onClick={() => this.props.onRemoveItem(el)}
                />
            </>
        );
    }

    render() {
        const { registros, sortField, sortOrder, isMobile, rows, totalRecords, first, onPageChange } = this.props;

        const statusStyle = !isMobile && { textAlign: 'center' };
        const acoesStyle = !isMobile && { textAlign: 'end' };

        return (
            <>
                <DataTable
                    className="table-light"
                    value={registros}
                    responsive
                    sortField={sortField}
                    sortOrder={sortOrder}
                    onSort={this.atualizarOrdenacao}
                    emptyMessage={<NenhumRegistroEncontrado message="Nenhuma conta a pagar encontrada" />}
                >
                    <Column
                        field="status"
                        header={isMobile ? "Status" : ""}
                        body={this.renderStatus}
                        style={{ width: '60px', ...statusStyle }}
                    />
                    <Column
                        header="Vencimento"
                        field="vencimento"
                        body={this.renderDataVencimento}
                        sortable={true}
                    />
                    <Column
                        header="Descrição"
                        field="descricao"
                        body={this.renderDescricaoField}
                        sortable={true}
                        bodyStyle={styleColumnOverflowElipsis}
                    />
                    <Column
                        header="Pessoa"
                        field="pessoaNome"
                        sortable={true}
                        body={this.renderNomeField}
                    />
                    <Column
                        header="Categoria"
                        field="categoriaNome"
                        body={this.renderCategoriaField}
                        sortable={true}
                        bodyStyle={styleColumnOverflowElipsis}
                    />
                    <Column
                        header="Valor"
                        field="valor"
                        sortable={true}
                        body={this.renderValorField}
                        bodyStyle={styleColumnOverflowElipsis}
                    />
                    <Column
                        header="A pagar"
                        field="valorAPagar"
                        body={this.renderPagoAPagarField}
                        bodyStyle={styleColumnOverflowElipsis}
                    />
                    <Column
                        header="Ações"
                        headerClassName='coluna-acoes-end'
                        body={this.renderAcoesField}
                        style={{ width: '130px', ...acoesStyle }}
                    />
                </DataTable>
                <Paginator
                    className='paginator-light'
                    rowsPerPageOptions={[10, 25, 50]}
                    totalRecords={totalRecords}
                    rows={rows}
                    first={first}
                    onPageChange={onPageChange}
                    rightContent={<div style={{ margin: '5px' }}>{totalRecords > 0 ? `${totalRecords} registro(s) encontrado(s)` : null}</div>}
                />
            </>
        )

    }
}

const mapStateToProps = state => ({
    isMobile: state.dispositivo.isMobile,
})

export default connect(mapStateToProps)(TabelaContasPagar);