import { del, post, exibirToast } from '../../../../../../common/requisicoes';
import { services } from '../../../../../../common/constantes/api';

export async function asyncDeletarUsuario(idOrganizacao, idCredencial, onSuccess, onError) {
    await del(`${services.GESTOR}/v1/organizacoes/${idOrganizacao}/credenciais/${idCredencial}`, null, exibirToast(onSuccess, "Usuário excluído com sucesso"), onError);
}

export async function asyncBloquearCredencial(idOrganizacao, idCredencial, onSuccess, onError) {
    await post(`${services.GESTOR}/v1/organizacoes/${idOrganizacao}/credenciais/${idCredencial}/bloquear`, null, null, exibirToast(onSuccess, "Usuário bloqueado com sucesso"), onError);
}

export async function asyncDesbloquearCredencial(idOrganizacao, idCredencial, onSuccess, onError) {
    await post(`${services.GESTOR}/v1/organizacoes/${idOrganizacao}/credenciais/${idCredencial}/desbloquear`, null, null, exibirToast(onSuccess, "Usuário desbloqueado com sucesso"), onError);
}
