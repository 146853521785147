import React, { Component } from 'react'
import autoBind from 'react-autobind';
import CardModulo from '../../../../../components/CardModulo';
import Col from '../../../../../components/Col';
import Grid from '../../../../../components/Grid';
import If from '../../../../../components/If';


class Modulos extends Component {
    constructor(props) {
        super(props);
        autoBind(this);
    }


    onSelectModulo(modulo) {
        const { value, onChange } = this.props
        const moduloJaSelecionado = value.includes(modulo)
        if (moduloJaSelecionado) {
            onChange(value.filter(item => item !== modulo).sort())
        } else {
            onChange([...value, modulo].sort())
        }
    }

    render() {
        const { value, errors, podeEditarEmpresa } = this.props;

        return (
            <>
                <Grid>
                    <Col sm='12' md='6' lg='6' xl='6' title={!podeEditarEmpresa ? "Você não possui permissão para editar" : "Assinatura eletrônica"}>
                        <CardModulo
                            value={'DOCUMENTOS_DIGITAIS'}
                            selected={value.includes('DOCUMENTOS_DIGITAIS')}
                            onSelect={this.onSelectModulo}
                            title='Assinatura eletrônica'
                            subtitle='Assinatura eletrônica de documentos'
                            disabled={!podeEditarEmpresa}
                        />
                    </Col>

                    <Col sm='12' md='6' lg='6' xl='6'>
                        <CardModulo
                            value={'ESTOQUE'}
                            selected={value.includes('ESTOQUE')}
                            onSelect={this.onSelectModulo}
                            title='Controle de estoque'
                            subtitle='Cadastro, movimentações e saldo de produtos'
                            disabled={!podeEditarEmpresa}
                        />
                    </Col>
                    <Col sm='12' md='6' lg='6' xl='6'>
                        <CardModulo
                            value={'FINANCAS'}
                            selected={value.includes('FINANCAS')}
                            onSelect={this.onSelectModulo}
                            title='Gestão de finanças'
                            subtitle='Tenha controle de suas finanças e ganhe confiança para buscar melhores resultados'
                            disabled={!podeEditarEmpresa}
                        />
                    </Col>
                    <Col sm='12' md='6' lg='6' xl='6'>
                        <CardModulo
                            value={'VENDAS'}
                            selected={value.includes('VENDAS')}
                            onSelect={this.onSelectModulo}
                            title='Gestão de vendas'
                            subtitle='Acompanhe e organize seu processo de venda e orçamentos'
                            disabled={!podeEditarEmpresa}
                        />
                    </Col>
                    <Col sm='12' md='6' lg='6' xl='6'>
                        <CardModulo
                            value={'INVESTIDOR'}
                            selected={value.includes('INVESTIDOR')}
                            onSelect={this.onSelectModulo}
                            title='Controle de investimentos'
                            subtitle='Consolide todos os seus ativos em um único lugar e descomplique a declaração de seu IR'
                            disabled={!podeEditarEmpresa}
                        />
                    </Col>
                    <If test={errors}>
                        <Col sm='12' style={{ padding: '0px 10px' }}>
                            <span style={{ color: 'red', fontSize: '12px' }}>{errors}</span>
                        </Col>
                    </If>
                </Grid>
            </>
        )
    }
}

export default Modulos
