import React, { Component } from 'react';
import autoBind from 'react-autobind';
import SingleSelect from '../SingleSelect';
import propTypes from 'prop-types'
import { usuarioPossuiPermissao } from '../../../common/autenticacao';
import { recursos, permissoes } from '../../../common/constantes/autorizacao';
import ModalAtivo from '../../../views/investimentos/Ativos/components/ModalAtivo';

class SingleSelectAtivo extends Component {
    constructor(props) {
        super(props);
        autoBind(this);

        this.state = {
            visible: false,
            podeInserir: usuarioPossuiPermissao(recursos.INVESTIDOR_ATIVOS, permissoes.INSERIR),
            podeVisualizar: usuarioPossuiPermissao(recursos.INVESTIDOR_ATIVOS, permissoes.VISUALIZAR),
        }
    }

    buscarUrlPesquisa(pesquisa, page) {
        return `${this.props.url}?query=codigo=contains="*${pesquisa}*"&page=${page}&size=50`;
    }

    buscarUrlRegistroUnico(id) {
        return `${this.props.url}?query=id==${id}`;
    }

    montarLabel(row) {
        return row.codigo
    }

    mostrarModal() {
        this.setState({ visible: !this.state.visible });
    }

    onHide(novoRegistro) {
        this.setarNovoRegistroNoSelect(novoRegistro)
        this.esconderModal()
    }

    setarNovoRegistroNoSelect(novoRegistro) {
        if (novoRegistro) {
            this.props.onChange({ label: novoRegistro.codigo, value: novoRegistro.id })
        }
    }

    esconderModal() {
        this.setState({ ...this.state, visible: false })
    }

    render() {
        const { podeInserir, podeVisualizar } = this.state
        const podeInserirEVisualizar = podeInserir && podeVisualizar

        return (
            <React.Fragment>
                <ModalAtivo
                    service={this.props.service}
                    visible={this.state.visible}
                    insertJustOne={true}
                    onHide={this.onHide}
                />
                <SingleSelect
                    buscarUrlPesquisa={this.buscarUrlPesquisa}
                    buscarUrlRegistroUnico={this.buscarUrlRegistroUnico}
                    montarLabel={this.montarLabel}
                    onClickModal={this.mostrarModal}
                    onChange={this.props.onChange}
                    disabledButton={!podeInserirEVisualizar}
                    titleBotaoNovo={podeInserirEVisualizar ? "Adicionar nova conta" : "Você não possui permissão para executar esta ação"}
                    {...this.props}
                />
            </React.Fragment>
        );
    }
}

SingleSelectAtivo.defaultProps = {
    label: 'Ativo'
}

SingleSelectAtivo.propTypes = {
    /** Url do servidor em que as requisições de busca deverão ser efetuadas: Ex:  `${services.GESTOR}/v1/usuarios/relacoes/pessoas`*/
    url: propTypes.string.isRequired
};


export default SingleSelectAtivo;
