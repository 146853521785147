import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import autoBind from 'react-autobind/lib/autoBind';
import { usuarioPossuiPermissao } from '../../../../../common/autenticacao';
import { recursos, permissoes } from '../../../../../common/constantes/autorizacao';
import Message from '../../../../../components/Message';

const textStyle = {
    margin: '5px 0px'
}

class MessageOrganizacaoBloqueada extends Component {
    constructor(props) {
        super(props);
        autoBind(this);
        this.state = {
            podeVisualizarPlanos: usuarioPossuiPermissao(recursos.PLANOS, permissoes.VISUALIZAR)
        }
    }

    render() {
        return (
            <Message
                severity="info"
                colStyle={{ margin: '1em 0em' }}
                text={
                    <div>
                        <h2>Organização bloqueada</h2>
                        <p style={textStyle}>Sua organização foi bloqueada. Para obter mais informações entre em contato conosco</p>
                        <span onClick={() => window.open('http://www.microsys.inf.br/s/index.php?pg=contato', '_blank')} style={{ cursor: 'pointer', fontWeight: 'bold' }}>
                            Entrar em contato
                        </span>
                    </div>
                }
            />
        )
    }
}

export default withRouter(MessageOrganizacaoBloqueada)
