export const csosns = {
    CSOSN_101: 'CSOSN_101',
    CSOSN_102: 'CSOSN_102',
    CSOSN_103: 'CSOSN_103',
    CSOSN_201: 'CSOSN_201',
    CSOSN_202: 'CSOSN_202',
    CSOSN_203: 'CSOSN_203',
    CSOSN_300: 'CSOSN_300',
    CSOSN_400: 'CSOSN_400',
    CSOSN_500: 'CSOSN_500',
    CSOSN_900: 'CSOSN_900',
}

export const modal = {
    DESTINATARIO: 1,
    ALIQUOTA: 2,
    ORGANIZACAO: 3
}

export const modalidadeICMSST = {
    PRECO_TABELADO: "PRECO_TABELADO",
    LISTA_NEGATIVA: "LISTA_NEGATIVA",
    LISTA_POSITIVA: "LISTA_POSITIVA",
    LISTA_NEUTRA: "LISTA_NEUTRA",
    MARGEM_VALOR_AGREGADO: "MARGEM_VALOR_AGREGADO",
    PAUTA: "PAUTA"
}

export const optionsIndPres = [
    { label: '0 - Não se aplica', value: 'NAO_SE_APLICA' },
    { label: '1 - Operação presencial', value: 'OPERACAO_PRESENCIAL' },
    { label: '2 - Operação pela internet', value: 'OPERACAO_PELA_INTERNET' },
    { label: '3 - Operação por teleatendimento', value: 'OPERACAO_POR_TELEATENDIMENTO' },
    { label: '5 - Operação presencial fora do estabelecimento', value: 'OPERACAO_PRESENCIAL_FORA_DO_ESTABELECIMENTO' },
    { label: '9 - Outros', value: 'OUTROS' },
]

export const optionsOrigemIcms = [
    { label: '0 - Nacional, exceto as indicadas nos código 3, 4, 5 e 8', value: 'NACIONAL' },
    { label: '1 - Estrangeira - Importação direta, exceto a indicada no código 6', value: 'ESTRANGEIRA' },
    { label: '2 - Estrangeira - Adquirida no mercado interno, exceto a indicada no código 7', value: 'ESTRANGEIRA_ADQUIRIDA_MERCADO_INTERNO' },
    { label: '3 - Nacional, mercadoria ou bem com Conteúdo de Importação superior a 40% e inferior ou igual a 70%', value: 'NACIONAL_IMPORTACAO_ENTRE_40_E_70_PORCENTO' },
    { label: '4 - Nacional, cuja produção tenha sido feita em conformidade com os processos produtivos básicos de que tratam as legislações citadas nos Ajustes', value: 'NACIONAL_COM_PROCESSO_PRODUTIVO' },
    { label: '5 - Nacional, mercadoria ou bem com Conteúdo de Importação inferior ou igual a 40%', value: 'NACIONAL_IMPORTACAO_INFERIOR_40_PORCENTO' },
    { label: '6 - Estrangeira - Importação direta, sem similar nacional, constante em lista da CAMEX e gás natural', value: 'ESTRANGEIRA_IMPORTACAO_DIRETA' },
    { label: '7 - Estrangeira - Adquirida no mercado interno, sem similar nacional, constante em lista da CAMEX e gás natural', value: 'ESTRANGEIRA_ADQUIR_MERCADO_INTERNO_SEM_SIMILAR_NAC' },
    { label: '8 - Nacional, mercadoria ou bem com Conteúdo de Importação superior a 70%', value: 'NACIONAL_IMPORTACAO_SUPERIOR_70_PORCENTO' },
];

export const optionsCsosn = [
    { label: '101 - Tributada pelo Simples Nacional com Permissão de Crédito', value: csosns.CSOSN_101 },
    { label: '102 - Tributada pelo Simples Nacional sem Permissão de Crédito', value: csosns.CSOSN_102 },
    { label: '103 - Isenção do ICMS no Simples Nacional para Faixa de Receita Bruta', value: csosns.CSOSN_103 },
    { label: '201 - Tributada pelo Simples Nacional com Permissão de Crédito e com Cobrança do ICMS por Substituição Tributária', value: csosns.CSOSN_201 },
    { label: '202 - Tributada pelo Simples Nacional sem Permissão de Crédito e com cobrança do ICMS por Substituição Tributária', value: csosns.CSOSN_202 },
    { label: '203 - Isenção do ICMS no Simples Nacional para Faixa de Receita Bruta e com Cobrança do ICMS por Substituição Tributária', value: csosns.CSOSN_203 },
    { label: '300 - Imune (Imunidade do ICMS)', value: csosns.CSOSN_300 },
    { label: '400 - Não Tributada pelo Simples Nacional', value: csosns.CSOSN_400 },
    { label: '500 - ICMS Cobrado Anteriormente por Substituição Tributária (Substituído) ou por Antecipação', value: csosns.CSOSN_500 },
    { label: '900 - Outros (Demais Operações)', value: csosns.CSOSN_900 },
]

export const optionsAliquotasFCP = [
    { label: 'Não se aplica', value: 0 },
    { label: '1%', value: 1 },
    { label: '1,5%', value: 1.5 },
    { label: '2%', value: 2 },
];

export const optionsAliqInterUFEnvolvidas = [
    { label: '4%', value: 4 },
    { label: '7%', value: 7 },
    { label: '12%', value: 12 },
];

export const optionsModalidadeDeterminacaoST = [
    { label: 'Preço tabelado ou máximo sugerido', value: "PRECO_TABELADO" },
    { label: 'Lista Negativa (valor)', value: "LISTA_NEGATIVA" },
    { label: 'Lista Positiva (valor)', value: "LISTA_POSITIVA" },
    { label: 'Lista Neutra (valor)', value: "LISTA_NEUTRA" },
    { label: 'Margem Valor Agregado (%)', value: "MARGEM_VALOR_AGREGADO" },
    { label: 'Pauta (valor)', value: "PAUTA" },
];

export const optionsModalidadeDeterminacaoICMS = [
    { label: 'Margem Valor Agregado', value: "MARGEM_VALOR_AGREGADO" },
    { label: 'Pauta', value: "PAUTA" },
    { label: 'Preço Tabelado Máx', value: "PRECO_TABELADO_MAX" },
    { label: 'Valor da operação', value: "VALOR_OPERACAO" },
];