import React, { Component } from 'react';
import { withFormik, Field } from 'formik';
import * as Yup from 'yup';
import Button from '../../../../components/Button';
import Grid from '../../../../components/Grid';
import ButtonNovo, { estadosBotaoNovo } from '../../../../components/Button/ButtonNovo';
import { withRouter } from 'react-router';
import ButtonSalvar from '../../../../components/Button/ButtonSalvar';
import ButtonCancelar, { estadosBotaoCancelar } from '../../../../components/Button/ButtonCancelar';
import InputField from '../../../../components/Input/InputField';
import { mensagensDeValidacao } from '../../../../common/constantes/mensagens';
import { asyncDeletePessoa, asyncCreatePessoa, asyncGetPessoa, asyncUpdatePessoa } from '../requests';
import { recursos, permissoes, estadosCadastro } from '../../../../common/constantes/autorizacao';
import { confirmarExclusao } from '../../../util/exclusaoDeRegistros';
import { SITUACAO, PESSOA_TIPO, tabsCadastro } from '../utils/constantes';
import { manterApenasNumeros } from '../../../../common/mascara';
import { converterPessoaParaApi, converterPessoaParaFormulario } from '../utils/pessoaConverter';
import { TabView, TabPanel } from 'primereact/components/tabview/TabView';
import Prompt from '../../../../components/Route/Prompt';
import PessoaTelefone from './PessoaTelefone';
import PessoaEmail from './PessoaEmail';
import { isValidCpf, isValidCnpj } from '@brazilian-utils/validators';
import ButtonExcluir from '../../../../components/Button/ButtonExcluir';
import PessoaEndereco from './PessoaEndereco';
import autoBind from "react-autobind";
import { validarUUID } from '../../../../common/manipulacaoDeString';
import { atualizarUrl, metodosAtualizarUrl, voltarParaAPesquisa } from '../../../util';
import Dropdown from '../../../../components/Select/Dropdown';
import { renderizarFieldsPessoaFisica } from './fieldsPessoaFisica';
import { renderizarFieldsPessoaJuridica } from './fieldsPessoaJuridica';
import { renderizarFieldsEstrangeiro } from './fieldsEstrangeiro';
import { renderizarFieldsObservacao } from './fieldsObservacao';
import { helpPessoaForm } from './help';
import { usuarioPossuiPermissao } from '../../../../common/autenticacao';
import { validarFormulario } from './utils/functions.js'
import PessoaContato from './PessoaContato';
import AutoProgressBar from '../../../../components/Loading/AutoProgressBar';
import If from '../../../../components/If';
import Form from '../../../../components/Form';
import FormActions from '../../../../components/FormActions';
import FormContent from '../../../../components/FormContent';
import { parseISO, isValid } from 'date-fns';
import { renderizarFieldsInformacoesFiscais } from './fieldsInformacoesFiscais';
import { jaExisteMesmoCpf, jaExisteMesmoCnpj } from './utils/functions'
import { confirm } from '../../../../components/Toast';

const initialValue = {
    id: "",
    nome: null,
    situacao: 'ATIVA',
    tipo: 'FISICA',
    observacao: null,

    //Pessoa física
    cpf: null,
    rg: null,
    genero: null,
    nascimento: null,

    //Pessoa jurídica
    cnpj: null,
    razaoSocial: null,
    indicadorInscricaoEstadual: null,
    inscricaoEstadual: '',
    simplesNacional: false,
    inscricaoSuframa: '',
    inscricaoMunicipal: '',

    //Pessoa estrangeira
    identificacao: '',

    enderecos: [],
    emails: [],
    telefones: [],
    contatos: []
}

const CADASTROURL = '/pessoas/cadastro';
const PESQUISAURL = '/pessoas';

class PessoaForm extends Component {

    constructor(props) {
        super(props);

        autoBind(this);

        this.state = {
            tabSelecionada: tabsCadastro.PESSOA_FISICA,
            podeInserir: usuarioPossuiPermissao(recursos.PESSOAS, permissoes.INSERIR),
            podeVisualizarFiscal: usuarioPossuiPermissao(recursos.VENDAS_VENDAS, permissoes.VISUALIZAR),
            podeEditar: usuarioPossuiPermissao(recursos.PESSOAS, permissoes.EDITAR),
            podeExcluir: usuarioPossuiPermissao(recursos.PESSOAS, permissoes.EXCLUIR)
        }
    }

    componentDidMount() {
        if (this.props.tabSelecionada) {
            this.setState({ tabSelecionada: this.props.tabSelecionada })
        }

        const id = this.props.isModal ? this.props.idPessoa : this.props.match.params.id;
        if (validarUUID(id)) {
            this.asyncSelectRegistro(id)
        }
    }

    buscarEstadoCadastro() {
        return this.props.values.id ? estadosCadastro.EDICAO : estadosCadastro.INCLUSAO
    }

    novo() {
        atualizarUrl(this.props.history, CADASTROURL, null, metodosAtualizarUrl.POP)
        this.props.resetForm({ values: initialValue })
        this.habilitarAbasDeAcordoComOTipo(this.props.values.tipo);
    }

    excluir() {
        confirmarExclusao(this.asyncDeleteRegistro);
    }

    async salvar(e, novoOnSuccess) {
        this.props.handleSubmit();
        if (await validarFormulario(this.props)) {
            let { values } = this.props

            if (values.tipo === 'FISICA' && await jaExisteMesmoCpf(values)) {
                confirm('Confirmação', this.montarMensagemConfirm(), () => {
                    this.salvarRegistro(novoOnSuccess)
                })
            } else if (values.tipo === 'JURIDICA' && await jaExisteMesmoCnpj(values)) {
                confirm('Confirmação', this.montarMensagemConfirm(), () => {
                    this.salvarRegistro(novoOnSuccess)
                })
            } else {
                this.salvarRegistro(novoOnSuccess)
            }
        } else {
            this.setState({ ...this.state, tabSelecionada: this.props.errors.tab })
        }
    }

    salvarRegistro(novoOnSuccess) {
        let dadosFormulario = converterPessoaParaApi(this.props.values);
        if (this.props.values.id) {
            this.asyncUpdateRegistro(dadosFormulario, novoOnSuccess);
        } else {
            this.asyncCreateRegistro(dadosFormulario, novoOnSuccess);
        }
    }

    montarMensagemConfirm() {
        if (this.props.values.tipo === 'ESTRANGEIRO') {
            return `Já existe uma pessoa com esta identificação. Tem certeza que deseja salvar?`
        } else if (this.props.values.tipo === 'FISICA') {
            return `Já existe uma pessoa com este CPF. Tem certeza que deseja salvar?`
        } else if (this.props.values.tipo === 'JURIDICA') {
            return `Já existe uma pessoa com este CNPJ. Tem certeza que deseja salvar?`
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.values.id !== prevProps.values.id) {
            this.setState({ ...this.buscarEstadoCadastro() })
        }
    }

    cancelar() {
        if (this.props.dirty) {
            this.props.resetForm({ values: this.props.initialValues })
        } else if (this.props.isModal) {
            this.props.hideModal()
        } else {
            voltarParaAPesquisa(this.props.history, PESQUISAURL)
        }
        this.habilitarAbasDeAcordoComOTipo(this.props.initialValues.tipo);
    }

    async asyncDeleteRegistro() {
        await asyncDeletePessoa(this.props.values.id, () => {
            this.props.resetForm()
            voltarParaAPesquisa(this.props.history, PESQUISAURL);
        })
    }

    async asyncUpdateRegistro(dadosFormulario, novoOnSuccess) {
        await asyncUpdatePessoa(dadosFormulario, () => {
            novoOnSuccess && novoOnSuccess();

            if (this.props.hideModal) {
                this.props.hideModal(this.props.values)
            } else {
                this.props.resetForm({ values: this.props.values })
            }

        })
    }

    async asyncCreateRegistro(dadosFormulario, novoOnSuccess) {
        await asyncCreatePessoa(dadosFormulario, ({ data: pessoa }) => {
            if (novoOnSuccess) {
                novoOnSuccess();
            } else {
                if (this.props.hideModal) {
                    this.props.hideModal({ ...this.props.values, id: pessoa.id })
                } else {
                    this.props.resetForm({ values: { ...this.props.values, id: pessoa.id } })
                    if (!this.props.isModal) {
                        atualizarUrl(this.props.history, CADASTROURL, pessoa.id, metodosAtualizarUrl.POP);
                    }
                }
            }
        })
    }

    async asyncSelectRegistro(idPessoa) {
        await asyncGetPessoa(idPessoa, ({ data: pessoa }) => {
            this.props.resetForm({ values: converterPessoaParaFormulario(pessoa) });
            if (!this.props.isModal) {
                atualizarUrl(this.props.history, CADASTROURL, pessoa.id, metodosAtualizarUrl.POP);
            }
            this.habilitarAbasDeAcordoComOTipo(pessoa.tipo);
        })
    }

    habilitarAbasDeAcordoComOTipo(tipo) {
        this.props.setFieldValue("tipo", tipo);

        if (this.state.tabSelecionada < tabsCadastro.ENDERECOS) {

            switch (tipo) {
                case 'FISICA':
                    this.selecionarTab({ index: tabsCadastro.PESSOA_FISICA })
                    break;
                case 'JURIDICA':
                    this.selecionarTab({ index: tabsCadastro.PESSOA_JURIDICA })
                    break;
                case 'ESTRANGEIRO':
                    this.selecionarTab({ index: tabsCadastro.ESTRANGEIRO })
                    break;
                default: this.selecionarTab({ index: tabsCadastro.ENDERECOS })
            }
        }
    }

    selecionarTab(e) {
        this.setState({ tabSelecionada: e.index })
    }

    render() {

        const informacoesPermissoes = {
            estadoCadastro: this.buscarEstadoCadastro(),
            podeInserir: this.state.podeInserir,
            podeEditar: this.state.podeEditar,
            podeExcluir: this.state.podeExcluir
        };
        const { values, isModal, dirty, setFieldValue } = this.props;
        const { estadoCadastro, podeVisualizarFiscal } = this.state

        let idPessoa = isModal ? this.props.idPessoa : values.id;

        const estadoBotaoNovo = dirty ? estadosBotaoNovo.SALVAR_E_NOVO : estadosBotaoNovo.NOVO;
        const onClickNovo = dirty ? (e) => this.salvar(e, this.novo) : this.novo;
        return (
            <>
                <Prompt dirty={this.props.dirty} isModal={isModal} />
                <Form header="Cadastro de pessoa" isModal={isModal} className="card-default screen-max-width">
                    <If test={this.props.isModal}>
                        <AutoProgressBar />
                    </If>
                    <FormActions className="screen-max-width">
                        <ButtonCancelar
                            {...informacoesPermissoes}
                            estadoBotao={dirty ? estadosBotaoCancelar.CANCELAR : estadosBotaoCancelar.VOLTAR}
                            onClick={this.cancelar}
                        />
                        <ButtonSalvar
                            {...informacoesPermissoes}
                            disabled={!dirty}
                            onClick={() => this.salvar()}
                        />
                        <ButtonNovo
                            onClick={onClickNovo}
                            hidden={(!dirty && !values.id) || isModal}
                            estadoBotao={estadoBotaoNovo}
                            {...informacoesPermissoes}
                        />
                        <ButtonExcluir
                            hidden={!idPessoa || isModal}
                            {...informacoesPermissoes}
                            onClick={this.excluir}
                        />
                        <Button
                            className='p-button-secondary'
                            type='button'
                            label="Opções"
                            icon="fa fa-list"
                            hidden={true}
                            onClick={event => this.menuOpcoes.toggle(event)}
                        />
                    </FormActions>
                    <FormContent>
                        <Grid style={{ width: '100%' }}>
                            <Field sm="12" md="6" lg='4' xl='4'
                                component={InputField}
                                label='Nome'
                                obrigatorio
                                name="nome"
                                helpMessage={helpPessoaForm.nome}
                                size={255}
                                {...informacoesPermissoes}
                            />
                            <Field sm="12" md="6" lg='4' xl='4'
                                component={Dropdown}
                                label='Situação'
                                name="situacao"
                                helpMessage={helpPessoaForm.situacao}
                                showClear={false}
                                onChange={(e) => setFieldValue('situacao', e.value)}
                                options={SITUACAO}
                                {...informacoesPermissoes}
                            />
                            <Field sm="12" md="6" lg='4' xl='4'
                                component={Dropdown}
                                label='Tipo'
                                obrigatorio
                                helpMessage={helpPessoaForm.tipo}
                                name="tipo"
                                showClear={false}
                                onChange={(e) => this.habilitarAbasDeAcordoComOTipo(e.value)}
                                options={PESSOA_TIPO}
                                disabled={this.props.validarIntermediador}
                                {...informacoesPermissoes}
                            />
                        </Grid>
                        <Grid >
                            <TabView
                                className="tab-view-light"
                                onTabChange={(e) => this.selecionarTab(e)}
                                activeIndex={this.state.tabSelecionada}
                                {...informacoesPermissoes}
                            >
                                <TabPanel
                                    header="Pessoa Física"
                                    headerStyle={values.tipo !== 'FISICA' ? { display: 'none' } : null}
                                >
                                    {renderizarFieldsPessoaFisica(values, setFieldValue, informacoesPermissoes)}
                                </TabPanel>
                                <TabPanel
                                    header="Pessoa Jurídica"
                                    headerStyle={values.tipo !== 'JURIDICA' ? { display: 'none' } : null}
                                >
                                    {renderizarFieldsPessoaJuridica(values, setFieldValue, informacoesPermissoes)}
                                </TabPanel>
                                <TabPanel
                                    header="Estrangeiro"
                                    headerStyle={values.tipo !== 'ESTRANGEIRO' ? { display: 'none' } : null}
                                >
                                    {renderizarFieldsEstrangeiro(values, informacoesPermissoes)}
                                </TabPanel>
                                <TabPanel
                                    header="Fiscal"
                                    headerStyle={!podeVisualizarFiscal ? { display: 'none' } : null}
                                >
                                    {renderizarFieldsInformacoesFiscais(values, setFieldValue, informacoesPermissoes)}
                                </TabPanel>
                                <TabPanel header="Endereços">
                                    <PessoaEndereco
                                        value={values.enderecos}
                                        onChange={enderecos => setFieldValue('enderecos', enderecos)}
                                        estadoCadastro={estadoCadastro}
                                        podeEditar={informacoesPermissoes.podeEditar}
                                        {...informacoesPermissoes}
                                    />
                                </TabPanel>
                                <TabPanel header="E-mails">
                                    <PessoaEmail
                                        value={values.emails}
                                        onChange={emails => setFieldValue('emails', emails)}
                                        estadoCadastro={estadoCadastro}
                                        podeEditar={informacoesPermissoes.podeEditar}
                                        {...informacoesPermissoes}
                                    />
                                </TabPanel>
                                <TabPanel header="Telefones">
                                    <PessoaTelefone
                                        value={values.telefones}
                                        onChange={telefones => setFieldValue('telefones', telefones)}
                                        estadoCadastro={estadoCadastro}
                                        podeEditar={informacoesPermissoes.podeEditar}
                                        {...informacoesPermissoes}
                                    />
                                </TabPanel>
                                <TabPanel header="Contatos">
                                    <PessoaContato
                                        value={values.contatos}
                                        onChange={contatos => setFieldValue('contatos', contatos)}
                                        estadoCadastro={estadoCadastro}
                                        podeEditar={informacoesPermissoes.podeEditar}
                                        {...informacoesPermissoes}
                                    />
                                </TabPanel>
                                <TabPanel header="Observação">
                                    {renderizarFieldsObservacao(setFieldValue, informacoesPermissoes)}
                                </TabPanel>
                            </TabView>
                        </Grid>
                    </FormContent>
                </Form>
            </>
        )
    }
}

PessoaForm = withFormik({

    enableReinitialize: true,
    validateOnChange: false,

    mapPropsToValues(props) {
        if (props.validarIntermediador) {
            return {
                ...initialValue,
                tipo: props.tipo
            }
        }
        return initialValue
    },
    validationSchema: Yup.object().shape({
        nome: Yup.string().nullable().required(mensagensDeValidacao.OBRIGATORIO)
    }),

    validate(values, props) {

        let errors = {};

        if (values.tipo === 'FISICA') {
            if (values.cpf && !isValidCpf(manterApenasNumeros(values.cpf)))
                errors.cpf = 'Digite um CPF válido.';

            if (values.nascimento && !isValid(parseISO(values.nascimento)))
                errors.nascimento = mensagensDeValidacao.DATA_INVALIDA;

            errors.tab = tabsCadastro.PESSOA_FISICA;
            return errors;
        }

        if (values.tipo === 'JURIDICA') {
            if (values.cnpj && !isValidCnpj(manterApenasNumeros(values.cnpj))) {
                errors.cnpj = 'Digite um CNPJ válido.';
            }

            if (props.validarIntermediador) {
                if (!values.cnpj) {
                    errors.cnpj = mensagensDeValidacao.OBRIGATORIO;
                }
            }

            errors.tab = tabsCadastro.PESSOA_JURIDICA;
            return errors;
        }

        if (values.tipo === 'ESTRANGEIRO') {

            errors.tab = tabsCadastro.ESTRANGEIRO;
            return errors;
        }

    },

    handleSubmit: () => { },
})(PessoaForm);

export default withRouter(PessoaForm);
