import React, { Component } from "react"
import Modal from "../../../../../components/Modal"
import Planos from "../.."
import { connect } from "react-redux"
import If from "../../../../../components/If"



class ModalPlanos extends Component {

    render() {
        return (
            <Modal
                header='Seu plano chegou ao limite!'
                visible={this.props.visible}
                onHide={this.props.onHide}
                styleModal={{ maxWidth: '1200px'}}
            >
                <If test={this.props.visible}>
                    <Planos
                        onHide={this.props.onHide}
                        isModal={true}
                        mensagemAviso={this.props.mensagemErroPlanoViolado}
                    />
                </If>
            </Modal>
        )
    }
}

const mapStateToProps = state => ({
    mensagemErroPlanoViolado: state.planoViolado.mensagemErroPlanoViolado,
})

export default connect(mapStateToProps)(ModalPlanos)