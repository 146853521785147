import React, { Component } from "react";
import { Column } from "primereact/components/column/Column";
import { asyncDeletarUsuario, asyncDesbloquearCredencial, asyncBloquearCredencial } from "./requests";
import Button from "../../../../../components/Button";
import { confirm, alert } from "../../../../../components/Toast";
import { buscarDadosLoginLocalStorage } from "../../../../../common/autenticacao";
import ButtonExcluirTable from "../../../../../components/Button/ButtonExcluirTable";
import autobind from "react-autobind";
import Message from "../../../../../components/Message";
import FormGroup from "../../../../../components/FormGoup";
import { removerElemento } from "../../../../../common/array";
import { DataTable } from "primereact/components/datatable/DataTable";
import { Checkbox } from "primereact/checkbox";
import NenhumRegistroEncontrado from "../../../../../components/NenhumRegistroEncontrado";
import { Badge } from "../../../../../components/Badge";

const styleButton = {
    borderRadius: '50%',
    padding: '5px',
    width: '30px',
    height: '30px',
    margin: '2px'
};

const Colors = {
    kBgLightGreen: '#dcedc8',
    kTextGreen: '#1b5e20',
    kBgLightRed: '#ffcdd2',
    kTextRed: '#b71c1c'
}

class CredenciaisDeUsuario extends Component {
    constructor(props) {
        super(props);
        autobind(this);

        this.state = {
            registros: null,
            cadastrarNovoUsuario: false,
            emailUsuarioLogado: buscarDadosLoginLocalStorage().email,
            exibirUsuariosBloqueados: false
        };
    }

    componentDidUpdate() {
        const { idOrganizacao, credenciais } = this.props;

        if (idOrganizacao !== this.state.idOrganizacao) {
            this.setState({
                idOrganizacao: idOrganizacao,
                registros: credenciais || null,
            });
        }
    }

    excluirUsuario(registro) {
        if (registro) {
            confirm("Confirmação", "Tem certeza que deseja excluir o usuário " + registro.email + "?", async () => {
                await asyncDeletarUsuario(this.props.idOrganizacao, registro.id, () => {
                    this.props.onChange(removerElemento(this.props.credenciais, registro))
                })
            })
        }
    }

    renderContaConfirmada(row) {
        if (row.ativa) {
            return Badge(Colors.kTextGreen, Colors.kBgLightGreen, 'Efetuada');
        } else {
            return Badge(Colors.kTextRed, Colors.kBgLightRed, 'Pendente');
        }
    }

    renderContaBloqueada(row) {
        if (row.bloqueada) {
            return Badge(Colors.kTextRed, Colors.kBgLightRed, 'Bloqueada');
        } else {
            return Badge(Colors.kTextGreen, Colors.kBgLightGreen, 'Desbloqueada');
        }
    }

    renderizarOpcoes(row) {
        if (row) {
            const { podeEditar } = this.props;
            return (
                <>
                    <ButtonExcluirTable
                        onClick={() => this.excluirUsuario(row)}
                        podeExcluir={podeEditar}
                        disabled={row.ativa}
                        title={row.ativa ? "Não é possível excluir usuários já confirmados." : undefined}
                    />
                    <Button
                        style={styleButton}
                        color="danger"
                        title="Bloquear o usuário"
                        icon="fa fa-lock"
                        onClick={() => this.bloquearUsuarioSelecionado(row)}
                        hidden={row.bloqueada}
                        disabled={(row && row.email === this.state.emailUsuarioLogado) || !podeEditar}
                    />
                    <Button
                        style={styleButton}
                        color="success"
                        title="Desbloquear o usuário"
                        icon="fa fa-unlock"
                        disabled={!podeEditar}
                        onClick={() => this.desbloquearUsuarioSelecionado(row)}
                        hidden={!row.bloqueada}
                    />
                </>
            );
        }
    }



    bloquearUsuarioSelecionado(row) {
        if (this.state.emailUsuarioLogado !== row.email) {
            this.confirmarBloqueioUsuario(row)
        } else {
            alert('Confirmação', 'Não é possível bloquear-se. Entre com outro usuário para isto.');
        }
    }

    confirmarBloqueioUsuario(row) {
        confirm('Confirmação', 'Tem certeza que deseja bloquear o usuário ' + row.email + '?', async () => {
            await asyncBloquearCredencial(this.props.idOrganizacao, row.id, () => {
                const { credenciais } = this.props;

                const values = credenciais.map(credencial => {
                    if (row.id === credencial.id) {
                        credencial.bloqueada = true;
                    }
                    return credencial
                })
                this.props.onChange(values)
            })
        })
    }

    desbloquearUsuarioSelecionado(row) {
        confirm('Confirmação', 'Tem certeza que deseja desbloquear o usuário ' + row.email + '?', async () => {
            await asyncDesbloquearCredencial(this.props.idOrganizacao, row.id, () => {
                const { credenciais } = this.props;

                const values = credenciais.map((credencial) => {
                    if (row.id === credencial.id) {
                        credencial.bloqueada = false;
                    }
                    return credencial
                })
                this.props.onChange(values)
            })
        })
    }

    render() {
        const { idOrganizacao, credenciais } = this.props;
        const { exibirUsuariosBloqueados } = this.state;

        if (!idOrganizacao) {
            return (
                <Message
                    severity="info"
                    colStyle={{ padding: '0px' }}
                    text="Para cadastrar os usuários é necessário salvar a organização"
                />
            );
        }

        const credenciaisFiltradas = credenciais && exibirUsuariosBloqueados ? credenciais : credenciais.filter(credencial => !credencial.bloqueada)

        return (
            <>
                <FormGroup>
                    <DataTable
                        className="table-light"
                        responsive
                        value={credenciaisFiltradas}
                        emptyMessage={<NenhumRegistroEncontrado />}
                        header={(
                            <span style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <Checkbox
                                    inputId="exibirUsuariosBloqueados"
                                    name="exibirUsuariosBloqueados"
                                    onChange={e => this.setState({ exibirUsuariosBloqueados: !exibirUsuariosBloqueados })}
                                    checked={exibirUsuariosBloqueados}
                                />
                                <label htmlFor="exibirUsuariosBloqueados" className="p-checkbox-label">
                                    Exibir bloqueados
                                </label>
                            </span>
                        )}
                    >
                        <Column
                            field="email"
                            header="E-mail"
                            sortable={true}
                        />
                        <Column
                            field="ativa"
                            body={this.renderContaConfirmada}
                            header="Confirmação"
                            sortable={true}
                        />
                        <Column
                            field="bloqueada"
                            body={this.renderContaBloqueada}
                            header="Situação"
                            sortable={true}
                        />
                        <Column
                            header="Ações"
                            body={this.renderizarOpcoes}
                            style={{ width: '100px' }}
                        />
                    </DataTable>
                </FormGroup>
            </>
        );
    }
}

export default CredenciaisDeUsuario
