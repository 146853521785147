import React, { Component } from 'react';
import { estadosCadastro } from '../../../../../common/constantes/autorizacao';
import { removerElemento, copiarObjeto } from '../../../../../common/array';
import { Column } from 'primereact/column';
import ButtonExcluirTable from '../../../../../components/Button/ButtonExcluirTable';
import ToolbarDetails from '../../../../../components/Toolbar';
import ButtonNovo from '../../../../../components/Button/ButtonNovo';
import autoBind from 'react-autobind';
import ToolbarItensLeft from '../../../../../components/Toolbar/ToolbarItensLeft';
import Modal from '../../../../../components/Modal';
import PessoaEmailForm from './Form';
import If from '../../../../../components/If';
import ButtonEditarTable from '../../../../../components/Button/ButtonEditarTable';
import { jaExisteMesmoEmail } from './util/validacoes.js';
import { GoBookmark } from 'react-icons/go';
import { DataTable } from 'primereact/components/datatable/DataTable';
import NenhumRegistroEncontrado from '../../../../../components/NenhumRegistroEncontrado';

class PessoaEmail extends Component {

    constructor(props) {
        super(props);
        autoBind(this);

        this.state = {
            registroSelecionado: null,
            modalFormVisible: false
        }
    }

    novo() {
        this.setState({ modalFormVisible: true, registroSelecionado: null })
    }

    onEditar(row) {
        this.setState({ registroSelecionado: row, modalFormVisible: true })
    }

    renderOpcoes(row) {
        const { estadoCadastro } = this.props
        const desabilitarCampos = (estadoCadastro === estadosCadastro.EDICAO && !this.props.podeEditar);

        return (
            <React.Fragment>
                <ButtonEditarTable
                    onClick={() => this.onEditar(row)}
                />
                <ButtonExcluirTable
                    onClick={() => this.excluirRegistro(row)}
                    podeExcluir={!desabilitarCampos} />
            </React.Fragment>
        )
    }
    hideModal() {
        this.setState({ modalFormVisible: false, registroSelecionado: null })
    }


    atualizarRegistro(dadosFormulario) {
        const emails = copiarObjeto(this.props.value);
        const result = emails && emails.map(item => {
            if (item.idTemporario && item.idTemporario === dadosFormulario.idTemporario) {
                return dadosFormulario
            } else if (item.id && item.id === dadosFormulario.id) {
                return dadosFormulario
            }

            if (dadosFormulario.favorito) {
                item.favorito = false
            }

            return item;
        })
        this.setState({ registroSelecionado: dadosFormulario })
        this.props.onChange(result)
    }

    inserirRegistro(dadosFormulario) {
        let emails = copiarObjeto(this.props.value)
        this.props.onChange(this.mergeEmailIntoEmails(dadosFormulario, emails));
        this.setState({ registroSelecionado: dadosFormulario })
    }

    mergeEmailIntoEmails(email, emails) {
        if (email.favorito) {
            emails.map((tempEmail) => {
                return tempEmail.favorito = false
            })
        }
        return [...emails, email]
    }

    excluirRegistro(dadosFormulario) {
        this.props.onChange(removerElemento(this.props.value, dadosFormulario))
        this.setState({ registroSelecionado: null })
    }

    atualizarRegistroSelecionado(registro) {
        this.setState({ registroSelecionado: registro })
    }

    validarMesmoEmail(email) {
        return jaExisteMesmoEmail(this.props.value, email);
    }

    marcarComoFavorito() {
        const emails = copiarObjeto(this.props.value)
        let existemFavoritos = false;

        emails.forEach(email => {
            if (email.favorito) {
                existemFavoritos = true
            }
        });

        return !existemFavoritos
    }

    renderEmail(row) {
        return (
            <span style={{ display: "flex", alignItems: "center" }}>
                {row.favorito ? <span> <GoBookmark size={22} color="#fbc02d" title="E-mail favorito" /> </span> : null}
                {row.email}
            </span>
        )
    }

    render() {

        const { estadoCadastro, podeEditar, value } = this.props;

        const desabilitarCampos = (estadoCadastro === estadosCadastro.EDICAO && !podeEditar);

        return (
            <React.Fragment>
                <Modal
                    visible={this.state.modalFormVisible}
                    header='Cadastro de e-mail'
                    onHide={this.hideModal}
                >
                    <If test={this.state.modalFormVisible}>
                        <PessoaEmailForm
                            registroSelecionado={this.state.registroSelecionado}
                            onNovoClick={() => this.setState({ registroSelecionado: null })}
                            inserirRegistro={this.inserirRegistro}
                            atualizarRegistro={this.atualizarRegistro}
                            excluirRegistro={this.excluirRegistro}
                            marcarComoFavorito={this.marcarComoFavorito}
                            podeEditar={!desabilitarCampos}
                            podeExcluir={!desabilitarCampos}
                            onHide={this.hideModal}
                            validarMesmoEmail={this.validarMesmoEmail}
                        />
                    </If>
                </Modal>
                <ToolbarDetails>
                    <ToolbarItensLeft style={{ paddingLeft: '0px' }}>
                        <ButtonNovo
                            label="Novo e-mail"
                            enableShortCut={false}
                            onClick={this.novo}
                            podeInserir={!desabilitarCampos}
                        />
                    </ToolbarItensLeft>
                </ToolbarDetails>
                <DataTable
                    className="table-light"
                    responsive
                    value={value}
                    emptyMessage={<NenhumRegistroEncontrado message="Nenhum e-mail encontrado" />}
                >
                    <Column
                        field="email"
                        body={this.renderEmail}
                        header="E-mail"
                        sortable={true}
                    />
                    <Column
                        body={this.renderOpcoes}
                        header="Ações"
                        style={{ width: '7em' }}
                    />
                </DataTable>
            </React.Fragment>
        )
    }
}

export default PessoaEmail
