import React, { useState } from 'react'
import { Button } from 'primereact/components/button/Button';
import { InputText } from 'primereact/components/inputtext/InputText';
import propTypes from 'prop-types'
import Col from '../../Col';
import { removerCaracteresInvalidosRsql } from '../../../common/rsql';

export default function InputSearch(props) {

    const [time, setTime] = useState(null);

    function onKeyDown(e) {
        if (props.onKeyDown)
            props.onKeyDown(e)
        else if (e.key === 'Enter') {
            clearTimeout(time)
            props.onPesquisar()
        }

    }

    function onClickPesquisar() {
        clearTimeout(time)
        props.onPesquisar()
    }

    function handleChangePesquisa(e) {
        clearTimeout(time)
        setTime(setTimeout(() => props.onPesquisar(), 500))
        props.onChange(removerCaracteresInvalidosRsql(e.target.value))
    }

    async function limparEPesquisar() {
        clearTimeout(time)
        await props.onChange('')
        props.onPesquisar()
    }

    const { placeholder, name, className, value, disabled, sm, md, lg, xl } = props
    return (
        <React.Fragment>
            <Col sm={sm} md={md} lg={lg} xl={xl}>
                <div className="p-inputgroup">
                    <InputText
                        type="text"
                        className={className}
                        placeholder={placeholder}
                        name={name}
                        style={{
                            width: '100%',
                            marginRight: '-33px'
                        }}
                        onKeyDown={onKeyDown}
                        value={value}
                        disabled={disabled}
                        onChange={handleChangePesquisa}
                    />
                    <Button
                        type="button"
                        className='p-button-secondary'
                        style={{
                            backgroundColor: '#fff0',
                            border: 'none',
                            visibility: !value ? 'hidden' : 'inherit',
                            zIndex: 1
                        }}
                        icon='fa fa-times'
                        onClick={limparEPesquisar}
                    />
                    <Button
                        type="button"
                        icon='fa fa-search'
                        disabled={disabled}
                        onClick={onClickPesquisar}
                    />
                </div>
            </Col>
        </React.Fragment>
    )
}
InputSearch.propTypes = {
    /** Placeholder da pesquisa */
    placeholder: propTypes.string,
    /** Nome do campo de pesquisa */
    name: propTypes.string,
    /** Classificacao da pesquisa */
    className: propTypes.string,
    /** Evento disparado ao teclar */
    onKeyDown: propTypes.func,
    /** Valor do componente */
    value: propTypes.any,
    /** Evento onChange*/
    onChange: propTypes.func,
    /** Evento clicar no pesquisa */
    onPesquisar: propTypes.func,
    /** Componente desabilitado */
    disabled: propTypes.bool,
    /** Tamanho do campo em small devices*/
    sm: propTypes.string,
    /** Tamanho do campo em medium devices*/
    md: propTypes.string,
    /** Tamanho do campo em large devices*/
    lg: propTypes.string,
    /** Tamanho do campo em extra large devices*/
    xl: propTypes.string,
    /**Define se o componente está carregando */
    loading: propTypes.bool
}
InputSearch.defaultProps = {
    placeholder: 'Digite algo para pesquisar...',
    sm: '12',
    md: '7',
    lg: '6',
    xl: '5'
}