import React, { Component } from "react";
import autoBind from 'react-autobind';
import Grid from "../../../../../components/Grid";
import { buttonAlterarStyle, buttonRemoverCartaoStyle, detalhesCardStyle } from "../../styles";
import { helpMeuCartao } from "./help";
import TituloCard from "../../../../../components/TituloCard";

const cardStyle = {
    padding: '7px',
    height: '100%'
}

class CardMeuCartao extends Component {

    constructor(props) {
        super(props)
        autoBind(this);
    }

    renderizarDetalhesCard() {
        const { numero, titular, expiracao } = this.props.informacoesCartao
        const { planoAtual } = this.props;

        if (numero == null) {
            return (
                <p style={(planoAtual && (planoAtual.tipo === "EXCLUSIVO" && planoAtual.meioPagamento !== "CARTAO_CREDITO")) ? { ...detalhesCardStyle, opacity: 0.5 } : detalhesCardStyle}>
                    {`Não há cartão de crédito configurado.`} <br />
                </p>
            )
        }

        return (
            <p style={(planoAtual && (planoAtual.tipo === "EXCLUSIVO" && planoAtual.meioPagamento !== "CARTAO_CREDITO")) ? { ...detalhesCardStyle, opacity: 0.5 } : detalhesCardStyle}>
                {`Número: ${numero}`} <br />
                {`Titular: ${titular}`} <br />
                {`Data de expiração: ${expiracao}`}
            </p>
        )
    }

    renderExcluir() {

        const { podeEditar, informacoesCartao, onRemoverCartaoClick } = this.props;
        const podeRemoverCartao = podeEditar && informacoesCartao.numero;

        if (podeRemoverCartao) {
            return (
                <Grid directionColumn justifyEnd style={{ height: '100%' }}>
                    <span
                        title={!podeEditar ? 'Você não possui permissão para editar' : ''}
                        style={podeEditar ? buttonRemoverCartaoStyle : { ...buttonRemoverCartaoStyle, opacity: 0.5 }}
                        onClick={() => onRemoverCartaoClick()}
                    >
                        Remover meu cartão de crédito
                    </span>
                </Grid>
            )
        }
    }

    render() {
        const { className, podeEditar, planoAtual } = this.props;
        const pagamentoCartao = planoAtual && (planoAtual.meioPagamento === "CARTAO_CREDITO")

        return (
            <div className={"card-default " + className} style={(planoAtual && !pagamentoCartao) ? { ...cardStyle, opacity: 0.5 } : cardStyle}>
                <TituloCard
                    title='Meu cartão de crédito'
                    helpMessage={helpMeuCartao.meuCartao}

                />
                <div style={{ height: 'calc(100% - 23px)', display: 'flex', justifyContent: "space-between", flexDirection: 'column' }}>
                    {this.renderizarDetalhesCard()}
                    <Grid directionColumn justifyEnd style={{ height: '100%' }}>
                        <span
                            title={!podeEditar ? 'Você não possui permissão para editar' : ''}
                            style={podeEditar ? buttonAlterarStyle : { ...buttonAlterarStyle, opacity: 0.5 }}
                            onClick={podeEditar && pagamentoCartao ? this.props.onAlterarClick : null}
                        >
                            Alterar cartão de crédito
                        </span>
                    </Grid>
                    {this.renderExcluir()}
                </div>
            </div>
        );
    }
}

export default CardMeuCartao;
