import { tipoCampos } from "../../../../components/PesquisaAvancada/util/constantes"

export const optionsFiltroAvancado = [
    { label: 'Código (SKU)', name: 'sku', type: tipoCampos.STRING },
    { label: 'Nome', name: 'nome', type: tipoCampos.STRING },
    {
        label: 'Situação', name: 'situacao', type: tipoCampos.SELECT,
        optionSelect: [
            { label: "Ativo", value: "ATIVO" },
            { label: "Inativo", value: "INATIVO" },
        ]
    },
    {
        label: 'Tipo', name: 'tipo', type: tipoCampos.SELECT,
        optionSelect: [
            { label: "Produto", value: "PRODUTO" },
            { label: "Serviço", value: "SERVICO" },
        ]
    },
    {
        label: 'Controlar estoque', name: 'controlarEstoque', type: tipoCampos.SELECT,
        optionSelect: [
            { label: "Sim", value: "true" },
            { label: "Não", value: "false" },
        ]
    },
    { label: 'Preço', name: 'preco', type: tipoCampos.DECIMAL },
]
