export function buscarUltimoParametroDaUrl(url) {
    const urlCortada = url.split("/")
    return urlCortada[urlCortada.length - 1]
}


export function removerCaracteres(valor, caracteres) {
    if (!valor) {
        return valor;
    }
    const expressaoRegular = "[" + caracteres.join("\\") + "]"
    const regexp = new RegExp(expressaoRegular, 'g')
    return valor.replace(regexp, '');
}


export function removerAcentos(valor) {
    let resultado = valor

    resultado = resultado.replace(/[ÀÁÂÃÄÅ]/g, "A")
    resultado = resultado.replace(/[àáâãäå]/g, "a")
    resultado = resultado.replace(/[ÈÉÊË]/g, "E")
    resultado = resultado.replace(/[éèê]/g, "e")
    resultado = resultado.replace(/[ÍÌ]/g, "I")
    resultado = resultado.replace(/[íì]/g, "i")
    resultado = resultado.replace(/[ÓÒÔÔ]/g, "O")
    resultado = resultado.replace(/[óòôõ]/g, "o")
    resultado = resultado.replace(/[ÚÙÛ]/g, "U")
    resultado = resultado.replace(/[ç]/g, "c")
    resultado = resultado.replace(/[Ç]/g, "C")

    return resultado
}

export function formatarParaPesquisarTiposEnumerados(valor) {
    let resultado = removerAcentos(valor)
    resultado = resultado.replace(/[/ -]/g, "_")
    return resultado
}

export function formatarParaPesquisarParaCamposBoolean(valorDigitado, valorEsperadoParaTrue, valorEsperadoParaFalse) {
    let resultado = String(removerAcentos(valorDigitado)).toLowerCase();

    if (String(valorEsperadoParaTrue).toLowerCase().includes(resultado)) {
        return true;
    } else if (String(valorEsperadoParaFalse).toLowerCase().includes(resultado)) {
        return false;
    }
}

export function validarUUID(uuid) {
    //https://stackoverflow.com/questions/7905929/how-to-test-valid-uuid-guid
    return /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/.test(uuid);
}

export function validarEmail(email) {
    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

export function gerarUUID() {
    return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
        //eslint-disable-next-line
        (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    )
}

export function buscarDescricaoTamanhoArquivo(bytes) {
    var sizes = ['B','KB','MB','GB','TB','PB','HB','YB'];

    if (bytes === 0) return '0 Byte';
    var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
};

