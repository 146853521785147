import { get } from '../../../../../../../../common/requisicoes';
import { services } from '../../../../../../../../common/constantes/api';
import { formatISO } from 'date-fns';

export function asyncGetBalancoDiario(dataInicial, dataFinal, onSuccess, onError) {
    return get(`${services.GESTOR}/v1/dashboards/financas/balanco_por_dia?dataInicial=${formatISO(dataInicial, {representation: 'date'}) }&dataFinal=${formatISO(dataFinal, {representation: 'date'})}`, null, onSuccess, onError, false);
}

export function asyncValorEstoquePorPeriodo(dataInicial, dataFinal, onSuccess, onError) {
    return get(`${services.GESTOR}/v1/dashboards/estoque/custo_estoque_por_periodo?dataInicial=${formatISO(dataInicial, {representation: 'date'}) }&dataFinal=${formatISO(dataFinal, {representation: 'date'})}`, null, onSuccess, onError, false);
}
