import { OverlayPanel } from 'primereact/overlaypanel';
import React, { Component } from 'react';
import { MdDescription } from 'react-icons/md';
import { InternalTextArea } from '../../../../../../../../../../components/TextArea';

class DescricaoMemorizada extends Component {
    render() {
        const {
            valueDescricao, onChangeDescricao,
            informacoesPermissoes, exibirLabels,
            isMobile
        } = this.props;

        return (
            <>
                <OverlayPanel style={isMobile ? { right: '12px' } : null} ref={(el) => this.panelDetalhes = el} showCloseIcon>
                    <InternalTextArea
                        helpMessage="Detalhes gerais da parcela"
                        type="text"
                        label="Detalhes da parcela"
                        name="descricao"
                        value={valueDescricao}
                        size={200}
                        onChange={onChangeDescricao}
                        {...informacoesPermissoes}
                    />
                </OverlayPanel>
                <button
                    onClick={e => this.panelDetalhes.toggle(e)}
                    style={{
                        marginTop: exibirLabels && !isMobile ? "20px" : '0px',
                        padding: '0px',
                        width: '30px',
                        height: '30px',
                        background: 'none',
                        border: 'none',
                        cursor: 'pointer',
                    }}
                >
                    <MdDescription
                        title='Detalhes da parcela'
                        size={30}
                        style={{ color: '#316095', padding: '5px' }}
                    />
                </button>
            </>
        )
    }
}

function descricaoMemorizadoSaoIguais(renderAnterior, renderAtual) {
    return renderAnterior.exibirLabels === renderAtual.exibirLabels &&
        renderAnterior.isMobile === renderAtual.isMobile &&
        renderAnterior.valueDescricao === renderAtual.valueDescricao

}

export default React.memo(DescricaoMemorizada, descricaoMemorizadoSaoIguais);
