import React, { Component } from 'react';
import { InternalInputDouble } from '../../../../../../../../components/Input/InputDouble';
import Grid from '../../../../../../../../components/Grid';
import Col from '../../../../../../../../components/Col';
import { FaTimes } from 'react-icons/fa';
import { services } from '../../../../../../../../common/constantes/api';
import { MdDescription } from 'react-icons/md';
import { OverlayPanel } from 'primereact/overlaypanel';
import { InternalTextArea } from '../../../../../../../../components/TextArea';
import ProdutoComEstoqueDisponivel from './components/ProdutoComEstoqueDisponivel';
import QuantidadeComEstoqueDisponivel from './components/QuantidadeComEstoqueDisponivel';
import autoBind from 'react-autobind'
import { usuarioPossuiModulo } from '../../../../../../../../common/autenticacao';
import { modulos } from '../../../../../../../../common/constantes/autorizacao';

const gridMobileStyle = {
    margin: '0px -12px',
    padding: '5px'
}

class ProdutosMemorizados extends Component {

    constructor(props) {
        super(props);
        autoBind(this);
        this.state = {
            possuiModuloEstoque: usuarioPossuiModulo(modulos.ESTOQUE),
            estoqueExcedido: false
        }
    }

    produtoExcedeuEstoque() {
        const { valueProduto, initialItens, itens } = this.props;
        if (!this.exibirEstoqueDoProduto()) {
            return false;
        }
        let estoqueSaldo = valueProduto.registro.estoqueSaldo
        initialItens.forEach((initialItem => {
            if (initialItem.produto && initialItem.produto.value === valueProduto.value) {
                estoqueSaldo += initialItem.quantidade
            }
        }))

        let quantidadeDestaVenda = 0
        itens.forEach((item => {
            if (item.produto && item.produto.value === valueProduto.value) {
                quantidadeDestaVenda += item.quantidade
            }
        }))
        const novoSaldo = estoqueSaldo - quantidadeDestaVenda

        return novoSaldo < 0
    }

    exibirEstoqueDoProduto() {
        const { valueProduto, status } = this.props;
        const { possuiModuloEstoque } = this.state
        return status === "VENDA" && valueProduto && possuiModuloEstoque && valueProduto.registro.controlarEstoque && valueProduto.registro.tipo === 'PRODUTO'
    }

    componentDidUpdate(prevProps) {
        if (prevProps.dirty !== this.props.dirty) {
            this.setState({ estoqueExcedido: false })
        }
    }

    async onChangeQuantidade(e) {
        await this.props.onChangeQuantidade(e)
        this.setState({ estoqueExcedido: this.produtoExcedeuEstoque() && this.props.dirty })
    }

    async onChangeProduto(e) {
        await this.props.onChangeProduto(e)
        this.setState({ estoqueExcedido: this.produtoExcedeuEstoque() && this.props.dirty })
    }

    render() {

        const {
            valueProduto,
            onChangeDescricao,
            valueDescricao,
            valueQuantidade,
            onChangeValor,
            valueValor,
            onClickExcluir,
            exibirLabels,
            isMobile,
            errors,
            excluirDisabled,
            backgroundColor,
            index,
            informacoesPermissoes,
            existeParcelaRecebida,
            options,
            onChangeOptions,
            loadInitialValues,
        } = this.props

        const gridStyle = isMobile ? { ...gridMobileStyle, backgroundColor } : { backgroundColor, padding: '7px 0px', minHeight: '65px' }
        const styleButtonExcluir = {
            marginTop: exibirLabels && '19px',
            color: '#e91224',
            cursor: excluirDisabled || !informacoesPermissoes.podeEditar ? 'default' : 'pointer',
            padding: '5px',
            opacity: excluirDisabled || !informacoesPermissoes.podeEditar ? '0.5' : null
        };

        const excedeuEstoque = this.state.estoqueExcedido;
        return (
            <>
                <OverlayPanel style={isMobile ? { right: '12px' } : null} ref={(el) => this.panelDetalhes = el} showCloseIcon>
                    <InternalTextArea
                        id={`id-produto-${index}`}
                        helpMessage="Detalhes gerais do item, estes detalhes serão disponibilizados na impressão do pedido"
                        type="text"
                        label="Detalhes do item"
                        name="descricao"
                        value={valueDescricao}
                        size={4096}
                        onChange={onChangeDescricao}
                        disabled={existeParcelaRecebida || !informacoesPermissoes.podeEditar}
                        {...informacoesPermissoes}
                    />
                </OverlayPanel>

                <Grid style={gridStyle} verticalAlignCenter>
                    <ProdutoComEstoqueDisponivel
                        colStyle={{ padding: '0px' }}
                        sm="5" md="6" lg='6' xl='6'
                        exibirLabels={exibirLabels}
                        url={`${services.GESTOR}/v1/vendas/relacoes/produtos`}
                        label={exibirLabels ? 'Produto ou serviço' : ""}
                        helpMessage={exibirLabels ? "Produto ou serviço vendido" : null}
                        name="produto"
                        onChange={this.onChangeProduto}
                        value={valueProduto}

                        mostrarInformacaoDoEstoque={this.exibirEstoqueDoProduto()}

                        options={options}
                        loadInitialValues={loadInitialValues}
                        onChangeOptions={onChangeOptions}

                        placeholder=''
                        touched
                        isMobile={isMobile}
                        disabled={existeParcelaRecebida}
                        errors={errors ? errors.produto : ""}
                        informacoesPermissoes={informacoesPermissoes}
                    />

                    <QuantidadeComEstoqueDisponivel
                        col='5' sm="3" md="3" lg='3' xl='2'
                        colStyle={{ padding: '0px' }}
                        exibirLabels={exibirLabels}
                        label={exibirLabels ? "Quantidade" : ""}
                        size={13}
                        className={(!errors && excedeuEstoque) ? "p-input-warning" : ""}
                        produtoExcedeuEstoque={excedeuEstoque}
                        helpMessage={exibirLabels ? "Quantidade do produto ou serviço vendido" : null}
                        value={valueQuantidade}
                        onChange={this.onChangeQuantidade}
                        decimalScale={2}
                        allowNegative={false}
                        touched
                        disabled={existeParcelaRecebida}
                        errors={errors ? errors.quantidade : ""}
                        informacoesPermissoes={informacoesPermissoes}
                    />

                    <Col col='7' sm="4" md="3" lg='3' xl='4' style={{ padding: '0px 4px', minHeight: exibirLabels ? '73px' : '55px' }}>
                        <Grid style={{ margin: '0px' }}>
                            <InternalInputDouble
                                colStyle={{ width: 'calc(100% - 60px)', padding: '0px' }}
                                label={exibirLabels ? 'Valor' : ""}
                                name="valor"
                                helpMessage={exibirLabels ? "Valor unitário do produto ou serviço vendido" : null}
                                value={valueValor}
                                onChange={onChangeValor}
                                size={13}
                                prefix='R$ '
                                allowNegative={false}
                                touched
                                errors={errors ? errors.valor : ""}
                                disabled={existeParcelaRecebida}
                                {...informacoesPermissoes}
                            />
                            <button
                                onClick={(e) => {
                                    this.panelDetalhes.toggle(e)
                                    document.getElementById(`id-produto-${index}`).focus()
                                }}
                                style={{
                                    marginTop: exibirLabels ? '19px' : "0px",
                                    padding: '0px',
                                    width: '30px',
                                    height: '30px',
                                    background: 'none',
                                    border: 'none',
                                    cursor: 'pointer',
                                }}
                            >
                                <MdDescription
                                    title='Detalhes do item'
                                    size={30}
                                    style={{
                                        color: '#316095',
                                        padding: '5px',
                                    }}
                                />
                            </button>
                            <FaTimes
                                size={30}
                                title={excluirDisabled ? 'A venda ou orçamento deve possuir ao menos um item' : 'Excluir item'}
                                style={styleButtonExcluir}
                                onClick={() => excluirDisabled || !informacoesPermissoes.podeEditar ? null : onClickExcluir()}
                            />
                        </Grid>
                    </Col>
                </Grid>
            </>
        )
    }
}

function produtosMemorizadosSaoIguais(renderAnterior, renderAtual) {
    if ((renderAnterior.valueProduto && !renderAtual.valueProduto) || (!renderAnterior.valueProduto && renderAtual.valueProduto)) {
        return false
    } else if (renderAnterior.valueProduto && renderAtual.valueProduto) {
        if (renderAnterior.valueProduto.value !== renderAtual.valueProduto.value ||
            renderAnterior.valueProduto.registro.estoqueSaldo !== renderAtual.valueProduto.registro.estoqueSaldo) {
            return false
        }
    }
    return renderAnterior.valueQuantidade === renderAtual.valueQuantidade &&
        renderAnterior.valueDescricao === renderAtual.valueDescricao &&
        renderAnterior.valueValor === renderAtual.valueValor &&
        renderAnterior.errors === renderAtual.errors &&
        renderAnterior.excluirDisabled === renderAtual.excluirDisabled &&
        renderAnterior.isMobile === renderAtual.isMobile &&
        renderAnterior.existeParcelaRecebida === renderAtual.existeParcelaRecebida &&
        renderAnterior.options === renderAtual.options
}

export default React.memo(ProdutosMemorizados, produtosMemorizadosSaoIguais);