export const helpPlanosForm = {
    ilimitado: "Quando verdadeiro, o sistema não validará quantidade de uso, será totalmente liberado para uso.",
    suporteChat: "Quando verdadeiro, a organização com esse plano poderá entrar em contato com o suporte através do chat do Tomtiket.",
    descricao: "Identificador do plano para seleção.",
    usuarios: "Define a quantidade de usuários que poderão ser criados.",
    armazenamento: "Define a quantidade que o usuário terá de armazenamento.",
    documentosMensais: "Define a quantidade de documento que poderá ser inserido no sistema.",
    contasFinanceiras: "Define a quantidade de contas financeiras que poderá ser inserido no sistema.",
    nfeMensais: "Define a quantidade de NF-e que poderá ser inserido no sistema.",
    vendasMensais: "Define a quantidade de vendas que poderá ser inserido no sistema.",
    valor: "Valor da mensalidade cobrado pelo plano."
}
