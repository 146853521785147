import React, { Component } from 'react';
import Grid from '../../../../../../../../components/Grid';
import Col from '../../../../../../../../components/Col';
import autoBind from 'react-autobind';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/components/datatable/DataTable';
import { format, parseISO } from 'date-fns';
import { formatarMonetario } from '../../../../../../../../common/mascara';
import { usuarioPossuiPermissao } from '../../../../../../../../common/autenticacao';
import { recursos, permissoes } from '../../../../../../../../common/constantes/autorizacao';
import If from '../../../../../../../../components/If';
import ModalEfetuarRecebimento from '../ModalEfetuarRecebimento';
import { contaReceberStatus } from '../../../utils/constantes';
import { colorsStatus } from '../../../../util/constantes';
import { formaPagamento } from '../utils/constantes';
import { Button } from 'primereact/button';
import NenhumRegistroEncontrado from '../../../../../../../../components/NenhumRegistroEncontrado';

class TabelaRecebimentos extends Component {
    constructor(props) {
        super(props);

        autoBind(this);

        this.state = {
            podeEditarVendas: usuarioPossuiPermissao(recursos.VENDAS_VENDAS, permissoes.EDITAR),
            podeInserirContaReceber: usuarioPossuiPermissao(recursos.FINANCAS_CONTAS_RECEBER, permissoes.INSERIR),
            podeVisualizarContaReceber: usuarioPossuiPermissao(recursos.FINANCAS_CONTAS_RECEBER, permissoes.VISUALIZAR),
        }
    }

    renderAcoesField(el) {
        const { podeVisualizarContaReceber, podeEditarVendas } = this.state;
        const { isMobile } = this.props
        const recebida = Boolean(el.status === contaReceberStatus.RECEBIDA);
        const naoRecebida = Boolean(el.status === contaReceberStatus.NAO_RECEBIDA);
        const parcialmenteRecebida = Boolean(el.status === contaReceberStatus.PARCIALMENTE_RECEBIDA);

        const disableBotoes = !podeEditarVendas || !podeVisualizarContaReceber;

        let style = {}

        if (!isMobile) {
            style = {
                display: 'flex',
                justifyContent: 'flex-end'
            }
        }

        return (
            <span style={style}>
                <If test={recebida || parcialmenteRecebida}>
                    <Button
                        className="p-button p-button-secondary"
                        style={{ borderRadius: '20px', marginRight: '5px' }}
                        disabled={disableBotoes}
                        title={disableBotoes ? "Você não possui permissão ou o módulo habilitado para executar essa ação" : "Estornar recebimentos"}
                        onClick={() => this.props.handleClickEstornarRecebimento(el)}
                        icon="pi pi-replay"
                    />
                </If>
                <If test={recebida}>
                    <Button
                        className="p-button p-button-success"
                        style={{ borderRadius: '20px', width: '105px' }}
                        disabled
                        label="Recebido"
                        icon="pi pi-check-circle"
                    />
                </If>
                <If test={naoRecebida || parcialmenteRecebida}>
                    <Button
                        id="btn-contas-receber-receber"
                        className="p-button p-button-secondary"
                        style={{ borderRadius: '20px', width: '105px' }}
                        disabled={disableBotoes}
                        title={disableBotoes ? "Você não possui permissão ou o módulo habilitado para executar essa ação" : "Efetuar o recebimento"}
                        onClick={() => this.props.handleClickEfetuarBaixa(el)}
                        icon="pi pi-check"
                        label="Receber"
                    />
                </If>

            </span>
        );
    };

    renderValorField(el) {
        const valorFormatado = formatarMonetario(el.valor)
        return <span title={valorFormatado}>{valorFormatado}</span>
    };

    renderValorAReceberField(el) {
        const valorFormatado = formatarMonetario(el.valorAReceber)
        return <span title={valorFormatado}>{valorFormatado}</span>
    };

    renderVencimentoField(el) {
        return <span>{format(parseISO(el.vencimento), "dd/MM/yyyy")}</span>
    };

    renderDescricaoField(el) {
        return (
            <span title={el.descricao} style={{ maxHeight: '60px', overflow: 'hidden', display: 'inline-flex' }}>
                {el.descricao}
            </span>
        );
    };

    renderFormaPagamentoField(el) {
        const label = formaPagamento.filter(formaPgto => formaPgto.value === el.forma)[0].label;
        return (
            <span title={label} style={{ maxHeight: '60px', overflow: 'hidden', display: 'inline-flex' }}>
                {label}
            </span>
        );
    }

    renderParcelaField(el) {
        const { parcelas } = this.props;

        const parcelaNumero = parcelas.findIndex(value => value.id === el.id);
        const parcelaTotal = parcelas.length;

        return <b>{`${parcelaNumero + 1}/${parcelaTotal}`}</b>
    }

    render() {
        const { isMobile, parcelas, totalRecebido, totalAReceber } = this.props;

        return (
            <>
                <Grid>
                    <Col>
                        <DataTable
                            className="table-light"
                            value={parcelas}
                            responsive
                            emptyMessage={<NenhumRegistroEncontrado />}
                        >
                            <Column
                                header={isMobile && "Parcela"}
                                body={this.renderParcelaField}
                                style={{ width: '50px' }}
                            />
                            <Column
                                header="Data"
                                field="vencimento"
                                sortable={true}
                                body={this.renderVencimentoField}
                                style={{ width: '110px' }}
                            />
                            <Column
                                header="Valor"
                                field="valor"
                                sortable={true}
                                body={this.renderValorField}
                                style={{ width: '105px', textOverflow: 'ellipsis', overflow: 'hidden' }}
                            />
                            <Column
                                header={isMobile ? "A receb." : "A receber"}
                                field="valorAReceber"
                                sortable={true}
                                body={this.renderValorAReceberField}
                                style={{ width: '120px', textOverflow: 'ellipsis', overflow: 'hidden' }}
                            />
                            <Column
                                header={isMobile ? "Desc." : "Descrição"}
                                field="descricao"
                                sortable={true}
                                body={this.renderDescricaoField}
                                style={{ wordBreak: 'break-all' }}
                            />
                            <Column
                                header={isMobile ? "Forma pgto." : "Forma de pagamento"}
                                field="forma"
                                sortable={true}
                                body={this.renderFormaPagamentoField}
                                style={{ wordBreak: 'break-all' }}
                            />
                            <Column
                                header={isMobile ? "Ações" : ""}
                                body={this.renderAcoesField}
                            />
                        </DataTable>
                    </Col>
                    <Col style={{ textAlign: 'end' }}>
                        <div>A receber: <b style={{ color: colorsStatus.VENDA.strongColor }}>{formatarMonetario(totalAReceber)}</b></div>
                        <div>Recebido: <b style={{ color: colorsStatus.VENDA.strongColor }}>{formatarMonetario(totalRecebido)}</b></div>
                    </Col>
                </Grid>
                <If test={this.props.modalRecebimentoVisible}>
                    <ModalEfetuarRecebimento
                        onHide={this.props.handleCloseModalRecebimento}
                        visible={this.props.modalRecebimentoVisible}
                        registroSelecionado={this.props.registroSelecionado}
                    />
                </If>
            </>
        )
    }
}

export default TabelaRecebimentos;
