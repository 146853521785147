import { get, del, post, put, exibirToast } from "../../../../../../common/requisicoes";
import { services } from "../../../../../../common/constantes/api";

export async function asyncGetRegistro(idConta, onSuccess, onError) {
    await get(`${services.GESTOR}/v1/contas/${idConta}`, null, onSuccess, onError);
}

export async function asyncDeleteRegistro(idConta, onSuccess, onError) {
    await del(`${services.GESTOR}/v1/contas/${idConta}`, null, exibirToast(onSuccess, "Conta removida com sucesso"), onError);
}

export async function asyncUpdateRegistro(data, onSuccess, onError) {
    await put(`${services.GESTOR}/v1/contas/${data.id}`, data, null, exibirToast(onSuccess, "Conta atualizada com sucesso"), onError);
}

export async function asyncCreateRegistro(data, onSuccess, onError) {
    await post(`${services.GESTOR}/v1/contas`, data, null, exibirToast(onSuccess, "Conta criada com sucesso"), onError);
}

export async function asyncAtualizarSaldoInicial(idConta, data, onSuccess, onError) {
    await put(`${services.GESTOR}/v1/contas/${idConta}/atualizar_saldo_inicial`, data, null, onSuccess, onError);
}

export async function asyncGetContaMesmoNome(nome, onSuccess, onError) {
    await get(`${services.GESTOR}/v1/contas?query=nome=="${nome}"`, null, onSuccess, onError);
}
