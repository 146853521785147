export function converterEntradaEstoqueParaApi(values) {
    return {
        ...values,
        produto: { 
            id: values.produto.value,
            tipo: values.produto.registro.tipo,
            controlarEstoque: values.produto.registro.controlarEstoque,
        },
    }
}
