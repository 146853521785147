import React, { Component } from 'react';
import { withFormik, Field } from "formik";
import autoBind from 'react-autobind';
import { mensagensDeValidacao } from "../../../common/constantes/mensagens";
import { helpMessage, initialValue, CADASTROURL, buscarEtapas } from './utils/constantes';
import ReactSteps from '../../../components/Steps';
import ButtonSalvar, { estadosBotaoSalvar } from '../../../components/Button/ButtonSalvar';
import ButtonCancelar, { estadosBotaoCancelar } from '../../../components/Button/ButtonCancelar';
import ButtonExcluir from '../../../components/Button/ButtonExcluir';
import ButtonNovo, { estadosBotaoNovo } from '../../../components/Button/ButtonNovo';
import Grid from '../../../components/Grid';
import InputField from '../../../components/Input/InputField';
import { services } from '../../../common/constantes/api';
import TextArea from '../../../components/TextArea';
import Col from '../../../components/Col';
import DocumentoSignatarios from './components/DocumentoSignatarios';
import SingleSelectUsuario from '../../../components/Select/SingleSelectUsuario';
import SingleSelectSetor from '../../../components/Select/SingleSelectSetor';
import { converterDocumentoParaApi, converterDocumentoParaFormulario } from './utils/documentoConverter';
import { validarFormulario, atualizarUrl, metodosAtualizarUrl } from '../../util';
import {
    asyncCriarDocumento,
    asyncAtualizarDocumento,
    asyncExcluirDocumento,
    asyncGetDocumento,
    asyncBuscaRelacaoPessoaEmails,
    asyncBaixarDocumentoComAssinaturas,
    asyncBaixarDocumentoOriginal,
    asyncBuscarResponsavel,
    asyncAdicionarArquivoDocumento
} from '../requests';
import { validarUUID, gerarUUID } from '../../../common/manipulacaoDeString';
import { confirm, alert, notify, ToastTypes } from '../../../components/Toast';
import NormalButton from '../../../components/Button';
import { recursos, permissoes, estadosCadastro } from '../../../common/constantes/autorizacao';
import InputDate from '../../../components/Input/InputDate';
import { statusDocumento } from '../util/constantes';
import Prompt from '../../../components/Route/Prompt';
import { Menu } from 'primereact/menu';
import DocumentoHistorico from './components/DocumentoHistorico';
import { asyncArquivarDocumento, asyncDesarquivarDocumento } from '../requests';
import { usuarioPossuiPermissao } from '../../../common/autenticacao';
import SingleSelectPessoa from '../../../components/Select/SingleSelectPessoa';
import ModalEnviarPorEmail from './modal/ModalEnviarPorEmail';
import ModalVerDetalhesDocumento from './modal/ModalVerDetalhesDocumento';
import DocumentoConfirmacao from './components/DocumentoConfirmacao';
import { exibirConfirmacaoMesmaIdentificacao, jaExisteDocumentoMesmaIdentificacao } from './utils/functions';
import { buscarEmailFavorito } from './utils/functions';
import { baixarArquivo } from '../../../common/relatorios';
import format from 'date-fns/format';
import { buscarMensagemExcluirDocumento } from '../util/funcoesList';
import { configuracoesUsuario, buscarConfiguracaoUsuario, salvarConfiguracaoUsuario } from '../../../common/configuracaoUsuario';
import { tutorialSteps } from '../../../components/Tutorial/steps/documentos';
import Tutorial from '../../../components/Tutorial';
import { converterUsuarioParaFormulario, converterSetorParaFormulario } from './utils/documentoConverter';
import { keyFilterConsultaRsql, removerCaracteresInvalidosRsql } from '../../../common/rsql';
import { TabPanel } from 'primereact/tabview';
import TabView from '../../../components/TabView';
import Form from '../../../components/Form';
import FormActions from '../../../components/FormActions';
import FormContent from '../../../components/FormContent';
import { parseISO, isValid } from 'date-fns';
import If from '../../../components/If';
import SecaoUploadArquivo from './components/SecaoUploadArquivo'
import SecaoArquivoAdicionado from './components/SecaoArquivoAdicionado';
import ExibirFeedbackCliente from './components/ExibirFeedbackCliente';

class Documentos extends Component {
    constructor(props) {

        super(props);

        autoBind(this);

        this.state = {
            podeInserir: usuarioPossuiPermissao(recursos.DOCUMENTOS, permissoes.INSERIR),
            podeEditar: usuarioPossuiPermissao(recursos.DOCUMENTOS, permissoes.EDITAR),
            podeExcluir: usuarioPossuiPermissao(recursos.DOCUMENTOS, permissoes.EXCLUIR),
            deveExibirTutorial: buscarConfiguracaoUsuario(configuracoesUsuario.EXIBIR_TUTORIAL_DOCUMENTO),
            tutorialVisible: false,
            tabSelecionada: 0,
            modalHistoricoVisible: false,
            modalEnviarDocumentoVisible: false,
            modalVerDetalhesDocumentoVisible: false,
            signatarioEspecifico: null,
            modalReenviarEmail: false,
        }
    }

    buscarEstadoCadastro() {
        return this.props.values.id ? estadosCadastro.EDICAO : estadosCadastro.INCLUSAO;
    }

    componentDidMount() {
        const uuid = this.props.match.params.id;
        if (validarUUID(uuid)) {
            this.asyncSelectRegistro(uuid);
        } else {
            this.novo()
        }

        if (this.state.deveExibirTutorial !== false) {
            this.setState({ tutorialVisible: true })
            salvarConfiguracaoUsuario(configuracoesUsuario.EXIBIR_TUTORIAL_DOCUMENTO, false, null, false)
        }

    }

    async novo() {
        atualizarUrl(this.props.history, CADASTROURL, null, metodosAtualizarUrl.POP);

        const { usuario, setor } = await asyncBuscarResponsavel();

        this.props.resetForm({
            values: {
                ...initialValue,
                usuario: converterUsuarioParaFormulario(usuario),
                setor: converterSetorParaFormulario(setor)
            }
        });
    }

    async validareSalvar(e, novoOnSuccess) {
        const { values, initialValues } = this.props;

        await this.props.handleSubmit();

        if (await validarFormulario(this.props)) {
            if (values.identificacao && values.identificacao !== initialValues.identificacao && await jaExisteDocumentoMesmaIdentificacao(values.id, values.identificacao)) {
                exibirConfirmacaoMesmaIdentificacao(() => {
                    this.salvarOuAtualizar(values, novoOnSuccess)
                })
            } else {
                this.salvarOuAtualizar(values, novoOnSuccess);
            }
        } else {
            this.setState({ tabSelecionada: this.props.errors.tab })
        }
    }

    salvarOuAtualizar(values, novoOnSuccess) {
        let dadosNovoArquivo
        if (values.arquivo && (values.status === statusDocumento.PENDENTE || values.status === statusDocumento.ARQUIVO_ADICIONADO)) {
            if (!this.props.initialValues.arquivo || values.arquivoInseridoComFalha || this.props.initialValues.arquivo !== values.arquivo) {
                dadosNovoArquivo = values.arquivo
            }
        }

        if (values.id) {
            if (dadosNovoArquivo) {
                asyncAdicionarArquivoDocumento(values.id, dadosNovoArquivo, () => {
                    this.atualizarRegistro(() => {
                        this.props.resetForm({ values: { ...this.props.values, status: statusDocumento.ARQUIVO_ADICIONADO, arquivo: dadosNovoArquivo } })
                    }, novoOnSuccess);
                })
            } else {
                this.atualizarRegistro(undefined, novoOnSuccess);
            }
        } else {
            this.criarRegistro(dadosNovoArquivo, novoOnSuccess)
        }
    }

    atualizarRegistro(onSuccess, novoOnSuccess) {
        const dadosForm = this.props.values
        const data = converterDocumentoParaApi(this.props.values);
        asyncAtualizarDocumento(data, () => {

            if (novoOnSuccess) {
                novoOnSuccess();
            } else {
                if (data.status === statusDocumento.EXPIRADO) {
                    this.asyncSelectRegistro(dadosForm.id, onSuccess)
                } else {
                    this.props.resetForm({ values: dadosForm });
                    onSuccess && onSuccess(dadosForm)
                }
            }
        });
    }

    criarRegistro(dadosNovoArquivo, novoOnSuccess) {        
        const data = converterDocumentoParaApi(this.props.values);

        asyncCriarDocumento(data, async document => {
            if (dadosNovoArquivo) {
                this.adicionarArquivoAoDocumentoCriado(document.data.id, dadosNovoArquivo, novoOnSuccess)
            } else {
                if(novoOnSuccess) {
                    novoOnSuccess()
                } else {
                    this.props.resetForm({ values: { ...this.props.values, status: statusDocumento.PENDENTE, arquivo: null } })
                    await this.asyncSelectRegistro(document.data.id, null, true)
                }
            }
        });
    }

    adicionarArquivoAoDocumentoCriado(idDocumento, dadosNovoArquivo, novoOnSuccess) {
        asyncAdicionarArquivoDocumento(idDocumento, dadosNovoArquivo, async () => {
            if(novoOnSuccess) {
                novoOnSuccess()
            } else {
                this.props.resetForm({ values: { ...this.props.values, status: statusDocumento.ARQUIVO_ADICIONADO, arquivo: dadosNovoArquivo } })
                await this.asyncSelectRegistro(idDocumento, null, true)
            }
        }, () => {
            this.props.setFieldValue('arquivoInseridoComFalha', true)
        })
    }

    cancelar() {
        if (this.props.dirty) {
            if (this.props.values.arquivoInseridoComFalha) {
                this.props.resetForm({ values: { ...this.props.initialValues, arquivo: null } });
            } else {
                this.props.resetForm({ values: this.props.initialValues });
            }

        } else {
            this.props.history.goBack();
        }
    }

    excluir() {
        confirm('Atenção', buscarMensagemExcluirDocumento(this.props.values), () => {
            asyncExcluirDocumento(this.props.values.id, () => {
                notify("Documento excluído com sucesso", ToastTypes.SUCCESS)
                this.props.resetForm()
                this.props.history.goBack();
            });
        });
    }

    async asyncSelectRegistro(idDocumento, onSuccess, manterDadosArquivo) {
        asyncGetDocumento(idDocumento, async document => {
            if (manterDadosArquivo) {
                this.props.resetForm({ values: { ...converterDocumentoParaFormulario(document.data), arquivo: this.props.values.arquivo } });
            } else {
                this.props.resetForm({ values: converterDocumentoParaFormulario(document.data) });
            }
            atualizarUrl(this.props.history, CADASTROURL, document.data.id, metodosAtualizarUrl.POP)
            onSuccess && onSuccess(document)
        }, () => {
            this.novo();
        });
    }

    arquivarDocumento() {
        if (this.props.dirty) {
            alert("Atenção", "Salve o documento para arquivá-lo")
            return
        }

        confirm('Tem certeza que deseja arquivar este documento?', 'Um documento arquivado não aparecerá mais na listagem, para visualizá-los marque o item "Exibir documentos arquivados" na listagem.', () => {
            asyncArquivarDocumento(this.props.values.id, () => {
                this.props.resetForm({ values: { ...this.props.values, arquivado: true } })
            });
        })
    }

    desarquivarDocumento() {
        if (this.props.dirty) {
            alert("Atenção", "Salve o documento para arquivá-lo")
            return
        }

        confirm('Confirmação', 'Tem certeza que deseja desarquivar este documento?', () => {
            asyncDesarquivarDocumento(this.props.values.id, () => {
                this.props.resetForm({ values: { ...this.props.values, arquivado: false } })
            });
        })
    }

    baixarDocumento() {
        const { values, initialValues } = this.props
        if (initialValues.arquivo && initialValues.arquivo.name === values.arquivo.name && initialValues.arquivo.size === values.arquivo.size) {
            asyncBaixarDocumentoOriginal(values.id, ({ data: file }) => {
                baixarArquivo(file, values.arquivo.name);
            });
        } else {
            baixarArquivo(values.arquivo, values.arquivo.name)
        }

    }

    baixarDocumentoAssinado() {
        const { values } = this.props
        asyncBaixarDocumentoComAssinaturas(values.id, ({ data: file }) => {
            baixarArquivo(file, values.arquivo.name.split('.pdf')[0] + ' - Assinado.pdf');
        });
    }

    buscaritensOpcoes() {

        const { values } = this.props;
        const { podeEditar } = this.state

        let res = [];

        if (values.id) {
            const historico = {
                label: "Histórico",
                icon: "fa fa-history",
                command: () => this.setState({ modalHistoricoVisible: true })
            };
            res.push(historico);

            if (values.arquivo) {
                const documentoOriginal = {
                    label: "Visualizar arquivo original",
                    icon: "fa fa-download",
                    command: this.baixarDocumento
                };
                res.push(documentoOriginal);
            }

            if (values.status === statusDocumento.ASSINADO) {
                const documentoAssinado = {
                    label: "Visualizar arquivo assinado",
                    icon: "fa fa-download",
                    command: this.baixarDocumentoAssinado
                };
                res.push(documentoAssinado);
            }

            if (values.arquivado === false && podeEditar) {
                const arquivar = {
                    label: "Arquivar",
                    icon: "fa fa-archive",
                    command: () => this.arquivarDocumento()
                }
                res.push(arquivar);
            }
            if (values.arquivado && podeEditar) {
                const arquivar = {
                    label: "Desarquivar",
                    icon: "fa fa-archive",
                    command: () => this.desarquivarDocumento()
                }
                res.push(arquivar);
            }
        }

        return res;
    }

    async onChangePessoa(e) {
        const { values, setFieldValue } = this.props
        const pessoaAntiga = values.pessoa
        setFieldValue("pessoa", e)

        if (values.status === statusDocumento.PENDENTE || values.status === statusDocumento.ARQUIVO_ADICIONADO) {
            let novosSignatarios = values.signatarios.filter(signatario => {
                if (!pessoaAntiga || !signatario.pessoa) {
                    return true
                }
                return signatario.pessoa.value !== (pessoaAntiga && pessoaAntiga.value)
            })
            if (e) {
                await asyncBuscaRelacaoPessoaEmails(e.value, ({ data }) => {
                    if (data.content.length > 0) {
                        const melhorEmail = buscarEmailFavorito(data.content);
                        novosSignatarios.push({
                            idTemporario: gerarUUID(),
                            pessoa: e,
                            email: melhorEmail.email,
                            nome: e.label,
                            papel: 'PARTE'
                        })
                    }
                })
            }
            setFieldValue('signatarios', novosSignatarios)
        }
    }

    onDocumentoEnviadoTodosSignatarios() {
        this.setState({ signatarioEspecifico: null })
        this.asyncSelectRegistro(this.props.values.id)
    }

    buscarNumeroEtapa() {
        const { status } = this.props.values

        switch (status) {
            case statusDocumento.PENDENTE:
                return 0;
            case statusDocumento.ARQUIVO_ADICIONADO:
                return 1;
            case statusDocumento.AGUARDANDO_ASSINATURAS:
                return 2;
            default: return 3
        }
    }

    buscarStatusEtapa() {
        const { status } = this.props.values
        if (status === statusDocumento.REJEITADO || status === statusDocumento.EXPIRADO) {
            return 'error'
        }
        if (status === statusDocumento.ASSINADO) {
            return 'finish'
        }
        return 'process'
    }

    enviarParaAssinatura() {
        if (!this.props.values.signatarios || this.props.values.signatarios.length === 0) {
            alert("Atenção", "Para enviar o documento para assinatura é necessário informar ao menos um signatário")
        } else {
            this.setState({ modalEnviarDocumentoVisible: true, modalReenviarEmail: false })
        }
    }

    render() {

        const { tabSelecionada, modalHistoricoVisible, modalEnviarDocumentoVisible, modalVerDetalhesDocumentoVisible } = this.state;

        const { dirty, values, isModal, setFieldValue } = this.props;

        const estadoCadastro = this.buscarEstadoCadastro()

        const informacoesPermissoes = {
            estadoCadastro: estadoCadastro,
            podeInserir: this.state.podeInserir,
            podeEditar: this.state.podeEditar,
            podeExcluir: this.state.podeExcluir
        };

        const itensOpcoes = this.buscaritensOpcoes();

        const desabilitarCamposSensiveis = !(values.status === statusDocumento.PENDENTE || values.status === statusDocumento.ARQUIVO_ADICIONADO)
        const desabilitarCampoExpiracao = (values.status === statusDocumento.EXPIRADO) ? false : desabilitarCamposSensiveis && values.status !== statusDocumento.AGUARDANDO_ASSINATURAS

        const estadoBotaoNovo = dirty ? estadosBotaoNovo.SALVAR_E_NOVO : estadosBotaoNovo.NOVO;

        const onClickNovo = dirty ? (e) => this.validareSalvar(e, this.novo) : this.novo;

        return (
            <>
                <Tutorial
                    steps={tutorialSteps}
                    showSkipButton
                    continuous
                    visible={this.state.tutorialVisible}
                    onHide={() => this.setState({ tutorialVisible: false })}
                />

                <Prompt dirty={dirty} isModal={isModal} />

                <Menu
                    model={itensOpcoes}
                    style={{ minWidth: '230px' }}
                    popup={true}
                    ref={el => (this.menuOpcoes = el)}
                />

                <Form
                    header={"Cadastro de documento" + (values.arquivado ? " (Arquivado)" : "")}
                    isModal={isModal}
                    className="card-default screen-max-width"
                >
                    <FormActions className="screen-max-width">
                        <ButtonCancelar
                            {...informacoesPermissoes}
                            hidden={isModal && values.id && !dirty}
                            estadoBotao={dirty ? estadosBotaoCancelar.CANCELAR : estadosBotaoCancelar.VOLTAR}
                            onClick={this.cancelar}
                        />
                        <ButtonSalvar
                            {...informacoesPermissoes}
                            estadoBotao={estadosBotaoSalvar.SALVAR}
                            disabled={!dirty}
                            onClick={this.validareSalvar}
                        />
                        <ButtonNovo
                            onClick={onClickNovo}
                            hidden={(!dirty && !values.id) || isModal}
                            estadoBotao={estadoBotaoNovo}
                            {...informacoesPermissoes}
                        />
                        <ButtonExcluir
                            hidden={!values.id || isModal}
                            {...informacoesPermissoes}
                            onClick={this.excluir}
                        />
                        <NormalButton
                            className="p-button-primary"
                            type="button"
                            label="Opções"
                            icon="fa fa-list"
                            style={{ margin: '5px' }}
                            hidden={itensOpcoes.length === 0}
                            onClick={event => this.menuOpcoes.toggle(event)}
                        />
                        <NormalButton
                            className="p-button-success"
                            disabled={dirty}
                            type="button"
                            label="Enviar para assinatura"
                            icon="fa fa-send"
                            style={{ margin: '5px' }}
                            hidden={values.status !== statusDocumento.ARQUIVO_ADICIONADO}
                            onClick={this.enviarParaAssinatura}
                            title={dirty ? "Salve os dados do documento para enviá-lo para assinatura" : "Enviar o documento para assinatura digital"}
                        />
                    </FormActions>
                    <FormContent>
                        <ReactSteps
                            className="step-documento-status"
                            etapas={buscarEtapas(values.status)}
                            current={this.buscarNumeroEtapa()}
                            status={this.buscarStatusEtapa()}
                        />


                        <Grid nestedGrid>

                            <Field sm="12" md="4" lg="4" xl="4"
                                component={InputField}
                                label='Identificação do documento'
                                onChange={e => setFieldValue("identificacao", removerCaracteresInvalidosRsql(e.target.value))}
                                name="identificacao"
                                size={50}
                                keyfilter={keyFilterConsultaRsql}
                                helpMessage={helpMessage.identificacao}
                                {...informacoesPermissoes}
                            />
                            <Field sm="12" md="4" lg="4" xl="4"
                                component={SingleSelectPessoa}
                                url={`${services.GESTOR}/v1/documentos/relacoes/pessoas`}
                                name="pessoa"
                                className="react-select-base step-documento-pessoa"
                                label="Pessoa "
                                value={values.pessoa}
                                helpMessage={helpMessage.pessoa}
                                onChange={this.onChangePessoa}
                                {...informacoesPermissoes}
                            />
                            <Field sm="12" md="4" lg="4" xl="4"
                                component={InputDate}
                                name="expiracaoAssinaturas"
                                label="Expiração das assinaturas"
                                onChange={(e) => setFieldValue('expiracaoAssinaturas', e.target.value)}
                                helpMessage={helpMessage.expiracaoAssinaturas}
                                value={values.expiracaoAssinaturas}
                                disabled={desabilitarCampoExpiracao}
                                {...informacoesPermissoes}
                            />

                            <If test={values.status === statusDocumento.PENDENTE && !values.arquivo}>
                                <Col sm="12" md="12" lg="12" xl="12" className="step-documento-botoes" style={{ minHeight: '150px' }}>
                                    <SecaoUploadArquivo
                                        onAdicionar={(e) => setFieldValue('arquivo', e)}
                                        disabled={values.id ? !informacoesPermissoes.podeEditar : !informacoesPermissoes.podeInserir}
                                    />
                                </Col>
                            </If>
                            <If test={values.arquivo && (values.status === statusDocumento.PENDENTE || values.status === statusDocumento.ARQUIVO_ADICIONADO)}>
                                <Col sm="12" md="12" lg="12" xl="12" style={{ minHeight: '150px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <SecaoArquivoAdicionado
                                        arquivo={values.arquivo}
                                        onAdicionar={(e) => setFieldValue('arquivo', e)}
                                        onBaixar={this.baixarDocumento}
                                        disabled={values.id ? !informacoesPermissoes.podeEditar : !informacoesPermissoes.podeInserir}
                                    />
                                </Col>
                            </If>
                            <If test={values.status !== statusDocumento.PENDENTE && values.status !== statusDocumento.ARQUIVO_ADICIONADO}>
                                <Col sm="12" md="12" lg="12" xl="12" className="step-documento-botoes" style={{ minHeight: '150px' }}>
                                    <ExibirFeedbackCliente
                                        onVerDetalhesClick={() => this.setState({ modalVerDetalhesDocumentoVisible: true })}
                                        signatarios={values.signatarios}
                                        status={values.status}
                                    />
                                </Col>
                            </If>

                        </Grid>
                        <Grid>
                            <TabView
                                className="tab-view-light"
                                onTabChange={(e) => this.setState({ tabSelecionada: e.index })}
                                activeIndex={tabSelecionada}
                            >
                                <TabPanel header="Signatários" headerClassName="step-documento-signatarios">
                                    <DocumentoSignatarios
                                        value={values.signatarios}
                                        onChange={signatarios => setFieldValue('signatarios', signatarios)}
                                        estadoCadastro={estadoCadastro}
                                        disabled={desabilitarCamposSensiveis}
                                        informacoesPermissoes={informacoesPermissoes}
                                    />
                                </TabPanel>
                                <TabPanel header="Confirmações para assinatura" headerClassName="step-documento-confirmacoes">
                                    <DocumentoConfirmacao
                                        value={values.confirmacoes}
                                        idDocumento={values.id}
                                        onChange={confirmacoes => setFieldValue('confirmacoes', confirmacoes)}
                                        estadoCadastro={estadoCadastro}
                                        disabled={desabilitarCamposSensiveis}
                                        informacoesPermissoes={informacoesPermissoes}
                                    />
                                </TabPanel>
                                <TabPanel header="Responsável">
                                    <Grid>
                                        <Field sm="12" md="6" lg="6" xl="6"
                                            component={SingleSelectUsuario}
                                            name="usuario"
                                            label="Usuário "
                                            obrigatorio
                                            disabled
                                            helpMessage={helpMessage.usuario}
                                            url={`${services.GESTOR}/v1/documentos/relacoes/usuarios`}
                                            value={values.usuario}
                                            onChange={(e) => setFieldValue("usuario", e)}
                                            esconderBotao={true}
                                            {...informacoesPermissoes}
                                        />
                                        <Field sm="12" md="6" lg="6" xl="6"
                                            component={SingleSelectSetor}
                                            name="setor"
                                            label="Setor "
                                            obrigatorio
                                            url={`${services.GESTOR}/v1/documentos/relacoes/setores`}
                                            helpMessage={helpMessage.setor}
                                            onChange={(e) => setFieldValue("setor", e)}
                                            value={values.setor}
                                            esconderBotao={true}
                                            {...informacoesPermissoes}
                                        />
                                    </Grid>
                                </TabPanel>
                                <TabPanel header="Observações">
                                    <Field sm="12" md="12" lg="12" xl="12"
                                        component={TextArea}
                                        placeholder="Escreva suas observações aqui..."
                                        name="observacao"
                                        rows={5}
                                        onChange={e => setFieldValue("observacao", e.target.value)}
                                        {...informacoesPermissoes}
                                    />
                                </TabPanel>
                            </TabView>
                        </Grid>
                    </FormContent>
                </Form>
                <If test={modalEnviarDocumentoVisible}>
                    <ModalEnviarPorEmail
                        modalReenviarEmail={this.state.modalReenviarEmail}
                        visible={modalEnviarDocumentoVisible}
                        onHide={() => this.setState({ modalEnviarDocumentoVisible: false })}
                        idDocumento={values.id}
                        arquivo={values.arquivo}
                        signatarioEspecifico={this.state.signatarioEspecifico}
                        onDocumentoEnviado={this.onDocumentoEnviadoTodosSignatarios}
                        emailsSignatarios={values.signatarios.map(signatario => signatario.email)}
                    />
                </If>
                <If test={modalVerDetalhesDocumentoVisible}>
                    <ModalVerDetalhesDocumento
                        documento={values}
                        visible={modalVerDetalhesDocumentoVisible}
                        onClickEnviarEmailSignatarioEspecifico={signatario => this.setState({ modalEnviarDocumentoVisible: true, modalReenviarEmail: true, signatarioEspecifico: signatario })}
                        onHide={() => this.setState({ modalVerDetalhesDocumentoVisible: false })}
                    />
                </If>
                <If test={modalHistoricoVisible}>
                    <DocumentoHistorico
                        idDocumento={values.id}
                        visible={modalHistoricoVisible}
                        onHide={() => this.setState({ modalHistoricoVisible: false })}
                    />
                </If>

            </>
        )
    }
}

Documentos = withFormik({
    enableReinitialize: true,
    validateOnChange: false,

    mapPropsToValues() {
        return initialValue;
    },

    async validate(values) {
        let errors = {};

        if (values && !values.usuario) {
            errors.usuario = mensagensDeValidacao.OBRIGATORIO;
            errors.tab = 2;
        }

        if (values && !values.setor) {
            errors.setor = mensagensDeValidacao.OBRIGATORIO;
            errors.tab = 2;
        }

        if (values.expiracaoAssinaturas && !isValid(parseISO(values.expiracaoAssinaturas))) {
            errors.expiracaoAssinaturas = mensagensDeValidacao.DATA_INVALIDA
        }

        if (values.expiracaoAssinaturas) {
            if (parseISO(values.expiracaoAssinaturas) < parseISO(format(new Date(), 'yyyy-MM-dd'))) {
                errors.expiracaoAssinaturas = 'A data de expiração não pode ser menor que hoje.'
            }
        }

        if (removerCaracteresInvalidosRsql(values.identificacao) !== values.identificacao) {
            errors.identificacao = 'A identificação não pode conter caracteres especiais.'
        }
        return errors;

    },

    handleSubmit: () => { }
})(Documentos);

export default Documentos;