export function converterUsuarioParaFormulario(usuarioServer) {
    return {
        id: usuarioServer.id,
        idCredencial: usuarioServer.credencial.id,
        nome: usuarioServer.nome,
        ativa: usuarioServer.credencial.ativa,
        bloqueada: usuarioServer.credencial.bloqueada,
        email: usuarioServer.credencial.email,
        papeis: converterPapeisParaFormulario(usuarioServer.papeis),
        setores: converterSetoresParaFormulario(usuarioServer.setores),
    }
}

function converterPapeisParaFormulario(papeis) {
    return papeis.map(papel => {
        return {
            label: papel.nome,
            value: papel.id
        }
    })
}

function converterSetoresParaFormulario(setores) {
    return setores.map(setor => {
        return {
            label: setor.nome,
            value: setor.id
        }
    })
}

export function converterUsuarioParaApi(values) {
    return {
        id: values.id,
        nome: values.nome,
        papeis: values.papeis.map(papel => {
            return { id: papel.value }
        }),
        setores: values.setores.map(setor => {
            return { id: setor.value }
        }),
        credencial: {
            email: values.email
        }
    }
}
