import React, { Component } from 'react';
import autoBind from 'react-autobind';
import Modal from '../../../../../../../components/Modal';
import { Field, withFormik } from 'formik';
import * as Yup from 'yup';
import Grid from '../../../../../../../components/Grid';
import TextArea from '../../../../../../../components/TextArea';
import Button from '../../../../../../../components/Button';
import { asyncCancelarNfce } from '../../../requests';
import { mensagensDeValidacao } from '../../../../../../../common/constantes/mensagens';
import { validarFormulario } from '../../../../../../util';
import ModalLoadingTransmissao from '../ModalLoadingTransmissao';
import { Status } from '../../../util/constantes';
import InputField from '../../../../../../../components/Input/InputField';
import { format, parseISO } from 'date-fns';
import If from '../../../../../../../components/If';
import { helpMessageNFCe } from '../../help';
import Message from '../../../../../../../components/Message';

class ModalCancelamento extends Component {
    constructor(props) {
        super(props);

        autoBind(this);

        this.state = {
            mostrarLoadingTransmissao: false
        }
    }

    cancelar() {
        this.props.onHide();
    }

    async confirmar() {
        this.props.handleSubmit();

        if (await validarFormulario(this.props)) {
            this.setState({ mostrarLoadingTransmissao: true, }, () => {
                asyncCancelarNfce(this.props.idNfce, this.converterParaApi(), () => {
                    this.props.onHide(this.props.values)
                }, () => {
                    this.props.onHide(this.props.values)
                })
            })
        }

    }

    converterParaApi() {
        const { values } = this.props;

        if (values.xJust) {
            return {
                xJust: String(values.xJust).trim()
            };
        }
        return { ...values };
    }

    montarInformacaoDeCancelamento() {
        return (
            <span>
                <div>
                    O prazo para cancelamento da NFC-e é de no máximo
                    <b> 30 minutos</b>,
                    sendo que esse prazo pode ser reduzido a critério de cada estado.
                </div>
                <b style={{ cursor: 'pointer', textDecoration: 'underline' }} onClick={() => window.open("https://www.confaz.fazenda.gov.br/legislacao/ajustes/2018/AJ0007_18")}>
                    Clique aqui para mais informações
                </b>
            </span>
        )
    }

    render() {

        const { visible, onHide, xJust, dhCancelamento, setFieldValue, statusNfce, values } = this.props;
        const NfceCancelada = statusNfce === Status.CANCELADA;

        return (
            <Modal
                header="Cancelar NFC-e"
                visible={visible}
                onHide={onHide}
            >
                <ModalLoadingTransmissao visible={this.state.mostrarLoadingTransmissao} message="Cancelando NFC-e..." />
                <Message severity="info" style={{ marginBottom: '12px' }} text={this.montarInformacaoDeCancelamento()} />
                <Grid>
                    <If test={NfceCancelada}>
                        <Field sm="12"
                            component={InputField}
                            label="Data e hora do evento"
                            value={dhCancelamento ? format(parseISO(dhCancelamento, new Date()), 'dd/MM/yyyy HH:mm') : ''}
                            name="dhEvento"
                            disabled
                        />
                    </If>
                    <Field sm="12"
                        component={TextArea}
                        label='Justificativa do cancelamento'
                        name="xJust"
                        obrigatorio
                        maxLength={255}
                        disabled={NfceCancelada}
                        value={xJust ? xJust : values.xJust}
                        onChange={e => setFieldValue('xJust', e.target.value)}
                        helpMessage={helpMessageNFCe.justificativaCancelamento}
                    />
                </Grid>
                <Grid justifyEnd>
                    <span style={{ padding: '0.5em' }}>
                        <Button
                            color="secondary"
                            icon="fa fa-arrow-left"
                            label="Voltar"
                            onClick={this.cancelar}
                        />
                        <Button
                            color="danger"
                            icon={NfceCancelada ? "fa fa-check" : "fa fa-send"}
                            label={NfceCancelada ? "Nota fiscal cancelada" : "Cancelar nota fiscal"}
                            disabled={NfceCancelada}
                            onClick={this.confirmar}
                            style={{ marginLeft: '10px' }}
                        />
                    </span>
                </Grid>
            </Modal>
        )
    }
}

ModalCancelamento = withFormik({
    validateOnBlur: false,
    enableReinitialize: true,
    validateOnChange: false,

    mapPropsToValues(props) {
        return {
            xJust: props.xJust || ""
        }
    },

    validationSchema: Yup.object().shape({
        xJust: Yup.string().nullable().trim().min(15, "A descrição deve ter mais de 15 caracteres").required(mensagensDeValidacao.OBRIGATORIO)
    }),

})(ModalCancelamento);

export default ModalCancelamento;
