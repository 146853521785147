import { del, exibirToast, get, post, put } from '../../../../../common/requisicoes';
import { services } from '../../../../../common/constantes/api';
import { formatISO } from 'date-fns';

export async function asyncCreateNfce(data, messageOnSuccess, onSuccess, onError) {
    await post(`${services.GESTOR}/v1/nfce`, data, null, messageOnSuccess ? exibirToast(onSuccess, messageOnSuccess) : onSuccess, onError);
}

export async function asyncGetNfce(idVenda, onSuccess, onError) {
    await get(`${services.GESTOR}/v1/nfce/${idVenda}`, null, onSuccess, onError);
}

export async function asyncUpdateNfce(nfce, messageOnSuccess = "Registro atualizado com sucesso", onSuccess, onError) {
    await put(`${services.GESTOR}/v1/nfce/${nfce.id}`, nfce, null, messageOnSuccess ? exibirToast(onSuccess, messageOnSuccess): onSuccess, onError);
}

export async function asyncDeleteNFce(id, onSuccess, onError) {
    return await del(`${services.GESTOR}/v1/nfce/${id}`, null, exibirToast(onSuccess, "Nota fiscal de consumidor excluída"), onError);
}

export async function asyncTransmitirNFce(id, onSuccess, onError) {
    return await post(`${services.GESTOR}/v1/nfce/${id}/transmitir`, null, null, onSuccess, onError, false);
}

export async function asyncGetResumoNfce(url, onSuccess, onError) {
    return await get(url, null, onSuccess, onError);
}

export async function asyncGetTotalizadoresCard(interval, onSuccess, onError) {
    return await get(`${services.GESTOR}/v1/nfce/totais?dataInicial=${formatISO(interval.dataInicial, { representation: 'date' })}&dataFinal=${formatISO(interval.dataFinal, { representation: 'date' })}`, null, onSuccess, onError);
}

export async function imprimirDANFE(idNfce, onSuccess, onError) {
    return get(`${services.GESTOR}/v1/nfce/${idNfce}/pdf`, { responseType: "arraybuffer" }, onSuccess, onError)
}

export async function imprimirXMLNfce(idNfce, onSuccess, onError) {
    return get(`${services.GESTOR}/v1/nfce/${idNfce}/xml`, { responseType: "arraybuffer" }, onSuccess, onError)
}

export async function asyncCancelarNfce(id, payload, onSuccess, onError) {
    return await post(`${services.GESTOR}/v1/nfce/${id}/cancelar`, payload, null, onSuccess, onError);
}

// export async function asyncGetNFe(idNfe, onSuccess, onError) {
    //     await get(`${services.GESTOR}/v1/nfce/${idNfe}`, null, onSuccess, onError);
    // }

// export async function asyncUpdateNFe(nfe, onSuccess, onError) {
//     await put(`${services.GESTOR}/v1/nfce/${nfe.id}`, nfe, null, exibirToast(onSuccess, "Nota Fiscal atualizada"), onError);
// }

// export async function asyncCorrigirNFe(id, payload, onSuccess, onError) {
//     return await post(`${services.GESTOR}/v1/nfce/${id}/correcao`, payload, null, exibirToast(onSuccess, "Nota fiscal corrigida"), onError);
// }



// export async function asyncRecalcularNfe(payload, onSuccess, onError) {
//     return await post(`${services.GESTOR}/v1/nfce/calcular`, payload, null, onSuccess, onError, false);
// }

// export async function buscarAliquotaSimples(onSuccess, onError) {
//     const organizacaoId = buscarDadosLoginLocalStorage().organizacao.id
//     return get(`${services.GESTOR}/v1/empresa/${organizacaoId}/aliquotasimples`, null, onSuccess, onError, false, false)
// 

