import { services } from "../../../../common/constantes/api";
import { del, get, post, put, exibirToast } from "../../../../common/requisicoes";
import { formatISO } from 'date-fns';

export async function asyncGetProventos(url, onSuccess, onError) {
    return await get(url, null, onSuccess, onError, true)
}

export async function asyncDeleteProvento(idProvento, onSuccess, onError) {
    await del(`${services.GESTOR}/v1/investidor/proventos/${idProvento}`, null, exibirToast(onSuccess, "Provento removido com sucesso"), onError);
}

export async function asyncGetProventoPorId(idProvento, onSuccess, onError, exibirLoading = true) {
    return await get(`${services.GESTOR}/v1/investidor/proventos/${idProvento}`, null, onSuccess, onError, exibirLoading);
}

export async function asyncCreateProvento(data, onSuccess, onError) {
    await post(`${services.GESTOR}/v1/investidor/proventos`, data, null, exibirToast(onSuccess, "Provento criado com sucesso"), onError);
}

export async function asyncUpdateProvento(provento, onSuccess, onError) {
    await put(`${services.GESTOR}/v1/investidor/proventos/${provento.id}`, provento, null, exibirToast(onSuccess, "Provento atualizado com sucesso"), onError);
}

export async function asyncGetContas(onSuccess, onError) {
    return await get(`${services.GESTOR}/v1/investidor/proventos/relacoes/contas?size=500`, null, onSuccess, onError, true)
}

export async function asyncGetTotalizadores(filtro, conta, ativos, onSuccess, onError) {
    const filtroConta = conta ? `&conta=${conta}` : '';
    let ativosIds = "";
    if (ativos && ativos.length > 0) {
        ativosIds = montarAtivosSelecionados(ativos);
    }
    return await get(`${services.GESTOR}/v1/investidor/proventos/totais?dataInicio=${formatISO(filtro.dataInicial, { representation: 'date' })}&dataFim=${formatISO(filtro.dataFinal, { representation: 'date' })}${filtroConta}&ativosIds=${ativosIds}`, null, onSuccess, onError, true)
}

function montarAtivosSelecionados(ativos) {
    return ativos.map(ativo => (ativo.value))
}