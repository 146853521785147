import { csosns, modal } from "../../../../../utils/constantes";

export default function mensagensValidacaoPadrao(det, dest, onAliquotasClick, corrigirDadosDestinatarioClick) {
    const { prod, imposto } = det;

    let validacoes = [];

    if (!imposto.icms) {
        validacoes.push({ message: "Campo ICMS é obrigatório", onClick: onAliquotasClick, modalAjuda: modal.ALIQUOTA })
    }
    if (!imposto.icms.orig) {
        validacoes.push({ message: "Campo origem é obrigatório", onClick: onAliquotasClick, modalAjuda: modal.ALIQUOTA })
    }
    if (!imposto.icms.CSOSN) {
        validacoes.push({ message: "Campo CSOSN é obrigatório", onClick: onAliquotasClick, modalAjuda: modal.ALIQUOTA })
    }
    if (!prod.NCM) {
        validacoes.push({ message: "Campo NCM é obrigatório", onClick: onAliquotasClick, modalAjuda: modal.ALIQUOTA })
    }
    if (!prod.CFOP) {
        validacoes.push({ message: "Campo CFOP é obrigatório", onClick: onAliquotasClick, modalAjuda: modal.ALIQUOTA })
    }
    if (imposto.icms.CSOSN && [csosns.CSOSN_201, csosns.CSOSN_202, csosns.CSOSN_900].includes(imposto.icms.CSOSN) && !prod.CEST) {
        validacoes.push({ message: "Campo CEST é obrigatório", onClick: onAliquotasClick, modalAjuda: modal.ALIQUOTA })
    }

    if( imposto.icms.CSOSN && imposto.icms.CSOSN === csosns.CSOSN_900){

        if(dest.tipo !== 'JURIDICA'){
            validacoes.push({ message: "Não é possível utilizar CSOSN 900 para destinatários que não sejam pessoa jurídica", onClick: corrigirDadosDestinatarioClick, modalAjuda: modal.DESTINATARIO})
        }

        if(!dest.IE){
            validacoes.push({ message: "Para utilizar a CSOSN 900 é necessário informar a inscrição estadual do destinatário", onClick: corrigirDadosDestinatarioClick, modalAjuda: modal.DESTINATARIO})
        }

        if(dest.indIEDest !== 'CONTRIBUINTE'){
            validacoes.push({ message: "Para utilizar a CSOSN 900 é necessário que o Indicador de IE do destinatário seja Contribuinte", onClick: corrigirDadosDestinatarioClick, modalAjuda: modal.DESTINATARIO})
        }
    }
    
    return validacoes;
}
