import { formatISO } from "date-fns";
import { services } from "../../../../common/constantes/api";
import { del, get, exibirToast } from "../../../../common/requisicoes";

export async function asyncGetPesquisaNegociacao(url, onSuccess, onError) {
    return await get(url, null, onSuccess, onError, true)
}

export async function asyncGetNegociacao(idNegociacao, onSuccess, onError) {
    await get(`${services.GESTOR}/v1/investidor/negociacoes/${idNegociacao}`, null, onSuccess,onError);
}

export async function asyncDeleteNegociacao(idNegociacao, onSuccess, onError) {
    await del(`${services.GESTOR}/v1/investidor/negociacoes/${idNegociacao}`, null, exibirToast(onSuccess, "Negociação removida com sucesso"), onError);
}

export async function asyncGetTotalizadores(filtro, conta, ativos, onSuccess, onError) {
    const filtroConta = conta ? `&conta=${conta}` : '';
    let ativosIds = "";
    if (ativos && ativos.length > 0) {
        ativosIds = montarAtivosSelecionados(ativos);
    }
    return await get(`${services.GESTOR}/v1/investidor/negociacoes/totais?dataInicio=${formatISO(filtro.dataInicial, { representation: 'date' })}&dataFim=${formatISO(filtro.dataFinal, { representation: 'date' })}${filtroConta}&ativosIds=${ativosIds}`, null, onSuccess, onError, true)
}

function montarAtivosSelecionados(ativos) {
    return ativos.map(ativo => (ativo.value))
}

export async function asyncGetContas(onSuccess, onError) {
    return await get(`${services.GESTOR}/v1/investidor/negociacoes/relacoes/contas?size=500`, null, onSuccess, onError, true)
}