import React from "react";
import propTypes from "prop-types";
import { Button as InternalButton } from 'primereact/button';
import { sizeLarge, sizeMedium, sizeSmall, iconButton } from './style';

const SIZE = {
    SMALL: 'small',
    MEDIUM: 'medium',
    LARGE: 'large',
    ICON: 'icon',
};


function Button(props) {
    function montarClassName(raised, rounded, color, className) {

        let classe = 'p-button';

        if (raised) {
            classe += ' p-button-raised';
        }
        if (rounded) {
            classe += ' p-button-rounded';
        }
        if (color) {
            classe += ` p-button-${color}`;
        }
        
        classe += ` ${className}`;

        return classe;
    }

    function montarEstiloBotao(size, style) {
        switch (size) {
            case SIZE.SMALL:
                return { ...sizeSmall, ...style };
            case SIZE.MEDIUM:
                return { ...sizeMedium, ...style };
            case SIZE.LARGE:
                return { ...sizeLarge, ...style };
            case SIZE.ICON:
                return { ...iconButton, ...style };
            default:
                return style;
        }
    }

    const {
        size,
        style,
        disabled,
        icon,
        type,
        label,
        onClick,
        color,
        iconPos,
        id,
        className,
        title,
        hidden,
        raised,
        rounded,
        children,
        styleContentButton,
        tabIndex
    } = props;

    return (
        <>
            <span title={title} hidden={hidden} style={styleContentButton}>
                <InternalButton
                    id={id}
                    label={label}
                    style={montarEstiloBotao(size, style)}
                    disabled={disabled}
                    type={type}
                    onClick={onClick}
                    icon={icon}
                    iconPos={iconPos}
                    className={montarClassName(raised, rounded, color, className) }
                    tabIndex={tabIndex}
                >
                    {children}
                </InternalButton>
            </span>
        </>
    );
}

Button.defaultProps = {
    hidden: false,
    disabled: false,
    iconPos: 'left',
    type: 'button',
};

Button.propTypes = {
    /** Define o id do componente */
    id: propTypes.string,
    /** Define o nome do componente */
    style: propTypes.object,
    /** Define o tipo do componente */
    type: propTypes.string,
    /** Esconde o componente */
    hidden: propTypes.bool,
    /** Desabilita o componente */
    disabled: propTypes.bool,
    /** Cor do componente */
    color: propTypes.oneOf(['primary', 'secondary', 'info', 'success', 'warning', 'danger']),
    /** Posição do ícone no botão */
    iconPos: propTypes.oneOf(['left', 'right']),
    /** Ícone no botão */
    icon: propTypes.string,
    /** Texto do botão */
    label: propTypes.any,
    /** Método disparado ao clicar. */
    onClick: propTypes.func,
    /** title do botão. */
    title: propTypes.string,
    /** Define o tipo de borda do botão como raised */
    raised: propTypes.bool,
    /** Define o tipo de borda do botão como rounded */
    rounded: propTypes.bool,
    /** Define o tamanho do botão */
    size: propTypes.oneOf(['small', 'medium', 'large', 'icon']),
    /** Define os filhos do componente */
    children: propTypes.any,
    tabIndex: propTypes.number
};

export default Button;
