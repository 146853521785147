import { combineReducers } from 'redux';

import loadingReducer from '../../components/Loading/reducer';
import menuLateralreducers from '../../views/PaginaInicial/reducers'
import reducerEstadoFormulario from '../../components/Route/reducer';
import reducerPlano from '../../views/configuracao/Planos/utils/reducer/atualizarPlano';
import suporteViaChat from '../../views/configuracao/Planos/utils/reducer/suporteViaChat';
import reducerPlanoViolado from '../../common/tratamentoDeErro/reducers/plano_violado_reducer';
import reducerTratamentoErro from '../../common/tratamentoDeErro/reducers/tratamento_erro_reducer';
import reducerDispositivo from '../../app/reducer/dispositivoReducer';
import reducerPossuiInternet from '../../app/reducer/possuiInternetReducer';
import menuLateralFechado from '../../views/fiscal/vendas/NFCe/Form/utils/reducer/menuLateralFechado';

const rootReducer = combineReducers({
    loading: loadingReducer,
    menuLateral: menuLateralreducers,
    menuLateralFechado: menuLateralFechado,
    estadoFormulario: reducerEstadoFormulario,
    plano: reducerPlano,
    planoViolado: reducerPlanoViolado,

    dispositivo: reducerDispositivo,

    possuiInternet: reducerPossuiInternet,
    possuiSuporteViaChat: suporteViaChat,
    mostrarErroTratado: reducerTratamentoErro
})

export default rootReducer
