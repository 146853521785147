import { buscarDocumentoPelaIdentificacao } from "../../requests"
import { confirm } from "../../../../components/Toast"

export async function jaExisteDocumentoMesmaIdentificacao(documentoId, identificacao) {
    let existeMesmoIdentificacao = false
    await buscarDocumentoPelaIdentificacao(identificacao, (e) => {
        if (e.data.content.length > 0 && e.data.content[0].id !== documentoId) {
            existeMesmoIdentificacao = true
        }
    })
    return existeMesmoIdentificacao
}

export function exibirConfirmacaoMesmaIdentificacao(onOk){
    confirm("Confirmação", `Já existe um documento com esta identificação. Tem certeza que deseja salvar?`, onOk)
}

export function buscarEmailFavorito(emails) {
    for (const email of emails) {
        if (email.favorito) {
            return email
        }
    }
    if (emails.length > 0) {
        return emails[0]
    }
}
