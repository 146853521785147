import React, { Component } from 'react';
import { AiFillWarning } from 'react-icons/ai';
import { BsInfoCircle } from 'react-icons/bs';
import { MdArrowDropDown, MdArrowDropUp } from 'react-icons/md';
import autoBind from 'react-autobind';
import './style.css';
import Modal from '../../../../../../../components/Modal';

const styleContentTitle = {
    display: 'flex',
    justifyContent: 'space-between'
}

const styleTitle = {
    fontSize: '16px',
    color: '#B71C1C',
    fontWeight: 'bold',
    paddingBottom: '10px'
}

const styleItem = {
    display: 'flex',
    alignItems: 'center',
}

const styleItemMessage = {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    marginLeft: '3px',
    fontSize: '14px'
}

const styleIconButton = {
    cursor: 'pointer'
}

class NfeCardValidacao extends Component {

    constructor(props) {
        super(props);

        autoBind(this);

        this.state = {
            mostrarTodas: true,
            popupAberto: false,
            erroSelecionado: null
        }
    }

    render() {
        const { erros = [], isMobile, validacoes = [] } = this.props;
        const { mostrarTodas, popupAberto, erroSelecionado } = this.state;

        if ((erros || validacoes) && (erros.length > 0 || validacoes.length > 0)) {
            const errors = [...erros, ...validacoes];

            return (
                <>
                    <div className="card card-default" style={{ boxShadow: 'unset', marginBottom: '10px', borderBottom: '1px solid #d5d5d5', padding: '0px 0px 10px 0px' }}>
                        <div style={styleContentTitle}>
                            <span style={styleTitle}>
                                {isMobile ? "Mensagens de validação" : "Mensagens de validação da nota fiscal"}
                            </span>
                        </div>
                        <div>
                            {errors.map(erro => {
                                return (
                                    <div key={erro.id} className="flex-parent">
                                        <div className="flex-child long-and-truncated" title={erro.mensagem}>
                                            <span style={styleItem}>
                                                <span>
                                                    <AiFillWarning color="#CD4141" fontSize={20} />
                                                </span>
                                                <span style={styleItemMessage}>
                                                    {erro.mensagem}
                                                </span>
                                            </span>
                                        </div>
                                        <div className="flex-child short-and-fixed">
                                            <div data-pr-tooltip="PrimeReact-Logo">
                                                <BsInfoCircle
                                                    style={styleIconButton}
                                                    size={15}
                                                    color="#333333"
                                                    title="Clique para ver a mensagem completa"
                                                    onClick={(e) => this.setState({ popupAberto: true, erroSelecionado: erro })}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )
                            }).slice(0, mostrarTodas ? errors.length : 5)}
                        </div>
                        {
                            (errors.length > 5) ?
                                <div
                                    style={{ textAlign: 'center', cursor: 'pointer' }}
                                    onClick={() => this.setState({ mostrarTodas: !mostrarTodas })}
                                >
                                    {
                                        mostrarTodas ?
                                            <MdArrowDropUp size={20} style={{ width: '150px' }} /> :
                                            <MdArrowDropDown size={20} style={{ width: '150px' }} />
                                    }
                                </div> : null
                        }
                    </div>
                    <Modal
                        header="Mensagem completa"
                        visible={popupAberto}
                        onHide={() => this.setState({ popupAberto: !popupAberto })}
                    >
                        <span style={{ wordBreak: 'break-all' }}>
                            {erroSelecionado && erroSelecionado.mensagem}
                        </span>
                    </Modal>
                </>
            )
        }
        return null;
    }
}

export default NfeCardValidacao;