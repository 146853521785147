import { solicitarLoginDoUsuario } from '../autenticacao';
import { notify, ToastTypes } from '../../components/Toast';
import { exibirModalPlanoViolado } from '../planoViolado';
import { dispatchAction } from '../redux';
import { actionTypes } from '../constantes/reduxTypes';


export function tratarErroRequisicoes(response) {
    if (!response || !response.status) {
        dispatchAction(actionTypes.POSSUI_INTERNET, false)
        return
    }

    if (response.status === 404) {
        notify("Registro não encontrado!", ToastTypes.ERROR)
        return
    }

    if (response.data) {
        if (response.data.error === 'invalid_token' || response.data.error === "invalid_grant") {
            solicitarLoginDoUsuario()
            return
        }
        if (response.data.message) {
            notify(response.data.message, ToastTypes.ERROR)
            return
        }
        if (response.data.summary) {
            if (response.data.details && response.data.details.length > 0 && response.data.details[0] != null) {
                if (response.data.details[0].includes("A operação excede a quantidade de") && response.data.details[0].includes("disponíveis para o seu plano")) {
                    exibirModalPlanoViolado(response.data.details[0])
                    return
                }
            }
            detalharErroTratado(response)
            return
        } else {
            detalharErroNaoTratado(response)
            return
        }
    } else {
        notify("Erro desconhecido!", ToastTypes.ERROR)
    }
}
function detalharErroNaoTratado(response) {
    let msg = 'Não foi possível obter detalhes do erro ocorrido'

    if (response.data.message)
        msg = response.data.message

    if (response.data.error)
        msg = response.data.error

    exibirAlert('Detalhamento da notificação', msg)
}

function detalharErroTratado(response) {
    let details = ''

    if (response && response.data && response.data.details && response.data.details.length === 1) {
        details = response.data.details[0];
    } else {
        response.data.details.forEach(detail => {
            details += `${detail}<br/>`
        });
    }

    exibirAlert("Um problema foi encontrado", details);
}

function exibirAlert(title = "", error = null) {
    dispatchAction(actionTypes.MOSTRAR_ERRO_TRATADO, { title, error })
    return
}
