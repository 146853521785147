export const helpConfiguracoesForm = {
    nome: 'Nome Fantasia da empresa',
    inscricaoEstadual: "Inscrição Estadual da empresa (Utilizada para emissão de notas fiscais)",
    inscricaoEstadualSubstitutoTributario: "Inscrição Estadual do Substituto Tributário (Utilizada para emissão de notas fiscais)",
    inscricaoMunicipal: "Inscrição Municipal da empresa (Utilizada para emissão de notas fiscais)",
    cnae: "Classificação Nacional da Atividade Econômica da empresa (Utilizada para emissão de notas fiscais)",
    regimeTributario: "Regime Tributário da empresa (Utilizada para emissão de notas fiscais)",
    certificadoSenha: "Senha do certificado digital",
    serieNfe: "Série da Nota Fiscal Eletrônica",
    numeroInicialNfe: "Número inicial para envio de Nota Fiscal Eletrônica. Os números das Notas Fiscais Eletrônicas serão gerados a partir deste número inicial",
    aliquotaSimples: "Alíquota do Anexo III do Simples Nacional de 2018 em atendimento à Lei Complementar nº 155",
    mesVigencia: "Mês em que a alíquota do simples nacional está sendo aplicada",
    serieNfce: "Série da Nota Fiscal de Consumidor",
    numeroInicialNfce: "Número inicial para envio de Nota Fiscal de Consumidor. Os números das Notas Fiscais de consumidor serão gerados a partir deste número inicial",
}
