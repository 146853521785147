import React, { Component } from 'react';
import * as Yup from "yup";
import Modal from '../../../../../components/Modal';
import Grid from '../../../../../components/Grid';
import { withRouter } from 'react-router';
import ButtonSalvar, { estadosBotaoSalvar } from '../../../../../components/Button/ButtonSalvar';
import ButtonCancelar, { estadosBotaoCancelar } from '../../../../../components/Button/ButtonCancelar';
import { usuarioPossuiPermissao } from '../../../../../common/autenticacao';
import { recursos, permissoes, estadosCadastro } from '../../../../../common/constantes/autorizacao';
import ButtonExcluir from '../../../../../components/Button/ButtonExcluir';
import { mensagensDeValidacao } from '../../../../../common/constantes/mensagens';
import autoBind from 'react-autobind';
import { withFormik, Field } from 'formik';
import InputField from '../../../../../components/Input/InputField';
import { helpMessage } from './help';
import Dropdown from '../../../../../components/Select/Dropdown';
import { confirm } from '../../../../../components/Toast';
import { asyncGetImportacao, asyncDeleteRegistro, asyncCreateRegistro, asyncUpdateRegistro } from '../../request';
import AutoProgressBar from '../../../../../components/Loading/AutoProgressBar';
import Form from '../../../../../components/Form';
import FormActions from '../../../../../components/FormActions';
import FormContent from '../../../../../components/FormContent';
import { isValidCnpj, isValidCpf } from '@brazilian-utils/validators';
import { manterApenasNumeros } from '../../../../../common/mascara';
import InputSelectCpfOrCnpj from '../../../../../components/Input/InputSelectCpfOrCnpj';
import { TiposUsuarios } from '../../util/constantes';
import Message from '../../../../../components/Message';

const initialValue = {
    id: '',
    usuario: '',
    tipo: 'CEI',
    senha: '',
    tipoUsuario: TiposUsuarios.CPF,
    usuarioCpf: false,
    usuarioCnpj: false
};

class ModalImportacao extends Component {

    constructor(props) {
        super(props);
        autoBind(this);

        this.state = {
            podeInserir: usuarioPossuiPermissao(recursos.INVESTIDOR_IMPORTACOES, permissoes.INSERIR),
            podeEditar: usuarioPossuiPermissao(recursos.INVESTIDOR_IMPORTACOES, permissoes.EDITAR),
            podeExcluir: usuarioPossuiPermissao(recursos.INVESTIDOR_IMPORTACOES, permissoes.EXCLUIR)
        }
    }

    async asyncSelectRegistro(idConta) {
        await asyncGetImportacao(idConta, ({ data: conta }) => {
            this.props.resetForm({ values: conta });
        })
    }

    novo() {
        this.props.resetForm({ values: initialValue });
    }

    async salvar(e, novoOnSuccess) {
        await this.props.handleSubmit();
        await this.props.validateForm();

        if (this.props.isValid) {
            const { values } = this.props
            if (values.id) {
                this.asyncUpdateRegistro(values, novoOnSuccess);
            } else {
                this.asyncCreateRegistro(values, novoOnSuccess);
            }
        }
    }

    async asyncUpdateRegistro(values, novoOnSuccess) {
        if (values.id) {
            await asyncUpdateRegistro(values, () => {
                if (novoOnSuccess) {
                    novoOnSuccess();
                } else {
                    this.props.resetForm({ values: this.props.initialValues });
                    this.props.onHide(values);
                }
            })
        }
    }

    async asyncCreateRegistro(values, novoOnSuccess) {
        if (values) {
            await asyncCreateRegistro(values, () => {
                if (novoOnSuccess) {
                    novoOnSuccess();
                } else {
                    this.props.onHide(values);
                }
            })
        }
    }

    changeCpfOuCnpj(CpfOuCnpj) {
        const { tipoUsuario } = this.props.values

        if (tipoUsuario === TiposUsuarios.CNPJ) {
            this.props.setFieldValue('usuario', CpfOuCnpj)
        } else {
            this.props.setFieldValue('usuario', CpfOuCnpj)
        } 
    }

    trocarTipoDocumento() {
        const tipoUsuario = this.props.values.tipoUsuario

        if (tipoUsuario === TiposUsuarios.CPF) {
            this.props.setFieldValue('tipoUsuario', TiposUsuarios.CNPJ)
            this.props.setFieldValue('usuario', null)
        } else {
            this.props.setFieldValue('tipoUsuario', TiposUsuarios.CPF)
            this.props.setFieldValue('usuario', null)
        }
    }
 
    cancelar() {
        if (this.props.dirty) {
            this.props.resetForm({ values: this.props.initialValues });
        } else {
            this.props.onHide();
        }
    }

    excluir() {
        confirm('Confirmação', 'Deseja excluir o registro?', async () => {
            await asyncDeleteRegistro(this.props.values.id, () => {
                this.props.onHide(this.props.values);
            })
        })
    }

    montarInformacaoParaCadastroDoCei() {
        return (
            <span>
                <div>
                    Caso ainda não tenha sido cadastrado ou você esqueceu seu usuário e senha, clique no link abaixo para se cadastrar
                </div>
                <b style={{cursor: 'pointer', textDecoration: 'underline'}} onClick={() => window.open("https://cei.b3.com.br/CEI_Responsivo/")}>
                    Cadastrar usuário e senha no CEI
                </b>
            </span>
        )
    }

    render() {

        const {
            visible,
            errors,
            onHide,
            dirty,
            values,
            isModal,
            esconderBotaoExcluir,
        } = this.props;

        const informacoesPermissoes = {
            podeInserir: this.state.podeInserir,
            podeEditar: this.state.podeEditar,
            podeExcluir: this.state.podeExcluir,
            estadoCadastro: values.id ? estadosCadastro.EDICAO : estadosCadastro.INCLUSAO
        };

        return (
            <Modal
                header={values.id ? "Editar importação" : "Cadastrar nova importação"}
                visible={visible}
                onHide={() => {
                    this.novo()
                    onHide(values)
                }}   
            >
                <AutoProgressBar />
                <Form>
                    <FormActions>
                        <ButtonCancelar
                            {...informacoesPermissoes}
                            hidden={isModal && values.id && !dirty}
                            estadoBotao={dirty ? estadosBotaoCancelar.CANCELAR : estadosBotaoCancelar.VOLTAR}
                            onClick={this.cancelar}
                        />
                        <ButtonSalvar
                            {...informacoesPermissoes}
                            estadoBotao={estadosBotaoSalvar.SALVAR}
                            disabled={!dirty}
                            onClick={this.salvar}
                        />
                        <ButtonExcluir
                            hidden={!values.id || isModal || esconderBotaoExcluir}
                            {...informacoesPermissoes}
                            onClick={this.excluir}
                        />
                    </FormActions>
                    <Message severity="info" style={{marginBottom: '12px'}} text={this.montarInformacaoParaCadastroDoCei()} />
                    <FormContent>
                        <Grid>
                            <Field sm="12" md="12" lg='12' xl='12'
                                component={Dropdown}
                                options={[
                                    { label: 'CEI - Canal Eletrônico do Investidor', value: 'CEI' }
                                ]}
                                label='Canal da importação'
                                title='Canal da importação'
                                obrigatorio
                                disabled
                                name="tipo"
                                showClear={false}
                                helpMessage={helpMessage.tipo}
                                value={values.tipo}
                                onChange={e => this.props.setFieldValue('tipo', e.value)}
                                {...informacoesPermissoes}
                            />
                            <Field
                                sm="12" md="6" lg='6' xl='6'
                                name="usuario"
                                component={InputSelectCpfOrCnpj}
                                value={values.usuario}
                                obrigatorio
                                errors={errors.usuario}
                                valueDocumentoTipo={values.tipoUsuario}
                                onChange={e => this.changeCpfOuCnpj(e)}
                                helpMessage={helpMessage.usuario}
                                onChangeDocumentoTipo={e => this.trocarTipoDocumento(e)}
                                {...informacoesPermissoes}
                            />
                            <Field sm="12" md="6" lg='6' xl='6'
                                component={InputField}
                                type="password"
                                label='Senha'
                                name="senha"
                                placeholder={values.id ? '••••••••••' : ''}
                                obrigatorio
                                size={255}
                                helpMessage={helpMessage.senha}
                                {...informacoesPermissoes}
                            />
                        </Grid>
                    </FormContent>
                </Form>
            </Modal>
        )
    }
}
ModalImportacao = withFormik({
    enableReinitialize: true,
    validateOnChange: false,

    mapPropsToValues(props) {
        if (props.registroSelecionado) {
            return props.registroSelecionado
        } else {
            return initialValue;
        }
    },

    validationSchema: Yup.object().shape({
        usuario: Yup.string().nullable().required(mensagensDeValidacao.OBRIGATORIO)
    }),

    validate(values) {
        let errors = {};
        
        if (values.usuario 
                && values.tipoUsuario === TiposUsuarios.CPF 
                    && !isValidCpf(manterApenasNumeros(values.usuario))) {              
            errors.usuario = 'Digite um CPF válido.';
            return errors
        }

        if (values.usuario 
            && values.tipoUsuario === TiposUsuarios.CNPJ 
                && !isValidCnpj(manterApenasNumeros(values.usuario))) {              
            errors.usuario = 'Digite um CNPJ válido.';
            return errors
        }

        if (!values.id && values.senha === ""){
            errors.senha = "Informe uma senha válida!"
            return errors;
        }
    },
    
    handleSubmit: () => { }
})(ModalImportacao);

export default withRouter(ModalImportacao);
