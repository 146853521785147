import { actionTypes } from "../../../../../common/constantes/reduxTypes";

export default (state = {}, action) => {
    switch (action.type) {
        case actionTypes.ATUALIZAR_PLANO:
            {
                return {
                    ...state,
                    dataAtualizacaoPlano: new Date()
                }
            }
        default:
            {
                return state
            }
    }
}