import React, { Component } from 'react';
import { withFormik, Field } from 'formik';
import autoBind from 'react-autobind'
import * as Yup from 'yup';
import { helpDocumentoConfirmacaoSignatarioForm } from './help';
import isValid from 'date-fns/isValid';
import isWithinInterval from 'date-fns/isWithinInterval';
import isDate from 'date-fns/isDate';
import parse from 'date-fns/parse';
import { mensagensDeValidacao } from '../../../../../../../../../common/constantes/mensagens';
import { gerarUUID } from '../../../../../../../../../common/manipulacaoDeString';
import { manterApenasNumeros } from '../../../../../../../../../common/mascara';
import ButtonCancelar, { estadosBotaoCancelar } from '../../../../../../../../../components/Button/ButtonCancelar';
import ButtonSalvar from '../../../../../../../../../components/Button/ButtonSalvar';
import ButtonNovo, { estadosBotaoNovo } from '../../../../../../../../../components/Button/ButtonNovo';
import ButtonExcluir from '../../../../../../../../../components/Button/ButtonExcluir';
import { validarFormulario } from '../../../../../../../../util';
import InputMask from '../../../../../../../../../components/Input/InputMask';
import InputDouble from '../../../../../../../../../components/Input/InputDouble';
import Dropdown from '../../../../../../../../../components/Select/Dropdown';
import InputField from '../../../../../../../../../components/Input/InputField';
import MenuSuperior from '../../../../../../../../../components/MenuSuperior';
import FormGroup from '../../../../../../../../../components/FormGoup';
import Grid from '../../../../../../../../../components/Grid';
import LayoutMenuSuperior from '../../../../../../../../../components/Layout/LayoutMenuSuperior';
import Paper from '../../../../../../../../../components/Paper';
import { isValidCnpj, isValidCpf } from '@brazilian-utils/validators';
import { estadosCadastro } from '../../../../../../../../../common/constantes/autorizacao';

const initialValue = {
    id: '',
    documento: '',
    pergunta: '',
    respostaTipo: 'TEXTO',
    respostaEsperada: '',
    idTemporario: ''
};

class DocumentoConfirmacaoSignatarioForm extends Component {

    constructor(props) {
        super(props);

        autoBind(this);

        this.state = {
            inserindoNovoRegistro: false
        }
    }

    componentDidMount() {
        this.setState({ inserindoNovoRegistro: !this.props.registroSelecionado });
    }

    novo() {
        this.props.onNovoClick();
        this.props.resetForm({ values: initialValue });
        this.setState({ inserindoNovoRegistro: true });
    }

    excluir() {
        this.props.excluirRegistro(this.props.registroSelecionado)
        this.props.onHide()
    }

    async salvar(e, novoOnSuccess) {

        this.props.handleSubmit()

        if (await validarFormulario(this.props)) {
            if (this.state.inserindoNovoRegistro) {
                this.criarRegistro(novoOnSuccess)
            } else {
                this.atualizarRegistro(novoOnSuccess)
            }
        }
    }

    criarRegistro(novoOnSuccess) {
        this.props.inserirRegistro({
            ...this.props.values,
            idTemporario: gerarUUID()
        });
        if (novoOnSuccess) {
            novoOnSuccess();
        } else {
            this.props.onHide();
        }
    }

    atualizarRegistro(novoOnSuccess) {
        this.props.atualizarRegistro(this.props.values);
        if (novoOnSuccess) {
            novoOnSuccess();
        } else {
            this.props.onHide();
        }
    }

    cancelar() {

        if (this.props.dirty) {
            this.props.resetForm({ values: initialValue })
        }
        else {
            this.props.onHide()
        }
    }

    renderCampoResposta(informacoesPermissoes) {
        const { values, disabled } = this.props

        const propriedadesPadrao = {
            label: 'Resposta esperada ',
            name: "respostaEsperada",
            obrigatorio: true,
            value: values.respostaEsperada,
            helpMessage: helpDocumentoConfirmacaoSignatarioForm.respostaEsperada,
            disabled: disabled,
            ...informacoesPermissoes
        }

        switch (values.respostaTipo) {
            case "CNPJ":
                return (
                    <Field sm="12" md="12" lg="6" xl="6"
                        component={InputMask}
                        mask="00.000.000/0000-00"
                        placeholder="  .   .   /    -  "
                        removerMascara={false}
                        onChange={e => this.props.setFieldValue('respostaEsperada', e.target.value)}
                        {...propriedadesPadrao}
                    />
                )
            case "CPF":
                return (
                    <Field sm="12" md="12" lg="6" xl="6"
                        component={InputMask}
                        mask="000.000.000-00"
                        placeholder="   .   .   -  "
                        removerMascara={false}
                        onChange={e => this.props.setFieldValue('respostaEsperada', e.target.value)}
                        {...propriedadesPadrao}
                    />
                )
            case "DATA":
                return (
                    <Field sm="12" md="12" lg="6" xl="6"
                        component={InputMask}
                        mask="00/00/0000"
                        removerMascara={false}
                        placeholder="  /  /    "
                        onChange={e => this.props.setFieldValue('respostaEsperada', e.target.value)}
                        {...propriedadesPadrao}
                    />
                )
            case "NUMERO":
                return (
                    <Field sm="12" md="12" lg="6" xl="6"
                        component={InputDouble}
                        size={15}
                        onChange={e => this.props.setFieldValue('respostaEsperada', e.target.value)}
                        decimalScale={2}
                        {...propriedadesPadrao}
                    />
                )
            case "SIM_NAO":
                return (
                    <Field sm="12" md="12" lg="6" xl="6"
                        component={Dropdown}
                        options={[
                            { label: 'Sim', value: "true" },
                            { label: 'Não', value: "false" },
                        ]}
                        showClear={false}
                        onChange={e => this.props.setFieldValue('respostaEsperada', e.value)}
                        {...propriedadesPadrao}
                    />
                )
            case "TEXTO":
                return (
                    <Field sm="12" md="12" lg="6" xl="6"
                        component={InputField}
                        size={255}
                        {...propriedadesPadrao}
                    />
                )
            default:
                return null
        }
    }

    onChangeTipoResposta(respostaTipo) {
        if (respostaTipo.value !== this.props.values.respostaTipo) {
            this.props.setFieldValue('respostaEsperada', null);
        }
        this.props.setFieldValue('respostaTipo', respostaTipo.value);
    }

    render() {
        const { values, informacoesPermissoes, dirty, disabled, estadoCadastro } = this.props;
        const { inserindoNovoRegistro } = this.state;

        const estadoBotaoNovo = dirty ? estadosBotaoNovo.SALVAR_E_NOVO : estadosBotaoNovo.NOVO;
        const podeInserir = estadoCadastro === estadosCadastro.INCLUSAO ? informacoesPermissoes.podeInserir : informacoesPermissoes.podeEditar

        const onClickNovo = dirty ? (e) => this.salvar(e, this.novo) : this.novo;

        return (
            <React.Fragment>
                <MenuSuperior isModal={true}>
                    <ButtonCancelar
                        {...informacoesPermissoes}
                        estadoBotao={dirty ? estadosBotaoCancelar.CANCELAR : estadosBotaoCancelar.VOLTAR}
                        onClick={this.cancelar}
                    />
                    <ButtonSalvar
                        {...informacoesPermissoes}
                        disabled={!dirty}
                        onClick={this.salvar}
                    />
                    <ButtonNovo
                        onClick={onClickNovo}
                        hidden={!dirty && !values.id}
                        disabled={disabled}
                        estadoBotao={estadoBotaoNovo}
                        podeInserir={podeInserir}
                    />
                    <ButtonExcluir
                        hidden={inserindoNovoRegistro}
                        podeExcluir={informacoesPermissoes.podeEditar}
                        disabled={disabled}
                        onClick={this.excluir}
                    />
                </MenuSuperior>
                <LayoutMenuSuperior isModal={true}>
                    <Paper
                        dirty={dirty}
                        {...informacoesPermissoes}
                        childsOnly={true}
                    >
                        <FormGroup>
                            <Grid>
                                <Field sm="12" md="12" lg="12" xl="12"
                                    component={InputField}
                                    label='Pergunta para confirmação '
                                    obrigatorio
                                    name="pergunta"
                                    size={255}
                                    disabled={disabled}
                                    helpMessage={helpDocumentoConfirmacaoSignatarioForm.pergunta}
                                    {...informacoesPermissoes}
                                />
                                <Field sm="12" md="12" lg="6" xl="6"
                                    component={Dropdown}
                                    label='Tipo de resposta '
                                    obrigatorio
                                    options={[
                                        { label: 'CNPJ', value: 'CNPJ' },
                                        { label: 'CPF', value: 'CPF' },
                                        { label: 'Data', value: 'DATA' },
                                        { label: 'Número', value: 'NUMERO' },
                                        { label: 'Sim/Não', value: 'SIM_NAO' },
                                        { label: 'Texto', value: 'TEXTO' }
                                    ]}
                                    showClear={false}
                                    name="respostaTipo"
                                    value={values.respostaTipo}
                                    onChange={this.onChangeTipoResposta}
                                    helpMessage={helpDocumentoConfirmacaoSignatarioForm.respostaTipo}
                                    disabled={disabled}
                                    {...informacoesPermissoes}
                                />
                                {this.renderCampoResposta(informacoesPermissoes)}
                            </Grid>
                        </FormGroup>
                    </Paper>
                </LayoutMenuSuperior>
            </React.Fragment>
        )
    }
}

DocumentoConfirmacaoSignatarioForm = withFormik({

    enableReinitialize: true,
    validateOnChange: false,

    mapPropsToValues(props) {
        if (props.registroSelecionado) {
            return { ...props.registroSelecionado }
        }
        return { ...initialValue }
    },

    validate(values) {

        const errors = {}

        switch (values.respostaTipo) {
            case "CNPJ":
                if (values.respostaEsperada && !isValidCnpj(manterApenasNumeros(values.respostaEsperada)))
                    errors.respostaEsperada = 'Digite um CNPJ válido.';
                break;
            case "CPF":
                if (values.respostaEsperada && !isValidCpf(manterApenasNumeros(values.respostaEsperada)))
                    errors.respostaEsperada = 'Digite um CPF válido.';
                break;
            case "DATA": {
                if (values.respostaEsperada) {
                    const dataParaConfirmacao = parse(values.respostaEsperada, 'dd/MM/yyyy', new Date())
                    if (isDate(dataParaConfirmacao) && isValid(dataParaConfirmacao)) {
                        if (!isWithinInterval(dataParaConfirmacao, { start: new Date(1900, 1, 1), end: new Date(2100, 1, 1) })) {
                            errors.respostaEsperada = 'Digite uma data válida.';
                        }
                    } else {
                        errors.respostaEsperada = 'Digite uma data válida.';
                    }
                }
                break;
            }
            default:
                break;
        }

        return errors
    },

    validationSchema: Yup.object().shape({
        pergunta: Yup.string().nullable().required(mensagensDeValidacao.OBRIGATORIO),
        respostaTipo: Yup.string().nullable().required(mensagensDeValidacao.OBRIGATORIO),
        respostaEsperada: Yup.string().nullable().required(mensagensDeValidacao.OBRIGATORIO),
    }),
    handleSubmit: () => { }
})(DocumentoConfirmacaoSignatarioForm);

export default DocumentoConfirmacaoSignatarioForm;
