import React, { Component } from 'react'
import { withFormik, Field } from 'formik'
import * as Yup from 'yup'
import FormGroup from '../../../../../../components/FormGoup'
import Grid from '../../../../../../components/Grid'
import Paper from '../../../../../../components/Paper'
import LayoutMenuSuperior from '../../../../../../components/Layout/LayoutMenuSuperior'
import MenuSuperior from '../../../../../../components/MenuSuperior'
import ButtonNovo from '../../../../../../components/Button/ButtonNovo'
import ButtonSalvar, { estadosBotaoSalvar } from '../../../../../../components/Button/ButtonSalvar'
import ButtonCancelar, { estadosBotaoCancelar } from '../../../../../../components/Button/ButtonCancelar'
import InputField from '../../../../../../components/Input//InputField'
import ButtonExcluir from '../../../../../../components/Button/ButtonExcluir'
import { mensagensDeValidacao } from '../../../../../../common/constantes/mensagens'
import { estadosCadastro } from '../../../../../../common/constantes/autorizacao'
import { validarFormulario } from '../../../../../util'
import autoBind from 'react-autobind'
import MsysCheckbox from '../../../../../../components/Input/Checkbox'
import { helpPessoaTelefoneForm } from './help'
import { OPERADORAS } from '../../../utils/constantes'
import Dropdown from '../../../../../../components/Select/Dropdown'
import { gerarUUID } from '../../../../../../common/manipulacaoDeString'
import InputMask from '../../../../../../components/Input/InputMask'
import { buscarMascaraTelefone, manterApenasNumeros } from '../../../../../../common/mascara'
import stringMask from 'string-mask'

const initialValue = {
    id: '',
    numero: '',
    operadora: null,
    observacao: '',
    favorito: false,
    idTemporario: null
}

class PessoaTelefoneForm extends Component {

    constructor(props) {
        super(props)
        autoBind(this)

        this.state = {
            inserindoNovoRegistro: false,
        }
    }

    componentDidMount() {
        this.setState({ inserindoNovoRegistro: !this.props.registroSelecionado });
    }

    novo() {
        this.props.onNovoClick()
        this.props.resetForm({ values: initialValue })
        this.setState({ inserindoNovoRegistro: true })
    }

    excluir() {
        this.props.excluirRegistro(this.props.registroSelecionado)
        this.props.onHide()
    }

    async salvar() {
        this.props.handleSubmit();
        if (await validarFormulario(this.props)) {
            if (!this.props.validarMesmoTelefone(this.props.values)) {
                if (this.state.inserindoNovoRegistro) {
                    this.criarRegistro()
                } else {
                    this.atualizarRegistro()
                }
            } else {
                this.props.setFieldError("numero", "Este número já existe")
            }
        }
    }

    criarRegistro() {
        this.props.inserirRegistro({ ...this.props.values, idTemporario: gerarUUID() })
        this.props.onHide()
    }

    atualizarRegistro() {
        this.props.atualizarRegistro(this.props.values);
        this.props.onHide()
    }

    cancelar() {
        if (this.props.dirty) {
            this.props.handleReset();
        } else {
            this.props.onHide()
        }
    }

    render() {

        const informacoesPermissoes = {
            estadoCadastro: this.state.inserindoNovoRegistro ? estadosCadastro.INCLUSAO : estadosCadastro.EDICAO,
            podeInserir: this.props.podeEditar,
            podeEditar: this.props.podeEditar,
            podeExcluir: this.props.podeEditar
        };

        const { values } = this.props;
        const { inserindoNovoRegistro } = this.state

        return (
            <React.Fragment>
                <MenuSuperior isModal={true}>
                    <ButtonCancelar
                        {...informacoesPermissoes}
                        estadoBotao={this.props.dirty ? estadosBotaoCancelar.CANCELAR : estadosBotaoCancelar.VOLTAR}
                        onClick={this.cancelar}
                    />
                    <ButtonSalvar
                        estadoBotao={estadosBotaoSalvar.CONFIRMAR}
                        {...informacoesPermissoes}
                        disabled={!this.props.dirty}
                        onClick={this.salvar}
                    />
                    <ButtonNovo
                        onClick={this.novo}
                        hidden={inserindoNovoRegistro}
                        {...informacoesPermissoes}
                    />
                    <ButtonExcluir
                        hidden={inserindoNovoRegistro}
                        {...informacoesPermissoes}
                        podeExcluir={this.props.podeExcluir}
                        onClick={this.excluir}
                    />
                </MenuSuperior>
                <LayoutMenuSuperior isModal={true}>
                    <Paper
                        dirty={this.props.dirty}
                        {...informacoesPermissoes}
                        childsOnly={true}
                    >
                        <FormGroup>
                            <Grid style={{ height: 'auto' }}>
                            <Field sm="12" md="12" lg='12' xl='12'
                                    component={MsysCheckbox}
                                    label="Telefone favorito"
                                    title="Marque esta opção caso o telefone seja o favorito"
                                    name="favorito"
                                    helpMessage={helpPessoaTelefoneForm.favorito}
                                    onChange={e => this.props.setFieldValue("favorito", !this.props.values.favorito)}
                                    checked={values.favorito}
                                    {...informacoesPermissoes}
                                />
                                <Field sm="12" md="6" lg="4" xl="4"
                                    component={InputMask}
                                    mask={buscarMascaraTelefone(values.numero)}
                                    placeholder={"(  )      -    "}
                                    label="Número"
                                    obrigatorio
                                    value={values.numero}
                                    name="numero"
                                    onChange={e => this.props.setFieldValue('numero', e.target.value)}
                                    helpMessage={helpPessoaTelefoneForm.numero}
                                    {...informacoesPermissoes}
                                />
                                <Field sm="12" md="4" lg='4' xl='4'
                                    component={Dropdown}
                                    label='Operadora'
                                    name="operadora"
                                    helpMessage={helpPessoaTelefoneForm.operadora}
                                    showClear={false}
                                    onChange={(e) => this.props.setFieldValue('operadora', e.value)}
                                    options={OPERADORAS}
                                    {...informacoesPermissoes}
                                />
                                <Field sm="12" md="12" lg='12' xl='12'
                                    component={InputField}
                                    type="text"
                                    label='Observação'
                                    name="observacao"
                                    {...informacoesPermissoes}
                                />
                            </Grid>
                        </FormGroup>
                    </Paper>
                </LayoutMenuSuperior>
            </React.Fragment>
        )
    }
}

PessoaTelefoneForm = withFormik({

    enableReinitialize: true,
    validateOnChange: false,

    mapPropsToValues(props) {
        if (props.registroSelecionado) {
            return { ...props.registroSelecionado }
        }
        return { ...initialValue, favorito: props.marcarComoFavorito() };
    },

    validationSchema: Yup.object().shape({
        numero: Yup.string().required(mensagensDeValidacao.OBRIGATORIO).nullable(),
    }),

    validate(values) {
        let errors = {};

        if (!new stringMask("(00) 00000-0000").validate(manterApenasNumeros(values.numero)) && !new stringMask("(00) 0000-0000").validate(manterApenasNumeros(values.numero))) {
            errors.numero = 'Digite um numero válido.';
        }
        return errors;
    },

    handleSubmit: () => { },
})(PessoaTelefoneForm);

export default PessoaTelefoneForm
