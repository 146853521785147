import React, { Component } from "react";
import { withFormik, Field } from "formik";
import * as Yup from "yup";
import autoBind from "react-autobind";
import ButtonSalvar from "../../../../../components/Button/ButtonSalvar";
import ButtonCancelar, {
    estadosBotaoCancelar
} from "../../../../../components/Button/ButtonCancelar";
import Prompt from "../../../../../components/Route/Prompt";
import MenuSuperior from "../../../../../components/MenuSuperior";
import {
    recursos,
    permissoes,
    estadosCadastro
} from "../../../../../common/constantes/autorizacao";
import FormGroup from "../../../../../components/FormGoup";
import { Menu } from "primereact/menu";
import Button from "../../../../../components/Button";
import LayoutMenuSuperior from "../../../../../components/Layout/LayoutMenuSuperior";
import { asyncUpdateCartao } from "./requests";
import { validarFormulario } from "../../../../util";
import Grid from "../../../../../components/Grid";
import Col from "../../../../../components/Col";
import Cards from 'react-credit-cards';
import InputField from "../../../../../components/Input/InputField";
import InputMask from "../../../../../components/Input/InputMask";
import { converterCartaoParaApi } from "./utils/cartaoConverter";
import { mensagensDeValidacao } from "../../../../../common/constantes/mensagens";
import { helpCartaoForm } from "./help";
import imgBandeira from './images/img_bandeiras.png'
import { dispararAcoesReduxAtualizacaoPlano } from "../../utils/redux";
import Modal from "../../../../../components/Modal";
import { usuarioPossuiPermissao, fazerLogin, buscarDadosLoginLocalStorage } from "../../../../../common/autenticacao";
import AutoProgressBar from "../../../../../components/Loading/AutoProgressBar";
import './style/index.css'
//Os nomes dos campos não estão seguindo o padrão pois se seguir o google chrome identifica que são campos de cartão de crédito e tenta preenchê-los automaticamente causando bugs
const initialValue = {
    id: "",
    nomeCompleto: '',
    num: '',
    nomeImpresso: '',
    expiracao: '',
    bandeira: 'unknown',
    codigoSeguranca: '',
    cartaoValido: false
};

class ModalCartao extends Component {
    constructor(props) {
        super(props);

        autoBind(this);

        this.state = {
            podeInserir: usuarioPossuiPermissao(recursos.PLANOS, permissoes.INSERIR),
            podeEditar: usuarioPossuiPermissao(recursos.PLANOS, permissoes.EDITAR),
            podeExcluir: usuarioPossuiPermissao(recursos.PLANOS, permissoes.EXCLUIR),
            focoCartao: '',
        };
    }

    async salvar() {
        const { handleSubmit, dirty, values, resetForm } = this.props
        handleSubmit()
        if (dirty && await validarFormulario(this.props)) {
            const dadosFormulario = converterCartaoParaApi(values)
            asyncUpdateCartao(dadosFormulario, () => {
                this.props.onHide()
                resetForm({ values: values });
                const dadosLocalStorage = buscarDadosLoginLocalStorage()
                if (dadosLocalStorage.organizacao.bloqueada) {
                    fazerLogin(dadosLocalStorage.email, dadosLocalStorage.senha, dadosLocalStorage.manterConectado, () => {
                        dispararAcoesReduxAtualizacaoPlano();
                    })
                } else {
                    dispararAcoesReduxAtualizacaoPlano();
                }
            });
        }
    }

    cancelar() {
        if (this.props.dirty) {
            this.props.resetForm({ values: this.props.initialValues });
        } else {
            this.props.onHide();
        }
    }

    onCallbackCard(type, isValid) {
        this.props.setFieldValue('bandeira', type.issuer)
        this.props.setFieldValue('cartaoValido', isValid)
    }

    buscarMascaraCartao() {
        switch (this.props.values.bandeira) {
            case 'amex':
                return '0000 000000 00000'
            case 'dinersclub':
                return '0000 000000 0000'
            default:
                return '0000 0000 0000 0000'
        }
    }

    onChangeNomeImpresso(e) {
        let value = e.target.value

        if (value) {
            value = e.target.value.replace(/([^a-zA-Z ])/, '')
            value = value.toUpperCase()
        }

        this.props.setFieldValue('nomeImpresso', value)
    }

    onChangeNomeCompleto(e) {
        let value = e.target.value
        if (value) {
            value = e.target.value.replace(/([^a-zA-Z ])/, '')
        }
        this.props.setFieldValue('nomeCompleto', value)
    }



    render() {
        const { dirty, values } = this.props;

        const { focoCartao } = this.state

        const informacoesPermissoes = {
            estadoCadastro: estadosCadastro.EDICAO,
            podeInserir: this.state.podeInserir,
            podeEditar: this.state.podeEditar,
            podeExcluir: this.state.podeExcluir
        };
        return (
            <React.Fragment>
                <Modal
                    header='Dados do cartão de crédito'
                    visible={this.props.visible}
                    onHide={this.props.onHide}
                >
                    <Prompt dirty={dirty} />

                    <Menu
                        model={this.state.itensOpcoes}
                        popup={true}
                        ref={el => (this.menuOpcoes = el)}
                    />

                    <MenuSuperior isModal>
                        <AutoProgressBar />
                        <ButtonSalvar
                            {...informacoesPermissoes}
                            disabled={!dirty}
                            title="Salvar o cartão"
                            onClick={this.salvar}
                        />
                        <ButtonCancelar
                            estadoBotao={this.props.dirty ? estadosBotaoCancelar.CANCELAR : estadosBotaoCancelar.VOLTAR}
                            onClick={this.cancelar}
                        />
                        <Button
                            className="p-button-secondary"
                            type="button"
                            label="Opções"
                            icon="fa fa-list"
                            hidden={true}
                            onClick={event => this.menuOpcoes.toggle(event)}
                        />
                    </MenuSuperior>
                    <LayoutMenuSuperior isModal>
                        <FormGroup>
                            <Grid>
                                <Col sm='12' md='6' lg='6' xl='6'>
                                    <Grid>
                                        <Field sm="12" md="12" lg='12' xl='12'
                                            component={InputMask}
                                            placeholder='0000 0000 0000 0000'
                                            mask={this.buscarMascaraCartao()}
                                            label='Número do cartão '
                                            obrigatorio
                                            name="num"
                                            onChange={e => this.props.setFieldValue('num', e.target.value)}
                                            helpMessage={helpCartaoForm.num}
                                            onFocus={e => this.setState({ focoCartao: 'number' })}
                                            {...informacoesPermissoes}
                                        />
                                        <Field sm="12" md="12" lg='12' xl='12'
                                            component={InputField}
                                            label='Nome completo do titular '
                                            obrigatorio
                                            name="nomeCompleto"
                                            onChange={this.onChangeNomeCompleto}
                                            helpMessage={helpCartaoForm.nomeCompleto}
                                            size={255}
                                            {...informacoesPermissoes}
                                        />
                                        <Field sm="12" md="12" lg='12' xl='12'
                                            component={InputField}
                                            label='Nome do titular impresso no cartão '
                                            obrigatorio
                                            helpMessage={helpCartaoForm.nomeImpresso}
                                            name="nomeImpresso"
                                            onChange={this.onChangeNomeImpresso}
                                            size={25}
                                            onFocus={e => this.setState({ focoCartao: 'name' })}
                                            {...informacoesPermissoes}
                                        />
                                        <Field sm="12" md="6" lg='6' xl='6'
                                            component={InputMask}
                                            placeholder="MM/AAAA"
                                            mask='00/0000'
                                            label='Data de expiração '
                                            obrigatorio
                                            helpMessage={helpCartaoForm.expiracao}
                                            name="expiracao"
                                            removerMascara={false}
                                            onChange={e => this.props.setFieldValue('expiracao', e.target.value)}
                                            onFocus={e => this.setState({ focoCartao: 'expiry' })}
                                            {...informacoesPermissoes}
                                        />
                                        <Field sm="12" md="6" lg='6' xl='6'
                                            component={InputField}
                                            keyfilter='pnum'
                                            placeholder='CVV'
                                            size={4}
                                            helpMessage={helpCartaoForm.codigoSeguranca}
                                            label='Código de segurança '
                                            obrigatorio
                                            name="codigoSeguranca"
                                            onFocus={e => this.setState({ focoCartao: 'cvc' })}
                                            {...informacoesPermissoes}
                                        />
                                    </Grid>
                                </Col>
                                <Col sm="12" md="6" lg='6' xl='6'>
                                    <Cards
                                        style={{ marginTop: '20px', width: '200px' }}
                                        number={values.num || ''}
                                        name={values.nomeImpresso || ''}
                                        expiry={values.expiracao || ''}
                                        cvc={values.codigoSeguranca || ''}
                                        placeholders={{
                                            name: "NOME DO TITULAR"
                                        }}
                                        locale={{
                                            valid: 'Expiração'
                                        }}
                                        focused={focoCartao}
                                        callback={this.onCallbackCard}
                                        acceptedCards={[
                                            'mastercard',
                                            'visa',
                                            'amex',
                                            'elo',
                                            'jcb',
                                            'dinersclub',
                                            'discover',
                                            'hipercard',
                                        ]}
                                    />
                                    <center>
                                        <p style={{ margin: '0', marginTop: '10px' }}>Bandeiras aceitas: </p>
                                        <img style={{ width: '300px', maxWidth: '100%' }} src={imgBandeira} alt="Bandeiras aceitas" />
                                    </center>
                                </Col>
                            </Grid>
                        </FormGroup>
                    </LayoutMenuSuperior>
                </Modal>
            </React.Fragment>
        );
    }
}

ModalCartao = withFormik({
    enableReinitialize: true,
    validateOnChange: true,

    mapPropsToValues() {
        return initialValue;
    },

    validate(values) {
        let errors = {}

        if (!values.cartaoValido) {
            errors.num = 'Número do cartão de crédito inválido.'
        }

        if (values.bandeira === 'unknown') {
            errors.num = 'Número do cartão de crédito inválido.'
        }

        if (values.codigoSeguranca && values.codigoSeguranca.trim().length < 3) {
            errors.codigoSeguranca = 'Código de segurança inválido'
        }

        if (values.expiracao && values.expiracao.substring(0, 2) > 12) {
            errors.expiracao = 'Mês inválido.'
        }

        if (values.expiracao && values.expiracao.trim().length < 7) {
            errors.expiracao = mensagensDeValidacao.DATA_INVALIDA
        }

        if (values.expiracao && !errors.expiracao) {
            const expiracaoArray = values.expiracao.split('/')
            const dataLimite = new Date(expiracaoArray[1], expiracaoArray[0] - 1, 30)

            if (dataLimite < new Date()) {
                errors.expiracao = 'A data é inferior ao mês atual.'
            }
        }

        return errors
    },
    validationSchema: Yup.object().shape({
        num: Yup.string().nullable().required(mensagensDeValidacao.OBRIGATORIO),
        nomeCompleto: Yup.string().nullable().required(mensagensDeValidacao.OBRIGATORIO),
        nomeImpresso: Yup.string().nullable().required(mensagensDeValidacao.OBRIGATORIO),
        expiracao: Yup.string().nullable().required(mensagensDeValidacao.OBRIGATORIO),
        codigoSeguranca: Yup.string().nullable().required(mensagensDeValidacao.OBRIGATORIO),
    }),

    handleSubmit: () => { }
})(ModalCartao);

export default ModalCartao;
