//CONVERTER PAPEL PARA FORMULARIO
export function converterPapelParaFormulario(papel, estruturaTree) {
    return {
        id: papel.id,
        nome: papel.nome,
        administrador: papel.administrador,
        permissoes: converterPermissoesParaFormulario(estruturaTree, papel)
    }
}


function converterPermissoesParaFormulario(opcoesTree, papel) {
    const permissoesSelecionadas = montarArrayDePermissoesDoPapelSelecionado(papel)
    return converterPermissoesParaTreeTable(permissoesSelecionadas, opcoesTree)
}

function montarArrayDePermissoesDoPapelSelecionado(papel) {
    let arrayPermissoesDoPapel = [];

    for (let i = 0; i < papel.recursos.length; i++) {
        const recurso = papel.recursos[i];

        for (let j = 0; j < recurso.permissoes.length; j++) {
            const permissao = recurso.permissoes[j];

            arrayPermissoesDoPapel.push(`${recurso.identificacao}|${permissao.identificacao}`)
        }
    }
    return arrayPermissoesDoPapel;
}

function converterPermissoesParaTreeTable(permissoesSelecionadas, opcoesTree) {
    let resultado = {}

    for (let i = 0; i < opcoesTree.length; i++) {
        const recurso = opcoesTree[i];

        var numeroChildrens = recurso.children.length;
        var qtdPermissoesSelecionadas = 0;

        for (let j = 0; j < recurso.children.length; j++) {
            const permissao = recurso.children[j];

            if (permissoesSelecionadas.includes(permissao.key)) {
                qtdPermissoesSelecionadas += 1;
                resultado[permissao.key] = { checked: true, partialChecked: false }
            } else {
                resultado[permissao.key] = { checked: false, partialChecked: false }
            }

            if (qtdPermissoesSelecionadas === numeroChildrens) { //Todas as permissões do recurso estão selecionadas
                resultado[recurso.key] = { checked: true, partialChecked: false }
            } else if (qtdPermissoesSelecionadas === 0) { //Não possui o recurso selecionado
                resultado[recurso.key] = { checked: false, partialChecked: false }
            } else {
                resultado[recurso.key] = { checked: false, partialChecked: true } //Possui algumas permissões do recurso selecionadas, apenas
            }
        }
    }
    return resultado;
}


//CONVERTER PAPEL PARA API
export function converterPapelParaApi(values) {
    return {
        id: values.id,
        nome: values.nome,
        recursos: transformarPermissoesParaApi(values.permissoes)
    }
}

function transformarPermissoesParaApi(permissoes) {
    let permissoesFiltradas = []
    for (const key in permissoes) {
        if (permissoes.hasOwnProperty(key)) {
            const permissao = permissoes[key];
            if (permissao.checked && key.split("|").length > 1) {
                permissoesFiltradas.push(key)
            }
        }
    }

    let recursos = permissoesFiltradas.map(permissaoFiltrada => permissaoFiltrada.split("|")[0])
    //removendo recursos repetidos
    recursos = [...new Set(recursos)]
    let result = recursos.map(recurso => {
        return {
            identificacao: recurso,
            permissoes: buscarPermissoesDoRecurso(recurso, permissoesFiltradas)
        }
    })
    return result
}

function buscarPermissoesDoRecurso(recurso, permissoesFiltradas) {
    let result = []
    permissoesFiltradas.forEach(permissaoFiltrada => {
        if (permissaoFiltrada.split("|")[0] === recurso) {
            result.push({ identificacao: permissaoFiltrada.split("|")[1] })
        }
    })
    return result
}

//CONVERTER ESTRUTURA PARA TREE
export function converterEstruturaParaTree(jsonEstrututra) {
    let newArray = [];

    for (let i = 0; i < jsonEstrututra.length; i++) {
        const recurso = jsonEstrututra[i];

        newArray[i] = {
            label: recurso.descricao,
            key: recurso.identificacao,
            children: []
        }

        for (let j = 0; j < recurso.permissoes.length; j++) {
            const permissao = recurso.permissoes[j];

            newArray[i].children[j] = {
                label: permissao.descricao,
                key: recurso.identificacao + '|' + permissao.identificacao
            }
        }
    }
    return newArray.sort((a, b) => a.label.localeCompare(b.label));
}
