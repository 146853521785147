import React, { Suspense } from "react";

import './layout/theme/layout.css'
import 'primereact/resources/themes/nova-light/theme.css';
import 'primereact/resources/primereact.min.css';
import './app/microsys.css';
import './app/App.css';
import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css';
import 'font-awesome/css/font-awesome.min.css';

import 'react-credit-cards/lib/styles.scss'
import 'rc-steps/assets/index.css';
import 'rc-steps/assets/iconfont.css';

import ReactDOM from "react-dom";
import * as serviceWorker from "./app/serviceWorker";
import { HashRouter } from "react-router-dom";

//Redux import
import { Provider } from "react-redux";
import { createStore, compose, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import ErrorBoundary from "./common/tratamentoDeErro/ErrorBoundary";

//reducers import
import rootReducers from "./config/reducers/";

import App from "./app/App";

import { LastLocationProvider } from 'react-router-last-location';
import { ProgressBar } from "primereact/progressbar";
//configura extensão do redux pro navegador
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

//aplica os middlewars na aplicação e cria a store do redux
export const store = createStore(
    rootReducers,
    composeEnhancer(applyMiddleware(thunk))
);

ReactDOM.render(
    <Provider store={store}>
        <HashRouter hashType="slash">
            <LastLocationProvider>
                <ErrorBoundary>
                    <Suspense fallback={<ProgressBar mode="indeterminate" style={{ height: '4px' }} />}>
                        <App />
                    </Suspense>
                </ErrorBoundary>
            </LastLocationProvider>
        </HashRouter>
    </Provider>,
    document.getElementById("root")
);

serviceWorker.register({
    onUpdate: registration => {
        const waitingServiceWorker = registration.waiting
        if (waitingServiceWorker) {
            waitingServiceWorker.addEventListener("statechange", event => {
                if (event.target.state === "activated") {
                    window.location.reload()
                }
            });
            waitingServiceWorker.postMessage({ type: "SKIP_WAITING" });
        }
    },
});
