import { recursos, permissoes } from "../../common/constantes/autorizacao";
import { usuarioPossuiPermissao } from "../../common/autenticacao";

export const itensBreadCrumb = [
    {
        valor: "pessoas",
        descricao: "Pessoas",
        podeAcessar: () => usuarioPossuiPermissao(recursos.PESSOAS, permissoes.VISUALIZAR)
    },
    {
        valor: "pessoas/cadastro",
        descricao: "Cadastro",
    },

    {
        valor: "produtos",
        descricao: "Produtos e serviços",
        podeAcessar: () => usuarioPossuiPermissao(recursos.PRODUTOS, permissoes.VISUALIZAR)
    },
    {
        valor: "produtos/cadastro",
        descricao: "Cadastro",
    },

    {
        valor: "papeis",
        descricao: "Grupos de usuários e permissões",
        podeAcessar: () => usuarioPossuiPermissao(recursos.PAPEIS, permissoes.VISUALIZAR)
    },
    {
        valor: "papeis/cadastro",
        descricao: "Cadastro",
    },

    {
        valor: "usuarios",
        descricao: "Usuários",
        podeAcessar: () => usuarioPossuiPermissao(recursos.USUARIOS, permissoes.VISUALIZAR)
    },
    {
        valor: "usuarios/cadastro",
        descricao: "Cadastro",
    },
    {
        valor: "configuracoes_gerais",
        descricao: "Configurações gerais",
        podeAcessar: () => true
    },
    {
        valor: "setores",
        descricao: "Setores",
        podeAcessar: () => usuarioPossuiPermissao(recursos.SETORES, permissoes.VISUALIZAR)
    },
    {
        valor: "setores/cadastro",
        descricao: "Cadastro",
    },
    {
        valor: "organizacoes",
        descricao: "Organizações",
        podeAcessar: () => usuarioPossuiPermissao(recursos.ORGANIZACOES, permissoes.VISUALIZAR)
    },
    {
        valor: "organizacoes/cadastro",
        descricao: "Cadastro",
    },

    {
        valor: "minhaconta",
        descricao: "Minha conta",
        podeAcessar: () => true
    },
    {
        valor: "documentos",
        descricao: "Assinatura eletrônica",
        podeAcessar: () => usuarioPossuiPermissao(recursos.DOCUMENTOS, permissoes.VISUALIZAR)
    },
    {
        valor: "documentos/cadastro",
        descricao: "Cadastro"
    },
    {
        valor: "planos",
        descricao: "Planos e preços",
        podeAcessar: () => usuarioPossuiPermissao(recursos.PLANOS, permissoes.VISUALIZAR)
    },
    {
        valor: "categorias",
        descricao: "Categorias",
        podeAcessar: () => usuarioPossuiPermissao(recursos.FINANCAS_CATEGORIAS, permissoes.VISUALIZAR)
    },
    {
        valor: "movimentacoes",
        descricao: "Movimentações financeiras",
        podeAcessar: () => usuarioPossuiPermissao(recursos.FINANCAS_LANCAMENTOS, permissoes.VISUALIZAR)
    },
    {
        valor: "contas",
        descricao: "Contas",
        podeAcessar: () => usuarioPossuiPermissao(recursos.FINANCAS_CONTAS, permissoes.VISUALIZAR)
    },
    {
        valor: "contas_receber",
        descricao: "Contas a receber",
        podeAcessar: () => usuarioPossuiPermissao(recursos.FINANCAS_CONTAS_RECEBER, permissoes.VISUALIZAR)
    },
    {
        valor: "contas_pagar",
        descricao: "Contas a pagar",
        podeAcessar: () => usuarioPossuiPermissao(recursos.FINANCAS_CONTAS_PAGAR, permissoes.VISUALIZAR)
    },
    {
        valor: "vendas",
        descricao: "Vendas e orçamentos",
        podeAcessar: () => usuarioPossuiPermissao(recursos.VENDAS_VENDAS, permissoes.VISUALIZAR)
    },
    {
        valor: "vendas/cadastro",
        descricao: "Cadastro"
    },
    {
        valor: "movimentacoes_estoque",
        descricao: "Movimentações de estoque",
        podeAcessar: () => usuarioPossuiPermissao(recursos.ESTOQUE_MOVIMENTACOES, permissoes.VISUALIZAR)
    },
    {
        valor: "agentes_autorizados",
        descricao: "Agentes autorizados",
        podeAcessar: () => usuarioPossuiPermissao(recursos.AGENTES_AUTORIZADOS, permissoes.VISUALIZAR)
    },
    {
        valor: "agentes_autorizados/cadastro",
        descricao: "Cadastro"
    },
    {
        valor: "nfes",
        descricao: "Notas fiscais (NF-e)",
        podeAcessar: () => usuarioPossuiPermissao(recursos.VENDAS_VENDAS, permissoes.VISUALIZAR)
    },
    {
        valor: "nfes/cadastro",
        descricao: "Cadastro"
    },
    {
        valor: "nfces",
        descricao: "Notas fiscais de consumidor(NFC-e)",
        podeAcessar: () => usuarioPossuiPermissao(recursos.VENDAS_VENDAS, permissoes.VISUALIZAR)
    },
    {
        valor: "nfces/cadastro",
        descricao: "Cadastro"
    },
    {
        valor: "administracao_planos",
        descricao: "Planos",
        podeAcessar: () => usuarioPossuiPermissao(recursos.PLANOS_ADMINISTRACAO, permissoes.VISUALIZAR)
    },
    {
        valor: "administracao_planos/cadastro",
        descricao: "Cadastro"
    },
    {
        valor: "ativos",
        descricao: "Ativos",
        podeAcessar: () => usuarioPossuiPermissao(recursos.INVESTIDOR_ATIVOS, permissoes.VISUALIZAR)
    },
    {
        valor: "proventos",
        descricao: "Proventos",
        podeAcessar: () => usuarioPossuiPermissao(recursos.INVESTIDOR_PROVENTOS, permissoes.VISUALIZAR)
    },
    {
        valor: "corretoras",
        descricao: "Corretoras e contas",
        podeAcessar: () => usuarioPossuiPermissao(recursos.INVESTIDOR_CORRETORAS, permissoes.VISUALIZAR)
    },
    {
        valor: "negociacoes",
        descricao: "Negociações",
        podeAcessar: () => usuarioPossuiPermissao(recursos.INVESTIDOR_NEGOCIACOES, permissoes.VISUALIZAR)
    },
    {
        valor: "importacoes",
        descricao: "Importações",
        podeAcessar: () => usuarioPossuiPermissao(recursos.INVESTIDOR_IMPORTACOES, permissoes.VISUALIZAR)
    },
];
