import React from 'react';

const contentStyle = {
    color: '#000000de',
    border: 'none',
    cursor: 'pointer',
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#e0e0e0',
    borderRadius: '5px',
    textDecoration: 'none',
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    padding: '2px 8px',
    fontSize: '12px',
}

const numberContent = {
    height: '18px',
    width: 'auto',
    fontSize: '14px',
    marginLeft: '-5px',
    marginRight: '2px',
    color: '#616161',
    borderRadius: '10px',
    backgroundColor: '#bdbdbd',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexShrink: '0',
    lineHeight: '1',
    padding: '5px',
}

export default function NotificacaoTotalRegistros(props) {
    return (
        <div style={{ ...contentStyle, color: props.colors.strongColor, backgroundColor: props.colors.lightColor  ,...props.style }}>
            <span style={{...numberContent, color: props.colors.lightColor, backgroundColor: props.colors.strongColor}}>{props.numberOfElements}
            </span>
            {(props.numberOfElements > 1 || props.numberOfElements === 0) ? 'registros' : 'registro'}
        </div>
    )
}