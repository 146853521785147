export const helpMessage = {
    descricao: 'Breve descrição da conta a receber',
    categoria: 'Categoria da conta a receber (Serão listadas aqui as categorias de receita)',
    conta: 'Conta de destino que o valor do pagamento será creditado',
    pessoa: 'Pessoa que originou a conta a receber',
    competencia: 'Data da competencia (efetivação) da conta a receber',
    emissao: 'Data da emissão (cadastro) da conta a receber',
    vencimento: 'Data do vencimento da conta a receber',
    valor: 'Valor da conta a receber',
    numeroDocumento: 'Identificação do documento que originou esta conta a receber',
    dataDocumento: 'Data do documento que originou esta conta a receber',
    ocorrencia: 'Ocorrência ou periodicidade da conta a receber',
    quantidadeRepeticoesParcelas: 'Quantidade de parcelas',
    quantidadeRepeticoesValorFixo: 'Número de vezes que esta conta a receber irá se repetir',
    observacao: 'Observações gerais da conta a receber',
};