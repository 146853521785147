export const container = {
    gridReverse: 'p-dir-rev ',
    nestedGrid: 'nested-grid ',
    directionColumn: 'p-dir-col ',
    directionColumnReverse: 'p-dir-col-rev ',
    justifyStart: 'p-justify-start ',
    justifyEnd: 'p-justify-end ',
    justifyCenter: 'p-justify-center ',
    justifyBetween: 'p-justify-between ',
    justifyAround: 'p-justify-around ',
    justifyEven: 'p-justify-even ',
    verticalAlignStart: 'p-align-start vertical-container ',
    verticalAlignEnd: 'p-align-end vertical-container ',
    verticalAlignCenter: 'p-align-center vertical-container ',
    verticalAlignStretch: 'p-align-stretch vertical-container ',
    verticalAlignContainer: 'vertical-container ',
};
