import { removerCaracteres } from "../manipulacaoDeString";

export function construirUrl(url, filtro, size, page, sort) {
    var retorno = url + formatarValor(filtro) + '&size=' + size + '&page=' + page + '&sort=' + sort;
    return retorno;
}

function formatarValor(parametro) {
    let retorno = parametro;
    retorno = retorno.split("/").join("%2F");
    // retorno = retorno.split("=").join("%3D");
    retorno = retorno.split("#").join("");
    retorno = retorno.split("'").join("'");
    retorno = retorno.split('"').join('"');
    retorno = retorno.split('	').join('');

    return retorno;
}

export function removerCaracteresInvalidosRsql(valor) {
    return removerCaracteres(valor, ["#", "%", '"', "[", "]", "`", "|", "\\", "^", "&", "{", "}"])
}

export const keyFilterConsultaRsql = /^[^%"[\]`|\\^&{}#']+$/;

