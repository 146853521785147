import React, { Component } from 'react';
import * as Yup from "yup";
import autoBind from 'react-autobind';
import Modal from '../../../../../components/Modal';
import { withRouter } from 'react-router';
import { withFormik, Field } from 'formik';
import { mensagensDeValidacao } from '../../../../../common/constantes/mensagens';
import { usuarioPossuiPermissao } from '../../../../../common/autenticacao';
import { recursos, permissoes, estadosCadastro } from '../../../../../common/constantes/autorizacao';
import AutoProgressBar from '../../../../../components/Loading/AutoProgressBar';
import ButtonSalvar from '../../../../../components/Button/ButtonSalvar';
import ButtonCancelar, { estadosBotaoCancelar } from '../../../../../components/Button/ButtonCancelar';
import Grid from '../../../../../components/Grid';
import InputDate from '../../../../../components/Input/InputDate';
import InputMoney from '../../../../../components/Input/InputMoney';
import { services } from '../../../../../common/constantes/api';
import SingleSelectContas from '../../../../../components/Select/SingleSelectConta';
import { asyncCreateLancamentoDespesa, asyncDeleteLancamentoDespesa, buscarCategoriaFavoritaDespesa, buscarContaFavoritaDespesa, asyncUpdateLancamentoDespesa } from './requests';
import { converterLancamentoDespesaParaApi, converterLancamentoDespesaParaFormulario } from './util/lancamentoDespesaConverter';
import { helpMessage } from './util/constantes';
import { confirm } from '../../../../../components/Toast';
import ButtonExcluir from '../../../../../components/Button/ButtonExcluir';
import ButtonNovo, { estadosBotaoNovo } from '../../../../../components/Button/ButtonNovo';
import InputField from '../../../../../components/Input/InputField';
import SingleSelectPessoa from '../../../../../components/Select/SingleSelectPessoa';
import TextArea from '../../../../../components/TextArea';
import { Accordion, AccordionTab } from 'primereact/accordion';
import SingleSelectCategoria, { tiposCategoria } from '../../../../../components/Select/SingleSelectCategoria';
import Form from '../../../../../components/Form';
import FormActions from '../../../../../components/FormActions';
import FormContent from '../../../../../components/FormContent';
import { formatISO, isValid, parseISO } from 'date-fns';

const initialValue = {
    id: null,
    tipo: "DESPESA",
    valor: 0,
    data: null,
    descricao: '',
    conta: null,
    categoria: null,
    pessoa: null,
    observacao: null,
};

class ModalLancamentoDespesa extends Component {

    constructor(props) {
        super(props);
        autoBind(this);

        this.state = {
            podeInserir: usuarioPossuiPermissao(recursos.FINANCAS_LANCAMENTOS, permissoes.INSERIR),
            podeEditar: usuarioPossuiPermissao(recursos.FINANCAS_LANCAMENTOS, permissoes.EDITAR),
            podeExcluir: usuarioPossuiPermissao(recursos.FINANCAS_LANCAMENTOS, permissoes.EXCLUIR),
            optionsSelectContas: []
        }
    }

    componentDidMount() {
        if (!this.props.values.id) {
            this.atribuirValoresPadrao()
        }
    }

    atribuirValoresPadrao() {
        const { values, valorPadraoData } = this.props
        let categoria = values.categoria
        let conta = values.conta
        let data = values.data

        if (valorPadraoData) {
            data = formatISO(valorPadraoData)
        }

        buscarCategoriaFavoritaDespesa(({ data: categoriaDespesa }) => {
            if (categoriaDespesa.totalElements > 0) {
                categoria = {
                    label: categoriaDespesa.content[0].nome,
                    value: categoriaDespesa.content[0].id,
                    registro: categoriaDespesa.content[0]
                }
            }
            if (this.props.dirty) {
                this.props.setFieldValue('categoria', categoria)
                this.props.setFieldValue('data', data)
            } else {
                this.props.resetForm({ values: { ...this.props.values, categoria, data } })
            }
        })

        buscarContaFavoritaDespesa(({ data: contaFavorita }) => {
            if (contaFavorita.totalElements > 0) {
                conta = {
                    label: contaFavorita.content[0].nome,
                    value: contaFavorita.content[0].id,
                    registro: contaFavorita.content[0]
                }
            }
            if (this.props.dirty) {
                this.props.setFieldValue('conta', conta)
            } else {
                this.props.resetForm({ values: { ...this.props.values, conta } })
            }
        })
    }

    novo() {
        this.props.onNovoClick && this.props.onNovoClick()
        this.props.resetForm({ values: initialValue });
        this.atribuirValoresPadrao()
    }

    async salvar(e, novoOnSuccess) {
        await this.props.handleSubmit();
        await this.props.validateForm()

        if (this.props.isValid) {
            if (this.props.values.id) {
                this.asyncUpdateLancamentoDespesa(this.props.values, novoOnSuccess)
            } else {
                this.asyncCreateLancamentoDespesa(this.props.values, novoOnSuccess)
            }
        }
    }

    async asyncCreateLancamentoDespesa(values, novoOnSuccess) {
        if (values) {
            await asyncCreateLancamentoDespesa(converterLancamentoDespesaParaApi(this.props.values), () => {
                if (novoOnSuccess) {
                    novoOnSuccess();
                } else {
                    this.props.onHide(values);
                }
            })
        }
    }

    async asyncUpdateLancamentoDespesa(values, novoOnSuccess) {
        if (values) {
            await asyncUpdateLancamentoDespesa(converterLancamentoDespesaParaApi(this.props.values), () => {
                if (novoOnSuccess) {
                    novoOnSuccess();
                } else {
                    this.props.onHide(values);
                }
            })
        }
    }

    excluir() {
        confirm('Confirmação', 'Deseja excluir o registro?', async () => {
            await asyncDeleteLancamentoDespesa(this.props.values.id, () => {
                this.props.onHide(true);
            })
        })
    }

    cancelar() {
        if (this.props.dirty) {
            this.props.resetForm({ values: this.props.initialValues });
        } else {
            this.props.onHide(this.state.optionsSelectContas);
        }
    }

    render() {
        const { visible, onHide, dirty, values } = this.props;

        const informacoesPermissoes = {
            podeInserir: this.state.podeInserir,
            podeEditar: this.state.podeEditar,
            podeExcluir: this.state.podeExcluir,
            estadoCadastro: values.id ? estadosCadastro.EDICAO : estadosCadastro.INCLUSAO
        };

        const estadoBotaoNovo = dirty ? estadosBotaoNovo.SALVAR_E_NOVO : estadosBotaoNovo.NOVO;
        const onClickNovo = dirty ? (e) => this.salvar(e, this.novo) : this.novo;

        return (
            <Modal
                header={values.id ? "Editar despesa" : "Nova despesa"}
                visible={visible}
                onHide={() => onHide(true)}
            >
                <AutoProgressBar />
                <Form>
                    <FormActions>
                        <ButtonCancelar
                            {...informacoesPermissoes}
                            hidden={values.id && !dirty}
                            estadoBotao={dirty ? estadosBotaoCancelar.CANCELAR : estadosBotaoCancelar.VOLTAR}
                            onClick={this.cancelar}
                        />
                        <ButtonSalvar
                            {...informacoesPermissoes}
                            disabled={!dirty}
                            onClick={this.salvar}
                        />
                        <ButtonNovo
                            onClick={onClickNovo}
                            hidden={!dirty && !values.id}
                            estadoBotao={estadoBotaoNovo}
                            {...informacoesPermissoes}
                        />
                        <ButtonExcluir
                            hidden={!values.id}
                            {...informacoesPermissoes}
                            onClick={this.excluir}
                        />
                    </FormActions>
                    <FormContent>
                        <Grid>
                            <Field sm="12" md="4" lg="4" xl="4"
                                component={InputMoney}
                                label='Valor'
                                autofocus
                                name="valor"
                                obrigatorio
                                size={12}
                                helpMessage={helpMessage.valor}
                                onChange={e => this.props.setFieldValue('valor', e.target.value)}
                                value={values.valor}
                                {...informacoesPermissoes}
                            />
                            <Field sm="12" md="8" lg='8' xl='8'
                                component={InputField}
                                label='Descrição'
                                name="descricao"
                                helpMessage={helpMessage.descricao}
                                size={255}
                                {...informacoesPermissoes}
                            />
                            <Field sm="12" md="6" lg="4" xl="4"
                                component={InputDate}
                                obrigatorio
                                label="Data"
                                name="data"
                                helpMessage={helpMessage.data}
                                onChange={e => this.props.setFieldValue('data', e.target.value)}
                                value={values.data}
                                {...informacoesPermissoes}
                            />
                            <Field
                                sm="12" md="6" lg="4" xl="4"
                                name="conta"
                                label="Conta"
                                component={SingleSelectContas}
                                value={values.conta}
                                obrigatorio
                                options={this.state.optionsSelectContas}
                                onChangeOptions={options => this.setState({ optionsSelectContas: options })}
                                helpMessage={helpMessage.conta}
                                onChange={e => this.props.setFieldValue('conta', e)}
                                url={`${services.GESTOR}/v1/lancamentos/relacoes/contas`}
                                {...informacoesPermissoes}
                            />
                            <Field
                                sm="12" md="6" lg="4" xl="4"
                                name="categoria"
                                label="Categoria"
                                component={SingleSelectCategoria}
                                tipoCategoria={tiposCategoria.DESPESA}
                                value={values.categoria}
                                helpMessage={helpMessage.categoria}
                                onChange={e => this.props.setFieldValue('categoria', e)}
                                url={`${services.GESTOR}/v1/lancamentos/relacoes/categorias/despesas`}
                                {...informacoesPermissoes}
                            />
                            <Accordion className="tab-accordion-lite">
                                <AccordionTab header="Informações adicionais">
                                    <Field sm="12" md="12" lg="12" xl="12"
                                        component={SingleSelectPessoa}
                                        url={`${services.GESTOR}/v1/lancamentos/relacoes/pessoas`}
                                        name="pessoa"
                                        label="Pessoa "
                                        value={values.pessoa}
                                        helpMessage={helpMessage.pessoa}
                                        onChange={(e) => this.props.setFieldValue('pessoa', e)}
                                        {...informacoesPermissoes}
                                    />
                                    <Field sm="12" md="12" lg='12' xl='12'
                                        component={TextArea}
                                        placeholder="Escreva sua observação aqui"
                                        label='Observação'
                                        name="observacao"
                                        helpMessage={helpMessage.observacao}
                                        onChange={e => this.props.setFieldValue('observacao', e.target.value)}
                                        {...informacoesPermissoes}
                                    />
                                </AccordionTab>
                            </Accordion>
                        </Grid>
                    </FormContent>
                </Form>
            </Modal>
        )
    }
}

ModalLancamentoDespesa = withFormik({
    validateOnChange: false,
    validateOnBlur: false,

    mapPropsToValues(props) {
        if (props.registroSelecionado) {
            return converterLancamentoDespesaParaFormulario(props.registroSelecionado)
        } else {
            return initialValue;
        }
    },

    validate(values) {
        let errors = {}
        if (values.valor <= 0) {
            errors.valor = "O valor deve ser maior que zero"
        }

        if (values.data && !isValid(parseISO(values.data))) {
            errors.data = mensagensDeValidacao.DATA_INVALIDA
        }
        return errors
    },

    validationSchema: Yup.object().shape({
        conta: Yup.object().nullable().required(mensagensDeValidacao.OBRIGATORIO),
        data: Yup.string().nullable().required(mensagensDeValidacao.DATA_INVALIDA),
        valor: Yup.string().nullable().required(mensagensDeValidacao.OBRIGATORIO),
        observacao: Yup.string().max(4096, 'O campo observação não pode ter mais que 4096 caracteres.').nullable(true)
    }),
    handleSubmit: () => { }
})(ModalLancamentoDespesa);

export default withRouter(ModalLancamentoDespesa);
