import { formatISO } from "date-fns";
import { buscarDadosLoginLocalStorage } from "../../../common/autenticacao";
import { services } from "../../../common/constantes/api";
import { post, get, del, exibirToast, put } from "../../../common/requisicoes";
import { notify } from "../../../components/Toast";

export async function asyncArquivarDocumento(idDocumento, onSuccess, onError, exibirLoading = true, mostrarToast = true) {
    await post(`${services.GESTOR}/v1/documentos/${idDocumento}/arquivar`, null, null, (e) => {
        if (mostrarToast) {
            notify("Documento arquivado com sucesso")
        }
        onSuccess && onSuccess(e)
    }, onError, exibirLoading);
}

export async function asyncDesarquivarDocumento(idDocumento, onSuccess, onError, exibirLoading = true, mostrarToast = true) {
    await post(`${services.GESTOR}/v1/documentos/${idDocumento}/desarquivar`, null, null, (e) => {
        if (mostrarToast) {
            notify("Documento desarquivado com sucesso")
        }
        onSuccess && onSuccess(e)
    }, onError, exibirLoading)
}

export async function asyncGetPesquisaDocumentos(url, onSuccess, onError, exibirLoading = true) {
    return await get(url, null, onSuccess, onError, exibirLoading)
}

export async function asyncBuscarResponsavel() {
    let dados = {};
    const dadosLogin = buscarDadosLoginLocalStorage();

    dados.setor = dadosLogin.setores[0];

    await get(`${services.GESTOR}/v1/documentos/relacoes/usuarios?query=credencial.id==${dadosLogin.id}`, null, ({ data }) => {
        dados.usuario = data.content[0];
    }, null, false);

    return dados;
}

export async function buscarDocumentoPelaIdentificacao(identificacao, onSuccess) {
    await get(`${services.GESTOR}/v1/documentos/resumo?query=identificacao=='${identificacao}'`, null, data => onSuccess(data));
}

export async function asyncCriarDocumento(data, onSuccess, onError) {
    await post(`${services.GESTOR}/v1/documentos`, data, null, exibirToast(onSuccess, "Documento criado com sucesso"), onError);
}

export async function asyncGetDocumento(idDocumento, onSuccess, onError) {
    await get(`${services.GESTOR}/v1/documentos/${idDocumento}`, null, onSuccess, onError);
}

export async function asyncExcluirDocumento(idDocumento, onSuccess, onError, exibirLoading = true) {
    await del(`${services.GESTOR}/v1/documentos/${idDocumento}`, null, onSuccess, onError, exibirLoading);
}

export async function asyncAtualizarDocumento(data, onSuccess, onError) {
    await put(`${services.GESTOR}/v1/documentos/${data.id}`, data, null, exibirToast(onSuccess, "Documento atualizado com sucesso"), onError);
}

export async function asyncAdicionarArquivoDocumento(documentoId, arquivo, onSuccess, onError) {
    const formData = new FormData();
    formData.append('arquivo', arquivo)
    await post(`${services.GESTOR}/v1/documentos/${documentoId}/arquivo`,  formData, { headers: { 'content-type': 'multipart/form-data' } }, onSuccess, onError);
}

export async function asyncBuscaRelacaoPessoaEmails(idPessoa, onSuccess, onError) {
    return await get(`${services.GESTOR}/v1/documentos/relacoes/pessoas/${idPessoa}/emails`, null, onSuccess, onError, false)
}

export async function asyncBaixarDocumentoOriginal(idDocumento, onSuccess, onError) {
    const url = `${services.GESTOR}/v1/documentos/${idDocumento}/arquivo`

    return get(url, { responseType: "arraybuffer" }, onSuccess, onError)
}

export async function asyncBaixarDocumentoComAssinaturas(idDocumento, onSuccess, onError) {
    const url = `${services.GESTOR}/v1/consulta_publica/${idDocumento}/arquivo_com_assinaturas`;

    return get(url, { responseType: "arraybuffer" }, onSuccess, onError)
}

export async function asyncGetTotalizadores(interval, onSuccess, onError) {
    if(interval.dataInicial && interval.dataFinal) {
        return await get(`${services.GESTOR}/v1/documentos/totais?dataInicio=${formatISO(interval.dataInicial, {representation: 'date'})}&dataFim=${formatISO(interval.dataFinal, {representation: 'date'})}`, null, onSuccess, onError);
    }
}