import React, { Component } from 'react';
import autoBind from 'react-autobind';
import Modal from '../../../../../../../components/Modal';
import { Field } from 'formik';
import ButtonCancelar, { estadosBotaoCancelar } from '../../../../../../../components/Button/ButtonCancelar';
import Grid from '../../../../../../../components/Grid';
import Form from '../../../../../../../components/Form';
import FormActions from '../../../../../../../components/FormActions';
import FormContent from '../../../../../../../components/FormContent';
import InputField from '../../../../../../../components/Input/InputField';
import InputMask from '../../../../../../../components/Input/InputMask';
import Dropdown from '../../../../../../../components/Select/Dropdown';
import InputCep from '../../../../../../../components/Input/InputCep';
import { buscarMascaraTelefone } from '../../../../../../../common/mascara';
import SingleSelectPais from '../../../../../../../components/Select/SingleSelectPais';
import SingleSelectMunicipio from '../../../../../../../components/Select/SingleSelectMunicipio';
import { Fieldset } from 'primereact/fieldset';
import ButtonSalvar, { estadosBotaoSalvar } from '../../../../../../../components/Button/ButtonSalvar';
import { helpNFeDest } from './help';
import { copiarObjeto } from '../../../../../../../common/array';
import Checkbox from '../../../../../../../components/Input/Checkbox';
import If from '../../../../../../../components/If';


export const optionsIndicadorInscricaoEstadual = [
    { label: '9 - Não contribuinte', value: 'NAO_CONTRIBUINTE' },
    { label: '1 - Contribuinte ICMS', value: 'CONTRIBUINTE' },
    { label: '2 - Contribuinte isento', value: 'CONTRIBUINTE_ISENTO' }
];

export const optionsTipoInscricao = [
    { label: 'Pessoa física', value: 'FISICA' },
    { label: 'Pessoa jurídica', value: 'JURIDICA' },
    { label: 'Pessoa estrangeira', value: 'ESTRANGEIRO' }
];

class ModalDadosDestinatario extends Component {

    constructor(props) {
        super(props);
        autoBind(this);
        this.state = {
            values: {
                enderDest: {}
            }
        }
    }

    componentDidMount() {
        this.setState({ values: copiarObjeto(this.props.values) })
    }


    cancelar() {
        this.props.onHide();
    }

    async onChange(field, value) {
        let values = { ...this.state.values }
        values[field] = value
        await this.setState({ values })
    }

    async onChangeEnderDest(field, value) {
        let values = { ...this.state.values }
        values.enderDest[field] = value

        if (value && value.registro && field === "municipio") {
            values.enderDest["idEst"] = value.registro.estado.id
            values.enderDest["estAliqInt"] = value.registro.estado.aliquotaInterna
        }

        await this.setState({ values })
    }

    async onChangeCep(e) {
        if (e.pesquisou) {
            e.bairro && await this.onChangeEnderDest('xBairro', e.bairro);
            e.complemento && await this.onChangeEnderDest('xCpl', e.complemento);
            e.logradouro && await this.onChangeEnderDest('xLgr', e.logradouro);
            e.municipio && await this.onChangeEnderDest('municipio', { label: e.municipio.nome + ' - ' + e.municipio.estado.sigla, value: e.municipio.id });
            e.pais && await this.onChangeEnderDest('pais', { label: e.pais.nome, value: e.pais.id });
        } else {
            this.onChangeEnderDest('CEP', e.value);
        }
    }

    confirmar() {
        this.props.onChange(this.state.values)
        this.props.onHide()
    }

    render() {

        const { visible, onHide, informacoesPermissoes } = this.props
        const { values } = this.state

        return (
            <Modal
                header="Dados do destinatário"
                visible={visible}
                onHide={onHide}
            >
                <Form>
                    <FormActions>
                        <ButtonCancelar
                            estadoBotao={estadosBotaoCancelar.VOLTAR}
                            onClick={this.cancelar}
                        />
                        <ButtonSalvar
                            onClick={this.confirmar}
                            estadoBotao={estadosBotaoSalvar.CONFIRMAR}
                        />
                    </FormActions>
                    <FormContent>
                        <Grid>
                            <Field
                                sm="6" md="6" lg="6" xl="6"
                                component={InputField}
                                label='Nome'
                                value={values.xNome}
                                onChange={e => this.onChange('xNome', e.target.value)}
                                obrigatorio
                                helpMessage={helpNFeDest.xNome}
                                {...informacoesPermissoes}
                            />
                            <Field sm="6" md="6" lg='6' xl='6'
                                component={InputField}
                                size={60}
                                label='E-mail'
                                value={values.email}
                                onChange={(e) => this.onChange('email', e.target.value)}
                                helpMessage={helpNFeDest.email}
                                {...informacoesPermissoes}
                            />
                            <Field sm="6" md="6" lg='6' xl='6'
                                component={Dropdown}
                                obrigatorio
                                label='Tipo de inscrição'
                                onChange={(e) => this.onChange('tipo', e.value)}
                                options={optionsTipoInscricao}
                                value={values.tipo}
                                showClear={false}
                                helpMessage={helpNFeDest.tipo}
                                {...informacoesPermissoes}
                            />
                            <If test={values.tipo === 'FISICA'}>
                                <Field sm="6" md="6" lg='6' xl='6'
                                    component={InputMask}
                                    mask="000.000.000-00"
                                    placeholder="   .   .   -  "
                                    label='CPF '
                                    value={values.CPF}
                                    onChange={(e) => this.onChange('CPF', e.target.value)}
                                    helpMessage={helpNFeDest.CPF}
                                    {...informacoesPermissoes}
                                />
                            </If>
                            <If test={values.tipo === 'JURIDICA'}>
                                <Field sm="6" md="6" lg='6' xl='6'
                                    component={InputMask}
                                    mask="00.000.000/0000-00"
                                    placeholder="  .   .   /    -  "
                                    label='CNPJ'
                                    onChange={(e) => this.onChange('CNPJ', e.target.value)}
                                    value={values.CNPJ}
                                    helpMessage={helpNFeDest.CNPJ}
                                    {...informacoesPermissoes}
                                />
                            </If>
                            <If test={values.tipo === 'ESTRANGEIRO'}>
                                <Field sm="6" md="6" lg='6' xl='6'
                                    component={InputField}
                                    size={20}
                                    label='Identificação do estrangeiro'
                                    value={values.idEstrangeiro}
                                    onChange={(e) => this.onChange('idEstrangeiro', e.target.value)}
                                    {...informacoesPermissoes}
                                />
                            </If>
                        </Grid>
                        <Fieldset legend="Fiscal" className='fieldset-light'>
                            <Grid>
                                <Field sm="6" md="4" lg='4' xl='4'
                                    component={Dropdown}
                                    obrigatorio
                                    label='Indicador de IE'
                                    onChange={async (e) => {
                                        await this.onChange('indIEDest', e.value)
                                        if (e.value === 'CONTRIBUINTE_ISENTO') {
                                            this.onChange('IE', null)
                                        }
                                    }}
                                    options={optionsIndicadorInscricaoEstadual}
                                    value={values.indIEDest}
                                    showClear={false}
                                    helpMessage={helpNFeDest.indIEDest}
                                    {...informacoesPermissoes}
                                />
                                <If test={values.indIEDest !== 'CONTRIBUINTE_ISENTO'}>
                                    <Field sm="6" md="4" lg='4' xl='4'
                                        component={InputField}
                                        size={14}
                                        type={values && values.indIEDest === "CONTRIBUINTE" ? "number" : "text"}
                                        label='Inscrição estadual'
                                        value={values.IE}
                                        onChange={(e) => this.onChange('IE', e.target.value ? e.target.value.toUpperCase() : '')}
                                        helpMessage={helpNFeDest.IE}
                                        {...informacoesPermissoes}
                                    />
                                </If>
                                <Field sm="6" md="4" lg='4' xl='4'
                                    component={InputField}
                                    size={9}
                                    type="number"
                                    label='Inscrição suframa'
                                    value={values.ISUF}
                                    onChange={(e) => this.onChange('ISUF', e.target.value)}
                                    helpMessage={helpNFeDest.ISUF}
                                    {...informacoesPermissoes}
                                />
                                <Field sm="12" md="6" lg='6' xl='6'
                                    component={Checkbox}
                                    colStyle={{ paddingTop: '10px' }}
                                    label="O destinatário é consumidor final"
                                    name="indFinal"
                                    onChange={(e) => this.props.onChangeIndFinal(e.checked ? 'CONSUMIDOR_FINAL' : 'NORMAL')}
                                    checked={this.props.valueIndFinal === 'CONSUMIDOR_FINAL'}
                                    {...informacoesPermissoes}
                                />
                                <If test={values.tipo === 'JURIDICA'}>
                                    <Field sm="12" md="6" lg='6' xl='6'
                                        component={Checkbox}
                                        colStyle={{ paddingTop: '10px' }}
                                        label="O destinatário é optante pelo simples nacional"
                                        name="simplesNacional"
                                        onChange={(e) => this.onChange("simplesNacional", e.checked)}
                                        checked={values.simplesNacional}
                                        {...informacoesPermissoes}
                                    />
                                </If>
                            </Grid>
                        </Fieldset>
                        <Fieldset legend="Endereço" className='fieldset-light'>
                            <Grid>
                                <Field sm="6" md="4" lg="4" xl="4"
                                    component={InputCep}
                                    label="CEP"
                                    type="tel"
                                    value={values.enderDest.CEP}
                                    onChange={e => this.onChangeCep(e)}
                                    {...informacoesPermissoes}
                                />
                                <Field sm="6" md="4" lg='4' xl='4'
                                    component={InputField}
                                    size={60}
                                    label='Logradouro'
                                    value={values.enderDest.xLgr}
                                    onChange={(e) => this.onChangeEnderDest('xLgr', e.target.value)}
                                    {...informacoesPermissoes}
                                />
                                <Field sm="6" md="4" lg='4' xl='4'
                                    component={InputField}
                                    size={60}
                                    label='Bairro'
                                    value={values.enderDest.xBairro}
                                    onChange={(e) => this.onChangeEnderDest('xBairro', e.target.value)}
                                    {...informacoesPermissoes}
                                />
                                <Field sm="6" md="4" lg='4' xl='4'
                                    component={InputField}
                                    size={60}
                                    label='Número'
                                    value={values.enderDest.nro}
                                    onChange={(e) => this.onChangeEnderDest('nro', e.target.value)}
                                    {...informacoesPermissoes}
                                />
                                <Field sm="6" md="4" lg='4' xl='4'
                                    component={InputField}
                                    size={60}
                                    label='Complemento'
                                    value={values.enderDest.xCpl}
                                    onChange={(e) => this.onChangeEnderDest('xCpl', e.target.value)}
                                    {...informacoesPermissoes}
                                />
                                <Field sm="6" md="4" lg="4" xl="4"
                                    component={InputMask}
                                    mask={buscarMascaraTelefone(values.telefone)}
                                    label='Telefone'
                                    placeholder={"(  )      -    "}
                                    value={values.enderDest.fone}
                                    onChange={(e) => this.onChangeEnderDest('fone', e.target.value)}
                                    {...informacoesPermissoes}
                                />
                                <Field sm="6" md="4" lg="4" xl="4"
                                    component={SingleSelectPais}
                                    label="País "
                                    onChange={(e) => this.onChangeEnderDest('pais', e)}
                                    value={values.enderDest.pais}
                                    {...informacoesPermissoes}
                                />
                                <Field sm="6" md="4" lg="4" xl="4"
                                    component={SingleSelectMunicipio}
                                    label="Município "
                                    onChange={(e) => this.onChangeEnderDest('municipio', e)}
                                    value={values.enderDest.municipio}
                                    {...informacoesPermissoes}
                                />
                            </Grid>
                        </Fieldset>
                    </FormContent>
                </Form>
            </Modal>
        )
    }
}


export default ModalDadosDestinatario;
