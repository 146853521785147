import React, { Component, Fragment } from "react";
import autoBind from 'react-autobind';
import { detalhesCardStyle } from "../../styles";
import TituloCard from "../../../../../components/TituloCard";

const cardStyle = {
    padding: '7px',
    height: '100%'
}

class CardUtilizacao extends Component {

    constructor(props) {
        super(props)
        autoBind(this);
        this.state = {
            labelsUtilizacao: ['-']
        }
    }

    componentDidUpdate(prevProps) {
        const { planoAtual } = this.props;

        if (prevProps.planoAtual?.id !== planoAtual?.id) {
            this.atualizarLabelsUtilizacao();
        }
    }

    componentDidMount() {
        this.atualizarLabelsUtilizacao();
    }

    atualizarLabelsUtilizacao() {
        const { planoAtual } = this.props
        let labelsUtilizacao = []

        if (planoAtual && planoAtual.utilizado) {
            planoAtual.utilizado.labels.forEach(label => {
                labelsUtilizacao.push(label)
            })
        }
        this.setState({ labelsUtilizacao })
    }

    render() {
        const { className } = this.props
        const { labelsUtilizacao } = this.state
        return (

            <div className={"card-default " + className} style={cardStyle}>
                <TituloCard
                    title='Quantidade utilizada'
                    helpMessage='Quantidade utilizada de cada recurso do sistema'
                />
                <div style={{ height: 'calc(100% - 23px)' }}>
                    <p style={detalhesCardStyle}>
                        {labelsUtilizacao.map(item => <Fragment key={item}>{item} <br /></Fragment>)}
                    </p>
                </div>
            </div>
        );
    }
}

export default CardUtilizacao;
