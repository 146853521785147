import React from 'react';
import Dropzone from 'react-dropzone';
import pdfImage from './images/pdf.svg'
import './styles/index.css'

export default function SecaoArquivoAdicionado({ arquivo, onAdicionar, style, onBaixar, disabled }) {

    function onUpload(files, error) {

        if (error) {
            alert("Problema ao adicionar o documento",
                "Só são permitidos arquivos do tipo PDF e com tamanho máximo de 25 MB")
        } else {
            onAdicionar(files[0])
        }
    }

    return (
        <div style={style}>
            <div
                style={{
                    height: '100%',
                    width: '100%',
                    padding: '10px',
                    display: 'flex',
                    borderRadius: '10px'
                }}
            >
                <img src={pdfImage} alt={arquivo.nome} width='50px' />
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    marginLeft: '5px'
                }}>
                    <span
                        className='secao-arquivo-adicionado-nome-arquivo'
                        style={{
                            color: '#006095',
                            fontSize: '16px',
                            fontWeight: 'bold',
                            verticalAlign: 'top',
                            cursor: 'pointer'
                        }}
                        onClick={onBaixar}
                        title='Abrir arquivo'
                    >{arquivo.name}</span>
                    <Dropzone
                        multiple={false}
                        accept='application/pdf'
                        onDropAccepted={e => onUpload(e, false)}
                        onDropRejected={e => onUpload(e, true)}
                        maxSize={25000000}
                        disabled={disabled}
                    >
                        {({ getRootProps, getInputProps }) => (
                            <span {...getRootProps()}
                                style={{
                                    fontSize: '13px',
                                    color: '#e91224',
                                    fontWeight: 'bold',
                                    width: 'fit-content',
                                    cursor: !disabled ? 'pointer' : 'not-allowed',
                                    opacity: !disabled ? '1' : '0.5'
                                }}
                            >
                                <input {...getInputProps()} />
                            Alterar
                            </span>
                        )}
                    </Dropzone>
                </div>
            </div>



        </div>
    )
}