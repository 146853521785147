export const csosns = {
    CSOSN_102: 'CSOSN_102',
    CSOSN_103: 'CSOSN_103',
    CSOSN_300: 'CSOSN_300',
    CSOSN_400: 'CSOSN_400',
    CSOSN_500: 'CSOSN_500',
}

export const cfop = {
    CFOP_5101: 'CFOP_5101',
    CFOP_5102: 'CFOP_5102',
    CFOP_5103: 'CFOP_5103',
    CFOP_5104: 'CFOP_5104',
    CFOP_5115: 'CFOP_5115',
    CFOP_5405: 'CFOP_5405',
    CFOP_5656: 'CFOP_5656',
    CFOP_5667: 'CFOP_5667',
    CFOP_5993: 'CFOP_5993',
}

export const modal = {
    DESTINATARIO: 1,
    ALIQUOTA: 2,
    ORGANIZACAO: 3
}

export const modalidadeICMSST = {
    PRECO_TABELADO: "PRECO_TABELADO",
    LISTA_NEGATIVA: "LISTA_NEGATIVA",
    LISTA_POSITIVA: "LISTA_POSITIVA",
    LISTA_NEUTRA: "LISTA_NEUTRA",
    MARGEM_VALOR_AGREGADO: "MARGEM_VALOR_AGREGADO",
    PAUTA: "PAUTA"
}

export const optionsIndPres = [
    { label: '0 - Não se aplica', value: 'NAO_SE_APLICA' },
    { label: '1 - Operação presencial', value: 'OPERACAO_PRESENCIAL' },
    { label: '2 - Operação pela internet', value: 'OPERACAO_PELA_INTERNET' },
    { label: '3 - Operação por teleatendimento', value: 'OPERACAO_POR_TELEATENDIMENTO' },
    { label: '5 - Operação presencial fora do estabelecimento', value: 'OPERACAO_PRESENCIAL_FORA_DO_ESTABELECIMENTO' },
    { label: '9 - Outros', value: 'OUTROS' },
]

export const optionsOrigemIcms = [
    { label: '0 - Nacional, exceto as indicadas nos código 3, 4, 5 e 8', value: 'NACIONAL' },
    { label: '1 - Estrangeira - Importação direta, exceto a indicada no código 6', value: 'ESTRANGEIRA' },
    { label: '2 - Estrangeira - Adquirida no mercado interno, exceto a indicada no código 7', value: 'ESTRANGEIRA_ADQUIRIDA_MERCADO_INTERNO' },
    { label: '3 - Nacional, mercadoria ou bem com Conteúdo de Importação superior a 40% e inferior ou igual a 70%', value: 'NACIONAL_IMPORTACAO_ENTRE_40_E_70_PORCENTO' },
    { label: '4 - Nacional, cuja produção tenha sido feita em conformidade com os processos produtivos básicos de que tratam as legislações citadas nos Ajustes', value: 'NACIONAL_COM_PROCESSO_PRODUTIVO' },
    { label: '5 - Nacional, mercadoria ou bem com Conteúdo de Importação inferior ou igual a 40%', value: 'NACIONAL_IMPORTACAO_INFERIOR_40_PORCENTO' },
    { label: '6 - Estrangeira - Importação direta, sem similar nacional, constante em lista da CAMEX e gás natural', value: 'ESTRANGEIRA_IMPORTACAO_DIRETA' },
    { label: '7 - Estrangeira - Adquirida no mercado interno, sem similar nacional, constante em lista da CAMEX e gás natural', value: 'ESTRANGEIRA_ADQUIR_MERCADO_INTERNO_SEM_SIMILAR_NAC' },
    { label: '8 - Nacional, mercadoria ou bem com Conteúdo de Importação superior a 70%', value: 'NACIONAL_IMPORTACAO_SUPERIOR_70_PORCENTO' },
];

export const optionsCsosn = [
    { label: '102 - Tributada pelo Simples Nacional sem Permissão de Crédito', value: csosns.CSOSN_102 },
    { label: '103 - Isenção do ICMS no Simples Nacional para Faixa de Receita Bruta', value: csosns.CSOSN_103 },
    { label: '300 - Imune (Imunidade do ICMS)', value: csosns.CSOSN_300 },
    { label: '400 - Não Tributada pelo Simples Nacional', value: csosns.CSOSN_400 },
    { label: '500 - ICMS Cobrado Anteriormente por Substituição Tributária (Substituído) ou por Antecipação', value: csosns.CSOSN_500 },
]

export const optionsCfop = [
    {label:'5.101 - Venda de produção do estabelecimento', value: cfop.CFOP_5101},
    {label:'5.102 - Venda de mercadoria adquirida ou recebida de terceiros', value: cfop.CFOP_5102},
    {label:'5.103 - Venda de produção do estabelecimento, efetuada fora do estabelecimento', value: cfop.CFOP_5103},
    {label:'5.104 - Venda de mercadoria adquirida ou recebida de terceiros, efetuada fora do estabelecimento', value: cfop.CFOP_5104},
    {label:'5.115 - Venda de mercadoria adquirida ou recebida de terceiros, recebida anteriormente em consignação mercantil', value: cfop.CFOP_5115},
    {label:'5.405 - Venda de mercadoria adquirida ou recebida de terceiros em operação com mercadoria sujeita ao regime de substituição tributária, na condição de contribuinte substituído', value: cfop.CFOP_5405},
    {label:'5.656 - Venda de combustível ou lubrificante adquirido ou recebido de terceiros destinado a consumidor ou usuário final', value: cfop.CFOP_5656},
    {label:'5.667 - Venda de combustível ou lubrificante a consumidor ou usuário final estabelecido em outra Unidade da Federação', value: cfop.CFOP_5667},
    {label:'5.993 - Saídas ou prestações de serviço para o Estado', value: cfop.CFOP_5993},
]

export const optionsAliquotasFCP = [
    { label: 'Não se aplica', value: 0 },
    { label: '1%', value: 1 },
    { label: '1,5%', value: 1.5 },
    { label: '2%', value: 2 },
];

export const optionsAliqInterUFEnvolvidas = [
    { label: '4%', value: 4 },
    { label: '7%', value: 7 },
    { label: '12%', value: 12 },
];

export const optionsModalidadeDeterminacaoST = [
    { label: 'Preço tabelado ou máximo sugerido', value: "PRECO_TABELADO" },
    { label: 'Lista Negativa (valor)', value: "LISTA_NEGATIVA" },
    { label: 'Lista Positiva (valor)', value: "LISTA_POSITIVA" },
    { label: 'Lista Neutra (valor)', value: "LISTA_NEUTRA" },
    { label: 'Margem Valor Agregado (%)', value: "MARGEM_VALOR_AGREGADO" },
    { label: 'Pauta (valor)', value: "PAUTA" },
];

export const optionsModalidadeDeterminacaoICMS = [
    { label: 'Margem Valor Agregado', value: "MARGEM_VALOR_AGREGADO" },
    { label: 'Pauta', value: "PAUTA" },
    { label: 'Preço Tabelado Máx', value: "PRECO_TABELADO_MAX" },
    { label: 'Valor da operação', value: "VALOR_OPERACAO" },
];