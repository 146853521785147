import React, { Component } from 'react';
import { ProgressBar } from 'primereact/progressbar';
import { formatarMonetario } from '../../../../../../../../../common/mascara';
import { FaBalanceScale } from 'react-icons/fa';
import FalhaAoCarregarAsInformacoes from '../../../../../FalhaAoCarregarAsInformacoes';
import '../styles/index.css';
import { Financas } from '../../../../../../../../util/constantes'

const messages = {
    helpMessage: "Receitas no período - Despesas no período"
}

export default class CardBalanco extends Component {

    montarTituloCard() {
        return (
            <div className="card-resultado-financeiro-dashboard-titulo">
                <div className="card-resultado-financeiro-dashboard-titulo-descricao" title={messages.helpMessage}>
                    Balanço (Receitas - Despesas)
                </div>
            </div>
        );
    }

    render() {
        if (this.props.loading) {
            return (
                <div className="card-resultado-financeiro-dashboard">
                    {this.montarTituloCard()}
                    <div className="card-resultado-financeiro-dashboard-content">
                        <ProgressBar mode="indeterminate" style={{ height: '3px', width: '100%', margin: '10px 0px' }} />
                    </div>
                </div>
            )
        } else if (this.props.error) {
            return (
                <div className="card-resultado-financeiro-dashboard">
                    {this.montarTituloCard()}
                    <div className="card-resultado-financeiro-dashboard-content">
                        <FalhaAoCarregarAsInformacoes />
                    </div>
                </div>
            )
        } else {
            const { saldoTotal } = this.props;

            const colorMoney = (saldoTotal < 0) ? Financas.cores.vermelho : Financas.cores.verde;

            return (
                <div className="card-resultado-financeiro-dashboard" style={{ height: '100%' }}>
                    {this.montarTituloCard()}
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: '100%' }}>
                        <div style={{ padding: '5px' }}>
                            <FaBalanceScale size={40} color={colorMoney} />
                        </div>
                        <div
                            title="Valor total a receber - Valor total a pagar"
                            style={{ color: colorMoney, padding: '5px', fontSize: '25px', fontWeight: 500 }}
                        >
                            {formatarMonetario(saldoTotal)}
                        </div>
                    </div>
                </div>
            )
        }
    }
}
