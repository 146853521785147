import { isValidCnpj } from "@brazilian-utils/validators";
import stringMask from "string-mask";
import { manterApenasNumeros } from "../../../../../../../common/mascara";
import { uuid } from "../../../../../../../common/uuid";
import { IndPres } from "../../../util/constantes";
import { validarPlaca } from "./validacoesTransp";

export function validarTransmissao(props) {
    const { values } = props;

    let validacoes = [];

    if (values && values.dest.tipo === 'ESTRANGEIRO') {
        if (!values.exporta.ufSaidaPais) {
            validacoes.push({ id: uuid(), mensagem: "Campo 'UF do veículo' é obrigatório para destinatário estrangeiro." });
        }
        if (!values.exporta.xLocExporta) {
            validacoes.push({ id: uuid(), mensagem: "Campo 'Local de Embarque' é obrigatório para destinatário estrangeiro. " });
        }
    }

    if (values && (values.total.ICMSTot.vDesc > values.total.ICMSTot.vProd)) {
        validacoes.push({ id: uuid(), mensagem: "Campo 'Valor do desconto' é maior que o valor dos produtos." });
    }

    if (values && values.dest.enderDest.fone && !new stringMask("(00) 00000-0000").validate(manterApenasNumeros(values.dest.enderDest.fone)) && !new stringMask("(00) 0000-0000").validate(manterApenasNumeros(values.dest.enderDest.fone))) {
        validacoes.push({ id: uuid(), mensagem: 'Destinatário com número de telefone inválido' });
    }

    if (values && values.transp.utilizaTransportador) {
        if (values && values.transp && values.transp.veicTransp) {
            if (!validarPlaca(values.transp.veicTransp.placa)) {
                validacoes.push({ id: uuid(), mensagem: "Campo 'Placa do veículo' está em um formato desconhecido." });
            }
            if (!values.transp.veicTransp.UF) {
                validacoes.push({ id: uuid(), mensagem: "Campo 'UF do veículo' é obrigatório." });
            }
        }
    }

    if (values && (values.indPres === IndPres.OPERACAO_PELA_INTERNET || values.indPres === IndPres.OPERACAO_POR_TELEATENDIMENTO)) {
        if (values && values.infIntermed.idCadIntTran === null) {
            validacoes.push({ id: uuid(), mensagem: "Campo Intermediador é obrigatório" })
        }
    }

    if (values && (values.indPres === IndPres.OPERACAO_PELA_INTERNET || values.indPres === IndPres.OPERACAO_POR_TELEATENDIMENTO)) {
        if (values && !values.infIntermed.CNPJ) {
            validacoes.push({ id: uuid(), mensagem: "Campo CNPJ do intermediador é obrigatório" })
        } else if (values.infIntermed.CNPJ && !isValidCnpj(manterApenasNumeros(values.infIntermed.CNPJ))) {
            validacoes.push({ id: uuid(), mensagem: "CNPJ do intermediador inválido" })
        }
    }


    return validacoes;
}
