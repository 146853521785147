import React, { Component } from 'react';
import ModalProduto from '../../../views/cadastros/Produtos/Modal';
import autoBind from 'react-autobind';
import { recursos, permissoes } from '../../../common/constantes/autorizacao';
import SingleSelect from '../SingleSelect';
import propTypes from 'prop-types'
import { usuarioPossuiPermissao } from '../../../common/autenticacao';
import { converterProdutoNovoParaListagemDaNfce } from '../../../views/fiscal/vendas/NFCe/Form/utils/converterProdutosParaModal';

class SingleSelectProduto extends Component {
    constructor(props) {
        super(props);

        autoBind(this);

        this.state = {
            visible: false,
            podeInserir: usuarioPossuiPermissao(recursos.PRODUTOS, permissoes.INSERIR),
            podeVisualizar: usuarioPossuiPermissao(recursos.PRODUTOS, permissoes.VISUALIZAR),
        }
    }

    buscarUrlPesquisa(pesquisa, page) {
        return `${this.props.url}?query=nome=contains="*${pesquisa}*";situacao=="ATIVO"${this.props.filtroAdicionalUrl}&page=${page}&size=50&sort=sku`;
    }

    mostrarModal() {
        this.setState({ visible: !this.state.visible });
    }

    esconderModal() {
        this.setState({ ...this.state, visible: false })
    }

    setarNovoRegistroNoSelect(novoRegistro) {
        const registroNovoConvertido = converterProdutoNovoParaListagemDaNfce(novoRegistro)
        if (novoRegistro) {
            this.props.onChange({ label: novoRegistro.sku + ' - ' + novoRegistro.nome, value: novoRegistro.id, registro: registroNovoConvertido })
        }
    }

    onHide(novoRegistro) {
        this.setarNovoRegistroNoSelect(novoRegistro)
        this.esconderModal()
    }

    montarLabel(row) {
        return row.sku + ' - ' + row.nome
    }

    render() {

        const { podeInserir, podeVisualizar } = this.state
        const podeInserirEVisualizar = podeInserir && podeVisualizar
        return (
            <React.Fragment>
                <ModalProduto
                    visible={this.state.visible}
                    onHide={this.onHide}
                    tipoPredefinido={this.props.tipoPredefinido}
                    service={this.props.service}
                />
                <SingleSelect
                    titleBotaoNovo="Adicionar novo produto ou serviço"
                    buscarUrlPesquisa={this.buscarUrlPesquisa}
                    montarLabel={this.montarLabel}
                    onClickModal={this.mostrarModal}
                    {...this.props}
                    disabledButton={!podeInserirEVisualizar}
                    onChange={this.props.onChange}
                    esconderBotao={this.props.esconderBotao}
                />
            </React.Fragment>
        );
    }
}

SingleSelectProduto.defaultProps = {
    label: 'Produto ou serviço',
    filtroAdicionalUrl: '',

}

SingleSelectProduto.propTypes = {
    /** Url do servidor em que as requisições de busca deverão ser efetuadas: Ex:  `${services.GESTOR}/v1/usuarios/relacoes/pessoas`*/
    url: propTypes.string.isRequired,
    /** Filtor adicional a ser passado para o RSQL ex: ";tipo=="PRODUTO";controlarEstoque==true"*/
    filtroAdicionalUrl: propTypes.string,
}

export default SingleSelectProduto;
