import { Condicoes } from "../../util/constantes"

export function converterNFceParaApi(valuesFormulario) {
    const values = {
        id: valuesFormulario.id ?? null,
        numeroVenda: valuesFormulario.numeroVenda,
        status: valuesFormulario.statusNfce,
        indPres: 'OPERACAO_PRESENCIAL',
        indFinal: 'CONSUMIDOR_FINAL',
        dest: converterDestParaApi(valuesFormulario.dest),
        det: converterProdutosParaApi(valuesFormulario.produtos),
        pag: converterPagamentosParaApi(valuesFormulario.pag),
        total: valuesFormulario.total,
        descontoPendenteRateio: valuesFormulario.descontoPendenteRateio,
    }
    return values
}

function converterDestParaApi(dest) {
    if (!dest?.CNPJ && !dest?.CPF) {
        return null
    }

    if (!dest) {
        return null
    }
    return {
        cliente: dest.cliente ?? null,
        tipo: dest.tipo,
        CNPJ: dest.CNPJ,
        CPF: dest.CPF,
        xNome: dest.xNome
    }
}

function converterProdutosParaApi(produtos) {
    let produtoConvertido = []

    produtos.forEach(produto => {
        produtoConvertido.push({
            id: produto.id ?? null,
            prod: {
                idProd: produto.idProd,
                xProd: produto.produto.slice(0, 120),
                cEAN: produto.codigoBarras,
                idNCM: produto.ncm.value,
                idCFOP: produto.cfop.value,
                idCom: produto.unidadeMedida.value,
                idCEST: produto.cest.value,
                qCom: produto.quantidade,
                vUnCom: produto.valorUnitario,
                vProd: produto.quantidade * produto.valorUnitario,
                vDesc: produto.descontoTotalProduto,
            },
            imposto: {
                icms: {
                    orig: produto.origem,
                    csosn: produto.csosn,
                }
            }
        })
    })

    return produtoConvertido
}

function converterPagamentosParaApi(pag) {
    if (!pag) {
        return {
            condicao: Condicoes.A_VISTA,
        }
    }
    return {
        id: pag.id ?? null,
        valorRecebido: pag.valorRecebido,
        valorTroco: pag.valorTroco,
        condicao: pag.condicao,
        quantidadeParcelas: pag.quantidadeParcelas,
        categoria: pag.categoria?.value ? { id: pag.categoria.value } : null,
        parcelas: pag.parcelas
    }
}