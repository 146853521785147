import React, { Component } from 'react';
import autoBind from 'react-autobind';
import SingleSelect from '../SingleSelect';
import { services } from '../../../common/constantes/api';

class SingleSelectUnidadeMedida extends Component {
    constructor(props) {
        super(props);
        autoBind(this);
    }

    buscarUrlPesquisa(pesquisa, page) {
        return `${services.GESTOR}/v1/unidades_medida?query=descricao=contains="*${pesquisa}*",unidade=contains="*${pesquisa}*"&page=${page}&size=50`
    }

    montarLabel(row) {
        return row.unidade + ' - ' + row.descricao
    }

    render() {
        return (
            <React.Fragment>
                <SingleSelect
                    buscarUrlPesquisa={this.buscarUrlPesquisa}
                    montarLabel={this.montarLabel}
                    label={this.props.label}
                    {...this.props}
                    onChange={this.props.onChange}
                    esconderBotao
                />
            </React.Fragment>
        );
    }
}

SingleSelectUnidadeMedida.defaultProps = {
    label: 'Unidade de medida'
}

export default SingleSelectUnidadeMedida;