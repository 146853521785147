import React, { Component } from 'react';
import autoBind from 'react-autobind';
import SingleSelect from '../SingleSelect';
import { services } from '../../../common/constantes/api';
import { permissoes, recursos } from '../../../common/constantes/autorizacao';
import { usuarioPossuiPermissao } from '../../../common/autenticacao';
import ModalPlanosAdministracao from '../../../views/administracao/Planos/Modal';

class SingleSelectPlano extends Component {
    constructor(props) {
        super(props);

        autoBind(this);

        this.state = {
            visible: false,
            podeInserir: usuarioPossuiPermissao(recursos.PLANOS_ADMINISTRACAO, permissoes.INSERIR),
            podeVisualizar: usuarioPossuiPermissao(recursos.PLANOS_ADMINISTRACAO, permissoes.VISUALIZAR),
        }
    }

    buscarUrlPesquisa(pesquisa, page) {
        return `${this.props.url}?query=descricao=contains="*${pesquisa}*"&page=${page}&size=50`
    }

    montarLabel(row) {
        return row.descricao;
    }

    mostrarModal() {
        this.setState({ visible: true });
    }
    
    esconderModal() {
        this.setState({ visible: false })
    }    

    onHide(novoRegistro) {
        this.setarNovoRegistroNoSelect(novoRegistro)
        this.esconderModal()
    }

    setarNovoRegistroNoSelect(novoRegistro) {
        if (novoRegistro) {
            this.props.onChange({
                label: this.montarLabel(novoRegistro),
                value: novoRegistro.id
            })
        }
    }

    render() {
        const { podeInserir, podeVisualizar } = this.state;
        const podeInserirEVisualizar = (podeInserir && podeVisualizar);

        return (
            <>
                <ModalPlanosAdministracao
                    service={services.GESTOR}
                    visible={this.state.visible}
                    onHide={this.onHide}
                />
                <SingleSelect
                    titleBotaoNovo="Adicionar novo plano"
                    buscarUrlPesquisa={this.buscarUrlPesquisa}
                    montarLabel={this.montarLabel}
                    onClickModal={this.mostrarModal}
                    {...this.props}
                    name={this.props.field.name}
                    disabledButton={!podeInserirEVisualizar}
                    onChange={this.props.onChange}
                />
            </>
        );
    }
}

SingleSelectPlano.defaultProps = {
    label: 'Plano'
}

export default SingleSelectPlano;