import React, { Component } from 'react';
import Dropdown from '../../components/Select/Dropdown';
import autoBind from 'react-autobind'
import { MdClose } from 'react-icons/md'
import Paper from '../../components/Paper';
import NormalButton from '../../components/Button';
import { removerElementoPorId } from '../../common/array';
import { buscarOpcoes, buscarOptionsSelect, convertToRSQL, buscarDescricaoFiltro } from './util/funcoes.js';
import CampoPersonalizado from '../../components/CampoPersonalizado';
import { Field, withFormik } from 'formik';
import propTypes from 'prop-types'
import { Sidebar } from 'primereact/sidebar';
import './style/index.css'
import If from '../If';
import { removerCaracteres } from '../../common/manipulacaoDeString';
import { caracteresEspeciaisRSQL } from '../../common/manipulacaoDeString/consts';
import Col from '../Col';
import Grid from '../Grid';


class PesquisaAvancada extends Component {

    constructor(props) {
        super(props);

        autoBind(this);

        this.state = {
            filtros: [],
            filtroSelecionado: null,
            visible: false
        }
    }

    renderizarFiltros() {
        return this.state.filtros && this.state.filtros.map((filtro, index) => {
            return this.renderSelectOperacoes(filtro, index);
        })
    }

    renderSelectOperacoes(filtro, index) {
        return (
            <div key={index} style={{ padding: '7px 0px', borderBottom: '1px solid #9999', borderTop: '1px solid #9999' }}>
                {this.montarFiltroHeader(filtro)}
                <Field col="12"
                    component={Dropdown}
                    label="Condição"
                    colStyle={{ padding: '0px' }}
                    name={filtro.name}
                    value={filtro.operacao}
                    options={buscarOpcoes(filtro)}
                    onChange={operacaoSelecionada => {
                        this.adicionarOperacaoAoFiltro(operacaoSelecionada, filtro.name)
                    }}
                />
                {filtro.operacao && this.renderizarCampoFiltro(filtro)}
            </div>
        )
    }

    adicionarFiltro(filtro) {
        this.setState({
            filtros: [
                ...this.state.filtros, { ...filtro, value: '' }
            ],
            filtroSelecionado: filtro
        })
        this.props.setFieldValue('filtro', filtro)
    }

    removerFiltro(el) {
        const { filtros } = this.state;
        const novaLista = removerElementoPorId(filtros, el, 'name')
        this.setState({
            filtros: novaLista
        })
    }

    montarFiltroHeader(filtro) {
        return (
            <div style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginBottom: "8px"
            }}>
                <span style={{ fontSize: '15px', fontWeight: 'bold' }}>{filtro.label}</span>
                <span
                    style={{ cursor: 'pointer' }}
                    onClick={() => this.removerFiltro(filtro)}
                >
                    <MdClose size="20px" />
                </span>
            </div>
        )
    }

    adicionarOperacaoAoFiltro(operacaoSelecionada, valueFiltro) {
        var novosFiltros = this.state.filtros.map(function (filtro) {
            if (filtro.name === valueFiltro) {
                filtro.operacao = operacaoSelecionada.value
                filtro.operacaoLabel = operacaoSelecionada.label
                filtro.value = ""
            }
            return filtro;
        })
        this.setState({ filtros: novosFiltros });
    }

    acionarInputAoFiltro(e) {
        var novosFiltros = this.state.filtros.map(filtro => {
            if (filtro.name === e.id) {
                if (filtro.type === "decimal" || filtro.type === "integer" || filtro.type === 'date') {
                    filtro.value = e.value
                } else {
                    filtro.value = removerCaracteres(e.value || "", caracteresEspeciaisRSQL)
                }
            }
            return filtro;
        })
        this.setState({ filtros: novosFiltros });
    }

    acionarInputEntreInicialAoFiltro(value, filtroName) {
        var novosFiltros = this.state.filtros.map(function (filtro) {
            if (filtro.name === filtroName) {
                filtro.valueInicial = value
            }
            return filtro;
        })
        this.setState({ filtros: novosFiltros });
    }

    acionarInputEntreFinalAoFiltro(value, filtroName) {
        var novosFiltros = this.state.filtros.map(function (filtro) {
            if (filtro.name === filtroName) {
                filtro.valueFinal = value
            }
            return filtro;
        })
        this.setState({ filtros: novosFiltros });
    }

    renderizarCampoFiltro(filtro) {
        switch (filtro.operacao) {
            case 'entre': return this.inputFieldEntre(filtro)
            default: return this.inputField(filtro)
        }
    }

    inputField(filtro) {
        return (
            <CampoPersonalizado
                sm="12" md="12" lg="12" xl="12"
                id={filtro.name}
                name={filtro.name}
                options={filtro.optionSelect}
                label="Valor"
                type={filtro.type}
                value={{ value: filtro.value }}
                hiddenOptionsButton
                touched
                colStyle={{ padding: '5px 0px' }}
                onChange={this.acionarInputAoFiltro}
            />
        )
    }

    inputFieldEntre(filtro) {
        return (
            <Col col="12">
                <Grid>
                    <CampoPersonalizado
                        col="6" sm="6" md="6" lg="6" xl="6"
                        id={filtro.name}
                        name={filtro.name}
                        options={filtro.optionSelect}
                        label="Valor inicial"
                        type={filtro.type}
                        value={{ value: filtro.valueInicial }}
                        hiddenOptionsButton
                        touched
                        colStyle={{ padding: '5px 2px' }}
                        onChange={(e) => this.acionarInputEntreInicialAoFiltro(e.value, filtro.name)}
                    />
                    <CampoPersonalizado
                        col="6" sm="6" md="6" lg="6" xl="6"
                        id={filtro.name + "final"}
                        name={filtro.name + "final"}
                        options={filtro.optionSelect}
                        label="Valor final"
                        type={filtro.type}
                        value={{ value: filtro.valueFinal }}
                        touched
                        colStyle={{ padding: '5px 0px' }}
                        hiddenOptionsButton
                        onChange={(e) => this.acionarInputEntreFinalAoFiltro(e.value, filtro.name)}
                    />
                </Grid>
            </Col>
        )
    }


    componentDidUpdate(prevProps, prevState) {

        const { onChangeDescricaoFiltro, liparFiltro } = this.props
        const { filtros } = this.state

        if (filtros !== prevState.filtros && onChangeDescricaoFiltro) {
            onChangeDescricaoFiltro(buscarDescricaoFiltro(filtros))
        }

        if ((prevProps.liparFiltro !== liparFiltro) && liparFiltro) {
            this.onLimparClick();
        }

    }

    onPesquisarClick() {
        const rsql = convertToRSQL(this.state.filtros)

        this.setState({ visible: false })

        this.props.onPesquisarClick(rsql)
    }

    onLimparClick() {
        const rsql = convertToRSQL([])

        this.setState({ filtros: [], filtroSelecionado: null })

        this.props.onPesquisarClick(rsql)
    }

    render() {
        return (
            <>
                <Sidebar
                    style={{ width: '310px', padding: '2px', overflowY: 'scroll', height: '100%' }}
                    blockScroll
                    visible={this.state.visible}
                    position="right"
                    baseZIndex={1000000}
                    onHide={() => {
                        this.setState({ visible: false })
                        this.props.onChangeFiltroRsql(convertToRSQL(this.state.filtros))
                    }}
                >
                    <Paper header="Pesquisa avançada">
                        <div style={{ textAlign: 'end' }}>
                            <span
                                style={{ fontSize: '11px', color: '#006095', cursor: 'pointer' }}
                                onClick={this.onLimparClick}
                            >
                                Limpar filtros aplicados
                            </span>
                        </div>

                        <Field col='12'
                            component={Dropdown}
                            name="adicionar_filtro"
                            label='Adicionar filtro por'
                            value={this.state.filtroSelecionado}
                            showClear={false}
                            colStyle={{ padding: '0px 0px 14px 0px' }}
                            onChange={(e) => this.adicionarFiltro(e)}
                            options={buscarOptionsSelect(this.props.optionsFiltros, this.state.filtros)}
                        />
                        {this.renderizarFiltros()}
                        <NormalButton
                            style={{ marginTop: '10px', width: '100%' }}
                            label="Pesquisar"
                            icon="fa fa-search"
                            onClick={this.onPesquisarClick}
                        />
                    </Paper>
                </Sidebar>
                <NormalButton
                    label="Pesquisa avançada"
                    icon="fa fa-filter"
                    style={{ margin: '0' }}
                    onClick={() => this.setState({ visible: !this.state.visible })}
                >
                    <If test={this.state.filtros.length > 0} >
                        <i className="filtro-notification" />
                    </If>
                </NormalButton>
            </>
        )
    }
}

PesquisaAvancada = withFormik({
    enableReinitialize: true,
    validateOnChange: false,

    handleSubmit: () => { }
})(PesquisaAvancada);


PesquisaAvancada.propTypes = {
    /** Array com informações dos filtros. 
     * optionsFiltroAvancado = [ { label: 'Codigo', name: 'codigo', type: 'string' } ] exemplo utilizado em: D:\Projetos\MsysGestorFrontend\web\src\views\documentodigital\documentos\index.jsx
    */
    optionsFiltros: propTypes.array,
    /**  Método a ser executado ao clicar em aplicar filtros */
    onPesquisarClick: propTypes.func,
    /**  Método para atualizar a string do filtro  */
    onChangeFiltroRsql: propTypes.func,

    /**  Método para atualizar a string do filtro  */
    onChangeDescricaoFiltro: propTypes.func,



}


export default PesquisaAvancada;
