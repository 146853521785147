import React, { Component } from 'react';
import autoBind from 'react-autobind';
import SingleSelect from '../SingleSelect';
import propTypes from 'prop-types'
import { services } from '../../../common/constantes/api';

class SingleSelectEstado extends Component {
    constructor(props) {
        super(props);

        autoBind(this);

        this.state = {
            visible: false,
        }
    }

    buscarUrlPesquisa(pesquisa, page) {
        return `${services.GESTOR}/v1/estados/resumo?query=nome=contains="*${pesquisa}*",sigla=contains="*${pesquisa}*"&page=${page}&size=50&sort=nome`;
    }

    montarLabel(row) {
        return row.nome + ' - ' + row.sigla;
    }

    render() {
        return (
            <React.Fragment>
                <SingleSelect
                    buscarUrlPesquisa={this.buscarUrlPesquisa}
                    montarLabel={this.montarLabel}
                    label={this.props.label}
                    {...this.props}
                    onChange={this.props.onChange}
                    esconderBotao
                />
            </React.Fragment>
        );
    }
}

SingleSelectEstado.defaultProps = {
    label: 'Estado'
}

SingleSelectEstado.propTypes = {
    service: propTypes.string
}

export default SingleSelectEstado;
