import { get } from '../../../../../../../../common/requisicoes';
import { services } from '../../../../../../../../common/constantes/api';
import { formatISO, isValid, isDate } from 'date-fns';

export function buscarProdutosMaisVendidos(dataInicial, dataFinal, page, onSuccess, onError) {
    let dataInicialFormatada = formatISO(new Date(1900, 1, 1), {representation: 'date'});
    let dataFinalFormatada = formatISO(new Date(2100, 1, 1), {representation: 'date'});

    if (isDate(dataInicial) && isValid(dataInicial)) {
        dataInicialFormatada = formatISO(dataInicial, {representation: 'date'})
    }
    if (isDate(dataFinal) && isValid(dataFinal)) {
        dataFinalFormatada = formatISO(dataFinal, {representation: 'date'})
    }
    return get(`${services.GESTOR}/v1/dashboards/vendas/vendas_por_produto?dataInicial=${dataInicialFormatada}&dataFinal=${dataFinalFormatada}&page=${page}&sort=valorVendas,desc&size=10`, null, onSuccess, onError, false);
}
