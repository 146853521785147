import React, { Component } from 'react'
import { estadosCadastro } from '../../../../../common/constantes/autorizacao'
import { removerElemento, copiarObjeto } from '../../../../../common/array'
import { Column } from 'primereact/column'
import ButtonExcluirTable from '../../../../../components/Button/ButtonExcluirTable'
import ToolbarDetails from '../../../../../components/Toolbar'
import ButtonNovo from '../../../../../components/Button/ButtonNovo'
import autoBind from 'react-autobind'
import ToolbarItensLeft from '../../../../../components/Toolbar/ToolbarItensLeft'
import Modal from '../../../../../components/Modal'
import PessoaTelefoneForm from './Form'
import If from '../../../../../components/If'
import ButtonEditarTable from '../../../../../components/Button/ButtonEditarTable'
import { jaExisteMesmoTelefone } from './util/validacoes'
import { GoBookmark } from 'react-icons/go'
import { formatarTelefone } from '../../../../../common/mascara'
import { DataTable } from 'primereact/components/datatable/DataTable'
import NenhumRegistroEncontrado from '../../../../../components/NenhumRegistroEncontrado'

class PessoaTelefone extends Component {

    constructor(props) {
        super(props);

        autoBind(this);

        this.state = {
            registroSelecionado: null,
            modalFormVisible: false
        }
    }

    novo() {
        this.setState({ modalFormVisible: true, registroSelecionado: null })
    }

    onEditar(row) {
        this.setState({ registroSelecionado: row, modalFormVisible: true })
    }

    renderOpcoes(row) {
        const { estadoCadastro } = this.props;

        const desabilitarCampos = (estadoCadastro === estadosCadastro.EDICAO && !this.props.podeEditar);
        return (
            <>
                <ButtonEditarTable
                    onClick={() => this.onEditar(row)}
                />
                <ButtonExcluirTable
                    onClick={() => this.excluirRegistro(row)}
                    podeExcluir={!desabilitarCampos} />
            </>
        )
    }

    hideModal() {
        this.setState({ modalFormVisible: false, registroSelecionado: null })
    }

    
    atualizarRegistro(dadosFormulario) {
        const telefones = copiarObjeto(this.props.value);
        
        const result = telefones && telefones.map(item => {
            if (item.idTemporario && item.idTemporario === dadosFormulario.idTemporario) {
                return dadosFormulario
            } else if (item.id && item.id === dadosFormulario.id) {
                return dadosFormulario
            }
            
            if (dadosFormulario.favorito) {
                item.favorito = false
            }
            
            return item;
        })
        
        this.setState({ registroSelecionado: dadosFormulario })
        this.props.onChange(result)
    }
    
    inserirRegistro(dadosFormulario) {
        let telefones = copiarObjeto(this.props.value)
        this.props.onChange(this.mergeTelefoneIntoTelefones(dadosFormulario, telefones));
        this.setState({ registroSelecionado: dadosFormulario })
    }
    
    mergeTelefoneIntoTelefones(telefone, telefones) {
        if (telefone.favorito) {
            telefones.map((tempTelefone) => {
                return tempTelefone.favorito = false
            })
        }
        return [...telefones, telefone]
    }
    
    excluirRegistro(dadosFormulario) {
        this.props.onChange(removerElemento(this.props.value, dadosFormulario))
        this.setState({ registroSelecionado: null })
    }
    
    validarMesmoTelefone(telefone) {
        return jaExisteMesmoTelefone(this.props.value, telefone);
    }

    marcarComoFavorito() {
        const telefones = copiarObjeto(this.props.value)
        let existemFavoritos = false;

        telefones.forEach(telefone => {
            if (telefone.favorito) {
                existemFavoritos = true
            }
        });

        return !existemFavoritos
    }

    renderNumero(row) {
        return (
            <span style={{ display: "flex", alignItems: "center" }}>
                {row.favorito ? <GoBookmark size={22} color="#fbc02d" title="Número favorito" /> : null}
                {formatarTelefone(row.numero)}
            </span>
        )
    }
    
    render() {
        const { estadoCadastro } = this.props;
        const desabilitarCampos = (estadoCadastro === estadosCadastro.EDICAO && !this.props.podeEditar);
        
        return (
            <>
                <Modal
                    visible={this.state.modalFormVisible}
                    header='Cadastro de telefone'
                    onHide={this.hideModal}
                    >
                    <If test={this.state.modalFormVisible}>
                        <PessoaTelefoneForm
                            registroSelecionado={this.state.registroSelecionado}
                            onNovoClick={() => this.setState({ registroSelecionado: null })}
                            excluirRegistro={this.excluirRegistro}
                            inserirRegistro={this.inserirRegistro}
                            atualizarRegistro={this.atualizarRegistro}
                            marcarComoFavorito={this.marcarComoFavorito}
                            podeEditar={!desabilitarCampos}
                            podeExcluir={!desabilitarCampos}
                            onHide={this.hideModal}
                            validarMesmoTelefone={this.validarMesmoTelefone}
                        />
                    </If>
                </Modal>
                <ToolbarDetails>
                    <ToolbarItensLeft style={{ paddingLeft: '0px' }}>
                        <ButtonNovo
                            label="Novo telefone"
                            enableShortCut={false}
                            onClick={this.novo}
                            podeInserir={!desabilitarCampos}
                        />
                    </ToolbarItensLeft>
                </ToolbarDetails>
                <DataTable
                    className="table-light"
                    responsive
                    value={this.props.value}
                    emptyMessage={<NenhumRegistroEncontrado message="Nenhum telefone encontrado" />}
                >
                    <Column
                        field="numero"
                        body={this.renderNumero}
                        header="Número"
                        sortable={true}
                    />
                    <Column
                        field="operadora"
                        header="Operadora"
                        sortable={true}
                    />
                    <Column
                        body={this.renderOpcoes}
                        header="Ações"
                        style={{ width: '7em' }}
                    />
                </DataTable>
            </>
        )
    }
}

export default PessoaTelefone
