import { Operacao, Status } from './constantes';

export function montarMensagemToast(status, operacao) {
    switch (status) {
        case Status.VENDA: {
            if (operacao === Operacao.EDITAR) {
                return "Venda alterada com sucesso";
            } else if (operacao === Operacao.EXCLUIR) {
                return "Venda excluída com sucesso";
            } else if (operacao === Operacao.INSERIR) {
                return "Venda incluída com sucesso";
            }
            break;
        }
        case Status.ORCAMENTO: {
            if (operacao === Operacao.EDITAR) {
                return "Orçamento alterado com sucesso";
            } else if (operacao === Operacao.EXCLUIR) {
                return "Orçamento excluído com sucesso";
            } else if (operacao === Operacao.INSERIR) {
                return "Orçamento incluído com sucesso";
            }
            break;
        }
        case Status.CANCELADA: {
            if (operacao === Operacao.EDITAR) {
                return "Registro alterado com sucesso";
            } else if (operacao === Operacao.EXCLUIR) {
                return "Registro excluído com sucesso";
            } else if (operacao === Operacao.INSERIR) {
                return "Registro incluído com sucesso";
            }
            break;
        }
        default: break;
    }








}