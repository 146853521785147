import React, { Component } from 'react';
import * as Yup from "yup";
import autoBind from 'react-autobind';
import Modal from '../../../../../components/Modal';
import { withRouter } from 'react-router';
import { usuarioPossuiPermissao } from '../../../../../common/autenticacao';
import { recursos, permissoes, estadosCadastro } from '../../../../../common/constantes/autorizacao';
import AutoProgressBar from '../../../../../components/Loading/AutoProgressBar';
import { withFormik, Field } from 'formik';
import { converterContaPagarParaFormulario, converterContaPagarParaApi } from './util/contaPagarConverter';
import { mensagensDeValidacao } from '../../../../../common/constantes/mensagens';
import ButtonNovo, { estadosBotaoNovo } from '../../../../../components/Button/ButtonNovo';
import ButtonSalvar from '../../../../../components/Button/ButtonSalvar';
import ButtonCancelar, { estadosBotaoCancelar } from '../../../../../components/Button/ButtonCancelar';
import ButtonExcluir from '../../../../../components/Button/ButtonExcluir';
import InputField from '../../../../../components/Input/InputField';
import { helpMessage } from './util/constantes';
import Grid from '../../../../../components/Grid';
import { services } from '../../../../../common/constantes/api';
import SingleSelectCategoria, { tiposCategoria } from '../../../../../components/Select/SingleSelectCategoria';
import InputDate from '../../../../../components/Input/InputDate';
import InputMoney from '../../../../../components/Input/InputMoney';
import Dropdown from '../../../../../components/Select/Dropdown';
import TabelaPagamentos from './components/TabelaPagamentos';
import { asyncCreateContaPagar, asyncUpdateContaPagar, asyncDeleteContaPagar, buscarCategoriaFavoritaDespesa, asyncEditarContaPagarProximasEmAberto, asyncEditarContaPagarTodasEmAberto } from '../../requests';
import SingleSelectPessoa from '../../../../../components/Select/SingleSelectPessoa';
import { confirm } from '../../../../../components/Toast';
import ModalPagamento from './components/ModalPagamento';
import { gerarUUID } from '../../../../../common/manipulacaoDeString';
import { removerElemento, copiarObjeto } from '../../../../../common/array';
import { Accordion, AccordionTab } from 'primereact/accordion';
import TextArea from '../../../../../components/TextArea';
import Form from '../../../../../components/Form';
import FormActions from '../../../../../components/FormActions';
import FormContent from '../../../../../components/FormContent';
import Checkbox from '../../../../../components/Input/Checkbox';
import Fieldset from '../../../../../components/FieldSet';
import If from '../../../../../components/If';
import ModalExclusaoContasPagar, { tiposExclusoesContasPagar } from '../ModalExclusaoContasPagar';
import { asyncDeleteContaPagarProximasEmAberto, asyncDeleteContaPagarTodasEmAberto, buscarContaFavoritaDespesa } from '../../requests';
import ModalEdicaoContasPagar, { tiposEdicoesContasPagar } from '../ModalEdicaoContasPagar';
import { exibirConfirmacaoMesmoNumeroDocumento, jaExisteContaPagarComMesmoNumeroDocumentoOrigem, saoIguais } from './util/utils';
import SingleSelectConta from '../../../../../components/Select/SingleSelectConta';
import { formatISO, isValid, parseISO } from 'date-fns';
import { RadioButton } from 'primereact/radiobutton';
import Col from '../../../../../components/Col';
import { formatarMonetario } from '../../../../../common/mascara';
import { removerCaracteresInvalidosRsql, keyFilterConsultaRsql } from '../../../../../common/rsql';
import InputDouble from '../../../../../components/Input/InputDouble';
import montarMensagemExclusaoTitulos from '../../../utils/functions';

export const periodicidadeDropDown = [
    { label: 'Dias', value: 'DIARIA' },
    { label: 'Semanas', value: 'SEMANAL' },
    { label: 'Meses', value: 'MENSAL' },
    { label: 'Trimestres', value: 'TRIMESTRAL' },
    { label: 'Semestres', value: 'SEMESTRAL' },
    { label: 'Anos', value: 'ANUAL' },
];

export const tipoContaPagar = {
    UNICA: 'UNICA',
    VALOR_FIXO: 'VALOR_FIXO',
    VALOR_PARCELADO: 'VALOR_PARCELADO'
};

const styleDescricaoPagamento = {
    margin: '0',
    width: '100%',
    textAlign: 'end',
    padding: '0.5em'
}

const initialValue = {
    id: null,
    descricao: null,
    categoria: null,
    tipo: 'UNICA',
    emissao: formatISO(new Date()),
    vencimento: "",
    valor: 0,
    ocorrencia: 'MENSAL',
    quantidadeRepeticoes: 2,

    pago: false,
    valorPago: null,
    dataPago: formatISO(new Date()),
    contaPago: null,

    pagamentos: [],

    pessoa: null,
    competencia: null,
    dataDocumento: null,
    numeroDocumento: null,
    observacao: null,

    activeIndex: null,
};

class ModalContaPagar extends Component {

    constructor(props) {
        super(props);
        autoBind(this);

        this.state = {
            podeInserir: usuarioPossuiPermissao(recursos.FINANCAS_CONTAS_PAGAR, permissoes.INSERIR),
            podeEditar: usuarioPossuiPermissao(recursos.FINANCAS_CONTAS_PAGAR, permissoes.EDITAR),
            podeExcluir: usuarioPossuiPermissao(recursos.FINANCAS_CONTAS_PAGAR, permissoes.EXCLUIR),
            podeVisualizarFinancasCategorias: usuarioPossuiPermissao(recursos.FINANCAS_CATEGORIAS, permissoes.VISUALIZAR),
            exibirModalPagamento: false,
            registroSelecionado: null,
            exibirModalExclusao: false,
            exibirModalConfirmacaoEdicao: false
        }
    }

    componentDidMount() {
        if (!this.props.values.id) {
            this.atribuirValoresIniciais()
        }
    }

    novo() {
        this.props.onNovoClick && this.props.onNovoClick()
        this.props.resetForm({ values: initialValue });
        this.setState({
            registroSelecionado: null
        })
        this.atribuirValoresIniciais()
    }

    atribuirValoresIniciais() {
        const { values, valorPadraoDataVencimento } = this.props
        let categoria = values.categoria
        let contaPago = values.contaPago
        let vencimento = values.vencimento

        if (valorPadraoDataVencimento) {
            vencimento = formatISO(valorPadraoDataVencimento)
        }

        buscarCategoriaFavoritaDespesa(({ data }) => {
            if (data.totalElements > 0) {
                categoria = {
                    label: data.content[0].nome,
                    value: data.content[0].id,
                    registro: data.content[0]
                }
            }
            if (this.props.dirty) {
                this.props.setFieldValue('categoria', categoria)
                this.props.setFieldValue('vencimento', vencimento)
            } else {
                this.props.resetForm({ values: { ...this.props.values, categoria, vencimento } })
            }
        })

        buscarContaFavoritaDespesa(({ data }) => {
            if (data.totalElements > 0) {
                contaPago = {
                    label: data.content[0].nome,
                    value: data.content[0].id,
                    registro: data.content[0]
                }
            }
            if (this.props.dirty) {
                this.props.setFieldValue('contaPago', contaPago)
            } else {
                this.props.resetForm({ values: { ...this.props.values, contaPago } })
            }
        })
    }

    alterouApenasRepeticoes() {
        let { values, initialValues } = this.props

        values = { ...values, pagamentos: [] }
        initialValues = { ...initialValues, pagamentos: [] }

        return saoIguais(values, initialValues)
    }


    async salvar(e, novoOnSuccess) {
        const { values, errors, initialValues } = this.props;

        await this.props.handleSubmit();
        await this.props.validateForm();

        if (this.props.isValid) {
            if (values) {
                if (values.numeroDocumento && values.numeroDocumento !== initialValues.numeroDocumento && await jaExisteContaPagarComMesmoNumeroDocumentoOrigem(values.id, values.numeroDocumento)) {
                    exibirConfirmacaoMesmoNumeroDocumento(() => {
                        this.salvarOuAtualizar(values, novoOnSuccess)
                    })
                } else {
                    this.salvarOuAtualizar(values, novoOnSuccess)
                }
            }
        } else {
            this.setState({ activeIndex: errors.activeIndex })
        }
    }

    async salvarOuAtualizar(values, novoOnSuccess) {
        if (values.id) {
            if (values.itemRepeticao && !this.alterouApenasRepeticoes()) {
                this.setState({ exibirModalConfirmacaoEdicao: true })
            } else {
                await asyncUpdateContaPagar(converterContaPagarParaApi(values), () => {
                    if (novoOnSuccess) {
                        novoOnSuccess();
                    } else {
                        this.props.onHide(values);
                    }
                })
            }
        } else {
            await asyncCreateContaPagar(converterContaPagarParaApi(values), () => {
                if(novoOnSuccess){
                    novoOnSuccess();
                }else{
                    this.props.onHide(values);
                }
            })
        }

    }

    excluir() {
        const { itemRepeticao, status, origem, id } = this.props.values
        if (itemRepeticao) {
            this.setState({ exibirModalExclusao: true })
        } else {
            confirm('Confirmação', montarMensagemExclusaoTitulos(status, origem), async () => {
                await asyncDeleteContaPagar(id, () => this.props.onHide())
            });
        }
    }

    cancelar() {
        if (this.props.dirty) {
            this.props.resetForm({ values: this.props.initialValues });
        } else {
            this.props.onHide(true);
        }
    }

    novoPagamento() {
        this.setState({
            exibirModalPagamento: true,
            registroSelecionado: null
        })
    }

    onHidePagamento() {
        this.setState({ exibirModalPagamento: false })
    }

    salvarPagamento(pagamento) {
        let novoPagamento = { ...pagamento }

        if (!pagamento.id && !pagamento.idTemporario) {

            novoPagamento.idTemporario = gerarUUID();

            this.inserirRegistroNaLista(novoPagamento)
        } else {
            this.atualizarRegistroNaLista(novoPagamento)
        }

        this.setState({
            registroSelecionado: novoPagamento
        })
        this.onHidePagamento();
    }

    inserirRegistroNaLista(dadosFormulario) {
        let pagamentos = copiarObjeto(this.props.values.pagamentos)
        pagamentos && pagamentos.push(dadosFormulario);

        this.props.setFieldValue('pagamentos', pagamentos)
    }

    atualizarRegistroNaLista(dadosFormulario) {

        const { pagamentos } = this.props.values;
        const result = pagamentos && pagamentos.map(item => {

            if (item.idTemporario && item.idTemporario === dadosFormulario.idTemporario) {
                return dadosFormulario
            } else if (item.id && item.id === dadosFormulario.id) {
                return dadosFormulario
            }
            return item;
        })

        this.props.setFieldValue('pagamentos', result)
    }

    editarPagamento(dadosFormulario) {
        this.setState({
            registroSelecionado: dadosFormulario,
            exibirModalPagamento: true
        })
    }

    calcularValorAPagar() {
        const { values } = this.props;
        let valorPago = 0;

        values.pagamentos && values.pagamentos.forEach(pagamento => {
            valorPago += pagamento.valor
        });

        return values.valor - valorPago
    }

    buscarTituloPagamentos(valorAPagar, valorTotal) {
        const totalPagamentos = valorTotal - valorAPagar
        if (!totalPagamentos) {
            return "Pagamentos"
        } else {
            return <span> Pagamentos (pagos <b>{formatarMonetario(totalPagamentos)}</b> de  {formatarMonetario(valorTotal || 0)} ) </span>
        }
    }

    excluirPagamento(dadosFormulario) {
        this.props.setFieldValue('pagamentos', removerElemento(this.props.values.pagamentos, dadosFormulario));
        this.setState({ registroSelecionado: null })
    }

    onHideModalExclusao(tipoExclusao) {
        if (tipoExclusao) {
            const { values } = this.props
            if (tipoExclusao === tiposExclusoesContasPagar.APENAS_SELECIONADA) {
                asyncDeleteContaPagar(values.id, () => {
                    this.props.onHide()
                })
            } else if (tipoExclusao === tiposExclusoesContasPagar.PROXIMAS_EM_ABERTO) {
                asyncDeleteContaPagarProximasEmAberto(values.id, () => {
                    this.props.onHide()
                })
            } else {
                asyncDeleteContaPagarTodasEmAberto(values.id, () => {
                    this.props.onHide()
                })
            }
        } else {
            this.setState({ exibirModalExclusao: false, values: null })
        }
    }

    onHideModalEdicao(tipoEdicao) {
        if (tipoEdicao) {
            const { values } = this.props
            if (tipoEdicao === tiposEdicoesContasPagar.APENAS_SELECIONADA) {
                asyncUpdateContaPagar(converterContaPagarParaApi(values), () => {
                    this.props.onHide()
                })
            } else if (tipoEdicao === tiposEdicoesContasPagar.PROXIMAS_EM_ABERTO) {
                asyncEditarContaPagarProximasEmAberto(converterContaPagarParaApi(values), () => {
                    this.props.onHide()
                })
            } else {
                asyncEditarContaPagarTodasEmAberto(converterContaPagarParaApi(values), () => {
                    this.props.onHide()
                })
            }
        } else {
            this.setState({ exibirModalConfirmacaoEdicao: false, values: null })
        }
    }

    renderModalHeader(values) {
        if (values.itemRepeticao && values.quantidadeRepeticoes) {
            const tituloRepeticao = `${values.itemRepeticao}/${values.quantidadeRepeticoes}`
            return values.id ? `Editar conta a pagar ${tituloRepeticao}` : `Nova conta a pagar ${tituloRepeticao}`;
        }

        return values.id ? `Editar conta a pagar` : `Nova conta a pagar`;
    }

    onHideModal() {
        if (this.props.dirty) {
            confirm('Confirmação', 'A conta a pagar possui informações não salvas, tem certeza que deseja sair sem salvar?', () => {
                this.props.onHide(true)
            })
        } else {
            this.props.onHide(true)
        }
    }

    buscarValor() {
        const { tipo, valor, quantidadeRepeticoes } = this.props.values
        if (tipo === tipoContaPagar.VALOR_PARCELADO) {
            return Math.trunc((valor / quantidadeRepeticoes) * 100) / 100
        } else {
            return valor
        }
    }

    render() {
        const { visible, dirty, values, isModal, setFieldValue } = this.props;
        const { exibirModalPagamento, podeInserir, podeEditar, podeExcluir, registroSelecionado, activeIndex, exibirModalExclusao, exibirModalConfirmacaoEdicao, podeVisualizarFinancasCategorias } = this.state;

        const informacoesPermissoes = {
            podeInserir: podeInserir,
            podeEditar: podeEditar,
            podeExcluir: podeExcluir,
            estadoCadastro: values.id ? estadosCadastro.EDICAO : estadosCadastro.INCLUSAO
        };

        const desabilitarCampos = (informacoesPermissoes.estadoCadastro === estadosCadastro.EDICAO && !podeEditar);
        const isEdicao = values.id !== null;

        const valorAPagar = this.calcularValorAPagar();
        const tituloPagamentos = this.buscarTituloPagamentos(valorAPagar, values.valor)

        const estadoBotaoNovo = dirty ? estadosBotaoNovo.SALVAR_E_NOVO : estadosBotaoNovo.NOVO;
        const onClickNovo = dirty ? (e) => this.salvar(e, this.novo) : this.novo;

        return (
            <Modal
                header={this.renderModalHeader(values)}
                visible={visible}
                onHide={this.onHideModal}
            >
                <AutoProgressBar />
                <Form>
                    <FormActions>
                        <ButtonCancelar
                            {...informacoesPermissoes}
                            hidden={isModal && values.id && !dirty}
                            estadoBotao={dirty ? estadosBotaoCancelar.CANCELAR : estadosBotaoCancelar.VOLTAR}
                            onClick={this.cancelar}
                        />
                        <ButtonSalvar
                            {...informacoesPermissoes}
                            disabled={!dirty}
                            onClick={this.salvar}
                        />
                        <ButtonNovo
                            onClick={onClickNovo}
                            hidden={!dirty && !values.id}
                            estadoBotao={estadoBotaoNovo}
                            {...informacoesPermissoes}
                        />
                        <ButtonExcluir
                            hidden={!values.id || isModal}
                            {...informacoesPermissoes}
                            onClick={this.excluir}
                        />
                    </FormActions>
                    <FormContent>
                        <Grid>
                            <Field sm="12" md="6" lg="4" xl="4"
                                component={InputMoney}
                                autofocus
                                label='Valor'
                                name="valor"
                                obrigatorio
                                size={15}
                                helpMessage={helpMessage.valor}
                                onChange={e => setFieldValue('valor', e.target.value)}
                                value={values.valor}
                                {...informacoesPermissoes}
                            />

                            <Field sm="12" md="6" lg="4" xl="4"
                                component={InputDate}
                                label="Emissão"
                                name="emissao"
                                obrigatorio
                                helpMessage={helpMessage.emissao}
                                onChange={e => setFieldValue('emissao', e.target.value)}
                                value={values.emissao}
                                {...informacoesPermissoes}
                            />

                            <Field sm="12" md="6" lg="4" xl="4"
                                component={InputDate}
                                label="Vencimento"
                                name="vencimento"
                                obrigatorio
                                helpMessage={helpMessage.vencimento}
                                onChange={e => setFieldValue('vencimento', e.target.value)}
                                value={values.vencimento}
                                {...informacoesPermissoes}
                            />

                            <Field sm="12" md="6" lg='4' xl='4'
                                component={InputField}
                                label='Descrição'
                                name="descricao"
                                helpMessage={helpMessage.descricao}
                                size={255}
                                {...informacoesPermissoes}
                            />
                            <Field sm="12" md="6" lg="4" xl="4"
                                name="pessoa"
                                label="Pessoa (credor)"
                                component={SingleSelectPessoa}
                                value={values.pessoa}
                                helpMessage={helpMessage.pessoa}
                                onChange={e => setFieldValue('pessoa', e)}
                                url={`${services.GESTOR}/v1/contas_pagar/relacoes/pessoas`}
                                {...informacoesPermissoes}
                            />
                            <Field sm="12" md="6" lg='4' xl='4'
                                name="categoria"
                                label="Categoria"
                                tipoCategoria={tiposCategoria.DESPESA}
                                helpMessage={helpMessage.categoria}
                                component={SingleSelectCategoria}
                                value={values.categoria}
                                onChange={e => setFieldValue('categoria', e)}
                                url={`${services.GESTOR}/v1/contas_pagar/relacoes/categorias/despesas`}
                                disabledButton={!podeVisualizarFinancasCategorias}
                                {...informacoesPermissoes}
                            />

                            <If test={!isEdicao}>
                                <Col md='4' lg='3'>
                                    <RadioButton
                                        inputId="rb-tipo-valor-unico"
                                        name="tipo"
                                        value={tipoContaPagar.UNICA}
                                        onChange={e => this.props.setFieldValue('tipo', e.value)}
                                        checked={values.tipo === tipoContaPagar.UNICA}
                                    />
                                    <label htmlFor="rb-tipo-valor-unico" className="p-radiobutton-label">É uma conta única</label>
                                </Col>
                                <Col md='4' lg='3'>
                                    <RadioButton
                                        inputId="rb-tipo-valor-fixo"
                                        name="tipo"
                                        value={tipoContaPagar.VALOR_FIXO}
                                        onChange={e => this.props.setFieldValue('tipo', e.value)}
                                        checked={values.tipo === tipoContaPagar.VALOR_FIXO}
                                    />
                                    <label htmlFor="rb-tipo-valor-fixo" className="p-radiobutton-label">É uma conta recorrente</label>
                                </Col>
                                <Col md='4' lg='3'>
                                    <RadioButton
                                        inputId="rb-tipo-valor-parcelado"
                                        name="tipo"
                                        value={tipoContaPagar.VALOR_PARCELADO}
                                        onChange={e => this.props.setFieldValue('tipo', e.value)}
                                        checked={values.tipo === tipoContaPagar.VALOR_PARCELADO}
                                    />
                                    <label htmlFor="rb-tipo-valor-parcelado" className="p-radiobutton-label">É uma  conta parcelada</label>
                                </Col>
                                <If test={values.tipo !== tipoContaPagar.UNICA}>
                                    <Field col='6' sm="6" md="6" lg="6" xl="6"
                                        component={InputDouble}
                                        decimalScale={0}
                                        disabled={informacoesPermissoes.estadoCadastro === estadosCadastro.EDICAO}
                                        label={values.tipo === tipoContaPagar.VALOR_FIXO ? 'Repetir por' : 'Parcelado em'}
                                        name="quantidadeRepeticoes"
                                        value={values.quantidadeRepeticoes}
                                        helpMessage={values.tipo === tipoContaPagar.VALOR_FIXO ? helpMessage.quantidadeRepeticoesValorFixo : helpMessage.quantidadeRepeticoesParcelas}
                                        size={2}
                                        onChange={async (e) => {
                                            await setFieldValue('quantidadeRepeticoes', e.target.value)
                                            await setFieldValue('valorPago', this.buscarValor())
                                        }}
                                        {...informacoesPermissoes}
                                    />

                                    <Field col='6' sm="6" md="6" lg='6' xl='6'
                                        component={Dropdown}
                                        colStyle={{ paddingTop: '26px' }}
                                        autoFocus
                                        disabled={informacoesPermissoes.estadoCadastro === estadosCadastro.EDICAO}
                                        value={values.ocorrencia}
                                        showClear={false}
                                        options={periodicidadeDropDown}
                                        isSearchableOnMobile={false}
                                        onChange={e => setFieldValue('ocorrencia', e.value || null)}
                                        {...informacoesPermissoes}
                                    />

                                    <p style={styleDescricaoPagamento}>
                                        Serão geradas {values.quantidadeRepeticoes} contas a pagar de <b>{formatarMonetario(this.buscarValor())}</b>
                                    </p>
                                    <p style={{ ...styleDescricaoPagamento, fontSize: '12px' }}>
                                        Em caso de divisão não exata, a sobra será somada à última parcela.
                                    </p>
                                </If>
                            </If>

                            <Field sm="12" md="12" lg="12" xl="12"
                                component={Checkbox}
                                colStyle={{ marginTop: '10px' }}
                                label='Valor já pago'
                                name="pago"
                                hidden={isEdicao}
                                onChange={() => {
                                    setFieldValue('pago', !values.pago)
                                    if (!values.pago) {
                                        this.props.setFieldValue('valorPago', this.buscarValor())
                                    }
                                }}
                                checked={values.pago}
                                {...informacoesPermissoes}
                            />

                            <If test={values.pago}>
                                <Field sm="12" md="6" lg="4" xl="4"
                                    component={InputMoney}
                                    label="Valor pago"
                                    name="valorPago"
                                    obrigatorio
                                    value={values.valorPago}
                                    onChange={e => setFieldValue('valorPago', e.target.value)}
                                    size={12}
                                    {...informacoesPermissoes}
                                />
                                <Field sm="12" md="6" lg="4" xl="4"
                                    component={InputDate}
                                    label="Data do pagamento"
                                    name="dataPago"
                                    obrigatorio
                                    onChange={e => setFieldValue('dataPago', e.target.value)}
                                    value={values.dataPago}
                                    {...informacoesPermissoes}
                                />
                                <Field sm="12" md="6" lg='4' xl='4'
                                    name="contaPago"
                                    label="Conta"
                                    obrigatorio
                                    component={SingleSelectConta}
                                    value={values.contaPago}
                                    helpMessage={helpMessage.conta}
                                    onChange={e => setFieldValue('contaPago', e)}
                                    url={`${services.GESTOR}/v1/contas_pagar/relacoes/contas`}
                                />

                            </If>

                            <If test={isEdicao}>
                                <Fieldset legend={tituloPagamentos} style={{ width: '100%', marginRight: '4px' }} className='fieldset-light'>
                                    <Grid >
                                        <ButtonNovo
                                            label="Novo pagamento"
                                            podeInserir={!desabilitarCampos}
                                            onClick={this.novoPagamento}
                                        />

                                        <If test={exibirModalPagamento}>
                                            <ModalPagamento
                                                isModal={true}
                                                visible={true}
                                                registroSelecionado={registroSelecionado}
                                                onSalvar={this.salvarPagamento}
                                                onHide={this.onHidePagamento}
                                                valorAPagar={valorAPagar}
                                            />
                                        </If>
                                        <TabelaPagamentos
                                            podeEditar={!desabilitarCampos}
                                            podeExcluir={!desabilitarCampos}
                                            registros={values.pagamentos}
                                            onEditar={this.editarPagamento}
                                            onExcluir={this.excluirPagamento}
                                        />
                                    </Grid>
                                </Fieldset>
                            </If>

                            <Accordion
                                className="tab-accordion-lite"
                                style={{ marginTop: '10px' }}
                                activeIndex={activeIndex}
                                onTabChange={(e) => this.setState({ activeIndex: e.index })}
                                multiple={true}
                            >
                                <AccordionTab header="Informações complementares">
                                    <Grid>
                                        <Field sm="12" md="6" lg="4" xl="4"
                                            component={InputDate}
                                            label="Competência"
                                            name="competencia"
                                            helpMessage={helpMessage.competencia}
                                            onChange={e => setFieldValue('competencia', e.target.value)}
                                            value={values.competencia}
                                            {...informacoesPermissoes}
                                        />

                                        <Field sm="12" md="6" lg='4' xl='4'
                                            component={InputField}
                                            label='Número do documento de origem'
                                            name="numeroDocumento"
                                            onChange={e => setFieldValue('numeroDocumento', removerCaracteresInvalidosRsql(e.target.value))}
                                            keyfilter={keyFilterConsultaRsql}
                                            helpMessage={helpMessage.numeroDocumento}
                                            size={255}
                                            {...informacoesPermissoes}
                                        />

                                        <Field sm="12" md="6" lg="4" xl="4"
                                            component={InputDate}
                                            label="Data do documento de origem"
                                            name="dataDocumento"
                                            helpMessage={helpMessage.dataDocumento}
                                            onChange={e => setFieldValue('dataDocumento', e.target.value)}
                                            value={values.dataDocumento}
                                            {...informacoesPermissoes}
                                        />

                                        <Field sm="12"
                                            component={TextArea}
                                            placeholder="Escreva sua observação aqui"
                                            label='Observação'
                                            name="observacao"
                                            helpMessage={helpMessage.observacao}
                                            maxLength={4096}
                                            onChange={e => setFieldValue('observacao', e.target.value)}
                                            {...informacoesPermissoes}
                                        />
                                    </Grid>
                                </AccordionTab>
                            </Accordion>
                        </Grid>
                    </FormContent>
                </Form>
                <If test={exibirModalExclusao}>
                    <ModalExclusaoContasPagar
                        visible={exibirModalExclusao}
                        onHide={this.onHideModalExclusao}
                    />
                </If>
                <If test={exibirModalConfirmacaoEdicao}>
                    <ModalEdicaoContasPagar
                        visible={exibirModalConfirmacaoEdicao}
                        onHide={this.onHideModalEdicao}
                    />
                </If>
            </Modal>
        )
    }
}

ModalContaPagar = withFormik({
    validateOnChange: false,
    validateOnBlur: false,

    mapPropsToValues(props) {
        if (props.registroSelecionado) {
            return converterContaPagarParaFormulario(props.registroSelecionado)
        } else {
            return initialValue;
        }
    },

    validate(values) {
        let errors = {};

        if (values.valor <= 0) {
            errors.valor = "O valor deve ser maior que zero"
        }

        if (values.emissao && !isValid(parseISO(values.emissao))) {
            errors.emissao = mensagensDeValidacao.DATA_INVALIDA
        }

        if (values.vencimento && !isValid(parseISO(values.vencimento))) {
            errors.vencimento = mensagensDeValidacao.DATA_INVALIDA
        }

        if (values.competencia && !isValid(parseISO(values.competencia))) {
            errors.competencia = mensagensDeValidacao.DATA_INVALIDA
            errors.activeIndex = [0];
        }

        if (values.dataDocumento && !isValid(parseISO(values.dataDocumento))) {
            errors.dataDocumento = mensagensDeValidacao.DATA_INVALIDA
            errors.activeIndex = [0];
        }

        if (values.tipo !== tipoContaPagar.UNICA) {
            if (values.quantidadeRepeticoes < 2) {
                errors.quantidadeRepeticoes = "Não pode ser inferior a 2";
            }
        }

        if (values.observacao && values.observacao.length > 4096) {
            errors.activeIndex = [0];
        }

        if (values.pago) {

            if (values.valorPago <= 0) {
                errors.valorPago = "O valor deve ser maior que zero";
            }
            if (!isValid(parseISO(values.dataPago))) {
                errors.dataPago = mensagensDeValidacao.DATA_INVALIDA
            }

            if (!values.contaPago) {
                errors.contaPago = mensagensDeValidacao.OBRIGATORIO
            }
        }

        return errors
    },

    validationSchema: Yup.object().shape({
        vencimento: Yup.string().nullable().required(mensagensDeValidacao.DATA_INVALIDA),
        emissao: Yup.string().nullable().required(mensagensDeValidacao.DATA_INVALIDA),
        valor: Yup.string().required(mensagensDeValidacao.OBRIGATORIO),
        numeroDocumento: Yup.string().max(50, 'O campo observação não pode ter mais que 50 caracteres.').nullable(),
        observacao: Yup.string().max(4096, 'O campo observação não pode ter mais que 4096 caracteres.').nullable()
    }),
    handleSubmit: () => { }
})(ModalContaPagar);


export default withRouter(ModalContaPagar);
