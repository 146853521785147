import { actionTypes } from "../../common/constantes/reduxTypes"

export default (state = false, action) => {
    switch (action.type) {
        case actionTypes.DISPOSITIVO: {
            return action.payload
        }
        default: {
            return state
        }
    }
}
