export const modulosDropdown = [
	{ label: "Administração", value: "ADMINISTRACAO", isFixed: true },
	{ label: "Documento digital", value: "DOCUMENTOS_DIGITAIS" },
	{ label: "Estoque", value: "ESTOQUE" },
	{ label: "Finanças", value: "FINANCAS" },
	{ label: "Vendas", value: "VENDAS" }
];

export const tipoPessoaDropdown = [
	{ label: 'Física', value: 'FISICA' },
	{ label: 'Jurídica', value: 'JURIDICA' }
];

export const meioPagamento = [
	{ label: 'Sem pagamento', value: 'SEM_PAGAMENTO' },
	{ label: 'Cartão', value: 'CARTAO_CREDITO' },
	{ label: 'Boleto', value: 'BOLETO_BANCARIO' }
]