import React from 'react';
import { Field } from 'formik';
import Grid from '../../../../../components/Grid';
import InputField from '../../../../../components/Input/InputField';
import FormGroup from '../../../../../components/FormGoup';
import { helpFieldText } from './help';

export function renderizarFieldsEstrangeiro(values, informacoesPermissoes) {

    return (
        <FormGroup>
            <Grid>
                <Field sm="12" md="6" lg='4' xl='4'
                    component={InputField}
                    type="text"
                    label='Identificação'
                    name="identificacao"
                    helpMessage={helpFieldText.identificacao}
                    size={255}
                    value={values.identificacao}
                    {...informacoesPermissoes}
                />
            </Grid>
        </FormGroup>
    )
}