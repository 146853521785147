import React, { Component } from "react";
import { Field } from "formik";
import { InternalInputField } from "../Input/InputField";
import autoBind from "react-autobind";
import { InternalInputDate } from "../Input/InputDate";
import { InternalInputDouble } from "../Input/InputDouble";
import Checkbox from "../Input/Checkbox";
import { InternalDropdown } from "../Select/Dropdown";
import Button from "../Button";
import { Menu } from "primereact/menu";
import Col from "../Col";
import propTypes from "prop-types";
import { InternalTextArea } from "../TextArea";
import { mensagensDeValidacao } from "../../common/constantes/mensagens";

const buttonStyle = {
    marginTop: "17px",
    backgroundColor: "#fff0"
};

export default class CampoPersonalizado extends Component {
    constructor(props) {
        super(props);
        autoBind(this);
    }

    getComponent() {
        switch (this.props.type) {
            case "string":
                return InternalInputField;
            case "date":
                return InternalInputDate;
            case "integer":
                return InternalInputDouble;
            case "decimal":
                return InternalInputDouble;
            case "check":
                return Checkbox;
            case "select":
                return InternalDropdown;
            case "multiSelect":
                return InternalDropdown;
            case "textArea":
                return InternalTextArea;
            default:
                return InternalInputField;
        }
    }

    buscarValorDeAcordoComComponente(e) {
        switch (this.props.type) {
            case "string":
                return e.target.value;
            case "date":
                return e.target.value;
            case "decimal":
                return e.target.value;
            case "integer":
                return e.target.value;
            case "check":
                return e.checked;
            case "select":
                return e.value;
            case "multiSelect":
                return e.value;
            case "textArea":
                return e.target.value;
            default:
                return e.value;
        }
    }

    getColStyle() {
        if (this.props.type === "check") {
            return { paddingTop: "17px", paddingBottom: "0px" };
        }
        return { paddingTop: "0px", paddingBottom: "0px" };
    }

    getValue() {
        return this.props.value && this.props.value.value;
    }

    onChange(e) {
        const novoValor = this.buscarValorDeAcordoComComponente(e);
        this.props.onChange({ id: this.props.id, value: novoValor });
    }

    getErrors() {
        const { value } = this.props

        if (this.props.required && !value) {
            return mensagensDeValidacao.OBRIGATORIO
        }

        if (this.props.required && !value.value) {
            return mensagensDeValidacao.OBRIGATORIO
        }
    }

    getLabel() {
        const { label, name, required } = this.props
        const requiredString = required ? ' *' : ''
        return label || name + requiredString
    }

    render() {
        const {
            name,
            col,
            sm,
            md,
            lg,
            xl,
            onExcluirClick,
            onEditarClick,
            id,
            hiddenOptionsButton,
            estadoCadastro,
            podeEditar,
            podeInserir,
            touched,
            type,
            colStyle
        } = this.props;

        const optionsButton = [
            {
                label: "Editar",
                icon: "fa fa-pencil",
                command: () => onEditarClick(id)
            },
            {
                label: "Excluir",
                icon: "fa fa-trash",
                command: () => onExcluirClick(id)
            }
        ];

        const value = this.getValue();
        const component = this.getComponent();

        return (
            <Col col={col} sm={sm} md={md} lg={lg} xl={xl} style={colStyle}>
                {/* <div className="p-inputgroup p-fluid"> */}
                <Field
                    col="12" sm="12" md="12" lg="12" xl="12"
                    component={component}
                    label={this.getLabel()}
                    size={type === 'string' ? 100 : undefined}
                    name={name}
                    value={value}
                    checked={value}
                    options={this.props.options}
                    onChange={this.onChange}
                    colStyle={{ padding: '0px' }}
                    estadoCadastro={estadoCadastro}
                    podeInserir={podeInserir}
                    podeEditar={podeEditar}
                    errors={this.getErrors()}
                    touched={touched}
                    decimalScale={this.props.type === "integer" ? 0 : 2}
                />
                <Menu
                    model={optionsButton}
                    popup={true}
                    ref={el => (this.menu = el)}
                />
                <Button
                    color="secondary"
                    icon="fa fa-ellipsis-v"
                    style={buttonStyle}
                    hidden={hiddenOptionsButton}
                    onClick={event => this.menu.toggle(event)}
                />
                {/* </div> */}
            </Col>
        );
    }
}

CampoPersonalizado.propTypes = {
    /** id do componente recebido por props, esse id será exportando no onChange, onEditarClick e onExcluirClick */
    id: propTypes.string,
    /** Tipo do campo que será renderizado */
    type: propTypes.oneOf(["string", "date", "integer", "check", "select", "multiSelect", "decimal", "textArea"]),
    /** Opções caso o componente seja select, essas opções devem ser passadas como strings simples*/
    options: propTypes.arrayOf(propTypes.object),
    /** Valor do componente de acordo com o tipo do componente */
    value: propTypes.any,
    /**Se o campo for obrigatório */
    required: propTypes.bool,
    /** Evento onChange, ele exportará o id recebido pelas props e o novo valor */
    onChange: propTypes.func,
    /** Nome do componente */
    name: propTypes.string,
    /** Label do componente */
    label: propTypes.string,
    /** Esconde botão de opções*/
    hiddenOptionsButton: propTypes.bool,
    /** Evento disparado ao clicar no ícone de excluir, esse evento exportará o id do componente que deverá ser excluído*/
    onExcluirClick: propTypes.func,
    /** Evento disparado ao clicar no ícone de editar, esse evento exportará o id do componente que deverá ser excluído*/
    onEditarClick: propTypes.func,
    /** Tamanho padrão da coluna utilizado em dispositivos muito pequenos (0 a 12) */
    col: propTypes.string,
    /** Tamanho do campo em small devices*/
    sm: propTypes.string,
    /** Tamanho do campo em medium devices*/
    md: propTypes.string,
    /** Tamanho do campo em large devices*/
    lg: propTypes.string,
    /** Tamanho do campo em extra large devices*/
    xl: propTypes.string
};
