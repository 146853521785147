import React, { Component } from 'react';
import autoBind from 'react-autobind';
import Grid from '../../../../../../../../components/Grid';
import Col from '../../../../../../../../components/Col';
import { InternalInputDouble } from '../../../../../../../../components/Input/InputDouble';
import { styleNumeracaoDefault } from './styles';
import VencimentoMemorizado from './components/VencimentoMemorizado';
import AcoesMemorizados from './components/AcoesMemorizados';
import DescricaoMemorizada from './components/DescricaoMemorizada';
import { Field } from 'formik';
import Dropdown from '../../../../../../../../components/Select/Dropdown';
import { formaPagamento } from '../utils/constantes';

class ParcelasMemorizadas extends Component {
    constructor(props) {
        super(props)

        autoBind(this);
    }

    render() {

        const {
            onChangeVencimento,
            onChangeValor,
            onChangeDescricao,
            labelParcela,
            valueVencimento,
            valueValor,
            valueDescricao,
            exibirLabels,
            isMobile,
            backgroundColor,
            errors,
            excluirDisabled,
            onClickExcluir,
            onChangeFormaPagamento,
            valueFormaPagamento,
            informacoesPermissoes
        } = this.props;
        
        return (
            <>
                <Grid style={{ margin: '0px -15px', background: isMobile && backgroundColor, paddingTop: '7px', paddingBottom: errors ? '10px' : undefined }}>
                    <Col col="6" sm="6" md="4" lg='3' xl='3'
                        style={{ padding: '0px 5px' }}
                    >
                        <Grid>
                            <Col
                                style={{
                                    ...styleNumeracaoDefault,
                                    padding: '0px',
                                    margin: exibirLabels ? '10px 0px 0px 0px' : '0px 0px 10px 0px'
                                }}>
                                {labelParcela}
                            </Col>
                            <VencimentoMemorizado
                                colStyle={{ width: 'calc(100% - 30px)', paddingLeft: isMobile ? "0px" : undefined, paddingRight: isMobile ? "0px" : undefined }}
                                exibirLabels={exibirLabels}
                                onChangeVencimento={onChangeVencimento}
                                valueVencimento={valueVencimento}
                                errors={errors}
                                isMobile={isMobile}
                                {...informacoesPermissoes}
                            />
                        </Grid>
                    </Col>
                    <InternalInputDouble
                        colStyle={{ padding: "0px 5px", marginBottom: errors && errors.valor ? '12px' : undefined }}
                        col="6" sm="6" md="4" lg='3' xl='3'
                        label={exibirLabels ? 'Valor' : ""}
                        helpMessage={exibirLabels && !isMobile ? 'Valor da parcela' : ""}
                        name="valor"
                        size={13}
                        obrigatorio={exibirLabels}
                        onChange={onChangeValor}
                        value={valueValor}
                        placeholder='R$ 0,00'
                        prefix='R$ '
                        errors={errors && errors.valor}
                        touched
                        allowNegative={false}
                        {...informacoesPermissoes}
                    />

                    <Col
                        col="12" sm="12" md="4" lg='4' xl='4'
                        style={{ padding: '0px 5px' }}
                    >
                        <Grid verticalAlignCenter>
                            <Field
                                col="12"
                                colStyle={{ width: 'calc(100% - 60px)' }}
                                component={Dropdown}
                                label={exibirLabels && !isMobile ? 'Forma de pagamento' : null}
                                helpMessage={exibirLabels && !isMobile ? "Forma de pagamento utilizada. Essa informação é utilizada na Nota Fiscal" : ""}
                                name="forma"
                                showClear={false}
                                value={valueFormaPagamento}
                                onChange={onChangeFormaPagamento}
                                options={formaPagamento}
                                errors={errors}
                                {...informacoesPermissoes}
                            />
                            <DescricaoMemorizada
                                isMobile={isMobile}
                                valueDescricao={valueDescricao}
                                onChangeDescricao={onChangeDescricao}
                                exibirLabels={exibirLabels}
                                informacoesPermissoes={informacoesPermissoes}
                                errors={errors}
                            />
                            <AcoesMemorizados
                                isMobile={isMobile}
                                exibirLabels={exibirLabels}
                                excluirDisabled={excluirDisabled}
                                onClickExcluir={excluirDisabled ? null : onClickExcluir}
                                informacoesPermissoes={informacoesPermissoes}
                                errors={errors}
                            />
                        </Grid>
                    </Col>
                </Grid>
            </>
        )
    }
}

function parcelasMemorizadasSaoIguais(renderAnterior, renderAtual) {
    return renderAnterior.valueVencimento === renderAtual.valueVencimento &&
        renderAnterior.valueValor === renderAtual.valueValor &&
        renderAnterior.valueDescricao === renderAtual.valueDescricao &&
        renderAnterior.isMobile === renderAtual.isMobile &&
        renderAnterior.errors === renderAtual.errors &&
        renderAnterior.index === renderAtual.index &&
        renderAnterior.exibirLabels === renderAtual.exibirLabels &&
        renderAnterior.valueFormaPagamento === renderAtual.valueFormaPagamento
}

export default React.memo(ParcelasMemorizadas, parcelasMemorizadasSaoIguais);
