import { manterApenasNumeros } from "../../../../../common/mascara";

export function converterParaFormulario(values) {
    return {
        ...values,
        telefone: manterApenasNumeros(values.telefone),
        logradouro: values.endereco ? values.endereco.logradouro : null,
        numero: values.endereco ? values.endereco.numero : null,
        bairro: values.endereco ? values.endereco.bairro : null,
        complemento: values.endereco ? values.endereco.complemento : null,
        cep: values.endereco ? values.endereco.cep : null,
        municipio: converterMunicipioParaFormulario(values.endereco),
        municipioExterior: values.endereco ? values.endereco.municipioExterior : null,
        estadoExterior: values.endereco ? values.endereco.estadoExterior : null,
        pais: converterPaisParaFormulario(values.endereco),
        plano: converterPlanoParaFormulario(values.plano),
        agenteAutorizado: converterAgenteAutorizadoParaFormulario(values.agenteAutorizado),
    }
}

function converterPlanoParaFormulario(plano) {
    if (plano) {
        return {
            label: plano.descricao,
            value: plano.id
        }
    }
    return null
}

function converterPaisParaFormulario(endereco) {
    if (endereco && endereco.pais) {
        return {
            label: endereco.pais.nome,
            value: endereco.pais.id
        }
    }
    return null
}

function converterAgenteAutorizadoParaFormulario(agente) {
    if (agente && agente.cpf) {
        return {
            label: agente.nome + ' - ' + agente.cpf,
            value: agente.id
        }
    } else if (agente && agente.cnpj) {
        return {
            label: agente.nome + ' - ' + agente.cnpj,
            value: agente.id
        }
    }
    return null
}

function converterMunicipioParaFormulario(endereco) {
    if (endereco && endereco.municipio) {
        return {
            label: endereco.municipio.nome + ' - ' + endereco.municipio.estadoSigla,
            value: endereco.municipio.id
        }
    }
    return null
}

export function converterParaApi(values) {

    if (values.tipo === 'JURIDICA') {
        values.cpf = null;
    }

    if (values.tipo === 'FISICA') {
        values.cnpj = null;
    }

    return {
        ...values,
        cpf: manterApenasNumeros(values.cpf),
        cnpj: manterApenasNumeros(values.cnpj),
        telefone: manterApenasNumeros(values.telefone),
        plano: values.plano ? { id: values.plano.value } : null,
        endereco: {
            logradouro: values.logradouro,
            numero: values.numero,
            bairro: values.bairro,
            complemento: values.complemento,
            municipioExterior: values.municipioExterior,
            estadoExterior: values.estadoExterior,
            
            cep: manterApenasNumeros(values.cep),
            municipio: values.municipio ? {
                id: values.municipio.value
            } : null,
            pais: values.pais ? {
                id: values.pais.value
            } : null
        },
        agenteAutorizado: values.agenteAutorizado ? {
            id: values.agenteAutorizado.value
        } : null
    }
}
