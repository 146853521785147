import React, { Component } from 'react';
import { InternalInputDate } from '../../../../../../../../../../components/Input/InputDate';

class VencimentoMemorizado extends Component {

    render() {
        const { exibirLabels, isMobile, onChangeVencimento, valueVencimento, errors, ...rest } = this.props;
        
        return (
            <InternalInputDate
                label={exibirLabels ? 'Vencimento' : ""}
                helpMessage={exibirLabels ? 'Data do vencimento desta parcela' : ""}
                name="vencimento"
                obrigatorio={exibirLabels}
                onChange={onChangeVencimento}
                value={valueVencimento}
                errors={errors && errors.vencimento}
                touched
                {...rest}
            />
        )
    }
}

function vencimentoMemorizadoSaoIguais(renderAnterior, renderAtual) {
    return renderAnterior.exibirLabels === renderAtual.exibirLabels &&
        renderAnterior.valueVencimento === renderAtual.valueVencimento &&
        renderAnterior.isMobile === renderAtual.isMobile &&
        renderAnterior.errors === renderAtual.errors
}

export default React.memo(VencimentoMemorizado, vencimentoMemorizadoSaoIguais);
