import { tipoCampos } from "../../../../components/PesquisaAvancada/util/constantes";

export const optionsFiltroAvancado = [
    { label: 'Nome', name: 'nome', type: tipoCampos.STRING },
    { label: 'E-mail', name: 'credencial.email', type: tipoCampos.STRING },
    {
        label: 'Ativo', name: 'credencial.ativa', type: tipoCampos.SELECT,
        optionSelect: [
            { label: "Sim", value: "true" },
            { label: "Não", value: "false" },
        ]
    }, {
        label: 'Situação', name: 'credencial.bloqueada', type: tipoCampos.SELECT,
        optionSelect: [
            { label: "Bloqueada", value: "true" },
            { label: "Desbloqueada", value: "false" }
        ]
    }
]