import { asyncSalvarConfiguracaoUsuario } from "./requests";
import { formatarConfiguracaoUsuarioParaApi, formatarConfiguracaoUsuarioParaAplicacao, adicionarConfiguracaoNosDadosLogin } from "./utils/functions";
import { buscarDadosLoginLocalStorage, salvarDadosLoginLocalStorage } from "../autenticacao";

export const configuracoesUsuario = {
    EXIBIR_TUTORIAL_LISTAGENS: 'WEB_EXIBIR_TUTORIAL_LISTAGENS',
    EXIBIR_TUTORIAL_PAPEIS_PERMISSOES: 'WEB_EXIBIR_TUTORIAL_PAPEIS_PERMISSOES',
    EXIBIR_TUTORIAL_PLANOS: 'WEB_EXIBIR_TUTORIAL_PLANOS',
    EXIBIR_TUTORIAL_CABECALHO_RODAPE: 'WEB_EXIBIR_TUTORIAL_CABECALHO_RODAPE',
    EXIBIR_TUTORIAL_CONFIRMACOES: 'WEB_EXIBIR_TUTORIAL_CONFIRMACOES',
    EXIBIR_TUTORIAL_MODELOS_DOCUMENTO: 'WEB_EXIBIR_TUTORIAL_MODELOS_DOCUMENTO',
    EXIBIR_TUTORIAL_DOCUMENTO: 'WEB_EXIBIR_TUTORIAL_DOCUMENTO',
}

export async function salvarConfiguracaoUsuario(chave, valor, onSuccess, dispararAcoes) {
    const valorFormatado = formatarConfiguracaoUsuarioParaApi(valor)

    await asyncSalvarConfiguracaoUsuario(chave, valorFormatado, async e => {
        salvarConfiguracaoLocalStorage(chave, valor);
        onSuccess && onSuccess(e)
    }, dispararAcoes) 
}

export function buscarConfiguracaoUsuario(chave) {
    const dadosLogin = buscarDadosLoginLocalStorage()
    
    for (const configuracao of dadosLogin.configuracoes) {
        if(configuracao.chave === chave){
            return formatarConfiguracaoUsuarioParaAplicacao(configuracao.valor)
        }
    }
    return null
}

function salvarConfiguracaoLocalStorage(chave, valor) {
    let dadosLogin = buscarDadosLoginLocalStorage()
    dadosLogin = adicionarConfiguracaoNosDadosLogin({chave, valor}, dadosLogin)
    salvarDadosLoginLocalStorage(dadosLogin)
}

