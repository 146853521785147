import { tipoCampos } from "../../../../components/PesquisaAvancada/util/constantes";

export const tabsCadastro = {
    PESSOA_FISICA: 0,
    PESSOA_JURIDICA: 1,
    ESTRANGEIRO: 2,
    FISCAL: 3,
    ENDERECOS: 4,
    EMAILS: 5,
    TELEFONES: 6,
    OBSERVACAO: 7,
}

export const SITUACAO = [
    { label: 'Ativa', value: 'ATIVA' },
    { label: 'Inativa', value: 'INATIVA' },
];

export const PESSOA_TIPO = [
    { label: 'Física', value: 'FISICA' },
    { label: 'Jurídica', value: 'JURIDICA' },
    { label: 'Estrangeiro', value: 'ESTRANGEIRO' },
];

export const GENERO = [
    { label: 'Feminino', value: 'FEMININO' },
    { label: 'Masculino', value: 'MASCULINO' },
    { label: 'Outro', value: 'OUTRO' },
];

export const INDICADOR_INSCRICAO_ESTADUAL = [
    { label: '9 - Não contribuinte', value: 'NAO_CONTRIBUINTE' },
    { label: '1 - Contribuinte ICMS', value: 'CONTRIBUINTE'},
    { label: '2 - Contribuinte isento', value: 'CONTRIBUINTE_ISENTO'}
];

export const OPERADORAS = [
    { label: 'Algar', value: 'ALGAR' },
    { label: 'Claro', value: 'CLARO' },
    { label: 'Nextel', value: 'NEXTEL'},
    { label: 'Oi', value: 'OI' },
    { label: 'Sercomtel', value: 'SERCOMTEL' },
    { label: 'Tim', value: 'TIM' },
    { label: 'Vivo', value: 'VIVO' },
]

export const optionsFiltroAvancado = [
    { label: 'Nome', name: 'nome', type: tipoCampos.STRING },
    { label: 'CPF (sem pontuação)', name: 'cpf', type: tipoCampos.STRING },
    { label: 'CNPJ (sem pontuação)', name: 'cnpj', type: tipoCampos.STRING },
    { label: 'E-mail', name: 'email', type: tipoCampos.STRING },
    { label: 'Telefone', name: 'telefone', type: tipoCampos.STRING },
    {
        label: 'Tipo', name: 'tipo', type: tipoCampos.SELECT,
        optionSelect: [
            { label: "Física", value: "FISICA" },
            { label: "Jurídica", value: "JURIDICA" },
            { label: "Estrangeiro", value: "ESTRANGEIRO" },
        ]
    },
    {
        label: 'Situação', name: 'situacao', type: tipoCampos.SELECT,
        optionSelect: [
            { label: "Ativa", value: "ATIVA" },
            { label: "Inativa", value: "INATIVA" },
            { label: "Bloqueada", value: "BLOQUEADA" },
        ]
    }
]