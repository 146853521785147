import React, { Component } from 'react'
import { Toolbar as InternalToolBar } from 'primereact/components/toolbar/Toolbar';
import If from '../If';

const style = {
    margin: '0px 0px 0px 0px',
    paddingLeft: '0px',
    backgroundColor: '#ffffff00',
    border: 'none',
}

class Toolbar extends Component {
    render() {
        return (
            <If test={!this.props.hidden}>
                <InternalToolBar style={{ ...style, ...this.props.style }}>
                    {this.props.children}
                </InternalToolBar>
            </If>
        )
    }
}

export default Toolbar