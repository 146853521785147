import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { recursos, permissoes } from '../../../common/constantes/autorizacao.js';
import autoBind from 'react-autobind';
import { formatarParaPesquisarParaCamposBoolean } from '../../../common/manipulacaoDeString/index.js';
import Grid from '../../../components/Grid';
import Col from '../../../components/Col';
import PesquisaAvancada from '../../../components/PesquisaAvancada';
import { optionsFiltroAvancado } from './utils/constantes';
import { asyncListarUsuarios, asyncDeleteUsuario } from './requests';
import ButtonExcluirTable from '../../../components/Button/ButtonExcluirTable';
import ButtonEditarTable from '../../../components/Button/ButtonEditarTable';
import { confirmarExclusao } from '../../util/exclusaoDeRegistros';
import { atualizarUrl } from '../../util';
import { removerElemento } from '../../../common/array';
import { Column } from 'primereact/column';
import InputSearch from '../../../components/Input/InputSearch';
import DescricaoFiltroAvancado from '../../../components/DescricaoFiltroAvancado';
import ButtonNovo from '../../../components/Button/ButtonNovo';
import { usuarioPossuiPermissao } from '../../../common/autenticacao';
import { construirUrl } from "../../../common/rsql";
import { services } from "../../../common/constantes/api";
import { salvarConfiguracaoUsuario, buscarConfiguracaoUsuario, configuracoesUsuario } from '../../../common/configuracaoUsuario';
import { tutorialSteps } from '../../../components/Tutorial/steps/listagens';
import Tutorial from '../../../components/Tutorial';
import Form from '../../../components/Form';
import FormActions from '../../../components/FormActions';
import FormContent from '../../../components/FormContent';
import { DataTable } from 'primereact/components/datatable/DataTable';
import NenhumRegistroEncontrado from '../../../components/NenhumRegistroEncontrado/index.js';
import { Paginator } from 'primereact/paginator';
import { Badge } from '../../../components/Badge/index.jsx';
import { Checkbox } from 'primereact/checkbox';

const Colors = {
    kBgLightGreen: '#dcedc8',
    kTextGreen: '#1b5e20',
    kBgLightRed: '#ffcdd2',
    kTextRed: '#b71c1c'
}


class Usuarios extends Component {
    constructor(props) {
        super(props);
        autoBind(this)
        this.state = {
            podeInserir: usuarioPossuiPermissao(recursos.USUARIOS, permissoes.INSERIR),
            podeExcluir: usuarioPossuiPermissao(recursos.USUARIOS, permissoes.EXCLUIR),
            deveExibirTutorial: buscarConfiguracaoUsuario(configuracoesUsuario.EXIBIR_TUTORIAL_LISTAGENS),
            descricaoFiltroAvancado: '',
            valorPesquisa: '',
            registros: [],
            sortField: 'nome',
            sortOrder: 1,
            page: 0,
            rows: 10,
            totalElements: 0,
            tutorialVisible: false,
            exibirUsuariosBloqueados: false
        }
    }

    componentDidMount() {
        this.pesquisar()

        if (this.state.deveExibirTutorial !== false) {
            this.setState({ tutorialVisible: true })
            salvarConfiguracaoUsuario(configuracoesUsuario.EXIBIR_TUTORIAL_LISTAGENS, false, null, false)
        }
    }

    buscarFiltro() {
        const { valorPesquisa } = this.state;
        const filtroAvancado = this.state.filtroAvancado || "";

        const valorCredencialConfirmada = formatarParaPesquisarParaCamposBoolean(valorPesquisa, 'EFETUADA', 'PENDENTE');
        const valorCredencialBloqueada = formatarParaPesquisarParaCamposBoolean(valorPesquisa, 'BLOQUEADA', 'DESBLOQUEADA');

        let result = `?query=(nome=contains="*${valorPesquisa}*",credencial.email=contains="*${valorPesquisa}*",credencial.ativa==${valorCredencialConfirmada},credencial.bloqueada==${valorCredencialBloqueada})`;
        if (filtroAvancado) {
            result += `;${filtroAvancado}`
        }
        return result
    }

    async pesquisar() {
        const { page, sortField, sortOrder, rows } = this.state;
        const filtro = this.buscarFiltro();
        const url = construirUrl(`${services.GESTOR}/v1/usuarios/resumo`, filtro, rows, page, sortOrder > 0 ? `${sortField},asc` : `${sortField},desc`);

        await asyncListarUsuarios(url, ({ data: usuarios }) => {
            this.setState({
                registros: usuarios.content,
                totalElements: usuarios.totalElements
            })
        })
    }

    onPesquisar() {
        this.setState({ page: 0 }, () => {
            this.pesquisar();
        })
    }

    onPageChange(e) {
        this.setState({ page: e.page, rows: e.rows }, () => {
            this.pesquisar()
        })
    }

    onSort(e) {
        this.setState({ sortField: e.sortField, sortOrder: e.sortOrder }, () => {
            this.pesquisar()
        })
    }

    onEditar(row) {
        atualizarUrl(this.props.history, '/usuarios/cadastro', row.id)
    }

    onExcluir(row) {
        confirmarExclusao(() => this.asyncExcluirRegistro(row))
    }

    async asyncExcluirRegistro(registro) {
        await asyncDeleteUsuario(registro.id, () => {
            this.setState({
                registros: removerElemento(this.state.registros, registro),
                totalElements: this.state.totalElements - 1
            })
        })
    }

    renderOpcoes(row) {
        return (
            <div style={{ display: 'inline' }} >
                <ButtonEditarTable onClick={() => this.onEditar(row)} />
                <ButtonExcluirTable
                    podeExcluir={this.state.podeExcluir}
                    onClick={() => this.onExcluir(row)}
                    disabled={row.contaConfirmada}
                    title={row.contaConfirmada ? "Não é possivel excluir um usuário com a conta confirmada" : "Excluir registro"}
                />
            </div>
        )
    }

    async onPesquisarFiltroAvancado(e) {
        await this.setState({ filtroAvancado: e })
        this.pesquisar()
    }

    onClickExibirUsuariosBloqueados() {
        this.setState({ exibirUsuariosBloqueados: !this.state.exibirUsuariosBloqueados });
    }

    renderContaConfirmada(row) {
        if (row.credencialAtiva) {
            return Badge(Colors.kTextGreen, Colors.kBgLightGreen, 'Efetuada');
        } else {
            return Badge(Colors.kTextRed, Colors.kBgLightRed, 'Pendente');
        }
    }


    renderContaBloqueada(row) {
        let fontColor = '';
        let backgroundColor = '';

        if (row.credencialBloqueada) {
            fontColor = '#b71c1c'
            backgroundColor = '#ffcdd2'
            return Badge(fontColor, backgroundColor, 'Bloqueada');
        } else {
            fontColor = '#1b5e20'
            backgroundColor = '#dcedc8'
            return Badge(fontColor, backgroundColor, 'Desbloqueada');
        }
    }

    render() {

        const {
            page,
            totalElements,
            podeInserir,
            sortField,
            sortOrder,
            registros,
            descricaoFiltroAvancado,
            valorPesquisa,
            exibirUsuariosBloqueados,
            rows
        } = this.state;

        const registrosFiltrados = exibirUsuariosBloqueados ? registros : registros.filter(el => !el.credencialBloqueada)

        return (
            <>
                <Tutorial
                    steps={tutorialSteps}
                    showSkipButton
                    continuous
                    visible={this.state.tutorialVisible}
                    onHide={() => this.setState({ tutorialVisible: false })}
                />
                <Form header="Usuários">
                    <FormActions>
                        <ButtonNovo
                            label='Novo usuário'
                            className="step-listagem-novo"
                            onClick={() => this.props.history.push("/usuarios/cadastro")}
                            title={podeInserir ? "Cadastrar um novo usuário" : "Você não possui permissão para executar essa ação"}
                            podeInserir={podeInserir}
                        />
                    </FormActions>
                    <FormContent>
                        <Grid justifyCenter>
                            <InputSearch
                                className="step-listagem-input-search"
                                onPesquisar={this.onPesquisar}
                                value={valorPesquisa}
                                onChange={value => this.setState({ valorPesquisa: value })}
                            />
                            <Col sm='12' md='4' lg='4' xl='3' className="step-listagem-filtro-avancado">
                                <PesquisaAvancada
                                    optionsFiltros={optionsFiltroAvancado}
                                    onPesquisarClick={this.onPesquisarFiltroAvancado}
                                    onChangeFiltroRsql={rsql => this.setState({ filtroAvancado: rsql })}
                                    onChangeDescricaoFiltro={e => this.setState({ descricaoFiltroAvancado: e })}
                                />
                            </Col>
                        </Grid>
                        <DescricaoFiltroAvancado texto={descricaoFiltroAvancado} />
                        <DataTable
                            className="table-light"
                            responsive
                            value={registrosFiltrados}
                            sortField={sortField}
                            sortOrder={sortOrder}
                            onSort={this.onSort}
                            emptyMessage={<NenhumRegistroEncontrado />}
                            header={
                                <span style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <Checkbox
                                        name="exibirUsuariosBloqueados"
                                        inputId="exibirUsuariosBloqueados"
                                        checked={exibirUsuariosBloqueados}
                                        onChange={this.onClickExibirUsuariosBloqueados}
                                    />
                                    <label htmlFor="exibirUsuariosBloqueados" className="p-checkbox-label">Exibir usuários bloqueados</label>
                                </span>
                            }
                        >
                            <Column
                                field="nome"
                                header="Nome"
                                sortable={true}
                            />
                            <Column
                                className="step-listagem-order"
                                field="credencialEmail"
                                header="E-mail"
                                body={this.renderEmail}
                                sortable={true}
                                style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}
                            />
                            <Column
                                field="contaConfirmada"
                                body={this.renderContaConfirmada}
                                header="Confirmação"
                                sortable={false}
                            />
                            <Column
                                field="credencialBloqueada"
                                body={this.renderContaBloqueada}
                                header="Situação"
                                sortable={true}
                            />
                            <Column
                                className="step-listagem-acoes"
                                body={this.renderOpcoes}
                                header="Ações"
                                style={{ width: '7em' }}
                            />
                        </DataTable>
                        <Paginator
                            className='paginator-light'
                            rowsPerPageOptions={[10, 25, 50]}
                            totalRecords={totalElements}
                            rows={rows}
                            first={page * rows}
                            onPageChange={this.onPageChange}
                            rightContent={<div style={{ margin: '5px' }}>{totalElements > 0 ? `${totalElements} registro(s) encontrado(s)` : null}</div>}
                        />
                    </FormContent>
                </Form>
            </>
        )
    }
}

export default withRouter(Usuarios)
