import { tipoCampos } from "../../../../../components/PesquisaAvancada/util/constantes"

export const optionsFiltroAvancado = [
    { label: 'Número', name: 'nro', type: tipoCampos.INTEGER },
    { label: 'Número da venda', name: 'vendaNumero', type: tipoCampos.INTEGER },
    { label: 'Cliente', name: 'destNome', type: tipoCampos.STRING },
    {
        label: 'Status', name: 'status', type: tipoCampos.SELECT,
        optionSelect: [
            { label: "Rejeitada", value: 'REJEITADA' },
            { label: "Transmitida", value: 'TRANSMITIDA' },
            { label: "Corrigida", value: 'CORRIGIDA' },
            { label: "Cancelada", value: 'CANCELADA' },
            { label: "Não enviada", value: 'NAO_ENVIADA' },
            { label: "Denegada", value: 'DENEGADA' },
            { label: "Aguardando autorização", value: 'AGUARDANDO_AUTORIZACAO' },
        ]
    },
];

export const ColorsCard = {
    NAO_ENVIADA: {
        lightColor: '#B3E5FC',
        strongColor: "#006095",
    },
    REJEITADA: {
        lightColor: '#FFCDD2',
        strongColor: "#B71C1C",
    },
    CANCELADA: {
        lightColor: '#E7ECF0',
        strongColor: "#333333",
    },
    AUTORIZADA: {
        lightColor: '#DCEDC8',
        strongColor: "#1B5E20",
    },
    DENEGADA: {
        lightColor: '##E7ECF0',
        strongColor: "#333333",
    }
}

export const infoStatusNfe = {
    REJEITADA: {
        name: 'Rejeitada',
        description: "Nota fiscal rejeitada pelo Sefaz",
        lightColor: '#FFCDD2',
        strongColor: '#B71C1C',
    },
    TRANSMITIDA: {
        name: 'Transmitida',
        description: "Nota fiscal transmitida para o Sefaz com sucesso",
        lightColor: '#DCEDC8',
        strongColor: '#1B5E20',
    },
    CORRIGIDA: {
        name: 'Corrigida',
        description: "Nota fiscal transmitida para o Sefaz e corrigida com sucesso",
        lightColor: '#DCEDC8',
        strongColor: '#1B5E20',
    },
    CANCELADA: {
        name: 'Cancelada',
        description: "Nota fiscal cancelada",
        lightColor: '#E7ECF0',
        strongColor: '#333333',
    },
    NAO_ENVIADA: {
        name: 'Não enviada',
        description: "A nota fiscal ainda não foi transmitida para o Sefaz",
        lightColor: '#B3E5FC',
        strongColor: '#006095',
    },
    DENEGADA: {
        name: 'Denegada',
        description: "Nota fiscal denegada pelo Sefaz",
        lightColor: '#E7ECF0',
        strongColor: '#333333',
    },
    AGUARDANDO_AUTORIZACAO: {
        name: "Aguardando",
        description: "A nota fiscal está sendo processada pelo Sefaz",
        lightColor: '#FFEFE5',
        strongColor: '#9D7A0A',
    }
};

export const Status = {
    REJEITADA: 'REJEITADA',
    TRANSMITIDA: 'TRANSMITIDA',
    CORRIGIDA: 'CORRIGIDA',
    CANCELADA: 'CANCELADA',
    NAO_ENVIADA: 'NAO_ENVIADA',
    DENEGADA: "DENEGADA",
    AGUARDANDO_AUTORIZACAO: "AGUARDANDO_AUTORIZACAO"
};

export const StatusNFeCards = {
    CANCELADA: 'CANCELADA',
    AUTORIZADA: 'AUTORIZADA',
    REJEITADA: 'REJEITADA',
    NAO_ENVIADA: 'NAO_ENVIADA'
};

export const Operacao = {
    INSERIR: 'INSERIR',
    EDITAR: 'EDITAR',
    EXCLUIR: 'EXCLUIR',
};

export const TipoDestinatario = {
    FISICA: 'FISICA',
    JURIDICA: 'JURIDICA',
    ESTRANGEIRO: 'ESTRANGEIRO'
}

export const IndPres = {
    NAO_SE_APLICA: 'NAO_SE_APLICA',
    OPERACAO_PRESENCIAL: 'OPERACAO_PRESENCIAL',
    OPERACAO_PELA_INTERNET: 'OPERACAO_PELA_INTERNET',
    OPERACAO_POR_TELEATENDIMENTO: 'OPERACAO_POR_TELEATENDIMENTO',
    OPERACAO_PRESENCIAL_FORA_DO_ESTABELECIMENTO: 'OPERACAO_PRESENCIAL_FORA_DO_ESTABELECIMENTO',
    OUTROS: 'OUTROS'
}

export const CADASTROURL = '/nfes/cadastro';
export const PESQUISAURL = '/nfes';
