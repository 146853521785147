import React, { Component } from 'react';
import autoBind from 'react-autobind';
import { recursos, permissoes } from '../../../common/constantes/autorizacao';
import SingleSelect from '../SingleSelect';
import ModalSetor from '../../../views/cadastros/Setores/Modal';
import propTypes from 'prop-types'
import { usuarioPossuiPermissao } from '../../../common/autenticacao';

class SingleSelectSetor extends Component {
    constructor(props) {
        super(props);

        autoBind(this);

        this.state = {
            visible: false,
            podeInserir: usuarioPossuiPermissao(recursos.SETORES, permissoes.INSERIR),
            podeVisualizar: usuarioPossuiPermissao(recursos.SETORES, permissoes.VISUALIZAR),
        }
    }

    buscarUrlPesquisa(pesquisa, page) {
        return `${this.props.url}?query=nome=contains="*${pesquisa}*"&page=${page}&size=50&sort=nome`;
    }

    mostrarModal() {
        this.setState({ visible: !this.state.visible });
    }

    esconderModal() {
        this.setState({ ...this.state, visible: false })
    }

    setarNovoRegistroNoSelect(novoRegistro) {
        if (novoRegistro) {
            this.props.onChange({ label: novoRegistro.nome, value: novoRegistro.id })
        }
    }

    onHide(novoRegistro) {
        this.setarNovoRegistroNoSelect(novoRegistro)
        this.esconderModal()
    }

    montarLabel(row) {
        return row && row.nome;
    }

    

    render() {

        const { podeInserir, podeVisualizar } = this.state
        const podeInserirEVisualizar = podeInserir && podeVisualizar

        return (
            <React.Fragment>
                <ModalSetor
                    service={this.props.service}
                    visible={this.state.visible}
                    onHide={this.onHide}
                    atualizarRegistrosSelect={this.atualizarRegistrosSelect}
                />
                <SingleSelect
                    titleBotaoNovo="Adicionar novo setor"
                    buscarUrlPesquisa={this.buscarUrlPesquisa}
                    montarLabel={this.montarLabel}
                    onClickModal={this.mostrarModal}
                    {...this.props}
                    disabledButton={!podeInserirEVisualizar}
                    onChange={(e) => {
                        this.props.onChange(e)
                    }}
                />
            </React.Fragment>
        );
    }
}

SingleSelectSetor.defaultProps = {
    label: 'Setor',
}

SingleSelectSetor.propTypes = {
    /** Url do servidor em que as requisições de busca deverão ser efetuadas: Ex:  `${services.GESTOR}/v1/usuarios/relacoes/pessoas`*/
    url: propTypes.string.isRequired,
}


export default SingleSelectSetor;
