import { parse } from "date-fns/esm"
import { formatarMonetario } from "../../../../../../common/mascara"
import { format } from "date-fns"
import { montarLabelPessoa } from "../../../../../../components/Select/SingleSelectPessoa/utils"

export function converterContaPagarParaFormulario(values) {

    return {
        ...values,
        pessoa: converterPessoaParaFormulario(values.pessoa),
        categoria: converterCategoriaParaFormulario(values.categoria),
        pagamentos: converterPagamentosParaFormulario(values.pagamentos),
        emissao: values.emissao,
        competencia: values.competencia,
        vencimento: values.vencimento,
        dataDocumento: values.dataDocumento,
    }

}

function converterPessoaParaFormulario(pessoaApi) {
    if (pessoaApi) {
        return {
            label: montarLabelPessoa(pessoaApi),
            value: pessoaApi.id,
            registro: pessoaApi
        }
    }
    return null
}


function converterCategoriaParaFormulario(categoriaApi) {
    if (categoriaApi) {
        return {
            label: categoriaApi.nome,
            value: categoriaApi.id,
            registro: categoriaApi
        }
    }
    return null
}

function converterPagamentosParaFormulario(pagamentosApi) {
    if(!pagamentosApi){
        return []
    }
    let pagamentosFormulario = pagamentosApi.map(pagamentoApi => {
        const data = pagamentoApi.data ? parse(pagamentoApi.data, 'yyyy-MM-dd', new Date()) : null
        return {
            ...pagamentoApi,
            data: pagamentoApi.data,
            dataLabel: data ? format(data, 'dd/MM/yyyy') : null,
            valorLabel: formatarMonetario(pagamentoApi.valor),
            jurosLabel:  formatarMonetario(pagamentoApi.juros),
            descontosLabel:  formatarMonetario(pagamentoApi.descontos),
            multaLabel:  formatarMonetario(pagamentoApi.multa),
            totalLabel:  formatarMonetario(pagamentoApi.total),
            conta: converterContaParaFormulario(pagamentoApi.conta)
        }
    });
    return pagamentosFormulario;
}

function converterContaParaFormulario(contaApi) {
    if (contaApi) {
        return {
            label: contaApi.nome,
            value: contaApi.id,
            registro: contaApi
        }
    }
    return null
}

function converterPagamentosParaApi(values) {
    let pagamentosApi = []
    if (values.pago) {
        pagamentosApi.push({
            valor: values.valorPago,
            data: values.dataPago,
            conta: values.contaPago ? { id: values.contaPago.value } : null,
            juros: 0,
            multa: 0,
            desconto: 0,
        })
    } else {
        pagamentosApi = values.pagamentos.map(pagamentoFormulario => {
            return {
                ...pagamentoFormulario,
                conta: pagamentoFormulario.conta ? { id: pagamentoFormulario.conta.value } : null,
                juros: pagamentoFormulario.juros || 0,
                multa: pagamentoFormulario.multa || 0,
                desconto: pagamentoFormulario.desconto || 0,
            }
        });
    }
    return pagamentosApi;
}



export function converterContaPagarParaApi(values) {
    return {
        ...values,
        categoria: values.categoria ? { id: values.categoria.value } : null,
        pessoa: values.pessoa ? { id: values.pessoa.value } : null,
        pagamentos: values.pagamentos || values.pago ? converterPagamentosParaApi(values) : null,
    }
}