import React, { Component } from 'react';
import { Card } from 'primereact/card';
import Col from '../../../../../components/Col';
import Grid from '../../../../../components/Grid';
import autoBind from 'react-autobind';
import { usuarioPossuiPermissao } from '../../../../../common/autenticacao';
import { recursos, permissoes } from '../../../../../common/constantes/autorizacao';
import './style/index.css'
import ButtonEditarTable from '../../../../../components/Button/ButtonEditarTable';
import ButtonExcluirTable from '../../../../../components/Button/ButtonExcluirTable';

const styleNomeConta = {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    padding: '0px 0px 0px 6px',
    fontSize: '15px',
    color: '#333333',
    fontWeight: 'bold',
    flexDirection: 'column',
};
const styleNumeroConta = {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    padding: '0px 0px 0px 6px',
    fontSize: '12px',
    color: '#7a7a7a',
    display: 'flex',
    flexDirection: 'column',
};

const styleBotaoOpcoesCard = {
    padding: '0px',
    display: 'flex',
    justifyContent: 'flex-end'
};

class CardCorretora extends Component {

    constructor(props) {

        super(props);

        autoBind(this);

        this.state = {
            podeExcluir: usuarioPossuiPermissao(recursos.INVESTIDOR_CORRETORAS, permissoes.EXCLUIR),
        }
    }

    renderizarContas() {
        const corretora = this.props.corretora
        const quantidadeContas = corretora.contas.length

        if (quantidadeContas === 1) {
            return `${quantidadeContas} conta cadastrada`
        } else if (quantidadeContas > 1) {
            return `${quantidadeContas} contas cadastradas`
        }
        return <span>Nenhuma conta cadastrada</span>
    }

    render() {
        const { nome, codigo } = this.props.corretora
        const { podeExcluir } = this.state

        return (
            <Col col='12' sm='12' md='4' lg='4' xl='4'>
                <Card>
                    <Grid>
                        <Col col='8' lg='9' style={styleNomeConta}>
                            <span title={`${codigo} - ${nome}`}>{`${codigo} - ${nome}`}</span>
                        </Col>
                        <Col col='4' lg='3' style={styleBotaoOpcoesCard}>
                            <ButtonEditarTable
                                onClick={() => this.props.editarCorretora(this.props.corretora)}
                            />
                                <ButtonExcluirTable
                                    onClick={() => this.props.excluirCorretora(this.props.corretora)}
                                    podeExcluir={podeExcluir}
                                />
                        </Col>
                        <Col col='8' lg='9' style={styleNumeroConta}>
                            {this.renderizarContas()}
                        </Col>
                    </Grid>
                </Card>
            </Col>
        )
    }
}
export default CardCorretora;
