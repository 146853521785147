import React, { Component } from 'react';
import Grid from '../../../../../../../../components/Grid';
import Col from '../../../../../../../../components/Col';
import { formatarMonetario } from '../../../../../../../../common/mascara';
import If from '../../../../../../../../components/If';
import { Vendas } from '../../../../../../../util/constantes';

const produtoStyle = {
    fontSize: '15px',
    fontWeight: '400',
    lineHeight: '1.5',
    color: '#000000de'
}

const hrStyle = {
    width: '100%',
    border: 'none',
    height: '1px',
    margin: '0',
    flexShrink: '0',
    backgroundColor: '#0000001f',
    marginBottom: '7px'
}

const valorStyle = {
    display: 'flex',
    justifyContent: 'flex-end',
    fontSize: '15px',
    fontWeight: '600',
    color: Vendas.cores.verde,
    padding: '0px',
}

export default class RankingMaisVendidos extends Component {
    render() {
        const { itens, exibirButtonVerMais } = this.props
        return (
            <div className='dashboard-scroll' style={{ padding: '15px', maxHeight: '280px', overflow: 'auto', marginTop: '10px' }}>
                {itens.map((item, index) => {
                    return (
                        <React.Fragment key={index}>
                            <Grid justifyBetween >
                                <Col col='12' style={{ paddingBottom: '0px' }} >
                                    <span style={produtoStyle}> <b>{index + 1}º</b> {item.descricao}</span>
                                </Col>
                                <Col col='12' style={{ display: 'flex', alignItems: 'center', paddingTop: '0px', justifyContent: 'flex-end' }}>
                                    <span style={valorStyle}>{formatarMonetario(item.valor)}</span>
                                </Col>
                                <hr style={hrStyle} />
                            </Grid>
                        </React.Fragment>
                    )
                })}
                <If test={exibirButtonVerMais}>
                    <div style={{ textAlign: 'center' }}>
                        <span onClick={this.props.onVerMaisClick} style={{ color: `${Vendas.cores.azul}`, cursor: 'pointer' }}>Ver mais</span>
                    </div>
                </If>
            </div>
        );
    }
}

