import { services } from '../../../../common/constantes/api'
import { post, exibirToast } from '../../../../common/requisicoes'

export async function asyncRecuperarSenha(email, onSuccess, onError) {
    const body = {
        email: email
    }

    const message = 'Um e-mail de redefinição de senha foi enviado para sua caixa de entrada.'
    post(services.GESTOR + '/v1/credenciais/recuperar_senha', body, null, exibirToast(onSuccess, message), onError)
}