import React, { Component } from 'react';
import autoBind from 'react-autobind';
import Dropdown from '../../../../../../../../../components/Select/Dropdown';
import { condicaoDropdown, quantidadeParcelasDropdown, Condicoes } from '../../../../../util/constantes';
import Grid from '../../../../../../../../../components/Grid';
import { Field } from 'formik';
import SingleSelectCategoria, { tiposCategoria } from '../../../../../../../../../components/Select/SingleSelectCategoria';
import { services } from '../../../../../../../../../common/constantes/api';
import { addMonths, formatISO, parseISO, isValid } from 'date-fns';
import ParcelasMemorizadas from './ParcelasMemorizadas';
import { connect } from 'react-redux';
import If from '../../../../../../../../../components/If';
import { validarValorNegativo } from '../../../../../util/functions';
import Col from '../../../../../../../../../components/Col';
import { copiarObjeto } from '../../../../../../../../../common/array';

class ParcelasNfce extends Component {
    constructor(props) {
        super(props);
        autoBind(this);
    }

    async onChangeValor(value, index) {
        let parcelas = copiarObjeto(this.props.parcelas)

        if (parcelas[index].valor !== value) {
            parcelas[index].valor = validarValorNegativo(value)

            await this.props.onChangeParcelas(parcelas)
            this.recalcularRateioParcela(index)
        }
    }

    onChangeVencimentoParcela(data, index) {
        let parcelas = copiarObjeto(this.props.parcelas)

        if (index === 0 && isValid(parseISO(data))) {
            parcelas = parcelas.map((parcela, index) => {
                return { ...parcela, vencimento: formatISO(addMonths(parseISO(data), index)) }
            })
            this.props.onChangeParcelas(parcelas)
        } else {
            parcelas[index] = { ...parcelas[index], vencimento: data }
            this.props.onChangeParcelas(parcelas)
        }
    }

    onChangeDescricao(value, index) {
        let parcelas = copiarObjeto(this.props.parcelas)

        parcelas[index] = { ...parcelas[index], descricao: value }

        this.props.onChangeParcelas(parcelas)
    }

    onChangeFormaPagamento(value, index) {
        let parcelas = copiarObjeto(this.props.parcelas)

        parcelas[index] = { ...parcelas[index], forma: value }

        this.props.onChangeParcelas(parcelas)
    }

    async onClickExcluir(index) {
        const { parcelas, quantidadeParcelas } = this.props
        let parcelasTemp = copiarObjeto(parcelas);

        parcelasTemp.splice(index, 1)

        await this.props.onChangeQuantidadeParcelas(quantidadeParcelas - 1)
        await this.props.onChangeParcelas(parcelasTemp)

        this.recalcularRateioParcelaAoExcluir(index)
    }


    async onChangeCondicaoParcelas(e) {
        const { onChangeCondicao, onChangeQuantidadeParcelas, onChangeParcelas, parcelas, emissao } = this.props;

        let apenasPrimeiraParcela = null;
        if (isValid(parseISO(emissao))) {
            let vencimentoPrimeiraParcela = e.value === Condicoes.A_VISTA ? parseISO(emissao) : addMonths(parseISO(emissao), 1);
            apenasPrimeiraParcela = [{ ...parcelas[0], vencimento: formatISO(vencimentoPrimeiraParcela), forma: 'DINHEIRO' }]
        } else {
            apenasPrimeiraParcela = [{ ...parcelas[0], vencimento: null, forma: 'DINHEIRO' }]
        }

        await onChangeCondicao(e.value)
        await onChangeQuantidadeParcelas(1)
        await onChangeParcelas(apenasPrimeiraParcela)

        this.gerarParcelas();

    }

    gerarParcelas() {

        const { parcelas, quantidadeParcelas, emissao } = this.props;

        let parcelasTemp = [];

        const rateioParcelas = this.rateioParcelas();

        for (let i = 0; i < quantidadeParcelas; i++) {
            let oldParcela = parcelas && parcelas[i];
            let valorParcela = rateioParcelas.valorPorParcela;

            if (quantidadeParcelas - 1 === i) {
                valorParcela = rateioParcelas.valorUltimaParcela
            }

            let idParcela = oldParcela ? oldParcela.id : null
            let vencimento = oldParcela ? oldParcela.vencimento : emissao && formatISO(addMonths(parseISO(emissao), i + 1))
            let descricao = oldParcela ? oldParcela.descricao : ""
            let forma = oldParcela ? oldParcela.forma : "DINHEIRO"

            parcelasTemp.push({
                id: idParcela,
                vencimento: vencimento,
                valor: valorParcela,
                descricao: descricao,
                forma: forma
            })
        }

        this.props.onChangeParcelas(parcelasTemp)
    }

    valorSomadoDasParcelasAnteriores(parcelas, index) {
        let valorParcelasAnteriores = 0.0;
        for (let i = 0; i < parcelas.length; i++) {
            if (i < index) {
                valorParcelasAnteriores += parcelas[i].valor
            }
        }
        return valorParcelasAnteriores
    }

    recalcularRateioParcela(indexItem) {

        let parcelas = this.props.parcelas

        const valorInformado = parcelas[indexItem].valor

        const valorParcelasAnteriores = this.valorSomadoDasParcelasAnteriores(parcelas, indexItem);
        const valorASerRatiado = this.props.totalLiquido - (valorParcelasAnteriores + valorInformado)
        const quantidadeParcelasRestantes = (parcelas.length - 1) - indexItem
        const valorRatiadoParaCadaParcelaRestante = parseFloat((valorASerRatiado / quantidadeParcelasRestantes).toFixed(2))

        let somaDasParcelas = 0;

        parcelas = parcelas.map((parcela, iterador) => {
            let valor = parcela.valor;

            if (iterador > indexItem) {
                somaDasParcelas += valorRatiadoParaCadaParcelaRestante
                if (iterador === (parcelas.length - 1)) {
                    valor = validarValorNegativo(parseFloat((valorRatiadoParaCadaParcelaRestante + (this.props.totalLiquido - somaDasParcelas)).toFixed(2)));
                } else {
                    valor = validarValorNegativo(valorRatiadoParaCadaParcelaRestante)
                }
            } else {
                somaDasParcelas += parcela.valor
            }
            parcela.valor = valor;
            return parcela
        })

        this.props.onChangeParcelas(parcelas)
    }

    recalcularRateioParcelaAoExcluir(indexItem) {
        let parcelas = this.props.parcelas

        const valorSomadoDasParcelasAnteriores = this.valorSomadoDasParcelasAnteriores(parcelas, indexItem);
        const valorASerRatiado = this.props.totalLiquido - valorSomadoDasParcelasAnteriores
        const quantidadeParcelasRestantes = parcelas.length - indexItem
        const valorRatiadoParaCadaParcelaRestante = parcelas.length === indexItem ? 0 : parseFloat((valorASerRatiado / quantidadeParcelasRestantes).toFixed(2))

        let somaDasParcelas = 0;

        parcelas = parcelas.map((parcela, iterador) => {
            if (iterador === parcelas.length - 1) {
                return { ...parcela, valor: this.props.totalLiquido - somaDasParcelas }
            } else if (iterador >= indexItem) {
                somaDasParcelas += valorRatiadoParaCadaParcelaRestante
                return { ...parcela, valor: validarValorNegativo(valorRatiadoParaCadaParcelaRestante) }
            } else {
                somaDasParcelas += parcela.valor
            }

            return parcela
        })

        this.props.onChangeParcelas(parcelas)
    }

    rateioParcelas() {
        const { totalLiquido, quantidadeParcelas } = this.props

        const valorPorParcela = parseFloat((totalLiquido / quantidadeParcelas).toFixed(2))
        const valorUltimaParcela = parseFloat((totalLiquido - (valorPorParcela * (quantidadeParcelas - 1))).toFixed(2))

        return { valorPorParcela: valorPorParcela, valorUltimaParcela: valorUltimaParcela }
    }

    render() {
        const {
            parcelas,
            condicao,
            isMobile,
            errors,
            informacoesPermissoes,
            quantidadeParcelas,
            categoria,
            onChangeQuantidadeParcelas,
            disabled,
        } = this.props;

        const isAVista = condicao === Condicoes.A_VISTA;
        const isAPrazo = condicao === Condicoes.A_PRAZO

        const valorResponsivo = isAVista ? '6' : '4'

        return (
            <Grid>
                <Field xs={valorResponsivo} sm={valorResponsivo} md={valorResponsivo} lg={valorResponsivo} xl={valorResponsivo}
                    component={Dropdown}
                    label='Condição'
                    name="condicao"
                    helpMessage="Condição de pagamento"
                    obrigatorio
                    value={condicao}
                    showClear={false}
                    options={condicaoDropdown}
                    onChange={this.onChangeCondicaoParcelas}
                    disabled={disabled}
                    {...informacoesPermissoes}
                />
                <If test={isAPrazo}>
                    <Field xs={valorResponsivo} sm={valorResponsivo} md={valorResponsivo} lg={valorResponsivo} xl={valorResponsivo}
                        component={Dropdown}
                        label='Qtd. Parcelas'
                        name="quantidadeParcelas"
                        helpMessage="Quantidade de parcelas que serão geradas para esta venda"
                        obrigatorio
                        value={quantidadeParcelas}
                        disabled={disabled}
                        showClear={false}
                        options={quantidadeParcelasDropdown}
                        onChange={async (e) => {
                            await onChangeQuantidadeParcelas(e.value)
                            this.gerarParcelas()
                        }}
                        {...informacoesPermissoes}
                    />
                </If>
                <Field xs={valorResponsivo} sm={valorResponsivo} md={valorResponsivo} lg={valorResponsivo} xl={valorResponsivo}
                    name="categoria"
                    label="Categoria"
                    helpMessage="Categoria financeira da venda. Útil para visualização das receitas por categoria no dashboard financeiro"
                    component={SingleSelectCategoria}
                    tipoCategoria={tiposCategoria.RECEITA}
                    value={categoria}
                    disabled={disabled}
                    onChange={this.props.onChangeCategoria}
                    url={`${services.GESTOR}/v1/vendas/relacoes/categorias/receitas`}
                    {...informacoesPermissoes}
                />
                <Col>
                    {parcelas.map((parcela, index) => {
                        return (
                            <ParcelasMemorizadas
                                condicaoPagamento={condicao}
                                key={index}
                                index={index}
                                isMobile={isMobile}
                                labelParcela={(index + 1) + "ª "}
                                valueVencimento={parcela.vencimento}
                                onChangeVencimento={(e) => this.onChangeVencimentoParcela(e.target.value, index)}
                                valueValor={parcela.valor}
                                onChangeValor={(e) => this.onChangeValor(e.target.value, index)}
                                onChangeFormaPagamento={(e) => this.onChangeFormaPagamento(e.value, index)}
                                valueFormaPagamento={parcela.forma}
                                valueDescricao={parcela.descricao}
                                onChangeDescricao={(e) => this.onChangeDescricao(e.target.value, index)}
                                recalcularRateioParcela={() => this.recalcularRateioParcela(index, parcela.valor)}
                                onClickExcluir={() => this.onClickExcluir(index)}
                                backgroundColor={isMobile && index % 2 === 0 ? "#f1f1f1" : null}
                                exibirLabels={index === 0}
                                errors={errors && errors.pag && errors.pag[index]}
                                excluirDisabled={parcelas.length === 1}
                                informacoesPermissoes={informacoesPermissoes}
                                disabled={disabled}
                            />
                        )
                    })}
                </Col>
            </Grid>
        )
    }
}

const mapStateToProps = state => ({
    isMobile: state.dispositivo.isMobile,

})

export default connect(mapStateToProps)(ParcelasNfce);
