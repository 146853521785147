import { getYear } from 'date-fns';

export function converterConfiguracoesGeraisParaFormulario(values, marcarCheckBoxEmiteNfe, marcarCheckBoxEmiteNfce) {
    return {
        ...values,
        logradouro: values.endereco?.logradouro ?? null,
        numero: values.endereco?.numero ?? null,
        bairro: values.endereco?.bairro ?? null,
        cep: values.endereco?.cep ?? null,
        inscricaoEstadual: values.inscricaoEstadual ?? null,
        razaoSocial: values.razaoSocial ?? null,
        municipio: values.endereco ? converterMunicipioParaFormulario(values.endereco.municipio) : null,
        pais: values.endereco ? converterPaisParaFormulario(values.endereco.pais) : null,
        complemento: values.endereco ? values.endereco.complemento : null,
        modulos: values.modulos.sort(),
        certificado: converterCertificadoParaFormulario(values.certificado),
        certificadoSenha: null,
        certificadoConfirmacaoSenha: null,

        tipoImpressaoDanfeNfe: values.fiscalNfe?.tipoImpressaoDanfeNfe,
        impressaoPartilhaDanfeNfe: values.fiscalNfe?.impressaoPartilhaDanfeNfe,
        impressaoFcpDanfeNfe: values.fiscalNfe?.impressaoFcpDanfeNfe,
        numeroInicialNfe: values.fiscalNfe?.numeroInicialNfe,
        envioAutomaticoEmailNfe: values.fiscalNfe?.envioAutomaticoEmailNfe,

        serieNfe: values.fiscalNfe?.serieNfe,
        tipoImpressaoDanfeNfce: values.fiscalNfce?.tipoImpressaoDanfeNfce,
        serieNfce: values.fiscalNfce?.serieNfce,
        numeroInicialNfce: values.fiscalNfce?.numeroInicialNfce,
        envioAutomaticoEmailNfce: values.fiscalNfce?.envioAutomaticoEmailNfce,
        codigoSegurancaContribuinteNfce: values.fiscalNfce?.codigoSegurancaContribuinteNfce,
        tokenSegurancaContribuinteNfce: values.fiscalNfce?.tokenSegurancaContribuinteNfce,
        csosnPadraoNfce: values.fiscalNfce?.csosnPadraoNfce,
        cfopPadraoNfce: values.fiscalNfce?.cfopPadraoNfce ? values.fiscalNfce.cfopPadraoNfce : null,

        certificadoVencimento: values.certificado != null ? values.certificado.vencimento : null,
        regimeTributario: values.regimeTributario ?? null,
        emiteNfe: marcarCheckBoxEmiteNfe === true ? marcarCheckBoxEmiteNfe : values.emiteNfe,
        emiteNfce: marcarCheckBoxEmiteNfce === true ? marcarCheckBoxEmiteNfce : values.emiteNfce,
    }
}

export function converterConfiguracoesGeraisParaApi(values) {
    return {
        ...values,
        pais: null,
        endereco: {
            logradouro: values.logradouro,
            numero: values.numero,
            bairro: values.bairro,
            cep: values.cep,
            municipio: values.municipio ? { id: values.municipio.value } : null,
            pais: values.pais ? { id: values.pais.value } : null,
            complemento: values.complemento,
        },
        fiscalNfe: {
            tipoImpressaoDanfeNfe: values.tipoImpressaoDanfeNfe,
            impressaoFcpDanfeNfe: values.impressaoFcpDanfeNfe,
            impressaoPartilhaDanfeNfe: values.impressaoPartilhaDanfeNfe,
            serieNfe: values.emiteNfe ? values.serieNfe : null,
            numeroInicialNfe: values.numeroInicialNfe,
            envioAutomaticoEmailNfe: values.envioAutomaticoEmailNfe
        },
        fiscalNfce: {
            envioAutomaticoEmailNfce: values.envioAutomaticoEmailNfce,
            tipoImpressaoDanfeNfce: values.tipoImpressaoDanfeNfce,
            tokenSegurancaContribuinteNfce: values.tokenSegurancaContribuinteNfce,
            codigoSegurancaContribuinteNfce: values.codigoSegurancaContribuinteNfce,
            serieNfce: values.emiteNfce ? values.serieNfce : null,
            numeroInicialNfce: values.numeroInicialNfce,
            csosnPadraoNfce: values.csosnPadraoNfce,
            cfopPadraoNfce: {
                id: values.cfopPadraoNfce?.id ?? null
            },
        },
    }
}

export function converterAliquotaSimplesParaApi(values) {
    return {
        ...values,
        percentualAliquota: values.aliquotaSimples,
        anoVigencia: getYear(new Date()),
    }
}

function converterMunicipioParaFormulario(municipio) {
    if (municipio) {
        return {
            label: municipio.nome + ' - ' + municipio.estadoSigla,
            value: municipio.id
        }
    }
    return null
}

function converterCertificadoParaFormulario(certificadoApi) {
    if (!certificadoApi) {
        return null
    }
    return {
        name: certificadoApi.nome,
        vencimento: certificadoApi.vencimento
    }
}

function converterPaisParaFormulario(pais) {
    if (pais) {
        return {
            label: pais.nome,
            value: pais.id
        }
    }
    return null
}