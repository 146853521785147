import { dispatchAction } from "../../../../common/redux";
import { actionTypes } from "../../../../common/constantes/reduxTypes";

export function dispararAcoesReduxAtualizacaoPlano() {
    dispatchAction(actionTypes.ATUALIZAR_PLANO)
}

export function dispararAcoesReduxPossuiSuporteViaChat(possuiSuporteViaChat) {
    dispatchAction(actionTypes.POSSUI_SUPORTE_VIA_CHAT, possuiSuporteViaChat)
}
