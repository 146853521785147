import React, { Component } from 'react'
import autoBind from 'react-autobind/lib/autoBind';
import Message from '../../../../../components/Message';

const textStyle = {
    margin: '5px 0px'
}

class MessagePagamentoNaoEfetuado extends Component {
    constructor(props) {
        super(props);

        autoBind(this);
    }

    render() {
        return (
            <Message
                severity="info"
                colStyle={{ margin: '1em 0em' }}
                text={
                    <div>
                        <h2>Sua organização foi bloqueada</h2>
                        <p style={textStyle}>Não recebemos o pagamento da mensalidade. Caso já tenha efetuado o pagamento, aguarde até que o boleto seja compensado. Caso tiver alguma dúvida, favor entrar em contato</p>
                        <span onClick={() => window.open('http://www.microsys.inf.br/s/index.php?pg=contato', '_blank')} style={{ cursor: 'pointer', fontWeight: 'bold' }}>
                            Entrar em contato
                        </span>
                    </div>
                }
            />
        )
    }
}

export default MessagePagamentoNaoEfetuado
