import React, { Component } from 'react';
import ButtonCancelar, { estadosBotaoCancelar } from "../../../../../components/Button/ButtonCancelar";
import LayoutMenuSuperior from "../../../../../components/Layout/LayoutMenuSuperior";
import autoBind from 'react-autobind';
import Modal from '../../../../../components/Modal';
import If from '../../../../../components/If';
import propTypes from 'prop-types'
import FeedbackResumoAssinaturas from '../../components/FeedbackResumoAssinaturas';
import Grid from '../../../../../components/Grid';
import ampulheta from '../../../images/ampulheta.svg'
import assinaturaEfetuada from '../../../images/assinatura_efetuada.svg'
import assinaturaRejeitada from '../../../images/assinatura_rejeitada.svg'
import documentoExpirado from '../../../images/documento_expirado.svg'
import { statusDocumento } from '../../../util/constantes';
import format from 'date-fns/format';
import { connect } from 'react-redux';
import { parseISO } from 'date-fns';

const detalhesStyle = {
    margin: 0,
    color: '#7b7b7b',
    wordBreak: 'break-word'
}

const papelSignatario = {
    PARTE: 'Parte',
    TESTEMUNHA: 'Testemunha',
    AVALISTA: 'Avalista',
    CONTRATANTE: 'Contratante',
    CONTRATADA: 'Contratada',
    CEDENTE: 'Cedente',
    CESSIONARIO: 'Cessionário',
    DEVEDOR_SOLIDARIO: 'Devedor solidário',
    EMITENTE: 'Emitente',
    ENDOSSANTE: 'Endossante',
    ENDOSSATARIO: 'Endossátario',
    GESTOR: 'Gestor',
    INTERVENIENTE: 'Interveniente',
    PARTE_COMPRADORA: 'Parte compradora',
    PARTE_VENDEDORA: 'Parte vendedora',
    PROCURADOR: 'Procurador',
    REPRESENTANTE_LEGAL: 'Representante legal',
    RESPONSAVEL_SOLIDARIO: 'Responsável solidário',
    VALIDADOR: 'Validador',
    ACUSAR_RECEBIMENTO: 'Acusar recebimento',
    SOLICITANTE: 'Solicitante',
}

const gridStyle = {
    marginTop: '40px',
    minHeight: '110px',
    borderBottom: '1px solid #e2e2e2',
    borderLeft: '3px solid #0288d1',
    paddingLeft: '10px'
}

const enviarEmailStyle = {
    fontWeight: 'bold',
    cursor: 'pointer'
}

class ModalVerDetalhesDocumento extends Component {

    constructor(props) {
        super(props);

        autoBind(this);
    }

    cancelar() {
        this.props.onHide();
    }

    buscarTextoAssinatura(assinatura) {
        if (!assinatura) {
            const documentoInutilizado = Boolean(this.props.documento.status === statusDocumento.REJEITADO || this.props.documento.status === statusDocumento.EXPIRADO);
            return (
                <p style={detalhesStyle}>
                    <strong>{documentoInutilizado ? "Documento não assinado pelo signatário" : "Aguardando assinatura"}</strong>
                </p>
            )
        }
        return null;
    }

    buscarBotaoReenviarEmail(signatario) {
        const documentoInutilizado = Boolean(
            (this.props.documento.status === statusDocumento.REJEITADO) ||
            (this.props.documento.status === statusDocumento.EXPIRADO) ||
            (this.props.documento.status === statusDocumento.ASSINADO)
        );
        const assinaturaEfetuada = Boolean(
            (signatario.assinatura && signatario.assinatura.status === "ASSINADO") ||
            (signatario.assinatura && signatario.assinatura.status === "REJEITADO")
        );

        if (!documentoInutilizado && !assinaturaEfetuada) {
            return (
                <div style={{ marginTop: '20px' }}>
                    {/* eslint-disable-next-line */}
                    <a
                        style={enviarEmailStyle}
                        onClick={() => this.props.onClickEnviarEmailSignatarioEspecifico(signatario)}
                    >
                        Reenviar e-mail
                    </a>
                </div>
            )
        }
    }

    montarDetalhesAssinatura(assinatura) {
        if (assinatura) {
            const dataParseada = parseISO(assinatura.dataHora)
            return (
                <>
                    <p style={detalhesStyle}><strong>Documento {assinatura.status === "REJEITADO" ? 'rejeitado' : 'assinado'} em {format(dataParseada, 'dd/MM/yyyy')}</strong></p>
                    <If test={assinatura.status === "ASSINADO"} >
                        <p style={detalhesStyle}><strong>IP do dispositivo: </strong>{assinatura.ip}</p>
                        <p style={detalhesStyle}><strong>Localização do signatário: </strong>Latitude: {assinatura.latitude}. Longitude: {assinatura.longitude}</p>
                    </If>
                    <p style={detalhesStyle}><strong>Observações do signatário: </strong> {(assinatura.observacao) || 'Nenhuma observação deixada pelo signatário.'} </p>
                </>
            )
        }
    }

    montarHashDocumento(documento) {
        if (documento.hash256) {
            return (
                <p style={{ ...detalhesStyle }}>
                    <strong>Chave do documento (Hash SHA256): </strong>
                    {documento.hash256}
                </p>
            )
        }
    }

    renderizarDetalhes() {
        const { documento, isMobile } = this.props;

        let signatarios = documento.signatarios;

        //ordena por nome
        signatarios = signatarios && signatarios.sort((a, b) => {
            return (String(a.nome).toLowerCase() > String(b.nome).toLowerCase()) ? 1 : ((String(b.nome).toLowerCase() > String(a.nome).toLowerCase()) ? -1 : 0);
        });

        return signatarios && signatarios.map((signatario, key) => {

            const assinatura = signatario.assinatura;
            let icon


            if (assinatura) {
                if (assinatura.status === "ASSINADO") {
                    icon = assinaturaEfetuada
                }
                if (assinatura.status === "REJEITADO") {
                    icon = assinaturaRejeitada
                }
            } else {
                if (documento.status === statusDocumento.EXPIRADO) {
                    icon = documentoExpirado
                } else {
                    icon = ampulheta
                }
            }


            return (
                <span key={key}>
                    <Grid justifyBetween style={gridStyle}>
                        <div className='p-col'>
                            <h2 style={{ fontSize: '16px' }}>
                                {`${papelSignatario[signatario.papel]}: ${signatario.nome} (${signatario.email})`}
                            </h2>
                            <div>
                                {this.montarDetalhesAssinatura(assinatura)}
                                {this.buscarTextoAssinatura(assinatura)}
                            </div>
                        </div>

                        <div style={{ width: isMobile ? '100%' : 'initial' }}>
                            <img src={icon} style={{ width: '40px', marginLeft: '70px' }} alt="" />
                            {this.buscarBotaoReenviarEmail(signatario)}
                        </div>
                    </Grid>
                </span>
            )
        });
    }

    render() {


        const { documento } = this.props;

        const assinaturas = documento.signatarios.filter(signatario => signatario.assinatura !== null);

        return (
            <Modal
                visible={this.props.visible}
                header='Detalhes do documento'
                onHide={this.props.onHide}
            >
                <If test={this.props.visible}>
                    <ButtonCancelar
                        estadoBotao={estadosBotaoCancelar.VOLTAR}
                        onClick={this.cancelar}
                    />
                    <LayoutMenuSuperior isModal={true}>
                        <center>
                            <FeedbackResumoAssinaturas
                                assinaturas={assinaturas}
                                status={documento.status}
                            />
                        </center>
                        <div style={{ marginTop: '20px' }}>
                            <div style={{ ...detalhesStyle }} title="Identificador único do documento que pode ser utilizado para consulta após todas as assinaturas serem efetuadas">
                                <strong> Identificador do documento: </strong>
                                {documento.id}
                            </div>
                            {this.montarHashDocumento(documento)}
                        </div>
                        <div style={{ fontSize: '14px', color: '#545454' }}>
                            {this.renderizarDetalhes()}
                        </div>
                    </LayoutMenuSuperior>
                </If>
            </Modal>
        )
    }
}

ModalVerDetalhesDocumento.propTypes = {
    visible: propTypes.bool,
    onHide: propTypes.func,
    documento: propTypes.object
}

const mapStateToProps = state => ({
    isMobile: state.dispositivo.isMobile,
})

export default connect(mapStateToProps)(ModalVerDetalhesDocumento);

