import React, { Component } from 'react';
import propTypes from 'prop-types';
import If from '../If';
import HelpTooltip from '../HelpTooltip';
import autoBind from 'react-autobind';
import { buscarMensagemEstadoCadastro } from '../../common/autorizacao/manipulacaoDeComponentes';

const botaoAjudaStyle = {
    cursor: 'pointer',
    margin: '0px 5px 0px 5px'
}

const mensagemStyle = {
    float: 'right',
    fontWeight: 'bold',
    fontSize: '15px'
}

class Paper extends Component {

    constructor(props) {
        super(props)
        autoBind(this)
    }

    onClickMdHelpOutline() {
        const { linkAjuda } = this.props;

        if (linkAjuda) {
            window.open(`${linkAjuda}`, '_blank')
        }
    }

    getHeader() {
        const { podeInserir, podeEditar, estadoCadastro, dirty, descricaoAjuda, linkAjuda } = this.props

        const mensagem = buscarMensagemEstadoCadastro(podeInserir, podeEditar, estadoCadastro, dirty)
        if (this.props.header) {
            return (
                <h2 style={{ borderBottom: '1px solid #d5d5d5', fontSize: '24px', marginBottom: '.5em', paddingBottom: '.5em' }}>
                    {this.props.header}
                    <If test={linkAjuda}>
                        <span
                            onClick={this.onClickMdHelpOutline}
                            className="step-ajuda">
                            <HelpTooltip
                                message={descricaoAjuda}
                                size={20}
                                style={botaoAjudaStyle}
                            />
                        </span>
                    </If>
                    <span style={mensagemStyle}>{mensagem}</span>
                </h2>
            );
        }
    }

    render() {
        const { childsOnly, children } = this.props

        if (childsOnly) {
            return children;
        }
        return (
            <div className="card" style={{ ...this.props.style, margin: '0px' }}>
                {this.getHeader()}
                <div>
                    {children}
                </div>
            </div>
        )
    }
}


Paper.defaultProps = {
    childsOnly: false,
    descricaoAjuda: 'Clique para obter ajuda'
}

Paper.propTypes = {
    /** Define o estilo do componente, caso necessitar alterar o padrão */
    style: propTypes.object,
    /** Define um título para o card */
    header: propTypes.string,
    /** Se verdadeiro, retora apenas os filhos do componente (sem cabeçalho e bordas), é útil para Modais */
    childsOnly: propTypes.bool,
    /** Estado em que o cadastro se encontra*/
    estadoCadastro: propTypes.string,
    /** Diz se o usuário possui permissão de visualizar*/
    podeEditar: propTypes.bool,
    /** Diz se o usuário possui permissão de excluir*/
    podeInserir: propTypes.bool,
    /** Diz se o paper está 'sujo' a fim de mostrar a mensagem 'Inserindo... ou 'Editando...' */
    dirty: propTypes.bool,
    /** Link da documentação da tela, será redirecionado para ele ao clicar no ícone.*/
    linkAjuda: propTypes.string,
    /** Descrição que vai aparecer no tooltip de ajuda*/
    descricaoAjuda: propTypes.string
}

export default Paper;
