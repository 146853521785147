import React, { Component } from 'react'
import { Field, withFormik } from 'formik';
import ButtonCancelar, { estadosBotaoCancelar } from '../../../components/Button/ButtonCancelar';
import { mensagensDeValidacao } from '../../../common/constantes/mensagens'
import Grid from '../../../components/Grid/index';
import { estadosCadastro, permissoes, recursos } from '../../../common/constantes/autorizacao';
import autoBind from 'react-autobind';
import { buscarDadosLoginLocalStorage, salvarDadosLoginLocalStorage, usuarioPossuiPermissao } from '../../../common/autenticacao';
import Form from '../../../components/Form';
import FormActions from '../../../components/FormActions';
import FormContent from '../../../components/FormContent';
import Prompt from '../../../components/Route/Prompt';
import ButtonSalvar from '../../../components/Button/ButtonSalvar';
import TabView from '../../../components/TabView';
import { TabPanel } from 'primereact/tabview';
import { buscarMascaraTelefone, manterApenasNumeros } from '../../../common/mascara';
import Dropdown from '../../../components/Select/Dropdown';
import InputField from '../../../components/Input/InputField';
import InputMask from '../../../components/Input/InputMask';
import Col from '../../../components/Col';
import Fieldset from '../../../components/FieldSet';
import SingleSelectMunicipio from '../../../components/Select/SingleSelectMunicipio';
import SingleSelectPais from '../../../components/Select/SingleSelectPais';
import { asyncBuscarInformacoesCredencial, asyncGetConfiguracoesEmpresa, asyncGetLogotipoEmpresa, asyncUpdateConfiguracoesEmpresa, asyncUploadCertificado, asyncUploadLogotipoEmpresa, sincronizarEmpresa } from './requests';
import { converterConfiguracoesGeraisParaApi, converterConfiguracoesGeraisParaFormulario } from './utils/configuracoesGeraisConverter';
import { validarFormulario } from '../../util';
import semImagemImg from "./images/sem_imagem.svg"
import LogotipoUpload from './components/LogotipoUpload';
import { helpConfiguracoesForm } from './help';
import { isValidCnpj, isValidCpf } from '@brazilian-utils/validators';
import Modulos from './components/Modulos'
import Checkbox from '../../../components/Input/Checkbox';
import InputDouble from '../../../components/Input/InputDouble';
import CertificadoUpload from './components/CertificadoUpload';
import Button from '../../../components/Button';
import ModalExcluirEmpresa from './components/ModalExcluirEmpresa';
import { notify, ToastTypes } from '../../../components/Toast';
import InputCep from '../../../components/Input/InputCep';
import Message from '../../../components/Message';
import stringMask from 'string-mask';
import ModalAliquotaSimples from './components/ModalAliquotaSimples';
import If from '../../../components/If';
import PreferenciasFinanceiras from './components/PreferenciasFinanceiras';
import { optionsCsosn } from '../../fiscal/vendas/NFCe/Form/utils/constantes';
import { Card } from 'primereact/card';
import SingleSelectCfop from '../../../components/Select/SingleSelectCfop';

const PlugnotasStatus = {
	PENDENTE: 'PENDENTE',
	SINCRONIZADA: 'SINCRONIZADA'
}

const initialValues = {
	nome: null,
	nomeFantasia: null,
	razaoSocial: null,
	email: null,
	tipo: null,
	cnpj: null,
	telefone: null,
	inscricaoMunicipal: null,
	inscricaoEstadual: null,
	inscricaoEstadualSubstitutoTributario: null,
	cnae: null,
	regimeTributario: "SIMPLES_NACIONAL",
	logradouro: null,
	numero: null,
	bairro: null,
	cep: null,
	municipio: null,
	pais: null,
	complemento: null,
	modulos: [],
	emiteNfe: false,
	emiteNfce: false,

	tipoImpressaoDanfeNfce: "NORMAL_RETRATO",
	serieNfce: null,
	numeroInicialNfce: null,
	envioAutomaticoEmailNfce: true,
	codigoSegurancaContribuinteNfce: null,
	tokenSegurancaContribuinteNfce: null,

	csosnPadraoNfce: null,
	cfopPadraoNfce: null,

	serieNfe: null,
	tipoImpressaoDanfeNfe: "NORMAL_RETRATO",
	impressaoFcpDanfeNfe: true,
	impressaoPartilhaDanfeNfe: true,
	numeroInicialNfe: null,
	envioAutomaticoEmailNfe: true,

	logotipoUrl: null,
	certificado: null,
	certificadoAlterado: false,
	certificadoSenha: null,
	certificadoConfirmacaoSenha: null,
	plugnotasStatus: PlugnotasStatus.SINCRONIZADA,
	percentualAliquotaSimples: null,
	mesVigenciaAliquotaSimples: null
}

const tabs = {
	INFORMACOES_EMPRESA: 0,
	MODULOS: 1,
	PREFERENCIAS_FINANCEIRAS: 2,
	CONFIGURACOES_NOTA: 3,
	SEGURANCA: 4
}

const buttonsStyle = {
	margin: '26px 26px 20px 7px',
}

class ConfiguracoesGerais extends Component {
	constructor(props) {
		super(props);
		autoBind(this);
		this.state = {
			podeExcluirEmpresa: usuarioPossuiPermissao(recursos.EMPRESA_CONFIGURACOES, permissoes.EXCLUIR_EMPRESA),
			podeEditarEmpresa: usuarioPossuiPermissao(recursos.EMPRESA_CONFIGURACOES, permissoes.EDITAR_EMPRESA),
			podeVisualizarVendas: usuarioPossuiPermissao(recursos.VENDAS_VENDAS, permissoes.VISUALIZAR),
			novoLogotipo: null,
			tabSelecionada: 0,
			modalExcluirEmpresaVisible: false,
			modalConfigurarAliquotaSimplesVisible: false,
			marcarCheckBoxEmiteNfe: false,
			marcarCheckBoxEmiteNfce: false
		};
	}

	componentDidMount() {
		this.asyncSelectRegistro();

		if (this.props.location.state && this.props.location.state.configurarSerieNfe) {
			this.setState({ tabSelecionada: 3, marcarCheckBoxEmiteNfe: true });
		} else if (this.props.location.state && this.props.location.state.configurarSerieNfce) {
			this.setState({ tabSelecionada: 3, marcarCheckBoxEmiteNfce: true });
		} else if (this.props.location.state && this.props.location.state.configurarPreferencias) {
			this.setState({ tabSelecionada: 3 });
		}
	}

	cancelar() {
		if (!this.props.dirty)
			this.props.history.goBack()
		else {
			this.props.resetForm({ values: this.props.initialValues, errors: {} })
		}
	}

	async salvar() {

		await this.props.handleSubmit();

		if (await validarFormulario(this.props)) {
			const dadosFormulario = converterConfiguracoesGeraisParaApi(this.props.values)
			await asyncUpdateConfiguracoesEmpresa(dadosFormulario, async () => {
				let erroUpload = false
				if (this.props.values.logotipoUrl !== this.props.initialValues.logotipoUrl) {
					await asyncUploadLogotipoEmpresa(this.state.novoLogotipo, null, () => erroUpload = true)
				}
				if (this.props.values.certificado !== this.props.initialValues.certificado) {
					await asyncUploadCertificado(this.props.values.certificado, this.props.values.certificadoSenha, null, () => erroUpload = true)
				}
				if (!erroUpload) {
					notify("Configurações atualizadas", ToastTypes.SUCCESS)
					this.asyncSelectRegistro()
				}
			})
		} else {
			if (this.props.errors.tab || this.props.errors.tab === 0) {
				this.setState({ tabSelecionada: this.props.errors.tab })
			}
		}
	}

	asyncSelectRegistro() {
		asyncGetConfiguracoesEmpresa(async ({ data: dadosEmpresa }) => {
			await asyncBuscarInformacoesCredencial(e => {
				let dadosLogin = buscarDadosLoginLocalStorage();
				dadosLogin = {
					...e.data,
					...dadosLogin,
					setores: e.data.setores,
					configuracoes: e.data.configuracoes,
					organizacao: e.data.organizacao,
					recursos: e.data.recursos,
				}
				salvarDadosLoginLocalStorage(dadosLogin)
			})

			let logotipoUrl = semImagemImg
			await asyncGetLogotipoEmpresa(({ data: logotipo }) => {
				logotipoUrl = URL.createObjectURL(logotipo)
			})
			this.setState({
				novoLogotipo: null,
				podeVisualizarVendas: usuarioPossuiPermissao(recursos.VENDAS_VENDAS, permissoes.VISUALIZAR)
			})
			const marcarCheckBoxEmiteNfe = this.state.marcarCheckBoxEmiteNfe
			const marcarCheckBoxEmiteNfce = this.state.marcarCheckBoxEmiteNfce
			this.props.resetForm({
				values: converterConfiguracoesGeraisParaFormulario({ ...dadosEmpresa, logotipoUrl }, marcarCheckBoxEmiteNfe, marcarCheckBoxEmiteNfce), errors: null
			});
		})
	}

	onUploadLogotipo(e) {
		this.setState({ novoLogotipo: e })
		this.props.setFieldValue('logotipoUrl', URL.createObjectURL(e))
	}

	onSelectModulo(modulo) {
		const { values, setFieldValue } = this.props
		const moduloJaSelecionado = values.modulos.includes(modulo)
		if (moduloJaSelecionado) {
			setFieldValue('modulos', values.modulos.filter(item => item !== modulo).sort())
		} else {
			setFieldValue('modulos', [...values.modulos, modulo].sort())
		}
	}

	onChangeCertificado(novoCertificado) {
		this.props.setFieldValue('certificado', novoCertificado)
		this.props.setFieldValue('certificadoAlterado', true)
	}

	onChangeCep(e) {
		if (e.pesquisou) {
			const { setFieldValue } = this.props;
			e.bairro && setFieldValue('bairro', e.bairro);
			e.complemento && setFieldValue('complemento', e.complemento);
			e.logradouro && setFieldValue('logradouro', e.logradouro);
			e.municipio && setFieldValue('municipio', { label: e.municipio.nome + ' - ' + e.municipio.estado.sigla, value: e.municipio.id });
			e.pais && setFieldValue('pais', { label: e.pais.nome, value: e.pais.id });

			this.props.setTouched({ field: { bairro: true, complemento: true, logradouro: true, municipio: true, pais: true, numero: true } });
		} else {
			this.props.setFieldValue('cep', e.value);
		}
	}

	onChangeTipo(e) {
		this.props.setFieldValue("tipo", e.value);
		this.props.setFieldValue("cpf", null);
		this.props.setFieldValue("cnpj", null);
	}

	sincronizarDadosDaEmpresa() {
		sincronizarEmpresa(() => {
			this.props.resetForm({ values: { ...this.props.values, plugnotasStatus: PlugnotasStatus.SINCRONIZADA } })
		})
	}

	buscarSenhaFake() {
		const { certificado, certificadoAlterado } = this.props.values
		if (certificado != null && !certificadoAlterado) {
			return "••••••••••"
		}
		return ""
	}

	valueCfop() {
		const {cfopPadraoNfce} = this.props.values
		if(cfopPadraoNfce) {
			return {
				label: `${cfopPadraoNfce.codigo} - ${cfopPadraoNfce.descricao}`,
				value: cfopPadraoNfce.id				
			}
		}
		return null
	}

	render() {
		const informacoesPermissoes = {
			estadoCadastro: estadosCadastro.EDICAO,
			podeEditar: this.state.podeEditarEmpresa,
		};
		const { values, isModal, dirty, errors } = this.props;
		const { tabSelecionada, modalExcluirEmpresaVisible, modalConfigurarAliquotaSimplesVisible, podeEditarEmpresa, podeExcluirEmpresa, podeVisualizarVendas } = this.state
		const esconderBotaoEMensagemSincronizar = Boolean(values.plugnotasStatus === PlugnotasStatus.SINCRONIZADA || !values.fiscal?.certificado);

		return (
			<>
				<Prompt dirty={dirty} isModal={isModal} />
				<Form className="card-default screen-max-width" header="Configurações gerais" isModal={isModal}>
					<FormActions className="screen-max-width">
						<ButtonCancelar
							{...informacoesPermissoes}
							hidden={isModal && values.id && !dirty}
							estadoBotao={dirty ? estadosBotaoCancelar.CANCELAR : estadosBotaoCancelar.VOLTAR}
							onClick={this.cancelar}
						/>
						<ButtonSalvar
							{...informacoesPermissoes}
							disabled={!dirty}
							onClick={this.salvar}
						/>
						<Button
							icon="pi pi-refresh"
							label="Sincronizar"
							hidden={esconderBotaoEMensagemSincronizar}
							onClick={this.sincronizarDadosDaEmpresa}
						/>
					</FormActions>
					<FormContent>
						<Message
							severity="warn"
							hidden={esconderBotaoEMensagemSincronizar}
							text={
								<div>
									<h2>Empresa desatualizada</h2>
									<p style={{ margin: '5px 0px' }}>
										As informações da sua empresa não estão atualizadas com o sistema de integração para emissão de documentos fiscais.
										Para atualizar clique em <b>Sincronizar</b> e aguarde
									</p>
								</div>
							}
						/>
						<Grid>
							<TabView
								className="tab-view-light"
								activeIndex={tabSelecionada}
								onTabChange={(e) => this.setState({ tabSelecionada: e.index })}
							>
								<TabPanel header="Informações da empresa">
									<Grid>
										<Col sm='4' md='4' lg='3' style={{ alignItems: 'center', display: 'flex', flexDirection: 'column' }}>
											<LogotipoUpload
												loading={!values.logotipoUrl}
												logotipo={values.logotipoUrl}
												onChange={(e) => this.onUploadLogotipo(e)}
												podeEditarEmpresa={this.state.podeEditarEmpresa}
											/>
										</Col>
										<Col sm='8' md='8' lg='9'>
											<Grid>
												<Field sm="12" md="6" lg='4' xl='4'
													label='Nome'
													obrigatorio
													name="nome"
													size={60}
													component={InputField}
													helpMessage={helpConfiguracoesForm.nome}
													{...informacoesPermissoes}
												/>
												<Field sm="12" md="6" lg='4' xl='4'
													label='Razão social'
													name="razaoSocial"
													size={60}
													obrigatorio={Boolean(values.certificado)}
													component={InputField}
													{...informacoesPermissoes}
												/>

												<Field sm="12" md="6" lg='4' xl='4'
													component={Dropdown}
													label='Tipo'
													obrigatorio
													name="tipo"
													showClear={false}
													onChange={this.onChangeTipo}
													options={[
														{ label: 'Física', value: 'FISICA' },
														{ label: 'Jurídica', value: 'JURIDICA' }
													]}
													{...informacoesPermissoes}
												/>
												<Field sm="12" md="6" lg='4' xl='4'
													hidden={values.tipo !== 'FISICA'}
													component={InputMask}
													mask="000.000.000-00"
													placeholder="   .   .   -  "
													label='CPF'
													obrigatorio
													name="cpf"
													onChange={e => this.props.setFieldValue('cpf', e.target.value)}
													{...informacoesPermissoes}
												/>
												<Field sm="12" md="6" lg='4' xl='4'
													hidden={values.tipo !== 'JURIDICA'}
													component={InputMask}
													mask="00.000.000/0000-00"
													placeholder="  .   .   /    -  "
													label='CNPJ'
													obrigatorio
													name="cnpj"
													onChange={e => this.props.setFieldValue('cnpj', e.target.value)}
													{...informacoesPermissoes}
												/>
												<Field sm="12" md="6" lg='4' xl='4'
													label='E-mail'
													obrigatorio
													name="email"
													size={255}
													component={InputField}
													{...informacoesPermissoes}
												/>
												<Field sm="12" md="6" lg='4' xl='4'
													component={InputMask}
													mask={buscarMascaraTelefone(values.telefone)}
													placeholder={"(  )      -    "}
													label='Telefone'
													obrigatorio
													name="telefone"
													size={16}
													onChange={e => this.props.setFieldValue('telefone', e.target.value)}
													{...informacoesPermissoes}
												/>
												<Field sm="12" md="6" lg='4' xl='4'
													label='Inscrição estadual'
													name="inscricaoEstadual"
													size={14}
													obrigatorio={Boolean(values.certificado)}
													component={InputField}
													value={values.inscricaoEstadual}
													onChange={e => this.props.setFieldValue('inscricaoEstadual', e.target.value)}
													helpMessage={helpConfiguracoesForm.inscricaoEstadual}
													{...informacoesPermissoes}
												/>
												<Field sm="12" md="6" lg='4' xl='4'
													label='I.E. do Substituto Tributário'
													name="inscricaoEstadualSubstitutoTributario"
													helpMessage={helpConfiguracoesForm.inscricaoEstadualSubstitutoTributario}
													size={14}
													component={InputField}
													{...informacoesPermissoes}
												/>
												<Field sm="12" md="6" lg='4' xl='4'
													label='Inscrição municipal'
													name="inscricaoMunicipal"
													helpMessage={helpConfiguracoesForm.inscricaoMunicipal}
													size={15}
													component={InputField}
													{...informacoesPermissoes}
												/>
												<Field sm="12" md="6" lg='4' xl='4'
													label='CNAE fiscal'
													helpMessage={helpConfiguracoesForm.cnae}
													name="cnae"
													size={7}
													component={InputField}
													{...informacoesPermissoes}
												/>
												<Field sm="12" md="6" lg='4' xl='4'
													component={Dropdown}
													helpMessage={helpConfiguracoesForm.regimeTributario}
													label='Regime tributário'
													name="regimeTributario"
													obrigatorio={Boolean(values.certificado)}
													onChange={(e) => this.props.setFieldValue("regimeTributario", e.value)}
													options={[
														{ label: 'Simples nacional', value: 'SIMPLES_NACIONAL' },
														{ label: 'Simples Nacional, excesso sublimite de receita bruta', value: 'SIMPLES_NACIONA_EXCESSO' }
													]}
													{...informacoesPermissoes}
												/>
											</Grid>
										</Col>
										<Col sm="12" md="12" lg='12' xl='12'>
											<Fieldset legend="Endereço" className="fieldset-light">
												<Grid>
													<Field sm="12" md="4" lg="4" xl="4"
														component={InputCep}
														label="CEP"
														name="cep"
														type="tel"
														obrigatorio={Boolean(values.certificado)}
														value={values.cep}
														onChange={e => this.onChangeCep(e)}
														{...informacoesPermissoes}
													/>
													<Field sm="12" md="4" lg="4" xl="4"
														label='Logradouro'
														name="logradouro"
														size={255}
														obrigatorio={Boolean(values.certificado)}
														component={InputField}
														{...informacoesPermissoes}
													/>
													<Field sm="12" md="4" lg="4" xl="4"
														label='Número'
														size={10}
														obrigatorio={Boolean(values.certificado)}
														name="numero"
														component={InputField}
														{...informacoesPermissoes}
													/>
													<Field sm="12" md="4" lg="4" xl="4"
														label='Bairro'
														name="bairro"
														obrigatorio={Boolean(values.certificado)}
														size={60}
														component={InputField}
														{...informacoesPermissoes}
													/>
													<Field sm="12" md="4" lg="4" xl="4"
														label='Complemento'
														name="complemento"
														size={255}
														component={InputField}
														{...informacoesPermissoes}
													/>
													<Field sm="12" md="4" lg="4" xl="4"
														label='País'
														name="pais"
														component={SingleSelectPais}
														value={values.pais}
														onChange={e => this.props.setFieldValue('pais', e)}
														{...informacoesPermissoes}
													/>
													<Field sm="12" md="4" lg="4" xl="4"
														label='Município'
														name="municipio"
														obrigatorio={Boolean(values.certificado)}
														component={SingleSelectMunicipio}
														value={values.municipio}
														onChange={e => this.props.setFieldValue('municipio', e)}
														{...informacoesPermissoes}
													/>
												</Grid>
											</Fieldset>
										</Col>
										<Col sm="12" md="12" lg='12' xl='12'>
											<Fieldset legend="Alíquota" className="fieldset-light">
												<Grid>
													<Button
														className='p-button-warning'
														type='button'
														label='Configurar Alíquota  do simples'
														icon='fa fa-pencil'
														onClick={() => this.setState({ modalConfigurarAliquotaSimplesVisible: true })}
														style={buttonsStyle}
														title={helpConfiguracoesForm.percentualAliquotaSimples}
													/>
												</Grid>
											</Fieldset>
										</Col>
									</Grid>
								</TabPanel>
								<TabPanel header="Módulos do sistema">
									<Fieldset className='fieldset-light' legend='Módulos habilitados'>
										<p style={{ marginTop: '0px' }}>
											Clicando nos itens abaixo você pode escolher quais módulos do sistema você irá utilizar. Todos os módulos possuem um plano gratuito, se você sentir necessidade de mais, pode aderir a um novo plano.
										</p>
										<Modulos
											podeEditarEmpresa={podeEditarEmpresa}
											value={values.modulos}
											onChange={(value) => this.props.setFieldValue('modulos', value)}
											errors={errors.modulos}
										/>
									</Fieldset>
								</TabPanel>
								<TabPanel header="Preferências financeiras">
									<PreferenciasFinanceiras
										podeEditarEmpresa={podeEditarEmpresa}
										financas={values.financas}
										onChange={(financas) => this.props.setFieldValue('financas', financas)}
										errors={errors.financas}
									/>
								</TabPanel>
								<TabPanel header="Configurações da nota fiscal" headerStyle={podeVisualizarVendas ? null : { display: 'none' }}>
									<Grid>
										<Col sm='6' style={{ alignItems: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
											<CertificadoUpload
												onChange={this.onChangeCertificado}
												value={values.certificado}
												podeEditarEmpresa={podeEditarEmpresa}
											/>
										</Col>
										<Col sm='6'>
											<Grid>
												<Field sm="12"
													label='Senha do certificado'
													name="certificadoSenha"
													size={128}
													component={InputField}
													type='password'
													helpMessage={helpConfiguracoesForm.certificadoSenha}
													disabled={!values.certificadoAlterado}
													title={!values.certificadoAlterado ? "Para alterar a senha é necessário inserir um novo certificado" : ""}
													placeholder={this.buscarSenhaFake()}
													{...informacoesPermissoes}
												/>
												<Field sm="12"
													label='Confirme a senha do certificado'
													name="certificadoConfirmacaoSenha"
													size={128}
													component={InputField}
													type='password'
													title={!values.certificadoAlterado ? "Para alterar a senha é necessário inserir um novo certificado" : ""}
													placeholder={this.buscarSenhaFake()}
													helpMessage={helpConfiguracoesForm.certificadoSenha}
													disabled={!values.certificadoAlterado}
													{...informacoesPermissoes}
												/>
											</Grid>
										</Col>
										<Col sm="12" md="12" lg='12' xl='12'>
											<Field
												sm="12" md="12" lg='12' xl='12'
												component={Checkbox}
												label="Configurar informações Fiscais para emissão de NF-e"
												name="emiteNfe"
												onChange={(e) => this.props.setFieldValue('emiteNfe', e.checked)}
												checked={values.emiteNfe}
												{...informacoesPermissoes}
											/>
											<Field
												sm="12" md="12" lg='12' xl='12'
												component={Checkbox}
												label="Configurar informações Fiscais para emissão de NFC-e"
												name="emiteNfce"
												onChange={(e) => this.props.setFieldValue('emiteNfce', e.checked)}
												checked={values.emiteNfce}
												{...informacoesPermissoes}
											/>
											<If test={values.emiteNfe}>
												<Card style={{ marginTop: '5px' }}>
													<Fieldset legend="Configurações NF-e" className="fieldset-light">
														<Grid>
															<Field sm="12" md="4" lg='4' xl='4'
																component={Dropdown}
																label='Tipo de impressão da DANF-e'
																name="tipoImpressaoDanfeNfe"
																value={values.tipoImpressaoDanfeNfe}
																showClear={false}
																errors={"Campo obrigatório"}
																onChange={(e) => this.props.setFieldValue('tipoImpressaoDanfeNfe', e.value)}
																options={[
																	{ label: 'Sem geração de DANF-e', value: 'SEM_GERACAO' },
																	{ label: 'Normal / Modo retrato', value: 'NORMAL_RETRATO' },
																	{ label: 'Normal / Modo paisagem', value: 'NORMAL_PAISAGEM' },
																	{ label: 'Simplificado', value: 'SIMPLIFICADO' },
																	// { label: 'DANF-e NFCE', value: 'NFCE' },
																	// { label: 'DANF-e NFCE com mensagem eletrônica', value: 'NFCE_MENSAGEM_ELETRONICA' },
																]}
																{...informacoesPermissoes}
															/>
															<Field sm="12" md="4" lg='4' xl='4'
																label='Série da NF-e'
																name="serieNfe"
																obrigatorio
																size={3}
																value={values.serieNfe}
																onChange={e => this.props.setFieldValue('serieNfe', e.target.value)}
																component={InputDouble}
																decimalScale={0}
																helpMessage={helpConfiguracoesForm.serieNfe}
																{...informacoesPermissoes}
															/>
															<Field sm="12" md="4" lg='4' xl='4'
																label='Número inicial da NF-e'
																name="numeroInicialNfe"
																size={9}
																value={values.numeroInicialNfe}
																onChange={e => this.props.setFieldValue('numeroInicialNfe', e.target.value)}
																thousandSeparator={""}
																component={InputDouble}
																decimalScale={0}
																helpMessage={helpConfiguracoesForm.numeroInicialNfe}
																{...informacoesPermissoes}
															/>
															<Field
																sm="12" md="12" lg='12' xl='12'
																component={Checkbox}
																label="Imprimir as informações do fundo de combate a probreza (FCP) na DANF-e"
																name="impressaoFcpDanfeNfe"
																onChange={(e) => this.props.setFieldValue('impressaoFcpDanfeNfe', e.checked)}
																checked={values.impressaoFcpDanfeNfe}
																{...informacoesPermissoes}
															/>
															<Field
																sm="12" md="12" lg='12' xl='12'
																component={Checkbox}
																label="Imprimir as informações da partilha de ICMS na DANF-e"
																name="impressaoPartilhaDanfeNfe"
																onChange={(e) => this.props.setFieldValue('impressaoPartilhaDanfeNfe', e.checked)}
																checked={values.impressaoPartilhaDanfeNfe}
																{...informacoesPermissoes}
															/>
															<Field
																sm="12" md="12" lg='12' xl='12'
																component={Checkbox}
																label="Enviar e-mails automaticamente para destinatário ao emitir uma NF-e"
																name="envioAutomaticoEmailNfe"
																onChange={(e) => this.props.setFieldValue('envioAutomaticoEmailNfe', e.checked)}
																checked={values.envioAutomaticoEmailNfe}
																{...informacoesPermissoes}
															/>
														</Grid>
													</Fieldset>
												</Card>
											</If>
										</Col>
										<Col sm="12" md="12" lg='12' xl='12'>
											<If test={values.emiteNfce}>
												<Card style={{ marginTop: '5px' }}>
													<Fieldset legend="Configurações NFC-e" className="fieldset-light">
														<Grid>
															<Field sm="12" md="4" lg='4' xl='4'
																component={Dropdown}
																label='Tipo de impressão da DANF-e'
																name="tipoImpressaoDanfeNfce"
																value={values.tipoImpressaoDanfeNfce}
																showClear={false}
																onChange={(e) => this.props.setFieldValue('tipoImpressaoDanfeNfce', e.value)}
																options={[
																	{ label: 'Sem geração de DANF-e', value: 'SEM_GERACAO' },
																	{ label: 'Normal / Modo retrato', value: 'NORMAL_RETRATO' },
																	{ label: 'Normal / Modo paisagem', value: 'NORMAL_PAISAGEM' },
																	{ label: 'Simplificado', value: 'SIMPLIFICADO' },
																	// { label: 'DANF-e NFCE', value: 'NFCE' },
																	// { label: 'DANF-e NFCE com mensagem eletrônica', value: 'NFCE_MENSAGEM_ELETRONICA' },
																]}
																{...informacoesPermissoes}
															/>
															<Field sm="12" md="4" lg='4' xl='4'
																label='Série da NFC-e'
																name="serieNfce"
																obrigatorio
																size={3}
																value={values.serieNfce}
																onChange={e => this.props.setFieldValue('serieNfce', e.target.value)}
																component={InputDouble}
																decimalScale={0}
																helpMessage={helpConfiguracoesForm.serieNfce}
																{...informacoesPermissoes}
															/>
															<Field sm="12" md="4" lg='4' xl='4'
																label='Número inicial da NFC-e'
																name="numeroInicialNfce"
																value={values.numeroInicialNfce}
																onChange={e => this.props.setFieldValue('numeroInicialNfce', e.target.value)}
																size={9}
																thousandSeparator={""}
																component={InputDouble}
																decimalScale={0}
																helpMessage={helpConfiguracoesForm.numeroInicialNfce}
																{...informacoesPermissoes}
															/>
															<Field sm="12" md="6" lg='6' xl='6'
																label='Token de seguranca do contribuinte'
																obrigatorio
																name="tokenSegurancaContribuinteNfce"
																size={10}
																value={values.tokenSegurancaContribuinteNfce}
																onChange={e => this.props.setFieldValue('tokenSegurancaContribuinteNfce', e.target.value?.replace(/\D/g, ''))}
																component={InputField}
																{...informacoesPermissoes}
															/>
															<Field sm="12" md="6" lg='6' xl='6'
																label='Código de segurança do contribuinte'
																name="codigoSegurancaContribuinteNfce"
																obrigatorio
																value={values.codigoSegurancaContribuinteNfce}
																onChange={e => this.props.setFieldValue('codigoSegurancaContribuinteNfce', e.target.value)}
																size={255}
																component={InputField}
																{...informacoesPermissoes}
															/>
															<Field
																sm="12" md="12" lg='12' xl='12'
																component={Checkbox}
																label="Enviar e-mails automaticamente para destinatário ao emitir uma NFC-e"
																name="envioAutomaticoEmailNfce"
																onChange={(e) => this.props.setFieldValue('envioAutomaticoEmailNfce', e.checked)}
																checked={values.envioAutomaticoEmailNfce}
																{...informacoesPermissoes}
															/>

															<div
																style={{ margin: '10px 0px 0px 7px', width: '100%' }}
																title="Ao definir as preferências elas serão utilizadas como informações padrões na NFC-e"
															>
																<b>Preferências NFC-e</b>
															</div>
															<Field sm="6" md="6" lg='6' xl='6'
																label='CFOP'
																name="cfopPadraoNfce"
																component={SingleSelectCfop}
																value={this.valueCfop()}
																onChange={e => this.props.setFieldValue('cfopPadraoNfce', e.registro)}
																{...informacoesPermissoes}
															/>
															<Field sm="6" md="6" lg="6" xl="6"
																component={Dropdown}
																label='CSOSN'
																options={optionsCsosn}
																name="csosnPadraoNfce"
																value={values.csosnPadraoNfce}
																onChange={e => this.props.setFieldValue('csosnPadraoNfce', e.value)}
																{...informacoesPermissoes}
															/>
														</Grid>
													</Fieldset>
												</Card>
											</If>
										</Col>
									</Grid>
								</TabPanel>
								<TabPanel header="Segurança">
									<Fieldset legend="Privacidade" className='fieldset-light'>
										Nesta seção você pode apagar completamente a sua conta, isso fará com que <b>todos os dados contidos no sistema sejam perdidos e este processo não pode ser revertido</b>. Portanto tenha absoluta certeza que deseja fazer isso antes de prosseguir.
										<Grid>
											<Button
												className='p-button-danger'
												type='button'
												label='Apagar todos os dados'
												icon='fa fa-trash'
												onClick={() => this.setState({ modalExcluirEmpresaVisible: true })}
												disabled={!podeExcluirEmpresa}
												style={buttonsStyle}
												title={!podeExcluirEmpresa ? "Você não possui permissão para apagar os dados" : null}
											/>
										</Grid>
									</Fieldset>
								</TabPanel>
							</TabView>
						</Grid>
					</FormContent>
				</Form>
				<ModalExcluirEmpresa
					visible={modalExcluirEmpresaVisible}
					onHide={() => this.setState({ modalExcluirEmpresaVisible: false })}
				/>
				<If test={modalConfigurarAliquotaSimplesVisible}>
					<ModalAliquotaSimples
						visible={modalConfigurarAliquotaSimplesVisible}
						onHide={() => this.setState({ modalConfigurarAliquotaSimplesVisible: false })}
					/>
				</If>
			</>
		)
	}
}

ConfiguracoesGerais = withFormik({

	mapPropsToValues() {
		return initialValues
	},

	enableReinitialize: true,
	validateOnChange: false,

	validate(values) {
		let errors = {};

		if (!values.nome) {
			errors.nome = mensagensDeValidacao.OBRIGATORIO
			errors.tab = tabs.INFORMACOES_EMPRESA
		}

		if (!values.telefone) {
			errors.telefone = mensagensDeValidacao.OBRIGATORIO
			errors.tab = tabs.INFORMACOES_EMPRESA
		}

		if (!new stringMask("(00) 00000-0000").validate(manterApenasNumeros(values.telefone)) && !new stringMask("(00) 0000-0000").validate(manterApenasNumeros(values.telefone))) {
			errors.telefone = mensagensDeValidacao.TELEFONE_INVALIDO;
			errors.tab = tabs.INFORMACOES_EMPRESA
		}

		if (!values.email) {
			errors.email = mensagensDeValidacao.OBRIGATORIO
			errors.tab = tabs.INFORMACOES_EMPRESA
		}

		if (values.tipo === 'FISICA') {
			if (!values.cpf) {
				errors.cpf = mensagensDeValidacao.OBRIGATORIO
				errors.tab = tabs.INFORMACOES_EMPRESA
			}
			if (values.cpf && !isValidCpf(manterApenasNumeros(values.cpf))) {
				errors.cpf = 'Digite um CPF válido.';
				errors.tab = tabs.INFORMACOES_EMPRESA
			}

		}

		if (values.tipo === 'JURIDICA') {
			if (!values.cnpj) {
				errors.cnpj = mensagensDeValidacao.OBRIGATORIO
				errors.tab = tabs.INFORMACOES_EMPRESA
			}
			if (values.cnpj && !isValidCnpj(manterApenasNumeros(values.cnpj))) {
				errors.cnpj = 'Digite um CNPJ válido.';
				errors.tab = tabs.INFORMACOES_EMPRESA
			}

		}

		if (!values.modulos || values.modulos.length === 0) {
			errors.modulos = "Selecione ao menos um módulo do sistema"
			errors.tab = tabs.MODULOS
		}

		if (values.certificado) {
			if (!values.cep) {
				errors.cep = mensagensDeValidacao.OBRIGATORIO
				errors.tab = tabs.INFORMACOES_EMPRESA
			}
			if (!values.razaoSocial) {
				errors.razaoSocial = mensagensDeValidacao.OBRIGATORIO
				errors.tab = tabs.INFORMACOES_EMPRESA
			}
			if (!values.numero) {
				errors.numero = mensagensDeValidacao.OBRIGATORIO
				errors.tab = tabs.INFORMACOES_EMPRESA
			}
			if (!values.bairro) {
				errors.bairro = mensagensDeValidacao.OBRIGATORIO
				errors.tab = tabs.INFORMACOES_EMPRESA
			}
			if (!values.logradouro) {
				errors.logradouro = mensagensDeValidacao.OBRIGATORIO
				errors.tab = tabs.INFORMACOES_EMPRESA
			}
			if (!values.regimeTributario) {
				errors.regimeTributario = mensagensDeValidacao.OBRIGATORIO
				errors.tab = tabs.INFORMACOES_EMPRESA
			}
			if (!values.inscricaoEstadual) {
				errors.inscricaoEstadual = mensagensDeValidacao.OBRIGATORIO
				errors.tab = tabs.INFORMACOES_EMPRESA
			}
			if (!values.municipio) {
				errors.municipio = mensagensDeValidacao.OBRIGATORIO
				errors.tab = tabs.INFORMACOES_EMPRESA
			}
		}

		if (values.certificadoAlterado) {
			if (!values.certificadoSenha) {
				errors.certificadoSenha = mensagensDeValidacao.OBRIGATORIO
				errors.tab = tabs.CONFIGURACOES_NOTA
			}
			if (values.certificadoSenha !== values.certificadoConfirmacaoSenha) {
				errors.certificadoConfirmacaoSenha = "A senha e a confirmação da senha não conferem"
				errors.tab = tabs.CONFIGURACOES_NOTA
			}
		}

		if (values.emiteNfe) {
			if (!values.tipoImpressaoDanfeNfe) {
				errors.tipoImpressaoDanfeNfe = mensagensDeValidacao.OBRIGATORIO
				errors.tab = tabs.CONFIGURACOES_NOTA
			}
			if (!values.serieNfe) {
				errors.serieNfe = "Campo obrigatório"
				errors.tab = tabs.CONFIGURACOES_NOTA
			}
		}

		if (values.emiteNfce) {
			if (!values.tipoImpressaoDanfeNfce) {
				errors.tipoImpressaoDanfeNfce = mensagensDeValidacao.OBRIGATORIO
				errors.tab = tabs.CONFIGURACOES_NOTA
			}
			if (!values.serieNfce) {
				errors.serieNfce = mensagensDeValidacao.OBRIGATORIO
				errors.tab = tabs.CONFIGURACOES_NOTA
			}
			if (!values.codigoSegurancaContribuinteNfce) {
				errors.codigoSegurancaContribuinteNfce = mensagensDeValidacao.OBRIGATORIO
				errors.tab = tabs.CONFIGURACOES_NOTA
			}
			if (!values.tokenSegurancaContribuinteNfce) {
				errors.tokenSegurancaContribuinteNfce = mensagensDeValidacao.OBRIGATORIO
				errors.tab = tabs.CONFIGURACOES_NOTA
			}
		}

		return errors;

	},

	handleSubmit: () => { },

})(ConfiguracoesGerais);

export default ConfiguracoesGerais
