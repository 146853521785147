import React, { Component } from 'react'
import Modal from '../../../../../components/Modal';
import Grid from '../../../../../components/Grid';
import autoBind from 'react-autobind';
import { Button } from 'primereact/button';
import AutoProgressBar from '../../../../../components/Loading/AutoProgressBar';
import If from '../../../../../components/If';
import format from 'date-fns/format';
import { formatarMonetario } from '../../../../../common/mascara';

export default class ModalConfirmarAdesao extends Component {

    constructor(props) {
        super(props)
        autoBind(this);
    }

    render() {

        const { visible, onHide, valorPlanoAtual, novoPlano, onConfirmarAdesaoClick, efetuarPagamentoAoAderir, dataProximoPagamento } = this.props
        const valorNovoPlanoFormatao = formatarMonetario(novoPlano.valor)
        const valorPlanoAtualFormatado = formatarMonetario(valorPlanoAtual)

        return (
            <>
                <Modal
                    header={efetuarPagamentoAoAderir ? 'Efetuar pagamento do plano' : 'Confirmar contratação do plano'}
                    visible={visible}
                    onHide={onHide}
                    styleModal={{ minWidth: '200px', maxWidth: '700px' }}
                >
                    <AutoProgressBar />
                    <Grid>
                        <p style={{ fontSize: '14px', textAlign: 'justify', marginLeft: '10px', marginRight: '10px' }}>
                            O valor da mensalidade será modificado de {valorPlanoAtualFormatado} para <b style={{ color: '#006095' }}>{valorNovoPlanoFormatao}</b>.
                           <If test={!efetuarPagamentoAoAderir}>
                                <br /> Este novo valor será  debitado na próxima mensalidade em <b>{format(dataProximoPagamento, 'dd/MM/yyyy')}</b>
                            </If>
                            <If test={efetuarPagamentoAoAderir}>
                                <br /> Para prosseguir, efetue o pagamento da mensalidade.
                            </If>
                        </p>
                    </Grid>
                    <Grid justifyEnd>
                        <div style={{ marginTop: '20px' }}>
                            <Button
                                className='p-button-secondary'
                                style={{ margin: '5px' }}
                                label='Voltar'
                                onClick={() => onHide()}
                            />
                            <Button
                                style={{ margin: '5px' }}
                                className='p-button-primary'
                                label={efetuarPagamentoAoAderir ? 'Efetuar pagamento' : 'Confirmar contratação'}
                                onClick={onConfirmarAdesaoClick}
                            />
                        </div>
                    </Grid>
                </Modal>
            </>
        )
    }
}
