import { actionTypes } from '../../../common/constantes/reduxTypes';
import { estadosCadastro } from '../../../common/constantes/autorizacao';

export default (state = {}, action) => {
    switch (action.type) {
        case actionTypes.ESTADO_FORMULARIO: {
            return {
                ...state,
                estado: action.value ? estadosCadastro.EDICAO : estadosCadastro.INCLUSAO
            }
        }
        default: {
            return state
        }
    }
}
