import React, { Component } from 'react';
import * as Yup from "yup";
import autoBind from 'react-autobind';
import { withRouter } from 'react-router';
import Modal from '../../../../../../../components/Modal';
import { withFormik, Field } from 'formik';
import AutoProgressBar from '../../../../../../../components/Loading/AutoProgressBar';
import ButtonSalvar, { estadosBotaoSalvar } from '../../../../../../../components/Button/ButtonSalvar';
import ButtonCancelar, { estadosBotaoCancelar } from '../../../../../../../components/Button/ButtonCancelar';
import ButtonExcluir from '../../../../../../../components/Button/ButtonExcluir';
import { usuarioPossuiPermissao } from '../../../../../../../common/autenticacao';
import { recursos, permissoes } from '../../../../../../../common/constantes/autorizacao';
import InputDate from '../../../../../../../components/Input/InputDate';
import Grid from '../../../../../../../components/Grid';
import InputMoney from '../../../../../../../components/Input/InputMoney';
import { mensagensDeValidacao } from '../../../../../../../common/constantes/mensagens';
import { helpMessage } from './util/constantes';
import Form from '../../../../../../../components/Form';
import FormActions from '../../../../../../../components/FormActions';
import FormContent from '../../../../../../../components/FormContent';
import Fieldset from '../../../../../../../components/FieldSet';
import { aplicarPercentual, validarFormulario } from '../../../../../../util';
import Col from '../../../../../../../components/Col';
import SingleSelectConta from '../../../../../../../components/Select/SingleSelectConta';
import { services } from '../../../../../../../common/constantes/api';
import { buscarContaFavoritaReceita } from '../../../../requests'
import { formatISO, isValid, parseISO } from 'date-fns';
import TextArea from '../../../../../../../components/TextArea';
import { formatarMonetario } from '../../../../../../../common/mascara';
import InputSelectPercentualOrValor, { tipos } from '../../../../../../../components/Input/InputSelectPercentualOrValor';
import { renderizarValidacao } from '../../../../../../../common/tratamentoDeErro/validacoesDeCampos';

const initialValue = {
    id: null,
    idTemporario: null,
    conta: null,
    data: formatISO(new Date()),
    desconto: 0,
    juros: 0,
    multa: 0,
    valor: null,
    observacao: null
}

const parteStyle = {
    fontSize: '13px',
    color: '#000000',
    margin: '0px 0px',
}

const totalStyle = {
    fontSize: '20px',
    color: '#000000',
    margin: '10px 0px 0px 0px',
}

class ModalRecebimento extends Component {

    constructor(props) {
        super(props);
        autoBind(this);

        this.state = {
            podeInserir: usuarioPossuiPermissao(recursos.FINANCAS_CONTAS_RECEBER, permissoes.INSERIR),
            podeEditar: usuarioPossuiPermissao(recursos.FINANCAS_CONTAS_RECEBER, permissoes.EDITAR),
            podeExcluir: usuarioPossuiPermissao(recursos.FINANCAS_CONTAS_RECEBER, permissoes.EXCLUIR),
            exibirModalRecebimento: false,
            tipoDesconto: tipos.VALOR,
            percentualDesconto: 0,
            tipoJuros: tipos.VALOR,
            percentualJuros: 0,
            tipoMulta: tipos.VALOR,
            percentualMulta: 0,
            restart: false,
        }
    }

    componentDidMount() {
        document.getElementById('valorRecebimento').focus()
        const { values } = this.props
        if (!values.id && !values.idTemporario) {
            buscarContaFavoritaReceita(({ data }) => {
                if (data.totalElements > 0) {
                    this.props.resetForm({
                        values: {
                            ...this.props.initialValues,
                            conta: {
                                label: data.content[0].nome,
                                value: data.content[0].id,
                                registro: data.content[0]
                            }
                        }
                    });
                }
            })
        }
    }

    onChangeValor(e) {
        const { setFieldValue } = this.props;
        const { tipoDesconto, percentualDesconto,
            tipoJuros, percentualJuros,
            tipoMulta, percentualMulta } = this.state;

        const valor = e.target.value
        setFieldValue('valor', valor);

        // CALCULAR  PERCENTUAIS
        if (tipoDesconto === tipos.PERCENTUAL) {
            setFieldValue('desconto', aplicarPercentual(valor, percentualDesconto));
        }

        if (tipoJuros === tipos.PERCENTUAL) {
            setFieldValue('juros', aplicarPercentual(valor, percentualJuros));
        }

        if (tipoMulta === tipos.PERCENTUAL) {
            setFieldValue('multa', aplicarPercentual(valor, percentualMulta));
        }

    }

    async salvar() {
        const { values, isValid, registroSelecionado, handleSubmit, onSalvar, resetForm } = this.props;

        await handleSubmit();

        if (await validarFormulario(this.props)) {
            if (values && isValid) {
                onSalvar(values)
                resetForm({ values: registroSelecionado });
            }
        }
    }

    cancelar() {
        if (this.props.dirty) {
            this.setState({restart: true});
            this.props.resetForm({ values: this.props.initialValues });
        } else {
            this.props.resetForm({ values: initialValue });
            this.props.onHide();
        }
    }

    render() {

        const { values, visible, onHide, isModal, dirty, setFieldValue, errors } = this.props;
        const { informacoesPermissoes } = this.state;
        const total = values.valor + values.juros + values.multa - values.desconto

        return (
            <Modal
                header={values.id ? "Editar recebimento" : "Novo recebimento"}
                visible={visible}
                onHide={onHide}
            >
                <AutoProgressBar />
                <Form>
                    <FormActions>
                        <ButtonCancelar
                            {...informacoesPermissoes}
                            estadoBotao={dirty ? estadosBotaoCancelar.CANCELAR : estadosBotaoCancelar.VOLTAR}
                            onClick={this.cancelar}
                        />
                        <ButtonSalvar
                            {...informacoesPermissoes}
                            estadoBotao={estadosBotaoSalvar.CONFIRMAR}
                            onClick={this.salvar}
                        />
                        <ButtonExcluir
                            hidden={!values.id || isModal}
                            {...informacoesPermissoes}
                            onClick={this.props.excluir}
                        />
                    </FormActions>
                    <FormContent>
                        <Grid>
                            <Field sm="12" md="6" lg="4" xl="4"
                                id='valorRecebimento'
                                component={InputMoney}
                                label="Valor recebido"
                                name="valor"
                                obrigatorio
                                value={values.valor}
                                helpMessage={helpMessage.valor}
                                onChange={this.onChangeValor}
                                size={15}
                                {...informacoesPermissoes}
                            />
                            <Field sm="12" md="6" lg="4" xl="4"
                                component={InputDate}
                                label="Data do recebimento"
                                name="data"
                                obrigatorio
                                onChange={e => setFieldValue('data', e.target.value)}
                                value={values.data}
                                yearRange='1910: 2100'
                                {...informacoesPermissoes}
                            />
                            <Field sm="12" md="6" lg='4' xl='4'
                                name="conta"
                                label="Conta"
                                obrigatorio
                                component={SingleSelectConta}
                                value={values.conta}
                                helpMessage={helpMessage.conta}
                                onChange={e => setFieldValue('conta', e)}
                                url={`${services.GESTOR}/v1/contas_receber/relacoes/contas`}
                                {...informacoesPermissoes}
                            />
                            <Fieldset legend="Informações complementares" style={{ width: '100%' }} className='fieldset-light'>
                                <Grid col="12" >
                                    <Col sm="12" md="6" lg="4" xl="4">
                                        <Field
                                            component={InputSelectPercentualOrValor}
                                            label="Descontos"
                                            name="desconto"
                                            value={values.desconto}
                                            valueBase={values.valor}
                                            errors={errors.desconto}
                                            restart={this.state.restart}
                                            onRestart={() => { this.setState({restart: false})}}
                                            helpMessage={helpMessage.desconto}
                                            onChange={(tipo, value, percentual) => {
                                                this.setState({ tipoDesconto: tipo, percentualDesconto: percentual })
                                                setFieldValue('desconto', value)
                                            }}
                                            size={15}
                                            {...informacoesPermissoes}
                                        />
                                    </Col>
                                    <Col sm="12" md="6" lg="4" xl="4">
                                        <Field
                                            component={InputSelectPercentualOrValor}
                                            label="Juros"
                                            name="juros"
                                            value={values.juros}
                                            valueBase={values.valor}
                                            restart={this.state.restart}
                                            onRestart={() => { this.setState({restart: false})}}
                                            helpMessage={helpMessage.juros}
                                            onChange={(tipo, value, percentual) => {
                                                this.setState({ tipoJuros: tipo, percentualJuros: percentual })
                                                setFieldValue('juros', value)
                                            }}
                                            size={15}
                                            {...informacoesPermissoes}
                                        />
                                    </Col>
                                    <Col sm="12" md="6" lg="4" xl="4">
                                        <Field
                                            component={InputSelectPercentualOrValor}
                                            label="Multa"
                                            name="multa"
                                            value={values.multa}
                                            valueBase={values.valor}
                                            restart={this.state.restart}
                                            onRestart={() => { this.setState({restart: false})}}
                                            helpMessage={helpMessage.multa}
                                            onChange={(tipo, value, percentual) => {
                                                this.setState({ tipoMulta: tipo, percentualMulta: percentual })
                                                setFieldValue('multa', value)
                                            }}
                                            size={15}
                                            {...informacoesPermissoes}
                                        />
                                    </Col>
                                    <Field sm="12"
                                        component={TextArea}
                                        placeholder="Escreva sua observação aqui"
                                        label='Observação'
                                        name="observacao"
                                        helpMessage={helpMessage.observacao}
                                        maxLength={4096}
                                        onChange={e => setFieldValue('observacao', e.target.value)}
                                    />
                                </Grid>
                            </Fieldset>
                            <Col>
                                <p style={parteStyle}>{`Descontos: ${formatarMonetario(values.desconto)}`}</p>
                                <p style={parteStyle}>{`Juros: ${formatarMonetario(values.juros)}`}</p>
                                <p style={parteStyle}>{`Multa: ${formatarMonetario(values.multa)}`}</p>
                                <p style={totalStyle}>{`Total: ${formatarMonetario(total)}`}</p>
                                {errors.total && renderizarValidacao(errors.total, true)}
                            </Col>
                        </Grid>
                    </FormContent>
                </Form>
            </Modal>
        )
    }
}


ModalRecebimento = withFormik({
    validateOnChange: false,

    mapPropsToValues(props) {
        if (props.registroSelecionado) {
            return props.registroSelecionado
        } else if (props.valorAReceber > 0) {
            return { ...initialValue, valor: props.valorAReceber };
        } else {
            return { ...initialValue }
        }
    },

    validate(values) {
        let errors = {}
        const valorTotal = values.valor + values.juros + values.multa - values.desconto;

        if (values.valor <= 0) {
            errors.valor = "O valor deve ser maior que zero"
        }

        if (valorTotal < 0) {
            errors.desconto = "O desconto não pode exceder o valor total"
        }

        if (valorTotal === 0) {
            errors.total = "O campo valor deve ser maior ou igual a R$ 00.01"
        }

        if (values.data && !isValid(parseISO(values.data))) {
            errors.data = mensagensDeValidacao.DATA_INVALIDA
        }

        return errors
    },

    validationSchema: Yup.object().shape({
        data: Yup.string().nullable().required(mensagensDeValidacao.DATA_INVALIDA),
        valor: Yup.string().nullable().required(mensagensDeValidacao.OBRIGATORIO),
        conta: Yup.string().nullable().required(mensagensDeValidacao.OBRIGATORIO),
    }),
    handleSubmit: () => { }
})(ModalRecebimento);


export default withRouter(ModalRecebimento);
