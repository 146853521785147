import React, { Component } from 'react';
import { withFormik, Field } from 'formik';
import * as Yup from 'yup';
import FormGroup from '../../../../../../components/FormGoup';
import Grid from '../../../../../../components/Grid';
import LayoutMenuSuperior from '../../../../../../components/Layout/LayoutMenuSuperior';
import MenuSuperior from '../../../../../../components/MenuSuperior';
import ButtonNovo from '../../../../../../components/Button/ButtonNovo';
import ButtonSalvar, { estadosBotaoSalvar } from '../../../../../../components/Button/ButtonSalvar';
import ButtonCancelar, { estadosBotaoCancelar } from '../../../../../../components/Button/ButtonCancelar';
import InputField from '../../../../../../components/Input//InputField';
import ButtonExcluir from '../../../../../../components/Button/ButtonExcluir';
import { mensagensDeValidacao } from '../../../../../../common/constantes/mensagens';
import { estadosCadastro } from '../../../../../../common/constantes/autorizacao';
import { validarFormulario } from '../../../../../util';
import autoBind from 'react-autobind'
import { helpPessoaContatoForm } from './help';
import Paper from '../../../../../../components/Paper';
import { gerarUUID } from '../../../../../../common/manipulacaoDeString';
import { converterPessoaContatoParaApi, converterPessoaContatoParaFormulario } from './util/pessoaContatoConverter';
import Checkbox from '../../../../../../components/Input/Checkbox';
import InputMask from '../../../../../../components/Input/InputMask';
import { buscarMascaraTelefone, manterApenasNumeros } from '../../../../../../common/mascara';
import stringMask from 'string-mask';

const initialValue = {
    id: null,
    idTemporario: null,
    email: "",
    nome: "",
    cargo: "",
    telefone: "",
    observacao: "",
    favorito: false
};

class PessoaContatoForm extends Component {

    constructor(props) {
        super(props);
        autoBind(this)

        this.state = {
            inserindoNovoRegistro: false
        }
    }

    componentDidMount() {
        this.setState({ inserindoNovoRegistro: !this.props.registroSelecionado });
    }

    novo() {
        this.props.onNovoClick()
        this.props.resetForm({ values: initialValue })
        this.setState({ inserindoNovoRegistro: true })
    }

    excluir() {
        this.props.excluirRegistro(this.props.registroSelecionado)
        this.props.onHide()
    }

    async salvar() {
        this.props.handleSubmit();
        if (await validarFormulario(this.props)) {
            if (this.state.inserindoNovoRegistro) {
                this.criarRegistro()
            } else {
                this.atualizarRegistro()
            }
        }
    }

    criarRegistro() {
        const dadosFormulario = converterPessoaContatoParaApi(this.props.values);
        this.props.inserirRegistro({ ...dadosFormulario, idTemporario: gerarUUID() })
        this.props.onHide()
    }

    atualizarRegistro() {
        const dadosFormulario = converterPessoaContatoParaApi(this.props.values);
        this.props.atualizarRegistro(dadosFormulario);
        this.props.onHide()
    }

    cancelar() {
        if (this.props.dirty) {
            this.props.handleReset();
        } else {
            this.props.onHide()
        }
    }

    render() {
        const informacoesPermissoes = {
            estadoCadastro: this.state.inserindoNovoRegistro ? estadosCadastro.INCLUSAO : estadosCadastro.EDICAO,
            podeInserir: this.props.podeEditar,
            podeEditar: this.props.podeEditar,
            podeExcluir: this.props.podeEditar
        };

        const { values } = this.props;
        const { inserindoNovoRegistro } = this.state

        return (
            <React.Fragment>
                <MenuSuperior isModal={true}>
                    <ButtonCancelar
                        {...informacoesPermissoes}
                        estadoBotao={this.props.dirty ? estadosBotaoCancelar.CANCELAR : estadosBotaoCancelar.VOLTAR}
                        onClick={this.cancelar}
                    />
                    <ButtonSalvar
                        estadoBotao={estadosBotaoSalvar.CONFIRMAR}
                        {...informacoesPermissoes}
                        disabled={!this.props.dirty}
                        onClick={this.salvar}
                    />
                    <ButtonNovo
                        onClick={this.novo}
                        hidden={inserindoNovoRegistro}
                        {...informacoesPermissoes}
                    />
                    <ButtonExcluir
                        hidden={inserindoNovoRegistro}
                        {...informacoesPermissoes}
                        onClick={this.excluir}
                        podeExcluir={this.props.podeExcluir}
                    />
                </MenuSuperior>
                <LayoutMenuSuperior isModal={true}>
                    <Paper
                        dirty={this.props.dirty}
                        {...informacoesPermissoes}
                        childsOnly={true}
                    >
                        <FormGroup>
                            <Grid>
                                <Field sm="12" md="12" lg='12' xl='12'
                                    component={Checkbox}
                                    label="Contato favorito"
                                    title="Marque esta opção caso o contato seja o favorito"
                                    name="favorito"
                                    helpMessage={helpPessoaContatoForm.favorito}
                                    onChange={e => this.props.setFieldValue("favorito", !values.favorito)}
                                    checked={values.favorito}
                                    {...informacoesPermissoes}
                                />
                                <Field sm="12" md="6" lg="4" xl="4"
                                    component={InputField}
                                    type="text"
                                    label="Nome "
                                    obrigatorio
                                    name="nome"
                                    value={values.nome}
                                    helpMessage={helpPessoaContatoForm.nome}
                                    size={255}
                                    {...informacoesPermissoes}
                                />
                                <Field sm="12" md="6" lg="4" xl="4"
                                    component={InputField}
                                    type="text"
                                    label="E-mail"
                                    name="email"
                                    value={values.email}
                                    helpMessage={helpPessoaContatoForm.email}
                                    size={255}
                                    {...informacoesPermissoes}
                                />
                                <Field sm="12" md="6" lg="4" xl="4"
                                    component={InputField}
                                    type="text"
                                    label="Cargo"
                                    name="cargo"
                                    value={values.cargo}
                                    helpMessage={helpPessoaContatoForm.cargo}
                                    size={128}
                                    {...informacoesPermissoes}
                                />
                                <Field sm="12" md="6" lg="4" xl="4"
                                    component={InputMask}
                                    mask={buscarMascaraTelefone(values.telefone)}
                                    placeholder={"(  )      -    "}
                                    label="Telefone"
                                    obrigatorio
                                    value={values.telefone}
                                    name="telefone"
                                    onChange={e => this.props.setFieldValue('telefone', e.target.value)}
                                    helpMessage={helpPessoaContatoForm.telefone}
                                    {...informacoesPermissoes}
                                />
                                <Field sm="12" md="12" lg='12' xl='12'
                                    component={InputField}
                                    type="text"
                                    label='Observação'
                                    name="observacao"
                                    helpMessage={helpPessoaContatoForm.observacao}
                                    {...informacoesPermissoes}
                                />
                            </Grid>
                        </FormGroup>
                    </Paper>
                </LayoutMenuSuperior>
            </React.Fragment>
        )
    }
}

PessoaContatoForm = withFormik({

    enableReinitialize: true,
    validateOnChange: false,

    mapPropsToValues(props) {
        if (props.registroSelecionado) {
            return converterPessoaContatoParaFormulario(props.registroSelecionado)
        }
        return { ...initialValue, favorito: props.marcarComoFavorito() };
    },

    validate(values) {
        let errors = {};

        if (!new stringMask("(00) 00000-0000").validate(manterApenasNumeros(values.telefone)) && !new stringMask("(00) 0000-0000").validate(manterApenasNumeros(values.telefone))) {
            errors.telefone = mensagensDeValidacao.TELEFONE_INVALIDO;
        }
        return errors;
    },

    validationSchema: Yup.object().shape({
        nome: Yup.string().nullable().required(mensagensDeValidacao.OBRIGATORIO),
        email: Yup.string().nullable().email(mensagensDeValidacao.EMAIL_INVALIDO),
    }),
})(PessoaContatoForm);

export default PessoaContatoForm
