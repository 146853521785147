import React, { Component } from 'react';
import { ProgressBar } from 'primereact/progressbar';
import { connect } from 'react-redux';
import autoBind from 'react-autobind/lib/autoBind';

class AutoProgressBar extends Component {

    constructor(props) {
        super(props)
        autoBind(this)
        this.state = {
            exibirLoading: false
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.actionLoading !== this.props.actionLoading) {
            if (this.props.actionLoading && typeof this.props.actionLoading === 'string') {
                if (this.props.actionLoading.includes('OCULTAR_LOADING') && this.state.exibirLoading) {
                    this.setState({ exibirLoading: false });
                } else if (this.props.actionLoading.includes('EXIBIR_LOADING') && !this.state.exibirLoading) {
                    this.setState({ exibirLoading: true });
                }
            }
        }
    }

    render() {
        return <ProgressBar mode="indeterminate" style={{ height: '4px', visibility: this.state.exibirLoading ? 'initial' : 'hidden', ...this.props.style }}/>
    }
}

const mapStateToProps = state => ({
    actionLoading: state.loading.actionLoading
})

export default connect(mapStateToProps)(AutoProgressBar)
