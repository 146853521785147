import React, { Component } from 'react';
import { Card } from 'primereact/card';
import autoBind from 'react-autobind';
import { buscarProdutos } from './requests';
import { ProgressBar } from 'primereact/progressbar';
import NenhumaInformacaoEncontrada from '../../../NenhumaInformacaoEncontrada';
import FalhaAoCarregarAsInformacoes from '../../../FalhaAoCarregarAsInformacoes';
import { asyncImprimirProdutoAbaixoEstoqueMinimo } from './requests/index';
import { baixarArquivo } from '../../../../../../../common/relatorios';
import { FaPrint } from 'react-icons/fa';

import './styles/index.css';
import If from '../../../../../../../components/If';
import { Link } from 'react-router-dom';
import { formatarDecimais } from '../../../../../../../common/mascara';
import { usuarioPossuiPermissao } from '../../../../../../../common/autenticacao';
import { permissoes, recursos } from '../../../../../../../common/constantes/autorizacao';
import { Estoque } from '../../../../../../util/constantes';

const Divider = () => {
    return <hr id="divider-estoque-minimo" />
}

const List = props => {
    return (
        <div id="estoque-minimo-content" className='dashboard-scroll'>
            <ul style={{ listStyle: "none", padding: '0px 3px', margin: '0px' }}>
                {props.children}
            </ul>
        </div>
    );
}

const ListItem = props => {
    return (
        <li style={{ margin: '6px 0px' }}>

            <div style={{ display: 'flex', justifyContent: 'space-between' }}>

                <div id="estoque-minimo-coluna-produto">
                    <div id="produto-nome">
                        <If test={props.podeVisualizarProdutos}>
                            <span className="link_to" title="Ir para cadastro do produto">
                                <Link to={{ pathname: `/produtos/cadastro/${props.id}` }}>
                                    {props.sku} - {props.nome} {props.unidadeMedida && `${props.unidadeMedidaUnidade}`}
                                </Link>
                            </span>
                        </If>
                        <If test={!props.podeVisualizarProdutos}>
                            <span>{props.sku} - {props.nome} ({props.unidadeMedida && props.unidadeMedidaUnidade})</span>
                        </If>
                    </div>
                    <div id="produto-estoque">
                        Estoque mínimo: {formatarDecimais(props.estoqueMinimo)}
                    </div>
                </div>

                <div id="estoque-minimo-coluna-estoque-atual">
                    <div title={`${formatarDecimais(props.estoqueAtual)} ${props.unidadeMedida ? props.unidadeMedidaDescricao : "(Unidade não informada)"}`}>
                        <span>{formatarDecimais(props.estoqueAtual)} </span>
                    </div>
                </div>
            </div>

        </li>
    );
}

const styleBotaoImprimir = {
    fontWeight: 'bold',
    fontSize: '15px',
    cursor: 'pointer'
};

export default class ProdutosAbaixoEstoqueMinimo extends Component {

    constructor(props) {
        super(props);

        autoBind(this);

        this.state = {
            loading: true,
            error: false,
            produtos: [],
            page: 0,
            totalPages: 0
        }
    }

    componentDidMount() {
        this.carregarInformacoes();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.atualizarGraficoNovamente !== this.props.atualizarGraficoNovamente) {
            this.carregarInformacoes(false);
        }
    }

    carregarInformacoes(exibirLoading = true) {
        this.setState({ loading: exibirLoading, error: false }, () => {
            buscarProdutos(this.state.page, ({ data }) => {
                this.setState({
                    totalElements: data.totalElements,
                    totalPages: data.totalPages,
                    produtos: data.content,
                    error: false,
                    loading: false
                })

            }, () => {
                this.setState({ error: true, loading: false })
            });
        })
    }

    getHeaderCard() {
        return (
            <div id="card-header-estoque-minimo">
                <span>
                    <label title="Lista os produtos com estoque abaixo do mínimo">Produtos abaixo do estoque mínimo</label>
                </span>
                <span style={{ marginTop: '3px' }}>
                    <If test={this.state.produtos && this.state.produtos.length > 0}>
                        <span
                            title="Imprimir produtos abaixo do estoque mínimo"
                            style={styleBotaoImprimir}
                            onClick={() => asyncImprimirProdutoAbaixoEstoqueMinimo(document => {
                                baixarArquivo(document.data, "Produtos abaixo do estoque mínimo.pdf");
                            })}
                        >
                            <FaPrint size="15px" />
                        </span>
                    </If>
                </span>
            </div>
        );
    }

    onVerMaisClick() {
        const page = this.state.page + 1

        buscarProdutos(page, ({ data }) => {
            this.setState({
                totalElements: data.totalElements,
                produtos: [...this.state.produtos, ...data.content],
                error: false,
                loading: false,
                page: data.number
            })
        }, () => {
            this.setState({ error: true, loading: false })
        });
    }

    render() {
        const podeVisualizarProdutos = usuarioPossuiPermissao(recursos.PRODUTOS, permissoes.VISUALIZAR);

        if (this.state.loading) {
            return (
                <Card header={this.getHeaderCard()} style={{ borderRadius: '5px' }}>
                    <ProgressBar mode="indeterminate" style={{ height: '3px' }} />
                </Card>
            )
        } else if (this.state.error) {
            return (
                <Card header={this.getHeaderCard()} style={{ borderRadius: '5px', height: '100%' }}>
                    <FalhaAoCarregarAsInformacoes />
                </Card>
            )
        } else {
            const { produtos, page, totalPages } = this.state;
            return (
                <>
                    <Card header={this.getHeaderCard()} style={{ borderRadius: '5px', height: '100%' }}>
                        {produtos.length > 0 ? (
                            <>
                                <div style={{ display: 'flex', justifyContent: 'space-between', fontWeight: 'bold' }}>
                                    <span>Produto</span>
                                    <span>Estoque atual</span>
                                </div>
                                <List>
                                    {produtos.map((produto, idx, array) => {
                                        return (
                                            <React.Fragment key={produto.id}>
                                                <ListItem
                                                    id={produto.id}
                                                    nome={produto.nome}
                                                    sku={produto.sku}
                                                    podeVisualizarProdutos={podeVisualizarProdutos}
                                                    estoqueMinimo={produto.estoqueMinimo}
                                                    estoqueAtual={produto.estoqueSaldo}
                                                    unidadeMedida={produto.unidadeMedida}
                                                    unidadeMedidaUnidade={produto.unidadeMedida && produto.unidadeMedida.unidade}
                                                    unidadeMedidaDescricao={produto.unidadeMedida && produto.unidadeMedida.descricao}
                                                    carregarInformacoes={this.carregarInformacoes}
                                                />
                                                {++idx !== array.length ? <Divider /> : null}
                                            </React.Fragment>
                                        )
                                    })}
                                    <If test={page !== (totalPages - 1)}>
                                        <div style={{ textAlign: 'center' }}>
                                            <span onClick={this.onVerMaisClick} style={{ color: `${Estoque.cores.azul}`, cursor: 'pointer' }}>Ver mais</span>
                                        </div>
                                    </If>
                                </List>
                            </>
                        ) : (
                            <NenhumaInformacaoEncontrada />
                        )}
                    </Card>
                </>
            )
        }
    }
}
