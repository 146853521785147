import React, { Component } from 'react';
import logo from '../../../../layout/images/logo_microsys.svg'
import { withRouter } from 'react-router'
import { buscarDadosLoginLocalStorage } from '../../../../common/autenticacao'
import SelectPesquisaTela from '../../../../components/Select/SelectPesquisaTela';
import autoBind from 'react-autobind';

class AppInlineProfile extends Component {

    constructor() {
        super();

        autoBind(this);

        this.state = {
            expanded: false
        };
    }

    getUserName() {
        const dadosLocalStorage = buscarDadosLoginLocalStorage()
        return dadosLocalStorage && dadosLocalStorage.email
    }

    render() {

        const { history } = this.props;

        return (
            <div className="profile" style={{ paddingBottom: '0px', paddingTop: '10px' }}>

                <img
                    src={logo}
                    alt="Logo microsys sistemas"
                    style={{ width: '200px', cursor: 'pointer' }}
                    onClick={() => history.location.pathname !== "/" ? history.push("/") : null}
                />

                <span className="username">  {this.getUserName()} </span>
                <div>
                    <SelectPesquisaTela 
                        style={{ marginTop: '10px', marginBottom: '10px', width: '100%' }} 
                    />
                </div>
            </div>
        );
    }
}

export default withRouter(AppInlineProfile)