import { criptografarLocalStorage, descriptografarLocalStorage } from "../criptografia/localStorage";

export function buscarItemLocalStorage(chave, descriptografar = true) {
    if (descriptografar) {
        try {
            const item = descriptografarLocalStorage(window.localStorage.getItem(chave))

            if(item){
                return JSON.parse(item)
            }
            return item
        } catch (e) {
            console.error(e)
        }
    } else
        return window.localStorage.getItem(chave)
}

export async function salvarItemLocalStorage(chave, valor, criptografar = true) {
    if (criptografar)
        return await window.localStorage.setItem(chave, criptografarLocalStorage(JSON.stringify(valor)))
    else
        return await window.localStorage.setItem(chave, valor)
}

export function removerItemLocalStorage(chave) {
    return window.localStorage.removeItem(chave)
}
