import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Route } from 'react-router-dom';
import NoMatch from '../../views/util/NoMatch'
import { buscarDadosLoginLocalStorage, usuarioPossuiPermissao, salvarDataUltimoAcessoLocalStorage, buscarDataUltimoAcessoLocalStorage, fazerLogout } from '../../common/autenticacao'
import { withRouter } from 'react-router'
import autoBind from 'react-autobind'
import { alert } from '../Toast';
import { salvarItemLocalStorage } from '../../common/localStorage';
import { DADOSLOCALSTORAGE } from '../../common/constantes/localStorage';

const NotAuthorized = () => {
    return (
        <NoMatch
            errorCode='400'
            title='Não autorizado!'
            message='Você não está autorizado a acessar essa rota, contate o administrador do sistema para mais detalhes.'
        />
    );
}

class PrivateRoute extends Component {

    constructor(props) {
        super(props)
        autoBind(this)
        this.state = {
            hash: window.location.hash
        }
    }

    validarRota() {
        const dadosLogin = buscarDadosLoginLocalStorage();

        if (!dadosLogin.access_token) {
            salvarItemLocalStorage(DADOSLOCALSTORAGE.ultimaRotaNaoAutenticada, this.props.location.pathname)
            window.location = '#/login';
        }
        return dadosLogin.access_token;
    }

    componentDidMount() {
        this.validarRota();
        this.validarManterConectado()
    }

    validarManterConectado() {
        const manterConectado = buscarDadosLoginLocalStorage().manterConectado
        if (!manterConectado) {
            let ultimoAcesso = buscarDataUltimoAcessoLocalStorage()
            if (ultimoAcesso) {
                let dataUltimoAcesso = Date.parse(ultimoAcesso)
                let dozeHorasAtras = new Date().setHours(new Date().getHours() - 12)
                if (dataUltimoAcesso < dozeHorasAtras) {
                    fazerLogout()
                    alert("Atenção", "Você foi desconectado por inatividade, faça login novamente para continuar")
                }
            }
            salvarDataUltimoAcessoLocalStorage(new Date())
        }
    }


    componentDidUpdate() {
        if (this.state.hash !== window.location.hash) {
            this.validarRota();
            this.validarManterConectado()
            this.setState({ ...this.state, hash: window.location.hash });
        }
    }

    getComponent() {
        const { recurso, permissao, component } = this.props;
        if (!this.validarRota() || (this.props.validarPermissoes && !usuarioPossuiPermissao(recurso, permissao))) {
            return NotAuthorized
        } else {
            return component
        }
    }

    render() {
        return (
            <React.Fragment>
                <Route
                    {...this.props}
                    component={this.getComponent()}
                />
            </React.Fragment>
        )
    }
}

PrivateRoute.propTypes = {
    /** Informar se o caminho da url precisa ser exato */
    exact: PropTypes.bool,
    /** Componente que deve ser carregado na url */
    component: PropTypes.any,
    /** Se deve validar as permissões */
    validarPermissoes: PropTypes.bool,
    /** Recurso que o usuário deve possuir */
    recurso: PropTypes.string,
    /** Permissão que o usuário deve possuir */
    permissao: PropTypes.string
}

PrivateRoute.defaultProps = {
    validarPermissoes: true,
    exact: true
}

export default withRouter(PrivateRoute)
