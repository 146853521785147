import { isValidCep } from "@brazilian-utils/validators";
import { manterApenasNumeros } from "../../../../common/mascara";
import { alert } from "../../../Toast";

export function buscarDadosCEP(cep, onSuccess, onError, onInvalidCEP) {
    if (isValidCep(manterApenasNumeros(cep))) {
        fetch(`https://viacep.com.br/ws/${cep}/json/`)
            .then(response => response.json())
            .then(async data => {
                if (data.erro) {
                    alert('Atenção', 'O CEP informado não foi encontrado. Preencha manualmente os dados ou verifique o número e tente novamente.');
                } else {
                    onSuccess && onSuccess(data);
                }
            })
            .catch(err => {
                onError && onError(err);
            })
    } else {
        onInvalidCEP && onInvalidCEP(`${cep} não é um CEP válido. Por favor, verifique.`);
    }
}

export function montarObjetoMunicipio(municipio, dadosViaCep) {
    const dados = municipio.content[0];

    return {
        ...dadosViaCep,
        municipio: dados,
        pais: dados.estado.pais
    };

}
