import React from 'react';
import { buscarDadosLoginLocalStorage } from '../../../../../../../common/autenticacao';
import { Status } from '../../../util/constantes';

const secaoStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '0px 15px'
}

const numeroStyle = {
    fontSize: '24px',
    color: "#006095",
    fontWeight: 'bold'
}

export default function NfceSerieNumero({ status, serie, numero, isMobile }) {

    const { organizacao } = buscarDadosLoginLocalStorage();
    const serieConfiguracoes = organizacao.fiscalNfce?.serieNfce

    return (
        <>
            <div style={{ display: 'flex', justifyContent: isMobile ? 'center' : 'flex-end' }}>
                <div style={secaoStyle}>
                    <div>Série</div>
                    <div
                        style={numeroStyle}
                        title={buscarTitleSerie(status, serie, serieConfiguracoes)}
                    >
                        <span>{buscarNumeroSerie(serie, serieConfiguracoes)}</span>
                    </div>
                </div>
                <div
                    style={secaoStyle}
                    title={buscarTitleNumeroNfce(numero)}
                >
                    <div>Número</div>
                    <div style={numeroStyle}>
                        {buscarNumeroNfce(numero)}
                    </div>
                </div>
            </div>
        </>
    );
}

function buscarTitleSerie(status, serie, serieConfiguracoes) {

    if (serie && status === Status.TRANSMITIDA) {
        return 'Série utilizada na emissão na NFC-e'
    }

    if (serieConfiguracoes) {
        return 'Série definida nas configurações da NFC-e'
    }

    return 'Nenhuma série configurada para NFC-e'
}

function buscarNumeroSerie(serie, serieConfiguracoes) {
    if (serie) {
        return serie
    }

    if (serieConfiguracoes) {
        return serieConfiguracoes
    }

    return '?'
}

function buscarTitleNumeroNfce(numero) {
    if (numero) {
        return 'Número gerado automaticamente na transmissão da NFC-e'
    }

    return 'O número da NFC-e será gerado automaticamente durante a transmissão'
}

function buscarNumeroNfce(numero) {
    if (numero) {
        return numero
    }

    return '-'
}

