export function carregarTomticket(exibirTomTicket = true) {
    // <![CDATA[
    var tomTicketExistente = document.getElementById('tomTicket')
    if (tomTicketExistente) {
        tomTicketExistente.remove()
    }

    var comTomTicketChatWidgetExistente = document.getElementById('comTomTicketChatWidget')
    if (comTomTicketChatWidgetExistente) {
        comTomTicketChatWidgetExistente.remove()
    }

    var comTomTicketChatContainerExistente = document.getElementById('comTomTicketChatContainer')
    if (comTomTicketChatContainerExistente) {
        comTomTicketChatContainerExistente.remove()
    }

    if (exibirTomTicket) {
        var novoTomTicket = document.createElement('script');

        novoTomTicket.id = 'tomTicket'
        document.tomticketChatLoaderScriptVersion = 2;
        novoTomTicket.src = 'https://microsyssistemas.tomticket.com/scripts-chat/chat.min.js' +
            '?id=EP28232' +
            '&account=1806848P30042020051232'+ 
            '&autoOpen=0' +
            '&hideWhenOffline=0' +
            '&d=microsyssistemas' +
            '&ts=' + new Date().getTime() +
            '&ref=' + encodeURIComponent(document.URL);
        document.body.appendChild(novoTomTicket);
    }

    //]]>
}