import React from 'react';
import propTypes from 'prop-types';

const FormActions = props => {
    return (
        <div {...props}>
            {props.children}
        </div>
    );
}

FormActions.prototype = {
    children: propTypes.any.isRequired,
}

export default FormActions;
