import React from 'react';
import { FaKey } from 'react-icons/fa';
import { copiarParaAreaTransferencia } from '../../../../../../../common/areaDeTransferencia';
import { notify, ToastTypes } from '../../../../../../../components/Toast';

const styleStatus = {
    height: '38px',
    borderRadius: '25px',
    fontWeight: "bold",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '14px'
}

const styleName = {
    textAlign: 'center',
    fontWeight: 'bold',
    padding: '2px 0px'
}

const styleChave = {
    textAlign: 'center',
    cursor: 'pointer',
    flex: '1 1',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    padding: '2px 0px',
    fontSize: '12px'
}

export default function NfeStatus({ backgroundColor, color, name, description, chave }) {
    if (chave) {
        return (
            <>
                <div title={description} style={{ ...styleStatus, backgroundColor, color }}>
                    <div style={styleName}>
                        {name}
                    </div>
                </div>
                <div
                    title="Clique para copiar"
                    style={{...styleChave, color}}
                    onClick={() => {
                        copiarParaAreaTransferencia(chave)
                        notify("Chave de acesso copiada para área de transferência", ToastTypes.SUCCESS)
                    }}
                >
                    <FaKey /> {chave}
                </div>
            </>
        );
    } else {
        return (
            <div title={description} style={{ ...styleStatus, backgroundColor, color }}>
                <div style={styleName}>
                    {name}
                </div>
            </div>
        );
    }
}
