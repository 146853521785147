import React, { Component } from 'react';
import Chart from "chart.js";
import { Card } from 'primereact/card';
import autoBind from 'react-autobind';
import { asyncValorEstoquePorPeriodo } from './requests';
import { ProgressBar } from 'primereact/progressbar';
import { formatarMonetario } from '../../../../../../../common/mascara';
import { format, parse } from 'date-fns';
import FalhaAoCarregarAsInformacoes from '../../../FalhaAoCarregarAsInformacoes';
import { Estoque } from '../../../../../../util/constantes';

const cardHeader = {
    padding: '14px',
    display: 'flex',
    justifyContent: 'space-between'
}

let graficoBalancoDiario;

export default class GraficoValorEstoquePorPeriodo extends Component {
    graficoDeValorEstoquePorDia = React.createRef();

    constructor(props) {
        super(props);

        autoBind(this);

        this.state = {
            loading: true,
            error: false,
            data: [],
            valorEstoque: [],
        }
    }

    componentDidMount() {
        this.carregarInformacoes();
    }

    componentDidUpdate(prevProps) {
        const { dataInicial, dataFinal } = this.props;

        if (prevProps.dataInicial !== dataInicial || prevProps.dataFinal !== dataFinal) {
            this.carregarInformacoes();
        }

        if (prevProps.atualizarGraficoNovamente !== this.props.atualizarGraficoNovamente) {
            this.carregarInformacoes(false);
        }
    }

    carregarInformacoes(exibirLoading = true) {
        const { dataInicial, dataFinal } = this.props;
        this.setState({ loading: exibirLoading, error: false }, () => {
            asyncValorEstoquePorPeriodo(dataInicial, dataFinal, ({ data: valorEstoquePeriodo }) => {
                let data = [];
                let valorEstoque = [];

                valorEstoquePeriodo.forEach(elemento => {
                    data.push(elemento.data);
                    valorEstoque.push(elemento.valorEstoque);
                });

                this.setState({ data, valorEstoque, loading: false, error: false }, () => {
                    this.buildChart();
                });
            }, () => {
                this.setState({ error: true, loading: false })
            });
        })
    }

    buildChart = () => {
        if (!this.graficoDeValorEstoquePorDia.current) {
            return
        }
        const myChartRef = this.graficoDeValorEstoquePorDia.current.getContext("2d");

        if (typeof graficoBalancoDiario !== "undefined") graficoBalancoDiario.destroy(); //Necessário para atualizar o gráfico sem bugs

        graficoBalancoDiario = new Chart(myChartRef, {
            type: 'line',
            data: {
                labels: this.state.data,
                datasets: [{
                    label: 'Valor do estoque no dia',
                    data: this.state.valorEstoque,
                    borderColor: Estoque.cores.verde,
                    backgroundColor: Estoque.cores.preto
                }],
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                legend: {
                    display: false,
                    position: 'bottom',
                    labels: {
                        usePointStyle: true
                    },
                },
                aspectRatio: 1,
                animation: {
                    animateScale: true,
                    animateRotate: true
                },
                tooltips: {
                    callbacks: {
                        title: tooltipItem => {
                            return `Data: ${tooltipItem[0].xLabel}`;
                        },
                        afterTitle: tooltipItem => {
                            return `Valor: ${formatarMonetario(tooltipItem[0].yLabel)}`;
                        },
                        label: (tooltipItem, data) => {
                            const label = data.datasets[tooltipItem.datasetIndex].label;
                            const valor = formatarMonetario(tooltipItem.yLabel);
                            return `${label}: ${valor}`;
                        },
                    }
                },
                plugins: {
                    datalabels: {
                        color: "#fff0",
                    }
                },
                scales: {
                    xAxes: [{
                        ticks: {
                            callback: value => format(parse(value, 'yyyy-MM-dd', new Date()), 'dd/MM/yyyy')
                        }

                    }],
                    yAxes: [{
                        ticks: {
                            callback: value => formatarMonetario(value),
                            beginAtZero: true
                        }
                    }],
                },
            }
        });
    }

    getHeaderCard() {
        return (
            <div style={cardHeader}>
                <label
                    title="Lista o valor do estoque em cada dia. Passando o mouse por cima dos pontos do gráfico é possível visualizar o valor no dia"
                    style={{ fontSize: '17px', color: '#333333' }}
                >
                    Valor do estoque ao longo do período
                </label>
            </div>
        );
    }

    render() {
        const valorEstoqueTotal = this.state.valorEstoque.pop();

        if (this.state.loading) {
            return (
                <Card header={this.getHeaderCard()} style={{ borderRadius: '5px' }}>
                    <ProgressBar mode="indeterminate" style={{ height: '3px' }} />
                </Card>
            )
        } else if (this.state.error) {
            return (
                <Card header={this.getHeaderCard()} style={{ borderRadius: '5px', height: '100%' }}>
                    <FalhaAoCarregarAsInformacoes />
                </Card>
            )
        } else {
            return (
                <Card className="card-canvas-dashboard-financas" header={this.getHeaderCard()} style={{ borderRadius: '5px' }}>
                    <div>
                        <canvas id="graficoDeValorEstoquePorDia" ref={this.graficoDeValorEstoquePorDia} />
                    </div>

                    <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', padding: '7px', fontWeight: 'bold' }}>
                        Valor atual do estoque: &nbsp;
                        <span style={{ fontSize: '17px', color: `${Estoque.cores.azul}` }}>
                            {formatarMonetario(valorEstoqueTotal)}
                        </span>
                    </div>
                </Card>
            )
        }
    }
}
