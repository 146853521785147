import React, { Component } from 'react';
import Grid from '../../../components/Grid';
import autoBind from 'react-autobind'
import { recursos, permissoes } from '../../../common/constantes/autorizacao';
import MessagePlanoExcedido from '../../configuracao/Planos/components/MessagePlanoExpirado';
import { buscarDadosLoginLocalStorage, usuarioPossuiPermissao, sincronizarInformacoesLogin } from '../../../common/autenticacao';
import MessageDiasRestantes from '../../configuracao/Planos/components/MessageDiasRestantes';
import DashboardDocumentosDigitais from './components/DashboardDocumentosDigitais';
import DashboardEstoque from './components/DashboardEstoque';
import DashboardFinancas from './components/DashboardFinancas';
import { asyncGetInformacoesGeraisPlano } from '../../configuracao/Planos/requests';
import DateInterval from '../../../components/DateInterval';
import { TabView, TabPanel } from 'primereact/tabview';

import GraficoSemPermissaoDashboard from './images/GraficoSemPermissaoDashboard.svg';

import './style/index.css';
import { connect } from 'react-redux';
import DashboardVendas from './components/DashboardVendas';
import { salvarItemLocalStorage, buscarItemLocalStorage } from '../../../common/localStorage';
import { DADOSLOCALSTORAGE } from '../../../common/constantes/localStorage';
import MessagePagamentoNaoEfetuado from '../../configuracao/Planos/components/MessagePagamentoNaoEfetuado';
import MessageOrganizacaoBloqueada from '../../configuracao/Planos/components/MessageOrganizacaoBloqueada';

class Dashboard extends Component {

    constructor(props) {
        super(props);

        autoBind(this);

        this.state = {
            dataInicial: null,
            dataFinal: null,
            renderizarMessageDiasRestantes: false,
            organizacaoBloqueada: buscarDadosLoginLocalStorage().organizacao.bloqueada,
            meioPagamentoOrganizacao: buscarDadosLoginLocalStorage().meioPagamento,
            tabSelecionada: this.buscarTabInicial(),
        }
    }

    buscarPermissoesAtualizadas() {
        return {
            podeVisualizarFinancas: usuarioPossuiPermissao(recursos.FINANCAS_DASHBOARD, permissoes.VISUALIZAR),
            podeVisualizarDocumentos: usuarioPossuiPermissao(recursos.DOCUMENTOS_DASHBOARD, permissoes.VISUALIZAR),
            podeVisualizarVendas: usuarioPossuiPermissao(recursos.VENDAS_DASHBOARD, permissoes.VISUALIZAR),
            podeVisualizarEstoque: usuarioPossuiPermissao(recursos.ESTOQUE_DASHBOARD, permissoes.VISUALIZAR),
        }
    }

    buscarTabInicial() {
        const { podeVisualizarFinancas, podeVisualizarDocumentos, podeVisualizarVendas, podeVisualizarEstoque } = this.buscarPermissoesAtualizadas();
        const tabViewSalva = Number(buscarItemLocalStorage(DADOSLOCALSTORAGE.tabviewDashboard, false) || 0)

        let tabsDisponiveis = []
        if (podeVisualizarFinancas) {
            tabsDisponiveis.push(0)
        }
        if (podeVisualizarVendas) {
            tabsDisponiveis.push(1)
        }
        if (podeVisualizarDocumentos) {
            tabsDisponiveis.push(2)
        }
        if (podeVisualizarEstoque) {
            tabsDisponiveis.push(3)
        }

        if (tabsDisponiveis.length === 0) {
            return null
        } else if (tabsDisponiveis.includes(tabViewSalva)) {
            return tabViewSalva;
        } else {
            return tabsDisponiveis[0];
        }
    }

    componentDidMount() {
        sincronizarInformacoesLogin()
        asyncGetInformacoesGeraisPlano(({ data }) => {
            let renderizarMessageDiasRestantes = data.valorPlanoAtual > 0 && data.efetuarPagamentoAoAderir && !this.state.organizacaoBloqueada
            this.setState({ renderizarMessageDiasRestantes })
        })
    }

    componentDidUpdate(prevProps) {
        if (prevProps.dataAtualizacaoMenuLateral !== this.props.dataAtualizacaoMenuLateral) {
            asyncGetInformacoesGeraisPlano(({ data }) => {
                let renderizarMessageDiasRestantes = data.valorPlanoAtual > 0 && data.efetuarPagamentoAoAderir && !this.state.organizacaoBloqueada
                this.setState({ renderizarMessageDiasRestantes, organizacaoBloqueada: buscarDadosLoginLocalStorage().organizacao.bloqueada })
            })
        }
    }

    onChangePeriodo(dataInicial, dataFinal) {
        this.setState({ dataInicial, dataFinal })
    }

    renderValidationMessage() {
        const { renderizarMessageDiasRestantes, organizacaoBloqueada, meioPagamentoOrganizacao } = this.state;

        if (meioPagamentoOrganizacao === "CARTAO_CREDITO") {
            if (organizacaoBloqueada) {
                return <MessagePlanoExcedido />
            } else if (renderizarMessageDiasRestantes) {
                return <MessageDiasRestantes />
            }
        } else if (meioPagamentoOrganizacao === "BOLETO_BANCARIO" && organizacaoBloqueada) {
            return <MessagePagamentoNaoEfetuado />
        } else if(organizacaoBloqueada) {
            return <MessageOrganizacaoBloqueada />
        }
    }

    possuiDashboardParaMostrar() {
        const { podeVisualizarFinancas, podeVisualizarDocumentos, podeVisualizarVendas, podeVisualizarEstoque } = this.buscarPermissoesAtualizadas();
        //acrescentar novos dashboards no if
        if (podeVisualizarDocumentos || podeVisualizarFinancas || podeVisualizarVendas || podeVisualizarEstoque) {
            return true;
        }
        return false;
    }


    onChangeTabView(e) {
        salvarItemLocalStorage(DADOSLOCALSTORAGE.tabviewDashboard, e.index, false)
        this.setState({ tabSelecionada: e.index })
    }

    render() {
        const { dataInicial, dataFinal } = this.state;

        const { podeVisualizarFinancas, podeVisualizarDocumentos, podeVisualizarVendas, podeVisualizarEstoque } = this.buscarPermissoesAtualizadas();

        if (this.possuiDashboardParaMostrar()) {
            return (
                <>

                    {this.renderValidationMessage()}

                    <Grid justifyCenter style={{ padding: '0px', margin: '0px' }}>
                        <DateInterval
                            onChange={interval => this.onChangePeriodo(interval.dataInicial, interval.dataFinal)}
                        />
                    </Grid>

                    <div style={{ margin: '0px', width: '100%' }}>
                        <TabView
                            className="tab-view-dashboard"
                            activeIndex={this.state.tabSelecionada}
                            onTabChange={this.onChangeTabView}
                            style={{ padding: '0px' }}
                            renderActiveOnly
                        >
                            <TabPanel header="Finanças" headerStyle={!podeVisualizarFinancas ? { display: 'none' } : null}>
                                <DashboardFinancas
                                    dataInicial={dataInicial}
                                    dataFinal={dataFinal}
                                />
                            </TabPanel>

                            <TabPanel header="Vendas" headerStyle={!podeVisualizarVendas ? { display: 'none' } : null}>
                                <DashboardVendas
                                    dataInicial={dataInicial}
                                    dataFinal={dataFinal}
                                />
                            </TabPanel>
                            <TabPanel header="Assinatura eletrônica" headerStyle={!podeVisualizarDocumentos ? { display: 'none' } : null}>
                                <DashboardDocumentosDigitais
                                    dataInicial={dataInicial}
                                    dataFinal={dataFinal}
                                />
                            </TabPanel>
                            <TabPanel header="Estoque" headerStyle={!podeVisualizarEstoque ? { display: 'none' } : null}>
                                <DashboardEstoque
                                    dataInicial={dataInicial}
                                    dataFinal={dataFinal}
                                />
                            </TabPanel>
                        </TabView>
                    </div>
                </>
            );
        } else {
            return (
                <>
                    {this.renderValidationMessage()}
                    <div style={{ textAlign: 'center' }}>
                        <img
                            src={GraficoSemPermissaoDashboard}
                            alt="Nenhuma informação a ser exibida"
                            width="130"
                            height="82"
                        />
                    </div>
                    <div style={{ textAlign: 'center' }}>
                        <span style={{ fontWeight: '600' }}>Nenhuma informação a ser exibida</span>
                    </div>
                </>
            )
        }
    }
}

const mapStateToProps = state => ({
    dataAtualizacaoMenuLateral: state.menuLateral.dataAtualizacaoMenuLateral,
})

export default connect(mapStateToProps)(Dashboard);
