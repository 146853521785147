import React, { Component } from 'react'
import Modal from '../../../../../components/Modal';
import Grid from '../../../../../components/Grid';
import autoBind from 'react-autobind';
import Col from '../../../../../components/Col';
import { Button } from 'primereact/button';
import { RadioButton } from 'primereact/radiobutton'
import AutoProgressBar from '../../../../../components/Loading/AutoProgressBar';

export const tiposEdicoesContasReceber = {
    APENAS_SELECIONADA: 'APENAS_SELECIONADA',
    PROXIMAS_EM_ABERTO: 'PROXIMAS_EM_ABERTO',
    TODAS_EM_ABERTO: 'TODAS_EM_ABERTO'
}


export default class ModalEdicaoContasReceber extends Component {

    constructor(props) {
        super(props)
        autoBind(this);

        this.state = {
            value: tiposEdicoesContasReceber.APENAS_SELECIONADA,
        }
    }

    buscarDescricaoEditar(value) {
        if (value === tiposEdicoesContasReceber.APENAS_SELECIONADA) {
            return 'Editar apenas selecionada'
        } else if (value === tiposEdicoesContasReceber.PROXIMAS_EM_ABERTO) {
            return 'Editar próximas em aberto'
        } else {
            return 'Editar todas em aberto'
        }
    }

    render() {

        const { visible, onHide } = this.props
        const { value } = this.state

        return (
            <React.Fragment>
                <Modal
                    header='Editar conta a receber com repetições'
                    visible={visible}
                    onHide={onHide}
                >
                    <AutoProgressBar/>
                    <p style={{ textAlign: 'justify', marginLeft:'5px' }}>
                        Você está tentando editar uma conta a receber que faz parte de várias repetições. <br /> Quais dessas repetições você deseja editar?
                    </p>
                    <Grid style={{ margin: '20px' }}>
                        <Col>
                            <RadioButton
                                inputId="rbEditar1"
                                name="value"
                                value={tiposEdicoesContasReceber.APENAS_SELECIONADA}
                                onChange={(e) => this.setState({ value: e.value })}
                                checked={value === tiposEdicoesContasReceber.APENAS_SELECIONADA}
                            />
                            <label htmlFor="rbEditar1" className="p-radiobutton-label">Editar <b>apenas esta conta a receber</b></label>
                        </Col>
                        <Col>
                            <RadioButton
                                inputId="rbEditar2"
                                name="value"
                                value={tiposEdicoesContasReceber.PROXIMAS_EM_ABERTO}
                                onChange={(e) => this.setState({ value: e.value })}
                                checked={value === tiposEdicoesContasReceber.PROXIMAS_EM_ABERTO}
                            />
                            <label htmlFor="rbEditar2" className="p-radiobutton-label">Editar <b>esta conta a receber e as próximas</b> repetições em aberto</label>
                        </Col>
                        <Col>
                            <RadioButton
                                inputId="rbEditar3"
                                name="value"
                                value={tiposEdicoesContasReceber.TODAS_EM_ABERTO}
                                onChange={(e) => this.setState({ value: e.value })}
                                checked={value === tiposEdicoesContasReceber.TODAS_EM_ABERTO}
                            />
                            <label htmlFor="rbEditar3" className="p-radiobutton-label">Editar <b>esta conta a receber e todas</b> as repetições em aberto</label>
                        </Col>
                    </Grid>
                    <Grid justifyEnd>
                        <div>
                            <Button
                                className='p-button-secondary'
                                style={{ margin: '5px' }}
                                label='Voltar'
                                onClick={() => onHide()}
                            />
                            <Button
                                style={{ margin: '5px' }}
                                className='p-button-success'
                                label={this.buscarDescricaoEditar(value)}
                                onClick={() => onHide(value)}
                            />
                        </div>
                    </Grid>
                </Modal>
            </React.Fragment>
        )
    }
}
