import { tipoCampos } from "../../../../components/PesquisaAvancada/util/constantes"

export const optionsFiltroAvancado = [
    { label: 'Descrição', name: 'descricao', type: tipoCampos.STRING },
    {
        label: 'Tipo', name: 'tipo', type: tipoCampos.SELECT,
        optionSelect: [
            { label: "Receita", value: "RECEITA" },
            { label: "Despesa", value: "DESPESA" },
        ]
    },
    { label: 'Valor', name: 'valor', type: tipoCampos.DECIMAL },
    { label: 'Observação', name: 'observacao', type: tipoCampos.STRING },
]