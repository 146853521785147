import React, { Component } from 'react'
import FormGroup from '../../../components/FormGoup/index';
import Grid from '../../../components/Grid/index';
import Card from '../../../components/Card/';
import logo from '../../../layout/images/logo_microsys.svg'
import { Button } from 'primereact/components/button/Button'
import { mensagensDeValidacao } from '../../../common/constantes/mensagens'
import PasswordField from '../../../components/Input/InputPassword'
import HotKey from 'react-shortcut'
import { asyncConfirmarSenha } from './requests'
import { cardStyle, imgStyle, errorStyle, subTitleStyle, contentStyle } from '../style'
import { withRouter } from 'react-router'
import { withFormik, Field } from 'formik';
import * as Yup from 'yup';
import { notify, ToastTypes } from '../../../components/Toast';
import { fazerLogoutLocalStorange } from '../../../common/autenticacao';
import autoBind from 'react-autobind'
import AutoProgressBar from '../../../components/Loading/AutoProgressBar';

class ConfirmarSenha extends Component {

    constructor(props) {
        super(props)
        autoBind(this)

        this.state = {
            errorMessage: '',
            loading: false
        }
    }

    componentDidMount() {

        const location = window.location.toString()
        if (location.indexOf('token=') === -1) {
            this.setState({ ...this.state, errorMessage: 'Link de autenticação expirado!' })
        }
        const token = location.substring(location.indexOf('token=') + 6)
        this.props.values.token = token
    }

    async criarSenha() {
        const { handleSubmit, isValid, urlConfirmacao, values } = this.props;

        handleSubmit()
        if (isValid && !this.state.errorMessage && !this.state.loading) {
            this.setState({ loading: true }, () => {
                asyncConfirmarSenha(urlConfirmacao, values, () => {
                    fazerLogoutLocalStorange()
                    window.location = "#/login"
                    notify('Senha cadastada com sucesso!', ToastTypes.SUCCESS)
                }, (e) => {
                    if (!e.response || !e.response.data) {
                        this.setState({ errorMessage: "Erro de conexão com a internet!", loading: false })
                    } else {
                        this.setState({ errorMessage: e.response.data.message, loading: false })
                    }
                })
            })


        }
    }

    render() {

        const errorMessage = (<div style={errorStyle}>
            <center>
                <h4 style={{ margin: 0, color: '#751111' }}>{this.state.errorMessage}</h4>
            </center>
        </div>)

        return (
            <>
                <AutoProgressBar />
                <div style={contentStyle}>
                    <Card style={cardStyle}>
                        {this.state.errorMessage ? errorMessage : undefined}
                        <div className="right-side p-g-12 p-lg-12" style={{ display: 'flex', justifyContent: 'center' }}>
                            <img alt='Logo Microsys'
                                style={imgStyle}
                                src={logo}
                            />
                        </div>
                        <div className="left-side p-g-12 p-lg-12">
                            <h3 style={subTitleStyle}>Bem-vindo, por favor crie uma senha de acesso.</h3>
                            <div className="content-section implementation">
                                <FormGroup>
                                    <Grid>
                                        <Field sm="12" md="12" lg='12' xl='12'
                                            component={PasswordField}
                                            label='Senha'
                                            feedback={false}
                                            name="senha"
                                        />
                                        <Field sm="12" md="12" lg='12' xl='12'
                                            component={PasswordField}
                                            label='Confirmação da senha'
                                            name="confirmarSenha"
                                            feedback={false}
                                        />
                                        <Button
                                            type='button'
                                            label={this.state.loading ? 'Carregando...': 'Criar senha'}
                                            disabled={this.state.loading}
                                            style={{ margin: '7px', height: '40px', width: '100%' }}
                                            onClick={this.criarSenha}
                                        />

                                    </Grid>
                                </FormGroup>
                                <HotKey
                                    keys={['enter']}
                                    simultaneous
                                    onKeysCoincide={() => {
                                        this.criarSenha()
                                    }}
                                />
                            </div>
                        </div>
                    </Card>
                </div>
            </>
        )
    }
}

ConfirmarSenha = withFormik({
    mapPropsToValues() {
        return {
            senha: '',
            confirmarSenha: '',
            token: ''
        }
    },

    validate: (e) => {
        let errors = {}
        if (e.senha !== e.confirmarSenha) {
            errors.confirmarSenha = 'As senhas não coincidem!'
        }
        return errors
    },

    validationSchema: Yup.object().shape({
        senha: Yup.string().nullable().required(mensagensDeValidacao.OBRIGATORIO).test('len', 'A senha deve ter no mínimo 8 caracteres', val => val && val.length >= 8),
        confirmarSenha: Yup.string().nullable().required(mensagensDeValidacao.OBRIGATORIO).test('len', 'A senha deve ter no mínimo 8 caracteres', val => val && val.length >= 8)
    }),
    handleSubmit: () => { }
})(ConfirmarSenha);

export default withRouter(ConfirmarSenha)

