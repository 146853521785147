import React, { Component } from 'react';
import { Card } from 'primereact/card';
import autoBind from 'react-autobind';
import { buscarContas } from './requests';
import { ProgressBar } from 'primereact/progressbar';
import { formatarMonetario } from '../../../../../../../common/mascara';
import NenhumaInformacaoEncontrada from '../../../NenhumaInformacaoEncontrada';
import { Link, withRouter } from 'react-router-dom';

import './styles/index.css';
import FalhaAoCarregarAsInformacoes from '../../../FalhaAoCarregarAsInformacoes';
import { usuarioPossuiPermissao } from '../../../../../../../common/autenticacao';
import { recursos, permissoes } from '../../../../../../../common/constantes/autorizacao';
import { Financas } from '../../../../../../util/constantes'


const List = props => {
    return (
        <div className="listagem-contas-content">
            <ul style={{ listStyle: "none", padding: '0px 3px', margin: '0px' }}>
                {props.children}
            </ul>
        </div>
    );
}

const ListItem = props => {
    return (
        <li style={{ margin: '6px 0px' }}>
            <div className="p-grid" style={{ margin: '0px 6px', }}>
                <span className="p-col listagem-contas-title" title={props.nome} style={{ padding: '0px' }}>
                    {props.nome}
                </span>
                <div
                    className="p-col-fixed listagem-contas-coluna-valor"
                    style={props.saldoAtual < 0 ? { color: Financas.cores.vermelho } : { color: Financas.cores.verde }}
                    onClick={props.habilitarVerDetalhes ? () => props.verDetalhesClick(props.id) : null}
                    title={props.habilitarVerDetalhes ? 'Ver movimentações' : null}
                >
                    {formatarMonetario(props.saldoAtual)}
                </div>
            </div>
        </li>
    );
}

class ListagemContas extends Component {

    constructor(props) {
        super(props);

        autoBind(this);

        this.state = {
            loading: true,
            error: false,
            contas: [],
            podeVisualizarLancamentos: usuarioPossuiPermissao(recursos.FINANCAS_LANCAMENTOS, permissoes.VISUALIZAR),
	    podeVisualizarContas: usuarioPossuiPermissao(recursos.FINANCAS_CONTAS, permissoes.VISUALIZAR), 
        }
    }

    componentDidMount() {
        this.carregarInformacoes();
    }

    componentDidUpdate(prevProps) {
        const { dataInicial, dataFinal } = this.props;

        if (prevProps.dataInicial !== dataInicial || prevProps.dataFinal !== dataFinal) {
            this.carregarInformacoes();
        }

        if (prevProps.atualizarGraficoNovamente !== this.props.atualizarGraficoNovamente) {
            this.carregarInformacoes(false);
        }
    }

    carregarInformacoes(exibirLoading = true) {
        this.setState({ loading: exibirLoading, error: false }, () => {
            buscarContas(({ data: contas }) => {
                this.setState({ contas, loading: false, error: false });
            }, () => {
                this.setState({ error: true, loading: false });
            });
        })
    }

    getHeaderCard() {
        return (
            <div id="card-header-listagem-contas">
                <label title="Lista as contas e seu saldo atual">
                    Saldo atual das minhas contas
                </label>
            </div>
        );
    }


    onVerDetalhesClick(contaId) {
        this.props.history.push({
            pathname: '/movimentacoes',
            state: { contaSelecionadaId: contaId }
        })
    }

    render() {
        if (this.state.loading) {
            return (
                <Card header={this.getHeaderCard()} style={{ borderRadius: '5px', height: '100%' }}>
                    <ProgressBar mode="indeterminate" style={{ height: '3px' }} />
                </Card>
            )
        } else if (this.state.error) {
            return (
                <Card header={this.getHeaderCard()} style={{ borderRadius: '5px', height: '100%' }}>
                    <FalhaAoCarregarAsInformacoes />
                </Card>
            )
        } else {
            const { contas, podeVisualizarLancamentos } = this.state;
            const saldoTotal = contas.reduce((acc, cur) => acc + cur.saldoAtual, 0);

            return (
                <Card header={this.getHeaderCard()} style={{ borderRadius: '5px', height: '100%' }}>
                    {contas.length > 0 ? (
                        <>
                            <List>
                                {contas.map((conta, idx, array) => {
                                    return (
                                        <React.Fragment key={conta.id}>
                                            <ListItem
                                                id={conta.id}
                                                nome={conta.nome}
                                                saldoAtual={conta.saldoAtual}
                                                verDetalhesClick={this.onVerDetalhesClick}
                                                habilitarVerDetalhes={podeVisualizarLancamentos}
                                            />
                                            {++idx !== array.length ? <hr className="divider-listagem-contas" /> : null}
                                        </React.Fragment>
                                    )
                                })}
                            </List>
                            <hr className="divider-listagem-contas" />
                            <div className="p-grid" style={{ margin: '0px 6px' }}>
                                <span title="Soma total de todas as contas" className="p-col" style={{ fontWeight: 'bold', padding: '0.5em 0px', fontSize: '16px' }}>
                                    Saldo total
                                </span>
                                <div className="p-col-fixed listagem-contas-coluna-total" style={saldoTotal < 0 ? { color: Financas.cores.vermelho, fontWeight: 'bold' } : { color: Financas.cores.verde, fontWeight: 'bold' }}>
                                    {formatarMonetario(saldoTotal)}
                                </div>
                            </div>
                            {this.state.podeVisualizarContas && (
                                <div style={{ textAlign: 'center' }}>
                                    <Link to="/contas">Visualizar contas</Link>
                                </div>
                            )}
                        </>
                    ) : <NenhumaInformacaoEncontrada />}
                </Card>
            )
        }
    }
}

export default withRouter(ListagemContas)