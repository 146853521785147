import React from 'react';
import { Field } from 'formik';
import Grid from '../../../../../components/Grid';
import InputField from '../../../../../components/Input/InputField';
import Dropdown from '../../../../../components/Select/Dropdown';
import InputMask from '../../../../../components/Input/InputMask';
import InputDate from '../../../../../components/Input/InputDate';
import { GENERO } from '../../utils/constantes';
import FormGroup from '../../../../../components/FormGoup';
import { helpFieldText } from './help';

export function renderizarFieldsPessoaFisica( values, setFieldValue, informacoesPermissoes ) {
    return (
        <FormGroup>
            <Grid>
                <Field sm="12" md="4" lg='3' xl='3'
                    component={InputMask}
                    mask="000.000.000-00"
                    placeholder="   .   .   -  "
                    label='CPF '
                    name="cpf"
                    helpMessage={helpFieldText.cpf}
                    value={values.cpf}
                    onChange={(e) => setFieldValue('cpf', e.target.value)}
                    {...informacoesPermissoes}
                />
                <Field sm="12" md="4" lg='3' xl='3'
                    component={InputField}
                    type="text"
                    label='RG'
                    name="rg"
                    value={values.rg}
                    helpMessage={helpFieldText.rg}
                    size={20}
                    {...informacoesPermissoes}
                />
                <Field sm="12" md="4" lg='3' xl='3'
                    component={Dropdown}
                    label='Gênero'
                    name="genero"
                    helpMessage={helpFieldText.genero}
                    onChange={(e) => setFieldValue('genero', e.value)}
                    options={GENERO}
                    value={values.genero}
                    {...informacoesPermissoes}
                />
                <Field sm="12" md="4" lg='3' xl='3'
                    component={InputDate}
                    name="nascimento"
                    label="Data de nascimento"
                    helpMessage={helpFieldText.dataNascimento}
                    onChange={(e) => setFieldValue('nascimento', e.target.value)}
                    value={values.nascimento}
                    {...informacoesPermissoes}
                />
            </Grid>
        </FormGroup>
    )
}