import React, { Component } from 'react';
import autoBind from 'react-autobind';
import SingleSelect from '../SingleSelect';
import propTypes from 'prop-types'

class SingleSelectCorretoraConta extends Component {
    constructor(props) {
        super(props);
        autoBind(this);

        this.state = {
            visible: false
        }
    }

    buscarUrlPesquisa(pesquisa, page) {
        return `${this.props.url}?query=codigo=contains="*${pesquisa}*"&page=${page}&size=50`;
    }

    buscarUrlRegistroUnico(id) {
        return `${this.props.url}?query=id==${id}`;
    }

    montarLabel(row) {
        return `${row.corretora.nome} (${row.codigo})`;
    }

    mostrarModal() {
        this.setState({ visible: !this.state.visible });
    }

    esconderModal() {
        this.setState({ ...this.state, visible: false })
    }

    render() {
        return (
            <React.Fragment>
                <SingleSelect
                    buscarUrlPesquisa={this.buscarUrlPesquisa}
                    montarLabel={this.montarLabel}
                    onClickModal={this.mostrarModal}
                    onChange={this.props.onChange}
                    esconderBotao={true}
                    {...this.props}
                />
            </React.Fragment>
        );
    }
}

SingleSelectCorretoraConta.defaultProps = {
    label: 'Conta'
}

SingleSelectCorretoraConta.propTypes = {
    /** Url do servidor em que as requisições de busca deverão ser efetuadas: Ex:  `${services.GESTOR}/v1/usuarios/relacoes/pessoas`*/
    url: propTypes.string.isRequired
};


export default SingleSelectCorretoraConta;
