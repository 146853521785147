import React from 'react';
import propTypes from 'prop-types';

const FormContent = props => {
    return (
        <div {...props}>
            {props.children}
        </div>
    );
}

FormContent.prototype = {
    children: propTypes.any.isRequired
}

export default FormContent;