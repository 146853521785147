import React from 'react';
import { connect } from 'react-redux';
import './style/index.css';
import FormActions from '../FormActions'
import FormMobileActions from '../FormMobileActions'

const Form = props => {

    const formAction = props.children && props.children.filter(children => children.type === FormActions);
    const formMobileAction = props.children && props.children.filter(children => children.type === FormMobileActions);
    const otherChildren = props.children && props.children.filter(children => children.type !== FormActions && children.type !== FormMobileActions);

    const renderActionButtons = () => {
        //quando é um celular e possui botoes personalizados para tal
        if (props.isMobile && formMobileAction.length > 0) {
            return formMobileAction;
        } else {
            return formAction;
        }
    }

    //usado em listagens, quando precisa ter um título para o formulário
    if (props.header && !props.isModal) {
        let classNameCard = props.className ? `card card-w-title ${props.className}` : `card card-w-title`
        return (
            <>
                {renderActionButtons()}
                <div className={classNameCard} style={props.style}>
                    <h1>{props.header}</h1>
                    {otherChildren}
                </div>
            </>
        );
    } else {
        //usado em modais, quando o modal já possui um título
        return (
            <>
                <div style={{width: '100%'}}>
                    {renderActionButtons()}
                </div>
                {otherChildren}
            </>
        );
    }
}

Form.defaultProps = {
    className: "card-default"
}

const mapStateToProps = state => ({
    isMobile: state.dispositivo.isMobile,
})

export default connect(mapStateToProps)(Form);
