import React, { Component } from 'react';
import ButtonNovo from '../../components/Button/ButtonNovo';
import { services } from '../../common/constantes/api.js';
import { recursos, permissoes } from '../../common/constantes/autorizacao.js';
import Col from '../../components/Col';
import Grid from '../../components/Grid';
import autoBind from 'react-autobind'
import PesquisaAvancada from '../../components/PesquisaAvancada';
import { optionsFiltroAvancado, statusDocumento, ColorsCard } from './util/constantes';
import DescricaoFiltroAvancado from '../../components/DescricaoFiltroAvancado';
import { construirUrl } from '../../common/rsql';
import ButtonEditarTable from '../../components/Button/ButtonEditarTable';
import { confirm, notify, ToastTypes } from '../../components/Toast';
import Button from '../../components/Button';
import InputSearch from '../../components/Input/InputSearch';
import { Column } from 'primereact/column';
import { renderStatus, aplicarEstiloArquivado, renderDataCriacao, buscarMensagemExcluirDocumento } from './util/funcoesList';
import If from '../../components/If';
import ButtonExcluir from '../../components/Button/ButtonExcluir';
import { Menu } from 'primereact/menu';
import DocumentoHistorico from './Form/components/DocumentoHistorico';
import { asyncArquivarDocumento, asyncBaixarDocumentoComAssinaturas, asyncBaixarDocumentoOriginal, asyncDesarquivarDocumento, asyncExcluirDocumento, asyncGetDocumento, asyncGetPesquisaDocumentos, asyncGetTotalizadores } from './requests';
import { atualizarUrl } from '../util';
import { usuarioPossuiPermissao } from '../../common/autenticacao';
import { executarComLoading } from '../../common/loading';
import { buscarConfiguracaoUsuario, configuracoesUsuario, salvarConfiguracaoUsuario } from '../../common/configuracaoUsuario';
import { tutorialSteps } from '../../components/Tutorial/steps/listagens';
import Tutorial from '../../components/Tutorial';
import { connect } from 'react-redux';
import FormActions from '../../components/FormActions';
import Form from '../../components/Form';
import FormContent from '../../components/FormContent';
import { baixarArquivo } from '../../common/relatorios';
import ModalVerDetalhesDocumento from './Form/modal/ModalVerDetalhesDocumento';
import ModalEnviarPorEmail from './Form/modal/ModalEnviarPorEmail';
import { DataTable } from 'primereact/components/datatable/DataTable';
import NenhumRegistroEncontrado from '../../components/NenhumRegistroEncontrado';
import { Paginator } from 'primereact/paginator';
import DateInterval from '../../components/DateInterval';
import { formatISO } from 'date-fns';
import CardTotalizadorListagem from './components/CardTotalizadorListagem';

import ampulheta from './images/ampulheta.svg';
import assinatura_efetuada from './images/assinatura_efetuada.svg';
import assinatura_rejeitada from './images/assinatura_rejeitada.svg';
import documento_expirado from './images/documento_expirado.svg';
import { formatarParaPesquisarTiposEnumerados } from '../../common/manipulacaoDeString';
import { Checkbox } from 'primereact/checkbox';

const buttonTableStyle = {
    borderRadius: '50%',
    padding: '5px',
    width: '30px',
    height: '30px',
    marginLeft: '2.5px',
    marginRight: '2.5px'
};

class AssinaturaEletronica extends Component {

    constructor(props) {

        super(props);

        autoBind(this);

        this.state = {
            podeInserir: usuarioPossuiPermissao(recursos.DOCUMENTOS, permissoes.INSERIR),
            podeExcluir: usuarioPossuiPermissao(recursos.DOCUMENTOS, permissoes.EXCLUIR),
            podeEditar: usuarioPossuiPermissao(recursos.DOCUMENTOS, permissoes.EDITAR),
            deveExibirTutorial: buscarConfiguracaoUsuario(configuracoesUsuario.EXIBIR_TUTORIAL_LISTAGENS),
            descricaoFiltroAvancado: "",
            filtroAvancado: "",
            valorPesquisa: "",
            exibirArquivados: false,
            registroSelecionado: null,
            modalDocumentoHistoricoVisible: false,
            documentosSelecionados: [],
            tutorialVisible: false,
            filtroData: "",
            valorCard: "",

            mostrarModalVerDetalhesDocumento: false,
            documentoSelecionado: null,
            modalEnviarDocumentoVisible: false,
            signatarioEspecifico: null,
            cards: {
                aguardandoAssinaturas: 0,
                assinados: 0,
                rejeitados: 0,
                expirados: 0
            },
            cardSelected: null,
            registros: [],

            interval: {
                dataInicial: null,
                dataFinal: null
            },

            sortField: 'criadoEm',
            sortOrder: -1,

            page: 0,
            rows: 10,
            totalElements: 0,
        }
    }

    componentDidMount() {
        const { state } = this.props.location;

        if (state && state.pesquisaPadrao) {
            this.setState({ valorPesquisa: state.pesquisaPadrao })
        }

        if (this.state.deveExibirTutorial !== false) {
            this.setState({ tutorialVisible: true })
            salvarConfiguracaoUsuario(configuracoesUsuario.EXIBIR_TUTORIAL_LISTAGENS, false, null, false)
        }
    }

    asyncExcluirRegistro() {
        const { registroSelecionado } = this.state;
        confirm('Atenção', buscarMensagemExcluirDocumento(registroSelecionado), () => {
            asyncExcluirDocumento(registroSelecionado.id, () => {
                notify("Documento excluído", ToastTypes.SUCCESS)
                this.pesquisar(false)
            })
        });
    }

    excluirSelecionados(documentos) {
        confirm('Confirmação', 'Tem certeza que deseja excluir estes documentos?',
            async () => {
                let error = false
                for (const documento of documentos) {
                    // eslint-disable-next-line 
                    await asyncExcluirDocumento(documento.id, null, () => { error = true })
                }
                if (!error) {
                    notify("Documentos excluídos", ToastTypes.SUCCESS)
                }
                this.limparRegistrosSelecionados()
                this.pesquisar()
            }
        );
    }

    buscarFiltro() {
        const { filtroData, valorPesquisa, valorCard, filtroAvancado, exibirArquivados } = this.state;

        const valorTiposEnumerados = formatarParaPesquisarTiposEnumerados(valorPesquisa);

        let filtroRSQL = String("?query=(")
            .concat(`identificacao=contains="*${valorPesquisa}*",`)
            .concat(`pessoaNome=contains="*${valorPesquisa}*",`)
            .concat(`status=contains="*${valorTiposEnumerados}*")`)
            .concat(`;(${filtroData})`)

        if (filtroAvancado) {
            filtroRSQL = filtroRSQL.concat(`;(${filtroAvancado})`)
        }
        if (!exibirArquivados) {
            filtroRSQL = filtroRSQL.concat(`;arquivado==${false}`)
        }
        if (valorCard) {
            filtroRSQL = filtroRSQL.concat(`;(${valorCard})`)
        }

        return filtroRSQL;
    }

    pesquisar(exibirLoading) {
        const { page, sortField, sortOrder, rows, interval } = this.state
        const filtro = this.buscarFiltro();
        const url = construirUrl(`${services.GESTOR}/v1/documentos/resumo`, filtro, rows, page, sortOrder > 0 ? `${sortField},asc` : `${sortField},desc`)

        asyncGetTotalizadores(interval, ({ data: totais }) => {
            this.setState({ cards: totais });
        });

        asyncGetPesquisaDocumentos(url, ({ data: documentos }) => {
            this.setState({ registros: documentos.content, totalElements: documentos.totalElements })
        }, null, exibirLoading)
    }

    async buscarDocumento(documentoId) {
        await asyncGetDocumento(documentoId, ({ data: documento }) => {
            this.setState({ documentoSelecionado: documento, mostrarModalVerDetalhesDocumento: true })
        });
    }

    renderAssinados(row) {
        let assinados = row ? aplicarEstiloArquivado(row.arquivado, `${row.qtdAssinaturasRealizadas} de ${row.qtdSignatarios}`) : aplicarEstiloArquivado(row.arquivado, '0 de 0');

        if ([statusDocumento.AGUARDANDO_ASSINATURAS, statusDocumento.ASSINADO, statusDocumento.REJEITADO, statusDocumento.EXPIRADO].includes(row.status)) {
            return (
                <span title={assinados} style={{ display: 'inline-block' }}>
                    <span
                        title="Clique para ver os detalhes"
                        className="link_to"
                        style={{ color: '#006095' }}
                        onClick={() => this.buscarDocumento(row.id)}
                    >
                        {assinados}
                    </span>
                </span>
            )
        }
    }

    onPesquisar() {
        this.setState({ page: 0 }, () => {
            this.pesquisar();
        })
    }

    onPageChange(e) {
        this.setState({ page: e.page, rows: e.rows }, () => {
            this.pesquisar()
        })
    }

    onSort(e) {
        this.setState({ sortField: e.sortField, sortOrder: e.sortOrder }, () => {
            this.pesquisar()
        })
    }

    onEditar(row) {
        atualizarUrl(this.props.history, `/documentos/cadastro/${row.id}`)
    }

    onExcluir() {
        this.asyncExcluirRegistro()
    }

    renderOpcoes(registroSelecionado) {
        const acoesDoBotao = this.buscarAcoes(registroSelecionado);
        return (
            <>
                <ButtonEditarTable
                    onClick={() => this.onEditar(registroSelecionado)}
                />
                <Button
                    color='secondary'
                    style={buttonTableStyle}
                    icon="fa fa-ellipsis-v"
                    size="icon"
                    disabled={Boolean(acoesDoBotao.length === 0)}
                    title={Boolean(acoesDoBotao.length === 0) ? "Nenhuma operação possível para este documento" : null}
                    onClick={event => {
                        this.menuOpcoes.toggle(event)
                        this.setState({ registroSelecionado })
                    }}
                />
            </>
        )
    }

    buscarAcoes(registroSelecionado) {
        let itens = [];

        if (registroSelecionado && registroSelecionado.status !== statusDocumento.PENDENTE) {
            itens.push({
                label: "Visualizar arquivo original",
                icon: "fa fa-download",
                disabled: !this.state.podeEditar,
                command: () => this.baixarDocumento(registroSelecionado.id)
            });
        }

        if (registroSelecionado && registroSelecionado.status === statusDocumento.ASSINADO) {
            itens.push({
                label: "Visualizar arquivo assinado",
                icon: "fa fa-download",
                disabled: !this.state.podeEditar,
                command: () => this.baixarDocumentoAssinado(registroSelecionado.id)
            });
        }

        itens.push({
            label: "Histórico",
            icon: "fa fa-history",
            command: () => this.setState({ modalDocumentoHistoricoVisible: true })
        });

        if (registroSelecionado && registroSelecionado.arquivado && this.state.podeEditar) {
            itens.push({
                label: "Desarquivar",
                icon: "fa fa-archive",
                disabled: !this.state.podeEditar,
                command: () => this.asyncDesarquivarDocumento()
            });
        }

        if (registroSelecionado && !registroSelecionado.arquivado && this.state.podeEditar) {
            itens.push({
                label: "Arquivar",
                icon: "fa fa-archive",
                disabled: !this.state.podeEditar,
                command: () => this.asyncArquivarDocumento()
            });
        }

        if (this.state.podeExcluir) {
            itens.push({
                label: 'Excluir',
                icon: 'fa fa-trash',
                command: () => this.onExcluir(),
            })
        }

        return itens;
    }

    baixarDocumento(documentoId) {
        asyncBaixarDocumentoOriginal(documentoId, ({ data: file }) => {
            baixarArquivo(file, 'Documento original.pdf');
        });

    }

    baixarDocumentoAssinado(documentoId) {
        asyncBaixarDocumentoComAssinaturas(documentoId, ({ data: file }) => {
            baixarArquivo(file, 'Documento assinado.pdf');
        });
    }

    asyncArquivarDocumento() {
        const { registroSelecionado } = this.state;
        const message = `Tem certeza que deseja arquivar este documento? Um documento arquivado não aparecerá mais na listagem, para visualizá-los marque o item "Exibir documentos arquivados" na listagem.`
        confirm('Atenção', message, () => {
            asyncArquivarDocumento(registroSelecionado.id, () => {
                this.pesquisar(false);
            }, null);
        });
    }

    asyncDesarquivarDocumento() {
        const { registroSelecionado } = this.state;
        const message = 'Tem certeza que deseja desarquivar este documento?'
        confirm('Atenção', message, () => {
            asyncDesarquivarDocumento(registroSelecionado.id, () => {
                this.pesquisar(false)
            }, null);
        })
    }

    async arquivarSelecionados(documentos) {
        confirm('Tem certeza que deseja arquivar estes documentos?', 'Um documento arquivado não aparecerá mais na listagem, para visualizá-los marque o item "Exibir documentos arquivados" na listagem.',
            async () => {
                executarComLoading(async () => {
                    for (const documento of documentos) {
                        await asyncArquivarDocumento(documento.id, null, null, false, false)
                    }
                    this.limparRegistrosSelecionados()
                    this.pesquisar(false)
                    notify("Documentos arquivados com sucesso")
                })
            })
    }

    async desarquivarSelecionados(documentos) {
        confirm('Confirmação', 'Tem certeza que deseja desarquivar este(s) documento(s)?',
            async () => {
                executarComLoading(async () => {
                    for (const documento of documentos) {
                        await asyncDesarquivarDocumento(documento.id, null, null, false, false)
                    }
                    this.limparRegistrosSelecionados()
                    this.pesquisar(false)
                    notify("Documentos desarquivados com sucesso")
                })
            })
    }

    limparRegistrosSelecionados() {
        this.setState({ documentosSelecionados: [] })
    }


    onPesquisarFiltroAvancado(e) {
        this.setState({ filtroAvancado: e }, () => this.onPesquisar());
    }

    onChangeCheckboxExibirArquivados(e) {
        this.limparRegistrosSelecionados()
        this.setState({ exibirArquivados: e.checked }, () => this.onPesquisar());
    }

    getHeader() {
        const { documentosSelecionados, podeEditar, podeExcluir, exibirArquivados } = this.state
        return (
            <Grid justifyBetween>
                <Col sm='6' md='6' lg='6' xl='6' style={{ display: 'flex', justifyContent: 'flex-start' }}>
                    <If test={documentosSelecionados.length > 0}>
                        <Button
                            className="p-button-primary"
                            type="button"
                            label="Arquivar"
                            icon="fa fa-archive"
                            disabled={!podeEditar}
                            title={!podeEditar ? "Você não possui permissão para executar essa ação" : ""}
                            style={{ margin: '5px' }}
                            onClick={() => this.arquivarSelecionados(documentosSelecionados)}
                        />
                        <Button
                            className="p-button-primary"
                            type="button"
                            hidden={exibirArquivados === false}
                            disabled={!podeEditar}
                            title={!podeEditar ? "Você não possui permissão para executar essa ação" : ""}
                            label="Desarquivar"
                            icon="fa fa-archive"
                            style={{ margin: '5px' }}
                            onClick={() => this.desarquivarSelecionados(documentosSelecionados)}
                        />
                        <ButtonExcluir
                            podeExcluir={podeExcluir}
                            onClick={() => this.excluirSelecionados(documentosSelecionados)}
                        />
                    </If>
                </Col>
                <Col sm='6' md='6' lg='6' xl='6'>
                    <span style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Checkbox
                            inputId="exibirArquivados"
                            checked={exibirArquivados}
                            name="exibirArquivados"
                            onChange={this.onChangeCheckboxExibirArquivados}
                        />
                        <label htmlFor="exibirArquivados" className="p-checkbox-label">Exibir documentos arquivados</label>
                    </span>
                </Col>
            </Grid>
        )
    }

    handleChangeInterval(interval) {
        this.setState({ page: 0, first: 0, interval: interval, filtroData: `criadoEm>=${formatISO(interval.dataInicial)};criadoEm<=${formatISO(interval.dataFinal)}` }, () => {
            this.pesquisar();
        })
    }

    pesquisarRSQLCardTotais(cardSelected, valueCard) {
        if (cardSelected === this.state.cardSelected) {
            this.setState({
                cardSelected: "",
                valorCard: "",
                page: 0,
                first: 0
            }, () => {
                this.pesquisar();
            });
        } else {
            this.setState({
                cardSelected,
                valorCard: valueCard,
                page: 0,
                first: 0
            }, () => {
                this.pesquisar();
            });
        }
    };

    handleSelectCard(statusDocumento) {
        const card = statusDocumento;
        const filtroRSQL = `status==${statusDocumento}`;
        this.pesquisarRSQLCardTotais(card, filtroRSQL);
    }

    render() {
        const {
            page,
            rows,
            totalElements,
            podeInserir,
            sortField,
            sortOrder,
            registros,
            descricaoFiltroAvancado,
            valorPesquisa,
            registroSelecionado,
            documentosSelecionados,
            modalDocumentoHistoricoVisible,
            mostrarModalVerDetalhesDocumento,
            documentoSelecionado,
            modalEnviarDocumentoVisible,
            cards,
            cardSelected
        } = this.state

        const { isMobile } = this.props;
        const acoesStyle = !isMobile && { textAlign: 'end' };

        return (
            <>
                <Menu
                    model={this.buscarAcoes(registroSelecionado)}
                    popup={true}
                    style={{ minWidth: '230px' }}
                    ref={el => this.menuOpcoes = el}
                />
                <Tutorial
                    steps={tutorialSteps}
                    showSkipButton
                    continuous
                    visible={this.state.tutorialVisible}
                    onHide={() => this.setState({ tutorialVisible: false })}
                    disableScrolling
                />
                <Form header="Documentos">
                    <FormActions>
                        <ButtonNovo
                            label="Novo documento"
                            className="step-listagem-novo"
                            onClick={() => { this.props.history.push('documentos/cadastro') }}
                            podeInserir={podeInserir}
                        />
                    </FormActions>

                    <FormContent>
                        <Grid justifyCenter verticalAlignCenter>
                            <span style={{ padding: '12px' }}>
                                <DateInterval
                                    onChange={this.handleChangeInterval}
                                />
                            </span>
                            <InputSearch
                                className="step-listagem-input-search"
                                onPesquisar={this.onPesquisar}
                                value={valorPesquisa}
                                onChange={value => this.setState({ valorPesquisa: value })}
                            />
                            <span style={{ padding: '12px' }}>
                                <PesquisaAvancada
                                    optionsFiltros={optionsFiltroAvancado}
                                    onPesquisarClick={this.onPesquisarFiltroAvancado}
                                    onChangeFiltroRsql={(rsql) => this.setState({ filtroAvancado: rsql })}
                                    onChangeDescricaoFiltro={e => this.setState({ descricaoFiltroAvancado: e })}
                                />
                            </span>
                        </Grid>
                        <Grid justifyBetween>
                            <Col xs="12" sm="6" md="6" lg="3" xl="3">
                                <CardTotalizadorListagem
                                    name="cardAguardandoAssinatura"
                                    title="Aguardando assinatura"
                                    helpMessage={!isMobile ? "Exibe a quantidade de documentos que estão aguardando assinatura no período" : ""}
                                    titleFiltro="Clique para filtrar pelos documentos aguardando assinatura"
                                    colors={ColorsCard.AGUARDANDO_ASSINATURAS}
                                    value={cards.aguardandoAssinaturas}
                                    icon={ampulheta}
                                    selected={cardSelected === statusDocumento.AGUARDANDO_ASSINATURAS}
                                    onSelect={() => this.handleSelectCard(statusDocumento.AGUARDANDO_ASSINATURAS)}
                                />
                            </Col>
                            <Col xs="12" sm="6" md="6" lg="3" xl="3">
                                <CardTotalizadorListagem
                                    name="cardAssinados"
                                    title="Assinados"
                                    helpMessage={!isMobile ? "Exibe a quantidade de documentos assinados no período" : ""}
                                    titleFiltro="Clique para filtrar pelos documentos assinados"
                                    colors={ColorsCard.ASSINADO}
                                    value={cards.assinados}
                                    icon={assinatura_efetuada}
                                    selected={cardSelected === statusDocumento.ASSINADO}
                                    onSelect={() => this.handleSelectCard(statusDocumento.ASSINADO)}
                                />
                            </Col>
                            <Col xs="12" sm="6" md="6" lg="3" xl="3">
                                <CardTotalizadorListagem
                                    name="cardRejeitados"
                                    title="Rejeitados"
                                    helpMessage={!isMobile ? "Exibe a quantidade de documentos rejeitados no período" : ""}
                                    titleFiltro="Clique para filtrar pelos documentos rejeitados"
                                    colors={ColorsCard.REJEITADO}
                                    value={cards.rejeitados}
                                    icon={assinatura_rejeitada}
                                    selected={cardSelected === statusDocumento.REJEITADO}
                                    onSelect={() => this.handleSelectCard(statusDocumento.REJEITADO)}
                                />
                            </Col>
                            <Col xs="12" sm="6" md="6" lg="3" xl="3">
                                <CardTotalizadorListagem
                                    name="cardExpirados"
                                    title="Expirados"
                                    helpMessage={!isMobile ? "Exibe a quantidade de documentos expirados no período" : ""}
                                    titleFiltro="Clique para filtrar pelos documentos expirados"
                                    colors={ColorsCard.EXPIRADO}
                                    value={cards.expirados}
                                    icon={documento_expirado}
                                    selected={cardSelected === statusDocumento.EXPIRADO}
                                    onSelect={() => this.handleSelectCard(statusDocumento.EXPIRADO)}
                                />
                            </Col>
                        </Grid>
                        <Grid style={{ paddingTop: '10px' }} justifyCenter verticalAlignCenter>
                            <DescricaoFiltroAvancado texto={descricaoFiltroAvancado} />
                            <DataTable
                                className="table-light"
                                responsive
                                value={registros}
                                sortField={sortField}
                                sortOrder={sortOrder}
                                onSort={this.onSort}
                                selection={documentosSelecionados}
                                header={this.getHeader()}
                                onSelectionChange={e => this.setState({ documentosSelecionados: e.value })}
                                emptyMessage={<NenhumRegistroEncontrado />}
                            >
                                <Column
                                    header={isMobile ? "Seleção" : ""}
                                    selectionMode="multiple"
                                    style={{ width: '45px' }}
                                />
                                <Column
                                    field="identificacao"
                                    header={isMobile ? "Identific." : "Identificação"}
                                    sortable={true}
                                    body={row => aplicarEstiloArquivado(row.arquivado, row.identificacao)}
                                    style={{ overflow: "hidden" }}
                                />
                                <Column
                                    field="pessoaNome"
                                    body={row => aplicarEstiloArquivado(row.arquivado, row.pessoaNome)}
                                    header="Pessoa"
                                    sortable={true}
                                    style={{ overflow: "hidden" }}
                                />
                                <Column
                                    field="criadoEm"
                                    header="Data de criação"
                                    sortable={true}
                                    body={row => renderDataCriacao(row)}
                                />
                                <Column
                                    field="assinados"
                                    header={isMobile ? "Ass." : "Assinaturas"}
                                    body={row => this.renderAssinados(row)}
                                    sortable={false}
                                />
                                <Column
                                    field="status"
                                    header="Status"
                                    style={{ width: '220px' }}
                                    body={row => renderStatus(row)}
                                    sortable={true}
                                />
                                <Column
                                    className="step-listagem-acoes"
                                    body={this.renderOpcoes}
                                    header="Ações"
                                    style={{ width: '100px', ...acoesStyle }}
                                />
                            </DataTable>
                            <Paginator
                                className='paginator-light'
                                rowsPerPageOptions={[10, 25, 50]}
                                totalRecords={totalElements}
                                rows={rows}
                                first={page * rows}
                                onPageChange={this.onPageChange}
                                rightContent={<div style={{ margin: '5px' }}>{totalElements > 0 ? `${totalElements} registro(s) encontrado(s)` : null}</div>}
                            />
                        </Grid>
                        <If test={documentoSelecionado && mostrarModalVerDetalhesDocumento}>
                            <ModalVerDetalhesDocumento
                                visible={mostrarModalVerDetalhesDocumento}
                                onHide={() => this.setState({ mostrarModalVerDetalhesDocumento: false })}
                                documento={documentoSelecionado}
                                isMobile={isMobile}
                                onClickEnviarEmailSignatarioEspecifico={signatario => this.setState({ modalEnviarDocumentoVisible: true, signatarioEspecifico: signatario })}
                            />
                        </If>
                        <If test={documentoSelecionado && modalEnviarDocumentoVisible}>
                            <ModalEnviarPorEmail
                                modalReenviarEmail={true}
                                visible={modalEnviarDocumentoVisible}
                                onHide={() => this.setState({ modalEnviarDocumentoVisible: false })}
                                idDocumento={documentoSelecionado && documentoSelecionado.id}
                                arquivo={documentoSelecionado && documentoSelecionado.arquivo}
                                signatarioEspecifico={this.state.signatarioEspecifico}
                                onDocumentoEnviado={() => this.setState({ signatarioEspecifico: null, modalEnviarDocumentoVisible: false })}
                                emailsSignatarios={this.state.signatarioEspecifico && [this.state.signatarioEspecifico.email]}
                            />
                        </If>
                    </FormContent>
                </Form>
                <If test={registroSelecionado && registroSelecionado.id}>
                    <DocumentoHistorico
                        idDocumento={registroSelecionado && registroSelecionado.id}
                        visible={modalDocumentoHistoricoVisible}
                        onHide={() => this.setState({ modalDocumentoHistoricoVisible: false, registroSelecionado: null })}
                    />
                </If>
            </>
        )
    }
}

const mapStateToProps = state => ({
    isMobile: state.dispositivo.isMobile,
})

export default connect(mapStateToProps)(AssinaturaEletronica);
