import React, { Component } from 'react';
import { ProgressBar } from 'primereact/progressbar';
import { BsGraphUp } from 'react-icons/bs';
import { formatarMonetario } from '../../../../../../../../../common/mascara';
import FalhaAoCarregarAsInformacoes from '../../../../../FalhaAoCarregarAsInformacoes';
import { Financas } from '../../../../../../../../util/constantes'

import '../styles/index.css';

const messages = {
    helpMessage: "Valor total de suas receitas cadastradas no período",
}

export default class CardReceitas extends Component {

    montarTituloCard() {
        return (
            <div className="card-resultado-financeiro-dashboard-titulo">
                <div className="card-resultado-financeiro-dashboard-titulo-descricao" title={messages.helpMessage}>
                    Receitas no período
                </div>
            </div>
        );
    }

    render() {
        if (this.props.loading) {
            return (
                <div className="card-resultado-financeiro-dashboard">
                    {this.montarTituloCard()}
                    <div className="card-resultado-financeiro-dashboard-content">
                        <ProgressBar mode="indeterminate" style={{ height: '3px', width: '100%', margin: '10px 0px' }} />
                    </div>
                </div>
            )
        } else if (this.props.error) {
            return (
                <div className="card-resultado-financeiro-dashboard">
                    {this.montarTituloCard()}
                    <div className="card-resultado-financeiro-dashboard-content">
                        <FalhaAoCarregarAsInformacoes />
                    </div>
                </div>
            )
        } else {
            const { receitasTotal, receitasRecebidas, receitasAReceber } = this.props;
            return (
                <div className="card-resultado-financeiro-dashboard">
                    {this.montarTituloCard()}
                    <div className="card-resultado-financeiro-dashboard-content">
                        <div className="card-resultado-financeiro-dashboard-icone">
                            <BsGraphUp size={40} color={Financas.cores.verde} />
                        </div>
                        <div className="card-resultado-financeiro-dashboard-valor">
                            <div className="card-resultado-financeiro-dashboard-valor-principal" title="Valor total a receber (Recebidos + A receber)" style={{ color: Financas.cores.verde }}>
                                {formatarMonetario(receitasTotal)}
                            </div>
                            <div className="card-resultado-financeiro-dashboard-valor-secundario">
                                <span className="card-resultado-financeiro-dashboard-valor-secundario-efetuado" title="Valor já recebido do montante total">
                                    <label>Recebidas</label>
                                    <div>
                                        {formatarMonetario(receitasRecebidas)}
                                    </div>
                                </span>
                                <span className="card-resultado-financeiro-dashboard-valor-secundario-pendente" title="Valor que falta receber do montante total">
                                    <label>A receber</label>
                                    <div>
                                        {formatarMonetario(receitasAReceber)}
                                    </div>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    }
}
