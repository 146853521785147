import React, { Component } from 'react'
import ButtonNovo from '../../../components/Button/ButtonNovo'
import { services } from '../../../common/constantes/api';
import { recursos, permissoes } from '../../../common/constantes/autorizacao';
import Grid from '../../../components/Grid';
import autoBind from 'react-autobind';
import { construirUrl } from '../../../common/rsql';
import { asyncGetPesquisaPapeis, asyncDeletePapel } from './requests'
import { atualizarUrl } from '../../util';
import { confirmarExclusao } from '../../util/exclusaoDeRegistros';
import { removerElemento } from '../../../common/array';
import ButtonEditarTable from '../../../components/Button/ButtonEditarTable';
import ButtonExcluirTable from '../../../components/Button/ButtonExcluirTable';
import InputSearch from '../../../components/Input/InputSearch';
import { Column } from 'primereact/column';
import { usuarioPossuiPermissao } from '../../../common/autenticacao';
import { configuracoesUsuario, buscarConfiguracaoUsuario, salvarConfiguracaoUsuario } from '../../../common/configuracaoUsuario';
import Tutorial from '../../../components/Tutorial';
import { tutorialSteps } from '../../../components/Tutorial/steps/listagens';
import Form from '../../../components/Form';
import FormActions from '../../../components/FormActions';
import FormContent from '../../../components/FormContent';
import { DataTable } from 'primereact/components/datatable/DataTable';
import NenhumRegistroEncontrado from '../../../components/NenhumRegistroEncontrado';
import { Paginator } from 'primereact/paginator';

class Papeis extends Component {

    constructor(props) {
        super(props);
        autoBind(this)
        this.state = {
            podeInserir: usuarioPossuiPermissao(recursos.PAPEIS, permissoes.INSERIR),
            podeExcluir: usuarioPossuiPermissao(recursos.PAPEIS, permissoes.EXCLUIR),
            deveExibirTutorial: buscarConfiguracaoUsuario(configuracoesUsuario.EXIBIR_TUTORIAL_LISTAGENS),
            descricaoFiltroAvancado: '',
            valorPesquisa: '',
            registros: [],
            sortField: 'nome',
            sortOrder: 1,
            page: 0,
            rows: 10,
            totalElements: 0,
            tutorialVisible: false
        }
    }

    componentDidMount() {
        this.pesquisar()

        if (this.state.deveExibirTutorial !== false) {
            this.setState({ tutorialVisible: true })
            salvarConfiguracaoUsuario(configuracoesUsuario.EXIBIR_TUTORIAL_LISTAGENS, false, null, false)
        }
    }

    pesquisar() {
        const { page, sortField, sortOrder } = this.state
        const filtro = `?query=nome=contains="*${this.state.valorPesquisa}*"`
        const url = construirUrl(`${services.GESTOR}/v1/papeis_permissoes/resumo`, filtro, 10, page, sortOrder > 0 ? `${sortField},asc` : `${sortField},desc`)

        asyncGetPesquisaPapeis(url, ({ data: papeis }) => {
            this.setState({
                registros: papeis.content,
                totalElements: papeis.totalElements
            })
        })
    }

    onPesquisar() {
        this.setState({ page: 0 }, () => {
            this.pesquisar();
        })
    }

    onPageChange(e) {
        this.setState({ page: e.page, rows: e.rows }, () => {
            this.pesquisar()
        })
    }

    onSort(e) {
        this.setState({ sortField: e.sortField, sortOrder: e.sortOrder }, () => {
            this.pesquisar()
        })
    }

    onEditar(row) {
        atualizarUrl(this.props.history, '/papeis/cadastro', row.id)
    }

    onExcluir(row) {
        confirmarExclusao(() => this.asyncExcluirRegistro(row))
    }

    async asyncExcluirRegistro(registro) {
        await asyncDeletePapel(registro.id, () => {
            this.setState({
                registros: removerElemento(this.state.registros, registro),
                totalElements: this.state.totalElements - 1
            })
        })
    }

    renderOpcoes(row) {
        return (
            <>
                <ButtonEditarTable
                    onClick={() => this.onEditar(row)}
                />
                <ButtonExcluirTable
                    onClick={() => this.onExcluir(row)}
                    podeExcluir={this.state.podeExcluir && row.administrador === false}
                />
            </>
        )
    }

    render() {
        const {
            page,
            totalElements,
            podeInserir,
            sortField,
            sortOrder,
            registros,
            valorPesquisa,
            rows } = this.state

        return (
            <>
                <Tutorial
                    steps={tutorialSteps}
                    showSkipButton
                    continuous
                    visible={this.state.tutorialVisible}
                    onHide={() => this.setState({ tutorialVisible: false })}
                />
                <Form header="Grupos de usuários e permissões">
                    <FormActions>
                        <ButtonNovo
                            label='Novo grupo'
                            className="step-listagem-novo"
                            onClick={() => { this.props.history.push('/papeis/cadastro') }}
                            podeInserir={podeInserir}
                        />
                    </FormActions>
                    <FormContent>
                        <Grid justifyCenter>
                            <InputSearch
                                className="step-listagem-input-search"
                                onPesquisar={this.onPesquisar}
                                value={valorPesquisa}
                                onChange={value => this.setState({ valorPesquisa: value })}
                            />
                        </Grid>
                        <DataTable
                            className="table-light"
                            responsive
                            value={registros}
                            sortField={sortField}
                            sortOrder={sortOrder}
                            onSort={this.onSort}
                            emptyMessage={<NenhumRegistroEncontrado />}
                        >
                            <Column
                                className="step-listagem-order"
                                field="nome"
                                header="Nome"
                                sortable={true}
                            />
                            <Column
                                className="step-listagem-acoes"
                                body={this.renderOpcoes}
                                header="Ações"
                                style={{ width: '7em' }}
                            />
                        </DataTable>
                        <Paginator
                            className='paginator-light'
                            rowsPerPageOptions={[10, 25, 50]}
                            totalRecords={totalElements}
                            rows={rows}
                            first={page * rows}
                            onPageChange={this.onPageChange}
                            rightContent={<div style={{ margin: '5px' }}>{totalElements > 0 ? `${totalElements} registro(s) encontrado(s)` : null}</div>}
                        />
                    </FormContent>
                </Form>
            </>
        )
    }
}

export default Papeis
