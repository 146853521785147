import React, { Component } from 'react';
import { Card } from 'primereact/card';
import autoBind from 'react-autobind';
import { buscarDocumentosComExpiracao } from './requests';
import { ProgressBar } from 'primereact/progressbar';
import { format, parse } from 'date-fns';
import { usuarioPossuiPermissao } from '../../../../../../../common/autenticacao';
import { recursos, permissoes } from '../../../../../../../common/constantes/autorizacao';
import NenhumaInformacaoEncontrada from '../../../NenhumaInformacaoEncontrada';

import './styles/index.css';
import FalhaAoCarregarAsInformacoes from '../../../FalhaAoCarregarAsInformacoes';
import { Link } from 'react-router-dom';
import { Documentos } from '../../../../../../util/constantes'

const Divider = () => {
    return <hr id="divider-documentos-expiracao-periodo" />
}

const List = props => {
    return (
        <div id="documentos-periodo" className='dashboard-scroll'>
            <ul style={{ listStyle: "none", padding: '0px 3px', margin: '0px' }}>
                {props.children}
            </ul>
        </div>
    );
}

const ListItem = props => {

    let titleDocumento = "Ir para o documento " + (props.identificacao ? props.identificacao : "");

    return (
        <li style={{ margin: '6px 0px' }}>
            <div className="p-grid" style={{ margin: '0px 6px' }}>
                <Link to={{ pathname: "/documentos/cadastro/" + props.docId }}   >
                    <div style={{ display: 'flex', alignItems: 'center' }} className="link_to">
                        <span id="documento-identificacao-title" title={titleDocumento} style={{ color: `${Documentos.cores.azul}` }}>
                            {props && props.identificacao ? props.identificacao : "Nenhuma descrição informada"}
                        </span>
                    </div>
                </Link>
            </div>
            <div className="p-grid p-align-center" style={{ margin: '0px' }}>
                <div className="p-col-4" style={{ padding: '0px 6px' }}>
                    <p>
                        {`Expiração ${format(parse(props.expiracao, 'yyyy-MM-dd', new Date()), 'dd/MM/yyyy')}`}
                    </p>
                </div>
                <div className="p-col-4" style={{ padding: '0px 6px' }}>
                    <span id="documento-pessoa">
                        {props && props.nomePessoa ? props.nomePessoa : 'Nenhuma pessoa informada'}
                    </span>
                </div>
                <div className="p-col-4" id="documento-quantidade-assinaturas">
                    {`Assinaturas ${props.assinaturasEfetuadas} de ${props.quantidadeSignatarios}`}
                </div>
            </div>
        </li>
    );
}

export default class DocumentosExpiracaoPeriodo extends Component {

    constructor(props) {
        super(props);

        autoBind(this);

        this.state = {
            loading: true,
            error: false,
            documentosComExpiracao: [],
            podeVisualizarDocumentos: usuarioPossuiPermissao(recursos.DOCUMENTOS, permissoes.VISUALIZAR)
        }
    }

    componentDidMount() {
        this.carregarInformacoes();
    }

    componentDidUpdate(prevProps) {
        const { dataInicial, dataFinal } = this.props;

        if (prevProps.dataInicial !== dataInicial || prevProps.dataFinal !== dataFinal) {
            this.carregarInformacoes();
        }
    }

    carregarInformacoes(exibirLoading = true) {
        const { dataInicial, dataFinal } = this.props;

        this.setState({ loading: exibirLoading, error: false }, () => {
            buscarDocumentosComExpiracao(dataInicial, dataFinal, ({ data: documentosComExpiracao }) => {
                this.setState({ documentosComExpiracao, loading: false, error: false });
            }, () => {
                this.setState({ error: true, loading: false });
            });
        })

    }

    getHeaderCard() {
        return (
            <div id="card-header-documentos-expiracao-periodo">
                <label
                    title="Lista os documentos que estão aguardando assinatura e que possuem expiração no período"
                >
                    Documentos com expiração no período
                </label>
            </div>
        );
    }

    render() {
        if (this.state.loading) {
            return (
                <Card header={this.getHeaderCard()} style={{ borderRadius: '5px', height: '100%' }}>
                    <ProgressBar mode="indeterminate" style={{ height: '3px' }} />
                </Card>
            )
        } else if (this.state.error) {
            return (
                <Card header={this.getHeaderCard()} style={{ borderRadius: '5px', height: '100%' }}>
                    <FalhaAoCarregarAsInformacoes />
                </Card>
            )
        } else {
            const { documentosComExpiracao } = this.state;
            return (
                <>
                    <Card header={this.getHeaderCard()} style={{ borderRadius: '5px', height: '100%' }}>
                        {documentosComExpiracao.length > 0 ? (
                            <>
                                <List>
                                    {documentosComExpiracao.map((documento, idx, array) => {
                                        return (
                                            <React.Fragment key={idx}>
                                                <ListItem
                                                    docId={documento.docId}
                                                    expiracao={documento.dataExpiracao}
                                                    identificacao={documento.identificacao}
                                                    nomePessoa={documento.nomePessoa}
                                                    assinaturasEfetuadas={documento.assinaturasEfetuadas}
                                                    quantidadeSignatarios={documento.quantidadeSignatarios}
                                                />
                                                {++idx !== array.length ? <Divider /> : null}
                                            </React.Fragment>
                                        )
                                    })}
                                </List>
                            </>
                        ) : <NenhumaInformacaoEncontrada />}
                    </Card>
                </>
            )
        }
    }
}
