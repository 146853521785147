import React, { Component } from "react";
import Button from "../";
import propTypes from "prop-types";

export const estadosBotaoNovo = {
    NOVO: "NOVO",
    SALVAR_E_NOVO: "SALVAR_E_NOVO"
};

class ButtonNovo extends Component {
    getDisabled() {
        if (this.props.podeInserir === false) return true;

        return this.props.disabled;
    }

    getTitle() {
        if (this.props.podeInserir === false){
            return "Você não possui permissão para executar essa ação";
        }else if(this.props.estadoBotao === estadosBotaoNovo.SALVAR_E_NOVO){
            return "Salvar e inserir novo registro"
        }
        
        return this.props.title;
    }

    render() {
        const {
            id,
            className,
            style,
            hidden,
            onClick,
            enableShortCut,
            label,
            rounded,
            raised,
            icon,
            estadoBotao,
            size
        } = this.props;

        const labelFormatado = estadoBotao === estadosBotaoNovo.SALVAR_E_NOVO ? "Salvar e novo" : label;

        return (
            <Button
                id={id}
                className={className}
                type="button"
                style={{ ...style, margin: "5px" }}
                hidden={hidden}
                onClick={onClick}
                disabled={this.getDisabled()}
                title={this.getTitle()}
                color="primary"
                iconPos="left"
                icon={icon ? icon : "fa fa-plus"}
                raised={raised}
                rounded={rounded}
                label={labelFormatado}
                size={size}
                hotKey={["alt", "n"]}
                enableShortCut={enableShortCut}
            />
        );
    }
}

ButtonNovo.defaultProps = {
    hidden: false,
    disabled: false,
    label: "Novo",
    title: "Inserir um novo registro"
};

ButtonNovo.propTypes = {
    /** Identificador do registro */
    id: propTypes.string,
    /** classificações do documento */
    className: propTypes.string,
    /** Define o botão como invisível */
    hidden: propTypes.bool,
    /** Ação executada ao clicar no botão */
    onClick: propTypes.func,
    /** Define o botão como desabilitaedo */
    disabled: propTypes.bool,
    /** Habilitar atalho */
    enableShortCut: propTypes.bool,
    /** Diz se o usuário possui permissão de inserir*/
    podeInserir: propTypes.bool,
    /** Title do componente*/
    title: propTypes.string,
    /** Estilo do componente */
    style: propTypes.object,
    /** Label do componente */
    label: propTypes.string
};

export default ButtonNovo;
