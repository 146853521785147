import React from 'react';
import Select, { components } from 'react-select';
import { IoIosArrowUp, IoIosArrowDown } from 'react-icons/io';
import { Status } from '../../../util/constantes';

import { customStyles } from './styles';

function DropdownIndicator(props) {
    const styleDropDown = { opacity: props.podeEditar ? '1' : '0.5' };
    return (
        <components.DropdownIndicator {...props}>
            {props.selectProps.menuIsOpen ? <IoIosArrowUp style={styleDropDown} /> : <IoIosArrowDown style={styleDropDown} />}
        </components.DropdownIndicator>
    );
};

function getValue({ value, options }) {
    for (let i = 0; i < options.length; i++) {
        const opcao = options[i];
        if (opcao.value === value) {
            return opcao;
        }
    }
    return value;
};

function getOptions({ idRegistro, options }) {
    if (idRegistro) {
        return options;
    } else {
        return options.filter(option => option.value !== Status.CANCELADA)
    }
};

function StatusVenda(props) {
    return (
        <Select
            styles={customStyles}
            options={getOptions(props)}
            onChange={props.onChange}
            value={getValue(props)}
            isDisabled={(props.podeEditar === false && props.idRegistro) || props.existeParcelaRecebida}
            components={{ DropdownIndicator: props.existeParcelaRecebida ? null : DropdownIndicator, IndicatorSeparator: null }}
            placeholder=""
            isSearchable={false}
        />
    )
};

export default StatusVenda;
