import axios from 'axios'
import { store } from '../../index'
import { solicitarLoginDoUsuario } from '../autenticacao'
import { tratarErroRequisicoes } from '../tratamentoDeErro/tratamentoDeMensagens'
import { notify, ToastTypes } from '../../components/Toast'
import { dispatchAction } from '../redux'
import { actionTypes } from '../constantes/reduxTypes'

export async function get(url, config, onSuccess, onError, exibirLoading = true, tratarErros = true) {
    dispatchRequest(exibirLoading)
    return await axios.get(url, config)
        .then(e => resolveSuccess(e, onSuccess, exibirLoading))
        .catch(e => {
            if (tokenExpirou(e.response)) {
                solicitarLoginDoUsuario(() => { get(url, config, onSuccess, onError, exibirLoading) })
                store.dispatch(asyncDispatchError)
            } else {
                resolveError(e, onError, exibirLoading, tratarErros, config)
            }
        })
}

export async function post(url, payload, config, onSuccess, onError, exibirLoading = true, tratarErros = true) {
    dispatchRequest(exibirLoading)
    return await axios.post(url, payload, config)
        .then(e => resolveSuccess(e, onSuccess, exibirLoading))
        .catch(e => resolveError(e, onError, exibirLoading, tratarErros, config))
}

export async function put(url, payload, config, onSuccess, onError, exibirLoading = true, tratarErros = true) {
    dispatchRequest(exibirLoading)
    return await axios.put(url, payload, config)
        .then(e => resolveSuccess(e, onSuccess, exibirLoading))
        .catch(e => resolveError(e, onError, exibirLoading, tratarErros, config))
}

export async function patch(url, payload, config, onSuccess, onError, exibirLoading = true, tratarErros = true) {

    dispatchRequest(exibirLoading)
    return await axios.patch(url, payload, config)
        .then(e => resolveSuccess(e, onSuccess, exibirLoading))
        .catch(e => resolveError(e, onError, exibirLoading, tratarErros, config))
}

export async function del(url, config, onSuccess, onError, exibirLoading = true, tratarErros = true) {
    dispatchRequest(exibirLoading)
    return await axios.delete(url, config)
        .then(e => resolveSuccess(e, onSuccess, exibirLoading))
        .catch(e => resolveError(e, onError, exibirLoading, tratarErros, config))
}

export function exibirToast(callback, message, toastType = ToastTypes.SUCCESS, wait = 5) {
    return (e) => {
        notify(message, toastType, wait)
        callback && callback(e)
    }
}


function tokenExpirou(response) {
    return response && response.data && response.data.error === 'invalid_token'
}

const asyncDispatchRequest = async function (dispatch) {
    dispatch({ type: 'EXIBIR_LOADING' })
}

const asyncDispatchSuccess = async function (dispatch) {
    dispatch({ type: 'OCULTAR_LOADING' })
}

const asyncDispatchError = async function (dispatch) {
    dispatch({ type: 'OCULTAR_LOADING' })
}

function dispatchRequest(exibirLodaing) {
    if (exibirLodaing)
        store.dispatch(asyncDispatchRequest)
}

function resolveSuccess(e, onSuccess, exibirLodaing) {
    dispatchAction(actionTypes.POSSUI_INTERNET, true)
    if (exibirLodaing)
        store.dispatch(asyncDispatchSuccess)
    onSuccess && onSuccess(e)
}

function resolveError(e, onError, exibirLodaing, tratarErros, config) {
    if (exibirLodaing) {
        store.dispatch(asyncDispatchError)
    }
    if (tratarErros) {
        if (config && config.responseType && config.responseType === "arraybuffer") {
            const response = JSON.parse(String.fromCharCode.apply(null, new Uint8Array(e.response.data)))
            tratarErroRequisicoes({ data: response, status: response.statusCode })
        } else {
            tratarErroRequisicoes(e.response)
        }
    }
    onError && onError(e)
}
