import React, { Component } from 'react';
import { Card } from 'primereact/card';
import Col from '../../../../../components/Col';
import Grid from '../../../../../components/Grid';
import { Button } from 'primereact/button';
import { formatarMonetario } from '../../../../../common/mascara';
import { Menu } from 'primereact/menu';
import autoBind from 'react-autobind';
import { usuarioPossuiPermissao } from '../../../../../common/autenticacao';
import { recursos, permissoes } from '../../../../../common/constantes/autorizacao';
import { GoBookmark } from 'react-icons/go'
import If from '../../../../../components/If';
import './style/index.css'
import { Financas } from '../../../../util/constantes'

const tipoConta = {
    DINHEIRO: 'DINHEIRO',
    CORRENTE: 'CORRENTE',
    POUPANCA: 'POUPANCA',
    INVESTIMENTO: 'INVESTIMENTO',
    OUTRO: 'OUTRO',
};

const styleNomeConta = {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    padding: '0px 0px 0px 6px',
    fontSize: '20px',
    color: '#333333',
    fontWeight: 'bold'
};

const styleSubtitleConta = {
    fontSize: '13px',
    color: '#333333a6',
    fontWeight: 'bold',
    padding: '3px 0px 0px 6px'
};

const styleSaldoAtualPositivo = {
    fontSize: '23px',
    color: Financas.cores.verde,
    fontWeight: 'bold',
};

const styleSaldoAtualNegativo = {
    fontSize: '23px',
    color: Financas.cores.vermelho,
    fontWeight: 'bold',
};

const styleContaFavorita = {
    marginLeft: '-5px'
}

const styleBotaoOpcoesCard = {
    padding: '0px',
    display: 'flex',
    justifyContent: 'flex-end'
};

const styleContaInativa = {
    backgroundImage: 'repeating-linear-gradient(40deg, #0000001a, transparent 2px)'
};

const styleTextContaInativa = {
    backgroundColor: Financas.cores.vermelho,
    fontWeight: 'bold',
    border: `1px solid ${Financas.cores.vermelho}`,
    borderRadius: '8%',
    width: 'auto',
    height: '51px',
    fontSize: '10px',
    color: '#fff',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '4px'
};

class CardConta extends Component {

    constructor(props) {

        super(props);

        autoBind(this);

        this.state = {
            podeExcluir: usuarioPossuiPermissao(recursos.FINANCAS_CONTAS, permissoes.EXCLUIR),
            podeEditar: usuarioPossuiPermissao(recursos.FINANCAS_CONTAS, permissoes.EDITAR),
        }
    }

    montarItensMenu() {
        const { conta } = this.props;
        const { podeEditar, podeExcluir } = this.state
        const { habilitarVerDetalhes } = this.props

        let itens = [];


        if (podeExcluir) {
            itens.push({ label: 'Excluir conta', icon: 'fa fa-trash', command: () => this.props.excluirConta(conta) });
        }

        if (conta.ativa) {
            if (podeEditar) {
                itens.push({ label: 'Inativar conta', icon: 'fa fa-ban', command: () => this.props.desativarConta(conta) });
            }
        } else {
            if (podeEditar) {
                itens.push({ label: 'Ativar conta', icon: 'fa fa-undo', command: () => this.props.ativarConta(conta) });
            }
        }
        if (habilitarVerDetalhes) {
            itens.push({ label: 'Ver movimentações', icon: 'fa fa-usd', command: () => this.props.verDetalhesClick(conta) });

        }
        return itens;
    };

    renderizarSaldoConta() {
        const { habilitarVerDetalhes, verDetalhesClick, conta } = this.props
        const { ativa, saldoAtual } = conta

        const saldoPositivo = Number(saldoAtual) >= 0;
        return (
            <Grid verticalAlignCenter>
                <Col col='9' style={!ativa ? { opacity: '0.70' } : null}>
                    <div>Saldo atual</div>
                    <div
                        className={habilitarVerDetalhes ? 'card-conta-saldo-atual' : null}
                        onClick={habilitarVerDetalhes ? () => verDetalhesClick(conta) : null}
                        style={saldoPositivo ? styleSaldoAtualPositivo : styleSaldoAtualNegativo}
                        title={habilitarVerDetalhes ? 'Ver movimentações' : null}
                    >
                        {formatarMonetario(saldoAtual)}
                    </div>
                </Col>
                {!ativa ? (
                    <Col col='3' style={{ textAlign: 'center', justifyContent: 'flex-end', display: 'flex' }}>
                        <div style={styleTextContaInativa}>
                            CONTA INATIVA
                        </div>
                    </Col>
                ) : null}
            </Grid>
        );
    };

    renderizarTipoConta(tipo) {
        if (tipo === tipoConta.POUPANCA) {
            return "POUPANÇA";
        }
        return tipo;
    }

    render() {
        const { favoritaReceita, favoritaDespesa, ativa, nome, tipo } = this.props.conta
        const { podeEditar, podeExcluir } = this.state
        const mensagemContaDespesa = "Conta favorita para despesas";
        const mensagemContaReceita = "Conta favorita para receitas";

        return (
            <Col col='12' sm='12' md='4' lg='4' xl='4'>
                <Menu model={this.montarItensMenu()} popup={true} ref={el => this.menu = el} id="popup_menu" />
                <Card
                    style={!ativa ? styleContaInativa : { borderRadius: '5px' }}
                    footer={this.renderizarSaldoConta()}
                >
                    <Grid style={!ativa ? { opacity: '0.70' } : null}>
                        <Col col='8' lg='9' style={styleNomeConta}>
                            <If test={favoritaReceita}>
                                <GoBookmark title={mensagemContaReceita} color={Financas.cores.verde} size={22} style={styleContaFavorita} />
                            </If>
                            <If test={favoritaDespesa}>
                                <GoBookmark title={mensagemContaDespesa} color={Financas.cores.vermelho} size={22} style={styleContaFavorita} />
                            </If>
                            <span title={nome}>{nome}</span>
                        </Col>
                        <Col col='4' lg='3' style={styleBotaoOpcoesCard}>
                            <If test={podeEditar}>
                                <Button
                                    title="Editar conta"
                                    className="p-button-primary"
                                    style={{ marginRight: '5px', borderRadius: '20px' }}
                                    icon="fa fa-pencil"
                                    onClick={() => this.props.editarConta(this.props.conta)}
                                />
                            </If>
                            <If test={podeEditar || podeExcluir}>
                                <Button
                                    title="Opções"
                                    className="p-button-secondary"
                                    icon="fa fa-ellipsis-v"
                                    style={{ borderRadius: '20px' }}
                                    aria-controls="popup_menu" aria-haspopup={true}
                                    onClick={(event) => this.menu.toggle(event)}
                                />
                            </If>
                        </Col>
                    </Grid>
                    <Grid justifyEnd>
                        <Col col='12' style={styleSubtitleConta}>
                            {this.renderizarTipoConta(tipo)}
                        </Col>
                    </Grid>
                </Card>
            </Col>
        )
    }
}
export default CardConta;
