import React, { Component } from 'react';
import PessoasForm from '../../../../views/cadastros/Pessoas/Form';
import Modal from '../../../../components/Modal/index';
import If from '../../../../components/If'


class ModalPessoas extends Component {

    render() {
        return (
            <Modal
                className={this.props.visible ? "modalDeUmAsyncSelect" : undefined}
                header='Cadastro de pessoa'
                visible={this.props.visible}
                onHide={this.props.onHide}
                styleModal={{ maxWidth: '1200px' }}
                >
                <If test={this.props.visible}>
                    <PessoasForm
                        validarIntermediador={this.props.validarIntermediador}
                        hideModal={this.props.onHide}
                        isModal={true}
                        idPessoa={this.props.idPessoa}
                        tabSelecionada={this.props.tabSelecionada}
                        tipo={this.props.tipo}
                    />
                </If>
            </Modal>
        )
    }
}

export default ModalPessoas