import React, { Component } from 'react'
import Modal from '../../../../../components/Modal';
import Grid from '../../../../../components/Grid';
import autoBind from 'react-autobind';
import Col from '../../../../../components/Col';
import { Button } from 'primereact/button';
import { RadioButton } from 'primereact/radiobutton'
import AutoProgressBar from '../../../../../components/Loading/AutoProgressBar';

export const tiposExclusoesContasPagar = {
    APENAS_SELECIONADA: 'APENAS_SELECIONADA',
    PROXIMAS_EM_ABERTO: 'PROXIMAS_EM_ABERTO',
    TODAS_EM_ABERTO: 'TODAS_EM_ABERTO'
}


export default class ModalExclusaoContasPagar extends Component {

    constructor(props) {
        super(props)
        autoBind(this);

        this.state = {
            value: tiposExclusoesContasPagar.APENAS_SELECIONADA,
        }
    }

    buscarDescricaoExcluir(value) {
        if (value === tiposExclusoesContasPagar.APENAS_SELECIONADA) {
            return 'Excluir apenas selecionada'
        } else if (value === tiposExclusoesContasPagar.PROXIMAS_EM_ABERTO) {
            return 'Excluir próximas em aberto'
        } else {
            return 'Excluir todas em aberto'
        }
    }

    render() {

        const { visible, onHide } = this.props
        const { value } = this.state

        return (
            <React.Fragment>
                <Modal
                    header='Excluir conta a pagar com repetições'
                    visible={visible}
                    onHide={onHide}
                >
                    <AutoProgressBar/>
                    <p style={{ textAlign: 'justify', marginLeft:'5px' }}>
                        Você está tentando excluir uma conta a pagar que faz parte de várias repetições. <br /> Quais dessas repetições você deseja excluir?
                    </p>
                    <Grid style={{ margin: '20px' }}>
                        <Col>
                            <RadioButton
                                inputId="rbExcluir1"
                                name="value"
                                value={tiposExclusoesContasPagar.APENAS_SELECIONADA}
                                onChange={(e) => this.setState({ value: e.value })}
                                checked={value === tiposExclusoesContasPagar.APENAS_SELECIONADA}
                            />
                            <label htmlFor="rbExcluir1" className="p-radiobutton-label">Excluir <b>apenas esta conta a pagar</b></label>
                        </Col>
                        <Col>
                            <RadioButton
                                inputId="rbExcluir2"
                                name="value"
                                value={tiposExclusoesContasPagar.PROXIMAS_EM_ABERTO}
                                onChange={(e) => this.setState({ value: e.value })}
                                checked={value === tiposExclusoesContasPagar.PROXIMAS_EM_ABERTO}
                            />
                            <label htmlFor="rbExcluir2" className="p-radiobutton-label">Excluir <b>esta conta a pagar e as próximas</b> repetições em aberto</label>
                        </Col>
                        <Col>
                            <RadioButton
                                inputId="rbExcluir3"
                                name="value"
                                value={tiposExclusoesContasPagar.TODAS_EM_ABERTO}
                                onChange={(e) => this.setState({ value: e.value })}
                                checked={value === tiposExclusoesContasPagar.TODAS_EM_ABERTO}
                            />
                            <label htmlFor="rbExcluir3" className="p-radiobutton-label">Excluir <b>esta conta a pagar e todas</b> as repetições em aberto</label>
                        </Col>
                    </Grid>
                    <Grid justifyEnd>
                        <div>
                            <Button
                                className='p-button-secondary'
                                style={{ margin: '5px' }}
                                label='Voltar'
                                onClick={() => onHide()}
                            />
                            <Button
                                style={{ margin: '5px' }}
                                className='p-button-danger'
                                label={this.buscarDescricaoExcluir(value)}
                                onClick={() => onHide(value)}
                            />
                        </div>
                    </Grid>
                </Modal>
            </React.Fragment>
        )
    }
}
