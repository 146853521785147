import { del, post, get, put, exibirToast } from '../../../../../common/requisicoes';
import { services } from '../../../../../common/constantes/api';

export async function asyncDeleteAdmPlano(planoId, onSuccess, onError) {
    await del(`${services.GESTOR}/v1/administracao/planos/${planoId}`, null, exibirToast(onSuccess, "Plano excluído com sucesso"), onError);
}

export async function asyncCreateAdmPlano(plano, onSuccess, onError) {
    await post(`${services.GESTOR}/v1/administracao/planos`, plano, null, exibirToast(onSuccess, "Plano criado com sucesso"), onError);
}

export async function asyncUpdateAdmPlano(plano, onSuccess, onError) {
    await put(`${services.GESTOR}/v1/administracao/planos/${plano.id}`, plano, null, exibirToast(onSuccess, "Plano atualizado com sucesso"), onError);
}

export async function asyncGetAdmPlano(planoId, onSuccess, onError) {
    await get(`${services.GESTOR}/v1/administracao/planos/${planoId}`, null, exibirToast(onSuccess), onError);
}