import React, { Component } from 'react'
import propTypes from 'prop-types'
import { TabView as InternalTabView } from 'primereact/tabview'
import { usuarioPossuiModulos } from '../../common/autenticacao';

class TabView extends Component {

    constructor(props) {
        super(props)

        this.state = {
            validouModulo: true
        };
    }

    componentDidMount() {
        if (this.props.modulosEspecificos)
            this.setState({ validouModulo:  usuarioPossuiModulos(this.props.modulosEspecificos) })
    }
    
    render() {
        if (!this.state.validouModulo)
            return null

        return (
            <InternalTabView
                {...this.props}
            >
                {this.props.children}
            </InternalTabView>
        )
    }
}

TabView.defaultProps = {
}

TabView.propTypes = {
    /** Identificador do componente */
    id: propTypes.string,
    /** Módulos que o usuario precisa ter acesso para renderizar o componente */
    modulosEspecificos: propTypes.any
}

export default TabView
