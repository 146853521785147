import { get } from '../../../../../../common/requisicoes';
import { services } from '../../../../../../common/constantes/api';


export async function asyncGetPessoa(idPessoa, onSuccess, onError) {
    return await get(`${services.GESTOR}/v1/documentos/relacoes/pessoas?query=id==${idPessoa}`, null, onSuccess, onError, false);
}


export async function asyncGetPessoaEmais(idPessoa, onSuccess, onError) {
    return await get(`${services.GESTOR}/v1/documentos/relacoes/pessoas/${idPessoa}/emails`, null, onSuccess, onError, false);
}
