import React, { Component } from 'react';
import Col from '../../../../../../../components/Col';
import autoBind from 'react-autobind'
import ProdutosMemorizados from './ProdutosMemorizados';
import { connect } from 'react-redux';
import { InternalInputDouble } from '../../../../../../../components/Input/InputDouble';
import Grid from '../../../../../../../components/Grid';
import { formatarMonetario } from '../../../../../../../common/mascara';
import { InternalDropdown } from '../../../../../../../components/Select/Dropdown';
import If from '../../../../../../../components/If';
import { Button } from 'primereact/button';

const valorProdutosDescricaoStyle = {
    width: '100%',
    textAlign: 'end',
    margin: '0px'
}

const valorProdutosValorStyle = {
    fontSize: '16px',
    color: '#006095'
}

const valorLiquidoDescricaoStyle = {
    width: '100%',
    textAlign: 'end',
    fontSize: '18px',
    fontWeight: 'normal',
    margin: '0px'
}

const buttonAdicionarStyle = {
    background: 'none',
    border: 'none',
    fontWeight: 'bold',
    boxShadow: 'none'
}

const valorLiquidoValorStyle = {
    fontSize: '24px',
    color: '#006095'
}

const tiposDesconto = {
    VALOR: "VALOR",
    PERCENTUAL: "PERCENTUAL",
    NAO_APLICADO: "NAO_APLICADO"
}

const optionsTiposDesconto = [
    { label: "R$", value: tiposDesconto.VALOR },
    { label: "%", value: tiposDesconto.PERCENTUAL }
]

class ProdutosVendas extends Component {
    constructor(props) {
        super(props);
        autoBind(this);
    }

    async onChangeProduto(value, index) {
        const { valorFrete, itens, tipoDesconto, valorDesconto, percentualDesconto, valorSeguro, valorDespesasAcessorias, totalIcmsSt, totalFcpSt } = this.props
        const novosItens = [...itens]
        let adicionarNovoItem = false
        if (value && value.value) {
            novosItens[index].produto = value
            novosItens[index].valor = value.registro.preco
            novosItens[index].subtotal = novosItens[index].valor * novosItens[index].quantidade
            if (index === (itens.length - 1)) {
                adicionarNovoItem = true
            }
        } else {
            novosItens[index].produto = null
            novosItens[index].valor = 0
            novosItens[index].subtotal = 0
        }
        const totalItens = novosItens.map(e => e.subtotal).reduce((a, b) => a + b, 0)
        const totalLiquido = this.calcularTotalLiquido(valorFrete, tipoDesconto, valorDesconto, percentualDesconto, totalItens, valorSeguro, valorDespesasAcessorias, totalIcmsSt, totalFcpSt)
        await this.props.onChangeTotalItens(totalItens)
        await this.props.onChangeTotalLiquido(totalLiquido)
        await this.props.onChangeItens(novosItens)
        if (adicionarNovoItem) {
            this.props.onAdicionarNovoProduto()
        }
    }

    async onChangeQuantidade(value, index) {
        const { valorFrete, itens, tipoDesconto, valorDesconto, percentualDesconto, valorSeguro, valorDespesasAcessorias, totalIcmsSt, totalFcpSt } = this.props
        const novosItens = [...itens]
        if (value) {
            if (value === novosItens[index].quantidade) {
                return
            }
            novosItens[index].quantidade = value
            novosItens[index].subtotal = novosItens[index].valor * novosItens[index].quantidade
        } else {
            if (!novosItens[index].quantidade) {
                return
            }
            novosItens[index].quantidade = value
            novosItens[index].subtotal = 0
        }
        this.props.onChangeItens(novosItens)
        if (novosItens[index].produto) {
            const totalItens = novosItens.map(e => e.subtotal).reduce((a, b) => a + b, 0)
            const totalLiquido = this.calcularTotalLiquido(valorFrete, tipoDesconto, valorDesconto, percentualDesconto, totalItens, valorSeguro, valorDespesasAcessorias, totalIcmsSt, totalFcpSt)
            await this.props.onChangeTotalItens(totalItens)
            await this.props.onChangeTotalLiquido(totalLiquido)
        }
    }

    onChangeDescricao(value, index) {
        const novosItens = [...this.props.itens]
        novosItens[index].descricao = value
        this.props.onChangeItens(novosItens)
    }

    onChangeValor(value, index) {
        const { valorFrete, itens, valorDesconto, percentualDesconto, tipoDesconto, valorSeguro, valorDespesasAcessorias, totalIcmsSt, totalFcpSt } = this.props
        const novosItens = [...itens]
        if (novosItens[index].valor === value) {
            return
        }
        novosItens[index].valor = value
        novosItens[index].subtotal = novosItens[index].valor * novosItens[index].quantidade

        const totalItens = novosItens.filter(e => e.produto).map(e => e.subtotal).reduce((a, b) => a + b, 0)
        const totalLiquido = this.calcularTotalLiquido(valorFrete, tipoDesconto, valorDesconto, percentualDesconto, totalItens, valorSeguro, valorDespesasAcessorias, totalIcmsSt, totalFcpSt)
        this.props.onChangeItens(novosItens)
        this.props.onChangeTotalItens(totalItens)
        this.props.onChangeTotalLiquido(totalLiquido)
    }

    onChangeValorFrete(value) {
        const { valorDesconto, percentualDesconto, tipoDesconto, totalItens, valorSeguro, valorDespesasAcessorias, totalIcmsSt, totalFcpSt } = this.props
        const totalLiquido = this.calcularTotalLiquido(value, tipoDesconto, valorDesconto, percentualDesconto, totalItens, valorSeguro, valorDespesasAcessorias, totalIcmsSt, totalFcpSt)
        this.props.onChangeValorFrete(value);
        this.props.onChangeTotalLiquido(totalLiquido);
    }


    onChangeValorSeguro(value) {
        const { valorFrete, valorDesconto, percentualDesconto, tipoDesconto, totalItens, valorDespesasAcessorias, totalIcmsSt, totalFcpSt } = this.props
        const totalLiquido = this.calcularTotalLiquido(valorFrete, tipoDesconto, valorDesconto, percentualDesconto, totalItens, value, valorDespesasAcessorias, totalIcmsSt, totalFcpSt)
        this.props.onChangeValorSeguro(value);
        this.props.onChangeTotalLiquido(totalLiquido);
    }

    onChangeValorDespesasAcessorias(value) {
        const { valorFrete, valorDesconto, percentualDesconto, tipoDesconto, totalItens, valorSeguro, totalIcmsSt, totalFcpSt } = this.props
        const totalLiquido = this.calcularTotalLiquido(valorFrete, tipoDesconto, valorDesconto, percentualDesconto, totalItens, valorSeguro, value, totalIcmsSt, totalFcpSt)
        this.props.onChangeValorDespesasAcessorias(value);
        this.props.onChangeTotalLiquido(totalLiquido);
    }

    calcularTotalLiquido(valorFrete, tipoDesconto, valorDesconto, percentualDesconto, totalItens, valorSeguro, valorDespesasAcessorias, totalIcmsSt, totalFcpSt) {
        let novoTotalLiquido = totalItens

        if (tipoDesconto === tiposDesconto.VALOR) {
            novoTotalLiquido = novoTotalLiquido - valorDesconto
        } else {
            novoTotalLiquido = totalItens - ((percentualDesconto / 100) * totalItens)
        }

        if (valorFrete) {
            novoTotalLiquido = novoTotalLiquido + valorFrete
        }
        if (valorSeguro) {
            novoTotalLiquido = novoTotalLiquido + valorSeguro
        }
        if (valorDespesasAcessorias) {
            novoTotalLiquido = novoTotalLiquido + valorDespesasAcessorias
        }
        if (totalIcmsSt) {
            novoTotalLiquido = novoTotalLiquido + totalIcmsSt
        }
        if (totalFcpSt) {
            novoTotalLiquido = novoTotalLiquido + totalFcpSt
        }

        return novoTotalLiquido
    }

    onChangeTipoDesconto(e) {
        const { percentualDesconto, valorDesconto, totalItens, valorFrete, valorSeguro, valorDespesasAcessorias, totalIcmsSt, totalFcpSt } = this.props
        const totalLiquido = this.calcularTotalLiquido(valorFrete, e.value, valorDesconto, percentualDesconto, totalItens, valorSeguro, valorDespesasAcessorias, totalIcmsSt, totalFcpSt)
        this.props.onChangeTotalLiquido(totalLiquido)
        this.props.onChangeTipoDesconto(e.value)
        this.props.onChangePercentualDesconto(null)
        this.props.onChangeValorDesconto(null)
    }

    onChangePercentualDesconto(value) {
        const { tipoDesconto, totalItens, valorFrete, valorDesconto, valorSeguro, valorDespesasAcessorias, totalIcmsSt, totalFcpSt } = this.props
        if (value > 100) {
            value = 100
        }
        const totalLiquido = this.calcularTotalLiquido(valorFrete, tipoDesconto, valorDesconto, value, totalItens, valorSeguro, valorDespesasAcessorias, totalIcmsSt, totalFcpSt)
        this.props.onChangeTotalLiquido(totalLiquido)
        this.props.onChangePercentualDesconto(value)
    }

    onChangeValorDesconto(value) {
        const { tipoDesconto, totalItens, valorFrete, percentualDesconto, valorSeguro, valorDespesasAcessorias, totalIcmsSt, totalFcpSt } = this.props
        const totalLiquido = this.calcularTotalLiquido(valorFrete, tipoDesconto, value, percentualDesconto, totalItens, valorSeguro, valorDespesasAcessorias, totalIcmsSt, totalFcpSt)
        this.props.onChangeTotalLiquido(totalLiquido)
        this.props.onChangeValorDesconto(value)
    }

    onClickExcluir(index) {
        const { valorFrete, tipoDesconto, valorDesconto, percentualDesconto, valorSeguro, valorDespesasAcessorias, totalIcmsSt, totalFcpSt } = this.props
        const novosItens = [...this.props.itens]
        novosItens.splice(index, 1)

        const totalItens = novosItens.map(e => e.subtotal).reduce((a, b) => a + b, 0)
        const totalLiquido = this.calcularTotalLiquido(valorFrete, tipoDesconto, valorDesconto, percentualDesconto, totalItens, valorSeguro, valorDespesasAcessorias, totalIcmsSt, totalFcpSt)
        this.props.onChangeTotalLiquido(totalLiquido)
        this.props.onChangeTotalItens(totalItens)
        this.props.onChangeItens(novosItens)
    }

    render() {
        const {
            isMobile,
            valorFrete,
            itens,
            totalItens,
            totalLiquido,
            tipoDesconto,
            valorDesconto,
            percentualDesconto,
            errors,
            primaryColor,
            informacoesPermissoes,
            existeParcelaRecebida,
            status,
            initialItens,
            dirty,
            valorSeguro,
            valorDespesasAcessorias,
            totalIcmsSt,
            totalFcpSt,
        } = this.props;

        return (
            <>
                {itens && itens.map((item, index) =>
                    <ProdutosMemorizados
                        key={index}
                        index={index}
                        status={status}
                        valueProduto={item.produto}
                        onChangeProduto={(e) => this.onChangeProduto(e, index)}

                        valueDescricao={item.descricao}
                        onChangeDescricao={(e) => this.onChangeDescricao(e.target.value, index)}

                        valueQuantidade={item.quantidade}
                        onChangeQuantidade={(e) => this.onChangeQuantidade(e.target.value, index)}

                        valueValor={item.valor}
                        onChangeValor={e => this.onChangeValor(e.target.value, index)}

                        loadInitialValues={index === 0}
                        options={this.props.options}
                        onChangeOptions={this.props.onChangeOptions}
                        initialItens={initialItens}
                        itens={itens}

                        onClickExcluir={() => this.onClickExcluir(index)}
                        exibirLabels={index === 0 || isMobile}
                        backgroundColor={isMobile && index % 2 === 0 ? "#f1f1f1" : null}
                        isMobile={isMobile}
                        dirty={dirty}
                        excluirDisabled={(index === 0 && itens.length < 2) || existeParcelaRecebida}
                        errors={errors && errors.itens && errors.itens[index]}
                        existeParcelaRecebida={existeParcelaRecebida}
                        informacoesPermissoes={informacoesPermissoes}
                    />
                )}
                <Grid>
                    <Col sm="4" md="4" lg='4' xl='4'>
                        <Button
                            label="Adicionar produto"
                            icon='fa fa-plus'
                            style={{ ...buttonAdicionarStyle, color: primaryColor }}
                            onClick={this.props.onAdicionarNovoProduto}
                            tabIndex={-1}
                            disabled={!informacoesPermissoes.podeEditar || existeParcelaRecebida}
                        />
                    </Col>
                    <Col sm="8" md="8" lg='8' xl='8'>
                        <p style={valorProdutosDescricaoStyle}>Valor dos produtos: <b style={{ ...valorProdutosValorStyle, color: primaryColor }}>{formatarMonetario(totalItens)}</b></p>
                    </Col>
                </Grid>
                <Grid justifyEnd>
                    <Col col='4' style={{ textAlign: 'end', paddingTop: '12px' }}>
                        <label title="Valor ou percentual de desconto a ser descontado do valor total dos produtos">
                            Desconto
                        </label>
                    </Col>
                    <InternalDropdown col='4' sm='2'
                        colStyle={{ maxWidth: '90px' }}
                        name="tipoDesconto"
                        value={tipoDesconto}
                        showClear={false}
                        options={optionsTiposDesconto}
                        onChange={this.onChangeTipoDesconto}
                        isSearchable={false}
                        disabled={existeParcelaRecebida}
                        {...informacoesPermissoes}
                    />
                    <If test={tipoDesconto === tiposDesconto.VALOR}>
                        <InternalInputDouble col='4' sm="3" md="3" lg='3' xl='2'
                            name="valorDesconto"
                            value={valorDesconto}
                            onChange={(e) => this.onChangeValorDesconto(e.target.value)}
                            size={15}
                            prefix='R$ '
                            placeholder='R$ 0,00'
                            allowNegative={false}
                            disabled={existeParcelaRecebida}
                            {...informacoesPermissoes}
                        />
                    </If>
                    <If test={tipoDesconto === tiposDesconto.PERCENTUAL}>
                        <InternalInputDouble col='5' sm="3" md="3" lg='3' xl='2'
                            name="percentualDesconto"
                            value={percentualDesconto}
                            onChange={(e) => this.onChangePercentualDesconto(e.target.value)}
                            size={5}
                            suffix=' %'
                            placeholder='0,00 %'
                            allowNegative={false}
                            disabled={existeParcelaRecebida}
                            {...informacoesPermissoes}
                        />
                    </If>
                </Grid>
                <Grid justifyEnd>
                    <Col col='6' style={{ textAlign: 'end', paddingTop: '12px' }}>
                        <label title="Valor de frete a ser adicionado ao valor total do pedido">
                            Valor de frete
                        </label>
                    </Col>
                    <InternalInputDouble col='4' sm="3" md="3" lg='3' xl='2'
                        name="valorFrete"
                        value={valorFrete}
                        onChange={(e) => this.onChangeValorFrete(e.target.value || 0)}
                        size={15}
                        prefix='R$ '
                        placeholder='R$ 0,00'
                        allowNegative={false}
                        disabled={existeParcelaRecebida}
                        {...informacoesPermissoes}
                    />
                </Grid>
                <Grid justifyEnd>
                    <Col col='6' style={{ textAlign: 'end', paddingTop: '12px' }}>
                        <label title="Valor do seguro a ser adicionado ao valor total do pedido">
                            Valor do seguro
                        </label>
                    </Col>
                    <InternalInputDouble col='4' sm="3" md="3" lg='3' xl='2'
                        name="valorSeguro"
                        value={valorSeguro}
                        onChange={(e) => this.onChangeValorSeguro(e.target.value || 0)}
                        size={15}
                        prefix='R$ '
                        placeholder='R$ 0,00'
                        allowNegative={false}
                        disabled={existeParcelaRecebida}
                        {...informacoesPermissoes}
                    />
                </Grid>
                <Grid justifyEnd>
                    <Col col='6' style={{ textAlign: 'end', paddingTop: '12px' }}>
                        <label title="Valor de acessórias a ser adicionado ao valor total do pedido">
                            Valor de acessórias
                        </label>
                    </Col>
                    <InternalInputDouble col='4' sm="3" md="3" lg='3' xl='2'
                        name="valorDespesasAcessorias"
                        value={valorDespesasAcessorias}
                        onChange={(e) => this.onChangeValorDespesasAcessorias(e.target.value || 0)}
                        size={15}
                        prefix='R$ '
                        placeholder='R$ 0,00'
                        allowNegative={false}
                        disabled={existeParcelaRecebida}
                        {...informacoesPermissoes}
                    />
                </Grid>
                <Grid justifyEnd>
                    <If test={totalIcmsSt}>
                        <Col col="12">
                            <p style={valorProdutosDescricaoStyle} title="Valor de ICMS-ST calculado na transmissão da nota fiscal">
                                Valor de ICMS-ST: <b>{formatarMonetario(totalIcmsSt)}</b>
                            </p>
                        </Col>
                    </If>
                    <If test={totalFcpSt}>
                        <Col col="12">
                            <p style={valorProdutosDescricaoStyle} title="Valor de FCP(Fundo de combate a pobreza) calculado na transmissão da nota fiscal">
                                Valor de FCP: <b>{formatarMonetario(totalFcpSt)}</b>
                            </p>
                        </Col>
                    </If>

                    <Col style={{ display: "flex", flexDirection: 'column', overflow: 'auto', whiteSpace: 'nowrap' }}>
                        <p style={valorLiquidoDescricaoStyle}>
                            Valor líquido:&nbsp;
                            <b style={{ ...valorLiquidoValorStyle, color: totalLiquido >= 0 ? primaryColor : "red" }}>
                                {formatarMonetario(totalLiquido)}
                            </b>
                        </p>
                        <If test={totalLiquido < 0}>
                            <span style={{ color: 'red', fontSize: '12px', textAlign: 'end', }}>{errors.totalLiquido}</span>
                        </If>
                    </Col>
                </Grid>
            </>
        )
    }
}

const mapStateToProps = state => ({
    isMobile: state.dispositivo.isMobile,
})

export default connect(mapStateToProps)(ProdutosVendas);
