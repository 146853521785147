import { get, del, post, put, exibirToast } from "../../../../../../common/requisicoes";
import { services } from "../../../../../../common/constantes/api";

export async function asyncCreateLancamentoReceita(data, onSuccess, onError) {
    await post(`${services.GESTOR}/v1/lancamentos`, data, null, exibirToast(onSuccess, "Receita criada com sucesso"), onError);
}

export async function asyncGetLancamentoReceita(lancamento, onSuccess, onError) {
    await get(`${services.GESTOR}/v1/lancamentos/${lancamento}`, null, onSuccess, onError);
}

export async function asyncUpdateLancamentoReceita(lancamento, onSuccess, onError) {
    await put(`${services.GESTOR}/v1/lancamentos/${lancamento.id}`, lancamento, null, exibirToast(onSuccess, "Receita atualizada com sucesso"), onError);
}

export async function asyncDeleteLancamentoReceita(lancamento, onSuccess, onError) {
    await del(`${services.GESTOR}/v1/lancamentos/${lancamento}`, null, exibirToast(onSuccess, "Receita removida com sucesso"), onError);
}

export async function buscarCategoriaFavoritaReceita(onSuccess, onError) {
    await get(`${services.GESTOR}/v1/lancamentos/relacoes/categorias/receitas?query=favoritaReceita=="true";ativa=="true"`, null, onSuccess, onError, false)
}

export async function buscarContaFavoritaReceita(onSuccess, onError) {
    await get(`${services.GESTOR}/v1/lancamentos/relacoes/contas?query=favoritaReceita=="true";ativa=="true"`, null, onSuccess, onError, false)
}