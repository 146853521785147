import React, { Component } from 'react'
import autoBind from 'react-autobind';
import { Button } from 'primereact/button';
import Dropzone from 'react-dropzone';
import { alert } from '../../../../../components/Toast';
import certificadoAdicionadoImg from './images/certificado.svg';

const selecionarCertificadoStyle = {
    background: 'none',
    border: 'none',
    fontWeight: 'bold',
    boxShadow: 'none',
    color: "#006095"
}

const divPrincipalStyle = {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    marginTop: '15px'
}

const certificadoDivStyle = {
    display: 'flex',
    justifyContent: 'center',
    width: '100%'
}

const nenhumCertificadoEncontradoStyle = {
    fontWeight: 'bold'
}

const certificadoNomeStyle = {
    fontWeight: 'bold',
    marginLeft: '10px',
    marginTop: '5px',
    marginBottom: '5px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
}

class CertificadoUpload extends Component {
    constructor(props) {
        super(props);
        autoBind(this);
        this.state = {
            imagemOriginal: null,
            imagemOriginalUrl: null,
            modalCropImageVisible: false
        }
    }

    onUploadLogotipo(files, error) {
        if (error) {
            alert("Problema ao adicionar o certificado",
                "Só são permitidos arquivos com extensão .pfx")
        } else {
            this.props.onChange(files[0])
        }
    }

    onHideModalCropImage(imagemCortada) {
        if (imagemCortada) {
            this.props.onChange(imagemCortada)
        }
        this.setState({ imagemOriginal: null, imagemOriginalUrl: null, modalCropImageVisible: false })
    }

    renderDropzone(label) {
        const { podeEditarEmpresa } = this.props
        return (
            <Dropzone
                multiple={false}
                accept='.pfx'
                onDropAccepted={e => this.onUploadLogotipo(e, false)}
                onDropRejected={e => this.onUploadLogotipo(e, true)}
                disabled={!podeEditarEmpresa}
            >
                {({ getRootProps, getInputProps }) => (
                    <div {...getRootProps()}  >
                        <input {...getInputProps()} />
                        <Button
                            label={label}
                            style={{
                                ...selecionarCertificadoStyle,
                                opacity: !podeEditarEmpresa ? "0.5" : '1'
                            }}
                            title={!podeEditarEmpresa ? "Você não possui permissão para editar": null}
                        />
                    </div>
                )}
            </Dropzone>
        )
    }

    render() {
        const { value } = this.props
        if (value) {
            return (
                <div style={divPrincipalStyle}>
                    <div style={certificadoDivStyle}>
                        <img width='70px' src={certificadoAdicionadoImg} alt="Certificado" />
                        <div style={{ maxWidth: 'calc(100% - 70px)' }}>
                            <p style={certificadoNomeStyle}>{value && value.name}</p>
                            {this.renderDropzone("ALTERAR CERTIFICADO")}
                        </div>
                    </div>
                </div>
            )
        } else {
            return (
                <div style={divPrincipalStyle}>
                    <p style={nenhumCertificadoEncontradoStyle}> Nenhum certificado digital encontrado  </p>
                    {this.renderDropzone("SELECIONAR CERTIFICADO")}
                </div>
            )
        }
    }
}

export default CertificadoUpload
