export function converterTransferenciaParaApi(values) {
    return {
        id: values.id,
        data: values.data,
        contaOrigem: {
            id: values.contaOrigem.value
        },
        contaDestino: {
            id: values.contaDestino.value
        },
        valor: values.valor,
        observacao: values.observacao
    }
}

export function converterTransferenciaParaFormulario(values) {
    return {
        id: values.id,
        data: values.data,
        contaOrigem: { 
            label: values.contaOrigem.nome, 
            value: values.contaOrigem.id 
        },
        contaDestino: { 
            label: values.contaDestino.nome, 
            value: values.contaDestino.id
        },
        valor: values.valor,
        observacao: values.observacao,
    }
}