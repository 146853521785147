import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import autoBind from 'react-autobind/lib/autoBind';
import { usuarioPossuiPermissao } from '../../../../../common/autenticacao';
import { recursos, permissoes } from '../../../../../common/constantes/autorizacao';
import Message from '../../../../../components/Message';
import If from '../../../../../components/If';

const textStyle = {
    margin: '5px 0px'
}

class MessagePlanoExcedido extends Component {
    constructor(props) {
        super(props);
        autoBind(this);
        this.state = {
            podeVisualizarPlanos: usuarioPossuiPermissao(recursos.PLANOS, permissoes.VISUALIZAR)
        }
    }

    render() {
        return (
            <Message
                severity="info"
                colStyle={{ margin: '1em 0em' }}
                text={
                    <div>
                        <h2>Seu plano expirou</h2>
                        <p style={textStyle}>Não foi possível efetuar a cobrança da mensalidade do seu plano. Atualize-o para o plano gratuito ou cadastre um novo cartão de crédito válido para desbloqueá-lo e continuar usando o sistema</p>
                        <If test={this.state.podeVisualizarPlanos}>
                            <span onClick={() => this.props.history.push("/planos")} style={{ cursor: 'pointer', fontWeight: 'bold' }}>
                                Ir para configurações
                            </span>
                        </If>
                    </div>
                }
            />
        )
    }
}

export default withRouter(MessagePlanoExcedido)
