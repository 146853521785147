import { tipoCampos } from "../../../../components/PesquisaAvancada/util/constantes";

export const optionsFiltroAvancado = [
    {
        label: 'Status', name: 'status', type: tipoCampos.SELECT,
        optionSelect: [
            { label: "Não paga", value: "NAO_PAGA" },
            { label: "Parcialmente paga", value: "PARCIALMENTE_PAGA" },
            { label: "Paga", value: "PAGA" },
        ]
    },
    { label: 'Descrição', name: 'descricao', type: tipoCampos.STRING },
    { label: 'Valor', name: 'valor', type: tipoCampos.DECIMAL },
    { label: 'Observação', name: 'observacao', type: tipoCampos.STRING },
]
