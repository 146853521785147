import React, { Component } from 'react';
import propTypes from 'prop-types';
import SelectableCard from './components/SelectableCard';

class CardTotalizadorListagem extends Component {
    render() {
        return <SelectableCard {...this.props} />
    }
}

CardTotalizadorListagem.propTypes = {
    selected: propTypes.bool,
    onSelect: propTypes.func,
    value: propTypes.number.isRequired,
    colors: propTypes.object.isRequired,
    title: propTypes.string.isRequired,
    name: propTypes.string.isRequired,
}

export default CardTotalizadorListagem;
