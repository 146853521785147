import React, { Component } from 'react';
import Chart from "chart.js";
import { Card } from 'primereact/card';
import autoBind from 'react-autobind';
import { buscarDocumentosPorStatusPeriodo } from './requests';
import { ProgressBar } from 'primereact/progressbar';
import 'chartjs-plugin-datalabels';
import NenhumaInformacaoEncontrada from '../../../NenhumaInformacaoEncontrada'

import FalhaAoCarregarAsInformacoes from '../../../FalhaAoCarregarAsInformacoes';
import { ColorsCard } from '../../../../../../assinaturaeletronica/util/constantes';

const cardHeader = {
    padding: '14px',
    display: 'flex',
    justifyContent: 'space-between'
}

const NomeECorDocumento = {
    PENDENTE: {
        nome: 'Pendente',
        cor: ColorsCard['PENDENTE'].strongColor
    },
    ARQUIVO_ADICIONADO: {
        nome: 'Arquivo adicionado',
        cor: ColorsCard['ARQUIVO_ADICIONADO'].strongColor
    },
    AGUARDANDO_ASSINATURAS: {
        nome: 'Aguardando assinaturas',
        cor: ColorsCard['AGUARDANDO_ASSINATURAS'].strongColor
    },
    ASSINADO: {
        nome: 'Assinado',
        cor: ColorsCard['ASSINADO'].strongColor
    },
    REJEITADO: {
        nome: 'Rejeitado',
        cor: ColorsCard['REJEITADO'].strongColor
    },
    EXPIRADO: {
        nome: 'Expirado',
        cor: ColorsCard['EXPIRADO'].strongColor
    },
}

let documentosPorStatus;

export default class GraficoDocumentosPorStatusPeriodo extends Component {
    graficoDeDocumentosAgrupadoPorStatus = React.createRef();

    constructor(props) {
        super(props);

        autoBind(this);

        this.state = {
            loading: true,
            error: false,
            status: [],
            corDocumento: [],
            quantidade: [],
            documentos: []
        }
    }

    componentDidMount() {
        this.carregarInformacoes();
    }

    componentDidUpdate(prevProps) {
        const { dataInicial, dataFinal } = this.props;

        if (prevProps.dataInicial !== dataInicial || prevProps.dataFinal !== dataFinal) {
            this.carregarInformacoes();
        }

        if (prevProps.atualizarGraficoNovamente !== this.props.atualizarGraficoNovamente) {
            this.carregarInformacoes(false);
        }
    }

    carregarInformacoes(exibirLoading = true) {
        const { dataInicial, dataFinal } = this.props;

        this.setState({ loading: exibirLoading, error: false }, () => {
            buscarDocumentosPorStatusPeriodo(dataInicial, dataFinal, ({ data: documentos }) => {

                let status = []
                let quantidade = []
                let corDocumentos = []

                documentos.forEach(documento => {
                    status.push(NomeECorDocumento[documento.status].nome)
                    corDocumentos.push(NomeECorDocumento[documento.status].cor)
                    quantidade.push(documento.quantidade)
                })

                this.setState({ status, quantidade, loading: false, documentos, corDocumentos }, () => {
                    this.buildChart();
                });
            }, () => {
                this.setState({ error: true, loading: false })
            });
        })
    }

    buildChart = () => {
        if (!this.graficoDeDocumentosAgrupadoPorStatus.current) {
            return
        }
        const myChartRef = this.graficoDeDocumentosAgrupadoPorStatus.current.getContext("2d");

        if (typeof documentosPorStatus !== "undefined") documentosPorStatus.destroy(); //Necessário para atualizar o gráfico sem bugs
        documentosPorStatus = new Chart(myChartRef, {
            type: 'doughnut',
            data: {
                datasets: [{
                    data: this.state.quantidade,
                    backgroundColor: this.state.corDocumentos,
                }],
                labels: this.state.status,


            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                aspectRatio: 1.1,
                legend: {
                    position: 'bottom',
                    labels: {
                        usePointStyle: true
                    },
                    onHover: function (e) {
                        e.target.style.cursor = 'pointer';
                    },
                },
                hover: {
                    onHover: function (e) {
                        var point = this.getElementAtEvent(e);
                        if (point.length) {
                            e.target.style.cursor = 'pointer';
                        } else {
                            e.target.style.cursor = 'default';
                        }
                    },
                },
                plugins: {
                    datalabels: {
                        color: "#fff",
                        font: {
                            size: 11
                        },
                        formatter: (value, ctx) => {
                            let total = 0;
                            let dataArr = ctx.chart.data.datasets[0].data;
                            dataArr.forEach(data => { total += data; });
                            return (value * 100 / total).toFixed(1) + "%";
                        },
                    }
                },
                tooltips: {
                    callbacks: {
                        label: (tooltipItem, data) => {
                            const quantidade = data.datasets[0].data[tooltipItem.index]
                            if (quantidade === 1) {
                                return ` ${quantidade} Documento`;
                            }
                            return ` ${quantidade} Documentos`;
                        },
                        title: (tooltipItem, data) => {
                            const label = data.labels[tooltipItem[0].index];
                            let total = 0;
                            let dataArr = data.datasets[0].data;
                            dataArr.forEach(data => total += data);
                            const value = dataArr[tooltipItem[0].index];
                            return label + " (" + (value * 100 / total).toFixed(1) + "%)";
                        }
                    }
                }
            }
        });
    }

    getHeaderCard() {
        return (
            <div style={cardHeader}>
                <label
                    title="Mostra os documentos adicionados no período e agrupa por status"
                    style={{ fontSize: '17px', color: '#333333' }}
                >
                    Documentos por status no período
                </label>
            </div>
        );
    }

    render() {
        const { loading, documentos } = this.state


        if (loading) {
            return (
                <Card header={this.getHeaderCard()} style={{ borderRadius: '5px', height: '100%' }}>
                    <ProgressBar mode="indeterminate" style={{ height: '3px' }} />
                </Card>
            )
        } else if (this.state.error) {
            return (
                <Card header={this.getHeaderCard()} style={{ borderRadius: '5px', height: '100%' }}>
                    <FalhaAoCarregarAsInformacoes />
                </Card>
            )
        } else if (documentos.length === 0) {
            return (
                <Card header={this.getHeaderCard()} style={{ borderRadius: '5px', height: '100%', }}>
                    <NenhumaInformacaoEncontrada />
                </Card>
            )
        } else {
            return (
                <Card header={this.getHeaderCard()} style={{ borderRadius: '5px', height: '100%', margin: '0px' }}>
                    <canvas id="graficoDeDocumentosAgrupadoPorStatus" ref={this.graficoDeDocumentosAgrupadoPorStatus} />
                </Card>
            )
        }
    }
}
