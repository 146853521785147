import React, { Component } from 'react';
import Modal from '../../../../../../../components/Modal';
import { Field, Form } from 'formik';
import Grid from '../../../../../../../components/Grid';
import FormContent from '../../../../../../../components/FormContent';
import TextArea from '../../../../../../../components/TextArea';

class ModalInformacoesComplementares extends Component {

    getInfos() {
        const { msgCreditoICMS, infAdic } = this.props;

        let result = '';

        if(msgCreditoICMS){
            result += msgCreditoICMS + '\n' || '';
        }
        if(infAdic) {
            result += infAdic || '';
        }

        return result

    }

    render() {
        const { visible, onHide, isMobile } = this.props;

        return (
            <Modal
                header={isMobile ? 'Informações do contribuinte' : 'Informações complementares de interesse do contribuinte'}
                visible={visible}
                onHide={() => onHide()}
            >
                <Form>
                    <FormContent>
                        <Grid>
                            <Field
                                style={{cursor: 'default'}}
                                component={TextArea}
                                rows={15}
                                readOnly
                                value={this.getInfos()}
                            />
                        </Grid>
                    </FormContent>
                </Form>
            </Modal>
        )
    }
}

export default ModalInformacoesComplementares;
