import React, { Component } from 'react';
import SetorForm from '../Form';
import Modal from '../../../../components/Modal/index';
import If from '../../../../components/If'

class ModalSetor extends Component {

    render() {
        return (
            <Modal
                className={this.props.visible ? "modalDeUmAsyncSelect" : undefined}
                header='Cadastro de setor'
                visible={this.props.visible}
                onHide={this.props.onHide}
            >
                <If test={this.props.visible}>
                    <SetorForm
                        service={this.props.service}
                        hideModal={this.props.onHide}
                        isModal={true}
                        papelObrigatorio={this.props.papelObrigatorio}
                        atualizarRegistrosSelect={this.props.atualizarRegistrosSelect}
                    />
                </If>
            </Modal>
        )
    }
}

export default ModalSetor
