import React from 'react';
import { FiCheck } from 'react-icons/fi';


const styleOpcaoCor = {
    cursor: 'pointer',
    borderRadius: '50%',
    height: '35px',
    width: '35px',
    margin: '2px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
};

const ColorOption = ({ color, selected, onClick }) => (
    <span style={{ ...styleOpcaoCor, backgroundColor: color, cursor: onClick ? 'pointer' : 'default' }} onClick={() => onClick && onClick(color)}>
        {selected ? <FiCheck style={{ color: '#fff', fontSize: '20px' }} /> : null}
    </span>
)

export default ColorOption;
