import React, { Component } from 'react'
import { Field, Form, withFormik } from "formik";
import reactAutobind from "react-autobind";
import { mensagensDeValidacao } from "../../../../../../../common/constantes/mensagens";
import ButtonCancelar, { estadosBotaoCancelar } from "../../../../../../../components/Button/ButtonCancelar";
import FormActions from "../../../../../../../components/FormActions";
import FormContent from "../../../../../../../components/FormContent";
import Grid from "../../../../../../../components/Grid";
import InputField from "../../../../../../../components/Input/InputField";
import AutoProgressBar from "../../../../../../../components/Loading/AutoProgressBar";
import Modal from "../../../../../../../components/Modal";
import * as Yup from 'yup'
import TextArea from '../../../../../../../components/TextArea';
import Button from '../../../../../../../components/Button';
import { asyncBuscarEmailsPessoa, asyncEnviarVendaPorEmail } from '../../../requests';
import { buscarDadosLoginLocalStorage } from '../../../../../../../common/autenticacao';
import Checkbox from '../../../../../../../components/Input/Checkbox';
import If from '../../../../../../../components/If';
const initialValue = {
    assunto: null,
    conteudo: null,
    destinatarios: null,
    enviarCopia: false
}

class ModalCompartilharPorEmail extends Component {

    constructor(props) {
        super(props);
        reactAutobind(this);
        this.state = {
            destinatarios: null,
            assunto: null,
            conteudo: null,

            emailUsuario: null
        }
    }

    componentDidMount() {
        const { numero, statusVenda, idPessoa } = this.props
        const dadosLogin = buscarDadosLoginLocalStorage()

        let assunto = `Venda Nº ${numero} - ${dadosLogin.organizacao.nome}`
        let conteudo = `Olá, seguem em anexo as informações da venda Nº ${numero}`

        if (statusVenda === "ORCAMENTO") {
            assunto = `Orçamento Nº ${numero} - ${dadosLogin.organizacao.nome}`
            conteudo = `Olá, seguem em anexo as informações do orçamento Nº ${numero}`
        } else if (statusVenda === "CANCELADA") {
            assunto = `Negociação cancelada Nº ${numero} - ${dadosLogin.organizacao.nome}`
            conteudo = `Olá, seguem em anexo as informações do orçamento cancelado Nº ${numero}`
        }

        this.props.setFieldValue("assunto", assunto)
        this.props.setFieldValue("conteudo", conteudo)

        if (idPessoa) {
            asyncBuscarEmailsPessoa(idPessoa, ({ data }) => {
                this.props.setFieldValue("destinatarios", data.map(e => e.email).join(", "))
            })
        }
        this.setState({ emailUsuario: dadosLogin.email })
    }


    async enviarPorEmail() {
        const { idVenda, values } = this.props
        await this.props.handleSubmit();
        await this.props.validateForm()

        if (this.props.isValid) {
            let destinatarios = values.destinatarios.split(",").map(item => item.trim()).filter(item => item.trim() !== "")
            if (values.enviarCopia) {
                destinatarios.push(this.state.emailUsuario)
            }

            asyncEnviarVendaPorEmail(idVenda, { ...values, destinatarios }, () => this.props.onHide())
        }
    }

    render() {

        const { visible, onHide, values, setFieldValue } = this.props;
        const { emailUsuario } = this.state

        return (
            <Modal
                header="Compartilhar por e-mail"
                visible={visible}
                onHide={onHide}
            >
                <AutoProgressBar />
                <Form>
                    <FormActions style={{ marginBottom: '10px' }}>
                        <ButtonCancelar
                            estadoBotao={estadosBotaoCancelar.VOLTAR}
                            onClick={this.props.onHide}
                        />
                        <Button
                            label="Enviar por e-mail"
                            icon='fa fa-send'
                            onClick={this.enviarPorEmail}
                        />
                    </FormActions>
                    <FormContent>
                        <Grid>
                            <Field sm="12" md="12" lg="12" xl="12"
                                component={InputField}
                                label='Destinatários'
                                name="destinatarios"
                                helpMessage='Pessoas que irão receber o e-mail, podem ser informados vários e-mails separados por vírgula'
                                obrigatorio
                                size={255}
                            />
                            <Field sm="12" md="12" lg="12" xl="12"
                                component={InputField}
                                label='Assunto'
                                obrigatorio
                                name="assunto"
                                helpMessage='Assunto (título) do e-mail que será encaminhado para os destinatários'
                                size={255}
                            />
                            <Field sm="12" md="12" lg="12" xl="12"
                                component={TextArea}
                                label='Texto do e-mail'
                                obrigatorio
                                placeholder="Escreva o texto do e-mail aqui..."
                                name="conteudo"
                                helpMessage='Texto do e-mail que será encaminhado para os destinatários'
                                rows={5}
                                onChange={e => setFieldValue("conteudo", e.target.value)}
                            />
                            <If test={emailUsuario}>
                                <Field sm='12' md='12' lg='12' xl='12'
                                    component={Checkbox}
                                    label={`Enviar cópia do e-mail para mim (${emailUsuario})`}
                                    name="enviarCopia"
                                    helpMessage="Inclui o usuário do sistema na lista de destinatários ao enviar o e-mail"
                                    onChange={(e) => setFieldValue('enviarCopia', e.checked)}
                                    checked={values.enviarCopia}
                                />
                            </If>
                        </Grid>
                    </FormContent>
                </Form>
            </Modal>
        )
    }
}

ModalCompartilharPorEmail = withFormik({
    enableReinitialize: true,
    validateOnChange: false,

    mapPropsToValues() {
        return initialValue;
    },

    async validate(values) {
        const errors = {}

        if (values.destinatarios) {
            let destinatarios = values.destinatarios.split(",").map(item => item.trim())
            let schemaEmail = Yup.string().email()

            for (const item of destinatarios) {
                if (item === "") {
                    errors.destinatarios = `O e-mail "${item}" não é um e-mail válido`
                    break
                } else if (!await schemaEmail.isValid(item)) {
                    errors.destinatarios = `O e-mail "${item}" não é um e-mail válido`
                    break
                }
            }
        }

        return errors
    },

    validationSchema: Yup.object().shape({
        assunto: Yup.string().nullable().required(mensagensDeValidacao.OBRIGATORIO),
        destinatarios: Yup.string().nullable().required(mensagensDeValidacao.OBRIGATORIO),
        conteudo: Yup.string().nullable().required(mensagensDeValidacao.OBRIGATORIO),
    }),
    handleSubmit: () => { }
})(ModalCompartilharPorEmail);

export default ModalCompartilharPorEmail;
