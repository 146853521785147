export const helpNFeTransporte = {
    qVol: 'Quantidade total de volumes transportados',
    esp: 'Espécie dos volumes transportados. Ex: Caixa, Pacote, Sacola',
    marca: 'Marca dos volumes transportados',
    nVol: 'Numeração dos volumes transportados',
    pesoB: 'Peso bruto total (em kg)',
    pesoL: 'Peso líquido total (em kg)',
    CPF: 'CPF do Transportador (Deixar em branco no caso do transportador ser pessoa jurídica)',
    CNPJ: 'CNPJ do Transportador (Deixar em branco no caso do transportador ser pessoa física)',
    xNome: 'Nome ou razão social do transportador',
    IE: 'Inscrição estadual do Transportador',
    xEnder: 'Endereço completo do Transportador',
    placa: 'Placa do veículo que realizará o transporte',
    RNTC: ''
}