import React, { Component } from "react";
import PropType from "prop-types";
import autoBind from "react-autobind";
import { usuarioPossuiModulos } from "../../common/autenticacao";

export default class Col extends Component {
  constructor(props) {
    super(props);

    autoBind(this);

    this.state = {
      moduloValido: true
    };
  }

  componentDidMount() {
    if (this.props.modulosEspecificos) {
      this.setState({
        moduloValido: usuarioPossuiModulos(this.props.modulosEspecificos)
      });
    }
  }

  montarClassName() {
    const {
      col, sm, md, lg, xl, offset, className = '',
    } = this.props;

    let classe = `${className} p-col`;

    if (col) {
      classe = `${className} p-col-${col}`;
    }
    if (offset) {
      classe += ` p-offset-${offset}`;
    }
    if (sm) {
      classe += ` p-sm-${sm}`;
    }
    if (md) {
      classe += ` p-md-${md}`;
    }
    if (lg) {
      classe += ` p-lg-${lg}`;
    }
    if (xl) {
      classe += ` p-xl-${xl}`;
    }

    return classe;
  }


  render() {
    const {
      hidden,
      id,
      style,
      children,
      col,
      sm,
      md,
      lg,
      xl,
      offset,
      title
    } = this.props;

    const { moduloValido } = this.state;
    const classe = this.montarClassName();

    if (!moduloValido) {
      return null;
    }

    return (
      <div
        id={id}
        title={title}
        style={style}
        hidden={hidden}
        col={col}
        sm={sm}
        md={md}
        lg={lg}
        xl={xl}
        offset={offset}
        className={classe}
      >
        {children}
      </div>
    );
  }
}

Col.PropType = {
  /** Identificador do componente */
  id: PropType.oneOfType([Number, String]),
  /** Classe do componente */
  className: PropType.oneOfType([Number, String]),
  /** Tamanho padrão da coluna utilizado em dispositivos muito pequenos (0 a 12) */
  col: PropType.oneOfType([Number, String]),
  /** Tamanho em small devices (0 a 12) */
  sm: PropType.oneOfType([Number, String]),
  /** Tamanho em medium devices (0 a 12) */
  md: PropType.oneOfType([Number, String]),
  /** Tamanho em large devices (0 a 12) */
  lg: PropType.oneOfType([Number, String]),
  /**Tamanho em extra large devices (0 a 12) */
  xl: PropType.oneOfType([Number, String]),
  /** Esconde o componente*/
  hidden: PropType.oneOfType([Number, String]),
  /** Estilo do componente*/
  style: PropType.oneOfType([Number, String]),
  /** Filhos do componente*/
  children: PropType.oneOfType([Number, String]),
};

Col.defaultProps = {
  col: 12,
  hidden: false
};
