import React from 'react';
import Col from '../../../../../../components/Col';
import Grid from '../../../../../../components/Grid';
import { formatarDecimais } from '../../../../../../common/mascara';
import { AiFillCloseCircle } from 'react-icons/ai';

const styleCardContent = {
    borderRadius: '5px',
    cursor: 'pointer'
};

const styleCloseIcon = {
    float: 'right',
    margin: '-10px -10px 0px -10px',
    fontSize: '20px',
    backgroundColor: 'white',
    borderRadius: '50%',
    color: '#006095',
}

const styleTitle = {
    fontSize: '14px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textAlign: 'end',
    padding: '0px',
    cursor: 'pointer',
};

const styleValue = {
    fontSize: '18px',
    marginTop: '5px',
    fontWeight: 'bold',
    overflow: 'hidden',
};

const CardSelectable = ({ primaryColor, title, value, onSelect, name, selected, helpMessage, titleFiltro }) => {

    const getContentStyle = () => {
        if (selected) {
            return {
                ...styleCardContent,
                border: `2px solid #006095`,
                boxShadow: '2px 4px 10px 0px #999999',
            };
        }
        return styleCardContent;
    }

    const titleFiltrar = !selected ? titleFiltro : `Clique para limpar o filtro`;

    return (
        <div className="p-card p-total-list-cards" onClick={() => onSelect(name)} style={getContentStyle()}>
            {selected ? <AiFillCloseCircle style={styleCloseIcon} /> : null}
            <Grid verticalAlignCenter justifyBetween style={{ margin: '0px' }}>
                <Col col="12">
                    <Grid style={{ margin: '0px' }}>
                        <div className="p-col" style={styleTitle} title={helpMessage}>
                            {title}
                        </div>
                    </Grid>
                    <Grid justifyEnd style={{ margin: '0px' }} title={titleFiltrar}>
                        <div style={{ ...styleValue, color: primaryColor }}>
                            {formatarDecimais(value)}
                        </div>
                    </Grid>
                </Col>
            </Grid>
        </div>
    );
}

export default CardSelectable;
