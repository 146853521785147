import React, { Component } from 'react';
import { DataTable } from 'primereact/components/datatable/DataTable';
import { Column } from 'primereact/column';
import { formatarMonetario } from '../../../../../../common/mascara';
import Button from '../../../../../../components/Button';
import autoBind from 'react-autobind';
import { Paginator } from 'primereact/paginator';
import { recursos, permissoes } from '../../../../../../common/constantes/autorizacao';
import { buscarDadosLoginLocalStorage, usuarioPossuiPermissao } from '../../../../../../common/autenticacao';
import { inserirMascara } from '../../../../../../common/mascara';
import { formatos } from '../../../../../../common/constantes/mascaras';
import NenhumRegistroEncontrado from '../../../../../../components/NenhumRegistroEncontrado';
import { format, parseISO } from 'date-fns';
import { Menu } from 'primereact/menu';
import { Status, infoStatusNfce } from '../../util/constantes';
import ModalHistorico from '../../Form/components/ModalHistorico';
import If from '../../../../../../components/If';

const styleButton = {
    borderRadius: '50%',
    padding: '5px',
    width: '30px',
    height: '30px',
    margin: '2px'
};

class TabelaNFCe extends Component {

    constructor(props) {
        super(props);

        autoBind(this);

        this.state = {
            podeExcluir: usuarioPossuiPermissao(recursos.VENDAS_VENDAS, permissoes.EXCLUIR),
            podeEditar: usuarioPossuiPermissao(recursos.VENDAS_VENDAS, permissoes.EDITAR),
            registroSelecionado: null,
            modalHistoricoVisible: false,
        }
    }

    atualizarOrdenacao(e) {
        this.props.setSortField(e.sortField);
        this.props.setSortOrder(e.sortOrder);
    }

    renderEmissaoField(nfce) {
        if (nfce.criadoEm)
            return <span>{format(parseISO(nfce.criadoEm, new Date()), 'dd/MM/yyyy')}</span>
        return "";
    }

    renderNumeroField(nfce) {

        const { organizacao } = buscarDadosLoginLocalStorage();
        const serieConfiguracoes = organizacao.fiscalNfce?.serieNfce

        return (
            <div
                title={this.buscarTitleSerie(nfce.serie, nfce.nro, serieConfiguracoes)}
            >
                <span>{this.buscarSerieNfce(nfce.serie, serieConfiguracoes)}</span>
                <span>{' / '}</span>
                <span
                    title={!nfce.nro ? "NFC-e não transmitida" : "Número da nota fiscal do Consumidor"}
                    style={{ fontWeight: 'bolder' }}
                >
                    {nfce.nro || '-'}
                </span>
            </div>
        )
    }

    buscarTitleSerie(serie, nro, serieConfiguracoes) {

        if (serie && nro) {
            return 'Série utilizada na transmissão da NFC-e'
        }

        if (serieConfiguracoes && !nro) {
            return 'Série definida nas configurações da NFC-e'
        }

        return 'Série não configurada'
    }

    buscarSerieNfce(serie, serieConfiguracoes) {

        if (serie) {
            return serie
        }

        if (serieConfiguracoes) {
            return serieConfiguracoes
        }

        return '?'
    }

    renderClienteField(nfce) {
        return (
            <span title={nfce.destNome} style={{ maxHeight: '60px', overflow: 'hidden', display: 'inline-flex' }}>
                {nfce.destNome}
            </span>
        );
    }

    renderCpfCnpjField(nfce) {
        const cpfCnpjComMascara = this.aplicaMascaraCpfCnpj(nfce)

        return (
            <span title={nfce.destCpf ?? nfce.destCnpj} style={{ maxHeight: '60px', overflow: 'hidden', display: 'inline-flex', width: '133px', marginLeft: '-10px' }}>
                {cpfCnpjComMascara}
            </span>
        )
    }

    renderStatusField(nfce) {
        if (!nfce.status) {
            return null;
        }

        return (
            <span title={infoStatusNfce[nfce.status].description} style={{
                backgroundColor: infoStatusNfce[nfce.status].lightColor,
                color: infoStatusNfce[nfce.status].strongColor,
                fontWeight: 'bold',
                fontSize: '13px',
                borderRadius: '3px',
                padding: '3px 10px',
            }}>
                <span style={{ width: '100%', textAlign: 'center' }}>{infoStatusNfce[nfce.status].name}</span>
            </span>
        )
    }

    renderValorField(nfce) {
        const valor = formatarMonetario(nfce.valor)
        return <span title={valor}>{valor}</span>
    }

    renderAcoesField(nfce) {
        const { podeExcluir } = this.state;

        let titleEditar = "Editar";
        let titleExcluir = "Excluir";
        let titleBtnOpcoes = "Opções";
        let disableBtnEditar = false;
        let disableBtnExcluir = false;
        let disableBtnOpcoes = false;

        if (!podeExcluir) {
            titleExcluir = "Você não possui permissão para executar essa ação"
        };


        if (nfce.status === Status.CANCELADA || nfce.status === Status.TRANSMITIDA || nfce.status === Status.CORRIGIDA) {
            titleExcluir = "Você só pode excluir uma NFC-e caso ela ainda não foi transmitida"
            disableBtnExcluir = true;
        }

        if (nfce.status === Status.AGUARDANDO_AUTORIZACAO) {
            titleBtnOpcoes = "Aguarde até que a NFC-e seja transmitida"
            titleExcluir = "Aguarde até que a NFC-e seja transmitida"
            disableBtnExcluir = true;
            disableBtnOpcoes = true;
        }

        if (nfce.status === Status.REJEITADA) {
            titleBtnOpcoes = "NFC-e rejeitada"
            disableBtnOpcoes = true;
        }
        if (nfce.status === Status.NAO_ENVIADA) {
            titleBtnOpcoes = "NFC-e não transmitida"
            disableBtnOpcoes = true;
        }


        if (nfce.status === Status.DENEGADA) {
            titleBtnOpcoes = "NFC-e denegada"
            disableBtnOpcoes = true;
            disableBtnExcluir = true;
        }

        if (!podeExcluir) {
            disableBtnExcluir = true;
        }

        return (
            <>
                <Button
                    style={styleButton}
                    className="p-button p-button-primary"
                    icon="fa fa-pencil"
                    title={titleEditar}
                    disabled={disableBtnEditar}
                    onClick={() => this.props.editarNfce(nfce)}
                />
                <Button
                    style={styleButton}
                    className="p-button p-button-danger"
                    icon="fa fa-trash"
                    title={titleExcluir}
                    disabled={disableBtnExcluir}
                    onClick={() => this.props.removerNfce(nfce)}
                />
                <Button
                    className="p-button-secondary"
                    icon="fa fa-ellipsis-v"
                    style={styleButton}
                    title={titleBtnOpcoes}
                    disabled={disableBtnOpcoes}
                    aria-controls="popup_menu"
                    aria-haspopup={true}
                    onClick={event => {
                        this.setState({ registroSelecionado: nfce })
                        this.menu.toggle(event)
                    }}
                />
            </>
        );
    }

    montarItensMenu() {
        let itens = [];
        const { registroSelecionado } = this.state;

        if (registroSelecionado) {
            if (registroSelecionado.status === Status.TRANSMITIDA || registroSelecionado.status === Status.CANCELADA) {
                itens.push({ label: 'Histórico', icon: 'fa fa-history', command: () => this.visualizarHistorico() });
                itens.push({ label: 'Imprimir DANFe', icon: 'fa fa-print', command: () => this.props.imprimirDANFE(registroSelecionado) });
                itens.push({ label: 'Baixar XML da NFCe', icon: 'fa fa-download', command: () => this.props.baixarXMLNfce(registroSelecionado) });
            }
        }
        return itens;
    }

    visualizarHistorico() {
        this.setState({ modalHistoricoVisible: true });
    }

    aplicaMascaraCpfCnpj(nfce) {
        if (nfce.destCpf) {
            return inserirMascara(nfce.destCpf, formatos.CPF)
        } else if (nfce.destCnpj) {
            return inserirMascara(nfce.destCnpj, formatos.CNPJ)
        }
    }

    numeroVendaCustomStyle() {
        const { isTablet } = this.props

        if (isTablet) {
            return { color: '#333333', overflow: 'hidden' }
        }
        return { color: '#333333', width: '60px', overflow: 'hidden' }
    }

    render() {
        const {
            registros,
            sortField,
            sortOrder,
            totalRecords,
            rows,
            first,
            onPageChange } = this.props;

        const { modalHistoricoVisible, registroSelecionado } = this.state

        const itensMenu = this.montarItensMenu();

        return (
            <>
                <Menu
                    model={itensMenu}
                    popup={true}
                    style={{ width: '200px' }}
                    ref={el => this.menu = el}
                />
                <DataTable
                    className="table-light"
                    value={registros}
                    responsive
                    sortField={sortField}
                    sortOrder={sortOrder}
                    onSort={this.atualizarOrdenacao}
                    emptyMessage={<NenhumRegistroEncontrado message="Nenhuma NFC-e encontrada" />}
                >
                    <Column
                        header="N°"
                        field="numeroVenda"
                        sortable={true}
                        style={this.numeroVendaCustomStyle()}
                    />

                    <Column
                        header="Série/NFC-e"
                        field="nro"
                        sortable={true}
                        body={this.renderNumeroField}
                        style={{ color: '#333333', width: '130px' }}
                    />
                    <Column
                        header="Data"
                        field="criadoEm"
                        sortable={true}
                        body={this.renderEmissaoField}
                        style={{ color: '#333333', width: '100px' }}
                    />
                    <Column
                        header="Cliente"
                        field="destNome"
                        sortable={true}
                        body={this.renderClienteField}
                        style={{ color: '#333333', maxWidth: '250px', overflow: 'hidden', wordBreak: 'break-all' }}
                    />
                    <Column
                        header="CPF/CNPJ"
                        field="destCpfCnpj"
                        sortable={false}
                        body={this.renderCpfCnpjField}
                        style={{ color: '#333333', width: '130px' }}
                    />
                    <Column
                        header="Status"
                        field="status"
                        sortable={true}
                        body={this.renderStatusField}
                        style={{ color: '#333333', width: '130px' }}
                    />
                    <Column
                        header="Valor"
                        field="valor"
                        sortable={true}
                        body={this.renderValorField}
                        style={{ color: '#333333', width: '120px', textOverflow: 'ellipsis', overflow: 'hidden', fontWeight: 'bold' }}
                    />
                    <Column
                        header="Ações"
                        body={this.renderAcoesField}
                        style={{ width: '130px' }}
                    />
                </DataTable>
                <Paginator
                    className='paginator-light'
                    rowsPerPageOptions={[10, 25, 50]}
                    totalRecords={totalRecords}
                    rows={rows}
                    first={first}
                    onPageChange={onPageChange}
                    rightContent={<div style={{ margin: '5px' }}>{totalRecords > 0 ? `${totalRecords} registro(s) encontrado(s)` : null}</div>}
                />
                <If test={modalHistoricoVisible}>
                    <ModalHistorico
                        idNfce={registroSelecionado && registroSelecionado.id}
                        visible={modalHistoricoVisible}
                        onHide={() => this.setState({ modalHistoricoVisible: false })}
                    />
                </If>
            </>
        )

    }
}

export default TabelaNFCe;
