import { tipoCampos } from "../../../../../components/PesquisaAvancada/util/constantes"

export const optionsFiltroAvancado = [
    { label: 'NFC-e', name: 'nro', type: tipoCampos.INTEGER },
    { label: 'Número Sequencial', name: 'numeroVenda', type: tipoCampos.INTEGER },
    { label: 'Cliente', name: 'destNome', type: tipoCampos.STRING },
    { label: 'CPF (sem pontuação)', name: 'destCpf', type: tipoCampos.STRING },
    { label: 'CNPJ (sem pontuação)', name: 'destCnpj', type: tipoCampos.STRING },
    {
        label: 'Status', name: 'status', type: tipoCampos.SELECT,
        optionSelect: [
            { label: "Rejeitada", value: 'REJEITADA' },
            { label: "Transmitida", value: 'TRANSMITIDA' },
            { label: "Corrigida", value: 'CORRIGIDA' },
            { label: "Cancelada", value: 'CANCELADA' },
            { label: "Não enviada", value: 'NAO_ENVIADA' },
            { label: "Denegada", value: 'DENEGADA' },
            { label: "Aguardando autorização", value: 'AGUARDANDO_AUTORIZACAO' },
        ]
    },
];

export const AcoesBtnSalvarNfce = {
    SALVAR_E_TRANSMITIR: 'SALVAR_E_TRANSMITIR',
    SALVAR: 'SALVAR'
}

export const mostrarToast = {
    FALSE: false,
    TRUE: true
}

export const ColorsCard = {
    NAO_ENVIADA: {
        lightColor: '#B3E5FC',
        strongColor: "#006095",
    },
    REJEITADA: {
        lightColor: '#FFCDD2',
        strongColor: "#B71C1C",
    },
    CANCELADA: {
        lightColor: '#E7ECF0',
        strongColor: "#333333",
    },
    AUTORIZADA: {
        lightColor: '#DCEDC8',
        strongColor: "#1B5E20",
    },
    DENEGADA: {
        lightColor: '##E7ECF0',
        strongColor: "#333333",
    }
}

export const infoStatusNfce = {
    REJEITADA: {
        name: 'Rejeitada',
        description: "NFC-e rejeitada pelo Sefaz",
        lightColor: '#FFCDD2',
        strongColor: '#B71C1C',
    },
    TRANSMITIDA: {
        name: 'Transmitida',
        description: "NFC-e transmitida para o Sefaz com sucesso",
        lightColor: '#DCEDC8',
        strongColor: '#1B5E20',
    },
    CANCELADA: {
        name: 'Cancelada',
        description: "NFC-e cancelada",
        lightColor: '#E7ECF0',
        strongColor: '#333333',
    },
    NAO_ENVIADA: {
        name: 'Não enviada',
        description: "A NFC-e ainda não foi transmitida para o Sefaz",
        lightColor: '#B3E5FC',
        strongColor: '#006095',
    },
    DENEGADA: {
        name: 'Denegada',
        description: "NFC-e denegada pelo Sefaz",
        lightColor: '#E7ECF0',
        strongColor: '#333333',
    },
    AGUARDANDO_AUTORIZACAO: {
        name: "Aguardando",
        description: "A NFC-e está sendo processada pelo Sefaz",
        lightColor: '#FFEFE5',
        strongColor: '#9D7A0A',
    }
};

export const Status = {
    REJEITADA: 'REJEITADA',
    TRANSMITIDA: 'TRANSMITIDA',
    CANCELADA: 'CANCELADA',
    NAO_ENVIADA: 'NAO_ENVIADA',
    DENEGADA: "DENEGADA",
    AGUARDANDO_AUTORIZACAO: "AGUARDANDO_AUTORIZACAO"
};

export const StatusNfceCards = {
    CANCELADA: 'CANCELADA',
    AUTORIZADA: 'AUTORIZADA',
    REJEITADA: 'REJEITADA',
    NAO_ENVIADA: 'NAO_ENVIADA'
};

export const Operacao = {
    INSERIR: 'INSERIR',
    EDITAR: 'EDITAR',
    EXCLUIR: 'EXCLUIR',
};

export const TipoDestinatario = {
    FISICA: 'FISICA',
    JURIDICA: 'JURIDICA',
    ESTRANGEIRO: 'ESTRANGEIRO'
}

export const MensagemToast = {
    CRIAR: "NFC-e incluída com sucesso",
    EDITAR: "NFC-e alterada com sucesso",
    EXCLUIR: "NFC-e excluída com sucesso",
}

export const IndPres = {
    NAO_SE_APLICA: 'NAO_SE_APLICA',
    OPERACAO_PRESENCIAL: 'OPERACAO_PRESENCIAL',
    OPERACAO_PELA_INTERNET: 'OPERACAO_PELA_INTERNET',
    OPERACAO_POR_TELEATENDIMENTO: 'OPERACAO_POR_TELEATENDIMENTO',
    OPERACAO_PRESENCIAL_FORA_DO_ESTABELECIMENTO: 'OPERACAO_PRESENCIAL_FORA_DO_ESTABELECIMENTO',
    OUTROS: 'OUTROS'
}

export const TiposDesconto = {
    PERCENTUAL: 'PERCENTUAL',
    VALOR: 'VALOR'
}

export const DocumentosTipos = {
    CPF: 'CPF',
    CNPJ: 'CNPJ'
}

export const TiposAcessorias = {
    PERCENTUAL: 'PERCENTUAL',
    VALOR: 'VALOR'
}

export const CADASTROURL = '/nfces/cadastro';
export const PESQUISAURL = '/nfces';

export const Condicoes = {
    A_VISTA: 'A_VISTA',
    A_PRAZO: 'A_PRAZO'
};

export const formaPagamento = [
    { label: "Dinheiro", value: "DINHEIRO" },
    { label: "Cheque", value: "CHEQUE" },
    { label: "Cartão de crédito", value: "CARTAO_CREDITO" },
    { label: "Cartão de débito", value: "CARTAO_DEBITO" },
    { label: "Crédito na loja", value: "CREDITO_LOJA" },
    { label: "Vale alimentação", value: "VALE_ALIMENTACAO" },
    { label: "Vale refeição", value: "VALE_REFEICAO" },
    { label: "Vale presente", value: "VALE_PRESENTE" },
    { label: "Vale combustível", value: "VALE_COMBUSTIVEL" },
    { label: "Boleto bancário", value: "BOLETO_BANCARIO" },
    { label: "Depósito bancário", value: "DEPOSITO_BANCARIO" },
    { label: "Pagamento instantâneo (PIX)", value: "PAGAMENTO_INSTANTANEO_PIX"},
    { label: "Transferência bancária, Carteira digital", value: "TRANFERENCIA_BANCARIA_CARTEIRA_DIGITAL" },
    { label: "Programa de fidelidade, Cashback, Crédito virtual", value: "PROGRAMA_FIDELIDADE_CASHBACK_CREDITO_VIRTUAL" },
];

export const condicaoDropdown = [
    { label: 'À vista', value: 'A_VISTA' },
    { label: 'A prazo', value: 'A_PRAZO' },
];

export const contaReceberStatus = {
    NAO_RECEBIDA: 'NAO_RECEBIDA',
    PARCIALMENTE_RECEBIDA: 'PARCIALMENTE_RECEBIDA',
    RECEBIDA: 'RECEBIDA',
};

export const quantidadeParcelasDropdown = [
    { label: '1x', value: 1 },
    { label: '2x', value: 2 },
    { label: '3x', value: 3 },
    { label: '4x', value: 4 },
    { label: '5x', value: 5 },
    { label: '6x', value: 6 },
    { label: '7x', value: 7 },
    { label: '8x', value: 8 },
    { label: '9x', value: 9 },
    { label: '10x', value: 10 },
    { label: '11x', value: 11 },
    { label: '12x', value: 12 },
    { label: '13x', value: 13 },
    { label: '14x', value: 14 },
    { label: '15x', value: 15 },
    { label: '16x', value: 16 },
    { label: '17x', value: 17 },
    { label: '18x', value: 18 },
    { label: '19x', value: 19 },
    { label: '20x', value: 20 },
    { label: '21x', value: 21 },
    { label: '22x', value: 22 },
    { label: '23x', value: 23 },
    { label: '24x', value: 24 },
    { label: '25x', value: 25 },
    { label: '26x', value: 26 },
    { label: '27x', value: 27 },
    { label: '28x', value: 28 },
    { label: '29x', value: 29 },
    { label: '30x', value: 30 },
    { label: '31x', value: 31 },
    { label: '32x', value: 32 },
    { label: '33x', value: 33 },
    { label: '34x', value: 34 },
    { label: '35x', value: 35 },
    { label: '36x', value: 36 },
    { label: '37x', value: 37 },
    { label: '38x', value: 38 },
    { label: '39x', value: 39 },
    { label: '40x', value: 40 },
    { label: '41x', value: 41 },
    { label: '42x', value: 42 },
    { label: '43x', value: 43 },
    { label: '44x', value: 44 },
    { label: '45x', value: 45 },
    { label: '46x', value: 46 },
    { label: '47x', value: 47 },
    { label: '48x', value: 48 },
];
