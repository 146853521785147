import React, { Component } from 'react';
import autoBind from 'react-autobind';
import Modal from '../../../../../../../components/Modal';
import { Field, Form } from 'formik';
import Grid from '../../../../../../../components/Grid';
import SingleSelectUnidadeMedida from '../../../../../../../components/Select/SingleSelectUnidadeMedida';
import { helpNFeProd } from '../ModalAliquotasProduto/help';
import InputField from '../../../../../../../components/Input/InputField';
import { copiarObjeto } from '../../../../../../../common/array';
import { formatarMonetario } from '../../../../../../../common/mascara';
import ButtonCancelar, { estadosBotaoCancelar } from '../../../../../../../components/Button/ButtonCancelar';
import ButtonSalvar, { estadosBotaoSalvar } from '../../../../../../../components/Button/ButtonSalvar';
import FormActions from '../../../../../../../components/FormActions';
import FormContent from '../../../../../../../components/FormContent';

class ModalDadosProduto extends Component {
    constructor(props) {
        super(props);

        autoBind(this);

        this.state = {
            values: {
                prod: {},
                imposto: {
                    ICMSUFDest: {},
                    icms: {},
                }
            }
        }
    }

    componentDidMount() {
        this.setState({ values: copiarObjeto(this.props.values) })
    }

    cancelar() {
        this.props.onHide();
    }

    onChangeProd(field, value) {
        let values = { ...this.state.values }
        values.prod[field] = value
        this.setState({ values })
    }

    confirmar() {
        const { informacoesPermissoes, onChange, onHide } = this.props;

        if (informacoesPermissoes.podeEditar) {
            onChange(this.state.values)
            onHide(this.state.values)
        } else {
            onHide()
        }
    }

    render() {
        const { visible, onHide, informacoesPermissoes } = this.props;
        const { values } = this.state;

        return (
            <>
                <Modal
                    header="Dados do produto"
                    visible={visible}
                    onHide={() => onHide()}
                >
                    <Form>
                        <FormActions>
                            <ButtonCancelar
                                estadoBotao={estadosBotaoCancelar.VOLTAR}
                                onClick={this.cancelar}
                            />
                            <ButtonSalvar
                                onClick={this.confirmar}
                                estadoBotao={estadosBotaoSalvar.CONFIRMAR}
                            />
                        </FormActions>
                        <FormContent>
                            <Grid>
                                <Field sm="6" md="3" lg="3" xl="3"
                                    component={InputField}
                                    label="Produto"
                                    value={values.prod.cProd + ' - ' + values.prod.xProd}
                                    disabled
                                />
                                <Field sm="6" md="3" lg="3" xl="3"
                                    component={InputField}
                                    label="Quantidade"
                                    value={values.prod.qCom}
                                    disabled
                                />
                                <Field sm="6" md="3" lg="3" xl="3"
                                    component={InputField}
                                    label="Valor unitário"
                                    value={formatarMonetario(values.prod.vUnCom)}
                                    disabled
                                />
                                <Field sm="6" md="3" lg="3" xl="3"
                                    component={InputField}
                                    label="Valor total"
                                    value={formatarMonetario(values.prod.vProd)}
                                    disabled
                                />
                                <Field sm="6" md="4" lg="4" xl="4"
                                    label='Unidade de medida'
                                    name="unidadeMedida"
                                    component={SingleSelectUnidadeMedida}
                                    value={values.prod.unidadeMedida}
                                    onChange={e => this.onChangeProd('unidadeMedida', e)}
                                    helpMessage={helpNFeProd.unidadeMedida}
                                    {...informacoesPermissoes}
                                />
                                <Field sm="6" md="4" lg="4" xl="4"
                                    component={InputField}
                                    label='Código de barras'
                                    type="number"
                                    name="cEAN"
                                    onChange={e => this.onChangeProd('cEAN', e.target.value)}
                                    value={values.prod.cEAN}
                                    size={14}
                                    helpMessage={helpNFeProd.cEAN}
                                    {...informacoesPermissoes}
                                />
                                <Field sm="6" md="4" lg="4" xl="4"
                                    component={InputField}
                                    label='Nº do Pedido de Compra'
                                    name="xPed"
                                    onChange={e => this.onChangeProd('xPed', e.target.value)}
                                    value={values.prod.xPed}
                                    size={6}
                                    helpMessage={helpNFeProd.xPed}
                                    {...informacoesPermissoes}
                                />
                                <Field sm="6" md="4" lg="4" xl="4"
                                    component={InputField}
                                    label='Item do Ped. de Compra'
                                    type="number"
                                    name="nItemPed"
                                    onChange={e => this.onChangeProd('nItemPed', e.target.value)}
                                    value={values.prod.nItemPed}
                                    size={6}
                                    helpMessage={helpNFeProd.nItemPed}
                                    {...informacoesPermissoes}
                                />
                            </Grid>
                        </FormContent>
                    </Form>
                </Modal>
            </>
        )
    }
}

export default ModalDadosProduto;
