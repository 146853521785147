export const Financas = {
    cores: {
        vermelho: "#b71c1c",
        vermelhoClaro: '#ffcdd2',
        verde: "#1b5e20",
        verdeClaro: '#dcedc8',
        azul: "#006095",
        preto: "#fff0",
        cinza: "#888888"
    },
}

export const Vendas = {
    cores: {
        vermelho: "#b71c1c",
        vermelhoClaro: '#ffcdd2',
        verde: "#1b5e20",
        verdeClaro: '#dcedc8',
        azul: "#006095",
        preto: "#fff0",
        cinza: "#888888"
    },
}

export const Documentos = {
    cores: {
        vermelho: "#b71c1c",
        vermelhoClaro: '#ffcdd2',
        verde: "#1b5e20",
        verdeClaro: '#dcedc8',
        azul: "#006095",
        preto: "#fff0",
        cinza: "#888888"
    },
    corStatus: {
        assinados: "#36a536",
        rejeitados: "#FF3737",
        aguardandoAssinaturas: "#01579B",
        expirados: "#B6531C"
    }

}

export const Estoque = {
    cores: {
        vermelho: "#b71c1c",
        vermelhoClaro: '#ffcdd2',
        verde: "#1b5e20",
        verdeClaro: '#dcedc8',
        azul: "#006095",
        preto: "#fff0",
        cinza: "#888888"
    },
}