import React, { Component } from 'react';

import InitialPage from '../views/PaginaInicial';
import { Route, Switch } from 'react-router-dom';
import { Login } from '../views/autenticacao/Login';
import PrivateRoute from '../components/Route';
import ConfirmacaoSenha from '../views/autenticacao/ConfirmacaoSenha';
import ConfirmacaoEmail from '../views/autenticacao/ConfirmacaoEmail';
import { connect } from 'react-redux';
import AutoCursorWait from '../components/Loading/AutoCursorWait';
import { withRouter } from 'react-router';
import '../common/requisicoes/interceptadores';
import ModalPlanos from '../views/configuracao/Planos/components/ModalPlanos'
import { services } from '../common/constantes/api';
import Manutencao from '../views/util/Manutencao';
import { paginaEmManutencao } from '../common/constantes/manutencao'
import { usuarioPossuiPermissao } from '../common/autenticacao';
import { recursos, permissoes } from '../common/constantes/autorizacao';
import { alert } from '../components/Toast';
import autoBind from 'react-autobind';
import { dispatchAction } from '../common/redux';
import { actionTypes } from '../common/constantes/reduxTypes';
import NoInternetBar from './components/NoInternetBar';
import If from '../components/If';
import { get } from '../common/requisicoes';
import { Growl } from 'primereact/growl';
import Button from '../components/Button';
import { buscarItemLocalStorage, salvarItemLocalStorage } from '../common/localStorage';
import { DADOSLOCALSTORAGE } from '../common/constantes/localStorage';
import { Cadastro } from '../views/autenticacao/Cadastro';
import Modal from '../components/Modal';
import { FcHighPriority } from "react-icons/fc";

class App extends Component {
    constructor(props) {
        super(props);

        autoBind(this);

        this.state = {
            modalPlanosVisible: false,
            mostrarModalTratamentoErro: false,
            beforeInstallPWAEvent: null,
            isMobile: false,
            isTablet: false,
            isDesktop: false,
            instalarPwa: true
        }
    }

    componentDidMount() {
        dispatchAction(actionTypes.POSSUI_INTERNET, window.navigator.onLine)
        window.addEventListener("resize", this.atualizarDispositivo);
        window.addEventListener('offline', this.atualizarEstadoSemConexao);
        window.addEventListener('online', this.atualizarEstadoComConexao);
        window.addEventListener('beforeinstallprompt', this.solicitarInstalacaoPWA);
        this.checarConexao()
        this.atualizarDispositivo()
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.atualizarDispositivo);
        window.removeEventListener('offline', this.atualizarEstadoSemConexao);
        window.removeEventListener('online', this.atualizarEstadoComConexao);
        window.removeEventListener('beforeinstallprompt', this.solicitarInstalacaoPWA);
    }

    async solicitarInstalacaoPWA(e) {
        e.preventDefault();
        const instalacaoJaSolicitada = await buscarItemLocalStorage(DADOSLOCALSTORAGE.instalacaoAplicativoJaSolicitada)
        if (!instalacaoJaSolicitada && this.state.instalarPwa) {
            this.setState({ beforeInstallPWAEvent: e })
            this.exibirModalInstalarApp()
            salvarItemLocalStorage(DADOSLOCALSTORAGE.instalacaoAplicativoJaSolicitada, true)
        }
        this.setState({ instalarPwa: false })
    }

    atualizarEstadoComConexao() {
        dispatchAction(actionTypes.POSSUI_INTERNET, true)
    }

    atualizarEstadoSemConexao() {
        dispatchAction(actionTypes.POSSUI_INTERNET, false)
    }

    checarConexao() {
        setTimeout(() => {
            if (!this.props.possuiInternet) {
                get(`${services.GESTOR}/actuator/health`, null, null, null, false, false)
            }
            this.checarConexao()
        }, 5000)
    }

    atualizarDispositivo() {
        const { isMobile, isTablet, isDesktop } = this.state;
        if (window.innerWidth <= 576 && isMobile === false) {
            this.setState({ isMobile: true, isTablet: false, isDesktop: false })
        } else if ((window.innerWidth > 576 && window.innerWidth <= 768) && isTablet === false) {
            this.setState({ isTablet: true, isMobile: false, isDesktop: false })
        } else if ((window.innerWidth > 768) && isDesktop === false) {
            this.setState({ isDesktop: true, isTablet: false, isMobile: false })
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.planoViolado !== this.props.planoViolado) {
            const podeVisualizarPlano = usuarioPossuiPermissao(recursos.PLANOS, permissoes.VISUALIZAR)
            if (podeVisualizarPlano) {
                this.setState({ modalPlanosVisible: true })
            } else {
                let mensagem = this.props.mensagemErroPlanoViolado.replace("Faça o upgrade do plano agora mesmo!", "Contate o administrador do sistema para fazer o upgrade e continuar utilizando do sistema normalmente")
                alert("Seu plano chegou ao limite!", mensagem)
            }
        }
        if (prevProps.mostrarErros !== this.props.mostrarErros) {
            this.setState({ mostrarModalTratamentoErro: true })
        }

        if (prevState.isMobile !== this.state.isMobile ||
            prevState.isDesktop !== this.state.isDesktop ||
            prevState.isTablet !== this.state.isTablet) {
            const { isMobile, isTablet, isDesktop } = this.state;
            dispatchAction(actionTypes.DISPOSITIVO, { isMobile, isTablet, isDesktop })
        }
    }

    exibirModalInstalarApp() {
        const summary = <span style={{ fontSize: '15px' }}>Este site pode ser instalado</span>;
        const detail = (
            <>
                <div className='subtitle' style={{ fontSize: '13px' }}>
                    Deseja instalar agora?
                </div>
                <div className='button' style={{ marginTop: '10px' }}>
                    <Button label='Instalar o App' onClick={this.instalarApp} />
                </div>

            </>
        )
        this.growl.show({ severity: 'info', summary: summary, detail: detail, sticky: true, life: true });
    }

    instalarApp() {
        this.growl.clear()
        if (this.state.beforeInstallPWAEvent) {
            this.state.beforeInstallPWAEvent.prompt()
        }
    }


    render() {
        const { modalPlanosVisible, isMobile } = this.state;

        return (
            <>
                <AutoCursorWait />

                <If test={!this.props.possuiInternet}>
                    <NoInternetBar />
                </If>

                <Switch>
                    {paginaEmManutencao && <Route path="/*" component={Manutencao} />}
                    <Route exact path='/login'
                        component={Login}
                    />
                    <Route exact path='/registro'
                        component={Cadastro}
                    />
                    <Route exact path='/registro/confirmar_senha'
                        render={props =>
                            <ConfirmacaoSenha
                                {...props}
                                urlConfirmacao={`${services.GESTOR}/v1/credenciais/confirmar`}
                            />
                        }
                        urlConfirmacao={`${services.GESTOR}/v1/credenciais/confirmar_senha`}
                    />
                    <Route exact path='/registro/confirmar_conta'
                        render={props =>
                            <ConfirmacaoSenha
                                {...props}
                                urlConfirmacao={`${services.GESTOR}/v1/credenciais/confirmar`}
                            />
                        }
                    />
                    <Route exact path='/registro/ativar_email'
                        component={ConfirmacaoEmail}
                    />
                    <PrivateRoute validarPermissoes={false}
                        path="*"
                        component={InitialPage}
                    />
                </Switch>
                <ModalPlanos
                    visible={modalPlanosVisible}
                    onHide={() => this.setState({ modalPlanosVisible: false })}
                />
                <Growl
                    className={`popup-instalar-app ${isMobile ? 'mobile' : null}`}
                    ref={(el) => this.growl = el}
                    position='bottomleft'
                    onClose={() => salvarItemLocalStorage(DADOSLOCALSTORAGE.instalacaoAplicativoJaSolicitada, true)}
                />
                <Modal
                    closeOnEsc={true}
                    showCloseIcon={true}
                    visible={this.state.mostrarModalTratamentoErro}
                    onHide={() => this.setState({ mostrarModalTratamentoErro: false })}
                    styleModal={{ width: 'auto', minWidth: '320px' }}
                >
                    <div style={{ fontWeight: 'bold', fontSize: '16px', borderBottom: '1px solid #ddd', paddingBottom: '10px', display: 'flex', alignItems: 'center' }}>
                        <FcHighPriority size={20} style={{ marginRight: '5px' }} />
                        {this.props.erros && this.props.erros.title}
                    </div>

                    <div style={{ wordBreak: 'break-word', padding: '1.2em 0em' }}>
                        <span dangerouslySetInnerHTML={{ __html: this.props.erros && this.props.erros.error }} />
                    </div>

                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button
                            label="Confirmar"
                            color="primary"
                            onClick={() => this.setState({ mostrarModalTratamentoErro: false })}
                        />
                    </div>
                </Modal>
            </>
        )
    }
}

App.defaultProps = {
    mensagemErroPlanoViolado: ""
}

const mapStateToProps = state => ({
    planoViolado: state.planoViolado.mostrarModalPlanoViolado,
    mensagemErroPlanoViolado: state.planoViolado.mensagemErroPlanoViolado,
    possuiInternet: state.possuiInternet,

    mostrarErros: state.mostrarErroTratado.mostrarModalTratamentoErro,
    erros: state.mostrarErroTratado.erros
})

export default withRouter(connect(mapStateToProps)(App))
