import StringMask from 'string-mask';
import { formatos } from '../constantes/mascaras';

export function inserirMascara(value, mask, reverse = false) {
        var formatter = new StringMask(mask, { reverse });
        var result = formatter.apply(value);

        return result;
}

export function manterApenasNumeros(str) {
        if (!str)
                return null
        let mascara = str;
        for (let i = 0; i < str.length; i++) {
                mascara = mascara.replace(/[^\d]/, '');
        }
        return mascara;
}

export function formatarMonetario(valor) {
        if (!valor) {
                return 'R$ 0,00'
        }
        return Number(valor).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
}

export function formatarDecimais(valor) {
        return Number(valor).toLocaleString('pt-BR', { style: 'decimal', currency: 'BRL', minimumFractionDigits: '2' })
}

export function formatarTelefone(telefone) {
        const telefoneSemMascara = manterApenasNumeros(telefone);
        return telefoneSemMascara && telefoneSemMascara.length > 10 ? inserirMascara(telefoneSemMascara, formatos.CELULAR) : inserirMascara(telefoneSemMascara, formatos.TELEFONE);
}

export function buscarMascaraTelefone(telefone) {
        if (telefone && manterApenasNumeros(telefone).length > 10) {
                return "(00) 00000-0000"
        }
        return "(00) 0000-00000"
}