import React, { Component } from 'react';
import { ProgressBar } from 'primereact/progressbar';
import { formatarMonetario } from '../../../../../../../../../common/mascara';
import { BsGraphDown } from 'react-icons/bs';
import FalhaAoCarregarAsInformacoes from '../../../../../FalhaAoCarregarAsInformacoes';
import { Financas } from '../../../../../../../../util/constantes'
import '../styles/index.css'

const messages = {
    helpMessage: "Valor total de suas despesas cadastradas no período"
}

export default class CardDespesas extends Component {

    montarTituloCard = () => {
        return (
            <div className="card-resultado-financeiro-dashboard-titulo">
                <div
                    className="card-resultado-financeiro-dashboard-titulo-descricao"
                    title={messages.helpMessage}
                >
                    Despesas no período
                </div>
            </div>
        )
    }

    render() {
        if (this.props.loading) {
            return (
                <div className="card-resultado-financeiro-dashboard">
                    {this.montarTituloCard()}
                    <div className="card-resultado-financeiro-dashboard-content">
                        <ProgressBar mode="indeterminate" style={{ height: '3px', width: '100%', margin: '10px 0px' }} />
                    </div>
                </div>
            )
        } else if (this.props.error) {
            return (
                <div className="card-resultado-financeiro-dashboard">
                    {this.montarTituloCard()}
                    <div className="card-resultado-financeiro-dashboard-content">
                        <FalhaAoCarregarAsInformacoes />
                    </div>
                </div>
            )
        } else {
            const { despesasTotal, despesasPagas, despesasAPagar } = this.props;
            return (
                <div className="card-resultado-financeiro-dashboard">
                    {this.montarTituloCard()}
                    <div className="card-resultado-financeiro-dashboard-content">
                        <div className="card-resultado-financeiro-dashboard-icone">
                            <BsGraphDown size={40} color={Financas.cores.vermelho} />
                        </div>
                        <div className="card-resultado-financeiro-dashboard-valor">
                            <div className="card-resultado-financeiro-dashboard-valor-principal" title="Valor total a pagar (Pagas + A pagar)" style={{ color: Financas.cores.vermelho }}>
                                {formatarMonetario(despesasTotal)}
                            </div>
                            <div className="card-resultado-financeiro-dashboard-valor-secundario">
                                <span className="card-resultado-financeiro-dashboard-valor-secundario-efetuado" title="Valor já pago do montante total">
                                    <label>Pagas</label>
                                    <div>
                                        {formatarMonetario(despesasPagas)}
                                    </div>
                                </span>
                                <span className="card-resultado-financeiro-dashboard-valor-secundario-pendente" title="Valor que falta pagar do montante total">
                                    <label>A pagar</label>
                                    <div>
                                        {formatarMonetario(despesasAPagar)}
                                    </div>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    }
}
