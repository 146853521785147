import React, { Component } from 'react';
import { withFormik, Field } from 'formik';
import * as Yup from 'yup';
import FormGroup from '../../../../../../components/FormGoup';
import Grid from '../../../../../../components/Grid';
import LayoutMenuSuperior from '../../../../../../components/Layout/LayoutMenuSuperior';
import MenuSuperior from '../../../../../../components/MenuSuperior';
import ButtonNovo, { estadosBotaoNovo } from '../../../../../../components/Button/ButtonNovo';
import ButtonSalvar from '../../../../../../components/Button/ButtonSalvar';
import ButtonCancelar, { estadosBotaoCancelar } from '../../../../../../components/Button/ButtonCancelar';
import InputField from '../../../../../../components/Input//InputField';
import ButtonExcluir from '../../../../../../components/Button/ButtonExcluir';
import { services } from '../../../../../../common/constantes/api';
import autoBind from 'react-autobind'
import Paper from '../../../../../../components/Paper';
import { validarFormulario } from '../../../../../util';
import { mensagensDeValidacao } from '../../../../../../common/constantes/mensagens';
import { helpDocumentoSignatarioForm } from './help';
import { gerarUUID } from '../../../../../../common/manipulacaoDeString';
import Checkbox from '../../../../../../components/Input/Checkbox';
import { papelSignatarios } from './util/constantes';
import SingleSelectPessoa from '../../../../../../components/Select/SingleSelectPessoa';
import SingleSelectPessoaEmail from '../../../../../../components/Select/SingleSelectPessoaEmail';
import Dropdown from '../../../../../../components/Select/Dropdown';
import { asyncBuscaRelacaoPessoaEmails } from '../../../../requests';
import If from '../../../../../../components/If';
import { asyncGetPessoaEmais } from '../requests';
import DocumentoConfirmacaoSignatario from './components/DocumentoConfirmacaoSignatario';
import { estadosCadastro } from '../../../../../../common/constantes/autorizacao';

const initialValue = {
    id: '',
    pessoa: null,
    email: '',
    nome: '',
    papel: 'PARTE',
    pessoaEmail: null,
    signatarioCadastrado: false,
    confirmacoes: [],
}

class DocumentoSignatariosForm extends Component {

    constructor(props) {
        super(props);

        autoBind(this);

        this.state = {
            inserindoNovoRegistro: false,
            resetarPesquisaEmails: false
        }
    }

    componentDidMount() {
        const { registroSelecionado } = this.props
        this.setState({ inserindoNovoRegistro: !registroSelecionado });
        if (registroSelecionado && registroSelecionado.email && registroSelecionado.pessoa && !registroSelecionado.pessoaEmail) {
            asyncBuscaRelacaoPessoaEmails(registroSelecionado.pessoa.value, ({ data }) => {
                for (const item of data.content) {
                    if (item.email === registroSelecionado.email) {
                        this.props.atualizarRegistroSelecionado({ ...registroSelecionado, pessoaEmail: { label: item.email, value: item.id } })
                    }
                }
            })
        }
    }


    novo() {
        this.props.onNovoClick()
        this.props.resetForm({ values: initialValue })
        this.setState({ inserindoNovoRegistro: true })
    }

    excluir() {
        this.props.excluirRegistro(this.props.registroSelecionado)
        this.props.onHide()
    }

    async salvar(e, novoOnSuccess) {

        this.props.handleSubmit()

        if (await validarFormulario(this.props)) {
            if (this.state.inserindoNovoRegistro) {
                this.criarRegistro(novoOnSuccess)
            } else {
                this.atualizarRegistro(novoOnSuccess)
            }
        }
    }

    onChangePessoa(e) {
        this.props.setFieldValue('pessoa', e)
        if (e) {
            this.props.setFieldValue('nome', e.label)
            this.props.setFieldValue('pessoaEmail', null)
            this.props.setFieldValue('email', null)
            this.setState({ resetarPesquisaEmails: !this.state.resetarPesquisaEmails })

            asyncGetPessoaEmais(e.value, ({ data }) => {
                if (data.content.length > 0) {
                    let encontrou = false
                    data.content.forEach(e => {
                        if (e.favorito) {
                            this.props.setFieldValue('pessoaEmail', { label: e.email, value: e.id })
                            this.props.setFieldValue('email', e.email)
                            encontrou = true
                        }
                    })
                    if (!encontrou) {
                        this.props.setFieldValue('pessoaEmail', { label: data.content[0].email, value: data.content[0].id })
                        this.props.setFieldValue('email', data.content[0].email)
                    }
                }

            })

        }
    }

    onChangePessoaEmail(e) {
        this.props.setFieldValue('pessoaEmail', e)
        if (e) {
            this.props.setFieldValue('email', e.label)
        } else {
            this.props.setFieldValue('email', null)
        }
    }

    criarRegistro(novoOnSuccess) {
        this.props.inserirRegistro({ ...this.props.values, idTemporario: gerarUUID() })
        if (novoOnSuccess) {
            novoOnSuccess();
        } else {
            this.props.onHide()
        }
    }

    atualizarRegistro(novoOnSuccess) {
        this.props.atualizarRegistro(this.props.values);
        if (novoOnSuccess) {
            novoOnSuccess();
        } else {
            this.props.onHide()
        }
    }

    cancelar() {
        if (this.props.dirty) {
            this.props.handleReset()
        } else {
            this.props.onHide()
        }
    }

    onChangeSignatarioCadastrado(e) {
        if (!e.checked) {
            this.props.setFieldValue('pessoa', null);
        }
        this.props.setFieldValue('signatarioCadastrado', e.checked);
    }

    render() {
        const { values, informacoesPermissoes, dirty, disabled, estadoCadastro } = this.props;
        const { inserindoNovoRegistro } = this.state;
        const pessoaId = values.pessoa ? values.pessoa.value : null

        const estadoBotaoNovo = dirty ? estadosBotaoNovo.SALVAR_E_NOVO : estadosBotaoNovo.NOVO;
        const onClickNovo = dirty ? (e) => this.salvar(e, this.novo) : this.novo;

        const podeInserir = estadoCadastro === estadosCadastro.INCLUSAO ? informacoesPermissoes.podeInserir : informacoesPermissoes.podeEditar
        
        return (
            <React.Fragment>
                <MenuSuperior
                    isModal={true}
                >
                    <ButtonCancelar
                        {...informacoesPermissoes}
                        estadoBotao={dirty ? estadosBotaoCancelar.CANCELAR : estadosBotaoCancelar.VOLTAR}
                        onClick={this.cancelar}
                    />
                    <ButtonSalvar
                        {...informacoesPermissoes}
                        disabled={!dirty}
                        onClick={this.salvar}
                    />

                    <ButtonNovo
                        onClick={onClickNovo}
                        hidden={!dirty && !values.id}
                        disabled={disabled}
                        estadoBotao={estadoBotaoNovo}
                        podeInserir={podeInserir}
                    />
                    <ButtonExcluir
                        hidden={inserindoNovoRegistro}
                        podeExcluir={informacoesPermissoes.podeEditar}
                        disabled={disabled}
                        onClick={this.excluir}
                    />
                </MenuSuperior>
                <LayoutMenuSuperior isModal={true}>
                    <Paper
                        dirty={dirty}
                        {...informacoesPermissoes}
                        childsOnly={true}
                    >
                        <FormGroup>
                            <Grid>
                                <Field sm="12" md="12" lg="12" xl="12"
                                    component={Checkbox}
                                    label="Signatário já cadastrado no sistema"
                                    name="signatarioCadastrado"
                                    helpMessage={helpDocumentoSignatarioForm.signatarioCadastrado}
                                    onChange={this.onChangeSignatarioCadastrado}
                                    checked={values.signatarioCadastrado}
                                    disabled={disabled}
                                    {...informacoesPermissoes}
                                />
                                <Field sm="12" md="12" lg="4" xl="4"
                                    component={SingleSelectPessoa}
                                    label='Pessoa '
                                    obrigatorio
                                    name="pessoa"
                                    url={`${services.GESTOR}/v1/documentos/relacoes/pessoas`}
                                    value={values.pessoa}
                                    onChange={this.onChangePessoa}
                                    helpMessage={helpDocumentoSignatarioForm.pessoa}
                                    hidden={!values.signatarioCadastrado}
                                    disabled={disabled}
                                    {...informacoesPermissoes}
                                />
                                <If test={values.pessoa}>
                                    <Field sm="12" md="12" lg="4" xl="4"
                                        component={SingleSelectPessoaEmail}
                                        label='E-mail '
                                        obrigatorio
                                        name="pessoaEmail"
                                        url={`${services.GESTOR}/v1/documentos/relacoes/pessoas/${pessoaId}/emails`}
                                        value={values.pessoaEmail}
                                        idPessoa={pessoaId}
                                        onChange={this.onChangePessoaEmail}
                                        helpMessage={helpDocumentoSignatarioForm.email}
                                        resetarPesquisa={this.state.resetarPesquisaEmails}
                                        disabled={disabled}
                                        {...informacoesPermissoes}
                                    />
                                </If>
                                <Field sm="12" md="12" lg="4" xl="4"
                                    component={InputField}
                                    label='Nome '
                                    obrigatorio
                                    name="nome"
                                    size={255}
                                    helpMessage={helpDocumentoSignatarioForm.nome}
                                    {...informacoesPermissoes}
                                    disabled={disabled}
                                    hidden={values.signatarioCadastrado}
                                />
                                <Field sm="12" md="12" lg="4" xl="4"
                                    component={InputField}
                                    label='E-mail '
                                    obrigatorio
                                    name="email"
                                    type='email'
                                    size={255}
                                    helpMessage={helpDocumentoSignatarioForm.email}
                                    hidden={values.signatarioCadastrado}
                                    disabled={disabled}
                                    {...informacoesPermissoes}
                                />
                                <Field sm="12" md="12" lg="4" xl="4"
                                    component={Dropdown}
                                    label='Assinar como '
                                    obrigatorio
                                    options={papelSignatarios}
                                    name="papel"
                                    value={values.papel}
                                    onChange={e => this.props.setFieldValue('papel', e.value)}
                                    helpMessage={helpDocumentoSignatarioForm.papel}
                                    disabled={disabled}
                                    {...informacoesPermissoes}
                                />
                            </Grid>
                            <DocumentoConfirmacaoSignatario
                                value={values.confirmacoes}
                                onChange={confirmacoes => this.props.setFieldValue('confirmacoes', confirmacoes)}
                                estadoCadastro={estadoCadastro}
                                disabled={disabled}
                                informacoesPermissoes={informacoesPermissoes}
                            />
                        </FormGroup>
                    </Paper>
                </LayoutMenuSuperior>
            </React.Fragment>
        )
    }
}

DocumentoSignatariosForm = withFormik({

    enableReinitialize: true,
    validateOnChange: false,

    mapPropsToValues(props) {
        if (props.registroSelecionado) {
            return { ...props.registroSelecionado, signatarioCadastrado: Boolean(props.registroSelecionado.pessoa) }
        }

        return { ...initialValue }
    },

    validate(values, props) {
        let errors = {}
        if (values.signatarioCadastrado) {
            if (!values.pessoa) {
                errors.pessoa = mensagensDeValidacao.OBRIGATORIO
            }
            if (!values.pessoaEmail) {
                errors.pessoaEmail = mensagensDeValidacao.OBRIGATORIO
            }
        } else {
            if (!values.nome) {
                errors.nome = mensagensDeValidacao.OBRIGATORIO
            }
            if (!values.email) {
                errors.email = mensagensDeValidacao.OBRIGATORIO
            }
        }

        props.registros.forEach(registro => {
            const idRegistro = registro.id || registro.idTemporario
            const idValues = values.id || values.idTemporario

            if ((idRegistro !== idValues) && registro.email === values.email) {
                errors.pessoaEmail = 'E-mail já utilizado para outro signatário.'
                errors.email = 'E-mail já utilizado para outro signatário.'
            }
        })

        return errors
    },

    validationSchema: Yup.object().shape({
        papel: Yup.string().nullable().required(mensagensDeValidacao.OBRIGATORIO),
        email: Yup.string().email("Informe um e-mail válido").nullable().required(mensagensDeValidacao.OBRIGATORIO)
    }),
})(DocumentoSignatariosForm);

export default DocumentoSignatariosForm
