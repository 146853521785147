import React from 'react';

export function Badge(fontColor, backgroundColor, description = "") {
    return (
        <span style={{
            backgroundColor: backgroundColor,
            color: fontColor,
            fontWeight: 'bold',
            fontSize: '13px',
            borderRadius: '3px',
            padding: '3px 10px',
        }}>
            <span style={{ width: '100%', textAlign: 'center', whiteSpace: 'pre'}}>{description}</span>
        </span>
    )
}
