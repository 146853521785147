import React, { Component } from 'react';
import Select from 'react-select';
import autoBind from 'react-autobind';
import propTypes from 'prop-types';
import Col from '../../Col';
import { renderizarValidacao } from '../../../common/tratamentoDeErro/validacoesDeCampos';
import {
    buscarDisabledDeAcordoComAsPermissoes,
    buscarHiddenDeAcordoComAsPermissoes
} from "../../../common/autorizacao/manipulacaoDeComponentes";
import { usuarioPossuiModulos } from "../../../common/autenticacao";
import { asyncGetOpcoesSelect } from "./requests";
import { buscarOption, converterRegistrosParaOptions, filtrarOpcoesRepetidas } from "./utils";
import { removerCaracteresInvalidosRsql } from '../../../common/rsql';
import MenuList from '../components/FastList'

const customStyles = {
    control: (provided, state) => ({
        ...provided,
        height: !state.hasValue ? 33 : undefined,
        border: state.selectProps.errors && state.selectProps.touched ? '1px solid #ff0000 !important' : '1px solid #a6a6a6 !important',
        backgroundColor: '#fff',
        opacity: state.isDisabled ? '0.5' : '1',
        ...dot(state.selectProps.errors, state.selectProps.touched),
    }),
    menu: style => ({
        ...style,
        marginTop: 0,
        marginBottom: 0
    }),
    menuList: (styles) => ({
        ...styles,
        maxHeight: 200
    }),
    multiValueLabel: (styles, state) => ({
        ...styles,
        // width: state.data.label && (state.data.label.length >= 20) ? '225px' : '100%',
        wordWrap: 'break-word',
        textOverflow: 'ellipsis'
    })
};
const dot = (errors, touched) => ({
    ':hover': {
        border: errors && touched ? '1px solid #ff0000 !important' : '1px solid black !important',
        cursor: 'pointer'
    },
});

export default class MultipleSelect extends Component {
    render() {

        const { field, form, ...rest } = this.props;

        return (
            <React.Fragment>
                <ReactSelect
                    {...field}
                    {...rest}
                    dirty={form && form.dirty}
                    errors={form && form.errors[field.name]}
                    touched={form && form.touched[field.name]}
                    value={this.props.value}
                />
            </React.Fragment>
        );
    }
}

class ReactSelect extends Component {

    constructor(props) {
        super(props);

        autoBind(this);

        this.state = {
            inputValue: "",
            idsJaPesquisados: [],
            options: [],
            validouModulo: true,
            timeOutPesquisa: null,
            pageSelecionada: 0,
            totalPages: null,
            loading: false,
            buscouTodosOsRegistros: false
        };
    }

    async componentDidMount() {
        const { value, modulosEspecificos } = this.props
        const { pageSelecionada } = this.state
        const validouModulo = usuarioPossuiModulos(modulosEspecificos);
        this.setState({ validouModulo })
        if (value) {
            this.atribuirNovasOpcoes(value)
        }
        if (validouModulo) {
            setTimeout(() => {
                this.buscarRegistros("", pageSelecionada)
            }, 1000)

        }
    }

    buscarRegistros(inputValue, pageSelecionada) {
        const { montarLabel, buscarUrlPesquisa } = this.props
        this.setState({ loading: true })
        asyncGetOpcoesSelect(buscarUrlPesquisa(removerCaracteresInvalidosRsql(inputValue), pageSelecionada), ({ data: registros }) => {
            const { options } = this.state
            const novasOptions = converterRegistrosParaOptions(registros.content, montarLabel)
            this.setState({
                options: filtrarOpcoesRepetidas(options, novasOptions),
                loading: false,
                pageSelecionada,
                totalPages: registros.totalPages,
                buscouTodosOsRegistros: inputValue === "" && registros.totalPages === 1
            })
        }, () => {
            this.setState({ loading: false })
        })
    }

    atribuirNovasOpcoes(novasOpcoes) {
        const { options } = this.state
        let novasOpcoesFiltradas = []
        for (const novaOpcao of novasOpcoes) {
            if (!buscarOption(options, novaOpcao)) {
                novasOpcoesFiltradas.push(novaOpcao)
            }
        }
        if (novasOpcoesFiltradas.length > 0) {
            this.setState({ options: [...options, ...novasOpcoesFiltradas] })
        }
    }

    componentDidUpdate(prevProps) {
        const { value } = this.props
        if (value && prevProps.value !== value) {
            this.atribuirNovasOpcoes(value)
        }
    }

    onInputChange(inputValue) {
        const { timeOutPesquisa, buscouTodosOsRegistros } = this.state
        if (inputValue && !buscouTodosOsRegistros) {
            clearTimeout(timeOutPesquisa)
            this.setState({
                inputValue,
                timeOutPesquisa: setTimeout(() => {
                    this.buscarRegistros(inputValue, 0)
                }, 500)
            })
        } else {
            this.setState({ inputValue });
        }
    }

    onScrollToBottom() {

        const { pageSelecionada, totalPages, inputValue, buscouTodosOsRegistros } = this.state

        if (!buscouTodosOsRegistros) {
            const page = pageSelecionada + 1
            if (totalPages && page < totalPages) {
                this.buscarRegistros(inputValue, page)
            }
        }
    }

    filterOption(e) {
        return (e.label || "").toLowerCase().includes(this.state.inputValue.toLowerCase());
    }

    getColStyle() {
        const { podeVisualizar, hidden, colStyle } = this.props;
        if (buscarHiddenDeAcordoComAsPermissoes(podeVisualizar, hidden))
            return { display: 'none', ...colStyle };
        return colStyle
    }


    montarTitulo() {
        const { obrigatorio, label } = this.props;

        if (obrigatorio) {
            return <label title={this.props.helpMessage}> {label} <b style={{ fontSize: '18px', lineHeight: '5px' }} > *</b> </label>
        }

        return <label title={this.props.helpMessage}> {label} </label>
    }

    render() {
        if (!this.state.validouModulo) return null;

        const {
            sm, md, lg, xl, isClearable, errors, touched, podeInserir, podeEditar, estadoCadastro, disabled, placeholder, onChange, value, label,
            noOptionsMessage
        } = this.props;

        const { options, inputValue, loading } = this.state;

        const desabilitarSelect = buscarDisabledDeAcordoComAsPermissoes(podeInserir, podeEditar, estadoCadastro, disabled);
        return (
            <Col
                sm={sm} md={md} lg={lg} xl={xl} style={this.getColStyle(this.props)}>
                {label && this.montarTitulo()}
                <Select
                    isMulti
                    isLoading={loading}
                    styles={customStyles}
                    inputValue={inputValue}
                    isClearable={isClearable}
                    options={options}
                    closeMenuOnSelect={true}
                    onInputChange={this.onInputChange}
                    isDisabled={desabilitarSelect}
                    placeholder={placeholder}
                    noOptionsMessage={noOptionsMessage}
                    {...this.props}
                    value={value}
                    onChange={onChange}
                    onMenuScrollToBottom={this.onScrollToBottom}
                    filterOption={this.filterOption}
                    components={options.length > 0 ? { MenuList } : undefined}
                />
                {errors && renderizarValidacao(errors, touched)}
            </Col>
        )
    }
}

ReactSelect.defaultProps = {
    noOptionsMessage: () => "Nenhum elemento encontrado",
    loadingMessage: () => 'Por favor, aguarde...',
    placeholder: 'Clique para selecionar',
    defaultOptions: false,
    cacheOptions: true,
    menuPlacement: 'auto',
    className: 'react-select-base',
    classNamePrefix: 'reactSelect',
    closeMenuOnSelect: false,
    disabled: false,
    podeVisualizar: true
};

ReactSelect.propTypes = {
    /** Quando definido como true, os resultados de loadOptions() serão carregados automaticamente antes do usuário clicar para pesquisar.  */
    defaultOptions: propTypes.bool,

    /** Quando definido como true, os dados carregados serão armazenados em cache. O cache permanecerá até o valor cacheOptions sofrer alterações. */
    cacheOptions: propTypes.bool,

    /** Função executada quando o valor do input altera de estado. */
    onInputChange: propTypes.func,

    /** Função que retorna uma Promisse, que é o conjunto de opções a ser usado quando esta é resolvida. É executada assim que o componente é montado e a cada vez que o usuário filtrar a pesqusia. */
    loadOptions: propTypes.func,

    /** texto a ser exibido quando as opções estão sendo carregadas. */
    loadingMessage: propTypes.func,
};
