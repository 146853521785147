import React, { Component } from 'react';
import ButtonNovo from '../../../components/Button/ButtonNovo';
import { usuarioPossuiPermissao } from '../../../common/autenticacao';
import { recursos, permissoes } from '../../../common/constantes/autorizacao';
import autoBind from 'react-autobind';
import { buscarTodasAsCorretoras, deletarCorretora } from './requests';
import ModalCorretora from './components/ModalCorretora';
import CardCorretora from './components/CardCorretora';
import Grid from '../../../components/Grid';
import { confirm } from '../../../components/Toast';
import { removerElementoPorId } from '../../../common/array';
import If from '../../../components/If';
import Form from '../../../components/Form';
import FormActions from '../../../components/FormActions';
import FormContent from '../../../components/FormContent';
import NenhumRegistroEncontrado from '../../../components/NenhumRegistroEncontrado';

class Corretoras extends Component {

    constructor(props) {

        super(props);

        autoBind(this);

        this.state = {
            podeInserir: usuarioPossuiPermissao(recursos.INVESTIDOR_CORRETORAS, permissoes.INSERIR),
            podeVisualizarCorretoras: usuarioPossuiPermissao(recursos.INVESTIDOR_CORRETORAS, permissoes.VISUALIZAR),

            exibirModalCorretora: false,
            corretoras: [],
            corretoraSelecionada: null,
        }
    }

    componentDidMount() {
        this.buscarCorretoras();
    }

    buscarCorretoras() {
        buscarTodasAsCorretoras(({ data }) => {
            this.setState({ corretoras: data.content });
        })
    }

    editarCorretora(corretora) {
        this.setState({
            corretoraSelecionada: corretora,
            exibirModalCorretora: true
        });
    };

    excluirCorretora(corretora) {
        confirm('Confirmação', 'Tem certeza que deseja excluir a corretora?', async () => {
            await deletarCorretora(corretora.id, () => {
                this.setState({ corretoras: removerElementoPorId(this.state.corretoras, corretora, 'id') });
            })
        })
    }

    renderizarCorretoras() {
        const { podeVisualizarCorretoras } = this.state
        let corretoras = this.state.corretoras;
        if (corretoras.length === 0) {
            return <NenhumRegistroEncontrado message='Nenhuma corretora encontrada' />
        }

        return corretoras?.map((corretora, key) => {
            return <CardCorretora
                key={key}
                corretora={corretora}
                editarCorretora={this.editarCorretora}
                excluirCorretora={this.excluirCorretora}
                habilitarVerDetalhes={podeVisualizarCorretoras}
            />
        });

    }

    onHideModalCorretora() {
        this.buscarCorretoras()
        this.setState({ exibirModalCorretora: false, corretoraSelecionada: null })
    }

    render() {
        const {exibirModalCorretora, corretoraSelecionada} = this.state
        return (
            <>
                <Form header="Corretoras e contas">
                    <FormActions>
                        <ButtonNovo
                            label="Nova corretora"
                            onClick={() => this.setState({ exibirModalCorretora: true })}
                            podeInserir={this.state.podeInserir}
                        />
                    </FormActions>
                    <FormContent>
                        <Grid>
                            {this.renderizarCorretoras()}
                        </Grid>
                    </FormContent>
                </Form>
                <If test={exibirModalCorretora}>
                    <ModalCorretora
                        visible={exibirModalCorretora}
                        onHide={this.onHideModalCorretora}
                        registroSelecionado={corretoraSelecionada}
                        buscarCorretoras={this.buscarCorretoras}
                    />
                </If>
            </>
        )
    }
}

export default Corretoras;
