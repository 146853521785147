import { services } from '../../../../common/constantes/api';
import { del, get, exibirToast } from '../../../../common/requisicoes';

export async function asyncDeleteOrganizacao(organizacaoId, onSuccess, onError) {
    await del(`${services.GESTOR}/v1/organizacoes/${organizacaoId}`, null, exibirToast(onSuccess, "Operação realizada com sucesso"), onError);
}

export async function asyncGetPesquisaOrganizacoes(url, onSuccess, onError) {
    await get(url, null, exibirToast(onSuccess), onError);
}
