import React, { Component } from 'react';
import { withFormik, Field } from 'formik';
import * as Yup from 'yup';
import Button from '../../../../components/Button';
import Grid from '../../../../components/Grid';
import ButtonNovo, { estadosBotaoNovo } from '../../../../components/Button/ButtonNovo';
import { withRouter } from 'react-router';
import ButtonSalvar, { estadosBotaoSalvar } from '../../../../components/Button/ButtonSalvar';
import ButtonCancelar, { estadosBotaoCancelar } from '../../../../components/Button/ButtonCancelar';
import InputField from '../../../../components/Input/InputField';
import { mensagensDeValidacao } from '../../../../common/constantes/mensagens';
import { asyncGetSetor, asyncDeleteSetor, asyncUpdateSetor, asyncCreateSetor, asyncGetSetorMesmoNome } from '../requests';
import { recursos, permissoes, estadosCadastro } from '../../../../common/constantes/autorizacao';
import { confirmarExclusao } from '../../../util/exclusaoDeRegistros';
import Prompt from '../../../../components/Route/Prompt';
import ButtonExcluir from '../../../../components/Button/ButtonExcluir';
import autoBind from "react-autobind";
import { validarUUID } from '../../../../common/manipulacaoDeString';
import { atualizarUrl, metodosAtualizarUrl, voltarParaAPesquisa, validarFormulario } from '../../../util';
import { helpSetorForm } from './help';
import { usuarioPossuiPermissao } from '../../../../common/autenticacao';
import { keyFilterConsultaRsql } from '../../../../common/rsql';
import Form from '../../../../components/Form';
import FormActions from '../../../../components/FormActions';
import FormContent from '../../../../components/FormContent';

const initialValue = {
    id: '',
    nome: '',
}

const CADASTROURL = '/setores/cadastro';
const PESQUISAURL = '/setores';

class SetorForm extends Component {

    constructor(props) {
        super(props);

        autoBind(this);

        this.state = {
            podeInserir: usuarioPossuiPermissao(recursos.SETORES, permissoes.INSERIR),
            podeEditar: usuarioPossuiPermissao(recursos.SETORES, permissoes.EDITAR),
            podeExcluir: usuarioPossuiPermissao(recursos.SETORES, permissoes.EXCLUIR),
            page: 0
        }
    }

    componentDidMount() {
        const id = this.props.match.params.id;

        if (validarUUID(id) && !this.props.isModal) {
            this.asyncSelectRegistro(id)
        }
    }

    buscarEstadoCadastro() {
        return this.props.values.id ? estadosCadastro.EDICAO : estadosCadastro.INCLUSAO
    }

    novo() {
        atualizarUrl(this.props.history, CADASTROURL, null, metodosAtualizarUrl.POP)
        this.props.resetForm({ values: initialValue })
    }

    excluir() {
        confirmarExclusao(this.asyncDeleteRegistro);
    }

    async salvar(e, novoOnSuccess) {
        this.props.handleSubmit();

        if (await validarFormulario(this.props)) {
            if (await this.validarNome()) {
                let dadosFormulario = this.props.values;

                if (this.props.values.id) {
                    this.asyncUpdateRegistro(dadosFormulario, novoOnSuccess);
                } else {
                    this.asyncCreateRegistro(dadosFormulario, novoOnSuccess);
                }
            } else {
                this.props.setFieldError('nome', 'O nome informado já existe')
            }
        }
    }

    async validarNome() {
        let nomeJaExiste = false
        const { values } = this.props
        await asyncGetSetorMesmoNome(values.nome, (e) => {
            if (e.data.content.length > 0 && e.data.content[0].id !== values.id) {
                nomeJaExiste = true
            }
        })
        return !nomeJaExiste
    }

    cancelar() {
        if (this.props.dirty) {
            this.props.resetForm({ values: this.props.initialValues })
        } else if (this.props.isModal) {
            this.fecharModal()
        } else {
            voltarParaAPesquisa(this.props.history, PESQUISAURL)
        }
    }

    fecharModal() {
        if (this.props.values.id) {
            this.props.hideModal(this.props.values);
        } else {
            this.props.hideModal();
        }
    }

    async asyncDeleteRegistro() {
        await asyncDeleteSetor(this.props.values.id, () => {
            this.props.resetForm()
            voltarParaAPesquisa(this.props.history, PESQUISAURL);
        });
    }

    async asyncUpdateRegistro(dadosFormulario, novoOnSuccess) {
        await asyncUpdateSetor(dadosFormulario, () => {
            if (novoOnSuccess) {
                novoOnSuccess();
            } else {
                this.props.resetForm({ values: this.props.values })
            }
        })
    }

    async asyncCreateRegistro(dadosFormulario, novoOnSuccess) {
        await asyncCreateSetor(dadosFormulario, ({ data: setor }) => {
            if (novoOnSuccess) {
                novoOnSuccess();
            } else {
                this.props.resetForm({ values: { ...this.props.values, id: setor.id } })
                atualizarUrl(this.props.history, CADASTROURL, setor.id, metodosAtualizarUrl.POP);
            }
        })
    }

    async asyncSelectRegistro(idSetor) {
        await asyncGetSetor(idSetor, ({ data: setor }) => {
            this.props.resetForm({ values: setor });

            if (!this.props.isModal) {
                atualizarUrl(this.props.history, CADASTROURL, setor.id, metodosAtualizarUrl.POP);
            }
        }, () => {
            this.novo()
        })
    }

    render() {

        const informacoesPermissoes = {
            estadoCadastro: this.buscarEstadoCadastro(),
            podeInserir: this.state.podeInserir,
            podeEditar: this.state.podeEditar,
            podeExcluir: this.state.podeExcluir
        };

        const { values, isModal, dirty } = this.props;

        const estadoBotaoSalvar = !dirty && isModal && values.id ? estadosBotaoSalvar.CONFIRMAR : estadosBotaoSalvar.SALVAR;
        const estadoBotaoNovo = dirty ? estadosBotaoNovo.SALVAR_E_NOVO : estadosBotaoNovo.NOVO;

        const onClickNovo = dirty ? (e) => this.salvar(e, this.novo) : this.novo;

        return (
            <>
                <Prompt dirty={dirty} isModal={isModal} />
                <Form className="card-default screen-max-width" header="Cadastro de setor" isModal={isModal}>
                    <FormActions className="screen-max-width">
                        <ButtonCancelar
                            {...informacoesPermissoes}
                            hidden={isModal && values.id && !dirty}
                            estadoBotao={dirty ? estadosBotaoCancelar.CANCELAR : estadosBotaoCancelar.VOLTAR}
                            onClick={this.cancelar}
                        />
                        <ButtonSalvar
                            {...informacoesPermissoes}
                            estadoBotao={estadoBotaoSalvar}
                            disabled={!dirty && estadoBotaoSalvar === estadosBotaoSalvar.SALVAR}
                            onClick={this.salvar}
                        />
                        <ButtonNovo
                            onClick={onClickNovo}
                            hidden={(!dirty && !values.id) || isModal}
                            estadoBotao={estadoBotaoNovo}
                            {...informacoesPermissoes}
                        />
                        <ButtonExcluir
                            hidden={!values.id || isModal}
                            onClick={this.excluir}
                            {...informacoesPermissoes}
                        />
                        <Button
                            className='p-button-secondary'
                            type='button'
                            label="Opções"
                            icon="fa fa-list"
                            hidden={true}
                            onClick={e => this.menuOpcoes.toggle(e)}
                        />
                    </FormActions>
                    <FormContent>
                        <Grid>
                            <Field sm="12" md="12" lg='12' xl='12'
                                component={InputField}
                                label='Nome'
                                obrigatorio
                                keyfilter={keyFilterConsultaRsql}
                                name="nome"
                                helpMessage={helpSetorForm.nome}
                                size={60}
                                {...informacoesPermissoes}
                            />
                        </Grid>
                    </FormContent>
                </Form>
            </>
        )
    }
}

SetorForm = withFormik({

    enableReinitialize: true,
    validateOnChange: false,

    mapPropsToValues() {
        return initialValue
    },

    validationSchema: Yup.object().shape({
        nome: Yup.string().nullable().required(mensagensDeValidacao.OBRIGATORIO)
            .max(60, 'O campo não pode ter mais de 60 caracteres.')
    }),

})(SetorForm);


export default withRouter(SetorForm);
