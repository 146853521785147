import React, { Component } from 'react';
import { recursos, permissoes } from '../../../common/constantes/autorizacao';
import { usuarioPossuiPermissao } from '../../../common/autenticacao';
import autoBind from 'react-autobind/lib/autoBind';
import Col from '../../../components/Col';
import Grid from '../../../components/Grid';
import CardTotalizadorListagem from '../components/CardTotalizadorListagem';
import PesquisaAvancada from '../../../components/PesquisaAvancada';
import { optionsFiltroAvancado } from './util/constantes';
import { construirUrl } from '../../../common/rsql';
import { services } from '../../../common/constantes/api';
import { salvarConfiguracaoUsuario, configuracoesUsuario } from '../../../common/configuracaoUsuario';
import {
    asyncGetLancamentos,
    asyncGetLancamento,
    asyncGetContas,
    asyncGetCategoriasDespesas,
    asyncGetCategoriasReceita,
    asyncGetConta,
    asyncGetTotalizadores,
    asyncBaixarRelatorioMovimentacoesPorPeriodo,
    asyncGetContaPagar,
    asyncGetContaReceber
} from './requests';
import DateInterval from '../../../components/DateInterval';
import { BsGraphDown, BsGraphUp } from 'react-icons/bs';
import { AiFillBank } from 'react-icons/ai';
import { FaBalanceScale, FaPrint } from 'react-icons/fa';
import TabelaLancamentos from './components/TabelaLancamentos';
import { confirm } from '../../../components/Toast';
import If from '../../../components/If';
import ModalTransferencia from './components/ModalTransferencia';
import { asyncDeleteTransferencia, asyncGetTransferencia } from './components/ModalTransferencia/requests';
import { tipoCampos } from '../../../components/PesquisaAvancada/util/constantes';
import InputSearch from '../../../components/Input/InputSearch';
import DescricaoFiltroAvancado from '../../../components/DescricaoFiltroAvancado';
import { formatarParaPesquisarTiposEnumerados } from '../../../common/manipulacaoDeString';
import ModalLancamentoReceita from './components/ModalLancamentoReceita';
import ModalSaldoInicial from '../Contas/components/ModalConta';
import { asyncDeleteLancamentoReceita } from './components/ModalLancamentoReceita/requests';
import ModalLancamentoDespesa from './components/ModalLancamentoDespesa';
import { asyncDeleteLancamentoDespesa } from './components/ModalLancamentoDespesa/requests';
import Form from '../../../components/Form';
import FormActions from '../../../components/FormActions';
import FormMobileActions from '../../../components/FormMobileActions';
import FormContent from '../../../components/FormContent';
import { Menu } from 'primereact/menu';
import { isWithinInterval, formatISO, format } from 'date-fns';
import { SelectButton } from 'primereact/selectbutton';
import { baixarArquivo } from '../../../common/relatorios';
import ModalContaPagar from '../ContasPagar/components/ModalContaPagar';
import ModalContaReceber from '../ContasReceber/components/ModalContaReceber';
import { Financas } from '../../util/constantes'
import Button from '../../../components/Button';

const selectButtonStyle = {
    whiteSpace: 'nowrap',
    overflowY: 'hidden',
    width: '100%',
}

const styleBotaoImprimir = {
    padding: '0px 10px',
    display: 'flex',
    background: Financas.cores.preto,
    color: Financas.cores.azul,
    fontWeight: 'bold',
    fontSize: '15px',
    alignItems: 'center',
    border: 'none',
    cursor: 'pointer'
};

class Lancamentos extends Component {

    constructor(props) {

        super(props);

        autoBind(this);

        this.state = {
            registroSelecionado: null,
            podeInserirLancamento: usuarioPossuiPermissao(recursos.FINANCAS_LANCAMENTOS, permissoes.INSERIR),

            exibirModalTransferencia: false,
            exibirModalLancamentoReceita: false,
            exibirModalLancamentoDespesa: false,
            exibirModalSaldoInicial: false,
            exibirModalContaPagar: false,
            exibirModalContaReceber: false,
            tutorialVisible: false,
            cardTotalSelected: "",
            descricaoFiltroAvancado: '',
            valorPesquisa: "",
            valorCard: "",
            filtroData: "",
            filtroConta: null,
            filtroAvancado: "",
            cards: {
                despesas: 0,
                receitas: 0,
                balanco: 0,
                saldo: 0
            },
            optionsContas: [],
            optionsFiltroAvancado: optionsFiltroAvancado,
            limparFiltroPesquisaAvancada: false,
            registros: [],
            totalRecords: 0,

            interval: {
                dataInicial: null,
                dataFinal: null
            },

            sortField: 'data',
            sortOrder: -1,

            page: 0,
            rows: 0,
            size: 10,
            first: 0
        }
    }

    onPesquisarFiltroAvancado(e) {
        this.setState({ filtroAvancado: e, limparFiltroPesquisaAvancada: false }, () => {
            this.pesquisar()
        });
    }

    componentDidMount() {
        const { location } = this.props
        if (this.state.deveExibirTutorial !== false) {
            this.setState({ tutorialVisible: true })
            salvarConfiguracaoUsuario(configuracoesUsuario.EXIBIR_TUTORIAL_LISTAGENS, false, null, false)
        }

        if (location.state && location.state.contaSelecionadaId) {
            this.setState({ filtroConta: location.state.contaSelecionadaId })
        }
        this.buscarInformacoesComplementaresFiltro();
    }

    buscarInformacoesComplementaresFiltro() {

        asyncGetContas(({ data: contas }) => {
            let optionsContas = contas && contas.content.map(conta => {
                return { label: conta.nome, value: conta.id }
            })
            this.setState({ optionsContas })
        });

        asyncGetCategoriasDespesas(({ data: catDespesas }) => {
            let optionSelectCategorias = [];

            catDespesas.content.forEach(categoria => {
                optionSelectCategorias.push({ label: categoria.nome, value: categoria.nome })
            })

            asyncGetCategoriasReceita(({ data: catReceita }) => {
                catReceita.content.forEach(categoria => {
                    optionSelectCategorias.push({ label: categoria.nome, value: categoria.nome })
                })

                const categorias = {
                    label: 'Categoria', name: 'categoriaNome', type: tipoCampos.SELECT,
                    optionSelect: optionSelectCategorias
                }

                this.setState({ optionsFiltroAvancado: [...this.state.optionsFiltroAvancado, { ...categorias }] })
            })
        })
    }


    buscarFiltro() {
        const { filtroAvancado, filtroData, valorPesquisa, valorCard, filtroConta } = this.state;

        const valorTiposEnumerados = formatarParaPesquisarTiposEnumerados(valorPesquisa);

        let filtroRSQL = String("?query=(")
            .concat(`descricao=contains="*${valorPesquisa}*",`)
            .concat(`data=contains="*${valorPesquisa}*",`)
            .concat(`categoriaNome=contains="*${valorPesquisa}*",`)
            .concat(`origem=contains="*${valorTiposEnumerados}*",`)
            .concat(`contaNome=contains="*${valorPesquisa}*",`)
            .concat(`observacao=contains="*${valorPesquisa}*",`)
            .concat(`valor=contains="*${valorPesquisa}*")`)
            .concat(`;(${filtroData})`)

        if (filtroConta) {
            filtroRSQL = filtroRSQL.concat(`;(conta.id==${filtroConta})`)
        }
        if (filtroAvancado) {
            filtroRSQL = filtroRSQL.concat(`;(${filtroAvancado})`)
        }
        if (valorCard) {
            filtroRSQL = filtroRSQL.concat(`;(${valorCard})`)
        }

        return filtroRSQL;
    }

    async pesquisar() {
        const { page, size, sortField, sortOrder, interval, filtroConta } = this.state
        const filtro = this.buscarFiltro();

        const url = construirUrl(`${services.GESTOR}/v1/lancamentos/resumo`, filtro || "?", size, page, sortOrder > 0 ? `${sortField},asc&sort=criadoEm,desc` : `${sortField},desc&sort=criadoEm,desc`);

        asyncGetTotalizadores(interval, filtroConta, ({ data: totais }) => {
            this.setState({
                cards: {
                    despesas: totais.despesasPagas,
                    receitas: totais.receitasRecebidas,
                    balanco: totais.balancoNoPeriodo,
                    saldo: totais.saldoAtual,
                }
            })
        });

        asyncGetLancamentos(url, ({ data: lancamentos }) => {
            this.setState({
                registros: lancamentos.content,
                totalRecords: lancamentos.totalElements,
            })
        })
    }

    handleChangeInterval(interval) {
        this.setState({ page: 0, first: 0, interval: interval, filtroData: `data>=${formatISO(interval.dataInicial, { representation: "date" })};data<=${formatISO(interval.dataFinal, { representation: "date" })}` }, () => {
            this.pesquisar();
        })
    }


    async handleEditItem(item) {
        if (item.origem === 'PAGAMENTO') {
            asyncGetContaPagar(item.contaPagarId, ({ data }) => {
                this.setState({ registroSelecionado: data, exibirModalContaPagar: true })
            })
        } else if (item.origem === 'RECEBIMENTO') {
            asyncGetContaReceber(item.contaReceberId, ({ data }) => {
                this.setState({ registroSelecionado: data, exibirModalContaReceber: true })
            })
        } else {
            await asyncGetLancamento(item.id, ({ data: lancamento }) => {
                if (item.origem === "TRANSFERENCIA") {
                    asyncGetTransferencia(lancamento.transferencia.id, ({ data: dados }) => {
                        this.setState({ registroSelecionado: dados, exibirModalTransferencia: true })
                    })
                } else if (item.origem === "SALDO_INICIAL") {
                    asyncGetConta(lancamento.conta.id, ({ data: dados }) => {
                        this.setState({ registroSelecionado: dados, exibirModalSaldoInicial: true })
                    })
                } else {
                    if (item.tipo === "RECEITA") {
                        this.setState({ registroSelecionado: lancamento, exibirModalLancamentoReceita: true })
                    } else {
                        this.setState({ registroSelecionado: lancamento, exibirModalLancamentoDespesa: true })
                    }
                }
            })
        }

    }

    async handleRemoveItem(item) {
        if (item.origem === "TRANSFERENCIA") {
            await asyncGetLancamento(item.id, ({ data: lancamentos }) => {
                confirm('Confirmação', 'Tem certeza que deseja excluir esta transferência?', async () => {
                    await asyncDeleteTransferencia(lancamentos.transferencia.id, () => this.pesquisar())
                });
            })
        } else {
            confirm('Confirmação', 'Tem certeza que deseja excluir esta movimentação?', async () => {
                if (item.tipo === "RECEITA") {
                    await asyncDeleteLancamentoReceita(item.id, () => this.pesquisar())
                } else {
                    await asyncDeleteLancamentoDespesa(item.id, () => this.pesquisar())
                }
            });
        }
    }

    onHideModal(values) {
        const { optionsContas } = this.state
        if (values) {
            if (values.conta && values.conta.value) {
                const encontrouNovaConta = optionsContas.some(item => item.value === values.conta.value)
                if (!encontrouNovaConta) {
                    const novasOptionsContas = [...optionsContas, values.conta]
                    this.setState({ optionsContas: novasOptionsContas })
                }
            } else if (values.length) {
                this.setState({ optionsContas: values })
            }
            this.pesquisar()
        }
        this.setState({
            exibirModalLancamentoReceita: false,
            exibirModalLancamentoDespesa: false,
            exibirModalTransferencia: false,
            exibirModalSaldoInicial: false,
            exibirModalContaPagar: false,
            exibirModalContaReceber: false,
            registroSelecionado: null
        })
    }

    onPesquisar() {
        this.setState({ page: 0, first: 0 }, () => {
            this.pesquisar();
        })
    }

    handleSelectCardDespesa(cardName) {
        let filtro = "tipo==DESPESA";
        this.pesquisarRSQLCardTotais(cardName, filtro);
    }

    handleSelectCardReceita(cardName) {
        let filtro = "tipo==RECEITA;origem!=TRANSFERENCIA";
        this.pesquisarRSQLCardTotais(cardName, filtro);
    }

    handleSelectConta(e) {
        this.setState({ filtroConta: e.value }, () => this.pesquisar())
    }

    pesquisarRSQLCardTotais(cardName, valorAplicadoNoFiltro) {
        const { cardTotalSelected } = this.state;

        if (cardTotalSelected === cardName) {
            this.setState({
                cardTotalSelected: "",
                valorCard: "",
                page: 0,
                first: 0
            }, () => {
                this.pesquisar();
            });
        } else {
            this.setState({
                cardTotalSelected: cardName,
                valorCard: valorAplicadoNoFiltro,
                page: 0,
                first: 0
            }, () => {
                this.pesquisar();
            });
        }
    }

    montarHeader() {
        const { interval, registros, filtroConta } = this.state;
        const dataInicialFormatada = interval.dataInicial && format(interval.dataInicial, 'dd/MM/yyyy');
        const dataFinalFormatada = interval.dataFinal && format(interval.dataFinal, 'dd/MM/yyyy');
        const { isMobile } = this.props;

        return (
            <span style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <span>Movimentações financeiras</span>
                <span>
                    <If test={registros && registros.length > 0}>
                        <span
                            title={`Imprimir relatório de ${dataInicialFormatada} até ${dataFinalFormatada}`}
                            style={styleBotaoImprimir}
                            onClick={() => {
                                asyncBaixarRelatorioMovimentacoesPorPeriodo(interval.dataInicial, interval.dataFinal, filtroConta, document => {
                                    baixarArquivo(document.data, 'Movimentacoes por periodo');
                                })
                            }}
                        >
                            <FaPrint size="15px" style={!isMobile && { marginRight: '5px' }} />
                            {!isMobile && <span>Imprimir</span>}
                        </span>
                    </If>
                </span>
            </span>
        )
    }

    render() {

        const {
            exibirModalTransferencia,
            cards,
            registros,
            totalRecords,
            first,
            size,
            sortField,
            sortOrder,
            valorPesquisa,
            optionsFiltroAvancado,
            descricaoFiltroAvancado,
            registroSelecionado,
            exibirModalLancamentoReceita,
            exibirModalLancamentoDespesa,
            exibirModalContaPagar,
            exibirModalContaReceber,
            cardTotalSelected,
            interval,
            filtroConta,
            optionsContas
        } = this.state;

        const permissoesLancamento = {
            podeInserir: this.state.podeInserirLancamento,
            podeDeletar: this.state.podeDeletarLancamento,
            podeEditar: this.state.podeEditarLancamento
        };
        const estaNoPeriodoAtual = interval.dataInicial && interval.dataFinal ? isWithinInterval(new Date(), { start: interval.dataInicial, end: interval.dataFinal }) : false

        return (
            <>
                <Menu
                    model={[
                        {
                            label: 'Nova receita',
                            icon: 'fa fa-plus',
                            command: () => this.setState({ exibirModalLancamentoReceita: true }),
                            disabled: !permissoesLancamento.podeInserir
                        },
                        {
                            label: 'Nova despesa',
                            icon: 'fa fa-plus',
                            command: () => this.setState({ exibirModalLancamentoDespesa: true }),
                            disabled: !permissoesLancamento.podeInserir
                        },
                        {
                            label: 'Nova transferência',
                            icon: 'fa fa-plus',
                            command: () => this.setState({ exibirModalTransferencia: true }),
                            disabled: !permissoesLancamento.podeInserir
                        }
                    ]}
                    popup={true}
                    ref={el => this.novoLacamento = el}
                />
                <Form header={this.montarHeader()}>
                    <FormActions>
                        <Button
                            label="Nova receita"
                            className="p-button-success"
                            icon="fa fa-plus"
                            title='Inserir uma nova movimentação de receita'
                            onClick={() => this.setState({ exibirModalLancamentoReceita: true })}
                            podeInserir={permissoesLancamento.podeInserir}
                            style={{ margin: '5px' }}
                        />
                        <Button
                            label="Nova despesa"
                            className="p-button-danger"
                            icon="fa fa-plus"
                            title='Inserir uma nova movimentação de despesa'
                            onClick={() => this.setState({ exibirModalLancamentoDespesa: true })}
                            podeInserir={permissoesLancamento.podeInserir}
                            style={{ margin: '5px' }}
                        />
                        <Button
                            label="Nova transferência"
                            icon="fa fa-plus"
                            title='Inserir uma nova transferência entre contas'
                            onClick={() => this.setState({ exibirModalTransferencia: true })}
                            podeInserir={permissoesLancamento.podeInserir}
                            style={{ margin: '5px' }}
                        />
                    </FormActions>

                    <FormMobileActions>
                        <Button
                            className='p-button-primary'
                            type='button'
                            label="Nova movimentação"
                            icon="fa fa-angle-down"
                            iconPos="right"
                            hidden={true}
                            style={{ marginBottom: '5px', marginLeft: '5px' }}
                            onClick={event => this.novoLacamento.toggle(event)}
                        />
                    </FormMobileActions>

                    <FormContent>
                        <Grid justifyCenter verticalAlignCenter>
                            <span style={{ padding: '12px' }}>
                                <DateInterval
                                    onChange={this.handleChangeInterval}
                                />
                            </span>
                            <InputSearch
                                onPesquisar={this.onPesquisar}
                                value={valorPesquisa}
                                onChange={value => this.setState({ valorPesquisa: value })}
                            />
                            <span style={{ padding: '12px' }}>
                                <PesquisaAvancada
                                    optionsFiltros={optionsFiltroAvancado}
                                    onPesquisarClick={this.onPesquisarFiltroAvancado}
                                    onChangeFiltroRsql={rsql => this.setState({ filtroAvancado: rsql })}
                                    onChangeDescricaoFiltro={e => this.setState({ descricaoFiltroAvancado: e })}
                                    liparFiltro={this.state.limparFiltroPesquisaAvancada}
                                />
                            </span>
                        </Grid>
                        <If test={optionsContas.length > 0}>
                            <Grid
                                verticalAlignCenter
                                justifyCenter
                                style={{ marginBottom: '10px', width: '100%' }}
                                className='grid-select-filtro'
                            >
                                <SelectButton
                                    style={selectButtonStyle}
                                    className='select-button-light'
                                    value={filtroConta}
                                    options={optionsContas}
                                    onChange={this.handleSelectConta}
                                />
                            </Grid>
                        </If>
                        <Grid justifyBetween>
                            <Col xs="12" sm="12" md="6" lg="3" xl="3">
                                <CardTotalizadorListagem
                                    name="cardReceita"
                                    title="Receitas recebidas"
                                    helpMessage="Soma de todas as movimentações de receitas realizadas no período selecionado"
                                    titleFiltro="Clique para filtrar por receitas realizadas"
                                    icon={<BsGraphUp size={25} color={Financas.cores.verde} />}
                                    primaryColor={cards.receitas < 0 ? Financas.cores.vermelho : Financas.cores.verde}
                                    value={cards.receitas}
                                    selectable
                                    selected={cardTotalSelected === "cardReceita"}
                                    onSelect={this.handleSelectCardReceita}
                                />
                            </Col>
                            <Col xs="12" sm="12" md="6" lg="3" xl="3">
                                <CardTotalizadorListagem
                                    name="cardDespesa"
                                    title="Despesas pagas"
                                    helpMessage="Soma de todas as movimentações de despesas realizadas no período selecionado"
                                    titleFiltro="Clique para filtrar por despesas realizadas"
                                    icon={<BsGraphDown size={25} color={Financas.cores.vermelho} />}
                                    primaryColor={Financas.cores.vermelho}
                                    value={cards.despesas}
                                    selectable
                                    selected={cardTotalSelected === "cardDespesa"}
                                    onSelect={this.handleSelectCardDespesa}
                                />
                            </Col>
                            <Col xs="12" sm="12" md="6" lg="3" xl="3">
                                <CardTotalizadorListagem
                                    name="cardBalancoPeriodo"
                                    title="Balanço do período"
                                    helpMessage="Balanço financeiro das movimentações realizadas no período selecionado (receitas - despesas)"
                                    icon={<FaBalanceScale size={25} color={cards.balanco < 0 ? Financas.cores.vermelho : Financas.cores.verde} />}
                                    primaryColor={cards.balanco < 0 ? Financas.cores.vermelho : Financas.cores.verde}
                                    value={cards.balanco}
                                />
                            </Col>
                            <Col xs="12" sm="12" md="6" lg="3" xl="3">
                                <CardTotalizadorListagem
                                    name="cardSaldoAtual"
                                    title="Saldo atual"
                                    helpMessage="Soma dos saldos de todas as contas até a data atual. Esse valor é calculado somando todas as movimentações até a data de hoje"
                                    icon={<AiFillBank size={25} color={Financas.cores.azul} />}
                                    primaryColor={Financas.cores.azul}
                                    value={cards.saldo}
                                />
                            </Col>
                        </Grid>
                        <Grid style={{ paddingTop: '10px' }} justifyCenter verticalAlignCenter>
                            <DescricaoFiltroAvancado texto={descricaoFiltroAvancado} />
                            <TabelaLancamentos
                                registros={registros}
                                totalRecords={totalRecords}
                                rows={size}
                                first={first}
                                sortField={sortField}
                                sortOrder={sortOrder}
                                setSortField={sortField => this.setState({ sortField })}
                                setSortOrder={sortOrder => this.setState({ sortOrder }, () => this.pesquisar())}
                                onEditItem={this.handleEditItem}
                                onRemoveItem={this.handleRemoveItem}
                                onPageChange={e => this.setState({ first: e.first, size: e.rows, page: e.page }, () => this.pesquisar())}
                            />
                        </Grid>
                    </FormContent>
                </Form>
                <If test={exibirModalTransferencia}>
                    <ModalTransferencia
                        transferenciaSelecionada={registroSelecionado}
                        onNovoClick={() => this.setState({ registroSelecionado: null })}
                        visible={exibirModalTransferencia}
                        valorPadraoData={estaNoPeriodoAtual ? new Date() : null}
                        onHide={this.onHideModal}
                    />
                </If>
                <If test={exibirModalLancamentoReceita}>
                    <ModalLancamentoReceita
                        registroSelecionado={registroSelecionado}
                        onNovoClick={() => this.setState({ registroSelecionado: null })}
                        visible={exibirModalLancamentoReceita}
                        valorPadraoData={estaNoPeriodoAtual ? new Date() : null}
                        onHide={this.onHideModal}
                    />
                </If>
                <If test={exibirModalLancamentoDespesa}>
                    <ModalLancamentoDespesa
                        registroSelecionado={registroSelecionado}
                        onNovoClick={() => this.setState({ registroSelecionado: null })}
                        visible={exibirModalLancamentoDespesa}
                        valorPadraoData={estaNoPeriodoAtual ? new Date() : null}
                        onHide={this.onHideModal}
                    />
                </If>
                <If test={this.state.exibirModalSaldoInicial}>
                    <ModalSaldoInicial
                        visible={this.state.exibirModalSaldoInicial}
                        onHide={this.onHideModal}
                        registroSelecionado={this.state.registroSelecionado}
                        esconderBotaoNovo
                        esconderBotaoExcluir
                    />
                </If>
                <If test={exibirModalContaPagar}>
                    <ModalContaPagar
                        onNovoClick={() => this.setState({ registroSelecionado: null })}
                        visible={exibirModalContaPagar}
                        onHide={this.onHideModal}
                        registroSelecionado={registroSelecionado}
                        valorPadraoDataVencimento={estaNoPeriodoAtual ? new Date() : null}
                    />
                </If>
                <If test={exibirModalContaReceber}>
                    <ModalContaReceber
                        onNovoClick={() => this.setState({ registroSelecionado: null })}
                        visible={exibirModalContaReceber}
                        onHide={this.onHideModal}
                        registroSelecionado={registroSelecionado}
                        valorPadraoDataVencimento={estaNoPeriodoAtual ? new Date() : null}
                    />
                </If>
            </>
        );
    }
}

export default Lancamentos;
