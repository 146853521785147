//tipos de ações gerais
export const actionTypes = {

    //MENU LATERAL
    MENU_LATERAL_ATUALIZAR: 'MENU_LATERAL_ATUALIZAR',

    //Verificar se o menu lateral deve ser renderizado aberto ou fechado
    MENU_LATERAL_FECHADO: 'MENU_LATERAL_FECHADO',

    //CADASTROS - Estado do formulário (Edição, etc)
    ESTADO_FORMULARIO: 'ESTADO_FORMULARIO',

    //Atualizar plano
    ATUALIZAR_PLANO: 'ATUALIZAR_PLANO',

    LIMITE_PLANO_VIOLADO: 'LIMITE_PLANO_VIOLADO',

    DISPOSITIVO: 'DISPOSITIVO',

    POSSUI_INTERNET: 'POSSUI_INTERNET',

    POSSUI_SUPORTE_VIA_CHAT: 'POSSUI_SUPORTE_VIA_CHAT',

    //Exibirá um modal contendo os erros do backend
    MOSTRAR_ERRO_TRATADO: 'MOSTRAR_ERRO_TRATADO'
};