import React, { Component } from 'react';
import autoBind from 'react-autobind';
import Modal from '../../../../../../../components/Modal';
import { Field, withFormik } from 'formik';
import * as Yup from 'yup';
import Grid from '../../../../../../../components/Grid';
import TextArea from '../../../../../../../components/TextArea';
import Button from '../../../../../../../components/Button';
import { asyncCancelarNfe } from '../../../requests';
import { mensagensDeValidacao } from '../../../../../../../common/constantes/mensagens';
import { validarFormulario } from '../../../../../../util';
import ModalLoadingTransmissao from '../ModalLoadingTransmissao';
import { Status } from '../../../util/constantes';
import InputField from '../../../../../../../components/Input/InputField';
import { format, parseISO } from 'date-fns';
import If from '../../../../../../../components/If';

class ModalCancelamento extends Component {
    constructor(props) {
        super(props);

        autoBind(this);

        this.state = {
            mostrarLoadingTransmissao: false
        }
    }

    cancelar() {
        this.props.onHide();
    }

    async confirmar() {
        this.props.handleSubmit();

        if (await validarFormulario(this.props)) {
            this.setState({ mostrarLoadingTransmissao: true, }, () => {
                asyncCancelarNfe(this.props.idNfe, this.converterParaApi(), () => {
                    this.props.onHide(this.props.values)
                }, () => {
                    this.props.onHide(this.props.values)
                })
            })
        }

    }

    converterParaApi() {
        const { values } = this.props;

        if (values.xJust) {
            return {
                xJust: String(values.xJust).trim()
            };
        }
        return { ...values };
    }

    render() {

        const { visible, onHide, xJust, dhCancelamento, setFieldValue, statusNfe, values } = this.props;
        const notaFiscalCancelada = statusNfe === Status.CANCELADA;

        return (
            <Modal
                header="Cancelar nota fiscal"
                visible={visible}
                onHide={onHide}
            >
                <ModalLoadingTransmissao visible={this.state.mostrarLoadingTransmissao} message="Cancelando nota fiscal..." />
                <Grid>
                    <If test={notaFiscalCancelada}>
                        <Field sm="12"
                            component={InputField}
                            label="Data e hora do evento"
                            value={dhCancelamento ? format(parseISO(dhCancelamento, new Date()), 'dd/MM/yyyy HH:mm') : ''}
                            name="dhEvento"
                            disabled
                        />
                    </If>
                    <Field sm="12"
                        component={TextArea}
                        label='Justificativa do cancelamento'
                        name="xJust"
                        maxLength={255}
                        disabled={notaFiscalCancelada}
                        value={xJust ? xJust : values.xJust}
                        onChange={e => setFieldValue('xJust', e.target.value)}
                    />
                </Grid>
                <Grid justifyEnd>
                    <span style={{ padding: '0.5em' }}>
                        <Button
                            color="secondary"
                            icon="fa fa-arrow-left"
                            label="Voltar"
                            onClick={this.cancelar}
                        />
                        <Button
                            color="danger"
                            icon={notaFiscalCancelada ? "fa fa-check" : "fa fa-send"}
                            label={notaFiscalCancelada ? "Nota fiscal cancelada" : "Cancelar nota fiscal"}
                            disabled={notaFiscalCancelada}
                            onClick={this.confirmar}
                            style={{ marginLeft: '10px' }}
                        />
                    </span>
                </Grid>
            </Modal>
        )
    }
}

ModalCancelamento = withFormik({

    enableReinitialize: true,
    validateOnChange: false,

    mapPropsToValues(props) {
        return {
            xJust: props.xJust || ""
        }
    },

    validationSchema: Yup.object().shape({
        xJust: Yup.string().trim().min(15, "A descrição deve ter mais de 15 caracteres").required(mensagensDeValidacao.OBRIGATORIO)
    }),

})(ModalCancelamento);

export default ModalCancelamento;
