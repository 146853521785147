import React, { Component } from 'react';
import autoBind from 'react-autobind';
import Modal from '../../../../../../../components/Modal';
import { Field } from 'formik';
import Grid from '../../../../../../../components/Grid';
import Form from '../../../../../../../components/Form';
import FormActions from '../../../../../../../components/FormActions';
import FormContent from '../../../../../../../components/FormContent';
import InputField from '../../../../../../../components/Input/InputField';
import InputMask from '../../../../../../../components/Input/InputMask';
import SingleSelectMunicipio from '../../../../../../../components/Select/SingleSelectMunicipio';
import ButtonSalvar, { estadosBotaoSalvar } from '../../../../../../../components/Button/ButtonSalvar';
import { helpNFeTransporte } from '../NfeTransporte/help';
import SingleSelectEstado from '../../../../../../../components/Select/SingleSelectEstado';

class ModalDadosTransportador extends Component {

    constructor(props) {
        super(props);

        autoBind(this);
    }

    confirmar() {
        this.props.onHide()
    }

    onChangePlaca(e) {
        if (e.target.value) {
            this.props.onChangeVeicTransp('placa', e.target.value.toUpperCase());
        } else {
            this.props.onChangeVeicTransp('placa', '');
        }
    }

    render() {
        const {
            visible,
            onHide,
            informacoesPermissoes,
            onChangeTransporta,
            onChangeVeicTransp,
            transp,
        } = this.props
        
        return (
            <Modal
                header="Dados do transportador"
                visible={visible}
                onHide={onHide}
            >
                <Form>
                    <FormActions>
                        <ButtonSalvar
                            onClick={this.confirmar}
                            estadoBotao={estadosBotaoSalvar.CONFIRMAR}
                        />
                    </FormActions>
                    <FormContent>
                        <Grid>
                            <Field
                                sm="6" md="4" lg='4' xl='4'
                                component={InputField}
                                size={60}
                                label='Nome do transportador'
                                name="xNome"
                                value={transp.transporta.xNome}
                                onChange={(e) => onChangeTransporta('xNome', e.target.value)}
                                helpMessage={helpNFeTransporte.xNome}
                                {...informacoesPermissoes}
                            />
                            <Field sm="6" md="4" lg='4' xl='4'
                                component={InputField}
                                size={7}
                                label='Placa do veículo'
                                name="placa"
                                value={transp.veicTransp.placa}
                                onChange={this.onChangePlaca}
                                helpMessage={helpNFeTransporte.placa}
                                {...informacoesPermissoes}
                            />
                            <Field sm="6" md="4" lg='4' xl='4'
                                component={InputField}
                                size={7}
                                label='RNTC do veículo (Opcional)'
                                name="RNTC"
                                value={transp.veicTransp.RNTC}
                                onChange={(e) => onChangeVeicTransp('RNTC', e.target.value)}
                                helpMessage={helpNFeTransporte.RNTC}
                                {...informacoesPermissoes}
                            />
                            <Field sm="6" md="4" lg="4" xl="4"
                                component={SingleSelectEstado}
                                label="UF do veículo"
                                onChange={(e) => onChangeVeicTransp('UF', e)}
                                name="UF"
                                value={transp.veicTransp.UF}
                                {...informacoesPermissoes}
                            />
                            <Field sm="6" md="4" lg='4' xl='4'
                                component={InputMask}
                                mask="000.000.000-00"
                                placeholder="   .   .   -  "
                                label='CPF do transportador'
                                name="CPF"
                                value={transp.transporta.CPF}
                                onChange={(e) => onChangeTransporta('CPF', e.target.value)}
                                helpMessage={helpNFeTransporte.CPF}
                                {...informacoesPermissoes}
                            />
                            <Field sm="6" md="4" lg='4' xl='4'
                                component={InputMask}
                                mask="00.000.000/0000-00"
                                placeholder="  .   .   /    -  "
                                label='CNPJ do transportador'
                                onChange={(e) => onChangeTransporta('CNPJ', e.target.value)}
                                name="CNPJ"
                                value={transp.transporta.CNPJ}
                                helpMessage={helpNFeTransporte.CNPJ}
                                {...informacoesPermissoes}
                            />
                            <Field sm="6" md="4" lg='4' xl='4'
                                component={InputField}
                                size={14}
                                label='IE do transportador'
                                name="IE"
                                value={transp.transporta.IE}
                                onChange={(e) => onChangeTransporta('IE', e.target.value)}
                                helpMessage={helpNFeTransporte.IE}
                                {...informacoesPermissoes}
                            />
                            <Field sm="6" md="4" lg='4' xl='4'
                                component={InputField}
                                size={60}
                                label='Endereço do transportador'
                                name="xEnder"
                                value={transp.transporta.xEnder}
                                onChange={(e) => onChangeTransporta('xEnder', e.target.value)}
                                helpMessage={helpNFeTransporte.xEnder}
                                {...informacoesPermissoes}
                            />
                            <Field sm="6" md="4" lg="4" xl="4"
                                component={SingleSelectMunicipio}
                                label="Município do transportador"
                                onChange={(e) => onChangeTransporta('municipio', e)}
                                name="municipio"
                                value={transp.transporta.municipio}
                                {...informacoesPermissoes}
                            />
                        </Grid>
                    </FormContent>
                </Form>
            </Modal>
        )
    }
}

export default ModalDadosTransportador;
