import React, { Component } from 'react'
import { withFormik, Field } from 'formik';
import * as Yup from 'yup';
import { mensagensDeValidacao } from '../../../../../common/constantes/mensagens'
import FormGroup from '../../../../../components/FormGoup/index';
import Grid from '../../../../../components/Grid/index';
import Button from '../../../../../components/Button'
import PasswordField from '../../../../../components/Input/InputPassword';
import InputField from '../../../../../components/Input/InputField';
import Col from '../../../../../components/Col';
import AutoProgressBar from '../../../../../components/Loading/AutoProgressBar';
import { fazerLogoutLocalStorange } from '../../../../../common/autenticacao';
import { asyncExcluirEmpresa } from '../../requests';
import autoBind from 'react-autobind'
import Modal from '../../../../../components/Modal';

const initialValues = {
	fraseConfirmacao: '',
	senha: ''
}

class ModalExcluirEmpresa extends Component {

	constructor(props) {
		super(props);
		autoBind(this)
	}

	excluir() {
		const { fraseConfirmacao, senha } = this.props.values;

		this.props.handleSubmit();

		if (this.props.isValid && fraseConfirmacao && senha) {
			asyncExcluirEmpresa(this.props.values, () => {
				this.props.onHide()
				fazerLogoutLocalStorange();
				window.location = "#/login";
			});
		}
	}

	render() {

		const { senha, fraseConfirmacao } = this.props.values;

		return (
			<Modal
				header='Confirmar exclusão da empresa?'
				visible={this.props.visible}
				onHide={() => this.props.onHide()}
			>
				<FormGroup>
					<AutoProgressBar />
					<Grid style={{ marginBottom: '10px' }}>
						<Col>
							Você tem certeza que deseja excluir sua empresa? Todos os <b>seus dados serão destruídos e esta ação é irreversível.</b><br />
						Caso queira continuar, informe a frase <b>apagar meus dados</b> e sua senha nos respectivos campos para prosseguir
					</Col>
						<Col>
							<Field
								colStyle={{ padding: 0 }}
								component={InputField}
								label='Frase de confirmação'
								feedback={false}
								name="fraseConfirmacao"
							/>
						</Col>
						<Col>
							<Field
								colStyle={{ padding: 0 }}
								component={PasswordField}
								label='Sua senha'
								feedback={false}
								name="senha"
							/>
						</Col>
					</Grid>
					<Col>
						<Grid justifyStart>
							<Button
								color='danger'
								disabled={!senha || !fraseConfirmacao}
								label='Apagar todos os dados e remover conta'
								icon='fa fa-trash'
								onClick={this.excluir}
							/>
						</Grid>
					</Col>
				</FormGroup>
			</Modal>
		)
	}
}

ModalExcluirEmpresa = withFormik({
	validateOnChange: false,

	mapPropsToValues() {
		return initialValues;
	},

	validationSchema: Yup.object().shape({
		senha: Yup.string().nullable().required(mensagensDeValidacao.OBRIGATORIO).test('len', 'A senha deve ter no mínimo 8 caracteres', val => val && val.length >= 8),
		fraseConfirmacao: Yup.string().nullable().required(mensagensDeValidacao.OBRIGATORIO).test('len', 'Frase de confirmação inválida', val => val && val === "apagar meus dados"),
	}),

	handleSubmit: () => { },

})(ModalExcluirEmpresa);

export default ModalExcluirEmpresa