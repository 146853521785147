import React, { Component } from 'react';
import { Card } from 'primereact/card';
import autoBind from 'react-autobind';
import { buscarDocumentosAssinados } from './requests';
import { ProgressBar } from 'primereact/progressbar';
import { format, parseISO } from 'date-fns';
import { usuarioPossuiPermissao } from '../../../../../../../common/autenticacao';
import { recursos, permissoes } from '../../../../../../../common/constantes/autorizacao';
import NenhumaInformacaoEncontrada from '../../../NenhumaInformacaoEncontrada';
import './styles/index.css';
import { Link } from 'react-router-dom';
import FalhaAoCarregarAsInformacoes from '../../../FalhaAoCarregarAsInformacoes';
import { Documentos } from '../../../../../../util/constantes';

const Divider = () => {
    return <hr id="divider-documentos-assinatura-periodo" />
}

const List = props => {
    return (
        <div id="documentos-periodo" className='dashboard-scroll'>
            <ul style={{ listStyle: "none", padding: '0px 3px', margin: '0px' }}>
                {props.children}
            </ul>
        </div>
    );
}

const ListItem = props => {
    let titleDocumento = "Ir para o documento " + (props.identificacao ? props.identificacao : "");

    return (
        <li style={{ margin: '6px 0px' }}>
            <div className="p-grid p-align-center" style={{ margin: '0px' }}>
                <Link className="p-col-4" to={{ pathname: "/documentos/cadastro/" + props.docId }}>
                    <div className="link_to" style={{ display: 'flex', alignItems: 'center' }}>
                        <span id="documento-identificacao-title" title={titleDocumento} style={{ color: `${Documentos.cores.azul}` }}>
                            {props && props.identificacao ? props.identificacao : "Nenhuma descrição informada"}
                        </span>
                    </div>
                </Link>
                <div className="p-col-4" style={{ padding: '0px 6px' }}>
                    <span id="documento-pessoa">
                        {props && props.nomePessoa ? props.nomePessoa : 'Nenhuma pessoa informada'}
                    </span>
                </div>
                <p className="p-col-4" style={{ margin: '6px 0px', textAlign: 'end' }}>
                    {`Assinado em ${format(parseISO(props.dataAssinatura), 'dd/MM/yyyy')}`}
                </p>
            </div>
        </li>
    );
}

export default class DocumentosAssinadosPeriodo extends Component {

    constructor(props) {
        super(props);

        autoBind(this);

        this.state = {
            loading: true,
            error: false,
            documentosAssinados: [],
            podeVisualizarDocumentos: usuarioPossuiPermissao(recursos.DOCUMENTOS, permissoes.VISUALIZAR)
        }
    }

    componentDidMount() {
        this.carregarInformacoes();
    }

    componentDidUpdate(prevProps) {
        const { dataInicial, dataFinal } = this.props;

        if (prevProps.dataInicial !== dataInicial || prevProps.dataFinal !== dataFinal) {
            this.carregarInformacoes();
        }
    }

    carregarInformacoes(exibirLoading = true) {
        const { dataInicial, dataFinal } = this.props;

        this.setState({ loading: exibirLoading, error: false }, () => {
            buscarDocumentosAssinados(dataInicial, dataFinal, ({ data: documentosAssinados }) => {
                this.setState({ documentosAssinados, loading: false, error: false });
            }, () => {
                this.setState({ error: true, loading: false });
            });
        })

    }

    getHeaderCard() {
        return (
            <div id="card-header-documentos-assinados-periodo">
                <label title="Lista os documentos assinados no período">Documentos assinados no período</label>
            </div>
        );
    }

    render() {
        if (this.state.loading) {
            return (
                <Card header={this.getHeaderCard()} style={{ borderRadius: '5px', height: '100%' }}>
                    <ProgressBar mode="indeterminate" style={{ height: '3px' }} />
                </Card>
            )
        } else if (this.state.error) {
            return (
                <Card header={this.getHeaderCard()} style={{ borderRadius: '5px', height: '100%' }}>
                    <FalhaAoCarregarAsInformacoes />
                </Card>
            )
        } else {
            const { documentosAssinados } = this.state;
            return (
                <>
                    <Card header={this.getHeaderCard()} style={{ borderRadius: '5px', height: '100%' }}>
                        {documentosAssinados.length > 0 ? (
                            <List>
                                {documentosAssinados.map((documento, idx, array) => {
                                    return (
                                        <React.Fragment key={idx}>
                                            <ListItem
                                                docId={documento.docId}
                                                dataAssinatura={documento.dataAssinatura}
                                                identificacao={documento.identificacao}
                                                nomePessoa={documento.nomePessoa}
                                            />
                                            {++idx !== array.length ? <Divider /> : null}
                                        </React.Fragment>
                                    )
                                })}
                            </List>
                        ) : <NenhumaInformacaoEncontrada />}
                    </Card>
                </>
            )
        }
    }
}
