import React, { Component } from 'react';
import ButtonNovo from '../../../components/Button/ButtonNovo'
import { services } from '../../../common/constantes/api.js';
import { recursos, permissoes, modulos } from '../../../common/constantes/autorizacao.js';
import { removerCaracteres } from '../../../common/manipulacaoDeString/index.js';
import Grid from '../../../components/Grid';
import PesquisaAvancada from '../../../components/PesquisaAvancada';
import Col from '../../../components/Col';
import DescricaoFiltroAvancado from '../../../components/DescricaoFiltroAvancado';
import { optionsFiltroAvancado } from './util/constantes';
import autoBind from 'react-autobind'
import { Column } from 'primereact/column';
import { asyncAjustarSaldo, asyncDeleteProduto, asyncGetProdutos, asyncConsultarValorTotalEstoque, asyncImprimirInventario } from './requests';
import { atualizarUrl } from '../../util';
import { confirmarExclusao } from '../../util/exclusaoDeRegistros';
import { removerElemento } from '../../../common/array';
import ButtonEditarTable from '../../../components/Button/ButtonEditarTable';
import ButtonExcluirTable from '../../../components/Button/ButtonExcluirTable';
import InputSearch from '../../../components/Input/InputSearch';
import { construirUrl } from '../../../common/rsql';
import { usuarioPossuiModulo, usuarioPossuiPermissao } from '../../../common/autenticacao';
import { tutorialSteps } from '../../../components/Tutorial/steps/listagens';
import { salvarConfiguracaoUsuario, configuracoesUsuario, buscarConfiguracaoUsuario } from '../../../common/configuracaoUsuario';
import Tutorial from '../../../components/Tutorial';
import Form from '../../../components/Form';
import FormActions from '../../../components/FormActions';
import FormContent from '../../../components/FormContent';
import { formatarMonetario, formatarDecimais } from '../../../common/mascara';
import InputMoney from '../../../components/Input/InputMoney';
import BotaoTabelaEstoque from './components/BotaoTabelaEstoque';
import { baixarArquivo } from '../../../common/relatorios';
import If from '../../../components/If';
import { FaPrint } from 'react-icons/fa';
import { Menu } from 'primereact/menu';
import { Link } from 'react-router-dom';
import ModalEntradaEstoque from '../../estoque/Movimentacoes/components/ModalEntradaEstoque';
import ModalSaidaEstoque from '../../estoque/Movimentacoes/components/ModalSaidaEstoque';
import { confirm } from '../../../components/Toast';
import NenhumRegistroEncontrado from '../../../components/NenhumRegistroEncontrado';
import { DataTable } from 'primereact/components/datatable/DataTable';
import { Paginator } from 'primereact/paginator';
import { Checkbox } from 'primereact/checkbox';

const ColorIcons = {
    SUCCESS: "#34a835",
    PRIMARY: "#006095",
    DANGER: "#e91224"
}

const styleBotaoImprimir = {
    padding: '0px 10px',
    display: 'flex',
    fontWeight: 'bold',
    fontSize: '15px',
    alignItems: 'center',
    cursor: 'pointer'
};

class Produtos extends Component {

    constructor(props) {
        super(props);
        autoBind(this)
        this.state = {
            podeInserir: usuarioPossuiPermissao(recursos.PRODUTOS, permissoes.INSERIR),
            podeExcluir: usuarioPossuiPermissao(recursos.PRODUTOS, permissoes.EXCLUIR),
            podeVisualizarMovimentacaoEstoque: usuarioPossuiPermissao(recursos.ESTOQUE_MOVIMENTACOES, permissoes.VISUALIZAR),
            podeInserirMovimentacaoEstoque: usuarioPossuiPermissao(recursos.ESTOQUE_MOVIMENTACOES, permissoes.INSERIR),
            deveExibirTutorial: buscarConfiguracaoUsuario(configuracoesUsuario.EXIBIR_TUTORIAL_LISTAGENS),
            possuiModuloEstoque: usuarioPossuiModulo(modulos.ESTOQUE),
            exibirModalEntradaEstoque: false,
            exibirModalSaidaEstoque: false,
            descricaoFiltroAvancado: '',
            valorPesquisa: '',
            registros: [],
            sortField: 'sku',
            sortOrder: 1,
            page: 0,
            rows: 10,
            totalElements: 0,
            tutorialVisible: false,
            valorEstoque: 0,
            exibirProdutosInativos: false,
            registroSelecionado: null,
            campoParaEditar: null
        }
    }

    componentDidMount() {
        this.pesquisar()

        if (this.state.deveExibirTutorial !== false) {
            this.setState({ tutorialVisible: true })
            salvarConfiguracaoUsuario(configuracoesUsuario.EXIBIR_TUTORIAL_LISTAGENS, false, null, false)
        }
    }

    buscarFiltro() {
        const { valorPesquisa, filtroAvancado } = this.state
        const pesquisaCodigo = removerCaracteres(valorPesquisa, ['.'])

        let result = `?query=(sku=contains="*${pesquisaCodigo}*",nome=contains="*${valorPesquisa}*",situacao=contains="*${valorPesquisa}*",preco=contains="*${valorPesquisa}*")`
        if (filtroAvancado) {
            result += `;${filtroAvancado}`
        }

        return result
    }

    async pesquisar() {
        const { page, rows, sortField, sortOrder, exibirProdutosInativos, podeVisualizarMovimentacaoEstoque } = this.state
        let filtro = this.buscarFiltro()
        filtro += String(`${exibirProdutosInativos ? '' : ';situacao=="ATIVO"'}`)

        const url = construirUrl(`${services.GESTOR}/v1/produtos/resumo`, filtro, rows, page, sortOrder > 0 ? `${sortField},asc` : `${sortField},desc`)

        await asyncGetProdutos(url, ({ data: produto }) => {
            this.setState({ registros: produto.content, totalElements: produto.totalElements })
        })

        if (podeVisualizarMovimentacaoEstoque) {
            asyncConsultarValorTotalEstoque(({ data: totais }) => {
                this.setState({ valorEstoque: totais.valorEstoque });
            });
        }

    }

    onPesquisar() {
        this.setState({ page: 0 }, () => {
            this.pesquisar();
        })
    }

    onPageChange(e) {
        this.setState({ page: e.page, rows: e.rows }, () => {
            this.pesquisar()
        })
    }

    onSort(e) {
        this.setState({ sortField: e.sortField, sortOrder: e.sortOrder }, () => {
            this.pesquisar()
        })
    }

    onEditar(row) {
        atualizarUrl(this.props.history, '/produtos/cadastro', row.id)
    }

    onExcluir(row) {
        confirmarExclusao(() => this.asyncExcluirRegistro(row))
    }

    async asyncExcluirRegistro(registro) {

        await asyncDeleteProduto(registro.id, () => {
            this.setState({
                registros: removerElemento(this.state.registros, registro),
                totalElements: this.state.totalElements - 1
            })
        })
    }

    renderOpcoes(row) {
        return (
            <>
                <ButtonEditarTable
                    onClick={() => this.onEditar(row)}
                />
                <ButtonExcluirTable
                    onClick={() => this.onExcluir(row)}
                    podeExcluir={this.state.podeExcluir}
                />
            </>
        )
    }

    aplicarEstiloInativo(row, field) {
        if (row.situacao === 'INATIVO') {
            return <span style={{ fontStyle: 'italic', opacity: '0.7' }}>{field}</span>
        }
        return field
    }

    onPesquisarFiltroAvancado(e) {
        this.setState({ filtroAvancado: e }, () => {
            this.pesquisar()
        });
    }

    renderEstoqueDisponivelField(el) {
        const { campoParaEditar } = this.state;

        if (!el.controlarEstoque || el.tipo !== "PRODUTO") {
            return ''
        }

        if (campoParaEditar && campoParaEditar.id === el.id) {
            let novoSaldo = campoParaEditar.estoqueSaldo;

            return (
                <form onSubmit={(e) => this.handleSalvarNovoSaldo(novoSaldo, e)}>
                    <span style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <span style={{ width: 'calc(100% - 60px)' }}>
                            <InputMoney
                                colStyle={{ padding: '0px' }}
                                size={11}
                                prefix=""
                                placeholder=""
                                autofocus
                                touched={true}
                                onChange={(e) => novoSaldo = e.target.value}
                                value={novoSaldo}
                            />
                        </span>
                        <span style={{ width: '60px' }}>
                            <BotaoTabelaEstoque
                                title="Salvar novo saldo"
                                icon="fa fa-check"
                                style={{ fontSize: '22px' }}
                                color={ColorIcons.SUCCESS}
                                onClick={() => this.handleSalvarNovoSaldo(novoSaldo, null)}
                            />
                            <BotaoTabelaEstoque
                                title="Cancelar"
                                icon="fa fa-times"
                                style={{ fontSize: '22px' }}
                                color={ColorIcons.DANGER}
                                onClick={() => this.setState({ campoParaEditar: null })}
                            />
                        </span>
                    </span>
                </form>
            )
        } else {
            const estoqueSaldoFormatado = formatarDecimais(el.estoqueSaldo)
            return (
                <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <span>
                        <div title={estoqueSaldoFormatado}>
                            {this.aplicarEstiloInativo(el, estoqueSaldoFormatado)}
                        </div>
                        <Link
                            hidden={!this.state.podeVisualizarMovimentacaoEstoque}
                            className='link_to'
                            style={{ fontSize: '11px', color: '#006095', cursor: 'pointer' }}
                            to={{ pathname: `movimentacoes_estoque`, state: el }}
                            title='Visualizar movimentações de estoque deste produto'
                        >
                            Ver movimentações
                        </Link>
                    </span>
                    <BotaoTabelaEstoque
                        title={!this.state.podeInserirMovimentacaoEstoque ? "Você não possui permissão para efetuar esta ação" : "Ajustar saldo"}
                        icon="fa fa-wrench"
                        color={ColorIcons.PRIMARY}
                        style={{ fontSize: '20px', padding: '0px 5px' }}
                        disabled={!this.state.podeInserirMovimentacaoEstoque}
                        onClick={event => {
                            this.setState({ registroSelecionado: el })
                            this.optionsEstoqueMenu.toggle(event)
                        }}
                    />
                </span>
            )
        }
    }

    handleSalvarNovoSaldo(novoSaldo, e) {
        e && e.preventDefault();

        const { campoParaEditar } = this.state;
        if (campoParaEditar.controlarEstoque && novoSaldo < campoParaEditar.estoqueMinimo) {
            confirm("Confirmação", "Novo saldo será menor que estoque mínimo, deseja continuar?", () => {
                this.movimentarNovoSaldoEstoque(novoSaldo)
            }, () => {
                this.setState({ campoParaEditar: null })
            })
        } else {
            if (!novoSaldo) {
                this.movimentarNovoSaldoEstoque(0)
            } else {
                this.movimentarNovoSaldoEstoque(novoSaldo)
            }

        }
    }

    movimentarNovoSaldoEstoque(novoSaldo) {
        const { registros, campoParaEditar } = this.state;

        if (novoSaldo >= 0 && campoParaEditar.estoqueSaldo !== novoSaldo) {
            asyncAjustarSaldo(campoParaEditar.id, { custo: campoParaEditar.ultimoCusto, novoSaldo: novoSaldo }, () => {

                this.setState({ campoParaEditar: null })

                this.pesquisar();
            }, () => {
                this.setState({ registros, campoParaEditar: null });
            });
        } else {
            this.setState({ campoParaEditar: null })
        }

    }

    onChangeCheckboxExibirInativos(e) {
        this.setState({ exibirProdutosInativos: e.checked }, () => this.pesquisar())
    }

    montarHeader() {
        const { possuiModuloEstoque, podeVisualizarMovimentacaoEstoque } = this.state;
        const { isMobile } = this.props;
        return (
            <span style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <span>Produtos e serviços</span>
                <span>
                    <If test={possuiModuloEstoque && podeVisualizarMovimentacaoEstoque}>
                        <span
                            title="Imprimir apoio ao inventário físico"
                            style={styleBotaoImprimir}
                            onClick={() => asyncImprimirInventario(document => {
                                baixarArquivo(document.data, "Apoio ao inventário físico.pdf");
                            })}
                        >
                            <FaPrint size="15px" style={!isMobile && { marginRight: '5px' }} />
                            {!isMobile && <span>Imprimir inventário</span>}
                        </span>
                    </If>
                </span>
            </span>
        )
    }

    onHideModaisEstoque(novoRegistro) {
        this.setState({ exibirModalSaidaEstoque: false, exibirModalEntradaEstoque: false }, () => {
            if (novoRegistro) {
                this.pesquisar()
            }
        })
    }

    buscarProdutoSelecionado() {
        const { registroSelecionado } = this.state

        if (registroSelecionado) {
            return {
                label: registroSelecionado.sku + ' - ' + registroSelecionado.nome,
                value: registroSelecionado.id,
                registro: {
                    ...registroSelecionado,
                    custo: registroSelecionado.ultimoCusto
                }
            }
        }
        return null
    }


    render() {

        const {
            page,
            totalElements,
            podeInserir,
            sortField,
            sortOrder,
            descricaoFiltroAvancado,
            valorPesquisa,
            registros,
            exibirProdutosInativos,
            possuiModuloEstoque,
            podeVisualizarMovimentacaoEstoque,
            exibirModalEntradaEstoque,
            exibirModalSaidaEstoque,
            registroSelecionado,
            rows } = this.state

        return (
            <>
                <Menu style={{ width: "212px" }}
                    model={[
                        {
                            label: 'Nova entrada de estoque',
                            icon: 'fa fa-plus',
                            command: () => this.setState({ exibirModalEntradaEstoque: true }),
                            disabled: false
                        },
                        {
                            label: 'Nova saída de estoque',
                            icon: 'fa fa-minus',
                            command: () => this.setState({ exibirModalSaidaEstoque: true }),
                            disabled: false
                        },
                        {
                            label: 'Ajustar saldo em estoque',
                            icon: 'fa fa-wrench',
                            command: () => {
                                this.setState({ campoParaEditar: registroSelecionado })
                            },
                            disabled: false
                        }
                    ]}
                    popup={true}
                    ref={el => this.optionsEstoqueMenu = el}
                />
                <Tutorial
                    steps={tutorialSteps}
                    showSkipButton
                    continuous
                    visible={this.state.tutorialVisible}
                    onHide={() => this.setState({ tutorialVisible: false })}
                />

                <Form header={this.montarHeader()}>
                    <FormActions>
                        <ButtonNovo
                            className="step-listagem-novo"
                            label='Novo produto'
                            onClick={() => this.props.history.push('/produtos/cadastro')}
                            podeInserir={podeInserir}
                        />
                    </FormActions>
                    <FormContent>
                        <Grid justifyCenter >
                            <InputSearch
                                className="step-listagem-input-search"
                                onPesquisar={this.onPesquisar}
                                value={valorPesquisa}
                                onChange={value => this.setState({ valorPesquisa: value })}
                            />
                            <Col sm='12' md='4' lg='3' xl='3' className="step-listagem-filtro-avancado">
                                <PesquisaAvancada
                                    optionsFiltros={optionsFiltroAvancado}
                                    onPesquisarClick={this.onPesquisarFiltroAvancado}
                                    onChangeFiltroRsql={rsql => this.setState({ filtroAvancado: rsql })}
                                    onChangeDescricaoFiltro={e => this.setState({ descricaoFiltroAvancado: e })}
                                />
                            </Col>
                        </Grid>
                        <DescricaoFiltroAvancado texto={descricaoFiltroAvancado} />
                        <DataTable
                            className='table-light'
                            responsive
                            value={registros}
                            sortField={sortField}
                            sortOrder={sortOrder}
                            onSort={this.onSort}
                            emptyMessage={<NenhumRegistroEncontrado />}
                            header={
                                <span style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <Checkbox
                                        name="exibirProdutosInativos"
                                        inputId="exibirProdutosInativos"
                                        checked={exibirProdutosInativos}
                                        onChange={this.onChangeCheckboxExibirInativos}
                                    />
                                    <label htmlFor="exibirProdutosInativos" className="p-checkbox-label">Exibir produtos inativos</label>
                                </span>
                            }
                        >
                            <Column
                                className="step-listagem-order"
                                field="sku"
                                header="Código"
                                body={row => this.aplicarEstiloInativo(row, row.sku)}
                                sortable={true}
                                style={{ textOverflow: 'ellipsis', overflow: 'hidden', width: '130px' }}
                            />
                            <Column
                                field="nome"
                                header="Nome"
                                body={row => this.aplicarEstiloInativo(row, row.nome)}
                                sortable={true}
                            />
                            <Column
                                field="preco"
                                body={(row) => this.aplicarEstiloInativo(row, formatarMonetario(row.preco))}
                                header="Preço"
                                style={{ width: '130px' }}
                                sortable={true}
                            />
                            {possuiModuloEstoque && <Column
                                header="Estoque disponível"
                                field="estoqueSaldo"
                                body={row => this.renderEstoqueDisponivelField(row)}
                                style={{ width: '170px', textOverflow: 'ellipsis', overflow: 'hidden', paddingBottom: '0px', paddingTop: '0px' }}
                            />}
                            <Column
                                className="step-listagem-acoes"
                                body={this.renderOpcoes}
                                header="Ações"
                                style={{ width: '7em' }}
                            />
                        </DataTable>
                        <Paginator
                            className='paginator-light'
                            rowsPerPageOptions={[10, 25, 50]}
                            totalRecords={totalElements}
                            rows={rows}
                            first={page * rows}
                            onPageChange={this.onPageChange}
                            rightContent={<div style={{ margin: '5px' }}>{totalElements > 0 ? `${totalElements} registro(s) encontrado(s)` : null}</div>}
                        />
                        {possuiModuloEstoque && podeVisualizarMovimentacaoEstoque && (
                            <Grid justifyEnd verticalAlignCenter style={{ margin: '10px 0px' }}>
                                <div
                                    style={{ fontSize: '16px' }}
                                    title="Valor da soma de todos os produtos em estoque. Resultado gerado utilizando o método P.E.P.S."
                                >
                                    Valor total do estoque:&nbsp;<b>{formatarMonetario(this.state.valorEstoque)}</b>
                                </div>
                            </Grid>
                        )}
                    </FormContent>
                </Form>
                <If test={exibirModalEntradaEstoque && registroSelecionado}>
                    <ModalEntradaEstoque
                        visible={exibirModalEntradaEstoque}
                        produto={this.buscarProdutoSelecionado()}
                        onHide={this.onHideModaisEstoque}
                    />
                </If>
                <If test={exibirModalSaidaEstoque}>
                    <ModalSaidaEstoque
                        visible={exibirModalSaidaEstoque}
                        produto={this.buscarProdutoSelecionado()}
                        onHide={this.onHideModaisEstoque}
                    />
                </If>
            </>
        )
    }
}

export default Produtos
