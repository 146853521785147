import React from 'react';
import propTypes from 'prop-types';
import { formatarMonetario } from '../../../../../../../../common/mascara';
import NotificacaoTotalRegistros from '../NotificacaoTotalRegistros';
import Grid from '../../../../../../../../components/Grid'
import Col from '../../../../../../../../components/Col'

const styleCardContent = {
    borderRadius: '5px',
    cursor: 'default',
    padding: '7px',
    height: '100%'
};

const styleValue = {
    fontSize: '18px',
    fontWeight: 'bold',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textAlign: 'end',
    paddingBottom: '0px',
};

const SimpleCard = ({ colors, title, value = 0, numberOfElements = 0, helpMessage }) => {
    const valorFormatado = formatarMonetario(value);

    return (
        <div className="p-card" style={styleCardContent}>
            <Grid justifyBetween verticalAlignCenter>
                <Col col="6" title="Total de registros da categoria">
                    <NotificacaoTotalRegistros colors={colors} style={{ cursor: 'default' }} numberOfElements={numberOfElements} />
                </Col>
                <Col col="6" style={{ textAlign: 'end' }}>
                    <span title={helpMessage} style={{ color: '#424242', fontSize: '14px' }}>
                        {title}
                    </span>
                </Col>
            </Grid>
            <Grid>
                <Col title={valorFormatado} style={{ ...styleValue, color: colors.strongColor }}>
                    {valorFormatado}
                </Col>
            </Grid>
        </div>
    )
}

SimpleCard.propTypes = {
    numberOfElements: propTypes.number.isRequired,
    value: propTypes.number.isRequired,
    colors: propTypes.object.isRequired,
    title: propTypes.string.isRequired,
}

export default SimpleCard;
