import React from 'react';
import { Field } from 'formik';
import Grid from '../../../../../components/Grid';
import FormGroup from '../../../../../components/FormGoup';
import Dropdown from '../../../../../components/Select/Dropdown';
import InputField from '../../../../../components/Input/InputField';
import Checkbox from '../../../../../components/Input/Checkbox';
import { helpFieldText, helpTitleText } from './help';
import { INDICADOR_INSCRICAO_ESTADUAL } from '../../utils/constantes';

export function renderizarFieldsInformacoesFiscais(values, setFieldValue, informacoesPermissoes) {

    const isContribuinteIsento = values.indicadorInscricaoEstadual === 'CONTRIBUINTE_ISENTO'

    return(
        <FormGroup>
            <Grid>
                <Field sm="12" md="6" lg='4' xl='4'
                    component={Dropdown}
                    label='Indicador de inscrição estadual'
                    name="indicadorInscricaoEstadual"
                    helpMessage={helpFieldText.indicadorInscricaoEstadual}
                    onChange={(e) => {
                        setFieldValue('indicadorInscricaoEstadual', e.value )
                        if(e.value === 'CONTRIBUINTE_ISENTO'){
                            setFieldValue('inscricaoEstadual', null)
                        }
                    }}
                    options={INDICADOR_INSCRICAO_ESTADUAL}
                    value={values.indicadorInscricaoEstadual}
                    showClear={false}
                    {...informacoesPermissoes}
                />
                <Field sm="12" md="6" lg='4' xl='4'
                    component={InputField}
                    size={14}
                    title={isContribuinteIsento ? helpTitleText.inscricaoEstadual : ''}
                    type={values && values.indicadorInscricaoEstadual === "CONTRIBUINTE" ? "number" : "text"}
                    label="Inscrição estadual"
                    helpMessage={helpFieldText.inscricaoEstadual}
                    name="inscricaoEstadual"
                    value={values && values.inscricaoEstadual && values.indicadorInscricaoEstadual === 'NAO_CONTRIBUINTE' ? values.inscricaoEstadual.toUpperCase() : values.inscricaoEstadual }
                    disabled={values.indicadorInscricaoEstadual === 'CONTRIBUINTE_ISENTO'}
                    {...informacoesPermissoes}
                /> 
                <Field sm="12" md="6" lg='4' xl='4'
                    component={Checkbox}
                    colStyle={{ display: 'flex', alignItems: 'flex-end', marginBottom: '7px' }}
                    label="A pessoa é consumidor final"
                    name="consumidorFinal"
                    helpMessage={helpFieldText.consumidorFinal}
                    onChange={(e) => setFieldValue('consumidorFinal', e.checked)}
                    checked={values.consumidorFinal}
                    {...informacoesPermissoes}
                />
            </Grid>
        </FormGroup>
    )
}