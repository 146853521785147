import { services } from "../../../../common/constantes/api";
import { del, get, post, put, exibirToast } from "../../../../common/requisicoes";

export async function asyncGetProdutos(url, onSuccess, onError) {
    return await get(url, null, onSuccess, onError, true)
}

export async function asyncGetProduto(idProduto, onSuccess, onError) {
    await get(`${services.GESTOR}/v1/produtos/${idProduto}`, null, onSuccess, onError);
}

export async function asyncDeleteProduto(idProduto, onSuccess, onError) {
    await del(`${services.GESTOR}/v1/produtos/${idProduto}`, null, exibirToast(onSuccess, "Produto excluído"), onError);
}

export async function asyncCreateProduto(produto, onSuccess, onError) {
    await post(`${services.GESTOR}/v1/produtos`, produto, null, exibirToast(onSuccess, "Produto criado"), onError);
}

export async function asyncUpdateProduto(produto, onSuccess, onError) {
    await put(`${services.GESTOR}/v1/produtos/${produto.id}`, produto, null, exibirToast(onSuccess, "Produto atualizado"), onError);
}

export async function asyncGetProdutoMesmoSku(produto, onSuccess, onError) {
    await get(`${services.GESTOR}/v1/produtos?query=sku=="${produto}"`, null, onSuccess, onError);
}

export async function asyncAjustarSaldo(idProduto, data, onSuccess, onError) {
    await post(`${services.GESTOR}/v1/produtos/estoque/${idProduto}/ajustar_saldo`, data, null, exibirToast(onSuccess, "Saldo em estoque ajustado"), onError);
}

export async function asyncImprimirInventario(onSuccess, onError) {
    await get(`${services.GESTOR}/v1/produtos/estoque/imprimir`, { responseType: "arraybuffer" }, onSuccess, onError);
}

export async function asyncConsultarValorTotalEstoque(onSuccess, onError) {
    await get(`${services.GESTOR}/v1/produtos/estoque/valor_total`, null, onSuccess, onError);
}