import React, { Component } from 'react';
import { withFormik, Field } from 'formik';
import * as Yup from 'yup';
import FormGroup from '../../../../../../components/FormGoup';
import Grid from '../../../../../../components/Grid';
import LayoutMenuSuperior from '../../../../../../components/Layout/LayoutMenuSuperior';
import MenuSuperior from '../../../../../../components/MenuSuperior';
import ButtonNovo from '../../../../../../components/Button/ButtonNovo';
import ButtonSalvar, { estadosBotaoSalvar } from '../../../../../../components/Button/ButtonSalvar';
import ButtonCancelar, { estadosBotaoCancelar } from '../../../../../../components/Button/ButtonCancelar';
import InputField from '../../../../../../components/Input//InputField';
import ButtonExcluir from '../../../../../../components/Button/ButtonExcluir';
import { mensagensDeValidacao } from '../../../../../../common/constantes/mensagens';
import { estadosCadastro } from '../../../../../../common/constantes/autorizacao';
import { validarFormulario } from '../../../../../util';
import autoBind from 'react-autobind'
import MsysCheckbox from '../../../../../../components/Input/Checkbox';
import { manterApenasNumeros } from '../../../../../../common/mascara';
import SingleSelectPais from '../../../../../../components/Select/SingleSelectPais'
import SingleSelectMunicipio from '../../../../../../components/Select/SingleSelectMunicipio';
import { isValidCep } from '@brazilian-utils/validators';
import InputCep from '../../../../../../components/Input/InputCep';
import { helpPessoaEnderecoForm } from './help';
import Paper from '../../../../../../components/Paper';
import { gerarUUID } from '../../../../../../common/manipulacaoDeString';
import { converterPessoaEnderecoParaApi, converterPessoaEnderecoParaFormulario } from './util/pessoaEnderecoConverter';


export const idPaisBrasil = "34e63b2e-c596-f34f-824d-bfd27eb62fa8"

const initialValue = {
    id: "",
    cep: "",
    logradouro: "",
    bairro: "",
    numero: "",
    complemento: "",
    pais: { label: 'Brasil', value: idPaisBrasil },
    municipio: null,
    municipioExterior: null,
    estadoExterior: null,
    favorito: false,
    idTemporario: null
};

class PessoaEnderecoForm extends Component {

    constructor(props) {
        super(props);
        autoBind(this)

        this.state = {
            inserindoNovoRegistro: false,
        }
    }

    componentDidMount() {
        this.setState({ inserindoNovoRegistro: !this.props.registroSelecionado });
    }

    novo() {
        this.props.onNovoClick()
        this.props.resetForm({ values: initialValue })
        this.setState({ inserindoNovoRegistro: true })
    }

    excluir() {
        this.props.excluirRegistro(this.props.registroSelecionado)
        this.props.onHide()
    }

    async salvar() {
        this.props.handleSubmit();
        if (await validarFormulario(this.props)) {
            if (this.state.inserindoNovoRegistro) {
                this.criarRegistro()
            } else {
                this.atualizarRegistro()
            }
        }
    }

    criarRegistro() {
        const dadosFormulario = converterPessoaEnderecoParaApi(this.props.values);
        this.props.inserirRegistro({ ...dadosFormulario, idTemporario: gerarUUID() })
        this.props.onHide()
    }

    atualizarRegistro() {
        const dadosFormulario = converterPessoaEnderecoParaApi(this.props.values);
        this.props.atualizarRegistro(dadosFormulario);
        this.props.onHide()
    }

    cancelar() {
        if (this.props.dirty) {
            this.props.handleReset();
        } else {
            this.props.onHide()
        }
    }

    onChangeCep(e) {
        if (e.pesquisou) {
            const { setFieldValue } = this.props;
            e.bairro && setFieldValue('bairro', e.bairro);
            e.complemento && setFieldValue('complemento', e.complemento);
            e.logradouro && setFieldValue('logradouro', e.logradouro);
            e.municipio && setFieldValue('municipio', { label: e.municipio.nome + ' - ' + e.municipio.estado.sigla, value: e.municipio.id });
            e.pais && setFieldValue('pais', { label: e.pais.nome, value: e.pais.id });
        } else {
            this.props.setFieldValue('cep', e.value);
        }
    }

    render() {
        const informacoesPermissoes = {
            estadoCadastro: this.state.inserindoNovoRegistro ? estadosCadastro.INCLUSAO : estadosCadastro.EDICAO,
            podeInserir: this.props.podeEditar,
            podeEditar: this.props.podeEditar,
            podeExcluir: this.props.podeEditar
        };

        const { values } = this.props;
        const { inserindoNovoRegistro } = this.state

        return (
            <React.Fragment>
                <MenuSuperior isModal={true}>
                    <ButtonCancelar
                        {...informacoesPermissoes}
                        estadoBotao={this.props.dirty ? estadosBotaoCancelar.CANCELAR : estadosBotaoCancelar.VOLTAR}
                        onClick={this.cancelar}
                    />
                    <ButtonSalvar
                        estadoBotao={estadosBotaoSalvar.CONFIRMAR}
                        {...informacoesPermissoes}
                        disabled={!this.props.dirty}
                        onClick={this.salvar}
                    />
                    <ButtonNovo
                        onClick={this.novo}
                        hidden={inserindoNovoRegistro}
                        {...informacoesPermissoes}
                    />
                    <ButtonExcluir
                        hidden={inserindoNovoRegistro}
                        {...informacoesPermissoes}
                        onClick={this.excluir}
                        podeExcluir={this.props.podeExcluir}
                    />
                </MenuSuperior>
                <LayoutMenuSuperior isModal={true}>
                    <Paper
                        dirty={this.props.dirty}
                        {...informacoesPermissoes}
                        childsOnly={true}
                    >
                        <FormGroup>
                            <Grid>
                                <Field sm="12" md="12" lg="12" xl="12"
                                    component={MsysCheckbox}
                                    label="Endereço favorito"
                                    title="Marque esta opção caso o endereço seja o favorito"
                                    name="favorito"
                                    helpMessage={helpPessoaEnderecoForm.favorito}
                                    onChange={() => this.props.setFieldValue("favorito", !values.favorito)}
                                    checked={values.favorito}
                                    {...informacoesPermissoes}
                                />
                                <Field sm="12" md="4" lg="4" xl="4"
                                    component={InputCep}
                                    label="CEP"
                                    name="cep"
                                    type="tel"
                                    helpMessage={helpPessoaEnderecoForm.cep}
                                    value={values.cep}
                                    onChange={e => this.onChangeCep(e)}
                                    {...informacoesPermissoes}
                                />
                                <Field sm="12" md="4" lg="4" xl="4"
                                    component={InputField}
                                    type="text"
                                    label="Logradouro "
                                    obrigatorio
                                    helpMessage={helpPessoaEnderecoForm.logradouro}
                                    name="logradouro"
                                    value={values.logradouro}
                                    size={255}
                                    {...informacoesPermissoes}
                                />
                                <Field sm="12" md="4" lg="4" xl="4"
                                    component={InputField}
                                    type="text"
                                    label="Bairro"
                                    helpMessage={helpPessoaEnderecoForm.bairro}
                                    size={60}
                                    name="bairro"
                                    value={values.bairro}
                                    {...informacoesPermissoes}
                                />
                                <Field sm="12" md="4" lg="4" xl="4"
                                    component={InputField}
                                    type="text"
                                    label="Número"
                                    size={60}
                                    helpMessage={helpPessoaEnderecoForm.numero}
                                    name="numero"
                                    value={values.numero}
                                    {...informacoesPermissoes}
                                />
                                <Field sm="12" md="4" lg="4" xl="4"
                                    component={InputField}
                                    type="text"
                                    label="Complemento"
                                    name="complemento"
                                    value={values.complemento}
                                    helpMessage={helpPessoaEnderecoForm.complemento}
                                    size={255}
                                    {...informacoesPermissoes}
                                />
                                <Field sm="12" md="4" lg="4" xl="4"
                                    component={SingleSelectPais}
                                    label="País "
                                    obrigatorio
                                    helpMessage={helpPessoaEnderecoForm.pais}
                                    name="pais"
                                    onChange={e => this.props.setFieldValue('pais', e)}
                                    value={values.pais}
                                    {...informacoesPermissoes}
                                />
                                <Field sm="12" md="4" lg="4" xl="4"
                                    component={SingleSelectMunicipio}
                                    label="Município "
                                    name="municipio"
                                    obrigatorio
                                    helpMessage={helpPessoaEnderecoForm.municipio}
                                    onChange={e => this.props.setFieldValue("municipio", e)}
                                    value={values.municipio}
                                    hidden={(values.pais ? values.pais.value : null) !== idPaisBrasil}
                                    {...informacoesPermissoes}
                                />
                                <Field sm="12" md="4" lg="4" xl="4"
                                    component={InputField}
                                    type="text"
                                    label="Estado exterior"
                                    helpMessage={helpPessoaEnderecoForm.estadoExterior}
                                    name="estadoExterior"
                                    value={values.estadoExterior}
                                    size={128}
                                    hidden={(values.pais ? values.pais.value : null) === idPaisBrasil}
                                    {...informacoesPermissoes}
                                />
                                <Field sm="12" md="4" lg="4" xl="4"
                                    component={InputField}
                                    type="text"
                                    obrigatorio
                                    label="Município exterior "
                                    helpMessage={helpPessoaEnderecoForm.municipioExterior}
                                    name="municipioExterior"
                                    value={values.municipioExterior}
                                    size={128}
                                    hidden={(values.pais ? values.pais.value : null) === idPaisBrasil}
                                    {...informacoesPermissoes}
                                />
                            </Grid>
                        </FormGroup>
                    </Paper>
                </LayoutMenuSuperior>
            </React.Fragment>
        )
    }
}

PessoaEnderecoForm = withFormik({

    enableReinitialize: true,
    validateOnChange: false,

    mapPropsToValues(props) {
        if (props.registroSelecionado) {
            return converterPessoaEnderecoParaFormulario(props.registroSelecionado)
        }
        return { ...initialValue, favorito: props.marcarComoFavorito() };
    },

    validationSchema: Yup.object().shape({
        logradouro: Yup.string().nullable().required(mensagensDeValidacao.OBRIGATORIO),
    }),

    validate(values) {

        let errors = {};

        if (values.cep && !isValidCep(manterApenasNumeros(values.cep))) {
            errors.cep = 'Digite um CEP válido.';
        }

        if (values.pais && values.pais.value === idPaisBrasil) {
            if (!values.municipio) {
                errors.municipio = mensagensDeValidacao.OBRIGATORIO;
            }
        } else {
            if (!values.municipioExterior) {
                errors.municipioExterior = mensagensDeValidacao.OBRIGATORIO;
            }
        }
        return errors;
    },
})(PessoaEnderecoForm);

export default PessoaEnderecoForm
