export const modulos = {
    ADMINISTRACAO: 'ADMINISTRACAO',
    DOCUMENTOS_DIGITAIS: 'DOCUMENTOS_DIGITAIS',
    FINANCAS: 'FINANCAS',
    VENDAS: 'VENDAS',
    ESTOQUE: 'ESTOQUE',
    INVESTIDOR: 'INVESTIDOR'

}

export const recursos = {
    ORGANIZACOES: 'ORGANIZACOES',
    PAPEIS: 'PAPEIS',
    USUARIOS: 'USUARIOS',
    PESSOAS: 'PESSOAS',
    SETORES: 'SETORES',
    PRODUTOS: 'PRODUTOS', 
    PLANOS: 'PLANOS',
    PLANOS_ADMINISTRACAO: 'PLANOS_ADMINISTRACAO',
    DOCUMENTOS: 'DOCUMENTOS',
    DOCUMENTOS_DASHBOARD: 'DOCUMENTOS_DASHBOARD',
    EMPRESA_CONFIGURACOES: 'EMPRESA_CONFIGURACOES',
    FINANCAS_CONTAS: 'FINANCAS_CONTAS',
    FINANCAS_CATEGORIAS: 'FINANCAS_CATEGORIAS',
    FINANCAS_LANCAMENTOS: 'FINANCAS_LANCAMENTOS',
    FINANCAS_CONTAS_RECEBER: 'FINANCAS_CONTAS_RECEBER',
    FINANCAS_CONTAS_PAGAR: 'FINANCAS_CONTAS_PAGAR',
    FINANCAS_DASHBOARD: 'FINANCAS_DASHBOARD',
    VENDAS_DASHBOARD: 'VENDAS_DASHBOARD',
    VENDAS_VENDAS: 'VENDAS_VENDAS',
    ESTOQUE_MOVIMENTACOES: 'ESTOQUE_MOVIMENTACOES',
    ESTOQUE_DASHBOARD: 'ESTOQUE_DASHBOARD',
    AGENTES_AUTORIZADOS: 'AGENTES_AUTORIZADOS',
    INVESTIDOR_ATIVOS: 'INVESTIDOR_ATIVOS',
    INVESTIDOR_CORRETORAS: 'INVESTIDOR_CORRETORAS',
    INVESTIDOR_PROVENTOS: 'INVESTIDOR_PROVENTOS',
    INVESTIDOR_NEGOCIACOES: 'INVESTIDOR_NEGOCIACOES',
    INVESTIDOR_IMPORTACOES: 'INVESTIDOR_IMPORTACOES'
}

export const permissoes = {
    INSERIR: 'INSERIR',
    VISUALIZAR: 'VISUALIZAR',
    EDITAR: 'EDITAR',
    EXCLUIR: 'EXCLUIR',
    EXCLUIR_EMPRESA: 'EXCLUIR_EMPRESA',
    EDITAR_EMPRESA: 'EDITAR_EMPRESA'
}

export const estadosCadastro = {
    INCLUSAO: 'INCLUSAO',
    EDICAO: 'EDICAO'
}

export const SEMPERMISSAO = '(Sem permissão)'
