import React, { Component } from 'react';
import * as Yup from "yup";
import autoBind from 'react-autobind';
import propTypes from 'prop-types';
import Modal from '../../../../../components/Modal';
import { withRouter } from 'react-router';
import { withFormik, Field } from 'formik';
import { usuarioPossuiPermissao } from '../../../../../common/autenticacao';
import { recursos, permissoes, estadosCadastro } from '../../../../../common/constantes/autorizacao';
import AutoProgressBar from '../../../../../components/Loading/AutoProgressBar';
import ButtonSalvar from '../../../../../components/Button/ButtonSalvar';
import ButtonCancelar, { estadosBotaoCancelar } from '../../../../../components/Button/ButtonCancelar';
import Grid from '../../../../../components/Grid';
import InputDate from '../../../../../components/Input/InputDate';
import InputMoney from '../../../../../components/Input/InputMoney';
import { services } from '../../../../../common/constantes/api';
import { helpMessage } from './util/constantes';
import { confirm } from '../../../../../components/Toast';
import ButtonExcluir from '../../../../../components/Button/ButtonExcluir';
import ButtonNovo, { estadosBotaoNovo } from '../../../../../components/Button/ButtonNovo';
import TextArea from '../../../../../components/TextArea';
import Form from '../../../../../components/Form';
import FormActions from '../../../../../components/FormActions';
import FormContent from '../../../../../components/FormContent';
import { isValid, parseISO, formatISO } from 'date-fns';
import SingleSelectProduto from '../../../../../components/Select/SingleSelectProduto';
import { converterSaidaEstoqueParaApi } from './util/saidaEstoqueConverter';
import { asyncCreateSaidaEstoque, asyncDeleteSaidaEstoque, asyncUpdateSaidaEstoque } from './request';
import { mensagensDeValidacao } from '../../../../../common/constantes/mensagens';
import Message from '../../../../../components/Message';
import { TipoMovimentacaoDocumento } from '../../util/constantes';

const initialValue = {
    id: null,
    data: formatISO(new Date()),
    tipo: "SAIDA",
    produto: null,
    custo: null,
    quantidade: 1,
    observacao: '',
};

class ModalSaidaEstoque extends Component {

    constructor(props) {
        super(props);
        autoBind(this);

        this.state = {
            podeInserir: usuarioPossuiPermissao(recursos.ESTOQUE_MOVIMENTACOES, permissoes.INSERIR),
            podeEditar: usuarioPossuiPermissao(recursos.ESTOQUE_MOVIMENTACOES, permissoes.EDITAR),
            podeExcluir: usuarioPossuiPermissao(recursos.ESTOQUE_MOVIMENTACOES, permissoes.EXCLUIR),
        }
    }

    novo() {
        this.props.onNovoClick && this.props.onNovoClick();
        this.props.resetForm({ values: initialValue });
    }

    async salvar(e, novoOnSuccess) {
        await this.props.handleSubmit();
        await this.props.validateForm();

        if (this.props.isValid) {
            if (this.props.values.id) {
                this.asyncUpdateSaidaEstoque(this.props.values, novoOnSuccess)
            } else {
                this.asyncCreateSaidadaEstoque(this.props.values, novoOnSuccess)
            }
        }
    }

    async asyncCreateSaidadaEstoque(values, novoOnSuccess) {
        if (values) {
            await asyncCreateSaidaEstoque(converterSaidaEstoqueParaApi(this.props.values), () => {
                if (novoOnSuccess) {
                    this.props.atualizarListagem()
                    novoOnSuccess();
                } else {
                    this.props.onHide(values);
                }
            });
        }
    }

    async asyncUpdateSaidaEstoque(values, novoOnSuccess) {
        if (values) {
            await asyncUpdateSaidaEstoque(converterSaidaEstoqueParaApi(this.props.values), () => {
                if (novoOnSuccess) {
                    this.props.atualizarListagem()
                    novoOnSuccess();
                } else {
                    this.props.onHide(values);
                }
            });
        }
    }

    excluir() {
        confirm('Confirmação', 'Deseja excluir o registro?', async () => {
            await asyncDeleteSaidaEstoque(this.props.values.id, () => {
                this.props.onHide(true);
            });
        })
    }

    cancelar() {
        if (this.props.dirty) {
            this.props.resetForm({ values: this.props.initialValues });
        } else {
            this.props.onHide();
        }
    }

    verificarEstoqueMinimo() {
        const { produto, quantidade } = this.props.values;
        if (produto) {
            const { estoqueMinimo, estoqueSaldo } = produto.registro;
            const novoSaldoEmEstoque = (estoqueSaldo - quantidade);

            if (novoSaldoEmEstoque < estoqueMinimo) {
                return "Quantidade excederá o estoque mínimo"
            }
        }
        return null;
    }

    validaDocumentoTipo() {
        if (this.props.registroSelecionado?.venda.documentoTipo === TipoMovimentacaoDocumento.VENDA){
            return 'venda'
        } else{
            return 'NFC-e'
        }
    }

    render() {
        const { visible, onHide, dirty, values, geradoAPartirDeUmaVenda } = this.props;

        const informacoesPermissoes = {
            podeInserir: this.state.podeInserir,
            podeEditar: this.state.podeEditar,
            podeExcluir: this.state.podeExcluir,
            estadoCadastro: values.id ? estadosCadastro.EDICAO : estadosCadastro.INCLUSAO
        };

        const estadoBotaoNovo = dirty ? estadosBotaoNovo.SALVAR_E_NOVO : estadosBotaoNovo.NOVO;

        const onClickNovo = dirty ? (e) => this.salvar(e, this.novo) : this.novo;

        const DocumentoTipo = this.validaDocumentoTipo()

        return (
            <Modal
                header={values.id ? "Editar saída de estoque" : "Nova saída do estoque"}
                visible={visible}
                onHide={onHide}
            >
                <AutoProgressBar />
                <Form>
                    <FormActions>
                        <ButtonCancelar
                            {...informacoesPermissoes}
                            estadoBotao={dirty ? estadosBotaoCancelar.CANCELAR : estadosBotaoCancelar.VOLTAR}
                            onClick={this.cancelar}
                        />
                        <ButtonSalvar
                            {...informacoesPermissoes}
                            disabled={geradoAPartirDeUmaVenda}
                            onClick={this.salvar}
                        />
                        <ButtonNovo
                            onClick={onClickNovo}
                            hidden={!dirty && !values.id}
                            estadoBotao={estadoBotaoNovo}
                            {...informacoesPermissoes}
                        />
                        <ButtonExcluir
                            hidden={!values.id}
                            {...informacoesPermissoes}
                            disabled={geradoAPartirDeUmaVenda}
                            title={geradoAPartirDeUmaVenda ? `Esta movimentação foi gerada automaticamente a partir de uma ${DocumentoTipo} e não pode ser removida.` : null}
                            onClick={this.excluir}
                        />
                    </FormActions>
                    <FormContent>
                        <Grid>
                            <Message
                                style={{ marginTop: '10px', marginBottom: '10px' }}
                                hidden={!geradoAPartirDeUmaVenda}
                                severity="info"
                                text={`Esta movimentação foi gerada automaticamente a partir de uma ${DocumentoTipo} e não pode ser alterada.`}
                            />

                            <Field
                                sm="12" md="12" lg="12" xl="12"
                                name="produto"
                                label="Produto"
                                autofocus
                                component={SingleSelectProduto}
                                value={values.produto}
                                obrigatorio
                                disabled={geradoAPartirDeUmaVenda}
                                helpMessage={helpMessage.produto}
                                onChange={e => this.props.setFieldValue('produto', e)}
                                url={`${services.GESTOR}/v1/estoque/movimentacoes/relacoes/produtos`}
                                tipoPredefinido="PRODUTO"
                                filtroAdicionalUrl=";tipo==PRODUTO;controlarEstoque==true"
                                {...informacoesPermissoes}
                            />
                            <Field
                                sm="12" md="6" lg="4" xl="4"
                                component={InputMoney}
                                label='Quantidade'
                                name="quantidade"
                                id="quantidade"
                                obrigatorio
                                size={11}
                                disabled={geradoAPartirDeUmaVenda}
                                prefix=""
                                placeholder=""
                                warnings={this.verificarEstoqueMinimo()}
                                helpMessage={helpMessage.quantidade}
                                onChange={e => this.props.setFieldValue('quantidade', e.target.value)}
                                value={values.quantidade}
                                {...informacoesPermissoes}
                            />
                            <Field
                                sm="12" md="6" lg="4" xl="4"
                                component={InputDate}
                                obrigatorio
                                label="Data da saída"
                                name="data"
                                disabled={geradoAPartirDeUmaVenda}
                                helpMessage={helpMessage.data}
                                onChange={e => this.props.setFieldValue('data', e.target.value)}
                                value={values.data}
                                {...informacoesPermissoes}
                            />
                            <Field sm="12" md="12" lg='12' xl='12'
                                component={TextArea}
                                placeholder="Escreva sua observação aqui"
                                label='Observação'
                                name="observacao"
                                disabled={geradoAPartirDeUmaVenda}
                                helpMessage={helpMessage.observacao}
                                onChange={e => this.props.setFieldValue('observacao', e.target.value)}
                                {...informacoesPermissoes}
                            />
                        </Grid>
                    </FormContent>
                </Form>
            </Modal>
        )
    }
}

ModalSaidaEstoque.defaultProps = {
    geradoAPartirDeUmaVenda: false,
    produtoSelecionado: null
}

ModalSaidaEstoque.propTypes = {
    /** Caso o registro foi gerado através de uma venda, não poderá ser alterada. */
    geradoAPartirDeUmaVenda: propTypes.bool,

    /**Produto selecionado que vem como sugestão */
    produto: propTypes.object,

    /**Registro recebido com os dados para serem carregados no formulário */
    registroSelecionado: propTypes.object,

    /**Função responsável por passar null para o registroSelecionado e para o produto */
    onNovoClick: propTypes.func
}

ModalSaidaEstoque = withFormik({
    enableReinitialize: true,
    validateOnChange: false,

    mapPropsToValues(props) {
        if (props.registroSelecionado) {
            return props.registroSelecionado;
        } else {
            return { ...initialValue, produto: props.produto }
        }
    },

    validate(values) {
        let errors = {}

        if (values.quantidade <= 0) {
            errors.quantidade = "A quantidade deve ser maior que zero"
        }
        if (values.data && !isValid(parseISO(values.data))) {
            errors.data = mensagensDeValidacao.DATA_INVALIDA
        }

        return errors
    },

    validationSchema: Yup.object().shape({
        produto: Yup.object().nullable().required(mensagensDeValidacao.OBRIGATORIO),
        data: Yup.string().nullable().required(mensagensDeValidacao.DATA_INVALIDA),
        quantidade: Yup.string().nullable().required(mensagensDeValidacao.OBRIGATORIO),
        observacao: Yup.string().max(255, 'O campo observação não pode ter mais que 255 caracteres.').nullable(true)
    }),
    handleSubmit: () => { }
})(ModalSaidaEstoque);

export default withRouter(ModalSaidaEstoque);
