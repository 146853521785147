import React, { Component } from 'react';
import autoBind from 'react-autobind';
import Modal from '../../../../../../../components/Modal';
import { Field } from 'formik';
import Button from '../../../../../../../components/Button';
import { imprimirCorrecao, imprimirXMLCorrecao } from '../../../requests';
import { DataTable } from 'primereact/components/datatable/DataTable';
import { Column } from 'primereact/column';
import { format, parseISO } from 'date-fns';
import Grid from '../../../../../../../components/Grid';
import Col from '../../../../../../../components/Col';
import If from '../../../../../../../components/If';
import TextArea from '../../../../../../../components/TextArea';
import Message from '../../../../../../../components/Message';
import InputField from '../../../../../../../components/Input/InputField';
import { FaDownload } from 'react-icons/fa';
import { baixarArquivo } from '../../../../../../../common/relatorios';
import Fieldset from '../../../../../../../components/FieldSet';
import NenhumRegistroEncontrado from '../../../../../../../components/NenhumRegistroEncontrado';

const styleButton = {
    borderRadius: '50%',
    padding: '5px',
    width: '30px',
    height: '30px',
    margin: '2px'
};

const styleBotaoImprimir = {
    padding: '0px 10px',
    background: '#fff0',
    fontWeight: 'bold',
    fontSize: '15px',
    alignItems: 'center',
    border: 'none'
};

class ModalHistoricoCorrecoes extends Component {
    constructor(props) {
        super(props);

        autoBind(this);

        this.state = {
            mostrarModalVisualizacao: false,
            registroSelecionado: null,
            correcaoVigente: null,
            outrasCorrecoes: []
        }
    }

    componentDidMount() {
        this.buscarCorrecaoVigenteEAtualizarEstado();
    }

    buscarCorrecaoVigenteEAtualizarEstado() {
        const { correcoesNfe: correcoes } = this.props;
        const correcoesOrdenadas = correcoes.sort((a, b) => b.nSeqEvento - a.nSeqEvento);

        this.setState({
            correcaoVigente: correcoesOrdenadas.length > 0 ? correcoesOrdenadas[0] : {},
            outrasCorrecoes: correcoesOrdenadas.length > 0 ? correcoesOrdenadas.slice(1, correcoesOrdenadas.length + 1) : {},
        })
    }

    renderAcoesField(el) {
        return (
            <Button
                style={styleButton}
                className="p-button p-button-primary"
                icon="fa fa-eye"
                title="Visualizar descrição completa"
                onClick={() => this.setState({ registroSelecionado: el }, () => {
                    this.setState({ mostrarModalVisualizacao: true });
                })}
            />
        );
    }

    renderDataField(el) {
        if (el.dhEvento) {
            return <span>{format(parseISO(el.dhEvento, new Date()), 'dd/MM/yyyy HH:mm')}</span>
        }
        return "";
    }

    renderNumeroField(el) {
        return el.nSeqEvento;
    }

    renderDescricaoField(el) {
        return <span title={el.xCorrecao}>{el.xCorrecao}</span>;
    }

    render() {
        const { visible, onHide, idNfe, isMobile } = this.props;
        const { registroSelecionado, mostrarModalVisualizacao, correcaoVigente, outrasCorrecoes } = this.state;

        return (
            <Modal
                header="Histórico de correções"
                visible={visible}
                onHide={onHide}
            >
                <Fieldset legend="Correção vigente" className='fieldset-light'>
                    <Grid justifyEnd>
                        <span style={{ display: 'flex', alignItems: 'center', fontSize: '16px', cursor: 'pointer' }}>
                            <div
                                style={styleBotaoImprimir}
                                title="Baixar PDF da carta de correção"
                                onClick={() => imprimirCorrecao(idNfe, ({ data: arquivo }) => {
                                    baixarArquivo(arquivo, "PDF - Carta de correção", "application/pdf")
                                })}
                            >
                                <FaDownload size="15px" style={!isMobile && { marginRight: '5px' }} />
                                <span>PDF</span>
                            </div>
                            <div
                                style={styleBotaoImprimir}
                                title="Baixar XML da carta de correção"
                                onClick={() => {
                                    imprimirXMLCorrecao(idNfe, ({ data: arquivo }) => {
                                        baixarArquivo(arquivo, `XML - Carta de correção`, "application/xml")
                                    })
                                }}
                            >
                                <FaDownload size="15px" style={!isMobile && { marginRight: '5px' }} />
                                <span>XML</span>
                            </div>
                        </span>
                    </Grid>
                    <Grid>
                        <Field
                            sm="12" md="6" lg="6" xl="6"
                            component={InputField}
                            label="Data e hora do evento"
                            value={(correcaoVigente && correcaoVigente.dhEvento) ? format(parseISO(correcaoVigente.dhEvento, new Date()), 'dd/MM/yyyy HH:mm') : ''}
                            name="dhEvento"
                            disabled
                        />
                        <Field
                            sm="12" md="6" lg="6" xl="6"
                            component={InputField}
                            label="Número"
                            value={(correcaoVigente && correcaoVigente.nSeqEvento) ? correcaoVigente.nSeqEvento : ''}
                            name="nSeqEvento"
                            disabled
                        />
                        <Field
                            col="12"
                            component={TextArea}
                            label='Descrição do que foi corrigido'
                            name="xCorrecao"
                            value={(correcaoVigente && correcaoVigente.xCorrecao) ? correcaoVigente.xCorrecao : ''}
                            disabled
                        />
                    </Grid>

                </Fieldset>
                <Fieldset legend="Correções anteriores" className='fieldset-light'>
                    <Grid>
                        <DataTable
                            className="table-light"
                            value={outrasCorrecoes}
                            responsive
                            sortField="nSeqEvento"
                            sortOrder={-1}
                            emptyMessage={<NenhumRegistroEncontrado />}
                        >
                            <Column
                                header="Data"
                                field="dhEvento"
                                body={this.renderDataField}
                                style={{ color: '#333333', width: '150px', opacity: '0.5' }}
                            />
                            <Column
                                header="Número"
                                field="nSeqEvento"
                                body={this.renderNumeroField}
                                style={{ color: '#333333', width: '100px', opacity: '0.5' }}
                            />
                            <Column
                                header="Descrição"
                                field="xCorrecao"
                                body={this.renderDescricaoField}
                                style={{ color: '#333333', flex: '1 1 0%', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', opacity: '0.5' }}
                            />
                            <Column
                                header=""
                                body={this.renderAcoesField}
                                style={{ width: '75px' }}
                            />
                        </DataTable>
                    </Grid>
                </Fieldset>
                <If test={registroSelecionado}>
                    <Modal
                        header="Descrição completa"
                        visible={mostrarModalVisualizacao}
                        onHide={() => this.setState({ mostrarModalVisualizacao: false })}
                    >
                        <Message
                            colStyle={{ padding: '0px' }}
                            severity="warn"
                            text={<span>Atenção, esta carta de correção não é mais válida. <b>A última correção transmitida substitui as anteriores.</b></span>}
                            style={{ marginBottom: '10px' }}
                        />
                        <Grid>
                            <Col sm="12" md="6" lg="6" xl="6">
                                <b>Data:</b> {registroSelecionado && format(parseISO(registroSelecionado.dhEvento, new Date()), 'dd/MM/yyyy HH:mm:ss')}
                            </Col>
                            <Col sm="12" md="6" lg="6" xl="6">
                                <b>Número:</b> {registroSelecionado && registroSelecionado.nSeqEvento}
                            </Col>
                            <Col>
                                <b>Descrição:</b> {registroSelecionado && registroSelecionado.xCorrecao}
                            </Col>
                        </Grid>
                    </Modal>
                </If>
            </Modal>
        )
    }
}

export default ModalHistoricoCorrecoes;
