import { format } from "date-fns"
import { Condicoes } from "../../util/constantes"

export function converterParcelasParaModal(data) {
    return {
        pag: montarObjetoPag(data),
        totaisNfce: data.totaisNfce
    }
}

function montarObjetoPag(data) {
    if (validarValorParcelas(data) && data.pag.parcelas.length > 0) {
        return data.pag
    }
    return {
        id: null,
        condicao: Condicoes.A_VISTA,
        valorRecebido: 0,
        valorTroco: 0,
        quantidadeParcelas: 1,
        categoria: null,
        parcelas: [
            {
                forma: 'DINHEIRO',
                valor: data.totaisNfce.vNF + data.totaisNfce.vOutro,
                vencimento: format(new Date(), 'yyyy-MM-dd'),
            },
        ]
    }
}

function validarValorParcelas(data) {
    let valorParcelasSalvasNaBase = 0
    let valorTotalDaNfce = data.totaisNfce.vNF + data.totaisNfce.vOutro

    data.pag.parcelas.forEach(parcela => {
        valorParcelasSalvasNaBase += parcela.valor
    })

    if (parseFloat(valorParcelasSalvasNaBase.toFixed(2)) !== parseFloat(valorTotalDaNfce.toFixed(2))) {
        return false
    }

    return true

}