import React, { Component } from 'react'
import { usuarioPossuiPermissao } from '../../../common/autenticacao'
import autoBind from 'react-autobind';
import { permissoes, recursos } from '../../../common/constantes/autorizacao'
import ButtonNovo from '../../../components/Button/ButtonNovo'
import Form from '../../../components/Form'
import FormActions from '../../../components/FormActions'
import FormContent from '../../../components/FormContent'
import { asyncGetImportacoes, asyncDeleteRegistro, asyncImportar } from './request'
import Grid from '../../../components/Grid';
import NenhumRegistroEncontrado from '../../../components/NenhumRegistroEncontrado';
import { removerElementoPorId } from '../../../common/array';
import CardImportacao from './components/CardImportacao';
import { confirm } from '../../../components/Toast';
import If from '../../../components/If';
import ModalImportacao from './components/ModalImportacao';

class Importacoes extends Component {

    constructor(props) {

        super(props);

        autoBind(this);

        this.state = {
            podeInserir: usuarioPossuiPermissao(recursos.INVESTIDOR_IMPORTACOES, permissoes.INSERIR),
            podeVisualizar: usuarioPossuiPermissao(recursos.INVESTIDOR_IMPORTACOES, permissoes.VISUALIZAR),

            exibirModalImportacao: false,
            importacaoSelecionada: null,
            importacoes: []
        }
    }

    componentDidMount() {
        this.buscarTodasAsImportacoes();
    }

    buscarTodasAsImportacoes() {
        asyncGetImportacoes(({ data }) => {
            this.setState({ importacoes: data.content });
        })
    }

    editarImportacao(importacao) {
        this.setState({
            importacaoSelecionada: importacao,
            exibirModalImportacao: true
        });
    };

    excluirImportacao(importacao) {
        confirm('Confirmação', 'Tem certeza que deseja excluir a importação?', async () => {
            await asyncDeleteRegistro(importacao.id, () => {
                this.setState({ importacoes: removerElementoPorId(this.state.importacoes, importacao, 'id') });
            })
        })
    }

    importar(importacao) {
        confirm('Confirmação', 'O processo de sincronização de negociações pode demorar alguns segundos. Deseja continuar?', async () => {
            await asyncImportar(importacao.id, () => { })
        })
    }

    renderizarImportacoes() {

        let importacoes = this.state.importacoes;

        if (importacoes.length === 0) {
            return <NenhumRegistroEncontrado message='Nenhuma importação encontrada' />
        }

        return importacoes.map((importacao, key) => {
            return <CardImportacao
                key={key}
                importacao={importacao}
                editarimportacao={this.editarImportacao}
                excluirimportacao={this.excluirImportacao}
                importar={this.importar}
            />
        });
    }

    onHideModalImportacao() {
        this.buscarTodasAsImportacoes();
        this.setState({ exibirModalImportacao: false, importacaoSelecionada: null })
    }

    render() {

        const { podeInserir, exibirModalImportacao, importacaoSelecionada, importacoes } = this.state

        return (
            <>
                <Form header="Importações">
                    <FormActions>
                        <ButtonNovo
                            label="Nova importação"
                            onClick={() => this.setState({ exibirModalImportacao: true })}
                            disabled={!podeInserir || importacoes.length > 0}
                        />
                    </FormActions>
                    <FormContent>
                        <Grid>
                            {this.renderizarImportacoes()}
                        </Grid>
                    </FormContent>
                </Form>
                <If test={exibirModalImportacao}>
                    <ModalImportacao
                        visible={exibirModalImportacao}
                        onHide={this.onHideModalImportacao}
                        registroSelecionado={importacaoSelecionada}
                    />
                </If>
            </>
        )
    }
}

export default Importacoes