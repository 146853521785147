import React, { Component } from "react";
import propTypes from "prop-types";
import Button from "../";

class ButtonExcluir extends Component {
    getDisabled() {
        if (this.props.podeExcluir === false) {
            return true;
        }
        return this.props.disabled;
    }

    getTitle() {
        if (this.props.podeExcluir === false) {
            return "Você não possui permissão para executar essa ação";
        }
        return this.props.title;
    }

    render() {
        const {
            onClick,
            hidden,
            enableShortCut,
            id,
            style,
            label,
            raised,
            rounded
        } = this.props;

        return (
            <Button
                id={id}
                style={{ ...style, margin: "5px" }}
                label={label}
                type="button"
                color="danger"
                disabled={this.getDisabled()}
                onClick={onClick}
                hidden={hidden}
                title={this.getTitle()}
                icon="fa fa-trash-o"
                raised={raised}
                rounded={rounded}
                hotKey={["alt", "delete"]}
                enableShortCut={enableShortCut}
            />
        );
    }
}

ButtonExcluir.defaultProps = {
    hidden: false,
    disabled: false,
    enableShortCut: true,
    label: "Excluir",
    title: "Excluir o registro"
};

ButtonExcluir.propTypes = {
    /** Identificador do componente */
    id: propTypes.string,
    /** Derfine o botão como invisível */
    hidden: propTypes.bool,
    /** Ação executada ao clicar no botão */
    onClick: propTypes.func,
    /** Define o botão como desabilitado */
    disabled: propTypes.bool,
    /** Habilitar atalho */
    enableShortCut: propTypes.bool,
    /** Diz se o usuário possui permissão de excluir*/
    podeExcluir: propTypes.bool,
    /** Title do componente*/
    title: propTypes.string,
    /** Estilo do componente */
    style: propTypes.object
};

export default ButtonExcluir;
