import React from 'react';

const secaoStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '0px 15px'
}

const numeroStyle = {
    fontSize: '24px',
    color: "#006095",
    fontWeight: 'bold'
}

const visualizarVendaStyle = {
    color: "#006095",
    fontWeight: 'bold',
    display: 'flex',
    justifyContent: 'flex-end',
    marginRight: '25px',
    fontSize: '12px'
}

export default function NfeSerieNumero({ serie, numero, onVisualizarVendaClick, isMobile }) {
    return (
        <>
            <div style={{ display: 'flex', justifyContent: isMobile ? 'center' : 'flex-end' }}>
                <div style={secaoStyle}>
                    <div>Série</div>
                    <div
                        style={numeroStyle}
                        title={serie ? "Série definida nas configurações do sistema" : "Série não configurada"}
                    >
                        <span>{serie || "?"}</span>
                    </div>
                </div>
                <div style={secaoStyle} title={!numero ? "O número da nota é gerados automaticamente pelo sistema" : null}>
                    <div>Número</div>
                    <div style={numeroStyle}>
                        {numero || '-'}
                    </div>
                </div>
            </div>
            <div style={{ ...visualizarVendaStyle, justifyContent: isMobile ? 'center' : 'flex-end' }}>
                <span onClick={onVisualizarVendaClick} className='link_to'>
                    Visualizar venda
                </span>
            </div>
        </>
    );
}

