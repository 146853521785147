import React, { Component } from 'react';
import Chart from "chart.js";
import autoBind from 'react-autobind';
import { asyncGetVendasPorDia } from './requests';
import { ProgressBar } from 'primereact/progressbar';
import { formatarMonetario } from '../../../../../../../common/mascara';
import FalhaAoCarregarAsInformacoes from '../../../FalhaAoCarregarAsInformacoes';
import { parseISO, format } from 'date-fns';
import NenhumaInformacaoEncontrada from '../../../NenhumaInformacaoEncontrada';
import TituloCard from '../../../../../../../components/TituloCard';
import { Vendas } from '../../../../../../util/constantes';

const cardStyle = {
    padding: '7px',
    borderRadius: '5px',
    backgroundColor: 'white',
    boxShadow: '#00000033 0px 1px 3px 0px, #00000024 0px 1px 1px 0px, #0000001f 0px 2px 1px -1px',
    minHeight: '100%'
}

const dashboardContent = {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
}

let graficoVendas;

export default class GraficoVendasPorDia extends Component {
    graficoVendasPorDia = React.createRef();

    constructor(props) {
        super(props);

        autoBind(this);

        this.state = {
            loading: true,
            error: false,
            dia: [],
            valorVendas: [],
            valorCanceladas: [],
        }
    }

    componentDidMount() {
        this.carregarInformacoes();
    }

    componentDidUpdate(prevProps) {
        const { dataInicial, dataFinal } = this.props;

        if (prevProps.dataInicial !== dataInicial || prevProps.dataFinal !== dataFinal) {
            this.carregarInformacoes();
        }

        if (prevProps.atualizarGraficoNovamente !== this.props.atualizarGraficoNovamente) {
            this.carregarInformacoes(false);
        }
    }

    carregarInformacoes(exibirLoading = true) {
        const { dataInicial, dataFinal } = this.props
        this.setState({ loading: exibirLoading, error: false }, () => {
            asyncGetVendasPorDia(dataInicial, dataFinal, ({ data }) => {
                let dia = []
                let valorVendas = []
                let valorCanceladas = []

                data.forEach(elemento => {
                    dia.push(format(parseISO(elemento.data), "dd/MM/yyyy"));
                    valorVendas.push(elemento.valorVendas);
                    valorCanceladas.push(elemento.valorCanceladas);
                });

                this.setState({ dia, valorVendas, valorCanceladas, loading: false, error: false }, () => {
                    this.buildChart();
                });
            }, () => {
                this.setState({ error: true, loading: false })
            });
        })
    }

    buildChart = () => {
        if (!this.graficoVendasPorDia.current) {
            return
        }
        const myChartRef = this.graficoVendasPorDia.current.getContext("2d");

        if (typeof graficoVendas !== "undefined") graficoVendas.destroy(); //Necessário para atualizar o gráfico sem bugs

        graficoVendas = new Chart(myChartRef, {
            type: 'line',
            data: {
                labels: this.state.dia,
                datasets: [{
                    label: 'Vendas',
                    data: this.state.valorVendas,
                    borderColor: Vendas.cores.verde,
                    backgroundColor: Vendas.cores.preto
                },
                {
                    label: 'Cancelamentos',
                    data: this.state.valorCanceladas,
                    borderColor: Vendas.cores.vermelho,
                    backgroundColor: Vendas.cores.preto,
                }],
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                legend: {
                    display: true,
                    position: 'bottom',
                    labels: {
                        usePointStyle: true
                    },
                    onHover: function (e) {
                        e.target.style.cursor = 'pointer';
                    },
                },
                hover: {
                    onHover: function (e) {
                        var point = this.getElementAtEvent(e);
                        if (point.length){ 
                            e.target.style.cursor = 'pointer';
                        }else {
                            e.target.style.cursor = 'default';
                        }
                    },
                },
                aspectRatio: 1,
                animation: {
                    animateScale: true,
                    animateRotate: true
                },
                tooltips: {
                    callbacks: {
                        title: tooltipItem => {
                            return `Competência: ${tooltipItem[0].xLabel}`;
                        },
                        label: (tooltipItem, data) => {
                            const label = data.datasets[tooltipItem.datasetIndex].label;
                            const valor = formatarMonetario(tooltipItem.yLabel);
                            return `${label}: ${valor}`;
                        },
                    }
                },
                plugins: {
                    datalabels: {
                        color: Vendas.cores.preto,
                    }
                },
                scales: {
                    xAxes: [{
                        ticks: {
                            callback: value => value
                        }

                    }],
                    yAxes: [{
                        ticks: {
                            callback: value => formatarMonetario(value),
                            beginAtZero: true
                        }
                    }],
                },
            }
        });
    }

    render() {


        const { loading, error, valorVendas, valorCanceladas } = this.state
        const totaisVendas = valorVendas.reduce((a, b) => a + b, 0)
        const totaisCanceladas = valorCanceladas.reduce((a, b) => a + b, 0)
        if (loading || error || (totaisVendas === 0 && totaisCanceladas === 0)) {
            return (
                <div style={cardStyle}>
                    <TituloCard
                        title={"Vendas no período"}
                        helpMessage="Apresenta o valor total das vendas agrupadas por dia no período"
                    />
                    <div style={dashboardContent}>
                        {loading && <ProgressBar mode="indeterminate" style={{ height: '3px', width: '100%', margin: '10px 0px' }} />}
                        {error && <FalhaAoCarregarAsInformacoes />}
                        {(totaisVendas === 0 && totaisCanceladas === 0) && <NenhumaInformacaoEncontrada />}
                    </div>
                </div>
            )
        } else {
            return (
                <div style={cardStyle}>
                    <TituloCard
                        title={"Vendas no período"}
                        helpMessage="Lista o valor total das vendas agrupadas por mês nos últimos doze meses"
                    />
                    <div style={{ marginTop: '20px' }}>
                        <canvas id="graficoVendasPorDia" ref={this.graficoVendasPorDia} />
                    </div>
                </div>
            )
        }
    }
}
