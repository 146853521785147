import React, { Component } from 'react';
import autoBind from 'react-autobind';
import SingleSelect from '../SingleSelect';
import { services } from '../../../common/constantes/api';

class SingleSelectCfop extends Component {
    constructor(props) {
        super(props);

        autoBind(this);

        this.state = {
            visible: false,
        }
    }

    buscarUrlPesquisa(pesquisa, page) {
        return `${services.GESTOR}/v1/cfops/?query=descricao=contains="*${pesquisa}*",codigo=contains="${pesquisa}*"&page=${page}&size=50`
    }

    montarLabel(row) {
        return row.codigo + ' - ' + row.descricao
    }

    render() {
        return (
            <React.Fragment>
                <SingleSelect
                    buscarUrlPesquisa={this.buscarUrlPesquisa}
                    montarLabel={this.montarLabel}
                    label={this.props.label}
                    {...this.props}
                    onChange={this.props.onChange}
                    esconderBotao
                />
            </React.Fragment>
        );
    }
}

SingleSelectCfop.defaultProps = {
    label: 'CFOP'
}

export default SingleSelectCfop;