import { del, post, put, exibirToast } from "../../../../../../common/requisicoes";
import { services } from "../../../../../../common/constantes/api";

export async function asyncCreateEntradaEstoque(data, onSuccess, onError) {
    await post(`${services.GESTOR}/v1/estoque/movimentacoes`, data, null, exibirToast(onSuccess, "Movimentação criada"), onError);
}

export async function asyncUpdateEntradaEstoque(lancamento, onSuccess, onError) {
    await put(`${services.GESTOR}/v1/estoque/movimentacoes/${lancamento.id}`, lancamento, null, exibirToast(onSuccess, "Movimentação atualizada"), onError);
}

export async function asyncDeleteEntradaEstoque(lancamento, onSuccess, onError) {
    await del(`${services.GESTOR}/v1/estoque/movimentacoes/${lancamento}`, null, exibirToast(onSuccess, "Movimentação removida"), onError);
}
