import React, { Component } from 'react';
import ButtonNovo from '../../../components/Button/ButtonNovo'
import { recursos, permissoes } from '../../../common/constantes/autorizacao.js';
import { formatarParaPesquisarTiposEnumerados, removerCaracteres } from '../../../common/manipulacaoDeString/index.js';
import Grid from '../../../components/Grid';
import Col from '../../../components/Col';
import PesquisaAvancada from '../../../components/PesquisaAvancada';
import DescricaoFiltroAvancado from '../../../components/DescricaoFiltroAvancado';
import { optionsFiltroAvancado } from './utils/constantes';
import { asyncGetPesquisaPessoas, asyncDeletePessoa } from './requests'
import { removerElemento } from '../../../common/array';
import { confirmarExclusao } from '../../util/exclusaoDeRegistros';
import { atualizarUrl } from '../../util';
import { Column } from 'primereact/column';
import ButtonEditarTable from '../../../components/Button/ButtonEditarTable';
import ButtonExcluirTable from '../../../components/Button/ButtonExcluirTable';
import InputSearch from '../../../components/Input/InputSearch';
import autoBind from 'react-autobind'
import { formatarTelefone, inserirMascara, manterApenasNumeros } from '../../../common/mascara';
import { formatos } from '../../../common/constantes/mascaras';
import { usuarioPossuiPermissao } from '../../../common/autenticacao';
import { construirUrl } from '../../../common/rsql';
import { services } from '../../../common/constantes/api.js';
import { configuracoesUsuario, buscarConfiguracaoUsuario, salvarConfiguracaoUsuario } from '../../../common/configuracaoUsuario';
import Tutorial from '../../../components/Tutorial';
import { tutorialSteps } from '../../../components/Tutorial/steps/listagens';
import FormActions from '../../../components/FormActions';
import FormContent from '../../../components/FormContent';
import Form from '../../../components/Form';
import { DataTable } from 'primereact/components/datatable/DataTable';
import { Paginator } from 'primereact/paginator';
import NenhumRegistroEncontrado from '../../../components/NenhumRegistroEncontrado';

class Pessoas extends Component {

    constructor(props) {
        super(props)
        autoBind(this)
        this.state = {
            podeInserir: usuarioPossuiPermissao(recursos.PESSOAS, permissoes.INSERIR),
            podeExcluir: usuarioPossuiPermissao(recursos.PESSOAS, permissoes.EXCLUIR),
            deveExibirTutorial: buscarConfiguracaoUsuario(configuracoesUsuario.EXIBIR_TUTORIAL_LISTAGENS),
            descricaoFiltroAvancado: "",
            valorPesquisa: "",
            registros: [],
            sortField: 'nome',
            sortOrder: 1,
            page: 0,
            rows: 10,
            totalElements: 0,
            tutorialVisible: false
        }
    }

    componentDidMount() {
        this.pesquisar()

        if (this.state.deveExibirTutorial !== false) {
            this.setState({ tutorialVisible: true })
            salvarConfiguracaoUsuario(configuracoesUsuario.EXIBIR_TUTORIAL_LISTAGENS, false, null, false)
        }
    }

    async pesquisar() {
        const { page, sortField, sortOrder, rows } = this.state
        const filtro = this.buscarFiltro()
        const url = construirUrl(`${services.GESTOR}/v1/pessoas/resumo`, filtro, rows, page, sortOrder > 0 ? `${sortField},asc` : `${sortField},desc`)

        await asyncGetPesquisaPessoas(url, ({ data: pessoas }) => {
            this.setState({ registros: pessoas.content, totalElements: pessoas.totalElements })
        })
    }

    buscarFiltro() {
        const { valorPesquisa } = this.state;
        let filtroAvancado = this.state.filtroAvancado || "";

        if (filtroAvancado.substr(0, 10) === "telefone==") {
            filtroAvancado = `telefone==${manterApenasNumeros(filtroAvancado)}`;
        } else if (filtroAvancado.substr(0, 10) === "telefone=c") {
            filtroAvancado = `telefone=contains="*${manterApenasNumeros(filtroAvancado)}*"`;
        } else if (filtroAvancado.substr(0, 10) === "telefone!=") {
            filtroAvancado = `telefone!=${manterApenasNumeros(filtroAvancado)}`;
        }

        const valorTiposEnumerados = formatarParaPesquisarTiposEnumerados(valorPesquisa);
        const pesquisaCodigo = removerCaracteres(valorPesquisa, ['.', '/', '-']);

        let result = `?query=(nome=contains="*${valorPesquisa}*",tipo=contains="*${valorTiposEnumerados}*",situacao=contains="*${valorTiposEnumerados}*",cpf=contains="*${pesquisaCodigo}*",cnpj=contains="*${pesquisaCodigo}*",email=contains="*${valorPesquisa}*",telefone=contains="*${valorPesquisa}*")`;

        if (filtroAvancado) {
            result += `;${filtroAvancado}`;
        }

        return result;
    }

    onPesquisar() {
        this.setState({ page: 0 }, () => {
            this.pesquisar();
        })
    }

    onPageChange(e) {
        this.setState({ page: e.page, rows: e.rows }, () => {
            this.pesquisar()
        })
    }

    onSort(e) {
        this.setState({ sortField: e.sortField, sortOrder: e.sortOrder }, () => {
            this.pesquisar()
        })
    }

    onPesquisarFiltroAvancado(e) {
        this.setState({ filtroAvancado: e }, () => {
            this.pesquisar()
        });
    }

    onEditar(row) {
        atualizarUrl(this.props.history, '/pessoas/cadastro', row.id)
    }

    onExcluir(row) {
        confirmarExclusao(() => this.asyncExcluirRegistro(row))
    }

    async asyncExcluirRegistro(registro) {
        await asyncDeletePessoa(registro.id, () => {
            this.setState({
                registros: removerElemento(this.state.registros, registro),
                totalElements: this.state.totalElements - 1
            })
        })
    }

    renderOpcoes(row) {
        return (
            <>
                <ButtonEditarTable
                    onClick={() => this.onEditar(row)}
                />
                <ButtonExcluirTable
                    onClick={() => this.onExcluir(row)}
                    podeExcluir={this.state.podeExcluir}
                />
            </>
        )
    }


    renderCpfCnpj(row) {
        if (row.tipo === 'JURIDICA') {
            return inserirMascara(row.cnpj, formatos.CNPJ);
        } else if (row.tipo === 'FISICA') {
            return inserirMascara(row.cpf, formatos.CPF);
        } else if (row.tipo === 'ESTRANGEIRO') {
            return row.identificacao;
        }
    }

    renderSituacao(row) {
        const label = row.situacao === 'ATIVA' ? 'Ativa' : 'Inativa'
        const strongColor = row.situacao === 'ATIVA' ? 'green' : '#da1f1f'
        const lightColor = row.situacao === 'ATIVA' ? '#dcedc8' : '#ffcdd2'

        return (
            <span style={{
                backgroundColor: lightColor,
                color: strongColor,
                fontWeight: 'bold',
                fontSize: '13px',
                borderRadius: '3px',
                padding: '3px 10px',
            }}>
                <span style={{ width: '100%', textAlign: 'center' }}>{label}</span>
            </span>
        )
    }

    renderTipo(row) {
        switch (row.tipo) {
            case 'FISICA': {
                return 'Física'
            }
            case 'JURIDICA': {
                return 'Jurídica'
            }
            case 'ESTRANGEIRO': {
                return 'Estrangeiro'
            }
            default: return row.tipo
        }
    }

    renderNome(row) {
        return <span title={row.nome}>{row.nome}</span>
    }

    renderEmail(row) {
        return <span title={row.email}>{row.email}</span>
    }

    render() {
        const { page, rows, totalElements, podeInserir, sortField, sortOrder, registros, descricaoFiltroAvancado, valorPesquisa } = this.state

        return (
            <>
                <Tutorial
                    steps={tutorialSteps}
                    showSkipButton
                    continuous
                    visible={this.state.tutorialVisible}
                    onHide={() => this.setState({ tutorialVisible: false })}
                />

                <Form header="Pessoas">
                    <FormActions>
                        <ButtonNovo
                            label='Nova pessoa'
                            className="step-listagem-novo"
                            onClick={() => { this.props.history.push('/pessoas/cadastro') }}
                            podeInserir={podeInserir}
                        />
                    </FormActions>
                    <FormContent>
                        <Grid justifyCenter>
                            <InputSearch
                                className="step-listagem-input-search"
                                onPesquisar={this.onPesquisar}
                                value={valorPesquisa}
                                onChange={value => this.setState({ valorPesquisa: value })}
                            />
                            <Col sm='12' md='4' lg='4' xl='3' className="step-listagem-filtro-avancado">
                                <PesquisaAvancada
                                    optionsFiltros={optionsFiltroAvancado}
                                    onPesquisarClick={this.onPesquisarFiltroAvancado}
                                    onChangeFiltroRsql={(rsql) => this.setState({ filtroAvancado: rsql })}
                                    onChangeDescricaoFiltro={e => this.setState({ descricaoFiltroAvancado: e })}
                                />
                            </Col>
                        </Grid>
                        <DescricaoFiltroAvancado texto={descricaoFiltroAvancado} />
                        <DataTable
                            className="table-light"
                            responsive
                            value={registros}
                            sortField={sortField}
                            sortOrder={sortOrder}
                            onSort={this.onSort}
                            emptyMessage={<NenhumRegistroEncontrado />}
                        >
                            <Column
                                className="step-listagem-order"
                                field="nome"
                                header="Nome"
                                sortable={true}
                                body={this.renderNome}
                                style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}
                            />
                            <Column
                                field="cpfcnpj"
                                header="CPF/CNPJ"
                                body={this.renderCpfCnpj}
                                style={{ width: '200px' }}
                            />
                            <Column
                                field="tipo"
                                header="Tipo"
                                sortable={true}
                                body={this.renderTipo}
                                style={{ width: '95px' }}
                            />
                            <Column
                                field="telefone"
                                header="Telefone"
                                sortable={true}
                                body={row => formatarTelefone(row.telefone)}
                                style={{ width: '130px' }}
                            />
                            <Column
                                style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}
                                field="email"
                                header="Email"
                                sortable={true}
                                body={this.renderEmail}
                            />
                            <Column
                                field="situacao"
                                header="Situação"
                                body={this.renderSituacao}
                                sortable={true}
                                style={{ width: '130px' }}
                            />
                            <Column
                                className="step-listagem-acoes"
                                body={this.renderOpcoes}
                                header="Ações"
                                style={{ width: '7em' }}
                            />
                        </DataTable>
                    </FormContent>
                    <Paginator
                        className='paginator-light'
                        rows={rows}
                        totalRecords={totalElements}
                        rowsPerPageOptions={[10, 25, 50]}
                        first={page * rows}
                        onPageChange={this.onPageChange}
                        rightContent={<div style={{ margin: '5px' }}>{totalElements > 0 ? `${totalElements} registro(s) encontrado(s)` : null}</div>}
                    />
                </Form>
            </>
        )
    }
}

export default Pessoas
