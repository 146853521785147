import { get, del, put, exibirToast, post } from '../../../../../common/requisicoes';
import { formatISO } from 'date-fns';
import { services } from '../../../../../common/constantes/api';
import { buscarDadosLoginLocalStorage } from '../../../../../common/autenticacao';


export async function buscarProximoNumero(onSuccess) {
    return await get(`${services.GESTOR}/v1/vendas/proximo_numero`, null, ({ data }) => {
        if (data) {
            onSuccess(data.numero);
        } else {
            onSuccess(null);
        }
    });
}

export async function buscarVendedorInicial(onSuccess) {
    const { email } = buscarDadosLoginLocalStorage();
    await get(`${services.GESTOR}/v1/vendas/relacoes/vendedores?query=credencial.email==${email}`, null, ({ data }) => {
        if (data && data.content) {
            onSuccess({
                label: data.content[0].nome,
                value: data.content[0].id
            });
        } else {
            onSuccess(null);
        }
    })
}

export async function asyncDeleteVenda(idVenda, messageOnSuccess = "Registro excluído com sucesso", onSuccess, onError) {
    await del(`${services.GESTOR}/v1/vendas/${idVenda}`, null, exibirToast(onSuccess, messageOnSuccess), onError);
}

export async function asyncGetVenda(idVenda, onSuccess, onError) {
    await get(`${services.GESTOR}/v1/vendas/${idVenda}`, null, onSuccess, onError);
}

export async function asyncCreateVenda(data, messageOnSuccess = "Registro criado com sucesso", onSuccess, onError) {
    await post(`${services.GESTOR}/v1/vendas`, data, null, exibirToast(onSuccess, messageOnSuccess), onError);
}

export async function asyncUpdateVenda(venda, messageOnSuccess = "Registro atualizado com sucesso", onSuccess, onError) {
    await put(`${services.GESTOR}/v1/vendas/${venda.id}`, venda, null, exibirToast(onSuccess, messageOnSuccess), onError);
}

export async function asyncEnviarVendaPorEmail(vendaId, body, onSuccess, onError) {
    await post(`${services.GESTOR}/v1/vendas/${vendaId}/enviar_por_email`, body, null, exibirToast(onSuccess, "Emails enviados"), onError);
}

export async function asyncBuscarEmailsPessoa(pessoaId, onSuccess, onError) {
    await get(`${services.GESTOR}/v1/vendas/relacoes/clientes/${pessoaId}/emails`, null, onSuccess, onError);
}

export async function asyncGetVendasOrcamentos(url, onSuccess, onError) {
    return await get(url, null, onSuccess, onError);
}

export async function asyncGetTotalizadores(interval, onSuccess, onError) {
    return await get(`${services.GESTOR}/v1/vendas/totais?dataInicial=${formatISO(interval.dataInicial, {representation: 'date'})}&dataFinal=${formatISO(interval.dataFinal, {representation: 'date'})}`, null, onSuccess, onError);
}

export async function asyncGerarNfe(vendaId, onSuccess, onError) {
    await post(`${services.GESTOR}/v1/vendas/${vendaId}/gerar_nfe`, null, null, exibirToast(onSuccess, "Nota fiscal gerada"), onError);
}
