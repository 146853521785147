import React, { Component } from 'react'
import { Field, withFormik } from 'formik';
import Button from '../../../../components/Button';
import ButtonExcluir from '../../../../components/Button/ButtonExcluir';
import InputField from '../../../../components/Input//InputField'
import Grid from '../../../../components/Grid';
import * as Yup from 'yup';
import { withRouter } from 'react-router';
import {
    asyncBloquearCredencial,
    asyncDeleteUsuario,
    asyncDesbloquearCredencial,
    asyncGetUsuario,
    atualizarUsuario,
    registrarNovoUsuario
} from './requests';
import MultipleSelectPapeis from '../../../../components/Select/MultipleSelectPapeis';
import { estadosCadastro, permissoes, recursos } from '../../../../common/constantes/autorizacao';
import ButtonSalvar from '../../../../components/Button/ButtonSalvar';
import { confirmarExclusao } from '../../../util/exclusaoDeRegistros';
import { validarUUID } from '../../../../common/manipulacaoDeString';
import {
    atualizarUrl,
    metodosAtualizarUrl,
    validarFormulario,
    voltarParaAPesquisa
} from '../../../util';
import autoBind from 'react-autobind';
import MultipleSelectSetores from '../../../../components/Select/MultipleSelectSetores';
import { services } from '../../../../common/constantes/api';
import { converterUsuarioParaApi, converterUsuarioParaFormulario } from './functions';
import { confirm } from '../../../../components/Toast';
import { mensagensDeValidacao } from '../../../../common/constantes/mensagens';
import ButtonNovo, { estadosBotaoNovo } from '../../../../components/Button/ButtonNovo';
import ButtonCancelar, { estadosBotaoCancelar } from '../../../../components/Button/ButtonCancelar';
import { helpFieldText } from './help';
import { buscarDadosLoginLocalStorage, usuarioPossuiPermissao } from '../../../../common/autenticacao';
import Form from '../../../../components/Form';
import FormActions from '../../../../components/FormActions';
import FormContent from '../../../../components/FormContent';

const CADASTROURL = '/usuarios/cadastro'
const PESQUISAURL = '/usuarios'

const initialValues = {
    id: '',
    nome: '',
    email: '',
    ativa: '',
    bloqueada: '',
    papeis: [],
    setores: []
};

class UsuariosForm extends Component {

    constructor(props) {
        super(props);

        autoBind(this);

        this.state = {
            podeInserir: usuarioPossuiPermissao(recursos.USUARIOS, permissoes.INSERIR),
            podeEditar: usuarioPossuiPermissao(recursos.USUARIOS, permissoes.EDITAR),
            podeExcluir: usuarioPossuiPermissao(recursos.USUARIOS, permissoes.EXCLUIR),
            podeEditarDocumentos: usuarioPossuiPermissao(recursos.DOCUMENTOS, permissoes.EDITAR),
            emailUsuarioLogado: buscarDadosLoginLocalStorage().email
        };
    }

    componentDidMount() {
        const id = this.props.match.params.id;

        if (validarUUID(id) && !this.props.isModal) {
            this.asyncSelectRegistro(id);
        } else {
            this.props.resetForm({ values: { ...initialValues, setores: [] } });
        }
    }

    excluir() {
        confirmarExclusao(this.asyncDeleteRegistro)
    }

    async asyncDeleteRegistro() {
        await asyncDeleteUsuario(this.props.values.id, () => {
            this.props.resetForm()
            voltarParaAPesquisa(this.props.history, PESQUISAURL);
        })
    }

    async asyncSelectRegistro(idUsuario) {
        await asyncGetUsuario(idUsuario, async ({ data: usuario }) => {
            let valoresFormulario = converterUsuarioParaFormulario({ ...usuario });

            this.props.resetForm({ values: valoresFormulario });

            if (!this.props.isModal) {
                atualizarUrl(this.props.history, CADASTROURL, usuario.id, metodosAtualizarUrl.POP)
            }
        })
    }

    bloquearCredencial() {
        const { email, id } = this.props.values;

        confirm('Confirmação', 'Tem certeza que deseja bloquear o usuário ' + email + '?', async () => {
            await asyncBloquearCredencial(id, () => {
                this.props.resetForm({ values: { ...this.props.values, bloqueada: true } })
            })
        })
    }

    desbloquearCredencial() {
        const { email, id } = this.props.values;

        confirm('Confirmação', 'Tem certeza que deseja desbloquear o usuário ' + email + '?', async () => {
            await asyncDesbloquearCredencial(id, () => {
                this.props.resetForm({ values: { ...this.props.values, bloqueada: false } });
            })
        })
    }

    async salvar(e, novoOnSuccess) {

        this.props.handleSubmit();

        if (await validarFormulario(this.props)) {
            const { emailUsuarioLogado } = this.state;

            if (this.props.values.id) {
                this.modificarUsuario({ ...this.props.values, emailUsuarioLogado }, novoOnSuccess);
            } else {
                this.criarUsuario({ ...this.props.values, emailUsuarioLogado }, novoOnSuccess);
            }
        }
    }

    async modificarUsuario(dadosForm, novoOnSuccess) {
        if (this.props.isModal && !this.props.dirty && this.props.values.id) {
            this.fecharModal();
        } else {
            await atualizarUsuario(converterUsuarioParaApi(dadosForm), () => {
                if (novoOnSuccess) {
                    novoOnSuccess();
                } else {
                    this.props.resetForm({ values: dadosForm })
                }
            });
        }
    }

    async criarUsuario(dadosForm, novoOnSuccess) {
        await registrarNovoUsuario(converterUsuarioParaApi(dadosForm), ({ data: usuario }) => {
            if (novoOnSuccess) {
                novoOnSuccess();
            } else {
                this.props.resetForm({ values: { ...this.props.values, id: usuario.id, bloqueada: false } })
                atualizarUrl(this.props.history, CADASTROURL, usuario.id, metodosAtualizarUrl.POP);
            }
        });
    }

    novo() {
        atualizarUrl(this.props.history, CADASTROURL, null, metodosAtualizarUrl.POP);

        this.props.resetForm({ values: { ...initialValues, setores: [] } })

        this.setState({
            exibirBotaoBloquear: false,
            exibirBotaoDesbloquear: false,
            exibirBotaoExcluir: false,
        })
    }

    cancelar() {
        if (this.props.dirty) {
            this.props.resetForm({ values: this.props.initialValues })
        } else if (this.props.isModal) {
            this.fecharModal()
        } else {
            voltarParaAPesquisa(this.props.history, PESQUISAURL)
        }
    }

    fecharModal() {
        if (this.props.values.id) {
            const urlRegistro = `${this.props.service}usuarios/${this.props.values.id}`;
            this.props.hideModal(urlRegistro);
        } else {
            this.props.hideModal();
        }
    }

    render() {

        const { podeInserir, podeEditar, podeExcluir, podeEditarDocumentos } = this.state

        const informacoesPermissoes = {
            podeInserir: podeInserir,
            podeEditar: podeEditar,
            podeExcluir: podeExcluir,
            estadoCadastro: estadosCadastro.EDICAO
        };

        const { values, dirty, isModal } = this.props;


        const estadoBotaoNovo = dirty ? estadosBotaoNovo.SALVAR_E_NOVO : estadosBotaoNovo.NOVO;

        const onClickNovo = dirty ? (e) => this.salvar(e, this.novo) : this.novo;

        return (
            <>
                <Form header="Cadastro de usuário" className="card-default screen-max-width">
                    <FormActions className="screen-max-width">
                        <ButtonCancelar
                            {...informacoesPermissoes}
                            hidden={isModal && values.id && !dirty}
                            estadoBotao={dirty ? estadosBotaoCancelar.CANCELAR : estadosBotaoCancelar.VOLTAR}
                            onClick={this.cancelar}
                        />
                        <ButtonSalvar
                            {...informacoesPermissoes}
                            disabled={!dirty}
                            onClick={this.salvar}
                        />
                        <ButtonNovo
                            onClick={onClickNovo}
                            hidden={(!dirty && !values.id) || isModal}
                            estadoBotao={estadoBotaoNovo}
                            {...informacoesPermissoes}
                        />
                        <ButtonExcluir
                            onClick={this.excluir}
                            hidden={Boolean(values.ativa || !values.id)}
                            disabled={Boolean(this.props.values.ativa)}
                            {...informacoesPermissoes}
                        />
                        <Button
                            color='success'
                            icon='fa fa-unlock'
                            label="Desbloquear"
                            style={{ margin: '5px' }}
                            onClick={this.desbloquearCredencial}
                            hidden={Boolean(this.props.values.bloqueada === false || !this.props.values.id || !this.state.podeEditar)}
                            {...informacoesPermissoes}
                        />
                        <Button
                            color='danger'
                            icon='fa fa-lock'
                            label="Bloquear"
                            style={{ margin: '5px' }}
                            onClick={this.bloquearCredencial}
                            hidden={Boolean(this.props.values.bloqueada === true || !this.props.values.id || !this.state.podeEditar)}
                            {...informacoesPermissoes}
                        />
                    </FormActions>
                    <FormContent>
                        <Grid>
                            <Field sm="12" md="6" lg='6' xl='6'
                                component={InputField}
                                label='Nome '
                                obrigatorio
                                name="nome"
                                helpMessage={helpFieldText.nome}
                                size={60}
                                {...informacoesPermissoes}
                            />
                            <Field sm="12" md="6" lg='6' xl='6'
                                component={InputField}
                                label='E-mail '
                                obrigatorio
                                name="email"
                                helpMessage={helpFieldText.email}
                                disabled={Boolean(values.id)}
                                {...informacoesPermissoes}
                            />
                            <Field sm="12" md="12" lg='12' xl='12'
                                component={MultipleSelectPapeis}
                                url={`${services.GESTOR}/v1/usuarios/relacoes/papeis`}
                                name="papeis"
                                obrigatorio
                                helpMessage={helpFieldText.papeis}
                                onChange={(e) => this.props.setFieldValue('papeis', e)}
                                value={this.props.values.papeis}
                                {...informacoesPermissoes}
                            />
                            <Field sm="12" md="12" lg='12' xl='12'
                                url={`${services.GESTOR}/v1/usuarios/relacoes/setores`}
                                component={MultipleSelectSetores}
                                label='Setores'
                                obrigatorio
                                helpMessage={helpFieldText.setores}
                                name="setores"
                                onChange={(e) => this.props.setFieldValue('setores', e)}
                                value={this.props.values.setores}
                                {...informacoesPermissoes}
                                hidden={!podeEditarDocumentos}
                            />
                        </Grid>
                    </FormContent>
                </Form>
            </>
        )
    }
}

UsuariosForm = withFormik({

    enableReinitialize: true,
    validateOnChange: false,

    mapPropsToValues() {
        return initialValues
    },

    validationSchema: Yup.object().shape({
        nome: Yup.string().required(mensagensDeValidacao.OBRIGATORIO).nullable(),
        email: Yup.string().required(mensagensDeValidacao.OBRIGATORIO).nullable().email('Por favor, informe um e-mail válido.'),
        papeis: Yup.array().required(mensagensDeValidacao.OBRIGATORIO).nullable(),
        setores: Yup.array().required(mensagensDeValidacao.OBRIGATORIO).nullable(),
    })

})(UsuariosForm);


export default withRouter(UsuariosForm)
