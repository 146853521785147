import { DocumentosTipos } from "../../util/constantes"

export function converterNfceParaFormulario(data) {
    return {
        id: data.id,
        plugnotasId: data.plugnotasId,
        numeroVenda: data.numeroVenda,
        serie: data.serie,
        nNfce: data.nnf,
        statusNfce: data.status,
        erros: data.erros,
        produtos: converterProdutoParaFormulario(data.det),
        documentoTipo: converterDocumentoTipoParaFormulario(data.dest),
        dest: converterDestinatarioParaFormulario(data.dest),
        pag: converterPagParaFormulario(data.pag),
        condicao: null,
        descontoPendenteRateio: 0,
        acessoriasPendenteRateio: 0,
        total: data.total,
        dhCancelamento: data.dhCancelamento,
        xJustCancelamento: data.xjustCancelamento,
    }
}

function converterProdutoParaFormulario(produtos) {
    let produtoConvertido = []

    produtos.forEach(produto => {
        produtoConvertido.push(montarObjetoDoProduto(produto))
    })
    return produtoConvertido
}

function montarObjetoDoProduto(produto) {

    const vUnCom = produto.prod.vUnCom
    const vDesc = produto.prod.vDesc
    const qCom = produto.prod.qCom

    return {
        id: produto.id,
        idProd: produto.prod.idProd,
        produto: produto.prod.xProd,
        descontoTotalProduto: produto.prod.vDesc,
        acrescimoTotalProduto: produto.prod.vOutro,
        totalProduto: calcularValorLiquidoProduto(vUnCom, vDesc, qCom),
        codigoBarras: produto.prod.codigoBarras,
        quantidade: produto.prod.qCom,
        valorUnitario: produto.prod.vUnCom,
        unidadeMedida: { label: `${produto.prod.uCom}-${produto.prod.xCom}`, value: produto.prod.idCom },
        ncm: { label: `${produto.prod.NCM} - ${produto.prod.xNCM}`, value: produto.prod.idNCM },
        cest: { label: `${produto.prod.CEST} - ${produto.prod.xCEST}`, value: produto.prod.idCEST },
        cfop: { label: `${produto.prod.CFOP} - ${produto.prod.xCFOP}`, value: produto.prod.idCFOP },
        csosn: produto.imposto.icms.csosn,
        origem: produto.imposto.icms.orig,
    }
}

function calcularValorLiquidoProduto(vUnCom, vDesc, qCom) {
    const valorLiquido = (vUnCom * qCom) - vDesc
    return valorLiquido
}

function converterDestinatarioParaFormulario(dest) {
    if (!dest) {
        return null
    }

    return {
        id: dest.id,
        cliente: dest.cliente,
        tipo: dest.tipo,
        CNPJ: dest.CNPJ ?? null,
        CPF: dest.CPF ?? null,
        xNome: dest.xNome
    }
}

function converterPagParaFormulario(pag) {

    let pagConvertido = {
        ...pag,
        categoria: converterCategoria(pag)
    }

    return pagConvertido

}

function converterCategoria(pag) {
    const categoria = pag.categoria

    if (categoria) {
        return {
            label: pag.categoria?.nome,
            registro: {
                ...pag.categoria
            },
            value: pag.categoria?.id
        }
    }
    return null
}

function converterDocumentoTipoParaFormulario(dest) {

    if (!dest) {
        return DocumentosTipos.CPF
    }

    if (dest && dest.CPF) {
        return DocumentosTipos.CPF
    } else if (dest && dest.CNPJ) {
        return DocumentosTipos.CNPJ
    }
}

