class Node {
    constructor(element) {
        this.element = element;
        this.next = null
    }
}

class LinkedList {

    constructor(colors) {
        this.head = null;
        this.size = 0;
        this.addAll(colors)
    }

    add(element) {
        var node = new Node(element);

        var current;

        if (this.head === null)
            this.head = node;
        else {
            current = this.head;

            while (current.next) {
                current = current.next;
            }

            current.next = node;
        }
        this.size++;
    }

    addAll(arrayOfElements) {
        if (arrayOfElements && arrayOfElements.length > 0) {
            for (let i = 0; i < arrayOfElements.length; i++) {
                const element = arrayOfElements[i];
                this.add(element);
            }
            return this.getList();
        }
        return;
    }

    getByIndex(index) {
        if (this.size === 0) {
            return null;
        }
        var node = this.head;
        var count = 0;

        while (count < index) {
            node = node.next;
            count++;
        }
        return node;
    }

    getList() {
        var current = this.head;
        var elements = [];

        while (current != null) {
            elements.push(current.element);
            current = current.next;
        }
        return elements;
    }

    indexOf(element) {
        var count = 0;
        var current = this.head;

        while (current != null) {
            if (current.element === element)
                return count;
            count++;
            current = current.next;
        }

        return -1;
    }

    getElements(firstElement, numberOfNextElements) {
        var count = this.indexOf(firstElement);
        var elements = [];

        if (count >= 0 && this.size >= numberOfNextElements) {
            var node = this.getByIndex(count);

            elements.push(node.element);

            for (let i = 0; i < numberOfNextElements; i++) {
                if (node.next !== null) {
                    node = node.next;
                    elements.push(node.element);
                } else {
                    node = this.head;
                    elements.push(node.element);
                }
            }

            return elements;
        }
        return -1;
    }
}

export default LinkedList;