import { services } from "../../../../common/constantes/api";
import { del, get, post, put, exibirToast } from "../../../../common/requisicoes";

export async function asyncGetAtivos(url, onSuccess, onError) {
    return await get(url, null, onSuccess, onError, true)
}

export async function asyncDeleteAtivo(idAtivo, onSuccess, onError) {
    await del(`${services.GESTOR}/v1/investidor/ativos/${idAtivo}`, null, exibirToast(onSuccess, "Ativo removido com sucesso"), onError);
}

export async function asyncGetAtivoPorId(idAtivo, onSuccess, onError, exibirLoading = true) {
    return await get(`${services.GESTOR}/v1/investidor/ativos/${idAtivo}`, null, onSuccess, onError, exibirLoading);
}

export async function buscarAtivoPeloMesmoCodigo(codigo, onSuccess) {
    await get(`${services.GESTOR}/v1/investidor/ativos?query=codigo=='${codigo}'`, null, data => onSuccess(data));
}

export async function asyncCreateAtivo(data, onSuccess, onError) {
    await post(`${services.GESTOR}/v1/investidor/ativos`, data, null, exibirToast(onSuccess, "Ativo criado com sucesso"), onError);
}

export async function asyncUpdateAtivo(ativo, onSuccess, onError) {
    await put(`${services.GESTOR}/v1/investidor/ativos/${ativo.id}`, ativo, null, exibirToast(onSuccess, "Ativo atualizado com sucesso"), onError);
}
