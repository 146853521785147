import React, { Component } from 'react';
import autoBind from 'react-autobind';
import Modal from '../../../../../../../components/Modal';
import { Field } from 'formik';
import ButtonCancelar, { estadosBotaoCancelar } from '../../../../../../../components/Button/ButtonCancelar';
import Grid from '../../../../../../../components/Grid';
import Form from '../../../../../../../components/Form';
import FormActions from '../../../../../../../components/FormActions';
import FormContent from '../../../../../../../components/FormContent';
import ButtonSalvar, { estadosBotaoSalvar } from '../../../../../../../components/Button/ButtonSalvar';
import { helpNFeProd } from './help';
import SingleSelectNcm from '../../../../../../../components/Select/SingleSelectNcm';
import SingleSelectCfop from '../../../../../../../components/Select/SingleSelectCfop';
import SingleSelectCest from '../../../../../../../components/Select/SingleSelectCest';
import { copiarObjeto } from '../../../../../../../common/array';
import { Fieldset } from 'primereact/fieldset';
import Dropdown from '../../../../../../../components/Select/Dropdown';
import InputDouble from '../../../../../../../components/Input/InputDouble';
import InputMoney from '../../../../../../../components/Input/InputMoney';
import PartilhaICMS from './components/PartilhaICMS';
import CalculoICMSST from './components/CalculoICMSST';
import If from '../../../../../../../components/If';
import { optionsOrigemIcms, optionsCsosn, csosns } from '../../../../../utils/constantes';
import CalculoICMS from './components/CalculoICMS';

class ModalAliquotasProduto extends Component {

    constructor(props) {
        super(props);
        autoBind(this);

        this.state = {
            values: {
                prod: {},
                imposto: {
                    ICMSUFDest: {},
                    icms: {},
                }
            },
        }
    }

    componentDidMount() {
        this.setState({ values: copiarObjeto(this.props.values) })
    }

    cancelar() {
        this.props.onHide();
    }

    onChangeProd(field, value) {
        let values = { ...this.state.values }
        values.prod[field] = value
        this.setState({ values })
    }

    onChangeImpostoIcms(field, value) {
        let values = { ...this.state.values }

        if (field === "CSOSN") {
            this.zerarAliquotasValoresAnteriores(values);
        }

        values.imposto.icms[field] = value

        this.setState({ values })
    }

    onChangeImpostoICMSUFDest(field, value) {
        let values = { ...this.state.values }
        values.imposto.ICMSUFDest[field] = value
        this.setState({ values })
    }

    zerarAliquotasValoresAnteriores(values) {
        values.imposto.ICMSUFDest["pFCPUFDest"] = 0;
        values.imposto.ICMSUFDest["pICMSInter"] = 0;
        values.imposto.ICMSUFDest["pICMSUFDest"] = 0;
        values.imposto.icms["pICMS"] = 0;
        values.imposto.icms["pCredSN"] = 0;
        values.imposto.icms["pMVAST"] = 0;
        values.imposto.icms["pMVASTReducao"] = 0;
        values.imposto.icms["pRedBC"] = 0;
        values.imposto.icms["pRedBCST"] = 0;
        values.imposto.icms["pICMSST"] = 0;
        values.imposto.icms["pFCPST"] = 0;
        values.imposto.icms["vBCSTRet"] = 0;
        values.imposto.icms["vICMSSTRet"] = 0;
        values.imposto.icms["vICMSSubstituto"] = 0;
        values.imposto.icms["pST"] = 0;
        values.imposto.icms["vBCFCPSTRet"] = 0;
        values.imposto.icms["pFCPSTRet"] = 0;
        values.imposto.icms["vFCPSTRet"] = 0;
    }

    confirmar() {
        const { informacoesPermissoes, onChange, onHide } = this.props;

        if (informacoesPermissoes.podeEditar) {
            onChange(this.state.values)
            onHide({ ultimaAtualizacaoModalAliquota: new Date().getTime() })
        } else {
            onHide()
        }
    }

    buscarOptionsCsosns() {
        const { dest } = this.props;

        if (dest.tipo === 'FISICA') {
            let result = []
            for (const csosn of optionsCsosn) {
                if ([csosns.CSOSN_102, csosns.CSOSN_103, csosns.CSOSN_202, csosns.CSOSN_300, csosns.CSOSN_400, csosns.CSOSN_500].includes(csosn.value)) {
                    result.push(csosn)
                }
            }
            return result
        } else if (dest.tipo === 'ESTRANGEIRO') {
            let result = []
            for (const csosn of optionsCsosn) {
                if ([csosns.CSOSN_300, csosns.CSOSN_400].includes(csosn.value)) {
                    result.push(csosn)
                }
            }
            return result
        } else {
            return optionsCsosn
        }
    }

    render() {
        const { visible, onHide, informacoesPermissoes, dest, podeCalcularDIFAL, podeCalcularICMSST } = this.props
        const { values } = this.state;

        const valueCsosn = values.imposto.icms.CSOSN;

        return (
            <Modal
                header="Alíquotas do produto"
                visible={visible}
                onHide={onHide}
            >
                <Form>
                    <FormActions>
                        <ButtonCancelar
                            estadoBotao={estadosBotaoCancelar.VOLTAR}
                            onClick={this.cancelar}
                        />
                        <ButtonSalvar
                            onClick={this.confirmar}
                            hidden={!informacoesPermissoes.podeEditar}
                            estadoBotao={estadosBotaoSalvar.CONFIRMAR}
                        />
                    </FormActions>
                    <FormContent>
                        <Grid>
                            <Field sm="6" md="4" lg='4' xl='4'
                                label='NCM'
                                name="NCM"
                                component={SingleSelectNcm}
                                value={values.prod.NCM}
                                onChange={e => this.onChangeProd('NCM', e)}
                                helpMessage={helpNFeProd.NCM}
                                {...informacoesPermissoes}
                            />
                            <Field sm="6" md="4" lg='4' xl='4'
                                label='CFOP'
                                name="CFOP"
                                component={SingleSelectCfop}
                                value={values.prod.CFOP}
                                onChange={e => this.onChangeProd('CFOP', e)}
                                helpMessage={helpNFeProd.CFOP}
                                {...informacoesPermissoes}
                            />
                            <Field sm="6" md="4" lg='4' xl='4'
                                label='CEST'
                                name="CEST"
                                component={SingleSelectCest}
                                value={values.prod.CEST}
                                onChange={e => this.onChangeProd('CEST', e)}
                                helpMessage={helpNFeProd.CEST}
                                {...informacoesPermissoes}
                            />
                        </Grid>

                        <Grid>
                            <Field sm="6" md="6" lg="6" xl="6"
                                component={Dropdown}
                                label='Origem do produto'
                                onChange={(e) => this.onChangeImpostoIcms('orig', e.value)}
                                options={optionsOrigemIcms}
                                value={values.imposto.icms.orig}
                                showClear={false}
                                helpMessage={helpNFeProd.orig}
                                {...informacoesPermissoes}
                            />
                            <Field sm="6" md="6" lg="6" xl="6"
                                component={Dropdown}
                                label='CSOSN'
                                options={this.buscarOptionsCsosns()}
                                name="CSOSN"
                                value={values.imposto.icms.CSOSN}
                                onChange={e => this.onChangeImpostoIcms('CSOSN', e.value)}
                                helpMessage={helpNFeProd.csosn}
                                {...informacoesPermissoes}
                            />
                        </Grid>

                        <If test={[csosns.CSOSN_102, csosns.CSOSN_103, csosns.CSOSN_202, csosns.CSOSN_300, csosns.CSOSN_400, csosns.CSOSN_500].includes(valueCsosn)}>
                            <If test={podeCalcularDIFAL}>
                                <Fieldset legend='DIFAL (Partilha de ICMS)' className='fieldset-light'>
                                    <Grid>
                                        <PartilhaICMS
                                            informacoesPermissoes={informacoesPermissoes}
                                            values={values}
                                            dest={dest}
                                            onChangeImpostoICMSUFDest={this.onChangeImpostoICMSUFDest}
                                        />
                                    </Grid>
                                </Fieldset>
                            </If>


                        </If>

                        <If test={[csosns.CSOSN_201, csosns.CSOSN_202, csosns.CSOSN_203, csosns.CSOSN_900].includes(valueCsosn)}>
                            <If test={podeCalcularICMSST}>
                                <Fieldset legend='Cálculo de ICMS-ST (Substituição Tributária)' className='fieldset-light'>
                                    <Grid>
                                        <CalculoICMSST
                                            informacoesPermissoes={informacoesPermissoes}
                                            values={values}
                                            dest={dest}
                                            onChangeImpostoIcms={this.onChangeImpostoIcms}
                                        />
                                    </Grid>
                                </Fieldset>
                            </If>
                        </If>

                        <If test={valueCsosn === csosns.CSOSN_900}>
                            <Fieldset legend='Cálculo de ICMS' className='fieldset-light'>
                                <Grid>
                                    <CalculoICMS
                                        informacoesPermissoes={informacoesPermissoes}
                                        values={values}
                                        dest={dest}
                                        valueCsosn={valueCsosn}
                                        onChangeImpostoIcms={this.onChangeImpostoIcms}
                                    />
                                </Grid>
                            </Fieldset>
                        </If>

                        <If test={valueCsosn === csosns.CSOSN_500}>
                            <Fieldset legend='ICMS ST Retido' className='fieldset-light'>
                                <Grid>
                                    <Field sm="12" md="4" lg='4' xl='4'
                                        label='Valor da BC do ICMS ST retido'
                                        name="vBCSTRet"
                                        value={values.imposto.icms.vBCSTRet}
                                        onChange={e => this.onChangeImpostoIcms('vBCSTRet', e.target.value)}
                                        size={15}
                                        component={InputMoney}
                                        decimalScale={2}
                                        helpMessage={helpNFeProd.vBCSTRet}
                                        {...informacoesPermissoes}
                                    />
                                    <Field sm="12" md="4" lg='4' xl='4'
                                        label='Alíq. sup. pelo Consumidor Final'
                                        name="pST"
                                        value={values.imposto.icms.pST}
                                        onChange={e => this.onChangeImpostoIcms('pST', e.target.value)}
                                        size={8}
                                        component={InputDouble}
                                        decimalScale={2}
                                        suffix=' %'
                                        maxValue={100}
                                        placeholder='0,00 %'
                                        helpMessage={helpNFeProd.pST}
                                        {...informacoesPermissoes}
                                    />
                                    <Field sm="12" md="4" lg='4' xl='4'
                                        label='Valor do ICMS próprio do substituto'
                                        name="vICMSSubstituto"
                                        value={values.imposto.icms.vICMSSubstituto}
                                        onChange={e => this.onChangeImpostoIcms('vICMSSubstituto', e.target.value)}
                                        size={15}
                                        component={InputMoney}
                                        decimalScale={2}
                                        helpMessage={helpNFeProd.vICMSSubstituto}
                                        {...informacoesPermissoes}
                                    />
                                    <Field sm="12" md="4" lg='4' xl='4'
                                        label='Valor do ICMS ST retido'
                                        name="vICMSSTRet"
                                        value={values.imposto.icms.vICMSSTRet}
                                        onChange={e => this.onChangeImpostoIcms('vICMSSTRet', e.target.value)}
                                        size={15}
                                        component={InputMoney}
                                        decimalScale={2}
                                        helpMessage={helpNFeProd.vICMSSTRet}
                                        {...informacoesPermissoes}
                                    />
                                </Grid>
                            </Fieldset>
                        </If>
                    </FormContent>
                </Form>
            </Modal>
        )
    }
}


export default ModalAliquotasProduto;
