import React, { Component } from 'react';
import { withFormik, Field } from 'formik';
import * as Yup from 'yup';
import ButtonNovo, { estadosBotaoNovo } from '../../../../components/Button/ButtonNovo';
import ButtonCancelar, { estadosBotaoCancelar } from '../../../../components/Button/ButtonCancelar';
import ButtonSalvar, { estadosBotaoSalvar } from '../../../../components/Button/ButtonSalvar';
import ButtonExcluir from '../../../../components/Button/ButtonExcluir';
import { mensagensDeValidacao } from '../../../../common/constantes/mensagens';
import InputField from '../../../../components/Input//InputField';
import Grid from '../../../../components/Grid';
import InputMask from '../../../../components/Input//InputMask';
import { buscarMascaraTelefone, manterApenasNumeros } from '../../../../common/mascara/';
import CredenciaisDeUsuario from './CredenciaisDeUsuario';
import Dropdown from '../../../../components/Select/Dropdown';
import Col from '../../../../components/Col';
import { recursos, permissoes, estadosCadastro } from '../../../../common/constantes/autorizacao';
import Fieldset from '../../../../components/FieldSet';
import Prompt from '../../../../components/Route/Prompt';
import { validarUUID } from '../../../../common/manipulacaoDeString';
import { atualizarUrl, metodosAtualizarUrl, validarFormulario, voltarParaAPesquisa } from '../../../util';
import autoBind from 'react-autobind';
import { helpOrganizacoesForm } from './help';
import { converterParaFormulario, converterParaApi } from './util/organizacaoConverter';
import { asyncUpdateOrganizacao, asyncCreateOrganizacao, asyncDeleteOrganizacao, asyncGetOrganizacao } from './requests';
import { usuarioPossuiPermissao, buscarDadosLoginLocalStorage } from '../../../../common/autenticacao';
import SingleSelectMunicipio from '../../../../components/Select/SingleSelectMunicipio';
import SingleSelectPais from '../../../../components/Select/SingleSelectPais';
import { isValidCpf, isValidCnpj } from '@brazilian-utils/validators';
import { confirm } from '../../../../components/Toast';
import InputDate from '../../../../components/Input/InputDate';
import FormActions from '../../../../components/FormActions';
import FormContent from '../../../../components/FormContent';
import Form from '../../../../components/Form';
import { formatISO } from 'date-fns';
import { services } from '../../../../common/constantes/api';
import SingleSelectAgenteAutorizado from '../../../../components/Select/SingleSelectAgenteAutorizado';
import Message from '../../../../components/Message';
import Button from '../../../../components/Button';
import SingleSelectPlano from '../../../../components/Select/SingleSelectPlano';
import { modulosDropdown, meioPagamento, tipoPessoaDropdown } from '../utils/constantes';
import InputCep from '../../../../components/Input/InputCep';
import stringMask from 'string-mask';

const initialValues = {
	id: null,
	nome: null,
	tipo: 'JURIDICA',
	cpf: null,
	bloqueada: false,
	cnpj: null,
	telefone: null,
	email: null,
	logradouro: null,
	numero: null,
	bairro: null,
	complemento: null,
	cep: null,
	municipio: null,
	municipioExterior: null,
	pais: null,
	modulos: [],
	plano: null,
	meioPagamento: null,
	agenteAutorizado: null,
	criadoEm: formatISO(new Date())
}

const idOrganizacaoMicrosys = "4171272a-c8d0-11e8-a8d5-f2801f1b9fd1"
const idPaisBrasil = "34e63b2e-c596-f34f-824d-bfd27eb62fa8"
const CADASTROURL = '/organizacoes/cadastro';
const PESQUISAURL = '/organizacoes';


class OrganizacoesForm extends Component {

	constructor(props) {
		super(props);

		autoBind(this);

		this.state = {
			idOrganizacaoLogada: null,
			podeInserirOrganizacao: usuarioPossuiPermissao(recursos.ORGANIZACOES, permissoes.INSERIR),
			podeEditarOrganizacao: usuarioPossuiPermissao(recursos.ORGANIZACOES, permissoes.EDITAR),
			podeExcluirOrganizacao: usuarioPossuiPermissao(recursos.ORGANIZACOES, permissoes.EXCLUIR),
			credenciais: [],
			registroSelecionado: null
		};
	}

	componentDidMount() {

		const id = this.props.match.params.id;

		if (validarUUID(id)) {
			this.asyncSelectRegistro(id);
		}

		this.setState({ idOrganizacaoLogada: buscarDadosLoginLocalStorage().organizacao.id });
	}

	buscarEstadoCadastro() {
		return this.props.values.id ? estadosCadastro.EDICAO : estadosCadastro.INCLUSAO;
	}

	novo() {
		atualizarUrl(this.props.history, CADASTROURL, null, metodosAtualizarUrl.POP);
		this.props.resetForm({ values: initialValues });
		this.setState({ credenciais: [], registroSelecionado: null });
	}

	excluir() {
		confirm('Confirmação', 'Tem certeza que deseja excluir a organização? Este processo não pode ser revertido.', async () => {
			await this.asyncDeleteRegistro();
		})
	}

	salvar(e, novoOnSuccess) {
		this.props.handleSubmit()

		if (this.props.isValid) {
			if (this.props.values.id) {
				this.asyncUpdateRegistro({ ...this.props.values }, novoOnSuccess);
			} else {
				this.asyncCreateRegistro({ ...this.props.values }, novoOnSuccess);
			}
		}
	}

	cancelar() {
		if (this.props.dirty) {
			this.props.resetForm({ values: this.props.initialValues });
		} else {
			voltarParaAPesquisa(this.props.history, PESQUISAURL);
		}
	}

	async asyncDeleteRegistro() {
		await asyncDeleteOrganizacao(this.props.values.id, () => {
			this.props.resetForm()
			voltarParaAPesquisa(this.props.history, PESQUISAURL)
		})
	}

	async asyncUpdateRegistro(values, novoOnSuccess) {
		await asyncUpdateOrganizacao(converterParaApi(values), () => {
			if (novoOnSuccess) {
				novoOnSuccess();
			} else {
				this.props.resetForm({ values: values });
			}
		})
	}

	async asyncCreateRegistro(values, novoOnSuccess) {
		if (values) {
			await asyncCreateOrganizacao(converterParaApi(values), ({ data: organizacao }) => {
				if (novoOnSuccess) {
					novoOnSuccess();
				} else {
					this.props.resetForm({ values: { ...values, id: organizacao.id } });
					this.asyncSelectRegistro(organizacao.id)
				}
			})
		}
	}

	async asyncSelectRegistro(idOrganizacao) {
		await asyncGetOrganizacao(idOrganizacao, ({ data: organizacao }) => {
			this.props.resetForm({ values: converterParaFormulario(organizacao) })
			this.setState({ credenciais: organizacao.credenciais })
			atualizarUrl(this.props.history, CADASTROURL, organizacao.id, metodosAtualizarUrl.POP);
		})
	}

	habilitarTipoOrganizacao(e) {
		this.props.setFieldValue("tipo", e);

		switch (e) {
			case 'FISICO':
				this.setState({ ...this.state, tabSelecionada: 0 });
				break;
			case 'JURIDICO':
				this.setState({ ...this.state, tabSelecionada: 1 });
				break;
			default:
		}
	}

	selecionarRegistro(e) {
		if (e.value) {
			this.setState({ registroSelecionado: e.value })
		}
	}

	getMessageOrganizacao() {
		return <span>Organização <b>bloqueada</b>. Nenhum usuário desta organização poderá inserir dados no sistema.</span>
	}

	bloquearOrganizacao() {
		confirm("Confirmação", "Tem certeza que deseja bloquear a organização? Os usuários desta organização não poderão inserir dados no sistema.", async () => {
			this.props.handleSubmit()

			if (await validarFormulario(this.props) && this.props.values.id) {
				this.asyncUpdateRegistro({ ...this.props.values, bloqueada: true });
			}
		})
	}

	desbloquearOrganizacao() {
		confirm("Confirmação", "Tem certeza que deseja desbloquear a organização?", async () => {
			this.props.handleSubmit()

			if (await validarFormulario(this.props) && this.props.values.id) {
				this.asyncUpdateRegistro({ ...this.props.values, bloqueada: false });
			}
		});
	}

	getTitleBtnExcluir() {
		if (this.state.idOrganizacaoLogada === this.props.values.id) {
			return "Não é possível excluir a própria organização.";
		} else {
			return "Excluir a organização.";
		}
	}

	onChangeCep(e) {
		if (e.pesquisou) {
			const { setFieldValue } = this.props;
			e.bairro && setFieldValue('bairro', e.bairro);
			e.complemento && setFieldValue('complemento', e.complemento);
			e.logradouro && setFieldValue('logradouro', e.logradouro);
			e.municipio && setFieldValue('municipio', { label: e.municipio.nome + ' - ' + e.municipio.estado.sigla, value: e.municipio.id });
			e.pais && setFieldValue('pais', { label: e.pais.nome, value: e.pais.id });
		} else {
			this.props.setFieldValue('cep', e.value);
		}
	}

	getModulos() {
		if (this.props.values.id === idOrganizacaoMicrosys) {
			return modulosDropdown
		}
		return modulosDropdown.filter(modulo => modulo.value !== 'ADMINISTRACAO')
	}

	render() {
		const { values, dirty, isModal } = this.props;
		const { podeInserirOrganizacao, podeEditarOrganizacao, podeExcluirOrganizacao } = this.state;
		const mesmaOrganizacao = this.state.idOrganizacaoLogada === this.props.values.id;

		const informacoesPermissoes = {
			podeInserir: podeInserirOrganizacao,
			podeEditar: podeEditarOrganizacao,
			podeExcluir: podeExcluirOrganizacao
		}

		const estadoBotaoNovo = dirty ? estadosBotaoNovo.SALVAR_E_NOVO : estadosBotaoNovo.NOVO;

		const onClickNovo = dirty ? (e) => this.salvar(e, this.novo) : this.novo;


		return (
			<>
				<Prompt dirty={dirty} />
				<Form header="Cadastro de organização" isModal={isModal} className="card-default screen-max-width">
					<FormActions className="screen-max-width">
						<ButtonCancelar
							estadoBotao={dirty ? estadosBotaoCancelar.CANCELAR : estadosBotaoCancelar.VOLTAR}
							onClick={this.cancelar}
							{...informacoesPermissoes}
						/>
						<ButtonSalvar
							estadoBotao={estadosBotaoSalvar.SALVAR}
							disabled={!dirty}
							onClick={this.salvar}
							{...informacoesPermissoes}
						/>
						<ButtonNovo
							onClick={onClickNovo}
							hidden={(!dirty && !values.id) || isModal}
							estadoBotao={estadoBotaoNovo}
							{...informacoesPermissoes}
						/>
						<ButtonExcluir
							disabled={mesmaOrganizacao}
							hidden={!values.id}
							title={this.getTitleBtnExcluir()}
							onClick={() => this.excluir()}
							{...informacoesPermissoes}
						/>
						<Button
							color="danger"
							label="Bloquear"
							title="Bloquear o usuário selecionado"
							icon="fa fa-lock"
							onClick={() => this.bloquearOrganizacao()}
							hidden={values.bloqueada || mesmaOrganizacao || !values.id}
							disabled={!informacoesPermissoes.podeEditar}
						/>
						<Button
							color="success"
							label="Desbloquear"
							title="Desbloquear o usuário selecionado"
							icon="fa fa-unlock"
							disabled={!informacoesPermissoes.podeEditar}
							onClick={() => this.desbloquearOrganizacao()}
							hidden={!values.bloqueada || mesmaOrganizacao || !values.id}
						/>
					</FormActions>
					<FormContent>
						<Grid>
							<Message
								severity="warn"
								hidden={values.bloqueada === false}
								text={this.getMessageOrganizacao()}
							/>
							<Field sm="12" md="4" lg='4' xl='4'
								label='Nome da organização *'
								name="nome"
								size={60}
								helpMessage={helpOrganizacoesForm.nome}
								component={InputField}
								{...informacoesPermissoes}
							/>
							<Field sm="12" md="4" lg='4' xl='4'
								component={Dropdown}
								label='Tipo *'
								name="tipo"
								showClear={false}
								onChange={(e) => this.habilitarTipoOrganizacao(e.value)}
								options={tipoPessoaDropdown}
								{...informacoesPermissoes}
							/>
							<Field sm="12" md="4" lg='4' xl='4'
								hidden={values.tipo !== 'FISICA'}
								component={InputMask}
								mask="000.000.000-00"
								placeholder="   .   .   -  "
								label='CPF *'
								name="cpf"
								onChange={e => this.props.setFieldValue('cpf', e.target.value)}
								{...informacoesPermissoes}
							/>
							<Field sm="12" md="4" lg='4' xl='4'
								hidden={values.tipo !== 'JURIDICA'}
								component={InputMask}
								mask="00.000.000/0000-00"
								placeholder="  .   .   /    -  "
								label='CNPJ *'
								name="cnpj"
								onChange={e => this.props.setFieldValue('cnpj', e.target.value)}
								{...informacoesPermissoes}
							/>
							<Field sm="12" md="4" lg='4' xl='4'
								label='E-mail'
								obrigatorio
								estadoCadastro={this.buscarEstadoCadastro()}
								name="email"
								size={255}
								component={InputField}
								{...informacoesPermissoes}
							/>
							<Field sm="12" md="4" lg='4' xl='4'
								component={InputMask}
								mask={buscarMascaraTelefone(values.telefone)}
								placeholder={"(  )      -    "}
								label='Telefone'
								obrigatorio
								name="telefone"
								size={16}
								onChange={e => this.props.setFieldValue('telefone', e.target.value)}
								{...informacoesPermissoes}
							/>
							<Field sm="12" md="4" lg='4' xl='4'
								component={InputDate}
								label="Data de criação"
								name="criadoEm"
								value={values.criadoEm}
								disabled
							/>
							<Field sm="12" md="6" lg='6' xl='6'
								component={SingleSelectAgenteAutorizado}
								url={`${services.GESTOR}/v1/organizacoes/relacoes/agentes_autorizados`}
								name="agenteAutorizado"
								label="Agente autorizado"
								value={values.agenteAutorizado}
								helpMessage={helpOrganizacoesForm.agenteAutorizado}
								onChange={e => this.props.setFieldValue('agenteAutorizado', e)}
								{...informacoesPermissoes}
							/>
							<Field sm="12" md="12" lg='12' xl='12'
								component={Dropdown}
								showClear={false}
								isMulti
								closeMenuOnSelect={false}
								backspaceRemovesValue={false}
								label='Módulos que a organização possui acesso'
								options={this.getModulos()}
								name="modulos"
								obrigatorio
								helpMessage={helpOrganizacoesForm.modulos}
								value={values.modulos}
								onChange={e => this.props.setFieldValue('modulos', e)}
								{...informacoesPermissoes}
							/>
							<Col sm="12" md="12" lg='12' xl='12'>
								<Fieldset legend="Plano e pagamento" className='fieldset-light'>
									<Grid>
										<Field sm="12" md="6" lg="6" xl="6"
											component={SingleSelectPlano}
											label='Plano'
											name="plano"
											obrigatorio
											value={values.plano}
											url={`${services.GESTOR}/v1/organizacoes/relacoes/planos`}
											onChange={e => this.props.setFieldValue('plano', e)}
											{...informacoesPermissoes}
										/>
										<Field sm="12" md="6" lg='6' xl='6'
											component={Dropdown}
											label='Meio de pagamento'
											name="meioPagamento"
											obrigatorio
											value={values.meioPagamento}
											showClear={false}
											onChange={e => this.props.setFieldValue('meioPagamento', e.value)}
											options={meioPagamento}
											{...informacoesPermissoes}
										/>
									</Grid>
								</Fieldset>
							</Col>

							<Col sm="12" md="12" lg='12' xl='12'>
								<Fieldset legend="Endereço" className='fieldset-light'>
									<Grid>
										<Field sm="12" md="4" lg="4" xl="4"
											component={InputCep}
											label="CEP"
											name="cep"
											type="tel"
											value={values.cep}
											onChange={e => this.onChangeCep(e)}
											{...informacoesPermissoes}
										/>
										<Field sm="12" md="4" lg="4" xl="4"
											label='Logradouro'
											name="logradouro"
											size={255}
											component={InputField}
											{...informacoesPermissoes}
										/>
										<Field sm="12" md="4" lg="4" xl="4"
											label='Número'
											size={10}
											name="numero"
											component={InputField}
											{...informacoesPermissoes}
										/>
										<Field sm="12" md="4" lg="4" xl="4"
											label='Bairro'
											name="bairro"
											size={60}
											component={InputField}
											{...informacoesPermissoes}
										/>
										<Field sm="12" md="4" lg="4" xl="4"
											label='Complemento'
											name="complemento"
											size={255}
											component={InputField}
											{...informacoesPermissoes}
										/>
										<Field sm="12" md="4" lg="4" xl="4"
											label='País'
											name="pais"
											component={SingleSelectPais}
											value={values.pais}
											onChange={e => this.props.setFieldValue('pais', e)}
											{...informacoesPermissoes}
										/>
										<Field sm="12" md="4" lg="4" xl="4"
											label='Município'
											name="municipio"
											component={SingleSelectMunicipio}
											value={values.municipio}
											onChange={e => this.props.setFieldValue('municipio', e)}
											hidden={(values.pais ? values.pais.value : null) !== idPaisBrasil}
											{...informacoesPermissoes}
										/>
										<Field sm="12" md="4" lg="4" xl="4"
											component={InputField}
											type="text"
											label="Estado exterior"
											helpMessage={helpOrganizacoesForm.estadoExterior}
											name="estadoExterior"
											value={values.estadoExterior}
											size={128}
											hidden={(values.pais ? values.pais.value : null) === idPaisBrasil}
											{...informacoesPermissoes}
										/>
										<Field sm="12" md="4" lg="4" xl="4"
											component={InputField}
											type="text"
											label="Município exterior"
											helpMessage={helpOrganizacoesForm.municipioExterior}
											name="municipioExterior"
											value={values.municipioExterior}
											size={128}
											hidden={(values.pais ? values.pais.value : null) === idPaisBrasil}
											{...informacoesPermissoes}
										/>
									</Grid>
								</Fieldset>
							</Col>
							<Col sm="12" md="12" lg='12' xl='12'>
								<Fieldset legend="Usuários do sistema" className='fieldset-light'>
									<CredenciaisDeUsuario
										idOrganizacao={values.id}
										credenciais={this.state.credenciais}
										onChange={credenciais => this.setState({ credenciais })}
										{...informacoesPermissoes}
									/>
								</Fieldset>
							</Col>
						</Grid>
					</FormContent>
				</Form>
			</>
		)
	}
}

OrganizacoesForm = withFormik({
	enableReinitialize: true,
	validateOnChange: false,

	mapPropsToValues() {
		return initialValues
	},

	validationSchema: Yup.object().shape({
		nome: Yup.string().nullable().required(mensagensDeValidacao.OBRIGATORIO),
		email: Yup.string().nullable().required(mensagensDeValidacao.OBRIGATORIO).email('E-mail inválido'),
		modulos: Yup.array().nullable().required(mensagensDeValidacao.OBRIGATORIO),
		plano: Yup.object().nullable().required(mensagensDeValidacao.OBRIGATORIO),
		meioPagamento: Yup.string().nullable().required(mensagensDeValidacao.OBRIGATORIO),
		telefone: Yup.string().nullable().required(mensagensDeValidacao.OBRIGATORIO),
	}),

	validate(values) {
		let errors = {};

		if (!new stringMask("(00) 00000-0000").validate(manterApenasNumeros(values.telefone)) && !new stringMask("(00) 0000-0000").validate(manterApenasNumeros(values.telefone))) {
			errors.telefone = mensagensDeValidacao.TELEFONE_INVALIDO;
		}

		if (values.tipo === 'FISICA') {
			if (!values.cpf)
				errors.cpf = mensagensDeValidacao.OBRIGATORIO;

			if (values.cpf && !isValidCpf(manterApenasNumeros(values.cpf)))
				errors.cpf = 'Digite um CPF válido.';
		}

		if (values.tipo === 'JURIDICA') {
			if (!values.cnpj)
				errors.cnpj = mensagensDeValidacao.OBRIGATORIO;

			if (values.cnpj && !isValidCnpj(manterApenasNumeros(values.cnpj)))
				errors.cnpj = 'Digite um CNPJ válido.';
		}
		return errors;
	},

})(OrganizacoesForm);


export default OrganizacoesForm
