import { get, del, exibirToast, put, post } from '../../../../../common/requisicoes';
import { services } from '../../../../../common/constantes/api';
import formatISO from 'date-fns/formatISO';
import { buscarDadosLoginLocalStorage } from '../../../../../common/autenticacao';


export async function asyncGetResumoNotas(url, onSuccess, onError) {
    return await get(url, null, onSuccess, onError);
}

export async function asyncGetNFe(idNfe, onSuccess, onError) {
    await get(`${services.GESTOR}/v1/nfes/${idNfe}`, null, onSuccess, onError);
}

export async function asyncUpdateNFe(nfe, onSuccess, onError) {
    await put(`${services.GESTOR}/v1/nfes/${nfe.id}`, nfe, null, exibirToast(onSuccess, "Nota Fiscal atualizada"), onError);
}

export async function asyncDeleteNFe(id, onSuccess, onError) {
    return await del(`${services.GESTOR}/v1/nfes/${id}`, null, exibirToast(onSuccess, "Nota fiscal excluída"), onError);
}

export async function asyncTransmitirNFe(id, onSuccess, onError) {
    return await post(`${services.GESTOR}/v1/nfes/${id}/transmitir`, null, null, onSuccess, onError);
}

export async function asyncCorrigirNFe(id, payload, onSuccess, onError) {
    return await post(`${services.GESTOR}/v1/nfes/${id}/correcao`, payload, null, exibirToast(onSuccess, "Nota fiscal corrigida"), onError);
}

export async function asyncCancelarNfe(id, payload, onSuccess, onError) {
    return await post(`${services.GESTOR}/v1/nfes/${id}/cancelar`, payload, null, exibirToast(onSuccess, "Nota fiscal cancelada"), onError);
}

export async function asyncGetTotalizadoresCard(interval, onSuccess, onError) {
    return await get(`${services.GESTOR}/v1/nfes/totais?dataInicial=${formatISO(interval.dataInicial, { representation: 'date' })}&dataFinal=${formatISO(interval.dataFinal, { representation: 'date' })}`, null, onSuccess, onError);
}

export async function asyncRecalcularNfe(payload, onSuccess, onError) {
    return await post(`${services.GESTOR}/v1/nfes/calcular`, payload, null, onSuccess, onError, false);
}

export async function imprimirDANFE(idNfe, onSuccess, onError) {
    return get(`${services.GESTOR}/v1/nfes/${idNfe}/pdf`, { responseType: "arraybuffer" }, onSuccess, onError)
}

export async function buscarAliquotaSimples(onSuccess, onError) {
    const organizacaoId = buscarDadosLoginLocalStorage().organizacao.id
    return get(`${services.GESTOR}/v1/empresa/${organizacaoId}/aliquotasimples`, null, onSuccess, onError, false, false)
}

export async function imprimirCorrecao(idNfe, onSuccess, onError) {
    return get(`${services.GESTOR}/v1/nfes/${idNfe}/correcao/pdf`, { responseType: "arraybuffer" }, onSuccess, onError)
}

export async function imprimirXMLNfe(idNfe, onSuccess, onError) {
    return get(`${services.GESTOR}/v1/nfes/${idNfe}/xml`, { responseType: "arraybuffer" }, onSuccess, onError)
}

export async function imprimirXMLCorrecao(idNfe, onSuccess, onError) {
    return get(`${services.GESTOR}/v1/nfes/${idNfe}/correcao/xml`, { responseType: "arraybuffer" }, onSuccess, onError)
}
