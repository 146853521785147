import React, { Component } from 'react';
import autoBind from 'react-autobind';
import SingleSelect from '../SingleSelect';
import propTypes from 'prop-types'
import { usuarioPossuiPermissao } from '../../../common/autenticacao';
import { recursos, permissoes } from '../../../common/constantes/autorizacao';
import ModalCategoria from '../../../views/financas/Categorias/components/ModalCategoria';
import If from '../../If';

const colourStyles = {
    option: (styles, { data }) => {
        return {
            ...styles,
            color: data.registro.aparencia,
            fontWeight: 'bold',
            backgroundColor: 'transparent',
            whiteSpace: 'nowrap',
        };
    },
    singleValue: (styles, { data }) => ({
        ...styles, color: data.registro.aparencia,
        fontWeight: 'bold'
    }),
};

export const tiposCategoria = {
    DESPESA: 'DESPESA',
    RECEITA: 'RECEITA'
}

class SingleSelectCategoria extends Component {
    constructor(props) {
        super(props);
        autoBind(this);

        this.state = {
            visible: false,
            podeInserir: usuarioPossuiPermissao(recursos.FINANCAS_CATEGORIAS, permissoes.INSERIR),
            podeVisualizar: usuarioPossuiPermissao(recursos.FINANCAS_CATEGORIAS, permissoes.VISUALIZAR)
        }
    }

    buscarUrlPesquisa(pesquisa, page) {
        return `${this.props.url}?query=nome=contains="*${pesquisa}*";ativa=="true"&page=${page}&size=50&sort=nome,asc`;
    }

    buscarUrlRegistroUnico(id) {
        return `${this.props.url}?query=id==${id}`;
    }

    montarLabel(row) {
        return row.nome
    }

    mostrarModal() {
        this.setState({ visible: !this.state.visible });
    }

    onHide(novoRegistro) {
        this.setarNovoRegistroNoSelect(novoRegistro)
        this.esconderModal()
    }

    setarNovoRegistroNoSelect(novoRegistro) {
        if (novoRegistro) {
            this.props.onChange({ label: novoRegistro.nome, value: novoRegistro.id, registro: novoRegistro })
        }
    }

    esconderModal() {
        this.setState({ visible: false })
    }

    render() {
        const { podeInserir, podeVisualizar } = this.state
        const { tipoCategoria, disabledButton, esconderBotao } = this.props
        const podeInserirEVisualizar = podeInserir && podeVisualizar

        return (
            <React.Fragment>
                <If test={this.state.visible}>
                    <ModalCategoria
                        tipoCategoria={tipoCategoria}
                        visible={this.state.visible}
                        insertJustOne={true}
                        onHide={this.onHide}
                    />
                </If>
                <SingleSelect
                    styles={colourStyles}
                    buscarUrlPesquisa={this.buscarUrlPesquisa}
                    buscarUrlRegistroUnico={this.buscarUrlRegistroUnico}
                    montarLabel={this.montarLabel}
                    onClickModal={this.mostrarModal}
                    esconderBotao={esconderBotao}
                    disabledButton={!podeInserirEVisualizar || disabledButton}
                    titleBotaoNovo={podeInserirEVisualizar && !disabledButton ? "Adicionar nova categoria" : "Você não possui permissão para executar esta ação"}
                    {...this.props}

                />
            </React.Fragment>
        );
    }
}

SingleSelectCategoria.defaultProps = {
    label: 'Categoria',
    disabledButton: false
}

SingleSelectCategoria.propTypes = {
    /** Url do servidor em que as requisições de busca deverão ser efetuadas: Ex:  `${services.GESTOR}/v1/usuarios/relacoes/pessoas`*/
    url: propTypes.string.isRequired
};


export default SingleSelectCategoria;
