import React, { Component } from 'react';
import { FaTimes } from 'react-icons/fa';

class AcoesMemorizados extends Component {
    render() {
        const {
            informacoesPermissoes, exibirLabels, onClickExcluir,
            excluirDisabled, isMobile
        } = this.props;

        return (
            <>
                <button
                    onClick={() => excluirDisabled || !informacoesPermissoes.podeEditar ? null : onClickExcluir()}
                    style={{
                        color: '#e91224',
                        width: '30px',
                        height: '30px',
                        marginTop: exibirLabels && !isMobile ? "22px" : '5px',
                        background: 'none',
                        border: 'none',
                        padding: '0px',
                    }}
                    disabled={excluirDisabled}
                >
                    <FaTimes
                        size={20}
                        title={excluirDisabled ? 'Você não pode executar esta ação' : 'Excluir item'}
                        style={{
                            color: '#e91224',
                            width: '20px',
                            height: '20px',
                            opacity: excluirDisabled ? '0.5' : '1',
                            cursor: excluirDisabled ? 'default' : 'pointer',
                        }}
                    />
                </button>
            </>
        )
    }
}

function acoesMemorizadasSaoIguais(renderAnterior, renderAtual) {
    return renderAnterior.exibirLabels === renderAtual.exibirLabels &&
        renderAnterior.excluirDisabled === renderAtual.excluirDisabled &&
        renderAnterior.isMobile === renderAtual.isMobile
}

export default React.memo(AcoesMemorizados, acoesMemorizadasSaoIguais);
