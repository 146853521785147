import React, { Component } from 'react';
import Chart from "chart.js";
import { Card } from 'primereact/card';
import autoBind from 'react-autobind';
import { asyncGetBalancoDiario } from './requests';
import { ProgressBar } from 'primereact/progressbar';
import { formatarMonetario } from '../../../../../../../common/mascara';
import { format, parse } from 'date-fns';
import NenhumaInformacaoEncontrada from '../../../NenhumaInformacaoEncontrada';
import FalhaAoCarregarAsInformacoes from '../../../FalhaAoCarregarAsInformacoes';
import { Financas } from '../../../../../../util/constantes'

const cardHeader = {
    padding: '14px',
    display: 'flex',
    justifyContent: 'space-between'
}

let graficoBalancoDiario;

export default class GraficoBalancoDiario extends Component {
    graficoDeDespesasAgrupadasPorDia = React.createRef();

    constructor(props) {
        super(props);

        autoBind(this);

        this.state = {
            loading: true,
            error: false,
            data: [],
            valorReceita: [],
            valorDespesa: [],
            valorTotal: []
        }
    }

    componentDidMount() {
        this.carregarInformacoes();
    }

    componentDidUpdate(prevProps) {
        const { dataInicial, dataFinal } = this.props;

        if (prevProps.dataInicial !== dataInicial || prevProps.dataFinal !== dataFinal) {
            this.carregarInformacoes();
        }

        if (prevProps.atualizarGraficoNovamente !== this.props.atualizarGraficoNovamente) {
            this.carregarInformacoes(false);
        }
    }

    carregarInformacoes(exibirLoading = true) {
        const { dataInicial, dataFinal } = this.props;
        this.setState({ loading: exibirLoading, error: false }, () => {
            asyncGetBalancoDiario(dataInicial, dataFinal, ({ data: balancoDiario }) => {
                let data = [];
                let valorReceita = [];
                let valorDespesa = [];
                let valorTotal = [];

                balancoDiario.forEach(elemento => {
                    data.push(elemento.data);
                    valorReceita.push(elemento.valorReceita);
                    valorDespesa.push(elemento.valorDespesa);
                    valorTotal.push(elemento.valorReceita - elemento.valorDespesa);
                });

                this.setState({ data, valorReceita, valorDespesa, valorTotal, loading: false, error: false }, () => {
                    this.buildChart();
                });
            }, () => {
                this.setState({ error: true, loading: false })
            });
        })
    }

    buildChart = () => {
        if (!this.graficoDeDespesasAgrupadasPorDia.current) {
            return
        }
        const myChartRef = this.graficoDeDespesasAgrupadasPorDia.current.getContext("2d");

        if (typeof graficoBalancoDiario !== "undefined") graficoBalancoDiario.destroy(); //Necessário para atualizar o gráfico sem bugs

        graficoBalancoDiario = new Chart(myChartRef, {
            type: 'line',
            data: {
                labels: this.state.data,
                datasets: [{
                    label: 'Total de receitas no dia',
                    data: this.state.valorReceita,
                    borderColor: Financas.cores.verde,
                    backgroundColor: Financas.cores.preto
                },
                {
                    label: 'Total de despesas no dia',
                    data: this.state.valorDespesa,
                    borderColor: Financas.cores.vermelho,
                    backgroundColor: Financas.cores.preto,
                }],
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                legend: {
                    display: true,
                    position: 'bottom',
                    labels: {
                        usePointStyle: true
                    }, onHover: function (e) {
                        e.target.style.cursor = 'pointer';
                    },
                },
                hover: {
                    onHover: function (e) {
                        var point = this.getElementAtEvent(e);
                        if (point.length) {
                            e.target.style.cursor = 'pointer';
                        } else {
                            e.target.style.cursor = 'default';
                        }
                    },
                },
                aspectRatio: 1,
                animation: {
                    animateScale: true,
                    animateRotate: true
                },
                tooltips: {
                    callbacks: {
                        title: tooltipItem => {
                            return `Data: ${tooltipItem[0].xLabel}`;
                        },
                        afterTitle: tooltipItem => {
                            return `Valor: ${formatarMonetario(tooltipItem[0].yLabel)}`;
                        },
                        label: (tooltipItem, data) => {
                            const label = data.datasets[tooltipItem.datasetIndex].label;
                            const valor = formatarMonetario(tooltipItem.yLabel);
                            return `${label}: ${valor}`;
                        },
                    }
                },
                plugins: {
                    datalabels: {
                        color: Financas.cores.preto,
                    }
                },
                scales: {
                    xAxes: [{
                        ticks: {
                            callback: value => format(parse(value, 'yyyy-MM-dd', new Date()), 'dd/MM/yyyy')
                        }

                    }],
                    yAxes: [{
                        ticks: {
                            callback: value => formatarMonetario(value),
                            beginAtZero: true
                        }
                    }],
                },
            }
        });
    }

    getHeaderCard() {
        return (
            <div style={cardHeader}>
                <label
                    title="Lista as receitas e despesas de cada dia. Passando o mouse por cima dos pontos do gráfico é possível visualizar o saldo diário"
                    style={{ fontSize: '17px', color: '#333333' }}
                >
                    Receitas e despesas efetivadas ao longo do tempo
                </label>
            </div>
        );
    }

    render() {

        const totalReceitas = this.state.valorReceita.reduce((a, b) => a + b, 0)
        const totalDespesas = this.state.valorDespesa.reduce((a, b) => a + b, 0)
        if (this.state.loading) {
            return (
                <Card header={this.getHeaderCard()} style={{ borderRadius: '5px' }}>
                    <ProgressBar mode="indeterminate" style={{ height: '3px' }} />
                </Card>
            )
        } else if (this.state.error) {
            return (
                <Card header={this.getHeaderCard()} style={{ borderRadius: '5px', height: '100%' }}>
                    <FalhaAoCarregarAsInformacoes />
                </Card>
            )
        } else if (totalReceitas === 0 && totalDespesas === 0) {
            return (
                <Card header={this.getHeaderCard()} style={{ borderRadius: '5px' }}>
                    <NenhumaInformacaoEncontrada />
                </Card>
            )
        } else {
            return (
                <Card className="card-canvas-dashboard-financas" header={this.getHeaderCard()} style={{ borderRadius: '5px' }}>
                    <canvas id="graficoDeDespesasAgrupadasPorDia" ref={this.graficoDeDespesasAgrupadasPorDia} />
                </Card>
            )
        }
    }
}
