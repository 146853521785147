import React, { Component } from "react";
import { Button } from 'primereact/button';
import { Menu } from "primereact/menu";
import { usuarioPossuiPermissao } from "../../../../../../../common/autenticacao";
import { recursos, permissoes } from "../../../../../../../common/constantes/autorizacao";
import If from "../../../../../../../components/If";
import ModalTransferencia from "../../../../../../financas/Lancamentos/components/ModalTransferencia";
import ModalLancamentoReceita from "../../../../../../financas/Lancamentos/components/ModalLancamentoReceita";
import ModalLancamentoDespesa from "../../../../../../financas/Lancamentos/components/ModalLancamentoDespesa";
import ModalContaReceber from "../../../../../../financas/ContasReceber/components/ModalContaReceber";
import reactAutobind from "react-autobind";
import ModalContaPagar from "../../../../../../financas/ContasPagar/components/ModalContaPagar";

class BotoesNovosLancamentos extends Component {

    constructor(props) {
        super(props);

        reactAutobind(this);

        this.state = {
            exibirModalContaReceber: false,
            exibirModalContaPagar: false,

            exibirModalLancamentoDespesa: false,
            exibirModalLancamentoReceita: false,
            exibirModalTransferencia: false,

            registroSelecionado: null,

            podeInserirContaAReceber: usuarioPossuiPermissao(recursos.FINANCAS_CONTAS_RECEBER, permissoes.INSERIR),
            podeInserirContaAPagar: usuarioPossuiPermissao(recursos.FINANCAS_CONTAS_PAGAR, permissoes.INSERIR),
            podeInserirNovaMovimentacao: usuarioPossuiPermissao(recursos.FINANCAS_LANCAMENTOS, permissoes.INSERIR),
        }
    }

    onHideModalContaReceber(novaConta) {
        this.setState({ exibirModalContaReceber: false });
        novaConta && this.props.atualizarGraficos();
    }

    onHideModalContaPagar(novaConta) {
        this.setState({ exibirModalContaPagar: false });
        novaConta && this.props.atualizarGraficos();
    }

    onHideModalTransferencia(novaTransferencia) {
        this.setState({ exibirModalTransferencia: false });
        novaTransferencia && this.props.atualizarGraficos();
    }

    onHideModalNovaReceita(novaReceita) {
        this.setState({ exibirModalLancamentoReceita: false });
        novaReceita && this.props.atualizarGraficos();
    }

    onHideModalNovaDespesa(novaDespesa) {
        this.setState({ exibirModalLancamentoDespesa: false });
        novaDespesa && this.props.atualizarGraficos();
    }

    render() {

        const {
            exibirModalLancamentoReceita,
            exibirModalLancamentoDespesa,
            exibirModalTransferencia,

            exibirModalContaPagar,
            exibirModalContaReceber,

            registroSelecionado,

            podeInserirContaAReceber,
            podeInserirContaAPagar,
            podeInserirNovaMovimentacao
        } = this.state;
        const { isMobile } = this.props

        const styleButtons = {
            margin: isMobile ? '3px 5px' : '3px 7px',
            width: isMobile ? '100%' : '185px',
        };
        const classNameButtons = isMobile ? 'p-grid p-justify-center' : 'p-grid p-justify-start';

        const opcoesNovoMobile = isMobile ?
            [{
                label: 'Conta a pagar',
                icon: 'fa fa-plus',
                command: () => this.setState({ exibirModalContaPagar: true }),
                disabled: !podeInserirContaAPagar,
            },
            {
                label: 'Conta a receber',
                icon: 'fa fa-plus',
                command: () => this.setState({ exibirModalContaReceber: true }),
                disabled: !podeInserirContaAReceber
            }]
            : []


        return (
            <>
                <Menu
                    model={[
                        ...opcoesNovoMobile,
                        {
                            label: 'Receita',
                            icon: 'fa fa-plus',
                            command: () => this.setState({ exibirModalLancamentoReceita: true }),
                            disabled: !podeInserirNovaMovimentacao
                        },
                        {
                            label: 'Despesa',
                            icon: 'fa fa-plus',
                            command: () => this.setState({ exibirModalLancamentoDespesa: true }),
                            disabled: !podeInserirNovaMovimentacao
                        },
                        {
                            label: 'Transferência',
                            icon: 'fa fa-plus',
                            command: () => this.setState({ exibirModalTransferencia: true }),
                            disabled: !podeInserirNovaMovimentacao
                        }
                    ]}
                    popup={true}
                    ref={el => this.novoLacamento = el}
                />
                <div className="p-col-12" style={{ padding: '0px' }}>
                    <div className={classNameButtons} style={{ margin: '0px' }}>
                        {!isMobile &&
                            <Button
                                label="Nova conta a receber"
                                className="p-button-success"
                                onClick={() => this.setState({ exibirModalContaReceber: true })}
                                style={styleButtons}
                                disabled={!podeInserirContaAReceber}
                            />
                        }
                        {!isMobile &&
                            <Button
                                label="Nova conta a pagar"
                                className="p-button-danger"
                                onClick={() => this.setState({ exibirModalContaPagar: true })}
                                style={styleButtons}
                                disabled={!podeInserirContaAPagar}
                            />
                        }

                        <Button
                            className='p-button-primary'
                            type='button'
                            label={isMobile ? 'Novo registro' : 'Nova movimentação'}
                            icon="fa fa-angle-down"
                            iconPos="right"
                            onClick={event => this.novoLacamento.toggle(event)}
                            style={styleButtons}
                            disabled={(!podeInserirNovaMovimentacao && !isMobile) || (!podeInserirNovaMovimentacao && !podeInserirContaAPagar && !podeInserirContaAReceber)}
                        />

                    </div>
                </div>
                <If test={exibirModalTransferencia}>
                    <ModalTransferencia
                        transferenciaSelecionada={null}
                        visible={exibirModalTransferencia}
                        valorPadraoData={new Date()}
                        onHide={this.onHideModalTransferencia}
                    />
                </If>
                <If test={exibirModalLancamentoReceita}>
                    <ModalLancamentoReceita
                        registroSelecionado={null}
                        visible={exibirModalLancamentoReceita}
                        valorPadraoData={new Date()}
                        onHide={this.onHideModalNovaReceita}
                    />
                </If>
                <If test={exibirModalLancamentoDespesa}>
                    <ModalLancamentoDespesa
                        registroSelecionado={null}
                        visible={exibirModalLancamentoDespesa}
                        valorPadraoData={new Date()}
                        onHide={this.onHideModalNovaDespesa}
                    />
                </If>
                <If test={exibirModalContaReceber}>
                    <ModalContaReceber
                        visible={exibirModalContaReceber}
                        onHide={this.onHideModalContaReceber}
                        registroSelecionado={null}
                        valorPadraoDataVencimento={new Date()}
                    />
                </If>
                <If test={exibirModalContaPagar}>
                    <ModalContaPagar
                        visible={exibirModalContaPagar}
                        onHide={this.onHideModalContaPagar}
                        registroSelecionado={registroSelecionado}
                        valorPadraoDataVencimento={new Date()}
                    />
                </If>
            </>
        )
    }
}

export default BotoesNovosLancamentos;
