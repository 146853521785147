import React, { Component } from 'react'
import autoBind from 'react-autobind';
import { buscarResultadoNoPeriodo } from './requests';
import Col from '../../../../../../../components/Col';
import CardResultado from './components/CardResultado';
import { MdAttachMoney, MdMoneyOff } from 'react-icons/md';
import { Vendas } from '../../../../../../util/constantes';

export default class CardsResultadoNoPeriodo extends Component {

    constructor(props) {
        super(props);

        autoBind(this);

        this.state = {
            loading: true,
            error: false,
            valorVendas: 0,
            percentualEvolucaoVendas: 0,
            valorOrcamentos: 0,
            percentualEvolucaoOrcamentos: 0,
            valorCanceladas: 0,
            percentualEvolucaoCanceladas: 0,
        }
    }

    componentDidMount() {
        this.carregarInformacoes();
    }

    componentDidUpdate(prevProps) {
        const { dataInicial, dataFinal } = this.props;

        if (prevProps.dataInicial !== dataInicial || prevProps.dataFinal !== dataFinal) {
            this.carregarInformacoes();
        }

        if (prevProps.atualizarGraficoNovamente !== this.props.atualizarGraficoNovamente) {
            this.carregarInformacoes(false);
        }
    }

    carregarInformacoes(exibirLoading = true) {
        const { dataInicial, dataFinal } = this.props;

        this.setState({ loading: exibirLoading, error: false }, () => {
            buscarResultadoNoPeriodo(dataInicial, dataFinal, ({ data }) => {
                const valorVendas = data.periodoAtual.valorVendas
                const valorVendasPeriodoAnterior = data.periodoAnterior.valorVendas
                let percentualEvolucaoVendas = 0
              
                if (valorVendasPeriodoAnterior && valorVendas) {
                    percentualEvolucaoVendas = ((valorVendas * 100 / valorVendasPeriodoAnterior) - 100).toFixed(0)
                }

                const valorOrcamentos = data.periodoAtual.valorOrcamentos
                const valorOrcamentosPeriodoAnterior = data.periodoAnterior.valorOrcamentos
                let percentualEvolucaoOrcamentos = 0
                if (valorOrcamentosPeriodoAnterior && valorOrcamentos) {
                    percentualEvolucaoOrcamentos = ((valorOrcamentos * 100 / valorOrcamentosPeriodoAnterior) - 100).toFixed(0)
                }

                const valorCanceladas = data.periodoAtual.valorCanceladas
                const valorCanceladasPeriodoAnterior = data.periodoAnterior.valorCanceladas
                let percentualEvolucaoCanceladas = 0
                if (valorCanceladasPeriodoAnterior && valorCanceladas) {
                    percentualEvolucaoCanceladas = ((valorCanceladas * 100 / valorCanceladasPeriodoAnterior) - 100).toFixed(0)
                }

                this.setState({
                    valorVendas,
                    percentualEvolucaoVendas,

                    valorOrcamentos,
                    percentualEvolucaoOrcamentos,

                    valorCanceladas,
                    percentualEvolucaoCanceladas,

                    loading: false,
                    error: false
                })
            }, () => {
                this.setState({ error: true, loading: false })
            });
        })
    }

    render() {

        const { loading, error,
            valorVendas, percentualEvolucaoVendas,
            valorOrcamentos, percentualEvolucaoOrcamentos,
            valorCanceladas, percentualEvolucaoCanceladas,
        } = this.state
        //Remove o padding lateral dos cards quando for dispositivo móvel
        const paddingCards = this.props.isMobile ? { padding: '0.5em 0em' } : { padding: '0.5em' };

        return (
            <>
                <Col xs="12" sm="6" md="4" lg="4" xl="4" style={paddingCards}>
                    <CardResultado
                        title="Orçamentos no período"
                        helpMessage="Valor total dos orçamentos efetuados no período selecionado"
                        valor={valorOrcamentos}
                        percentualEvolucao={percentualEvolucaoOrcamentos}
                        loading={loading}
                        error={error}
                        colorValor={Vendas.cores.azul}
                        colorPercentualEvolucao={percentualEvolucaoOrcamentos >= 0 ? Vendas.cores.verde : Vendas.cores.vermelho}
                        backgroundPercentualEvolucao={percentualEvolucaoOrcamentos >= 0 ? Vendas.cores.verde : Vendas.cores.vermelho}
                        icon={<MdAttachMoney size={50} color={Vendas.cores.azul} />}
                    />
                </Col>
                <Col xs="12" sm="6" md="4" lg="4" xl="4" style={paddingCards}>
                    <CardResultado
                        title="Vendas no período"
                        helpMessage="Valor total das vendas efetuadas no período selecionado"
                        valor={valorVendas}
                        percentualEvolucao={percentualEvolucaoVendas}
                        loading={loading}
                        error={error}
                        colorValor={Vendas.cores.verde}
                        colorPercentualEvolucao={percentualEvolucaoVendas >= 0 ? Vendas.cores.verde : Vendas.cores.vermelho}
                        backgroundPercentualEvolucao={percentualEvolucaoVendas >= 0 ? Vendas.cores.verde : Vendas.cores.vermelho}
                        icon={<MdAttachMoney size={50} color={Vendas.cores.verde} />}
                    />
                </Col>
                <Col xs="12" sm="6" md="4" lg="4" xl="4" style={paddingCards}>
                    <CardResultado
                        title="Cancelados no período"
                        helpMessage="Valor total das vendas e orçamentos cancelados no período selecionado"
                        valor={valorCanceladas}
                        percentualEvolucao={percentualEvolucaoCanceladas}
                        loading={loading}
                        error={error}
                        colorValor={Vendas.cores.vermelho}
                        colorPercentualEvolucao={percentualEvolucaoCanceladas >= 0 ? Vendas.cores.vermelho : Vendas.cores.verde}
                        backgroundPercentualEvolucao={percentualEvolucaoCanceladas >= 0 ? Vendas.cores.vermelho : Vendas.cores.verde}
                        icon={<MdMoneyOff size={50} color={Vendas.cores.vermelho} />}
                    />
                </Col>

            </>
        )
    }
}

