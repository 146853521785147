export const styleStatusDefault = {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginBottom: '10px',
    marginLeft: '5px',
    marginRight: '5px',
};

export const styleNumeracaoDefault = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '16px',
    width: '30px',
};
