import React, { Component } from 'react';
import ResponsiveModal from 'react-responsive-modal';
import propTypes from 'prop-types'
import autoBind from 'react-autobind'
import { connect } from 'react-redux';
import { buscarDadosLoginLocalStorage } from '../../common/autenticacao';

import './style/index.css';

class Modal extends Component {

    constructor(props) {
        super(props)

        autoBind(this);
    }

    getCloseOnEsc() {
        if (document.getElementsByClassName('ajs-no-overflow').length > 0) {
            return false
        }
        return this.props.closeOnEsc
    }

    possuiSuporteViaChat() {
        let organizacao = buscarDadosLoginLocalStorage().organizacao
        return organizacao && organizacao.plano && organizacao.plano.suporteChat
    }

    render() {

        const {
            showCloseIcon,
            visible,
            closeOnOverlayClick,
            onHide,
            focusTrapped,
            className,
            closeIconSize,
            children,
            header,
            styleOverlay,
            styleModal,
            styleCloseButton,
            styleCloseIcon,
            isMobile
        } = this.props

        const styles = {
            modal: {
                maxWidth: '900px',
                minWidth: '0px',
                width: '100%',
                ...styleModal,
            },
            overlay: {
                transform: 'translateZ(0)',
                paddingTop: isMobile ? '0px' : '20px',
                paddingRight: isMobile ? '0px' : '20px',
                paddingBottom: this.possuiSuporteViaChat() ? '55px' : '0px',
                paddingLeft: isMobile ? '0px' : '20px',
                ...styleOverlay
            },
            closeButton: {
                cursor: 'pointer',
                ...styleCloseButton
            },
            closeIcon: {
                ...styleCloseIcon
            }
        }
        return (
            <ResponsiveModal
                open={visible}
                closeOnOverlayClick={closeOnOverlayClick}
                onClose={() => onHide()}
                focusTrapped={showCloseIcon ? focusTrapped : false}
                closeIconSize={closeIconSize}
                styles={styles}
                showCloseIcon={showCloseIcon}
                closeOnEsc={this.getCloseOnEsc()}
                className={className}
            >
                <div className="modal">
                    {header ? <h1>{header}</h1> : null}
                    {children}
                </div>
            </ResponsiveModal>
        );
    }
}

Modal.defaultProps = {
    closeIconSize: 20,
    closeOnOverlayClick: false,
    focusTrapped: true,
    width: "85%",
    showCloseIcon: true
}

Modal.propTypes = {
    /** Define se o componente está visível, propriedade de controle */
    visible: propTypes.bool,
    /** Se deve ser fechado ao clicar fora */
    closeOnOverlayClick: propTypes.bool,
    /** Evento disparado ao fechar */
    onHide: propTypes.func,
    /** Se o foco deve ficar sempre só dentro do modal */
    focusTrapped: propTypes.bool,
    /** Tamanho do ícone do botão close */
    closeIconSize: propTypes.number,
    /** Texto do cabeçalho */
    header: propTypes.any,
    /** Tamanho do modal */
    width: propTypes.any,

    styleOverlay: propTypes.object,
    /** Define o estilo do modal */
    styleModal: propTypes.object,
    /** Define o estilo do botão close */
    styleCloseButton: propTypes.object,
    /** Define o estilo do icone */
    styleCloseIcon: propTypes.object,
    /** Define o que acontece quando der esc no modal*/
    closeOnEsc: propTypes.bool,
    /** Nome da classe do componente*/
    className: propTypes.string
}

const mapStateToProps = state => ({
    isMobile: state.dispositivo.isMobile,
})

export default connect(mapStateToProps)(Modal);

