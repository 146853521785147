import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { fazerLogout, usuarioPossuiPermissao } from '../../../../common/autenticacao';
import { confirm } from '../../../../components/Toast';
import { connect } from 'react-redux';
import autoBind from 'react-autobind';
import { recursos, permissoes } from '../../../../common/constantes/autorizacao'
import { Menu } from 'primereact/menu';

class AppTopbar extends Component {

    constructor(props) {
        super(props)

        autoBind(this);
        this.state = {
            menuConfiguracoesItens: []
        }
    }

    componentDidMount() {
        this.setState({ menuConfiguracoesItens: this.buscarMenuConfiguracoesItens() })
    }

    logoutClick() {
        confirm('Confirmação', 'Deseja mesmo sair?', () => {
            fazerLogout();
        })
    }

    alterarRota(rota) {
        this.props.history.push(rota);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.dataAtualizacaoMenuLateral !== this.props.dataAtualizacaoMenuLateral) {
            this.setState({ menuConfiguracoesItens: this.buscarMenuConfiguracoesItens() })
        }
    }

    buscarMenuConfiguracoesItens() {
        let menuConfiguracoesItens = [
            { label: 'Configurações gerais', command: () => this.alterarRota('/configuracoes_gerais') }
        ]
        if (usuarioPossuiPermissao(recursos.PLANOS, permissoes.VISUALIZAR)) {
            menuConfiguracoesItens.push({ label: 'Planos e preços', command: () => this.alterarRota('/planos') })
        }
        if (usuarioPossuiPermissao(recursos.USUARIOS, permissoes.VISUALIZAR)) {
            menuConfiguracoesItens.push({ label: 'Usuários do sistema', command: () => this.alterarRota('/usuarios') })
        }
        if (usuarioPossuiPermissao(recursos.PAPEIS, permissoes.VISUALIZAR)) {
            menuConfiguracoesItens.push({ label: 'Grupos de usuários e permissões', command: () => this.alterarRota('/papeis') })
        }
     
        return menuConfiguracoesItens
    }

    politicaDePrivacidade() {
        window.open('http://www.microsys.inf.br/s/index.php?pg=lgpd', '_blank')
    }

    render() {

        const menuMinhaContaItens = [
            { label: 'Minha conta', command: () => this.alterarRota('/minhaconta')},
            { label: 'Politica de privacidade', command: () => this.politicaDePrivacidade()},
            { label: 'Sair do sistema', command: this.logoutClick }           
        ]

        return (
            <React.Fragment>
                <Menu model={this.buscarMenuConfiguracoesItens()} popup ref={el => this.menuConfiguracoes = el} style={{ width: '245px' }} />
                <Menu model={menuMinhaContaItens} popup ref={el => this.menuMinhaConta = el} />
                <div className={`layout-topbar clearfix ${this.props.className}`} id={this.props.id}>

                    <span className="layout-menu-button" onClick={this.props.onToggleMenu}>
                        <span className="fa fa-bars" />
                    </span>

                    <span className="layout-menu-container">
                        <span className="layout-menu-container-icons" title="Minha conta">

                            <span onClick={(event) => this.menuMinhaConta.toggle(event)}
                                className='layout-topbar-icon fa fa-user'>
                            </span>

                            <span onClick={(event) => this.menuConfiguracoes.toggle(event)} title="Configurações"
                                className='layout-topbar-icon fa fa-cog'>
                            </span>
                        </span>
                    </span>
                </div>

            </React.Fragment>
        );
    }
}

AppTopbar.defaultProps = {
    onToggleMenu: null
}

AppTopbar.propTypes = {
    onToggleMenu: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    dataAtualizacaoMenuLateral: state.menuLateral.dataAtualizacaoMenuLateral
})

export default connect(mapStateToProps)(withRouter(AppTopbar));
